import React, { useEffect } from "react";
import { EditOutlined, MessageOutlined, SyncOutlined, UserDeleteOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  message,
  Row,
  Space,
  Statistic,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import truncate from "lodash.truncate";
import uniq from "lodash.uniq";
import { useTranslation } from "react-i18next";

import {
  chatActions,
  RequestStatus,
  traineeActions,
  traineeMeasurementsActions,
  traineeMeasurementsSelectors,
} from "@fitness-app/app-store";
import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { ChatStatus } from "@fitness-app/data-models/entities/Chat";
import { TraineeStatus, type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useFormHandler } from "~/hooks/useFormHandler";
import TraineeStatusBadge from "~/modules/Trainee/components/TraineeStatusBadge/TraineeStatusBadge";
import TraineeAccess from "~/modules/Trainee/TraineeProfile/components/TraineeAccess";
import { TraineeAppVersion } from "~/modules/Trainee/TraineeProfile/components/TraineeAppVersion";
import TraineeProfileForm from "~/modules/Trainee/TraineeProfile/TraineeProfileForm/TraineeProfileForm";
import { type TraineeProfileFormModel } from "~/modules/Trainee/TraineeProfile/TraineeProfileForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const getClientAge = (birthday: string) => {
  return dayjs().diff(dayjs(birthday, "YYYY"), "years");
};

const ProfileInfo = () => {
  const { profile, profileStatus, userStatus, showUpdateProfileData } = useAppSelector((store) => store.trainee);
  const { t } = useTranslation(["trainees"]);
  const { memberId } = useUserClaims();
  const { isTeamMember, isTrainer } = useUserRole();
  const [showResendButton, toggleResendButton] = React.useState(true);
  const { openFormModal, showModalForm, hideFormModal, formModel } = useFormHandler<
    TraineeProfileFormModel,
    TraineeProfile
  >();
  const [loading, onSuccess, onError, onStart] = useEntityChange();
  const dispatch = useAppDispatch();
  const latestWeight = useAppSelector(traineeMeasurementsSelectors.getLatestWeightMeasurement);

  const clientNotAssigned = isTeamMember && profile?.assignedTrainerId !== memberId;
  const traineeId = profile?.id;

  useEffect(() => {
    if (traineeId) {
      void dispatch(traineeMeasurementsActions.fetchLatestWeightMeasurement({ traineeId }));
    }
  }, [traineeId]);

  if (!profile || profileStatus === RequestStatus.FETCHING) {
    return <Card bodyStyle={{ padding: 0, minHeight: 300 }} style={{ height: "100%" }} loading />;
  }

  const shouldDisableOption = profile?.status === TraineeStatus.ARCHIVED && isTeamMember;
  const handleMainButton = () => {
    if (shouldDisableOption) {
      // resendInvite();
    } else {
      openFormModal(
        {
          firstName: profile.user?.firstName ?? profile.firstName ?? "",
          assignedTrainerId: profile.assignedTrainerId,
          lastName: profile.user?.lastName ?? profile.lastName ?? "",
          comment: profile.comment ?? "",
          tags: uniq(profile.tags ?? []),
          email: profile.email,
          gender: profile.gender,
          type: profile.type,
          phoneNumber: String(profile.metadata?.phoneNumber || profile.productClient?.phoneNumber || ""),
        },
        profile,
      );
    }
  };

  const onClientArchive = async () => {
    void message.loading({ content: "Archiwizujemy dane klienta...", key: "archiveClient", duration: 0 });
    await dispatch(
      traineeActions.archiveTraineeProfile({
        traineeId: profile.id,
      }),
    );
    message.destroy("archiveClient");
    void message.success("Pomyślnie zarchiwizowano klienta.");
  };

  const resendInvite = async (traineeId: string, userId: string) => {
    if (!profile || !profile.productId) {
      void message.warning("Klient nie jest przypisany do żadnego produktu. Zgłoś problem do administratora.");
      return;
    }
    try {
      toggleResendButton(false);
      void message.loading({ duration: 0, key: "resendInvite", content: "Przetwarzam..." });
      await dispatch(traineeActions.resendInvitationToApp({ traineeId, userId })).unwrap();
      void message.success("E-mail z zaproszeniem do aplikacji i kodem logowania został wysłany.");
    } catch (e) {
      void message.error(String(e));
      toggleResendButton(true);
    } finally {
      message.destroy("resendInvite");
    }
  };

  const menu = (
    <Menu>
      {isTrainer && profile.userId ? (
        <Menu.Item
          key="1"
          icon={<SyncOutlined />}
          onClick={() => {
            if (profile.userId) {
              void resendInvite(profile.id, profile.userId);
            }
          }}
        >
          {t("resendInvite")}
        </Menu.Item>
      ) : null}
      {!shouldDisableOption ? (
        <Menu.Item
          key="3"
          danger
          icon={<UserDeleteOutlined />}
          onClick={onClientArchive}
          disabled={!isTrainer || Boolean(profile.productId)}
        >
          {t("common:button.archive")}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const updateTraineeProfile = async (model: TraineeProfileFormModel) => {
    onStart();
    const extended: TraineeProfileFormModel & { metadata?: TraineeProfile["metadata"] } = model;
    if (!extended.assignedTrainerId) {
      extended.assignedTrainerId = null;
    }

    if (extended.phoneNumber) {
      extended.metadata = {
        ...(profile.metadata || {}),
        phoneNumber: model.phoneNumber || null,
      };
    }

    delete extended.phoneNumber;

    try {
      await dispatch(
        traineeActions.updateTrainee({
          id: profile.id,
          profile: extended,
        }),
      ).unwrap();
      onSuccess(t<string>("messages.successfullyUpdatedProfile"));
      hideFormModal();
    } catch {
      onError();
    }
  };

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      id="profile-info"
      className="activity-card h-full"
      title={
        <Space size={6} align="center">
          {t("sections.basicInfo")}{" "}
          {profile.assignedTrainer ? (
            <div>
              (<span>{t("profile.trainer")}:</span> <Tag color="magenta">{getAuthorName(profile.assignedTrainer)}</Tag>)
            </div>
          ) : (
            <div className={profile.type === AppAccessType.Limited ? "hidden" : undefined}>
              <Tag color="red">{t("profile.lackOfTrainer")}</Tag>
            </div>
          )}
        </Space>
      }
      extra={
        shouldDisableOption ? null : (
          <Dropdown.Button size="small" onClick={handleMainButton} overlay={menu}>
            <EditOutlined /> {t("common:button.edit")}
          </Dropdown.Button>
        )
      }
    >
      <TraineeStatusBadge
        banner
        status={profile.status}
        extraAction={
          [TraineeStatus.INACTIVE, TraineeStatus.TERMINATED].includes(profile.status) &&
          profile.userId &&
          showResendButton ? (
            <Tooltip title={t("resendInvite")}>
              <Button
                className="ml-2"
                size="small"
                onClick={() => resendInvite(profile.id, profile.userId!)}
                icon={<SyncOutlined />}
              />
            </Tooltip>
          ) : undefined
        }
      />

      {profile.userId && profile.status === TraineeStatus.ACTIVE ? (
        <TraineeAppVersion userId={profile.userId} traineeId={profile.id} />
      ) : null}
      <Row gutter={[8, 8]} style={{ padding: 16 }} className="w-full">
        <Col xl={24} xxl={12} flex={1} className="w-full">
          <Space direction="vertical" align="center" className="w-full" size={16}>
            <Avatar
              icon={<UserOutlined />}
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }}
              src={profile.user?.avatarUrl}
            />
            <div className="flex flex-col items-center gap-y-2">
              {showUpdateProfileData ? (
                <h3>
                  {profile.firstName} {profile.lastName}
                </h3>
              ) : (
                <h3>
                  {profile.user?.firstName || profile.firstName} {profile.user?.lastName ?? profile.lastName ?? ""}
                </h3>
              )}
              <div className="flex flex-wrap justify-center gap-x-2 gap-y-1">
                <Typography.Paragraph
                  underline
                  copyable={{ tooltips: [t("copy"), t("copied")] }}
                  style={{ marginBottom: 0 }}
                >
                  {profile.email}
                </Typography.Paragraph>
                {profile.metadata?.phoneNumber ||
                  (profile.productClient?.phoneNumber && (
                    <Typography.Paragraph
                      underline
                      copyable={{ tooltips: [t("copy"), t("copied")] }}
                      style={{ marginBottom: 0 }}
                    >
                      {profile.metadata?.phoneNumber || profile.productClient?.phoneNumber}
                    </Typography.Paragraph>
                  ))}
              </div>

              {isTrainer && (
                <Space>
                  <Typography.Text copyable={{ tooltips: [profile.id, t("common:copied")], text: profile.id }}>
                    Trainee id: {truncate(profile.id, { length: 10 })}
                  </Typography.Text>
                  {profile.userId && (
                    <Typography.Text
                      copyable={{ tooltips: [profile.userId, t("common:copied")], text: profile.userId }}
                    >
                      User id: {truncate(profile.userId, { length: 10 })}
                    </Typography.Text>
                  )}
                </Space>
              )}
            </div>

            <div className="flex flex-col items-center gap-y-2">
              <Typography.Text type="secondary">
                {t("basicInfo.lastSeenAt")}:{" "}
                {userStatus?.lastChanged ? dayjs(userStatus.lastChanged).format("HH:mm DD.MM.YYYY") : "-"}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t("basicInfo.added")}: {dayjs(profile.createdAt).format("DD.MM.YYYY")}
              </Typography.Text>
              {isTrainer && (
                <div>
                  <Typography.Text type="secondary">{t("profileForm.accessType")}:</Typography.Text>{" "}
                  <Tag color={profile.type === AppAccessType.FullAccess ? "success" : "warning"}>
                    {t(`accessType.${profile.type}`)}
                  </Tag>
                </div>
              )}
            </div>

            <div className="flex flex-wrap justify-center  gap-y-2" style={{ marginBottom: 8 }}>
              {uniq(profile.tags || []).map((tag) => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))}
            </div>

            <Tooltip
              title={
                (!profile.userId &&
                  "Nie można wysłać wiadomości do tego użytkownika ponieważ nie potwierdził on Twojego zaproszenia.") ||
                (clientNotAssigned &&
                  "Nie można wysyłać wiadomości do tego użytkownika, ponieważ nie jest on przypisany do Ciebie")
              }
              placement="right"
            >
              <Button
                type="primary"
                icon={<MessageOutlined />}
                disabled={shouldDisableOption || !profile.userId || clientNotAssigned}
                onClick={() =>
                  profile.userId
                    ? dispatch(
                        chatActions.findAndAddChannelToList({
                          userId: profile.userId,
                          type: profile?.status === TraineeStatus.ARCHIVED ? ChatStatus.Archived : ChatStatus.Active,
                        }),
                      )
                    : undefined
                }
              >
                {t("profile.sendMessage")}
              </Button>
            </Tooltip>

            <Space direction="horizontal" size={36} split={<Divider type="vertical" style={{ height: 35 }} />}>
              <Statistic
                title="Waga"
                value={
                  latestWeight?.weight || profile.weight
                    ? `${latestWeight.weight ? latestWeight.weight ?? "0" : profile.weight ?? "0"} kg`
                    : "-"
                }
              />
              <Statistic title="Wzrost" value={profile.height ? `${profile.height} cm` : "-"} />
              <Statistic title="Wiek" value={profile.birthday ? `${getClientAge(profile.birthday)} lat` : "-"} />
            </Space>
          </Space>
        </Col>
        <Col xl={24} xxl={12} flex={1}>
          <TraineeAccess trainee={profile} shouldDisableOption={shouldDisableOption} />
        </Col>
        <ModalForm
          title={t("profileForm.title")}
          open={showModalForm}
          onCancel={hideFormModal}
          loading={Boolean(loading)}
        >
          <TraineeProfileForm onSubmit={updateTraineeProfile} model={formModel} />
        </ModalForm>
      </Row>
    </Card>
  );
};

export default ProfileInfo;
