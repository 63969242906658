import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import MealsPlans from "~/modules/Nutrition/MealsPlans/MealsPlans";
import NutritionDishes from "~/modules/Nutrition/NutritionDishes/NutritionDishes";
import NutritionIngredients from "~/modules/Nutrition/NutritionIngredients/NutritionIngredients";
import SupplementsTemplates from "~/modules/Nutrition/SupplementsTemplates/SupplementsTemplates";

const TABS_KEY = ["plans", "templates", "dishes", "ingredients", "supplements"];

const Nutrition = (): React.ReactElement | null => {
  const { t } = useTranslation(["nutrition", "common"]);

  const { tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const tabFromUrl = tab || location.pathname.split("/").slice(-1)[0] || "";

  const activeTab = TABS_KEY.includes(tabFromUrl) ? tabFromUrl : "plans";

  useEffect(() => {
    if (!TABS_KEY.includes(tabFromUrl)) {
      navigate(`/nutrition/plans`);
    }
  }, [tabFromUrl]);

  return (
    <div className="p-6">
      <Tabs
        type="card"
        defaultActiveKey="dishes"
        className="tabs-reset"
        activeKey={activeTab}
        onChange={(tab) => navigate(`/nutrition/${tab}`)}
      >
        <Tabs.TabPane key="plans" tab={t("plans")}>
          <MealsPlans />
        </Tabs.TabPane>

        <Tabs.TabPane key="templates" tab={t("mealDayTemplates")} disabled>
          test
        </Tabs.TabPane>

        <Tabs.TabPane key="dishes" tab={t("dishes")}>
          <NutritionDishes />
        </Tabs.TabPane>

        <Tabs.TabPane key="ingredients" tab={t("ingredients")}>
          <NutritionIngredients />
        </Tabs.TabPane>

        <Tabs.TabPane key="supplements" tab={t("supplementsTemplates")}>
          <SupplementsTemplates />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Nutrition;
