import { type Database } from "@fitness-app/supabase";

import { type AuthorTypeEnum } from "./AuthorTypeEnum";
import { type TrainingStatus } from "./TrainingProgram";

export type MeasurementEntity = Database["public"]["Tables"]["measurement"]["Row"];

export enum MeasurementType {
  FeedbackAboutTraining = "feedback_about_training",
  FeedbackAboutNutrition = "feedback_about_nutrition",
  BodyMeasurements = "body_measurements",
  WeightMeasurement = "weight_measurement",
  HydrationMeasurement = "hydration_measurement",
  ClientPhoto = "client_photo",
}

export interface LatestWeightMeasurement {
  date: string | null;
  weight: number;
  change: null | number;
}

export enum BodyMeasurement {
  BODY_FAT = "bf",
  BICEPS_LEFT = "bicepsLeft",
  BICEPS_RIGHT = "bicepsRight",
  CALF_LEFT = "calfLeft",
  CALF_RIGHT = "calfRight",
  CHEST = "chest",
  HIPS = "hips",
  MIDDLE = "middle",
  NECK = "neck",
  THIGH_LEFT = "thighLeft",
  THIGH_RIGHT = "thighRight",
  WAIST = "waist",
}

export type BodyMeasurementData = Record<BodyMeasurement, number | null>;

export interface BodyMeasurements extends Omit<MeasurementEntity, "data"> {
  data: BodyMeasurementData;
  creator: AuthorTypeEnum;
  type: MeasurementType.BodyMeasurements;
}

export interface WeightMeasurementData {
  weight: number;
}

export interface WeightMeasurement extends Omit<MeasurementEntity, "data"> {
  data: WeightMeasurementData;
  creator: AuthorTypeEnum;
  type: MeasurementType.WeightMeasurement;
}

export interface HydrationMeasurementData {
  portion: number;
}

export interface HydrationMeasurement extends Omit<MeasurementEntity, "data"> {
  data: HydrationMeasurementData;
  creator: AuthorTypeEnum;
  type: MeasurementType.HydrationMeasurement;
}

export enum ClientPhotoType {
  Front = "front",
  Back = "back",
  Side = "side",
}

export interface ClientPhotoData {
  comment: string;
  isAllowedToShare: boolean;
  content: {
    photoId: string;
    url: string;
    thumbnailUrl?: string;
    type: ClientPhotoType;
  }[];
}

export interface ClientPhoto extends Omit<MeasurementEntity, "data"> {
  data: ClientPhotoData;
  creator: AuthorTypeEnum;
  type: MeasurementType.ClientPhoto;
}

export interface TrainingFeedbackData {
  rate: number;
  comment: string;
  programId: string | null;
  workoutId: string;
  activityId: string | null;
  trainingStatus: TrainingStatus;
}

export interface RejectedTrainingFeedbackData {
  rate: null;
  comment: string;
  programId: string | null;
  workoutId: string;
  activityId: string | null;
  trainingStatus: TrainingStatus.REJECTED;
}

export interface TrainingFeedback extends Omit<MeasurementEntity, "data"> {
  data: RejectedTrainingFeedbackData | TrainingFeedbackData;
  creator: AuthorTypeEnum;
  type: MeasurementType.FeedbackAboutTraining;
}

export type Measurement = BodyMeasurements | WeightMeasurement | HydrationMeasurement | ClientPhoto | TrainingFeedback;
