import React, { memo, useMemo, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  MoreOutlined,
  SmileOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import data from "@emoji-mart/data";
import i18n from "@emoji-mart/data/i18n/pl.json";
import Picker from "@emoji-mart/react";
import { Avatar, Dropdown, Popconfirm, Popover, Tooltip, Typography, type MenuProps } from "antd";
import dayjs from "dayjs";
import { type BaseEmoji } from "emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index";
import { useTranslation } from "react-i18next";
import Linkify from "react-linkify";

import { updateChatMessage } from "@fitness-app/app-store/src/store/reducers/chat/actions";
import { UserRole } from "@fitness-app/data-models";
import {
  MessageInContext,
  MessageType,
  type ChatMember,
  type ChatMessage as IChatMessage,
} from "@fitness-app/data-models/entities/Chat";
import { getUserInitials } from "@fitness-app/utils";
import { cn } from "@fitness-app/utils/src/styles/cn";

import FilesList from "~/components/Upload/FilesList";
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useOuterClick } from "~/hooks/useOuterClick";
import { useChatMessageEditContext } from "~/modules/Chat/components/ChatMessageEditContext";
import ChatMessageReactions from "~/modules/Chat/components/ChatMessagesList/ChatMessageReactions";
import { useChatUtilitiesContext } from "~/modules/Chat/providers/ChatUtilitiesProvider";
import { useAppDispatch } from "~/store/initializeStore";

interface ChatMessageProps {
  incomingMessage: boolean;
  authorData?: ChatMember;
  channelId: string;
  message: IChatMessage;
  shouldRenderMetaTag: boolean;
  authorsById: Record<string, ChatMember>;
}

export const renderDate = (timestamp: string) => dayjs(timestamp).locale("pl").format("LT");

const ChatMessage = ({
  message,
  incomingMessage,
  shouldRenderMetaTag,
  authorData,
  authorsById,
}: ChatMessageProps): React.ReactElement => {
  const { isClient, userId } = useUserRole();
  const [openEmojiPicker, toggleEmojiPicker] = useState(false);
  const dispatch = useAppDispatch();
  const innerRef = useOuterClick(openEmojiPicker ? () => toggleEmojiPicker(false) : undefined, 1);
  const { setNoteModel } = useChatUtilitiesContext();
  const { setMessageToEdit } = useChatMessageEditContext();
  const [videoLink, setVideoLink] = useState<string | null>(null);

  const onEmojiSelect = (emoji: BaseEmoji) => {
    toggleEmojiPicker(false);

    if (message.reactions?.find((reaction) => reaction.icon === emoji.native && reaction.authorId === userId)) {
      void dispatch(
        updateChatMessage({
          id: message.id,
          channelId: message.channelId,
          authorId: message.authorId,
          reactions: message.reactions.filter((r) => !(r.icon === emoji.native && r.authorId === userId)),
        }),
      );
    } else {
      void dispatch(
        updateChatMessage({
          id: message.id,
          authorId: message.authorId,
          channelId: message.channelId,
          reactions: [
            ...(message.reactions ?? []),
            {
              iconCode: emoji.unified,
              addedAt: dayjs().unix(),
              authorId: userId,
              icon: emoji.native,
              iconName: emoji.id || emoji.name,
            },
          ],
        }),
      );
    }
  };

  const { t } = useTranslation("chat");
  const clientId = authorsById[message.authorId]?.clientId;
  const handleMessageDeleting = () => {
    void dispatch(
      updateChatMessage({
        id: message.id,
        channelId: message.channelId,
        authorId: message.authorId,
        deleted: true,
      }),
    );
  };

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "reaction",
        label: "Dodaj reakcję",
        icon: <SmileOutlined />,
        onClick: () => toggleEmojiPicker(true),
      },
      ...(incomingMessage
        ? clientId
          ? [
              {
                key: "note",
                label: "Utwórz notatkę",
                icon: <FileOutlined />,
                onClick: () =>
                  setNoteModel({
                    traineeId: clientId,
                    content:
                      message.content || message.metadata?.transcription
                        ? `Treść wiadomości: ${message.content || message.metadata?.transcription}`
                        : `Załączniki wysłane przez klienta ${dayjs(message.createdAt).format("HH:mm DD.MM.YYYY")}`,
                    attachments: message.files
                      ? message.files.map((file) => ({
                          url: file.url,
                          name: file.originalName || file.originalName || file.uid,
                          uid: file.uid,
                          contentType: file.contentType,
                          size: file.size,
                          protected: true,
                        }))
                      : [],
                  }),
              },
            ]
          : []
        : [
            {
              key: "edit",
              label: "Edytuj wiadomość",
              icon: <EditOutlined />,
              onClick: () => setMessageToEdit(message),
            },
            {
              key: "remove",
              danger: true,
              label: (
                <Popconfirm
                  title="Czy na pewno chcesz usunąć tę wiadomość?"
                  arrow={false}
                  okButtonProps={{ danger: true }}
                  description="Ta operacja jest nieodwracalna."
                  onConfirm={() => handleMessageDeleting()}
                >
                  <span>Usuń wiadomość</span>
                </Popconfirm>
              ),
              icon: <DeleteOutlined />,
            },
          ]),
    ],
    [incomingMessage, message, clientId],
  );
  const componentDecorator = (href: string, text: string, key: number) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  const renderMessage = () => {
    if (
      message.type == MessageType.WithContext &&
      message.context &&
      [MessageInContext.AddDishComment, MessageInContext.UpdateDishComment].includes(message.context.type)
    ) {
      return (
        <div
          className="relative mx-3 mb-1 flex flex-col rounded-md border border-dashed bg-blue-300/40 p-2"
          id="chat-message"
        >
          <p className="mb-1 text-xs font-medium   text-gray-500 underline">
            {t(`contextType.${message.context.type}`)}
          </p>
          {<div className="text-xs font-semibold">Dodano do posiłku {message.context.metadata.dishName}:</div>}
          <div
            className={cn(
              "wh m-1 my-2 ml-8 inline-block w-full whitespace-pre-line break-words rounded-md bg-gray-200 p-1 px-2 text-left font-medium leading-snug",
              incomingMessage && "ml-0 bg-blue-300 text-white",
              !message.content && "hidden",
            )}
          >
            <Linkify componentDecorator={componentDecorator}>{message.content}</Linkify>
          </div>
          {message.files?.length ? (
            <div className="w-full">
              <FilesList files={message.files} readonly showOnlyFirstAudio />
            </div>
          ) : null}

          {message.metadata?.transcription ? (
            <div className={"mb-2 mr-7 text-right"}>
              <Tooltip title={message.metadata?.transcription}>
                <Typography.Link>{message.metadata.summary || "Pokaż transkrypcję"}</Typography.Link>
              </Tooltip>
            </div>
          ) : null}
        </div>
      );
    }
    if (message.type == MessageType.WithContext && message.context.type === MessageInContext.AddRecordingFeedback) {
      return (
        <div
          className={cn("relative mx-3 mb-1 flex flex-col rounded-md border border-dashed bg-blue-300/40 p-2")}
          id="chat-message"
        >
          <YoutubeOutlined
            className="text-green-500"
            style={{ fontSize: 24 }}
            onClick={() => setVideoLink(String(message.context.metadata.url))}
          />
          <p className="mb-1 text-xs font-medium text-gray-600 underline">
            {t(`contextType.${message.context.type}`)}:
          </p>
          {
            <div className="text-xs font-semibold">
              {message.context.metadata.exerciseName} w dniu {message.context.metadata.exerciseDate}
            </div>
          }
          <div
            className={cn(
              "wh m-1 my-2 ml-8 inline-block whitespace-pre-line break-words rounded-md bg-gray-300/80 p-1 px-2 text-left font-medium leading-snug",
              incomingMessage && "bg-blue-300 text-white",
              !message.content && "hidden",
            )}
          >
            <Linkify componentDecorator={componentDecorator}>{message.content}</Linkify>
          </div>
          {message.files?.length ? (
            <div className="w-full">
              <FilesList files={message.files} readonly showOnlyFirstAudio />
            </div>
          ) : null}

          {message.metadata?.transcription ? (
            <div className={"mb-2 mr-7 text-right"}>
              <Tooltip title={message.metadata?.transcription}>
                <Typography.Link>{message.metadata.summary || "Pokaż transkrypcję"}</Typography.Link>
              </Tooltip>
            </div>
          ) : null}
          <VideoPlayer visible={!!videoLink} videoUrl={videoLink} onClose={() => setVideoLink(null)} />
        </div>
      );
    }
    if (message.type == MessageType.WithContext && message.context) {
      return (
        <div
          className={cn("relative mx-3 mb-1 flex flex-col rounded-md border border-dashed bg-blue-300/40 p-2")}
          id="chat-message"
        >
          <p className="mb-1 text-xs font-medium text-gray-600 underline">
            {t(`contextType.${message.context.type}`)}:
          </p>
          {
            <div className="text-xs font-semibold">
              {message.context.metadata.exerciseName} w dniu {message.context.metadata.exerciseDate}
            </div>
          }
          <div
            className={cn(
              "wh m-1 my-2 ml-8 inline-block whitespace-pre-line break-words rounded-md bg-gray-300/80 p-1 px-2 text-left font-medium leading-snug",
              incomingMessage && "bg-blue-300 text-white",
              !message.content && "hidden",
            )}
          >
            <Linkify componentDecorator={componentDecorator}>{message.content}</Linkify>
          </div>
          {message.files?.length ? (
            <div className="w-full">
              <FilesList files={message.files} readonly showOnlyFirstAudio />
            </div>
          ) : null}

          {message.metadata?.transcription ? (
            <div className={"mb-2 mr-7 text-right"}>
              <Tooltip title={message.metadata?.transcription}>
                <Typography.Link>{message.metadata.summary || "Pokaż transkrypcję"}</Typography.Link>
              </Tooltip>
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className="group relative flex w-full flex-col" id="chat-message">
        <div className={incomingMessage ? "relative min-w-[50px] self-start " : "relative min-w-[50px] self-end "}>
          <div
            className={cn(
              "invisible absolute inset-y-0 z-50 flex cursor-pointer items-center justify-center group-hover:visible",
              incomingMessage ? "right-3" : "left-3",
            )}
          >
            <Popover
              overlayInnerStyle={{ boxShadow: "none", padding: 0 }}
              trigger="focus"
              destroyTooltipOnHide
              open={openEmojiPicker}
              arrow={false}
              onOpenChange={toggleEmojiPicker}
              content={
                <div ref={innerRef}>
                  <Picker data={data} onEmojiSelect={onEmojiSelect} i18n={i18n} />
                </div>
              }
            >
              <Dropdown menu={{ items }} placement={incomingMessage ? "topLeft" : "topRight"}>
                <MoreOutlined className="rotate-90 text-gray-600" style={{ fontSize: 26 }} />
              </Dropdown>
            </Popover>
          </div>

          <div className="px-3">
            <div
              className={cn(
                "overflow-wrap my-0.5 mb-1 inline-block w-auto whitespace-pre-line break-words rounded-md bg-gray-200 p-1 px-2 text-left font-medium leading-snug [&>a]:break-all",
                isClient && "bg-white",
                incomingMessage ? "ml-0 mr-8 bg-blue-400 text-white" : "ml-8 mr-0",
                !message.content && "hidden",
              )}
            >
              <Linkify componentDecorator={componentDecorator}>{message.content}</Linkify>
            </div>
          </div>
        </div>

        {message.files?.length ? (
          <FilesList files={message.files} readonly position={incomingMessage ? "left" : "right"} showOnlyFirstAudio />
        ) : null}

        {message.metadata?.transcription ? (
          <div className={"mb-2 mr-7 text-right"}>
            <Tooltip title={message.metadata.transcription}>
              <Typography.Link>{message.metadata.summary || "Pokaż transkrypcję"}</Typography.Link>
            </Tooltip>
          </div>
        ) : null}

        <ChatMessageReactions
          position={incomingMessage ? "left" : "right"}
          incomingMessage={incomingMessage}
          reactions={message.reactions}
          messageId={message.id}
          authorsById={authorsById}
          authorId={message.authorId}
          channelId={message.channelId}
        />
      </div>
    );
  };

  return (
    <div
      className="flex flex-col overflow-x-hidden py-0.5"
      style={{ alignItems: incomingMessage ? "flex-start" : "flex-end" }}
    >
      {shouldRenderMetaTag || (!message.content && message.files?.length) ? (
        <Tooltip title={authorData?.name}>
          <div
            className={cn(
              "mx-0 mb-1 mt-1 flex cursor-pointer items-center justify-center px-3",
              !incomingMessage && "mr-2",
            )}
            style={{ flexDirection: incomingMessage ? "row" : "row-reverse" }}
          >
            <Avatar
              size={26}
              className="mx-1"
              src={authorData?.avatarUrl}
              gap={6}
              style={
                authorData?.avatarUrl
                  ? undefined
                  : {
                      backgroundColor: authorData?.role === UserRole.CLIENT ? "rgb(247, 194, 93)" : "rgb(24, 26, 101)",
                    }
              }
              icon={!authorData ? <DeleteOutlined /> : undefined}
            >
              {authorData ? getUserInitials(authorData.name) : "Deleted"}
            </Avatar>
            {!authorData || (authorData.disabled && !isClient) ? (
              <span className="text-xs text-gray-500" style={{ marginRight: 5 }}>
                {t("inactive")}
              </span>
            ) : null}
            <span className="text-xs text-gray-500">{renderDate(message.createdAt)}</span>
          </div>
        </Tooltip>
      ) : null}
      {renderMessage()}
    </div>
  );
};

export default memo(ChatMessage);
