import keyBy from "lodash.keyby";
import { createSelector } from "reselect";

import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { type Meal, type MealsPlanDetails, type NutritionPlanDay } from "@fitness-app/data-models/entities/MealsPlan";

import { type AppStore } from "../../../index";

export const getMealsPlan = (state: AppStore) => state.mealsPlans.selectedMealsPlanDetails;

const getFirstWeek = (mealsPlan: MealsPlanDetails): { weekNumber: number; days: NutritionPlanDay[] } => {
  return mealsPlan.weeks[0] || { days: [], weekNumber: 1 };
};

export const getMealsMapHelper = (mealsPlan: MealsPlanDetails[] | null) => {
  if (!mealsPlan || mealsPlan.length === 0) {
    return {
      meals: {},
      dishes: {},
      mealsByDay: [],
    };
  }

  const mealsMap = mealsPlan
    .map((mealsDay) =>
      getFirstWeek(mealsDay)
        .days.map((day) => day.meals.map((meal) => ({ ...meal, dayId: day.id })))
        .flat(),
    )
    .flat();

  const dishes = mealsPlan
    .map((mealsDay, dayIndex) =>
      getFirstWeek(mealsDay)
        .days.map((day) =>
          day.meals.map((meal, mealIndex) => ({
            ...meal,
            dayIndex,
            dayId: day.id,
            mealIndex,
          })),
        )
        .flat(),
    )
    .flat()
    .map((meal) =>
      meal.dishes.map((dish) => ({
        ...dish,
        mealIndex: meal.mealIndex,
        mealId: meal.id,
        dayId: meal.dayId,
        dayIndex: meal.dayIndex,
      })),
    )
    .flat();

  return {
    meals: keyBy(mealsMap, "id"),
    dishes: keyBy(dishes, "id"),
    mealsByDay: mealsPlan
      .map((mealsDay) =>
        getFirstWeek(mealsDay).days.map((day) => ({
          id: day.id,
          name: day.name,
          meals: day.meals.map((meal) => ({
            name: meal.name,
            type: meal.type,
            id: meal.id,
          })),
        })),
      )
      .flat(),
  };
};
export const getMealsMap = createSelector(
  [getMealsPlan],
  (
    mealsPlan,
  ): {
    mealsByDay: {
      id: string;
      name: string;
      meals: { name: string | null; type: string; id: string }[];
    }[];
    meals: Record<string, Meal & { dayId: string }>;
    dishes: Record<
      string,
      (Dish | IngredientWithPortion) & {
        mealId: string;
        dayId: string;
        mealIndex: number;
        dayIndex: number;
      }
    >;
  } => {
    return getMealsMapHelper(mealsPlan);
  },
);
