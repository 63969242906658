import { type StorageConfig, type UploadValidatorConfig } from "~/components/Upload/types";

// 40 MB
const MAX_FILE_SIZE_IN_BYTES = 40485760;

export const getUploadConfig = (
  channelId: string,
): { validatorConfig: UploadValidatorConfig; storageConfig: StorageConfig } =>
  ({
    validatorConfig: {
      maxFilesListSize: MAX_FILE_SIZE_IN_BYTES * 10,
      maxFilesListLength: 10,
      maxFileSize: MAX_FILE_SIZE_IN_BYTES,
    },
    storageConfig: {
      isPublicBucket: false,
      bucketName: "chat",
      objectPath: `${channelId}`,
    },
  }) as const;
