export const getPageRange = (
  page: number | null | undefined,
  perPage: number,
  increment = false,
): { start: number; stop: number; currentPage: number } => {
  const currentPage = (page ?? 1) + (increment ? 1 : 0);

  const start = (currentPage - 1) * perPage;
  const stop = currentPage * perPage - 1;

  return {
    start,
    stop,
    currentPage,
  };
};
