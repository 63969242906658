import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "~/modules/Auth/AuthProvider";

interface AuthRedirectProps {
  children: React.ReactNode;
}

const AuthRedirect = ({ children }: AuthRedirectProps): React.ReactElement => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;
  const userId = auth.user?.id;

  useEffect(() => {
    if (userId && path.startsWith("/auth")) {
      navigate("/", { replace: true });
    }
  }, [path, userId]);

  return <>{children}</>;
};

export default AuthRedirect;
