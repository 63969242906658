import { useCallback, useState } from "react";

export function useFormHandler<T extends object, SavedEntity = object>() {
  const [showModalForm, toggleModalForm] = useState(false);
  const [formModel, setFormModel] = useState<T | null>(null);
  const [editedEntity, setEditedEntity] = useState<SavedEntity | null>(null);

  const openFormModal = useCallback((model?: T, editedEntity?: SavedEntity) => {
    if (model) {
      setFormModel(model);
    }
    if (editedEntity) {
      setEditedEntity(editedEntity);
    }
    toggleModalForm(true);
  }, []);
  const hideFormModal = useCallback(() => {
    toggleModalForm(false);
    clearModel();
  }, []);

  const clearModel = () => {
    setEditedEntity(null);
    setFormModel(null);
  };

  return {
    showModalForm,
    toggleModalForm,
    formModel,
    setFormModel,
    openFormModal,
    hideFormModal,
    editedEntity,
    setEditedEntity,
    clearModel,
  };
}
