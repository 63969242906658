import { type MediaLibraryItemWithLinkedExercise } from "@fitness-app/data-models/entities/MediaLibrary";

import { type RequestStatus } from "../../../enums/requestStatus";

export enum FileStatus {
  REMOVING = "removing",
}

export const MEDIA_LIBRARY_REDUCER_NAME = "mediaLibrary";

export interface MediaLibraryReducer {
  list: MediaLibraryItemWithLinkedExercise[];
  listStatus: null | RequestStatus;
  processingFileId: null | string;
  fileStatus: null | FileStatus;
}
