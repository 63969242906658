import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import keyBy from "lodash.keyby";
import { createSelector } from "reselect";

import { type ClientNutritionDayWithSource } from "@fitness-app/data-models/entities/ClientNutritionDay";
import { type NutritionDaySummary } from "@fitness-app/data-models/entities/ClientNutritionWeek";
import { type WeekDay } from "@fitness-app/data-models/entities/TrainingProgram";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";
import { getMealsPlanTargets } from "@fitness-app/utils/src/nutrition/getMealsPlanTargets";
import { reduceMealsNutrients } from "@fitness-app/utils/src/nutrition/reduceMealsNutrients";

import { type AppStore } from "../../../index";

export const weekdays: WeekDay[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

dayjs.extend(isoWeek);
dayjs.extend(utc);
export const getCurrentMealsPlanDetails = (store: AppStore) => store.traineeMealsPlan.selectedMealsPlanDetails;
export const getCurrentMealsPlan = (store: AppStore) => store.traineeMealsPlan.selectedMealsPlan;
export const getSelectedDay = (_store: AppStore, props: { day: string; userId: string }) => props.day;
export const getUserId = (_store: AppStore, props: { day: string; userId: string }) => props.userId;

export const getNutritionTrackedWeek = (store: AppStore) => store.traineeNutritionTracker.fetchedWeekDays;
export const getNutritionWeekFulfillment = createSelector(
  [getCurrentMealsPlanDetails, getSelectedDay, getNutritionTrackedWeek, getUserId, getCurrentMealsPlan],
  (clientNutrition, day, trackedDays, userId, mealsPlan) => {
    const selectedNutrition = clientNutrition?.[0];

    if (!selectedNutrition) {
      return null;
    }

    const selectedDate = dayjs.utc(day);

    const firstDay = selectedDate.utc().startOf("isoWeek");
    const week = Array.from({ length: 7 }, (_, i) => firstDay.add(i, "day"));

    const daysToAnalyze: (ClientNutritionDayWithSource | { date: string; isEmptyDayInPlan: boolean })[] = [];

    week.forEach((day) => {
      const selectedDayIndex = day.locale("pl").isoWeekday();

      const foundDay = selectedNutrition.weeks[0]?.days.find((day) => day.name === weekdays[selectedDayIndex]);
      const isEmptyDayInPlan = !foundDay?.meals.map((meal) => meal.dishes).flat().length;

      const trackedDay = trackedDays.find((item) => item.eventDate === day.format("YYYY-MM-DD"));

      if (trackedDay) {
        daysToAnalyze.push({
          ...trackedDay,
          source: "tracked",
          isEmptyDayInPlan,
        });
        return;
      }

      foundDay
        ? daysToAnalyze.push({
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            id: generateUniqId(),
            eventDate: day.format("YYYY-MM-DD"),
            eventWeek: `${day.format("YYYY")}-${day.isoWeek()}`,
            day: foundDay,
            traineeId: selectedNutrition.traineeId,
            clientMealsPlanId: selectedNutrition.planId,
            source: "mealsPlan",
            userId: userId || null,
            isEmptyDayInPlan,
          })
        : daysToAnalyze.push({ date: day.format("YYYY-MM-DD"), isEmptyDayInPlan } as {
            date: string;
            isEmptyDayInPlan: boolean;
          });
    });

    const mealsPlanTargets = getMealsPlanTargets(mealsPlan);

    const summary: NutritionDaySummary[] = daysToAnalyze.map((dayToAnalyze) => {
      if ("date" in dayToAnalyze) {
        return {
          calories: 0,
          macros: {
            carbs: 0,
            fat: 0,
            protein: 0,
          },
          targets: mealsPlanTargets,
          eventDate: dayToAnalyze.date,
          nutritionTargets: mealsPlanTargets,
          isEmptyDayInPlan: dayToAnalyze.isEmptyDayInPlan,
        };
      }

      const reduced = reduceMealsNutrients(dayToAnalyze.day.meals, true);
      const reducedTargets = reduceMealsNutrients(dayToAnalyze.day.meals);

      const day: NutritionDaySummary = {
        calories: reduced.calories,
        macros: {
          carbs: reduced.carbs,
          fat: reduced.fat,
          protein: reduced.protein,
        },
        targets: {
          calories: reducedTargets.calories,
          macros: {
            carbs: reducedTargets.carbs,
            fat: reducedTargets.fat,
            protein: reducedTargets.protein,
          },
        },
        nutritionTargets: mealsPlanTargets,
        eventDate: dayToAnalyze.eventDate,
        isEmptyDayInPlan: dayToAnalyze.isEmptyDayInPlan ?? false,
      };

      return day;
    });

    return keyBy(summary, "eventDate");
  },
);
