import dayjs, { type Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import compact from "lodash.compact";
import groupBy from "lodash.groupby";
import isEmpty from "lodash.isempty";
import keyBy from "lodash.keyby";
import last from "lodash.last";
import mapValues from "lodash.mapvalues";
import range from "lodash.range";
import sortBy from "lodash.sortby";
import { v4 as uuid } from "uuid";

import { type CalendarEventType, type TrainerEvent } from "../../entities/CalendarEvent";
import { type NutritionDaySummary } from "../../entities/ClientNutritionWeek";
import { WorkoutSource } from "../../entities/ClientWorkout";
import { type ExerciseRecordingWithExerciseInfo } from "../../entities/ExerciseRecording";
import { type Measurement } from "../../entities/Measurement";
import { NotificationType, type ClientNotifications } from "../../entities/Notification";
import { type WorkoutActivityEvent } from "../../entities/ProgramActivity";
import { type ProgramAutomationTask, type WorkoutRoutine } from "../../entities/ProgramAutomation";
import {
  ProgramWorkoutType,
  type ClientArchivedProgram,
  type ClientProgramSchedule,
  type ClientTrainingProgram,
  type ClientTrainingProgramDetails,
  type ExceptionWeek,
  type MappedSchedule,
  type MappedWeekSchedule,
  type ProgramSchedule,
  type ProgramWeek,
  type ProgramWorkout,
  type TrainingStatus,
  type WeekDay,
} from "../../entities/TrainingProgram";
import { WorkoutService } from "./WorkoutService";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type PrepareClientScheduleUpdateParams = {
  currentProgram: ClientTrainingProgram;
  exceptionWeeks?: Record<string, ExceptionWeek>;
  updatedSchedule?: ProgramSchedule;
  changeFromWeek?: string | null;
};

export const weekdays: WeekDay[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export enum ActionType {
  ClientTerminated = "CLIENT_TERMINATED",
  ClientAddPhoto = "CLIENT_ADD_PHOTO",
  ClientAddVideo = "CLIENT_ADD_VIDEO",
  ClientSentSurveyAnswers = "CLIENT_SENT_SURVEY_ANSWERS",
  ClientUpdateSurveyAnswers = "CLIENT_UPDATE_SURVEY_ANSWERS",
  ClientAcceptedInvitation = "CLIENT_ACCEPTED_INVITATION",
  ClientRejectedInvitation = "CLIENT_REJECTED_INVITATION",
  ClientProgramEndingIn1Day = "CLIENT_PROGRAM_ENDING_IN_1_DAY",
  ClientProgramEndingIn1Week = "CLIENT_PROGRAM_ENDING_IN_1_WEEK",
  StartOfCurrenPlan = "START_OF_CURRENT_PLAN",
}

interface ICreateProgramScheduleArgs {
  currentProgram: ClientTrainingProgram;
  exceptionWeeks?: Record<number | string, ExceptionWeek | null>;
  updatedSchedule?: ProgramSchedule;
  changeFromWeek?: string;
}

export const EVENT_TYPE = {
  WORKOUT: "workout",
  AUTOMATION_TASK: "automationTask",
  MEASUREMENT: "measurement",
  CLIENT_NOTIFICATIONS: "productNotifications",
  MEAL_TRACKED: "mealTracked",
} as const;

export const ACTIVITY_EVENTS = {
  WORKOUT: "workout",
} as const;

const findCurrentWeekScheduleIndex = (program: ProgramSchedule, startDate: string, currentDay: Dayjs) => {
  let currentWeekIndex = 0;
  if (program.length > 1) {
    const weekAIndex = dayjs(startDate).isoWeek() % 2;
    const weekBIndex = dayjs(startDate).add(1, "weeks").isoWeek() % 2;
    const weekMap = {
      0: weekAIndex,
      1: weekBIndex,
    };
    currentWeekIndex = weekMap[(currentDay.isoWeek() % 2) as 0 | 1];
  }
  return currentWeekIndex;
};

const getNextAllowedWeekForUpdate = (range: { week: number; year: number }[]) => {
  if (!range.length) {
    return null;
  }
  const lastItemInWeekRange = last(range);
  if (!lastItemInWeekRange) {
    return null;
  }
  if (lastItemInWeekRange?.week === 53) {
    return `${lastItemInWeekRange.year + 1}-${1}`;
  }
  return `${lastItemInWeekRange.year}-${lastItemInWeekRange.week + 1}`;
};

export interface GenericCalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  data: object;
  type: (typeof EVENT_TYPE)[keyof typeof EVENT_TYPE] | ActionType | CalendarEventType;
}

export interface WorkoutEventType extends GenericCalendarEvent {
  data: ProgramWorkout;
  metadata: {
    status: TrainingStatus | null;
    source: WorkoutSource;
    id: string;
    requiredRecordings?: ExerciseRecordingWithExerciseInfo[];
  };
  type: "workout";
}

export interface StartOfCurrentPlanEventType extends GenericCalendarEvent {
  type: ActionType.StartOfCurrenPlan;
  data: ClientTrainingProgram;
}

export interface AutomationTaskEventType extends GenericCalendarEvent {
  data: ProgramAutomationTask;
  type: typeof EVENT_TYPE.AUTOMATION_TASK;
}

export interface MeasurementEventType extends GenericCalendarEvent {
  data: Measurement;
  type: "measurement";
}

export interface ProductEventType extends GenericCalendarEvent {
  data: ClientNotifications;
  type: typeof EVENT_TYPE.CLIENT_NOTIFICATIONS;
}

export interface CalendarTrainerEventType extends GenericCalendarEvent {
  data: TrainerEvent;
  type: CalendarEventType;
}

export interface NutritionEventType extends GenericCalendarEvent {
  data: NutritionDaySummary;
  type: typeof EVENT_TYPE.MEAL_TRACKED;
}

export interface Training extends Partial<MappedSchedule> {
  date: string;
  dayName: string;
  isWorkoutDay: boolean;
  dateObj: Dayjs;
  isRestDay?: boolean;
}

export interface CurrentTrainingWeekSchedule {
  dates: Training[];
  currentProgramWeek: number | null;
  numberOfWeeksInProgram: number;
}

export type CalendarEvent =
  | WorkoutEventType
  | StartOfCurrentPlanEventType
  | MeasurementEventType
  | AutomationTaskEventType
  | ProductEventType
  | CalendarTrainerEventType
  | NutritionEventType;

const MIGRATION_DATE = dayjs("2024-01-15");

export class ProgramService {
  static getRecordingsForWorkout(
    workout: ProgramWorkout,
    exerciseRecordings: Record<string, ExerciseRecordingWithExerciseInfo>,
    eventDate: string,
  ) {
    return workout.exercises
      .map((exercise) => exerciseRecordings[exercise.exercise.id])
      .filter((item): item is ExerciseRecordingWithExerciseInfo => item?.scheduledAt === eventDate);
  }

  static weekday: Record<string, number> = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };
  static getScheduleForProgramAutomationWeek(routine: WorkoutRoutine | null, weekNumber: number) {
    if (!routine?.schedule) {
      return {
        week: {} as ProgramWeek,
        isException: false,
      };
    }

    const { exceptionWeeks, weekSchedule } = routine.schedule;

    if (exceptionWeeks?.[weekNumber]) {
      return {
        week: exceptionWeeks[weekNumber],
        isException: true,
      };
    }

    const weekIndex = weekNumber % 2 ? 0 : 1;

    return {
      week: weekSchedule.length === 1 ? weekSchedule[0] : weekSchedule[weekIndex ?? 0],
      isException: false,
      weekIndex,
    };
  }
  static getScheduleForDateRange(
    range: { start: Date; end: Date },
    programs: {
      program: ClientTrainingProgram | ClientArchivedProgram;
      details: ClientTrainingProgramDetails | null;
    }[],
  ) {
    const sortedPrograms = sortBy(programs, (programData) => programData.program.startDate).map((programData) =>
      "archivedDate" in programData.program
        ? {
            ...programData,
            program: {
              ...programData.program,
              startAt: dayjs(programData.program.startDate, "YYYY-MM-DD").startOf("day"),
              endAt: dayjs(programData.program.endDate).endOf("day"),
            },
          }
        : {
            ...programData,
            program: {
              ...programData.program,
              startAt: dayjs(programData.program.startDate, "YYYY-MM-DD").startOf("day"),
              endAt: dayjs(programData.program.endDate, "YYYY-MM-DD").endOf("day"),
            },
          },
    );

    const schedules: {
      date: dayjs.Dayjs;
      dateStr: string;
      workoutsSchedule?: ClientProgramSchedule | null;
      details: ClientTrainingProgramDetails | null;
    }[] = [];

    let counter = 0;
    const start = dayjs(range.start).startOf("day");
    const diff = Math.ceil(dayjs(range.end).endOf("day").diff(start, "day", true));

    do {
      const day = start.add(counter, "day");
      const programForRange = sortedPrograms.find(
        (programData) =>
          programData.program.workoutsSchedule &&
          programData.program.startAt.isSameOrBefore(day, "day") &&
          programData.program.endAt.isAfter(day),
      );
      schedules.push({
        dateStr: day.format("YYYY-MM-DD"),
        date: day,
        workoutsSchedule: programForRange?.program.workoutsSchedule || null,
        details: programForRange?.details || null,
      });
      counter++;
    } while (counter !== diff);

    return keyBy(schedules, "dateStr");
  }

  static getScheduleForWeekDays(
    schedulesByDate: Record<
      string,
      { date: dayjs.Dayjs; workoutsSchedule?: ClientProgramSchedule | null; dateStr: string }
    >,
    date: dayjs.Dayjs,
  ): ProgramWeek {
    if (!schedulesByDate) {
      return {
        monday: { workoutDay: null },
        tuesday: { workoutDay: null },
        wednesday: { workoutDay: null },
        thursday: { workoutDay: null },
        friday: { workoutDay: null },
        saturday: { workoutDay: null },
        sunday: { workoutDay: null },
      };
    }
    const monday = date.startOf("isoWeek").startOf("day");
    const diff = Math.ceil(date.endOf("isoWeek").endOf("day").diff(monday, "day", true));
    let counter = 0;

    const programWeek: ProgramWeek = {
      monday: { workoutDay: null },
      tuesday: { workoutDay: null },
      wednesday: { workoutDay: null },
      thursday: { workoutDay: null },
      friday: { workoutDay: null },
      saturday: { workoutDay: null },
      sunday: { workoutDay: null },
    };

    do {
      const day = monday.add(counter, "day");
      const dayStr = day.format("YYYY-MM-DD");
      const weekStr = `${date.get("year")}-${date.isoWeek()}`;

      const { exceptionWeeks, oddWeekIndex, evenWeekIndex, schedule } = schedulesByDate[dayStr]?.workoutsSchedule || {};

      if (exceptionWeeks?.[weekStr]) {
        // @ts-expect-error ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        programWeek[weekdays[day.day()]] = exceptionWeeks[weekStr]?.[weekdays[day.day()]] || {
          workoutDay: null,
        };
      }

      const weekIndex = date.isoWeek() % 2 ? oddWeekIndex : evenWeekIndex;
      const scheduleForWeek = schedule ? schedule[weekIndex ?? 0] : null;

      if (!scheduleForWeek) {
        counter++;
        continue;
      }
      // @ts-expect-error ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      programWeek[weekdays[day.day()]] = scheduleForWeek[weekdays[day.day()]] || {
        workoutDay: null,
      };

      counter++;
    } while (counter !== diff);

    return programWeek;
  }

  static getScheduleForWeek(currentProgram: ClientTrainingProgram | null, date: dayjs.Dayjs) {
    if (!currentProgram?.workoutsSchedule) {
      return {
        week: {} as ProgramWeek,
        isException: false,
      };
    }
    const weekStr = `${date.get("year")}-${date.isoWeek()}`;
    const { exceptionWeeks, oddWeekIndex, evenWeekIndex, schedule } = currentProgram.workoutsSchedule;

    if (exceptionWeeks?.[weekStr]) {
      return {
        week: exceptionWeeks[weekStr],
        isException: true,
      };
    }

    const weekIndex = date.isoWeek() % 2 ? oddWeekIndex : evenWeekIndex;

    return {
      week: schedule[weekIndex ?? 0],
      isException: false,
      weekIndex,
    };
  }

  static prepareClientScheduleUpdate({
    currentProgram,
    changeFromWeek,
    exceptionWeeks,
    updatedSchedule,
  }: PrepareClientScheduleUpdateParams) {
    const startDate = dayjs(currentProgram.startDate, "YYYY-MM-DD").startOf("day");
    if (!updatedSchedule && !exceptionWeeks) {
      throw new Error("missing-data");
    }
    if (!isEmpty(exceptionWeeks) && !updatedSchedule) {
      const emptySchedule = {
        oddWeekIndex: 0,
        evenWeekIndex: 0,
        allowChangeScheduleFromWeek: null,
        schedule: [],
      };
      return {
        ...(currentProgram.workoutsSchedule || emptySchedule),
        exceptionWeeks: {
          ...(currentProgram?.workoutsSchedule?.exceptionWeeks ?? {}),
          ...exceptionWeeks,
        },
        lastScheduleUpdate: dayjs().toISOString(),
      };
    }
    if (!isEmpty(exceptionWeeks)) {
      const emptySchedule = {
        oddWeekIndex: 0,
        evenWeekIndex: 0,
        allowChangeScheduleFromWeek: null,
        schedule: updatedSchedule,
      };
      return {
        ...(currentProgram.workoutsSchedule || emptySchedule),
        exceptionWeeks: {
          ...(currentProgram?.workoutsSchedule?.exceptionWeeks ?? {}),
          ...exceptionWeeks,
        },
        lastScheduleUpdate: dayjs().toISOString(),
      };
    }

    const firstWeekStartNumber = startDate.isoWeek();
    const hasDifferentSchemaForWeeks = !isEmpty(updatedSchedule?.[1]);

    const oddWeekIndex = firstWeekStartNumber % 2 ? 0 : hasDifferentSchemaForWeeks ? 1 : 0;

    const evenWeekIndex = !(firstWeekStartNumber % 2) ? 0 : hasDifferentSchemaForWeeks ? 1 : 0;

    if (
      currentProgram.workoutsSchedule?.allowChangeScheduleFromWeek &&
      changeFromWeek &&
      changeFromWeek < currentProgram.workoutsSchedule.allowChangeScheduleFromWeek
    ) {
      throw new Error("Cannot change schedule from selected week");
    }

    const today = dayjs().endOf("day");

    const firstWeekOfPlan = startDate.isoWeek();
    const lastWeekWithOldSchedule = changeFromWeek ? Number(changeFromWeek.split("-")[1]) : today.isoWeek();

    if (!currentProgram.workoutsSchedule || firstWeekOfPlan === lastWeekWithOldSchedule) {
      return {
        schedule: updatedSchedule,
        oddWeekIndex,
        evenWeekIndex,
        exceptionWeeks: exceptionWeeks || {},
        lastScheduleUpdate: dayjs().toISOString(),
        allowChangeScheduleFromWeek: null,
      };
    }

    const range: { week: number; year: number; weekStr: string }[] = [];
    const endWeek = lastWeekWithOldSchedule < firstWeekOfPlan ? lastWeekWithOldSchedule + 53 : lastWeekWithOldSchedule;

    for (let i = firstWeekOfPlan; i <= endWeek - 1; i++) {
      if (i > 53) {
        range.push({
          week: i - 53,
          year: startDate.get("year") + 1,
          weekStr: `${startDate.get("year") + 1}-${i - 53}`,
        });
      } else {
        range.push({
          week: i,
          year: startDate.get("year"),
          weekStr: `${startDate.get("year")}-${i}`,
        });
      }
    }

    const updated: Record<string, ExceptionWeek> = {};
    range.forEach((item) => {
      // @ts-expect-error ignore
      updated[item.weekStr] =
        item.week % 2
          ? currentProgram.workoutsSchedule?.schedule[currentProgram.workoutsSchedule.oddWeekIndex]
          : currentProgram.workoutsSchedule?.schedule[currentProgram.workoutsSchedule.evenWeekIndex];
    });

    return {
      schedule: updatedSchedule,
      oddWeekIndex,
      evenWeekIndex,
      exceptionWeeks: Object.keys(updated) ? { ...(exceptionWeeks || {}), ...updated } : exceptionWeeks ?? {},
      lastScheduleUpdate: dayjs().toISOString(),
      allowChangeScheduleFromWeek: getNextAllowedWeekForUpdate(range),
    };
  }

  static createProgramSchedule({
    currentProgram,
    exceptionWeeks = {},
    updatedSchedule,
    changeFromWeek,
  }: ICreateProgramScheduleArgs): ClientProgramSchedule {
    const startDate = dayjs(currentProgram.startDate, "YYYY-MM-DD").startOf("day");

    if (!updatedSchedule && !exceptionWeeks) {
      throw new Error("missing-data");
    }
    if (!isEmpty(exceptionWeeks) && !updatedSchedule) {
      const emptySchedule = {
        oddWeekIndex: 0 as 0 | 1,
        evenWeekIndex: 0 as 0 | 1,
        allowChangeScheduleFromWeek: null,
        schedule: [] as unknown as ProgramSchedule,
      };
      return {
        ...(currentProgram.workoutsSchedule || emptySchedule),
        exceptionWeeks: {
          ...(currentProgram?.workoutsSchedule?.exceptionWeeks ?? {}),
          ...exceptionWeeks,
        },
        lastScheduleUpdate: dayjs().toISOString(),
      };
    }

    if (!isEmpty(exceptionWeeks)) {
      const emptySchedule = {
        oddWeekIndex: 0,
        evenWeekIndex: 0,
        allowChangeScheduleFromWeek: null,
        schedule: updatedSchedule,
      } as ClientProgramSchedule;
      return {
        ...(currentProgram.workoutsSchedule || emptySchedule),
        exceptionWeeks: {
          ...(currentProgram?.workoutsSchedule?.exceptionWeeks ?? {}),
          ...exceptionWeeks,
        },
        lastScheduleUpdate: dayjs().toISOString(),
      };
    }

    if (!updatedSchedule) {
      throw new Error("Cannot update schedule without new one");
    }

    const firstWeekStartNumber = startDate.isoWeek();
    const hasDifferentSchemaForWeeks = !isEmpty(updatedSchedule?.[1]);
    const oddWeekIndex = firstWeekStartNumber % 2 ? 0 : hasDifferentSchemaForWeeks ? 1 : 0;
    const evenWeekIndex = !(firstWeekStartNumber % 2) ? 0 : hasDifferentSchemaForWeeks ? 1 : 0;

    if (
      currentProgram.workoutsSchedule?.allowChangeScheduleFromWeek &&
      changeFromWeek &&
      changeFromWeek < currentProgram.workoutsSchedule.allowChangeScheduleFromWeek
    ) {
      throw new Error("Cannot change schedule from selected week");
    }

    const today = dayjs().endOf("day");

    const firstWeekOfPlan = startDate.isoWeek();
    const lastWeekWithOldSchedule = changeFromWeek ? Number(changeFromWeek.split("-")[1]) : today.isoWeek();

    if (!currentProgram.workoutsSchedule || firstWeekOfPlan === lastWeekWithOldSchedule) {
      return {
        schedule: updatedSchedule,
        oddWeekIndex,
        evenWeekIndex,
        exceptionWeeks: exceptionWeeks || {},
        lastScheduleUpdate: dayjs().toISOString(),
        allowChangeScheduleFromWeek: null,
      };
    }

    const range: { week: number; year: number; weekStr: string }[] = [];
    const endWeek = lastWeekWithOldSchedule < firstWeekOfPlan ? lastWeekWithOldSchedule + 53 : lastWeekWithOldSchedule;
    for (let i = firstWeekOfPlan; i <= endWeek - 1; i++) {
      if (i > 53) {
        range.push({
          week: i - 53,
          year: startDate.get("year") + 1,
          weekStr: `${startDate.get("year") + 1}-${i - 53}`,
        });
      } else {
        range.push({
          week: i,
          year: startDate.get("year"),
          weekStr: `${startDate.get("year")}-${i}`,
        });
      }
    }

    const updated = {} as Record<string, ExceptionWeek | null>;
    range.forEach((item) => {
      updated[item.weekStr] =
        item.week % 2
          ? currentProgram.workoutsSchedule?.schedule[currentProgram.workoutsSchedule.oddWeekIndex] || null
          : currentProgram.workoutsSchedule?.schedule[currentProgram.workoutsSchedule.evenWeekIndex] || null;
    });

    const getNextAllowedWeekForUpdate = () => {
      if (!range.length) {
        return null;
      }
      const lastItemInWeekRange = last(range);

      if (!lastItemInWeekRange) {
        return null;
      }

      if (lastItemInWeekRange.week === 53) {
        return `${lastItemInWeekRange.year + 1}-${1}`;
      } else {
        return `${lastItemInWeekRange?.year}-${lastItemInWeekRange.week + 1}`;
      }
    };

    return {
      schedule: updatedSchedule,
      oddWeekIndex,
      evenWeekIndex,
      exceptionWeeks: Object.keys(updated) ? { ...(exceptionWeeks || {}), ...updated } : exceptionWeeks ?? {},
      lastScheduleUpdate: dayjs().toISOString(),
      allowChangeScheduleFromWeek: getNextAllowedWeekForUpdate(),
    };
  }

  static getWorkoutsForDateRange = (
    dateRanges: [Dayjs, Dayjs],
    clientProgram: ClientTrainingProgram | null,
    {
      workouts,
      clientNotifications,
      calendarEvents,
      clientActivities,
      archivedWorkouts,
      workoutsOnlyForCurrentPlan = false,
      nutritionStatistics,
      endDateStrict = false,
      clientTasks = [],
      measurements = [],
      isArchiving = false,
      exerciseRecordings = {},
    }: {
      workouts: ProgramWorkout[];
      archivedWorkouts?: ProgramWorkout[];
      workoutsOnlyForCurrentPlan?: boolean;
      endDateStrict?: boolean;
      clientActivities: WorkoutActivityEvent[];
      clientTasks?: ProgramAutomationTask[];
      nutritionStatistics?: NutritionDaySummary[];
      clientNotifications?: ClientNotifications[];
      calendarEvents?: TrainerEvent[];
      measurements?: Measurement[];
      isArchiving?: boolean;
      exerciseRecordings?: Record<string, ExerciseRecordingWithExerciseInfo>;
    },
  ): CalendarEvent[] => {
    const workoutsById = workouts ? keyBy(workouts, "id") : {};
    const archivedWorkoutsById = archivedWorkouts ? keyBy(archivedWorkouts, "id") : {};
    const clientActivitiesGroupedByDate = clientActivities ? groupBy(clientActivities, "eventDate") : {};
    const startDate = dayjs(dateRanges[0]).startOf("day").subtract(1, "week");
    const endDate = endDateStrict
      ? dateRanges[1]
      : dayjs(dateRanges[1] || dateRanges[0])
          .endOf("day")
          .add(1, "week");
    const events: CalendarEvent[] = [];

    const dayjsTimeRange: Dayjs[] = [];

    if (clientTasks.length) {
      clientTasks.forEach((task) => {
        events.push({
          id: uuid(),
          type: EVENT_TYPE.AUTOMATION_TASK,
          data: task,
          start: dayjs(task.sendDate).startOf("day").toDate(),
          allDay: false,
          end: dayjs(task.sendDate).endOf("day").toDate(),
          title: dayjs(task.sendDate).format("HH:mm"),
        });
      });
    }

    do {
      const newDate: Dayjs | undefined =
        dayjsTimeRange.length === 0 ? startDate : dayjsTimeRange[dayjsTimeRange.length - 1]?.add(1, "day");
      if (newDate) {
        dayjsTimeRange.push(newDate);
      }
    } while (!dayjsTimeRange[dayjsTimeRange.length - 1]?.isSameOrAfter(endDate));

    const clientActivitiesGroupedByDateOutsideCurrentPlan = groupBy(
      clientActivities?.filter((activity) => activity.programId !== clientProgram?.id),
      "eventDate",
    );
    if (clientProgram?.startDate) {
      events.push({
        id: uuid(),
        title: "Początek obecnego programu",
        allDay: true,
        start: dayjs(clientProgram.startDate).startOf("day").toDate(),
        end: dayjs(clientProgram.startDate).endOf("day").toDate(),
        data: clientProgram,
        type: ActionType.StartOfCurrenPlan,
      });
    }

    for (const timeRangeItem of dayjsTimeRange) {
      const isoWeekOfTimeRangeItem = dayjs(timeRangeItem).isoWeek();
      const dateYear = dayjs(timeRangeItem).get("year");
      const dateString = dayjs(timeRangeItem).format("YYYY-MM-DD");
      const isoWeekString = `${dateYear}-${isoWeekOfTimeRangeItem}`;

      if (clientNotifications && !isEmpty(clientNotifications)) {
        clientNotifications.forEach((event) => {
          const shouldShow = [NotificationType.ClientPurchaseProduct].includes(event.type);
          // TODO: fix notification
          // (event.type === NotificationType.CLIENT_ACTION &&
          //   event.data?.action === ActionType.ClientAcceptedInvitation);
          if (event.eventDate === dateString && shouldShow) {
            events.push({
              id: event.id,
              title: event.type,
              allDay: true,
              start: timeRangeItem.startOf("day").toDate(),
              end: timeRangeItem.endOf("day").toDate(),
              data: event,
              type: EVENT_TYPE.CLIENT_NOTIFICATIONS,
            });
          }
        });
      }

      if (nutritionStatistics && !isEmpty(nutritionStatistics)) {
        nutritionStatistics.forEach((event) => {
          if (event.eventDate === dateString) {
            events.push({
              title: "Posiłki",
              id: uuid(),
              allDay: true,
              start: timeRangeItem.startOf("day").toDate(),
              end: timeRangeItem.endOf("day").toDate(),
              data: event,
              type: EVENT_TYPE.MEAL_TRACKED,
            });
          }
        });
      }

      if (measurements?.length) {
        measurements.forEach((measurement) => {
          if (dayjs(measurement.eventTimestamp).format("YYYY-MM-DD") === dateString) {
            events.push({
              id: measurement.id,
              title: "Pomiary",
              allDay: true,
              start: timeRangeItem.startOf("day").toDate(),
              end: timeRangeItem.endOf("day").toDate(),
              data: measurement,
              type: "measurement",
            });
          }
        });
      }

      if (calendarEvents && !isEmpty(calendarEvents)) {
        calendarEvents.forEach((event) => {
          if (dayjs(event.start).format("YYYY-MM-DD") === dateString) {
            events.push({
              id: event.id,
              title: event.eventType,
              allDay: true,
              start: timeRangeItem.startOf("day").toDate(),
              end: timeRangeItem.endOf("day").toDate(),
              data: event,
              type: event.eventType,
            });
          }
        });
      }

      if (!workoutsOnlyForCurrentPlan) {
        const trainingsForDate = clientActivitiesGroupedByDateOutsideCurrentPlan?.[dateString];
        if (trainingsForDate?.length) {
          trainingsForDate.forEach((trainingForDate) => {
            events.unshift({
              id: trainingForDate.id,
              title: trainingForDate.data.name,
              allDay: true,
              start: timeRangeItem.startOf("day").toDate(),
              end: timeRangeItem.endOf("day").toDate(),
              data: trainingForDate.data,
              metadata: {
                requiredRecordings: ProgramService.getRecordingsForWorkout(
                  trainingForDate.data,
                  exerciseRecordings,
                  timeRangeItem.format("YYYY-MM-DD"),
                ),
                status: WorkoutService.getWorkoutStatus(trainingForDate.data, timeRangeItem).status,
                source: WorkoutSource.FromActivity,
                id: trainingForDate.id,
              },
              type: "workout",
            });
          });
        }
      }

      if (clientProgram?.workoutsSchedule) {
        const mProgramStartDate = dayjs(clientProgram.startDate, "YYYY-MM-DD").startOf("day");
        let mProgramEndDate = dayjs(clientProgram.endDate, "YYYY-MM-DD").endOf("day");
        if (isArchiving) {
          if (mProgramEndDate.isSame(dateRanges[1])) {
            mProgramEndDate = dateRanges[1].subtract(1, "day");
          } else if (mProgramEndDate.isAfter(dateRanges[1])) {
            mProgramEndDate = dateRanges[1];
          }
        }
        if (timeRangeItem.isSameOrAfter(mProgramStartDate) && timeRangeItem.isSameOrBefore(mProgramEndDate)) {
          if (clientActivitiesGroupedByDate[dateString]) {
            let shouldSkipFromTemplateWorkouts = false;
            (clientActivitiesGroupedByDate[dateString] || [])
              .filter((event) => event.type === ACTIVITY_EVENTS.WORKOUT)
              .forEach(({ data: workout, id, programId, eventTimestamp }) => {
                if (
                  // TODO: verify if this is correct
                  workout.replicatedFromSchedule ||
                  (workout.replicatedFromSchedule && programId === clientProgram.id) ||
                  (workout.replicatedFromSchedule &&
                    programId === null &&
                    dayjs(eventTimestamp).isBefore(MIGRATION_DATE))
                ) {
                  shouldSkipFromTemplateWorkouts = true;
                }
                if (programId === clientProgram.id) {
                  events.unshift({
                    id: workout.id,
                    title: workout.name,
                    allDay: true,
                    start: timeRangeItem.startOf("day").toDate(),
                    end: timeRangeItem.endOf("day").toDate(),
                    metadata: {
                      status: WorkoutService.getWorkoutStatus(workout, timeRangeItem).status,
                      source: WorkoutSource.FromActivity,
                      id,
                      requiredRecordings: ProgramService.getRecordingsForWorkout(
                        workout,
                        exerciseRecordings,
                        timeRangeItem.format("YYYY-MM-DD"),
                      ),
                    },
                    data: workout,
                    type: "workout",
                  });
                }
              });
            if (shouldSkipFromTemplateWorkouts) {
              continue;
            }
          }
          // get from exceptions
          const exceptionWeekSchedule = clientProgram.workoutsSchedule?.exceptionWeeks?.[isoWeekString] || null;
          const dayOfWeek = weekdays[timeRangeItem.day()];
          if (exceptionWeekSchedule && dayOfWeek) {
            const workoutIdForDay = exceptionWeekSchedule[dayOfWeek]?.workoutDay;
            const regularWorkout = workoutIdForDay && workoutsById[workoutIdForDay];
            if (workoutIdForDay && regularWorkout) {
              events.unshift({
                id: "",
                title: regularWorkout.name,
                allDay: true,
                start: timeRangeItem.startOf("day").toDate(),
                end: timeRangeItem.endOf("day").toDate(),
                data: regularWorkout,
                metadata: {
                  status: WorkoutService.getWorkoutStatus(regularWorkout, timeRangeItem, true).status,
                  source: WorkoutSource.FromException,
                  id: workoutIdForDay,
                  requiredRecordings: ProgramService.getRecordingsForWorkout(
                    regularWorkout,
                    exerciseRecordings,
                    timeRangeItem.format("YYYY-MM-DD"),
                  ),
                },
                type: EVENT_TYPE.WORKOUT,
              });
            }
            const archivedWorkout = workoutIdForDay && archivedWorkoutsById?.[workoutIdForDay];
            if (workoutIdForDay && archivedWorkout) {
              events.unshift({
                id: "",
                title: archivedWorkout.name,
                allDay: true,
                start: timeRangeItem.startOf("day").toDate(),
                end: timeRangeItem.endOf("day").toDate(),
                data: archivedWorkout,
                metadata: {
                  status: WorkoutService.getWorkoutStatus(archivedWorkout, timeRangeItem, true).status,
                  source: WorkoutSource.FromException,
                  id: workoutIdForDay,
                  requiredRecordings: ProgramService.getRecordingsForWorkout(
                    archivedWorkout,
                    exerciseRecordings,
                    timeRangeItem.format("YYYY-MM-DD"),
                  ),
                },
                type: EVENT_TYPE.WORKOUT,
              });
            }
          } else {
            const currentWeekIndex = findCurrentWeekScheduleIndex(
              clientProgram?.workoutsSchedule?.schedule,
              clientProgram.startDate,
              timeRangeItem,
            );

            const workoutIdForDay = dayOfWeek
              ? clientProgram.workoutsSchedule?.schedule[currentWeekIndex]?.[dayOfWeek]?.workoutDay
              : null;
            const scheduledWorkout = workoutIdForDay && workoutsById[workoutIdForDay];
            if (workoutIdForDay && scheduledWorkout) {
              events.unshift({
                id: "",
                title: scheduledWorkout.name,
                allDay: true,
                start: timeRangeItem.startOf("day").toDate(),
                end: timeRangeItem.endOf("day").toDate(),
                data: scheduledWorkout,
                metadata: {
                  status: WorkoutService.getWorkoutStatus(scheduledWorkout, timeRangeItem, true).status,
                  source: WorkoutSource.FromSchedule,
                  id: workoutIdForDay,
                  requiredRecordings: ProgramService.getRecordingsForWorkout(
                    scheduledWorkout,
                    exerciseRecordings,
                    timeRangeItem.format("YYYY-MM-DD"),
                  ),
                },
                type: EVENT_TYPE.WORKOUT,
              });
            }
          }
        }
      }
    }
    return events;
  };

  public static getCurrentTrainingWeek({
    activeProgram,
    programDetails,
    activities,
  }: {
    activeProgram: ClientTrainingProgram;
    activities: Record<string, WorkoutActivityEvent[]> | WorkoutActivityEvent[];
    programDetails: ClientTrainingProgramDetails[];
  }) {
    const activitiesRecord = Array.isArray(activities) ? groupBy(activities, "eventDate") : activities;
    const programSchedule = this.getProgramScheduleForWeek({
      activeProgram,
      programDetails,
      activities: activitiesRecord,
    });
    const currentWeek = dayjs().isoWeek();
    const nextWeek = dayjs().add(1, "week").isoWeek();
    const currentProgramWeekIndex = programSchedule.findIndex((week) => week.isoWeek === currentWeek);
    const currentProgramWeek = programSchedule[currentProgramWeekIndex];
    const nextProgramWeekIndex = programSchedule.findIndex((week) => week.isoWeek === nextWeek);
    const nextProgramWeek = programSchedule[nextProgramWeekIndex];

    if (!activeProgram) {
      return null;
    }
    const startWeek = dayjs().startOf("isoWeek");
    const endWeek = dayjs().add(1, "week").endOf("isoWeek");

    const days: { date: string; dayName: string; dateObj: Dayjs }[] = [];

    do {
      const newDate = days.length === 0 ? startWeek : days[days.length - 1]?.dateObj.add(1, "day");
      if (newDate) {
        days.push({
          date: newDate.format("YYYY-MM-DD"),
          dayName: weekdays[newDate.isoWeekday() - 1] || "",
          dateObj: newDate,
        });
      }
    } while (!days[days.length - 1]?.dateObj.isSameOrAfter(endWeek));

    const trainings: Record<string, MappedSchedule | null> = {
      ...(currentProgramWeek?.schedule ?? {}),
      ...(nextProgramWeek?.schedule ?? {}),
    };

    const twoWeeksWithTraining = days.map((day) => {
      const activitiesByDate = groupBy(activities, "eventDate");
      const fromActivities = activitiesByDate?.[day.date]?.flat() as WorkoutActivityEvent[] | undefined;
      const workouts = compact(trainings[day.date]?.workouts) ?? [];

      const workoutsForDate =
        fromActivities?.map((item) => item.data) ||
        (workouts.length ? workouts.map((item) => (item?.type === ProgramWorkoutType.Regular ? item : item.data)) : []);

      return {
        ...day,
        ...(trainings[day.date] ?? {}),
        workouts: workoutsForDate,
        isRestDay: workoutsForDate.every((item) => item.isRestDay),
        isWorkoutDay: Boolean(workoutsForDate.length),
        trainingStatus: workoutsForDate.length
          ? workoutsForDate.map(
              (selectedWorkout) =>
                WorkoutService.getWorkoutStatus(selectedWorkout, dayjs(day.date, "YYYY-MM-DD")).status,
            )
          : null,
      };
    });

    return {
      dates: twoWeeksWithTraining,
      currentProgramWeek: currentProgramWeek ? currentProgramWeek.weekNumber : null,
      numberOfWeeksInProgram: programSchedule.length,
    } as CurrentTrainingWeekSchedule;
  }

  public static getProgramScheduleForWeek({
    activeProgram,
    programDetails,
    activities,
  }: {
    activeProgram: ClientTrainingProgram;
    programDetails: ClientTrainingProgramDetails[];
    activities: Record<string, WorkoutActivityEvent[]>;
  }) {
    const programSchedule: MappedWeekSchedule = [];
    if (!activeProgram) return programSchedule;
    if (!activeProgram.workoutsSchedule) return [];
    const { duration, startDate, endDate } = activeProgram;
    const currentDateMoment = dayjs(startDate);
    const programRange = range(0, (duration || 1) + 1);

    const workoutsById = programDetails ? keyBy(programDetails.map((details) => details.workouts).flat(), "id") : {};
    const archivedWorkoutsById = programDetails
      ? keyBy(programDetails.map((details) => details.archivedWorkouts).flat(), "id")
      : {};
    programRange.forEach((week) => {
      const dateForWeek = dayjs(currentDateMoment).add(week, "week");
      const weekSchedule = ProgramService.getScheduleForWeek(activeProgram, dateForWeek);
      const mappedWeekSchedule = mapValues(weekSchedule.week, ({ workoutDay }, key) => {
        const date = dayjs(dateForWeek).isoWeekday(ProgramService.weekday[key]!).format("YYYY-MM-DD");
        if (dayjs(date).isBefore(dayjs(startDate).startOf("day")) || dayjs(date).isAfter(dayjs(endDate).endOf("day"))) {
          return null;
        }

        const fromActivities = activities?.[date];
        const workout = workoutDay ? workoutsById[workoutDay] || archivedWorkoutsById[workoutDay] : null;
        const workoutsForDate = fromActivities?.map((item) => item.data) || [workout];
        return {
          date,
          dayName: key,
          workouts: workoutsForDate,
          trainingStatus: workoutsForDate.map((selectedWorkout) =>
            selectedWorkout ? WorkoutService.getWorkoutStatus(selectedWorkout, dayjs(date, "YYYY-MM-DD")).status : null,
          ),
          workoutId: workoutDay,
        } as MappedSchedule;
      });

      programSchedule.push({
        weekNumber: week + 1,
        isoWeek: dateForWeek.isoWeek(),
        isoYear: dateForWeek.get("year"),
        schedule: keyBy(mappedWeekSchedule, "date"),
      });
    });
    const firstWeek = programSchedule[0]?.schedule;

    if (firstWeek && isEmpty(Object.values(firstWeek))) {
      programSchedule.shift();
      programSchedule.forEach((_, index: number) => {
        if (programSchedule[index]) {
          programSchedule[index].weekNumber = index + 1;
        }
      });
    }
    return sortBy(programSchedule, "weekNumber");
  }
}
