import zipIcon from "../assets/file-archive.svg";
import audioIcon from "../assets/file-audio.svg";
import excelIcon from "../assets/file-excel.svg";
import defaultIcon from "../assets/file-generic.svg";
import pdfIcon from "../assets/file-pdf.svg";
import textIcon from "../assets/file-text.svg";
import wordIcon from "../assets/file-word.svg";
import imageIcon from "../assets/image.svg";
import videoIcon from "../assets/video.svg";

export const getFileIcon = (extension: string): string => {
  switch (extension) {
    case "doc":
    case "docx":
      return wordIcon;
    case "mpg":
    case "mp4":
    case "avi":
      return videoIcon;
    case "pdf":
      return pdfIcon;
    case "txt":
    case "rtf":
      return textIcon;
    case "wma":
    case "mp3":
      return audioIcon;
    case "xls":
    case "xlsx":
    case "csv":
      return excelIcon;
    case "bmp":
    case "jpeg":
    case "jpg":
    case "png":
      return imageIcon;
    case "zip":
    case "7z":
    case "rar":
      return zipIcon;
    default:
      return defaultIcon;
  }
};
