export const createWFirmaIntegrationLink = ({
  clientId,
  functionDomain,
  userId,
}: {
  clientId: string;
  functionDomain: string;
  userId: string;
}) => {
  return encodeURI(
    `https://wfirma.pl/oauth2/auth?response_type=code&client_id=${clientId}&scope=invoices-read invoices-write&redirect_uri=${functionDomain}/integrations-verify/wfirma/redirect/${userId}`,
  );
};

export const createWFirmaRedirectLink = ({ functionDomain, userId }: { functionDomain: string; userId: string }) => {
  return encodeURI(`${functionDomain}/integrations-verify/wfirma/redirect/${userId}`);
};
