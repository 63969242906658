import React, { useCallback, useEffect, useState, type FunctionComponent } from "react";
import { DeleteOutlined, PlusOutlined, RobotOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, message, Popconfirm, Select, Space, Spin, Steps, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productAutomationActions, productsSettingsActions } from "@fitness-app/app-store";
import { type DiscordIntegration } from "@fitness-app/data-models/entities/Automation";
import { createBotDiscordIntegrationLink } from "@fitness-app/utils/src/discord/links";

import { useDiscordIntegration } from "~/modules/Products/Product/ProductAutomation/hooks/useDiscordIntegration";
import { useAppDispatch } from "~/store/initializeStore";
import discordImg from "../assets/discord_guide.png";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

const { Step } = Steps;

enum IntegrationSteps {
  SetServerId,
  AddBotToServer,
  VerifyIntegration,
}

enum ViewState {
  StepsView,
  ListView,
}

const DISCORD_GUIDE_URL =
  "https://support.discord.com/hc/pl/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-";

interface FormModel {
  serverId: null | string;
}

const CheckIntegration = ({
  serverId,
  productId,
  startFromScratch,
  showIntegrationList,
}: {
  serverId: string;
  productId: string;
  startFromScratch: () => void;
  showIntegrationList: () => void;
}) => {
  const { t } = useTranslation(["settings", "common"]);
  const dispatch = useAppDispatch();
  const [showLoader, toggleLoader] = useState(true);
  const [errorStatus, setErrorStatus] = useState("");

  const checkDiscordIntegration = async () => {
    try {
      await dispatch(
        productsSettingsActions.verifyDiscordIntegration({
          serverId,
          productId,
        }),
      ).unwrap();
      toggleLoader(false);
      setTimeout(() => {
        showIntegrationList();
      }, 2000);
    } catch (e) {
      toggleLoader(false);
      setErrorStatus(e as string);
    }
  };

  useEffect(() => {
    void checkDiscordIntegration();
  }, []);

  if (errorStatus) {
    const authError = Number(errorStatus) === 403;
    return (
      <div className="text-center" data-testid={serverId} style={{ width: "100%" }}>
        <Alert
          message={t("productIntegration.discord.failedVerification")}
          description={
            authError
              ? t("productIntegration.discord.authorizationError")
              : t("productIntegration.discord.genericError")
          }
          type="error"
          showIcon
        />
        {authError ? (
          <Button onClick={startFromScratch} type="primary" style={{ marginTop: 20 }}>
            {t("productIntegration.discord.startFromFirstStep")}
          </Button>
        ) : (
          <Button onClick={checkDiscordIntegration} type="primary" style={{ marginTop: 20 }}>
            {t("common:button.tryAgain")}
          </Button>
        )}
      </div>
    );
  }

  if (!showLoader) {
    return (
      <div className="text-center" data-testid={serverId} style={{ width: "100%" }}>
        <Alert message={t("productIntegration.discord.successVerification")} type="success" showIcon />
      </div>
    );
  }

  return (
    <div className="text-center" data-testid={serverId} style={{ width: "100%" }}>
      <Typography.Paragraph>{t("productIntegration.discord.verification")}</Typography.Paragraph>
      <Spin />
    </div>
  );
};

const ADD_NEW_ITEM = "ADD_NEW_ITEM";

const DiscordProductIntegration: FunctionComponent<Props> = ({ productId }) => {
  const [currentStep, changeStep] = useState<IntegrationSteps>(IntegrationSteps.SetServerId);
  const { t } = useTranslation(["settings", "common"]);
  const [serverId, setServerId] = useState<null | string>(null);
  const dispatch = useAppDispatch();

  const { discordIntegration } = useDiscordIntegration();
  const [view, setView] = useState(discordIntegration.length ? ViewState.ListView : ViewState.StepsView);
  const [selectedAccount, selectAccount] = useState<null | DiscordIntegration>(null);

  const onFormFinish = (formModel: FormModel) => {
    setServerId(formModel.serverId);
    changeStep(IntegrationSteps.AddBotToServer);
  };

  const removeDiscordIntegration = async () => {
    const messageKey = "removingDiscordIntegration";
    void message.error({
      key: messageKey,
      content: t("productIntegration.discord.removing"),
    });
    await dispatch(
      productAutomationActions.updateProductAutomationIntegrations({
        productId,
        integrations: {},
      }),
    );
    setView(ViewState.StepsView);
  };

  const onIntegrationClick = useCallback((serverId: string) => {
    if (serverId) {
      window.open(
        createBotDiscordIntegrationLink({
          serverId,
          // TODO: set proper env variable
          clientId: "bot_Id",
        }),
      );
    }
  }, []);

  const renderStep = useCallback(() => {
    switch (currentStep) {
      case IntegrationSteps.SetServerId:
        return (
          <div className="w-full">
            <Form<FormModel> layout="vertical" onFinish={onFormFinish}>
              <Form.Item
                label={t("productIntegration.discord.serverIdLabel")}
                required
                name="serverId"
                rules={[
                  {
                    required: true,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                  {
                    type: "number",
                    message: t<string>("productIntegration.discord.wrongFormat"),
                    transform: (value) => (value ? Number(value) : 0),
                  },
                ]}
              >
                <Input placeholder={t<string>("productIntegration.discord.idPlaceholder")} />
              </Form.Item>
              <Typography.Link href={DISCORD_GUIDE_URL} target="_blank">
                {t("productIntegration.discord.howGetServerId")}
              </Typography.Link>

              <Form.Item style={{ textAlign: "right" }}>
                <Button htmlType="submit" type="primary">
                  {t("common:button.next")}
                </Button>
              </Form.Item>
            </Form>

            <div>
              <img src={discordImg} alt="discord guide" style={{ maxWidth: 500, height: "auto" }} />
            </div>
          </div>
        );
      case IntegrationSteps.AddBotToServer:
        return (
          <div className="text-center">
            <Space size={24} direction="vertical" style={{ marginTop: 40 }}>
              <Typography.Title level={5}>{t("productIntegration.discord.botInfo")}</Typography.Title>
              <Button icon={<RobotOutlined />} type="primary" onClick={() => onIntegrationClick(serverId ?? "")}>
                {t("productIntegration.discord.addBot")}
              </Button>

              <Typography.Paragraph type="secondary">
                {t("productIntegration.discord.botNextStep")}
              </Typography.Paragraph>

              <Form.Item style={{ textAlign: "right" }}>
                <Button type="primary" onClick={() => changeStep(IntegrationSteps.VerifyIntegration)}>
                  {t("common:button.next")}
                </Button>
              </Form.Item>
            </Space>
          </div>
        );
      case IntegrationSteps.VerifyIntegration:
        return (
          <CheckIntegration
            serverId={serverId!}
            productId={productId}
            startFromScratch={() => changeStep(IntegrationSteps.SetServerId)}
            showIntegrationList={() => setView(ViewState.ListView)}
          />
        );
    }
  }, [currentStep, t, serverId]);

  if (view === ViewState.ListView) {
    return (
      <div className="mx-auto my-10 flex min-h-[500px] min-w-[850px] max-w-[900px] flex-col items-center">
        <Space direction="vertical" size={24}>
          <Typography.Title level={4}>{t("productIntegration.discord.connectedAccounts")}</Typography.Title>
          <Select
            size="large"
            placeholder={t("selectAccount")}
            style={{ minWidth: 600 }}
            notFoundContent={false}
            onChange={(value) => selectAccount(discordIntegration.find((item) => item.guildId === value) ?? null)}
            onSelect={(value: string) =>
              selectAccount(discordIntegration.find((item) => item.guildId === value) ?? null)
            }
            value={selectedAccount?.guildId}
            optionFilterProp="title"
            optionLabelProp="title"
          >
            {discordIntegration.map((account) => (
              <Select.Option value={account.guildId} title={account.guild.name} key={account.guildId}>
                {account.guild.name}
              </Select.Option>
            ))}
            {
              <Select.Option value={ADD_NEW_ITEM} title={t<string>("addNewAccount")}>
                <Button
                  type="link"
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                  }}
                >
                  <PlusOutlined /> {t("addNewAccount")}
                </Button>
              </Select.Option>
            }
          </Select>
          {selectedAccount && (
            <div
              style={{
                maxWidth: 600,
                marginBottom: 20,
              }}
            >
              <Space direction="horizontal" align="end" style={{ width: "100%", justifyContent: "flex-end" }}>
                <Popconfirm
                  onConfirm={removeDiscordIntegration}
                  title={t("emailClients.deleteWarning")}
                  okText={t("common:button.delete")}
                  okButtonProps={{ danger: true }}
                >
                  <Button type="dashed" danger icon={<DeleteOutlined />}>
                    {t("common:button.delete")}
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          )}
        </Space>
        <div
          style={{
            maxWidth: 600,
            marginTop: 20,
          }}
        >
          <Alert showIcon type="info" message={t("productIntegration.discord.automationInfo")} />
        </div>
      </div>
    );
  }

  return (
    <div className="max-width-[900px] mx-auto my-10 min-h-[500px] min-w-[850px]">
      <Steps size="small" current={currentStep} style={{ width: "100%" }}>
        <Step title={t("productIntegration.discord.setServerId")} />
        <Step title={t("productIntegration.discord.addBotToServer")} />
        <Step title={t("productIntegration.discord.verifyIntegration")} />
      </Steps>

      <div className="mx-auto my-10 flex max-w-[600px] items-center">{renderStep()}</div>
    </div>
  );
};

export default DiscordProductIntegration;
