import React, { useEffect } from "react";
import { type DraggableSyntheticListeners } from "@dnd-kit/core";
import { CSS, type Transform } from "@dnd-kit/utilities";
import classNames from "classnames";

import { Action, type ActionProps } from "~/components/Dnd/Action";

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: object;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  children?: React.ReactNode;
  onRemove?(): void;
}

export const Handle = React.forwardRef<HTMLButtonElement, ActionProps>((props, ref) => {
  return (
    <Action ref={ref} cursor="grab" data-cypress="draggable-handle" {...props}>
      <svg viewBox="0 0 20 20" width="14">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </Action>
  );
});

Handle.displayName = "Handle";

export const DndItem = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        sorting,
        style,
        transition,
        transform,
        value,
        children,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      const wrapper = {
        transform: CSS.Transform.toString(
          transform ? { x: transform.x, y: transform.y, scaleX: 1, scaleY: 1 } : { x: 0, y: 0, scaleX: 1, scaleY: 1 },
        ),
        transition,
      };

      return (
        <li
          className={classNames(
            "touch-manipulation list-none",
            fadeIn && "animate-[fadeIn_500ms_ease]",
            dragOverlay && "z-[999]",
          )}
          style={
            {
              ...wrapperStyle,
              width: "100%",
              ...wrapper,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            className={classNames(
              "relative",
              dragging && !dragOverlay && "opacity-50",
              dragOverlay && "opacity-100",
              disabled && "cursor-not-allowed",
            )}
            style={{ ...(style || {}) }}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {children || value}
            {handle ? <Handle {...handleProps} {...listeners} /> : null}
          </div>
        </li>
      );
    },
  ),
);
