import React, { useState } from "react";
import { Avatar, Button, Card, Empty, Space, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { botsConfigActions, PerformingAction, RequestStatus } from "@fitness-app/app-store";
import { type ChatBot } from "@fitness-app/data-models/entities/ChatBot";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import BotConfigForm from "~/modules/Automation/BotsConfig/BotConfigForm";
import { type BotConfigFormModel } from "~/modules/Automation/BotsConfig/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const BotsConfig = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["automation", "common"]);
  const { listStatus, list, performingAction } = useAppSelector((store) => store.botsConfig);
  const [showEditForm, toggleForm] = useState(false);
  const [model, setModel] = useState<(ChatBot & { avatar: string[] }) | null>(null);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();

  const onBotEdit = (bot: ChatBot) => {
    setModel({ ...bot, avatar: bot.icon ? [bot.icon] : [] });
    toggleForm(true);
  };

  const handleBotConfigSubmit = async (formModel: BotConfigFormModel) => {
    onStart();
    try {
      if (!model) {
        await dispatch(
          botsConfigActions.addBotConfig({
            name: formModel.name,
            icon: formModel.avatar[0] || null,
            description: formModel.description || "",
            id: uuid(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            eventsSent: [],
            chatRoomName: null,
            eventsConsumed: [],
            settings: {},
            capabilities: {
              canCreateRooms: true,
              canSendMessagesToAnyRoom: true,
            },
          }),
        );
      } else {
        await dispatch(
          botsConfigActions.updateBotConfig({
            id: model.id,
            bot: {
              name: formModel.name,
              icon: formModel.avatar[0] || null,
              description: formModel.description || "",
            },
          }),
        );
      }
      onSuccess();
      toggleForm(false);
      setModel(null);
    } catch {
      onFailure();
    }
  };
  return (
    <Card
      loading={listStatus === RequestStatus.FETCHING}
      bordered={false}
      extra={
        <Button onClick={() => toggleForm(true)} type="primary">
          {t("common:button.add")}
        </Button>
      }
    >
      <Table<ChatBot>
        loading={false}
        dataSource={list}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common:emptyList")} />,
        }}
      >
        <Table.Column<ChatBot>
          title={t("botsConfig.list.name")}
          key="name"
          dataIndex="name"
          render={(name, row) => (
            <Space>
              {row.icon && <Avatar size="large" src={row.icon} />} <span>{name}</span>
            </Space>
          )}
        />

        <Table.Column<ChatBot>
          title={t("botsConfig.list.description")}
          key="description"
          dataIndex="description"
          render={(desc) => (desc ? <Typography.Text type="secondary">{desc}</Typography.Text> : "-")}
        />

        <Table.Column<ChatBot>
          title={t("programAutomation.list.options")}
          key="action"
          align="center"
          render={(_, row) => (
            <>
              <Button type="link" onClick={() => onBotEdit(row)}>
                {t("common:button.edit")}
              </Button>
              {/*<Divider type={'vertical'} />*/}
              {/*<Popconfirm*/}
              {/*  title={t('programAutomation.list.delete')}*/}
              {/*  okText={t('common:switch.yes')}*/}
              {/*  cancelText={t('common:button.cancel')}*/}
              {/*  placement="top"*/}
              {/*  onConfirm={() => onBotRemove(row)}*/}
              {/*>*/}
              {/*  <Button danger type="link">*/}
              {/*    {t('common:button.delete')}*/}
              {/*  </Button>*/}
              {/*</Popconfirm>*/}
            </>
          )}
        />
      </Table>
      <ModalForm
        visible={showEditForm}
        onCancel={() => {
          toggleForm(false);
          setModel(null);
        }}
        title={model ? t("botConfig.editData", { botName: model?.name ?? "" }) : t("botsConfig.addTitle")}
        loading={
          Boolean(loading) ||
          performingAction === PerformingAction.Editing ||
          performingAction === PerformingAction.Adding
        }
      >
        <BotConfigForm onSubmit={handleBotConfigSubmit} model={model || undefined} />
      </ModalForm>
    </Card>
  );
};

export default BotsConfig;
