export const getUserInitials = (
  name: string | { firstName: string; lastName: string | null } | { name: string },
): string => {
  let nameToProcess = "";
  if (typeof name !== "string") {
    nameToProcess = "firstName" in name ? `${name.firstName} ${name.lastName ?? ""}` : name.name;
  } else {
    nameToProcess = name;
  }

  if (!nameToProcess) {
    return "";
  }

  const split = nameToProcess.split(" ");

  if (split.length === 1) {
    return split[0]?.charAt(0) ?? "-";
  }

  return split
    .map((name) => name.charAt(0))
    .slice(0, 2)
    .map((char) => char.toUpperCase())
    .join("");
};
