import { flatten, isNumber, max, min, times } from "lodash";
import { v4 as uuid } from "uuid";

import { type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";
import {
  type AlternativeExercise,
  type ExerciseInProgramFormModel,
  type ExerciseInWorkout,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { getFitnessValueFromField, type ReturnOfFitnessField } from "./getFitnessValueFromField";

const getAggregateValue = (
  fitnessField: ReturnOfFitnessField | { value: number | [number, number] | null; type?: string } | number,
): number | [number, number] | null => {
  if (typeof fitnessField === "number") {
    return fitnessField;
  }
  if (fitnessField.type === undefined) {
    return Number(fitnessField.value);
  }
  const flattenValue = Array.isArray(fitnessField.value)
    ? (flatten(fitnessField.value).map((value) => Number(value)) as [number, number])
    : Number(fitnessField.value);
  let minValue: number | undefined | null = null;
  let maxValue: number | undefined | null = null;
  if (fitnessField.type === "perSet") {
    minValue = typeof flattenValue === "number" ? flattenValue : min(flattenValue);
    maxValue = typeof flattenValue === "number" ? flattenValue : max(flattenValue);
    return minValue === maxValue ? Number(minValue) : [Number(minValue), Number(maxValue)];
  }
  return flattenValue;
};

const getSeriesValue = (
  fitnessField:
    | number
    | [number, number]
    | ReturnOfFitnessField
    | { value: number | [number, number] | null; type?: string },
  key: number,
) => {
  if (typeof fitnessField === "number") {
    return fitnessField;
  }
  if (Array.isArray(fitnessField)) {
    return fitnessField;
  }
  if (fitnessField.value === undefined) {
    return fitnessField ?? null;
  }
  if (fitnessField.type === undefined) {
    return fitnessField.value;
  }
  if (fitnessField.type === "perSet" && Array.isArray(fitnessField.value)) {
    return fitnessField.value[key] || 1;
  }
  if (fitnessField.type === "error") {
    return null;
  }
  return fitnessField.value ?? null;
};

export const formatExerciseInProgram = (
  formModel: ExerciseInProgramFormModel,
  exercise: ExerciseWithVideo,
  options: {
    updatingExercise?: ExerciseInWorkout | null;
    exercisesMap: Record<string, ExerciseWithVideo>;
  },
): ExerciseInWorkout => {
  let set: ExerciseInWorkout["set"] = {};

  const weightValue = formModel.weight ? getFitnessValueFromField(formModel.weight) : 0;
  const repsValue = formModel.numberOfRepeats ? getFitnessValueFromField(formModel.numberOfRepeats) : 0;

  times(formModel.numberOfSeries, (key) => {
    set = {
      ...set,
      [key + 1]: {
        repeats: getSeriesValue(repsValue, key),
        weight: getSeriesValue(weightValue, key),
        duration: formModel.duration
          ? {
              format: formModel.duration.format,
              value: getSeriesValue(getFitnessValueFromField(formModel.duration.value || null), key),
            }
          : null,
      },
    };
  });

  const alternativeExercises = formModel.alternativeExercises?.length
    ? formModel.alternativeExercises
        .map((id): AlternativeExercise | null => {
          const exercise = options.exercisesMap[id];

          if (exercise) {
            return {
              name: exercise.name,
              id: exercise.id,
              thumbnailUrl: exercise.video?.thumbnailUrl || null,
            };
          }
          return null;
        })
        .filter((item): item is AlternativeExercise => !!item)
    : [];

  const { exercisesType, exerciseId, ...rest } = formModel;
  return {
    createdAt: new Date().toISOString(),
    ...options.updatingExercise,
    ...rest,
    updatedAt: new Date().toISOString(),
    id: options.updatingExercise?.id || uuid(),
    exerciseId: exercise.id,
    exercise,
    restTime: "restTime" in formModel ? formModel.restTime : null,
    alternativeExercises: alternativeExercises,
    duration: formModel.duration
      ? {
          format: formModel.duration.format,
          value: getAggregateValue(getFitnessValueFromField(formModel.duration.value || null)) || null,
        }
      : null,
    weight: getAggregateValue(weightValue),
    numberOfRepeats: getAggregateValue(repsValue),
    tempo: formModel.tempo && !isNumber(formModel.tempo) ? formModel.tempo.toUpperCase() : formModel.tempo ?? null,
    set,
  };
};

export const formatExerciseSet = (setData: ExerciseInWorkout): ExerciseInWorkout => {
  let workoutSet = {};

  const weightValue =
    Array.isArray(setData.weight) || !setData.weight
      ? { value: setData.weight ?? null }
      : getFitnessValueFromField(setData.weight);
  const repsValue =
    Array.isArray(setData.numberOfRepeats) || !setData.numberOfRepeats
      ? { value: setData.numberOfRepeats ?? null }
      : getFitnessValueFromField(setData.numberOfRepeats);
  times(setData.numberOfSeries, (key) => {
    workoutSet = {
      ...workoutSet,
      [key + 1]: {
        repeats: getSeriesValue(setData.set?.[key + 1]?.repeats ?? repsValue, key),
        weight: getSeriesValue(setData.set?.[key + 1]?.weight ?? weightValue, key),
        duration: setData.duration
          ? {
              format: setData.duration.format,
              value:
                setData.duration.value === null
                  ? null
                  : getSeriesValue(setData.set?.[key + 1]?.duration?.value ?? setData.duration.value, key),
            }
          : null,
      },
    };
  });

  return {
    ...setData,
    duration: setData.duration
      ? {
          format: setData.duration.format,
          value:
            setData.duration.value === null
              ? null
              : getAggregateValue(getFitnessValueFromField(setData.duration.value)),
        }
      : null,
    weight: getAggregateValue(weightValue),
    numberOfRepeats: getAggregateValue(repsValue),
    tempo: setData.tempo && !isNumber(setData.tempo) ? setData.tempo.toUpperCase() : setData.tempo ?? null,
    set: workoutSet,
  };
};

export default formatExerciseInProgram;
