import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type ClientTrainingProgram,
  type ClientTrainingProgramDetails,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
};

export const fetchProgramWithDetails = createAsyncThunk<
  { plan: ClientTrainingProgram; details: ClientTrainingProgramDetails },
  Payload,
  AsyncThunkCreator<string>
>(`${TRAINEE_PROGRAM_REDUCER_NAME}/fetchProgramWithDetails`, async (payload, { rejectWithValue, extra: { db } }) => {
  const query = db
    .from("client_training_program")
    .select("*, details:client_training_program_details (*)")
    .eq("id", payload.id);

  const { error, data } = await query.single();

  if (error) {
    return rejectWithValue(error.message);
  }
  const { details, ...rest } = data;

  const programDetails = Array.isArray(details) ? details[0] : details;

  return {
    plan: { ...(rest as ClientTrainingProgram) },
    details: programDetails as ClientTrainingProgramDetails,
  };
});
