import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
};

export const fetchTraineeSurveys = createAsyncThunk<ClientSurvey[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/fetchTraineeSurveys`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("client_survey")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("traineeId", payload.traineeId)
      .returns<ClientSurvey[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
