import { createAsyncThunk } from "@reduxjs/toolkit";

import { type IngredientGroup } from "@fitness-app/data-models/entities/Ingredient";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchIngredientGroups = createAsyncThunk<IngredientGroup[], Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/fetchIngredientGroups`,
  async (payload, { rejectWithValue, extra: { db }, getState }) => {
    const fetched = getState().nutrition.ingredientGroups;
    if (fetched.length) {
      return fetched;
    }

    const { error, data } = await db.from("ingredient_group").select("*").order("id");

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
