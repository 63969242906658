import { type Dish, type DishTypeEnum, type MealTypeEnum } from "@fitness-app/data-models/entities/Dish";
import {
  type Ingredient,
  type IngredientCategory,
  type IngredientGroup,
  type IngredientMeasure,
} from "@fitness-app/data-models/entities/Ingredient";

import { type RequestStatus } from "../../../enums/requestStatus";

export interface NutritionReducer {
  ingredients: Ingredient[];
  ingredientsStatus: RequestStatus | null;
  ingredientsPage: number;
  ingredientsPerPage: number;
  ingredientsTotalPages: number;
  ingredientGroups: IngredientGroup[];
  ingredientCategories: IngredientCategory[];
  ingredientMeasures: IngredientMeasure[];
  ingredientSearchTerm: null | string;
  filteredCategories: number[];
  dishes: Dish[];
  dishesStatus: RequestStatus | null;
  dishesPage: number;
  dishesPerPage: number;
  dishesTotalPages: number;
  dishSearchTerm: null | string;
  dishFilters: {
    mealTypes: MealTypeEnum[];
    dishTypes: DishTypeEnum[];
    dishTags: string[];
  };
}

export const NUTRITION_REDUCER_NAME = "nutrition";
