import React, { useState } from "react";
import { Badge, Button, message, Result, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions } from "@fitness-app/app-store";

import { useAppDispatch } from "~/store/initializeStore";
import commission from "./prowizja.png";

const PaymentIntegration = ({ connected }: { connected: boolean }) => {
  const { t } = useTranslation("settings");
  const [showLoader, toggleLoader] = useState(false);

  const dispatch = useAppDispatch();

  const createLinkForIntegration = async () => {
    toggleLoader(true);
    await dispatch(
      productsSettingsActions.generateLinkForIntegration({
        onFailure: () => void message.error("Fail when trying integrated with Stripe"),
        payload: undefined,
      }),
    );
    toggleLoader(false);
  };

  if (connected) {
    return (
      <Space direction="vertical" size={24}>
        <Row gutter={24} justify="center">
          <div style={{ maxWidth: 800, textAlign: "center" }} className="max-w-[800px] text-center">
            <Space size={16} direction="vertical" style={{ marginTop: 40 }}>
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                {t("clientIntegration.status")}
              </Typography.Title>
              <Badge status="success" text={t("clientIntegration.completed")} />
            </Space>
          </div>
        </Row>
      </Space>
    );
  }

  return (
    <Result
      status="info"
      title={t("integrateWithStripe.title")}
      subTitle={t("integrateWithStripe.desc")}
      extra={[
        <Button type="primary" key="button" loading={showLoader} onClick={createLinkForIntegration}>
          {t("integrateWithStripe.button")}
        </Button>,
        <div key="info" style={{ marginTop: 50, display: "flex", flexDirection: "column" }}>
          <h2>{t("integrateWithStripe.stripeFees")}</h2>
          <div>
            <img src={commission} alt="stripe-fees" style={{ maxWidth: 500, height: "auto" }} />
          </div>
          <a href="https://stripe.com/en-pl/pricing#pricing-details" target="_blank" rel="noreferrer">
            {t("integrateWithStripe.learnMore")}
          </a>
        </div>,
      ]}
    />
  );
};

export default PaymentIntegration;
