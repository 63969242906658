import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { type ClientActivityEvent } from "@fitness-app/data-models/entities/ClientActivity";
import { type Trainee } from "@fitness-app/data-models/entities/Trainee";

import { RequestStatus } from "../../../enums/requestStatus";
import {
  fetchClientActivity,
  fetchClientActivityEvents,
  fetchDailyActivities,
  fetchTrainee,
  fetchTraineeSettings,
  fetchTraineeStatus,
} from "./actions";
import { updateTrainee } from "./actions/updateTrainee";
import { updateTraineeNotificationsSettings } from "./actions/updateTraineeNotificationsSettings";
import { updateTraineeOnboardingSettings } from "./actions/updateTraineeOnboardingSettings";
import { updateTraineeWorkoutSettings } from "./actions/updateTraineeWorkoutSettings";
import { TRAINEE_REDUCER_NAME, type TraineeReducer } from "./types";

const initialState: TraineeReducer = {
  profileStatus: null,
  profile: null,
  showUpdateProfileData: false,

  clientActivityReport: null,
  clientActivityReportStatus: null,

  clientActivitiesLog: [],
  clientActivitiesLogStatus: null,

  traineeSettings: null,
  traineeSettingsStatus: null,

  userStatus: null,
  userStatusStatus: null,

  clientActivityEventsStatus: null,
  clientActivityEvents: [],
  totalPages: 0,
  page: 0,
  pageSize: 30,
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_REDUCER_NAME,
  reducers: {
    updateTraineeProfile(state, { payload }: PayloadAction<Trainee>) {
      if (payload && state.profile?.id === payload.id) {
        state.profile = {
          ...state.profile,
          ...payload,
        };
      }
    },
    clearTrainerProfile() {
      return initialState;
    },
    clearActivityEvents(state) {
      state.clientActivityEvents = [];
      state.clientActivityEventsStatus = null;
      state.page = 0;
      state.totalPages = 0;
    },
    addActivityEvent(state, { payload }: PayloadAction<ClientActivityEvent>) {
      state.clientActivityEvents = uniqBy([payload, ...state.clientActivityEvents], "eventId");
    },
    updateActivityEvent(state, { payload }: PayloadAction<ClientActivityEvent>) {
      state.clientActivityEvents = state.clientActivityEvents.map((event) => {
        if (event.eventId === payload.eventId) {
          return { ...event, ...payload };
        }
        return event;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainee.pending, (state, { meta }) => {
      if ("refetch" in meta.arg && meta.arg.refetch) {
        return;
      }
      state.profileStatus = RequestStatus.FETCHING;
      state.profile = null;
    });
    builder.addCase(fetchTrainee.fulfilled, (state, action) => {
      state.profileStatus = RequestStatus.SUCCESS;
      if (state.profile?.id === action.payload.id) {
        state.profile = {
          ...state.profile,
          ...action.payload,
        };
      } else {
        state.profile = action.payload;
      }
    });
    builder.addCase(fetchTrainee.rejected, (state) => {
      state.profileStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchClientActivity.pending, (state) => {
      state.clientActivityReportStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchClientActivity.fulfilled, (state, action) => {
      state.clientActivityReport = action.payload;
      state.clientActivityReportStatus = RequestStatus.SUCCESS;
    });
    builder.addCase(fetchClientActivity.rejected, (state) => {
      state.clientActivityReportStatus = RequestStatus.FAILED;
    });
    builder.addCase(updateTrainee.fulfilled, (state, { payload, meta }) => {
      if (payload && state.profile?.id === payload.id) {
        state.profile = {
          ...state.profile,
          ...payload,
        };
      }
      if (meta.arg.profile.firstName && state.profile?.user) {
        state.profile.user.firstName = meta.arg.profile.firstName;
        state.profile.user.lastName = meta.arg.profile.lastName || "";
        state.showUpdateProfileData = true;
      }
    });
    builder.addCase(fetchTraineeSettings.pending, (state) => {
      state.traineeSettingsStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchTraineeSettings.fulfilled, (state, action) => {
      state.traineeSettingsStatus = RequestStatus.SUCCESS;
      state.traineeSettings = action.payload;
    });
    builder.addCase(updateTraineeNotificationsSettings.fulfilled, (state, { payload }) => {
      if (state.traineeSettings?.notificationsSettings) {
        state.traineeSettings.notificationsSettings = payload.notificationsSettings;
        state.traineeSettings.updatedAt = payload.updatedAt;
      }
    });
    builder.addCase(updateTraineeOnboardingSettings.pending, (state, { meta }) => {
      if (state.traineeSettings?.onboarding && meta.arg.onboarding) {
        state.traineeSettings.onboarding = meta.arg.onboarding;
        state.traineeSettings.updatedAt = new Date().toISOString();
        if (state.profile?.settings?.onboarding) {
          state.profile.settings.onboarding = meta.arg.onboarding;
        }
      }
    });
    builder.addCase(updateTraineeOnboardingSettings.fulfilled, (state, { payload }) => {
      if (state.traineeSettings?.onboarding) {
        state.traineeSettings.onboarding = payload.onboarding;
        state.traineeSettings.updatedAt = payload.updatedAt;
      }
    });
    builder.addCase(updateTraineeWorkoutSettings.pending, (state, { meta }) => {
      if (state.traineeSettings) {
        state.traineeSettings.workoutSettings = meta.arg.workoutSettings;
      }
    });
    builder.addCase(fetchTraineeStatus.pending, (state) => {
      state.userStatusStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchTraineeStatus.fulfilled, (state, { payload }) => {
      state.userStatusStatus = RequestStatus.SUCCESS;
      state.userStatus = payload;
    });
    builder.addCase(fetchTraineeStatus.rejected, (state) => {
      state.userStatusStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchDailyActivities.pending, (state) => {
      state.clientActivitiesLogStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchDailyActivities.fulfilled, (state, { payload }) => {
      state.clientActivitiesLogStatus = RequestStatus.SUCCESS;
      state.clientActivitiesLog = payload;
    });
    builder.addCase(fetchDailyActivities.rejected, (state) => {
      state.clientActivitiesLogStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchClientActivityEvents.pending, (state) => {
      if (state.clientActivityEventsStatus === null) {
        state.clientActivityEventsStatus = RequestStatus.FETCHING;
      } else {
        state.clientActivityEventsStatus = RequestStatus.UPDATING;
      }
    });
    builder.addCase(fetchClientActivityEvents.fulfilled, (state, { payload }) => {
      state.clientActivityEventsStatus = RequestStatus.SUCCESS;
      state.clientActivityEvents =
        payload.currentPage === 1 ? payload.data : [...state.clientActivityEvents, ...payload.data];
      state.page = payload.currentPage;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(fetchClientActivityEvents.rejected, (state) => {
      state.clientActivityEventsStatus = RequestStatus.FAILED;
    });
  },
});

export const { updateTraineeProfile, clearActivityEvents, clearTrainerProfile, addActivityEvent, updateActivityEvent } =
  reducerSlice.actions;
export default reducerSlice.reducer;
