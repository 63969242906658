import {
  type ProgramAutomation,
  type ProgramAutomationWithInitialSurvey,
} from "@fitness-app/data-models/entities/ProgramAutomation";

import { type PerformingAction } from "../../../enums/performingAction";
import { type RequestStatus } from "../../../enums/requestStatus";

export type BaseProgramAutomation = Partial<ProgramAutomation> &
  Pick<ProgramAutomation, "id" | "schedule" | "comment" | "createdAt" | "updatedAt">;

export interface ProgramAutomationReducer {
  data: BaseProgramAutomation[];
  selectedAutomation: null | ProgramAutomationWithInitialSurvey;
  listStatus: RequestStatus | null;
  itemStatus: RequestStatus | null;
  performingAction: PerformingAction | null;
  error: Error | null;
}

export const AUTOMATION_REDUCER_NAME = "programAutomation";
