import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_RESOURCES_REDUCER_NAME } from "../types";
import { fetchProductResources } from "./fetchProductResources";

type Payload = {
  productId: string;
  resourceId: string;
};

export const removeProductResource = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/removeProductResource`,
  async (payload, { rejectWithValue, dispatch, extra: { db } }) => {
    const { error } = await db.from("product_resource").delete().eq("id", payload.resourceId);

    if (error) {
      return rejectWithValue(error.message);
    }
    void dispatch(fetchProductResources({ productId: payload.productId }));
  },
);
