import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import cloneDeep from "lodash.clonedeep";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export interface ScheduledClientProgramDateModel {
  name: string;
  id: string;
  startDate: string;
  endDate: string;
}

export const DATE_FORMAT = "YYYY-MM-DD";

const recalculateRestItems = (
  item: ScheduledClientProgramDateModel,
  endDate: string,
): ScheduledClientProgramDateModel => {
  const diffInDays = Math.floor(
    dayjs(item.endDate, DATE_FORMAT).endOf("day").diff(dayjs(item.startDate, DATE_FORMAT).startOf("day"), "day", true),
  );
  if (dayjs(item.startDate, DATE_FORMAT).startOf("day").isSameOrBefore(endDate)) {
    const updatedStart = dayjs(endDate, DATE_FORMAT).add(1, "day").startOf("day");
    return {
      ...item,
      startDate: updatedStart.format(DATE_FORMAT),
      endDate: updatedStart.add(diffInDays, "day").endOf("day").format(DATE_FORMAT),
    };
  } else {
    return item;
  }
};

export const recalculateScheduledProgramDates = (
  updatedProgram:
    | { id: string; startDate: string }
    | { id: string; endDate: string }
    | { currentProgramEndDate: string; id?: never },
  scheduledPrograms: ScheduledClientProgramDateModel[],
) => {
  if ("currentProgramEndDate" in updatedProgram) {
    let currentEnd = updatedProgram.currentProgramEndDate;

    return scheduledPrograms.map((program) => {
      const updated = recalculateRestItems(program, currentEnd);
      currentEnd = updated.endDate;

      return updated;
    });
  }

  const updatedProgramIndex = scheduledPrograms.findIndex((program) => program.id === updatedProgram.id);

  const updatedItem = cloneDeep(scheduledPrograms[updatedProgramIndex]);

  if (!updatedItem) {
    return scheduledPrograms;
  }

  const diffInDays = Math.floor(
    dayjs(updatedItem.endDate, DATE_FORMAT)
      .endOf("day")
      .diff(dayjs(updatedItem.startDate, DATE_FORMAT).startOf("day"), "day", true),
  );

  if ("startDate" in updatedProgram) {
    updatedItem.startDate = updatedProgram.startDate;
    updatedItem.endDate = dayjs(updatedProgram.startDate, DATE_FORMAT)
      .startOf("day")
      .add(diffInDays, "day")
      .format(DATE_FORMAT);
  } else {
    updatedItem.endDate = updatedProgram.endDate;
  }

  if (updatedProgramIndex === 0) {
    if (
      dayjs(scheduledPrograms[1]?.startDate, DATE_FORMAT)
        .startOf("day")
        .isSameOrBefore(dayjs(updatedItem.endDate, DATE_FORMAT), "day")
    ) {
      let currentEnd = updatedItem.endDate;

      const restPrograms = scheduledPrograms.slice(1);

      const updatedRestPrograms = restPrograms.map((program) => {
        const updated = recalculateRestItems(program, currentEnd);
        currentEnd = updated.endDate;

        return updated;
      });

      return [updatedItem, ...updatedRestPrograms];
    } else {
      return [updatedItem, ...scheduledPrograms.slice(1)];
    }
  }

  const programBefore = scheduledPrograms[updatedProgramIndex - 1];
  const programAfter = scheduledPrograms[updatedProgramIndex + 1];

  if (
    programBefore &&
    dayjs(updatedItem.startDate, DATE_FORMAT)
      .startOf("day")
      .isSameOrBefore(dayjs(programBefore.endDate, DATE_FORMAT).startOf("day"))
  ) {
    programBefore.endDate = dayjs(updatedItem.startDate, DATE_FORMAT).subtract(1, "day").format(DATE_FORMAT);
    let currentEnd = updatedItem.endDate;

    const restPrograms = scheduledPrograms.slice(updatedProgramIndex + 1);

    const updatedRestPrograms = restPrograms.map((program) => {
      const updated = recalculateRestItems(program, currentEnd);
      currentEnd = updated.endDate;

      return updated;
    });

    const before = scheduledPrograms.slice(0, updatedProgramIndex);
    before[updatedProgramIndex - 1] = programBefore;

    return [...before, updatedItem, ...updatedRestPrograms];
  } else if (
    programAfter &&
    dayjs(updatedItem.endDate, DATE_FORMAT)
      .startOf("day")
      .isSameOrAfter(dayjs(programAfter.startDate, DATE_FORMAT).startOf("day"))
  ) {
    programAfter.startDate = dayjs(updatedItem.endDate, DATE_FORMAT).add(1, "day").format(DATE_FORMAT);
    let currentEnd = updatedItem.endDate;

    const restPrograms = scheduledPrograms.slice(updatedProgramIndex + 1);

    const updatedRestPrograms = restPrograms.map((program) => {
      const updated = recalculateRestItems(program, currentEnd);
      currentEnd = updated.endDate;

      return updated;
    });

    return [...scheduledPrograms.slice(0, updatedProgramIndex), updatedItem, ...updatedRestPrograms];
  } else {
    return [
      ...scheduledPrograms.slice(0, updatedProgramIndex),
      updatedItem,
      ...scheduledPrograms.slice(updatedProgramIndex + 1),
    ];
  }
};
