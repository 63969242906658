import { combineReducers, type Action } from "@reduxjs/toolkit";
import { type AnyAction, type Reducer } from "redux";

import { type AppStore } from "../index";
import { automationLogsReducer } from "./automationLogs";
import { automationTemplatesReducer } from "./automationTemplates";
import { botsConfigReducer } from "./botsConfig";
import { chatReducer } from "./chat";
import { clientProductsReducer } from "./clientProducts";
import { clientScheduledTasksReducer } from "./clientScheduledTasks";
import { type ClientScheduledTasksReducer } from "./clientScheduledTasks/types";
import { exercisesReducer } from "./exercises";
import { liveWorkoutReducer } from "./liveWorkout";
import { mealsPlanReducer } from "./mealsPlans";
import { mediaLibraryReducer } from "./mediaLibrary";
import { notificationsReducer } from "./notifications";
import { nutritionReducer } from "./nutrition";
import { productReducer } from "./product";
import { productAutomationReducer } from "./productAutomation";
import { productClientReducer } from "./productClient";
import { productClientsReducer } from "./productClients";
import { productOrdersReducer } from "./productOrders";
import { productResourcesReducer } from "./productResources";
import { productsReducer } from "./products";
import { productsSettingsReducer } from "./productsSettings";
import { programAutomationReducer } from "./programAutomation";
import { programBuilderReducer } from "./programBuilder";
import { programsReducer } from "./programs";
import { statisticsReducer } from "./statistics";
import { supplementsTemplatesReducer } from "./supplementsTemplates";
import { surveysTemplatesReducer } from "./surveysTemplates";
import { teamReducer } from "./team";
import { traineeReducer } from "./trainee";
import { traineeActivitiesReducer } from "./traineeActivities";
import { traineeMealsPlanReducer } from "./traineeMealsPlans";
import { traineeMeasurementsReducer } from "./traineeMeasurements";
import { clientNotesReducer } from "./traineeNotes";
import { traineeNutritionTrackerReducer } from "./traineeNutritionTracker";
import { traineeProgramReducer } from "./traineeProgram";
import { traineesReducer } from "./trainees";
import { traineeSurveysReducer } from "./traineeSurveys";
import { traineeWorkoutsStatsReducer } from "./traineeWorkoutsStats";
import { userReducer } from "./user";
import { workoutTemplatesReducer } from "./workoutTemplates";

const rootReducer = combineReducers<AppStore>({
  user: userReducer,
  team: teamReducer,
  products: productsReducer,
  productAutomation: productAutomationReducer,
  productsSettings: productsSettingsReducer,
  product: productReducer,
  productClients: productClientsReducer,
  productOrders: productOrdersReducer,
  productResources: productResourcesReducer,
  automationLogs: automationLogsReducer,
  productClient: productClientReducer,
  nutrition: nutritionReducer,
  mealsPlans: mealsPlanReducer,
  exercises: exercisesReducer,
  programs: programsReducer,
  programBuilder: programBuilderReducer,
  workoutTemplates: workoutTemplatesReducer,
  surveysTemplates: surveysTemplatesReducer,
  botsConfig: botsConfigReducer,
  automationTemplates: automationTemplatesReducer,
  programAutomation: programAutomationReducer,
  mediaLibrary: mediaLibraryReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  trainees: traineesReducer,
  trainee: traineeReducer,
  traineeNotes: clientNotesReducer,
  traineeMeasurements: traineeMeasurementsReducer,
  traineeSurveys: traineeSurveysReducer,
  traineeProgram: traineeProgramReducer,
  clientTasks: clientScheduledTasksReducer as Reducer<ClientScheduledTasksReducer, AnyAction>,
  traineeActivities: traineeActivitiesReducer,
  traineeMealsPlan: traineeMealsPlanReducer,
  clientProducts: clientProductsReducer,
  supplementsTemplates: supplementsTemplatesReducer,
  traineeNutritionTracker: traineeNutritionTrackerReducer,
  traineeWorkoutsStats: traineeWorkoutsStatsReducer,
  statistics: statisticsReducer,
  liveWorkout: liveWorkoutReducer,
});

const LOG_OUT_ACTION = "User/logOutSuccess";

export const createRootReducer = (state: AppStore | undefined, action: Action<string>) => {
  let newState = state;
  if (action.type === LOG_OUT_ACTION) {
    newState = undefined;
  }

  return rootReducer(newState, action);
};

export default rootReducer;
