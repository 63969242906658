import React, { useEffect, useState, type FunctionComponent } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, AutoComplete, Button, Col, DatePicker, Form, Input, Select, Space, Switch, TreeSelect } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { DiscordIntegrationTask, EmailClientTask } from "@fitness-app/data-models/entities/Automation";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

import { useEmailLists } from "~/hooks/useEmailLists";
import { type ProgramAutomationTaskFormModel } from "~/modules/Automation/ProgramAutomation/components/ProgramAutomationTaskForm/types";
import { useDiscordIntegration } from "~/modules/Products/Product/ProductAutomation/hooks/useDiscordIntegration";

interface OwnProps {
  formController?: FormInstance<ProgramAutomationTaskFormModel>;
  onSubmit: (formData: ProgramAutomationTaskFormModel) => void;
  model?: ProgramAutomationTaskFormModel | null;
}

type Props = OwnProps;

const ProgramAutomationTaskForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["products", "common"]);
  const [selectedTaskType, selectTaskType] = useState<null | EmailClientTask | DiscordIntegrationTask>(
    model?.action.type || null,
  );
  const { tags, lists, listOrAccountOptions, emailAutomationTaskOptions } = useEmailLists();
  const { discordAutomationTaskOptions, discordIntegration } = useDiscordIntegration();

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    } else {
      formController?.resetFields();
    }
  }, [model]);

  const renderTaskAction = () => {
    if (
      selectedTaskType === DiscordIntegrationTask.RemoveFromServer ||
      selectedTaskType === DiscordIntegrationTask.AddToServer
    ) {
      return (
        <>
          <Form.Item
            name={["action", "serverId"]}
            label={t<string>("products:form.discordServerId")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              placeholder={t("products:form.selectDiscordAccount")}
              notFoundContent={false}
              optionFilterProp="title"
              optionLabelProp="title"
              allowClear
            >
              {discordIntegration.map((account) => (
                <Select.Option value={account.guildId} title={account.guild.name} key={account.guildId}>
                  {account.guild.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    }
    if (selectedTaskType === EmailClientTask.AddTags) {
      return (
        <>
          <Form.Item
            name={["action", "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              disabled={model?.protected}
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions.filter((account) => account.value !== EmailClient.FreshMail)}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>

          <Col offset={8}>
            <Alert message={t("products:form.emailClientTagsInfo")} style={{ marginTop: 10, marginBottom: 10 }} />
          </Col>

          <Form.Item
            name={["action", "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              disabled={model?.protected}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveTags) {
      return (
        <>
          <Form.Item
            name={["action", "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              disabled={model?.protected}
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions.filter((account) => account.value !== EmailClient.FreshMail)}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>

          <Col offset={8}>
            <Alert message={t("products:form.emailClientTagsInfo")} style={{ marginTop: 10, marginBottom: 10 }} />
          </Col>

          <Form.Item
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={["action", "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              disabled={model?.protected}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.AddToLists) {
      return (
        <Form.Item
          name={["action", "emailLists"]}
          label={t<string>("products:form.emailLists")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <TreeSelect
            style={{ maxWidth: 700 }}
            treeData={lists}
            disabled={model?.protected}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            treeNodeLabelProp="title"
          />
        </Form.Item>
      );
    }

    if (selectedTaskType === EmailClientTask.AddFieldValues) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={["action", "emailLists"]}
            label={t<string>("products:productAutomation.form.emailListsOrAccount")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions}
              disabled={model?.protected}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>
          <Form.Item
            label={t("productAutomation.form.fieldValues")}
            tooltip={t("productAutomation.form.fieldValuesTooltip")}
          >
            <Form.List name={["action", "values"]} initialValue={[{ key: "", value: "" }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                      <Space style={{ width: "100%" }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          style={{ flex: 1, width: 220, marginBottom: 5 }}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Input
                            disabled={model?.protected}
                            placeholder={t<string>("productAutomation.form.bodyPlaceholder")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ flex: 1, width: 235, marginBottom: 5 }}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <AutoComplete
                            options={[]}
                            disabled={model?.protected}
                            placeholder={t("productAutomation.form.valuePlaceholder")}
                          />
                        </Form.Item>
                        {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : null}
                      </Space>
                    </div>
                  ))}
                  {!model?.protected && (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            key: "",
                            value: "",
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("productAutomation.form.addField")}
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveFromLists) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={["action", "emailLists"]}
            label={t<string>("products:form.emailLists")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={lists}
              disabled={model?.protected}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return (
    <div>
      <Form<ProgramAutomationTaskFormModel>
        name="automation-task-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        form={formController}
        initialValues={{
          active: true,
          description: "",
          ...model,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name={["action", "type"]}
          label={t<string>("productAutomation.form.taskType")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            disabled={model?.protected}
            options={[...emailAutomationTaskOptions, ...discordAutomationTaskOptions]}
            onSelect={(value: EmailClientTask) => selectTaskType(value)}
          />
        </Form.Item>

        {renderTaskAction()}

        <Form.Item
          name="sentTime"
          label={t("automation:automatedMessageForm.operationTime")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <DatePicker.TimePicker minuteStep={15} allowClear={false} format="HH:mm" />
        </Form.Item>

        <Form.Item name="description" label={t<string>("productAutomation.form.description")}>
          <Input.TextArea rows={3} placeholder={t<string>("productAutomation.form.descriptionTaskPlaceholder")} />
        </Form.Item>

        <Form.Item hidden name="active" valuePropName="checked" label={t<string>("productAutomation.form.active")}>
          <Switch />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProgramAutomationTaskForm;
