import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = { id: string };

export const removeProduct = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCT_REDUCER_NAME}/removeProduct`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { analytics, productApi } }) => {
    try {
      await productApi.post("/remove-product", { id: payload.id });
      analytics.track("remove_product", {
        productId: payload.id,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
