import { type Database } from "@fitness-app/supabase";

import {
  type SurveyQuestion,
  type SurveyQuestionAutomation,
  type SurveyQuestionBasic,
  type SurveyQuestionId,
  type SurveyQuestionType,
  type SurveyTemplate,
} from "./SurveyTemplate";

export type ClientSurveyEntity = Database["public"]["Tables"]["client_survey"]["Row"];

export enum SurveyAction {
  Created = "created",
  Sent = "sent",
  Fulfilled = "fulfilled",
  Scheduled = "scheduled",
  Finished = "finished",
  OpenByClient = "open_by_client",
}

export enum SurveyActionOwner {
  System = "system",
  Trainer = "trainer",
  Client = "client",
}

export enum SurveyItemType {
  Question = "question",
  Information = "info",
  GroupIntro = "groupIntro",
  Summary = "summary",
}

export enum SurveyStatus {
  New = "new",
  Open = "open",
  Fulfilled = "fulfilled",
  PartiallyFulfilled = "partially_fulfilled",
  Archived = "archived",
  Scheduled = "scheduled",
  Sent = "sent",
}

export interface SurveyActionLog {
  action: SurveyAction;
  actionOwner: SurveyActionOwner;
  date: string;
  actionOwnerId: string | null;
}

export enum SurveyType {
  Recurring = "recurring",
  OneTime = "one-time",
  Initial = "initial",
  AdditionInitial = "additional_initial",
}

export interface SurveyTextAnswer {
  type: SurveyQuestionType.Text;
  answer: string | number;
}

export interface ExtendedAnswerOption {
  title: string;
  desc: string;
  value: string;
  extended?: boolean;
}

export interface SurveySelectAnswer {
  type: SurveyQuestionType.Select;
  multiSelect: boolean;
  answer: (string | number | ExtendedAnswerOption)[] | null;
}

export interface SliderConfig {
  min: number;
  max: number;
  labels: Record<string | number, string>;
  step: number;
  isRange: boolean;
  type: "numeric";
}

// export interface SurveyQuestionSlider extends SurveyQuestionBasic {
//   type: SurveyQuestionType.Slider;
//   config: SliderConfig;
// }

export interface SurveyQuestionAB extends SurveyQuestionBasic {
  type: SurveyQuestionType.AB;
  extendedAnswerA: boolean;
  extendedAnswerB: boolean;
}

export interface SurveyABAnswer {
  type: SurveyQuestionType.AB;
  answer: boolean | null;
  extendedAnswer: string | null;
}

export type SurveyAnswer = SurveyABAnswer | SurveyTextAnswer | SurveySelectAnswer;

export interface BaseSurvey
  extends Omit<ClientSurveyEntity, "questions" | "logs" | "answers" | "variables" | "automation"> {
  questions: Record<string, SurveyQuestion>;
  logs: SurveyActionLog[];
  answers: Record<string, SurveyAnswer> | null;
  type: SurveyType;
  variables: Record<string, string | number> | null;
  automation: Record<SurveyQuestionId, SurveyQuestionAutomation[]> | null;
  status: SurveyStatus;
}

export interface OneTimeSurvey extends BaseSurvey {
  type: SurveyType.OneTime;
  interval: null;
}

export interface InitialSurvey extends BaseSurvey {
  type: SurveyType.Initial | SurveyType.AdditionInitial;
  interval: null;
}

export enum CycleSurveyType {
  UntilDate = "until_date",
  Infinite = "infinite",
  CycleNumber = "cycle_number",
}

export interface UntilDateCycle {
  type: CycleSurveyType.UntilDate;
  date: string;
}

export interface InfiniteCycle {
  type: CycleSurveyType.Infinite;
}

export interface NumberOfCycle {
  type: CycleSurveyType.CycleNumber;
  numberOfCycles: number;
}

export type CycleSurvey = UntilDateCycle | InfiniteCycle | NumberOfCycle;

export type SurveyTemplateModel =
  | (SurveyTemplate & {
      surveyId: string;
      sentDate: Date | null;
    } & ClientSurvey)
  | InitialSurvey
  | OneTimeSurvey;

export enum RecurringSurveyStatus {
  New = "new",
  Active = "active",
  Finished = "finished",
}

export enum SurveyIntervalUnit {
  Month = "month",
  Week = "week",
}

export type Timezone = string;
export type Hour = number;
export type Minutes = number;
export type Offset = number;

export interface RecurringSurveyInterval {
  dayOfDelivery: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
  timeOfDelivery: [Hour, Minutes, Offset] | [Hour, Minutes, Offset, Timezone];
  count: number;
  unit: SurveyIntervalUnit;
  cycle: CycleSurvey;
  status: RecurringSurveyStatus;
}

export interface RecurringSurvey extends BaseSurvey {
  type: SurveyType.Recurring;
  interval: RecurringSurveyInterval;
  cycleNumber: number;
}

export type ClientSurvey = OneTimeSurvey | RecurringSurvey | InitialSurvey;
