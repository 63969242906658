import { useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export function useRequestHandler(
  successCallback?: () => void,
): [boolean | string, () => void, () => void, (id?: string) => void, () => void] {
  const [loading, toggleLoader] = useState<boolean | string>(false);
  const { t } = useTranslation("common");

  const messageKey = "REQUEST_LOADER_";

  const onStart = (id?: string, loadingMessage = t("messages.loading.default")) => {
    toggleLoader(id ?? true);
    void message.loading({ content: loadingMessage, key: messageKey, duration: 0 });
  };

  const onSuccess = (successMessage = t("messages.success.default")) => {
    toggleLoader(false);
    message.destroy(messageKey);
    void message.success(successMessage);
    successCallback?.();
  };
  const onFailure = (errorMessage = t("messages.error.default")) => {
    toggleLoader(false);
    message.destroy(messageKey);
    void message.error(errorMessage);
  };

  const onEnd = () => {
    toggleLoader(false);
    message.destroy(messageKey);
  };

  return [loading, onSuccess, onFailure, onStart, onEnd];
}
