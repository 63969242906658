import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";

import { CampaignStatus } from "@fitness-app/data-models/entities/Campaign";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";

import { supabase } from "~/store/initializeStore";

export const useCampgainCancelation = () => {
  const queryClient = useQueryClient();
  const cancelMutation = useMutation({
    async mutationFn({ campaignId, status, skipped }: { campaignId: string; status: CampaignStatus; skipped: number }) {
      if (![CampaignStatus.Scheduled, CampaignStatus.Running].includes(status)) {
        message.error("Nie można anulować kampanii w tym statusie");
        return null;
      }

      message.loading({ content: "Anulowanie kampanii...", key: "cancelCampaign" });

      const { error, count } = await supabase
        .from("program_automation_task")
        .update(
          { status: ProgramAutomationTaskStatus.Canceled, updatedAt: new Date().toISOString() },
          { count: "exact" },
        )
        .eq("programAutomationId", campaignId)
        .eq("status", ProgramAutomationTaskStatus.Upcoming);

      if (error) {
        message.error(error.message);
        return null;
      }

      await supabase
        .from("campaign")
        .update({
          status: status === CampaignStatus.Running ? CampaignStatus.Cancelled : CampaignStatus.Archived,
          skipped: skipped + (count || 0),
          updatedAt: new Date().toISOString(),
        })
        .eq("id", campaignId);

      message.destroy("cancelCampaign");
      message.success(
        status === CampaignStatus.Running ? "Kampania została anulowana" : "Kampania została zarchiwizowana",
      );
      queryClient.invalidateQueries(["campaigns"]);
    },
  });

  return {
    cancelMutation,
  };
};
