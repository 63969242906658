import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Dish } from "@fitness-app/data-models/entities/Dish";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  dish: Dish;
};

export const addDish = createAsyncThunk<Dish | null, Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/addDish`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db.from("dish").insert(payload.dish).select().single<Dish>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_dish");

    return data || null;
  },
);
