import { type AppThunk } from "../../../index";
import { unsubscribeFromMediaLibrary as action } from "../reducer";

export const unsubscribeFromMediaLibrary =
  (): AppThunk =>
  async (dispatch, _, { subscriptions, db }) => {
    if (subscriptions.realtimeMediaLibraryListChannel) {
      await db.removeChannel(subscriptions.realtimeMediaLibraryListChannel);
      subscriptions.mediaLibraryListChannelName = null;
    }
    dispatch(action());
  };
