import { useState } from "react";
import dayjs, { type Dayjs } from "dayjs";

import { clientScheduledTasksActions, programAutomationActions } from "@fitness-app/app-store";
import { type ScheduledItems, type WorkoutRoutine } from "@fitness-app/data-models/entities/ProgramAutomation";

import { useRequestHandler } from "~/hooks/useRequestHandler";
import { useAppDispatch } from "~/store/initializeStore";

export const useAddingProgramAutomationToClient = (traineeId: string, currentDate: Date) => {
  const [processing, onSuccess, onError, onStart] = useRequestHandler();
  const [showAutomationScheduleForm, toggleAutomationScheduleForm] = useState(false);
  const dispatch = useAppDispatch();

  const handleAddingAutomationToClient = async (data: {
    events: ScheduledItems[];
    scheduledPrograms: (WorkoutRoutine & { startAt?: Dayjs; endAt?: Dayjs })[];
    startDate: string;
    clientId: string;
    programAutomationId: string;
    programAutomationName: string;
  }) => {
    onStart();

    try {
      const mapped = {
        ...data,
        scheduledPrograms: data.scheduledPrograms.map(({ startAt, endAt, ...rest }) => rest),
      };
      await dispatch(programAutomationActions.addAutomationToClient(mapped)).unwrap();
      void dispatch(clientScheduledTasksActions.fetchClientTasks({ traineeId, selectedDate: dayjs(currentDate) }));
      onSuccess();
      toggleAutomationScheduleForm(false);
    } catch (e) {
      onError();
    }
  };

  return {
    addingAutomationToClient: processing,
    handleAddingAutomationToClient,
    showAutomationScheduleForm,
    toggleAutomationScheduleForm,
  };
};
