import React, { memo, useCallback, useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { type Meal } from "@fitness-app/data-models/entities/MealsPlan";

import { SortableItem } from "~/components/Dnd/SortableItem";
import MealDish from "./MealDish";

interface MealDishesListProps {
  meal: Meal;
  dayIndex: number;
  dayId: string;
  mealIndex: number;
  onAddDish: () => void;
  readonly?: boolean;
}

const MealDishesList = ({
  meal,
  dayIndex,
  mealIndex,
  onAddDish,
  dayId,
  readonly,
}: MealDishesListProps): React.ReactElement => {
  const { t } = useTranslation("nutrition");
  const { setNodeRef } = useDroppable({
    id: `PLACEHOLDER__${dayIndex}__${mealIndex}`,
  });

  const dishesIds: string[] = useMemo(() => (meal.dishes || []).map((dish) => dish?.id) || [], [meal.dishes]);
  const getIndex = useCallback(
    (id: string) => {
      const index = dishesIds.indexOf(id);

      return index;
    },
    [dishesIds],
  );

  return (
    <div id={meal.id} className="flex-1" ref={setNodeRef}>
      <SortableContext items={dishesIds} disabled={readonly}>
        {meal.dishes.map((dish, index) => (
          <SortableItem
            disabled={false}
            key={dish.id}
            id={dish.id}
            index={index}
            containerId={meal.id}
            getIndex={getIndex}
          >
            <MealDish readonly={readonly} key={dish.id} dishOrIngredient={dish} mealId={meal.id} dayId={dayId} />
          </SortableItem>
        ))}

        {meal.dishes.length === 0 ? (
          <div className="py-5 text-center">
            <p className="text-gray-400">{t("mealsPlan.emptyList")}</p>
          </div>
        ) : null}
        {!readonly && (
          <div className="invisible mt-2 text-center group-hover:visible">
            <Button size="small" type="link" icon={<PlusOutlined />} onClick={onAddDish}>
              {t("mealsPlan.addNewItem")}
            </Button>
          </div>
        )}
      </SortableContext>
    </div>
  );
};

export default memo(MealDishesList);
