export const SUBSCRIPTION_STATUS_MAPPER = {
  active: "success",
  imported: "success",
  past_due: "warning",
  paused: "warning",
  incomplete_expired: "warning",
  trialing: "processing",
  incomplete: "processing",
  canceled: "warning",
  unpaid: "error",
  expired: "error",
} as const;
