import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";

import { type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";
import {
  TrainingStatus,
  type ExerciseInWorkout,
  type ProgramWorkout,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import recreateExerciseDataForAlternativeExercise from "@fitness-app/utils/src/programs/recreateExerciseDataForAlternativeExercise";

import { type AsyncThunkCreator } from "../../../index";
import { traineeProgramActions } from "../../traineeProgram";
import { updateClientCurrentWorkout } from "../reducer";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";
import { saveWorkoutToSpecifiedDay } from "./saveWorkoutToSpecifiedDay";

type Payload = {
  id: string;
  activityId: string | null;
  exercise: ExerciseWithVideo;
  workoutPart: WorkoutExercisesType;
  exerciseToReplace: ExerciseInWorkout;
  replaceInProgram: boolean;
  workoutId: string;
};

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const updateExerciseToAlternative = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/updateExerciseToAlternative`,
  async (payload, { getState, dispatch, extra: { analytics } }) => {
    const { currentWorkout: savedWorkout } = getState().traineeActivities.data;
    const { programDetails } = getState().traineeProgram;

    if (!savedWorkout) {
      throw new Error("currentWorkout is not defined");
    }

    if (!payload.activityId) {
      await dispatch(saveWorkoutToSpecifiedDay({ isMobile: true })).unwrap();
    }

    const { currentWorkout } = getState().traineeActivities.data;

    if (!currentWorkout) {
      throw new Error("currentWorkout is not defined");
    }

    const updated: ExerciseInWorkout = {
      ...recreateExerciseDataForAlternativeExercise(payload.exerciseToReplace, payload.exercise, true),
      exercise: payload.exercise,
      exerciseId: payload.exercise.id,
      updatedAt: new Date().toISOString(),
      status: TrainingStatus.NEW,
    };

    const updatedWorkoutExercises = currentWorkout[payload.workoutPart].map((exercise) => {
      return exercise.id === payload.exerciseToReplace.id ? { ...exercise, ...updated } : exercise;
    });
    const { ...rest } = currentWorkout;
    const updatedWorkout: ProgramWorkout = {
      ...rest,
      [payload.workoutPart]: updatedWorkoutExercises,
      updatedAt: new Date().toISOString(),
    };

    dispatch(updateClientCurrentWorkout(updatedWorkout));
    await dispatch(saveWorkoutToSpecifiedDay({ isMobile: true }));

    const workoutInProgramToUpdate = programDetails
      ? programDetails.workouts.find((savedWorkout) => savedWorkout.id === payload.workoutId)
      : null;

    if (programDetails?.programId && payload.replaceInProgram && workoutInProgramToUpdate) {
      const updatedWorkoutExercises = workoutInProgramToUpdate[payload.workoutPart].map((exercise) => {
        return exercise.id === payload.exerciseToReplace.id ? { ...exercise, ...updated } : exercise;
      });
      const { ...rest } = workoutInProgramToUpdate;
      const updatedWorkout: ProgramWorkout = {
        ...rest,
        [payload.workoutPart]: updatedWorkoutExercises,
        updatedAt: new Date().toISOString(),
      };
      await dispatch(
        traineeProgramActions.updateWorkoutInProgram({
          programId: programDetails.programId,
          workout: updatedWorkout,
        }),
      );
    }

    analytics.track("update_exercise_to_alternative", { from: payload.exerciseToReplace.id, to: payload.exercise.id });
  },
);
