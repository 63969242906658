import React from "react";
import { Alert, Badge } from "antd";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

interface ProductClientStatusBadgeProps {
  status: ProductClient["status"];
  banner?: boolean;
  extraAction?: React.ReactElement;
}

const statusMapper = {
  active: {
    text: "Aktywny",
    status: "success",
  },
  archived: {
    text: "Zarchiwizowany",
    status: "error",
  },
} as const;

const ProductClientStatusBadge = ({
  status,
  banner,
  extraAction,
}: ProductClientStatusBadgeProps): React.ReactElement => {
  const config = statusMapper[status] || { text: "Nieaktywny", status: "warning" };
  if (banner) {
    return (
      <Alert
        banner
        className="flex justify-center"
        message={
          <span>
            {config.text} {extraAction}
          </span>
        }
        type={config.status}
      />
    );
  }
  return <Badge status={config.status} text={config.text} className="whitespace-nowrap" />;
};

export default ProductClientStatusBadge;
