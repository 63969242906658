import mapValues from "lodash.mapvalues";
import round from "lodash.round";
import { v4 as uuid } from "uuid";

import { type Ingredient, type IngredientMeasure, type Nutrients } from "@fitness-app/data-models/entities/Ingredient";

import { type AddIngredientFormModel } from "~/modules/Nutrition/AddIngredientForm/types";

export const calculateNutrientsToDefault = (nutrients: Nutrients, packageSize = 100): Nutrients =>
  mapValues(nutrients, (value) =>
    typeof value === "number" ? round((value / packageSize) * 100, 1) : null,
  ) as Nutrients;

export function transformToProductTypesArray(booleanObject: Ingredient): AddIngredientFormModel["productTypes"] {
  const productTypes: AddIngredientFormModel["productTypes"] = [];

  if (booleanObject.isDairyFree) productTypes.push("isDiaryFree");
  if (booleanObject.isGlutenFree) productTypes.push("isGlutenFree");
  if (booleanObject.isHighProtein) productTypes.push("isHighProtein");
  if (booleanObject.isVegan) productTypes.push("isVegan");
  if (booleanObject.isVegetarian) productTypes.push("isVegetarian");

  return productTypes;
}

export const transformUpdateModelToEntity = (
  model: AddIngredientFormModel,
  measuresMap: Record<string, IngredientMeasure>,
  ingredient: Ingredient,
): Ingredient => {
  const nutrients = calculateNutrientsToDefault({ ...model.nutrients, calories: model.calories }, 100);

  const measures: Ingredient["measures"] = model.measures.map((measure) => {
    const { value, weightPerUnit, unit } = measure;
    const measureFromMap = measuresMap[value];

    return {
      weightPerUnit,
      unit,
      id: measureFromMap?.id || 0,
      name: measureFromMap?.name || "",
      energyPerUnit: round(measure.weightPerUnit * (nutrients.calories / 100)),
    };
  });

  const defaultMeasure = ingredient.measures.find((measure) => measure.id === 1);

  if (defaultMeasure && !measures.find((measure) => measure.id === 1)) {
    measures.unshift(defaultMeasure);
  }

  return {
    ...ingredient,
    nutrients: {
      ...nutrients,
      digestibleCarbs: nutrients.fiber ? nutrients.carbohydrates - nutrients.fiber : nutrients.carbohydrates,
      carbohydrateExchangers: round(nutrients.carbohydrates / 10, 1),
    },
    measures,
    calories: nutrients.calories,
    isDairyFree: model.productTypes.includes("isDiaryFree"),
    isGlutenFree: model.productTypes.includes("isGlutenFree"),
    isHighProtein: model.productTypes.includes("isHighProtein"),
    isVegan: model.productTypes.includes("isVegan"),
    isVegetarian: model.productTypes.includes("isVegetarian"),
    mainCategory: model.mainCategory,
    rawIngredients: model.rawIngredients,
    name: model.name,
    brand: model.brand,
    manufacturer: model.manufacturer,
    barCode: model.barCode,
    image: model.image,
    verified: true,
    updatedAt: new Date().toISOString(),
    rating: 3,
  };
};

export const transformModelToEntity = (
  model: AddIngredientFormModel,
  measuresMap: Record<string, IngredientMeasure>,
  userId?: string,
): Ingredient => {
  const { nutritionPer, caloriesUnit, productTypes, ...rest } = model;
  const nutrients = calculateNutrientsToDefault(
    { ...model.nutrients, calories: model.calories },
    nutritionPer === "piece" ? model.measures[0]?.weightPerUnit ?? 100 : 100,
  );

  return {
    ...rest,
    category: null,
    createdBy: userId || null,
    groups: [],
    nutrients: {
      ...nutrients,
      digestibleCarbs: nutrients.fiber ? nutrients.carbohydrates - nutrients.fiber : nutrients.carbohydrates,
      carbohydrateExchangers: round(nutrients.carbohydrates / 10, 1),
    },
    calories: nutrients.calories,
    packageSize: model.measures[0]?.weightPerUnit ?? null,
    isDairyFree: model.productTypes.includes("isDiaryFree"),
    isGlutenFree: model.productTypes.includes("isGlutenFree"),
    isHighProtein: model.productTypes.includes("isHighProtein"),
    isSuperFood: false,
    isVegan: model.productTypes.includes("isVegan"),
    isVegetarian: model.productTypes.includes("isVegetarian"),
    mainCategory: model.mainCategory,
    measures: model.measures.map((measure) => {
      const { value, weightPerUnit, unit } = measure;
      const measureFromMap = measuresMap[value];

      return {
        weightPerUnit,
        unit,
        id: measureFromMap?.id || 0,
        name: measureFromMap?.name || "",
        energyPerUnit: round(measure.weightPerUnit * (nutrients.calories / 100)),
      };
    }),
    price: null,
    rating: 3,
    rawIngredients: model.rawIngredients,
    source: "owner_app",
    tags: [],
    type: "ingredient",
    unit: caloriesUnit === "grams" ? "gram" : "ml",
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
    verified: false,
    id: uuid(),
  };
};
