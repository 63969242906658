import { useState } from "react";
import { Form } from "antd";

import { type ScheduledEmailIntegrationAutomation } from "@fitness-app/data-models/entities/AutomationTemplate";

import { type ProgramAutomationTaskFormModel } from "~/modules/Automation/ProgramAutomation/components/ProgramAutomationTaskForm/types";

export const useProgramAutomationTaskForm = () => {
  const [visible, toggleVisibility] = useState(false);
  const [programAutomationTaskFormController] = Form.useForm<ProgramAutomationTaskFormModel>();
  const [editedTask, setTask] = useState<null | ProgramAutomationTaskFormModel>(null);

  const closeModal = () => {
    toggleVisibility(false);
    setTask(null);
  };

  const openModal = (task?: ScheduledEmailIntegrationAutomation) => {
    if (task) {
      setTask(task as unknown as ProgramAutomationTaskFormModel);
    }
    toggleVisibility(true);
  };

  return {
    visible,
    closeModal,
    openModal,
    editedTask,
    programAutomationTaskFormController,
  };
};
