import { ProductIntegrationClient, SubscriberIntegrationClient } from "@fitness-app/data-models/entities/Automation";
import { IntegrationClient } from "@fitness-app/data-models/entities/ProductsSettings";

import discordIcon from "./assets/Discord-Logo-Color.svg";
import integromat from "./assets/integromat.svg";
import notionIcon from "./assets/notion-logo-1.svg";
import SlackIcon from "./assets/slack.svg";
import zapierIcon from "./assets/zapier.svg";

interface ClientIntegrationOption {
  value: SubscriberIntegrationClient;
  disabled?: boolean;
  icon?: string;
}

interface ProductIntegrationOption {
  value: ProductIntegrationClient;
  disabled?: boolean;
  icon?: string;
  size?: number;
}

interface IntegrationOption {
  value: IntegrationClient;
  disabled?: boolean;
  icon?: string;
}

export const integrationOptions: IntegrationOption[] = [
  {
    value: IntegrationClient.Notion,
    icon: notionIcon,
  },
  {
    value: IntegrationClient.Zapier,
    disabled: true,
    icon: zapierIcon,
  },
  {
    value: IntegrationClient.Integromat,
    disabled: true,
    icon: integromat,
  },
];

export const clientIntegrationOptions: ClientIntegrationOption[] = [
  {
    value: SubscriberIntegrationClient.Discord,
    icon: discordIcon,
  },
];

export const productIntegrationOptions: ProductIntegrationOption[] = [
  {
    value: ProductIntegrationClient.Discord,
    icon: discordIcon,
    size: 22,
  },
  {
    value: ProductIntegrationClient.Slack,
    icon: SlackIcon,
    disabled: true,
    size: 22,
  },
];
