import { userSelectors } from "@fitness-app/app-store";
import { UserRole } from "@fitness-app/data-models";

import { useAppSelector } from "~/store/initializeStore";

export const useUserClaims = () => {
  const { trainerId, memberRole, memberId, isRegularTrainer, isSharedTrainer, role } = useAppSelector(
    userSelectors.getUserClaims,
  );

  return {
    trainerId,
    memberRole,
    memberId,
    isRegularTrainer,
    isSharedTrainer,
    isOwner: role === UserRole.TRAINER,
  };
};
