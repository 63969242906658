import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";

import { type WorkoutActivityEvent } from "@fitness-app/data-models/entities/ProgramActivity";
import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { updateActivity, updateClientCurrentWorkout } from "../../traineeActivities/reducer";
import { LIVE_WORKOUT_REDUCER_NAME } from "../types";

type Payload = { workout: ProgramWorkout };

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const saveLiveWorkoutToSpecifiedDay = createAsyncThunk<
  WorkoutActivityEvent | null,
  Payload,
  AsyncThunkCreator<string>
>(
  `${LIVE_WORKOUT_REDUCER_NAME}/saveLiveWorkoutToSpecifiedDay`,
  async (payload, { getState, dispatch, rejectWithValue, extra: { db, errorTrackingService, analytics } }) => {
    const { activity, workoutDate } = getState().liveWorkout;
    const { currentDay } = getState().traineeActivities.data;

    if (!activity) {
      throw new Error("activity is not defined");
    }

    const { data, error } = await db
      .from("client_workout_activity")
      .update({
        updatedAt: new Date().toISOString(),
        data: {
          ...payload.workout,
          // @ts-expect-error ignore
          exercises: payload.workout.exercises.map(({ series, workoutPart, type, ...exercise }) => exercise),
          // @ts-expect-error ignore
          preWorkout: payload.workout.preWorkout.map(({ series, workoutPart, type, ...exercise }) => exercise),
          // @ts-expect-error ignore
          postWorkout: payload.workout.postWorkout.map(({ series, workoutPart, type, ...exercise }) => exercise),
          updatedAt: new Date().toISOString(),
        },
      })
      .eq("id", activity.id)
      .select("*")
      .single<WorkoutActivityEvent>();

    if (error) {
      errorTrackingService?.recordError(error, "saveLiveWorkoutToSpecifiedDay");
      return rejectWithValue(error.message);
    }

    analytics.track("update_live_workout_activity");

    if (data) {
      dispatch(updateActivity(data));

      if (workoutDate && workoutDate === currentDay) {
        dispatch(updateClientCurrentWorkout(data.data));
      }
    }

    return data;
  },
);
