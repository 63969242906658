import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  traineeId: string;
};

export const deleteTraineeSurvey = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/deleteTraineeSurvey`,
  async ({ traineeId, id }, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("client_survey").delete().match({ id, traineeId });

    if (error) {
      return rejectWithValue(error.message);
    }
  },
);
