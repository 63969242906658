import { type Product } from "@fitness-app/data-models/entities/Product";
import { type ProductStats } from "@fitness-app/data-models/entities/ProductStats";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PRODUCTS_REDUCER_NAME = "Products";

export type ProductWithStats = Product & {
  product_stats?: Pick<ProductStats, "totalClients" | "activeClients" | "archivedClients">;
};

export interface ProductsReducer {
  productsInfo: null;
  productsInfoStatus: null;
  productsListStatus: null | RequestStatus;
  productsListData: ProductWithStats[];
  addingNewProduct: boolean;
  productsError: null | string;
}
