import { createAsyncThunk } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";

import {
  type TrainingProgramDetails,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import splitSuperSet from "@fitness-app/utils/src/programs/superSetHelpers/splitSuperSet";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentProgram } from "../selectors/getCurrentProgram";
import { PROGRAM_BUILDER_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutId: string;
  exercisesToSplit: string[];
  exercisesType: WorkoutExercisesType;
};

export const splitExercisesFromSuperSet = createAsyncThunk<TrainingProgramDetails, Payload, AsyncThunkCreator<string>>(
  `${PROGRAM_BUILDER_REDUCER_NAME}/splitExercisesFromSuperSet`,
  async (
    { programId, workoutId, exercisesType, exercisesToSplit },
    { dispatch, rejectWithValue, getState, extra: { db, analytics } },
  ) => {
    const currentProgram = getCurrentProgram(getState());

    const updatedWorkouts = cloneDeep(currentProgram.details.workouts);
    const workout = updatedWorkouts.find((workout) => workout.id);

    if (!workout) {
      return rejectWithValue("No workout to update");
    }

    const updatedExercises = splitSuperSet({
      exercisesToSplit,
      exercises: workout[exercisesType] || [],
      fromArray: true,
      toArray: true,
    });

    const workouts = updatedWorkouts.map((workout) => {
      if (workout.id === workoutId) {
        return {
          ...workout,
          [exercisesType]: updatedExercises,
        };
      }
      return workout;
    });

    dispatch(updateWorkoutsInProgram({ workouts, programId }));

    const { data: programDetails, error } = await db
      .from("training_program_details")
      .update({
        workouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<TrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("split_exercises_from_super_set");

    return programDetails;
  },
);
