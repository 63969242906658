import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CopyOutlined, DatabaseOutlined, DownOutlined, RedoOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Modal, Tabs, type MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { RequestStatus, workoutTemplatesActions } from "@fitness-app/app-store";
import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplateWithCreator } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { duplicateWorkout } from "@fitness-app/utils/src/programs/workoutsTemplate";

import ModalForm from "~/components/ModalForm/ModalForm";
import WorkoutDay from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/WorkoutDay";
import AddWorkoutTemplateForm from "~/modules/TrainingPrograms/ProgramDayTemplates/AddWorkoutTemplateForm";
import ProgramDayTemplates from "~/modules/TrainingPrograms/ProgramDayTemplates/ProgramDayTemplates";
import { type AddWorkoutTemplateFormModel } from "~/modules/TrainingPrograms/ProgramDayTemplates/types";
import { useProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";
import { useAppDispatch } from "~/store/initializeStore";

const { confirm } = Modal;

const ProgramBuilder = () => {
  const { t } = useTranslation(["workouts", "common"]);
  const {
    programStatus,
    programDetails,
    programId,
    selectedProgram,
    fetchProgramWithDetails,
    duplicateWorkoutInProgram,
    addNewWorkoutToProgram,
    removeWorkoutFromProgram,
    replaceWorkoutInProgram,
  } = useProgramBuilderContext();
  const dispatch = useAppDispatch();
  const [activeWorkout, setActiveWorkout] = useState<null | string>(null);
  const [selectionType, setSelectionType] = useState<null | "checkbox" | "radio">(null);
  const [showWorkoutTemplatesModal, toggleWorkoutTemplatesModal] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<WorkoutTemplateWithCreator[]>([]);
  const [showTemplateForm, toggleTemplateForm] = useState(false);

  useEffect(() => {
    void fetchProgramWithDetails();
  }, [programId]);

  const programWorkouts = useMemo(() => programDetails?.workouts || [], [programDetails?.workouts]);

  const copyWorkout = useCallback((workout: ProgramWorkout) => {
    const duplicatedWorkout = duplicateWorkout(workout);
    void duplicateWorkoutInProgram({
      workout: duplicatedWorkout,
    });
    setActiveWorkout(duplicatedWorkout.id);
  }, []);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        icon: <DatabaseOutlined />,
        onClick: () => {
          setSelectionType("checkbox");
          toggleWorkoutTemplatesModal(true);
        },
        label: t("workoutMenu.addFormTemplate"),
      },
      {
        key: "2",
        icon: <RedoOutlined />,
        onClick: () => {
          setSelectionType("radio");
          toggleWorkoutTemplatesModal(true);
        },
        label: t("workoutMenu.replaceFromTemplate"),
      },
      {
        key: "3",
        icon: <SaveOutlined />,
        onClick: () => toggleTemplateForm(true),
        label: t("workoutMenu.saveAsTemplate"),
      },
      ...programWorkouts?.map((workout) => ({
        key: workout.id,
        onClick: () => copyWorkout(workout),
        label: t("workoutMenu.copyTemplate", { name: workout.name }),
        icon: <CopyOutlined />,
      })),
    ],
    [copyWorkout, programWorkouts, t],
  );

  const onTabsEdit = async (e: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
    if (action === "add" && programId) {
      const newWorkoutId = uuid();
      void addNewWorkoutToProgram({
        workoutId: newWorkoutId,
      });
      setActiveWorkout(newWorkoutId);
      return;
    }

    if (action === "remove" && typeof e === "string" && programId) {
      const targetKey = e;
      const removedIndex = programWorkouts.findIndex((workout) => workout.id === targetKey);
      const nextActiveWorkout = removedIndex === 0 ? programWorkouts[1] : programWorkouts[removedIndex - 1];

      if (programWorkouts[removedIndex]?.exercises.length) {
        confirm({
          title: "Czy na pewno chcesz usunąć trening?",
          content: "Odzyskanie dodanych ćwiczeń będzie niemożliwe.",
          onOk: async () => {
            const response = await removeWorkoutFromProgram({
              workoutId: targetKey,
            });
            if (response !== false) {
              setActiveWorkout(nextActiveWorkout?.id || null);
            }
          },
          cancelText: "Anuluj",
          okText: "Potwierdź",
        });
      } else {
        const response = await removeWorkoutFromProgram({
          workoutId: targetKey,
        });
        if (response !== false) {
          setActiveWorkout(nextActiveWorkout?.id || null);
        }
      }
    }
  };

  const onAddWorkoutFromTemplates = () => {
    if (selectionType === "checkbox" && programId) {
      void addNewWorkoutToProgram({
        workoutTemplates: selectedTemplates,
      });
    } else if (selectedTemplates[0] && activeWorkout && programId) {
      void replaceWorkoutInProgram({
        workoutIdToReplace: activeWorkout,
        workoutTemplate: selectedTemplates[0],
      });
    }

    toggleWorkoutTemplatesModal(false);
    setSelectionType(null);
    setSelectedTemplates([]);
  };

  const activeWorkoutData = useMemo(
    () => programWorkouts.find((workout) => workout.id === activeWorkout || programWorkouts[0]?.id),
    [activeWorkout, programWorkouts],
  );

  const saveWorkoutAsTemplate = (formData: AddWorkoutTemplateFormModel, workout: ProgramWorkout | undefined) => {
    void dispatch(
      workoutTemplatesActions.addWorkoutTemplate({
        template: formData,
        workout,
      }),
    );
    toggleTemplateForm(false);
  };

  return (
    <div>
      <Card loading={programStatus === RequestStatus.FETCHING} title={selectedProgram?.name} className="min-h-[600px]">
        <Tabs
          type="editable-card"
          onEdit={onTabsEdit}
          activeKey={activeWorkout ?? undefined}
          defaultActiveKey={programWorkouts[0]?.id}
          onChange={(id) => setActiveWorkout(id)}
          tabBarExtraContent={
            <Dropdown menu={{ items }}>
              <Button size="small" style={{ marginBottom: 10 }}>
                {t("common:button.options")} <DownOutlined />
              </Button>
            </Dropdown>
          }
        >
          {programWorkouts.map((workout) => (
            <Tabs.TabPane key={workout.id} tab={workout.name} closable={programWorkouts.length > 1}>
              <WorkoutDay workout={workout} programId={programId || null} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Card>
      <Modal
        bodyStyle={{
          padding: 0,
        }}
        cancelText="Anuluj"
        okText={selectionType === "checkbox" ? "Wybierz" : "Zastąp"}
        width={950}
        open={showWorkoutTemplatesModal}
        onCancel={() => {
          toggleWorkoutTemplatesModal(false);
          setSelectionType(null);
        }}
        destroyOnClose
        okButtonProps={{
          disabled: !selectedTemplates?.length,
        }}
        onOk={onAddWorkoutFromTemplates}
      >
        <ProgramDayTemplates
          selectMode
          selectionType={selectionType || undefined}
          onRowSelect={(selectedTemplates) => setSelectedTemplates(selectedTemplates)}
        />
      </Modal>
      <ModalForm
        open={showTemplateForm}
        onCancel={() => toggleTemplateForm(false)}
        okText="Dodaj"
        title="Dodaj trening do szablonów"
      >
        <AddWorkoutTemplateForm
          onSubmit={(formData) => saveWorkoutAsTemplate(formData, activeWorkoutData)}
          model={
            activeWorkoutData
              ? {
                  name: activeWorkoutData.name,
                  description: activeWorkoutData.description,
                }
              : undefined
          }
        />
      </ModalForm>
    </div>
  );
};

export default ProgramBuilder;
