import { useState } from "react";

import { traineeMeasurementsActions } from "@fitness-app/app-store";

import { useLoading } from "~/hooks/useLoading";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { type WeightMeasurementFormModel } from "./types";

export const useWeightMeasurementForm = () => {
  const [addingWeightMeasurement, startAddingWeightMeasurement, finishAddingWeightMeasurement] = useLoading();
  const dispatch = useAppDispatch();
  const [showWeightModal, toggleWeightModal] = useState(false);
  const profile = useAppSelector((state) => state.trainee.profile);

  const onAddNewWeightMeasurement = async (formModel: WeightMeasurementFormModel, traineeId: string) => {
    startAddingWeightMeasurement();

    try {
      await dispatch(
        traineeMeasurementsActions.upsertMeasurement({
          data: {
            weight: formModel.weight,
          },
          eventDate: formModel.date.format("YYYY-MM-DD"),
          id: formModel.id,
          traineeId,
          userId: profile?.userId,
          previousMeasurement: formModel.previousMeasurement,
        }),
      ).unwrap();
      finishAddingWeightMeasurement();
      toggleWeightModal(false);
    } catch {
      finishAddingWeightMeasurement();
    }
  };

  return {
    showWeightModal,
    toggleWeightModal,
    addingWeightMeasurement,
    onAddNewWeightMeasurement,
  };
};
