import { type ProductAutomationLog } from "@fitness-app/data-models/entities/ProductAutomationLog";

import { type RequestStatus } from "../../../enums/requestStatus";

export const AUTOMATION_LOGS_REDUCER_NAME = "automationLogs";
export interface AutomationLogsReducer {
  clientAutomationLogs: ProductAutomationLog[];
  clientAutomationLogsStatus: RequestStatus | null;
  selectedClient: null | { email: string; productId: string | null };
}
