import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductAutomationLog } from "@fitness-app/data-models/entities/ProductAutomationLog";

import { type AsyncThunkCreator } from "../../../index";
import { AUTOMATION_LOGS_REDUCER_NAME } from "../types";

type Payload = {
  clientEmail: string;
  productId: string;
};

export const fetchClientLogs = createAsyncThunk<ProductAutomationLog[], Payload, AsyncThunkCreator<string>>(
  `${AUTOMATION_LOGS_REDUCER_NAME}/fetchClientLogs`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("automation_log")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("clientEmail", payload.clientEmail)
      .returns<ProductAutomationLog[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
