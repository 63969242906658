import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientTrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { addFromTemplate, createProgramWorkoutTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentTraineeProgram } from "../selectors/getCurrentProgram";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutId?: string;
  workoutTemplates?: WorkoutTemplate[];
};

export const addNewWorkoutToProgram = createAsyncThunk<
  ClientTrainingProgramDetails,
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/addNewWorkoutToProgram`,
  async ({ programId, workoutId, workoutTemplates }, { rejectWithValue, getState, dispatch, extra: { db } }) => {
    const currentProgram = getCurrentTraineeProgram(getState());

    const newWorkout = workoutTemplates
      ? workoutTemplates.map(addFromTemplate)
      : [createProgramWorkoutTemplate(currentProgram.details.workouts.length, { id: workoutId })];

    const updatedWorkouts = [...currentProgram.details.workouts, ...newWorkout];

    dispatch(updateWorkoutsInProgram({ workouts: updatedWorkouts, programId }));

    const { data: programDetails, error } = await db
      .from("client_training_program_details")
      .update({
        workouts: updatedWorkouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<ClientTrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return programDetails;
  },
);
