import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import { type ChatMessage } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";
import { markAllAsRead } from "./markAllAsRead";
import { markChatMessagesAsRead } from "./markChatMessagesAsRead";
import { subscribeToChannelMessage } from "./subscribeToChannelMessage";
import { subscribeToChannelMessageMobile } from "./subscribeToChannelMessageMobile";

type Payload = {
  channelId: string;
  mobile?: boolean;
  withoutLoader?: boolean;
};

export const getInitialChannelMessages = createAsyncThunk<ChatMessage[], Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/getInitialChannelMessages`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, auth } }) => {
    const { messagesBatchSize, unreadMessages } = getState().chat;
    const unreadMessagesNumber = unreadMessages?.perChannel[payload.channelId] || 0;

    const { customClaims } = await getLoggedUser(auth);

    const batchSize =
      typeof unreadMessages === "number" || !unreadMessagesNumber
        ? messagesBatchSize
        : unreadMessagesNumber.length > messagesBatchSize
        ? unreadMessagesNumber.length
        : messagesBatchSize;

    const { error, data } = await db
      .from("chat_message")
      .select("*, metadata:chat_message_metadata(transcription, summary, context, sentiment)")
      .order("createdAt", { ascending: false })
      .eq("deleted", false)
      .eq("channelId", payload.channelId)
      .limit(batchSize)
      .returns<ChatMessage[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    if (payload.mobile) {
      dispatch(subscribeToChannelMessageMobile(payload.channelId));
    } else {
      dispatch(subscribeToChannelMessage(payload.channelId));
    }

    if (customClaims.role === UserRole.CLIENT) {
      void dispatch(markAllAsRead());
    } else {
      void dispatch(
        markChatMessagesAsRead({
          channelId: payload.channelId,
          messages: data.map((item) => ({ id: item.id, createdAt: item.createdAt })),
        }),
      );
    }

    return data;
  },
  {
    condition: ({ channelId }, { getState }) => {
      const channelStatus = getState().chat.channelsStatus[channelId];

      if (channelStatus?.fetchingFirstPage || channelStatus?.fetchingNextPage) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);
