import React from "react";
import { Form, Input, InputNumber, Select, Slider, Space } from "antd";
import { useTranslation } from "react-i18next";

import { getFitnessValueFromField } from "@fitness-app/utils/src/programs/getFitnessValueFromField";

import FitnessFieldTooltip from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/FitnessFieldTooltip";
import {
  durationFormatOptions,
  numberOfSeriesOptions,
  rirScale,
} from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/types";

interface PowerExerciseFormProps {
  isSuperSet?: boolean;
  showDurationField?: boolean;
  validateDurationField?: boolean;
}

const tempoDesc = (
  <div>
    <div>Określa tempo jednego potwórzenia np. 4010</div>
    <div>4 – określa fazę ekscentryczną</div>
    <div>1 – określa fazę koncentryczną</div>
    <div>0 – określa czas pomiędzy jedną fazą i drugą</div>
  </div>
);

const PowerExerciseForm = ({
  isSuperSet,
  showDurationField = true,
  validateDurationField = true,
}: PowerExerciseFormProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  return (
    <>
      <Form.Item label={t("exerciseInProgram.numberOfSeries")} name="numberOfSeries">
        <Select options={numberOfSeriesOptions} disabled={isSuperSet} defaultValue={1} style={{ maxWidth: 100 }} />
      </Form.Item>

      <Form.Item
        label={t("exerciseInProgram.numberOfRepeats")}
        name="numberOfRepeats"
        tooltip={<FitnessFieldTooltip />}
        rules={[
          {
            validator: (_, value: string) => {
              const validatedValue = getFitnessValueFromField(value);
              return validatedValue.type === "error"
                ? Promise.reject(new Error(t<string>("common:validationErrors.invalidDataFormat")))
                : Promise.resolve();
            },
          },
        ]}
      >
        <Input style={{ width: "60%" }} addonAfter={t("repeatsAbv")} />
      </Form.Item>

      <Form.Item
        label={t("exerciseInProgram.weight")}
        name="weight"
        tooltip={<FitnessFieldTooltip bigValue unit="kg" />}
        rules={[
          {
            validator: (_, value: string) => {
              const validatedValue = getFitnessValueFromField(value);
              return validatedValue.type === "error"
                ? Promise.reject(new Error(t<string>("common:validationErrors.invalidDataFormat")))
                : Promise.resolve();
            },
          },
        ]}
      >
        <Input style={{ width: "60%" }} addonAfter="kg" />
      </Form.Item>

      <Form.Item
        label={t("exerciseInProgram.restTime")}
        name="restTime"
        rules={[{ required: true, type: "number", message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <InputNumber
          style={{ width: "50%" }}
          defaultValue={60}
          min={1}
          max={1000}
          addonAfter={t("duration.secondsAbv")}
        />
      </Form.Item>

      {showDurationField && (
        <Form.Item
          label={t("exerciseInProgram.duration")}
          tooltip={<FitnessFieldTooltip bigValue unit={t<string>("duration.minutesAbv")} />}
        >
          <Space.Compact>
            <Form.Item
              name={["duration", "value"]}
              noStyle
              rules={[
                validateDurationField
                  ? {
                      validator: (_, value: string) => {
                        const validatedValue = getFitnessValueFromField(value);
                        return validatedValue.type === "error"
                          ? Promise.reject(new Error(t<string>("common:validationErrors.invalidDataFormat")))
                          : Promise.resolve();
                      },
                    }
                  : {},
              ]}
            >
              <Input style={{ width: 100 }} />
            </Form.Item>
            <Form.Item
              name={["duration", "format"]}
              noStyle
              rules={[{ required: false, message: t<string>("common:validationErrors.fieldIsRequired") }]}
            >
              <Select
                options={durationFormatOptions}
                style={{ width: 100 }}
                placeholder={t("exerciseInProgram.selectFormat")}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      )}

      <Form.Item label={t("exerciseInProgram.tempo")} name="tempo" tooltip={tempoDesc}>
        <Input style={{ width: "50%" }} />
      </Form.Item>

      <Form.Item label={t("exerciseInProgram.rir")} name="rir">
        <Slider
          step={null}
          tooltip={{
            formatter: (value) => t(`rir.${value}`),
          }}
          defaultValue={-1}
          marks={rirScale}
          max={10}
          min={-1}
        />
      </Form.Item>
    </>
  );
};

export default PowerExerciseForm;
