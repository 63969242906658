import { type Database } from "@fitness-app/supabase";

import { type BunnyVideoObject } from "../external/Bunny";

export type MediaLibraryEntity = Database["public"]["Tables"]["media_library"]["Row"];

export enum VideoUploadStatus {
  Pending = "pending",
  Queued = "queued",
  Processing = "processing",
  Encoding = "encoding",
  Ready = "ready",
  Failed = "failed",
}

export enum MediaLibraryType {
  Video = "video",
}

export enum MediaLibraryResourceType {
  Marketing = "marketing",
  Exercise = "exercise",
}

export interface MediaLibraryVideoData extends BunnyVideoObject {
  uploadStatus: VideoUploadStatus;
  type: "bunny";
  thumbnails: string[];
  authorizationSignature: string;
  authorizationExpire: number;
  dashUrl: string | null;
  hlsUrl: string | null;
  mp4Url: string | null;
  thumbnailUrl: string | null;
  previewUrl: string | null;
}

export interface MediaLibraryVideo extends Omit<MediaLibraryEntity, "data"> {
  type: MediaLibraryType.Video;
  resourceType: MediaLibraryResourceType;
  data: MediaLibraryVideoData;
}

export type MediaLibraryItem = MediaLibraryVideo;
export type MediaLibraryItemWithLinkedExercise = MediaLibraryItem & { exercises: { id: string; name: string }[] };
