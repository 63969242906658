import React, { useEffect } from "react";
import { AutoComplete, Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { DEFAULT_LIST_OF_SUPPLEMENTS } from "./listOfSupplements";
import { type NutritionSupplementFormModel } from "./types";

interface NutritionSupplementFormProps {
  formController?: FormInstance<NutritionSupplementFormModel>;
  onSubmit: (formData: NutritionSupplementFormModel) => void;
  model?: Partial<NutritionSupplementFormModel> | null;
}

const NutritionSupplementForm = ({ model, onSubmit, formController }: NutritionSupplementFormProps) => {
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <Form<NutritionSupplementFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        url: null,
        dose: "",
        name: "",
        comment: "",
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="name"
        label="Nazwa"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <AutoComplete autoFocus options={DEFAULT_LIST_OF_SUPPLEMENTS} />
      </Form.Item>

      <Form.Item
        name="dose"
        label="Dawkowanie"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item name="comment" label="Komentarz">
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item
        name="url"
        label="Link do sklepu"
        rules={[{ type: "url", message: t<string>("common:validationErrors.wrongUrl") }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default NutritionSupplementForm;
