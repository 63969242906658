import React, { type FunctionComponent } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { userActions } from "@fitness-app/app-store";

import AvatarUploadField from "~/components/AvatarUploadField/AvatarUploadField";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

type Props = OwnProps;

const ProfileDrawer: FunctionComponent<Props> = ({ toggleDrawer, isOpen }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const { profile } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();

  const logoutUser = () => {
    dispatch(userActions.logOutUser());
  };

  const onAvatarDelete = () => {
    dispatch(userActions.updateUserProfile({ avatarUrl: "" }));
  };

  const onAvatarUpdate = (urls: (string | undefined)[]) => {
    dispatch(userActions.updateUserProfile({ avatarUrl: urls[0] || "" }));
  };

  return (
    <Drawer
      title={t("profileDrawer.title")}
      placement="right"
      onClose={toggleDrawer}
      open={isOpen}
      width={400}
      bodyStyle={{ padding: 24, height: "auto" }}
      className="max-w-full"
    >
      <div className="my-4 flex flex-wrap gap-8">
        <div>
          <Badge dot status="success">
            <AvatarUploadField
              storageRef={`${profile?.id}`}
              fileName="userAvatar"
              onChange={onAvatarUpdate}
              withCrop
              multiple={false}
              icon={UserOutlined}
              fileList={profile?.avatarUrl ? [profile.avatarUrl] : []}
              onDelete={onAvatarDelete}
              className="upload-avatar"
            />
          </Badge>
        </div>
        <div className="flex flex-col gap-3">
          <h4>
            {profile?.firstName || ""} {profile?.lastName || ""}
          </h4>
          <p className="truncate">{profile?.email}</p>
          <Link to="/user/set-password" onClick={toggleDrawer}>
            Zmień hasło
          </Link>
          <Button onClick={logoutUser} danger size="small">
            {t("common:button.logout")}
          </Button>
        </div>
      </div>

      <div></div>
    </Drawer>
  );
};

export default ProfileDrawer;
