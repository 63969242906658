import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";
import { createWorkoutTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { WORKOUT_TEMPLATES_REDUCER_NAME } from "../types";
import { fetchWorkoutTemplates } from "./fetchWorkoutTemplates";

type Payload = {
  template: { name: string; description: string; tags: string[] };
  workout?: ProgramWorkout | undefined;
};

export const addWorkoutTemplate = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${WORKOUT_TEMPLATES_REDUCER_NAME}/addWorkoutTemplate`,
  async ({ template, workout }, { dispatch, rejectWithValue, extra: { db, auth, analytics } }) => {
    const loggedUser = await getLoggedUser(auth);

    const { error } = await db.from("workout_template").insert({
      ...createWorkoutTemplate({ ...template, createdBy: loggedUser.id }),
      exercises: workout?.exercises || [],
      preWorkout: workout?.preWorkout || [],
      postWorkout: workout?.postWorkout || [],
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_workout_template");

    void dispatch(fetchWorkoutTemplates());
  },
);
