import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientMealsPlanDetails } from "@fitness-app/data-models/entities/ClientNutrition";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal } from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { updateDaysInMealsPlan } from "../reducer";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  dayId: string;
  mealId: string;
  product: DishInMeal | IngredientWithPortion;
};

export const updateProductInMealsPlan = createAsyncThunk<ClientMealsPlanDetails, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/updateProductInMealsPlan`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db } }) => {
    const { traineeMealsPlan } = getState();

    if (!traineeMealsPlan.selectedMealsPlanDetails || traineeMealsPlan.selectedMealsPlanDetails.length === 0) {
      return rejectWithValue("No meals plan details");
    }

    const [plan] = traineeMealsPlan.selectedMealsPlanDetails;

    if (!plan) {
      return rejectWithValue("No meals plan");
    }

    const updatedDays = plan.weeks[0]?.days.map((day) =>
      day.id === payload.dayId
        ? {
            ...day,
            meals: day.meals.map((meal) =>
              meal.id === payload.mealId
                ? {
                    ...meal,
                    dishes: meal.dishes.some((dish) => dish.id === payload.product.id)
                      ? meal.dishes.map((dish) =>
                          dish.id !== payload.product.id ? dish : { ...dish, ...payload.product },
                        )
                      : [...meal.dishes, payload.product],
                  }
                : meal,
            ),
          }
        : day,
    );

    dispatch(updateDaysInMealsPlan(updatedDays || []));

    const { error, data } = await db
      .from("client_meals_plan_details")
      .update({
        weeks: [
          {
            weekNumber: 1,
            days: updatedDays || [],
          },
        ],
        updatedAt: new Date().toISOString(),
      })
      .eq("id", plan.id)
      .select()
      .returns<ClientMealsPlanDetails[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data[0]!;
  },
);
