import { WORKOUT_EXERCISES_TYPE, type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";

export const replaceExercisesInWorkoutFromTemplate = (
  workout: ProgramWorkout,
  templateWorkout: WorkoutTemplate | ProgramWorkout,
) => {
  return {
    ...workout,
    [WORKOUT_EXERCISES_TYPE.NORMAL]: templateWorkout?.[WORKOUT_EXERCISES_TYPE.NORMAL]
      ? templateWorkout[WORKOUT_EXERCISES_TYPE.NORMAL]
      : [],
    [WORKOUT_EXERCISES_TYPE.PRE_WORKOUT]: templateWorkout?.[WORKOUT_EXERCISES_TYPE.PRE_WORKOUT]
      ? templateWorkout[WORKOUT_EXERCISES_TYPE.PRE_WORKOUT]
      : [],
    [WORKOUT_EXERCISES_TYPE.POST_WORKOUT]: templateWorkout?.[WORKOUT_EXERCISES_TYPE.POST_WORKOUT]
      ? templateWorkout[WORKOUT_EXERCISES_TYPE.POST_WORKOUT]
      : [],
  };
};
