import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { RequestStatus } from "@fitness-app/data-models";
import {
  LiveWorkoutStatus,
  WorkoutSliderType,
  type LiveWorkoutData,
  type Timer,
  type WorkoutSliderItemType,
} from "@fitness-app/data-models/entities/LiveWorkout";
import { type WorkoutActivityEvent } from "@fitness-app/data-models/entities/ProgramActivity";

import { fetchSelectedActivity } from "./actions/fetchSelectedActivity";
import { saveLiveWorkoutToSpecifiedDay } from "./actions/saveLiveWorkoutToSpecifiedDay";
import { LIVE_WORKOUT_REDUCER_NAME, type LiveWorkoutReducer, type StartWorkoutPayload } from "./types";

const initialState: LiveWorkoutReducer = {
  isOpenModal: false,
  status: LiveWorkoutStatus.INACTIVE,
  step: 0,
  totalSteps: null,
  stepType: WorkoutSliderType.StartInfo,
  workoutDate: null,
  workoutId: null,
  stepText: "",
  timer: {
    secondsLeft: 0,
    secondsTotal: 0,
    active: false,
    unit: 15,
  },
  workoutData: {
    totalExercises: 0,
    currentExercise: 0,
    doneExercises: 0,
  },
  liveWorkout: null,
  activity: null,
  activityStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: LIVE_WORKOUT_REDUCER_NAME,
  reducers: {
    startWorkout(state, action: PayloadAction<StartWorkoutPayload>) {
      state.isOpenModal = true;
      state.workoutDate = action.payload.workoutDate;
      state.stepType = action.payload.stepType;
      state.totalSteps = action.payload.totalSteps;
      state.stepText = action.payload.stepText;
      state.status = LiveWorkoutStatus.ACTIVE;
      state.step = action.payload.step ?? 0;
      state.workoutData = {
        ...state.workoutData,
        totalExercises: action.payload.totalExercises,
        currentExercise: action.payload.currentExercise ?? 1,
        doneExercises: action.payload.doneExercises ?? 0,
      };
      state.liveWorkout = action.payload.workout;
    },
    closeLiveWorkoutSheet(state) {
      state.isOpenModal = false;
    },
    openLiveWorkoutSheet(state) {
      state.isOpenModal = true;
    },
    changeStep(
      state,
      action: PayloadAction<{ step: number; stepText?: string | null; stepType: WorkoutSliderItemType }>,
    ) {
      state.step = action.payload.step;
      state.stepText = action.payload.stepText || "";
      state.stepType = action.payload.stepType;
    },
    setTimerStep(state, action: PayloadAction<{ step: number; stepType: WorkoutSliderItemType; restTime: number }>) {
      state.step = action.payload.step;
      state.stepType = action.payload.stepType;
      state.timer = {
        ...state.timer,
        secondsTotal: action.payload.restTime,
        secondsLeft: action.payload.restTime,
      };
    },
    setTimer(state, action: PayloadAction<Timer>) {
      state.timer = {
        ...state.timer,
        ...action.payload,
      };
    },
    timerStart(state) {
      state.timer.active = true;
    },
    timerStop(state) {
      state.timer.active = false;
    },
    timerTick(state, { payload }: PayloadAction<number | null | undefined>) {
      const newSecondsLeft = state.timer.secondsLeft + (payload ?? -1);

      state.timer.secondsLeft = Math.max(0, newSecondsLeft);
    },
    timerReset(state) {
      state.timer = initialState.timer;
    },
    changeTimerDuration(state, { payload }: PayloadAction<number>) {
      const newSecondsLeft = state.timer.secondsLeft + payload;
      const newSecondsTotal = state.timer.secondsTotal + payload;
      state.timer = {
        ...state.timer,
        secondsLeft: Math.max(0, newSecondsLeft),
        secondsTotal: Math.max(0, newSecondsTotal),
      };
    },
    markExerciseAsDone(
      state,
      { payload }: PayloadAction<undefined | { currentExercise: number; doneExercises: number }>,
    ) {
      state.workoutData = {
        ...state.workoutData,
        currentExercise: payload?.currentExercise ?? state.workoutData.currentExercise + 1,
        doneExercises: payload?.doneExercises ?? state.workoutData.doneExercises + 1,
      };
    },
    cancelWorkout() {
      return {
        ...initialState,
      };
    },
    endWorkout() {
      return {
        ...initialState,
      };
    },
    updateLiveWorkoutActivity(state, { payload }: PayloadAction<WorkoutActivityEvent>) {
      if (payload.id === state.activity?.id) {
        state.activity = payload;
      }
    },
    updateLiveWorkout(state, { payload }: PayloadAction<LiveWorkoutData>) {
      state.liveWorkout = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSelectedActivity.pending, (state) => {
      state.activityStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchSelectedActivity.fulfilled, (state, { payload }) => {
      state.activity = payload;
      state.activityStatus = RequestStatus.SUCCESS;
      state.workoutId = payload.workoutId;
      state.workoutDate = payload.eventDate;
    });
    builder.addCase(fetchSelectedActivity.rejected, (state) => {
      state.activityStatus = RequestStatus.FAILED;
    });
    builder.addCase(saveLiveWorkoutToSpecifiedDay.fulfilled, (state, { payload }) => {
      if (state.activity) {
        state.activity = {
          ...state.activity,
          ...payload,
        };
      }
    });
  },
});

export const {
  closeLiveWorkoutSheet,
  startWorkout,
  openLiveWorkoutSheet,
  endWorkout,
  cancelWorkout,
  markExerciseAsDone,
  timerStart,
  setTimerStep,
  changeStep,
  changeTimerDuration,
  timerStop,
  timerTick,
  timerReset,
  updateLiveWorkoutActivity,
  updateLiveWorkout,
  setTimer,
} = reducerSlice.actions;

export default reducerSlice.reducer;
