import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { exercisesActions, teamActions, workoutTemplatesActions } from "@fitness-app/app-store";

import ProgramBuilder from "~/modules/TrainingPrograms/ProgramBuilder/ProgramBuilder";
import TrainingPrograms from "~/modules/TrainingPrograms/TrainingPrograms";
import SharedProgramProvider from "~/shared/providers/SharedProgramProvider";
import { useAppDispatch } from "~/store/initializeStore";

const TrainingProgramsRouter = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(exercisesActions.fetchExercises());
    void dispatch(workoutTemplatesActions.fetchWorkoutTemplates());
    void dispatch(teamActions.fetchTrainerConfig());
  }, [dispatch]);

  return (
    <Routes>
      <Route index element={<TrainingPrograms />} />
      <Route
        path="/plans/:id"
        element={
          <SharedProgramProvider>
            <ProgramBuilder />
          </SharedProgramProvider>
        }
      />
      <Route path="/:tab/*" element={<TrainingPrograms />} />
    </Routes>
  );
};

export default TrainingProgramsRouter;
