import React from "react";
import { SwapOutlined } from "@ant-design/icons";
import { Button, Collapse, Space, Spin, type CollapseProps } from "antd";
import { useTranslation } from "react-i18next";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { type IngredientOnShoppingList } from "@fitness-app/data-models/entities/Ingredient";
import { calculateMeasureMultiplier } from "@fitness-app/utils/src/nutrition/calculateMeasureMultiplier";
import {
  getIngredientInMeals,
  getShoppingList,
  selectAppropriateMeasure,
} from "@fitness-app/utils/src/nutrition/getShoppingList";
import { recalculateMeasures } from "@fitness-app/utils/src/nutrition/recalculateMeasures";
import { cn } from "@fitness-app/utils/src/styles/cn";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useNutritionCategories } from "~/modules/Nutrition/hooks/useNutritionCategories";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import ReplaceIngredientForm from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/components/ReplaceIngredientForm";
import { useReplaceIngredientForm } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/components/useReplaceIngredientForm";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";

interface CategoryListProps {
  ingredients: IngredientOnShoppingList[];
}

const { Panel } = Collapse;

const weekdaysArr = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export const Weekdays = ({ days }: { days: string[] }) => {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-row gap-x-1">
      {weekdaysArr.map((weekday) => (
        <div
          key={weekday}
          className={cn(
            "flex h-9 w-9 items-center justify-center overflow-hidden rounded-lg bg-white shadow-sm",
            days.includes(weekday) ? "bg-green-100" : undefined,
          )}
        >
          {t(`weekdaysShort.${weekday}`)}
        </div>
      ))}
    </div>
  );
};

const CategoryList = ({ ingredients }: CategoryListProps) => {
  const { planDetails } = useMealsPlanContext();
  const { measuresMap } = useNutritionMeasures();
  const { selectIngredientToReplace, showForm, handleCloseForm, handleFormSubmit, selectedIngredient, saving } =
    useReplaceIngredientForm();
  const nutritionPlanDays = planDetails.weeks[0]?.days;

  if (!nutritionPlanDays) {
    return;
  }

  return (
    <>
      <Collapse ghost bordered={false} defaultActiveKey={[]}>
        {ingredients.map((ingredient) => {
          const ingredientDishes = getIngredientInMeals(ingredient, nutritionPlanDays);
          return (
            <Panel
              key={ingredient.ingredientId}
              header={
                <div className="grid grid-cols-3">
                  <div className="space-x-4">
                    {ingredient.name}
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        selectIngredientToReplace(ingredient);
                      }}
                      className="h-auto py-0"
                      type="link"
                      icon={<SwapOutlined />}
                    />
                  </div>
                  <div>{ingredient.portion} g</div>
                  <div>
                    {(function () {
                      const [measure, multiplier] = selectAppropriateMeasure(ingredient);
                      if (measure?.name === "g") {
                        const enhancedIngredient = recalculateMeasures(ingredient as DishIngredient);
                        const { multiplier, measureId } = calculateMeasureMultiplier(enhancedIngredient, measuresMap);
                        if (enhancedIngredient.measure && measureId !== 1) {
                          return <span>{`${multiplier} x ${enhancedIngredient.measure}`}</span>;
                        }
                        return "-";
                      }
                      return <span>{measure ? `${multiplier} x ${measure.name}` : "-"}</span>;
                    })()}
                  </div>
                </div>
              }
            >
              <div className="flex flex-col divide-y divide-gray-200 rounded-md bg-gray-100/80 p-2">
                {ingredientDishes.map((dish, index) => (
                  <div key={index} className="grid grid-cols-2 items-center py-2">
                    <span>{dish[0]?.dishName}</span>
                    <span className="flex justify-end">
                      <Weekdays days={dish.map((item) => item.name)} />
                    </span>
                  </div>
                ))}
              </div>
            </Panel>
          );
        })}
      </Collapse>
      <ModalForm
        open={showForm}
        onCancel={handleCloseForm}
        okText="Zamień produkt"
        loading={saving}
        title={selectedIngredient ? `Wymień produkt (${selectedIngredient.name}) w diecie` : "Wymień produkt w diecie"}
      >
        {selectedIngredient ? (
          <ReplaceIngredientForm ingredient={selectedIngredient} onSubmit={handleFormSubmit} />
        ) : (
          <Spin />
        )}
      </ModalForm>
    </>
  );
};

const MealsPlanShoppingList = ({ openByDefault = true }: { openByDefault?: boolean }): React.ReactElement => {
  const {
    mealsMap: { meals },
  } = useMealsPlanContext();
  const shoppingList = React.useMemo(() => getShoppingList(Object.values(meals)), [meals]);
  const { categoryMap } = useNutritionCategories();
  const { t } = useTranslation("nutrition");

  const items: CollapseProps["items"] = Object.keys(shoppingList.byCategory).map((categoryId) => ({
    key: categoryId,
    label: t(`categories.${categoryMap[categoryId]?.name || "none"}`),
    children: <CategoryList ingredients={shoppingList.byCategory[categoryId] || []} />,
  }));

  return (
    <Space direction="vertical" size={24} className="w-full p-6">
      <div className="grid grid-cols-3">
        <div>{t("shoppingList.name")}</div>
        <div>{t("shoppingList.weight")}</div>
        <div>{t("shoppingList.measure")}</div>
      </div>
      <Collapse items={items} defaultActiveKey={openByDefault ? Object.keys(shoppingList.byCategory) : []} />
    </Space>
  );
};

export default MealsPlanShoppingList;
