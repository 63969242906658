import React, { useState } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Descriptions, Grid, Input, message, Spin, Switch, Tooltip } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { traineeProgramActions } from "@fitness-app/app-store";
import { TraineeStatus, type Trainee } from "@fitness-app/data-models/entities/Trainee";
import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import TraineeProgramTermination from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramTermination";
import { useAppDispatch } from "~/store/initializeStore";

interface TraineeProgramInfoConfigProps {
  program: ClientTrainingProgram;
  trainee: Trainee;
}

const disabledSwitchDesc = "Nie można aktywować planu klientowi, który nie potwierdził zaproszenia";

const TraineeProgramInfoConfig = ({ program, trainee }: TraineeProgramInfoConfigProps) => {
  const [editingComment, toggleComment] = useState(false);
  const [showLoader, toggleLoader] = useState(false);
  const { t } = useTranslation(["trainees", "workouts"]);
  const dispatch = useAppDispatch();
  const { md } = Grid.useBreakpoint();
  const [additionalCommentInput, setAdditionalCommentInputValue] = useState(program.comment);
  const editAdditionalComment = async () => {
    if (additionalCommentInput && additionalCommentInput.length > 500) {
      void message.error("Komentarz przekracza dopuszczalną ilość znaków: 500.");
      return;
    }
    toggleLoader(true);
    await dispatch(
      traineeProgramActions.updateTraineeProgram({
        traineeId: trainee.id,
        programId: program.id,
        program: {
          comment: additionalCommentInput,
        },
      }),
    );
    toggleLoader(false);
    toggleComment(false);
  };

  const toggleProgramAccess = async (checked: boolean) => {
    toggleLoader(true);
    await dispatch(
      traineeProgramActions.toggleProgramAccess({
        traineeId: trainee.id,
        programId: program.id,
        hasAccessToProgram: checked,
      }),
    );
    toggleLoader(false);
  };

  const renderAdditionalCommentItem = () => {
    if (editingComment) {
      return (
        <div>
          <span>
            <Input.TextArea
              rows={5}
              count={{ max: 500, show: true }}
              defaultValue={program.comment ?? undefined}
              onChange={(e) => setAdditionalCommentInputValue(e.target.value)}
            />
          </span>
          {showLoader ? (
            <Spin size="small" />
          ) : (
            <CheckOutlined className="ml-5 cursor-pointer pr-2.5 text-green-500" onClick={editAdditionalComment} />
          )}
          <CloseOutlined className="cusror-poinet ml-3.5 text-red-500" onClick={() => toggleComment(false)} />
        </div>
      );
    }
    return (
      <div className="flex items-center overflow-y-auto">
        <span className="whitespace-pre-wrap">{program.comment || "-"}</span>
        <EditOutlined className="ml-5 cursor-pointer pr-2.5 text-green-500" onClick={() => toggleComment(true)} />
      </div>
    );
  };
  return (
    <div className="ml-3.5 flex flex-col py-6">
      <Descriptions column={1} bordered labelStyle={{ width: md ? 250 : 320 }} layout={md ? "horizontal" : "vertical"}>
        <Descriptions.Item label="Nazwa planu">{program.name}</Descriptions.Item>
        <Descriptions.Item label="Typ planu">{t<string>(`workouts:programType.${program.type}`)}</Descriptions.Item>
        <Descriptions.Item label="Poziom trudności">
          {t<string>(`workouts:programLevel.${program.level}`)}
        </Descriptions.Item>
        <Descriptions.Item label="Czas trwania">
          {program.duration ? t<string>("program.duration.weeks", { count: program.duration }) : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Data rozpoczęcia planu">
          {program.startDate ? dayjs(program.startDate, "YYYY-MM-DD").format("DD.MM.YYYY") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Data ukończenia planu">
          <TraineeProgramTermination activeProgram={program} traineeId={trainee.id} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <span>
              Udostępnij plan klientowi
              {trainee.status !== TraineeStatus.ACTIVE && (
                <>
                  &nbsp;
                  <Tooltip title={disabledSwitchDesc}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              )}
            </span>
          }
        >
          <Switch
            disabled={trainee.status !== TraineeStatus.ACTIVE}
            onChange={toggleProgramAccess}
            checked={trainee.hasActiveTrainingProgram || false}
            loading={showLoader && !editingComment}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Uwagi do planu">{renderAdditionalCommentItem()}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default TraineeProgramInfoConfig;
