import { type Session, type User, type UserAppMetadata } from "@supabase/supabase-js";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import { logInSuccess } from "../reducer";
import { UserStatus } from "../types";
import { subscribeToUserProfile } from "./subscribeToUserProfile";

export const setLoggedUserData =
  ({ id, email }: User, session?: Session | null, isMobile = false, claims?: UserAppMetadata): AppThunk =>
  async (dispatch, getState, { auth }) => {
    const { status } = getState().user;

    if (status === UserStatus.LOGGING_IN) {
      return;
    }

    const customClaims = claims
      ? claims
      : session
      ? session.user.app_metadata
      : (await getLoggedUser(auth)).customClaims;

    dispatch(
      logInSuccess({
        id,
        email,
        customClaims: customClaims || null,
      }),
    );

    dispatch(subscribeToUserProfile(id, isMobile));
  };
