import React, { type FunctionComponent } from "react";
import { Card, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import AutomationTasks from "~/modules/Products/Product/ProductAutomation/components/AutomationTasks/AutomationTasks";
import Integrations from "~/modules/Products/Product/ProductAutomation/components/Integrations/Integrations";
import Webhooks from "~/modules/Products/Product/ProductAutomation/components/Webhooks/Webhooks";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

const ProductAutomation: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation("products");
  return (
    <Card>
      <Tabs tabPosition="left">
        <Tabs.TabPane key="1" tab={t("productAutomation.emailClientTasks")}>
          <AutomationTasks productId={productId} />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab={t("productAutomation.webhooks")}>
          <Webhooks productId={productId} />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab={t("productAutomation.integrations.title")} disabled>
          <Integrations productId={productId} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default ProductAutomation;
