import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Measurement } from "@fitness-app/data-models/entities/Measurement";
import { type DiscriminateUnion } from "@fitness-app/utils/src/types/utils";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  dateStart: Date;
  dateEnd: Date;
  type: Measurement["type"];
};

export type SelectedMeasurement<T extends Measurement["type"]> = DiscriminateUnion<Measurement, "type", T>;

export const fetchMeasurementsForRangeAndType = createAsyncThunk<
  SelectedMeasurement<Payload["type"]>[],
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/fetchMeasurementsForRangeAndType`,
  async ({ traineeId, dateStart, dateEnd, type }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("measurement")
      .select("*")
      .order("eventTimestamp")
      .eq("traineeId", traineeId)
      .eq("type", type)
      .gte("eventTimestamp", dateStart.toISOString())
      .lte("eventTimestamp", dateEnd.toISOString())
      .returns<SelectedMeasurement<typeof type>[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data || [];
  },
);
