import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DropAnimation,
  type UniqueIdentifier,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Drawer, message, type InputRef } from "antd";
import isEqual from "lodash.isequal";
import keyBy from "lodash.keyby";
import cloneDeep from "lodash/cloneDeep";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { type NutritionPlanDay } from "@fitness-app/data-models/entities/MealsPlan";

import { DndItem } from "~/components/Dnd/DndItem";
import { coordinateGetter } from "~/components/Dnd/multipleContainersKeyboardCoordinates";
import AddDishToMeal from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/AddDishToMeal";
import MealDetails from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDetails";
import MealDish from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDish";
import TrashZone from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/TrashZone";
import MealsProductProvider from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsProductProvider";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";

export type Items = Record<string, string[][]>;

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

const DRAWER_WIDTH = 400;

export const TRASH_ID = "void";

const MealsPlanSchedule = ({ readonly }: { readonly?: boolean }): React.ReactElement | null => {
  const { t } = useTranslation(["nutrition"]);
  const {
    mealsMap: { meals },
    planDetails,
    updateMealsPlanDays,
  } = useMealsPlanContext();
  const [activeId, setActiveId] = useState<string | null>(null);
  const [items, setItems] = useState<NutritionPlanDay[]>([]);
  const recentlyMovedToNewContainer = useRef(false);
  const [clonedItems, setClonedItems] = useState<NutritionPlanDay[] | null>(null);
  const [selectedMeal, setSelectedMeal] = useState<string | null>(null);
  const searchInputRef = useRef<InputRef>(null);
  const previousItems = useRef<NutritionPlanDay[]>([]);

  const onAddDishToMeal = useCallback((mealId?: string) => {
    if (mealId) {
      setSelectedMeal(mealId);
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 300);
    }
  }, []);

  const dishes = useMemo(() => {
    const dishes = items
      .map((mealsDay, dayIndex) =>
        mealsDay.meals
          .map((meal, mealIndex) => ({
            ...meal,
            dayId: mealsDay.id,
            dayIndex,
            mealIndex,
          }))
          .flat(),
      )
      .flat()
      .map((meal) =>
        meal.dishes.map((dish, index) => ({
          ...dish,
          dayId: meal.dayId,
          mealIndex: meal.mealIndex,
          mealId: meal.id,
          dayIndex: meal.dayIndex,
          dishIndex: index,
        })),
      )
      .flat();

    return keyBy(dishes, "id");
  }, [items]);

  const addDishToMeal = useCallback(
    (dishOrIngredient: Dish | Ingredient, passedMealId?: string) => {
      const mealId = passedMealId || selectedMeal;

      if (!mealId) {
        void message.error("Meal not found");
        return;
      }

      const schedule = planDetails;

      const meal = meals[mealId];

      if (!meal || !schedule?.weeks[0]) {
        void message.error("Meal not found");
        return;
      }

      const updatedDays = schedule.weeks[0].days.map((day) => {
        if (day.id !== meal.dayId) {
          return day;
        }
        const meals = day.meals.map((meal) => {
          if (meal.id !== mealId) {
            return meal;
          }
          return {
            ...meal,
            dishes: [
              ...meal.dishes,
              dishOrIngredient.type === "ingredient"
                ? {
                    ...dishOrIngredient,
                    portion: dishOrIngredient.packageSize || 100,
                    ingredientId: dishOrIngredient.id,
                    id: uuid(),
                  }
                : {
                    ...dishOrIngredient,

                    parentId: dishOrIngredient.id,
                    id: uuid(),
                  },
            ],
          };
        });

        return {
          ...day,
          meals,
        };
      });

      previousItems.current = [];

      void updateMealsPlanDays({
        days: updatedDays,
      });
    },
    [meals, selectedMeal],
  );

  const renderSortableItemDragOverlay = useCallback(
    (id: string) => (
      <DndItem value={id} dragOverlay>
        <MealDish key={id} dishOrIngredient={dishes[id]!} />
      </DndItem>
    ),
    [dishes],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  useEffect(() => {
    if (planDetails) {
      setItems(planDetails.weeks[0]?.days || []);
    }
  }, [planDetails]);

  const schedule = planDetails;

  const onDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  const maxMeals = Math.max(...(schedule.weeks[0]?.days || []).map((day) => day.meals.length));

  const findDishPosition = (id: UniqueIdentifier) => {
    const dish = dishes[id];

    if (String(id).startsWith("PLACEHOLDER")) {
      const [, dayIndex, mealIndex] = String(id).split("__");
      return {
        dayId: "PLACEHOLDER",
        mealId: "PLACEHOLDER",
        mealIndex: Number(mealIndex),
        dayIndex: Number(dayIndex),
      };
    }

    if (dish) {
      return {
        dayId: dish.dayId,
        mealId: dish.mealId,
        mealIndex: dish.mealIndex,
        dayIndex: dish.dayIndex,
      };
    } else {
      return null;
    }
  };

  return (
    <MealsProductProvider>
      <>
        <div
          className="flex flex-col gap-8 overflow-x-auto px-2 py-5"
          style={{
            width: selectedMeal ? `calc(100% - ${DRAWER_WIDTH}px + 85px)` : "100%",
          }}
        >
          <DndContext
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
              },
            }}
            onDragStart={({ active }) => {
              setActiveId(active.id as string);
              setClonedItems(items);
            }}
            onDragOver={({ active, over }) => {
              const overId = over?.id;

              if (overId == null || active.id in items) {
                return;
              }

              const overPosition = findDishPosition(overId);
              const activePosition = findDishPosition(active.id);

              if (!overPosition || !activePosition) {
                return;
              }

              if (
                activePosition.dayIndex !== overPosition.dayIndex ||
                activePosition.mealIndex !== overPosition.mealIndex
              ) {
                setItems((items) => {
                  const cloned = cloneDeep(items);
                  const activeItems = cloned[activePosition.dayIndex]?.meals[activePosition.mealIndex]?.dishes || [];
                  const overItems = cloned[overPosition.dayIndex]?.meals[overPosition.mealIndex]?.dishes || [];
                  const overIndex = overItems.map((dish) => dish.id).indexOf(overId as string);
                  const activeIndex = activeItems.map((dish) => dish.id).indexOf(active.id as string);

                  let newIndex: number;

                  if (overId in items) {
                    newIndex = overItems.length + 1;
                  } else {
                    const isBelowOverItem =
                      over &&
                      active.rect.current.translated &&
                      active.rect.current.translated.top > over.rect.top + over.rect.height;

                    const modifier = isBelowOverItem ? 1 : 0;

                    newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
                  }

                  recentlyMovedToNewContainer.current = true;

                  // @ts-expect-error ignore
                  cloned[activePosition.dayIndex].meals[activePosition.mealIndex].dishes =
                    cloned[activePosition.dayIndex]?.meals[activePosition.mealIndex]?.dishes.filter(
                      (dish) => dish.id !== active.id,
                    ) || [];
                  // @ts-expect-error ignore
                  cloned[overPosition.dayIndex].meals[overPosition.mealIndex].dishes = [
                    ...overItems.slice(0, newIndex),
                    activeItems[activeIndex],
                    ...overItems.slice(newIndex, overItems.length),
                  ].filter(Boolean);

                  return cloned;
                });
              }
            }}
            onDragEnd={({ active, over }) => {
              previousItems.current = items;

              const activePosition = findDishPosition(active.id);

              if (!activePosition) {
                if (!isEqual(previousItems.current, items)) {
                  void updateMealsPlanDays({
                    days: items,
                  });
                }
                setActiveId(null);
                return;
              }

              const overId = over?.id;

              if (overId == null) {
                if (!isEqual(previousItems.current, items)) {
                  void updateMealsPlanDays({
                    days: items,
                  });
                }
                setActiveId(null);
                return;
              }

              if (overId === TRASH_ID) {
                let cloned: null | NutritionPlanDay[] = null;
                setItems((prev) => {
                  cloned = cloneDeep(prev);

                  // @ts-expect-error ignore
                  cloned[activePosition.dayIndex].meals[activePosition.mealIndex].dishes = cloned[
                    activePosition.dayIndex
                  ].meals[activePosition.mealIndex].dishes.filter((dish) => dish.id !== active.id);

                  return cloned;
                });
                if (cloned) {
                  void updateMealsPlanDays({
                    days: cloned,
                  });
                  previousItems.current = cloned;
                }
                setActiveId(null);

                return;
              }

              const overPosition = findDishPosition(overId);

              if (overPosition) {
                const activeIndex =
                  items[activePosition.dayIndex]?.meals[activePosition.mealIndex]?.dishes
                    .map((dish) => dish.id)
                    .indexOf(active.id as string) ?? -1;
                const overIndex =
                  items[overPosition.dayIndex]?.meals[overPosition.mealIndex]?.dishes
                    .map((dish) => dish.id)
                    .indexOf(overId as string) ?? -1;

                if (activeIndex !== overIndex && activeIndex !== -1 && overIndex !== -1) {
                  let cloned: null | NutritionPlanDay[] = null;
                  setItems((prev) => {
                    cloned = cloneDeep(prev);

                    // @ts-expect-error ignore
                    cloned[overPosition.dayIndex].meals[overPosition.mealIndex].dishes = arrayMove(
                      cloned[overPosition.dayIndex]?.meals[overPosition.mealIndex]?.dishes || [],
                      activeIndex,
                      overIndex,
                    ).filter(Boolean);

                    return cloned;
                  });
                  if (cloned) {
                    void updateMealsPlanDays({
                      days: cloned,
                    });
                    previousItems.current = cloned;
                  }
                } else {
                  void updateMealsPlanDays({
                    days: previousItems.current,
                  });
                }
              }

              setActiveId(null);
            }}
            onDragCancel={onDragCancel}
            sensors={sensors}
          >
            <div className="overflow-x-auto px-2 py-5">
              {Array.from({ length: maxMeals + 1 }, (_, i) => i).map((mealIndex) => (
                <div key={mealIndex} className="grid-cols-week grid divide-x">
                  {items.map((day, dayIndex) => (
                    <MealDetails
                      day={day}
                      dayIndex={dayIndex}
                      mealIndex={mealIndex}
                      meal={day.meals[mealIndex] || null}
                      key={day.meals[mealIndex]?.id || `empty-${dayIndex}`}
                      readonly={readonly}
                      onAddDish={() => onAddDishToMeal(day.meals[mealIndex]?.id)}
                    />
                  ))}
                </div>
              ))}
            </div>
            {createPortal(
              <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
                {activeId ? renderSortableItemDragOverlay(activeId) : null}
              </DragOverlay>,
              document.body,
            )}
            {activeId && <TrashZone id={TRASH_ID} />}
          </DndContext>
        </div>
        <Drawer
          title={t("mealsPlan.addDish")}
          open={Boolean(selectedMeal)}
          mask={false}
          onClose={() => setSelectedMeal(null)}
          width={DRAWER_WIDTH}
        >
          <AddDishToMeal searchInputRef={searchInputRef} onAddDish={addDishToMeal} selectedMealId={selectedMeal} />
        </Drawer>
      </>
    </MealsProductProvider>
  );
};

export default MealsPlanSchedule;
