import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramWorkout, type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentProgram } from "../selectors/getCurrentProgram";
import { PROGRAM_BUILDER_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workout: ProgramWorkout;
};

export const updateWorkoutInProgram = createAsyncThunk<TrainingProgramDetails, Payload, AsyncThunkCreator<string>>(
  `${PROGRAM_BUILDER_REDUCER_NAME}/updateWorkoutInProgram`,
  async ({ programId, workout }, { rejectWithValue, getState, dispatch, extra: { db, analytics } }) => {
    const currentProgram = getCurrentProgram(getState());

    const updatedWorkouts = currentProgram.details.workouts.map((savedWorkout) =>
      savedWorkout.id === workout.id ? workout : savedWorkout,
    );

    dispatch(updateWorkoutsInProgram({ workouts: updatedWorkouts, programId }));

    const { data: programDetails, error } = await db
      .from("training_program_details")
      .update({
        workouts: updatedWorkouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<TrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_workout_in_program");

    return programDetails;
  },
);
