import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Dayjs } from "dayjs";

import { type ClientInvoiceData, type Order } from "@fitness-app/data-models/entities/Order";
import { getErrorMessage } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  invoiceData: ClientInvoiceData;
  clientEmail?: string;
  updateClientData?: boolean;
  orderId?: string;
  sellDate?: Dayjs;
  issueDate?: Dayjs;
};

export const updateInvoiceData = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCT_CLIENT_REDUCER}/updateInvoiceData`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const { email } = await getLoggedUser(auth);

      const { error } = await db
        .from("product_client")
        .update({
          client: payload.invoiceData,
        })
        .eq("email", email);

      if (error) {
        onFailure?.();

        return rejectWithValue(error.message);
      }

      if (payload.orderId) {
        const orderToUpdate: Partial<Order> = {
          client: payload.invoiceData,
        };

        if (payload.issueDate) {
          orderToUpdate.issueDate = payload.issueDate.unix();
        }

        if (payload.sellDate) {
          orderToUpdate.sellDate = payload.sellDate.unix();
        }

        const { error } = await db.from("product_order").update(orderToUpdate).eq("id", payload.orderId);

        if (error) {
          onFailure?.();

          return rejectWithValue(error.message);
        }
      }

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
