import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Badge, Button, Modal, Skeleton, Table, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import groupBy from "lodash.groupby";
import round from "lodash.round";
import { useTranslation } from "react-i18next";

import { traineeMeasurementsActions } from "@fitness-app/app-store";
import { getTraineeId } from "@fitness-app/app-store/src/store/reducers/trainee/selectors/getTraineeId";
import { type ClientNutritionDay } from "@fitness-app/data-models/entities/ClientNutritionDay";
import { type NutritionDaySummary as INutritionDaySummary } from "@fitness-app/data-models/entities/ClientNutritionWeek";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { MealIngredientStatus, type DishInMeal, type Meal } from "@fitness-app/data-models/entities/MealsPlan";
import { calculateForPortion } from "@fitness-app/utils/src/nutrition/countNutrients";
import { reduceDishIngredientsNutrients } from "@fitness-app/utils/src/nutrition/reduceMealsNutrients";

import NutritionDayTargets from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/Nutrition/NutritionDayTargets";
import { supabase, useAppDispatch, useAppSelector } from "~/store/initializeStore";

const Dishes = ({ dishes }: { dishes: (DishInMeal | IngredientWithPortion)[]; meals: Meal[] }) => {
  const { t } = useTranslation("nutrition");

  if (dishes.length > 4) {
    const grouped = groupBy(dishes, "mealType");

    return Object.keys(grouped).map((mealType) => (
      <div key={mealType} className="py-3">
        <Typography.Title level={4}>{t(`mealType.${mealType}`)}</Typography.Title>
        <Table
          dataSource={grouped[mealType] || []}
          pagination={false}
          data-ctx="table"
          size="small"
          rowKey={(product) => product.id}
        >
          <Table.Column title="Nazwa" dataIndex="name" key="name" width="30%" />
          <Table.Column<DishInMeal | IngredientWithPortion>
            title="Porcja"
            dataIndex="portion"
            key="portion"
            width="15%"
            align="center"
            render={(portion: number, row) => (
              <div>
                {round(
                  row.type !== "ingredient"
                    ? calculateForPortion(
                        row.ingredients.reduce((prev, current) => {
                          return (current.portion ?? 0) + prev;
                        }, 0),
                        { portions: row.portions || 1, portionCount: row.portionCount || 1 },
                      )
                    : portion,
                  1,
                )}{" "}
                g
              </div>
            )}
          />
          <Table.Column<DishInMeal | IngredientWithPortion>
            title="Wartości odżywcze (g)"
            dataIndex="macros"
            key="macros"
            render={(_, row) => {
              const { protein, carbs, fat } = reduceDishIngredientsNutrients([row]);
              return (
                <dd className="flex justify-between gap-1">
                  <span className="flex-1">B:{protein}</span>
                  <span className="flex-1">W:{carbs}</span>
                  <span className="flex-1">T:{fat}</span>
                </dd>
              );
            }}
          />
          <Table.Column<DishInMeal | IngredientWithPortion>
            title="Kalorie"
            dataIndex="calories"
            key="calories"
            align="center"
            render={(_calories, row) => {
              const { calories } = reduceDishIngredientsNutrients([row]);
              return <div>{round(calories)} kcal</div>;
            }}
          />
          <Table.Column
            title="Status"
            dataIndex="status"
            key="status"
            align="center"
            width="15%"
            render={(status: MealIngredientStatus | null) => {
              if (status === MealIngredientStatus.EATEN) {
                return <Badge status="success" text="Zjedzone" />;
              }

              if (status === MealIngredientStatus.SKIPPED) {
                return <Badge status="error" text="Pominięte" />;
              }

              return <Badge status="default" text="Brak statusu" />;
            }}
          />
          <Table.Column
            title="Źródło"
            dataIndex="fromTracker"
            key="fromTracker"
            align="center"
            width="15%"
            render={(fromTracker: boolean) => {
              if (!fromTracker) {
                return <Badge color="gold" text="Jadłospis" className="whitespace-nowrap" />;
              }

              return <Badge color="blue" text="Wyszukiwarka" className="whitespace-nowrap" />;
            }}
          />
          <Table.Column
            title="Notatka"
            dataIndex="clientNote"
            key="clientNote"
            align="center"
            render={(clientNote: string) => (
              <div>
                {clientNote ? (
                  <Tooltip title={clientNote}>
                    <InfoCircleOutlined className="text-blue-500" />
                  </Tooltip>
                ) : (
                  "-"
                )}
              </div>
            )}
          />
        </Table>
      </div>
    ));
  }

  return (
    <Table dataSource={dishes} pagination={false} data-ctx="table" size="small" rowKey={(product) => product.id}>
      <Table.Column title="Nazwa" dataIndex="name" key="name" width="30%" />
      <Table.Column title="Posiłek" dataIndex="mealType" key="mealType" render={(type) => t(`mealType.${type}`)} />
      <Table.Column<DishInMeal | IngredientWithPortion>
        title="Porcja"
        dataIndex="portion"
        key="portion"
        width="15%"
        align="center"
        render={(portion: number, row) => (
          <div>
            {round(
              row.type !== "ingredient"
                ? calculateForPortion(
                    row.ingredients.reduce((prev, current) => {
                      return (current.portion ?? 0) + prev;
                    }, 0),
                    { portions: row.portions || 1, portionCount: row.portionCount || 1 },
                  )
                : portion,
              1,
            )}{" "}
            g
          </div>
        )}
      />
      <Table.Column<DishInMeal | IngredientWithPortion>
        title="Wartości odżywcze (g)"
        dataIndex="macros"
        key="macros"
        render={(_, row) => {
          const { protein, carbs, fat } = reduceDishIngredientsNutrients([row]);
          return (
            <dd className="flex justify-between gap-1">
              <span className="flex-1">B:{protein}</span>
              <span className="flex-1">W:{carbs}</span>
              <span className="flex-1">T:{fat}</span>
            </dd>
          );
        }}
      />
      <Table.Column<DishInMeal | IngredientWithPortion>
        title="Kalorie"
        dataIndex="calories"
        key="calories"
        align="center"
        render={(_calories, row) => {
          const { calories } = reduceDishIngredientsNutrients([row]);
          return <div>{round(calories)} kcal</div>;
        }}
      />
      <Table.Column
        title="Status"
        dataIndex="status"
        key="status"
        align="center"
        width="15%"
        render={(status: MealIngredientStatus | null) => {
          if (status === MealIngredientStatus.EATEN) {
            return <Badge status="success" text="Zjedzone" />;
          }

          if (status === MealIngredientStatus.SKIPPED) {
            return <Badge status="error" text="Pominięte" />;
          }

          return <Badge status="default" text="Brak statusu" />;
        }}
      />
      <Table.Column
        title="Źródło"
        dataIndex="fromTracker"
        key="fromTracker"
        align="center"
        width="15%"
        render={(fromTracker: boolean) => {
          if (!fromTracker) {
            return <Badge color="gold" text="Jadłospis" className="whitespace-nowrap" />;
          }

          return <Badge color="blue" text="Wyszukiwarka" className="whitespace-nowrap" />;
        }}
      />
      <Table.Column
        title="Notatka"
        dataIndex="clientNote"
        key="clientNote"
        align="center"
        render={(clientNote: string) => (
          <div>
            {clientNote ? (
              <Tooltip title={clientNote}>
                <InfoCircleOutlined className="text-blue-500" />
              </Tooltip>
            ) : (
              "-"
            )}
          </div>
        )}
      />
    </Table>
  );
};

const MealsList = ({
  nutritionDay,
  selectedNutritionDay,
}: {
  nutritionDay: ClientNutritionDay | null;
  selectedNutritionDay: INutritionDaySummary;
}) => {
  if (!nutritionDay) {
    return "Brak danych";
  }

  const dishes = nutritionDay.day.meals
    .map((meal) => meal.dishes.map((dish) => ({ ...dish, mealType: meal.type })))
    .flat();

  return (
    <div className="flex flex-col gap-y-4 py-4">
      {dishes.length ? <Dishes dishes={dishes} meals={nutritionDay.day.meals} /> : <div>Brak posiłków</div>}

      <div>
        <NutritionDayTargets
          macrosNeeds={selectedNutritionDay.targets}
          caloriesValue={selectedNutritionDay.calories}
          carbsValue={selectedNutritionDay.macros.carbs}
          fatValue={selectedNutritionDay.macros.fat}
          proteinValue={selectedNutritionDay.macros.protein}
        />
      </div>
    </div>
  );
};

export const NutritionDaySummary = (): React.ReactElement => {
  const { selectedNutritionDay } = useAppSelector((state) => state.traineeMeasurements);
  const traineeId = useAppSelector(getTraineeId);
  const dispatch = useAppDispatch();
  const { data, isLoading } = useQuery(["nutrition_day", traineeId, selectedNutritionDay?.eventDate], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!traineeId || !selectedNutritionDay?.eventDate) {
        return null;
      }
      const query = supabase
        .from("client_nutrition_day")
        .select("*")
        .eq("traineeId", traineeId)
        .eq("eventDate", selectedNutritionDay.eventDate);

      const { error, data } = await query.single<ClientNutritionDay>();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  return (
    <Modal
      open={Boolean(selectedNutritionDay)}
      title={
        selectedNutritionDay
          ? `Dziennik posiłków z ${dayjs(selectedNutritionDay.eventDate).format("ll")}`
          : "Pobieram dzień"
      }
      width={900}
      onCancel={() => dispatch(traineeMeasurementsActions.selectNutritionDay(null))}
      footer={
        <Button key="back" onClick={() => dispatch(traineeMeasurementsActions.selectNutritionDay(null))}>
          Zamknij
        </Button>
      }
    >
      {isLoading || !data || !selectedNutritionDay ? (
        <div className="py-4">
          <Skeleton paragraph={{ rows: 8 }} />
        </div>
      ) : (
        <MealsList nutritionDay={data} selectedNutritionDay={selectedNutritionDay} />
      )}
    </Modal>
  );
};
