import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import orderBy from "lodash.orderby";
import { useTranslation } from "react-i18next";

import { type InitialSurvey as InitialSurveyInterface } from "@fitness-app/data-models/entities/ClientSurvey";
import { type SurveyQuestion } from "@fitness-app/data-models/entities/SurveyTemplate";
import { createInitialSurveyFromAnswers } from "@fitness-app/utils/src/surveys/createInitialSurvey";

import { defaultAnswers } from "~/modules/SurveysTemplates/InitialSurveyConfigurator/InitialSurvey.data";
import { type SurveyFormModel } from "~/modules/SurveysTemplates/SurveyForm/types";

interface SurveyFormProps {
  formController?: FormInstance<SurveyFormModel>;
  onSubmit: (formData: SurveyFormModel) => void;
  model?: Partial<SurveyFormModel> | null;
  isInitialSurvey?: boolean;
}

const getSurveyOptions = (surveyDetails?: InitialSurveyInterface) => {
  if (!surveyDetails) {
    return [];
  }
  const questions: SurveyQuestion[] = orderBy(Object.values(surveyDetails.questions), "orderKey");

  return questions.map((question) => ({
    value: question.key || question.id,
    label: question.title,
  }));
};

const SurveyForm = ({ model, onSubmit, formController, isInitialSurvey }: SurveyFormProps) => {
  const { t } = useTranslation(["surveys", "common"]);
  const surveyDetails = createInitialSurveyFromAnswers(defaultAnswers, new Date(), t);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);

      if (isInitialSurvey) {
        const ids = Object.values(model.questions || {})
          .map((question) => (question.protected ? question.key : null))
          .filter((item): item is string => Boolean(item));
        formController?.setFieldsValue({ selectedBasicQuestions: ids });
      }
    }
  }, [model]);

  return (
    <Form<SurveyFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        title: "",
        desc: "",
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="title"
        label={t("survey.title")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label={t("survey.description")}
        rules={[
          {
            type: "string",
            max: 500,
            message: t<string>("common:validationErrors.maxLength", {
              maxLength: 500,
            }),
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      {isInitialSurvey && (
        <Form.Item
          name="selectedBasicQuestions"
          label={t("basicQuestions")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select mode="multiple" options={getSurveyOptions(surveyDetails[0])} />
        </Form.Item>
      )}
    </Form>
  );
};

export default SurveyForm;
