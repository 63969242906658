import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

import ClientAccount from "~/components/InactiveAccount/ClientAccount";
import InactiveAccount from "~/components/InactiveAccount/InactiveAccount";
import Auth from "~/modules/Auth/Auth";
import AuthProvider from "~/modules/Auth/AuthProvider";
import LoginByEmail from "~/modules/Auth/pages/LoginByEmail";
import PasswordReminder from "~/modules/Auth/pages/PasswordReminder";
import { SignUp } from "~/modules/Auth/pages/SignUp";
import ProtectedAuth from "~/modules/Auth/ProtectedAuth";
import DashboardRouter from "~/modules/Dashboard/DashboardRouter";
import Login from "./modules/Auth/pages/Login";

import "./App.css";

import NotFound from "./components/NotFound/NotFound";

function App() {
  const { i18n } = useTranslation("common");
  const [queryClient] = useState(
    new QueryClient({
      // web query config
    }),
  );
  useEffect(() => {
    if (["pl", "en"].includes(i18n.language) || !i18n.language) {
      return;
    }
    const [lang] = i18n.language.split("-");
    void i18n.changeLanguage(lang || "en");
  }, [i18n.language]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route>
            <Route path="/auth/*" element={<Auth />}>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="password-reminder" element={<PasswordReminder />} />
              <Route path="login-by-link" element={<LoginByEmail />} />
              <Route index element={<Navigate to="login" replace />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="/inactive"
              element={
                <ProtectedAuth>
                  <InactiveAccount />
                </ProtectedAuth>
              }
            />
            <Route
              path="/client"
              element={
                <ProtectedAuth>
                  <ClientAccount />
                </ProtectedAuth>
              }
            />
            <Route
              path="/*"
              element={
                <ProtectedAuth>
                  <DashboardRouter />
                </ProtectedAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
