import React from "react";
import { Card, Col, Row } from "antd";

import SetPasswordForm from "~/components/SetPasswordForm/SetPasswordForm";

const SetPassword = () => {
  return (
    <Row className="p-6">
      <Col xs={24}>
        <Card>
          <SetPasswordForm />
        </Card>
      </Col>
    </Row>
  );
};

export default SetPassword;
