import React, { useState, type FunctionComponent } from "react";
import { Alert, Button, Form, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { userActions } from "@fitness-app/app-store";

import { useAppDispatch } from "~/store/initializeStore";

const tailLayout = {
  wrapperCol: { offset: 16, span: 8 },
};

const PasswordReminder: FunctionComponent = () => {
  const { t } = useTranslation(["auth", "common"]);
  const [status, setStatus] = useState<null | {
    type: "error" | "info";
    message: string;
  }>(null);
  const [formController] = Form.useForm<{ email: string }>();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmitLoginForm = async ({ email }: { email: string }) => {
    setIsSubmitting(true);
    // eslint-disable-next-line
    await dispatch(userActions.sendPasswordResetLink(email));
    setStatus({ type: "info", message: t("forgotPassword.info") });
    formController.setFields([{ name: "email", value: "" }]);
    setIsSubmitting(false);

    setTimeout(() => {
      setStatus(null);
    }, 3500);
  };

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="min-w-[350px] p-4">
        <Typography.Title level={4} className="pb-8">
          {t("resetPassword.title")}
        </Typography.Title>
        {status && <Alert showIcon className="mb-10" type={status.type} message={status.message} />}
        <Form<{ email: string }>
          name="login-form"
          layout="vertical"
          form={formController}
          onFinish={handleSubmitLoginForm}
          initialValues={{
            email: email || "",
          }}
          size="large"
          validateTrigger={["onSubmit", "onBlur"]}
        >
          <Form.Item
            name="email"
            label={t("login")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
              {
                type: "email",
                message: t<string>("validationErrors.wrongEmail"),
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" block className="basis-4/12" loading={isSubmitting}>
              {t("reset")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PasswordReminder;
