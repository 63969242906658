import dayjs from "dayjs";

import { TraineeActivityEventType } from "../../businessEvents/domain/traineeActivity/TraineeActivityEvents";
import { ClientActivityFlag, type ClientActivity, type ClientActivityWithScore } from "../../entities/ClientActivity";

export class ClientActivityWithCalculatedScore {
  report: ClientActivityWithScore;
  constructor(report: ClientActivity) {
    this.report = {
      ...report,
      score: 0,
      measurementStatus: ClientActivityFlag.Idle,
      communicationStatus: ClientActivityFlag.Idle,
      workoutsStatus: ClientActivityFlag.Idle,
    };
  }

  static flagToPoints = {
    [ClientActivityFlag.Idle]: 0,
    [ClientActivityFlag.Danger]: -10,
    [ClientActivityFlag.Warning]: 5,
    [ClientActivityFlag.Safe]: 10,
    [ClientActivityFlag.VerySafe]: 15,
  };

  public calculateScore() {
    this.setMeasurementsStatus();
    this.setWorkoutsStatus();
    this.setCommunicationStatus();
    return this;
  }

  public getReport() {
    return this.report;
  }

  private setMeasurementsStatus() {
    if (this.report.measurements.length === 0 || !this.actionOccurredInLastDays(this.report.lastMeasurementsAt, 21)) {
      this.measurementsStatus = ClientActivityFlag.Danger;
      return;
    }

    if (
      this.actionOccurredInLastDays(this.report.lastMeasurementsAt, 7) &&
      this.report.measurements.some(
        (measurement) => measurement.type === TraineeActivityEventType.TraineeAddedBodyPhotos,
      )
    ) {
      this.measurementsStatus = ClientActivityFlag.VerySafe;
      return;
    }

    if (this.actionOccurredInLastDays(this.report.lastMeasurementsAt, 10)) {
      this.measurementsStatus = ClientActivityFlag.Safe;
      return;
    }

    this.measurementsStatus = ClientActivityFlag.Warning;
  }

  private setWorkoutsStatus() {
    if (!this.report.hasActivePlan) {
      this.workoutsStatus = ClientActivityFlag.Danger;
      return;
    }

    if (!this.report.activities) {
      this.workoutsStatus = ClientActivityFlag.Danger;
      return;
    }

    const [currentWeek, oneWeekBefore, twoWeekBefore] = this.report.activities;

    if (twoWeekBefore.activitiesScheduled === null && oneWeekBefore.activitiesScheduled === null) {
      this.workoutsStatus = ClientActivityFlag.Safe;
      return;
    }

    if (oneWeekBefore.activitiesScheduled === null) {
      this.workoutsStatus = ClientActivityFlag.Warning;
      return;
    }

    const totalWorkoutsScheduled = oneWeekBefore.workoutsScheduled ?? 0 + (twoWeekBefore.workoutsScheduled ?? 0);
    const totalWorkoutsCompleted = oneWeekBefore.workoutsCompleted + twoWeekBefore.workoutsCompleted;
    const totalActivitiesScheduled = oneWeekBefore.activitiesScheduled + (twoWeekBefore.activitiesScheduled ?? 0);
    const totalActivitiesCompleted = oneWeekBefore.activitiesCompleted + twoWeekBefore.activitiesCompleted;

    if (
      totalWorkoutsScheduled &&
      totalWorkoutsCompleted >= totalWorkoutsScheduled &&
      totalActivitiesScheduled &&
      totalActivitiesCompleted >= totalActivitiesScheduled
    ) {
      this.workoutsStatus = ClientActivityFlag.VerySafe;
    }

    if (totalWorkoutsScheduled && totalWorkoutsCompleted >= totalWorkoutsScheduled) {
      this.workoutsStatus = ClientActivityFlag.Safe;
      return;
    }

    if (totalWorkoutsScheduled && totalWorkoutsScheduled > totalWorkoutsCompleted) {
      this.workoutsStatus = ClientActivityFlag.Warning;
      return;
    }

    if (currentWeek.activitiesCompleted > 0) {
      this.workoutsStatus = ClientActivityFlag.Warning;
      return;
    }

    this.workoutsStatus = ClientActivityFlag.Danger;
  }

  private setCommunicationStatus() {
    if (!this.report.lastMessageAt && !this.report.lastLoggedAt) {
      this.communicationStatus = ClientActivityFlag.Danger;
      return;
    }

    if (this.actionOccurredInLastDays(this.report.lastMessageAt, 3)) {
      this.communicationStatus = ClientActivityFlag.VerySafe;
      return;
    }

    if (this.actionOccurredInLastDays(this.report.lastMessageAt, 7)) {
      this.communicationStatus = ClientActivityFlag.Safe;
      return;
    }

    if (this.actionOccurredInLastDays(this.report.lastLoggedAt, 7)) {
      this.communicationStatus = ClientActivityFlag.Warning;
      return;
    }

    this.communicationStatus = ClientActivityFlag.Danger;
  }

  private actionOccurredInLastDays(actionTimestamp: number | null, days: number) {
    if (!actionTimestamp) {
      return false;
    }
    return dayjs.unix(actionTimestamp).isAfter(dayjs().subtract(days, "day"));
  }

  private set communicationStatus(flag: ClientActivityFlag) {
    this.report.communicationStatus = flag;
    this.updateScore(flag);
  }

  private set measurementsStatus(flag: ClientActivityFlag) {
    this.report.measurementStatus = flag;
    this.updateScore(flag);
  }

  private set workoutsStatus(flag: ClientActivityFlag) {
    this.report.workoutsStatus = flag;
    this.updateScore(flag);
  }

  private updateScore(flag: ClientActivityFlag) {
    this.report.score += ClientActivityWithCalculatedScore.flagToPoints[flag];
  }
}
