import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { AUTOMATION_REDUCER_NAME, type BaseProgramAutomation } from "../types";

type Payload = void;

export const fetchProgramAutomations = createAsyncThunk<BaseProgramAutomation[], Payload, AsyncThunkCreator<string>>(
  `${AUTOMATION_REDUCER_NAME}/fetchProgramAutomations`,
  async (_, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("program_automation")
      .select("name, id, createdAt, updatedAt, comment, schedule")
      .order("createdAt", { ascending: false })
      .returns<BaseProgramAutomation[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
