import { createAsyncThunk } from "@reduxjs/toolkit";

import { type UpdateProductPriceDTO } from "@fitness-app/data-models/entities/Product";
import { getError } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = { prices: UpdateProductPriceDTO[] };

export const updateProductPrices = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<Error>>(
  `${PRODUCT_REDUCER_NAME}/updateProductPrices`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/update-product-prices", payload);
      analytics.track("update_product_prices");
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getError(e));
    }
  },
);
