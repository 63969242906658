import React, { type FunctionComponent } from "react";
import { Alert, Descriptions, Grid } from "antd";
import { useTranslation } from "react-i18next";

import { type Order } from "@fitness-app/data-models/entities/Order";
import { COUNTRIES } from "@fitness-app/utils/src/constants/countries";

interface OwnProps {
  orderClient?: Order["client"];
  withTitle?: boolean;
  withAlert?: boolean;
}

type Props = OwnProps;

const InvoiceDataInfo: FunctionComponent<Props> = ({ orderClient, withTitle = true, withAlert = false }) => {
  const { t } = useTranslation(["products", "common"]);
  const { lg } = Grid.useBreakpoint();

  if (!orderClient && withAlert) {
    return <Alert showIcon type="warning" message={t("common:setInvoiceDataAlert")} />;
  }

  return (
    <Descriptions title={withTitle ? t("productOrder.title") : ""} bordered layout={lg ? "horizontal" : "vertical"}>
      {!orderClient ? (
        <Descriptions.Item span={3} style={{ textAlign: "center" }}>
          {t("common:noData")}
        </Descriptions.Item>
      ) : (
        <>
          <Descriptions.Item label={t("productOrder.firstName")} span={3}>
            {orderClient.firstName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={t("productOrder.lastName")} span={3}>
            {orderClient.lastName || "-"}
          </Descriptions.Item>
          {orderClient.companyName !== "" && (
            <Descriptions.Item label={t("productOrder.companyName")} span={3}>
              {orderClient.companyName}
            </Descriptions.Item>
          )}
          {orderClient.nip ? (
            <Descriptions.Item label={t("productOrder.nip")} span={3}>
              {orderClient.nip}
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label={t("productOrder.street")} span={3}>
            {orderClient.street || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={t("productOrder.postalCode")} span={3}>
            {orderClient.postalCode || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={t("productOrder.city")} span={3}>
            {orderClient.city || "-"}
          </Descriptions.Item>
          {orderClient.country ? (
            <Descriptions.Item label={t("productOrder.country")} span={3}>
              {t(
                `common:countries.${
                  COUNTRIES.find((country) => country.isoCode === orderClient.country)?.name ?? "Poland"
                }`,
              )}
            </Descriptions.Item>
          ) : null}
        </>
      )}
    </Descriptions>
  );
};

export default InvoiceDataInfo;
