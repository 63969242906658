import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { type ExerciseStats } from "@fitness-app/data-models/entities/ExerciseStats";

import { supabase } from "~/store/initializeStore";

export const useExerciseStats = (traineeId: string | null) => {
  const [selectedExercise, setExercise] = useState<string | null>(null);

  const { data, isLoading } = useQuery(["exerciseStats", traineeId, selectedExercise], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!traineeId || !selectedExercise) {
        return null;
      }
      const { error, data } = await supabase
        .from("workout_exercise_stats")
        .select("*")
        .eq("traineeId", traineeId)
        .eq("exerciseId", selectedExercise)
        .order("eventDate")
        .returns<ExerciseStats[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  return {
    selectedExercise,
    setExercise,
    fetchingExerciseStats: isLoading,
    exerciseStats: data || [],
  };
};
