import { type ProductPriceAction } from "@fitness-app/data-models/entities/Product";

import { type RequestStatus } from "../../../enums/requestStatus";
import { type ProductWithStats } from "../products/types";

export const PRODUCT_REDUCER_NAME = "Product";

export interface ProductReducer {
  productDetailsStatus: null | RequestStatus;
  details: ProductWithStats | null;
  productError: null | Error;
  productId: string | null;
  productPricesActions: null | { id: string; action: ProductPriceAction }[];
}
