import { type TFunction } from "i18next";
import isEmpty from "lodash.isempty";

import {
  TeamMemberRole,
  TeamMemberStatus,
  type TeamMemberWithUser,
} from "@fitness-app/data-models/entities/TeamMember";

export const EMPTY_TRAINER_FIELD = "none";

export const createTrainerOptions = (list: TeamMemberWithUser[], t: TFunction<string[]>, withOwner = true) => {
  if (!list || isEmpty(list)) {
    return undefined;
  }
  const trainerOptions = [];
  list
    .filter((trainer) => (withOwner ? trainer : trainer.role !== TeamMemberRole.ACCOUNT_OWNER))
    .forEach((trainer) => {
      if (trainer.selectable) {
        const text = `${trainer.firstName} ${trainer.lastName}`;
        trainerOptions.push({
          value: trainer.id,
          text: trainer.status === TeamMemberStatus.ACTIVE ? text : `${text} (nieaktywny)`,
        });
      }
    });
  trainerOptions.push({
    value: EMPTY_TRAINER_FIELD,
    text: t("trainerOptions.noTrainer"),
  });
  return trainerOptions;
};
