import React, { useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions } from "@fitness-app/app-store";
import { InvoicesClient } from "@fitness-app/data-models/entities/Product";
import { type InFaktIntegration as InFaktIntegrationInterface } from "@fitness-app/data-models/entities/ProductsSettings";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useLoading } from "~/hooks/useLoading";
import { useAppDispatch } from "~/store/initializeStore";
import InFaktIntegrationForm from "./InFaktIntegrationForm";
import { type InFaktIntegrationFormModel } from "./types";

interface OwnProps {
  integration: InFaktIntegrationInterface[] | null;
}

type Props = OwnProps;

const ADD_NEW_ITEM = "ADD_NEW_ITEM";

const InFaktIntegration: FunctionComponent<Props> = ({ integration }) => {
  const { t } = useTranslation(["settings", "common"]);
  const [loading, startLoading, stopLoading] = useLoading();
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();

  const [selectedAccountUrl, changeSelectedAccount] = useState<undefined | string>(undefined);

  const selectedAccount = useMemo(() => {
    return integration?.find((account) => account.url === selectedAccountUrl);
  }, [selectedAccountUrl, integration]);

  const handleRemoveIntegration = async () => {
    if (!selectedAccount) {
      return;
    }
    void message.loading(t("messages.loading.removeAccount"));

    const onSuccess = () => {
      message.destroy();
      void message.success(t("messages.success.removeAccount"));
      changeSelectedAccount(undefined);
    };
    const onFailure = () => {
      message.destroy();
      void message.error(t("messages.error.removeAccount"));
    };
    await dispatch(
      productsSettingsActions.removeIntegrationWithInvoicesClient({
        payload: { url: selectedAccount.url, client: selectedAccount.client },
        onFailure,
        onSuccess,
      }),
    );
  };

  const handleChange = (value?: string | undefined) => {
    if (value === ADD_NEW_ITEM || !value) {
      changeSelectedAccount(undefined);
      setTimeout(() => {
        toggleModal(true);
      }, 100);

      return;
    }
    changeSelectedAccount(value);
  };

  const handleSubmit = async (data: InFaktIntegrationFormModel) => {
    startLoading();
    await dispatch(
      productsSettingsActions.integrateWithInvoicesClient({
        payload: {
          ...data,
          url: data.accountName,
          client: InvoicesClient.inFakt,
        },
        onSuccess: () => {
          void message.success(t(selectedAccount ? "infakt.editSuccess" : "infakt.success"));
          toggleModal(false);
          handleChange(data.accountName);
        },
        onFailure: (errorCode) => {
          if (errorCode === 403) {
            void message.error(t("infakt.accessError"));
            return;
          }
          void message.error(t("infakt.error"));
        },
      }),
    );
    stopLoading();
  };

  return (
    <Space direction="vertical" size={24} className="transition-visibility mt-20 duration-300 ease-in-out">
      <Typography.Title level={4}>{t("emailClients.activeCampaign.connectedAccounts")}</Typography.Title>
      <Select
        size="large"
        placeholder={t("selectAccount")}
        style={{ minWidth: 400, maxWidth: 450 }}
        notFoundContent={false}
        onChange={handleChange}
        onSelect={handleChange}
        value={selectedAccount?.url ?? undefined}
        optionFilterProp="title"
        optionLabelProp="title"
      >
        {integration?.map((account) => (
          <Select.Option value={account.url} title={account.url} key={account.url}>
            {account.accountName} - {t(`common:invoices.${account.client}`)} - {account.url}
          </Select.Option>
        ))}
        {
          <Select.Option value={ADD_NEW_ITEM} title={t<string>("addNewAccount")}>
            <Button
              type="link"
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
            >
              <PlusOutlined /> {t("addNewAccount")}
            </Button>
          </Select.Option>
        }
      </Select>
      {selectedAccount && (
        <>
          <Space direction="horizontal" align="end" style={{ width: "100%", justifyContent: "flex-end" }}>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => toggleModal(true)}>
              {t("common:button.edit")}
            </Button>
            <Popconfirm
              onConfirm={handleRemoveIntegration}
              title={t("invoicesClient.deleteWarning")}
              okText={t("common:button.delete")}
              okButtonProps={{ danger: true }}
              overlayStyle={{ maxWidth: 350 }}
            >
              <Button type="dashed" danger icon={<DeleteOutlined />}>
                {t("common:button.delete")}
              </Button>
            </Popconfirm>
          </Space>
        </>
      )}
      <ModalForm
        visible={showModal}
        loading={loading}
        onCancel={() => toggleModal(false)}
        title={selectedAccount ? t("emailClients.editAccount") : t("emailClients.addNewAccount")}
      >
        <InFaktIntegrationForm
          onSubmit={handleSubmit}
          model={
            selectedAccount
              ? {
                  apiKey: "",
                  accountName: selectedAccount.accountName,
                  accountApiKey: "",
                  settings: selectedAccount.settings,
                }
              : undefined
          }
        />
      </ModalForm>
    </Space>
  );
};

export default InFaktIntegration;
