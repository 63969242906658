import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { type InAppResourcePayload, type ProductResource } from "@fitness-app/data-models/entities/ProductResource";
import { getErrorMessage } from "@fitness-app/utils";
import { DEFAULT_RESOURCE_CATEGORY } from "@fitness-app/utils/src/constants/defaultResourceCategory";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_RESOURCES_REDUCER_NAME } from "../types";
import { fetchProductResources } from "./fetchProductResources";

type Payload = {
  productId: string;
  model: Omit<InAppResourcePayload, "productId" | "owner" | "order">;
};

export const addProductResource = createAsyncThunk<string, ActionParams<Payload, string>, AsyncThunkCreator<string>>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/addProductResource`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, dispatch, extra: { db, analytics, auth } }) => {
    const { id } = await getLoggedUser(auth);
    try {
      const model: ProductResource = {
        ...payload.model,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: uuid(),
        category: payload.model.category === DEFAULT_RESOURCE_CATEGORY ? null : payload.model.category,
        productId: payload.productId,
        owner: id,
        order: null,
      };

      const { error } = await db.from("product_resource").insert(model);

      if (error) {
        onFailure?.();
        return rejectWithValue(error.message);
      }
      analytics.track("add_resource_to_product", {
        productId: payload.productId,
      });
      onSuccess?.();

      void dispatch(fetchProductResources({ productId: payload.productId }));
      return model.id;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
