import { type Dayjs } from "dayjs";

import { type UserRoleEnum } from "@fitness-app/data-models";

export enum FormMode {
  Calendar,
  Template,
  Template_Add,
}

export const weekDayLabels = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] as const;

export type WeekdayLabels = (typeof weekDayLabels)[number];

export interface AutomatedMessagesFormModel {
  id: string | null;
  mode: FormMode;
  name: string;
  sentTime: Dayjs;
  messages: string[];
  authorId: string;
  authorName: string;
  authorRole: UserRoleEnum;
  authorAvatar: string | null;
  comment?: string;
  sentDate?: Dayjs;
  saveAsTemplate?: boolean;
  daysOfDelivery?: WeekdayLabels[];
  numberOfRepeats?: number;
}
