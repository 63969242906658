import { type RequestStatus } from "@fitness-app/data-models";
import { type ClientNutritionDay } from "@fitness-app/data-models/entities/ClientNutritionDay";

export const TRAINEE_NUTRITION_TRACKER_REDUCER_NAME = "traineeNutritionTracker";

export interface TraineeNutritionTrackerReducer {
  selectedWeek: string | null;
  fetchedWeekDays: ClientNutritionDay[];
  listStatus: RequestStatus | null;
  fetchedWeek: string | null;
}
