import { type Order } from "@fitness-app/data-models/entities/Order";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PRODUCT_ORDERS_REDUCER_NAME = "ProductOrders";

export interface ProductOrdersReducer {
  orders: Order[];
  selectedMonth: string | null;
  productId: string | null;
  ordersStatus: RequestStatus | null;
  searchTerm: string | null;
  error: Error | null;
}
