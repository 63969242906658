import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { RequestStatus } from "../../../enums/requestStatus";
import { addExercise } from "./actions/addExercise";
import { deleteExercise } from "./actions/deleteExercise";
import { fetchExercises } from "./actions/fetchExercises";
import { updateExercise } from "./actions/updateExercise";
import { EXERCISES_REDUCER_NAME, type ExercisesReducer } from "./types";

const initialState: ExercisesReducer = {
  list: [],
  searchText: "",
  exercisesStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: EXERCISES_REDUCER_NAME,
  reducers: {
    setSearchText(state, { payload }: PayloadAction<string>) {
      state.searchText = payload;
    },
    clearSearchText(state) {
      state.searchText = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addExercise.pending, (state) => {
      state.exercisesStatus = RequestStatus.UPDATING;
    });
    builder.addCase(addExercise.fulfilled, (state, { payload }) => {
      state.exercisesStatus = null;
      state.list.unshift(payload);
    });
    builder.addCase(addExercise.rejected, (state) => {
      state.exercisesStatus = null;
    });
    builder.addCase(updateExercise.pending, (state) => {
      state.exercisesStatus = RequestStatus.UPDATING;
    });
    builder.addCase(updateExercise.fulfilled, (state, { payload }) => {
      state.exercisesStatus = RequestStatus.SUCCESS;
      if (payload) {
        state.list = state.list.map((exercise) => (exercise.id === payload.id ? payload : exercise));
      }
    });
    builder.addCase(updateExercise.rejected, (state) => {
      state.exercisesStatus = null;
    });
    builder.addCase(fetchExercises.pending, (state) => {
      state.exercisesStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchExercises.fulfilled, (state, { payload }) => {
      state.exercisesStatus = RequestStatus.SUCCESS;
      state.list = payload;
    });
    builder.addCase(fetchExercises.rejected, (state) => {
      state.exercisesStatus = RequestStatus.FAILED;
    });
    builder.addCase(deleteExercise.pending, (state, { meta }) => {
      state.list = state.list.filter((exercise) => exercise.id !== meta.arg);
    });
  },
});

export const { setSearchText, clearSearchText } = reducerSlice.actions;
export default reducerSlice.reducer;
