import React, { useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Image, Progress, Spin, Tooltip, Typography } from "antd";
import InlineSVG from "react-inlinesvg";

import { type FileMessageContent } from "@fitness-app/data-models/entities/Chat";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { getFileIcon } from "~/components/Upload/helpers/getFileIcon";
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import DeleteIcon from "./assets/delete-icon.svg";
import { UploadFileStatus, type FileData } from "./types";

export interface FileItemProps {
  file: FileData | FileMessageContent;
  onRemove?: (file: FileData) => void;
  className?: string;
}

const ITEM_SIZE = 56;

const LoaderContainer = ({
  children,
  loading,
  percentage,
}: {
  children: React.ReactElement;
  loading: boolean;
  percentage?: number | null;
}): React.ReactElement => {
  if (loading) {
    return typeof percentage === "number" ? (
      <Progress type="circle" percent={percentage} size={40} />
    ) : (
      <Spin size="small" />
    );
  }

  return children;
};

const FileItem = ({ file, onRemove, className }: FileItemProps): React.ReactElement => {
  const isImage = file.contentType.startsWith("image");
  const isVideo = file.contentType.startsWith("video");
  const [video, setVideo] = useState<null | string>(null);
  const uploading = file.status === UploadFileStatus.UPLOADING;

  const renderContent = (): React.ReactElement => {
    if ((isImage || isVideo) && file.status === UploadFileStatus.ERROR) {
      return (
        <div data-testid="image-thumbnail" className="flex h-full w-full items-center justify-center">
          <Progress type="circle" percent={file.progress ?? 0} size={30} status="exception" />
        </div>
      );
    }

    if (isImage) {
      return (
        <div
          data-testid="image-thumbnail"
          className="flex h-full w-full items-center justify-center rounded bg-cover bg-center"
        >
          <Image
            alt={file.originalName}
            className="rounded-lg border border-transparent object-cover"
            src={file.thumbUrl || file.url}
            preview={{
              src: file.url,
            }}
            width={ITEM_SIZE - 4}
            height={ITEM_SIZE - 4}
          />
        </div>
      );
    }

    if (isVideo) {
      return (
        <div
          onClick={() => setVideo(file.url || null)}
          data-testid="image-thumbnail"
          className="flex h-full w-full items-center justify-center rounded bg-black/90 bg-cover bg-center"
        >
          <PlayCircleOutlined className="text-white" />
        </div>
      );
    }

    return (
      <a
        href={file.url}
        target="_blank"
        rel="noopener noreferrer"
        className={cn("overflow-wrap-anywhere flex h-10 w-48 items-center gap-x-2 px-2.5 py-0")}
      >
        <InlineSVG className="h-6 w-6" src={getFileIcon(file.extension)} style={{ flex: "0 0 24px" }} />
        <Typography.Text ellipsis>{file.originalName}</Typography.Text>
      </a>
    );
  };

  return (
    <Tooltip title={file.errorMessage || (isImage || video ? undefined : file.originalName)}>
      <div
        data-testid="file-item"
        className={cn(
          "min-w-48 group relative inline-flex h-[56px] w-48 cursor-pointer items-center justify-center rounded-lg border border-gray-200 bg-gray-50 hover:border-gray-300",
          (isImage || isVideo) && `h-[56px] w-[56px] min-w-[56px] border-transparent`,
          uploading && "border border-gray-200",
          file.status === UploadFileStatus.ERROR && "border-red-500 hover:border-red-600",
          className,
        )}
      >
        {onRemove && (
          <div
            onClick={"status" in file ? (): void => onRemove(file as FileData) : undefined}
            className="invisible absolute -right-1 -top-2 z-[15] flex cursor-pointer items-center justify-center rounded-full bg-red-500 p-1 text-sm outline-none transition-all group-hover:visible"
          >
            <InlineSVG className="h-3 w-3" src={DeleteIcon} />
          </div>
        )}

        <LoaderContainer loading={uploading} percentage={file.progress}>
          {renderContent()}
        </LoaderContainer>

        {isVideo && file.url && (
          <VideoPlayer hideControl videoUrl={file.url} visible={!!video} onClose={() => setVideo(null)} />
        )}
      </div>
    </Tooltip>
  );
};

export default FileItem;
