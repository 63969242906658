import { type ChatBot } from "@fitness-app/data-models/entities/ChatBot";

import { type PerformingAction } from "../../../enums/performingAction";
import { type RequestStatus } from "../../../enums/requestStatus";

export interface BotsConfigReducer {
  list: ChatBot[];
  listStatus: RequestStatus | null;
  performingAction: PerformingAction | null;
  error: Error | null;
}

export const BOT_CONFIG_REDUCER_NAME = "BotConfig";
