import React, { useEffect, useState, type FunctionComponent } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Input, Menu, Space, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { productClientsActions, RequestStatus } from "@fitness-app/app-store";
import { ProductChargeType } from "@fitness-app/data-models/entities/Product";

import ImportClientsModal from "~/modules/Products/Product/ProductClientsList/components/ImportClientsModal/ImportClientsModal";
import ProductClientsTable from "~/modules/Products/Product/ProductClientsList/ProductClientsTable";
import { useExportClientsToCsv } from "~/modules/Products/Product/ProductClientsList/useExportClientsToCsv";
import ProductClientDetails from "~/modules/Products/ProductClientDetails/ProductClientDetails";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
  ownerId?: string;
  readonly?: boolean;
}

type Props = OwnProps;

const ProductClientsList: FunctionComponent<Props> = ({ productId, ownerId }) => {
  const { t } = useTranslation("products");
  const [showImportModal, toggleImportModal] = useState(false);
  const [keepImportModal, resetImportModalData] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const { details } = useAppSelector((store) => store.product);
  const { searchStatus, list, searchTerm, filters } = useAppSelector((store) => store.productClients);
  const { exportToCsvClients } = useExportClientsToCsv();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!showImportModal) {
      resetImportModalData(false);

      setTimeout(() => {
        resetImportModalData(true);
      }, 100);
    }
  }, [showImportModal]);

  if (!details) {
    return null;
  }
  const onClear = () => {
    setSearchValue("");
    dispatch(productClientsActions.setSearchTerm(""));
    void dispatch(productClientsActions.fetchProductClients({ productId }));
  };

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (searchTerm) {
        onClear();
      }
      return;
    }
    dispatch(productClientsActions.setSearchTerm(trimmed));

    void dispatch(productClientsActions.fetchProductClients({ productId }));
  };

  const menu = (
    <Menu>
      {(details.product_stats?.activeClients && details.product_stats.activeClients > 0) ||
      (details.product_stats?.totalClients && details.product_stats.totalClients > 0) ? (
        <Menu.Item key="1" onClick={() => exportToCsvClients(productId, details?.name ?? "", "active")}>
          {t("clients.active")}
        </Menu.Item>
      ) : null}

      <>
        {details.product_stats?.archivedClients && details.product_stats?.archivedClients > 0 ? (
          <Menu.Item key="2" onClick={() => exportToCsvClients(productId, details?.name ?? "", "archived")}>
            {t("clients.archived")}
          </Menu.Item>
        ) : null}

        <Menu.Item key="3" onClick={() => exportToCsvClients(productId, details?.name ?? "", "all")}>
          {t("clients.all")}
        </Menu.Item>
      </>
    </Menu>
  );

  return (
    <Card
      extra={
        <Button type="primary" onClick={() => toggleImportModal(true)}>
          {t("import.importClientsButton")}
        </Button>
      }
    >
      <Space size={4}>
        <Input.Search
          placeholder={t<string>("search.placeholder")}
          onSearch={onSearch}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ width: 300, margin: "20px 0" }}
          loading={searchStatus === RequestStatus.FETCHING}
        />
        {Boolean(searchTerm) && (
          <Button onClick={onClear} type="link">
            {t("search.reset")}
          </Button>
        )}
      </Space>
      <Tabs
        destroyInactiveTabPane
        tabBarExtraContent={
          <Dropdown overlay={menu}>
            <Button type="dashed">
              {t("orders.exportToCsv")} <DownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <Tabs.TabPane
          tab={`${
            filters.statuses.length === 2 || filters.statuses.length === 0
              ? t("clients.all")
              : t(`clients.${filters.statuses[0]}`)
          }${searchTerm ? ` (${list.length})` : ""}`}
          key="active"
        >
          <ProductClientsTable details={details} productId={productId} ownerId={ownerId} />
        </Tabs.TabPane>
      </Tabs>

      {keepImportModal && (
        <ImportClientsModal
          visible={showImportModal}
          close={() => toggleImportModal(false)}
          isFree={details.chargeType === ProductChargeType.FREE || false}
        />
      )}
    </Card>
  );
};

const ProductClientsListRouter = ({ productId, ownerId }: { productId: string; ownerId?: string }) => {
  return (
    <Routes>
      <Route path={`/imports/:importId`} element={<span>test</span>} />
      <Route
        path={`/:clientEmail`}
        element={<ProductClientDetails productId={productId} ownerId={ownerId} readonly={Boolean(ownerId)} />}
      />
      <Route
        index
        element={<ProductClientsList productId={productId} ownerId={ownerId} readonly={Boolean(ownerId)} />}
      />
    </Routes>
  );
};

export default ProductClientsListRouter;
