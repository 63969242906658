import { createAsyncThunk } from "@reduxjs/toolkit";

import { ClientActivityWithCalculatedScore } from "@fitness-app/data-models/domain/services/ClientActivityWithCalculatedScore";
import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import {
  type ClientActivity,
  type ClientActivityWithScoreAndTrainer,
} from "@fitness-app/data-models/entities/ClientActivity";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEES_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
  assignedTrainerId?: string | null;
} | void;

export const fetchTraineesActivity = createAsyncThunk<
  {
    page: number;
    list: ClientActivityWithScoreAndTrainer[];
    totalPages: number;
    count: number | null;
  },
  Payload,
  AsyncThunkCreator<string>
>(`${TRAINEES_REDUCER_NAME}/fetchTraineesActivity`, async (payload, { rejectWithValue, extra: { db }, getState }) => {
  try {
    const { activitiesMetadata } = getState().trainees;
    const { currentPage, start } = getPageRange(payload?.page, activitiesMetadata.listSize);

    // Determine the trainer ID to filter by
    let effectiveTrainerId: string | undefined = undefined;
    if (payload?.assignedTrainerId) {
      effectiveTrainerId =
        payload.assignedTrainerId === "none" ? "00000000-0000-0000-0000-000000000000" : payload.assignedTrainerId;
    } else if (activitiesMetadata.currentAssignedTrainerFilter) {
      effectiveTrainerId = activitiesMetadata.currentAssignedTrainerFilter;
    }

    const rpcParams = {
      ...(effectiveTrainerId !== undefined && { p_assigned_trainer_id: effectiveTrainerId }),
      p_trainee_status: TraineeStatus.ACTIVE,
      p_access_type: AppAccessType.FullAccess,
      p_limit: activitiesMetadata.listSize,
      p_offset: start,
    };

    // Call the RPC function
    const { data, error } = await db.rpc("get_client_activities", rpcParams);

    if (error) {
      return rejectWithValue(error.message);
    }

    if (!data) {
      return rejectWithValue("No data received from the server");
    }

    return {
      list:
        (data.body as ClientActivity[])?.map(
          (activity) =>
            new ClientActivityWithCalculatedScore(activity)
              .calculateScore()
              .getReport() as ClientActivityWithScoreAndTrainer,
        ) || [],
      page: currentPage,
      totalPages: data.total_result_set ? Math.ceil(data.total_result_set / activitiesMetadata.listSize) : 1,
      count: data.total_result_set || 0,
    };
  } catch (err) {
    if (err instanceof Error) {
      return rejectWithValue(err.message);
    }
    return rejectWithValue("An unknown error occurred");
  }
});
