import React, { memo, useState } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Descriptions, message, Space, Switch } from "antd";
import { useTranslation } from "react-i18next";

import { programAutomationActions } from "@fitness-app/app-store";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useLoading } from "~/hooks/useLoading";
import ChatConfigurationForm from "~/modules/Automation/ProgramAutomation/components/ChatConfigurationForm/ChatConfigurationForm";
import { type ChatConfigurationFormModel } from "~/modules/Automation/ProgramAutomation/components/ChatConfigurationForm/types";
import { type WelcomeMessagesFormModel } from "~/modules/Automation/WelcomeMessagesForm/types";
import WelcomeMessagesForm from "~/modules/Automation/WelcomeMessagesForm/WelcomeMessagesForm";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const AutomationWelcomeMessages = () => {
  const { t } = useTranslation(["automation", "common"]);
  const selectedAutomation = useAppSelector((store) => store.programAutomation.selectedAutomation);
  const [loading, startLoading, stopLoading] = useLoading();
  const dispatch = useAppDispatch();
  const [showWelcomeMessagesForm, toggleWelcomeMessagesForm] = useState(false);
  const [showChatConfigurationForm, toggleChatConfigurationForm] = useState(false);

  if (!selectedAutomation) {
    return null;
  }

  const toggleWelcomeMessages = async (checked: boolean) => {
    try {
      startLoading();
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            hasActiveWelcomeMessages: checked,
          },
        }),
      ).unwrap();
      stopLoading();
      void message.success(t("saveSuccess"));
    } catch {
      stopLoading();
      void message.error(t("saveError"));
    }
  };

  const onFormSubmit = async (model: WelcomeMessagesFormModel) => {
    try {
      startLoading();
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            hasActiveWelcomeMessages: !!model.welcomeMessages.length,
            welcomeMessages: model.welcomeMessages,
          },
        }),
      ).unwrap();

      toggleWelcomeMessagesForm(false);
      stopLoading();
      void message.success(t("saveSuccess"));
    } catch {
      stopLoading();
      void message.error(t("saveError"));
    }
  };

  const onChatConfigFormSubmit = async (model: ChatConfigurationFormModel) => {
    try {
      startLoading();
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            chatConfiguration: {
              createMarketingChannel: model.createMarketingChannel ?? false,
              sendByDefaultMessageFromAutomationToMarketingChannel:
                model.sendByDefaultMessageFromAutomationToMarketingChannel ?? false,
              marketingChannelTitle: model.marketingChannelTitle || null,
            },
          },
        }),
      ).unwrap();

      toggleChatConfigurationForm(false);
      stopLoading();
      void message.success(t("saveSuccess"));
    } catch {
      stopLoading();
      void message.error(t("saveError"));
    }
  };

  return (
    <>
      <Descriptions bordered layout="vertical">
        <Descriptions.Item
          label={
            <div className="flex justify-between">
              <Space direction="horizontal">
                <span>{t("welcomeMessages")}</span>{" "}
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={selectedAutomation.hasActiveWelcomeMessages ?? false}
                  loading={loading}
                  onChange={toggleWelcomeMessages}
                />
              </Space>
              <div className="flex gap-3">
                <Button
                  type="dashed"
                  onClick={() => toggleChatConfigurationForm(true)}
                  size="small"
                  icon={<SettingOutlined />}
                >
                  {t("chatConfig.configureChat")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => toggleWelcomeMessagesForm(true)}
                  size="small"
                  icon={<EditOutlined />}
                >
                  {t("editWelcomeMessages")}
                </Button>
              </div>
            </div>
          }
        >
          {selectedAutomation.welcomeMessages?.length
            ? selectedAutomation.welcomeMessages.map((item, i) => (
                <li key={i} className="mt-2 whitespace-pre-wrap rounded-md bg-gray-100 p-4">
                  {typeof item === "string" ? item : "Załącznik"}
                </li>
              ))
            : t("noMessages")}
        </Descriptions.Item>
      </Descriptions>
      <ModalForm
        open={showWelcomeMessagesForm}
        onCancel={() => toggleWelcomeMessagesForm(false)}
        title={t("botsConfig.setWelcomeMessages")}
        loading={loading}
      >
        <WelcomeMessagesForm
          onSubmit={onFormSubmit}
          model={
            selectedAutomation
              ? {
                  welcomeMessages:
                    selectedAutomation?.welcomeMessages?.filter((item): item is string => typeof item === "string") ??
                    [],
                }
              : undefined
          }
        />
      </ModalForm>

      <ModalForm
        open={showChatConfigurationForm}
        onCancel={() => toggleChatConfigurationForm(false)}
        title={t("chatConfig.title")}
        loading={loading}
      >
        <ChatConfigurationForm
          model={selectedAutomation ? selectedAutomation.chatConfiguration : undefined}
          onSubmit={onChatConfigFormSubmit}
        />
      </ModalForm>
    </>
  );
};

export default memo(AutomationWelcomeMessages);
