import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TeamMember } from "@fitness-app/data-models";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";
import { fetchTrainerConfig } from "./fetchTrainerConfig";

type Payload = {
  memberId: string;
  data: Partial<TeamMember>;
};

export const updateTrainerInTeam = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/updateTrainerInTeam`,
  async (payload, { rejectWithValue, dispatch, extra: { db, auth, analytics } }) => {
    const { id } = await getLoggedUser(auth);

    const { error } = await db
      .from("team_member")
      .update({ ...payload.data, updatedAt: new Date().toISOString() })
      .eq("id", payload.memberId);

    if (error) {
      return rejectWithValue(error.message);
    }

    void dispatch(fetchTrainerConfig());

    analytics.track("trainer-updated-team-member", { trainerId: id, memberId: payload.memberId });
  },
);
