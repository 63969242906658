import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import { resetPasswordFailed, resetPasswordStarted, resetPasswordSuccess } from "../reducer";

export interface LoginData {
  password: string;
  oldPassword?: string;
}

export const setUserPassword =
  ({ password, oldPassword }: LoginData, callback?: () => void): AppThunk =>
  async (dispatch, _, { auth }) => {
    dispatch(resetPasswordStarted());
    try {
      const { email } = await getLoggedUser(auth);

      if (oldPassword) {
        await auth.signInWithPassword({ email: email, password: oldPassword });
      }

      const { error } = await auth.updateUser({ password });

      if (error) {
        dispatch(resetPasswordFailed(error.message));
        return;
      }
      if (callback) {
        callback();
      }

      dispatch(resetPasswordSuccess());
    } catch (e) {
      dispatch(resetPasswordFailed("unknown-error"));
    }
  };
