import { createAsyncThunk } from "@reduxjs/toolkit";

import { type IngredientCategory } from "@fitness-app/data-models/entities/Ingredient";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchIngredientCategories = createAsyncThunk<IngredientCategory[], Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/fetchIngredientCategories`,
  async (payload, { rejectWithValue, extra: { db }, getState }) => {
    const fetched = getState().nutrition.ingredientCategories;
    if (fetched.length) {
      return fetched;
    }

    const { error, data } = await db.from("ingredient_main_category").select("*").order("id");

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
