import React, { useState, type FunctionComponent } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Empty,
  message,
  Modal,
  Popconfirm,
  Popover,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { productAutomationActions, RequestStatus } from "@fitness-app/app-store";
import { type Webhook } from "@fitness-app/data-models/entities/Automation";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import {
  type WebhookFormModel,
  type WebhookWithArrayPayload,
} from "~/modules/Products/Product/ProductAutomation/components/WebhookForm/types";
import WebhookForm from "~/modules/Products/Product/ProductAutomation/components/WebhookForm/WebhookForm";
import {
  formatAuthFromModel,
  formatAuthIntoFormModel,
} from "~/modules/Products/Product/ProductAutomation/components/Webhooks/formatAuth";
import {
  formatWebhookPayloadFromModel,
  formatWebhookPayloadIntoModel,
} from "~/modules/Products/Product/ProductAutomation/components/Webhooks/formatWebhookPayload";
import { secretsApi, useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

const Webhooks: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation("products");
  const { data, status, error } = useAppSelector((store) => store.productAutomation);
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();
  const [model, setModel] = useState<null | WebhookWithArrayPayload>(null);
  const [processedId, setProcessedId] = useState<null | string>(null);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(() => {
    toggleModal(false);
    setModel(null);
  });
  const [removing, onRemoveSuccess, onRemoveFailure, onStartRemoving] = useEntityChange();
  const [fetchedKey, setFetchedKey] = useState<{
    path: string;
    key: string | null;
  } | null>(null);

  const handleWebhookUpdate = (formData: WebhookFormModel, webhookToUpdate: Webhook) => {
    const { toSave, toProcess } = formatAuthFromModel(formData);

    const { auth, authType, ...rest } = formData;
    const webhook = {
      ...rest,
      id: webhookToUpdate.id,
      payload: formatWebhookPayloadFromModel(formData.payload),
      auth: toSave,
    } as Webhook;
    onStart();
    void dispatch(
      productAutomationActions.updateProductAutomation({
        payload: {
          productId,
          webhook,
          action: "update",
          authToProcess: toProcess,
        },
        onSuccess,
        onFailure,
      }),
    );
  };

  const handleWebhookSave = (formData: WebhookFormModel) => {
    const webhookToUpdate = data?.webhooks?.find((webhook) => webhook.id === model?.id);
    if (webhookToUpdate) {
      handleWebhookUpdate(formData, webhookToUpdate);
      return;
    }
    const { toSave, toProcess } = formatAuthFromModel(formData);

    const { auth, authType, ...rest } = formData;
    const webhook = {
      ...rest,
      id: model?.id || uuid(),
      payload: formatWebhookPayloadFromModel(formData.payload),
      auth: toSave,
    } as Webhook;

    onStart();
    void dispatch(
      productAutomationActions.updateProductAutomation({
        payload: {
          productId,
          webhook,
          action: "add",
          authToProcess: toProcess,
        },
        onSuccess,
        onFailure,
      }),
    );
  };

  const handleWebhookRemove = (webhook: Webhook) => {
    onStartRemoving(webhook.id);
    void dispatch(
      productAutomationActions.updateProductAutomation({
        payload: {
          productId,
          webhook,
          action: "remove",
        },
        onSuccess: onRemoveSuccess,
        onFailure: onRemoveFailure,
      }),
    );
  };

  const getApiKey = async (auth: Webhook["auth"], webhookdId: string) => {
    setFetchedKey({ path: webhookdId, key: null });
    try {
      const res = await secretsApi.post<Record<string, string>>("/get-secret", {
        productId,
        connectedResource: "webhook",
        resourceId: webhookdId,
        auth,
      });

      if (!res?.data?.body) {
        throw new Error("API Key not exists");
      }

      setFetchedKey({ path: webhookdId, key: "fetched" });

      Modal.info({
        title: t("productAutomation.authData"),
        width: 550,
        content: (
          <Descriptions column={1} bordered layout="horizontal">
            {Object.entries(res.data.body).map(([key, value]) => (
              <Descriptions.Item label={t(`productAutomation.form.${key}`)} key={key}>
                {value || "-"}
              </Descriptions.Item>
            ))}
          </Descriptions>
        ),
        onOk: () => setFetchedKey(null),
        onCancel: () => setFetchedKey(null),
      });
    } catch (err) {
      setFetchedKey(null);
      if (err instanceof Error) {
        void message.error(err.message);
      }
    }
  };

  const handleEdit = (webhook: Webhook) => {
    setModel({
      ...webhook,
      payload: formatWebhookPayloadIntoModel(webhook.payload),
      ...formatAuthIntoFormModel(webhook),
    });
    toggleModal(true);
  };

  const renderAuthCell = (auth: Webhook["auth"], webhook: Webhook) => {
    if (!auth) {
      return t("productAutomation.none");
    }

    if (fetchedKey?.path === webhook.id && !fetchedKey.key) {
      return <Spin size="small" />;
    }

    return (
      <Space direction="horizontal">
        <span>{t(`productAutomation.auth.${auth.type}`)}</span>
        <Button size="small" onClick={() => getApiKey(auth, webhook.id)}>
          {t("productAutomation.showKey")}
        </Button>
      </Space>
    );
  };

  const handleActiveChange = async (state: boolean, webhook: Webhook) => {
    setProcessedId(webhook.id);
    await dispatch(
      productAutomationActions.updateProductAutomation({
        payload: {
          productId,
          webhook: {
            ...webhook,
            active: state,
          },
          action: "update",
        },
      }),
    );
    setProcessedId(null);
  };

  return (
    <Card
      bordered={false}
      className="!shadow-none"
      loading={status === RequestStatus.SUBSCRIBING}
      extra={
        data?.webhooks?.length && (
          <Button type="primary" onClick={() => toggleModal(true)}>
            {t("button.addWebhook")}
          </Button>
        )
      }
    >
      {error && <Alert closable showIcon message={error} type="error" />}
      <Table<Webhook>
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("productAutomation.emptyWebhookList")}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal(true)}>
                {t("productAutomation.addFirstWebhook")}
              </Button>
            </Empty>
          ),
        }}
        dataSource={data?.webhooks ?? []}
      >
        <Table.Column<Webhook>
          title={t("productAutomation.table.url")}
          dataIndex="url"
          key="url"
          ellipsis
          width={350}
          render={(url: string) => (
            <Tooltip title={url}>
              <div
                style={{
                  maxWidth: 350,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {url}
              </div>
            </Tooltip>
          )}
        />
        <Table.Column<Webhook>
          title={t("productAutomation.table.description")}
          dataIndex="description"
          key="description"
          render={(rowText: string) =>
            rowText ? (
              <Popover content={rowText} overlayStyle={{ maxWidth: 300 }}>
                <InfoCircleOutlined style={{ fontSize: 20 }} />
              </Popover>
            ) : null
          }
        />
        <Table.Column<Webhook>
          title={t("productAutomation.table.method")}
          dataIndex="method"
          key="method"
          render={(text: string) => {
            return text?.toUpperCase() || "-";
          }}
        />
        <Table.Column<Webhook>
          title={t("productAutomation.table.auth")}
          dataIndex="auth"
          key="auth"
          render={renderAuthCell}
        />
        <Table.Column<Webhook>
          title={t("productAutomation.table.triggers")}
          dataIndex="triggers"
          key="triggers"
          render={(events: string[]) => (
            <Space direction="horizontal">
              {events.map((event) => (
                <Tag key={event}>{t(`common:events.product.${event.split(".").pop()}`)}</Tag>
              ))}
            </Space>
          )}
        />
        <Table.Column<Webhook>
          title={t("productAutomation.table.active")}
          dataIndex="active"
          key="active"
          render={(active: boolean, row) => (
            <Switch
              loading={processedId === row.id}
              checked={active}
              onChange={(state) => handleActiveChange(state, row)}
            />
          )}
        />
        <Table.Column<Webhook>
          title={t("list.columns.options")}
          key="action"
          align="left"
          render={(_, row: Webhook) => (
            <Space>
              <Button disabled type="link" onClick={() => handleEdit(row)}>
                {t("common:button.edit")}
              </Button>
              <Popconfirm
                overlayStyle={{ maxWidth: 400 }}
                placement="leftTop"
                title={t("webhook.deleteWarning")}
                onConfirm={() => handleWebhookRemove(row)}
              >
                <Button type="link" danger loading={typeof removing === "string" && removing === row.id}>
                  {t("common:button.delete")}
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <ModalForm<WebhookFormModel>
        onCancel={() => {
          toggleModal(false);
          setModel(null);
        }}
        visible={showModal}
        title={t("productAutomation.addWebhook")}
        width={850}
        loading={!!loading}
      >
        <WebhookForm onSubmit={handleWebhookSave} model={model} />
      </ModalForm>
    </Card>
  );
};

export default Webhooks;
