import React, { useEffect, type FunctionComponent } from "react";
import { Checkbox, Form, Input, InputNumber } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { DEFAULT_VAT_RATE } from "../constants";
import { useVatIssuer, type InvoiceFormController } from "../hooks/useVatIssuer";
import { type IFakturowniaIntegrationForm } from "./types";

interface OwnProps {
  formController?: FormInstance<IFakturowniaIntegrationForm>;
  onSubmit: (formData: IFakturowniaIntegrationForm) => void;
  model?: IFakturowniaIntegrationForm | null;
}

type Props = OwnProps;

const FakturowniaIntegrationForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["settings", "common"]);
  const { isVatIssuer, onChangeVatIssuerField } = useVatIssuer(
    model?.settings?.isVatIssuer,
    formController as InvoiceFormController,
  );

  useEffect(() => {
    if (!model) {
      formController?.resetFields();
    } else {
      formController?.setFieldsValue(model);
    }
  }, [model]);
  return (
    <div className="min-w-[450px]">
      <Form<IFakturowniaIntegrationForm>
        name="invoice-integration-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        initialValues={{
          url: model?.url ?? "",
          settings: { isVatIssuer, defaultVatRate: DEFAULT_VAT_RATE },
        }}
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="accountName"
          label={t("invoices.accountName")}
          tooltip={t("invoices.fakturownia.accountName")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input
            autoFocus={!model}
            disabled={!!model?.url}
            placeholder={t<string>("invoices.placeholder.accountName")}
          />
        </Form.Item>
        <Form.Item
          name="url"
          label={t("invoices.url")}
          tooltip={t("invoices.fakturownia.apiUrL")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("invoices.placeholder.apiUrl")} />
        </Form.Item>

        <Form.Item
          name="apiKey"
          tooltip={t("invoices.fakturownia.tooltip")}
          label={t("invoices.apiKey")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus={!!model} />
        </Form.Item>

        <Form.Item
          name="departmentId"
          label={t("invoices.departmentId")}
          tooltip={t("invoices.fakturownia.departmentId")}
        >
          <Input placeholder={t<string>("invoices.placeholder.departmentId")} />
        </Form.Item>

        <Form.Item name={["settings", "isVatIssuer"]} valuePropName="checked">
          <Checkbox onChange={(e) => onChangeVatIssuerField(e.target.checked)}>{t("invoices.isVatIssuer")}</Checkbox>
        </Form.Item>

        {isVatIssuer ? (
          <Form.Item
            label={t("invoices.defaultVatRate")}
            name={["settings", "defaultVatRate"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber<string | number>
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value?.replace("%", "") ?? 0}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={t("invoices.legalVATExemption")}
            name={["settings", "legalVatExemption"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default FakturowniaIntegrationForm;
