import { createAsyncThunk } from "@reduxjs/toolkit";
import { type InvoicesClient } from "@fitness-app/data-models/entities/Product";
import { type InvoiceSettings } from "@fitness-app/data-models/entities/ProductsSettings";
import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export type Payload =
  | {
      url: string;
      apiKey: string;
      client: InvoicesClient;
      accountName: string;
      departmentId?: string;
      accountApiKey?: string;
      settings?: InvoiceSettings | null;
    }
  | {
      url: string;
      clientSecret: string;
      client: InvoicesClient.wFirma;
      accountName: string;
      settings?: InvoiceSettings | null;
    };

export const integrateWithInvoicesClient = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/integrateWithInvoicesClient`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { integrationApi, analytics } }) => {
    try {
      await integrationApi.post("integrate-with-invoices-client", payload);

      analytics.track("integrate_with_invoices_client", {
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
