import React, { useCallback, useEffect, useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, UserAddOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, DatePicker, Empty, Form, Input, Select, Table, Tooltip, Typography } from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { EMAIL_REGEX } from "@fitness-app/utils/src/constants/emailRegex";

import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";
import { type ManualItemModel, type TableModel } from "../ImportClientsModal";

interface OwnProps {
  setPriceException: (email: string, priceId: string, id?: string) => void;
  setDateException: (email: string, date: dayjs.Dayjs | null, id?: string) => void;
  setDateSubscriberException: (id: string, date: dayjs.Dayjs | null) => void;
  setEmail: (id: string, value: string) => void;
  setName: (id: string, value: string) => void;
  setPhone: (id: string, value: string) => void;
  tableModel: TableModel[];
  setSelectedRows: (keys: string[]) => void;
  selectedClients: string[];
  addNewRow: () => void;
  removeItem: (id: string) => void;
  isFree: boolean;
}

type Props = OwnProps;

const EmailField = (row: ManualItemModel & { setEmail: OwnProps["setEmail"] }) => {
  const [inputValue, setValue] = useState(row.email);
  const [debouncedValue] = useDebounce(inputValue, 700);
  const [hasError, setState] = useState<boolean>(false);

  useEffect(() => {
    if (debouncedValue) {
      setState(!debouncedValue.match(EMAIL_REGEX));
    } else {
      setState(false);
    }
    row.setEmail(row.id, debouncedValue);
  }, [debouncedValue]);

  return (
    <Form.Item hasFeedback={hasError} style={{ marginBottom: -2 }} validateStatus={hasError ? "error" : undefined}>
      <Input value={inputValue} onChange={(e) => setValue(e.target.value?.trim())} />
    </Form.Item>
  );
};

const NameField = (row: ManualItemModel & { setName: OwnProps["setName"] }) => {
  const [inputValue, setValue] = useState(row.name);
  const [debouncedValue] = useDebounce(inputValue, 700);

  useEffect(() => {
    row.setName(row.id, debouncedValue);
  }, [debouncedValue]);

  return <Input value={inputValue} onChange={(e) => setValue(e.target.value)} />;
};

const SubscribeDateField = (
  row: ManualItemModel & {
    setDateSubscriberException: OwnProps["setDateSubscriberException"];
  },
) => {
  const getDisabledDate = useCallback((current: dayjs.Dayjs) => current && current > dayjs().endOf("day"), []);
  return (
    <DatePicker
      format="DD.MM.YYYY"
      disabledDate={getDisabledDate}
      value={row.subscribedDate || undefined}
      allowClear={false}
      onChange={(date) => row.setDateSubscriberException(row.id, date)}
    />
  );
};

const ParseClientsList: FunctionComponent<Props> = ({
  setPriceException,
  tableModel,
  setDateException,
  setSelectedRows,
  selectedClients,
  addNewRow,
  setEmail,
  setDateSubscriberException,
  setName,
  removeItem,
  isFree,
  setPhone,
}) => {
  const { t } = useTranslation("products");
  const productPricesOptions = useProductPricesOptions();

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedClients,
      onChange: (selected: React.Key[]) => setSelectedRows(selected as string[]),
      getCheckboxProps: (record: TableModel) => ({
        name: record.email,
      }),
    }),
    [setSelectedRows, selectedClients],
  );

  const getDisabledDate = useCallback((current: dayjs.Dayjs) => current && current < dayjs().endOf("day"), []);

  return (
    <div className="py-5">
      <Table<TableModel>
        size="small"
        dataSource={tableModel}
        pagination={false}
        rowKey={(item) => item.id || item.email}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
              <Button icon={<UserAddOutlined />} type="primary" onClick={addNewRow}>
                {t("import.addFirstClient")}
              </Button>
            </Empty>
          ),
        }}
      >
        <Table.Column<TableModel>
          title={t("productTransactions.tableHeader.index")}
          width={42}
          dataIndex="index"
          ellipsis
          key="index"
          render={(name, row, i) => i + 1}
        />
        <Table.Column<TableModel | ManualItemModel>
          title={t("productTransactions.tableHeader.name")}
          dataIndex="name"
          key="name"
          width={isFree ? undefined : 150}
          render={(name: string, row) =>
            row.id ? <NameField {...(row as ManualItemModel)} setName={setName} /> : name
          }
        />
        <Table.Column<TableModel | ManualItemModel>
          title={t("productTransactions.tableHeader.email")}
          dataIndex="email"
          key="email"
          width={isFree ? undefined : 170}
          render={(email: string, row) =>
            row.id ? <EmailField {...(row as ManualItemModel)} setEmail={setEmail} /> : email
          }
          ellipsis
        />
        <Table.Column<TableModel | ManualItemModel>
          title={t("productTransactions.tableHeader.phoneNumber")}
          dataIndex="phoneNumber"
          key="phoneNumber"
          render={(email: string, row) =>
            row.id ? <NameField {...(row as ManualItemModel)} setName={setPhone} /> : email
          }
          ellipsis
        />
        <Table.Column<TableModel | ManualItemModel>
          title={t("productClients.tableHeader.createdAt")}
          dataIndex="subscribedDate"
          key="subscribedDate"
          width={isFree ? undefined : 150}
          render={(subscribedDate: string, row) =>
            row.id ? (
              <SubscribeDateField
                {...(row as ManualItemModel)}
                setDateSubscriberException={setDateSubscriberException}
              />
            ) : (
              dayjs(subscribedDate).format("DD.MM.YYYY")
            )
          }
        />
        {!isFree && (
          <>
            <Table.Column<TableModel | ManualItemModel>
              title={t("import.headerForm.productPrice")}
              dataIndex="priceId"
              width={220}
              key="priceId"
              render={(priceId: string, row) => {
                return (
                  <Select
                    style={{ width: 200 }}
                    placeholder={t("import.headerForm.productPriceSelect")}
                    options={productPricesOptions}
                    value={priceId}
                    onChange={(value) => setPriceException(row.email, value, row.id)}
                  />
                );
              }}
            />
            <Table.Column<TableModel>
              title={t("import.headerForm.accessTo")}
              dataIndex="accessTo"
              key="accessTo"
              width={350}
              render={(accessTo: Dayjs | null, { isAfterToday, email, id }) => (
                <div className="flex items-center">
                  <DatePicker
                    format="DD.MM.YYYY HH:mm"
                    disabledDate={getDisabledDate}
                    value={accessTo || undefined}
                    allowClear={false}
                    onChange={(date) => setDateException(email, date, id)}
                  />
                  {isAfterToday && (
                    <Tooltip title={t("import.wrongDateTooltip")}>
                      <div>
                        <WarningOutlined className="my-0 ml-1 mr-2 text-lg text-red-600" />
                        <Typography.Text type="danger">{t("import.wrongDate")}</Typography.Text>
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            />
          </>
        )}

        <Table.Column<TableModel | ManualItemModel>
          dataIndex="action"
          key="action"
          render={(_, { id }) =>
            id ? (
              <DeleteOutlined
                style={{ color: "#f64e60", fontSize: 16, cursor: "pointer" }}
                onClick={() => removeItem(id)}
              />
            ) : null
          }
        />
      </Table>

      <Button icon={<UserAddOutlined />} type="primary" onClick={addNewRow} style={{ marginTop: 30 }}>
        {t("import.addClientManually")}
      </Button>
    </div>
  );
};

export default ParseClientsList;
