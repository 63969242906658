import { useEffect, useState } from "react";

import { nutritionActions } from "@fitness-app/app-store";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";

import { useEntityChange } from "~/hooks/useEntityChange";
import {
  transformToProductTypesArray,
  transformUpdateModelToEntity,
} from "~/modules/Nutrition/AddIngredientForm/transformModelToEntity";
import { type AddIngredientFormModel } from "~/modules/Nutrition/AddIngredientForm/types";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { useAppDispatch } from "~/store/initializeStore";

export const useEditIngredient = () => {
  const [showEditIngredientModal, setShowEditIngredientModal] = useState(false);
  const [editModel, setEditModel] = useState<Ingredient | null>(null);
  const [editFormModel, setEditFormModel] = useState<AddIngredientFormModel | null>(null);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const dispatch = useAppDispatch();
  const { measuresMap } = useNutritionMeasures();

  useEffect(() => {
    if (editModel) {
      setEditFormModel({
        nutrients: { ...editModel.nutrients, salt: editModel.nutrients.salt ?? 0 },
        image: editModel.image,
        name: editModel.name,
        brand: editModel.brand,
        barCode: editModel.barCode,
        manufacturer: editModel.manufacturer,
        calories: editModel.nutrients.calories,
        liquid: editModel.liquid ?? false,
        nutritionPer: "grams",
        caloriesUnit: editModel.unit === "gram" ? "grams" : "millilitres",
        productTypes: transformToProductTypesArray(editModel),
        mainCategory: null,
        rawIngredients: editModel.rawIngredients || "",
        measures: editModel.measures
          .filter((measure) => measure.id !== 1)
          .map((measure) => ({
            value: measure.id,
            weightPerUnit: measure.weightPerUnit,
            unit: measure.unit as "ml" | "grams",
          })),
      });
      setShowEditIngredientModal(true);
    }
  }, [editModel]);

  const saveUpdatedIngredient = async (model: AddIngredientFormModel) => {
    if (editModel) {
      onStart();
      try {
        await dispatch(
          nutritionActions.editIngredient({
            ingredient: transformUpdateModelToEntity(model, measuresMap, editModel),
          }),
        ).unwrap();
        onSuccess();
        closeEditModal();
      } catch (e) {
        onFailure();
      }
    }
  };

  const closeEditModal = () => {
    setShowEditIngredientModal(false);
    setTimeout(() => {
      setEditModel(null);
      setEditFormModel(null);
    }, 500);
  };

  return {
    showEditIngredientModal,
    setShowEditIngredientModal,
    editModel,
    setEditModel,
    closeEditModal,
    editFormModel,
    saveUpdatedIngredient,
    updating: loading,
  };
};
