import {
  type ClientNotifications,
  type NotificationType,
  type OwnerNotifications,
  type TrainerNotifications,
} from "@fitness-app/data-models/entities/Notification";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import {
  addNotificationToList,
  deleteNotificationFromList,
  setNumberOfUnreadNotifications,
  subscribeToNotificationsFailed,
  subscribeToNotificationsPending,
  subscribeToNotificationsSuccess,
  updateNotificationsOnList,
} from "../reducer";
import { fetchNotifications } from "./fetchNotifications";
import { unsubscribeFromNotifications } from "./unsubscribeFromNotifications";

export const subscribeToNotifications =
  (filter: "all" | "unread", type?: NotificationType[]): AppThunk =>
  async (dispatch, getState, { auth, subscriptions, db }) => {
    try {
      dispatch(subscribeToNotificationsPending());

      await dispatch(fetchNotifications({ filter, type }));

      const { id } = await getLoggedUser(auth);

      if (subscriptions.notificationsChannelName && subscriptions.realtimeNotificationsChannel) {
        await db.removeChannel(subscriptions.realtimeNotificationsChannel);
      }

      subscriptions.notificationsChannelName = `notifications-${id}`;
      subscriptions.realtimeNotificationsChannel = db.channel(subscriptions.notificationsChannelName);

      subscriptions.realtimeNotificationsChannel
        .on<ClientNotifications | OwnerNotifications | TrainerNotifications>(
          "postgres_changes",
          { event: "*", schema: "public", table: "notification", filter: `ownerId=eq.${id}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateNotificationsOnList(payload.new));
            }
            if (payload.eventType === "INSERT") {
              if (type?.length) {
                if (type.includes(payload.new.type)) {
                  dispatch(addNotificationToList(payload.new));
                }
              } else {
                dispatch(addNotificationToList(payload.new));
              }
              if (payload.new.status === "unread") {
                dispatch(setNumberOfUnreadNotifications(getState().notifications.totalUnread + 1));
              }
            }
            if (payload.eventType === "DELETE" && payload.old.id) {
              dispatch(deleteNotificationFromList(payload.old.id));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToNotificationsSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromNotifications());
          }
        });
    } catch {
      dispatch(subscribeToNotificationsFailed());
    }
  };
