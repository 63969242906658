import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type Trainee } from "@fitness-app/data-models/entities/Trainee";
import {
  type ClientTrainingProgram,
  type ClientTrainingProgramDetails,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { updateTraineeProfile } from "../../trainee/reducer";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";
import { fetchProgramWithDetails } from "./fetchProgramWithDetails";

type Payload = {
  traineeId: string;
  program: ClientTrainingProgram;
  programDetails: ClientTrainingProgramDetails;
  hasActiveProgram: boolean;
  keepOldTrainingWithoutStatus: boolean;
};

export const addProgramToTrainee = createAsyncThunk<Trainee, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/addProgramToTrainee`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, trainersApi, analytics } }) => {
    const { profile } = getState().trainee;

    if (profile?.activeTrainingProgramId) {
      try {
        await trainersApi.post("/trainee/program-archive", {
          programId: profile.activeTrainingProgramId,
          traineeId: payload.traineeId,
          startDateOfNextProgram: payload.program.startDate || dayjs().format("YYYY-MM-DD"),
          keepOldWorkoutsWithoutStatus: payload.keepOldTrainingWithoutStatus,
        });
      } catch (e) {
        return rejectWithValue(getErrorMessage(e));
      }
    }

    const { error: errorProgram } = await db.from("client_training_program").insert(payload.program);

    if (errorProgram) {
      return rejectWithValue(errorProgram.message);
    }

    await db.from("client_training_program_details").insert(payload.programDetails);

    const { error, data } = await db
      .from("trainee")
      .update({
        activeTrainingProgramId: payload.program.id,
        updatedAt: new Date().toISOString(),
        hasActiveTrainingProgram: payload.hasActiveProgram,
      })
      .eq("id", payload.traineeId)
      .single<Trainee>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_program_to_trainee");
    void dispatch(fetchProgramWithDetails({ id: payload.program.id }));

    dispatch(updateTraineeProfile(data));

    return data;
  },
);
