import { createSelector } from "@reduxjs/toolkit";

import { type AppStore } from "../../../index";

const getOrders = (store: AppStore) => store.productOrders.orders;
const getSearchTerm = (store: AppStore) => store.productOrders.searchTerm;

export const getFilteredOrders = createSelector([getOrders, getSearchTerm], (orders, searchTerm) => {
  if (!searchTerm?.length) {
    return orders;
  }

  const searchInLowerCase = searchTerm.toLowerCase();

  return orders.filter((order) => {
    if (order.clientEmail.toLowerCase().includes(searchInLowerCase)) {
      return true;
    }
    if (order.client.lastName.toLowerCase().includes(searchInLowerCase)) {
      return true;
    }

    return false;
  });
});
