import { type UserProfile } from "@fitness-app/data-models";
import { type CustomClaims } from "@fitness-app/data-models/entities/UserProfile";

import { type RequestStatus } from "../../../enums/requestStatus";

export const USER_REDUCER = "User";
export enum UserStatus {
  CREATING_NEW_ACCOUNT = "CREATING_NEW_ACCOUNT",
  LOGGING_IN = "LOGGING_IN",
  IS_LOGGED = "IS_LOGGED",
  IS_LOGGED_OUT = "IS_LOGGED_OUT",
  LOGGING_OUT = "LOGGING_OUT",
  HAS_ERROR = "HAS_ERROR",
}

export interface AuthUser {
  email?: string | null;
  id: string;
  customClaims?: Record<string, string>;
}

export interface UserReducer {
  appVersion: string | null;
  status: null | UserStatus;
  data: null | AuthUser;
  profile: UserProfile | null;
  error: null | string;
  registerError: null | string;
  profileStatus: RequestStatus | null;
  passwordStatus: RequestStatus | null;
  updateUserDataStatus: RequestStatus | null;
  sendPasswordResetEmailStatus: RequestStatus | null;
  sendLoginLinkStatus: RequestStatus | null;
  customClaims: null | CustomClaims;
}
