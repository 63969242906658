import React, { useMemo } from "react";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Menu } from "antd";
import { Scale, TrendingUp } from "lucide-react";
import { useTranslation } from "react-i18next";

import ModalForm from "~/components/ModalForm/ModalForm";
import BodyMeasurementForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/BodyMeasurementForm/BodyMeasurementForm";
import { type BodyMeasurementFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/BodyMeasurementForm/types";
import { useBodyMeasurementForm } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/BodyMeasurementForm/useBodyMeasurementForm";
import TraineeMeasurementsTable from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeMeasurementsTable";
import { type WeightMeasurementFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/WeightMeasurementForm/types";
import { useWeightMeasurementForm } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/WeightMeasurementForm/useWeightMeasurementForm";
import WeightMeasurementForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/WeightMeasurementForm/WeightMeasurementForm";

interface TraineeBodyWeightMesurementsProps {
  traineeId: string;
}

const TraineeBodyWeightMeasurements = ({ traineeId }: TraineeBodyWeightMesurementsProps) => {
  const { t } = useTranslation(["trainees", "common"]);
  const { showWeightModal, toggleWeightModal, addingWeightMeasurement, onAddNewWeightMeasurement } =
    useWeightMeasurementForm();
  const { showBodyMeasurementModal, toggleBodyMeasurementModal, addingBodyMeasurement, onAddNewBodyMeasurement } =
    useBodyMeasurementForm();

  const ctaMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item
          key="weightMeasurement"
          icon={<Scale size={16} className="align-text-top" color="rgb(38, 153, 251)" />}
          onClick={() => toggleWeightModal(true)}
        >
          {t("cta.weightMeasurement")}
        </Menu.Item>
        <Menu.Item
          key="bodyMeasurement"
          icon={<TrendingUp size={16} className="align-text-top" color="rgb(38, 153, 251)" />}
          onClick={() => toggleBodyMeasurementModal(true)}
        >
          {t("cta.bodyMeasurement")}
        </Menu.Item>
      </Menu>
    ),
    [t],
  );

  return (
    <>
      <Card
        bordered={false}
        className="!shadow-none"
        extra={
          <Dropdown overlay={ctaMenu}>
            <Button type="primary" icon={<PlusOutlined />}>
              {t("common:button.add")} <DownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <TraineeMeasurementsTable traineeId={traineeId} />
      </Card>
      <ModalForm<WeightMeasurementFormModel>
        open={showWeightModal}
        onCancel={() => toggleWeightModal(false)}
        title={t("addWeightModalTitle")}
        loading={addingWeightMeasurement}
      >
        <WeightMeasurementForm onSubmit={(form) => onAddNewWeightMeasurement(form, traineeId)} traineeId={traineeId} />
      </ModalForm>

      <ModalForm<BodyMeasurementFormModel>
        open={showBodyMeasurementModal}
        onCancel={() => toggleBodyMeasurementModal(false)}
        title={t("addBodyModalTitle")}
        loading={addingBodyMeasurement}
      >
        <BodyMeasurementForm onSubmit={(form) => onAddNewBodyMeasurement(form, traineeId)} traineeId={traineeId} />
      </ModalForm>
    </>
  );
};

export default TraineeBodyWeightMeasurements;
