import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { TrainingProgramLevel, TrainingProgramType } from "@fitness-app/data-models/entities/TrainingProgram";

import { useTagOptions } from "~/hooks/useTagOptions";
import { programLevelOptions, programTypeOptions, type ProgramFormModel } from "./types";

interface ProgramFormProps {
  formController?: FormInstance<ProgramFormModel>;
  onSubmit: (formData: ProgramFormModel) => void;
  model?: Partial<ProgramFormModel> | null;
}

const ProgramForm = ({ model, onSubmit, formController }: ProgramFormProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const { options: tagsOptions } = useTagOptions("trainingProgramTags");

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <Form<ProgramFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        name: "",
        level: TrainingProgramLevel.BEGINNER,
        type: TrainingProgramType.FULL_BODY_WORKOUT,
        comment: "",
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="name"
        tooltip={t<string>("program.nameInfo")}
        label={t<string>("program.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="level"
        label={t<string>("program.level")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select options={programLevelOptions} />
      </Form.Item>

      <Form.Item
        name="type"
        label={t<string>("program.type")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select options={programTypeOptions} />
      </Form.Item>

      <Form.Item name="tags" label={t<string>("program.tags")}>
        <Select notFoundContent={false} options={tagsOptions} mode="tags" />
      </Form.Item>

      <Form.Item
        name="comment"
        label={t<string>("program.comment")}
        rules={[
          {
            required: false,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
    </Form>
  );
};

export default ProgramForm;
