import PartySocket from "partysocket";

import { getErrorMessage } from "@fitness-app/utils";
import { parseIncomingClientRealtimeMessage } from "@fitness-app/utils/src/chat/realtimeParsers";
import { RealtimeRoom } from "@fitness-app/utils/src/notifications/realtimeRooms";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import {
  addMessageToChannel,
  removeMessageFromChannel,
  subscribeToChannelFailed,
  subscribeToChannelSuccess,
  updateMessageInChannel,
  updateMessagesToMark,
} from "../reducer";
import { unsubscribeFromChannelMessages } from "./unsubscribeFromChannelMessages";

export const subscribeToChannelMessage =
  (channelId: string): AppThunk =>
  async (dispatch, getState, { auth, parties, config }) => {
    try {
      const { id } = await getLoggedUser(auth);

      if (parties.chatRooms[channelId]) {
        parties.chatRooms[channelId]?.close();
      }

      let socket = parties.chatRooms[channelId];

      if (!socket) {
        const token = (await auth.getSession())?.data?.session?.access_token;
        socket = new PartySocket({
          host: config.realtimeServerUrl || "localhost:1999",
          room: channelId,
          party: RealtimeRoom.ChatRoom,
          query: () => ({
            token: token,
          }),
        });

        parties.chatRooms[channelId] = socket;
      }

      socket.addEventListener("message", (event: WebSocketEventMap["message"]) => {
        const parsedEvent = parseIncomingClientRealtimeMessage(event.data);

        if (parsedEvent.type === "initial") {
          dispatch(subscribeToChannelSuccess({ channelId }));
        }
        if (parsedEvent.type === "message") {
          if (parsedEvent.operation === "add") {
            dispatch(addMessageToChannel({ message: parsedEvent.data, channelId }));
            const { activeChannels } = getState().chat;
            const foundChannel = activeChannels.find((channel) => channel.id === channelId);
            if (foundChannel && !foundChannel.minimized) {
              dispatch(
                updateMessagesToMark({
                  messages: [parsedEvent.data].filter((item) => item.authorId !== id).map((item) => item.id),
                  channelId,
                }),
              );
            }
          }

          if (parsedEvent.operation === "delete" || (parsedEvent.operation === "update" && parsedEvent.data.deleted)) {
            dispatch(removeMessageFromChannel({ messageId: parsedEvent.data.id, channelId }));
          }

          if (parsedEvent.operation === "update" && !parsedEvent.data.deleted) {
            dispatch(updateMessageInChannel({ message: parsedEvent.data, channelId }));
          }
        }
      });

      socket.addEventListener("close", () => {
        const activeChannels = getState().chat.activeChannels;
        if (activeChannels.find((channel) => channel.id === channelId)) {
          dispatch(subscribeToChannelMessage(channelId));
        } else {
          dispatch(unsubscribeFromChannelMessages(channelId));
        }
      });

      socket.addEventListener("error", (error) => {
        dispatch(subscribeToChannelFailed(getErrorMessage(error)));
      });
    } catch (error) {
      dispatch(subscribeToChannelFailed(getErrorMessage(error)));
    }
  };
