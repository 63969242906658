import { useEffect, useMemo } from "react";
import uniqBy from "lodash.uniqby";

import { botsConfigActions, teamActions, userSelectors } from "@fitness-app/app-store";
import { UserRole, type UserRoleEnum } from "@fitness-app/data-models";
import { CURRENT_ASSIGNED_TRAINER } from "@fitness-app/data-models/contants/currentAssignedTrainer";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const useAuthorOptions = (
  model?: Partial<{ authorId?: string; authorName?: string; authorRole?: UserRoleEnum }>,
  withAssignedTrainerOption = false,
  withCurrentClient = true,
) => {
  const teamTrainers = useAppSelector((store) => store.team.trainers);
  const clientData = useAppSelector((store) => store.trainee.profile);
  const userDetails = useAppSelector((store) => store.user.profile);
  const bots = useAppSelector((store) => store.botsConfig.list);
  const parentTrainerId = useAppSelector(userSelectors.getParentTrainerId);
  const { isTrainer, isTeamMember } = useUserRole();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(botsConfigActions.fetchChatBots());
    void dispatch(teamActions.fetchTeamMembers());
  }, [dispatch]);

  const authorOptions = useMemo(() => {
    const authors: {
      value: string;
      label: string;
      avatarUrl: string | null;
      role: UserRoleEnum;
    }[] = [];

    if (!userDetails) {
      return [];
    }

    const user = {
      value: userDetails.id,
      label: `${userDetails.firstName} ${userDetails.lastName}`,
      avatarUrl: userDetails.avatarUrl,
      role: userDetails.role || UserRole.TRAINER,
    };
    authors.push(user);

    const botsWithCapabilities = bots.filter((bot) => bot.capabilities.canSendMessagesToAnyRoom);

    if (botsWithCapabilities.length) {
      botsWithCapabilities.forEach((bot) => {
        authors.unshift({
          value: bot.id,
          label: bot.name,
          role: UserRole.BOT,
          avatarUrl: bot.icon || null,
        });
      });
    }

    if (
      withCurrentClient &&
      isTrainer &&
      clientData?.assignedTrainer &&
      clientData?.assignedTrainer?.userId !== userDetails.id
    ) {
      authors.push({
        value: clientData.assignedTrainer.userId || CURRENT_ASSIGNED_TRAINER,
        label: `${clientData.assignedTrainer.firstName} ${clientData.assignedTrainer.lastName}`,
        role: UserRole.TEAM_MEMBER,
        avatarUrl:
          teamTrainers.find((trainer) => trainer.userId === clientData.assignedTrainer?.userId)?.user?.avatarUrl ||
          null,
      });
    }

    if (
      model?.authorId &&
      !authors.some((author) => author.value === model.authorId) &&
      isTeamMember &&
      model.authorId !== parentTrainerId
    ) {
      authors.push({
        value: model.authorId,
        label: model.authorName ?? model.authorId,
        role: model.authorRole ?? UserRole.TRAINER,
        avatarUrl: null,
      });
    }

    if (withAssignedTrainerOption) {
      authors.unshift({
        value: CURRENT_ASSIGNED_TRAINER,
        label: "Aktualny trener przypisany do klienta",
        role: UserRole.TRAINER,
        avatarUrl: null,
      });
    }

    if (isTrainer && !withCurrentClient && withAssignedTrainerOption && !model) {
      teamTrainers.forEach((trainer) => {
        if (trainer.userId !== userDetails.id && trainer.userId && trainer.selectable) {
          authors.push({
            value: trainer.userId,
            label: `${trainer.firstName} ${trainer.lastName}`,
            role: UserRole.TEAM_MEMBER,
            avatarUrl: trainer.user?.avatarUrl || null,
          });
        }
      });
    }

    return uniqBy(authors, "value");
  }, [userDetails, isTrainer, model, parentTrainerId, bots, teamTrainers]);

  return authorOptions;
};
