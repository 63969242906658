import React, { useCallback, useEffect } from "react";
import { Alert, Skeleton } from "antd";
import { useParams } from "react-router-dom";

import { mealsPlanActions, RequestStatus } from "@fitness-app/app-store";
import { getMealsMap } from "@fitness-app/app-store/src/store/reducers/mealsPlans/selectors";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal, type MealsPlan, type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";

import MealsPlanProvider from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface MealsPlanTemplateProviderProps {
  children: React.ReactElement;
}

const MealsPlanTemplateProvider = ({ children }: MealsPlanTemplateProviderProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { selectedMealsPlanDetails, selectedMealsPlan, selectedMealsPlanStatus } = useAppSelector(
    (store) => store.mealsPlans,
  );
  const mealsMap = useAppSelector(getMealsMap);

  useEffect(() => {
    if (id) {
      void dispatch(mealsPlanActions.fetchMealsPlanWithDetails({ id }));
    }
  }, [id, dispatch]);

  const [planDetails] = selectedMealsPlanDetails || [];

  const updateMealsPlan = useCallback(
    async ({
      mealsPlan,
      mealsPlanDetails,
    }: {
      mealsPlan: Partial<MealsPlan> & { id: string };
      mealsPlanDetails: (Partial<MealsPlanDetails> & { id: string }) | null;
    }) => {
      await dispatch(
        mealsPlanActions.updateMealsPlan({
          mealsPlan,
          mealsPlanDetails,
        }),
      ).unwrap();
    },
    [dispatch],
  );

  const planDetailsId = planDetails?.id;

  const updateMealsPlanDays = useCallback(
    async ({ days }: { days: MealsPlanDetails["weeks"][number]["days"] }) => {
      if (planDetailsId) {
        await dispatch(
          mealsPlanActions.updatedMealsPlanDays({
            days,
            id: planDetailsId,
          }),
        );
      }
    },
    [dispatch, planDetailsId],
  );

  const updateProductInMealsPlan = useCallback(
    async ({
      dayId,
      mealId,
      product,
    }: {
      dayId: string;
      mealId: string;
      product: DishInMeal | IngredientWithPortion;
    }) => {
      await dispatch(
        mealsPlanActions.updateProductInMealsPlan({
          dayId,
          mealId,
          product,
        }),
      );
    },
    [dispatch],
  );

  const deleteProductFromMealsPlan = useCallback(
    async ({ dayId, mealId, productId }: { dayId: string; mealId: string; productId: string }) => {
      await dispatch(
        mealsPlanActions.deleteProductFromMealsPlan({
          dayId,
          mealId,
          productId,
        }),
      );
    },
    [dispatch],
  );

  if (!selectedMealsPlanStatus || (!planDetails && selectedMealsPlanStatus === RequestStatus.FETCHING)) {
    return <Skeleton className="p-5" paragraph={{ rows: 5 }} />;
  }

  if (!id || !planDetails || !selectedMealsPlan) {
    return <Alert type="error" />;
  }

  return (
    <MealsPlanProvider
      mealsMap={mealsMap}
      planId={id}
      planDetails={planDetails}
      plan={selectedMealsPlan}
      updateMealsPlan={updateMealsPlan}
      updateMealsPlanDays={updateMealsPlanDays}
      updateProductInMealsPlan={updateProductInMealsPlan}
      deleteProductFromMealsPlan={deleteProductFromMealsPlan}
    >
      {children}
    </MealsPlanProvider>
  );
};

export default MealsPlanTemplateProvider;
