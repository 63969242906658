import React, { useState } from "react";
import { FileDoneOutlined } from "@ant-design/icons";
import { Button, Collapse, Empty, message, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { programAutomationActions } from "@fitness-app/app-store";
import { getErrorMessage } from "@fitness-app/utils";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useFormHandler } from "~/hooks/useFormHandler";
import AssignInitialSurveyForm from "~/modules/Automation/AssignedInitialSurvey/AssignInitialSurveyForm";
import { type AssignInitialSurveyFormModel } from "~/modules/Automation/AssignedInitialSurvey/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const AssignedInitialSurvey = (): React.ReactElement => {
  const { t } = useTranslation(["automation", "common"]);
  const selectedAutomation = useAppSelector((store) => store.programAutomation.selectedAutomation);
  const { openFormModal, hideFormModal, showModalForm, formModel } = useFormHandler<AssignInitialSurveyFormModel>();
  const dispatch = useAppDispatch();
  const [saving, toggleSaving] = useState(false);

  const onSubmit = async (formData: AssignInitialSurveyFormModel) => {
    if (!selectedAutomation) {
      return;
    }
    toggleSaving(true);
    try {
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            initialSurveyId: formData.id || null,
            additionalSurveyId: formData.additionalSurveyId || null,
          },
        }),
      ).unwrap();
      hideFormModal();
    } catch (e) {
      void message.error(getErrorMessage(e));
    } finally {
      toggleSaving(false);
    }
  };

  return (
    <>
      <Collapse>
        <Collapse.Panel key="nutrition" header={t("survey.initialSurveysTitle")}>
          {selectedAutomation?.initialSurvey ? (
            <div className="flex flex-col gap-y-6">
              <Empty
                description={<span>{t("survey.initialSurveyAssigned")}</span>}
                image={<FileDoneOutlined style={{ fontSize: 42 }} />}
                imageStyle={{ height: 60, marginTop: 20 }}
              >
                <div className="flex flex-col gap-y-4">
                  <Typography.Text strong>{selectedAutomation.initialSurvey?.title || "Brak"}</Typography.Text>
                </div>
              </Empty>
              <Empty description={<span>{t("survey.additionalSurveyTitle")}</span>} imageStyle={{ display: "none" }}>
                <div className="flex flex-col gap-y-4">
                  <Typography.Text strong>{selectedAutomation.additionalSurvey?.title || "Brak"}</Typography.Text>
                  <div>
                    <Button
                      type="primary"
                      onClick={() =>
                        openFormModal({
                          id: selectedAutomation.initialSurveyId,
                          additionalSurveyId: selectedAutomation.additionalSurveyId || null,
                        })
                      }
                    >
                      {t("common:button.edit")}
                    </Button>
                  </div>
                </div>
              </Empty>
            </div>
          ) : (
            <Empty description={<span>{t("survey.lackOfInitialSurvey")}</span>}>
              <Button type="primary" onClick={() => openFormModal()}>
                {t("common:button.add")}
              </Button>{" "}
            </Empty>
          )}
        </Collapse.Panel>
      </Collapse>
      <ModalForm loading={saving} open={showModalForm} onCancel={hideFormModal} title={t("survey.updateInitialSurvey")}>
        <AssignInitialSurveyForm model={formModel} onSubmit={onSubmit} />
      </ModalForm>
    </>
  );
};
