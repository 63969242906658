import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { fetchTrainee } from "../../trainee/actions";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  hasAccessToProgram: boolean;
  programId: string;
  traineeId: string;
};

export const toggleProgramAccess = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/toggleProgramAccess`,
  async (payload, { rejectWithValue, dispatch, extra: { trainersApi, analytics } }) => {
    try {
      await trainersApi.post("/trainee/toggle-program-access", payload);
      analytics.track("toggle_program_access");
      void dispatch(fetchTrainee({ id: payload.traineeId, refetch: true }));
    } catch (e) {
      rejectWithValue(getErrorMessage(e));
    }
  },
);
