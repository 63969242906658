import { type Database } from "@fitness-app/supabase";

import { type TraineeEvents } from "../businessEvents/domain/trainee/TraineeEvents";
import {
  type TraineeActivityEvents,
  type TraineeActivityEventType,
} from "../businessEvents/domain/traineeActivity/TraineeActivityEvents";
import { type AppAccessType } from "./Automation";
import { type Trainee, type TraineeWithAssignedTrainer } from "./Trainee";

export type ClientActivityEntity = Database["public"]["Tables"]["client_activity"]["Row"];
export type ClientActivityEventEntity = Database["public"]["Tables"]["client_activity_event"]["Row"];
export type ClientMonthActivityEntity = Database["public"]["Tables"]["client_month_activity"]["Row"];

export type ClientActivityEvent = (TraineeActivityEvents | TraineeEvents) & {
  traineeId: string;
  userId?: string | null;
};

export enum ClientActivityFlag {
  Idle = "idle",
  Danger = "danger",
  Warning = "warning",
  Safe = "safe",
  VerySafe = "verySafe",
}

export type WeekWithYear = `${number}-${number}`;

export type WeekActivities = {
  workoutsScheduled: number | null;
  workoutsCompleted: number;
  activitiesScheduled: number | null;
  activitiesCompleted: number;
  cardioScheduled: number | null;
  cardioCompleted: number;
  startDate: string;
  endDate: string;
  weekNumber: string;
};

export type LastMonthOfWorkouts = [WeekActivities, WeekActivities, WeekActivities, WeekActivities];

export interface ClientActivity extends Omit<ClientActivityEntity, "activities" | "measurements"> {
  activities: LastMonthOfWorkouts | null;
  measurements: {
    type:
      | TraineeActivityEventType.TraineeAddedBodyMeasurements
      | TraineeActivityEventType.TraineeAddedBodyPhotos
      | TraineeActivityEventType.TraineeAddedWeightMeasurement;
    timestamp: number;
  }[];
  accessType: AppAccessType;
}

export interface ClientActivityWithScore extends ClientActivity {
  measurementStatus: ClientActivityFlag;
  workoutsStatus: ClientActivityFlag;
  communicationStatus: ClientActivityFlag;
  score: number;
}

export type ClientActivityWithScoreAndTrainer = ClientActivityWithScore & {
  trainee: {
    id: string;
    userId: string | null;
    email: Trainee["email"];
    firstName: Trainee["firstName"];
    lastName: Trainee["lastName"];
    assignedTrainerId: Trainee["assignedTrainerId"];
    assignedTrainer: TraineeWithAssignedTrainer["assignedTrainer"];
    tags: Trainee["tags"];
    hasActiveTrainingProgram: Trainee["hasActiveTrainingProgram"];
    activeTrainingProgramId: Trainee["activeTrainingProgramId"];
    createdAt: Trainee["createdAt"];
  };
};

// YYYY-MM-DD
export type DayString = `${number}-${number}-${number}`;
// YYYY-MM
export type MonthYearString = `${number}-${number}`;

export interface ClientDayActivity {
  date: DayString;
  mealTracked: number;
  messagesCount: number;
  lastMessageAt: number | null;
  logInCount: number;
  lastLoggedAt: number | null;
  lastActionAt: number | null;
  exercisesCompleted: number;
  lastWorkoutActivityAt: number | null;
  actions: { type: TraineeActivityEventType; timestamp: number; id: string; data?: any }[];
}

export interface ClientMonthActivity extends Omit<ClientMonthActivityEntity, "days"> {
  days: Record<DayString, ClientDayActivity>;
  monthYear: MonthYearString;
}
