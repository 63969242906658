import React, { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Row, Select, Slider, Switch, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type ClientMealsPlanDetails } from "@fitness-app/data-models/entities/ClientNutrition";
import {
  DietaryPreference,
  KCAL_PER_NUTRIENT_GRAM,
  MacroSplit,
  MacroSplitRanges,
  MealsPerDay,
  TargetGender,
} from "@fitness-app/data-models/entities/MealsPlan";
import { countNutrientGrams } from "@fitness-app/utils/src/nutrition/countNutrients";
import {
  type NutrientsTargets,
  type TraineeNutritionGeneratorFormModel,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import { useMealPlanOptions } from "~/modules/Nutrition/hooks/useMealPlanOptions";
import { useMealsPlanContextNullable } from "~/shared/providers/MealsPlanProvider";
import { useAppSelector } from "~/store/initializeStore";

interface TraineeNutritionFormProps {
  formController?: FormInstance<TraineeNutritionGeneratorFormModel>;
  onSubmit: (
    formData: TraineeNutritionGeneratorFormModel,
    targets: NutrientsTargets,
    supplements?: ClientMealsPlanDetails["supplements"],
  ) => void;
  model?: Partial<TraineeNutritionGeneratorFormModel> | null;
  traineeId: string;
}

const TraineeNutritionGeneratorForm = ({ model, onSubmit, formController }: TraineeNutritionFormProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const { dietaryPreferences, mealsPerDay, macroSplits } = useMealPlanOptions();
  const activeMealsPlanId = useAppSelector((store) => store.trainee.profile?.activeMealsPlanId);
  const context = useMealsPlanContextNullable();
  const [protein, setProtein] = useState(model?.targets?.protein ?? 30);
  const [carbs, setCarbs] = useState(model?.targets?.carbs ?? 40);
  const [fat, setFat] = useState(model?.targets?.fat ?? 30);
  const macroSplit = Form.useWatch(["macroSplit"], formController);
  const targetCalories = Form.useWatch(["targetCalories"], formController);
  const [submitCount, setSubmitCount] = useState(0);

  useEffect(() => {
    if (model && submitCount === 0) {
      formController?.setFieldsValue(model);
      setFat(model.targets?.fat ?? 30);
      setCarbs(model.targets?.carbs ?? 40);
      setProtein(model.targets?.protein ?? 30);
    }
  }, [model, submitCount]);

  useEffect(() => {
    if (macroSplit) {
      const { protein, carbs, fat } = MacroSplitRanges[macroSplit];
      setProtein(protein);
      setCarbs(carbs);
      setFat(fat);
    }
  }, [macroSplit]);

  const update = (type: "protein" | "carbs" | "fat", value: number | null) => {
    if (type === "protein" && value !== null) {
      setProtein(value);
      if (protein + carbs + fat > 100) {
        carbs > 0 ? setCarbs(100 - value - fat) : setFat(100 - value - carbs);
      }
    } else if (type === "carbs" && value !== null) {
      setCarbs(value);
      if (protein + carbs + fat > 100) {
        fat > 0 ? setFat(100 - value - protein) : setProtein(100 - value - fat);
      }
    } else if (value !== null) {
      setFat(value);
      if (protein + carbs + fat > 100) {
        protein > 0 ? setProtein(100 - value - carbs) : setCarbs(100 - value - protein);
      }
    }
  };

  const handleSubmit = (model: TraineeNutritionGeneratorFormModel) => {
    setSubmitCount((prev) => prev + 1);
    onSubmit(
      model,
      {
        carbs,
        protein,
        fat,
      },
      context?.planDetails.supplements || [],
    );
  };

  return (
    <>
      <Form<TraineeNutritionGeneratorFormModel>
        name="form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        form={formController}
        initialValues={{
          targetCalories: 2000,
          dietaryPreference: DietaryPreference.NoPreference,
          mealsSchema: MealsPerDay.THREE,
          macroSplit: MacroSplit.BALANCED,
          targets: {
            gender: TargetGender.UNISEX,
          },
          copySupplementsPlan: true,
          prompt: "",
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label={t<string>("mealPlanForm.name")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item
          name="prompt"
          label={t<string>("mealPlanForm.prompt")}
          rules={[
            {
              required: false,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="dietaryPreference"
          label={t<string>("mealPlanForm.dietaryPreference")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select options={dietaryPreferences} placeholder={t("common:selectPlaceholder")} />
        </Form.Item>
        <Form.Item
          name="macroSplit"
          label={t<string>("mealPlanForm.macroSplit")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select options={macroSplits} placeholder={t("common:selectPlaceholder")} />
        </Form.Item>
        <Form.Item
          name="mealsSchema"
          label={t<string>("mealPlanForm.mealsPerDay")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select disabled options={mealsPerDay} placeholder={t("common:selectPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="targetCalories"
          label={t<string>("mealPlanForm.targetCalories")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <InputNumber min={0} max={5000} precision={0} />
        </Form.Item>

        {activeMealsPlanId && (
          <Form.Item label="Kopiuj suplementacje z obecnej diety" valuePropName="checked" name="copySupplementsPlan">
            <Switch />
          </Form.Item>
        )}

        <Row className="my-4 justify-center">
          <Col span={12}>
            <Typography.Text strong>{t("nutrients.protein")}</Typography.Text>
            <Slider min={0} max={100} value={protein} onChange={(value) => update("protein", value)} />
          </Col>
          <Col span={5}>
            <InputNumber
              addonAfter={
                <div className="w-[40px]">{`${countNutrientGrams(
                  protein,
                  targetCalories ?? 0,
                  KCAL_PER_NUTRIENT_GRAM.protein,
                )} g`}</div>
              }
              min={0}
              max={100}
              className="ml-1 mt-4"
              value={protein}
              formatter={(value) => `${value}%`}
              parser={(value) => (value !== undefined ? Number(value.replace("%", "")) : 0)}
              onChange={(value) => update("protein", value)}
            />
          </Col>
          <Col span={12}>
            <Typography.Text strong>{t("nutrients.carbohydrates")}</Typography.Text>
            <Slider min={0} max={100} value={carbs} onChange={(value) => update("carbs", value)} />
          </Col>
          <Col span={5}>
            <InputNumber
              className="ml-1 mt-4"
              addonAfter={
                <div className="w-[40px]">{`${countNutrientGrams(
                  carbs,
                  targetCalories ?? 0,
                  KCAL_PER_NUTRIENT_GRAM.carbs,
                )} g`}</div>
              }
              formatter={(value) => `${value}%`}
              parser={(value) => (value !== undefined ? Number(value.replace("%", "")) : 0)}
              min={0}
              max={100}
              value={carbs}
              onChange={(value) => update("carbs", value)}
            />
          </Col>
          <Col span={12}>
            <Typography.Text strong>{t("nutrients.fat")}</Typography.Text>
            <Slider min={0} max={100} value={fat} onChange={(value) => update("fat", value)} />
          </Col>
          <Col span={5}>
            <InputNumber
              className="ml-1 mt-4"
              addonAfter={
                <div className="w-[40px]">{`${countNutrientGrams(
                  fat,
                  targetCalories ?? 0,
                  KCAL_PER_NUTRIENT_GRAM.fat,
                )} g`}</div>
              }
              formatter={(value) => `${value}%`}
              parser={(value) => (value !== undefined ? Number(value.replace("%", "")) : 0)}
              min={0}
              max={100}
              value={fat}
              onChange={(value) => update("fat", value)}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TraineeNutritionGeneratorForm;
