import React from "react";
import { Route, Routes } from "react-router-dom";

import SurveyDetails from "~/modules/SurveysTemplates/SurveyDetails/SurveyDetails";
import Surveys from "~/modules/SurveysTemplates/Surveys";

const SurveysRouter = () => {
  return (
    <Routes>
      <Route index element={<Surveys />} />
      <Route path="/details/:id" element={<SurveyDetails />} />
      <Route path="/:tab/*" element={<Surveys />} />
    </Routes>
  );
};

export default SurveysRouter;
