import React, { useEffect, type FunctionComponent } from "react";
import { Card, Space } from "antd";
import { useParams } from "react-router-dom";

import { automationTemplatesActions, programAutomationActions, RequestStatus } from "@fitness-app/app-store";
import {
  type AutomationWeekWithWorkouts,
  type ScheduledNutritionByCurrentWeight,
} from "@fitness-app/data-models/entities/ProgramAutomation";

import { AssignedInitialSurvey } from "~/modules/Automation/AssignedInitialSurvey/AssignedInitialSurvey";
import AutomationScheduleTable from "~/modules/Automation/ProgramAutomation/components/AutomationScheduleTable/AutomationScheduleTable";
import AutomationWelcomeMessages from "~/modules/Automation/ProgramAutomation/components/AutomationWelcomeMessage/AutomationWelcomeMessages";
import ScheduleTrainingPrograms from "~/modules/Automation/ProgramAutomation/components/ScheduleTrainingPrograms/ScheduleTrainingPrograms";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import ScheduledNutrition from "./components/ScheduledNutrition/ScheduledNutrition";

export interface AutomationRow {
  week: string;
  scheduleForWeek: AutomationWeekWithWorkouts | null;
  nutritionPlanForWeek: ScheduledNutritionByCurrentWeight | null;
}

const ProgramAutomationDetails: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const itemStatus = useAppSelector((store) => store.programAutomation.itemStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      void dispatch(programAutomationActions.fetchProgramAutomation(id));
    }

    void dispatch(automationTemplatesActions.fetchAutomationTemplates());
  }, [id, dispatch]);

  return (
    <div className="p-6">
      <Card loading={itemStatus === RequestStatus.FETCHING}>
        <Space direction="vertical" className="w-full" size={16}>
          <ScheduleTrainingPrograms />
          <ScheduledNutrition />
          <AssignedInitialSurvey />
          <AutomationWelcomeMessages />
          <AutomationScheduleTable />
        </Space>
      </Card>
    </div>
  );
};

export default ProgramAutomationDetails;
