import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramAutomation } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../../index";
import { AUTOMATION_REDUCER_NAME } from "../types";
import { fetchProgramAutomation } from "./fetchProgramAutomation";

type Payload = {
  automation: Partial<ProgramAutomation>;
  automationId: string;
};

export const updateAutomationTemplate = createAsyncThunk<ProgramAutomation, Payload, AsyncThunkCreator<string>>(
  `${AUTOMATION_REDUCER_NAME}/updateAutomationTemplate`,
  async (payload, { rejectWithValue, dispatch, extra: { db } }) => {
    const { error, data } = await db
      .from("program_automation")
      .update({ ...payload.automation, updatedAt: new Date().toISOString() })
      .eq("id", payload.automationId)
      .select("*")
      .single<ProgramAutomation>();

    if (error) {
      return rejectWithValue(error.message);
    }

    if ("initialSurveyId" in payload.automation) {
      void dispatch(fetchProgramAutomation(payload.automationId));
    }

    return data;
  },
);
