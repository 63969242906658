import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
};

export const removeScheduledProgram = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/removeScheduledProgram`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("client_training_program").delete().eq("id", payload.programId);

    if (error) {
      return rejectWithValue(error.message);
    }
  },
);
