import { type RequestStatus } from "@fitness-app/data-models";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

export const CLIENT_PRODUCTS_SLICE_NAME = "clientProducts";

export interface ClientProductsReducer {
  listStatus: null | RequestStatus;
  list: ProductClient[];
  listError: null | Error;
}
