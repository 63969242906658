import { createAsyncThunk } from "@reduxjs/toolkit";
import { type SurveyTemplateWithCreator } from "@fitness-app/data-models/entities/SurveyTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { SURVEYS_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = string;

export const fetchSurveyTemplate = createAsyncThunk<SurveyTemplateWithCreator, Payload, AsyncThunkCreator<string>>(
  `${SURVEYS_TEMPLATES_REDUCER_NAME}/fetchSurveyTemplate`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { data, error } = await db
      .from("survey_template")
      .select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" })
      .eq("archived", false)
      .eq("id", payload)
      .order("createdAt", { ascending: false })
      .single<SurveyTemplateWithCreator>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
