class UnknownError extends Error {
  public originalError: unknown;
  public static message = "UnknownError";
  public status = 500;
  constructor(originalError: unknown, ...params: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownError);
    }
    this.name = "UnknownError";
    this.status = 500;
    this.originalError = originalError;
    this.message = UnknownError.message;
  }
}

export default UnknownError;
