import React, { type FunctionComponent } from "react";
import { Alert, Button, Divider, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus, userActions } from "@fitness-app/app-store";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface PasswordSetFormModel {
  password: string;
  confirmPassword: string;
  oldPassword?: string;
}

const SetPasswordForm: FunctionComponent = () => {
  const [formController] = Form.useForm<PasswordSetFormModel>();
  const { t } = useTranslation(["auth", "common"]);
  const { passwordStatus, error, profile } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();

  const handleSetPasswordForm = async (values: PasswordSetFormModel) => {
    // eslint-disable-next-line
    await dispatch(
      userActions.setUserPassword(
        {
          password: values.password,
        },
        () => {
          void message.success(t("passwordChangeSuccess"));
          formController.resetFields();
        },
      ),
    );
  };

  const sendNewLink = async () => {
    if (profile?.email) {
      window.localStorage.setItem("emailForSignIn", profile.email);
      // eslint-disable-next-line
      await dispatch(userActions.sendLoginLink(profile.email, `${window.location.origin}/auth/sign-with-link`));
      void message.success(t("checkYourEmail"));
      setTimeout(() => {
        dispatch(userActions.logOutUser());
      }, 700);
      dispatch(userActions.resetErrors());
    }
  };
  const renderAlertAction = () => {
    if (error === "auth/requires-recent-login") {
      return (
        <Button type="dashed" onClick={sendNewLink}>
          {t("sendNewLoginLink")}
        </Button>
      );
    }
  };

  return (
    <div className="mx-auto max-w-[600px] pb-24 pt-8">
      <Divider>{t("loginByEmail.changePassword")}</Divider>
      {error && <Alert showIcon className="mb-10" type="error" message={error} action={renderAlertAction()} />}
      <Form<PasswordSetFormModel>
        name="set-password-form"
        layout="vertical"
        form={formController}
        onFinish={handleSetPasswordForm}
        validateTrigger={["onSubmit", "onBlur"]}
      >
        <Form.Item
          name="password"
          label={t("password")}
          rules={[
            {
              required: true,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
            {
              min: 8,
              message: t<string>("validationErrors.minLength", { number: 8 }),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="passwordConfirmation"
          label={t("passwordConfirmation")}
          rules={[
            {
              required: true,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("validationErrors.passwordNotMatch"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <div className="">
          <Button
            type="primary"
            htmlType="submit"
            block
            className="basis-4/12"
            loading={passwordStatus === RequestStatus.UPDATING}
          >
            {t("confirm")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SetPasswordForm;
