import { message } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { productClientsActions } from "@fitness-app/app-store";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useAppDispatch } from "~/store/initializeStore";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

function exportToCsv(clients: ProductClient[], productId: string, productName: string) {
  let data = "";
  const tableData = [];
  const headers = [
    "email",
    "firstName",
    "lastName",
    "createdAt",
    "status",
    "type",
    "productId",
    "lastOrderId",
    "accessPeriod",
    "refCode",
    "imported",
    "discordId",
  ];
  const dataRows: [string, string, string, string, string, string, string, string, string, string, boolean, string][] =
    [];

  clients.forEach((client, index) => {
    dataRows[index] = [
      client.email,
      client.firstName,
      client.lastName,
      dayjs(client.createdAt).toISOString(),
      client.status,
      client.paymentType || "-",
      client.productId,
      client.lastOrder || "-",
      client.accessPeriod
        ? `${dayjs.unix(client.accessPeriod.currentPeriodStart).format(DATE_FORMAT)} - ${dayjs
            .unix(
              client.archivedInfo?.archivedAt ||
                client.accessPeriod.pauseCollection?.resumes_at ||
                client.accessPeriod.currentPeriodEnd ||
                0,
            )
            .format(DATE_FORMAT)}`
        : "-",
      client.refCode || "-",
      client.imported ?? false,
      client.discordId || "-",
    ];
  });

  const rows = [headers, ...dataRows];
  for (const row of rows) {
    const rowData = [];
    for (const column of row) {
      rowData.push(column);
    }
    tableData.push(rowData.join(","));
  }
  data += tableData.join("\n");
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  a.setAttribute("download", `${productId}-${productName}-clients-fitness-export.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const useExportClientsToCsv = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("common");

  const exportToCsvClients = async (productId: string, productName: string, type: "all" | "archived" | "active") => {
    const messageId = "exportMessage";
    void message.loading({
      duration: 0,
      content: t("messages.loading.defaultSlow"),
      key: messageId,
    });

    try {
      const { list } = await dispatch(productClientsActions.fetchAllProductClients({ productId, type })).unwrap();
      message.destroy(messageId);
      exportToCsv(list, productId, productName);
    } catch {
      message.destroy(messageId);
      void message.error(t("messages.error.default"));
    }
  };

  return {
    exportToCsvClients,
  };
};
