import React, { useState, type FunctionComponent } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Empty, message, Popconfirm, Popover, Space, Switch, Table, Tag } from "antd";
import groupBy from "lodash.groupby";
import isEmpty from "lodash.isempty";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { productAutomationActions, RequestStatus } from "@fitness-app/app-store";
import {
  AutomationStartPoint,
  DiscordIntegrationTask,
  EmailClientTask,
  FitnessAppTask,
  PriceFilterType,
  ProductTask,
  TaskCreator,
  TaskType,
  type AddClientToFitnessApp,
  type AddFieldValues,
  type AddTagsTask,
  type AddToListsTask,
  type AutomationTask,
  type EmailTaskGroup,
  type FieldValuesEmailClient,
  type FieldValuesEmailList,
  type RemoveFromListsTask,
  type RemoveTagsTask,
  type TagsEmailClient,
  type TagsEmailList,
} from "@fitness-app/data-models/entities/Automation";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

import ModalForm from "~/components/ModalForm/ModalForm";
import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useFitnessAppAutomationTask } from "~/modules/Products/hooks/useFitnessAppAutomationTask";
import AutomationTaskForm from "~/modules/Products/Product/ProductAutomation/components/AutomationTaskForm/AutomationTaskForm";
import {
  type AddClientToFitnessApp as AddClientToFitnessAppFormModel,
  type AutomationTaskFormModel,
} from "~/modules/Products/Product/ProductAutomation/components/AutomationTaskForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

const AutomationTasks: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation("products");
  const { data, status, error } = useAppSelector((store) => store.productAutomation);
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();
  const productDetails = useAppSelector((store) => store.product.details);
  const [model, setModel] = useState<null | (AutomationTaskFormModel & { id: string })>(null);
  const [processedId, setProcessedId] = useState<null | string>(null);
  const { programAutomationOptions } = useFitnessAppAutomationTask(productDetails);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(() => {
    toggleModal(false);
    setModel(null);
  });
  const [removing, onRemoveSuccess, onRemoveFailure, onStartRemoving] = useEntityChange();

  const handleTaskRemove = (task: AutomationTask) => {
    onStartRemoving(task.id);
    void dispatch(
      productAutomationActions.updateProductAutomationTask({
        payload: {
          productId,
          task,
          action: "remove",
        },
        onSuccess: onRemoveSuccess,
        onFailure: onRemoveFailure,
      }),
    );
  };

  const handleActiveChange = async (state: boolean, task: AutomationTask) => {
    setProcessedId(task.id);
    await dispatch(
      productAutomationActions.updateProductAutomationTask({
        payload: {
          productId,
          task: {
            ...task,
            active: state,
          },
          action: "update",
        },
      }),
    );
    setProcessedId(null);
  };

  const handleSubmit = (formData: AutomationTaskFormModel) => {
    let action: AutomationTask["action"] | null = null;
    let automationTask: AutomationTask | null = null;

    const filters: AutomationTask["filters"] = [];

    if (formData.automationFilters && formData.includedPrices?.length) {
      filters.push({ type: PriceFilterType.Included, prices: formData.includedPrices });
    }

    if (formData.automationFilters && formData.excludedPrices?.length) {
      filters.push({ type: PriceFilterType.Excluded, prices: formData.excludedPrices });
    }

    const taskBaseData = {
      active: formData.active,
      description: formData.description,
      protected: formData.protected ?? false,
      triggers: formData.triggers,
      creator: formData.creator ?? TaskCreator.Owner,
      filters: filters.length ? filters : null,
    };

    if (formData.action.type === ProductTask.RemoveFromProduct) {
      action = formData.action;

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.ProductTask,
      };
    }

    if (formData.action.type === ProductTask.ImportIntoProduct) {
      action = formData.action;

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.ProductTask,
      };
    }

    if (formData.action.type === FitnessAppTask.AddClientToApp) {
      const { startAutomationFrom, ...rest } = formData.action;

      let sendReminderAboutAccess: AddClientToFitnessApp["sendReminderAboutAccess"] = false;

      if (
        "sendReminderAboutAccess" in formData.action &&
        formData.action.sendReminderAboutAccess &&
        !isEmpty(formData.action.sendReminderAboutAccess)
      ) {
        sendReminderAboutAccess = formData.action.sendReminderAboutAccess.map((item) => {
          return {
            ...item,
            actions: item.actions.map((action) => {
              if (action.type === EmailClientTask.AddTags || action.type === EmailClientTask.RemoveTags) {
                const lists: (TagsEmailList | TagsEmailClient)[] = action.emailClients.map((listStr) => {
                  const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
                  if (id && name) {
                    return {
                      client: client as EmailClient,
                      account,
                      id,
                      name,
                    } as TagsEmailList;
                  }
                  return {
                    client: client as EmailClient,
                    account,
                  } as TagsEmailClient;
                });
                const groupByClient = groupBy(lists, "client");

                if (groupByClient[EmailClient.GetResponse] && groupByClient[EmailClient.GetResponse].length > 1) {
                  void message.warning("getResponseListWarning");
                  return;
                }

                return {
                  ...action,
                  emailClients: lists,
                } as AddTagsTask | RemoveTagsTask;
              }

              if (action.type === EmailClientTask.AddToLists || action.type === EmailClientTask.RemoveFromLists) {
                const lists = action.emailLists.map((listStr) => {
                  const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
                  return {
                    client: client as EmailClient,
                    account: account || "",
                    id: id || "",
                    name: name || "",
                  };
                });
                const groupByClient = groupBy(lists, "client");

                if (groupByClient[EmailClient.GetResponse] && groupByClient[EmailClient.GetResponse].length > 1) {
                  void message.warning("getResponseListWarning");
                  return;
                }

                return {
                  ...action,
                  emailLists: lists,
                } as AddToListsTask | RemoveFromListsTask;
              }

              const lists: (FieldValuesEmailList | FieldValuesEmailClient)[] = action.emailLists.map((listStr) => {
                const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
                if (id && name) {
                  return {
                    client: client as EmailClient,
                    account,
                    id,
                    name,
                  } as FieldValuesEmailList;
                }
                return {
                  client: client as EmailClient,
                  account,
                } as FieldValuesEmailClient;
              });
              const groupByClient = groupBy(lists, "client");

              if (groupByClient[EmailClient.GetResponse] && groupByClient[EmailClient.GetResponse].length > 1) {
                void message.warning("getResponseListWarning");
                return;
              }

              return {
                ...action,
                emailLists: lists,
                values: action.values ? Object.fromEntries(action.values?.map((item) => [item.key, item.value])) : {},
              } as AddFieldValues;
            }) as EmailTaskGroup[],
          };
        });
      }

      action = {
        ...rest,
        tags: formData.action.tags || [],
        programAutomation: formData.action.programAutomation
          ? {
              name:
                programAutomationOptions.find(
                  (item) =>
                    formData.action.type === FitnessAppTask.AddClientToApp &&
                    item.value === formData.action.programAutomation,
                )?.label ?? "",
              id: formData.action.programAutomation,
              startAutomationFrom: formData.action.startAutomationFrom || AutomationStartPoint.AfterPurchase,
            }
          : null,
        sendReminderAboutAccess,
      };

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.FitnessApp,
      };
    }

    if (formData.action.type === FitnessAppTask.AddProgramAutomationToClient) {
      const { startAutomationFrom, ...rest } = formData.action;

      action = {
        ...rest,
        programAutomation: {
          name:
            programAutomationOptions.find(
              (item) =>
                formData.action.type === FitnessAppTask.AddClientToApp &&
                item.value === formData.action.programAutomation,
            )?.label ?? "",
          id: formData.action.programAutomation,
          startAutomationFrom: formData.action.startAutomationFrom || AutomationStartPoint.AfterPurchase,
        },
      };

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.FitnessApp,
      };
    }

    if (formData.action.type === FitnessAppTask.RemoveClientFromApp) {
      action = formData.action;

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.FitnessApp,
      };
    }

    if (
      formData.action.type === DiscordIntegrationTask.AddToServer ||
      formData.action.type === DiscordIntegrationTask.RemoveFromServer
    ) {
      action = formData.action;

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.DiscordIntegration,
      };
    }

    if (formData.action.type === EmailClientTask.AddTags || formData.action.type === EmailClientTask.RemoveTags) {
      const lists: (TagsEmailList | TagsEmailClient)[] = formData.action.emailClients.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        if (id && name) {
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          } as TagsEmailList;
        }
        return {
          client: client as EmailClient,
          account,
        } as TagsEmailClient;
      });
      const groupByClient = groupBy(lists, "client");

      if (groupByClient[EmailClient.GetResponse] && groupByClient[EmailClient.GetResponse].length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }

      action = {
        ...formData.action,
        emailClients: lists,
      };

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.EmailClient,
      };
    }

    if (
      formData.action.type === EmailClientTask.AddToLists ||
      formData.action.type === EmailClientTask.RemoveFromLists
    ) {
      const lists = formData.action.emailLists.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        return {
          client: client as EmailClient,
          account: account || "",
          id: id || "",
          name: name || "",
        };
      });
      const groupByClient = groupBy(lists, "client");

      if (groupByClient[EmailClient.GetResponse] && groupByClient[EmailClient.GetResponse].length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }

      action = {
        ...formData.action,
        emailLists: lists,
      };

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.EmailClient,
      };
    }

    if (formData.action.type === EmailClientTask.AddFieldValues) {
      const lists: (FieldValuesEmailList | FieldValuesEmailClient)[] = formData.action.emailLists.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        if (id && name) {
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          } as FieldValuesEmailList;
        }
        return {
          client: client as EmailClient,
          account,
        } as FieldValuesEmailClient;
      });
      const groupByClient = groupBy(lists, "client");

      if (groupByClient[EmailClient.GetResponse] && groupByClient[EmailClient.GetResponse].length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }

      action = {
        ...formData.action,
        emailLists: lists,
        values: formData.action.values
          ? Object.fromEntries(formData.action.values?.map((item) => [item.key, item.value]))
          : {},
      };

      automationTask = {
        ...taskBaseData,
        action,
        id: model?.id || uuid(),
        type: TaskType.EmailClient,
      };
    }

    if (!action || !automationTask) {
      return onFailure();
    }

    onStart();
    void dispatch(
      productAutomationActions.updateProductAutomationTask({
        payload: {
          productId,
          task: automationTask,
          action: model?.id ? "update" : "add",
        },
        onSuccess,
        onFailure,
      }),
    );
  };

  const handleEdit = (task: AutomationTask) => {
    let action: AutomationTaskFormModel["action"] | null = null;

    if (
      task.action.type === DiscordIntegrationTask.AddToServer ||
      task.action.type === DiscordIntegrationTask.RemoveFromServer
    ) {
      action = {
        ...task.action,
      };
    }

    if (task.action.type === EmailClientTask.AddTags || task.action.type === EmailClientTask.RemoveTags) {
      action = {
        ...task.action,
        emailClients: task.action.emailClients.map((list) =>
          "id" in list
            ? `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`
            : `${list.client}${LIST_ITEM_SEPARATOR}${list.account}`,
        ),
      };
    }

    if (task.action.type === EmailClientTask.AddToLists || task.action.type === EmailClientTask.RemoveFromLists) {
      action = {
        ...task.action,
        emailLists: task.action.emailLists.map(
          (list) =>
            `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
        ),
      };
    }
    if (task.action.type === EmailClientTask.AddFieldValues) {
      action = {
        ...task.action,
        emailLists: task.action.emailLists.map((list) =>
          "id" in list
            ? `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`
            : `${list.client}${LIST_ITEM_SEPARATOR}${list.account}`,
        ),
        values: Object.entries(task.action.values).map(([key, value]) => ({
          key,
          value,
        })),
      };
    }

    if (task.action.type === FitnessAppTask.RemoveClientFromApp) {
      action = {
        ...task.action,
      };
    }

    if (task.action.type === FitnessAppTask.AddClientToApp) {
      let sendReminderAboutAccess: AddClientToFitnessAppFormModel["sendReminderAboutAccess"] = [];

      if (
        "sendReminderAboutAccess" in task.action &&
        task.action.sendReminderAboutAccess &&
        task.action.sendReminderAboutAccess.length
      ) {
        sendReminderAboutAccess = task.action.sendReminderAboutAccess.map((item) => {
          return {
            ...item,
            actions: item.actions.map((action) => {
              if (action.type === EmailClientTask.AddTags || action.type === EmailClientTask.RemoveTags) {
                return {
                  ...action,
                  emailClients: action.emailClients.map((list) =>
                    "id" in list
                      ? `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`
                      : `${list.client}${LIST_ITEM_SEPARATOR}${list.account}`,
                  ),
                };
              }

              if (action.type === EmailClientTask.AddToLists || action.type === EmailClientTask.RemoveFromLists) {
                return {
                  ...action,
                  emailLists: action.emailLists.map(
                    (list) =>
                      `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                  ),
                };
              }
              return {
                ...action,
                emailLists: action.emailLists.map((list) =>
                  "id" in list
                    ? `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`
                    : `${list.client}${LIST_ITEM_SEPARATOR}${list.account}`,
                ),
                values: Object.entries(action.values).map(([key, value]) => ({
                  key,
                  value,
                })),
              };
            }),
          };
        });
      }

      action = {
        type: task.action.type,
        programAutomation: task.action.programAutomation?.id || null,
        startAutomationFrom: task.action.programAutomation
          ? task.action.programAutomation.startAutomationFrom
          : undefined,
        tags: task.action.tags,
        accessType: task.action.accessType,
        sendReminderAboutAccess,
      };
    }

    if (task.action.type === FitnessAppTask.AddProgramAutomationToClient) {
      action = {
        type: task.action.type,
        programAutomation: task.action.programAutomation.id,
        startAutomationFrom: task.action.programAutomation
          ? task.action.programAutomation.startAutomationFrom
          : undefined,
      };
    }

    if (!action) {
      void message.warning("Edycja dla tego zadania nie jest obecnie możliwa.");
      return;
    }

    const pricesIncludedFilter = task.filters?.find((filter) => filter.type === PriceFilterType.Included);
    const pricesExcludedFilter = task.filters?.find((filter) => filter.type === PriceFilterType.Excluded);

    setModel({
      ...task,
      includedPrices: pricesIncludedFilter?.prices,
      excludedPrices: pricesExcludedFilter?.prices,
      automationFilters: !!(pricesIncludedFilter || pricesExcludedFilter),
      action,
    });

    toggleModal(true);
  };

  return (
    <Card
      bordered={false}
      className="!shadow-none"
      loading={status === RequestStatus.SUBSCRIBING}
      extra={
        data?.tasks?.length && (
          <Button type="primary" onClick={() => toggleModal(true)}>
            {t("automationTasks.addTask")}
          </Button>
        )
      }
    >
      {error && <Alert closable showIcon message={error} type="error" />}
      <Table<AutomationTask>
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("automationTasks.emptyList")}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal(true)}>
                {t("automationTasks.addFirstTask")}
              </Button>
            </Empty>
          ),
        }}
        dataSource={data?.tasks ?? []}
      >
        <Table.Column<AutomationTask>
          title={t("automationTasks.column.task")}
          dataIndex="task"
          key="task"
          ellipsis
          width={350}
          render={(_, row) => t(`common:${row.type}.${row.action.type}`)}
        />

        <Table.Column<AutomationTask>
          title={t("automationTasks.column.description")}
          dataIndex="description"
          key="description"
          render={(rowText: string, row) =>
            rowText ? (
              <Popover
                content={row.creator === TaskCreator.System ? t(rowText) : rowText}
                overlayStyle={{ maxWidth: 300 }}
              >
                <InfoCircleOutlined style={{ fontSize: 20 }} />
              </Popover>
            ) : (
              "-"
            )
          }
        />
        <Table.Column<AutomationTask>
          title={t("automationTasks.column.triggers")}
          dataIndex="triggers"
          key="triggers"
          render={(events: string[]) => (
            <Space direction="horizontal">
              {events.map((event) => (
                <Tag key={event}>{t(`common:${event}`)}</Tag>
              ))}
            </Space>
          )}
        />
        <Table.Column<AutomationTask>
          title={t("automationTasks.column.active")}
          dataIndex="active"
          key="active"
          render={(active: boolean, row) => (
            <Switch
              loading={processedId === row.id}
              checked={active}
              onChange={(state) => handleActiveChange(state, row)}
            />
          )}
        />
        <Table.Column<AutomationTask>
          title={t("automationTasks.column.options")}
          key="action"
          align="left"
          render={(_, row) => (
            <Space>
              <Button type="link" onClick={() => handleEdit(row)}>
                {t("common:button.edit")}
              </Button>
              <Popconfirm
                overlayStyle={{ maxWidth: 400 }}
                placement="leftTop"
                disabled={row.protected}
                title={t("task.deleteWarning")}
                onConfirm={() => handleTaskRemove(row)}
              >
                <Button
                  type="link"
                  danger
                  disabled={row.protected}
                  loading={typeof removing === "string" && removing === row.id}
                >
                  {t("common:button.delete")}
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <ModalForm<AutomationTaskFormModel>
        onCancel={() => {
          toggleModal(false);
          setModel(null);
        }}
        visible={showModal}
        title={t("automationTasks.title")}
        width={900}
        loading={!!loading}
      >
        <AutomationTaskForm
          onSubmit={handleSubmit}
          model={
            model
              ? {
                  ...model,
                  description:
                    model.creator === TaskCreator.System ? t<string>(model.description ?? "") : model.description,
                }
              : undefined
          }
        />
      </ModalForm>
    </Card>
  );
};

export default AutomationTasks;
