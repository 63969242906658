import React from "react";
import { VideoCameraAddOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Switch, Tooltip } from "antd";

interface RecordVideoSwitchProps {
  exerciseInWorkoutId: string;
  size?: "small" | "default";
  toggleRecordingVideoFlag?: (exerciseId: string, checked: boolean) => void;
  defaultValue?: boolean;
}

export const RecordVideoSwitch = ({
  exerciseInWorkoutId,
  size = "default",
  toggleRecordingVideoFlag,
  defaultValue,
}: RecordVideoSwitchProps) => {
  if (!toggleRecordingVideoFlag) {
    return null;
  }
  return (
    <Tooltip title="Wymagaj dodania przez klienta video z techniką ćwiczenia">
      <Switch
        defaultChecked={defaultValue}
        onChange={(checked) => toggleRecordingVideoFlag(exerciseInWorkoutId, checked)}
        size={size}
        onClick={(_checked, event) => event.stopPropagation()}
        checkedChildren={<VideoCameraAddOutlined />}
        unCheckedChildren={<VideoCameraOutlined />}
      />
    </Tooltip>
  );
};
