import { createAsyncThunk } from "@reduxjs/toolkit";
import { type EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";
import { getError, getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export type PayloadAC = {
  url: string;
  apiKey: string;
  client: EmailClient.ActiveCampaign;
};

export type PayloadCK = {
  url: string;
  apiSecret: string;
  client: EmailClient.ConvertKit;
};

export type PayloadMailerLite = {
  url: string;
  apiKey: string;
  client: EmailClient.MailerLite;
};

export type PayloadGetResponse = {
  url: string;
  apiKey: string;
  client: EmailClient.GetResponse;
};

export type PayloadMailChimp = {
  url: string;
  apiKey: string;
  client: EmailClient.MailChimp;
  serverPrefix: string;
};

export type PayloadFreshMail = {
  url: string;
  apiKey: string;
  client: EmailClient.FreshMail;
  firstNameKey: string;
  lastNameKey: string;
};

export const integrateWithEmailClient = createAsyncThunk<
  void,
  ActionParams<PayloadAC | PayloadCK | PayloadMailerLite | PayloadMailChimp | PayloadGetResponse | PayloadFreshMail>,
  AsyncThunkCreator<string>
>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/integrateWithEmailClient`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { integrationApi, analytics } }) => {
    try {
      await integrationApi.post("/integrate-with-email-client", payload);

      analytics.track("integrate_with_email_client", {
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.(getError(e).status);

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
