import { type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";

export const createWorkoutDayOptions = (
  workouts: TrainingProgramDetails["workouts"],
  archivedWorkouts: TrainingProgramDetails["archivedWorkouts"],
) => {
  const list: { value: string; label: string; disabled?: boolean }[] = workouts.map((workout) => ({
    value: workout.id,
    label: workout.name,
  }));
  if (archivedWorkouts?.length) {
    archivedWorkouts.forEach((workout) => {
      list.push({ value: workout.id, label: `${workout.name} (usunięty)`, disabled: true });
    });
  }
  return list;
};
