import { v4 as uuidv4 } from "uuid";

import { type ClientMealsPlanDetails, type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import {
  MEALS_SCHEMA,
  MealsPerDay,
  WEEKDAYS,
  type MealsPlan,
  type MealsPlanDetails,
} from "@fitness-app/data-models/entities/MealsPlan";

import { generateUniqId } from "../helpers/generateUniqId";

export const generateMealsPlanDetails = (
  mealsPlan: MealsPlan,
  template?: ClientMealsPlanDetails | MealsPlanDetails | null,
): MealsPlanDetails => {
  return {
    id: uuidv4(),
    planId: mealsPlan.id,
    supplements: template?.supplements || [],
    weeks: template?.weeks
      ? template.weeks.map((week) => ({
          ...week,
          days: week.days.map((day) => ({
            ...day,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            id: uuidv4(),
          })),
        }))
      : [
          {
            weekNumber: 1,
            days: Object.values(WEEKDAYS).map((day) => ({
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              id: uuidv4(),
              comment: "",
              isTrainingDay: false,
              trainingCalories: 0,
              name: day,
              meals: MEALS_SCHEMA[mealsPlan.mealsSchema || MealsPerDay.THREE].map((meal) => ({
                ...meal,
                id: uuidv4(),
              })),
            })),
          },
        ],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const generateUpdatedMealsPlanSchema = (
  mealsPlanDetails: MealsPlanDetails,
  mealsSchema: MealsPerDay,
): Partial<MealsPlanDetails> & {
  weeks: MealsPlanDetails["weeks"];
  id: string;
} => {
  return {
    id: mealsPlanDetails.id,
    updatedAt: new Date().toISOString(),
    weeks: [
      {
        weekNumber: 1,
        days:
          mealsPlanDetails.weeks[0]?.days.map((day) => ({
            ...day,
            meals: MEALS_SCHEMA[mealsSchema || MealsPerDay.THREE].map((meal) => {
              const alreadyExists = day.meals.find((m) => m.type === meal.type);
              if (alreadyExists) {
                return alreadyExists;
              }
              return {
                ...meal,
                id: uuidv4(),
              };
            }),
          })) || [],
      },
    ],
  };
};

export const generateTraineeMealsPlanDetails = (
  mealsPlan: ClientNutrition | { id: MealsPlan["id"]; mealsSchema: MealsPlan["mealsSchema"] },
  traineeId: string,
  template?: MealsPlanDetails | null,
  supplements?: ClientMealsPlanDetails["supplements"],
): ClientMealsPlanDetails => {
  return {
    id: generateUniqId(),
    traineeId,
    planId: mealsPlan.id,
    supplements: supplements || template?.supplements || [],
    weeks: template?.weeks
      ? (template.weeks.map((week) => ({
          weekNumber: week.weekNumber,
          days: week.days.map((day) => ({
            ...day,
            id: generateUniqId(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            meals: day.meals.map((meal) => ({
              ...meal,
              dishes: meal.dishes.map((dish) => {
                if (dish.type === "ingredient") {
                  const { kcalmar, ...rest } = dish;
                  return rest;
                } else {
                  const ingredients = dish.ingredients.map((ingredient) => {
                    const { kcalmar, ...rest } = ingredient;
                    return rest;
                  });
                  return {
                    ...dish,
                    ingredients,
                  };
                }
              }),
              id: generateUniqId(),
            })),
          })),
        })) as ClientMealsPlanDetails["weeks"])
      : [
          {
            weekNumber: 1,
            days: Object.values(WEEKDAYS).map((day) => ({
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              id: uuidv4(),
              comment: "",
              isTrainingDay: false,
              trainingCalories: 0,
              name: day,
              meals: MEALS_SCHEMA[mealsPlan.mealsSchema || MealsPerDay.THREE].map((meal) => ({
                ...meal,
                id: uuidv4(),
              })),
            })),
          },
        ],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};
