type ParsedVersion = {
  nativeVersion: string;
  buildNumber: number;
  jsCodeVersion: string;
};

type Response = {
  status: "latest" | "outdated" | "critical" | "withIssues";
  currentUserVersion: string | null;
};

// Function to parse the version string and extract the native version, build number, and js code version
function parseVersion(version: string): ParsedVersion | null {
  const regex = /(\d+\.\d+\.\d+)(?: \((\d+)\))? - (\d+\.\d+\.\d+)(?: \((\w+)\))?/;
  const match = version.match(regex);
  if (!match) {
    return null;
  }
  return {
    nativeVersion: match[1] || "",
    buildNumber: parseInt(match[2] || "", 10) || 0,
    jsCodeVersion: match[3] || "",
  };
}

function compareDottedVersions(v1: string, v2: string): number {
  const parts1 = v1.split(".").map(Number);
  const parts2 = v2.split(".").map(Number);
  const length = Math.max(parts1.length, parts2.length);

  for (let i = 0; i < length; i++) {
    const num1 = parts1[i] || 0;
    const num2 = parts2[i] || 0;
    if (num1 > num2) return 1;
    if (num1 < num2) return -1;
  }
  return 0;
}

export function compareClientAppVersion({
  currentUserVersion,
  requiredVersion,
  currentAvailableVersion,
  versionsWithIssues = [],
}: {
  currentUserVersion: string | null;
  requiredVersion: string;
  currentAvailableVersion: string;
  versionsWithIssues?: string[];
}): Response {
  if (!currentUserVersion) {
    return { status: "latest", currentUserVersion };
  }

  if (versionsWithIssues?.length) {
    const currentWithoutMobile = currentUserVersion.replace(" (mobile)", "");
    if (versionsWithIssues.includes(currentWithoutMobile)) {
      return { status: "withIssues", currentUserVersion };
    }
  }

  const userVersion = parseVersion(currentUserVersion);
  const latestVersion = parseVersion(currentAvailableVersion);

  if (!userVersion || !latestVersion) {
    return { status: "outdated", currentUserVersion };
  }

  const nativeComparison = compareDottedVersions(userVersion.nativeVersion, latestVersion.nativeVersion);
  const jsComparison = compareDottedVersions(userVersion.jsCodeVersion, latestVersion.jsCodeVersion);

  if (nativeComparison >= 0 && jsComparison >= 0) {
    return { status: "latest", currentUserVersion };
  }

  const requiredVer = parseVersion(requiredVersion);

  if (!requiredVer) {
    return { status: "outdated", currentUserVersion };
  }

  const nativeComparisonRequired = compareDottedVersions(userVersion.nativeVersion, requiredVer.nativeVersion);
  const jsComparisonRequired = compareDottedVersions(userVersion.jsCodeVersion, requiredVer.jsCodeVersion);

  if (nativeComparisonRequired < 0 || jsComparisonRequired < 0) {
    return { status: "critical", currentUserVersion };
  }

  return { status: "outdated", currentUserVersion };
}
