import React, { useEffect, useMemo } from "react";
import { Button, Checkbox, Input, List, message, Select, Space, Switch, Typography } from "antd";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { teamActions, traineesActions } from "@fitness-app/app-store";
import { getErrorMessage } from "@fitness-app/utils";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useTagOptions } from "~/hooks/useTagOptions";
import { type SelectedUsersListItem } from "~/modules/Chat/SendMessageToMultipleUsers/types";
import { useAppDispatch } from "~/store/initializeStore";

interface SelectedUsersListProps {
  selectedUsers: SelectedUsersListItem[];
  setSelectedUsers: (users: SelectedUsersListItem[]) => void;
  setSelectedTags: (tags: string[]) => void;
  selectedTags: string[];
  condition: "hasTag" | "notHasTag" | false;
  changeCondition: (value: "hasTag" | "notHasTag" | false) => void;
  sendToMarketingChannelIfExists: boolean;
  toggleSendToMarketingChannel: (value: boolean) => void;
}

const { Search } = Input;

export const SelectedUsersList = ({
  selectedUsers,
  setSelectedUsers,
  setSelectedTags,
  changeCondition,
  condition,
  sendToMarketingChannelIfExists,
  toggleSendToMarketingChannel,
}: SelectedUsersListProps): React.ReactElement => {
  const [checkAll, setCheckAll] = React.useState(false);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const { options: tags } = useTagOptions("traineeTags");
  const dispatch = useAppDispatch();
  const { t } = useTranslation("dashboard");
  const { isTrainer } = useUserRole();
  const [clientList, setClientList] = React.useState<SelectedUsersListItem[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  const clientsById = useMemo(() => keyBy(clientList, "id"), [clientList]);

  useEffect(() => {
    void dispatch(teamActions.fetchTrainerConfig());
    dispatch(traineesActions.fetchAllActiveTrainees())
      .unwrap()
      .then((response) => {
        setClientList(response.list as SelectedUsersListItem[]);
      })
      .catch((e) => message.error(getErrorMessage(e)));
  }, [dispatch]);

  const shouldHideList = Boolean(condition);

  const selectedById = useMemo(() => keyBy(selectedUsers, "id"), [selectedUsers]);

  const onItemChange = (checked: boolean, id: string) => {
    if (checked && !selectedById[id]) {
      const selected = clientsById[id];
      setSelectedUsers(selected ? [...selectedUsers, selected] : selectedUsers);
    } else if (!checked && selectedById[id]) {
      setSelectedUsers(selectedUsers.filter((item) => item.id !== id));
    }
  };

  const filteredClients = useMemo(() => {
    if (searchTerm.length > 0) {
      return clientList.filter((client) => {
        return (
          client.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          client.lastName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }

    return clientList;
  }, [clientList, searchTerm]);

  return (
    <div>
      <Space direction="vertical" size={16}>
        {isTrainer && (
          <Space>
            <Switch checked={sendToMarketingChannelIfExists} onChange={toggleSendToMarketingChannel} />
            <Typography.Text>Wyślij wiadomości na kanał ogłoszeniowy/marketingowy jeśli istnieje</Typography.Text>
          </Space>
        )}
        <Space>
          <Switch
            checked={condition === "hasTag"}
            onChange={(active) => {
              changeCondition(active ? "hasTag" : false);
              setSelectedTags([]);
            }}
          />
          <Typography.Text>Wyślij do wszystkich klientów którzy posiadają tag:</Typography.Text>
        </Space>
        {condition === "hasTag" && (
          <Select
            placeholder="Wybierz tagi..."
            style={{ minWidth: 250 }}
            options={tags}
            mode="multiple"
            maxTagCount={10}
            onChange={setSelectedTags}
          />
        )}
        <Space>
          <Switch
            checked={condition === "notHasTag"}
            onChange={(active) => {
              changeCondition(active ? "notHasTag" : false);
              setSelectedTags([]);
            }}
          />
          <Typography.Text>Wyślij do wszystkich klientów którzy nie posiadają tag:</Typography.Text>
        </Space>

        {condition === "notHasTag" && (
          <Select
            placeholder="Wybierz tagi..."
            style={{ minWidth: 250 }}
            mode="multiple"
            maxTagCount={10}
            options={tags}
            onChange={setSelectedTags}
          />
        )}

        {shouldHideList ? null : (
          <Checkbox
            indeterminate={indeterminate}
            onChange={(value) => {
              setCheckAll(value.target.checked);
              setIndeterminate(false);
              setSelectedUsers(value.target.checked ? clientList : []);
            }}
            checked={checkAll}
          >
            Zaznacz wszystkich klientów ({selectedUsers.length})
          </Checkbox>
        )}
      </Space>

      {!shouldHideList && (
        <div className="mt-4 flex flex-col gap-y-2">
          <Space size={4}>
            <Search
              value={searchTerm}
              placeholder="Wyszkuj klienta..."
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: 250 }}
            />
            {Boolean(searchTerm) && (
              <Button onClick={() => setSearchTerm("")} type="link">
                {t("products:search.reset")}
              </Button>
            )}
          </Space>
          <Checkbox.Group value={selectedUsers.map((item) => item.id)}>
            <List
              itemLayout="horizontal"
              split={false}
              pagination={{ defaultCurrent: 1, defaultPageSize: 10, hideOnSinglePage: true }}
              dataSource={filteredClients}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.id} onChange={(event) => onItemChange(event.target.checked, item.id)}>
                    {item.firstName} {item.lastName}
                  </Checkbox>
                </List.Item>
              )}
              locale={{ emptyText: "Brak aktywnych klientów" }}
            />
          </Checkbox.Group>
        </div>
      )}
    </div>
  );
};
