import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_PRODUCTS_SLICE_NAME } from "../types";

type Payload = void;

export const fetchClientProducts = createAsyncThunk<ProductClient[], Payload, AsyncThunkCreator<string>>(
  `${CLIENT_PRODUCTS_SLICE_NAME}/fetchClientProducts`,
  async (_, { rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("product_client")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("email", currentUser.email)
      .returns<ProductClient[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
