import { type AppThunk } from "../../../index";
import { unsubscribeFromTeamMember as action } from "../reducer";

export const unsubscribeFromTeamMember =
  (): AppThunk =>
  async (dispatch, _, { subscriptions, db }) => {
    if (subscriptions.realtimeTeamMemberListChannel) {
      await db.removeChannel(subscriptions.realtimeTeamMemberListChannel);
      subscriptions.teamMemberListChannelName = null;
    }
    dispatch(action());
  };
