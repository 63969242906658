import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";
import { type NutritionPlanDay } from "@fitness-app/data-models/entities/MealsPlan";

import { RequestStatus } from "../../../enums/requestStatus";
import { deleteProductFromMealsPlan } from "./actions/deleteProductFromMealsPlan";
import { fetchDishesOccurrence } from "./actions/fetchDishesOccurrence";
import { fetchMealsPlanWithDetails } from "./actions/fetchMealsPlanWithDetails";
import { updatedMealsPlanDays } from "./actions/updatedMealsPlanDays";
import { updateProductInMealsPlan } from "./actions/updateProductInMealsPlan";
import { MEALS_PLANS_REDUCER_NAME, type TraineeMealsPlansReducer } from "./types";

const initialState: TraineeMealsPlansReducer = {
  selectedMealsPlan: null,
  selectedMealsPlanStatus: null,
  selectedMealsPlanDetails: null,
  dishesOccurrence: null,
  currentMealsPlanDetails: null,
};

const reducerSlice = createSlice({
  initialState,
  name: MEALS_PLANS_REDUCER_NAME,
  reducers: {
    updateDaysInMealsPlan: (state, { payload }: PayloadAction<NutritionPlanDay[]>) => {
      if (state.selectedMealsPlanDetails?.[0]?.weeks[0]?.days) {
        state.selectedMealsPlanDetails[0].weeks[0].days = payload;
      }
    },
    clearDishesOccurrence(state) {
      state.dishesOccurrence = null;
    },
    clearMealsPlan() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDishesOccurrence.fulfilled, (state, { payload }) => {
      state.dishesOccurrence = payload || [];
    });
    builder.addCase(fetchMealsPlanWithDetails.pending, (state, { meta }) => {
      if (!meta.arg.refetch) {
        state.selectedMealsPlanStatus = RequestStatus.FETCHING;
      }
    });
    builder.addCase(fetchMealsPlanWithDetails.fulfilled, (state, { payload, meta }) => {
      state.selectedMealsPlanStatus = RequestStatus.SUCCESS;
      state.selectedMealsPlan = payload.plan;
      state.selectedMealsPlanDetails = payload.details;

      if (state.selectedMealsPlan.status === ClientNutritionStatus.InProgress && meta.arg.keepCurrent !== false) {
        const details = payload.details[0];
        state.currentMealsPlanDetails = details
          ? {
              ...details,
              name: state.selectedMealsPlan.name,
              startAt: state.selectedMealsPlan.startAt,
              endAt: state.selectedMealsPlan.endAt,
            }
          : null;
      }
    });
    builder.addCase(fetchMealsPlanWithDetails.rejected, (state) => {
      state.selectedMealsPlanStatus = RequestStatus.FAILED;
    });
    builder.addCase(updatedMealsPlanDays.pending, (state, { meta }) => {
      if (state.selectedMealsPlanDetails) {
        state.selectedMealsPlanDetails = state.selectedMealsPlanDetails.map((details) =>
          details.id === meta.arg.id
            ? {
                ...details,
                updatedAt: new Date().toISOString(),
                weeks: [
                  {
                    weekNumber: 1,
                    days: meta.arg.days,
                  },
                ],
              }
            : details,
        );
      }
    });
    builder.addCase(deleteProductFromMealsPlan.fulfilled, (state, { payload }) => {
      if (state.selectedMealsPlanDetails) {
        state.selectedMealsPlanDetails = state.selectedMealsPlanDetails.map((details) =>
          details.id === payload.id ? payload : details,
        );
      }
    });
    builder.addCase(updateProductInMealsPlan.fulfilled, (state, { payload }) => {
      if (state.selectedMealsPlanDetails) {
        state.selectedMealsPlanDetails = state.selectedMealsPlanDetails.map((details) =>
          details.id === payload.id ? payload : details,
        );
      }
    });
  },
});

export const { updateDaysInMealsPlan, clearMealsPlan, clearDishesOccurrence } = reducerSlice.actions;
export default reducerSlice.reducer;
