import { type Product } from "@fitness-app/data-models/entities/Product";
import { type ProductStats } from "@fitness-app/data-models/entities/ProductStats";
import { getErrorMessage } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import {
  addProductToList,
  removeProductFromList,
  subscribeToProductsFailed,
  subscribeToProductsStarted,
  subscribeToProductsSuccess,
  updateProductOnList,
  updateProductStatsOnList,
} from "../reducer";
import { fetchProducts } from "./fetchProducts";
import { unsubscribeFromProducts } from "./unsubscribeFromProducts";

export const subscribeToProducts =
  (): AppThunk =>
  async (dispatch, getState, { auth, subscriptions, db }) => {
    try {
      dispatch(subscribeToProductsStarted());

      void dispatch(fetchProducts());

      const { id } = await getLoggedUser(auth);

      if (subscriptions.productsListChannelName && subscriptions.realtimeProductsListChannel) {
        await db.removeChannel(subscriptions.realtimeProductsListChannel);
      }

      subscriptions.productsListChannelName = `products-list-${id}`;
      subscriptions.realtimeProductsListChannel = db.channel(subscriptions.productsListChannelName);

      subscriptions.realtimeProductsListChannel
        .on<Product>(
          "postgres_changes",
          { event: "*", schema: "public", table: "product", filter: `owner=eq.${id}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductOnList(payload.new));
            }
            if (payload.eventType === "INSERT") {
              dispatch(addProductToList(payload.new));
            }
            if (payload.eventType === "DELETE" && payload.old.id) {
              dispatch(removeProductFromList(payload.old.id));
            }
          },
        )
        .on<ProductStats>(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "product_stats" },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductStatsOnList({ product_stats: payload.new, id: payload.new.productId }));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToProductsSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromProducts());
          }
        });
    } catch (error) {
      dispatch(subscribeToProductsFailed(getErrorMessage(error)));
    }
  };
