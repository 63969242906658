import { HIDDEN_CHECKOUT_PATH } from "../checkout/checkout";

type GetProductLinkParams = {
  productId: string;
  slug?: string;
  priceId?: string;
  ref?: string;
  customDomain?: string;
  domain: string;
  checkoutId?: string | string[] | null;
};

export const getProductLinkForMultiplePrices = ({ productId, ref, slug, checkoutId, domain }: GetProductLinkParams) => {
  return encodeURI(
    `${domain}/c/${HIDDEN_CHECKOUT_PATH}/${slug || productId}${
      checkoutId ? `?variant=${Array.isArray(checkoutId) ? checkoutId.join(",") : checkoutId}` : ""
    }${ref ? `${checkoutId ? "&" : "?"}ref=${ref}` : ""}`,
  );
};

export const getProductLink = ({
  productId,
  priceId = "",
  ref,
  customDomain,
  slug,
  checkoutId,
  domain,
}: GetProductLinkParams) => {
  if (customDomain) {
    const joinSlashOrEmptyStr = customDomain.endsWith("/") || customDomain.includes("?") ? "" : "/";
    const refParam = customDomain.includes("?") ? `&ref=${ref}` : `?ref=${ref}`;

    return `${customDomain}${joinSlashOrEmptyStr}${ref ? refParam : ""}`;
  }

  if (domain && priceId === "free") {
    return `${domain}/embed/${productId}${ref ? `?ref=${ref}` : ""}`;
  }

  if (checkoutId) {
    return encodeURI(
      `${domain}/c/${HIDDEN_CHECKOUT_PATH}/${slug || productId}${
        checkoutId ? `?variant=${Array.isArray(checkoutId) ? checkoutId.join(",") : checkoutId}` : ""
      }${ref ? `${checkoutId ? "&" : "?"}ref=${ref}` : ""}`,
    );
  }

  return encodeURI(
    `${domain}/c/${slug || productId}${priceId ? `?priceId=${priceId}` : ""}${
      ref ? `${priceId ? "&" : "?"}ref=${ref}` : ""
    }`,
  );
};
