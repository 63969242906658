import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramAutomationWithInitialSurvey } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../../index";
import { AUTOMATION_REDUCER_NAME } from "../types";

type Payload = string;

export const fetchProgramAutomation = createAsyncThunk<
  ProgramAutomationWithInitialSurvey,
  Payload,
  AsyncThunkCreator<string>
>(`${AUTOMATION_REDUCER_NAME}/fetchProgramAutomation`, async (payload, { rejectWithValue, extra: { db } }) => {
  const { error, data } = await db
    .from("program_automation")
    .select("*, initialSurvey:initialSurveyId(id, title, name), additionalSurvey:additionalSurveyId(id, title, name)")
    .eq("id", payload)
    .single<ProgramAutomationWithInitialSurvey>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return data;
});
