import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import {
  NotificationStatus,
  type ClientNotifications,
  type ClientReminders,
  type OwnerNotifications,
  type TrainerNotifications,
} from "@fitness-app/data-models/entities/Notification";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchNotifications } from "./actions/fetchNotifications";
import { markAllAsRead } from "./actions/markAllAsRead";
import { markAsRead } from "./actions/markAsRead";
import { NOTIFICATIONS_REDUCER_NAME, type NotificationsReducer } from "./types";

const initialState: NotificationsReducer = {
  notifications: [],
  listStatus: null,
  totalUnread: 0,
  filter: "all",
  performingAction: null,
  page: 0,
  pageSize: 30,
  totalPages: 0,
};

const reducerSlice = createSlice({
  initialState,
  name: NOTIFICATIONS_REDUCER_NAME,
  reducers: {
    subscribeToNotificationsPending(state) {
      state.listStatus = RequestStatus.SUBSCRIBING;
    },
    subscribeToNotificationsSuccess(state) {
      state.listStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToNotificationsFailed(state) {
      state.listStatus = RequestStatus.FAILED;
    },
    unsubscribeFromNotifications(state) {
      state.notifications = [];
      state.listStatus = null;
    },
    setNumberOfUnreadNotifications(state, { payload }: PayloadAction<number>) {
      state.totalUnread = payload;
    },
    updateNotificationsOnList(
      state,
      {
        payload,
      }: PayloadAction<Partial<ClientNotifications | OwnerNotifications | TrainerNotifications | ClientReminders>>,
    ) {
      state.notifications = state.notifications.map((notification) => {
        if (notification.id === payload.id && notification.type === payload.type) {
          return {
            ...notification,
            ...payload,
          } as ClientNotifications | OwnerNotifications | TrainerNotifications | ClientReminders;
        }

        return notification;
      });
    },
    deleteNotificationFromList(state, { payload }: PayloadAction<string>) {
      state.notifications = state.notifications.filter((file) => file.id !== payload);
    },
    addNotificationToList(
      state,
      { payload }: PayloadAction<ClientNotifications | OwnerNotifications | TrainerNotifications | ClientReminders>,
    ) {
      state.notifications =
        state.filter === "unread" && payload.status === NotificationStatus.READ
          ? state.notifications
          : [payload, ...state.notifications];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state, { meta }) => {
      if (!meta.arg.page) {
        state.listStatus = RequestStatus.FETCHING;
        state.filter = meta.arg.filter;
      }
    });
    builder.addCase(fetchNotifications.fulfilled, (state, { meta, payload }) => {
      if (!meta.arg.page) {
        state.listStatus = RequestStatus.SUCCESS;
      }
      state.notifications = payload.currentPage > 1 ? [...state.notifications, ...payload.data] : payload.data;
      state.page = payload.currentPage;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(fetchNotifications.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(markAsRead.pending, (state, { meta }) => {
      state.notifications = state.notifications
        .map((n) => (n.id === meta.arg ? { ...n, status: NotificationStatus.READ } : n))
        .filter((n) => (state.filter === "unread" ? n.status === NotificationStatus.UNREAD : n));

      state.totalUnread = state.totalUnread > 0 ? state.totalUnread - 1 : 0;
    });
    builder.addCase(markAllAsRead.pending, (state) => {
      state.notifications = state.notifications.map((n) => ({ ...n, status: NotificationStatus.READ }));

      state.totalUnread = 0;

      if (state.filter === "unread") {
        state.notifications = [];
      }
    });
  },
});

export const {
  setNumberOfUnreadNotifications,
  subscribeToNotificationsFailed,
  subscribeToNotificationsSuccess,
  unsubscribeFromNotifications,
  subscribeToNotificationsPending,
  updateNotificationsOnList,
  deleteNotificationFromList,
  addNotificationToList,
} = reducerSlice.actions;

export default reducerSlice.reducer;
