import React, { useEffect, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Select, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";

import { type ProgramSchedule, type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";
import { createWorkoutDayOptions } from "@fitness-app/utils/src/programs/createWorkoutDayOptions";

import { week } from "~/constants/weekOptions";
import { type ProgramScheduleFormModel } from "~/modules/Automation/ScheduleProgramStepper/steps/types";

interface ProgramWeekScheduleProps {
  onSubmit: (model: ProgramScheduleFormModel) => void;
  formController: FormInstance<ProgramScheduleFormModel>;
  schedule?: ProgramSchedule | null;
  programWorkouts: TrainingProgramDetails;
}

const weekInitial = {
  monday: { workoutDay: null },
  tuesday: { workoutDay: null },
  wednesday: { workoutDay: null },
  thursday: { workoutDay: null },
  friday: { workoutDay: null },
  saturday: { workoutDay: null },
  sunday: {
    workoutDay: null,
  },
};

const ProgramWeekSchedule = ({ formController, onSubmit, schedule, programWorkouts }: ProgramWeekScheduleProps) => {
  const workoutsOptions = useMemo(() => {
    return createWorkoutDayOptions(programWorkouts.workouts ?? [], programWorkouts.archivedWorkouts ?? []);
  }, [programWorkouts]);

  useEffect(() => {
    if (schedule) {
      formController.setFieldsValue({
        weeks: schedule,
      });
    } else {
      formController.resetFields();
    }
  }, [schedule, formController]);

  return (
    <div>
      <Form layout="vertical" onFinish={onSubmit} form={formController}>
        <Form.List initialValue={[weekInitial]} name="weeks">
          {(fields, { add, remove }) => (
            <div style={{ display: "flex" }}>
              {fields.map((field, index) => (
                <div className="flex-1" key={field.name}>
                  <div className="flex">
                    <Typography.Title level={5}>
                      Tydzień: {String.fromCharCode(97 + index).toUpperCase()}
                    </Typography.Title>
                    {index === 1 && (
                      <Button
                        onClick={() => remove(index)}
                        danger
                        size="small"
                        className="ml-8"
                        icon={<DeleteOutlined />}
                      />
                    )}
                  </div>

                  {week.map((day) => (
                    <Form.Item
                      {...field}
                      key={`${field.name}-${day.key}`}
                      name={[field.name, day.key, "workoutDay"]}
                      label={day.label}
                      wrapperCol={{ xs: 18 }}
                    >
                      <Select options={workoutsOptions} placeholder="Wybierz dzień treningowy" allowClear />
                    </Form.Item>
                  ))}
                </div>
              ))}
              {fields.length === 1 && (
                <div className="flex flex-1 items-center justify-center">
                  <Button type="dashed" onClick={() => add(weekInitial)}>
                    Aktywuj tydzień B
                  </Button>
                </div>
              )}
            </div>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default ProgramWeekSchedule;
