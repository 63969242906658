import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Measurement } from "@fitness-app/data-models/entities/Measurement";
import { type DiscriminateUnion } from "@fitness-app/utils/src/types/utils";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  eventDate: string;
  traineeId: string;
  type: Measurement["type"];
};

export type SelectedMeasurement<T extends Measurement["type"]> = DiscriminateUnion<Measurement, "type", T>;

export const fetchMeasurementForDay = createAsyncThunk<
  SelectedMeasurement<Payload["type"]> | null,
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/fetchMeasurementForDay`,
  async ({ traineeId, type, eventDate }, { rejectWithValue, extra: { db, errorTrackingService } }) => {
    const { error, data } = await db
      .from("measurement")
      .select("*")
      .order("eventTimestamp", { ascending: false })
      .match({ eventDate: eventDate, type: type, traineeId: traineeId })
      .returns<SelectedMeasurement<typeof type>[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    const first = data[0];

    if (!data || data.length === 0 || !first) {
      return null;
    }

    if (data.length === 2) {
      errorTrackingService?.recordError(
        new Error("More than one measurement found for the same day"),
        "fetchMeasurementForDay",
      );
    }

    return first;
  },
);
