import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProductsSettings } from "@fitness-app/data-models/entities/ProductsSettings";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchProductsSettings = createAsyncThunk<ProductsSettings, Payload, AsyncThunkCreator<string>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/fetchProductsSettings`,
  async (_, { rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("products_settings")
      .select()
      .eq("userId", currentUser.id)
      .single<ProductsSettings>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
