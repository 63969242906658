import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Steps } from "antd";
import dayjs from "dayjs";

import { chatActions } from "@fitness-app/app-store";
import { getErrorMessage } from "@fitness-app/utils";

import { useEntityChange } from "~/hooks/useEntityChange";
import { generateDaysOfSent } from "~/modules/Automation/AutomatedMessagesForm/useGeneratedDaysForMessages";
import MessageComposeForm from "~/modules/Chat/SendMessageToMultipleUsers/MessageComposeForm";
import { SelectedUsersList } from "~/modules/Chat/SendMessageToMultipleUsers/SelectedUsersList";
import { useAppDispatch } from "~/store/initializeStore";
import { type MessageComposeFormModel, type SelectedUsersListItem } from "./types";

interface SendMessageToMultipleUsersModalProps {
  open: boolean;
  onClose: () => void;
}

enum Step {
  SelectUsers,
  WriteMessage,
}

const prepareSuccessMessage = ({ count, clients, now }: { count: number; clients: number; now: boolean }) => {
  if (clients === 0) {
    return "Nie znaleziono klientów spełniających kryteria wysyłki wiadomości";
  }

  if (count !== clients && !now) {
    return `Zaplanowano wysłanie ${count} wiadomości do ${clients} klientów`;
  }

  if (count !== clients && now) {
    return `Wysłano ${count} wiadomości do ${clients} klientów`;
  }

  return `Wysłano wiadomość do ${clients} klientów`;
};

export const SendMessageToMultipleUsersModal = ({
  open,
  onClose,
}: SendMessageToMultipleUsersModalProps): React.ReactElement => {
  const [currentStep, setStep] = React.useState(Step.SelectUsers);
  const [selectedUsers, setSelectedUsers] = React.useState<SelectedUsersListItem[]>([]);
  const [formController] = Form.useForm<MessageComposeFormModel>();
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const dispatch = useAppDispatch();
  const allowedToSend = selectedTags.length !== 0 || selectedUsers.length !== 0;
  const [tagCondition, setTagCondition] = React.useState<"hasTag" | "notHasTag" | false>(false);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(onClose);
  const [sendToMarketingChannelIfExists, toggleSendToMarketingChannel] = useState(false);

  useEffect(() => {
    formController.resetFields();
    if (!open) {
      setSelectedUsers([]);
      setStep(Step.SelectUsers);
      setTagCondition(false);
      setSelectedTags([]);
    }
  }, [open]);

  const steps = [Step.SelectUsers, Step.SelectUsers];

  const handleFormSubmit = async (model: MessageComposeFormModel) => {
    let dates:
      | undefined
      | {
          sendAt: number;
          sendDate: string;
          sentTime: {
            hours: number;
            minutes: number;
          };
        }[];
    try {
      onStart();
      if (model.daysOfDelivery && !model.sendImmediately) {
        const generatedDaysOfDelivery = generateDaysOfSent({
          sentDate: model.sentDate,
          daysOfDelivery: model.daysOfDelivery,
          numberOfRepeats: model.numberOfRepeats || 1,
        });
        dates = generatedDaysOfDelivery.map((day) => {
          const dayjsDate = dayjs(day);
          const sendDate = new Date(
            dayjsDate.get("year"),
            dayjsDate.get("month"),
            dayjsDate.get("date"),
            model.sentTime.get("hours"),
            model.sentTime.get("minutes"),
          );

          return {
            sendDate: sendDate.toISOString(),
            sentTime: {
              hours: model.sentTime.get("hour"),
              minutes: model.sentTime.get("minutes"),
            },
            sendAt: Math.floor(sendDate.getTime() / 1000),
          };
        });
      }
      if (selectedUsers.length) {
        const response = await dispatch(
          chatActions.sendMessageToMultipleUsers({
            messages: model.messages,
            sendImmediately: model.sendImmediately,
            clients: selectedUsers,
            authorId: model.authorId,
            authorRole: model.authorRole,
            authorName: model.authorName,
            authorAvatar: model.authorAvatar,
            dates,
            sendToMarketingChannelIfExists,
          }),
        ).unwrap();
        onSuccess(
          prepareSuccessMessage({ count: response.count, clients: response.clients, now: model.sendImmediately }),
          response.clients === 0 ? "warning" : "success",
        );
      } else if (tagCondition && selectedTags.length) {
        const response = await dispatch(
          chatActions.sendMessageToMultipleUsers({
            messages: model.messages,
            sendImmediately: model.sendImmediately,
            tags: selectedTags,
            authorId: model.authorId,
            authorRole: model.authorRole,
            authorName: model.authorName,
            authorAvatar: model.authorAvatar,
            condition: tagCondition,
            dates,
            sendToMarketingChannelIfExists,
          }),
        ).unwrap();
        onSuccess(
          prepareSuccessMessage({ count: response.count, clients: response.clients, now: model.sendImmediately }),
          response.clients === 0 ? "warning" : "success",
        );
      }
    } catch (e) {
      onFailure(getErrorMessage(e));
    }
  };

  return (
    <Modal
      destroyOnClose
      open={open}
      title="Wyślij wiadomość do wielu klientów"
      onCancel={onClose}
      width={800}
      footer={
        <div className="flex items-center justify-end gap-3">
          {currentStep > 0 && <Button onClick={() => setStep((prev) => prev - 1)}>Cofnij</Button>}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => setStep((prev) => prev + 1)} disabled={!allowedToSend}>
              Dalej
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={() => formController.submit()} loading={!!loading}>
              Wyślij
            </Button>
          )}
        </div>
      }
    >
      <div className="py-3">
        <Steps
          current={currentStep}
          items={[
            {
              title: "Wybór klientów",
            },
            {
              title: "Treść wiadomości",
            },
          ]}
        />
      </div>
      <div>
        {currentStep === Step.SelectUsers ? (
          <SelectedUsersList
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            changeCondition={setTagCondition}
            condition={tagCondition}
            sendToMarketingChannelIfExists={sendToMarketingChannelIfExists}
            toggleSendToMarketingChannel={toggleSendToMarketingChannel}
          />
        ) : (
          <MessageComposeForm onSubmit={handleFormSubmit} formController={formController} />
        )}
      </div>
    </Modal>
  );
};
