import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Dayjs } from "dayjs";

import { type WorkoutActivityEventWithFeedback } from "@fitness-app/data-models/entities/ProgramActivity";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  selectedDate: Dayjs;
  unit?: "month" | "week" | "day";
  optimistic?: boolean;
};

export const fetchTraineeActivitiesForRange = createAsyncThunk<
  WorkoutActivityEventWithFeedback[],
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/fetchTraineeActivitiesForRange`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const from = payload.selectedDate
      .subtract(1, payload.unit || "month")
      .startOf(payload.unit || "month")
      .toISOString();
    const to = payload.selectedDate
      .add(1, payload.unit || "month")
      .endOf(payload.unit || "month")
      .toISOString();

    const { error, data } = await db
      .from("client_workout_activity")
      .select(
        "*, workoutRate:feedbackId(data->activityId, data->workoutId, data->rate, data->comment, data->trainingStatus, data->programId)",
      )
      .order("createdAt", { ascending: false })
      .eq("traineeId", payload.traineeId)
      .gte("eventTimestamp", from)
      .lte("eventTimestamp", to)
      .returns<WorkoutActivityEventWithFeedback[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
