import { useQuery } from "@tanstack/react-query";

import { supabase } from "~/store/initializeStore";

export const ALL_PRODUCTS_ITEM_ID = "all";

export const useProductsList = () => {
  const { data, isLoading } = useQuery(["useProductsList"], {
    keepPreviousData: true,
    queryFn: async () => {
      const { error, data } = await supabase.from("product").select("id, name").throwOnError();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  return {
    isLoading,
    productsList: [
      { label: "Wszystkie produkty", value: ALL_PRODUCTS_ITEM_ID },
      ...(data || []).map((product) => ({ value: product.id, label: product.name })),
    ],
    list: (data || []).map((product) => ({ value: product.id, label: product.name })),
  };
};
