import truncate from "lodash.truncate";

export const FILE_CHARS_NAME_LIMIT = 20;

export const createFileShortcutName = (name: string, maxLength = FILE_CHARS_NAME_LIMIT): string => {
  if (name.length <= maxLength) {
    return name;
  }
  const splitToArr = name.split(".");
  const extension = splitToArr.pop();
  const truncatedName = truncate(splitToArr.join(), {
    length: maxLength,
    omission: " (...).",
  });

  return truncatedName.concat(extension || "");
};
