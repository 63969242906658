import React, { useEffect, type FunctionComponent } from "react";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { Button, Space, Table, Tag, type TableProps } from "antd";
import dayjs from "dayjs";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { productClientsActions, RequestStatus } from "@fitness-app/app-store";
import { TaskType } from "@fitness-app/data-models/entities/Automation";
import { PaymentTypeOption, type Product } from "@fitness-app/data-models/entities/Product";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import DiscordIdCell from "~/modules/Products/Product/ProductClientsList/components/DiscordIdCell";
import { PaymentTypeTag } from "~/modules/Products/Product/ProductClientsList/components/PaymentTypeTag";
import ProductClientStatusBadge from "~/modules/Products/Product/ProductClientsList/components/ProductClientStatusBadge";
import ProductOneTimePaymentInfo from "~/modules/Products/Product/ProductClientsList/components/ProductOneTimePaymentInfo/ProductOneTimePaymentInfo";
import ProductSubscriptionInfo from "~/modules/Products/Product/ProductClientsList/components/ProductSubscriptionInfo/ProductSubscriptionInfo";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  details: Product;
  productId: string;
  ownerId?: string;
}

type Props = OwnProps;

const statuses = ["active", "archived"] as const;
export const createStatusOptions = () => {
  const list: { value: ProductClient["status"]; text: React.ReactElement }[] = [];
  statuses.forEach((item) => {
    list.push({ value: item, text: <ProductClientStatusBadge status={item} /> });
  });
  return list;
};

const statusesOptions = createStatusOptions();

const ProductClientsTable: FunctionComponent<Props> = ({ details, ownerId }) => {
  const { t } = useTranslation("products");
  const navigate = useNavigate();
  const { data: productAutomation } = useAppSelector((store) => store.productAutomation);
  const dispatch = useAppDispatch();
  const { listStatus, list, page, listSize, totalPages, filters, searchTerm } = useAppSelector(
    (store) => store.productClients,
  );
  const productId = details.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const documentVisible = useVisibilityChange();

  useEffect(() => {
    const page = searchParams.get("page");
    void dispatch(productClientsActions.fetchProductClients({ productId, page: page ? Number(page) : undefined }));
  }, [dispatch, ownerId, productId, documentVisible]);

  const hasDiscordAutomation = productAutomation?.tasks?.some(
    (automation) => automation.type === TaskType.DiscordIntegration,
  );

  const handleChange: TableProps<ProductClient>["onChange"] = (pagination, currentFilters) => {
    if (!isEqual(currentFilters.status, filters.statuses)) {
      dispatch(productClientsActions.updateStatusesFilter((currentFilters.status || []) as ProductClient["status"][]));

      void dispatch(productClientsActions.fetchProductClients({ productId }));
    } else {
      if (pagination.current && pagination.current !== page) {
        setSearchParams({ page: String(pagination.current || 1) });
        void dispatch(
          productClientsActions.fetchProductClients({
            page: pagination.current,
            productId,
          }),
        );
      }
    }
  };

  return (
    <>
      <Table<ProductClient>
        dataSource={list}
        rowKey="email"
        size="middle"
        scroll={{ x: true }}
        locale={{
          emptyText: searchTerm ? t("search.empty") : t("productClients.emptyState"),
        }}
        loading={listStatus === RequestStatus.FETCHING || listStatus === RequestStatus.UPDATING}
        pagination={{
          current: page,
          pageSize: listSize,
          total: totalPages * listSize,
          pageSizeOptions: ["50"],
          showSizeChanger: false,
        }}
        onChange={handleChange}
      >
        <Table.Column<ProductClient>
          title={t("productTransactions.tableHeader.index")}
          width={50}
          dataIndex="index"
          key="index"
          render={(name, row, i) => (page > 1 ? (page - 1) * listSize + i + 1 : i + 1)}
        />
        <Table.Column<ProductClient>
          title={t("productTransactions.tableHeader.name")}
          dataIndex="name"
          key="name"
          render={(_, row) => `${row.firstName} ${row.lastName}`}
        />
        <Table.Column<ProductClient> title={t("productTransactions.tableHeader.email")} dataIndex="email" key="email" />
        <Table.Column
          title={t("productClients.tableHeader.createdAt")}
          dataIndex="createdAt"
          key="createdAt"
          render={(createdAt: string) => dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
        />
        <Table.Column
          title={t("productClients.tableHeader.status")}
          dataIndex="status"
          key="status"
          filteredValue={filters.statuses}
          filters={statusesOptions}
          render={(status: ProductClient["status"]) => <ProductClientStatusBadge status={status} />}
        />
        {hasDiscordAutomation && (
          <Table.Column<ProductClient>
            title={t("productClients.tableHeader.discordId")}
            dataIndex="discordId"
            key="discordId"
            ellipsis
            render={(_, row) => <DiscordIdCell row={row} />}
          />
        )}
        <Table.Column<ProductClient>
          title={t("productClients.tableHeader.paymentType")}
          dataIndex="paymentType"
          key="paymentType"
          render={(type: (typeof PaymentTypeOption)[keyof typeof PaymentTypeOption], row) => (
            <Space>
              <PaymentTypeTag productClient={row} />
              {!row.upsellToProduct && row.paymentType === PaymentTypeOption.Recurring ? (
                <ProductSubscriptionInfo accessPeriod={row.accessPeriod} status={row.status} />
              ) : null}
              {!row.upsellToProduct &&
              row.paymentType === PaymentTypeOption.OneTime &&
              row.accessPeriod?.type === "one_time" ? (
                <ProductOneTimePaymentInfo accessPeriod={row.accessPeriod} showStrategyInfo />
              ) : null}
              {row.upsellToProduct && (
                <>
                  <Tag color="green">Upsell do: {row.upsellToProduct.productName}</Tag>
                  <Button
                    type="link"
                    size="small"
                    onClick={() => navigate(`/products/${row.upsellToProduct?.productId}/clients/${row.email}`)}
                  >
                    Przejdź
                  </Button>
                </>
              )}
            </Space>
          )}
        />
        <Table.Column<ProductClient>
          title={t("productClients.tableHeader.options")}
          dataIndex="action"
          key="action"
          render={(_, row) => (
            <Space size={4}>
              <Button
                type="link"
                onClick={() =>
                  ownerId
                    ? navigate(`/admin/${ownerId}/${row.productId}/clients/${row.id}`)
                    : navigate(`/products/${row.productId}/clients/${row.id}`)
                }
              >
                {t(`common:details`)}
              </Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default ProductClientsTable;
