import uniq from "lodash.uniq";

import { ExerciseBodyPart, ExerciseType } from "@fitness-app/data-models/entities/Exercise";
import {
  type ExerciseInProgramCardioFormModel,
  type ExerciseInProgramFormModel,
  type ExerciseInWorkout,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";

export const createFieldValueString = (propsArr: (number | [number, number])[] | null, isAggregated = true) => {
  if (!propsArr) {
    return "";
  }
  if (!Array.isArray(propsArr)) {
    return typeof propsArr === "number" ? `${propsArr}` : "";
  }

  const uniqValues = uniq(propsArr.flat());

  if (uniqValues.length === 1) {
    return `${uniqValues[0]}`;
  }
  if (!isAggregated && propsArr.length === 2) {
    return `${propsArr[0]} - ${propsArr[1]}`;
  }
  const arrWithStr = propsArr.map((prop) => {
    if (typeof prop === "number") {
      return `${prop}`;
    }
    if (Array.isArray(prop) && prop[0] === prop[1]) {
      return `${prop[0]}`;
    }
    if (Array.isArray(prop)) {
      return `${prop[0]}-${prop[1]}`;
    }
    return null;
  });
  let uniqPerSet = 0;
  arrWithStr.forEach((item, i) => {
    if (i === 0) {
      uniqPerSet += 1;
      return;
    }
    if (item !== arrWithStr[i - 1]) {
      uniqPerSet += 1;
    }
  });
  return uniqPerSet > 1 ? arrWithStr.join("/") : arrWithStr[0];
};

interface Accu {
  repeats: (number | [number, number])[];
  weight: (number | [number, number])[];
  duration: (number | [number, number] | null)[] | null;
}

export const mapSavedExerciseToFormModel = (exercise: ExerciseInWorkout) => {
  const reduced: Accu = Object.values(exercise.set).reduce<Accu>(
    (prev, current) => ({
      repeats: [...prev.repeats, current.repeats ?? 0],
      weight: [...(prev.weight || 0), current.weight || 0],
      duration: current.duration ? [...(prev.duration || []), current.duration.value] : null,
    }),
    { repeats: [], weight: [], duration: null },
  );

  const filteredDuration = reduced.duration?.filter((item): item is number | [number, number] => !!item);
  return {
    numberOfRepeats: createFieldValueString(reduced.repeats),
    weight: createFieldValueString(reduced.weight),
    duration:
      reduced.duration && exercise.duration
        ? {
            format: exercise.duration.format,
            value: filteredDuration?.length ? createFieldValueString(filteredDuration) : null,
          }
        : null,
  };
};

export const transformExerciseInFormModel = (
  selectedExercise: ExerciseInWorkout,
  exercisesType: WorkoutExercisesType,
): ExerciseInProgramFormModel => {
  const constantsPropModel = {
    numberOfSeries: selectedExercise.numberOfSeries,
    tempo: selectedExercise.tempo || "",
    comment: selectedExercise.comment,

    alternativeExercises: selectedExercise.alternativeExercises
      ? selectedExercise.alternativeExercises.map((exercise) => exercise.id)
      : [],
  };

  if (selectedExercise.exercise.type === ExerciseType.CARDIO) {
    const { numberOfRepeats, duration } = mapSavedExerciseToFormModel(selectedExercise);
    return {
      ...constantsPropModel,
      duration: duration || { value: "", format: "seconds" },
      numberOfRepeats,
      tempo: Number(selectedExercise.tempo) ?? "",
      exercisesType,
      exerciseId: [
        selectedExercise.exercise.type,
        selectedExercise.exercise.bodyParts[0] ?? ExerciseBodyPart.GENERAL_DEVELOPMENT,
        selectedExercise.exercise.id,
      ],
    } satisfies ExerciseInProgramCardioFormModel;
  }

  const { weight, numberOfRepeats, duration } = mapSavedExerciseToFormModel(selectedExercise);

  return {
    ...constantsPropModel,
    numberOfRepeats: numberOfRepeats || "1",
    weight: weight || "0",
    duration: duration || undefined,
    rir: selectedExercise.rir ?? -1,
    restTime: selectedExercise.restTime ?? 60,
    tempo: String(selectedExercise.tempo) ?? "",
    exercisesType,
    exerciseId: [
      selectedExercise.exercise.type,
      selectedExercise.exercise.bodyParts[0] ?? ExerciseBodyPart.GENERAL_DEVELOPMENT,
      selectedExercise.exercise.id,
    ],
  };
};
