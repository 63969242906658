import isEmpty from "lodash.isempty";
import range from "lodash.range";

import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import {
  SurveyStatus,
  SurveyType,
  type InitialSurvey,
  type SurveyAnswer,
} from "@fitness-app/data-models/entities/ClientSurvey";
import { ActivityLevel, DietaryPreference } from "@fitness-app/data-models/entities/MealsPlan";
import { Gender } from "@fitness-app/data-models/entities/shared";
import {
  ClientExperience,
  PlaceToWorkout,
  Weekday,
  WorkoutTarget,
  type InitialSurveyQuestions,
} from "@fitness-app/data-models/entities/SurveyTemplate";

type TFunction = (label: string) => string;
export const createGenderOptions = (t: TFunction) => [
  {
    value: Gender.MALE,
    label: t("clientSurvey.male"),
  },
  {
    value: Gender.FEMALE,
    label: t("clientSurvey.female"),
  },
];

export const createExperienceOptions = (t: TFunction) => [
  {
    value: ClientExperience.NEWBIES,
    label: t("clientSurvey.experience.newbies.name"),
    desc: t("clientSurvey.experience.newbies.desc"),
  },
  {
    value: ClientExperience.MEDIUM,
    label: t("clientSurvey.experience.medium.name"),
    desc: t("clientSurvey.experience.medium.desc"),
  },
  {
    value: ClientExperience.EXPERT,
    label: t("clientSurvey.experience.expert.name"),
    desc: t("clientSurvey.experience.expert.desc"),
  },
];

export const createActivityOptions = (t: TFunction) => [
  {
    value: ActivityLevel.LOW,
    label: t("clientSurvey.activity.low.label"),
    desc: t("clientSurvey.activity.low.desc"),
  },
  {
    value: ActivityLevel.MEDIUM,
    label: t("clientSurvey.activity.medium.label"),
    desc: t("clientSurvey.activity.medium.desc"),
  },
  {
    value: ActivityLevel.VERY_ACTIVE,
    label: t("clientSurvey.activity.high.label"),
    desc: t("clientSurvey.activity.high.desc"),
  },
  {
    value: ActivityLevel.EXTREME,
    label: t("clientSurvey.activity.extreme.label"),
    desc: t("clientSurvey.activity.extreme.desc"),
  },
];

export const createPlaceToWorkoutOptions = (t: TFunction) => [
  {
    value: PlaceToWorkout.GYM,
    label: t("clientSurvey.placeToWorkout.gym.label"),
    desc: t("clientSurvey.placeToWorkout.gym.desc"),
  },
  {
    value: PlaceToWorkout.HOME_WITH_EQUIPMENT,
    label: t("clientSurvey.placeToWorkout.homeWithEquipment.label"),
    desc: t("clientSurvey.placeToWorkout.homeWithEquipment.desc"),
  },
  {
    value: PlaceToWorkout.HOME,
    label: t("clientSurvey.placeToWorkout.home.label"),
    desc: t("clientSurvey.placeToWorkout.home.desc"),
  },
];

export const createTargetsOptions = (t: TFunction) => [
  {
    value: WorkoutTarget.WEIGHT_LOSS,
    label: t("clientSurvey.targets.weightLoss.label"),
  },
  {
    value: WorkoutTarget.BETTER_FIGURE,
    label: t("clientSurvey.targets.betterFigure.label"),
  },
  {
    value: WorkoutTarget.BETTER_SHAPE,
    label: t("clientSurvey.targets.betterShape.label"),
  },
];

export const createScheduleOptions = (t: TFunction) => [
  {
    value: Weekday.MONDAY,
    label: t("common:weekdays.monday"),
  },
  {
    value: Weekday.TUESDAY,
    label: t("common:weekdays.tuesday"),
  },
  {
    value: Weekday.WEDNESDAY,
    label: t("common:weekdays.wednesday"),
  },
  {
    value: Weekday.THURSDAY,
    label: t("common:weekdays.thursday"),
  },
  {
    value: Weekday.FRIDAY,
    label: t("common:weekdays.friday"),
  },
  {
    value: Weekday.SATURDAY,
    label: t("common:weekdays.saturday"),
  },
  {
    value: Weekday.SUNDAY,
    label: t("common:weekdays.sunday"),
  },
];

export const bodyFatPercentageOptions = (t: TFunction) => ({
  male: [
    {
      numberValue: 8,
      value: "< 10",
      label: t("clientSurvey.bodyFatPercentage.lessThan10"),
    },
    {
      numberValue: 12,
      value: "10-14",
      label: "10-14%",
    },
    {
      value: "15-18",
      numberValue: 17,
      label: "15-20%",
    },
    {
      value: "21-29",
      numberValue: 24,
      label: "21-29%",
    },
    {
      value: "> 30",
      numberValue: 35,
      label: t("clientSurvey.bodyFatPercentage.moreThan30"),
    },
  ],
  female: [
    {
      value: "< 15",
      numberValue: 8,
      label: t("clientSurvey.bodyFatPercentage.lessThan15"),
    },
    {
      value: "15-18",
      numberValue: 17,
      label: "15-18%",
    },
    {
      value: "19-22",
      numberValue: 21,
      label: "19-22%",
    },
    {
      value: "24-30",
      numberValue: 27,
      label: "24-30%",
    },
    {
      value: "> 30",
      numberValue: 35,
      label: t("clientSurvey.bodyFatPercentage.moreThan30"),
    },
  ],
});

export const createBodyFatOptions = (key: "male" | "female" = "male", t: TFunction) => {
  const marks: Record<string, string> = {};
  bodyFatPercentageOptions(t)[key].forEach((option) => {
    marks[option.numberValue] = option.label;
  });
  return marks;
};

export const yearOfBirthOptions = () =>
  range(2010, 1940, -1).map((value) => ({
    value,
    label: `${new Date().getFullYear() - value}`,
  }));

export const dietaryPreferences = [
  {
    value: DietaryPreference.NoPreference,
    label: "Brak preferencji / wszystkie produkty",
  },
  {
    value: DietaryPreference.SemiVegetarian,
    label: "Dieta semiwegetariańska",
  },
  {
    value: DietaryPreference.Vegetarian,
    label: "Dieta wegetariańska",
  },
  {
    value: DietaryPreference.Vegan,
    label: "Dieta wegańska",
  },
];

export const createQuestions = (t: TFunction, gender: Gender.MALE | Gender.FEMALE) => ({
  height: {
    orderKey: 0,
    title: t("initialSurvey.height.question"),
    desc: t("initialSurvey.height.desc"),
    type: "text",
    isRequired: true,
    key: "height",
    simplifyOnboarding: true,
  },
  weight: {
    orderKey: 1,
    title: t("initialSurvey.weight.question"),
    desc: t("initialSurvey.weight.desc"),
    type: "text",
    isRequired: true,
    key: "weight",
    simplifyOnboarding: true,
  },
  birthYear: {
    orderKey: 2,
    title: t("initialSurvey.birthYear.question"),
    desc: t("initialSurvey.birthYear.desc"),
    type: "text",
    isRequired: true,
    key: "birthYear",
    simplifyOnboarding: true,
  },
  gender: {
    orderKey: 3,
    title: t("initialSurvey.gender.question"),
    desc: t("initialSurvey.gender.desc"),
    type: "select",
    isRequired: true,
    key: "gender",
    answers: [
      { title: t("initialSurvey.gender.male"), value: "male" },
      { title: t("initialSurvey.gender.female"), value: "female" },
    ],
    simplifyOnboarding: true,
  },
  targets: {
    orderKey: 4,
    title: t("initialSurvey.targets.question"),
    desc: t("initialSurvey.targets.desc"),
    type: "select",
    isRequired: true,
    key: "targets",
    answers: createTargetsOptions((label) => t(`survey:${label}`)),
    simplifyOnboarding: false,
  },
  activityLevel: {
    orderKey: 5,
    title: t("initialSurvey.activity.question"),
    desc: t("initialSurvey.activity.desc"),
    type: "select",
    isRequired: true,
    key: "activityLevel",
    answers: createActivityOptions((label: string) => t(`survey:${label}`)),
    simplifyOnboarding: false,
  },
  experience: {
    orderKey: 6,
    title: t("initialSurvey.experience.question"),
    desc: t("initialSurvey.experience.desc"),
    type: "select",
    isRequired: true,
    key: "experience",
    answers: createExperienceOptions((label) => t(`survey:${label}`)),
    simplifyOnboarding: false,
  },
  placeToWorkout: {
    orderKey: 7,
    title: t("initialSurvey.placeToWorkout.question"),
    desc: t("initialSurvey.placeToWorkout.desc"),
    type: "select",
    isRequired: true,
    key: "placeToWorkout",
    answers: createPlaceToWorkoutOptions((label) => t(`survey:${label}`)),
    simplifyOnboarding: false,
    multiSelect: true,
  },
  schedule: {
    orderKey: 8,
    title: t("initialSurvey.schedule.question"),
    desc: t("initialSurvey.schedule.desc"),
    type: "select",
    isRequired: true,
    key: "schedule",
    multiSelect: true,
    answers: createScheduleOptions((label) => t(`survey:${label}`)),
    simplifyOnboarding: false,
  },
  bodyFatPercentage: {
    orderKey: 9,
    title: t("initialSurvey.bodyFatPercentage.question"),
    desc: t("initialSurvey.bodyFatPercentage.desc"),
    type: "select",
    isRequired: true,
    key: "bodyFatPercentage",
    answers: bodyFatPercentageOptions((label) => t(`survey:${label}`))[gender],
    simplifyOnboarding: true,
  },
  dietaryPreference: {
    orderKey: 10,
    title: t("surveys:clientSurvey.dietaryPreference.label"),
    desc: t("surveys:clientSurvey.dietaryPreference.desc"),
    type: "select",
    isRequired: true,
    key: "dietaryPreference",
    answers: dietaryPreferences,
    simplifyOnboarding: true,
  },
});

const getAnswers = (answers: InitialSurveyQuestions) => {
  return {
    height: {
      type: "text",
      answer: `${answers.height} cm`,
    },
    weight: {
      type: "text",
      answer: `${answers.weight} kg`,
    },
    birthYear: {
      type: "text",
      answer: answers.birthYear,
    },
    gender: {
      type: "select",
      multiSelect: false,
      answer: [answers.gender],
    },
    targets: {
      type: "select",
      multiSelect: false,
      answer: answers.targets,
    },
    activityLevel: {
      type: "select",
      multiSelect: false,
      answer: answers.activityLevel ? [answers.activityLevel] : [],
    },
    experience: {
      type: "select",
      multiSelect: false,
      answer: answers.experience ? [answers.experience] : [],
    },
    placeToWorkout: {
      type: "select",
      multiSelect: false,
      answer: answers.placeToWorkout ? [answers.placeToWorkout] : [],
    },
    schedule: {
      type: "select",
      multiSelect: true,
      answer: answers.schedule,
    },
    bodyFatPercentage: {
      type: "select",
      multiSelect: false,
      answer: [answers.bodyFatPercentage],
    },
    dietaryPreference: {
      type: "select",
      multiSelect: false,
      answer: [answers.dietaryPreference],
    },
  };
};

export const createInitialSurveyFromAnswers = (
  answers: InitialSurveyQuestions | null,
  created: Date,
  t: TFunction,
  clientType?: AppAccessType,
): InitialSurvey[] => {
  if (!answers || isEmpty(answers)) {
    return [];
  }

  const survey: InitialSurvey = {
    title: "Początkowa ankieta klienta (podstawowe pytania)",
    name: "Początkowa ankieta klienta",
    desc: "Ankieta jest wypełnienia przez użytkownika zawsze przy tworzeniu konta w aplikacji treneiro.me",
    logs: [],
    status: SurveyStatus.Fulfilled,
    editable: false,
    type: SurveyType.Initial,
    answerDate: created,
    sentDate: created,
    surveyId: "1",
    id: "1",
    // @ts-expect-error ignore
    questions: Object.fromEntries(
      Object.entries(createQuestions(t, answers.gender)).filter(([_, value]) =>
        clientType === AppAccessType.Limited ? value.simplifyOnboarding : true,
      ),
    ),
    answers: getAnswers(answers) as unknown as Record<string, SurveyAnswer>,
    created,
    notRemovable: true,
    clientId: "",
    trainerId: "",
    clientUid: "",
  };

  return [survey];
};
