import { v4 as uuid } from "uuid";

import { type Exercise } from "@fitness-app/data-models/entities/Exercise";
import {
  ProgramWorkoutType,
  type ClientTrainingProgramDetails,
  type ProgramWorkout,
  type TrainingProgramDetails,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";

const numberLetter: Record<number, string> = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
};

export const createWorkoutTemplate = (
  options: {
    name?: string;
    description?: string;
    tags?: string[];
    id?: string;
    createdBy?: string;
  },
  workoutsLength = 0,
): WorkoutTemplate => {
  return {
    tags: options?.tags || [],
    id: options?.id || uuid(),
    name: options?.name || `Trening ${numberLetter[workoutsLength + 1]}`,
    exercises: [],
    preWorkout: [],
    postWorkout: [],
    description: options?.description || "",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    createdBy: options?.createdBy || null,
  };
};

export const createProgramWorkoutTemplate = (
  workoutsLength = 0,
  options?: {
    name?: string;
    description?: string;
    tags?: string[];
    id?: string;
  },
): ProgramWorkout => {
  return {
    id: options?.id || uuid(),
    name: options?.name || `Trening ${numberLetter[workoutsLength + 1]}`,
    exercises: [],
    preWorkout: [],
    postWorkout: [],
    description: options?.description || "",
    type: ProgramWorkoutType.Regular,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    templateId: null,
  };
};

export const createExerciseInWorkout = (exercise: Exercise) => {
  return {
    ...exercise,
    id: uuid(),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
};

export const addFromTemplate = (workoutTemplate: WorkoutTemplate | ProgramWorkout): ProgramWorkout => {
  const { tags, ...rest } = workoutTemplate;
  return {
    ...rest,
    description: rest.description || "",
    type: ProgramWorkoutType.Regular,
    templateId: workoutTemplate.id,
    id: uuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const duplicateWorkout = (workout: ProgramWorkout) => ({
  ...workout,
  exercises: workout.exercises.map((exercise) => ({ ...exercise, id: uuid() })),
  preWorkout: workout.preWorkout.map((exercise) => ({ ...exercise, id: uuid() })),
  postWorkout: workout.postWorkout.map((exercise) => ({ ...exercise, id: uuid() })),
  name: `${workout.name} Kopia`,
  id: uuid(),
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});

export const createProgramDetailsTemplate = (programId: string): TrainingProgramDetails => {
  return {
    programId,
    workouts: [createProgramWorkoutTemplate()],
    programTemplateId: null,
    archivedWorkouts: [],
    id: uuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const createClientProgramDetailsTemplate = ({
  programId,
  programTemplateId,
  traineeId,
}: {
  programId: string;
  programTemplateId: string | null;
  traineeId: string;
}): ClientTrainingProgramDetails => {
  return {
    programId,
    workouts: [createProgramWorkoutTemplate()],
    programTemplateId: programTemplateId || null,
    traineeId,
    archivedWorkouts: [],
    id: uuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const duplicateEntity = <T extends { id: string }>(entity: T): T =>
  ({
    ...entity,
    id: uuid(),
  }) as T;

export const duplicateCollection = <T extends { id: string }>(collection: T[]): T[] =>
  collection.map((entity) => duplicateEntity(entity));
