import orderBy from "lodash.orderby";
import sum from "lodash.sum";

import { type ExerciseStats } from "@fitness-app/data-models/entities/ExerciseStats";
import {
  TrainingStatus,
  type ExerciseDuration,
  type ExerciseSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

export let progressUnit = "kg";

export type ProgressChartData = {
  date: string;
  progress: number;
  weight: number | null;
  duration: number | null;
  repeats: number;
}[];

export const formatDurationProperty = (duration: ExerciseDuration["value"]) => {
  if (Array.isArray(duration)) {
    return duration[0];
  }
  return duration;
};
const getArrProperty = (exercises: Record<string, ExerciseSet>, property: "trainingLoad" | "repeats") => {
  const arrOfProperty: ([number, number] | number | null)[] = [];

  Object.values(exercises).forEach((set) => {
    if (property === "trainingLoad") {
      if (set.duration === null && set.status === TrainingStatus.FULFILLED) {
        progressUnit = "kg";
        arrOfProperty.push(set.weight);
      } else if (set.status === TrainingStatus.FULFILLED) {
        progressUnit = "s";
        arrOfProperty.push(formatDurationProperty(set.duration?.value ?? 0));
      }
    } else if (property === "repeats" && set.status === TrainingStatus.FULFILLED) {
      if (set[property] === null) {
        arrOfProperty.push(1);
      } else if (Array.isArray(set[property])) {
        arrOfProperty.push(set[property][0]);
      } else {
        arrOfProperty.push(set[property]);
      }
    }
  });

  return arrOfProperty;
};

const repeatCalc = (exercises: Record<string, ExerciseSet>) => {
  return Object.values(exercises).reduce((prev, set) => {
    if (set.status === TrainingStatus.FULFILLED) {
      if (set.repeats === null || typeof set.repeats !== "number") {
        prev += 1;
        return prev;
      }
      prev += set.repeats;
      return prev;
    } else {
      return prev;
    }
  }, 0);
};

const calcWeights = (exercises: Record<string, ExerciseSet>) => {
  return Object.values(exercises).reduce((prev, set) => {
    if (set.status === TrainingStatus.FULFILLED) {
      if (set.weight === null) {
        prev += 0;
        return prev;
      }
      prev += Array.isArray(set.weight) ? set.weight[0] : set.weight;
      return prev;
    } else {
      return prev;
    }
  }, 0);
};

export const formatProgressData = (activities: ExerciseStats[]) => {
  //  ilosc powtorzen kazdej seri * ciezar w kazdej serii = wynik
  // suma wszystkich wynikow / suma powtorzen = progress w kg
  let progressTotals: Record<
    string,
    {
      progress: number;
      weight: number | null;
      duration: number | null;
      repeats: number;
      date: string;
      trainingLoad: number[];
    }
  > = {};
  let chartData: ProgressChartData = [];
  activities.forEach((activity) => {
    if (activity.data.status === TrainingStatus.FULFILLED) {
      const exercises = activity.data.set;
      const sumOfRepetition = repeatCalc(exercises);
      const trainingLoad = getArrProperty(exercises, "trainingLoad");
      let totalWeightPerSeries: number[] = [];
      const repetitions = getArrProperty(exercises, "repeats");
      for (const [index] of repetitions.entries()) {
        const possibleRepeats = repetitions[index];
        const repeats = (Array.isArray(possibleRepeats) ? possibleRepeats[0] : possibleRepeats) || 1;
        const possibleWeight = trainingLoad[index];
        const weight = (Array.isArray(possibleWeight) ? possibleWeight[0] : possibleWeight) || 0;
        totalWeightPerSeries = [...totalWeightPerSeries, repeats * weight];
      }
      const sumOfTotalWeightPerSerie = sum(totalWeightPerSeries);
      const progress = sumOfTotalWeightPerSerie / sumOfRepetition;
      // @ts-expect-error ignore
      progressTotals = {
        ...progressTotals,
        [activity.eventDate]: {
          progress: parseFloat(progress.toFixed(1)),
          repeats: sumOfRepetition,
          trainingLoad,
          date: activity.eventDate,
          duration: null,
          weight: calcWeights(exercises),
        },
      };
    }
  });

  Object.entries(progressTotals).forEach(([_key, value]) => {
    chartData = [
      ...chartData,
      {
        ...value,
      },
    ];
  });

  return orderBy(chartData, ["date"], "asc");
};
