import React, { useCallback, useState } from "react";
import { Card, DatePicker, Space } from "antd";
import dayjs, { type Dayjs } from "dayjs";

import BodyMeasurementsChart from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/BodyMeasurementsChart";
import WeightMeasurementChart from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/WeightMeasurementChart";

const TraineeMeasurementsCharts = () => {
  const [dateRange, setDateRange] = useState<null | [Dayjs | null, Dayjs | null]>([
    dayjs().subtract(3, "month").startOf("month"),
    dayjs().endOf("day"),
  ]);

  const getDisabledDate = useCallback((current: dayjs.Dayjs) => {
    return current && current > dayjs().endOf("day");
  }, []);

  return (
    <Space style={{ width: "100%" }} direction="vertical" size={16}>
      <Card
        extra={
          <DatePicker.RangePicker
            value={dateRange}
            format="DD.MM.YYYY"
            disabledDate={getDisabledDate}
            ranges={{
              "Obecny miesiąc": [dayjs().startOf("month"), dayjs().endOf("day")],
              "3 miesiące": [dayjs().subtract(3, "month").startOf("month"), dayjs().endOf("day")],
              Rok: [dayjs().subtract(1, "year"), dayjs().endOf("day")],
            }}
            onChange={(values) => setDateRange(Array.isArray(values) && values.length === 2 ? values : null)}
          />
        }
      >
        <Space direction="vertical" size={12} style={{ width: "100%" }}>
          <WeightMeasurementChart range={dateRange} setRange={setDateRange} />
          <BodyMeasurementsChart range={dateRange} setRange={setDateRange} />
        </Space>
      </Card>
    </Space>
  );
};

export default TraineeMeasurementsCharts;
