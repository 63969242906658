import { type FunctionComponent } from "react";
import { Select } from "antd";
import i18n from "i18next";
import { Outlet } from "react-router-dom";

import AuthRedirect from "~/modules/Auth/AuthRedirect";
import bg from "./assets/auth-bg.jpg";
import logo from "./assets/logo.png";

const Auth: FunctionComponent = () => {
  return (
    <AuthRedirect>
      <div className="flex min-h-screen bg-white">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img className="absolute inset-0 h-full w-full object-cover" src={bg} alt="" />
        </div>
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="absolute right-4 top-4 lg:right-8 lg:top-8">
              <Select
                value={i18n.language}
                size="large"
                onChange={(lang) => void i18n.changeLanguage(lang)}
                popupMatchSelectWidth={false}
              >
                <Select.Option value="en" key="en">
                  <span className="flag-icon flag-icon-gb" />
                  🇺🇸 EN
                </Select.Option>
                <Select.Option value="pl" key="pl">
                  <span className="flag-icon flag-icon-pl" />
                  🇵🇱 PL
                </Select.Option>
              </Select>
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="mx-auto mb-8 h-[200px] w-[200px] items-center justify-center rounded-full bg-black lg:mb-12">
                <a href="https://programhollywood.pl/" target="_blank" rel="nofollow noreferrer">
                  <img src={logo} alt="logo" className="mx-auto block h-48 w-auto" />
                </a>
              </div>
            </div>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </AuthRedirect>
  );
};

export default Auth;
