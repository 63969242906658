import React, { useEffect, useMemo, useState } from "react";
import { BarcodeOutlined } from "@ant-design/icons";
import { Descriptions, Form, InputNumber, Radio, Select, Space, Tag, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import omit from "lodash.omit";
import round from "lodash.round";
import uniqBy from "lodash.uniqby";
import { useTranslation } from "react-i18next";

import {
  nutrientsModel,
  type IngredientWithPortion,
  type Nutrients,
} from "@fitness-app/data-models/entities/Ingredient";
import { type Meal } from "@fitness-app/data-models/entities/MealsPlan";
import { getNutrientsInPortion } from "@fitness-app/utils/src/nutrition/sumNutrients";

import { useNutritionCategories } from "~/modules/Nutrition/hooks/useNutritionCategories";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { MealDayLiveSummary } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDayLiveSummary";

interface IngredientDetailsProps {
  ingredient: IngredientWithPortion;
  formController: FormInstance<{ portion: string }>;
  updatePortion: (portion: number) => void;
  mealsForDay: Meal[];
}

const IngredientDetails = ({ ingredient, formController, updatePortion, mealsForDay }: IngredientDetailsProps) => {
  const { t } = useTranslation(["nutrition"]);
  const { categoryMap } = useNutritionCategories();
  const { measuresMap } = useNutritionMeasures();
  const [portion, setPortion] = useState<number | undefined>(ingredient.portion ?? 0);
  const portionRadioOption = Form.useWatch("portion", formController);

  const measureOptions = useMemo(
    () =>
      uniqBy(
        ingredient.measures.map((measure) => ({
          label: measure.id === 1 ? measuresMap[measure.id]?.unit ?? "grams" : measuresMap[measure.id]?.name ?? "",
          value: measure.id,
          unit: measuresMap[measure.id]?.unit ?? "grams",
          multiplier: 1,
          energyPerUnit:
            measure.id === 1 && measure.weightPerUnit === 1
              ? round(measure.energyPerUnit * 100, 1)
              : measure.energyPerUnit,
          weightPerUnit:
            measure.id === 1 && measure.weightPerUnit === 1
              ? round(measure.weightPerUnit * 100, 1)
              : measure.weightPerUnit,
        })),
        "value",
      ),
    [measuresMap, ingredient.measures],
  );

  const additionalMeasureOptions = useMemo(
    () =>
      measureOptions
        .filter((measure) => measure.value !== 1)
        .map((measure) => ({
          label: measure.label,
          value: measure.value,
          unit: measure.unit,
          multiplier: 2,
          energyPerUnit: measure.energyPerUnit * 2,
          weightPerUnit: measure.weightPerUnit * 2,
        })),
    [measureOptions],
  );

  const [customMeasureOption, setCustomMeasureOption] = useState(measureOptions[0]?.value);

  useEffect(() => {
    const defaultMeasure = measureOptions.find((measure) => measure.weightPerUnit === ingredient.portion);

    if (defaultMeasure) {
      formController.setFieldsValue({
        portion: `${defaultMeasure.value}-${defaultMeasure.multiplier}`,
      });
      return;
    }

    const defaultAdditionalMeasure = additionalMeasureOptions.find(
      (measure) => measure.weightPerUnit === ingredient.portion,
    );

    if (defaultAdditionalMeasure) {
      formController.setFieldsValue({
        portion: `${defaultAdditionalMeasure.value}-${defaultAdditionalMeasure.multiplier}`,
      });
      return;
    }

    formController.setFieldsValue({ portion: "custom" });
    setPortion(ingredient.portion);
  }, [ingredient, measureOptions, additionalMeasureOptions, formController]);

  const mergedMeasureOptions = useMemo(
    () => [...measureOptions, ...additionalMeasureOptions],
    [measureOptions, additionalMeasureOptions],
  );

  const selectedPortion = useMemo(() => {
    if (portionRadioOption === "custom") {
      const selectedOption = mergedMeasureOptions.find((option) => option.value === customMeasureOption);

      if (!selectedOption || selectedOption?.value === 1) {
        return portion ?? 100;
      } else {
        return portion ? portion * selectedOption.weightPerUnit : 0;
      }
    } else {
      const selectedOption = mergedMeasureOptions.find(
        (option) => `${option.value}-${option.multiplier}` === portionRadioOption,
      );
      return selectedOption?.weightPerUnit ?? 100;
    }
  }, [portion, customMeasureOption, portionRadioOption, mergedMeasureOptions]);

  const onFormSubmit = (_data: { portion: string }) => {
    updatePortion(selectedPortion);
  };

  const summary = useMemo(() => {
    return {
      protein: getNutrientsInPortion({ ...ingredient, portion: selectedPortion }, "protein"),
      fat: getNutrientsInPortion({ ...ingredient, portion: selectedPortion }, "fat"),
      carbs: getNutrientsInPortion({ ...ingredient, portion: selectedPortion }, "carbohydrates"),
      calories: getNutrientsInPortion({ ...ingredient, portion: selectedPortion }, "calories"),
    };
  }, [selectedPortion, ingredient]);

  return (
    <>
      <Space direction="vertical" size={24}>
        <div>
          <Typography.Title level={4}>
            {ingredient.name} {ingredient.brand ? ` - ${ingredient.brand}` : ""}
          </Typography.Title>
        </div>
        <div>
          <Typography.Title level={5}>{t("ingredientForm.portion")}</Typography.Title>
          <div>
            <Form form={formController} onFinish={onFormSubmit}>
              <Form.Item name="portion" noStyle>
                <Radio.Group>
                  <Space direction="vertical" size={12}>
                    {[...measureOptions, ...additionalMeasureOptions].map((measure) => (
                      <Radio
                        value={`${measure.value}-${measure.multiplier}`}
                        key={`${measure.value}-${measure.multiplier}`}
                      >
                        {measure.label === "gram" || measure.label === "ml" ? "" : `${measure.multiplier}x `}
                        {measure.label === "gram" || measure.label === "ml"
                          ? ""
                          : `${t(`measures.${measure.label}`).toLowerCase()} - `}{" "}
                        {measure.weightPerUnit} {measure.unit} - {measure.energyPerUnit} kcal
                      </Radio>
                    ))}
                    <Space className="w-full" onClick={() => formController.setFieldsValue({ portion: "custom" })}>
                      <Radio value="custom" />

                      <InputNumber
                        min={0}
                        step={1}
                        precision={1}
                        value={portion}
                        onChange={(value) => setPortion(value ?? 0)}
                      />
                      <Select
                        value={customMeasureOption}
                        onSelect={(value) => setCustomMeasureOption(value)}
                        options={measureOptions.map((option) => ({
                          value: option.value,
                          label: t(`measures.${option.label}`).toLowerCase(),
                        }))}
                        style={{ width: 150 }}
                      />
                    </Space>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Form>
            <div className="font-base flex justify-between pl-2 pt-4">
              <dd className="flex gap-2 text-sm text-gray-700">
                <span>B: {summary.protein} g</span>
                <span>|</span>
                <span>W: {summary.carbs} g</span>
                <span>|</span>
                <span>T: {summary.fat} g</span>
                <span>|</span>
                <span>{summary.calories} kcal</span>
              </dd>
            </div>
          </div>
        </div>

        <Descriptions column={1} title={t("ingredientForm.baseInformation")}>
          <Descriptions.Item label={t("ingredientForm.name")}>{ingredient.name}</Descriptions.Item>
          <Descriptions.Item label={t("ingredientForm.brand")}>{ingredient.brand || "-"}</Descriptions.Item>
          <Descriptions.Item label={t("ingredientForm.manufacturer")}>
            {ingredient.manufacturer || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={t("ingredientForm.category")}>
            <Tag>{t(`categories.${categoryMap[ingredient.mainCategory || "16"]?.name}`)}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label={t("ingredientForm.barCode")}>
            {ingredient.barCode ? (
              <div>
                <BarcodeOutlined /> {ingredient.barCode}
              </div>
            ) : (
              "-"
            )}
          </Descriptions.Item>
        </Descriptions>

        {ingredient.rawIngredients && (
          <div className="flex flex-col gap-2">
            <Typography.Title level={5}>{t("ingredientForm.rawIngredients")}</Typography.Title>
            <Typography.Text>{ingredient.rawIngredients}</Typography.Text>
          </div>
        )}

        <Descriptions title={`${t("ingredientForm.nutritionTable")} / 100g`} bordered column={1}>
          <Descriptions.Item label={t("nutrients.calories")}>{ingredient.nutrients.calories} kcal</Descriptions.Item>
          <Descriptions.Item label={t("nutrients.protein")}>{ingredient.nutrients.protein} g</Descriptions.Item>
          <Descriptions.Item label={t("nutrients.carbohydrates")}>
            {ingredient.nutrients.carbohydrates} g
          </Descriptions.Item>
          <Descriptions.Item label={t("nutrients.includesAddedSugars")}>
            {ingredient.nutrients.sugars} g
          </Descriptions.Item>
          <Descriptions.Item label={t("nutrients.fat")}>{ingredient.nutrients.fat} g</Descriptions.Item>
          <Descriptions.Item label={t("nutrients.includesSaturatedFats")}>
            {ingredient.nutrients.saturatedFat} g
          </Descriptions.Item>
          <Descriptions.Item label={t("nutrients.fiber")}>
            {ingredient.nutrients.fiber ? `${ingredient.nutrients.fiber} g` : "-"}
          </Descriptions.Item>

          <Descriptions.Item label={t("nutrients.salt")}>
            {ingredient.nutrients.fiber ? `${ingredient.nutrients.salt} g` : "-"}
          </Descriptions.Item>

          {Object.keys(
            omit(
              ingredient.nutrients,
              ["calories", "protein", "carbohydrates", "fat", "saturatedFat", "sugars", "salt"],
              "fiber",
            ),
          ).map((key) => (
            <Descriptions.Item label={t(`nutrients.${key}`)} key={key}>
              {typeof ingredient.nutrients[key as keyof Nutrients] === "number" ? (
                <span>
                  {ingredient.nutrients[key as keyof Nutrients]}{" "}
                  {Array.isArray(nutrientsModel[key as keyof Nutrients])
                    ? nutrientsModel[key as keyof Nutrients][0]
                    : nutrientsModel[key as keyof Nutrients]}
                </span>
              ) : (
                "-"
              )}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Space>
      <MealDayLiveSummary
        mealsForDay={mealsForDay}
        currentDish={{
          id: ingredient.id,
          ...summary,
        }}
      />
    </>
  );
};

export default IngredientDetails;
