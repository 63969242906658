import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type Measurement } from "@fitness-app/data-models/entities/Measurement";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  selectedDate: Date;
};

export const fetchMeasurementsForRange = createAsyncThunk<Measurement[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/fetchMeasurementsForRange`,
  async ({ traineeId, selectedDate }, { rejectWithValue, extra: { db } }) => {
    const monthStart = dayjs(selectedDate).subtract(1, "months").startOf("month").toISOString();
    const monthEnd = dayjs(selectedDate).add(1, "months").endOf("month").toISOString();

    const { error, data } = await db
      .from("measurement")
      .select("*")
      .order("eventTimestamp")
      .eq("traineeId", traineeId)
      .gte("eventTimestamp", monthStart)
      .lte("eventTimestamp", monthEnd)
      .returns<Measurement[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data || [];
  },
);
