import { useMemo } from "react";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/store/initializeStore";

export const useNutritionMeasures = () => {
  const { ingredientMeasures } = useAppSelector((state) => state.nutrition);
  const { t } = useTranslation("nutrition");

  const measuresMap = useMemo(() => {
    return keyBy(ingredientMeasures, "id");
  }, [ingredientMeasures]);

  const options = ingredientMeasures.map((value) => ({
    title: t(`measures.${value.name}`),
    label: t(`measures.${value.name}`),
    value: value.id,
    name: value.name || "",
  }));

  return { measureOptions: options, measuresMap: measuresMap };
};
