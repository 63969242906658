import { keyBy, sortBy } from "lodash";
import { type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { formatExerciseSet } from "../formatExerciseInProgram";

type Params = {
  exercises: ExerciseInWorkout[];
  exercisesToUpdateIds: string[];
  numberOfSeries: number;
  fromArray?: boolean;
  toArray?: boolean;
};

export const changeNumberOfSeriesInSuperSet = ({
  exercises,
  exercisesToUpdateIds,
  numberOfSeries,
  fromArray = false,
  toArray = false,
}: Params) => {
  const exercisesArr = fromArray
    ? exercises
    : Object.entries(exercises).map(([key, item]) => ({
        ...item,
        workoutKey: key,
      }));

  const selectedExercises = exercisesArr.filter((item) => exercisesToUpdateIds.includes(item.id));
  const updatedSuperSetExercises = selectedExercises.map((item) => {
    const updatedSet = formatExerciseSet({ ...item, numberOfSeries });
    return {
      ...item,
      ...updatedSet,
    };
  });
  if (toArray) {
    const restExercises = exercisesArr.filter((item) => !exercisesToUpdateIds.includes(item.id));
    return sortBy([...restExercises, ...updatedSuperSetExercises], "orderKey");
  }
  const updatedSuperSetExercisesObj = keyBy(updatedSuperSetExercises, "workoutKey");
  return {
    ...exercises,
    ...updatedSuperSetExercisesObj,
  };
};

export default changeNumberOfSeriesInSuperSet;
