import { type Database } from "@fitness-app/supabase";

import { type WithCreator } from "../utils/withCreator";
import { type ExerciseBodyPartEnum, type ExerciseTypeEnum, type ExerciseWithVideo } from "./Exercise";
import { type WorkoutActivityEvent } from "./ProgramActivity";

export type TrainingProgramEntity = Database["public"]["Tables"]["training_program"]["Row"];
export type TrainingProgramDetailsEntity = Database["public"]["Tables"]["training_program_details"]["Row"];

export type TrainingProgramLevelEnum = Database["public"]["Enums"]["TrainingProgramLevel"];
export type TrainingProgramTypeEnum = Database["public"]["Enums"]["TrainingProgramType"];

export type ClientTrainingProgramEntity = Database["public"]["Tables"]["client_training_program"]["Row"];
export type ClientTrainingProgramDetailsEntity = Database["public"]["Tables"]["client_training_program_details"]["Row"];

export const TrainingProgramLevel: Record<TrainingProgramLevelEnum, TrainingProgramLevelEnum> = {
  ADVANCED: "ADVANCED",
  BEGINNER: "BEGINNER",
  INTERMEDIATE: "INTERMEDIATE",
} as const;

export const TrainingProgramType: Record<TrainingProgramTypeEnum, TrainingProgramTypeEnum> = {
  "5X5": "5X5",
  FULL_BODY_WORKOUT: "FULL_BODY_WORKOUT",
  AB_ROUTINE: "AB_ROUTINE",
  SPLIT: "SPLIT",
  PUSH_PULL: "PUSH_PULL",
  BETTER_SHAPE: "BETTER_SHAPE",
  FITNESS: "FITNESS",
  OTHER: "OTHER",
} as const;

export enum ProgramWorkoutType {
  Regular = "regular",
}

export enum TrainingStatus {
  NEW = "NEW",
  FULFILLED = "FULFILLED",
  REJECTED = "REJECTED",
  PARTIALLY_FULFILLED = "PARTIALLY_FULFILLED",
  EMPTY = "EMPTY",
  PASSED = "PASSED",
}

export type DurationFormat = "seconds" | "minutes";

export interface ExerciseDuration {
  format: DurationFormat;
  value: number | [number, number] | null;
}

export enum WeightHint {
  DEFAULT = "DEFAULT",
  LATEST = "LATEST",
  MAX_VALUE = "MAX_VALUE",
}

export interface ExerciseSet {
  duration?: ExerciseDuration | null;
  repeats: [number, number] | number | null;
  weight: [number, number] | number | null;
  status?: TrainingStatus;
}

export type ExerciseProperty = "repeats" | "weight" | "duration";

export interface AlternativeExercise {
  id: string;
  name: string;
  thumbnailUrl?: string | null;
}

export const WORKOUT_EXERCISES_TYPE = {
  PRE_WORKOUT: "preWorkout",
  NORMAL: "exercises",
  POST_WORKOUT: "postWorkout",
} as const;

export type WorkoutExercisesType = (typeof WORKOUT_EXERCISES_TYPE)[keyof typeof WORKOUT_EXERCISES_TYPE];

export interface ExerciseInWorkout {
  createdAt: string;
  updatedAt: string;
  alternativeExercises?: AlternativeExercise[];
  comment: string;
  duration?: ExerciseDuration | null;
  id: string;
  numberOfRepeats?: [number, number] | number | null;
  numberOfSeries: number;
  restTime: number | null;
  tempo: string | number | null;
  weight?: [number, number] | number | null;
  exercise: ExerciseWithVideo;
  rir?: number;
  exerciseId?: string;
  set: Record<string, ExerciseSet>;
  status?: TrainingStatus;
  isSuperSet?: false;
  superSetId?: number | null;
  clientComment?: string | null;
  requireExerciseVideoRecording?: boolean;
}

export interface ExerciseInWorkoutWithOrderKey extends ExerciseInWorkout {
  orderKey: number;
}

export interface ProgramWorkout {
  createdAt: string;
  updatedAt: string;
  name: string;
  exercises: ExerciseInWorkout[];
  postWorkout: ExerciseInWorkout[];
  preWorkout: ExerciseInWorkout[];
  templateId?: string | null;
  type: ProgramWorkoutType.Regular;
  id: string;
  description: string;
  replicatedFromSchedule?: boolean;
  isRestDay?: boolean;
  tags?: never;
}

export interface SuperSet {
  isSuperSet: true;
  orderKey: number;
  superSetId: number;
  key: number;
  aggregateNumberOfRepeats?: number | null;
  id: string;
  numberOfSeries: number;
  numberOfRepeats?: number | [number, number];
  mergedExercisesIds: string[];
  mergedExercises: ExerciseInWorkout[];
  status: TrainingStatus;
}

export type WithMetadata = {
  title?: string;
  workoutId: string;
  exercisesType: WorkoutExercisesType;
  isRestDay?: boolean;
};

export type ArchivedWorkout = ProgramWorkout & { archivedAt: string };
export type ExerciseInWorkoutType = SuperSet | ExerciseInWorkout;
export type ExerciseTypeWithMetadata = (SuperSet & WithMetadata) | (ExerciseInWorkout & WithMetadata);

export interface TrainingProgramDetails extends Omit<TrainingProgramDetailsEntity, "archivedWorkouts" | "workouts"> {
  archivedWorkouts?: ArchivedWorkout[];
  workouts: ProgramWorkout[];
}

export interface TrainingProgram extends Omit<TrainingProgramEntity, "tags"> {
  tags: string[];
}

export type TrainingProgramWithCreator = WithCreator<TrainingProgram>;
export type WeekDay = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

export type ProgramWeek = Record<WeekDay, ProgramDay>;
export interface ProgramDay {
  workoutDay: string | null;
}

export type ProgramSchedule = [ProgramWeek] | [ProgramWeek, ProgramWeek] | ProgramWeek[];
export type ExceptionWeek = ProgramWeek;

export interface ExerciseInProgramFormModelBase {
  exerciseId: [ExerciseTypeEnum, ExerciseBodyPartEnum, string];
  exercisesType: WorkoutExercisesType;
  numberOfSeries: number;
  alternativeExercises: string[];
  comment: string;
}

export interface ExerciseInProgramCardioFormModel extends ExerciseInProgramFormModelBase {
  tempo: number;
  duration: {
    value?: string | null;
    format: DurationFormat;
  } | null;
  weight?: never;
  repeats?: never;
  numberOfRepeats?: string | null;
}

export interface ExerciseInProgramRestFormModel extends ExerciseInProgramFormModelBase {
  tempo: string;
  weight: string;
  numberOfRepeats: string;
  restTime: number;
  rir: number;
  duration?: {
    value?: string | null;
    format: DurationFormat;
  };
}

export interface ClientProgramSchedule {
  evenWeekIndex: 0 | 1;
  exceptionWeeks?: Record<string, ExceptionWeek | null>;
  oddWeekIndex: 0 | 1;
  schedule: ProgramSchedule;
  lastScheduleUpdate: string | null;
  allowChangeScheduleFromWeek: null | string;
}

export enum ClientProgramStatus {
  Scheduled = "scheduled",
  InProgress = "in_progress",
  Finished = "finished",
  Archived = "archived",
}

export interface ClientTrainingProgram
  extends Omit<ClientTrainingProgramEntity, "workoutsSchedule" | "tags" | "metadata"> {
  workoutsSchedule: ClientProgramSchedule | null;
  status: ClientProgramStatus;
  tags: string[];
  metadata: Record<string, string> | null;
}

export interface ClientArchivedProgram extends ClientTrainingProgram {
  archivedAt: string;
  archivedBy: string;
}

export interface ClientTrainingProgramDetails
  extends Omit<ClientTrainingProgramDetailsEntity, "archivedWorkouts" | "workouts"> {
  archivedWorkouts?: ArchivedWorkout[];
  workouts: ProgramWorkout[];
}

export type ExerciseInProgramFormModel = ExerciseInProgramCardioFormModel | ExerciseInProgramRestFormModel;

export interface MappedWeek {
  isoYear: number;
  isoWeek: number;
  weekNumber: number;
  schedule: Record<string, MappedSchedule | null>;
}

export interface MappedSchedule {
  date: string;
  dayName: string;
  trainingStatus: (TrainingStatus | null)[];
  workouts: (WorkoutActivityEvent | ProgramWorkout)[];
  workoutId: string;
}

export type MappedWeekSchedule = MappedWeek[];
