import { type AppThunk } from "../../../index";
import { logOutFailed, logOutStarted, logOutSuccess } from "../reducer";
import { unsubscribeFromUserProfile } from "./unsubscribeFromUserProfile";

export const logOutUser =
  (callback?: () => void): AppThunk =>
  async (dispatch, getState, { auth, analytics }) => {
    dispatch(logOutStarted());
    try {
      // await dispatch(setUserPresence(UserActivityStatus.Offline));
      dispatch(unsubscribeFromUserProfile());
      const res = await auth.signOut();
      if (res.error) {
        dispatch(logOutFailed(res.error.message));
        return;
      }

      dispatch(logOutSuccess());
      callback?.();
      analytics.reset();
    } catch (e) {
      dispatch(logOutFailed("Unknown error"));
    }
  };
