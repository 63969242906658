import { createAsyncThunk } from "@reduxjs/toolkit";

import { type MealsPlan, type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";
import { type MealsPlanRequestDto } from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

export const generateNutritionForTrainee = createAsyncThunk<
  {
    plan: MealsPlan;
    details: MealsPlanDetails;
  } | null,
  MealsPlanRequestDto,
  AsyncThunkCreator<string>
>(
  `${MEALS_PLANS_REDUCER_NAME}/generateNutritionForTrainee`,
  async (payload, { rejectWithValue, getState, extra: { analytics, nutritionApi } }) => {
    const { profile: traineeProfile } = getState().trainee;

    if (!traineeProfile) {
      return rejectWithValue("Trainee profile not found");
    }

    const response = await nutritionApi?.post<{
      plan: MealsPlan;
      details: MealsPlanDetails;
    }>("/generate-meals-plan", { ...payload, traineeId: traineeProfile.id });

    analytics.track("Nutrition Plan Generated", {
      targetCalories: payload.targetCalories,
      dietaryPreference: payload.dietaryPreference,
      mealsSchema: payload.mealsSchema,
    });

    if (!response) {
      return rejectWithValue("Error while generating nutrition");
    }

    return response.data;
  },
);
