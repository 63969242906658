import React from "react";
import { Table, Typography } from "antd";

import { type ExerciseStats } from "@fitness-app/data-models/entities/ExerciseStats";
import { type ProgressChartData } from "@fitness-app/utils/src/exercises/formatExerciseProgressData";
import { cn } from "@fitness-app/utils/src/styles/cn";

interface ProgressGraphTooltipProps {
  active?: boolean;
  label: string;
  chartData: ProgressChartData;
  payload?: { value: number }[];
  exerciseData: ExerciseStats;
}

const columns = [
  {
    title: "Seria",
    dataIndex: "series",
    key: "series",
  },
  {
    title: "Powtórzenia",
    dataIndex: "repeats",
    key: "repeats",
  },
  {
    title: "Ciężar",
    dataIndex: "weight",
    key: "weight",
  },
  {
    title: "Czas",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Objętość ciężaru",
    dataIndex: "volumeWeight",
    key: "volumeWeight",
  },
  {
    title: "Objętość (czas)",
    dataIndex: "volumeDuration",
    key: "volumeDuration",
  },
];

export const calculatePercentProgress = (date: string, chartData: ProgressChartData) => {
  const selectedDataIndex = chartData.findIndex((item) => item.date === date);
  const actualHoverProgress = chartData[selectedDataIndex]?.progress;
  const previousHoverProgress = chartData[selectedDataIndex - 1]?.progress;
  if (
    previousHoverProgress === undefined ||
    actualHoverProgress === undefined ||
    isNaN(actualHoverProgress) ||
    isNaN(previousHoverProgress) ||
    (previousHoverProgress === 0 && actualHoverProgress === 0)
  ) {
    return 0;
  }
  if (previousHoverProgress === 0 && actualHoverProgress !== 0) {
    return 100;
  }
  return ((actualHoverProgress * 100) / previousHoverProgress - 100).toFixed(2);
};

const tooltipColumnData = (workout: ExerciseStats) => {
  let unit: "seconds" | "minutes" = "seconds";
  let column: {
    rowId: number | string;
    series: number;
    repeats: number;
    weight: string;
    volumeWeight: string;
    duration: string;
    volumeDuration: string;
  }[] = [];
  let sumOfRepeat = 0;
  let sumOfWeight = 0;
  let sumOfDuration = 0;
  let sumOfWeightVolume = 0;
  let sumOfDurationVolume = 0;

  const label = {
    seconds: "sek",
    minutes: "min",
  };

  const getWorkout = workout?.data?.set ?? {};

  Object.values(getWorkout).forEach((item, index) => {
    unit = item.duration?.format || "seconds";
    const weight = Array.isArray(item.weight) ? item.weight[0] : item.weight || 0;
    const duration = item.duration
      ? Array.isArray(item.duration.value)
        ? item.duration.value[0]
        : item.duration.value
      : null;
    const repeats = Array.isArray(item.repeats) ? item.repeats[0] : item.repeats ?? 1;

    const volumeWeight: number = repeats * weight;
    const volumeWeightDisplay = volumeWeight === 0 ? "-" : `${volumeWeight} kg`;

    const volumeDuration = duration ? (repeats || 1) * duration : null;
    const volumeDurationDisplay =
      Number.isNaN(volumeDuration) || volumeDuration === null ? "-" : `${volumeDuration} ${label[unit]}`;

    sumOfRepeat += repeats;
    sumOfWeight += weight;
    sumOfDuration += duration ?? 0;
    sumOfWeightVolume += repeats * weight;
    sumOfDurationVolume += duration ? repeats * duration : 0;

    column = [
      ...column,
      {
        rowId: index,
        series: index + 1,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        repeats: Array.isArray(repeats) ? repeats[0] : repeats || 1,
        weight: weight ? `${weight} kg` : "-",
        volumeWeight: volumeWeightDisplay,
        duration: duration ? `${duration} ${label[unit]}` : "-",
        volumeDuration: volumeDurationDisplay,
      },
    ];
  });

  const columnWithSum = [
    ...column,
    {
      rowId: Object.keys(getWorkout).length + 1,
      series: "Suma",
      repeats: sumOfRepeat,
      weight: "-",
      duration: sumOfDuration ? `${sumOfDuration} ${label[unit]}` : "-",
      volumeWeight: sumOfWeight ? `${sumOfWeightVolume} kg` : "-",
      volumeDuration: sumOfDurationVolume && sumOfDurationVolume !== 0 ? `${sumOfDurationVolume} ${label[unit]}` : "-",
    },
  ];

  return columnWithSum;
};

const ProgressGraphTooltip = ({ active, label, chartData, payload, exerciseData }: ProgressGraphTooltipProps) => {
  if (!active || !payload) {
    return null;
  }

  const columnData = tooltipColumnData(exerciseData);
  return (
    <div
      className={cn(
        // common
        "rounded-tremor-default text-tremor-default border px-4 py-2",
        // light
        "bg-tremor-background shadow-tremor-dropdown border-tremor-border",
        // dark
        "dark:bg-dark-tremor-background dark:shadow-dark-tremor-dropdown dark:border-dark-tremor-border",
      )}
    >
      <div className="flex flex-col gap-y-3">
        <Typography.Title level={5}>{`${label}`}</Typography.Title>
        <div>
          <p className="text-base font-medium text-teal-700">
            Progresja: {`${payload?.[1]?.value}`} {columnData.at(-1)?.volumeDuration !== "-" ? "sek" : "kg"}
          </p>
          <p className="text-base font-medium text-green-500">
            Progresja w stosunku do poprzedniego treningu: {calculatePercentProgress(label, chartData)}%
          </p>
        </div>

        {columnData && (
          <Table
            rowKey={(record) => record.rowId}
            columns={columns}
            dataSource={columnData}
            size="small"
            pagination={false}
            bordered={false}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressGraphTooltip;
