import React, { useEffect, useRef, useState, type FunctionComponent } from "react";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { Button, Empty, Input, List, Spin, Switch, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { chatActions, RequestStatus } from "@fitness-app/app-store";
import { ChatStatus, ChatType, type ChatChannelWithLastMessage } from "@fitness-app/data-models/entities/Chat";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import ChatChannelItem from "~/modules/Chat/ChatChannelItem";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  chatStatus: ChatStatus;
  changeTab: (status: ChatStatus) => void;
  status: {
    status: RequestStatus | null;
    fetchingInitialPage: boolean;
    fetchingNextPage: boolean;
    isLastPage: boolean;
    fetchingExtraChannel: boolean;
  };
  channels: ChatChannelWithLastMessage[];
}

type Props = OwnProps;

const ChatChannelsList: FunctionComponent<Props> = ({ chatStatus, changeTab, channels, status }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["chat", "dashboard"]);
  const documentVisible = useVisibilityChange();
  const subscriptionStatus = useAppSelector((store) => store.chat.status);
  const prevSubscriptionStatus = useRef<RequestStatus | null>(null);
  const { isTrainer } = useUserRole();
  const [type, setType] = useState<"all" | "marketing">("all");

  useEffect(() => {
    if (
      documentVisible &&
      subscriptionStatus === RequestStatus.SUBSCRIBED &&
      prevSubscriptionStatus.current === RequestStatus.SUBSCRIBED
    ) {
      void dispatch(chatActions.fetchInitialChatListPage({ status: chatStatus }));
    }

    prevSubscriptionStatus.current = subscriptionStatus;
  }, [documentVisible, subscriptionStatus, dispatch, chatStatus]);

  useEffect(() => {
    void dispatch(
      chatActions.fetchInitialChatListPage({
        status: chatStatus,
        type: type === "all" ? undefined : [ChatType.OneWayMarketing, ChatType.TwoWayMarketing],
      }),
    );
  }, [chatStatus, dispatch, type]);

  const renderLoadMoreButton = () => {
    if (!status.fetchingInitialPage && !status.fetchingNextPage && !status.isLastPage) {
      return (
        <div className="my-4 h-12 text-center">
          <Button size="small" onClick={() => void dispatch(chatActions.getNextChatListPage(chatStatus))}>
            pobierz więcej
          </Button>
        </div>
      );
    }
    if (status.fetchingNextPage) {
      return (
        <div className="my-4 h-12 text-center">
          <Spin size="small" />
        </div>
      );
    }

    return null;
  };

  const onSearch = (searchValue: string) => {
    void dispatch(chatActions.fetchInitialChatListPage({ status: chatStatus, searchValue }));
  };

  return (
    <>
      {isTrainer && (
        <div className="mb-3 flex items-center gap-3 px-3">
          <Switch checked={type === "marketing"} onChange={(value) => setType(value ? "marketing" : "all")} />
          <Typography.Text>Pokaż tylko czaty ogłoszeniowe</Typography.Text>
        </div>
      )}

      <Input.Search
        placeholder="Wyszukaj przez imię, nazwisko lub email"
        onSearch={onSearch}
        allowClear
        style={{ width: "100%", padding: "0 10px 20px" }}
      />

      <List
        loadMore={renderLoadMoreButton()}
        itemLayout="horizontal"
        dataSource={channels}
        loading={status.fetchingInitialPage && subscriptionStatus !== RequestStatus.SUBSCRIBED}
        renderItem={(channel) => (
          <ChatChannelItem channel={channel} changeTab={changeTab} archived={chatStatus === ChatStatus.Archived} />
        )}
        locale={{
          emptyText: <Empty className="mt-12" description={t("dashboard:chatDrawer.emptyChannelList")} />,
        }}
        style={{ minHeight: 500 }}
        className="max-h-full overflow-y-auto px-4"
      />
    </>
  );
};

export default ChatChannelsList;
