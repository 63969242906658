import React from "react";

interface TabsContainerProps {
  children: React.ReactElement;
}

const TabsContainer = ({ children }: TabsContainerProps) => {
  return <div className="tabs-container">{children}</div>;
};

export default TabsContainer;
