import React from "react";

import { type Meal } from "@fitness-app/data-models/entities/MealsPlan";

import MealDaySummary from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDaySummary";

interface MealDayLiveSummaryProps {
  mealsForDay: Meal[];
  currentDish: {
    id: string;
    calories: number;
    carbs: number;
    fat: number;
    protein: number;
  };
}

export const MealDayLiveSummary = ({ mealsForDay, currentDish }: MealDayLiveSummaryProps): React.ReactElement => {
  return (
    <div className="absolute bottom-0 left-0 -translate-x-full bg-white shadow-md">
      <div className="bg-green-200/30">
        <div className="flex h-[30px] items-center bg-gray-200/80 text-sm text-gray-900">
          <div className="px-2 font-medium">Po edycji</div>
        </div>
        <div className="p-2">
          <MealDaySummary
            meals={mealsForDay}
            overrides={{
              [currentDish.id]: {
                ...currentDish,
              },
            }}
          />
        </div>
      </div>
      <div className="bg-red-200/30">
        <div className="flex h-[30px] items-center bg-gray-200/80 text-sm text-gray-900">
          <div className="px-2">Przed edycją</div>
        </div>
        <div className="p-2">
          <MealDaySummary meals={mealsForDay} />
        </div>
      </div>
    </div>
  );
};
