import React from "react";
import { Navigate, useParams } from "react-router-dom";

const ProductRedirect = (): React.ReactElement => {
  const { id } = useParams();

  return <Navigate to={`/products/${id}/details`} replace />;
};

export default ProductRedirect;
