import { type BusinessEventType } from "@fitness-app/data-models/businessEvents/domain/businessEventTypes";
import {
  type APIKey,
  type AuthType,
  type BasicAuth,
  type BearerToken,
  type Webhook,
} from "@fitness-app/data-models/entities/Automation";

export interface PostPayloadItem {
  type: string;
  value: string | number;
  key: string;
}
export interface GetPayloadItem {
  value: string;
  key: string;
}

export interface WebhookFormModel {
  url: string;
  description?: string;
  method: "get" | "post";
  authType: AuthType;
  auth: null | Omit<BasicAuth, "type"> | Omit<BearerToken, "type"> | Omit<APIKey, "type">;
  active: boolean;
  triggers: BusinessEventType[];
  payload?: PostPayloadItem[] | GetPayloadItem[] | undefined;
  payloadAsParams?: boolean;
}

type KeysToOmit = "payload" | "auth";

export type WebhookWithArrayPayload = Omit<Webhook, KeysToOmit> & {
  payload: PostPayloadItem[] | GetPayloadItem[] | undefined;
  authType: AuthType;
  auth: null | Omit<BasicAuth, "type"> | Omit<BearerToken, "type"> | Omit<APIKey, "type">;
};

export const API_KEY_SECRET = "***************";

export const paramFieldOptions = [
  "productId",
  "price",
  "priceId",
  "clientEmail",
  "clientFirstName",
  "clientLastName",
  "clientName",
  "paymentType",
  "productName",
  "orderId",
] as const;
