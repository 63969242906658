import React, { useCallback, useState } from "react";
import { PlayCircleOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Image } from "antd";

import { MediaContentType, type Campaign } from "@fitness-app/data-models/entities/Campaign";

import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import { useAppSelector } from "~/store/initializeStore";

export const VideoItem = ({ data }: { data: Campaign["data"] }) => {
  const [video, setVideo] = useState<string | null>(null);
  const { list } = useAppSelector((store) => store.mediaLibrary);

  const renderThumbnail = useCallback(() => {
    if (data.contentType === MediaContentType.None) {
      return null;
    }

    if (data.contentType === MediaContentType.Image) {
      return (
        <Image.PreviewGroup items={[data.message.image]}>
          <Image width={200} src={data.message.image} />
        </Image.PreviewGroup>
      );
    }

    if (data.contentType === MediaContentType.Video) {
      const item = list.find((item) => item.data.guid === data.message.guid);
      if (!item) {
        return null;
      }
      return (
        <div className="relative h-auto max-w-[120px]">
          {item.data.thumbnailUrl && <img alt="thumbnail" src={item.data.thumbnailUrl} className="h-auto w-full" />}
          <div className="group absolute inset-0 flex items-center justify-center transition-all hover:bg-gray-100/70">
            <PlayCircleOutlined
              className="invisible text-green-700 group-hover:visible"
              onClick={() => setVideo(item.data.hlsUrl || item.data.mp4Url || null)}
              style={{ fontSize: 24 }}
            />
          </div>
        </div>
      );
    }

    if (data.contentType === MediaContentType.Youtube) {
      return (
        <div className="relative h-auto max-w-[120px]">
          <YoutubeOutlined
            className="text-red-500"
            style={{ fontSize: 22 }}
            onClick={() => setVideo(data.message.videoLink)}
          />
        </div>
      );
    }

    return null;
  }, [data]);

  return (
    <>
      {renderThumbnail()}
      <VideoPlayer hideControl videoUrl={video} visible={!!video} onClose={() => setVideo(null)} />
    </>
  );
};
