import React from "react";
import { Checkbox, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const QuestionBase = () => {
  const { t } = useTranslation("surveys");
  return (
    <>
      <Form.Item
        name={["question", "title"]}
        label={t("questionForm.title")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item name={["question", "desc"]} label={t("questionForm.desc")}>
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item valuePropName="checked" name={["question", "isRequired"]} label=" " colon={false}>
        <Checkbox>{t("questionForm.answerIsRequired")}</Checkbox>
      </Form.Item>
    </>
  );
};

export default QuestionBase;
