import React, { useEffect, useMemo } from "react";
import { Avatar, Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { teamActions, teamSelectors } from "@fitness-app/app-store";
import { RequestStatus, TeamMemberStatus } from "@fitness-app/data-models";
import { getUserInitials } from "@fitness-app/utils";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useTagOptions } from "~/hooks/useTagOptions";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { accessTypeOptions, type TraineeProfileFormModel } from "./types";

interface TraineeProfileFormProps {
  formController?: FormInstance<TraineeProfileFormModel>;
  onSubmit: (formData: TraineeProfileFormModel) => void;
  model?: Partial<TraineeProfileFormModel> | null;
}

const TraineeProfileForm = ({ model, onSubmit, formController }: TraineeProfileFormProps) => {
  const { t } = useTranslation(["trainees", "common"]);
  const trainers = useAppSelector(teamSelectors.getTrainersTeam);
  const status = useAppSelector((store) => store.team.listStatus);
  const { userId, isTrainer } = useUserRole();
  const { isSharedTrainer, isOwner } = useUserClaims();
  const { options: tagsOptions } = useTagOptions("traineeTags");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status !== RequestStatus.SUBSCRIBED) {
      dispatch(teamActions.subscribeToTeam());
    }
  }, [isTrainer, status]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const trainerOptions = useMemo(() => {
    if (!trainers?.length) {
      return [];
    }
    return trainers
      .map((trainer) => {
        const displayName = `${trainer.firstName} ${trainer.lastName}`;
        const label = trainer.userId === userId ? `${displayName} ${t("trainerOptions.yourAccount")}` : displayName;
        return {
          label,
          value: trainer.id,
          disabled: !trainer.selectable || trainer.status !== TeamMemberStatus.ACTIVE,
          avatarUrl: trainer.user?.avatarUrl || null,
        };
      })
      .filter((trainer) => !trainer.disabled);
  }, [trainers, userId, t]);

  const fieldDisabled = Boolean(model);

  return (
    <Form<TraineeProfileFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Form.Item
        name="firstName"
        label={t<string>("profileForm.firstName")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t<string>("profileForm.lastName")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            type: "string",
            min: 9,
            message: t<string>("common:validationErrors.invalidDataFormat"),
          },
        ]}
        name="phoneNumber"
        label={t<string>("profileForm.phoneNumber")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label={t<string>("profileForm.email")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            type: "email",
            message: t<string>("common:validationErrors.wrongEmail"),
          },
        ]}
      >
        <Input disabled={fieldDisabled} />
      </Form.Item>

      <Form.Item
        name="type"
        label={t<string>("profileForm.accessType")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          placeholder={t<string>("common:selectPlaceholder")}
          options={accessTypeOptions}
          disabled={!(isOwner || isSharedTrainer)}
          popupMatchSelectWidth={false}
        />
      </Form.Item>

      <Form.Item
        name="assignedTrainerId"
        label={t<string>("profileForm.assignedTrainer")}
        validateStatus={status === RequestStatus.SUBSCRIBING ? "validating" : undefined}
      >
        <Select
          placeholder={t<string>("common:selectPlaceholder")}
          showSearch
          optionFilterProp="label"
          optionLabelProp="label"
          allowClear
          disabled={!(isOwner || isSharedTrainer)}
        >
          {trainerOptions.map((trainer) => (
            <Select.Option
              key={trainer.value}
              value={trainer.value}
              title={trainer.label}
              label={trainer.label}
              disabled={trainer.disabled}
            >
              <Avatar src={trainer.avatarUrl}>{getUserInitials(trainer.label)}</Avatar> {trainer.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="tags" label={t<string>("profileForm.tags")}>
        <Select
          notFoundContent={false}
          options={tagsOptions}
          mode="tags"
          placeholder={t<string>("products:form.clientTagsPlaceholder")}
        />
      </Form.Item>
    </Form>
  );
};

export default TraineeProfileForm;
