import { createAsyncThunk } from "@reduxjs/toolkit";

import { type IngredientMeasure } from "@fitness-app/data-models/entities/Ingredient";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchIngredientMeasures = createAsyncThunk<IngredientMeasure[], Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/fetchIngredientMeasures`,
  async (_payload, { rejectWithValue, getState, extra: { db, errorTrackingService } }) => {
    const measures = getState().nutrition.ingredientMeasures;

    if (measures.length) {
      return measures;
    }

    const { error, data } = await db.from("ingredient_measurement").select("*").order("id");

    if (error) {
      errorTrackingService?.recordError(error, "fetchIngredientMeasures");
      return rejectWithValue(error.message);
    }

    return data;
  },
);
