import React from "react";
import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";

import QuestionBase from "~/modules/SurveysTemplates/SurveyForm/questions/QuestionBase";

const ABQuestion = () => {
  const { t } = useTranslation("surveys");
  return (
    <div>
      <QuestionBase />
      <Form.Item valuePropName="checked" name={["question", "extendedAnswerA"]} label=" " colon={false}>
        <Checkbox>{t("questionForm.extendedAnswerA")}</Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name={["question", "extendedAnswerB"]} label=" " colon={false}>
        <Checkbox>{t("questionForm.extendedAnswerB")}</Checkbox>
      </Form.Item>
    </div>
  );
};

export default ABQuestion;
