import React from "react";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

import { PaymentTypeOption, type OrderPaymentType } from "@fitness-app/data-models/entities/Product";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { paymentTypeColorMapper } from "~/modules/Products/constants/paymentTypeColorMapper";
import { tagSubscriptionColorMapper } from "~/modules/Products/constants/tagSubscriptionColorMapper";

interface PaymentTypeTagProps {
  productClient: ProductClient;
}

export const PaymentTypeTag = ({ productClient }: PaymentTypeTagProps): React.ReactElement => {
  const { t } = useTranslation("products");

  let type: OrderPaymentType | "installments" = productClient.paymentType;

  if (productClient.accessPeriod?.type === "recurring" && productClient.accessPeriod.installments) {
    type = "installments";
  }

  return (
    <Tag
      color={
        productClient.paymentType === PaymentTypeOption.Recurring && !productClient.accessPeriod.installments
          ? productClient.accessPeriod.cancelAtPeriodEnd
            ? tagSubscriptionColorMapper.cancelAtEnd
            : tagSubscriptionColorMapper[productClient.accessPeriod.status]
          : paymentTypeColorMapper[type]
      }
    >
      {t(`products:type.${type}`)}
    </Tag>
  );
};
