import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import round from "lodash.round";

import { OrderStatusOption, type Order, type SessionMetadata } from "@fitness-app/data-models/entities/Order";

import { type DateRange } from "~/modules/Dashboard/AppStats/hooks/types";
import { ALL_PRODUCTS_ITEM_ID } from "~/modules/Dashboard/AppStats/hooks/useProductsList";
import { supabase } from "~/store/initializeStore";

export const useSessionData = (products: string[], range: DateRange) => {
  const { data, isLoading } = useQuery(["useSessionData", products, range], {
    enabled: !!products.length && !!range,
    keepPreviousData: true,
    queryFn: async () => {
      if (!products.length) {
        return;
      }

      const monthStart = range ? range[0]?.toISOString() : null;
      const monthEnd = range ? range[1]?.toISOString() : null;

      if (!monthStart || !monthEnd) {
        return;
      }

      if (products.length === 1 && products[0] === ALL_PRODUCTS_ITEM_ID) {
        const { error, data } = await supabase
          .from("session_metadata")
          .select("id, product, referer, visitorId, createdAt, country, order(id, status)")
          .gte("createdAt", monthStart)
          .lte("createdAt", monthEnd)
          .order("createdAt", { ascending: false })
          .returns<(SessionMetadata & { order: null | { status: Order["status"]; id: string } })[]>()
          .throwOnError();

        if (error) {
          throw new Error(error.message);
        }

        return data;
      }
      const { error, data } = await supabase
        .from("session_metadata")
        .select("id, product, referer, visitorId, createdAt, country, order(id, status)")
        .in("product", products)
        .gte("createdAt", monthStart)
        .lte("createdAt", monthEnd)
        .order("createdAt", { ascending: false })
        .returns<(SessionMetadata & { order: null | { status: Order["status"]; id: string } })[]>()
        .throwOnError();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  const { data: previousSession } = useQuery(["usePreviousSession", products, range], {
    enabled: !!products.length && !!range,
    keepPreviousData: true,
    queryFn: async () => {
      if (!products.length) {
        return;
      }

      const monthStart = range ? range[0]?.startOf("day") : null;
      const monthEnd = range ? range[1]?.endOf("day") : null;

      if (!monthStart || !monthEnd) {
        return;
      }

      const diff = monthEnd.diff(monthStart, "days", false);
      const previousMonthStart = monthStart
        .subtract(diff + 1, "days")
        .startOf("day")
        .toISOString();
      const previousMonthEnd = monthEnd
        .subtract(diff + 1, "days")
        .endOf("day")
        .toISOString();

      if (products.length === 1 && products[0] === ALL_PRODUCTS_ITEM_ID) {
        const { error, data } = await supabase
          .from("session_metadata")
          .select("id, product, referer, visitorId, createdAt, country, order(id, status)")
          .gte("createdAt", previousMonthStart)
          .lte("createdAt", previousMonthEnd)
          .order("createdAt", { ascending: false })
          .returns<(SessionMetadata & { order: null | { status: Order["status"]; id: string } })[]>()
          .throwOnError();

        if (error) {
          throw new Error(error.message);
        }

        return data;
      }
      const { error, data } = await supabase
        .from("session_metadata")
        .select("id, product, referer, visitorId, createdAt, country, order(id, status)")
        .in("product", products)
        .gte("createdAt", previousMonthStart)
        .lte("createdAt", previousMonthEnd)
        .order("createdAt", { ascending: false })
        .returns<(SessionMetadata & { order: null | { status: Order["status"]; id: string } })[]>()
        .throwOnError();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  const purchased = useMemo(
    () => data?.filter((session) => session.order?.status === OrderStatusOption.Paid) || [],
    [data],
  );

  const previous = useMemo(
    () => previousSession?.filter((session) => session.order?.status === OrderStatusOption.Paid) || [],
    [previousSession],
  );

  return {
    isLoading,
    sessions: data,
    purchased: purchased,
    sessionChange: data && previousSession?.length ? round((data.length / previousSession?.length) * 100 - 100) : null,
    conversion: data?.length ? round((purchased.length / data?.length) * 100) : 0,
    conversionPrevious: previousSession?.length ? round((previous.length / previousSession?.length) * 100) : 0,
  };
};
