import React, { useEffect } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { useTagOptions } from "~/hooks/useTagOptions";
import { DEFAULT_LIST_OF_SUPPLEMENTS } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/NutritionSupplementForm/listOfSupplements";
import { type SupplementTemplateFormModel } from "./types";

interface SupplementsTemplateFormFormProps {
  formController?: FormInstance<SupplementTemplateFormModel>;
  onSubmit: (formData: SupplementTemplateFormModel) => void;
  model?: Partial<SupplementTemplateFormModel> | null;
}

const SupplementsTemplateForm = ({ model, onSubmit, formController }: SupplementsTemplateFormFormProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const { filters: tags } = useTagOptions("mealsPlanTags");

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <Form<SupplementTemplateFormModel>
      name="form"
      layout="vertical"
      form={formController}
      initialValues={{
        name: "",
        tags: [],
        supplements: [
          {
            name: "",
            dose: "",
            comment: "",
            url: "",
            id: generateUniqId(),
          },
        ],
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="name"
        label={t("supplementsTemplatesForm.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
            whitespace: true,
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="description"
        label={t("supplementsTemplatesForm.description")}
        rules={[
          {
            required: false,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item name="tags" label={t("supplementsTemplatesForm.tags")}>
        <Select notFoundContent={false} options={tags} mode="multiple" />
      </Form.Item>

      <Form.Item label={t("supplementsTemplatesForm.supplements")}>
        <Form.List name="supplements" initialValue={[""]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key} className="my-2 flex flex-col flex-wrap rounded-md bg-gray-200 bg-opacity-30 p-4">
                  <Form.Item
                    name={[field.key, "name"]}
                    label="Nazwa"
                    rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
                  >
                    <AutoComplete autoFocus options={DEFAULT_LIST_OF_SUPPLEMENTS} />
                  </Form.Item>

                  <Form.Item hidden name={[field.key, "id"]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={[field.key, "dose"]}
                    label="Dawkowanie"
                    rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>

                  <Form.Item name={[field.key, "comment"]} label="Komentarz">
                    <Input.TextArea rows={3} />
                  </Form.Item>

                  <Form.Item
                    name={[field.key, "url"]}
                    label="Link do sklepu"
                    rules={[{ type: "url", message: t<string>("common:validationErrors.wrongUrl") }]}
                  >
                    <Input />
                  </Form.Item>
                  {fields.length > 1 && (
                    <MinusCircleOutlined
                      className="self-end text-red-600"
                      onClick={() => remove(field.name)}
                      style={{ marginLeft: 12, fontSize: 18 }}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      name: "",
                      dose: "",
                      comment: "",
                      url: "",
                      id: generateUniqId(),
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                  style={{ marginTop: 10 }}
                >
                  {t("common:button.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
};

export default SupplementsTemplateForm;
