import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { AuthorTypeEnum } from "@fitness-app/data-models/entities/AuthorTypeEnum";
import { MeasurementType, type ClientPhoto } from "@fitness-app/data-models/entities/Measurement";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  data: ClientPhoto["data"];
  eventDate: string;
  traineeId: string;
  id?: null | string;
  userId?: string | null;
};

export const addBodyPhotos = createAsyncThunk<ClientPhoto, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/addBodyPhotos`,
  async (payload, { rejectWithValue, extra: { db, auth, analytics } }) => {
    const user = await getLoggedUser(auth);

    if (payload.id) {
      const { data, error } = await db
        .from("measurement")
        .update({
          data: payload.data,
          updatedAt: new Date().toISOString(),
        })
        .eq("id", payload.id)
        .select("*")
        .single<ClientPhoto>();

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    }

    const measurement = {
      data: payload.data,
      type: MeasurementType.ClientPhoto,
      eventDate: payload.eventDate,
      eventTimestamp: dayjs(payload.eventDate, "YYYY-MM-DD").hour(12).toISOString(),
      traineeId: payload.traineeId,
      creator: user.id === payload.userId ? AuthorTypeEnum.Client : AuthorTypeEnum.Trainer,
      id: generateUniqId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      userId: payload.userId || null,
    };

    const { error, data } = await db.from("measurement").insert(measurement).select("*").single<ClientPhoto>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_body_photos", { count: payload.data.content.length, hasComment: !!payload.data.comment });

    return data;
  },
);
