import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  searchTerm: string;
};

export const searchIngredient = createAsyncThunk<
  { data: Ingredient[]; page: number; totalPages: number },
  Payload,
  AsyncThunkCreator<string>
>(
  `${NUTRITION_REDUCER_NAME}/searchIngredient`,
  async ({ searchTerm }, { rejectWithValue, getState, extra: { ingredientsSearchIndex } }) => {
    try {
      const { ingredientsPerPage, filteredCategories } = getState().nutrition;

      if (filteredCategories.length) {
        const response = await ingredientsSearchIndex.search(searchTerm, {
          limit: ingredientsPerPage,
          sort: ["rating:desc", "source:desc"],
          attributesToCrop: ["kcalmar"],
          filter: [filteredCategories.map((category) => `mainCategory = ${category}`)],
        });

        return { data: response.hits, page: 1, totalPages: 1 };
      } else {
        const response = await ingredientsSearchIndex.search(searchTerm, {
          limit: ingredientsPerPage,
          sort: ["rating:desc", "source:desc"],
          attributesToCrop: ["kcalmar"],
        });

        return { data: response.hits, page: 1, totalPages: 1 };
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
