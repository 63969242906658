import React, { useCallback, useEffect, useRef } from "react";
import { Alert, Skeleton } from "antd";

import { RequestStatus, traineeMealsPlanActions, traineeMealsPlanSelectors } from "@fitness-app/app-store";
import { type ClientMealsPlanDetails, type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal, type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";

import MealsPlanProvider from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface MealsPlanTemplateProviderProps {
  children: React.ReactElement;
  id: string;
  traineeId: string;
}

const TraineeMealsPlanProvider = ({ children, id }: MealsPlanTemplateProviderProps) => {
  const dispatch = useAppDispatch();
  const { selectedMealsPlanDetails, selectedMealsPlan, selectedMealsPlanStatus } = useAppSelector(
    (store) => store.traineeMealsPlan,
  );
  const mealsMap = useAppSelector(traineeMealsPlanSelectors.getMealsMap);
  const previousPlanId = useRef(id);

  useEffect(() => {
    if (id) {
      void dispatch(traineeMealsPlanActions.fetchMealsPlanWithDetails({ id }));
    }
    previousPlanId.current = id;
  }, [id, dispatch]);

  const [planDetails] = selectedMealsPlanDetails || [];

  const updateMealsPlan = useCallback(
    async ({
      mealsPlan,
      mealsPlanDetails,
    }: {
      mealsPlan: Partial<ClientNutrition> & { id: string };
      mealsPlanDetails: (Partial<ClientMealsPlanDetails> & { id: string }) | null;
    }) => {
      await dispatch(
        traineeMealsPlanActions.updateMealsPlan({
          mealsPlan,
          mealsPlanDetails,
        }),
      ).unwrap();
    },
    [dispatch],
  );

  const planDetailsId = planDetails?.id;

  const updateMealsPlanDays = useCallback(
    async ({ days }: { days: MealsPlanDetails["weeks"][number]["days"] }) => {
      if (planDetailsId) {
        await dispatch(
          traineeMealsPlanActions.updatedMealsPlanDays({
            days,
            id: planDetailsId,
          }),
        );
      }
    },
    [dispatch, planDetailsId],
  );

  const updateProductInMealsPlan = useCallback(
    async ({
      dayId,
      mealId,
      product,
    }: {
      dayId: string;
      mealId: string;
      product: DishInMeal | IngredientWithPortion;
    }) => {
      await dispatch(
        traineeMealsPlanActions.updateProductInMealsPlan({
          dayId,
          mealId,
          product,
        }),
      );
    },
    [dispatch],
  );

  const deleteProductFromMealsPlan = useCallback(
    async ({ dayId, mealId, productId }: { dayId: string; mealId: string; productId: string }) => {
      await dispatch(
        traineeMealsPlanActions.deleteProductFromMealsPlan({
          dayId,
          mealId,
          productId,
        }),
      );
    },
    [dispatch],
  );

  if (
    !selectedMealsPlanStatus ||
    (!planDetails && selectedMealsPlanStatus === RequestStatus.FETCHING) ||
    id !== previousPlanId.current
  ) {
    return <Skeleton className="p-5" paragraph={{ rows: 5 }} />;
  }

  if (!id || !planDetails || !selectedMealsPlan) {
    return <Alert type="error" />;
  }

  return (
    <MealsPlanProvider
      mealsMap={mealsMap}
      planId={id}
      planDetails={planDetails}
      plan={selectedMealsPlan}
      updateMealsPlan={updateMealsPlan}
      updateMealsPlanDays={updateMealsPlanDays}
      updateProductInMealsPlan={updateProductInMealsPlan}
      deleteProductFromMealsPlan={deleteProductFromMealsPlan}
    >
      {children}
    </MealsPlanProvider>
  );
};

export default TraineeMealsPlanProvider;
