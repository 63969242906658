import React, { type FunctionComponent } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import dayjs from "dayjs";
import { Camera, Dumbbell, GlassWater, Salad, Scale, TrendingUp } from "lucide-react";
import { useTranslation } from "react-i18next";

import { MeasurementType, type Measurement } from "@fitness-app/data-models/entities/Measurement";

import IconText from "~/components/IconText/IconText";
import MeasurementContent from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/MeasurementContent";

interface MeasurementCalendarEventProps {
  measurement: Measurement;
}

const icon = {
  [MeasurementType.BodyMeasurements]: <TrendingUp size={16} className="align-text-top" color="rgb(38, 153, 251)" />,
  [MeasurementType.WeightMeasurement]: <Scale size={16} className="align-text-top" color="rgb(38, 153, 251)" />,
  [MeasurementType.ClientPhoto]: <Camera size={16} className="align-text-top" color="rgb(38, 153, 251)" />,
  [MeasurementType.HydrationMeasurement]: <GlassWater size={16} className="align-text-top" color="rgb(38, 153, 251)" />,
  [MeasurementType.FeedbackAboutNutrition]: <Salad size={16} className="align-text-top" color="rgb(38, 153, 251)" />,
  [MeasurementType.FeedbackAboutTraining]: <Dumbbell size={16} className="align-text-top" color="rgb(38, 153, 251)" />,
};

const MeasurementCalendarEvent = ({ measurement }: MeasurementCalendarEventProps) => {
  const { t } = useTranslation(["trainees", "common"]);
  return (
    <Popover
      placement="left"
      content={<MeasurementContent measurement={measurement} />}
      title={
        <div className="flex items-center justify-between gap-x-8">
          <p>Powadomienie od klienta</p>
          <IconText
            icon={ClockCircleOutlined as FunctionComponent<unknown>}
            text={dayjs(measurement.eventTimestamp).format("DD.MM.YYYY HH:mm")}
          />
        </div>
      }
    >
      <div className="flex items-center gap-x-2">
        <span>{icon[measurement.type]}</span>
        <span>{t(`measurementType.${measurement.type}`)}</span>
      </div>
    </Popover>
  );
};

export default MeasurementCalendarEvent;
