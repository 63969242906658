import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import { type MealsPlanDetails, type MealsPlanWithCreator } from "@fitness-app/data-models/entities/MealsPlan";
import { generateMealsPlanDetails } from "@fitness-app/utils/src/nutrition/generateMealsPlanDetails";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";
import { fetchMealsPlan } from "./fetchMealsPlan";

type Payload = MealsPlanWithCreator;

export const duplicateMealsPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/duplicateMealsPlan`,
  async (plan, { rejectWithValue, dispatch, getState, extra: { db, analytics } }) => {
    const { data } = getState().user;
    const { creator, ...rest } = plan;
    const duplicatedProgram = {
      ...rest,
      createdBy: data?.id || null,
      shared: rest.shared ?? true,
      id: uuid(),
      name: `${plan.name} Kopia`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const { error } = await db.from("meals_plan").insert(duplicatedProgram);

    if (error) {
      return rejectWithValue(error.message);
    }

    const { data: mealPlanDetails } = await db
      .from("meals_plan_details")
      .select()
      .eq("planId", plan.id)
      .single<MealsPlanDetails>();

    const { error: erroOnSavingDetails } = await db
      .from("meals_plan_details")
      .insert(generateMealsPlanDetails(duplicatedProgram, mealPlanDetails));

    if (erroOnSavingDetails) {
      return rejectWithValue(erroOnSavingDetails.message);
    }

    analytics.track("duplicate_meals_plan");

    void dispatch(fetchMealsPlan());
  },
);
