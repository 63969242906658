import { type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

type Params = {
  exercises: ExerciseInWorkout[];
  exercisesToSplit: string[];
  fromArray?: boolean;
  toArray?: boolean;
  workoutKey?: string;
};

export const splitSuperSet = ({ exercises, exercisesToSplit }: Params) => {
  let updated: ExerciseInWorkout[] | [string, ExerciseInWorkout][] = [];
  updated = exercises.map((exercise) => ({
    ...exercise,
    superSetId: exercisesToSplit.includes(exercise.id) ? null : exercise.superSetId || null,
  }));
  return updated;
};

export default splitSuperSet;
