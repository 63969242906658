import { useEffect, useMemo } from "react";
import groupBy from "lodash.groupby";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { nutritionActions } from "@fitness-app/app-store";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const useNutritionCategories = () => {
  const { ingredientCategories } = useAppSelector((state) => state.nutrition);
  const { t } = useTranslation("nutrition");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ingredientCategories.length === 0) {
      void dispatch(nutritionActions.fetchIngredientCategories());
    }
  }, []);

  const categoryMap = useMemo(() => {
    return keyBy(ingredientCategories, "id");
  }, [ingredientCategories]);

  const subCategories = groupBy(ingredientCategories, "parentId");
  const parentCategories = ingredientCategories
    .filter((category) => category.isParent)
    .map((value) => ({
      title: t(`categories.${value.name}`),
      text: t(`categories.${value.name}`),
      value: value.id,
      key: value.id,
      children: subCategories[value.id]?.map((subCategory) => ({
        title: t(`categories.${subCategory.name}`),
        text: t(`categories.${subCategory.name}`),
        value: subCategory.id,
        key: subCategory.id,
      })),
    }));

  return { categories: parentCategories, categoryMap };
};
