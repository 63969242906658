import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = { name?: string; from: string; to: string; traineeId: string };

export const generateShoppingList = createAsyncThunk<string | null, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/generateShoppingList`,
  async (payload, { extra: { nutritionApi, analytics } }) => {
    if (!nutritionApi) {
      throw new Error("nutritionApi-is-not-defined");
    }

    const response = await nutritionApi.post<{ id: string | null }>("/generate-shopping-list", payload);

    analytics.track("generate_shopping_list", { days: Math.abs(dayjs(payload.to).diff(dayjs(payload.from), "days")) });

    return response.data.id;
  },
);
