import React, { useEffect, useMemo, type FunctionComponent } from "react";
import { CloseOutlined, MailOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Form, Input, Select, Space, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs, { type Dayjs } from "dayjs";
import sortBy from "lodash.sortby";
import { useTranslation } from "react-i18next";

import { type ClientInvoiceData, type Order } from "@fitness-app/data-models/entities/Order";
import { COUNTRIES } from "@fitness-app/utils/src/constants/countries";
import { getFlagEmoji } from "@fitness-app/utils/src/products/getFlagEmoji";

export interface ExtendedModel extends ClientInvoiceData {
  sendInvoiceToClient: boolean;
  updateClientData: boolean;
  sellDate: Dayjs;
  issueDate: Dayjs;
}

interface OwnProps {
  formController?: FormInstance<ClientInvoiceData | ExtendedModel>;
  onSubmit: (formData: ClientInvoiceData | ExtendedModel) => void;
  model?: Partial<ClientInvoiceData | ExtendedModel> | null;
  withInvoiceMetadata?: boolean;
  order?: Order;
  children?: React.ReactNode;
}

type Props = OwnProps;

const InvoiceFormData: FunctionComponent<Props> = ({
  formController,
  onSubmit,
  model,
  withInvoiceMetadata,
  order,
  children,
}) => {
  const { t } = useTranslation(["common", "checkout"]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
    if (order && formController) {
      const sellDate = order.sellDate ? dayjs.unix(order.sellDate) : dayjs(order.createdAt);
      const issueDate = order.issueDate ? dayjs.unix(order.issueDate) : dayjs();

      formController.setFieldsValue({
        sendInvoiceToClient: true,
        updateClientData: true,
        sellDate,
        issueDate,
      });
    }
  }, [model, order]);

  const countriesSorted = useMemo(
    () =>
      sortBy(
        COUNTRIES.map((country) => ({
          ...country,
          label: t(`countries.${country.name}`),
        })),
        "label",
      ),
    [t],
  );

  return (
    <Form<ClientInvoiceData>
      name="invoice-form"
      layout="horizontal"
      labelCol={{ xxl: { span: 8, offset: 0 }, span: 24, offset: 0 }}
      wrapperCol={{ xxl: { span: 24, offset: 0 }, span: 24, offset: 0 }}
      onFinish={onSubmit}
      validateTrigger="onSubmit"
      form={formController}
      initialValues={{
        firstName: "",
        lastName: "",
        companyName: "",
        nip: "",
        street: "",
        postalCode: "",
        city: "",
        country: "PL",
        ...model,
      }}
    >
      {children}
      {withInvoiceMetadata && (
        <>
          <Form.Item
            name="sellDate"
            label={t<string>("checkout:sellDate")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="issueDate"
            label={t<string>("checkout:issueDate")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="sendInvoiceToClient"
            valuePropName="checked"
            // labelCol={{ xxl: { span: 16, offset: 4 }, span: 24, offset: 0 }}
            label={t<string>("checkout:sendInvoiceToClient")}
          >
            <Switch checkedChildren={<MailOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>

          <Form.Item name="updateClientData" valuePropName="checked">
            <Checkbox>{t("checkout:updateClientData")}</Checkbox>
          </Form.Item>
        </>
      )}
      <>
        <Form.Item
          name="firstName"
          label={t<string>("checkout:firstName")}
          rules={[
            {
              required: true,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("checkout:firstNamePlaceholder")} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={t<string>("checkout:lastName")}
          rules={[
            {
              required: true,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("checkout:lastNamePlaceholder")} />
        </Form.Item>
      </>

      <>
        <Form.Item name="companyName" label={t<string>("checkout:companyName")}>
          <Input />
        </Form.Item>

        <Form.Item name="nip" label={t<string>("checkout:nip.name")} hasFeedback>
          <Input placeholder={t<string>("checkout:nipPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="street"
          label={t<string>("checkout:street")}
          rules={[
            {
              required: false,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("checkout:streetPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="postalCode"
          label={t<string>("checkout:postalCode")}
          rules={[
            {
              required: false,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="city"
          label={t<string>("checkout:city")}
          rules={[
            {
              required: false,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="country"
          label={t<string>("checkout:country")}
          rules={[
            {
              required: false,
              message: t<string>("validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            optionLabelProp="label"
            showSearch
            filterOption={(input, option) => {
              return (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase());
            }}
          >
            {countriesSorted.map((country) => (
              <Select.Option key={country.isoCode} value={country.isoCode} label={country.label}>
                <Space>
                  <span role="img" aria-label={country.label}>
                    {getFlagEmoji(country.isoCode)}
                  </span>
                  {country.label}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </>
    </Form>
  );
};

export default InvoiceFormData;
