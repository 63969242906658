import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  NotificationStatus,
  type ClientNotifications,
  type NotificationType,
  type OwnerNotifications,
  type TrainerNotifications,
} from "@fitness-app/data-models/entities/Notification";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { setNumberOfUnreadNotifications } from "../reducer";
import { NOTIFICATIONS_REDUCER_NAME, type NotificationsReducer } from "../types";

type Payload = {
  page?: number;
  filter: NotificationsReducer["filter"];
  type?: NotificationType[];
};

export const fetchNotifications = createAsyncThunk<
  {
    data: (ClientNotifications | OwnerNotifications | TrainerNotifications)[];
    currentPage: number;
    totalPages: number;
  },
  Payload,
  AsyncThunkCreator<string>
>(
  `${NOTIFICATIONS_REDUCER_NAME}/fetchNotifications`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { pageSize } = getState().notifications;

    const { start, stop, currentPage } = getPageRange(payload?.page, pageSize);

    if (currentPage === 1) {
      const { count } = await db
        .from("notification")
        .select("*", { count: "exact", head: true })
        .eq("ownerId", currentUser.id)
        .eq("status", NotificationStatus.UNREAD)
        .limit(120);

      dispatch(setNumberOfUnreadNotifications(count ?? 0));
    }

    let query = db
      .from("notification")
      .select("*", { count: "exact" })
      .order("createdAt", { ascending: false })
      .eq("ownerId", currentUser.id);

    if (payload.filter === "unread") {
      query = query.eq("status", NotificationStatus.UNREAD);
    }

    if (payload.type?.length) {
      query = query.in("type", payload.type);
    }

    const { error, data, count } = await query
      .range(start, stop)
      .limit(pageSize)
      .returns<(ClientNotifications | OwnerNotifications | TrainerNotifications)[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return { data, currentPage, totalPages: count ? Math.ceil(count / pageSize) : 0 };
  },
);
