import React, { useCallback, useEffect, useRef, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

import { type ClientMealsPlanDetails, type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import {
  type DishInMeal,
  type Meal,
  type MealsPlanDetails,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";

export interface IMealsPlanContext {
  canRevertLastChanges: number;
  revert: () => void;
  planId: string;
  planDetails: ClientMealsPlanDetails | MealsPlanDetails;
  plan: MealsPlanWithCreator | ClientNutrition;
  updateMealsPlan: (data: {
    mealsPlan: Partial<MealsPlanWithCreator | ClientNutrition> & { id: string };
    mealsPlanDetails: (Partial<MealsPlanDetails | ClientMealsPlanDetails> & { id: string }) | null;
  }) => Promise<void>;
  mealsMap: {
    mealsByDay: {
      id: string;
      name: string;
      meals: { name: string | null; type: string; id: string }[];
    }[];
    meals: Record<string, Meal & { dayId: string }>;
    dishes: Record<
      string,
      (Dish | IngredientWithPortion) & {
        mealId: string;
        dayId: string;
        mealIndex: number;
        dayIndex: number;
      }
    >;
  };
  updateMealsPlanDays: (props: { days: MealsPlanDetails["weeks"][number]["days"] }) => Promise<void>;
  updateProductInMealsPlan: (props: {
    dayId: string;
    mealId: string;
    product: DishInMeal | IngredientWithPortion;
  }) => Promise<void>;
  deleteProductFromMealsPlan: (props: { dayId: string; mealId: string; productId: string }) => Promise<void>;
}

export const MealsPlanContext = React.createContext<IMealsPlanContext | undefined>(undefined);

export const useMealsPlanContext = () => {
  const context = React.useContext(MealsPlanContext);
  if (!context) {
    throw new Error("useMealsPlanContext must be used within a MealsPlanContext");
  }
  return context;
};

export const useMealsPlanContextNullable = () => {
  const context = React.useContext(MealsPlanContext);
  if (!context) {
    return null;
  }
  return context;
};

const MealsPlanProvider = ({
  children,
  ...rest
}: { children: React.ReactElement } & Omit<IMealsPlanContext, "canRevertLastChanges" | "revert">) => {
  const previousWeeks = useRef([rest.planDetails.weeks]);
  const [canRevertLastChanges, setCanRevertLastChanges] = useState(0);
  const prevPlanId = useRef(rest.planDetails.planId);

  useEffect(() => {
    if (prevPlanId.current !== rest.planDetails.planId) {
      previousWeeks.current = [rest.planDetails.weeks];
      setCanRevertLastChanges(0);
      prevPlanId.current = rest.planDetails.planId;
      return;
    }

    if (!isEqual(previousWeeks.current.at(-1), rest.planDetails.weeks)) {
      const previous = previousWeeks.current.slice(-5);
      previousWeeks.current = previous.length ? [...previous, rest.planDetails.weeks] : [rest.planDetails.weeks];
      setCanRevertLastChanges(previousWeeks.current.length - 1);
    }

    prevPlanId.current = rest.planDetails.planId;
  }, [rest.planDetails]);

  const revert = useCallback(() => {
    const previous = previousWeeks.current.at(-2);
    const previousWeekDays = previous ? previous[0]?.days : null;
    if (previous && previousWeekDays) {
      void rest.updateMealsPlanDays({ days: cloneDeep(previousWeekDays) });
      previousWeeks.current = previousWeeks.current.slice(0, -1);
      setCanRevertLastChanges(previousWeeks.current.length - 1);
    }
  }, [rest.updateMealsPlanDays]);

  return (
    <MealsPlanContext.Provider value={{ ...rest, revert, canRevertLastChanges }}>{children}</MealsPlanContext.Provider>
  );
};

export default MealsPlanProvider;
