import React from "react";
import { Route, Routes } from "react-router-dom";

import TeamMemberList from "./pages/TeamMemberList/TeamMemberList";

const TeamRouter = (): React.ReactElement => {
  return (
    <Routes>
      <Route index element={<TeamMemberList />} />
    </Routes>
  );
};

export default TeamRouter;
