import auth from "./auth.json";
import automation from "./automation.json";
import campaigns from "./campaigns.json";
import chat from "./chat.json";
import checkout from "./checkout.json";
import client from "./client.json";
import common from "./common.json";
import dashboard from "./dashboard.json";
import nutrition from "./nutrition.json";
import products from "./products.json";
import settings from "./settings.json";
import surveys from "./surveys.json";
import team from "./team.json";
import trainees from "./trainees.json";
import workouts from "./workouts.json";

export default {
  common,
  auth,
  dashboard,
  team,
  products,
  settings,
  checkout,
  client,
  nutrition,
  workouts,
  surveys,
  automation,
  chat,
  trainees,
  campaigns,
};
