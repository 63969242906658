import React, { createContext, useContext, useEffect, useState } from "react";
import dayjs, { type Dayjs } from "dayjs";
import { v4 as uuid } from "uuid";

import { automationTemplatesActions, clientScheduledTasksActions } from "@fitness-app/app-store";
import { TemplateType, type ScheduledMessagesAutomation } from "@fitness-app/data-models/entities/AutomationTemplate";
import {
  ProgramAutomationTaskStatus,
  type ProgramAutomationTask,
} from "@fitness-app/data-models/entities/ProgramAutomation";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useLoading } from "~/hooks/useLoading";
import AutomatedMessagesForm from "~/modules/Automation/AutomatedMessagesForm/AutomatedMessagesForm";
import { FormMode, type AutomatedMessagesFormModel } from "~/modules/Automation/AutomatedMessagesForm/types";
import { generateDaysOfSent } from "~/modules/Automation/AutomatedMessagesForm/useGeneratedDaysForMessages";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface AutomatedMessagesFormProviderProps {
  children: React.ReactElement;
}

interface IAutomatedMessagesFormContext {
  toggleVisibility: (open: boolean) => void;
  addNewMessage: (date: dayjs.Dayjs) => void;
}

export const AutomatedMessagesFormContext = createContext<undefined | IAutomatedMessagesFormContext>(undefined);

export const useAutomatedMessagesFormContext = (): IAutomatedMessagesFormContext => {
  const automatedMessagesFormProvider = useContext(AutomatedMessagesFormContext);

  if (!automatedMessagesFormProvider) {
    throw new Error("No AutomatedMessagesFormContext.Provider found when calling useAutomatedMessagesFormContext.");
  }

  return automatedMessagesFormProvider;
};

const AutomatedMessagesFormProvider = ({ children }: AutomatedMessagesFormProviderProps) => {
  const selectedTask = useAppSelector((store) => store.clientTasks.selectedTask);
  const [savingData, startSavingData, finishSavingData] = useLoading();
  const { userId: userUid } = useUserRole();
  const client = useAppSelector((state) => state.trainee.profile);
  const dispatch = useAppDispatch();
  const [showModal, toggleModal] = useState(false);
  const [model, setModel] = useState<null | AutomatedMessagesFormModel | { mode: FormMode; sentDate: dayjs.Dayjs }>(
    null,
  );

  useEffect(() => {
    void dispatch(automationTemplatesActions.fetchAutomationTemplates());
  }, []);

  const onFormSubmit = async (formModel: AutomatedMessagesFormModel) => {
    if (!selectedTask && client) {
      const generatedDaysOfDelivery = generateDaysOfSent({
        sentDate: formModel.sentDate,
        daysOfDelivery: formModel.daysOfDelivery,
        numberOfRepeats: formModel.numberOfRepeats,
      });

      const sequenceId = uuid();

      const tasks = generatedDaysOfDelivery.map((day) => {
        const dayjsDate = dayjs(day);
        const sendDate = new Date(
          dayjsDate.get("year"),
          dayjsDate.get("month"),
          dayjsDate.get("date"),
          formModel.sentTime.get("hours"),
          formModel.sentTime.get("minutes"),
        );
        const task: ProgramAutomationTask = {
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          status: ProgramAutomationTaskStatus.Upcoming,
          programAutomationId: "manual",
          programAutomationName: "",
          id: uuid(),
          sequenceId,
          clientEmail: client.email,
          traineeId: client.id,
          addedBy: userUid ?? "",
          task: {
            name: formModel.name,
            type: TemplateType.ScheduledMessages,
            comment: formModel.comment || "",
            attachments: [],
            templateId: null,
            messages: formModel.messages,
            authorId: formModel.authorId,
            authorName: formModel.authorName,
            authorRole: formModel.authorRole,
            authorAvatar: formModel.authorAvatar || null,
            sentTime: {
              hours: formModel.sentTime.get("hour"),
              minutes: formModel.sentTime.get("minutes"),
            },
            id: uuid(),
          },
          sendDate: sendDate.toISOString(),
          sendAt: Math.floor(sendDate.getTime() / 1000),
          sentAt: null,
          errorMessage: null,
        };
        return task;
      });

      startSavingData();
      await dispatch(clientScheduledTasksActions.addClientTasks({ tasks }));
      finishSavingData();
      toggleModal(false);
      setModel(null);
    }
    if (formModel.sentDate && selectedTask?.task.type === TemplateType.ScheduledMessages) {
      const { sentDate, sentTime, ...rest } = formModel;
      const sendDate = new Date(
        sentDate.get("year"),
        sentDate.get("month"),
        sentDate.get("date"),
        sentTime.get("hours"),
        sentTime.get("minutes"),
      );
      const updatedTask: ScheduledMessagesAutomation = {
        ...selectedTask.task,
        ...rest,
        id: selectedTask.task.id,
        sentTime: {
          hours: formModel.sentTime.get("hour"),
          minutes: formModel.sentTime.get("minutes"),
        },
      };

      const updated = {
        ...selectedTask,
        task: updatedTask,
        sendDate: sendDate.toISOString(),
        sendAt: Math.floor(sendDate.getTime() / 1000),
      };

      startSavingData();
      await dispatch(clientScheduledTasksActions.updateClientTask({ id: selectedTask.id, task: updated }));
      finishSavingData();
      toggleModal(false);
      setModel(null);
      dispatch(clientScheduledTasksActions.setSelectedTask(null));
    }
  };

  useEffect(() => {
    if (selectedTask && selectedTask.task.type == TemplateType.ScheduledMessages) {
      setModel({
        ...selectedTask.task,
        sentTime: dayjs(selectedTask.sendDate),
        sentDate: dayjs(selectedTask.sendDate),
        mode: FormMode.Calendar,
      });
      toggleModal(true);
    }
  }, [selectedTask]);

  const onCancel = () => {
    toggleModal(false);
    dispatch(clientScheduledTasksActions.setSelectedTask(null));
  };

  const addNewMessage = (date: Dayjs) => {
    setModel({
      sentDate: date,
      mode: FormMode.Calendar,
    });
    toggleModal(true);
  };

  return (
    <AutomatedMessagesFormContext.Provider
      value={{
        toggleVisibility: toggleModal,
        addNewMessage,
      }}
    >
      {children}
      <ModalForm
        visible={showModal}
        onCancel={onCancel}
        title={model && "name" in model ? "Edytuj zaplanowaną wiadomość" : "Zaplanuj sekwencję wiadomości"}
        loading={savingData}
      >
        <AutomatedMessagesForm onSubmit={onFormSubmit} model={model || { mode: FormMode.Calendar }} />
      </ModalForm>
    </AutomatedMessagesFormContext.Provider>
  );
};

export default AutomatedMessagesFormProvider;
