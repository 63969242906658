import { createAsyncThunk } from "@reduxjs/toolkit";

import { ClientProgramStatus, type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
};

export const fetchScheduledPrograms = createAsyncThunk<ClientTrainingProgram[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/fetchScheduledPrograms`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("client_training_program")
      .select("*")
      .order("startDate", { ascending: true })
      .eq("traineeId", payload.traineeId)
      .eq("status", ClientProgramStatus.Scheduled)
      .returns<ClientTrainingProgram[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
