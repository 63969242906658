import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { RequestStatus } from "../../../enums/requestStatus";
import {
  duplicateWorkoutInProgram,
  fetchProgramWithDetails,
  removeWorkoutFromProgram,
  validateProgramWithDetails,
} from "./actions";
import { addNewWorkoutToProgram } from "./actions/addNewWorkoutToProgram";
import { PROGRAM_BUILDER_REDUCER_NAME, type ProgramBuilderReducer } from "./types";

const initialState: ProgramBuilderReducer = {
  programId: null,
  programStatus: null,
  programDetails: null,
  selectedProgram: null,
  validatedProgram: null,
};

const reducerSlice = createSlice({
  initialState,
  name: PROGRAM_BUILDER_REDUCER_NAME,
  reducers: {
    updateWorkoutsInProgram(state, { payload }: PayloadAction<{ programId: string; workouts: ProgramWorkout[] }>) {
      if (state.programDetails?.programId === payload.programId) {
        state.programDetails.workouts = payload.workouts;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProgramWithDetails.pending, (state, { meta }) => {
      state.programStatus = RequestStatus.FETCHING;
      state.programId = meta.arg.id;
    });
    builder.addCase(fetchProgramWithDetails.rejected, (state) => {
      state.programStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchProgramWithDetails.fulfilled, (state, { payload }) => {
      state.programStatus = RequestStatus.SUCCESS;
      state.programDetails = payload.details;
      state.selectedProgram = payload.plan;
    });

    builder.addCase(duplicateWorkoutInProgram.fulfilled, (state, { payload }) => {
      if (state.programDetails && state.programDetails.programId === payload.programId) {
        state.programDetails = payload;
      }
    });
    builder.addCase(addNewWorkoutToProgram.fulfilled, (state, { payload }) => {
      if (state.programDetails && state.programDetails.programId === payload.programId) {
        state.programDetails = payload;
      }
    });

    builder.addCase(removeWorkoutFromProgram.fulfilled, (state, { payload }) => {
      if (state.programDetails && state.programDetails.programId === payload.programId) {
        state.programDetails = payload;
      }
    });
    builder.addCase(validateProgramWithDetails.fulfilled, (state, { payload }) => {
      state.validatedProgram = payload.details;
    });
  },
});

export const { updateWorkoutsInProgram } = reducerSlice.actions;
export default reducerSlice.reducer;
