import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  type WorkoutTemplate,
  type WorkoutTemplateWithCreator,
} from "@fitness-app/data-models/entities/WorkoutTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { WORKOUT_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = {
  template: Partial<WorkoutTemplateWithCreator | (WorkoutTemplate & { creator?: never })>;
  templateId: string;
};

export const updateTemplate = createAsyncThunk<WorkoutTemplate | null, Payload, AsyncThunkCreator<string>>(
  `${WORKOUT_TEMPLATES_REDUCER_NAME}/updateTemplate`,
  async ({ template: { creator, ...rest }, templateId }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("workout_template")
      .update(rest)
      .eq("id", templateId)
      .select()
      .single<WorkoutTemplate>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data || null;
  },
);
