import React, { useEffect, useMemo, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Select, Switch, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { programBuilderActions } from "@fitness-app/app-store";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";
import {
  type TrainingProgramDetails,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { getName } from "@fitness-app/data-models/utils/getAuthorName";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useTrainingPlanSearch } from "~/modules/TrainingPrograms/hooks/useTrainingPlanSearch";
import { useAppDispatch } from "~/store/initializeStore";
import { type TraineeSelectProgramModel } from "./types";

interface TraineeSelectProgramProps {
  formController?: FormInstance<TraineeSelectProgramModel>;
  onSubmit: (
    formData: TraineeSelectProgramModel,
    fetchedPlan: null | {
      plan: TrainingProgramWithCreator;
      details: TrainingProgramDetails;
    },
  ) => void;
  model?: Partial<TraineeSelectProgramModel> | null;
  isUpdatingPlan?: boolean;
  traineeStatus?: TraineeStatus;
}

const PROGRAM_LENGTH_TYPE = {
  WEEKS: "weeks",
  MONTHS: "months",
  SELECTED_DATE: "selectedDate",
} as const;

const programLengthOptions = [
  {
    value: PROGRAM_LENGTH_TYPE.WEEKS,
    label: "Tygodnie",
  },
  {
    value: PROGRAM_LENGTH_TYPE.MONTHS,
    label: "Miesiące",
  },
  {
    value: PROGRAM_LENGTH_TYPE.SELECTED_DATE,
    label: "Wybrana data",
  },
];

const TraineeSelectProgram = ({
  model,
  onSubmit,
  formController,
  isUpdatingPlan,
  traineeStatus,
}: TraineeSelectProgramProps) => {
  const { t } = useTranslation(["common"]);
  const lengthUnit = Form.useWatch("lengthUnit", formController);
  const dispatch = useAppDispatch();
  const [fetchedPlan, setFetchedPlan] = useState<{
    plan: TrainingProgramWithCreator;
    details: TrainingProgramDetails;
  } | null>(null);
  const { userId } = useUserRole();
  const { data, isLoading, filter, changeFilter, setSearchValue } = useTrainingPlanSearch();

  const onSelect = async (value: string, option: { value: string; label: string; key?: string }) => {
    if (option?.key) {
      const plan = await dispatch(programBuilderActions.validateProgramWithDetails({ id: option.key })).unwrap();
      setFetchedPlan(plan);
      formController?.setFieldsValue({
        name: plan.plan.name,
      });
    }
  };

  useEffect(() => {
    if (isUpdatingPlan) {
      formController?.setFieldsValue({ keepOldTrainingWithoutStatus: true });
    }
  }, [isUpdatingPlan]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const planOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!userId) {
      return data.map((plan) => ({
        label: plan.name,
        value: plan.id,
        key: plan.id,
        createdBy: plan.createdBy,
        creator: plan.creator,
        tags: plan.tags,
      }));
    }
    const ordered = orderBy(
      data.map((plan) => ({
        label: plan.name,
        value: plan.id,
        key: plan.id,
        createdBy: plan.createdBy,
        creator: plan.creator,
        tags: plan.tags,
      })),
      (item) => item.createdBy === userId,
      "desc",
    );

    return ordered;
  }, [data]);

  const onPlanSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
  }, 300);

  return (
    <Form<TraineeSelectProgramModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        lengthUnit: "weeks",
        startDate: dayjs().startOf("day"),
        hasActiveProgram: true,
        duration: 8,
      }}
      onFinish={(model) => onSubmit(model, fetchedPlan)}
    >
      {isUpdatingPlan && (
        <Form.Item
          name="keepOldTrainingWithoutStatus"
          label="Zachowaj niewykonane treningi"
          valuePropName="checked"
          tooltip="Treningi ze poprzedniego planu, które nie posiadają status zostana zachowane w kalendarzu jako niewykonane."
        >
          <Switch />
        </Form.Item>
      )}

      <Form.Item label="Wyświetl tylko moje szablony">
        <Switch value={Boolean(filter)} onChange={(checked) => changeFilter(checked ? "only_author" : null)} />
      </Form.Item>

      <Form.Item validateStatus={isLoading ? "validating" : undefined} name="selectedTemplate" label="Wybrany szablon">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          placeholder="Wyszukaj szablon..."
          showSearch
          allowClear
          onSearch={onPlanSearch}
          onSelect={onSelect}
          onClear={() => {
            setFetchedPlan(null);
            formController?.setFieldsValue({
              name: "",
            });
          }}
        >
          {planOptions.map((option) => (
            <Select.Option value={option.value} label={option.label} key={option.value}>
              <div className={cn("flex flex-col gap-y-2")}>
                <span>{option.label}</span>
                <Typography.Text className="text-xs" type="secondary">
                  Autor: {"creator" in option ? getName(option.creator, undefined, false) : "-"}
                </Typography.Text>
                {option.tags?.length ? (
                  <Typography.Text className="text-xs" type="secondary">
                    Tagi: {option.tags?.join(", ")}
                  </Typography.Text>
                ) : null}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="name"
        label="Nazwa"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="startDate"
        label="Data rozpoczęcia"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <DatePicker
          placeholder="Wybierze datę"
          format="DD.MM.YYYY"
          showToday={false}
          disabledDate={(current) => current && current < dayjs().startOf("day")}
        />
      </Form.Item>

      <Form.Item
        name="lengthUnit"
        label="Jednostka długości"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Select className="max-w-[150px]" options={programLengthOptions} />
      </Form.Item>

      {lengthUnit === PROGRAM_LENGTH_TYPE.SELECTED_DATE ? (
        <Form.Item
          name="endDate"
          label="Data zakończenia"
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <DatePicker
            placeholder="Wybierze datę"
            format="DD.MM.YYYY"
            disabledDate={(current) => current && current < dayjs().add(1, "day").startOf("day")}
            showToday={false}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="duration"
          label="Długość trwania"
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <InputNumber
            className="max-w-[150px]"
            min={1}
            max={52}
            addonAfter={lengthUnit === PROGRAM_LENGTH_TYPE.WEEKS ? "tyg." : "mie."}
          />
        </Form.Item>
      )}

      <Form.Item name="comment" label="Uwagi do planu">
        <Input.TextArea rows={3} />
      </Form.Item>

      {traineeStatus === TraineeStatus.ACTIVE && (
        <Form.Item
          name="hasActiveProgram"
          label="Udostępnij plan klientowi"
          tooltip="Możesz udostępnić plan natychmiast pod dodaniu lub w późniejszym czasie."
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )}
    </Form>
  );
};

export default TraineeSelectProgram;
