import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { AuthorTypeEnum } from "@fitness-app/data-models/entities/AuthorTypeEnum";
import {
  MeasurementType,
  type BodyMeasurements,
  type WeightMeasurement,
} from "@fitness-app/data-models/entities/Measurement";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  data: WeightMeasurement["data"] | BodyMeasurements["data"];
  eventDate: string;
  traineeId: string;
  id?: null | string;
  previousMeasurement?: WeightMeasurement | BodyMeasurements | null;
  userId?: string | null;
};

export const upsertMeasurement = createAsyncThunk<
  WeightMeasurement | BodyMeasurements,
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/upsertMeasurement`,
  async (payload, { rejectWithValue, extra: { db, auth, analytics } }) => {
    const user = await getLoggedUser(auth);
    if (payload.previousMeasurement) {
      const { data, error } = await db
        .from("measurement")
        .update({
          data: payload.data,
          updatedAt: new Date().toISOString(),
        })
        .eq("id", payload.previousMeasurement.id)
        .select("*")
        .single<WeightMeasurement | BodyMeasurements>();

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    }

    const fragment =
      "weight" in payload.data
        ? { type: MeasurementType.WeightMeasurement, data: payload.data }
        : { type: MeasurementType.BodyMeasurements, data: payload.data };

    const measurement = {
      ...fragment,
      eventDate: payload.eventDate,
      eventTimestamp: dayjs(payload.eventDate, "YYYY-MM-DD").hour(12).toISOString(),
      traineeId: payload.traineeId,
      creator: user.id === payload.userId ? AuthorTypeEnum.Client : AuthorTypeEnum.Trainer,
      id: generateUniqId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      userId: payload.userId || null,
    };

    const { error, data } = await db
      .from("measurement")
      .insert(measurement)
      .select("*")
      .single<WeightMeasurement | BodyMeasurements>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_measurement", { type: measurement.type });

    return data;
  },
);
