import React, { type FunctionComponent } from "react";
import { Badge, Descriptions, Grid, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { type FreeProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { FREE_STATUS_MAPPER } from "~/modules/Products/constants/oneTimeStatusMapper";
import { paymentTypeColorMapper } from "~/modules/Products/constants/paymentTypeColorMapper";

interface OwnProps {
  productClient: FreeProductClient;
}

type Props = OwnProps;

const ProductClientFreeSettings: FunctionComponent<Props> = ({ productClient }) => {
  const { t } = useTranslation(["client", "products", "common"]);
  const { lg } = Grid.useBreakpoint();

  return (
    <Descriptions bordered labelStyle={{ width: 250 }} layout={lg ? "horizontal" : "vertical"}>
      <Descriptions.Item label={t("subscription.status")} span={3}>
        <Badge
          status={FREE_STATUS_MAPPER[productClient.status]}
          text={t(`common:productStatus.${productClient.status}`)}
        />
      </Descriptions.Item>
      <Descriptions.Item label={t("list.columns.paymentType")} span={3}>
        <Tag color={paymentTypeColorMapper[productClient.paymentType]}>{t(`type.${productClient.paymentType}`)}</Tag>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ProductClientFreeSettings;
