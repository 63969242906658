import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";

import { RequestStatus } from "../../../enums/requestStatus";
import { addClientNote } from "./actions/addClientNote";
import { editClientNote } from "./actions/editClientNote";
import { fetchClientNotes } from "./actions/fetchClientNotes";
import { removeClientNote } from "./actions/removeClientNote";
import { CLIENT_NOTES_REDUCER_NAME, NoteAction, type TraineeNotesReducer } from "./types";

const initialState: TraineeNotesReducer = {
  selectedNoteId: null,
  data: [],
  dataStatus: null,
  noteAction: null,
  traineeId: null,
};

const reducerSlice = createSlice({
  initialState,
  name: CLIENT_NOTES_REDUCER_NAME,
  reducers: {
    unsubscribeFromClientNotes(state) {
      state.data = [];
      state.dataStatus = null;
      state.traineeId = null;
    },
    subscribeToClientNotesPending(state, { payload }: PayloadAction<string>) {
      state.dataStatus = RequestStatus.FETCHING;
      state.traineeId = payload;
    },
    subscribeToClientNotesFulfilled(state, { payload }: PayloadAction<ClientNoteWithAuthorData[]>) {
      state.dataStatus = RequestStatus.SUCCESS;
      state.data = payload;
    },
    subscribeToClientNotesRejected(state) {
      state.dataStatus = RequestStatus.FAILED;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientNotes.pending, (state, { meta }) => {
      state.dataStatus = RequestStatus.FETCHING;
      state.traineeId = meta.arg.traineeId;
    });
    builder.addCase(fetchClientNotes.fulfilled, (state, { payload }) => {
      state.dataStatus = RequestStatus.SUCCESS;
      state.data = payload;
    });
    builder.addCase(fetchClientNotes.rejected, (state) => {
      state.dataStatus = RequestStatus.FAILED;
    });
    builder.addCase(addClientNote.pending, (state) => {
      state.noteAction = NoteAction.Adding;
    });
    builder.addCase(addClientNote.fulfilled, (state, { payload }) => {
      state.noteAction = null;
      if (payload.traineeId === state.traineeId) {
        state.data.unshift(payload);
      }
    });
    builder.addCase(addClientNote.rejected, (state) => {
      state.noteAction = null;
    });
    builder.addCase(editClientNote.pending, (state) => {
      state.noteAction = NoteAction.Updating;
    });
    builder.addCase(editClientNote.fulfilled, (state, { payload }) => {
      state.noteAction = null;
      state.data = state.data.map((note) => (note.id === payload.id ? { ...note, ...payload } : note));
    });
    builder.addCase(editClientNote.rejected, (state) => {
      state.noteAction = null;
    });
    builder.addCase(removeClientNote.fulfilled, (state, { meta }) => {
      state.data = state.data.filter((note) => note.id !== meta.arg.noteId);
    });
  },
});

export const {
  subscribeToClientNotesFulfilled,
  subscribeToClientNotesPending,
  subscribeToClientNotesRejected,
  unsubscribeFromClientNotes,
} = reducerSlice.actions;
export default reducerSlice.reducer;
