import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { TraineeStatus, type TraineeWithAssignedTrainer } from "@fitness-app/data-models/entities/Trainee";

import { type AsyncThunkCreator } from "../../../index";
import { getUserClaims } from "../../user/selectors";
import { TRAINEES_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchAllActiveTrainees = createAsyncThunk<
  {
    list: TraineeWithAssignedTrainer[];
    count: number;
  },
  Payload,
  AsyncThunkCreator<string>
>(`${TRAINEES_REDUCER_NAME}/fetchAllActiveTrainees`, async (payload, { rejectWithValue, extra: { db }, getState }) => {
  const { role, memberId } = getUserClaims(getState());

  let query = db
    .from("trainee")
    .select(
      "id, userId, firstName, lastName, email, createdAt, assignedTrainerId, assignedTrainer:assignedTrainerId(id, firstName, lastName, userId, email)",
      { count: "exact" },
    )
    .order("createdAt", { ascending: false })
    .eq("status", TraineeStatus.ACTIVE)
    .not("userId", "is", null)
    .eq("type", AppAccessType.FullAccess);

  if (role === UserRole.TEAM_MEMBER && memberId) {
    query = query.eq("assignedTrainerId", memberId);
  }

  const { error, data, count } = await query.returns<TraineeWithAssignedTrainer[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return {
    list: data,
    count: count || 0,
  };
});
