import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  userId: string;
};

export const resendInvitationToApp = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/resendInvitationToApp`,
  async (payload, { rejectWithValue, extra: { notificationsApi, analytics } }) => {
    if (!notificationsApi) {
      throw new Error("notificationsApi is not defined");
    }

    try {
      await notificationsApi?.post("/resend-invitation", payload);

      analytics.track("resend_invitation_to_app");
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
