import React, { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

import { userActions } from "@fitness-app/app-store";

import { supabase, useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { Logger } from "~/utils/Logger";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextType {
  user: { email?: string | null; id: string } | null;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext) || {};
}
const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement => {
  const user = useAppSelector((store) => store.user.data);
  const dispatch = useAppDispatch();
  const [isUserResolved, setIsUserResolved] = useState(false);
  const navigate = useNavigate();

  const userUid = user?.id;

  const getUser = useCallback(async () => {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();

    if (error) {
      setIsUserResolved(true);
      return;
    }

    if (session) {
      supabase.auth
        .getUser()
        .then((response) => {
          if (response.data.user) {
            dispatch(
              userActions.setLoggedUserData(response.data.user, undefined, false, response.data.user.app_metadata),
            );
          }
        })
        .catch((err) => {
          Logger.error(err);
        })
        .finally(() => {
          setIsUserResolved(true);
        });
    } else {
      setIsUserResolved(true);
    }
  }, []);

  useEffect(() => {
    void getUser();
  }, []);

  useEffect(() => {
    const subscription = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_OUT" && userUid) {
        dispatch(userActions.logOutUser());
        Sentry.captureEvent({ message: "User signed out", extra: { event } });
      }
      if (event === "SIGNED_IN" && session?.user?.id && !userUid) {
        dispatch(userActions.setLoggedUserData(session.user, session));
      }

      if (event === "USER_UPDATED" && session) {
        dispatch(userActions.setLoggedUserData(session.user, session));
      }

      if (event === "TOKEN_REFRESHED" && session) {
        dispatch(userActions.setLoggedUserData(session.user, session));
      }

      if (event === "PASSWORD_RECOVERY") {
        navigate("/user/set-password");
      }
    });

    return subscription.data.subscription.unsubscribe;
  }, [userUid]);

  const value = { user };

  if (!isUserResolved) {
    return (
      <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
