import React, { memo } from "react";
import { MinusCircleOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { AutoComplete, Avatar, Button, Form, Input, Select, Space } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { automationTemplatesSelectors } from "@fitness-app/app-store";
import { CURRENT_ASSIGNED_TRAINER } from "@fitness-app/data-models/contants/currentAssignedTrainer";
import { getUserInitials } from "@fitness-app/utils";

import { useAuthorOptions } from "~/hooks/trainer/useAuthorOptions";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { type ISurveyQuestionAutomationForm } from "~/modules/SurveysTemplates/SurveyDetails/SurveyQuestionAutomationForm";
import { useAppSelector } from "~/store/initializeStore";

interface SurveyAutomationChatMessageFormProps {
  parentFieldName: string | number;
  path: (string | number)[];
  formController?: FormInstance<ISurveyQuestionAutomationForm>;
}

const SurveyAutomationChatMessageForm = ({
  parentFieldName,
  formController,
  path,
}: SurveyAutomationChatMessageFormProps): React.ReactElement => {
  const { t } = useTranslation(["dashboard", "common", "products", "surveys"]);
  const templates = useAppSelector(automationTemplatesSelectors.getAutomationTemplatesMessages);
  const { isTeamMember } = useUserRole();
  const { trainerId } = useUserClaims();
  const userDetails = useAppSelector((store) => store.user.profile);

  const authorOptions = useAuthorOptions(undefined, true);

  const hydrateForm = (templateName: string | null) => {
    const template = templates.find((template) => template.name === templateName);

    if (template) {
      const fieldsValues = {
        messages: template.data.messages,
        authorId:
          isTeamMember && template.data.authorId === trainerId ? userDetails?.id || undefined : template.data.authorId,
      };

      formController?.setFields([
        {
          // @ts-expect-error - fix this
          name: [...path, "messages"],
          value: fieldsValues.messages,
        },
      ]);
      formController?.setFields([
        {
          // @ts-expect-error - fix this
          name: [...path, "authorId"],
          value: fieldsValues.authorId,
        },
      ]);
    }
  };

  return (
    <>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>

      <Form.Item
        name={[parentFieldName, "name"]}
        label={t("automatedMessageForm.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <AutoComplete
          showSearch
          allowClear
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={(inputValue, option) => {
            if (option?.children) {
              // @ts-expect-error ignore

              return option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }
            return false;
          }}
          onSelect={(value: string) => hydrateForm(value)}
        >
          {templates.map((template) => (
            <Select.Option key={template.id} value={template.name}>
              {template.name}
            </Select.Option>
          ))}
        </AutoComplete>
      </Form.Item>

      <Form.Item
        name={[parentFieldName, "authorId"]}
        label={t("automatedMessageForm.messageAuthor")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select placeholder={t("automatedMessageForm.messageAuthorPlaceholder")}>
          {authorOptions.map((author) => (
            <Select.Option value={author.value} label={author.label} key={author.value}>
              <Space direction="horizontal">
                <Avatar src={author.avatarUrl} size={26}>
                  {author.value === CURRENT_ASSIGNED_TRAINER ? <UserOutlined /> : getUserInitials(author.label)}
                </Avatar>{" "}
                {author.label}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={t("automatedMessageForm.messages")} tooltip={t("automatedMessageForm.dynamicValue")}>
        <Form.List name={[parentFieldName, "messages"]} initialValue={[""]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div
                  key={field.key}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "baseline",
                  }}
                >
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    key={field.key}
                    rules={[
                      {
                        required: true,
                        message: t<string>("common:validationErrors.fieldIsRequired"),
                      },
                    ]}
                    style={{ width: "90%" }}
                  >
                    <Input.TextArea rows={2} placeholder={t<string>("automatedMessageForm.placeholderMessage")} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginLeft: 12 }} />
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ marginTop: 10 }}>
                  {t("surveys:surveyDetailsForm.addMessage")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default memo(SurveyAutomationChatMessageForm);
