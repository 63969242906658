import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";

type Payload = {
  survey: ClientSurvey;
};

export const addTraineeSurvey = createAsyncThunk<ClientSurvey, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/addTraineeSurvey`,
  async ({ survey }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db.from("client_survey").insert(survey).select("*").single<ClientSurvey>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_trainee_survey");

    return data;
  },
);
