import { type Database } from "@fitness-app/supabase";

export type ExerciseEntity = Database["public"]["Tables"]["exercise"]["Row"];

export type ExerciseTypeEnum = Database["public"]["Enums"]["WorkoutType"];
export type ExerciseBodyPartEnum = Database["public"]["Enums"]["ExerciseBodyPart"];
export type ExerciseVideoTypeEnum = Database["public"]["Enums"]["ExerciseVideoType"];

export const ExerciseType: Record<
  Database["public"]["Enums"]["WorkoutType"],
  Database["public"]["Enums"]["WorkoutType"]
> = {
  POWER: "POWER",
  CARDIO: "CARDIO",
  STRETCHING: "STRETCHING",
  MOBILIZATION: "MOBILIZATION",
  CALISTHENICS: "CALISTHENICS",
  REHABILITATION: "REHABILITATION",
} as const;

export const ExerciseBodyPart: Record<
  Database["public"]["Enums"]["ExerciseBodyPart"],
  Database["public"]["Enums"]["ExerciseBodyPart"]
> = {
  LEGS: "LEGS",
  BACK: "BACK",
  CHEST: "CHEST",
  SHOULDERS: "SHOULDERS",
  ARMS: "ARMS",
  GENERAL_DEVELOPMENT: "GENERAL_DEVELOPMENT",
  ABS: "ABS",
  GLUTS: "GLUTS",
  FOREARMS: "FOREARMS",
  CORE: "CORE",
  BICEPS: "BICEPS",
  TRICEPS: "TRICEPS",
} as const;

export const ExerciseVideoType: Record<
  Database["public"]["Enums"]["ExerciseVideoType"],
  Database["public"]["Enums"]["ExerciseVideoType"]
> = {
  NONE: "NONE",
  LINK: "LINK",
  YOUTUBE: "YOUTUBE",
  MEDIA_LIBRARY: "MEDIA_LIBRARY",
  VIMEO: "VIMEO",
} as const;

export interface ExerciseVideoUrls {
  title: string;
  hlsUrl: string | null;
  mp4Url: string | null;
  thumbnailUrl: string | null;
  guid: string;
  previewUrl: string | null;
}

export interface ExerciseLink {
  title: string;
  type: ExerciseVideoTypeEnum;
  id: string;
}

export interface VideoExerciseLink extends ExerciseLink {
  type: typeof ExerciseVideoType.MEDIA_LIBRARY;
  data: ExerciseVideoUrls;
}
export interface Exercise extends Omit<ExerciseEntity, "links" | "videoUrls" | "tags"> {
  links?: VideoExerciseLink[];
  instruction: string[];
  tags: string[];
}

export interface ExerciseWithVideo extends Exercise {
  video: ExerciseVideoUrls | null;
}
