import React, { useEffect, useState, type FunctionComponent } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Radio, Row, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { ProductIntegrationClient } from "@fitness-app/data-models/entities/Automation";

import { productIntegrationOptions } from "~/modules/Products/Product/ProductAutomation/components/Integrations/integration/integrationOptions";
import { useAppSelector } from "~/store/initializeStore";
import DiscordProductIntegration from "./integration/DiscordProductIntegration/DiscordProductIntegration";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

const Integrations: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation("settings");
  const { data } = useAppSelector((state) => state.productsSettings);

  const [selectedIntegration, setIntegration] = useState<ProductIntegrationClient | null>(null);

  useEffect(() => {
    if (productIntegrationOptions.filter((item) => !item.disabled).length === 1) {
      setIntegration(productIntegrationOptions[0]?.value || null);
    }
  }, []);

  const renderActiveIntegration = () => {
    if (selectedIntegration === ProductIntegrationClient.Discord) {
      return <DiscordProductIntegration productId={productId} />;
    }

    return null;
  };

  return (
    <div className="flex min-h-[300px] flex-col pt-8">
      <Space direction="vertical" size={24}>
        <Row gutter={24} justify="center">
          <Radio.Group
            size="large"
            value={selectedIntegration}
            onChange={(e) => setIntegration(e.target.value as ProductIntegrationClient)}
          >
            {productIntegrationOptions.map((client) => (
              <Tooltip title={client.disabled ? t("common:availableSoon") : undefined} key={client.value}>
                <Radio.Button value={client.value} disabled={client.disabled}>
                  <img
                    src={client.icon}
                    alt={`${client.value}-icon`}
                    className="mx-4 my-0 h-[25px] w-[25px] object-contain align-middle"
                    style={client.size ? { width: client.size, height: client.size } : undefined}
                  />
                  {t(`common:integration.${client.value}`)}
                  {data?.extra &&
                  client.value in data.extra &&
                  // @ts-expect-error ignore
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  data?.extra?.[client.value]?.length ? (
                    <CheckCircleOutlined style={{ color: "#1bc5bd", marginLeft: 8 }} />
                  ) : null}
                </Radio.Button>
              </Tooltip>
            ))}
          </Radio.Group>
        </Row>
      </Space>
      {renderActiveIntegration()}
    </div>
  );
};

export default Integrations;
