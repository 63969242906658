import { type Database } from "@fitness-app/supabase";

import { type AuthorTypeEnum } from "./AuthorTypeEnum";

export type ClientNoteEntity = Database["public"]["Tables"]["client_note"]["Row"];

export enum ClientNoteType {
  Text = "text",
  Event = "event",
}

export enum ClientNoteCategory {
  General = "general",
  Nutrition = "nutrition",
  Training = "training",
  Health = "health",
  Management = "management",
}

export interface ClientNote extends Omit<ClientNoteEntity, "attachments"> {
  type: ClientNoteType;
  authorType: AuthorTypeEnum;
  category: ClientNoteCategory;
  attachments: { url: string; name: string; uid: string; protected?: boolean }[] | null;
}

export interface ClientNoteWithAuthorData extends ClientNote {
  author: {
    avatarUrl: string;
    firstName: string;
    lastName: string;
  } | null;
}
