import React, { memo, useCallback, useMemo, type MouseEvent } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import round from "lodash.round";

import { traineeMealsPlanSelectors } from "@fitness-app/app-store";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal } from "@fitness-app/data-models/entities/MealsPlan";
import { reduceDishIngredientsNutrients } from "@fitness-app/utils/src/nutrition/reduceMealsNutrients";
import { getNutrientsInPortion } from "@fitness-app/utils/src/nutrition/sumNutrients";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useMealsProductContext } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsProductProvider";
import { useAppSelector } from "~/store/initializeStore";

interface MealDishProps {
  dishOrIngredient: DishInMeal | IngredientWithPortion;
  dayId?: string;
  mealId?: string;
  readonly?: boolean;
}

const MealDish = ({ dishOrIngredient, dayId, mealId, readonly }: MealDishProps): React.ReactElement => {
  const { selectProduct, duplicateProduct } = useMealsProductContext();
  const dishesOccurrence = useAppSelector(traineeMealsPlanSelectors.getDishOccurrenceForSelectedPlan);

  const { protein, fat, carbs, calories } = useMemo(
    () => reduceDishIngredientsNutrients([dishOrIngredient]),
    [dishOrIngredient],
  );

  const duplicate = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (mealId && dayId) {
        duplicateProduct(dishOrIngredient, mealId, dayId);
      }
    },
    [duplicateProduct],
  );

  const dishOccurrence = dishesOccurrence?.[dishOrIngredient.name] ?? [];

  if (dishOrIngredient.type === "ingredient") {
    return (
      <div
        className={cn(
          "group/item relative m-2 rounded-xl bg-emerald-200 p-2",
          !readonly && "cursor-pointer hover:bg-emerald-300",
          dishOccurrence.length > 0 && "border-2 border-emerald-500",
        )}
        onClick={readonly ? undefined : () => selectProduct(dishOrIngredient, mealId, dayId)}
      >
        {mealId && dayId && !readonly ? (
          <div className="absolute right-1.5 top-1.5 hidden group-hover/item:block">
            <Button onClick={duplicate} type="text" size="small" icon={<CopyOutlined />} />
          </div>
        ) : null}
        <dd className="max-w-[95%] text-sm font-medium text-gray-900">
          {dishOrIngredient.name}
          {dishOrIngredient.brand ? ` (${dishOrIngredient.brand})` : ""}
        </dd>
        <div className="flex justify-between pt-2 font-light">
          <dd className="flex gap-1 text-xs text-gray-700">
            <span>B:{protein}</span>
            <span>|</span>
            <span>W:{carbs}</span>
            <span>|</span>
            <span>T:{fat}</span>
          </dd>
          <dd className="ml-2 text-right text-xs text-gray-700">
            {getNutrientsInPortion(dishOrIngredient, "calories", 0)} kc
          </dd>
        </div>
      </div>
    );
  }

  return (
    <Tooltip
      overlayStyle={{ minWidth: 380 }}
      title={
        dishOccurrence.length ? (
          <div>
            {dishOccurrence.map((occurrence, index) => (
              <div key={occurrence.id}>
                {index + 1}. {occurrence.plan.name}: {occurrence.plan.startAt} -{" "}
                {occurrence.plan.endAt || "obecny plan"}
              </div>
            ))}
          </div>
        ) : undefined
      }
    >
      <div
        className={cn(
          "group/item relative m-2 rounded-xl border border-transparent bg-emerald-200 p-2",
          !readonly && "cursor-pointer hover:bg-emerald-300",
          dishOccurrence.length > 0 && "border border-red-500",
        )}
        onClick={readonly ? undefined : () => selectProduct(dishOrIngredient, mealId, dayId)}
      >
        {mealId && dayId && !readonly ? (
          <div className="absolute right-1.5 top-1.5 hidden group-hover/item:block">
            <Button onClick={duplicate} type="text" size="small" icon={<CopyOutlined />} />
          </div>
        ) : null}
        <dd className="max-w-[95%] text-sm font-medium text-gray-900">{dishOrIngredient.name}</dd>
        <div className="flex justify-between pt-2 font-light	">
          <dd className="flex gap-1 text-xs text-gray-700">
            <span>B:{protein}</span>
            <span>|</span>
            <span>W:{carbs}</span>
            <span>|</span>
            <span>T:{fat}</span>
          </dd>
          {dishOccurrence.length > 0 ? (
            <dd className="ml-2 text-xs font-bold text-gray-700">({dishOccurrence.length})</dd>
          ) : (
            <dd className="ml-2 text-right text-xs text-gray-700">{round(calories, 0)} kc</dd>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default memo(MealDish);
