import { type Order } from "@fitness-app/data-models/entities/Order";

export function exportOrdersToCsv(orders: Order[], productId: string, selectedMonth: string) {
  let data = "";
  const tableData = [];
  const headers = [
    "id",
    "priceId",
    "productId",
    "email",
    "firstName",
    "lastName",
    "createdAt",
    "amount",
    "amountConverted",
    "currency",
    "status",
    "invoice",
    "invoice number",
  ];
  const dataRows: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    number,
    string,
    string,
    string,
    string,
    string,
  ][] = [];

  orders.forEach((order, index) => {
    dataRows[index] = [
      order.id,
      order.priceId ?? "-",
      order.product ?? "-",
      order.clientEmail,
      order.client.firstName,
      order.client.lastName,
      order.createdAt ?? "-",
      !order.price || order.price.free ? 0 : order.price.amount,
      !order.price || order.price.free ? "0" : (order.price.amount / 100).toFixed(2),
      !order.price || order.price.free ? "-" : order.price.currency,
      order.status ?? "-",
      order.connectedInvoice ? "yes" : "no",
      String(order.connectedInvoice && "number" in order.connectedInvoice ? order.connectedInvoice.number : "-"),
    ];
  });

  const rows = [headers, ...dataRows];
  for (const row of rows) {
    const rowData = [];
    for (const column of row) {
      rowData.push(column);
    }
    tableData.push(rowData.join(","));
  }
  data += tableData.join("\n");
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  a.setAttribute("download", `${productId}-${selectedMonth}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
