import { type ProductsSettings } from "@fitness-app/data-models/entities/ProductsSettings";
import { getError } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import {
  subscribeToIntegrationFailed,
  subscribeToIntegrationStarted,
  subscribeToIntegrationSuccess,
  updateProductsSettings,
} from "../reducer";
import { fetchProductsSettings } from "./fetchProductsSettings";
import { unsubscribeFromProductsSettings } from "./unsubscribeFromProductsSettings";

export const subscribeToProductsSettings =
  (): AppThunk =>
  async (dispatch, getState, { auth, subscriptions, db }) => {
    try {
      dispatch(subscribeToIntegrationStarted());

      void dispatch(fetchProductsSettings());

      const { id } = await getLoggedUser(auth);

      if (subscriptions.productsSettingsListChannelName && subscriptions.realtimeProductsSettingsListChannel) {
        await db.removeChannel(subscriptions.realtimeProductsSettingsListChannel);
      }

      subscriptions.productsSettingsListChannelName = `products-settings-listener`;
      subscriptions.realtimeProductsSettingsListChannel = db.channel(subscriptions.productsSettingsListChannelName);

      subscriptions.realtimeProductsSettingsListChannel
        .on<ProductsSettings>(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "products_settings", filter: `userId=eq.${id}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductsSettings(payload.new));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToIntegrationSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromProductsSettings());
          }
        });
    } catch (error) {
      dispatch(subscribeToIntegrationFailed(getError(error)));
    }
  };
