import { createAsyncThunk } from "@reduxjs/toolkit";

import { ProgramService } from "@fitness-app/data-models/domain/services/ProgramService";
import {
  type ClientTrainingProgram,
  type ExceptionWeek,
  type ProgramSchedule,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  programSchedule?: ProgramSchedule;
  exceptionWeeks?: Record<string, ExceptionWeek>;
  updateScheduleFromWeek?: string | null;
};

export const setProgramSchedule = createAsyncThunk<ClientTrainingProgram, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/setProgramSchedule`,
  async (payload, { rejectWithValue, getState, extra: { db, analytics } }) => {
    const { selectedProgram } = getState().traineeProgram;

    if (!selectedProgram) {
      throw new Error("Trainee program data doesn't exist");
    }

    const schedule = ProgramService.prepareClientScheduleUpdate({
      currentProgram: selectedProgram,
      exceptionWeeks: payload.exceptionWeeks,
      updatedSchedule: payload.programSchedule,
      changeFromWeek: payload.updateScheduleFromWeek,
    });

    const { error, data } = await db
      .from("client_training_program")
      .update({
        workoutsSchedule: schedule,
        updatedAt: new Date().toISOString(),
      })
      .eq("id", selectedProgram.id)
      .select("*")
      .single<ClientTrainingProgram>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("set_program_schedule");

    return data;
  },
);
