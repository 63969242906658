import React, { memo, useMemo } from "react";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Empty, List, Popconfirm, Space, Typography } from "antd";
import orderBy from "lodash.orderby";
import { useTranslation } from "react-i18next";

import { programAutomationActions } from "@fitness-app/app-store";
import { type WorkoutRoutine } from "@fitness-app/data-models/entities/ProgramAutomation";

import ScheduleProgramStepper, {
  useScheduleProgramStepper,
} from "~/modules/Automation/ScheduleProgramStepper/ScheduleProgramStepper";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ScheduleTrainingPrograms = () => {
  const { t } = useTranslation(["automation", "common"]);
  const { visible, closeModal, openModal, routineModel } = useScheduleProgramStepper();
  const selectedAutomation = useAppSelector((store) => store.programAutomation.selectedAutomation);
  const dispatch = useAppDispatch();
  const sortedScheduledRoutine = useMemo(
    () => orderBy(selectedAutomation?.scheduledWorkoutsRoutine || [], ["startWeek"]),
    [selectedAutomation?.scheduledWorkoutsRoutine],
  );

  if (!selectedAutomation) {
    return null;
  }

  const removeScheduledProgram = (routineId: string) => {
    const updatedScheduledWorkouts = selectedAutomation.scheduledWorkoutsRoutine.filter(
      (item) => item.routineId !== routineId,
    );
    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          scheduledWorkoutsRoutine: updatedScheduledWorkouts,
        },
      }),
    );
  };

  const onSubmitScheduledProgram = async (workoutRoutine: WorkoutRoutine) => {
    if (routineModel) {
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            scheduledWorkoutsRoutine: [
              ...(selectedAutomation.scheduledWorkoutsRoutine ?? []).map((item) =>
                item.routineId !== routineModel.routineId ? item : workoutRoutine,
              ),
            ],
          },
        }),
      );
    } else {
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            scheduledWorkoutsRoutine: [...(selectedAutomation.scheduledWorkoutsRoutine ?? []), workoutRoutine],
          },
        }),
      );
    }
  };

  return (
    <>
      <Collapse>
        <Collapse.Panel key="programs" header={t("trainings.plannedAutomation")}>
          <List
            itemLayout="horizontal"
            bordered={false}
            locale={{
              emptyText: (
                <Empty description={t("trainings.emptyState")}>
                  <Button icon={<PlusOutlined />} type="primary" onClick={() => openModal()}>
                    {t("trainings.addPlan")}
                  </Button>
                </Empty>
              ),
            }}
            dataSource={sortedScheduledRoutine}
            footer={
              sortedScheduledRoutine.length ? (
                <div className="flex items-center justify-center">
                  <Button icon={<PlusOutlined />} size="small" type="primary" onClick={() => openModal()}>
                    {t("trainings.addAnotherPlan")}
                  </Button>
                </div>
              ) : null
            }
            renderItem={(routine) => (
              <List.Item
                actions={[
                  <Typography.Link key="edit" onClick={() => openModal(routine)}>
                    edytuj
                  </Typography.Link>,
                  <Popconfirm
                    key="remove"
                    title={t("trainings.confirmDelete")}
                    okText={t("common:yes")}
                    cancelText={t("common:no")}
                    placement="left"
                    onConfirm={() => removeScheduledProgram(routine.routineId)}
                  >
                    <Typography.Link type="danger">{t("common:button.delete")}</Typography.Link>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={<Typography.Text strong>{routine.name}</Typography.Text>}
                  description={
                    <Space direction="horizontal" size={8}>
                      <CalendarOutlined />
                      <Typography.Text type="secondary">
                        {routine.startWeek !== routine.endWeek
                          ? t("trainings.fromTo", {
                              weekFrom: routine.startWeek,
                              weekTo: routine.endWeek,
                            })
                          : t("trainings.from", { week: routine.startWeek })}
                      </Typography.Text>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Collapse.Panel>
      </Collapse>
      {selectedAutomation && (
        <ScheduleProgramStepper
          visible={visible}
          handleClose={closeModal}
          routineModel={routineModel || undefined}
          selectedAutomation={selectedAutomation}
          onSubmit={onSubmitScheduledProgram}
        />
      )}
    </>
  );
};

export default memo(ScheduleTrainingPrograms);
