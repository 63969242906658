import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type Product } from "@fitness-app/data-models/entities/Product";
import { type ProductStats } from "@fitness-app/data-models/entities/ProductStats";

import { RequestStatus } from "../../../enums/requestStatus";
import { addProduct } from "./actions/addProduct";
import { fetchProducts } from "./actions/fetchProducts";
import { PRODUCTS_REDUCER_NAME, type ProductsReducer } from "./types";

const initialState: ProductsReducer = {
  productsInfo: null,
  productsInfoStatus: null,
  productsListStatus: null,
  productsListData: [],
  addingNewProduct: false,
  productsError: null,
};

const productsSlice = createSlice({
  name: PRODUCTS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductsStarted(state) {
      state.productsListStatus = RequestStatus.SUBSCRIBING;
      state.productsError = null;
    },
    subscribeToProductsSuccess(state) {
      state.productsListStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToProductsFailed(state, { payload }: PayloadAction<string>) {
      state.productsListStatus = RequestStatus.FAILED;
      state.productsError = payload;
    },
    unsubscribeFromProducts(state) {
      state.productsListStatus = null;
      state.productsListData = [];
      state.productsError = null;
    },
    removeProductFromList(state, { payload }: PayloadAction<string>) {
      state.productsListData = state.productsListData.filter((product) => product.id !== payload);
    },
    addProductToList(state, { payload }: PayloadAction<Product>) {
      state.productsListData = [payload, ...state.productsListData];
    },
    updateProductOnList(state, { payload }: PayloadAction<Product>) {
      state.productsListData = state.productsListData.map((product) => {
        if (product.id === payload.id) {
          return {
            ...product,
            ...payload,
          };
        }
        return product;
      });
    },
    updateProductStatsOnList(
      state,
      {
        payload,
      }: PayloadAction<{
        product_stats?: Pick<ProductStats, "totalClients" | "activeClients" | "archivedClients">;
        id: string;
      }>,
    ) {
      state.productsListData = state.productsListData.map((product) => {
        if (product.id === payload.id) {
          return {
            ...product,
            product_stats: payload.product_stats,
          };
        }
        return product;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProduct.pending, (state) => {
      state.addingNewProduct = true;
    });
    builder.addCase(addProduct.fulfilled, (state) => {
      state.addingNewProduct = false;
    });
    builder.addCase(addProduct.rejected, (state) => {
      state.addingNewProduct = false;
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.productsListData = payload;
      state.productsListStatus = RequestStatus.SUBSCRIBED;
    });
  },
});

export const {
  subscribeToProductsStarted,
  subscribeToProductsFailed,
  subscribeToProductsSuccess,
  unsubscribeFromProducts,
  addProductToList,
  removeProductFromList,
  updateProductOnList,
  updateProductStatsOnList,
} = productsSlice.actions;
export default productsSlice.reducer;
