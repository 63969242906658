import React, { useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { Dumbbell } from "lucide-react";

import { type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";

interface ExerciseThumbnailProps {
  exerciseInWorkout: ExerciseInWorkout;
}

export const ExerciseThumbnail = ({ exerciseInWorkout }: ExerciseThumbnailProps): React.ReactElement => {
  const [videoLink, setVideoLink] = useState<string | null>(null);

  if (!exerciseInWorkout.exercise.video && !exerciseInWorkout.exercise.videoLink) {
    return <Avatar shape="square" size="large" icon={<Dumbbell className="mt-1.5" />} />;
  }
  const url = exerciseInWorkout.exercise.video?.hlsUrl || exerciseInWorkout.exercise.video?.mp4Url;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        className="group relative"
        onClick={(e) => {
          e.stopPropagation();
          setVideoLink(url || exerciseInWorkout.exercise.videoLink || null);
        }}
      >
        <Avatar
          shape="square"
          size="large"
          src={exerciseInWorkout.exercise.video?.thumbnailUrl}
          icon={<Dumbbell className="mt-1.5" />}
        />
        <div className="invisible absolute inset-0 flex items-center justify-center rounded-[8px] bg-gray-700 bg-opacity-50 group-hover:visible">
          <PlayCircleOutlined className="text-white" />
        </div>
      </div>
      <VideoPlayer visible={!!videoLink} videoUrl={videoLink} onClose={() => setVideoLink(null)} />
    </div>
  );
};
