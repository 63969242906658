import React, { useEffect, useState } from "react";
import { EditOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productActions } from "@fitness-app/app-store";
import { type Product } from "@fitness-app/data-models/entities/Product";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import { type UpdateProductSlugFormModel } from "~/modules/Products/Product/ProductDetails/components/types";
import UpdateProductSlugForm from "~/modules/Products/Product/ProductDetails/components/UpdateProductSlugForm";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface ProductPageProps {
  product: Product;
  readonly?: boolean;
}

const ProductPage = ({ product, readonly }: ProductPageProps) => {
  const user = useAppSelector((state) => state.user.profile);
  const { t } = useTranslation("products");
  const [showProductSlugModal, toggleSlugModal] = useState(false);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const [model, setModel] = useState<null | UpdateProductSlugFormModel>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setModel({
      slug: product?.slug || "",
    });
  }, [product]);

  if (!user) {
    return null;
  }

  const onSave = async (model: UpdateProductSlugFormModel) => {
    if (model.slug === product.slug) {
      toggleSlugModal(false);
      return;
    }
    setModel(model);
    onStart();
    try {
      await dispatch(
        productActions.updateProductSlug({
          productId: product.id,
          slug: model.slug.toLowerCase(),
        }),
      ).unwrap();
      onSuccess();
      toggleSlugModal(false);
    } catch (e) {
      onFailure(
        // @ts-expect-error ignore
        e.code === "already-exists" ? t("salesPage.errors.slugExists") : t("common:messages.error.default"),
      );
    }
  };

  // if (!user.salesPageSettings?.active || !user.salesPageSettings.slug) {
  //   return (
  //     <Alert
  //       showIcon
  //       type="warning"
  //       message={t("salesPage.activeSalesPageToGetProductPage")}
  //       description={
  //         <Typography.Link onClick={() => history.push("/settings")}>{t("salesPage.goToSettings")}</Typography.Link>
  //       }
  //     />
  //   );
  // }
  const url = `https://${import.meta.env.VITE_ECOMMERCE_DOMAIN}/p/${product.slug || product.id}`;
  return (
    <>
      <Space size={12}>
        <Space>
          <Typography.Link target="_blank" href={url}>
            {product.slug || product.id}
          </Typography.Link>
          <Tooltip title={t("list.copyToClipboard")}>
            <LinkOutlined style={{ fontSize: 22 }} onClick={() => navigator.clipboard.writeText(url)} />
          </Tooltip>
        </Space>
        {product.slug ? (
          <Button
            type="dashed"
            size="small"
            disabled={readonly}
            icon={<EditOutlined />}
            onClick={() => toggleSlugModal(true)}
          >
            {t("salesPage.editSlug")}
          </Button>
        ) : (
          <Button
            type="dashed"
            size="small"
            disabled={readonly}
            icon={<EditOutlined />}
            onClick={() => toggleSlugModal(true)}
          >
            {t("salesPage.createSlug")}
          </Button>
        )}
      </Space>
      <ModalForm
        title={t("salesPage.updateProductSlug")}
        visible={showProductSlugModal}
        loading={Boolean(loading)}
        onCancel={() => toggleSlugModal(false)}
      >
        <UpdateProductSlugForm onSubmit={onSave} model={model || undefined} />
      </ModalForm>
    </>
  );
};

export default ProductPage;
