import { type Database } from "@fitness-app/supabase";

export type ProductClientToImportEntity = Database["public"]["Tables"]["product_client_import"]["Row"];
export type ProductClientsImportAggregationEntity = Database["public"]["Tables"]["product_import_aggregation"]["Row"];

export enum ClientImportStatus {
  Pending = "pending",
  Imported = "imported",
  Failed = "failed",
}

export interface ProductClientToImportDocument extends ProductClientToImportEntity {
  status: ClientImportStatus;
}

export enum ClientImportAggregation {
  InProgress = "inProgress",
  Finished = "finished",
}

export interface ProductClientsImportAggregation extends ProductClientsImportAggregationEntity {
  status: ClientImportAggregation;
}
