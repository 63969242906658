import isNumber from "lodash.isnumber";
import keyBy from "lodash.keyby";
import sortBy from "lodash.sortby";
import uniqBy from "lodash.uniqby";

import {
  type ExerciseInWorkout,
  type ExerciseInWorkoutWithOrderKey,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { formatExerciseSet } from "../formatExerciseInProgram";

type Params = {
  exercises: ExerciseInWorkout[];
  exercisesToGroup: string[];
  numberOfSeries?: number;
  fromArray?: boolean;
  toArray?: boolean;
};
// exercisesToGroup: [workoutKey1, workoutKey2]
export const groupInSuperSet = ({ exercisesToGroup, exercises, fromArray = false, toArray = false }: Params) => {
  const exercisesArr: ExerciseInWorkoutWithOrderKey[] = fromArray
    ? exercises.map((item, i) => ({ ...item, orderKey: i }))
    : Object.entries(exercises).map(([key, item], index) => ({
        ...item,
        id: key,
        orderKey: index,
      }));
  // fix old program without orderKey
  const exercisesArrWithOrderKeys = isNumber(exercisesArr[0]?.orderKey)
    ? exercisesArr
    : exercisesArr.map((item, i) => ({ ...item, orderKey: i }));
  const ordersKey = Array.from({ length: exercisesArr.length }, (_, k) => k);
  // order of first superset

  const selectedExercises = exercisesArrWithOrderKeys.filter((item) => exercisesToGroup.includes(item.id));
  const orderedExercisesToGroup = sortBy(selectedExercises, ["orderKey", "superSetId", "createdAt"]);
  const takenNumbersOfSuperSet = uniqBy(
    exercisesArrWithOrderKeys.filter((item) => item.superSetId),
    (item) => item.superSetId,
  ).map((item) => item.superSetId);
  const numberOfSuperSet = takenNumbersOfSuperSet.length;

  const getAvailableNumberOfSeries = () => {
    if (!takenNumbersOfSuperSet.length) {
      return [1];
    }
    const availableSeriesNumber: number[] = [];

    for (let i = 1; i <= takenNumbersOfSuperSet.length + 1; i++) {
      if (!takenNumbersOfSuperSet.includes(i)) {
        availableSeriesNumber.push(i);
      }
    }
    return availableSeriesNumber;
  };

  const alreadyBelongToSuperSet = orderedExercisesToGroup.filter((item) => item.superSetId);
  const superSetId = alreadyBelongToSuperSet.length
    ? alreadyBelongToSuperSet[0]?.superSetId
    : getAvailableNumberOfSeries()[0] || numberOfSuperSet + 1;

  if (!orderedExercisesToGroup[0]) {
    return exercises;
  }

  const { numberOfSeries } = orderedExercisesToGroup[0];

  const orderOfFirstExerciseFromSuperSet = orderedExercisesToGroup[0].orderKey ?? 0;
  const numberOfExercisesToGroup = orderedExercisesToGroup.length;

  const reorderSuperset: ExerciseInWorkoutWithOrderKey[] = orderedExercisesToGroup.map((item, index) => {
    const updatedSet = formatExerciseSet({ ...item, numberOfSeries });
    return {
      ...item,
      ...updatedSet,
      orderKey: orderOfFirstExerciseFromSuperSet + index,
      superSetId,
    };
  });

  const findIndexOrFirstExercise = ordersKey.findIndex((key) => key === orderOfFirstExerciseFromSuperSet);
  const usedOrdersKey = ordersKey.slice(findIndexOrFirstExercise, findIndexOrFirstExercise + numberOfExercisesToGroup);
  const restOrdersKey = ordersKey.filter((item) => !usedOrdersKey.includes(item));
  const reorderedRestExercises: ExerciseInWorkoutWithOrderKey[] = sortBy(exercisesArr, "orderKey")
    .filter((item) => !exercisesToGroup.includes(item.id))
    .map((item, index) => ({
      ...item,
      orderKey: restOrdersKey[index] ?? restOrdersKey.length + 1,
    }));

  if (toArray) {
    return sortBy(reorderSuperset.concat(reorderedRestExercises), "orderKey").map(({ orderKey, ...rest }) => rest);
  }
  const reorderSupersetObj = keyBy(reorderSuperset, "id");

  const reorderdRestExercisesObj = keyBy(reorderedRestExercises, "id");
  return {
    ...reorderSupersetObj,
    ...reorderdRestExercisesObj,
  };
};

export default groupInSuperSet;
