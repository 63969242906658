import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { SURVEYS_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = {
  surveyTemplateId: string;
};

export const archiveSurveyTemplate = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${SURVEYS_TEMPLATES_REDUCER_NAME}/archiveSurveyTemplate`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("survey_template").update({ archived: true }).eq("id", payload.surveyTemplateId);

    if (error) {
      return rejectWithValue(error.message);
    }

    return payload.surveyTemplateId;
  },
);
