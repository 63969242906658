import React from "react";
import { Card, Col, Row, Tabs } from "antd";

import BotsConfig from "~/modules/Automation/BotsConfig/BotsConfig";
import AutomationList from "~/modules/Automation/ProgramAutomation/AutomationList";
import MessageTemplatesList from "./MessageTemplates/MessageTemplatesList";

const Automation = () => {
  return (
    <Row className="p-6">
      <Col xs={24}>
        <Card title="Automatyzacje" className="shadow">
          <Tabs>
            <Tabs.TabPane tab="Automatyzacje programu" key="programAutomation">
              <AutomationList />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Konfiguracja botów" key="botsConfig">
              <BotsConfig />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Szablony wiadomości" key="messagesTemplates">
              <MessageTemplatesList />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default Automation;
