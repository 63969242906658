import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import { type SupplementsTemplateWithCreator } from "@fitness-app/data-models/entities/SupplementTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { SUPPLEMENTS_TEMPLATES_REDUCER_NAME } from "../types";
import { fetchSupplementsTemplates } from "./fetchSupplementsTemplates";

type Payload = SupplementsTemplateWithCreator;

export const duplicateSupplementsTemplates = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${SUPPLEMENTS_TEMPLATES_REDUCER_NAME}/duplicateSupplementsTemplate`,
  async (template, { rejectWithValue, dispatch, getState, extra: { db, analytics } }) => {
    const { data } = getState().user;
    const { creator, ...rest } = template;
    const duplicated = {
      ...rest,
      createdBy: data?.id || null,
      id: uuid(),
      name: `${rest.name} Kopia`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const { error } = await db.from("supplements_template").insert(duplicated);

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("duplicate_supplements_template");

    void dispatch(fetchSupplementsTemplates());
  },
);
