import { type Database } from "@fitness-app/supabase";

import {
  type ScheduledEmailIntegrationAutomation,
  type ScheduledMessagesAutomation,
  type ScheduledPopUpMessagesAutomation,
  type ScheduledSurveyAutomation,
} from "./AutomationTemplate";
import { type FileMessageContent } from "./Chat";
import { type DietaryPreference } from "./MealsPlan";
import { type Attachment } from "./shared";
import {
  type ExceptionWeek,
  type ProgramSchedule,
  type ProgramWorkout,
  type TrainingProgram,
  type TrainingProgramDetails,
} from "./TrainingProgram";

export type ProgramAutomationEntity = Database["public"]["Tables"]["program_automation"]["Row"];
export type ProgramAutomationTaskEntity = Database["public"]["Tables"]["program_automation_task"]["Row"];

export type WeekdaysNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type WeekdayNumberToString = `${WeekdaysNumber}`;

export type DayContent = {
  id: string;
  items: (ScheduledMessagesAutomation | ScheduledEmailIntegrationAutomation | ScheduledSurveyAutomation)[];
};

export type AutomationWeek = Partial<Record<WeekdayNumberToString, DayContent>>;
export type AutomationWeekWithWorkouts = Record<
  WeekdayNumberToString,
  DayContent & { workouts?: ProgramWorkout[] | null }
>;
export type WeekNumber = string;

export interface WorkoutRoutine extends TrainingProgram {
  duration: number;
  id: string;
  programId?: string;
  schedule: {
    exceptionWeeks: Record<string, ExceptionWeek | null>;
    weekSchedule: ProgramSchedule;
  } | null;
  startWeek: number;
  endWeek: number;
  programWorkouts: TrainingProgramDetails;
  additionalComment: string;
  routineId: string;
  keepOldTrainingWithoutStatus?: boolean;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export type ScheduledItems = {
  id: string;
  sendDate: string;
  items: (ScheduledMessagesAutomation | ScheduledEmailIntegrationAutomation | ScheduledSurveyAutomation)[];
};

export enum ScheduledNutritionType {
  ByCurrentWeight = "byCurrentWeight",
  AssignedByTrainer = "assignedByTrainer",
}

export type NutritionByType = {
  [DietaryPreference.NoPreference]: { id: string; name: string };
} & { [key in DietaryPreference]?: { id: string; name: string } };

export interface MiddleRange {
  from: number;
  to: number;
  nutrition: NutritionByType;
}

export interface StartRange {
  from: null;
  to: number;
  nutrition: NutritionByType;
}

export interface EndRange {
  from: number;
  to: null;
  nutrition: NutritionByType;
}

export interface ScheduledNutritionByCurrentWeight {
  startWeek: number;
  endWeek: number | null;
  additionalAttachments: Attachment[];
  additionalComment: string;
  id: string;
  type: ScheduledNutritionType.ByCurrentWeight;
  sendDate: {
    hours: number;
    minutes: number;
    week: number;
    day: number;
  } | null;
  ranges: [StartRange, ...MiddleRange[], EndRange];
}

export interface ScheduledNutritionAssignedByTrainer {
  startDate: string | null;
  endDate: string | null;
  id: string;
  type: ScheduledNutritionType.AssignedByTrainer;
  mealsPlanId: string;
  mealsPlanName: string;
}

export type ScheduledNutrition = ScheduledNutritionByCurrentWeight | ScheduledNutritionAssignedByTrainer;

export interface AutomationChatConfiguration {
  createMarketingChannel: boolean;
  sendByDefaultMessageFromAutomationToMarketingChannel: boolean | null;
  marketingChannelTitle: string | null;
}

export interface ProgramAutomation
  extends Omit<
    ProgramAutomationEntity,
    "schedule" | "scheduledWorkoutsRoutine" | "scheduledNutritionPlan" | "welcomeMessages" | "chatConfiguration"
  > {
  schedule: Record<WeekNumber, AutomationWeek>;
  scheduledWorkoutsRoutine: WorkoutRoutine[];
  scheduledNutritionPlan?: ScheduledNutritionByCurrentWeight[];
  welcomeMessages: (string | FileMessageContent)[];
  chatConfiguration: null | AutomationChatConfiguration;
}

export interface ProgramAutomationWithInitialSurvey extends ProgramAutomation {
  initialSurvey?: null | { name: string; title: string; id: string };
  additionalSurvey?: null | { name: string; title: string; id: string };
}

export enum ProgramAutomationTaskStatus {
  Upcoming = "upcoming",
  Done = "done",
  Rejected = "rejected",
  Canceled = "canceled",
}

export type UserId = string;

export interface ProgramAutomationTask extends Omit<ProgramAutomationTaskEntity, "task"> {
  task:
    | ScheduledMessagesAutomation
    | ScheduledEmailIntegrationAutomation
    | ScheduledNutrition
    | ScheduledSurveyAutomation
    | ScheduledPopUpMessagesAutomation;
  status: ProgramAutomationTaskStatus;
  addedBy: "productAutomation" | UserId;
}
