import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  searchTerm: string;
};

export const searchDish = createAsyncThunk<
  { data: Dish[]; page: number; totalPages: number },
  Payload,
  AsyncThunkCreator<string>
>(
  `${NUTRITION_REDUCER_NAME}/searchDish`,
  async ({ searchTerm }, { rejectWithValue, getState, extra: { dishesSearchIndex } }) => {
    try {
      const { dishesPerPage } = getState().nutrition;

      const response = await dishesSearchIndex.search(searchTerm, { limit: dishesPerPage });

      return { data: response.hits, page: 1, totalPages: 1 };
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
