import { createAsyncThunk } from "@reduxjs/toolkit";
import intersectionBy from "lodash.intersectionby";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { markChatMessagesAsReadSuccess } from "../reducer";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  channelId: string;
  messages: { id: string; createdAt?: string }[];
};

export const markChatMessagesAsRead = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/markChatMessagesAsRead`,
  async ({ messages, channelId }, { rejectWithValue, getState, dispatch, extra: { db, auth } }) => {
    await getLoggedUser(auth);
    const unread = getState().chat.unreadMessages;
    if (!unread) {
      return;
    }
    const { _perChannel, unreadMessages } = unread;
    const unreadPerChannel = _perChannel[channelId] || [];
    const messagesToMark = intersectionBy(unreadMessages, messages, "id");

    if (!unreadPerChannel.length || !messagesToMark.length) {
      dispatch(markChatMessagesAsReadSuccess({ messages: messages.map((item) => item.id), channelId }));

      return;
    }

    const { error } = await db.rpc("update_unread_messages", {
      channel_id: channelId,
      messages_to_mark: messagesToMark.map((item) => item.id),
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    dispatch(markChatMessagesAsReadSuccess({ messages: messages.map((item) => item.id), channelId }));
  },
);
