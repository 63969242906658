import { type AutomationTemplate } from "@fitness-app/data-models/entities/AutomationTemplate";

import { type RequestStatus } from "../../../enums/requestStatus";

export interface AutomationTemplatesReducer {
  status: null | RequestStatus;
  listData: AutomationTemplate[];
  error: null | string;
}

export const TEMPLATES_REDUCER_NAME = "automationTemplates";
