import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  SurveyAction,
  SurveyActionOwner,
  SurveyStatus,
  type ClientSurvey,
} from "@fitness-app/data-models/entities/ClientSurvey";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";

type Payload = {
  survey: ClientSurvey;
};

export const markSurveyAsOpened = createAsyncThunk<ClientSurvey, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/markSurveyAsOpened`,
  async (payload, { rejectWithValue, extra: { db, auth, errorTrackingService, analytics } }) => {
    const authUser = await getLoggedUser(auth);

    const survey: Partial<ClientSurvey> = {
      status: SurveyStatus.Open,
      updatedAt: new Date().toISOString(),
      logs: [
        ...(payload.survey.logs || []),
        {
          date: new Date().toISOString(),
          action: SurveyAction.OpenByClient,
          actionOwner: SurveyActionOwner.Client,
          actionOwnerId: authUser.id,
        },
      ],
    };

    const { error, data } = await db
      .from("client_survey")
      .update(survey)
      .eq("id", payload.survey.id)
      .select("*")
      .single<ClientSurvey>();

    if (error) {
      errorTrackingService?.recordError(error, "markSurveyAsOpened");
      return rejectWithValue(error.message);
    }

    analytics.track("mark_survey_as_opened");

    return data;
  },
);
