import React, { useCallback, useEffect, useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Modal,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  type MenuProps,
  type TableProps,
} from "antd";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { programsActions, RequestStatus } from "@fitness-app/app-store";
import { type TrainingProgramWithCreator } from "@fitness-app/data-models/entities/TrainingProgram";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useTagOptions } from "~/hooks/useTagOptions";
import { useTeamMembers } from "~/modules/Team/hooks/useTeamMembers";
import ProgramForm from "~/modules/TrainingPrograms/components/ProgramForm/ProgramForm";
import { type ProgramFormModel } from "~/modules/TrainingPrograms/components/ProgramForm/types";
import { ProgramSearch } from "~/modules/TrainingPrograms/tabs/ProgramSearch";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ProgramsList = () => {
  const { t } = useTranslation(["workouts", "common"]);
  const [showProgramForm, setShowProgramForm] = useState(false);
  const { listStatus, list, pagination, filters } = useAppSelector((store) => store.programs);
  const dispatch = useAppDispatch();
  const { filters: tagsFilters } = useTagOptions("trainingProgramTags");
  const [modal, contextHolder] = Modal.useModal();

  const loggedUser = useAppSelector((store) => store.user.data);
  const [editedModel, setEditedModel] = useState<(ProgramFormModel & { id: string }) | null>(null);
  const navigate = useNavigate();
  const { isTrainer, userId } = useUserRole();
  const { createTrainerOptions } = useTeamMembers();
  const { isOwner } = useUserClaims();

  const [savingProgram, onSuccess, onFailure, onStart] = useEntityChange(() => {
    setShowProgramForm(false);
    setEditedModel(null);
  });

  const [duplicatingProgram, onSuccessDuplicated, onFailureDuplicated, onStartDuplicating] = useEntityChange();

  useEffect(() => {
    void dispatch(programsActions.fetchPrograms());
  }, [dispatch]);

  const toggleSharedState = async (programId: string, shared: boolean) => {
    await dispatch(
      programsActions.updateProgram({
        programId,
        program: {
          shared,
          updatedAt: new Date().toISOString(),
        },
      }),
    ).unwrap();
  };

  const onSubmitProgramForm = async (model: ProgramFormModel) => {
    onStart();
    try {
      if (editedModel) {
        await dispatch(
          programsActions.updateProgram({
            programId: editedModel.id,
            program: {
              ...model,
              updatedAt: new Date().toISOString(),
            },
          }),
        ).unwrap();
      } else {
        const programId = await dispatch(
          programsActions.addProgram({
            program: {
              ...model,
              shared: isTrainer,
              tags: model.tags || [],
              id: uuid(),
              createdBy: loggedUser?.id || null,
              updatedAt: new Date().toISOString(),
              createdAt: new Date().toISOString(),
              archived: false,
            },
          }),
        ).unwrap();

        navigate(programId);
      }

      onSuccess();
    } catch {
      onFailure();
    }
  };

  const duplicateProgram = async (program: TrainingProgramWithCreator) => {
    onStartDuplicating(program.id, t<string>("program.duplicating"));
    try {
      await dispatch(programsActions.duplicateProgram(program)).unwrap();
      onSuccessDuplicated();
    } catch {
      onFailureDuplicated();
    }
  };

  const getMenuItems = useCallback(
    (row: TrainingProgramWithCreator): MenuProps["items"] => [
      {
        label: t("common:button.duplicate"),
        key: "duplicate",
        icon: <CopyOutlined />,
        onClick: () => {
          void duplicateProgram(row);
        },
      },
      {
        label: t("common:button.edit"),
        key: "edit",
        icon: <EditOutlined />,
        onClick: () => {
          setEditedModel({
            name: row.name,
            level: row.level || null,
            type: row.type || null,
            comment: row.comment || "",
            id: row.id,
            tags: row.tags || [],
          });
          setShowProgramForm(true);
        },
      },
      {
        label: t("common:button.archive"),
        key: "delete",
        icon: <DeleteOutlined />,
        danger: true,

        onClick: () => {
          void modal.confirm({
            title: t("exercisesTable.deleteWarning"),
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { danger: true },
            okText: t("common:button.archive"),
            onOk() {
              void dispatch(
                programsActions.updateProgram({
                  programId: row.id,
                  program: { archived: true },
                }),
              );
            },
          });
        },
        disabled: !isTrainer && row.createdBy !== userId,
      },
    ],
    [t, isTrainer, userId],
  );

  const handleChange: TableProps<TrainingProgramWithCreator>["onChange"] = (tablePagination, tableFilters) => {
    if (!isOwner) {
      if (!isEqual(filters.tags, tableFilters.tags)) {
        void dispatch(
          programsActions.fetchPrograms({
            tags: tableFilters.tags as string[],
            page: 1,
          }),
        );
      } else if (tablePagination.current && tablePagination.current !== pagination.currentPage) {
        void dispatch(programsActions.fetchPrograms({ page: tablePagination.current }));
        return;
      }
    } else {
      if (!isEqual(filters.addedBy ? [filters.addedBy] : null, tableFilters.createdBy)) {
        const newTrainerFilter = tableFilters.createdBy?.[0] || null;
        void dispatch(
          programsActions.fetchPrograms({
            addedBy: (newTrainerFilter as string) || null,
            page: 1,
          }),
        );
      } else if (!isEqual(filters.tags, tableFilters.tags)) {
        void dispatch(
          programsActions.fetchPrograms({
            tags: tableFilters.tags as string[],
            addedBy: filters.addedBy || null,
            page: 1,
          }),
        );
      } else if (tablePagination.current && tablePagination.current !== pagination.currentPage) {
        void dispatch(programsActions.fetchPrograms({ page: tablePagination.current }));
        return;
      }
    }
  };

  return (
    <Card
      extra={
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowProgramForm(true)}>
          {t("program.addNewProgram")}
        </Button>
      }
    >
      <Space size={12}>
        <ProgramSearch />
        <Space className="my-4">
          <Switch
            checked={filters.addedBy === userId}
            onChange={(active) => {
              void dispatch(
                programsActions.fetchPrograms({
                  addedBy: active ? userId : null,
                  page: 1,
                }),
              );
            }}
          />
          <Typography.Text>Wyświetl tylko moje plany</Typography.Text>
        </Space>
      </Space>

      <Table
        dataSource={list}
        loading={listStatus === RequestStatus.FETCHING}
        pagination={{
          pageSize: pagination.perPage,
          total: pagination.totalPages * pagination.perPage,
          current: pagination.currentPage,
          showSizeChanger: false,
        }}
        rowKey="id"
        scroll={{ x: true }}
        onChange={handleChange}
      >
        <Table.Column<TrainingProgramWithCreator> title={t("program.name")} dataIndex="name" key="name" />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.type")}
          dataIndex="type"
          key="type"
          render={(type) => t(`programType.${type}`)}
          // filters={programTypeOptions.map((part) => ({
          //   text: part.label,
          //   value: part.value,
          // }))}
          // onFilter={(value, record) => (record.type ? record.type.includes(value as TrainingProgramTypeEnum) : false)}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.level")}
          dataIndex="level"
          key="level"
          render={(level) => t(`programLevel.${level}`)}
          // filters={programLevelOptions.map((part) => ({
          //   text: part.label,
          //   value: part.value,
          // }))}
          // onFilter={(value, record) =>
          //   record.level ? record.level.includes(value as TrainingProgramLevelEnum) : false
          // }
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.tags")}
          dataIndex="tags"
          key="tags"
          filterMultiple
          filterSearch
          filters={tagsFilters}
          filteredValue={filters.tags}
          render={(tags: string[]) =>
            tags?.length ? (
              <Space direction="vertical">
                {tags.map((tag, i) => (
                  <Tag key={`${tag}-${i}`}>{tag}</Tag>
                ))}
              </Space>
            ) : (
              "-"
            )
          }
        />

        <Table.Column<TrainingProgramWithCreator>
          title={t("program.createdBy")}
          dataIndex="createdBy"
          key="createdBy"
          {...(!isOwner
            ? {}
            : {
                filterMultiple: false,
                filterSearch: true,
                filteredValue: filters.addedBy ? [filters.addedBy] : null,
                filters: createTrainerOptions(true),
              })}
          render={(_createdBy: string, row) => {
            if (row.creator) {
              return `${row.creator.firstName} ${row.creator.lastName}`;
            }
            return "-";
          }}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.shared")}
          dataIndex="shared"
          key="shared"
          render={(shared: boolean, row) => {
            return (
              <Switch
                unCheckedChildren={<EyeInvisibleOutlined />}
                checkedChildren={<ShareAltOutlined />}
                disabled={!isTrainer && row.createdBy !== userId}
                defaultChecked={shared}
                onChange={(change) => {
                  void toggleSharedState(row.id, change);
                }}
              />
            );
          }}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.options")}
          key="action"
          align="left"
          render={(_, row) => (
            <>
              <Dropdown.Button
                menu={{ items: getMenuItems(row) }}
                loading={duplicatingProgram === row.id}
                onClick={() => {
                  navigate(row.id);
                }}
              >
                {t("common:button.details")}
              </Dropdown.Button>
              {contextHolder}
            </>
          )}
        />
      </Table>
      <ModalForm
        title={editedModel ? t("program.editProgram") : t("program.addNewProgram")}
        loading={!!savingProgram}
        open={showProgramForm}
        onCancel={() => {
          setShowProgramForm(false);
          setEditedModel(null);
        }}
      >
        <ProgramForm onSubmit={onSubmitProgramForm} model={editedModel} />
      </ModalForm>
    </Card>
  );
};

export default ProgramsList;
