import { UserLang, UserRole, type UserLangEnum } from "@fitness-app/data-models/entities/UserProfile";

import { type AppThunk } from "../../../index";
import { signUpFailed, signUpStarted, signUpSuccess } from "../reducer";

export interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  termsAndPrivacyPolicy: boolean;
  lang: UserLangEnum;
  timezone: string;
  ip: string | null;
  ref: string | null;
}

export const signUpUser =
  (
    { email, password, lastName, firstName, termsAndPrivacyPolicy, lang, timezone, ip }: RegisterData,
    callback?: () => void,
  ): AppThunk =>
  async (dispatch, _, { db, analytics }) => {
    dispatch(signUpStarted());
    try {
      const res = await db.auth.signUp({
        email: email.toUpperCase(),
        password,
        options: { data: { firstName, lastName, lang, ip, timezone } },
      });
      if (!res.data.user) {
        throw new Error("Error when creating new account");
      }

      await db.from("user_profile").upsert({
        id: res.data.user.id,
        firstName,
        lastName,
        termsAndPrivacyPolicy,
        lang: lang ? (lang.toUpperCase() as UserLangEnum) : UserLang.PL,
        email: email.toLowerCase(),
        timezone,
        ip,
      });
      analytics.identify(res.data.user.id, { email, name: `${firstName} ${lastName}`, role: UserRole.TRAINER });
      analytics.track("User created account", {
        role: UserRole.TRAINER,
        timezone,
        lang,
      });
      dispatch(signUpSuccess());
      if (callback) {
        callback();
      }
    } catch (e) {
      dispatch(signUpFailed("Error when creating new account"));
    }
  };
