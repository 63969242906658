import { createAsyncThunk } from "@reduxjs/toolkit";
import type Stripe from "stripe";
import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

export const cancelSubscriptionAtCurrentPeriodEnd = createAsyncThunk<
  Stripe.Response<Stripe.BillingPortal.Session>,
  ActionParams<
    { authorId: string; productId: string; isOwner?: boolean; clientEmail: string },
    Stripe.Response<Stripe.BillingPortal.Session>
  >,
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENT_REDUCER}/cancelSubscriptionAtCurrentPeriodEnd`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { productApi } }) => {
    try {
      const res = await productApi.post<Stripe.Response<Stripe.BillingPortal.Session>>("/cancel-subscription", payload);
      onSuccess?.(res.data);

      return res.data;
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
