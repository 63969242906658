import groupBy from "lodash.groupby";
import toPairs from "lodash.topairs";
import zipObject from "lodash.zipobject";
import { createSelector } from "reselect";

import { type ClientPhoto } from "@fitness-app/data-models/entities/Measurement";

import { type AppStore } from "../../../index";

export const getPhotos = (store: AppStore) => store.traineeMeasurements.photos;

export const getGroupedPhotosByDay = createSelector(
  [getPhotos],
  (
    photos,
  ): {
    date: string;
    photos: ClientPhoto[];
  }[] => {
    if (!photos.length) {
      return [];
    }

    const groupedByDate = toPairs(
      groupBy(
        photos.filter((photo) => photo.id !== "-1"),
        "eventDate",
      ),
    );

    return groupedByDate.map(
      (item) => zipObject(["date", "photos"], item) as unknown as { date: string; photos: ClientPhoto[] },
    );
  },
);
