import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENTS_REDUCER_NAME } from "../types";

export const fetchProductClients = createAsyncThunk<
  { isLastPage: boolean; list: ProductClient[]; totalPages: number; page: number },
  { productId: string; page?: number } | { clientEmail: string; page?: number },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENTS_REDUCER_NAME}/fetchProductClients`,
  async (payload, { rejectWithValue, extra: { db }, getState }) => {
    const { listSize, filters, searchTerm } = getState().productClients;

    const currentPage = payload?.page ?? 1;

    const start = (currentPage - 1) * listSize;
    const stop = currentPage * listSize - 1;

    let query = db.from("product_client").select("*", { count: "exact" }).order("createdAt", { ascending: false });

    if ("productId" in payload) {
      query = query
        .eq("productId", payload.productId)
        .in("status", filters.statuses.length ? filters.statuses : ["active", "archived"]);
    } else {
      query = query.eq("email", payload.clientEmail);
    }

    if (searchTerm) {
      query = query.or(`email.ilike.%${searchTerm}%,lastName.ilike.%${searchTerm}%`);
    }

    const { error, data, count } = await query.range(start, stop).limit(listSize).returns<ProductClient[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return {
      isLastPage: false,
      page: currentPage,
      list: data,
      totalPages: count ? Math.ceil(count / listSize) : 0,
    };
  },
);
