import { type AppThunk } from "../../../index";
import { unsubscribeFromProductClient as action } from "../reducer";

export const unsubscribeFromProductClient =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    if (subscriptions.realtimeProductClientChannel) {
      await db.removeChannel(subscriptions.realtimeProductClientChannel);
      subscriptions.productClientChannelName = null;
    }
    dispatch(action());
  };
