import { type SurveyTemplateWithCreator } from "@fitness-app/data-models/entities/SurveyTemplate";

import { type RequestStatus } from "../../../enums/requestStatus";

export const SURVEYS_TEMPLATES_REDUCER_NAME = "surveysTemplates";

export interface SurveysTemplatesReducer {
  list: SurveyTemplateWithCreator[];
  listStatus: null | RequestStatus;
  selectedSurvey: null | SurveyTemplateWithCreator;
  selectedSurveyStatus: null | RequestStatus;
  creators: { label: string; value: string }[];
}
