import React, { Fragment, type FunctionComponent } from "react";
import {
  CheckOutlined,
  ClockCircleOutlined,
  FileSearchOutlined,
  InfoOutlined,
  TagOutlined,
  VideoCameraOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, message, Space } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { notificationsActions } from "@fitness-app/app-store";
import {
  NotificationStatus,
  NotificationType,
  type ClientNotifications,
  type OwnerNotifications,
  type TrainerNotifications,
} from "@fitness-app/data-models/entities/Notification";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  notification: ClientNotifications | OwnerNotifications | TrainerNotifications;
  closeDrawer: () => void;
}

type Props = OwnProps;

const alertType = {
  [NotificationType.ClientPurchaseProduct]: "success",
  [NotificationType.ClientTerminated]: "warning",
  [NotificationType.ProductSubscriptionEnd]: "error",
  [NotificationType.ProductAccessPeriodEnd]: "error",
  [NotificationType.ProductPaymentRequiredAction]: "warning",
  [NotificationType.ClientRenewedSubscription]: "success",
  [NotificationType.ClientSubscriptionPlanUpdated]: "success",
  [NotificationType.ClientRenewedAccessPeriod]: "success",
  [NotificationType.ClientsImportFinished]: "success",
  [NotificationType.ClientResumeSubscriptionAtPeriodEnd]: "success",
  [NotificationType.ClientCancelSubscriptionAtPeriodEnd]: "warning",
  [NotificationType.ProductAccessPeriodEndingIn14Days]: "warning",
  [NotificationType.ProductAccessPeriodEndingIn7Days]: "warning",
  [NotificationType.ProductAccessPeriodEndingIn3Days]: "warning",
  [NotificationType.ProductAccessPeriodEndingIn1Day]: "warning",
  [NotificationType.VideoBeenUploaded]: "info",
  [NotificationType.ClientSurveyAnswersUpdated]: "info",
  [NotificationType.ClientSurveyAnswered]: "info",
  [NotificationType.ClientAddedWeightMeasurement]: "info",
  [NotificationType.ClientAddedBodyMeasurements]: "info",
  [NotificationType.ClientAddedBodyPhotos]: "info",
  [NotificationType.ClientRateWorkout]: "info",
  [NotificationType.ClientProgramEndingIn1Day]: "info",
  [NotificationType.ClientProgramEndingIn1Week]: "info",
  [NotificationType.ClientSentVideoRecording]: "info",
} as const;

const infoStatusColorMapper = {
  error: "bg-red-600",
  warning: "bg-yellow-600",
  info: "bg-blue-500",
  success: "bg-green-500",
};

const alertIcon = {
  [NotificationType.ClientPurchaseProduct]: TagOutlined,
  [NotificationType.ClientRenewedSubscription]: TagOutlined,
  [NotificationType.ClientRenewedAccessPeriod]: TagOutlined,
  [NotificationType.ClientSubscriptionPlanUpdated]: TagOutlined,
  [NotificationType.ClientTerminated]: WarningOutlined,
  [NotificationType.ProductPaymentRequiredAction]: WarningOutlined,
  [NotificationType.ProductSubscriptionEnd]: WarningOutlined,
  [NotificationType.ProductAccessPeriodEnd]: WarningOutlined,
  [NotificationType.ClientsImportFinished]: FileSearchOutlined,
  [NotificationType.ClientResumeSubscriptionAtPeriodEnd]: TagOutlined,
  [NotificationType.ClientCancelSubscriptionAtPeriodEnd]: WarningOutlined,
  [NotificationType.ProductAccessPeriodEndingIn14Days]: TagOutlined,
  [NotificationType.ProductAccessPeriodEndingIn7Days]: TagOutlined,
  [NotificationType.ProductAccessPeriodEndingIn3Days]: TagOutlined,
  [NotificationType.ProductAccessPeriodEndingIn1Day]: TagOutlined,
  [NotificationType.VideoBeenUploaded]: VideoCameraOutlined,
  [NotificationType.ClientSurveyAnswersUpdated]: InfoOutlined,
  [NotificationType.ClientSurveyAnswered]: InfoOutlined,
  [NotificationType.ClientAddedWeightMeasurement]: InfoOutlined,
  [NotificationType.ClientAddedBodyMeasurements]: InfoOutlined,
  [NotificationType.ClientAddedBodyPhotos]: InfoOutlined,
  [NotificationType.ClientSentVideoRecording]: InfoOutlined,
  [NotificationType.ClientRateWorkout]: InfoOutlined,
  [NotificationType.ClientProgramEndingIn1Day]: InfoOutlined,
  [NotificationType.ClientProgramEndingIn1Week]: InfoOutlined,
} as const;

const NotificationItem: FunctionComponent<Props> = ({ notification, closeDrawer }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const performingAction = useAppSelector((store) => store.notifications.performingAction);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const redirectToNotification = () => {
    const { data, type, status } = notification;

    if (status === NotificationStatus.UNREAD) {
      void dispatch(notificationsActions.markAsRead(notification.id));
    }

    switch (type) {
      case NotificationType.VideoBeenUploaded:
        navigate(`/dashboard/${data.productId}/course/lessons/${notification.data.lessonId}`);
        break;
      case NotificationType.ClientPurchaseProduct:
      case NotificationType.ClientRenewedSubscription:
      case NotificationType.ClientRenewedAccessPeriod:
      case NotificationType.ClientResumeSubscriptionAtPeriodEnd:
      case NotificationType.ClientCancelSubscriptionAtPeriodEnd:
      case NotificationType.ClientSubscriptionPlanUpdated:
        navigate(
          `/products/${data.productId}/${
            notification.connectedClient?.email && "isFree" in data && !data.isFree
              ? `clients/${notification.connectedClient?.email}`
              : "clients"
          }`,
        );
        break;
      case NotificationType.ClientSurveyAnswersUpdated:
      case NotificationType.ClientSurveyAnswered:
      case NotificationType.ClientAddedWeightMeasurement:
      case NotificationType.ClientAddedBodyMeasurements:
      case NotificationType.ClientAddedBodyPhotos:
      case NotificationType.ClientRateWorkout:
      case NotificationType.ClientProgramEndingIn1Week:
      case NotificationType.ClientProgramEndingIn1Day:
        navigate(`/trainee/details/${notification.connectedClient?.traineeId}`);
        break;
      case NotificationType.ClientSentVideoRecording:
        navigate(`/trainee/details/${notification.connectedClient?.traineeId}?tab=measurements&subTab=videos`);
        break;
      case NotificationType.ClientsImportFinished:
        void message.warning(t("common:availableSoon"));
    }

    closeDrawer();

    return null;
  };

  const Icon = alertIcon[notification.type] ?? Fragment;

  return (
    <li
      className={cn(
        "cursor-pointer border-b px-6 py-4 hover:bg-gray-100",
        notification.status === NotificationStatus.UNREAD && "bg-gray-100/70",
      )}
      onClick={redirectToNotification}
    >
      <div className="flex space-x-3">
        <Badge dot={notification.status === NotificationStatus.UNREAD}>
          <Avatar
            className={infoStatusColorMapper[alertType[notification.type]]}
            icon={<Icon />}
            src={notification.connectedClient?.avatarUrl}
          />
        </Badge>

        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">
              {t(
                `notificationType.${
                  notification.type === NotificationType.ClientPurchaseProduct && notification.data?.isFree
                    ? "client_subscribe_product"
                    : notification.type
                }`,
                {
                  name:
                    notification.data && "lessonName" in notification.data
                      ? notification.data.lessonName
                      : `${notification.connectedClient?.firstName} ${notification.connectedClient?.lastName}`,
                  productName:
                    notification.data && "productName" in notification.data ? notification.data.productName ?? "" : "",
                  date:
                    notification.data && "periodEnd" in notification.data
                      ? dayjs.unix(notification.data.periodEnd).format("DD.MM.YYYY HH:mm")
                      : notification.data && "endDate" in notification.data
                      ? dayjs(notification.data.endDate).format("DD.MM.YYYY")
                      : undefined,
                },
              )}
            </h3>
            <p className="text-sm text-gray-500">
              {notification.status === NotificationStatus.UNREAD && (
                <Space>
                  <Button
                    size="small"
                    loading={performingAction?.notificationId === notification.id}
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      void dispatch(notificationsActions.markAsRead(notification.id));
                    }}
                  />
                </Space>
              )}
            </p>
          </div>
          <p className="text-sm text-gray-500">
            <Space size={4}>
              <ClockCircleOutlined /> <span>{dayjs(notification.createdAt).format("DD.MM HH:mm")}</span>
            </Space>
          </p>
        </div>
      </div>
    </li>
  );
};

export default NotificationItem;
