import dayjs, { type Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { type ClientDayActivity, type ClientMonthActivity } from "@fitness-app/data-models/entities/ClientActivity";

import { type Level } from "~/components/ActivityCalendar/types";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const getLevel = (count: number): Level => {
  if (count === 0) {
    return 0;
  }
  if (count <= 2) {
    return 1;
  }
  if (count <= 5) {
    return 2;
  }
  if (count <= 15) {
    return 3;
  }
  return 4;
};

const countDailyScore = (log: ClientDayActivity | null): { level: Level; count: number } => {
  if (!log) {
    return { level: 0, count: 0 };
  }

  const { mealTracked, messagesCount, logInCount, exercisesCompleted, actions } = log;

  const countForLevel = mealTracked + messagesCount + logInCount + exercisesCompleted * 1.5 + actions.length * 2;

  const count = mealTracked + messagesCount + logInCount + exercisesCompleted + actions.length;

  return {
    count,
    level: getLevel(countForLevel),
  };
};
export const getActivitiesDate = (
  range: null | [Dayjs | null, Dayjs | null],
  clientActivitiesLog: ClientMonthActivity[],
) => {
  if (range === null) {
    return [];
  }

  const [startDate, endDate] = range;

  if (!startDate || !endDate) {
    return [];
  }

  let daysWithLogs: Record<string, ClientDayActivity> = {};

  clientActivitiesLog.forEach((log) => {
    daysWithLogs = { ...daysWithLogs, ...log.days };
  });

  const activitiesRange: { count: number; date: string; level: Level }[] = [];

  let counter = 0;
  const diff = Math.ceil(endDate.endOf("day").diff(startDate.startOf("day"), "day", true));

  do {
    const date = startDate.add(counter, "day").format("YYYY-MM-DD");
    activitiesRange.push({
      date: date,
      ...countDailyScore(daysWithLogs[date] || null),
    });
    counter++;
  } while (counter !== diff);

  return activitiesRange;
};

export enum DefaultUnits {
  Body = "cm",
  Weight = "kg",
  Calories = "kcal",
  Nutrients = "g",
  BodyFat = "%",
}
