import React, { useEffect } from "react";
import { Card, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { traineeActions } from "@fitness-app/app-store";

import TraineeDevices from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSettings/TraineeDevices";
import TraineeNotifications from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSettings/TraineeNotifications";
import { useAppDispatch } from "~/store/initializeStore";

const TraineeSettings = () => {
  const { t } = useTranslation(["trainees", "common"]);
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [search] = useSearchParams();

  useEffect(() => {
    if (id) {
      void dispatch(traineeActions.fetchTraineeSettings({ id }));
    }
  }, [id]);

  return (
    <Card bordered={false} className="!shadow-none">
      <Tabs tabPosition="left" defaultActiveKey={search.get("settingsTab") || "notifications"}>
        <Tabs.TabPane tab={t("features.notifications")} key="notifications">
          <TraineeNotifications />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("settings.devices")} key="devices">
          <TraineeDevices />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default TraineeSettings;
