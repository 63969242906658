import {
  type ChatIncomingRealtimeEvents,
  type ChatMessage,
  type ChatOutgoingRealtimeEvents,
  type InitialChatStateEvent,
  type RoomInfo,
} from "@fitness-app/data-models/entities/Chat";

export const parseIncomingRealtimeMessage = (message: string, senderId: string): ChatIncomingRealtimeEvents => {
  const parsedMessage = JSON.parse(message) as ChatIncomingRealtimeEvents;

  return {
    ...parsedMessage,
    senderId,
  };
};

export const parseOutgoingRealtimeMessage = (message: ChatIncomingRealtimeEvents | InitialChatStateEvent) => {
  const transformed: ChatOutgoingRealtimeEvents = message;
  return JSON.stringify(transformed);
};

export const parseIncomingClientRealtimeMessage = (message: string): ChatOutgoingRealtimeEvents => {
  const parsedMessage = JSON.parse(message) as ChatOutgoingRealtimeEvents;

  return {
    ...parsedMessage,
  };
};

export const prepareInitialChatState = (messages: ChatMessage[], roomInfo: RoomInfo): InitialChatStateEvent => {
  return {
    messages,
    type: "initial",
    room: roomInfo,
  };
};
