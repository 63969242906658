import { type AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { type ClientActivityWithScoreAndTrainer } from "@fitness-app/data-models/entities/ClientActivity";
import {
  type TraineeStatus,
  type TraineeWithAssignedTrainerAndProduct,
} from "@fitness-app/data-models/entities/Trainee";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TRAINEES_REDUCER_NAME = "trainees";

export interface TraineesReducer {
  listStatus: null | RequestStatus;
  list: TraineeWithAssignedTrainerAndProduct[];
  page: number;
  totalPages: number;
  listSize: number;
  searchStatus: RequestStatus | null;
  searchTerm: string;
  filters: {
    currentAssignedTrainerFilter: string | null;
    currentStatusFilter: TraineeStatus[] | null;
    currentAccessType: AppAccessType[] | null;
    currentFilteredTags: string[];
  };
  activitiesMetadata: {
    page: number;
    totalPages: number;
    listSize: number;
    currentAssignedTrainerFilter: string | null;
    status: null | RequestStatus;
    fetchingNextPage: boolean;
  };
  traineesActivity: ClientActivityWithScoreAndTrainer[];
}
