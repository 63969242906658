import { type ProductAutomation } from "@fitness-app/data-models/entities/Automation";
import { getError } from "@fitness-app/utils";

import { type AppThunk } from "../../../index";
import {
  subscribeToProductAutomationFailed,
  subscribeToProductAutomationStarted,
  subscribeToProductAutomationSuccess,
  unsubscribeFromProductAutomation,
  updateProductAutomation,
} from "../reducer";
import { fetchProductAutomation } from "./fetchProductAutomation";

export const subscribeToProductAutomation =
  (productId: string): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    try {
      dispatch(subscribeToProductAutomationStarted());

      void dispatch(fetchProductAutomation(productId));

      if (subscriptions.productAutomationChannelName && subscriptions.realtimeProductAutomationChannel) {
        await db.removeChannel(subscriptions.realtimeProductAutomationChannel);
      }

      subscriptions.productAutomationChannelName = `product-automation-${productId}`;
      subscriptions.realtimeProductAutomationChannel = db.channel(subscriptions.productAutomationChannelName);

      subscriptions.realtimeProductAutomationChannel
        .on<ProductAutomation>(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "product_automation", filter: `productId=eq.${productId}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductAutomation(payload.new));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToProductAutomationSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromProductAutomation());
          }
        });
    } catch (e) {
      dispatch(subscribeToProductAutomationFailed(getError(e)));
    }
  };
