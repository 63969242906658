import { useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import { ingredientsSearchIndex } from "~/store/initializeStore";

const ITEMS_PER_PAGE = 25;

export const useSearchIngredients = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["searchIngredient", searchValue],
    enabled: !!searchValue,
    queryFn: async () => {
      const response = await ingredientsSearchIndex.search(searchValue, {
        limit: ITEMS_PER_PAGE,
        sort: ["rating:desc", "source:desc"],
        attributesToCrop: ["kcalmar"],
        offset: 0,
      });
      return {
        data: response.hits.map(({ _formatted, kcalmar, ...rest }) => rest),
        totalHits: response.estimatedTotalHits,
        hits: response.hits,
      };
    },
  });

  const clearSearch = () => {
    setSearchValue("");
    void queryClient.invalidateQueries(["searchIngredient", searchValue]);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  return {
    searchValue,
    data,
    isLoading,
    isFetching,
    clearSearch,
    inputRef,
    setSearchValue,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    items: !searchValue || !data ? [] : data.pages.flatMap((page) => page?.data || []),
  };
};
