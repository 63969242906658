import { useEffect, useRef } from "react";

export function useOuterClick(callback?: () => void, clickThreshold = 1) {
  const callbackRef = useRef<(e: MouseEvent) => void>(); // initialize mutable ref, which stores callback
  const innerRef = useRef<HTMLDivElement>(null); // returned to client, who marks "border" element
  const clickCount = useRef(0);

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(this: Document, e: MouseEvent) {
      if (!callbackRef.current) {
        return;
      }
      clickCount.current++;

      if (clickCount.current > clickThreshold) {
        // @ts-expect-error ignore
        if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);

        clickCount.current = 0;
      }
    }
  }, [clickThreshold]); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
