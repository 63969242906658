import mapValues from "lodash.mapvalues";

import {
  ExerciseType,
  type ExerciseTypeEnum,
  type ExerciseWithVideo,
} from "@fitness-app/data-models/entities/Exercise";
import {
  TrainingStatus,
  type ExerciseInWorkout,
  type ExerciseSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { getPropertiesValue } from "./prepareSetsFormModel";
import { reduceExerciseProperty } from "./reduceExerciseProperty";

const notEquivalentWorkoutTypes: string[] = [ExerciseType.POWER, ExerciseType.CARDIO];

const areExerciseSetTypesEqual = (baseType: ExerciseTypeEnum, alternativeType: ExerciseTypeEnum) => {
  if (baseType === alternativeType) return true;
  return !(notEquivalentWorkoutTypes.includes(baseType) || notEquivalentWorkoutTypes.includes(alternativeType));
};

const recreateExerciseSetForType = (
  workoutSet: Record<string, ExerciseSet>,
  type: ExerciseTypeEnum,
  resetSetsStatus = false,
): Record<string, ExerciseSet> =>
  mapValues(workoutSet, (set) => ({
    ...set,
    status: resetSetsStatus ? TrainingStatus.NEW : set.status,
    repeats: type === ExerciseType.CARDIO ? null : getPropertiesValue(set.repeats ?? 1, 0),
    duration: type === ExerciseType.POWER ? null : set.duration,
  }));

const recreateExerciseDataForAlternativeExercise = (
  baseExercise: ExerciseInWorkout,
  alternativeExercise: ExerciseWithVideo,
  resetSetsStatus = false,
): ExerciseInWorkout => {
  const {
    exercise: { type: baseExerciseType },
  } = baseExercise;

  const { type: alternativeExerciseType } = alternativeExercise;

  const isSameType = areExerciseSetTypesEqual(baseExerciseType, alternativeExerciseType);

  const newWorkoutSet =
    isSameType && !resetSetsStatus
      ? { ...baseExercise.set }
      : recreateExerciseSetForType(baseExercise.set, alternativeExercise.type, resetSetsStatus);

  const weight = isSameType
    ? { weight: baseExercise.weight }
    : {
        ...(alternativeExercise.type === ExerciseType.CARDIO
          ? {}
          : { weight: reduceExerciseProperty(newWorkoutSet, "weight") }),
      };

  const duration = isSameType
    ? { duration: baseExercise.duration }
    : {
        ...(alternativeExercise.type === ExerciseType.POWER
          ? {}
          : {
              duration: {
                value: reduceExerciseProperty(newWorkoutSet, "duration"),
                format: baseExercise.duration?.format ?? "seconds",
              },
            }),
      };

  const numberOfRepeats = isSameType
    ? { numberOfRepeats: baseExercise.numberOfRepeats }
    : {
        ...(alternativeExercise.type === ExerciseType.CARDIO ? {} : { numberOfRepeats: baseExercise.numberOfRepeats }),
      };

  return {
    ...baseExercise,
    set: newWorkoutSet,
    alternativeExercises: [
      ...(baseExercise.alternativeExercises?.filter((item) => item.id !== alternativeExercise.id) || []),
      {
        id: baseExercise.exercise.id,
        name: baseExercise.exercise.name,
        thumbnailUrl: baseExercise.exercise.video?.thumbnailUrl,
      },
    ],
    ...weight,
    ...duration,
    ...numberOfRepeats,
  };
};

export default recreateExerciseDataForAlternativeExercise;
