import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import {
  ClientNutritionStatus,
  type ClientMealsPlanDetails,
  type ClientNutrition,
} from "@fitness-app/data-models/entities/ClientNutrition";

import { type AsyncThunkCreator } from "../../../index";
import { updateTrainee } from "../../trainee/actions";
import { MEALS_PLANS_REDUCER_NAME } from "../types";
import { fetchMealsPlanWithDetails } from "./fetchMealsPlanWithDetails";

type Payload = {
  nutrition: ClientNutrition;
  mealsPlanDetails: ClientMealsPlanDetails;
  shareWithClient: boolean;
};

export const addNutritionToTrainee = createAsyncThunk<
  {
    plan: ClientNutrition | null;
    details: ClientMealsPlanDetails | null;
  } | null,
  Payload,
  AsyncThunkCreator<string>
>(
  `${MEALS_PLANS_REDUCER_NAME}/addNutritionToTrainee`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, analytics } }) => {
    const { profile: traineeProfile } = getState().trainee;

    if (traineeProfile?.activeMealsPlanId) {
      const { error } = await db
        .from("client_meals_plan")
        .update({
          status: ClientNutritionStatus.Archived,
          updatedAt: new Date().toISOString(),
          endAt: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
        })
        .eq("id", traineeProfile.activeMealsPlanId);

      if (error) {
        return rejectWithValue(error.message);
      }
    }

    const { error, data } = await db
      .from("client_meals_plan")
      .insert(payload.nutrition)
      .select("*")
      .single<ClientNutrition>();

    if (error || !data) {
      return rejectWithValue(error.message);
    }

    const { error: error2, data: mealsPlanDetailsList } = await db
      .from("client_meals_plan_details")
      .insert(payload.mealsPlanDetails)
      .select("*")
      .returns<ClientMealsPlanDetails[]>();

    if (error2) {
      return rejectWithValue(error2.message);
    }

    await dispatch(
      updateTrainee({
        id: payload.nutrition.traineeId,
        profile: {
          activeMealsPlanId: payload.nutrition.id,
          hasActiveNutrition: payload.shareWithClient ?? true,
          updatedAt: new Date().toISOString(),
        },
        additionalData: {
          nutrition: {
            name: payload.nutrition.name,
            id: payload.nutrition.id,
            startAt: payload.nutrition.startAt,
          },
        },
      }),
    );

    const profile = getState().user.profile;

    void dispatch(fetchMealsPlanWithDetails({ id: payload.nutrition.id }));
    analytics.track("add_nutrition_to_trainee");

    return {
      plan: data
        ? {
            ...data,
            creator: {
              id: data.addedBy ?? "",
              firstName: profile?.firstName ?? "",
              lastName: profile?.lastName ?? "",
            },
          }
        : null,
      details: mealsPlanDetailsList[0] || null,
    } as const;
  },
);
