import { ActivityLevel } from "@fitness-app/data-models/entities/MealsPlan";
import { Gender } from "@fitness-app/data-models/entities/shared";
import {
  ClientExperience,
  PlaceToWorkout,
  Weekday,
  WorkoutTarget,
  type InitialSurveyQuestions,
} from "@fitness-app/data-models/entities/SurveyTemplate";

export const defaultAnswers: InitialSurveyQuestions = {
  height: 180,
  weight: 70,
  birthYear: 1996,
  gender: Gender.MALE,
  targets: [WorkoutTarget.WEIGHT_LOSS],
  activityLevel: ActivityLevel.LOW,
  experience: ClientExperience.MEDIUM,
  placeToWorkout: PlaceToWorkout.HOME_WITH_EQUIPMENT,
  schedule: [Weekday.MONDAY, Weekday.WEDNESDAY, Weekday.FRIDAY],
  bodyFatPercentage: "21-29",
};
