import React, { useCallback, useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Select, Switch } from "antd";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";

import { surveysTemplatesActions } from "@fitness-app/app-store";
import { TaskType } from "@fitness-app/data-models/entities/Automation";
import { TemplateType } from "@fitness-app/data-models/entities/AutomationTemplate";
import {
  SurveyAutomationConditionType,
  SurveyQuestionType,
  type SurveyAutomationCondition,
  type SurveyEmailAutomation,
  type SurveyEmailIntegrationAutomation,
  type SurveyMessageAutomation,
  type SurveyQuestion,
  type SurveyQuestionAutomation,
  type SurveyTemplate,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import {
  formatModelIntoSurveyAutomationForm,
  formatSurveyAutomationFormIntoModel,
} from "~/modules/SurveysTemplates/SurveyDetails/formatSurveyAutomationFormIntoModel";
import SurveyAutomationChatMessageForm from "~/modules/SurveysTemplates/SurveyDetails/SurveyAutomationChatMessageForm";
import SurveyAutomationEmailMessageForm from "~/modules/SurveysTemplates/SurveyDetails/SurveyAutomationEmailMessageForm";
import SurveyAutomationEmailTaskForm from "~/modules/SurveysTemplates/SurveyDetails/SurveyAutomationEmailTaskForm";
import { useAppDispatch } from "~/store/initializeStore";

interface SurveyQuestionAutomationFormProps {
  question: SurveyQuestion;
  surveyId: string;
  model?: SurveyQuestionAutomation[];
  survey: SurveyTemplate;
}

export interface ISurveyQuestionAutomationForm {
  hasAutomation: boolean;
  automation: {
    condition: SurveyAutomationCondition;
    tasks: ({
      type: TemplateType | TaskType;
      id?: string;
    } & (SurveyMessageAutomation | Omit<SurveyEmailIntegrationAutomation, "filters"> | SurveyEmailAutomation))[];
  }[];
}

const conditionOptions = [
  {
    value: SurveyAutomationConditionType.AnyAnswer,
    label: "Dowolona odpowiedź na pytanie",
  },
  {
    value: SurveyAutomationConditionType.SpecificAnswer,
    label: "Wybrana odpowiedź",
  },
];

const taskOptions = [
  {
    value: TemplateType.ScheduledMessages,
    label: "Wyślij wiadomość czatową do klienta",
  },
  {
    value: TemplateType.ScheduledEmails,
    label: "Wyślij powiadomienia na e-mail trenera",
  },
  { value: TaskType.EmailClient, label: "Dodaj do listy/tag/field" },
];

const getQuestionTypeOptions = (question: SurveyQuestion) => {
  if (question.type === SurveyQuestionType.Text) {
    return [];
  }
  if (question.type === SurveyQuestionType.AB) {
    return [
      { value: "1", label: "Tak" },
      { value: "0", label: "Nie" },
    ];
  }

  if (question.type === SurveyQuestionType.Select) {
    return question.answers.map((answer) => ({
      value: answer,
      label: answer,
    }));
  }
  return [];
};

const SurveyQuestionAutomationForm = ({
  question,
  surveyId,
  model,
  survey,
}: SurveyQuestionAutomationFormProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const [saving, setSaving] = useState(false);
  const [formController] = Form.useForm<ISurveyQuestionAutomationForm>();
  const hasActiveAutomation = Form.useWatch("hasAutomation", formController);
  const dispatch = useAppDispatch();
  const automationState = Form.useWatch("automation", formController);

  useEffect(() => {
    if (model) {
      const formatted = formatModelIntoSurveyAutomationForm(model);
      formController.setFieldsValue(formatted);
    }
  }, [model]);

  const onSubmit = async (formData: ISurveyQuestionAutomationForm) => {
    setSaving(true);
    if (model && !formData.hasAutomation) {
      const automation = cloneDeep(survey.automation || {});

      if (automation[question.id]) {
        automation[question.id] = [];
      }

      await dispatch(
        surveysTemplatesActions.editSurveyTemplate({
          surveyTemplateId: surveyId,
          surveyTemplate: {
            automation,
          },
        }),
      );
    } else if (formData.hasAutomation) {
      const automation = cloneDeep(survey.automation || {});

      automation[question.id] = formatSurveyAutomationFormIntoModel(formData, question);

      await dispatch(
        surveysTemplatesActions.editSurveyTemplate({
          surveyTemplateId: surveyId,
          surveyTemplate: {
            automation,
          },
        }),
      );
    }

    setSaving(false);
  };

  const renderTaskForm = useCallback(
    (type: TemplateType | TaskType | undefined, parentIndex: number, rootIndex: number) => {
      if (type === TemplateType.ScheduledMessages) {
        return (
          <SurveyAutomationChatMessageForm
            formController={formController}
            path={["automation", rootIndex, "tasks", parentIndex]}
            parentFieldName={parentIndex}
          />
        );
      }
      if (type === TemplateType.ScheduledEmails) {
        return <SurveyAutomationEmailMessageForm parentFieldName={parentIndex} />;
      }
      if (type === TaskType.EmailClient) {
        return (
          <SurveyAutomationEmailTaskForm
            parentFieldName={parentIndex}
            formController={formController}
            path={["automation", rootIndex, "tasks", parentIndex]}
          />
        );
      }
      return null;
    },
    [formController],
  );

  return (
    <div className="mx-auto mt-12 max-w-[600px]">
      <Divider />
      <Form form={formController} layout="vertical" onFinish={onSubmit} id={`question-${question.id}-automation`}>
        <Form.Item label="Aktywna automatyzacja" name="hasAutomation" valuePropName="checked">
          <Switch />
        </Form.Item>

        {hasActiveAutomation && (
          <>
            <Form.Item label="Lista automatyzacji">
              <Form.List
                name="automation"
                initialValue={[
                  {
                    condition: {
                      type: SurveyAutomationConditionType.AnyAnswer,
                    },
                    tasks: [
                      {
                        type: TemplateType.ScheduledMessages,
                      },
                    ],
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div
                        key={field.key}
                        style={{
                          border: "1px dashed #d9d9d9",
                          backgroundColor: "#fff",
                          marginTop: 12,
                          padding: 16,
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "baseline",
                        }}
                      >
                        <div className="flex w-full">
                          <Form.Item
                            {...field}
                            name={[field.name, "condition", "type"]}
                            label="Warunek uruchomienia automatyzacji"
                            rules={[
                              {
                                required: true,
                                message: t<string>("common:validationErrors.fieldIsRequired"),
                              },
                            ]}
                            style={{ width: "90%" }}
                          >
                            <Select options={conditionOptions} />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                            className="text-red-500"
                            style={{ marginLeft: 25 }}
                          />
                        </div>
                        <div className="w-full">
                          {automationState?.[index]?.condition?.type ===
                            SurveyAutomationConditionType.SpecificAnswer && (
                            <Form.Item
                              label="Uruchom po odpowiedzi"
                              name={[field.name, "condition", "answers"]}
                              rules={[
                                {
                                  required: true,
                                  message: t<string>("common:validationErrors.fieldIsRequired"),
                                },
                              ]}
                            >
                              <Select
                                mode={question.type !== SurveyQuestionType.Text ? "multiple" : "tags"}
                                placeholder={
                                  question.type === SurveyQuestionType.Text
                                    ? "Wpisz jakies słowo powinna zawierać odpowiedź"
                                    : "Wybierz odpowiedzi"
                                }
                                options={getQuestionTypeOptions(question)}
                              />
                            </Form.Item>
                          )}
                        </div>

                        <div className="w-full">
                          <Form.Item label="Zadania">
                            <Form.List
                              name={[field.name, "tasks"]}
                              initialValue={[
                                {
                                  type: TemplateType.ScheduledMessages,
                                },
                              ]}
                            >
                              {(nestedFields, { add, remove }) => (
                                <>
                                  {nestedFields.map((nestedField, nestedIndex) => (
                                    <div
                                      key={`${field.key}-${nestedField.key}`}
                                      style={{
                                        border: "1px dashed #d9d9d9",
                                        backgroundColor: "#fafafa",
                                        marginTop: 12,
                                        padding: 16,
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      <div className="flex w-full">
                                        <Form.Item
                                          {...nestedField}
                                          name={[nestedField.name, "type"]}
                                          label="Typ automatyzacji"
                                          rules={[
                                            {
                                              required: true,
                                              message: t<string>("common:validationErrors.fieldIsRequired"),
                                            },
                                          ]}
                                          style={{ width: "90%" }}
                                        >
                                          <Select options={taskOptions} />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                          onClick={() => remove(nestedField.name)}
                                          className="text-red-500"
                                          style={{ marginLeft: 25 }}
                                        />
                                      </div>
                                      <div className="w-full">
                                        <Form.Item>
                                          {renderTaskForm(
                                            automationState?.[index]?.tasks?.[nestedIndex]?.type,
                                            nestedIndex,
                                            index,
                                          )}
                                        </Form.Item>
                                      </div>
                                    </div>
                                  ))}
                                  {fields.length < 5 && (
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                        style={{ marginTop: 10 }}
                                      >
                                        Dodaj zadanie
                                      </Button>
                                    </Form.Item>
                                  )}
                                </>
                              )}
                            </Form.List>
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    {fields.length < 5 && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          style={{ marginTop: 10 }}
                        >
                          Dodaj automatyzację
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Form.Item>
          </>
        )}

        <div className="flex justify-end">
          <Form.Item>
            {formController.isFieldTouched("hasAutomation") || formController.isFieldTouched("automation") ? (
              <Button type="primary" htmlType="submit" block size="middle" icon={<SaveOutlined />} loading={saving}>
                {t("common:button.save")}
              </Button>
            ) : null}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SurveyQuestionAutomationForm;
