import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Order } from "@fitness-app/data-models/entities/Order";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

export const fetchProductClientOrders = createAsyncThunk<
  Order[],
  { productId: string; clientEmail: string },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENT_REDUCER}/fetchProductClientOrders`,
  async ({ productId, clientEmail }, { rejectWithValue, extra: { db }, getState }) => {
    const { ordersPageSize } = getState().productClient;

    const { error, data } = await db
      .from("product_order")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("product", productId)
      .eq("clientEmail", clientEmail)
      .limit(ordersPageSize)
      .returns<Order[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
