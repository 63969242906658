import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_NOTES_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
};

export const fetchClientNotes = createAsyncThunk<ClientNoteWithAuthorData[], Payload, AsyncThunkCreator<string>>(
  `${CLIENT_NOTES_REDUCER_NAME}/fetchClientNotes`,
  async ({ traineeId }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("client_note")
      .select("*, author:authorId(avatarUrl, firstName, lastName)")
      .order("createdAt", { ascending: false })
      .eq("traineeId", traineeId)
      .returns<ClientNoteWithAuthorData[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
