import { RequestStatus } from "@fitness-app/data-models";

import { type AppThunk } from "../../../index";
import { unsubscribeFromChannel as actionCreator, setCurrentChannelStatus } from "../reducer";

export const unsubscribeFromChannelMessages =
  (channelId: string): AppThunk =>
  async (dispatch, getState, { subscriptions, db, parties }) => {
    if (subscriptions.channels[channelId]?.realtimeChannel) {
      await db.removeChannel(subscriptions.channels[channelId].realtimeChannel);
      subscriptions.channels[channelId].realtimeChannelName = null;
    }

    if (parties.chatRooms[channelId]) {
      parties.chatRooms[channelId]?.close();
      parties.chatRooms[channelId] = null;
    }

    if (parties.chatRoomsNative[channelId]) {
      parties.chatRoomsNative[channelId]?.close();
      parties.chatRoomsNative[channelId] = null;
    }

    dispatch(setCurrentChannelStatus(RequestStatus.IDLE));
    dispatch(actionCreator(channelId));
  };
