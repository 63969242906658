export const ONE_TIME_STATUS_MAPPER = {
  active: "success",
  imported: "success",
  past_due: "warning",
  expired: "error",
  archived: "default",
} as const;

export const FREE_STATUS_MAPPER = {
  active: "success",
  archived: "error",
} as const;
