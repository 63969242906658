import React from "react";
import { useTranslation } from "react-i18next";

import { type Meal, type NutritionPlanDay } from "@fitness-app/data-models/entities/MealsPlan";
import { getMealHour } from "@fitness-app/utils/src/nutrition/getMealHour";

import MealDaySummary from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDaySummary";
import MealDishesList from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDishesList";

interface MealDetailsProps {
  dayIndex: number;
  mealIndex: number;
  meal: Meal | null;
  day: NutritionPlanDay;
  onAddDish: () => void;
  readonly?: boolean;
}

const MealDetails = ({ dayIndex, meal, mealIndex, day, onAddDish, readonly }: MealDetailsProps): React.ReactElement => {
  const { t } = useTranslation(["nutrition", "common"]);

  return (
    <div key={dayIndex} className="group flex flex-col pb-4">
      {meal ? (
        <>
          {mealIndex === 0 && (
            <div className="flex items-center gap-x-4 rounded-r-lg bg-gray-50 p-3">
              <div className="text-sm font-medium capitalize leading-6 text-gray-900">
                {t(`common:weekdays:${day.name}`)}
              </div>
            </div>
          )}
          <dt className="flex h-[30px] justify-start gap-2 border-b bg-gray-100/75 px-2 py-1 capitalize text-gray-500">
            {dayIndex === 0 ? (
              <>
                <span>{meal.name || t(`mealType.${meal.type}`)}</span>{" "}
                <span>{getMealHour(day.meals[mealIndex]?.hour || null)}</span>
              </>
            ) : null}
          </dt>

          <MealDishesList
            readonly={readonly}
            meal={meal}
            dayIndex={dayIndex}
            dayId={day.id}
            mealIndex={mealIndex}
            onAddDish={onAddDish}
          />
        </>
      ) : (
        <div className="flex flex-col justify-start gap-2 py-1  text-gray-500">
          {dayIndex === 0 ? (
            <div className="flex h-[30px] items-center bg-gray-100/75 text-sm text-gray-900">
              <div className="px-2">{t("mealsPlan.summary")}</div>
            </div>
          ) : (
            <div className="h-[30px] bg-gray-100/75" />
          )}
          <MealDaySummary meals={day.meals} />
        </div>
      )}
    </div>
  );
};

export default MealDetails;
