import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProductAutomation } from "@fitness-app/data-models/entities/Automation";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_AUTOMATION_REDUCER_NAME } from "../types";

type Payload = string;

export const fetchProductAutomation = createAsyncThunk<ProductAutomation, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/fetchProductAutomation`,
  async (productId, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("product_automation")
      .select("*")
      .eq("productId", productId)
      .single<ProductAutomation>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
