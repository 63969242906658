import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { addFromTemplate, createProgramWorkoutTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentProgram } from "../selectors/getCurrentProgram";
import { PROGRAM_BUILDER_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutId?: string;
  workoutTemplates?: WorkoutTemplate[];
};

export const addNewWorkoutToProgram = createAsyncThunk<TrainingProgramDetails, Payload, AsyncThunkCreator<string>>(
  `${PROGRAM_BUILDER_REDUCER_NAME}/addNewWorkoutToProgram`,
  async (
    { programId, workoutId, workoutTemplates },
    { rejectWithValue, getState, dispatch, extra: { db, analytics } },
  ) => {
    const currentProgram = getCurrentProgram(getState());

    const newWorkout = workoutTemplates
      ? workoutTemplates.map(addFromTemplate)
      : [createProgramWorkoutTemplate(currentProgram.details.workouts.length, { id: workoutId })];

    const updatedWorkouts = [...currentProgram.details.workouts, ...newWorkout];

    dispatch(updateWorkoutsInProgram({ workouts: updatedWorkouts, programId }));

    const { data: programDetails, error } = await db
      .from("training_program_details")
      .update({
        workouts: updatedWorkouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<TrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_new_workout_to_program", { count: workoutTemplates?.length ?? 0 });

    return programDetails;
  },
);
