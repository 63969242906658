import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramAutomationTask } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../..";
import { CLIENT_SCHEDULED_TASKS_REDUCER_NAME } from "../types";

type Payload = {
  tasks: ProgramAutomationTask[];
};

export const addClientTasks = createAsyncThunk<null | ProgramAutomationTask[], Payload, AsyncThunkCreator<string>>(
  `${CLIENT_SCHEDULED_TASKS_REDUCER_NAME}/addClientTasks`,
  async (payload, { extra: { db, analytics } }) => {
    const { data } = await db
      .from("program_automation_task")
      .insert(payload.tasks)
      .select("*")
      .returns<ProgramAutomationTask[]>();

    analytics.track("add_client_tasks", { count: payload.tasks.length });

    return data;
  },
);
