import React, { type FunctionComponent } from "react";
import { Alert, Button, Checkbox, Divider, Form, Input, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import { userActions, UserStatus } from "@fitness-app/app-store";
import { type UserLangEnum } from "@fitness-app/data-models/entities/UserProfile";

import { getUserIp } from "~/modules/Auth/hooks/getUserIp";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const tailLayout = {
  wrapperCol: { offset: 12, span: 12 },
};

interface RegisterFormModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAndPrivacyPolicy: boolean;
}

export const SignUp: FunctionComponent = () => {
  const { t, i18n } = useTranslation(["auth", "common", "checkout"]);
  const { status: userStatus, registerError } = useAppSelector((store) => store.user);
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const ref = searchParams.get("ref");
  const code = searchParams.get("code") || "";
  const email = searchParams.get("email") || "";
  const dispatch = useAppDispatch();

  const handleSubmitRegisterForm = async (data: RegisterFormModel) => {
    const ip = await getUserIp();

    dispatch(
      userActions.signUpUser({
        ...data,
        ip,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        lang: i18n.language as UserLangEnum,
        ref,
      }),
    );
  };

  return (
    <div className="flex flex-1 items-center justify-center p-4">
      <div className="min-w-[350px] max-w-[500px]">
        <Typography.Title level={4}>{t("registerPanel")}</Typography.Title>
        {registerError ? (
          <Alert
            className="mb-12"
            type={registerError ? "error" : "info"}
            message={registerError ? registerError : <Trans t={t} i18nKey="common:startJourney" />}
          />
        ) : null}
        <Form<RegisterFormModel>
          name="register-form"
          layout="vertical"
          form={form}
          onFinish={handleSubmitRegisterForm}
          validateTrigger={["onSubmit", "onBlur"]}
          initialValues={{
            code,
            email,
            termsAndPrivacyPolicy: false,
          }}
        >
          <Form.Item
            name="firstName"
            label={t("firstName")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            name="lastName"
            label={t("lastName")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label={t("login")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
              {
                type: "email",
                message: t<string>("validationErrors.wrongEmail"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("password")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
              {
                min: 8,
                message: t<string>("validationErrors.minLength", { number: 8 }),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="passwordConfirmation"
            label={t("passwordConfirmation")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t("validationErrors.passwordNotMatch"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="termsAndPrivacyPolicy"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(t<string>("validationErrors.fieldIsRequired")),
              },
            ]}
          >
            <Checkbox>
              {t<string>("acceptTerms")}
              <a
                href={t<string>("common:links.terms")}
                target="_blank"
                rel="noreferrer nofollow"
                style={{ marginRight: 5 }}
              >
                {t<string>("termsOfService")}
              </a>
              {t<string>("acceptOr")}
              <a
                href={t<string>("common:links.privacyPolicy")}
                target="_blank"
                rel="noreferrer nofollow"
                style={{ marginRight: 5 }}
              >
                {t<string>("privacyPolicy")}
              </a>
              {t<string>("acceptEnd")}
              <span className="accent-red-800">*</span>
            </Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" block loading={userStatus === UserStatus.CREATING_NEW_ACCOUNT}>
              {t("common:button.register")}
            </Button>
          </Form.Item>
        </Form>

        <Divider />

        <div className="flex justify-center">
          <h4 className="mr-2">{t("common:alreadyHaveAccount")}</h4>
          <Link to="/auth/login">{t("common:button.login")}</Link>
        </div>
      </div>
    </div>
  );
};
