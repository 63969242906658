import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AutomationTemplate } from "@fitness-app/data-models/entities/AutomationTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { TEMPLATES_REDUCER_NAME } from "../types";

type Payload = AutomationTemplate;

export const addTemplate = createAsyncThunk<AutomationTemplate, Payload, AsyncThunkCreator<string>>(
  `${TEMPLATES_REDUCER_NAME}/addTemplate`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("automation_template")
      .insert(payload)
      .select("*")
      .single<AutomationTemplate>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_template_automation");

    return data;
  },
);
