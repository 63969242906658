import React, { memo, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, AutoComplete, Button, Col, Form, Input, Select, Space, TreeSelect } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { DiscordIntegrationTask, EmailClientTask } from "@fitness-app/data-models/entities/Automation";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

import { useEmailLists } from "~/hooks/useEmailLists";
import { useDiscordIntegration } from "~/modules/Products/Product/ProductAutomation/hooks/useDiscordIntegration";
import { type ISurveyQuestionAutomationForm } from "~/modules/SurveysTemplates/SurveyDetails/SurveyQuestionAutomationForm";

interface SurveyAutomationEmailTaskFormProps {
  parentFieldName: string | number;
  formController?: FormInstance<ISurveyQuestionAutomationForm>;
  path: (string | number)[];
}

const SurveyAutomationEmailTaskForm = ({
  parentFieldName,
  path,
  formController,
}: SurveyAutomationEmailTaskFormProps): React.ReactElement => {
  const { t } = useTranslation(["products", "common"]);
  const [selectedTaskType, selectTaskType] = useState<null | EmailClientTask | DiscordIntegrationTask>(
    // @ts-expect-error - fix this
    path && formController ? formController.getFieldValue(path.concat("action").concat("type")) : null,
  );
  const { tags, lists, listOrAccountOptions, emailAutomationTaskOptions } = useEmailLists();
  const { discordAutomationTaskOptions, discordIntegration } = useDiscordIntegration();

  const renderTaskAction = () => {
    if (
      selectedTaskType === DiscordIntegrationTask.RemoveFromServer ||
      selectedTaskType === DiscordIntegrationTask.AddToServer
    ) {
      return (
        <>
          <Form.Item
            name={[parentFieldName, "action", "serverId"]}
            label={t<string>("products:form.discordServerId")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              placeholder={t("products:form.selectDiscordAccount")}
              notFoundContent={false}
              optionFilterProp="title"
              optionLabelProp="title"
              allowClear
            >
              {discordIntegration.map((account) => (
                <Select.Option value={account.guildId} title={account.guild.name} key={account.guildId}>
                  {account.guild.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    }
    if (selectedTaskType === EmailClientTask.AddTags) {
      return (
        <>
          <Form.Item
            name={[parentFieldName, "action", "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions.filter((account) => account.value !== EmailClient.FreshMail)}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>

          <Col offset={8}>
            <Alert message={t("products:form.emailClientTagsInfo")} style={{ marginTop: 10, marginBottom: 10 }} />
          </Col>

          <Form.Item
            name={[parentFieldName, "action", "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveTags) {
      return (
        <>
          <Form.Item
            name={[parentFieldName, "action", "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions.filter((account) => account.value !== EmailClient.FreshMail)}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>

          <Col offset={8}>
            <Alert message={t("products:form.emailClientTagsInfo")} style={{ marginTop: 10, marginBottom: 10 }} />
          </Col>

          <Form.Item
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={[parentFieldName, "action", "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.AddToLists) {
      return (
        <Form.Item
          name={[parentFieldName, "action", "emailLists"]}
          label={t<string>("products:form.emailLists")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <TreeSelect
            style={{ maxWidth: 700 }}
            treeData={lists}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
            treeNodeLabelProp="title"
          />
        </Form.Item>
      );
    }

    if (selectedTaskType === EmailClientTask.AddFieldValues) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={[parentFieldName, "action", "emailLists"]}
            label={t<string>("products:productAutomation.form.emailListsOrAccount")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>
          <Form.Item
            label={t("productAutomation.form.fieldValues")}
            tooltip={t("productAutomation.form.fieldValuesTooltip")}
          >
            <Form.List name={[parentFieldName, "action", "values"]} initialValue={[{ key: "", value: "" }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                      <Space style={{ width: "100%" }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          style={{ flex: 1, width: 220, marginBottom: 5 }}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Input placeholder={t<string>("productAutomation.form.bodyPlaceholder")} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ flex: 1, width: 235, marginBottom: 5 }}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <AutoComplete options={[]} placeholder={t("productAutomation.form.valuePlaceholder")} />
                        </Form.Item>
                        {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : null}
                      </Space>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          key: "",
                          value: "",
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("productAutomation.form.addField")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveFromLists) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={[parentFieldName, "action", "emailLists"]}
            label={t<string>("products:form.emailLists")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={lists}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
              treeNodeLabelProp="title"
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return (
    <div>
      <>
        <Form.Item
          name={[parentFieldName, "action", "type"]}
          label={t<string>("productAutomation.form.taskType")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            options={[...emailAutomationTaskOptions, ...discordAutomationTaskOptions]}
            onSelect={(value: EmailClientTask) => selectTaskType(value)}
          />
        </Form.Item>

        {renderTaskAction()}
      </>
    </div>
  );
};

export default memo(SurveyAutomationEmailTaskForm);
