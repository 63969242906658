import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type Order } from "@fitness-app/data-models/entities/Order";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchOrders } from "./actions/fetchOrders";
import { PRODUCT_ORDERS_REDUCER_NAME, type ProductOrdersReducer } from "./types";

const initialState: ProductOrdersReducer = {
  orders: [],
  ordersStatus: null,
  productId: null,
  searchTerm: null,
  selectedMonth: null,
  error: null,
};

const productOrdersSlice = createSlice({
  name: PRODUCT_ORDERS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductOrdersStarted(state, { payload }: PayloadAction<{ productId: string; selectedMonth: string }>) {
      state.ordersStatus = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.productId = payload.productId;
      state.selectedMonth = payload.selectedMonth;
    },
    subscribeToProductOrdersSuccess(state, { payload }: PayloadAction<Order[]>) {
      state.ordersStatus = RequestStatus.SUBSCRIBED;
      state.orders = payload;
    },
    subscribeToProductOrdersFailed(state, { payload }: PayloadAction<Error>) {
      state.ordersStatus = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromProductOrders() {
      return initialState;
    },
    clearSearchMode(state) {
      state.searchTerm = "";
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.ordersStatus = RequestStatus.FETCHING;
      state.orders = [];
    });
    builder.addCase(fetchOrders.fulfilled, (state, { payload }) => {
      state.ordersStatus = RequestStatus.SUCCESS;
      state.orders = payload;
    });
    builder.addCase(fetchOrders.rejected, (state) => {
      state.ordersStatus = RequestStatus.FAILED;
    });
  },
});

export const {
  subscribeToProductOrdersFailed,
  subscribeToProductOrdersStarted,
  subscribeToProductOrdersSuccess,
  unsubscribeFromProductOrders,
  clearSearchMode,
  setSearchTerm,
} = productOrdersSlice.actions;

export default productOrdersSlice.reducer;
