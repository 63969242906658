import React, { useEffect, useMemo, useState, type FunctionComponent } from "react";
import { CloseOutlined, MailOutlined } from "@ant-design/icons";
import { Alert, Form, message, Modal, Space, Steps, Switch, Typography } from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { type IFileInfo } from "react-csv-reader";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { productActions } from "@fitness-app/app-store";
import {
  ClientImportStatus,
  type ProductClientToImportDocument,
} from "@fitness-app/data-models/entities/ProductClientToImport";
import { EMAIL_REGEX } from "@fitness-app/utils/src/constants/emailRegex";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import AddClientsToProduct from "./steps/AddClientsToProduct";
import ParseClientsList from "./steps/ParseClientsList";
import SelectImportFile from "./steps/SelectImportFile";

interface OwnProps {
  visible: boolean;
  close: () => void;
  isFree: boolean;
}

type Props = OwnProps;

const { Step } = Steps;

enum StepType {
  SelectFile,
  ParseList,
  AddToProduct,
}

export interface HeaderMatcherForm {
  email: string;
  subscribeDate: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  name: string;
  hasLastNameTogether: boolean;
  productPriceId: string;
}

export type File = {
  data: Record<string, string>[];
  fileInfo: IFileInfo;
} | null;

export interface TableModel {
  email: string;
  name: string;
  subscribedDate: dayjs.Dayjs;
  accessTo: null | dayjs.Dayjs;
  priceId: string | null;
  phoneNumber?: string | null;
  isAfterToday: boolean;
  id?: string;
}

export type ManualItemModel = TableModel & {
  id: string;
};

export type TableModelWithPrice = TableModel & { priceId: string };

const LIMIT_FOR_SENDING_EMAIL_BY_MAILINGR = 500;

const ImportClientsModal: FunctionComponent<Props> = ({ visible, close, isFree }) => {
  const { t } = useTranslation("products");
  const [currentStep, changeStep] = useState<StepType>(StepType.SelectFile);
  const [formController] = Form.useForm<HeaderMatcherForm>();
  const [uploadedFile, setFile] = useState<File>(null);
  const owner = useAppSelector((store) => store.user.data);
  const [formModel, setFormModel] = useState<HeaderMatcherForm | null>(null);
  const dispatch = useAppDispatch();
  const [sendEmailAboutAccount, toggleSendEmailAboutAccount] = useState(!isFree);
  const [priceExceptions, setPriceExceptions] = useState<Record<string, string>>({});
  const [emailExceptions, setEmailExceptions] = useState<Record<string, string>>({});
  const [nameExceptions, setNameExceptions] = useState<Record<string, string>>({});
  const [phoneExceptions, setPhoneExceptions] = useState<Record<string, string>>({});
  const [dateAccessToExceptions, setDateAccess] = useState<Record<string, Dayjs | null>>({});
  const [dateSubscribedToExceptions, setDateSubscribed] = useState<Record<string, Dayjs | null>>({});
  const productDetails = useAppSelector((store) => store.product.details);
  const [successImportedClient, toggleImportClients] = useState(false);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [manuallyAdded, addClientManually] = useState<ManualItemModel[]>([]);

  const prices = productDetails?.prices;

  const tableModel: TableModel[] = useMemo(() => {
    if (!formModel && !manuallyAdded.length) {
      return [];
    }
    const data = uploadedFile?.data ?? [];
    let fromFile: TableModel[] = [];
    if (formModel) {
      if (isFree) {
        fromFile = data.map((item) => {
          const clientEmail = item[formModel.email]?.toLowerCase() ?? "";
          const phoneNumber = item[formModel.phoneNumber] ?? "";
          const subscribedDate = dayjs(item[formModel.subscribeDate]);
          return {
            email: clientEmail,
            name: formModel.hasLastNameTogether
              ? item[formModel.firstName ?? ""]
              : `${item[formModel.firstName] ?? ""} ${item[formModel.lastName] ?? ""}`.trim(),
            subscribedDate,
            phoneNumber,
            isAfterToday: true,
            priceId: null,
            accessTo: null,
          } as TableModel;
        });
      } else {
        const selectedPrice = prices?.find((price) => price.id === formModel.productPriceId);
        fromFile = data.map((item) => {
          const probablyEmail = item[formModel.email];
          const clientEmail = probablyEmail ? probablyEmail.toLowerCase() : "";
          const phoneNumber = item[formModel.phoneNumber] ?? "";
          const subscribedDate = dayjs(item[formModel.subscribeDate]);
          const hasExceptionPrice = priceExceptions[clientEmail];
          const hasExceptionDate = dateAccessToExceptions[clientEmail];
          const selectedClientPrice = hasExceptionPrice
            ? productDetails?.prices?.find((price) => price.id === priceExceptions[clientEmail])
            : selectedPrice;
          const accessTo = hasExceptionDate
            ? hasExceptionDate
            : selectedClientPrice?.accessPeriod
            ? subscribedDate.add(
                selectedClientPrice.accessPeriod.interval_count,
                selectedClientPrice.accessPeriod.interval,
              )
            : null;
          return {
            email: clientEmail,
            name: formModel.hasLastNameTogether
              ? item[formModel.firstName ?? ""]
              : `${item[formModel.firstName] ?? ""} ${item[formModel.lastName] ?? ""}`,
            phoneNumber,
            subscribedDate,
            priceId: priceExceptions[clientEmail] || formModel.productPriceId,
            accessTo,
            isAfterToday: accessTo ? accessTo.isBefore(dayjs().startOf("day")) : false,
          } as TableModel;
        });
      }
    }

    const fromManualForm = manuallyAdded.map((item) => {
      const priceId = priceExceptions[item.id];
      const selectedClientPrice = productDetails?.prices?.find((price) => price.id === priceId) || prices?.[0];
      const hasExceptionDate = dateAccessToExceptions[item.id];
      const subscribedDate = dateSubscribedToExceptions[item.id] || item.subscribedDate;
      const accessTo = hasExceptionDate
        ? hasExceptionDate
        : selectedClientPrice?.accessPeriod
        ? subscribedDate.add(selectedClientPrice.accessPeriod.interval_count, selectedClientPrice.accessPeriod.interval)
        : null;
      return {
        ...item,
        email: emailExceptions[item.id] ?? item.email,
        name: nameExceptions[item.id] ?? item.name ?? "",
        phoneNumber: phoneExceptions[item.id] ?? item.phoneNumber ?? "",
        subscribedDate,
        accessTo,
        priceId: priceId || prices?.[0]?.id || null,
        isAfterToday: accessTo ? accessTo.isBefore(dayjs().startOf("day")) : false,
      };
    });
    return [...fromFile, ...fromManualForm];
  }, [
    formModel,
    uploadedFile,
    priceExceptions,
    dateAccessToExceptions,
    prices,
    manuallyAdded,
    dateSubscribedToExceptions,
    emailExceptions,
    nameExceptions,
    phoneExceptions,
    isFree,
  ]);

  useEffect(() => {
    if (tableModel.length > LIMIT_FOR_SENDING_EMAIL_BY_MAILINGR) {
      toggleSendEmailAboutAccount(false);
    }
  }, [tableModel]);

  const handleFormSubmit = (model: HeaderMatcherForm) => {
    setFormModel(model);
    if (uploadedFile?.data) {
      setSelectedClients(
        uploadedFile?.data
          .map((item) => item[model.email.toLowerCase()]?.toLowerCase())
          .filter((item): item is string => Boolean(item)),
      );
    }
    changeStep(StepType.ParseList);
  };

  const handleClientImports = async (validatedClients: (TableModel & { priceId: string })[]) => {
    if (!productDetails || !owner) {
      return null;
    }
    const now = new Date().toISOString();
    const importId = uuid();
    const clientsToImport: Omit<ProductClientToImportDocument, "total" | "count">[] = validatedClients.map((item) => {
      const [firstName, lastName] = item.name.split(" ");
      return {
        email: item.email.toLowerCase(),
        priceId: item.priceId ?? null,
        accessTo: item.accessTo?.toISOString() ?? null,
        subscribedDate: item.subscribedDate.isValid() ? item.subscribedDate?.toISOString() : dayjs().toISOString(),
        createdAt: now,
        updatedAt: now,
        productId: productDetails.id,
        ownerId: owner.id,
        id: generateUniqId(),
        importId,
        status: ClientImportStatus.Pending,
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        phoneNumber: item.phoneNumber || null,
        isFree,
        errorMessage: null,
        sendEmailAboutAccountCreation: sendEmailAboutAccount,
      };
    });
    try {
      await dispatch(
        productActions.importClientsList({
          productId: productDetails.id,
          list: clientsToImport,
        }),
      );
      toggleImportClients(true);
      formController?.resetFields();
    } catch (e) {
      void message.error(t("import.cannotImportClients"));
    }
  };

  const onAddNewRow = () => {
    const id = uuid();
    addClientManually((prev) => [
      ...prev,
      {
        email: "",
        name: "",
        id,
        subscribedDate: dayjs(),
        accessTo: null,
        isAfterToday: true,
        priceId: null,
      },
    ]);
    setSelectedClients((prev) => [...prev, id]);
  };

  const stepSwitch = () => {
    switch (currentStep) {
      case StepType.SelectFile:
        return (
          <SelectImportFile
            onSubmit={handleFormSubmit}
            isFree={isFree}
            formController={formController}
            file={uploadedFile}
            setFile={setFile}
            productId={productDetails?.id ?? ""}
            skipStep={() => {
              changeStep(StepType.ParseList);
              onAddNewRow();
            }}
          />
        );
      case StepType.ParseList:
        return (
          <>
            <Space
              align="center"
              direction="vertical"
              style={{
                justifyContent: "center",
                width: "100%",
                margin: "30px 0",
              }}
            >
              <Typography.Paragraph strong style={{ marginBottom: 0 }}>
                {t("import.sendInfoAboutAccountCreation")}
              </Typography.Paragraph>
              <Switch
                disabled={tableModel.length > LIMIT_FOR_SENDING_EMAIL_BY_MAILINGR}
                checkedChildren={<MailOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={sendEmailAboutAccount}
                onChange={toggleSendEmailAboutAccount}
              />
              {tableModel.length > LIMIT_FOR_SENDING_EMAIL_BY_MAILINGR && (
                <Alert
                  showIcon
                  type="warning"
                  message={t("import.cannotSendEmailNotification", {
                    count: LIMIT_FOR_SENDING_EMAIL_BY_MAILINGR,
                  })}
                />
              )}
            </Space>
            <ParseClientsList
              selectedClients={selectedClients}
              setSelectedRows={setSelectedClients}
              tableModel={tableModel}
              addNewRow={onAddNewRow}
              isFree={isFree}
              setPriceException={(email, priceId, id?: string) =>
                setPriceExceptions((prev) => ({
                  ...prev,
                  [id || email]: priceId,
                }))
              }
              setDateException={(email, date, id?: string) =>
                setDateAccess((prev) => ({ ...prev, [id || email]: date }))
              }
              setDateSubscriberException={(id, value) => setDateSubscribed((prev) => ({ ...prev, [id]: value }))}
              setName={(id, value) => setNameExceptions((prev) => ({ ...prev, [id]: value }))}
              setEmail={(id, value) => setEmailExceptions((prev) => ({ ...prev, [id]: value }))}
              setPhone={(id, value) => setPhoneExceptions((prev) => ({ ...prev, [id]: value }))}
              removeItem={(id) => {
                addClientManually((prev) => prev.filter((item) => item.id !== id));
                setSelectedClients((prev) => prev.filter((addedId) => addedId !== id));
              }}
            />
          </>
        );
      case StepType.AddToProduct:
        return <AddClientsToProduct tableModel={tableModel} close={close} finished={successImportedClient} />;
      default:
        return null;
    }
  };

  const handleNextStep = () => {
    if (currentStep === StepType.SelectFile) {
      formController?.submit();
    }
    if (currentStep == StepType.ParseList) {
      const clientsToImport = tableModel.filter((item): item is TableModelWithPrice =>
        Boolean(
          (isFree || !item.isAfterToday) &&
            selectedClients.includes(item.id ? item.id : item.email) &&
            item.email.match(EMAIL_REGEX) &&
            (isFree || item.priceId),
        ),
      );

      if (!clientsToImport.length) {
        void message.warning(t("import.emptyList"));
        return;
      }
      changeStep(StepType.AddToProduct);
      void handleClientImports(clientsToImport);
    }
  };

  const handleCancelButton = () => {
    if (currentStep === StepType.SelectFile) {
      close();
      return;
    }

    changeStep((prev) => prev - 1);
  };

  return (
    <Modal
      width={1400}
      className="max-w-full"
      title={t("import.modalTitle")}
      visible={visible}
      onCancel={handleCancelButton}
      destroyOnClose
      onOk={handleNextStep}
      okButtonProps={{
        loading: successImportedClient,
      }}
      cancelText={currentStep === StepType.SelectFile ? t("common:button.cancel") : t("common:button.prev")}
      okText={currentStep === StepType.AddToProduct ? t("import.importButton") : t("import.nextButton")}
    >
      <Steps size="small" current={currentStep}>
        <Step title={t("import.selectFile")} />
        <Step title={t("import.verifyList")} />
        <Step title={t("import.addToProduct")} />
      </Steps>
      <div className="max-h-[900px] min-h-[300px] overflow-y-auto">{stepSwitch()}</div>
    </Modal>
  );
};

export default ImportClientsModal;
