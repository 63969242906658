import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form, InputNumber } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

import { traineeMeasurementsActions, traineeMeasurementsSelectors } from "@fitness-app/app-store";
import { MeasurementType } from "@fitness-app/data-models/entities/Measurement";
import { BODY_FIELDS } from "@fitness-app/data-models/shared/BodyFields";

import { type BodyMeasurementFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/BodyMeasurementForm/types";
import { useAppDispatch } from "~/store/initializeStore";

interface BodyMeasurementFormProps {
  formController?: FormInstance<BodyMeasurementFormModel>;
  onSubmit: (formData: BodyMeasurementFormModel) => void;
  model?: Partial<BodyMeasurementFormModel> | null;
  traineeId: string;
}

const BodyMeasurementForm = ({
  formController,
  onSubmit,
  model,
  traineeId,
}: BodyMeasurementFormProps): React.ReactElement => {
  const { t } = useTranslation(["trainees", "common"]);
  const [selectedDate, setDate] = useState(model?.date || dayjs());
  const [fetchingData, toggleLoader] = useState(false);
  const dispatch = useAppDispatch();
  const latestMeasurement = useSelector(traineeMeasurementsSelectors.getLatestBodyMeasurements, shallowEqual);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const fetchMeasurementForSelectedDate = async (d: dayjs.Dayjs) => {
    try {
      toggleLoader(true);
      const result = await dispatch(
        traineeMeasurementsActions.fetchMeasurementForDay({
          eventDate: d.format("YYYY-MM-DD"),
          type: MeasurementType.BodyMeasurements,
          traineeId,
        }),
      ).unwrap();

      if (result && result.type === MeasurementType.BodyMeasurements) {
        formController?.setFieldsValue({
          id: result.eventDate === d.format("YYYY-MM-DD") ? result.id : null,
          data: result.data,
          previousMeasurement: result,
        });
      } else {
        formController?.setFieldsValue({
          id: null,
          ...(latestMeasurement.withVariation
            ? {
                data: Object.fromEntries(
                  latestMeasurement.withVariation.map((field) => [field.name, field.value ?? 0]),
                ),
              }
            : {}),
        });
      }
      toggleLoader(false);
    } catch {
      toggleLoader(false);
    }
  };

  useEffect(() => {
    void fetchMeasurementForSelectedDate(selectedDate);
  }, [selectedDate]);

  const getDisabledDate = useCallback((current: dayjs.Dayjs) => current && current > dayjs().endOf("day"), []);

  return (
    <Form<BodyMeasurementFormModel>
      name="add-body-measurement-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        weight: 0,
        date: selectedDate,
        id: null,
        data: Object.fromEntries(BODY_FIELDS.map((field) => [field.name, 0])),
        previousMeasurement: null,
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="date"
        label={t<string>("addWeightForm.date")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <DatePicker
          disabledDate={getDisabledDate}
          allowClear={false}
          format="DD.MM.YYYY"
          onChange={(date) => (date ? setDate(date) : undefined)}
        />
      </Form.Item>

      {BODY_FIELDS.map((field) => (
        <Form.Item
          key={field.name}
          name={["data", field.name]}
          wrapperCol={{ span: 10 }}
          label={t<string>(`${field.name}`)}
          rules={[
            {
              type: "number",
              min: 0,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
          status={fetchingData ? "validating" : undefined}
        >
          <InputNumber addonAfter={field.unit} />
        </Form.Item>
      ))}

      <Form.Item name="id" hidden preserve />

      <Form.Item hidden preserve name="previousMeasurement" />
    </Form>
  );
};

export default BodyMeasurementForm;
