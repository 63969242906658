import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramAutomationTask } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_SCHEDULED_TASKS_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  task: Partial<ProgramAutomationTask>;
  isOptimisticUpdate?: boolean;
};

export const updateClientTask = createAsyncThunk<ProgramAutomationTask | null, Payload, AsyncThunkCreator<string>>(
  `${CLIENT_SCHEDULED_TASKS_REDUCER_NAME}/updateClientTask`,
  async (payload, { extra: { db, analytics } }) => {
    const { data } = await db
      .from("program_automation_task")
      .update({ ...payload.task, updatedAt: new Date().toISOString() })
      .eq("id", payload.id)
      .select("*")
      .single<ProgramAutomationTask>();

    analytics.track("update_client_task");

    return data;
  },
);
