import { type Database } from "@fitness-app/supabase";

import { type Ingredient, type IngredientWithPortion } from "./Ingredient";
import { type DishInMeal } from "./MealsPlan";

export type DishEntity = Database["public"]["Tables"]["dish"]["Row"];
export type DishEmbeddingEntity = Database["public"]["Tables"]["dish_embedding"]["Row"];
export type LatestSelectedProductsEntity = Database["public"]["Tables"]["latest_selected_products"]["Row"];
export type MealTypeEnum = Database["public"]["Enums"]["MealType"];
export type DishTypeEnum = Database["public"]["Enums"]["DishType"];
export type KitchenTypeEnum = Database["public"]["Enums"]["KitchenType"];
export type SensitiveIngredientEnum = Database["public"]["Enums"]["SensitiveIngredient"];

export const MealType: Record<Database["public"]["Enums"]["MealType"], Database["public"]["Enums"]["MealType"]> = {
  BREAKFAST: "BREAKFAST",
  SECOND_BREAKFAST: "SECOND_BREAKFAST",
  LUNCH: "LUNCH",
  DINNER: "DINNER",
  SNACK: "SNACK",
  SUPPER: "SUPPER",
} as const;

export const SensitiveIngredient: Record<
  Database["public"]["Enums"]["SensitiveIngredient"],
  Database["public"]["Enums"]["SensitiveIngredient"]
> = {
  MEAT: "MEAT",
  FISH: "FISH",
  SOY: "SOY",
  TREE_NUTS: "TREE_NUTS",
  SHELLFISH: "SHELLFISH",
  EGGS: "EGGS",
  DAIRY: "DAIRY",
  GLUTEN: "GLUTEN",
  PEANUTS: "PEANUTS",
};

export const DishType: Record<Database["public"]["Enums"]["DishType"], Database["public"]["Enums"]["DishType"]> = {
  KETO: "KETO",
  VEGETARIAN: "VEGETARIAN",
  SEMI_VEGETARIAN: "SEMI_VEGETARIAN",
  VEGAN: "VEGAN",
  GLUTEN_FREE: "GLUTEN_FREE",
  DIABETIC: "DIABETIC",
  DAIRY_FREE: "DAIRY_FREE",
  HIGH_PROTEIN: "HIGH_PROTEIN",
  HIGH_FIBER: "HIGH_FIBER",
  LOW_CARB: "LOW_CARB",
  LOW_FAT: "LOW_FAT",
  LACTOSE_FREE: "LACTOSE_FREE",
  LOW_CHOLESTEROL: "LOW_CHOLESTEROL",
  PALEO: "PALEO",
  LOW_FOODMAP: "LOW_FOODMAP",
};

export const KitchenType: Record<
  Database["public"]["Enums"]["KitchenType"],
  Database["public"]["Enums"]["KitchenType"]
> = {
  POLISH: "POLISH",
  ITALIAN: "ITALIAN",
  SPANISH: "SPANISH",
  FRENCH: "FRENCH",
  MEXICAN: "MEXICAN",
  ASIAN: "ASIAN",
  AMERICAN: "AMERICAN",
  GREEK: "GREEK",
  GERMAN: "GERMAN",
  THAI: "THAI",
  BRITISH: "BRITISH",
};
export const dishTags = [
  "fastPrepTime",
  "cheap",
  "fit",
  "afterWorkout",
  "lowGlycemicIndex",
  "salad",
  "summer",
  "winter",
  "withoutWheat",
  "soup",
  "detox",
  "dessert",
  "onePot",
  "thermomix",
  "smoothie",
  "noCooking",
  "simple",
  "instantPot",
  "lowCalories",
  "sugarFree",
];

export const dishSizeTypes = [1, 2, 3];

export interface DishIngredient extends Ingredient {
  portion: number;
  portionCalories: number;
  measure: string;
  measureWeight: number;
  calories: number;
  id: string;
  ingredientId: string;
  hint: string | null;
  measureId: number | null;
  measureCapacity: string | null;
  multiplier?: number;
}

export interface Dish extends DishEntity {
  ingredients: DishIngredient[];
  type: "dish";
  media:
    | {
        url: string;
        type: string;
      }[]
    | null;
  metadata: Record<string, string | number | null> | null;
  preparationSteps: string[];
}

export interface DishEmbedding extends DishEmbeddingEntity {
  ingredients: DishIngredient[];
  parentId: string;
  type: "dish";
  media:
    | {
        url: string;
        type: string;
      }[]
    | null;
  metadata: Record<string, string | number | null> | null;
  preparationSteps: string[];
}

export interface LatestSelectedProducts extends Omit<LatestSelectedProductsEntity, "products"> {
  products: (DishInMeal | IngredientWithPortion)[];
}
