import * as Sentry from "@sentry/browser";

export class Logger {
  public static error(error: unknown, message?: string) {
    if (message) {
      Sentry.captureMessage(message);
    }
    Sentry.captureException(error);
  }
}
