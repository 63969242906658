import { createAsyncThunk } from "@reduxjs/toolkit";
import { type EndAccessStrategy } from "@fitness-app/data-models/entities/Product";
import {
  type OneTimeProductAccessPeriod,
  type SubscriptionProductAccessPeriod,
} from "@fitness-app/data-models/entities/ProductClient";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  productId: string;
  clientEmail: string;
  updatedAccessStrategy: EndAccessStrategy;
  clientAccessPeriod: SubscriptionProductAccessPeriod | OneTimeProductAccessPeriod;
};

export const updateProductEndAccessStrategy = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CLIENT_REDUCER}/updateProductEndAccessStrategy`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error } = await db
      .from("product_client")
      .update({
        accessPeriod: {
          ...payload.clientAccessPeriod,
          endAccessStrategy: payload.updatedAccessStrategy,
        },
      })
      .eq("email", payload.clientEmail);

    if (error) {
      return rejectWithValue(error.message);
    }
  },
);
