import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

export const mailerOptions = [
  {
    value: EmailClient.ActiveCampaign,
  },
  {
    value: EmailClient.ConvertKit,
  },
  {
    value: EmailClient.MailerLite,
  },
  {
    value: EmailClient.MailChimp,
  },
  {
    value: EmailClient.GetResponse,
  },
  {
    value: EmailClient.FreshMail,
  },
];
