import orderBy from "lodash.orderby";

import {
  ExerciseBodyPart,
  ExerciseType,
  ExerciseVideoType,
  type ExerciseBodyPartEnum,
  type ExerciseTypeEnum,
  type ExerciseVideoTypeEnum,
  type VideoExerciseLink,
} from "@fitness-app/data-models/entities/Exercise";
import {
  MediaLibraryType,
  VideoUploadStatus,
  type MediaLibraryItem,
} from "@fitness-app/data-models/entities/MediaLibrary";

import i18next from "~/i18nextConfig";

export interface ExerciseFormModel {
  name: string;
  type: ExerciseTypeEnum | null;
  bodyParts: ExerciseBodyPartEnum[];
  videoType: ExerciseVideoTypeEnum;
  videoLink: null | string;
  instruction: string[];
  links: VideoExerciseLink[];
  description: string;
  updateConnectedExercises?: boolean;
  videoId?: string | null;
  tags?: string[];
}

export const exerciseTypeOptions = [
  {
    label: i18next.t(`workouts:exerciseType.${ExerciseType.POWER}`),
    value: ExerciseType.POWER,
  },
  {
    label: i18next.t(`workouts:exerciseType.${ExerciseType.CARDIO}`),
    value: ExerciseType.CARDIO,
  },
  {
    label: i18next.t(`workouts:exerciseType.${ExerciseType.MOBILIZATION}`),
    value: ExerciseType.MOBILIZATION,
  },
  {
    label: i18next.t(`workouts:exerciseType.${ExerciseType.STRETCHING}`),
    value: ExerciseType.STRETCHING,
  },
  {
    label: i18next.t(`workouts:exerciseType.${ExerciseType.CALISTHENICS}`),
    value: ExerciseType.CALISTHENICS,
  },
  {
    label: i18next.t(`workouts:exerciseType.${ExerciseType.REHABILITATION}`),
    value: ExerciseType.REHABILITATION,
  },
];

export const bodyPartOptions = [
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.ARMS}`),
    value: ExerciseBodyPart.ARMS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.ABS}`),
    value: ExerciseBodyPart.ABS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.CHEST}`),
    value: ExerciseBodyPart.CHEST,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.GENERAL_DEVELOPMENT}`),
    value: ExerciseBodyPart.GENERAL_DEVELOPMENT,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.LEGS}`),
    value: ExerciseBodyPart.LEGS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.BACK}`),
    value: ExerciseBodyPart.BACK,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.SHOULDERS}`),
    value: ExerciseBodyPart.SHOULDERS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.GLUTS}`),
    value: ExerciseBodyPart.GLUTS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.CORE}`),
    value: ExerciseBodyPart.CORE,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.FOREARMS}`),
    value: ExerciseBodyPart.FOREARMS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.BICEPS}`),
    value: ExerciseBodyPart.BICEPS,
  },
  {
    label: i18next.t(`workouts:bodyPart.${ExerciseBodyPart.TRICEPS}`),
    value: ExerciseBodyPart.TRICEPS,
  },
];

export const videoTypeOptions = Object.values(ExerciseVideoType).map((type) => ({
  value: type,
  disabled: type !== ExerciseVideoType.NONE && type !== ExerciseVideoType.MEDIA_LIBRARY,
  label: i18next.t(`workouts:exerciseVideoType.${type}`),
}));

export const createMediaLibraryVideoOptions = (list: MediaLibraryItem[]) => {
  const onlyWithCompletedStatus = list.filter(
    (video) => video.type === MediaLibraryType.Video && video.data.uploadStatus === VideoUploadStatus.Ready,
  );
  return orderBy(
    onlyWithCompletedStatus.map((item) => ({
      value: item.id,
      label: item.title,
    })),
    "label",
  );
};
