import { useUserRole } from "~/hooks/trainer/useUserRole";
import { AppStats } from "~/modules/Dashboard/AppStats/AppStats";
import { TeamMemberDashboard } from "~/modules/Dashboard/TeamMemberDashboard/TeamMemberDashboard";

export default function Home() {
  const { isTeamMember, isTrainer } = useUserRole();

  if (isTeamMember) {
    return <TeamMemberDashboard />;
  }

  if (isTrainer) {
    return <AppStats />;
  }

  return <main className="flex flex-col gap-6" />;
}
