import { createAsyncThunk } from "@reduxjs/toolkit";

import { type UserStatus } from "@fitness-app/data-models/entities/UserStatus";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  userId: string;
};

export const fetchTraineeStatus = createAsyncThunk<UserStatus | null, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/fetchTraineeStatus`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db.from("user_status").select("*").eq("id", payload.userId).maybeSingle<UserStatus>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
