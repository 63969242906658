import React, { useEffect, useMemo, useRef, useState, type FunctionComponent } from "react";
import { CalendarOutlined, FileDoneOutlined, MessageOutlined, TagOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  DatePicker,
  Divider,
  Form,
  List,
  message,
  Popconfirm,
  Select,
  Space,
  Tree,
  Typography,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs, { type Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { sortBy } from "lodash";
import isEqual from "lodash.isequal";
import orderBy from "lodash.orderby";
import uniq from "lodash.uniq";
import { useTranslation } from "react-i18next";

import { programAutomationActions, traineeProgramActions } from "@fitness-app/app-store";
import {
  TemplateType,
  type BaseSurveyTemplate,
  type ScheduledSurveyAutomation,
} from "@fitness-app/data-models/entities/AutomationTemplate";
import { SurveyType } from "@fitness-app/data-models/entities/ClientSurvey";
import {
  type DayContent,
  type ProgramAutomation,
  type ScheduledItems,
  type WorkoutRoutine,
} from "@fitness-app/data-models/entities/ProgramAutomation";
import { getUserInitials } from "@fitness-app/utils";

import { useAuthorOptions } from "~/hooks/trainer/useAuthorOptions";
import ScheduleProgramStepper, {
  useScheduleProgramStepper,
} from "~/modules/Automation/ScheduleProgramStepper/ScheduleProgramStepper";
import { getMinutesWithLeadingZero } from "~/modules/Automation/utils/getMinutesWithLeadingZero";
import AutomationVariablesForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/AutomationScheduleSelectorForm/AutomationVariablesForm";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);

dayjs.locale("pl");

type AutomationScheduleSelectorFormModel = {
  automationTemplate: string;
  firstDay: Date;
  authorId?: string;
};

interface OwnProps {
  formController?: FormInstance<AutomationScheduleSelectorFormModel>;
  onSubmit: ({
    events,
    scheduledPrograms,
    startDate,
    clientId,
    programAutomationId,
    programAutomationName,
  }: {
    events: ScheduledItems[];
    scheduledPrograms: WorkoutRoutine[];
    startDate: string;
    clientId: string;
    programAutomationId: string;
    programAutomationName: string;
  }) => void;
}

type Props = OwnProps;

type ScheduleTree = {
  day: DayContent;
  dayOffset: number;
  dayDate: string;
};

const createAutomationSchedule = (automation?: ProgramAutomation | null, startDate?: Dayjs | null) => {
  if (!automation || !startDate) {
    return [];
  }
  const firstDay = dayjs(startDate);
  const weeks = Object.entries(automation.schedule)
    .map(([weekStr, week]) => {
      const weekNumber = Number(weekStr) - 1;

      return Object.entries(week)
        .map(([dayStr, day]) => {
          const dayNumber = Number(dayStr) - 1;
          const dayOffset = dayNumber + weekNumber * 7;

          return {
            day: day.items.length ? day : null,
            dayOffset,
            dayDate: firstDay.add(dayOffset, "day").format("DD-MM-YYYY"),
          };
        })
        .filter((item): item is ScheduleTree => !!item.day);
    })
    .flat();

  return weeks;
};

const today = dayjs();

const SEPARATOR = "_";

const AutomationScheduleSelectorForm: FunctionComponent<Props> = ({ onSubmit, formController }) => {
  const { t } = useTranslation(["automation", "common"]);
  const { data } = useAppSelector((store) => store.programAutomation);
  const [variableFormController] = Form.useForm<Record<string, string | number>>();

  const clientData = useAppSelector((store) => store.trainee.profile);
  const { selectedProgram: activeProgram, scheduledPrograms } = useAppSelector((store) => store.traineeProgram);
  const {
    visible: showScheduledProgramEditor,
    closeModal: closeEditModal,
    openModal: openEditModal,
    routineModel,
  } = useScheduleProgramStepper();
  const [selectedAutomation, setAutomation] = useState<null | ProgramAutomation | undefined>();
  const [startDate, setStartDate] = useState<null | Dayjs>(null);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const previousKeys = useRef<string[]>([]);
  const dispatch = useAppDispatch();
  const surveyTemplates = useAppSelector((store) => store.surveysTemplates.list);
  const surveyTemplatesById = useMemo(() => {
    return Object.fromEntries(
      surveyTemplates.map((item) => {
        if (!item.automation) {
          return [item.id, {} as Record<string, string>];
        }

        return [
          item.id,
          Object.fromEntries(
            uniq(
              Object.values(item.automation)
                .map((automations) =>
                  automations.map((automation) => automation.tasks.map((task) => task.variables)).flat(2),
                )
                .flat(2),
            )
              .filter((variable): variable is string => typeof variable === "string")
              .sort()
              .map((variable) => [variable, ""]),
          ) as Record<string, string>,
        ];
      }),
    );
  }, [surveyTemplates]);

  useEffect(() => {
    void dispatch(programAutomationActions.fetchProgramAutomations());
  }, []);

  const traineeId = clientData?.id;

  useEffect(() => {
    if (traineeId) {
      void dispatch(traineeProgramActions.fetchScheduledPrograms({ traineeId }));
    }
  }, [dispatch, traineeId]);

  const schedule = useMemo(
    () => createAutomationSchedule(selectedAutomation, startDate),
    [selectedAutomation, startDate],
  );

  useEffect(() => {
    const allKeys = schedule
      .map((item) => item.day.items.map((dayItem) => `${item.day.id}${SEPARATOR}${dayItem.id}`))
      .flat();
    if (startDate && !isEqual(previousKeys.current, allKeys)) {
      setSelectedKeys(allKeys);
    }
    previousKeys.current = allKeys;
  }, [selectedAutomation, startDate]);

  const onAutomationSelect = async (value: string) => {
    const automation = await dispatch(programAutomationActions.fetchProgramAutomation(value)).unwrap();
    setAutomation(automation);
  };

  const authorOptions = useAuthorOptions();

  const treeData = useMemo(
    () =>
      schedule.map((item) => ({
        title: item.dayDate,
        key: item.day.id,
        icon: <CalendarOutlined />,
        children: orderBy(item.day.items, ["sentTime.hours", "sentTime.minutes"]).map((dayItem) => ({
          title: `${dayItem.sentTime.hours}:${getMinutesWithLeadingZero(dayItem.sentTime.minutes)} - ${
            "name" in dayItem
              ? dayItem.name
              : `${t(`common:emailClientTask.${dayItem.action.type}`)} - ${
                  "emailLists" in dayItem.action
                    ? dayItem.action.emailLists.map((item) => `${item.client}`).join(", ")
                    : dayItem.action.emailClients.map((item) => `${item.client} - ${item.account}`).join(", ")
                }`
          }`,
          icon:
            dayItem.type === TemplateType.ScheduledMessages ? (
              <MessageOutlined />
            ) : dayItem.type === TemplateType.ScheduledSurvey ? (
              <FileDoneOutlined />
            ) : (
              <TagOutlined />
            ),
          key: `${item.day.id}${SEPARATOR}${dayItem.id}`,
          children:
            dayItem.type === TemplateType.ScheduledMessages
              ? dayItem.messages.map((message, i) => ({
                  title: `- ${message}`,
                  key: `${item.day.id}-${dayItem.id}-${i}`,
                  checkable: false,
                }))
              : [],
        })),
      })),
    [schedule],
  );

  const routinesWithDates = useMemo(() => {
    if (!selectedAutomation?.scheduledWorkoutsRoutine?.length || !startDate) {
      return [];
    }
    let previousEndDate: null | Dayjs = null;

    return sortBy(selectedAutomation.scheduledWorkoutsRoutine, "startWeek").map((routine, i) => {
      if (!selectedAutomation.scheduledWorkoutsRoutine[i - 1]) {
        previousEndDate = startDate.add(routine.duration, "week").subtract(1, "day").endOf("day");
        return {
          ...routine,
          startAt: startDate,
          endAt: previousEndDate,
        };
      }

      if (!previousEndDate) {
        throw new Error("Cannot calculate start date for scheduled program");
      }

      const nextStartDate = previousEndDate.add(1, "day");
      previousEndDate = nextStartDate.add(routine.duration, "week").subtract(1, "day").endOf("day");

      return {
        ...routine,
        startAt: nextStartDate,
        endAt: previousEndDate,
      };
    });
  }, [selectedAutomation, startDate]);

  const { hasRecurringSurvey, variables } = useMemo(() => {
    const surveys = schedule
      .map((item) =>
        item.day.items.some(
          (item) => item.type === TemplateType.ScheduledSurvey && item.survey.type === SurveyType.Recurring,
        ),
      )
      .flat()
      .some(Boolean);

    const variables = schedule
      .map((item) =>
        item.day.items.filter((item): item is ScheduledSurveyAutomation => {
          return Boolean(
            item.type === TemplateType.ScheduledSurvey &&
              (item.survey.variables ||
                (item.survey.templateId && Object.keys(surveyTemplatesById[item.survey.templateId]!)?.length)),
          );
        }),
      )
      .flat()
      .map((item) =>
        item.survey.templateId
          ? { ...surveyTemplatesById[item.survey.templateId], ...item.survey.variables }
          : item.survey.variables,
      )
      .reduce<BaseSurveyTemplate["variables"]>((acc, current) => {
        return {
          ...acc,
          ...current,
        };
      }, {});

    return {
      hasRecurringSurvey: surveys,
      variables,
    };
  }, [schedule, surveyTemplatesById]);

  const handleSubmit = async (formData: AutomationScheduleSelectorFormModel) => {
    if (!startDate || !selectedAutomation || !clientData) {
      return null;
    }

    try {
      const values = await variableFormController?.validateFields();

      const scheduledEventIds = selectedKeys.map((key) => key.split(SEPARATOR)[1]);

      const foundAuthorData = authorOptions.find((author) => author.value === formData.authorId);

      const selectedEvents = schedule
        .map((item) => ({
          sendDate: dayjs(item.dayDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          id: item.day.id,
          items: item.day.items
            .filter((event) => scheduledEventIds.includes(event.id))
            .map((task) =>
              task.type === TemplateType.ScheduledMessages && foundAuthorData
                ? {
                    ...task,
                    authorId: foundAuthorData.value,
                    authorRole: foundAuthorData.role,
                    authorName: foundAuthorData.label,
                    authorAvatar: foundAuthorData.avatarUrl ?? null,
                  }
                : task.type === TemplateType.ScheduledSurvey
                ? {
                    ...task,
                    survey: {
                      ...task.survey,
                      variables: {
                        ...(task.survey.variables || {}),
                        ...values,
                      },
                    },
                  }
                : task,
            ),
        }))
        .filter((day) => day.items.length);

      onSubmit({
        events: selectedEvents,
        startDate: startDate?.format(""),
        scheduledPrograms: routinesWithDates,
        clientId: clientData.id,
        programAutomationId: selectedAutomation.id,
        programAutomationName: selectedAutomation.name,
      });
    } catch {
      void message.error("Uzupełnij wymagane pola formularza");
    }
  };

  const showAlertAboutProgramOverride =
    activeProgram && routinesWithDates[0]?.startAt.isSameOrBefore(dayjs(activeProgram.endDate, "YYYY-MM-DD"), "date");

  const onSubmitScheduledProgram = (workoutRoutine: WorkoutRoutine) => {
    if (selectedAutomation?.scheduledWorkoutsRoutine?.length) {
      setAutomation({
        ...selectedAutomation,
        scheduledWorkoutsRoutine: selectedAutomation.scheduledWorkoutsRoutine.map((item) =>
          item.id === workoutRoutine.id ? workoutRoutine : item,
        ),
      });
    }

    closeEditModal();
  };

  const onSubmitFormVariables = (_model: Record<string, string | number>) => {};

  return (
    <>
      <Form<AutomationScheduleSelectorFormModel>
        name="login-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        form={formController}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="automationTemplate"
          label={t("automationSchedule.selectSchedule")}
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <Select
            showSearch
            onSelect={onAutomationSelect}
            placeholder={t("automationSchedule.selectSchedulePlaceholder")}
            filterOption={(inputValue, option) => {
              if (option && "label" in option) {
                return (
                  String(option?.label)
                    .toUpperCase()
                    .indexOf(inputValue?.toUpperCase()) !== -1
                );
              }

              return false;
            }}
            options={data.map((automation) => ({ value: automation.id, label: automation.name }))}
          />
        </Form.Item>
        <Form.Item
          name="firstDay"
          label={t("automationSchedule.firstDay")}
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            onChange={setStartDate}
            disabledDate={(current) => dayjs(current).isBefore(today.startOf("week"), "day")}
          />
        </Form.Item>

        {treeData.length ? (
          <Form.Item
            name="authorId"
            label={t("automatedMessageForm.messageAuthor")}
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Select placeholder={t("automatedMessageForm.messageAuthorPlaceholder")}>
              {authorOptions.map((author) => (
                <Select.Option value={author.value} label={author.label} key={author.value}>
                  <Space direction="horizontal">
                    <Avatar src={author.avatarUrl} size={26}>
                      {getUserInitials(author.label)}
                    </Avatar>{" "}
                    {author.label}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
      </Form>
      {routinesWithDates.length ? (
        <div>
          <Typography.Title level={5}>Zaplanowane programy w automatyzacji:</Typography.Title>
          {showAlertAboutProgramOverride && (
            <Alert
              message={`Wybrana automatyzacja nadpiszę obecnie dodany plan treningowy, który kończy się ${dayjs(
                activeProgram?.endDate,
                "YYYY-MM-DD",
              ).format("DD.MM.YYYY")}`}
              showIcon
              type="warning"
              style={{
                marginBottom: 12,
              }}
            />
          )}

          <List
            itemLayout="horizontal"
            bordered
            dataSource={routinesWithDates}
            renderItem={(routine) => (
              <List.Item
                actions={[
                  <Typography.Link key="edit" onClick={() => openEditModal(routine)}>
                    edytuj
                  </Typography.Link>,
                  <Popconfirm
                    key="remove"
                    title="Czy na pewno chcesz usunąc ten program z automatyzacji?"
                    okText="Tak"
                    cancelText="Nie"
                    placement="left"
                    onConfirm={() =>
                      setAutomation((prev) => {
                        if (!prev?.scheduledWorkoutsRoutine) {
                          return prev;
                        }
                        return {
                          ...prev,
                          scheduledWorkoutsRoutine: prev.scheduledWorkoutsRoutine.filter(
                            (item) => item.id !== routine.id,
                          ),
                        };
                      })
                    }
                  >
                    <Typography.Link type="danger">usuń</Typography.Link>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={<Typography.Text strong>{routine.name}</Typography.Text>}
                  description={
                    <Space direction="horizontal" size={8}>
                      <CalendarOutlined />
                      <Typography.Text type="secondary">
                        {routine.startWeek !== routine.endWeek
                          ? `Plan od ${routine.startAt.format("DD.MM.YYYY")} do ${routine.endAt.format("DD.MM.YYYY")}`
                          : `Plan dla tygodnia ${routine.startWeek}`}
                      </Typography.Text>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
          {scheduledPrograms?.length ? (
            <Typography.Text type="secondary">
              Zaplanowane obecnie programy treningowe w przyszłości zostaną nadpisane przez te zmiany
            </Typography.Text>
          ) : null}
        </div>
      ) : null}

      <div>
        <Divider orientation="center">Harmonogram zadań</Divider>
        <Tree
          checkable
          showIcon
          selectable={false}
          checkedKeys={selectedKeys}
          onCheck={(keys) => setSelectedKeys(keys as string[])}
          treeData={treeData}
        />
      </div>
      {selectedAutomation && (
        <ScheduleProgramStepper
          onSubmit={onSubmitScheduledProgram}
          visible={showScheduledProgramEditor}
          handleClose={closeEditModal}
          routineModel={routineModel || undefined}
          selectedAutomation={selectedAutomation}
          withoutWeeksLimit
        />
      )}

      <div className="mt-4">
        {hasRecurringSurvey && (
          <Typography.Text type="secondary" className="text-sm">
            W harmonogramie zadań widzisz tylko pierwsze wystąpienie cyklicznych ankiet. Kolejne wystąpienia będą
            generowanie automatycznie po wysłaniu poprzednich ankiet.
          </Typography.Text>
        )}
      </div>

      {variables && Object.keys(variables).length ? (
        <AutomationVariablesForm
          formController={variableFormController}
          model={variables}
          variables={Object.keys(variables)}
          onSubmit={onSubmitFormVariables}
        />
      ) : null}
    </>
  );
};

export default AutomationScheduleSelectorForm;
