import { createSlice } from "@reduxjs/toolkit";
import uniq from "lodash.uniq";
import uniqBy from "lodash.uniqby";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchSupplementsTemplates } from "./actions/fetchSupplementsTemplates";
import { removeTemplate } from "./actions/removeTemplate";
import { updateTemplate } from "./actions/updateTemplate";
import { SUPPLEMENTS_TEMPLATES_REDUCER_NAME, type SupplementsTemplatesReducer } from "./types";

const initialState: SupplementsTemplatesReducer = {
  list: [],
  listStatus: null,
  tags: [],
  creators: [],
};

const reducerSlice = createSlice({
  initialState,
  name: SUPPLEMENTS_TEMPLATES_REDUCER_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSupplementsTemplates.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchSupplementsTemplates.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.list = payload;
      if (payload.length) {
        state.tags = uniq(payload.reduce<string[]>((prev, current) => prev.concat(current.tags || []), []));

        state.creators = uniqBy(
          payload.reduce<{ label: string; value: string }[]>((prev, current) => {
            if (current.creator) {
              prev.push({
                label: `${current.creator.firstName} ${current.creator.lastName}`,
                value: current.creator.id,
              });
            }
            return prev;
          }, []),
          "value",
        );
      }
    });
    builder.addCase(fetchSupplementsTemplates.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(updateTemplate.pending, (state, { meta }) => {
      if (meta.arg.templateId) {
        state.list = state.list.map((template) => {
          if (template.id === meta.arg.templateId) {
            return { ...template, ...meta.arg.template };
          }
          return template;
        });
      }
    });
    builder.addCase(removeTemplate.fulfilled, (state, { payload }) => {
      if (payload) {
        state.list = state.list.filter((template) => template.id !== payload);
      }
    });
  },
});

export default reducerSlice.reducer;
