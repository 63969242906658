import React, { memo, useCallback, useMemo, useState } from "react";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, message, Space, Table } from "antd";
import dayjs from "dayjs";
import { keyBy } from "lodash";
import cloneDeep from "lodash.clonedeep";
import groupBy from "lodash.groupby";
import last from "lodash.last";
import omit from "lodash.omit";
import sortBy from "lodash.sortby";
import { Utensils } from "lucide-react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { automationTemplatesActions, programAutomationActions, RequestStatus } from "@fitness-app/app-store";
import { ProgramService } from "@fitness-app/data-models/domain/services/ProgramService";
import {
  EmailClientTask,
  TaskCreator,
  TaskType,
  type AutomationTask,
  type FieldValuesEmailClient,
  type FieldValuesEmailList,
  type TagsEmailClient,
  type TagsEmailList,
} from "@fitness-app/data-models/entities/Automation";
import {
  TemplateType,
  type ScheduledEmailIntegrationAutomation,
  type ScheduledMessagesAutomation,
  type ScheduledMessagesTemplate,
  type ScheduledSurveyAutomation,
  type ScheduledSurveyAutomationPlanned,
} from "@fitness-app/data-models/entities/AutomationTemplate";
import { CycleSurveyType, SurveyType } from "@fitness-app/data-models/entities/ClientSurvey";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";
import {
  type DayContent,
  type WeekdayNumberToString,
  type WeekdaysNumber,
} from "@fitness-app/data-models/entities/ProgramAutomation";
import { type ProgramWorkout, type WeekDay } from "@fitness-app/data-models/entities/TrainingProgram";

import ModalForm from "~/components/ModalForm/ModalForm";
import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { useLoading } from "~/hooks/useLoading";
import AutomatedMessagesForm from "~/modules/Automation/AutomatedMessagesForm/AutomatedMessagesForm";
import { FormMode, type AutomatedMessagesFormModel } from "~/modules/Automation/AutomatedMessagesForm/types";
import AddAutomationButton from "~/modules/Automation/ProgramAutomation/components/AddAutomationButton/AddAutomationButton";
import AddSurveyTemplateForm from "~/modules/Automation/ProgramAutomation/components/AddSurveyTemplateForm/AddSurveyTemplateForm";
import { SurveyAutomationItem } from "~/modules/Automation/ProgramAutomation/components/AddSurveyTemplateForm/SurveyAutomationItem";
import EmailClientTaskItem from "~/modules/Automation/ProgramAutomation/components/EmailClientTaskItem/EmailClientTaskItem";
import { useSurveyTemplateForm } from "~/modules/Automation/ProgramAutomation/components/hooks/useSurveyTemplateForm";
import MessageAutomationItem from "~/modules/Automation/ProgramAutomation/components/MessageAutomationItem/MessageAutomationItem";
import ProgramAutomationTaskForm from "~/modules/Automation/ProgramAutomation/components/ProgramAutomationTaskForm/AutomationTaskForm";
import { type ProgramAutomationTaskFormModel } from "~/modules/Automation/ProgramAutomation/components/ProgramAutomationTaskForm/types";
import { useProgramAutomationTaskForm } from "~/modules/Automation/ProgramAutomation/components/ProgramAutomationTaskForm/useProgramAutomationTaskForm";
import { createAutomationWeekTemplate } from "~/modules/Automation/ProgramAutomation/helpers";
import { type AutomationRow } from "~/modules/Automation/ProgramAutomation/ProgramAutomationDetails";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const weekdays = Array.from({ length: 7 });
const weekdaysArr = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const AutomationScheduleTable = () => {
  const { t } = useTranslation(["automation", "common"]);
  const {
    visible: automationTaskFormVisible,
    closeModal: closeTaskModal,
    openModal: openTaskModal,
    programAutomationTaskFormController,
    editedTask,
  } = useProgramAutomationTaskForm();
  const [loading, startLoading, stopLoading] = useLoading();
  const [selectedCell, setCell] = useState<null | {
    week: string;
    day: WeekdayNumberToString;
  }>(null);
  const [model, setModel] = useState<null | AutomatedMessagesFormModel>(null);
  const [showAutomatedMessagesForm, toggleAutomatedMessagesForm] = useState(false);
  const { selectedAutomation, itemStatus } = useAppSelector((store) => store.programAutomation);
  const { showSurveyForm, toggleSurveyForm, onSendSurveyForm } = useSurveyTemplateForm(selectedCell, () =>
    setCell(null),
  );

  const dispatch = useAppDispatch();

  const handleSubmit = async (formData: AutomatedMessagesFormModel) => {
    if (!selectedAutomation || !selectedCell) {
      return;
    }

    startLoading();

    const updatedModel: ScheduledMessagesAutomation = model
      ? {
          ...model,
          ...formData,
          id: model.id || uuid(),
          attachments: [],
          type: TemplateType.ScheduledMessages,
          templateId: formData.id || null,
          sentTime: {
            hours: formData.sentTime.get("hour"),
            minutes: formData.sentTime.get("minutes"),
          },
        }
      : {
          ...formData,
          attachments: [],
          id: uuid(),
          type: TemplateType.ScheduledMessages,
          templateId: formData.id || null,
          sentTime: {
            hours: formData.sentTime.get("hour"),
            minutes: formData.sentTime.get("minutes"),
          },
        };

    const weekToUpdate = cloneDeep(selectedAutomation.schedule[selectedCell.week]) || {};

    if (model) {
      weekToUpdate[selectedCell.day] = {
        id: weekToUpdate[selectedCell.day]?.id ?? "",
        items:
          weekToUpdate[selectedCell.day]?.items.map((item) => (item.id !== updatedModel.id ? item : updatedModel)) ??
          [],
      };
    } else {
      const itemsToUpdate = weekToUpdate[selectedCell.day];
      weekToUpdate[selectedCell.day] = {
        id: weekToUpdate[selectedCell.day]?.id ?? uuid(),
        items: itemsToUpdate?.items ? [...itemsToUpdate.items, updatedModel] : [updatedModel],
      };
    }

    try {
      await dispatch(
        programAutomationActions.updateAutomationTemplate({
          automationId: selectedAutomation.id,
          automation: {
            id: selectedAutomation.id,
            schedule: {
              ...selectedAutomation.schedule,
              [selectedCell.week]: weekToUpdate,
            },
          },
        }),
      ).unwrap();
      if (formData.saveAsTemplate) {
        const payload: ScheduledMessagesTemplate = {
          id: uuid(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          name: formData.name,
          comment: formData.comment || "",
          type: TemplateType.ScheduledMessages,
          data: {
            attachments: [],
            messages: formData.messages,
            authorId: formData.authorId,
            authorRole: formData.authorRole,
            authorName: formData.authorName,
            sentTime: {
              hours: formData.sentTime.get("hour"),
              minutes: formData.sentTime.get("minutes"),
            },
          },
        };
        await dispatch(automationTemplatesActions.addTemplate(payload));
      }
      stopLoading();
      toggleAutomatedMessagesForm(false);
      setModel(null);
      setCell(null);
    } catch {
      stopLoading();
      void message.error(t("saveError"));
    }
  };

  const setEditedModelForAutomatedMessages = (
    task: ScheduledMessagesAutomation,
    day: WeekdayNumberToString,
    week: string,
  ) => {
    setCell({ day, week });
    setModel({
      ...task,
      comment: task.comment ?? "",
      sentTime: dayjs().set("hour", task.sentTime.hours).set("minutes", task.sentTime.minutes),
      mode: FormMode.Template,
    });
    toggleAutomatedMessagesForm(true);
  };

  const handleSubmitEmailTask = (formData: ProgramAutomationTaskFormModel) => {
    if (!selectedAutomation || !selectedCell) {
      return;
    }

    let action: AutomationTask["action"] | null = null;
    let automationTask: ScheduledEmailIntegrationAutomation | null = null;

    const taskBaseData = {
      active: formData.active,
      description: formData.description,
      id: uuid(),
      protected: formData.protected ?? false,
      creator: formData.creator ?? TaskCreator.Owner,
      sentTime: {
        hours: formData.sentTime.get("hour"),
        minutes: formData.sentTime.get("minutes"),
      },
    };

    if (formData.action.type === EmailClientTask.AddTags || formData.action.type === EmailClientTask.RemoveTags) {
      const lists: (TagsEmailList | TagsEmailClient)[] = formData.action.emailClients.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        if (id && name) {
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          } as TagsEmailList;
        }
        return {
          client: client as EmailClient,
          account,
        } as TagsEmailClient;
      });
      const groupByClient = groupBy(lists, "client");

      const getResponsesAccounts = groupByClient[EmailClient.GetResponse];

      if (getResponsesAccounts && getResponsesAccounts.length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }

      action = {
        ...formData.action,
        emailClients: lists,
      };

      automationTask = {
        ...taskBaseData,
        action,
        type: TaskType.EmailClient,
      };
    }

    if (
      formData.action.type === EmailClientTask.AddToLists ||
      formData.action.type === EmailClientTask.RemoveFromLists
    ) {
      const lists = formData.action.emailLists.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        return {
          client: client as EmailClient,
          account: account || "",
          id: id || "",
          name: name || "",
        };
      });
      const groupByClient = groupBy(lists, "client");

      const getResponsesAccounts = groupByClient[EmailClient.GetResponse];

      if (getResponsesAccounts && getResponsesAccounts.length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }

      action = {
        ...formData.action,
        emailLists: lists,
      };

      automationTask = {
        ...taskBaseData,
        action,
        type: TaskType.EmailClient,
      };
    }

    if (formData.action.type === EmailClientTask.AddFieldValues) {
      const lists: (FieldValuesEmailList | FieldValuesEmailClient)[] = formData.action.emailLists.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        if (id && name) {
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          } as FieldValuesEmailList;
        }
        return {
          client: client as EmailClient,
          account,
        } as FieldValuesEmailClient;
      });
      const groupByClient = groupBy(lists, "client");

      const getResponsesAccounts = groupByClient[EmailClient.GetResponse];

      if (getResponsesAccounts && getResponsesAccounts.length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }

      action = {
        ...formData.action,
        emailLists: lists,
        values: formData.action.values
          ? Object.fromEntries(formData.action.values?.map((item) => [item.key, item.value]))
          : {},
      };

      automationTask = {
        ...taskBaseData,
        action,
        type: TaskType.EmailClient,
      };
    }
    const weekToUpdate = cloneDeep(selectedAutomation.schedule[selectedCell.week]) || {};

    if (!automationTask) {
      return null;
    }

    weekToUpdate[selectedCell.day] = {
      id: weekToUpdate[selectedCell.day]?.id ?? uuid(),
      items: [...(weekToUpdate[selectedCell.day]?.items || []), automationTask],
    };

    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          schedule: {
            ...selectedAutomation.schedule,
            [selectedCell.week]: weekToUpdate,
          },
        },
      }),
    );

    toggleAutomatedMessagesForm(false);
    setCell(null);
    closeTaskModal();
  };

  const addNewWeek = () => {
    if (!selectedAutomation) {
      return;
    }
    const nextWeek = selectedAutomation?.schedule
      ? (Number(last(Object.keys(selectedAutomation?.schedule))) ?? 0) + 1
      : 1;
    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          schedule: {
            ...selectedAutomation.schedule,
            [isNaN(nextWeek) ? 1 : nextWeek]: createAutomationWeekTemplate(),
          },
        },
      }),
    );
  };

  const duplicateRow = (selectedWeek: string) => {
    if (!selectedAutomation) {
      return;
    }
    const nextWeek = selectedAutomation?.schedule
      ? (Number(last(Object.keys(selectedAutomation?.schedule))) ?? 0) + 1
      : 1;
    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          schedule: {
            ...selectedAutomation.schedule,
            [nextWeek]: createAutomationWeekTemplate(selectedAutomation.schedule[selectedWeek]),
          },
        },
      }),
    );
  };

  const removeRow = (selectedWeek: string) => {
    if (!selectedAutomation) {
      return;
    }
    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          schedule: omit(selectedAutomation.schedule, [selectedWeek]),
        },
      }),
    );
  };

  const schedule = useMemo(() => {
    if (!selectedAutomation?.schedule) {
      return [];
    }

    const plannedWeekNumber = Object.keys(selectedAutomation.schedule).length;
    const scheduledSurveysCycle: (ScheduledSurveyAutomationPlanned & { dayKey: string; weekKey: number })[] = [];

    const enhancedWithWorkouts = Object.entries(cloneDeep(selectedAutomation.schedule)).map(([key, values]) => {
      const weekNumber = Number(key);

      const planForWeek = selectedAutomation.scheduledWorkoutsRoutine.find(
        (routine) => routine.startWeek <= weekNumber && routine.endWeek >= weekNumber,
      );

      const nutritionPlanForWeek = selectedAutomation.scheduledNutritionPlan?.find(
        (routine) => routine.startWeek === weekNumber,
      );

      const scheduleForWeek = planForWeek
        ? ProgramService.getScheduleForProgramAutomationWeek(planForWeek, weekNumber)
        : null;

      const workoutsById = planForWeek
        ? keyBy(
            [...planForWeek.programWorkouts.workouts, ...(planForWeek.programWorkouts.archivedWorkouts ?? [])],
            "id",
          )
        : {};

      const plannedSurveys = Object.keys(values)
        .map(
          (dayKey) =>
            values[dayKey as unknown as WeekdaysNumber]?.items
              .map((item) => ({ ...item, dayKey }))
              .filter(
                (task): task is ScheduledSurveyAutomation & { dayKey: string } =>
                  task.type === TemplateType.ScheduledSurvey,
              ),
        )
        .flat();

      plannedSurveys.forEach((item) => {
        if (!item) {
          return;
        }
        if (item.survey.type === SurveyType.OneTime) {
          return;
        }

        if (item.survey.interval.cycle.type === CycleSurveyType.CycleNumber) {
          const cycleNumber = item.survey.interval.cycle.numberOfCycles - 1;
          Array.from({ length: cycleNumber }).forEach((_, i) => {
            const nextWeek = weekNumber + (item.survey.interval?.count ?? 1) * (i + 1);

            if (nextWeek > plannedWeekNumber) {
              return;
            }

            scheduledSurveysCycle.push({
              ...item,
              cycleNumber: 1 + (i + 1),
              source: item.id,
              id: uuid(),
              dayKey: item.dayKey,
              weekKey: nextWeek,
            });
          });
        }
        if (item.survey.interval.cycle.type === CycleSurveyType.Infinite) {
          const cycleNumber = plannedWeekNumber - weekNumber;
          Array.from({ length: cycleNumber }).forEach((_, i) => {
            const nextWeek = weekNumber + (item.survey.interval?.count ?? 1) * (i + 1);

            if (nextWeek > plannedWeekNumber) {
              return;
            }

            scheduledSurveysCycle.push({
              ...item,
              id: uuid(),
              dayKey: item.dayKey,
              weekKey: nextWeek,
              cycleNumber: 1 + (i + 1),
              source: item.id,
            });
          });
        }
      });

      return {
        ...Object.fromEntries(
          Object.entries(values).map(([dayKey, day]) => {
            const dayToString = weekdaysArr[Number(dayKey)] as WeekDay;
            const workouts = scheduleForWeek ? scheduleForWeek.week?.[dayToString] : null;

            return [
              dayKey,
              {
                ...day,
                workouts: workouts
                  ? Object.values(workouts)
                      .map((workoutId: string) => workoutsById[workoutId])
                      .filter((item) => item)
                  : [],
              },
            ];
          }),
        ),
        week: key,
        scheduleForWeek,
        nutritionPlanForWeek: nutritionPlanForWeek || null,
      };
    });

    const grouped = groupBy(scheduledSurveysCycle, "weekKey");

    Object.keys(grouped).forEach((weekStr) => {
      const weekNumber = Number(weekStr) - 1;

      const scheduledSurveys = grouped[weekStr] || [];

      scheduledSurveys.forEach((item) => {
        // @ts-expect-error ignore
        enhancedWithWorkouts[weekNumber][item.dayKey]?.items.push(item);
      });
    });

    return enhancedWithWorkouts;
  }, [selectedAutomation]);

  const renderCell = useCallback(
    (day: DayContent & { workouts?: ProgramWorkout[] }, row: AutomationRow, rowIndex: number, dayNumber: number) => {
      return day.items?.length || day.workouts?.length ? (
        <Space direction="vertical" key={`${rowIndex}-${dayNumber}`}>
          {day.workouts?.map((workout) => (
            <Space size={2} key={workout.id}>
              <Badge status="processing" /> {workout.name}
            </Space>
          ))}
          {row.nutritionPlanForWeek && dayNumber === 1 ? (
            <div>
              <Utensils color="#2699fb" size={16} /> <span>{t("nutrition.addPlannedNutrition")}</span>
            </div>
          ) : null}

          {sortBy(day.items, ["sentTime.hours", "sentTime.minutes"]).map((item) => {
            if (item.type === TemplateType.ScheduledMessages) {
              return (
                <MessageAutomationItem
                  key={`${item.type}-${item.id}`}
                  item={item}
                  onEdit={() =>
                    setEditedModelForAutomatedMessages(item, `${dayNumber}` as WeekdayNumberToString, row.week)
                  }
                  onRemove={() =>
                    removeAddedAutomation(item.id, day.id, `${dayNumber}` as WeekdayNumberToString, row.week)
                  }
                />
              );
            }

            if (item.type === TemplateType.ScheduledSurvey) {
              return (
                <SurveyAutomationItem
                  key={`${item.type}-${item.id}`}
                  item={item}
                  onEdit={() => {
                    // setEditedModelForAutomatedMessages(item, `${dayNumber}` as WeekdayNumberToString, row.week)
                  }}
                  onRemove={() => {
                    removeAddedAutomation(item.id, day.id, `${dayNumber}` as WeekdayNumberToString, row.week);
                  }}
                />
              );
            }

            if (item.type === TaskType.EmailClient) {
              return (
                <EmailClientTaskItem
                  key={`${item.type}-${item.id}`}
                  item={item}
                  onRemove={() =>
                    removeAddedAutomation(item.id, day.id, `${dayNumber}` as WeekdayNumberToString, row.week)
                  }
                />
              );
            }

            return null;
          })}

          <AddAutomationButton
            addScheduledMessages={() => openScheduleMessagesForm(`${dayNumber}` as WeekdayNumberToString, row.week)}
            addEmailAction={() => openScheduleEmailAction(`${dayNumber}` as WeekdayNumberToString, row.week)}
            addSurveyAction={() => openSurveyForm(`${dayNumber}` as WeekdayNumberToString, row.week)}
          />
        </Space>
      ) : (
        <AddAutomationButton
          addScheduledMessages={() => openScheduleMessagesForm(`${dayNumber}` as WeekdayNumberToString, row.week)}
          addEmailAction={() => openScheduleEmailAction(`${dayNumber}` as WeekdayNumberToString, row.week)}
          addSurveyAction={() => openSurveyForm(`${dayNumber}` as WeekdayNumberToString, row.week)}
        />
      );
    },
    [selectedAutomation],
  );

  const openScheduleMessagesForm = (day: WeekdayNumberToString, week: string) => {
    setCell({ day, week });
    toggleAutomatedMessagesForm(true);
  };

  const openScheduleEmailAction = (day: WeekdayNumberToString, week: string) => {
    setCell({ day, week });
    openTaskModal();
  };

  const openSurveyForm = (day: WeekdayNumberToString, week: string) => {
    setCell({ day, week });
    toggleSurveyForm(true);
  };

  const removeAddedAutomation = (itemId: string, dayId: string, dayNumber: WeekdayNumberToString, week: string) => {
    if (!selectedAutomation) {
      return;
    }

    const weekToUpdate = cloneDeep(selectedAutomation.schedule[week]) || {};

    weekToUpdate[dayNumber] = {
      id: weekToUpdate[dayNumber]?.id ?? uuid(),
      items: weekToUpdate[dayNumber]?.items?.filter((item) => item.id !== itemId) || [],
    };

    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          schedule: {
            ...selectedAutomation.schedule,
            [week]: weekToUpdate,
          },
        },
      }),
    );
  };

  return (
    <>
      <Table
        dataSource={schedule}
        pagination={false}
        bordered
        rowKey="week"
        loading={itemStatus === RequestStatus.FETCHING}
        scroll={{ x: true }}
        className="mb-4 mt-8"
      >
        <Table.Column<AutomationRow>
          title={t("programAutomation.weekHeader")}
          dataIndex="week"
          width={200}
          key="week"
          render={(_, row, i) => {
            return (
              <Space size={2}>
                <span>{t("programAutomation.week", { week: row.week })}</span>
                <Button
                  title={t<string>("common:button.duplicate")}
                  type="link"
                  icon={<CopyOutlined />}
                  onClick={() => duplicateRow(row.week)}
                />
                {i > 0 && (
                  <Button
                    title={t<string>("common:button.delete")}
                    danger
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => removeRow(row.week)}
                  />
                )}
              </Space>
            );
          }}
        />
        {weekdays.map((_, i) => {
          return (
            <Table.Column<AutomationRow>
              title={t("programAutomation.day", { day: i + 1 })}
              dataIndex={i + 1}
              key={i + 1}
              /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
              render={(...args) => renderCell(...args, i + 1)}
              // width="11%"
            />
          );
        })}
      </Table>

      <Button type="primary" icon={<PlusOutlined />} onClick={addNewWeek}>
        {t("programAutomation.addWeek")}
      </Button>
      <ModalForm<AutomatedMessagesFormModel>
        open={showAutomatedMessagesForm}
        onCancel={() => {
          toggleAutomatedMessagesForm(false);
          setModel(null);
          setCell(null);
        }}
        loading={loading}
        title={model ? t("programAutomation.form.update") : t("programAutomation.form.title")}
        okText={model ? t("programAutomation.update") : t("programAutomation.add")}
      >
        <AutomatedMessagesForm onSubmit={handleSubmit} model={model || { mode: FormMode.Template_Add }} />
      </ModalForm>

      <ModalForm<ProgramAutomationTaskFormModel>
        open={automationTaskFormVisible}
        onCancel={() => {
          setCell(null);
          closeTaskModal();
        }}
        title={t("automationTasks.title")}
        width={900}
      >
        <ProgramAutomationTaskForm
          formController={programAutomationTaskFormController}
          onSubmit={handleSubmitEmailTask}
          model={editedTask}
        />
      </ModalForm>

      <ModalForm
        open={showSurveyForm}
        onCancel={() => {
          setCell(null);
          toggleSurveyForm(false);
        }}
        title={t("surveys:addSurvey")}
        width={900}
      >
        <AddSurveyTemplateForm onSubmit={onSendSurveyForm} selectedCell={selectedCell} />
      </ModalForm>
    </>
  );
};

export default memo(AutomationScheduleTable);
