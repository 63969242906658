import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchClientLogs } from "./actions/fetchClientLogs";
import { AUTOMATION_LOGS_REDUCER_NAME, type AutomationLogsReducer } from "./types";

const initialState: AutomationLogsReducer = {
  selectedClient: null,
  clientAutomationLogs: [],
  clientAutomationLogsStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: AUTOMATION_LOGS_REDUCER_NAME,
  reducers: {
    clearClientsLogs(state) {
      state.clientAutomationLogs = [];
      state.selectedClient = null;
      state.clientAutomationLogsStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientLogs.pending, (state) => {
      state.clientAutomationLogs = [];
      state.clientAutomationLogsStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchClientLogs.fulfilled, (state, { payload }) => {
      state.clientAutomationLogs = payload;
      state.clientAutomationLogsStatus = RequestStatus.SUCCESS;
    });
    builder.addCase(fetchClientLogs.rejected, (state) => {
      state.clientAutomationLogsStatus = RequestStatus.FAILED;
    });
  },
});

export const { clearClientsLogs } = reducerSlice.actions;
export default reducerSlice.reducer;
