import { type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { reduceExerciseProperty } from "./reduceExerciseProperty";

export const removeSeriesFromExercise = (seriesNumber: number, exercise: ExerciseInWorkout): ExerciseInWorkout => {
  const workoutSetArray = Object.values(exercise.set);
  workoutSetArray.splice(seriesNumber - 1, 1);
  let updatedSet = {};
  workoutSetArray.forEach((series, index) => {
    updatedSet = {
      ...updatedSet,
      [index + 1]: series,
    };
  });
  return {
    ...exercise,
    set: updatedSet,
    numberOfSeries: Object.keys(updatedSet).length,
    numberOfRepeats: exercise.numberOfRepeats ? reduceExerciseProperty(updatedSet, "repeats") : null,
    weight: exercise.weight ? reduceExerciseProperty(updatedSet, "weight") : null,
    duration: exercise.duration
      ? {
          value: reduceExerciseProperty(updatedSet, "duration"),
          format: exercise.duration.format,
        }
      : null,
  };
};
