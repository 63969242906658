import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { type ProductResourceCategory } from "@fitness-app/data-models/entities/ProductResource";
import { getErrorMessage } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_RESOURCES_REDUCER_NAME } from "../types";
import { fetchResourcesCategories } from "./fetchResourcesCategories";

type Payload = {
  productId: string;
  model: { name: string };
};

export const addResourceCategory = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/addResourceCategory`,
  async (payload, { rejectWithValue, dispatch, extra: { db, analytics, auth } }) => {
    const { id } = await getLoggedUser(auth);
    try {
      const model: ProductResourceCategory = {
        name: payload.model.name,
        description: null,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: uuid(),
        productId: payload.productId,
        owner: id,
      };

      const { error } = await db.from("product_resource_category").insert(model);

      if (error) {
        return rejectWithValue(error.message);
      }
      analytics.track("add_resource_category", {
        productId: payload.productId,
      });

      void dispatch(fetchResourcesCategories());
      return model.id;
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
