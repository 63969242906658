import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Drawer, Dropdown, Form, Input, List, message, Popover, Space, Tag } from "antd";
import cloneDeep from "lodash.clonedeep";
import keyBy from "lodash.keyby";
import round from "lodash.round";
import { Soup } from "lucide-react";
import { useTranslation } from "react-i18next";

import { mealsPlanActions, nutritionActions } from "@fitness-app/app-store";
import { type Dish, type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal, type Meal } from "@fitness-app/data-models/entities/MealsPlan";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";
import { calculateMeasureMultiplier } from "@fitness-app/utils/src/nutrition/calculateMeasureMultiplier";
import { recalculateMeasures } from "@fitness-app/utils/src/nutrition/recalculateMeasures";
import { sumNutrients } from "@fitness-app/utils/src/nutrition/sumNutrients";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useFormHandler } from "~/hooks/useFormHandler";
import DishForm from "~/modules/Nutrition/DishForm/DishForm";
import { type DishFormModel } from "~/modules/Nutrition/DishForm/types";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import DishDetails from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/DishDetails";
import IngredientDetails from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/IngredientDetails";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export interface IMealsProductContext {
  selectProduct: (product: DishInMeal | IngredientWithPortion, mealId?: string, dayId?: string) => void;
  duplicateProduct: (product: DishInMeal | IngredientWithPortion, mealId: string, dayId: string) => void;
}

const MealsProductContext = createContext<IMealsProductContext | undefined>(undefined);

export interface DishDetailsForm {
  ingredients: DishIngredient[];
  name: string;
  portionCount: number;
  portions: number;
  prepTime: number | null;
  preparationSteps: string[];
}
export const useMealsProductContext = () => {
  const context = useContext(MealsProductContext);
  if (context === undefined) {
    throw new Error("useMealsProductContext must be used within a MealsProductProvider");
  }
  return context;
};

const MealsProductProvider = ({ children }: { children: React.ReactElement }) => {
  const { t } = useTranslation("common");
  const { measuresMap } = useNutritionMeasures();
  const { userId } = useUserRole();

  const [formController] = Form.useForm<{ portion: string }>();
  const { deleteProductFromMealsPlan, updateProductInMealsPlan, updateMealsPlanDays, planDetails, mealsMap } =
    useMealsPlanContext();
  const hasCategories = useAppSelector((store) => store.nutrition.ingredientCategories).length;
  const [dishFormController] = Form.useForm<DishDetailsForm>();
  const [selectedProduct, setSelectedProduct] = useState<DishInMeal | IngredientWithPortion | null>(null);
  const [mealId, setMealId] = useState<string | null>(null);
  const [dayId, setDayId] = useState<string | null>(null);
  const [mealsForDay, setMealsForDay] = useState<Meal[]>([]);
  const dispatch = useAppDispatch();
  const [removing, setRemoving] = useState(false);
  const { openFormModal, editedEntity, hideFormModal, showModalForm, formModel } = useFormHandler<
    DishFormModel,
    DishInMeal
  >();
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const [isAddingNewTemplate, setIsAddingNewTemplate] = useState(false);
  const [similarDishes, setSimilarDishes] = useState<DishInMeal[]>([]);
  const [prompt, setPrompt] = useState("");
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (!hasCategories) {
      void dispatch(nutritionActions.fetchIngredientCategories());
      void dispatch(nutritionActions.fetchIngredientMeasures());
      void dispatch(nutritionActions.fetchIngredientGroups());
    }
  }, [hasCategories]);

  const selectProduct = useCallback(
    (product: DishInMeal | IngredientWithPortion, mealId?: string, dayId?: string) => {
      setSelectedProduct(product);
      setMealId(mealId || null);
      setDayId(dayId || null);

      const dish = mealsMap.dishes[product.id];
      const mealsForDay = dish ? mealsMap.mealsByDay.find((day) => day.id === dish.dayId) : null;
      const meals = mealsForDay
        ? mealsForDay.meals
            .map((meal) => mealsMap.meals[meal.id])
            .filter((meal): meal is Meal & { dayId: string } => Boolean(meal))
        : [];

      setMealsForDay(meals);
    },
    [mealsMap],
  );

  const findReplacement = useCallback(
    async (dish: DishInMeal | IngredientWithPortion) => {
      const meal = mealsForDay.find((meal) => meal.id === mealId);
      if (dish.type !== "ingredient" && planDetails && meal) {
        setSearching(true);
        try {
          const dishes = await dispatch(
            mealsPlanActions.findSimilarDishes({
              dishId: dish.id,
              nutritionId: planDetails.planId,
              prompt,
              mealType: meal.type,
            }),
          ).unwrap();
          setSimilarDishes(dishes);
        } catch (e) {
          void message.error("Błąd podczas pobierania listy. Spróbuj ponownie.");
        } finally {
          setSearching(false);
        }
      }
    },
    [planDetails, mealId, mealsForDay, prompt],
  );

  const replaceDish = (dish: DishInMeal) => {
    if (mealId && dayId && selectedProduct && selectedProduct?.type !== "ingredient") {
      const updated = {
        ...dish,
        id: selectedProduct.id,
      };
      void updateProductInMealsPlan({
        mealId,
        dayId,
        product: updated,
      });
      setSelectedProduct(null);
      setMealId(null);
      setDayId(null);
    }
  };

  const deleteProduct = useCallback(
    async (product: DishInMeal | IngredientWithPortion) => {
      if (mealId && dayId) {
        setRemoving(true);
        await deleteProductFromMealsPlan({
          mealId,
          dayId,
          productId: product.id,
        });
        setRemoving(false);
        setSelectedProduct(null);
        setMealId(null);
        setDayId(null);
      }
    },
    [mealId, dayId, dispatch],
  );

  const updateIngredientPortion = useCallback(
    (portion: number, product: IngredientWithPortion) => {
      if (mealId && dayId) {
        void updateProductInMealsPlan({
          mealId,
          dayId,
          product: { ...product, portion },
        });
      }
      setSelectedProduct(null);
      setMealId(null);
      setDayId(null);
    },
    [dispatch, mealId, dayId],
  );

  const onDishEdit = useCallback(
    (dish: DishInMeal, isNewDish = false) => {
      const model: DishFormModel = {
        image: dish.media?.map((media) => media.url) || [],
        tags: dish.tags || [],
        name: dish.name,
        includes: dish.includes || [],
        dishTypes: dish.dishTypes || [],
        kitchenTypes: dish.kitchenTypes || [],
        cookTime: dish.cookTime || null,
        size: dish.size || 2,
        preparationSteps: dish.preparationSteps || [],
        portions: dish.portions || 1,
        needsCooking: dish.needsCooking ?? false,
        needsReheating: dish.needsReheating ?? false,
        mealTypes: dish.mealTypes || [],
        prepTime: dish.prepTime || null,
        ingredients: dish.ingredients.map((ingredient) => {
          const enhancedIngredient = recalculateMeasures(ingredient);
          const { multiplier } = calculateMeasureMultiplier(enhancedIngredient, measuresMap);

          return {
            ...enhancedIngredient,
            multiplier,
          };
        }),
      };
      setIsAddingNewTemplate(isNewDish);
      openFormModal(model, dish);
    },
    [openFormModal],
  );

  const updateDish = useCallback(
    (
      model: {
        ingredients: DishIngredient[];
        portion: number;
        prepTime: number;
        portions: number;
        calories: number;
        portionCount: number;
        name: string;
      },
      product: DishInMeal,
    ) => {
      const updated = { ...product, ...model };
      if (mealId && dayId) {
        void updateProductInMealsPlan({
          mealId,
          dayId,
          product: updated,
        });
      }

      if (isAddingNewTemplate) {
        onDishEdit(updated, true);
      } else {
        setSelectedProduct(null);
        setMealId(null);
        setDayId(null);
      }
    },
    [dispatch, mealId, dayId, isAddingNewTemplate, onDishEdit],
  );

  const onUpdateDish = async (
    formData: DishFormModel,
    { portionSize, calories }: { portionSize: number; calories: number },
  ) => {
    const { image, ingredients, ...rest } = formData;

    if (formModel && editedEntity) {
      const byKey = keyBy(editedEntity.ingredients, "id");

      if (isAddingNewTemplate) {
        const { parentId, portion, portionCount, status, clientNote, ...rest } = editedEntity;
        const dish: Dish = {
          ...rest,
          ...rest,
          id: generateUniqId(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          type: "dish",
          createdBy: userId,
          metadata: {
            parentId: editedEntity.parentId,
          },
          source: "custom",
          verified: true,
          visible: true,
          media: Array.isArray(image) && image.length ? [{ url: image[0] || "", type: "coverPhoto" }] : null,
          portionSize,
          calories,
          ingredients: formData.ingredients.map((ingredient) => ({
            ...(byKey[ingredient.id] || {}),
            ...ingredient,
            calories: ingredient.nutrients.calories,
            portionCalories: ingredient.nutrients.calories
              ? round((ingredient.nutrients.calories * ingredient.portion) / 100, 1)
              : 0,
          })),
        };
        try {
          onStart();
          await dispatch(nutritionActions.addDish({ dish })).unwrap();
          onSuccess();

          hideFormModal();
        } catch (e) {
          onFailure();
        }
      } else {
        const dish: Partial<Dish> = {
          ...rest,
          media: Array.isArray(image) && image.length ? [{ url: image[0] || "", type: "coverPhoto" }] : null,
          updatedAt: new Date().toISOString(),
          portionSize,
          calories,
          ingredients: formData.ingredients.map((ingredient) => ({
            ...(byKey[ingredient.id] || {}),
            ...ingredient,
            calories: ingredient.nutrients.calories,
            portionCalories: ingredient.nutrients.calories
              ? round((ingredient.nutrients.calories * ingredient.portion) / 100, 1)
              : 0,
          })),
        };

        try {
          onStart();
          const updatedDish = await dispatch(
            nutritionActions.updateDish({ dish, dishId: editedEntity.parentId }),
          ).unwrap();
          onSuccess();

          if (updatedDish) {
            const { id, ...rest } = updatedDish;
            setSelectedProduct((prev) => ({
              ...(prev as DishInMeal),
              ...rest,
            }));
          }

          hideFormModal();
        } catch (e) {
          onFailure();
        }
      }
    }
  };

  const duplicateProduct = useCallback(
    (product: DishInMeal | IngredientWithPortion, mealId: string, dayId: string) => {
      const clonedProduct = cloneDeep({
        ...product,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: generateUniqId(),
      });
      const days = cloneDeep(planDetails?.weeks[0]?.days || []);

      const updatedDays = days.map((day) => {
        if (day.id !== dayId) {
          return day;
        }
        const meals = day.meals.map((meal) => {
          if (meal.id !== mealId) {
            return meal;
          }
          return {
            ...meal,
            dishes: [...meal.dishes, clonedProduct],
          };
        });

        return {
          ...day,
          meals,
        };
      });

      void updateMealsPlanDays({ days: updatedDays });
    },
    [planDetails, updateMealsPlanDays],
  );

  return (
    <MealsProductContext.Provider value={{ selectProduct, duplicateProduct }}>
      <>
        <Drawer
          open={!!selectedProduct}
          onClose={() => setSelectedProduct(null)}
          width={600}
          extra={
            <Space size={12}>
              {selectedProduct && selectedProduct?.type !== "ingredient" && (
                <Popover
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setSimilarDishes([]);
                      setPrompt("");
                    }
                  }}
                  content={
                    <Space direction="vertical" style={{ width: 400 }}>
                      <Input.TextArea
                        value={prompt}
                        placeholder="Wpisz czego szukasz"
                        onChange={(e) => setPrompt(e.target.value || "")}
                      />

                      <Button loading={searching} type="primary" onClick={() => findReplacement(selectedProduct)}>
                        Znajdź zamiennik
                      </Button>

                      <List
                        className="w-full"
                        itemLayout="horizontal"
                        loading={searching}
                        dataSource={similarDishes}
                        locale={{
                          emptyText: "Wyszukaj zamiennik dla wybranej potrawy.",
                        }}
                        key="id"
                        renderItem={(item) => {
                          const protein = sumNutrients(item.ingredients, "protein");
                          const carbs = sumNutrients(item.ingredients, "carbohydrates");
                          const fat = sumNutrients(item.ingredients, "fat");
                          return (
                            <List.Item className="group relative cursor-pointer">
                              <List.Item.Meta
                                avatar={<Soup size={14} className="mt-px align-text-top" />}
                                title={item.name}
                                description={
                                  <div className="flex flex-col gap-y-2">
                                    <div className="flex justify-between pt-2 font-light">
                                      <dd className="text-xs text-gray-700">
                                        B: {protein} g | W: {carbs} g | T: {fat} g
                                      </dd>
                                      <dd className="text-xs text-gray-700">
                                        {item.calories} kcal ({item.portionSize || "100"}g)
                                      </dd>
                                    </div>
                                    {item.createdBy === userId && (
                                      <div>
                                        <Tag color="green">Twój szablon potrawy</Tag>
                                      </div>
                                    )}
                                  </div>
                                }
                              />
                              <div className="ease invisible absolute inset-0 flex items-center justify-center bg-gray-100/70 transition-[visibility] duration-100 group-hover:visible">
                                <div className="flex gap-3">
                                  <Button
                                    key="add"
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={() => replaceDish(item)}
                                  />
                                </div>
                              </div>
                            </List.Item>
                          );
                        }}
                      />
                    </Space>
                  }
                  title="Wymień posiłek"
                  trigger="click"
                >
                  <Button icon={<SwapOutlined />} />
                </Popover>
              )}
              {selectedProduct && (
                <Button
                  loading={removing}
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => deleteProduct(selectedProduct)}
                />
              )}
              {selectedProduct && selectedProduct?.type !== "ingredient" && (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: "Edytuj główny szablon potrawy",
                        onClick: () => onDishEdit(selectedProduct),
                      },
                      {
                        key: "2",
                        label: "Zapisz jako nowy szablon potrawy",
                        onClick: () => {
                          setIsAddingNewTemplate(true);
                          dishFormController.submit();
                        },
                      },
                    ],
                  }}
                >
                  <Button icon={<EditOutlined />} />
                </Dropdown>
              )}
              <Button
                type="primary"
                onClick={() => {
                  if (selectedProduct?.type === "ingredient") {
                    formController.submit();
                  } else {
                    dishFormController.submit();
                  }
                }}
              >
                {t("button.save")}
              </Button>
            </Space>
          }
        >
          {selectedProduct?.type === "ingredient" && (
            <IngredientDetails
              formController={formController}
              ingredient={selectedProduct}
              updatePortion={(portion) => updateIngredientPortion(portion, selectedProduct)}
              mealsForDay={mealsForDay}
            />
          )}
          {selectedProduct && selectedProduct.type !== "ingredient" && (
            <DishDetails
              dish={selectedProduct}
              formController={dishFormController}
              onSubmit={(props) => updateDish(props, selectedProduct)}
              mealsForDay={mealsForDay}
            />
          )}
        </Drawer>
        {children}

        <ModalForm
          open={showModalForm}
          onCancel={hideFormModal}
          title={t("nutrition:dish.addNewDish")}
          width={900}
          loading={!!loading}
        >
          <DishForm onSubmit={onUpdateDish} model={formModel} />
        </ModalForm>
      </>
    </MealsProductContext.Provider>
  );
};

export default MealsProductProvider;
