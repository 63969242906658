import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  slug: string;
};

export const updateProductSlug = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_REDUCER_NAME}/updateProductSlug`,
  async (payload, { extra: { productApi, analytics } }) => {
    await productApi.post("/update-product-slug", payload);

    analytics.track("update_product_slug", {
      slug: payload.slug ?? "",
      product_id: payload.productId,
    });
  },
);
