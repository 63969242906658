import { TrainingStatus } from "@fitness-app/data-models/entities/TrainingProgram";

export const mapTrainingStatusToAlert = {
  [TrainingStatus.REJECTED]: "error",
  [TrainingStatus.PASSED]: "error",
  [TrainingStatus.NEW]: "info",
  [TrainingStatus.FULFILLED]: "success",
  [TrainingStatus.PARTIALLY_FULFILLED]: "warning",
  [TrainingStatus.EMPTY]: "warning",
} as const;

export const mapTrainingStatusToBadge = {
  [TrainingStatus.REJECTED]: "error",
  [TrainingStatus.PASSED]: "error",
  [TrainingStatus.NEW]: "processing",
  [TrainingStatus.FULFILLED]: "success",
  [TrainingStatus.PARTIALLY_FULFILLED]: "warning",
  [TrainingStatus.EMPTY]: "warning",
} as const;
