import at from "lodash.at";
import flatten from "lodash.flatten";
import get from "lodash.get";
import orderBy from "lodash.orderby";

import { type ExerciseInWorkout, type ExerciseProperty } from "@fitness-app/data-models/entities/TrainingProgram";

const convertToArray = (value: number | [number, number]) => {
  if (Array.isArray(value)) return value;
  return [value];
};

export const reduceExerciseProperty = (
  data: ExerciseInWorkout["set"],
  objectKey: ExerciseProperty,
  flat = false,
): null | number | [number, number] => {
  let sum: (number | undefined | null)[] = [];
  Object.keys(data).forEach((key) => {
    if (objectKey === "duration") {
      if (data[key]?.[objectKey] === null) {
        return null;
      }
      sum = [...sum, ...convertToArray(get(data[key], "duration.value", 0))];
    } else {
      sum = [...sum, ...at(data[key], objectKey)] as (number | undefined | null)[];
    }
  });
  const ordered = orderBy(flatten(sum)).filter((item): item is number => Boolean(item));
  let min = ordered[0];
  if (!min) min = 0;

  let max = Math.max.apply(null, ordered);
  if (!max || max === -Infinity || max === Infinity) max = 0;
  if (min === max && flat) {
    return min;
  }
  return [min, max];
};

type Data = [number, number] | number | string | null;

export const displayExerciseData = (data: Data, unit = ""): string => {
  if (data === undefined || data === null) return "-";
  if (!Array.isArray(data)) return `${data}${unit}`;
  if (data[0] === data[1]) return `${data[0]}${unit}`;
  return `${data[0]} - ${data[1]}${unit}`;
};
