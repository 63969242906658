import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Exercise, type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";

import { type AsyncThunkCreator } from "../../../index";
import { EXERCISES_REDUCER_NAME } from "../types";

type Payload = {
  exercise: Exercise;
};

export const addExercise = createAsyncThunk<ExerciseWithVideo, Payload, AsyncThunkCreator<string>>(
  `${EXERCISES_REDUCER_NAME}/addExercise`,
  async ({ exercise }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("exercise")
      .insert(exercise)
      .select(
        "*, creator:createdBy(id, firstName, lastName), video:videoId(title, data->thumbnailUrl, data->previewUrl, data->mp4Url,data->hlsUrl, data->guid)",
      )
      .single<ExerciseWithVideo>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_exercise", { type: exercise.type });

    return data;
  },
);
