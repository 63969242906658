import { createAsyncThunk } from "@reduxjs/toolkit";
import { type EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";
import { getError, getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export type Payload = {
  url: string;
  client: EmailClient;
  serverPrefix?: string;
  lastNameKey?: string;
  firstNameKey?: string;
};

export const refreshConnectedListsWithEmailClient = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<string>
>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/refreshListsConnectedWithEmailClient`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { integrationApi, analytics } }) => {
    try {
      await integrationApi.patch("/refresh-email-lists", payload);

      analytics.track("refresh_connected_lists_with_email_client", {
        client: payload.client,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.(getError(e).status);

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
