import React, { type FunctionComponent } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";

import { type ChatChannel, type ChatMember } from "@fitness-app/data-models/entities/Chat";
import { getUserInitials } from "@fitness-app/utils";

import { useChatAuthorOptions } from "~/modules/Chat/hooks/useChatAuthorOptions";
import { useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  channel: ChatChannel;
  selectedAuthor: ChatMember | null;
  onSelectChatMember: (member: ChatMember | null) => void;
}

type Props = OwnProps;
const AuthorSelector: FunctionComponent<Props> = ({ channel, selectedAuthor, onSelectChatMember }) => {
  const { t } = useTranslation("chat");
  const user = useAppSelector((store) => store.user.profile);
  const authors = useChatAuthorOptions(channel);
  if (!user) {
    return null;
  }
  const AuthorMenu = (
    <Menu selectedKeys={[selectedAuthor?.uid || user.id]}>
      <h4 style={{ margin: "0 10px" }}>{t("channel.selectMessageAuthor")}</h4>
      <Menu.Item key={user.id} onClick={() => onSelectChatMember(null)}>
        <div className="flex items-center gap-x-2">
          <Avatar src={user.avatarUrl} size={24}>
            {getUserInitials(`${user.firstName} ${user.lastName}`)}
          </Avatar>
          {user.firstName} {user.lastName}
        </div>
      </Menu.Item>
      {authors.map((author) => {
        return (
          <Menu.Item key={author.uid} onClick={() => onSelectChatMember(author)}>
            <div className="flex items-center gap-x-2">
              <Avatar src={author.avatarUrl} size={24}>
                {getUserInitials(author.name)}
              </Avatar>
              {author.name}
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={AuthorMenu} className="cursor-pointer" placement="topRight">
      <div>
        <Avatar src={selectedAuthor ? selectedAuthor.avatarUrl : user.avatarUrl} size={24}>
          {getUserInitials(selectedAuthor?.name || `${user.firstName} ${user.lastName}`)}
        </Avatar>
      </div>
    </Dropdown>
  );
};

export default AuthorSelector;
