import round from "lodash.round";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { type Ingredient, type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";

const DEFAULT_MEASURE_ID = 1;
const getDefaultMeasure = (kcalPer100Gram: number) => ({
  id: DEFAULT_MEASURE_ID,
  value: DEFAULT_MEASURE_ID,
  label: "gram",
  name: "g",
  unit: "grams",
  energyPerUnit: round(kcalPer100Gram / 100, 2),
  weightPerUnit: 1,
  multiplier: 100,
});

export const getInitialMeasure = (
  ingredient: Ingredient | IngredientWithPortion | DishIngredient,
  measureOptions: {
    id: number;
    value: number;
    label: string;
    name: string;
    unit: string;
    energyPerUnit: number;
    weightPerUnit: number;
    multiplier: number;
  }[],
) => {
  if (
    "portion" in ingredient &&
    ((ingredient.portion && "fromTracker" in ingredient) || "hint" in ingredient || "measureId" in ingredient)
  ) {
    const defaultMeasure = measureOptions.find((measure) => measure.weightPerUnit === ingredient.portion);
    if (defaultMeasure && defaultMeasure.id !== DEFAULT_MEASURE_ID) {
      return {
        measure: defaultMeasure,
        multiplier: defaultMeasure.multiplier || 1,
      };
    }

    if (ingredient.measureId) {
      const defaultMeasure = measureOptions.find((measure) => measure.id === ingredient.measureId);
      if (defaultMeasure) {
        return {
          measure: defaultMeasure,
          multiplier: round(ingredient.portion / defaultMeasure.weightPerUnit, 1) || defaultMeasure.multiplier || 1,
        };
      }

      const fromDefault = getDefaultMeasure(ingredient.nutrients.calories);

      return {
        measure: fromDefault,
        multiplier: round(ingredient.portion / fromDefault.weightPerUnit, 1),
      };
    }

    if (defaultMeasure) {
      return {
        measure: defaultMeasure,
        multiplier: defaultMeasure.multiplier || 1,
      };
    }
  }

  if (ingredient.packageSize) {
    const defaultMeasure = measureOptions.find((measure) => measure.weightPerUnit === ingredient.packageSize);
    if (defaultMeasure) {
      return {
        measure: defaultMeasure,
        multiplier: defaultMeasure.multiplier || 1,
      };
    }

    const fromDefault = getDefaultMeasure(ingredient.nutrients.calories);

    return {
      measure: fromDefault,
      multiplier: "portion" in ingredient ? round(ingredient.portion / fromDefault.weightPerUnit, 1) : 100,
    };
  }

  if ("measureId" in ingredient && ingredient.measureId) {
    const defaultMeasure = measureOptions.find((measure) => measure.id === ingredient.measureId);
    if (defaultMeasure) {
      return {
        measure: defaultMeasure,
        multiplier: defaultMeasure.multiplier || 1,
      };
    }
  }

  const fromDefault = getDefaultMeasure(ingredient.nutrients.calories);

  return {
    measure: fromDefault,
    multiplier: 100,
  };
};
