import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TraineeSettings } from "@fitness-app/data-models/entities/TraineeSettings";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  notificationsSettings: TraineeSettings["notificationsSettings"];
  id: string;
};

export const updateTraineeNotificationsSettings = createAsyncThunk<TraineeSettings, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/updateTraineeNotificationsSettings`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { data, error } = await db
      .from("trainee_settings")
      .update({ notificationsSettings: payload.notificationsSettings, updatedAt: new Date().toISOString() })
      .eq("id", payload.id)
      .select("*")
      .single<TraineeSettings>();

    if (error || !data) {
      return rejectWithValue(error?.message || "Failed to update notifications settings");
    }

    analytics.track("update_trainee_notifications_settings");

    return data;
  },
);
