import { createAsyncThunk } from "@reduxjs/toolkit";
import { type InAppResourcePayload, type ProductResource } from "@fitness-app/data-models/entities/ProductResource";
import { DEFAULT_RESOURCE_CATEGORY } from "@fitness-app/utils/src/constants/defaultResourceCategory";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_RESOURCES_REDUCER_NAME } from "../types";
import { fetchProductResources } from "./fetchProductResources";

type Payload = {
  productId: string;
  model: Partial<InAppResourcePayload> & { id: string };
};

export const updateProductResource = createAsyncThunk<
  ProductResource,
  ActionParams<Payload, string>,
  AsyncThunkCreator<string>
>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/updateProductResource`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, dispatch, extra: { db } }) => {
    const model: Partial<ProductResource> = {
      ...payload.model,
      category: payload.model.category === DEFAULT_RESOURCE_CATEGORY ? null : payload.model.category,
      updatedAt: new Date().toISOString(),
    };

    const updated = await db
      .from("product_resource")
      .update(model)
      .eq("id", payload.model.id)
      .select()
      .returns<ProductResource>();

    if (updated.error) {
      onFailure?.();
      return rejectWithValue(updated.error.message);
    }
    void dispatch(fetchProductResources({ productId: payload.productId }));

    onSuccess?.();

    return updated.data;
  },
);
