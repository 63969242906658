import { useSearchParams } from "react-router-dom";

import { traineeMealsPlanActions } from "@fitness-app/app-store";
import {
  type NutrientsTargets,
  type TraineeNutritionGeneratorFormModel,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import { useEntityChange } from "~/hooks/useEntityChange";
import { useAppDispatch } from "~/store/initializeStore";

export const useGenerateNutrition = (onSuccessCallback?: () => void) => {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();

  const [generating, onSuccess, onFailure, onStart] = useEntityChange(() => {
    onSuccessCallback?.();
  });

  const generateNutrition = async (formData: TraineeNutritionGeneratorFormModel, targets: NutrientsTargets) => {
    onStart(undefined, "Generowanie diety może zajać nawet 1 minutę...");
    try {
      const response = await dispatch(
        traineeMealsPlanActions.generateNutritionForTrainee({
          ...formData,
          targets,
        }),
      ).unwrap();
      onSuccess();

      if (response) {
        setSearchParams({ template: "true", planId: response.plan.id, tab: "nutrition", nutritionTab: "schedule" });
      }
    } catch (e) {
      onFailure();
    }
  };

  return {
    generating,
    generateNutrition,
  };
};
