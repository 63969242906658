import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Exercise, type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";

import { type AsyncThunkCreator } from "../../../index";
import { EXERCISES_REDUCER_NAME } from "../types";

type Payload = {
  exercise: Partial<Exercise> & { id: string };
};

export const updateExercise = createAsyncThunk<ExerciseWithVideo | null, Payload, AsyncThunkCreator<string>>(
  `${EXERCISES_REDUCER_NAME}/updateExercise`,
  async ({ exercise: { id, ...rest } }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("exercise")
      .update(rest)
      .eq("id", id)
      .select(
        "*, creator:createdBy(id, firstName, lastName), video:videoId(title, data->thumbnailUrl, data->previewUrl, data->mp4Url,data->hlsUrl, data->guid)",
      )
      .returns<ExerciseWithVideo[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_exercise", { type: rest.type });

    return data[0] || null;
  },
);
