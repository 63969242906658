import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  ids: string[];
};

export const deleteMeasurement = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/deleteMeasurement`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db.from("measurement").delete().in("id", payload.ids);

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("delete_measurement");
  },
);
