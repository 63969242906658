import { useMemo } from "react";

import { ConversionTrackingClient } from "@fitness-app/data-models/entities/ProductsSettings";

import { useAppSelector } from "~/store/initializeStore";

export const useMetaConversion = () => {
  const integrationData = useAppSelector((store) => store.productsSettings.data);

  const conversionTrackingIntegrations =
    integrationData?.conversionTracking?.[ConversionTrackingClient.MetaConversionApi];

  const conversionsList = useMemo(() => {
    if (!conversionTrackingIntegrations) {
      return [];
    }

    return conversionTrackingIntegrations.map((conversion) => ({
      label: conversion.name,
      value: conversion.id,
    }));
  }, [conversionTrackingIntegrations]);

  return {
    conversionsListOptions: conversionsList,
  };
};
