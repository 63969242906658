import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  planId: string;
};

export const deleteMealsPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/deleteMealsPlan`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db.from("meals_plan").delete().eq("id", payload.planId);

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("delete_meals_plan");
  },
);
