import React, { useState } from "react";
import { CloseOutlined, DeleteOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Col, Popconfirm, Row, Space, Table, Tabs, Tooltip } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { CampaignStatus, type Campaign } from "@fitness-app/data-models/entities/Campaign";
import { MediaLibraryResourceType } from "@fitness-app/data-models/entities/MediaLibrary";

import { CampaignDetails } from "~/modules/Campaigns/CampaignDetails/CampaignDetails";
import { CampaignForm } from "~/modules/Campaigns/CampaignForm/CampaignForm";
import { useCampaignsList } from "~/modules/Campaigns/hooks/useCampaignsList";
import { useCampgainCancelation } from "~/modules/Campaigns/hooks/useCampgainCancelation";
import MediaLibrary from "~/modules/MediaLibrary/MediaLibrary";

const statusMapper = {
  [CampaignStatus.Scheduled]: {
    text: "Zaplanowana",
    status: "warning",
  },
  [CampaignStatus.Finished]: {
    text: "Zakończona",
    status: "success",
  },
  [CampaignStatus.Running]: {
    text: "W trakcie",
    status: "processing",
  },
  [CampaignStatus.Cancelled]: {
    text: "Anulowana",
    status: "error",
  },
  [CampaignStatus.Archived]: {
    text: "Zarchiwizowana",
    status: "error",
  },
} as const;

export const Campaigns = () => {
  const { t } = useTranslation(["campaigns", "common"]);
  const { isLoading, data } = useCampaignsList();
  const [showFormModal, openFormModal] = useState(false);
  const { cancelMutation } = useCampgainCancelation();
  return (
    <>
      <Row className="p-6">
        <Col xs={24}>
          <Card
            loading={isLoading}
            title={t("title")}
            extra={
              <Space size={16}>
                <MediaLibrary type={MediaLibraryResourceType.Marketing} />
                <Button type="primary" icon={<PlusOutlined />} onClick={() => openFormModal(true)}>
                  {t("addNewCampaign")}
                </Button>
              </Space>
            }
          >
            <Tabs type="card" defaultActiveKey="popup">
              <Tabs.TabPane tab={t("popup.title")} key="popup">
                <Table<Campaign>
                  rowKey="id"
                  dataSource={data || []}
                  locale={{
                    emptyText: <div>{t("table.emptyState")} </div>,
                  }}
                  expandable={{
                    expandedRowRender: (rowData) => <CampaignDetails campaign={rowData} />,
                  }}
                  scroll={{ x: true }}
                >
                  <Table.Column title={t("table.name")} dataIndex="title" key="title" ellipsis />
                  <Table.Column
                    title={t("table.createdAt")}
                    dataIndex="createdAt"
                    key="createdAt"
                    render={(createdAt: string) => dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
                  />
                  <Table.Column title={t("table.total")} dataIndex="total" key="total" />
                  <Table.Column
                    title={
                      <div>
                        {t<string>("table.skipped")}{" "}
                        <Tooltip title={t<string>("table.skippedInfo")}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    }
                    dataIndex="skipped"
                    key="skipped"
                  />
                  <Table.Column title={t("table.sent")} dataIndex="sent" key="sent" />
                  <Table.Column
                    title={
                      <div>
                        {t<string>("table.opened")}{" "}
                        <Tooltip title={t<string>("table.nonUniqueInfo")}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    }
                    dataIndex="opened"
                    key="opened"
                  />
                  <Table.Column title={t<string>("table.uniqueOpened")} dataIndex="uniqueOpened" key="uniqueOpened" />
                  <Table.Column
                    title={
                      <div>
                        {t<string>("table.clicked")}{" "}
                        <Tooltip title={t<string>("table.nonUniqueInfo")}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    }
                    dataIndex="clicked"
                    key="clicked"
                  />
                  <Table.Column title={t<string>("table.uniqueClicks")} dataIndex="uniqueClicks" key="uniqueClicks" />
                  <Table.Column
                    title={t("table.status")}
                    dataIndex="status"
                    key="status"
                    render={(status: Campaign["status"]) => (
                      <Badge
                        status={statusMapper[status].status}
                        text={statusMapper[status].text}
                        className="whitespace-nowrap"
                      />
                    )}
                  />
                  <Table.Column<Campaign>
                    title="Opcje"
                    dataIndex="action"
                    key="action"
                    render={(_text, record) => (
                      <Space>
                        <Popconfirm
                          title="Czy na pewno chcesz anulować wybraną kampanie?"
                          okText="Tak"
                          cancelText="Nie"
                          placement="left"
                          onConfirm={() =>
                            cancelMutation.mutateAsync({
                              campaignId: record.id,
                              status: record.status,
                              skipped: record.skipped,
                            })
                          }
                        >
                          <Button
                            disabled={![CampaignStatus.Scheduled, CampaignStatus.Running].includes(record.status)}
                            size="small"
                            type="text"
                            title="Usuń"
                            danger
                            icon={record.status === CampaignStatus.Running ? <CloseOutlined /> : <DeleteOutlined />}
                          />
                        </Popconfirm>
                      </Space>
                    )}
                  />
                </Table>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <CampaignForm onClose={() => openFormModal(false)} open={showFormModal} />
    </>
  );
};
