import React, { useEffect, useState } from "react";
import { Button, Card, Input, Popconfirm, Space, Table } from "antd";
import { type TableProps } from "antd/es/table";
import keyBy from "lodash.keyby";
import round from "lodash.round";
import { useTranslation } from "react-i18next";

import { nutritionActions, RequestStatus } from "@fitness-app/app-store";
import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";
import { calculateMeasureMultiplier } from "@fitness-app/utils/src/nutrition/calculateMeasureMultiplier";
import { recalculateMeasures } from "@fitness-app/utils/src/nutrition/recalculateMeasures";
import { sumNutrients } from "@fitness-app/utils/src/nutrition/sumNutrients";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useFormHandler } from "~/hooks/useFormHandler";
import DishForm from "~/modules/Nutrition/DishForm/DishForm";
import { type DishFormModel } from "~/modules/Nutrition/DishForm/types";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const NutritionDishes = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("nutrition");
  const [searchValue, setSearchValue] = useState("");
  const { dishes, dishesPage, dishesPerPage, dishesStatus, dishesTotalPages, dishSearchTerm } = useAppSelector(
    (state) => state.nutrition,
  );
  const { measuresMap } = useNutritionMeasures();

  const { userId, isTrainer } = useUserRole();
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const [deleting, onSuccessDelete, onFailureDelete, onStartDeleting] = useEntityChange();
  const { showModalForm, toggleModalForm, openFormModal, formModel, editedEntity, hideFormModal } = useFormHandler<
    DishFormModel,
    Dish
  >();

  useEffect(() => {
    void dispatch(nutritionActions.fetchDishes());
  }, [dispatch]);

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (dishSearchTerm) {
        onClear();
      }
      return;
    }
    void dispatch(nutritionActions.searchDish({ searchTerm: trimmed }));
  };

  const onClear = () => {
    setSearchValue("");
    dispatch(nutritionActions.clearSearchingDish());
  };

  const handleChange: TableProps<Dish>["onChange"] = (pagination) => {
    if (pagination.current !== dishesPage) {
      void dispatch(nutritionActions.fetchDishes({ page: pagination.current }));
    }
  };

  const onSaveDish = async (
    formData: DishFormModel,
    { portionSize, calories }: { portionSize: number; calories: number },
  ) => {
    const { image, ingredients, ...rest } = formData;

    if (formModel && editedEntity) {
      const byKey = keyBy(editedEntity.ingredients, "id");
      const dish: Dish = {
        ...editedEntity,
        ...rest,
        media: Array.isArray(image) && image.length ? [{ url: image[0] || "", type: "coverPhoto" }] : null,
        updatedAt: new Date().toISOString(),
        portionSize,
        calories,
        ingredients: formData.ingredients.map((ingredient) => ({
          ...(byKey[ingredient.id] || {}),
          ...ingredient,
          calories: ingredient.nutrients.calories,
          portionCalories: ingredient.nutrients.calories
            ? round((ingredient.nutrients.calories * ingredient.portion) / 100, 1)
            : 0,
        })),
      };

      try {
        onStart();
        await dispatch(nutritionActions.updateDish({ dish, dishId: editedEntity.id })).unwrap();
        onSuccess();
        hideFormModal();
      } catch (e) {
        onFailure();
      }
    } else {
      const dish: Dish = {
        ...rest,
        media: Array.isArray(image) && image.length ? [{ url: image[0] || "", type: "coverPhoto" }] : null,
        id: generateUniqId(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        type: "dish",
        createdBy: userId,
        metadata: {
          parentId: null,
        },
        source: "custom",
        portionSize,
        calories,
        verified: true,
        visible: true,
        ingredients: formData.ingredients.map((ingredient) => ({
          ...ingredient,
          calories: ingredient.nutrients.calories,
          portionCalories: ingredient.nutrients.calories
            ? round((ingredient.nutrients.calories * ingredient.portion) / 100, 1)
            : 0,
        })),
      };

      try {
        onStart();
        await dispatch(nutritionActions.addDish({ dish })).unwrap();
        onSuccess();
        toggleModalForm(false);
      } catch (e) {
        onFailure();
      }
    }
  };

  const deleteDish = async (id: string) => {
    onStartDeleting(id);
    try {
      await dispatch(nutritionActions.deleteDish(id)).unwrap();
      onSuccessDelete();
    } catch {
      onFailureDelete();
    }
  };

  const handleEdit = (dish: Dish) => {
    const model: DishFormModel = {
      image: dish.media?.map((media) => media.url) || [],
      tags: dish.tags || [],
      name: dish.name,
      includes: dish.includes || [],
      dishTypes: dish.dishTypes || [],
      kitchenTypes: dish.kitchenTypes || [],
      cookTime: dish.cookTime || null,
      size: dish.size || 2,
      preparationSteps: dish.preparationSteps || [],
      portions: dish.portions || 1,
      needsCooking: dish.needsCooking ?? false,
      needsReheating: dish.needsReheating ?? false,
      mealTypes: dish.mealTypes || [],
      prepTime: dish.prepTime || null,
      ingredients: dish.ingredients.map((ingredient) => {
        const enhancedIngredient = recalculateMeasures(ingredient);
        const { multiplier } = calculateMeasureMultiplier(enhancedIngredient, measuresMap);

        return {
          ...enhancedIngredient,
          multiplier,
        };
      }),
    };
    openFormModal(model, dish);
  };

  return (
    <>
      <Card
        extra={
          <Button onClick={() => toggleModalForm(true)} type="primary">
            {t("dish.addNewDish")}
          </Button>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
          <div className="flex w-full items-center">
            <Input.Search
              placeholder={t<string>("dish.searchDishPlaceholder")}
              onSearch={onSearch}
              loading={dishesStatus === RequestStatus.SEARCHING}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ width: 300, margin: "20px 0" }}
            />
            {Boolean(searchValue) && (
              <Button onClick={onClear} type="link">
                {t("reset")}
              </Button>
            )}
          </div>
          <Table<Dish>
            dataSource={dishes}
            rowKey="id"
            loading={dishesStatus === RequestStatus.FETCHING}
            scroll={{ x: true }}
            pagination={{
              pageSize: dishesPerPage,
              total: dishesTotalPages * dishesPerPage,
              current: dishesPage,
              showSizeChanger: false,
            }}
            onChange={handleChange}
          >
            <Table.Column<Dish> title={t("dish.name")} dataIndex="name" key="name" />
            <Table.Column<Dish>
              title={t("ingredientsTable.servingSize")}
              dataIndex="portionSize"
              key="portionSize"
              render={(packageSize: number) => packageSize || 100}
            />
            <Table.Column<Dish> title={t("ingredientsTable.calories")} dataIndex="calories" key="calories" />
            <Table.Column<Dish>
              title={t("ingredientsTable.protein")}
              dataIndex="protein"
              key="protein"
              render={(_, row) => sumNutrients(row.ingredients, "protein")}
            />
            <Table.Column<Dish>
              title={t("ingredientsTable.carbs")}
              dataIndex={["nutrients", "carbohydrates"]}
              key="carbs"
              render={(_, row) => sumNutrients(row.ingredients, "carbohydrates")}
            />
            <Table.Column<Dish>
              title={t("ingredientsTable.fat")}
              dataIndex={["nutrients", "fat"]}
              key="fat"
              render={(_, row) => sumNutrients(row.ingredients, "fat")}
            />
            <Table.Column<Dish>
              title={t("common:list.options")}
              key="action"
              align="left"
              render={(_, row) => (
                <Space>
                  <Button type="link" onClick={() => handleEdit(row)}>
                    {t("common:list.edit")}
                  </Button>
                  {isTrainer && (
                    <Popconfirm
                      overlayStyle={{ maxWidth: 400 }}
                      placement="leftTop"
                      title={t("dish.deleteWarning")}
                      onConfirm={() => deleteDish(row.id)}
                    >
                      <Button type="link" danger loading={deleting === row.id}>
                        {t("common:button.delete")}
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              )}
            />
          </Table>
        </Space>
      </Card>
      <ModalForm
        open={showModalForm}
        onCancel={() => toggleModalForm(false)}
        title={t("dish.addNewDish")}
        width={900}
        loading={!!loading}
      >
        <DishForm onSubmit={onSaveDish} model={formModel} />
      </ModalForm>
    </>
  );
};

export default NutritionDishes;
