import { createSelector } from "@reduxjs/toolkit";

import {
  ExerciseInWorkoutType,
  RestType,
  WorkoutSliderType,
  type LiveWorkoutData,
  type WorkoutSliderItem,
} from "@fitness-app/data-models/entities/LiveWorkout";
import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AppStore } from "../../../index";
import { getLiveWorkout } from "./getLiveWorkout";

const getWorkout = (store: AppStore) => store.liveWorkout.activity;

export const workoutBuilder = (workout: LiveWorkoutData, workoutData: ProgramWorkout): WorkoutSliderItem[] => {
  const data: WorkoutSliderItem[] = [];
  data.push({
    type: WorkoutSliderType.StartInfo,
    exercises: workout.filter((item) => item.type === ExerciseInWorkoutType.Normal).length ?? 0,
    supersets: workout.filter((item) => item.type === ExerciseInWorkoutType.SuperSet).length ?? 0,
    comment: workoutData.description || "",
    name: workoutData.name || "",
  });
  workout.forEach((item) => {
    if (item.type === ExerciseInWorkoutType.Normal) {
      data.push({
        type: WorkoutSliderType.ExerciseInfo,
        exercisesInfo: [item],
        isSuperSet: false,
        workoutPart: item.workoutPart,
      });
      item.series.forEach((set) => data.push(set));
    }
    if (item.type === RestType.AfterExercise) {
      data.push(item);
      data.push({
        type: WorkoutSliderType.ProgressInfo,
      });
    }
    if (item.type === ExerciseInWorkoutType.SuperSet) {
      data.push({
        type: WorkoutSliderType.ExerciseInfo,
        exercisesInfo: item.mergedExercises,
        isSuperSet: true,
        workoutPart: item.workoutPart,
      });
      item.series.forEach((set) => data.push(set));
    }
  });
  data.push({
    type: WorkoutSliderType.ProgressInfo,
  });
  data.push({
    type: WorkoutSliderType.EndInfo,
  });
  return data;
};

export const getLiveWorkoutItems = createSelector([getLiveWorkout, getWorkout], (liveWorkout, workout) => {
  if (!liveWorkout || !workout) {
    return [];
  }
  return workoutBuilder(liveWorkout.liveWorkout, workout.data);
});
