import React from "react";
import { UndoOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";

export const RevertLastChanges = () => {
  const { canRevertLastChanges, revert } = useMealsPlanContext();

  if (!canRevertLastChanges) {
    return null;
  }

  return (
    <Button onClick={revert} size="small" icon={<UndoOutlined />}>
      Cofnij ({canRevertLastChanges})
    </Button>
  );
};
