import React, { useEffect } from "react";
import { Form, Input, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { TeamMemberRole } from "@fitness-app/data-models";

import { useTagOptions } from "~/hooks/useTagOptions";
import { type TeamTrainerFormModel } from "./types";

interface TeamTrainerFormProps {
  formController?: FormInstance<TeamTrainerFormModel>;
  onSubmit: (formData: TeamTrainerFormModel) => void;
  model?: Partial<TeamTrainerFormModel> | null;
}

const TeamTrainerForm = ({ model, onSubmit, formController }: TeamTrainerFormProps) => {
  const { t } = useTranslation(["team", "common"]);
  const { options: tagsOptions } = useTagOptions("specializationTags");

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model, formController]);

  const editMode = Boolean(model);

  return (
    <Form<TeamTrainerFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        status: true,
        role: null,
        specializationTags: [],
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="firstName"
        label={t<string>("common.firstName")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input disabled={editMode} />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t<string>("common.lastName")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input disabled={editMode} />
      </Form.Item>
      <Form.Item
        name="email"
        label={t<string>("common.email")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            type: "email",
            message: t<string>("common:validationErrors.wrongEmail"),
          },
        ]}
      >
        <Input disabled={editMode} />
      </Form.Item>

      <Form.Item
        name="role"
        label={t<string>("common.role")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          placeholder={t<string>("selectRole")}
          options={[
            {
              label: t("role.regular_trainer"),
              value: TeamMemberRole.REGULAR_TRAINER,
            },
            {
              label: t("role.shared_trainer"),
              value: TeamMemberRole.SHARED_TRAINER,
            },
          ]}
        />
      </Form.Item>

      <Form.Item name="specializationTags" label={t<string>("membersSpecializations")}>
        <Select notFoundContent={false} options={tagsOptions} mode="tags" />
      </Form.Item>

      <Form.Item name="status" label={t<string>("common.active")} valuePropName="checked">
        <Switch disabled={!model} />
      </Form.Item>
    </Form>
  );
};

export default TeamTrainerForm;
