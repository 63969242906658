import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isoWeek from "dayjs/plugin/isoWeek";

import { type ClientNutritionWeek } from "@fitness-app/data-models/entities/ClientNutritionWeek";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

type Payload = {
  traineeId: string;
  dateStart: Date;
  dateEnd: Date;
};

export const fetchNutritionSummaryForRange = createAsyncThunk<
  ClientNutritionWeek[],
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/fetchNutritionSummaryForRange`,
  async ({ traineeId, dateStart, dateEnd }, { rejectWithValue, extra: { db } }) => {
    const startDate = dayjs(dateStart);
    const endDate = dayjs(dateEnd);

    const startWeek = startDate.format("YYYY-WW");
    let endWeek = endDate.format("YYYY-WW");

    if (endWeek.endsWith("01")) {
      endWeek = endDate.subtract(1, "week").format("YYYY-WW");
    }

    const { error, data } = await db
      .from("client_nutrition_week")
      .select("*")
      .order("eventWeekIso")
      .eq("traineeId", traineeId)
      .gte("eventWeekIso", startWeek)
      .lte("eventWeekIso", endWeek)
      .returns<ClientNutritionWeek[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data || [];
  },
);
