import {
  AuthType,
  type APIKey,
  type BasicAuth,
  type BearerToken,
  type Webhook,
} from "@fitness-app/data-models/entities/Automation";

import {
  API_KEY_SECRET,
  type WebhookFormModel,
} from "~/modules/Products/Product/ProductAutomation/components/WebhookForm/types";

export const formatAuthIntoFormModel = (model: Webhook) => {
  if (model.auth?.type === AuthType.BasicAuth) {
    return {
      authType: AuthType.BasicAuth,
      auth: {
        username: API_KEY_SECRET,
        password: API_KEY_SECRET,
      },
    };
  }

  if (model.auth?.type === AuthType.BearerToken) {
    return {
      authType: AuthType.BearerToken,
      auth: {
        token: API_KEY_SECRET,
      },
    };
  }

  if (model.auth?.type === AuthType.ApiKey) {
    return {
      authType: AuthType.ApiKey,
      auth: {
        key: model.auth.key,
        value: API_KEY_SECRET,
        addTo: model.auth.addTo,
      },
    };
  }

  return {
    authType: AuthType.None,
    auth: null,
  };
};

export type AuthFormatter =
  | { toSave: BasicAuth; toProcess: BasicAuth }
  | { toSave: null; toProcess: null }
  | { toSave: APIKey; toProcess: APIKey }
  | { toSave: BearerToken; toProcess: BearerToken };

export const formatAuthFromModel = (model: WebhookFormModel): AuthFormatter => {
  if (model.authType === AuthType.BasicAuth && model.auth && "username" in model.auth) {
    return {
      toSave: {
        type: AuthType.BasicAuth,
        username: "processing",
        password: "processing",
      },
      toProcess: {
        type: AuthType.BasicAuth,
        username: model.auth.username,
        password: model.auth.password,
      },
    };
  }
  if (model.authType === AuthType.BearerToken && model.auth && "token" in model.auth) {
    return {
      toSave: {
        type: AuthType.BearerToken,
        token: "processing",
      },
      toProcess: {
        type: AuthType.BearerToken,
        token: model.auth.token,
      },
    };
  }
  if (model.authType === AuthType.ApiKey && model.auth && "addTo" in model.auth) {
    return {
      toSave: {
        type: AuthType.ApiKey,
        key: model.auth.key,
        value: "processing",
        addTo: model.auth.addTo,
      },
      toProcess: {
        type: AuthType.ApiKey,
        key: model.auth.key,
        value: model.auth.value,
        addTo: model.auth.addTo,
      },
    };
  }
  return {
    toSave: null,
    toProcess: null,
  };
};

export const formatUpdatedAuthFromModel = (model: WebhookFormModel, webhook: Webhook): AuthFormatter => {
  if (!webhook.auth) {
    return formatAuthFromModel(model);
  }

  if (model.authType === AuthType.BearerToken && model.auth && "token" in model.auth) {
    return {
      toSave: {
        type: AuthType.BearerToken,
        token: "processing",
      },
      toProcess: {
        type: AuthType.BearerToken,
        token: model.auth.token,
      },
    };
  }
  if (model.authType === AuthType.ApiKey && model.auth && "addTo" in model.auth) {
    return {
      toSave: {
        type: AuthType.ApiKey,
        key: model.auth.key,
        value: "processing",
        addTo: model.auth.addTo,
      },
      toProcess: {
        type: AuthType.ApiKey,
        key: model.auth.key,
        value: model.auth.value,
        addTo: model.auth.addTo,
      },
    };
  }
  return {
    toSave: null,
    toProcess: null,
  };
};
