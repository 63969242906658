import React, { useEffect, useMemo, useState } from "react";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Divider, Empty, Skeleton, Space, Switch, Timeline, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { automationLogsActions, RequestStatus } from "@fitness-app/app-store";
import { ProductAutomationStatus } from "@fitness-app/data-models/entities/ProductAutomationLog";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import LogContentItem from "./LogContentItem";

interface ClientAutomationLogsProps {
  productClient: ProductClient;
  ownerId?: string;
}

const colorMapper = {
  [ProductAutomationStatus.Success]: "green",
  [ProductAutomationStatus.Error]: "red",
  [ProductAutomationStatus.Warning]: "orange",
};

const dotMapper = {
  [ProductAutomationStatus.Success]: <CheckCircleOutlined />,
  [ProductAutomationStatus.Error]: <ExclamationCircleOutlined />,
  [ProductAutomationStatus.Warning]: <WarningOutlined />,
};
const ClientAutomationLogs = ({ productClient, ownerId }: ClientAutomationLogsProps) => {
  const { t } = useTranslation(["products", "common"]);
  const dispatch = useAppDispatch();
  const { clientAutomationLogs, clientAutomationLogsStatus } = useAppSelector((state) => state.automationLogs);
  const productId = productClient.productId;
  const clientEmail = productClient.email;
  const [onlyConnectedWithProduct, setOnlyConnectedWithProduct] = useState(true);

  useEffect(() => {
    void dispatch(automationLogsActions.fetchClientLogs({ productId, clientEmail }));
  }, [productId, clientEmail, ownerId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(automationLogsActions.clearClientsLogs());
    };
  }, [dispatch]);

  const filteredLogs = useMemo(() => {
    if (!onlyConnectedWithProduct) {
      return clientAutomationLogs;
    }

    return clientAutomationLogs.filter((log) => log.productId === productId);
  }, [clientAutomationLogs, onlyConnectedWithProduct, productId]);

  return (
    <Skeleton active loading={clientAutomationLogsStatus === RequestStatus.SUBSCRIBING}>
      <Divider orientation="left">{t("automationLogs.title")}</Divider>

      <div className="full-width mb-6 flex justify-end px-4">
        <Space>
          <Typography.Text strong>{t("automationLogs.showOnlyConnectedWithSelectedProduct")}</Typography.Text>
          <Switch checked={onlyConnectedWithProduct} onChange={setOnlyConnectedWithProduct} />
        </Space>
      </div>

      {filteredLogs.length === 0 ? (
        <div className="py-6">
          <Empty />
        </div>
      ) : (
        <div className="py-6">
          <Timeline mode="left">
            {filteredLogs.map((log) => (
              <Timeline.Item
                key={log.id}
                color={colorMapper[log.status]}
                dot={dotMapper[log.status]}
                label={
                  <span className="mr-2">
                    <ClockCircleOutlined className="mr-2" />
                    {dayjs.unix(log.timestamp).format("HH:mm:ss DD.MM.YYYY")}
                  </span>
                }
              >
                <LogContentItem log={log} />
              </Timeline.Item>
            ))}
            <Timeline.Item color="blue" dot={<UserOutlined />}>
              <p>{t("automationLogs.accountCreated")}</p>
            </Timeline.Item>
          </Timeline>
        </div>
      )}
    </Skeleton>
  );
};

export default ClientAutomationLogs;
