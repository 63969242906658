import React, { useEffect, useState, type FunctionComponent } from "react";
import { Alert, Button, Form, Input, Result, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import { userActions, UserStatus } from "@fitness-app/app-store";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface LoginFormModel {
  email: string;
}

const tailLayout = {
  wrapperCol: { offset: 16, span: 8 },
};

const LoginByEmail: FunctionComponent = () => {
  const { t } = useTranslation(["auth", "common"]);
  const dispatch = useAppDispatch();
  const [formController] = Form.useForm<LoginFormModel>();
  const [loading, toggleLoader] = useState(false);
  const [showSuccess, toggleSuccess] = useState(false);
  const { status: userStatus, error } = useAppSelector((store) => store.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  const emailInQuery = email ? decodeURI(email ?? "").replace(" ", "+") : "";

  useEffect(() => {
    dispatch(userActions.resetErrors());
  }, []);

  useEffect(() => {
    if (emailInQuery) {
      setTimeout(() => {
        formController.submit();
        setSearchParams("");
      }, 0);
    }
  }, [emailInQuery]);

  const onFinish = async (values: LoginFormModel) => {
    const validatedEmail = values.email?.trim();
    toggleLoader(true);
    const isLocalhost = window.location.hostname === "localhost";
    // eslint-disable-next-line
    await dispatch(
      userActions.sendLoginLink(
        validatedEmail,
        isLocalhost ? `${window.location.origin}/auth/sign-with-link` : undefined,
      ),
    );
    window.localStorage.setItem("emailForSignIn", validatedEmail ?? "");
    toggleLoader(false);
    toggleSuccess(true);
  };

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="min-w-[350px] p-4">
        {showSuccess ? (
          <div>
            <Result
              status="success"
              title={t("auth:sendLink.successTitle")}
              subTitle={t("auth:sendLink.successMessage")}
              extra={<Link to="/">{t("common:goToLoginPage")}</Link>}
            />
          </div>
        ) : (
          <>
            <Typography.Title level={4} className="pb-8">
              {t("loginPanel")}
            </Typography.Title>
            {error && (
              <Alert
                className="mb-10"
                type={userStatus === UserStatus.HAS_ERROR ? "error" : "info"}
                message={userStatus === UserStatus.HAS_ERROR ? error : <Trans t={t} i18nKey="common:startJourney" />}
              />
            )}

            <Form<LoginFormModel>
              name="login-form"
              form={formController}
              layout="vertical"
              onFinish={onFinish}
              size="large"
              validateTrigger={["onSubmit", "onBlur"]}
              initialValues={{ email: emailInQuery || "" }}
            >
              <Form.Item
                name="email"
                label={t("login")}
                rules={[
                  {
                    required: true,
                    message: t<string>("validationErrors.fieldIsRequired"),
                  },
                  {
                    type: "email",
                    message: t<string>("validationErrors.wrongEmail"),
                    transform: (value: string) => value?.trim(),
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" block className="basis-4/12" loading={loading}>
                  {t("common:button.sendLink")}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginByEmail;
