import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { type WorkoutTemplateWithCreator } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { duplicateCollection } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { WORKOUT_TEMPLATES_REDUCER_NAME } from "../types";
import { fetchWorkoutTemplates } from "./fetchWorkoutTemplates";

type Payload = WorkoutTemplateWithCreator;

export const duplicateWorkoutTemplate = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${WORKOUT_TEMPLATES_REDUCER_NAME}/duplicateWorkoutTemplate`,
  async (template, { rejectWithValue, dispatch, getState, extra: { db } }) => {
    const { data } = getState().user;
    const { creator, ...rest } = template;
    const duplicated = {
      ...rest,
      createdBy: data?.id || null,
      exercises: duplicateCollection(template.exercises),
      postWorkout: duplicateCollection(template.postWorkout),
      preWorkout: duplicateCollection(template.preWorkout),
      id: uuid(),
      name: `${rest.name} Kopia`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const { error } = await db.from("workout_template").insert(duplicated);

    if (error) {
      return rejectWithValue(error.message);
    }

    void dispatch(fetchWorkoutTemplates());
  },
);
