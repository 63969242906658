import { useMemo } from "react";
import { orderBy } from "lodash";

import { userSelectors } from "@fitness-app/app-store";
import { TeamMemberRole, UserRole } from "@fitness-app/data-models";
import { type ChatChannel, type ChatMember } from "@fitness-app/data-models/entities/Chat";

import { useAppSelector } from "~/store/initializeStore";

export const useChatAuthorOptions = (channel: ChatChannel): ChatMember[] => {
  const user = useAppSelector((state) => state.user.profile);
  const selectedMember = useAppSelector((state) => state.team.selectedMember);
  const parentId = useAppSelector(userSelectors.getParentTrainerId);

  return useMemo(() => {
    if (!user) {
      return [];
    }

    const withoutUser = Object.keys(channel.members).filter(
      (memberId) =>
        channel.members[memberId]?.uid !== user.id &&
        channel.members[memberId]?.role !== UserRole.CLIENT &&
        channel.members[memberId]?.role !== UserRole.SYSTEM &&
        !channel.members[memberId]?.disabled,
    );

    const idsWithoutUser = orderBy(withoutUser, ["role"]);

    if (user.role === UserRole.TRAINER) {
      return idsWithoutUser
        .map((memberId) => channel.members[memberId])
        .filter((member): member is ChatMember => !!member);
    }

    if (user.role === UserRole.TEAM_MEMBER && selectedMember?.role === TeamMemberRole.SHARED_TRAINER) {
      return idsWithoutUser
        .filter((id) => id !== parentId)
        .map((memberId) => channel.members[memberId])
        .filter((member): member is ChatMember => !!member);
    }

    return Object.keys(channel.members)
      .filter((memberId) => channel.members[memberId]?.role === UserRole.BOT)
      .map((id) => channel.members[id])
      .filter((member): member is ChatMember => !!member);
  }, [user, channel, parentId, selectedMember]);
};
