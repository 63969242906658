import { type TFunction } from "i18next";

import { CycleSurveyType, SurveyIntervalUnit, SurveyType } from "@fitness-app/data-models/entities/ClientSurvey";

export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 23];

export const createSurveyTypeOptions = (t: TFunction<string[]>) => [
  {
    value: SurveyType.OneTime,
    label: t("surveyType.one-time"),
  },
  {
    value: SurveyType.Recurring,
    label: t("surveyType.recurring"),
  },
];

export const createIntervalOptions = (t: TFunction<string[]>, monthDisabled = false) => [
  {
    value: SurveyIntervalUnit.Week,
    label: t("surveyIntervalUnit.week"),
  },
  {
    value: SurveyIntervalUnit.Month,
    label: t("surveyIntervalUnit.month"),
    disabled: monthDisabled,
  },
];

export const createCycleTypeOptions = (t: TFunction<string[]>, withDateOption = true) => [
  {
    value: CycleSurveyType.Infinite,
    label: t("cycleType.infinite"),
  },
  ...(withDateOption
    ? [
        {
          value: CycleSurveyType.UntilDate,
          label: t("cycleType.untilDate"),
        },
      ]
    : []),
  {
    value: CycleSurveyType.CycleNumber,
    label: t("cycleType.cycleNumber"),
  },
];
