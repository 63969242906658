import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ChatMessage } from "@fitness-app/data-models/entities/Chat";

import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  channelId: string;
};

export const getNextChannelMessages = createAsyncThunk<
  { page: number; messages: ChatMessage[]; isLastPage: boolean } | undefined,
  Payload,
  AsyncThunkCreator<string>
>(
  `${CHAT_REDUCER_NAME}/getNextChannelMessages`,
  async (payload, { rejectWithValue, getState, extra: { db } }) => {
    const { messagesBatchSize, channelsStatus } = getState().chat;

    const { start, stop, currentPage } = getPageRange(
      channelsStatus[payload.channelId]?.currentPage,
      messagesBatchSize,
      true,
    );

    const { error, data } = await db
      .from("chat_message")
      .select("*, metadata:chat_message_metadata(transcription, summary, context, sentiment)")
      .order("createdAt", { ascending: false })
      .eq("deleted", false)
      .eq("channelId", payload.channelId)
      .limit(messagesBatchSize)
      .range(start, stop)
      .returns<ChatMessage[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return { messages: data, isLastPage: data.length < messagesBatchSize, page: currentPage };
  },
  {
    condition: (payload, { getState }) => {
      const { chat } = getState();
      const channelStatus = chat.channelsStatus[payload.channelId];
      if (channelStatus?.fetchingNextPage) {
        // Already fetching, don't need to re-fetch
        return false;
      }
    },
  },
);
