import { type Database } from "@fitness-app/supabase";

export type TrainerConfigEntity = Database["public"]["Tables"]["trainer_config"]["Row"];
export type AppConfigEntity = Database["public"]["Tables"]["app_config"]["Row"];

export interface TrainerConfig extends TrainerConfigEntity {
  config: {
    limit?: number;
  };
  mealsPlanTags: string[];
  trainingProgramTags: string[];
  mealDaysTags: string[];
  traineeTags: string[];
  workoutTags: string[];
  specializationTags: string[];
  exercisesTags: string[];
}

export enum AppConfigType {
  APP_VERSION = "app_version",
}

export interface AppVersionData {
  current: string;
  minRequired: string;
  versionsWithIssues: string[];
}

export interface AppVersionByPlatform {
  ios: AppVersionData | null;
  android: AppVersionData | null;
  web: AppVersionData | null;
}

export interface AppVersionConfig extends Omit<AppConfigEntity, "data"> {
  type: AppConfigType.APP_VERSION;
  data: AppVersionByPlatform;
}
