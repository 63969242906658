import { z } from "zod";

import { type Database } from "@fitness-app/supabase";

import { type DishIngredient, type MealTypeEnum } from "./Dish";
import { type MealIngredientStatus } from "./MealsPlan";

export type IngredientEntity = Database["public"]["Tables"]["ingredient"]["Row"];
export type IngredientCategoryEntity = Database["public"]["Tables"]["ingredient_main_category"]["Row"];
export type IngredientGroup = Database["public"]["Tables"]["ingredient_group"]["Row"];
export type IngredientMeasure = Database["public"]["Tables"]["ingredient_measurement"]["Row"];

export type IngredientCategory = Omit<IngredientCategoryEntity, "subCategories">;

export interface Nutrients {
  calories: number;
  protein: number;
  vegetableProtein: number | null;
  animalProtein: number | null;
  fat: number;
  carbohydrates: number;
  digestibleCarbs: number | null;
  sugars: number | null;
  saturatedFat: number | null;
  polyunsaturatedFat: number | null;
  monounsaturatedFat: number | null;
  fiber: number | null;
  water: number | null;
  cholesterol: number | null;
  sodium: number | null;
  salt: number | null;
  calcium: number | null;
  potassium: number | null;
  iron: number | null;
  magnesium: number | null;
  zinc: number | null;
  phosphorus: number | null;
  iodine: number | null;
  copper: number | null;
  selenium: number | null;
  vitaminA: number | null;
  vitaminB1: number | null;
  vitaminB2: number | null;
  vitaminB5: number | null;
  vitaminB6: number | null;
  vitaminB7: number | null;
  vitaminB12: number | null;
  vitaminC: number | null;
  vitaminD: number | null;
  vitaminE: number | null;
  vitaminK: number | null;
  vitaminPP: number | null;
  folicAcid: number | null;
  omega3: number | null;
  omega6: number | null;
  caffeine: number | null;
  carbohydrateExchangers: number | null;
  proteinFatExchangers: number | null;
  glycemicIndex: number | null;
  glycemicLoad: number | null;
}

export interface Measure {
  id: number;
  name: string;
  energyPerUnit: number;
  weightPerUnit: number;
  unit: string;
  fromFitatu?: boolean | null;
  kcalmarMeasurement?: string | null;
}

export interface Ingredient extends Omit<IngredientEntity, "measures" | "nutrients"> {
  nutrients: Nutrients;
  measures: Measure[];
  type: "ingredient";
  kcalmar?: object | null;
  source: "client_app" | "owner_app" | null | string;
}

export interface IngredientWithPortion extends Ingredient {
  portion: number;
  ingredientId: string;
  status?: MealIngredientStatus | null;
  clientNote?: string;
  measureText?: string;
  fromTracker?: boolean;
  measureId?: number | null;
  multiplier?: number | null;
}

export type WithDayAndMealName = {
  inMeals: {
    dayId: string;
    mealName: string | null;
    mealType: MealTypeEnum;
    dishName: string;
    dishId: string;
    mealId: string;
    dishPreparationSteps: string[];
  }[];
};

export type IngredientOnShoppingList =
  | (IngredientWithPortion & WithDayAndMealName)
  | (DishIngredient & WithDayAndMealName);

export type LeanIngredientOnShoppingList = {
  inMeals: {
    dayId: string;
    mealName: string | null;
    mealType: MealTypeEnum;
    dishName: string;
    dishId: string;
    mealId: string;
    dishPreparationSteps: string[];
  }[];
  checked: boolean;
  portion: number;
  ingredientId: string;
  id: string;
  multiplier: number | null;
  measureName: string | null;
  measure: Measure | null;
  name: string;
};

export const measureSchema = z.object({
  id: z.number(),
  name: z.string(),
  energyPerUnit: z.number(),
  weightPerUnit: z.number(),
  unit: z.string(),
  fromFitatu: z.boolean().optional().nullable(),
  kcalmarMeasurement: z.string().optional().nullable(),
}) satisfies z.Schema<Measure>;

export const nutrientsSchema = z.object({
  calories: z.number(),
  protein: z.number(),
  vegetableProtein: z.number().nullable(),
  animalProtein: z.number().nullable(),
  fat: z.number(),
  carbohydrates: z.number(),
  digestibleCarbs: z.number().nullable(),
  sugars: z.number().nullable(),
  saturatedFat: z.number().nullable(),
  polyunsaturatedFat: z.number().nullable(),
  monounsaturatedFat: z.number().nullable(),
  fiber: z.number().nullable(),
  water: z.number().nullable(),
  cholesterol: z.number().nullable(),
  sodium: z.number().nullable(),
  salt: z.number().nullable(),
  calcium: z.number().nullable(),
  potassium: z.number().nullable(),
  iron: z.number().nullable(),
  magnesium: z.number().nullable(),
  zinc: z.number().nullable(),
  phosphorus: z.number().nullable(),
  iodine: z.number().nullable(),
  copper: z.number().nullable(),
  selenium: z.number().nullable(),
  vitaminA: z.number().nullable(),
  vitaminB1: z.number().nullable(),
  vitaminB2: z.number().nullable(),
  vitaminB5: z.number().nullable(),
  vitaminB6: z.number().nullable(),
  vitaminB7: z.number().nullable(),
  vitaminB12: z.number().nullable(),
  vitaminC: z.number().nullable(),
  vitaminD: z.number().nullable(),
  vitaminE: z.number().nullable(),
  vitaminK: z.number().nullable(),
  vitaminPP: z.number().nullable(),
  folicAcid: z.number().nullable(),
  omega3: z.number().nullable(),
  omega6: z.number().nullable(),
  caffeine: z.number().nullable(),
  carbohydrateExchangers: z.number().nullable(),
  proteinFatExchangers: z.number().nullable(),
  glycemicIndex: z.number().nullable(),
  glycemicLoad: z.number().nullable(),
}) satisfies z.Schema<Nutrients>;

export const ingredientSchema = z
  .object({
    type: z.literal("ingredient"),
    id: z.string(),
    name: z.string(),
    image: z.string().nullable(),
    brand: z.string().nullable(),
    packageSize: z.number().nullable(),
    groups: z.array(z.number()).nullable(),
    mainCategory: z.number().nullable(),
    category: z.number().nullable(),
    manufacturer: z.string().nullable(),
    unit: z.string().nullable(),
    tags: z.array(z.string()).nullable(),
    isVegan: z.boolean().nullable(),
    isVegetarian: z.boolean().nullable(),
    isDairyFree: z.boolean().nullable(),
    isGlutenFree: z.boolean().nullable(),
    isSuperFood: z.boolean().nullable(),
    price: z.number().nullable(),
    calories: z.number().nullable(),
    createdAt: z.string().nullable(),
    updatedAt: z.string().nullable(),
    barCode: z.string().nullable(),
    createdBy: z.string().nullable(),
    verified: z.boolean().nullable(),
    rawIngredients: z.string().nullable(),
    liquid: z.boolean().nullable(),
    isHighProtein: z.boolean().nullable(),
    source: z.string().nullable(),
    rating: z.number().nullable(),
    measures: z.array(measureSchema),
    nutrients: nutrientsSchema,
  })
  .required() satisfies z.Schema<Ingredient>;

export const nutrientsModel: Record<keyof Nutrients, string | string[]> = {
  calories: "kcal",
  protein: "g",
  vegetableProtein: "g",
  animalProtein: "g",
  fat: "g",
  carbohydrates: "g",
  digestibleCarbs: "g",
  sugars: "g",
  saturatedFat: "g",
  polyunsaturatedFat: "g",
  monounsaturatedFat: "g",
  salt: "g",
  fiber: "g",
  water: "g",
  cholesterol: "mg",
  sodium: ["mg", "µg"],
  selenium: ["µg", "mg"],
  calcium: ["mg", "µg"],
  potassium: ["mg", "µg"],
  iron: ["mg", "µg"],
  magnesium: ["mg", "µg"],
  zinc: ["mg", "µg"],
  phosphorus: ["mg", "µg"],
  iodine: ["µg", "mg"],
  copper: ["mg", "µg"],
  vitaminA: ["µg", "mg"],
  vitaminB1: ["mg", "µg"],
  vitaminB2: ["mg", "µg"],
  vitaminB5: ["mg", "µg"],
  vitaminB6: ["mg", "µg"],
  vitaminB7: ["µg", "mg"],
  vitaminB12: ["µg", "mg"],
  vitaminC: ["mg", "µg"],
  vitaminD: ["µg", "mg"],
  vitaminE: ["mg", "µg"],
  vitaminK: ["µg", "mg"],
  vitaminPP: ["mg", "µg"],
  folicAcid: ["µg", "mg"],
  omega3: "g",
  omega6: "g",
  caffeine: "mg",
  carbohydrateExchangers: "",
  proteinFatExchangers: "",
  glycemicIndex: "",
  glycemicLoad: "",
};
