export const DEFAULT_LIST_OF_SUPPLEMENTS = [
  { label: "AAKG", value: "AAKG" },
  { label: "Arginina", value: "Arginina" },
  { label: "Beta-alanina", value: "Beta-alanina" },
  { label: "BCAA", value: "BCAA" },
  { label: "Cytrulina", value: "Cytrulina" },
  { label: "Guarana", value: "Guarana" },
  { label: "Kreatyna", value: "Kreatyna" },
  { label: "Kwas foliowy", value: "Kwas foliowy" },
  { label: "Kwas Omega 3", value: "Kwas Omega 3" },
  { label: "Kofeina", value: "Kofeina" },
  { label: "Witamina D3", value: "Witamina D3" },
  { label: "Witamina K2", value: "Witamina K2" },
  { label: "Witamina C", value: "Witamina C" },
];
