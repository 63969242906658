import { createAsyncThunk } from "@reduxjs/toolkit";
import { type UpdateProductDTO } from "@fitness-app/data-models/entities/Product";
import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = UpdateProductDTO;

export const updateProduct = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCT_REDUCER_NAME}/updateProduct`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/update-product", payload);
      analytics.track("update_product", {
        productId: payload.id,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
