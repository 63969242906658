import { type Order } from "@fitness-app/data-models/entities/Order";
import { type ProductClientWithTraineeInfo } from "@fitness-app/data-models/entities/ProductClient";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PRODUCT_CLIENT_REDUCER = "productClientReducer";

export interface ProductClientReducer {
  status: null | RequestStatus;
  data: ProductClientWithTraineeInfo | null;
  error: null | string;
  clientOrders: Order[];
  ordersStatus: null | RequestStatus;
  ordersPage: number;
  ordersPageSize: number;
}
