import { createAsyncThunk } from "@reduxjs/toolkit";

import { ChatStatus, type ChatChannelWithLastMessage } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = ChatStatus;

export const getNextChatListPage = createAsyncThunk<
  { data: ChatChannelWithLastMessage[]; page: number; isLastPage: boolean },
  Payload,
  AsyncThunkCreator<string>
>(`${CHAT_REDUCER_NAME}/getNextChatListPage`, async (payload, { rejectWithValue, getState, extra: { db, auth } }) => {
  const currentUser = await getLoggedUser(auth);

  const { channelsSize, channelsListStatus, archivedListStatus } = getState().chat;

  const { start, stop, currentPage } = getPageRange(
    payload === ChatStatus.Active ? channelsListStatus.currentPage : archivedListStatus.currentPage,
    channelsSize,
    true,
  );

  const { error, data } = await db
    .from("chat_channel")
    .select(
      "*, lastMessage:lastMessageId(id, content, createdAt), profiles:user_profile(avatarUrl, firstName, lastName, id, email))",
    )
    .order("updatedAt", { ascending: false })
    .contains("assignedUsers", [currentUser.id])
    .eq("status", payload)
    .limit(channelsSize)
    .range(start, stop)
    .returns<ChatChannelWithLastMessage[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return { data, page: currentPage + 1, isLastPage: data.length < channelsSize };
});
