import React, { useCallback } from "react";
import { Descriptions, Typography } from "antd";

import { MediaContentType, type Campaign } from "@fitness-app/data-models/entities/Campaign";

import { VideoItem } from "~/components/VideoPlayer/VideoItem";

interface CampaignDetailsProps {
  campaign: Campaign;
}

const { Text, Link, Paragraph } = Typography;

export const CampaignDetails = ({ campaign }: CampaignDetailsProps): React.ReactElement => {
  const renderContent = useCallback(() => {
    if (campaign.data.contentType === MediaContentType.None) {
      return null;
    }

    if (campaign.data.contentType === MediaContentType.Image) {
      return (
        <Descriptions.Item label="Zdjęcie">
          <VideoItem data={campaign.data} />
        </Descriptions.Item>
      );
    }

    if (
      campaign.data.contentType === MediaContentType.Video ||
      campaign.data.contentType === MediaContentType.Youtube
    ) {
      return (
        <Descriptions.Item label="Video">
          <VideoItem data={campaign.data} />
        </Descriptions.Item>
      );
    }

    return null;
  }, [campaign]);

  return (
    <Descriptions title="Szczegóły kampanii" bordered column={1}>
      <Descriptions.Item label="Tytuł">
        <Text strong>{campaign.data.message.title}</Text>
      </Descriptions.Item>
      <Descriptions.Item label="Opis" labelStyle={{ width: 150 }}>
        <Paragraph
          className="whitespace-pre-wrap"
          ellipsis={{
            rows: 6,
            expandable: "collapsible",
            symbol: (open) => (open ? "mniej" : "więcej"),
          }}
        >
          {campaign.data.message.description}
        </Paragraph>
      </Descriptions.Item>
      <Descriptions.Item label="Link">
        <Link href={campaign.data.message.ctaLink} target="_blank" copyable>
          {campaign.data.message.ctaLink}
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label="Tekst dla CTA">
        <Text underline>{campaign.data.message.ctaText}</Text>
      </Descriptions.Item>
      {renderContent()}
    </Descriptions>
  );
};
