import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Order } from "@fitness-app/data-models/entities/Order";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

export const fetchProductClientOrdersNextPage = createAsyncThunk<
  { orders: Order[]; page: number },
  { productId: string; clientEmail: string; page: number },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENT_REDUCER}/fetchProductClientOrdersNextPage`,
  async ({ productId, clientEmail, page }, { rejectWithValue, extra: { db }, getState }) => {
    const { ordersPageSize } = getState().productClient;
    const { error, data } = await db
      .from("product_order")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("product", productId)
      .eq("clientEmail", clientEmail)
      .range(page * ordersPageSize, ordersPageSize * (page + 1) - 1)
      .limit(ordersPageSize)
      .returns<Order[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return { orders: data, page };
  },
);
