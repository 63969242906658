import React, { useEffect } from "react";
import { ClockCircleOutlined, FlagOutlined, InfoCircleOutlined, TrophyOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Popover, Row, Skeleton, Space, Statistic, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { RequestStatus, traineeActions } from "@fitness-app/app-store";
import { ClientActivityFlag } from "@fitness-app/data-models/entities/ClientActivity";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { DATE_TIME_FORMAT, flagColor } from "../../constants";
import WorkoutWeekProgress from "../../WorkoutWeekProgress/WorkoutWeekProgress";

const TraineeStats = () => {
  const { t } = useTranslation(["trainee"]);
  const { profile, clientActivityReportStatus, clientActivityReport } = useAppSelector((store) => store.trainee);
  const status = profile?.status;
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      void dispatch(traineeActions.fetchClientActivity({ id }));
    }
  }, [dispatch, id]);

  if (status !== TraineeStatus.ACTIVE || !clientActivityReport) {
    return null;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Skeleton title={false} active loading={clientActivityReportStatus === RequestStatus.SUBSCRIBING}>
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-1 xl:grid-cols-3">
            <Card>
              <div className="flex flex-col justify-between sm:flex-row">
                <Statistic
                  title={
                    <Space size={4}>
                      <span>Aktywność treningowa</span>{" "}
                      <Tooltip title={t("dashboard:clientsActivity.workoutsStatus")}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  value={t<string>(`dashboard:clientsActivity.${clientActivityReport.workoutsStatus}`)}
                  suffix={
                    clientActivityReport ? (
                      <Popover
                        content={
                          <Space direction="vertical">
                            <Space>
                              <span>Ostatnia aktywność treningowa:</span>
                              <span>
                                {clientActivityReport.lastWorkoutActivityAt && (
                                  <>
                                    <ClockCircleOutlined />{" "}
                                  </>
                                )}
                                <Typography.Text strong>
                                  {clientActivityReport.lastWorkoutActivityAt
                                    ? dayjs.unix(clientActivityReport.lastWorkoutActivityAt).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </Typography.Text>
                              </span>
                            </Space>

                            {clientActivityReport.activities && (
                              <Space wrap size={12}>
                                {typeof clientActivityReport.activities[3].activitiesScheduled === "number" ||
                                clientActivityReport.activities[3].activitiesCompleted ? (
                                  <WorkoutWeekProgress
                                    label="3 tyg. temu"
                                    weekOfActivities={clientActivityReport.activities[3]}
                                  />
                                ) : null}
                                {typeof clientActivityReport.activities[2].activitiesScheduled === "number" ||
                                clientActivityReport.activities[2].activitiesCompleted ? (
                                  <WorkoutWeekProgress
                                    label="2 tyg. temu"
                                    weekOfActivities={clientActivityReport.activities[2]}
                                  />
                                ) : null}

                                {typeof clientActivityReport.activities[1].activitiesScheduled === "number" ||
                                clientActivityReport.activities[1].activitiesCompleted ? (
                                  <WorkoutWeekProgress
                                    label="Tydzień temu"
                                    weekOfActivities={clientActivityReport.activities[1]}
                                  />
                                ) : null}

                                {typeof clientActivityReport.activities[0].activitiesScheduled === "number" ||
                                clientActivityReport.activities[0].activitiesCompleted ? (
                                  <WorkoutWeekProgress
                                    label="Obecny tydzień"
                                    weekOfActivities={clientActivityReport.activities[0]}
                                  />
                                ) : null}
                              </Space>
                            )}

                            <Divider style={{ margin: "5px 0" }} />
                            <Space direction="horizontal">
                              <span>Brak dodanego planu lub brak aktywności klienta:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Danger] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>W ostatnich 2 tygodniach klient wykonał mniej niż 90% planu treningowego:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Warning] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>W ostatnich 2 tygodniach klient wykonał wszystkie treningi</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>
                                W ostatnich 2 tygodniach klient wykonał wszystkie treningi i dodatkowe aktywności
                              </span>
                              <TrophyOutlined style={{ color: flagColor[ClientActivityFlag.VerySafe] }} />
                            </Space>
                          </Space>
                        }
                        title="Status aktywności treningowej"
                        trigger="hover"
                      >
                        {clientActivityReport.workoutsStatus === ClientActivityFlag.VerySafe ? (
                          <TrophyOutlined style={{ color: flagColor[clientActivityReport.workoutsStatus] }} />
                        ) : (
                          <FlagOutlined style={{ color: flagColor[clientActivityReport.workoutsStatus] }} />
                        )}
                      </Popover>
                    ) : null
                  }
                  valueStyle={{
                    color: flagColor[clientActivityReport.workoutsStatus],
                    fontSize: 22,
                  }}
                />
              </div>
            </Card>
            <Card>
              <div className="flex flex-col justify-between sm:flex-row">
                <Statistic
                  title={
                    <Space size={4}>
                      <span>Komunikacja</span>{" "}
                      <Tooltip title={t("dashboard:clientsActivity.communicationStatus")}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  value={t<string>(`dashboard:clientsActivity.${clientActivityReport?.communicationStatus}`)}
                  valueStyle={{
                    color: flagColor[clientActivityReport.communicationStatus],
                    fontSize: 22,
                  }}
                  suffix={
                    clientActivityReport ? (
                      <Popover
                        content={
                          <Space direction="vertical">
                            <Space>
                              <span>Ostatni wiadomość wysłana przez klienta:</span>
                              <span>
                                {clientActivityReport.lastMessageAt && (
                                  <>
                                    <ClockCircleOutlined />{" "}
                                  </>
                                )}
                                <Typography.Text strong>
                                  {clientActivityReport.lastMessageAt
                                    ? dayjs.unix(clientActivityReport.lastMessageAt).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </Typography.Text>
                              </span>
                            </Space>

                            <Space>
                              <span>Ostatnie logowanie:</span>
                              <span>
                                {clientActivityReport.lastLoggedAt && (
                                  <>
                                    <ClockCircleOutlined />{" "}
                                  </>
                                )}
                                <Typography.Text strong>
                                  {clientActivityReport.lastLoggedAt
                                    ? dayjs.unix(clientActivityReport.lastLoggedAt).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </Typography.Text>
                              </span>
                            </Space>

                            <Divider style={{ margin: "5px 0" }} />
                            <Space direction="horizontal">
                              <span>Brak logowania w ostatnich 7 dniach i brak wiadomości:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Danger] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>Brak wiadomości w ostatnich 7 dniach, ale klient logował się do aplikacji:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Warning] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>Wiadomość od klienta w ostatnich 7 dniach</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>Wiadomość od klienta w ostatnich 3 dniach</span>
                              <TrophyOutlined style={{ color: flagColor[ClientActivityFlag.VerySafe] }} />
                            </Space>
                          </Space>
                        }
                        title="Status komunikacji"
                        trigger="hover"
                      >
                        {clientActivityReport.communicationStatus === ClientActivityFlag.VerySafe ? (
                          <TrophyOutlined style={{ color: flagColor[clientActivityReport.communicationStatus] }} />
                        ) : (
                          <FlagOutlined style={{ color: flagColor[clientActivityReport.communicationStatus] }} />
                        )}
                      </Popover>
                    ) : null
                  }
                />
              </div>
            </Card>
            <Card>
              <div className="flex flex-col justify-between sm:flex-row">
                <Statistic
                  title={
                    <Space size={4}>
                      <span>Pomiary</span>{" "}
                      <Tooltip title={t("dashboard:clientsActivity.measurementStatus")}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  value={t<string>(`dashboard:clientsActivity.${clientActivityReport?.measurementStatus}`)}
                  valueStyle={{
                    color: flagColor[clientActivityReport?.measurementStatus],
                    fontSize: 22,
                  }}
                  suffix={
                    clientActivityReport ? (
                      <Popover
                        content={
                          <Space direction="vertical">
                            <Space>
                              <span>Ostatni pomiar klienta:</span>
                              <span>
                                {clientActivityReport.lastMeasurementsAt && (
                                  <>
                                    <ClockCircleOutlined />{" "}
                                  </>
                                )}
                                <Typography.Text strong>
                                  {clientActivityReport.lastMeasurementsAt
                                    ? dayjs.unix(clientActivityReport.lastMeasurementsAt).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </Typography.Text>
                              </span>
                            </Space>

                            <Divider style={{ margin: "5px 0" }} />
                            <Space direction="horizontal">
                              <span>Brak pomiarów w ostatnich 21 dniach:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Danger] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>Ostatni pomiar ponad 10 dni temu:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Warning] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>Ostatni pomiar dodany w ostatnich 10 dniach:</span>
                              <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                            </Space>
                            <Space direction="horizontal">
                              <span>Ostatni pomiar dodany w ostatnich 7 dniach oraz dodano zdjęcie:</span>
                              <TrophyOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                            </Space>
                          </Space>
                        }
                        title="Status pomiarów"
                        trigger="hover"
                      >
                        {clientActivityReport.measurementStatus === ClientActivityFlag.VerySafe ? (
                          <TrophyOutlined style={{ color: flagColor[clientActivityReport.measurementStatus] }} />
                        ) : (
                          <FlagOutlined style={{ color: flagColor[clientActivityReport.measurementStatus] }} />
                        )}
                      </Popover>
                    ) : null
                  }
                />
              </div>
            </Card>
          </dl>
        </Skeleton>
      </Col>
    </Row>
  );
};

export default TraineeStats;
