import groupBy from "lodash.groupby";

import { type Exercise } from "@fitness-app/data-models/entities/Exercise";
import { WORKOUT_EXERCISES_TYPE, type DurationFormat } from "@fitness-app/data-models/entities/TrainingProgram";

import i18next from "~/i18nextConfig";

export const exercisesOTypeOptions = Object.values(WORKOUT_EXERCISES_TYPE).map((value) => ({
  value,
  label: i18next.t(`workouts:exercisesType.${value}`),
}));

const groupByWorkoutType = (collection: ReturnType<typeof pickValuesFromObjectWorkout>) =>
  groupBy(collection, (item) => item.type);

export const createExercisesOptions = (
  collection: Exercise[],
): { label: string; options: { label: string; value: string }[] }[] => {
  const grouped = groupByWorkoutType(pickValuesFromObjectWorkout(collection));

  return Object.keys(grouped).map((key) => ({
    label: i18next.t(`workouts:exerciseType.${key}`),
    options: grouped[key] ?? [],
  }));
};

const pickValuesFromObjectWorkout = (collection: Exercise[]) =>
  collection.map((item) => ({
    label: item.name,
    value: item.id,
    type: item.type,
    iconName: item.iconName,
    bodyPart: item.bodyParts?.[0] || "GENERAL_DEVELOPMENT",
  }));

export const getGroupedWorkoutsOptions = (collection: Exercise[]) => {
  const grouped = Object.fromEntries(
    Object.entries(groupByWorkoutType(pickValuesFromObjectWorkout(collection))).map(([key, group]) => [
      key,
      groupBy(group, (item) => item.bodyPart),
    ]),
  );
  return Object.keys(grouped).map((key) => ({
    label: i18next.t(`workouts:exerciseType.${key}`),
    value: key,
    isLeaf: false,
    // @ts-expect-error ignore
    children: Object.keys(grouped[key]).map((bodyPart) => ({
      label: i18next.t(`workouts:bodyPart.${bodyPart}`),
      value: bodyPart,
      isLeaf: false,
      // @ts-expect-error ignore
      children: grouped[key][bodyPart].map((item) => ({
        ...item,
        title: item.label,
        isLeaf: true,
      })),
    })),
  }));
};

export const numberOfSeriesOptions = Array.from({ length: 15 }).map((_, index) => ({
  label: `${index + 1}`,
  value: index + 1,
}));

export const durationFormatOptions: { value: DurationFormat; label: string }[] = [
  {
    value: "minutes",
    label: i18next.t("workouts:duration.minutesAbv"),
  },
  {
    value: "seconds",
    label: i18next.t("workouts:duration.secondsAbv"),
  },
];

export const rirScale = Object.fromEntries(
  [
    { value: -1, label: i18next.t(`workouts:rir.none`) },
    ...Array.from({ length: 11 }).map((_, index) => ({
      value: index,
      label: index,
    })),
  ].map((item) => [item.value, item.label]),
);
