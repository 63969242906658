import React, { useEffect, type FunctionComponent } from "react";
import { Space, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { type TableModel } from "../ImportClientsModal";

interface OwnProps {
  tableModel: TableModel[];
  close: () => void;
  finished: boolean;
}

type Props = OwnProps;

const AddClientsToProduct: FunctionComponent<Props> = ({ tableModel, finished, close }) => {
  const { t } = useTranslation("products");

  useEffect(() => {
    if (finished) {
      setTimeout(() => {
        close();
      }, 3000);
    }
  }, [finished, close]);

  return (
    <div className="flex min-h-[300px] flex-col items-center justify-center">
      <Space direction="vertical" align="center" size={16}>
        <Typography.Title level={4} style={{ padding: "0 20px" }}>
          {finished ? t("import.processingInfo") : t("import.startInfo", { count: tableModel.length })}
        </Typography.Title>
        <Spin />
      </Space>
    </div>
  );
};

export default AddClientsToProduct;
