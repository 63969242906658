import React, { useMemo } from "react";
import { Card, Col, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ProductsList from "~/modules/Products/ProductsList/ProductsList";
import { ProductsTransactions } from "~/modules/Products/ProductsTransactions/ProductsTransactions";
import ProductsSettings from "./ProductsSettings/ProductsSettings";

const Products = (): React.ReactElement => {
  const { t } = useTranslation("products");
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs = useMemo(() => {
    return [
      {
        key: "products",
        label: t("productsList"),
        children: <ProductsList />,
      },
      {
        key: "productsTransactions",
        label: t("productTransactions.title"),
        children: <ProductsTransactions />,
      },
      {
        key: "settings",
        label: t("settings"),
        children: <ProductsSettings />,
      },
    ];
  }, [t]);

  return (
    <Row>
      <Col xs={24}>
        <Card title={t("products")}>
          <Tabs
            type="card"
            hideAdd
            activeKey={searchParams.get("tab") || "products"}
            items={tabs}
            onChange={(activeKey) => setSearchParams((prev) => ({ ...prev, tab: activeKey }))}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Products;
