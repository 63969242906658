import { createAsyncThunk } from "@reduxjs/toolkit";

import { type PriceConfig, type ProductPrice } from "@fitness-app/data-models/entities/Product";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = {
  config: PriceConfig;
  productId: string;
  priceId: string;
};

const defaultPriceConfig: PriceConfig = {
  showAsPromo: false,
  activeUntil: null,
};

export const updateProductPriceConfig = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_REDUCER_NAME}/updateProductPriceConfig`,
  async (payload, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { product } = getState();

      if (product.details?.id !== payload.productId) {
        throw new Error("different-product-id");
      }

      if (!product.details.prices?.length) {
        throw new Error("product-without-prices");
      }

      const updatedPrices = product.details.prices.map((price: ProductPrice) =>
        price.id === payload.priceId ? { ...price, config: { ...defaultPriceConfig, ...payload.config } } : price,
      );

      const { error } = await db.from("product").update({ prices: updatedPrices }).eq("id", payload.productId);

      if (error) {
        return rejectWithValue(error.message);
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
