import { createAsyncThunk } from "@reduxjs/toolkit";

import { type CreatorStats } from "@fitness-app/data-models/entities/Reports";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { getParentTrainerId } from "../../user/selectors";
import { STATISTICS_REDUCER_NAME } from "../types";

export const fetchCreatorStats = createAsyncThunk<CreatorStats | null, void, AsyncThunkCreator<string>>(
  `${STATISTICS_REDUCER_NAME}/fetchCreatorStats`,
  async (_, { rejectWithValue, getState, extra: { db } }) => {
    try {
      const parentId = getParentTrainerId(getState());

      if (!parentId) {
        throw new Error("user-is-not-logged");
      }

      const { data, error } = await db
        .from("creator_stats")
        .select("*")
        .eq("creatorId", parentId)
        .maybeSingle<CreatorStats>();

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
