import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ChatBot } from "@fitness-app/data-models/entities/ChatBot";

import { type AsyncThunkCreator } from "../../../index";
import { BOT_CONFIG_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchChatBots = createAsyncThunk<ChatBot[], Payload, AsyncThunkCreator<string>>(
  `${BOT_CONFIG_REDUCER_NAME}/fetchChatBots`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("chat_bot")
      .select("*")
      .order("createdAt", { ascending: false })

      .returns<ChatBot[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
