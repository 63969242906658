import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ExerciseAggregateStats } from "@fitness-app/data-models/entities/ExerciseStats";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_WORKOUTS_STATS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
};

export const fetchExercisesRecords = createAsyncThunk<ExerciseAggregateStats[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_WORKOUTS_STATS_REDUCER_NAME}/fetchExercisesRecords`,
  async ({ traineeId }, { rejectWithValue, extra: { db, errorTrackingService } }) => {
    const { error, data } = await db
      .from("workout_aggregate_exercise_stats")
      .select("*")
      .order("name")
      .eq("traineeId", traineeId)
      .returns<ExerciseAggregateStats[]>();

    if (error) {
      errorTrackingService?.log(`Cannot fetch exercises records: ${error.message}`);
      return rejectWithValue(error.message);
    }

    return data || [];
  },
);
