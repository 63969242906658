import React, { createContext, useCallback, useContext, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";

import { exercisesSelectors } from "@fitness-app/app-store";
import { type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";
import {
  WORKOUT_EXERCISES_TYPE,
  type ExerciseInProgramFormModel,
  type ExerciseInWorkout,
  type ProgramWorkout,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { addSeriesToExercise } from "@fitness-app/utils/src/programs/addSeriesToExercise";
import formatExerciseInProgram from "@fitness-app/utils/src/programs/formatExerciseInProgram";
import { removeSeriesFromExercise } from "@fitness-app/utils/src/programs/removeSeriesFromExercise";
import changeNumberOfSeriesInSuperSet from "@fitness-app/utils/src/programs/superSetHelpers/changeNumberOfSeriesInSuperSet";
import groupInSuperSet from "@fitness-app/utils/src/programs/superSetHelpers/groupInSuperSet";
import splitSuperSet from "@fitness-app/utils/src/programs/superSetHelpers/splitSuperSet";

import ModalForm from "~/components/ModalForm/ModalForm";
import ExerciseInProgramForm from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/ExerciseInProgramForm";
import { transformExerciseInFormModel } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/transformExerciseInFormModel";
import { useAppSelector } from "~/store/initializeStore";

interface WorkoutActionContextInterface {
  updateWorkoutMetadata: (props: { name?: string; description?: string; isRestDay?: boolean }) => void;
  openExerciseForm: (type: WorkoutExercisesType) => void;
  groupExercisesIntoSuperSet: (exercisesIds: string[], type: WorkoutExercisesType, workoutId: string) => void;
  splitExercisesFromSuperSet: (exercisesIds: string[], type: WorkoutExercisesType, workoutId: string) => void;
  updateExerciseInWorkout: (exercise: ExerciseInWorkout, type: WorkoutExercisesType) => void;
  deleteExerciseFromWorkout: (exerciseId: string | string[], type: WorkoutExercisesType) => void;
  toggleRecordingVideoFlag?: (exerciseId: string, checked: boolean, type: WorkoutExercisesType) => void;
  handleRemoveSeriesFromExercise: (setNumber: number, exerciseId: string, type: WorkoutExercisesType) => void;
  handleAddSeriesToExercise: (exerciseId: string, type: WorkoutExercisesType) => void;
  handleUpdateSuperSetSeries: (numberOfSeries: number, exercisesIds: string[], type: WorkoutExercisesType) => void;
}

export const WorkoutActionProviderContext = createContext<WorkoutActionContextInterface | undefined>(undefined);

export const useWorkoutActionProviderContext = (): WorkoutActionContextInterface => {
  const workoutActionProviderContext = useContext(WorkoutActionProviderContext);

  if (!workoutActionProviderContext) {
    throw new Error("No WorkoutActionContext.Provider found when calling useWorkoutActionProviderContext.");
  }

  return workoutActionProviderContext;
};

interface WorkoutActionProviderProps {
  selectedWorkout: ProgramWorkout | WorkoutTemplate;
  entityId: string;
  children: React.ReactNode;
  updateWorkout: (entityId: string, workout: ProgramWorkout | WorkoutTemplate) => void;
}

const WorkoutActionProvider = ({ children, selectedWorkout, entityId, updateWorkout }: WorkoutActionProviderProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const [showExerciseInWorkoutModal, toggleExerciseInWorkoutModal] = useState(false);
  const [workoutExercisesType, setWorkoutExercisesType] = useState<WorkoutExercisesType | null>(null);
  const [exerciseModel, setExerciseModel] = useState<{
    exercise: ExerciseInWorkout;
    formModel: ExerciseInProgramFormModel;
  } | null>(null);
  const exercisesMap = useAppSelector(exercisesSelectors.getExercisesMap);

  const updateWorkoutMetadata = useCallback(
    (props: { name?: string; description?: string; isRestDay?: boolean }) => {
      const updatedWorkout = {
        ...selectedWorkout,
        name: props.name || selectedWorkout.name,
        description: props.description || selectedWorkout.description,
        isRestDay: props.isRestDay ?? selectedWorkout.isRestDay,
      };

      updateWorkout(entityId, updatedWorkout as ProgramWorkout);
    },
    [updateWorkout, selectedWorkout, entityId],
  );

  const closeExerciseForm = () => {
    toggleExerciseInWorkoutModal(false);
    setWorkoutExercisesType(null);
    setExerciseModel(null);
  };

  const openExerciseForm = useCallback((type: WorkoutExercisesType) => {
    setWorkoutExercisesType(type);
    toggleExerciseInWorkoutModal(true);
  }, []);

  const onSaveExercise = (formData: ExerciseInProgramFormModel, exercise: ExerciseWithVideo) => {
    if (exerciseModel) {
      const exerciseInWorkout = formatExerciseInProgram(formData, exercise, {
        exercisesMap,
        updatingExercise: exerciseModel.exercise,
      });
      const updated = selectedWorkout[formData.exercisesType].map((exercise) =>
        exercise.id === exerciseModel.exercise.id ? exerciseInWorkout : exercise,
      );
      updateWorkout(entityId, {
        ...selectedWorkout,
        [formData.exercisesType]: updated,
        updatedAt: new Date().toISOString(),
      });

      closeExerciseForm();
    } else {
      const exerciseInWorkout = formatExerciseInProgram(formData, exercise, {
        exercisesMap,
      });
      const updated = [...selectedWorkout[formData.exercisesType], exerciseInWorkout];
      updateWorkout(entityId, {
        ...selectedWorkout,
        [formData.exercisesType]: updated,
        updatedAt: new Date().toISOString(),
      });

      closeExerciseForm();
    }

    setExerciseModel(null);
  };

  const handleRemoveSeriesFromExercise = (setNumber: number, exerciseId: string, type: WorkoutExercisesType) => {
    const updated = selectedWorkout[type].map((exercise) =>
      exercise.id !== exerciseId ? exercise : removeSeriesFromExercise(setNumber, exercise),
    );
    updateWorkout(entityId, {
      ...selectedWorkout,
      [type]: updated,
      updatedAt: new Date().toISOString(),
    });
  };

  const handleAddSeriesToExercise = (exerciseId: string, type: WorkoutExercisesType) => {
    const updated = selectedWorkout[type].map((exercise) =>
      exercise.id !== exerciseId ? exercise : addSeriesToExercise(exercise),
    );
    updateWorkout(entityId, {
      ...selectedWorkout,
      [type]: updated,
      updatedAt: new Date().toISOString(),
    });
  };

  const toggleRecordingVideoFlag = (exerciseId: string, checked: boolean, type: WorkoutExercisesType) => {
    const updated = selectedWorkout[type].map((exercise) =>
      exercise.id !== exerciseId ? exercise : { ...exercise, requireExerciseVideoRecording: checked },
    );

    updateWorkout(entityId, {
      ...selectedWorkout,
      [type]: updated,
      updatedAt: new Date().toISOString(),
    });
  };

  const handleUpdateSuperSetSeries = (numberOfSeries: number, exercisesIds: string[], type: WorkoutExercisesType) => {
    const updatedExercises = changeNumberOfSeriesInSuperSet({
      exercises: selectedWorkout[type],
      exercisesToUpdateIds: exercisesIds,
      numberOfSeries,
      fromArray: true,
      toArray: true,
    });

    updateWorkout(entityId, {
      ...selectedWorkout,
      [type]: updatedExercises,
      updatedAt: new Date().toISOString(),
    });
  };

  const deleteExerciseFromWorkout = (exerciseId: string | string[], type: WorkoutExercisesType) => {
    const updated = selectedWorkout[type].filter((exercise) =>
      Array.isArray(exerciseId) ? !exerciseId.includes(exercise.id) : exercise.id !== exerciseId,
    );
    updateWorkout(entityId, {
      ...selectedWorkout,
      [type]: updated,
      updatedAt: new Date().toISOString(),
    });
  };

  const groupExercisesIntoSuperSet = useCallback(
    (exercisesIds: string[], type: WorkoutExercisesType) => {
      const workout = cloneDeep(selectedWorkout);

      const updatedExercises = groupInSuperSet({
        exercisesToGroup: exercisesIds,
        exercises: workout[type] || [],
        fromArray: true,
        toArray: true,
      });

      updateWorkout(entityId, {
        ...selectedWorkout,
        [type]: updatedExercises,
        updatedAt: new Date().toISOString(),
      });
    },
    [updateWorkout, entityId, selectedWorkout],
  );

  const splitExercisesFromSuperSet = useCallback(
    (exercisesIds: string[], type: WorkoutExercisesType) => {
      const workout = cloneDeep(selectedWorkout);

      const updatedExercises = splitSuperSet({
        exercisesToSplit: exercisesIds,
        exercises: workout[type] || [],
        fromArray: true,
        toArray: true,
      });

      updateWorkout(entityId, {
        ...selectedWorkout,
        [type]: updatedExercises,
        updatedAt: new Date().toISOString(),
      });
    },
    [updateWorkout, entityId, selectedWorkout],
  );

  const updateExerciseInWorkout = (
    exercise: ExerciseInWorkout,
    type: WorkoutExercisesType = WORKOUT_EXERCISES_TYPE.NORMAL,
  ) => {
    const model = transformExerciseInFormModel(exercise, type);

    setExerciseModel({ exercise, formModel: model });
    openExerciseForm(type);
  };

  return (
    <WorkoutActionProviderContext.Provider
      value={{
        updateWorkoutMetadata,
        openExerciseForm,
        groupExercisesIntoSuperSet,
        updateExerciseInWorkout,
        splitExercisesFromSuperSet,
        deleteExerciseFromWorkout,
        handleAddSeriesToExercise,
        handleRemoveSeriesFromExercise,
        handleUpdateSuperSetSeries,
        toggleRecordingVideoFlag,
      }}
    >
      {children}
      <ModalForm
        title={exerciseModel?.formModel ? t("workout.editExerciseInPlan") : t("workout.addExerciseToPlan")}
        open={showExerciseInWorkoutModal}
        onCancel={closeExerciseForm}
        width={650}
      >
        <ExerciseInProgramForm
          model={exerciseModel?.formModel}
          onSubmit={onSaveExercise}
          workoutExercisesType={workoutExercisesType}
        />
      </ModalForm>
    </WorkoutActionProviderContext.Provider>
  );
};

export default WorkoutActionProvider;
