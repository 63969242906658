import React, { type ReactElement } from "react";
import { Button, Form, Modal } from "antd";
import { type ModalProps } from "antd/lib/modal";
import { useTranslation } from "react-i18next";

interface OwnProps {
  loading?: boolean;
  children: ReactElement;
  onSave?: () => void;
}

type Props = OwnProps & ModalProps;

function ModalForm<T extends object>({ children, loading, visible, onSave, ...restProps }: Props) {
  const { t } = useTranslation("common");
  const [formController] = Form.useForm<T>();

  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    formController.resetFields();
    restProps.onCancel?.(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
  };

  return (
    <Modal
      destroyOnClose
      afterClose={() => {
        formController.resetFields();
      }}
      width={700}
      maskClosable={false}
      open={visible}
      {...restProps}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          {restProps.cancelText || t<string>("button.cancel")}
        </Button>,
        <Button
          key="submit"
          type={restProps.okButtonProps?.type || "primary"}
          danger={restProps.okButtonProps?.danger ?? false}
          disabled={restProps.okButtonProps?.disabled ?? false}
          loading={loading}
          onClick={onSave || formController.submit}
        >
          {restProps.okText || t<string>("button.save")}
        </Button>,
      ]}
    >
      <div className="py-3">{React.cloneElement(children, { formController })}</div>
    </Modal>
  );
}

export default ModalForm;
