import React, { useEffect, useState, type FunctionComponent } from "react";
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TreeSelect,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import groupBy from "lodash.groupby";
import round from "lodash.round";
import { useTranslation } from "react-i18next";

import { productClientActions } from "@fitness-app/app-store";
import { ProductEventType } from "@fitness-app/data-models/businessEvents/domain/product/ProductEvents";
import {
  AutomationStartPoint,
  DiscordIntegrationTask,
  EmailClientTask,
  FitnessAppTask,
  RefundType,
  TaskType,
  type AutomationTaskWithoutTriggers,
  type DiscordAutomationTask,
  type EmailClientAutomationTask,
  type FieldValuesEmailClient,
  type FieldValuesEmailList,
  type FitnessAppAutomationTask,
  type ProductAutomation,
  type ProductAutomationTask,
  type ProductTask,
} from "@fitness-app/data-models/entities/Automation";
import { type Order } from "@fitness-app/data-models/entities/Order";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { useEmailLists } from "~/hooks/useEmailLists";
import { useFitnessAppAutomationTask } from "~/modules/Products/hooks/useFitnessAppAutomationTask";
import { type AddProgramAutomationToClient } from "~/modules/Products/Product/ProductAutomation/components/AutomationTaskForm/types";
import { useDiscordIntegration } from "~/modules/Products/Product/ProductAutomation/hooks/useDiscordIntegration";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { type ProductClientArchiveFormModel } from "./types";

interface Props {
  formController?: FormInstance<ProductClientArchiveFormModel>;
  onSubmit: (formData: ProductClientArchiveFormModel) => void;
  client: ProductClient;
  productAutomation: ProductAutomation | null;
  lastOrder: Order | null;
}

export const useProductClientArchive = (syncOrdersAfterArchive = true) => {
  const [showArchiveModal, toggleArchiveModel] = useState(false);
  const [processing, toggleProcessing] = useState(false);
  const { t } = useTranslation("common");
  const [selectedClient, setClient] = useState<null | ProductClient>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedClient) {
      toggleArchiveModel(true);
    } else {
      toggleArchiveModel(false);
    }
  }, [selectedClient]);

  const handleSubmit = async (model: ProductClientArchiveFormModel) => {
    if (!selectedClient) {
      return;
    }

    const tasks = model.tasks.map((task) => {
      if (
        task.type === DiscordIntegrationTask.RemoveFromServer ||
        task.type === DiscordIntegrationTask.AddToServer ||
        task.type === DiscordIntegrationTask.AddRole ||
        task.type === DiscordIntegrationTask.RemoveRole
      ) {
        return {
          action: task,
          type: TaskType.DiscordIntegration,
        };
      }

      if (
        task.type === FitnessAppTask.AddClientToApp ||
        task.type === FitnessAppTask.RemoveClientFromApp ||
        task.type === FitnessAppTask.AddProgramAutomationToClient
      ) {
        return {
          action: task,
          type: TaskType.FitnessApp,
        };
      }

      if (task.type === EmailClientTask.AddTags || task.type === EmailClientTask.RemoveTags) {
        return {
          type: TaskType.EmailClient,
          action: {
            ...task,
            emailClients: task.emailClients.map((clientStr) => {
              const [client, account] = clientStr.split(LIST_ITEM_SEPARATOR);
              return {
                client: client as EmailClient,
                account,
              };
            }),
          },
        };
      }

      if (task.type === EmailClientTask.AddToLists || task.type === EmailClientTask.RemoveFromLists) {
        const lists = task.emailLists.map((listStr) => {
          const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          };
        });
        const groupByClient = groupBy(lists, "client");

        if (EmailClient.GetResponse in groupByClient && groupByClient[EmailClient.GetResponse]?.length > 1) {
          void message.warning("getResponseListWarning");
          return;
        }

        return {
          type: TaskType.EmailClient,
          action: {
            ...task,
            emailLists: lists,
          },
        };
      }

      const lists: (FieldValuesEmailList | FieldValuesEmailClient)[] = task.emailLists.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        if (id && name) {
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          } as FieldValuesEmailList;
        }
        return {
          client: client as EmailClient,
          account,
        } as FieldValuesEmailClient;
      });
      const groupByClient = groupBy(lists, "client");

      if (EmailClient.GetResponse in groupByClient && groupByClient[EmailClient.GetResponse]?.length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }
      return {
        type: TaskType.EmailClient,
        action: {
          ...task,
          emailLists: lists,
        },
      };
    }) as (AutomationTaskWithoutTriggers | undefined)[];

    const reformattedModel = {
      ...model,
      tasks: tasks.filter((task): task is AutomationTaskWithoutTriggers => Boolean(task?.type)),
      clientEmail: selectedClient?.email,
      productId: selectedClient?.productId,
    };

    try {
      toggleProcessing(true);
      await dispatch(productClientActions.archiveProductClient(reformattedModel)).unwrap();
      toggleProcessing(false);
      toggleArchiveModel(false);
      if (syncOrdersAfterArchive) {
        void dispatch(
          productClientActions.fetchProductClientOrders({
            productId: selectedClient.productId,
            clientEmail: selectedClient.email,
          }),
        );
      }
    } catch (e) {
      toggleProcessing(false);
      void message.error(t("messages.error.default"));
    }
  };

  return {
    selectedClient,
    showArchiveModal,
    setClient,
    handleSubmit,
    processing,
  };
};

const refundTypeOptions = (
  t: (label: string, interpolations?: Record<string, string | number>) => string,
  lastOrderAmount: number,
  currency: string,
  lastOrder: Order | null,
) => [
  {
    value: RefundType.None,
    label: t(`clientDetails.refund.${RefundType.None}`),
  },
  ...(lastOrder?.paymentIntentId ||
  (lastOrder?.price && "paymentGatewayInvoiceId" in lastOrder.price && lastOrder.price.paymentGatewayInvoiceId)
    ? [
        {
          value: RefundType.AllLastPayment,
          label: t(`clientDetails.refund.${RefundType.AllLastPayment}`, {
            lastOrderAmount,
            currency,
          }),
        },
      ]
    : []),
  {
    value: RefundType.Manual,
    label: t(`clientDetails.refund.${RefundType.Manual}`),
  },
];

export const ProductClientArchive: FunctionComponent<Props> = ({
  formController,
  onSubmit,
  productAutomation,
  client,
  lastOrder,
}) => {
  const { t } = useTranslation(["products", "common"]);
  const { tags, lists, emailClients, listOrAccountOptions, emailAutomationTaskOptions } = useEmailLists();
  const { discordAutomationTaskOptions, discordIntegration } = useDiscordIntegration();
  const [selectedRefund, setRefund] = useState(RefundType.None);
  const productDetails = useAppSelector((store) => store.product.details);
  const [selectedTasksType, selectTasksType] = useState<
    (EmailClientTask | DiscordIntegrationTask | ProductTask | FitnessAppTask)[]
  >([]);

  const selectedProgramAutomation = Form.useWatch(
    ["action", "programAutomation"],
    formController,
  ) as AddProgramAutomationToClient["programAutomation"];
  const { fitnessAppTaskOptions, programAutomationOptions, appAccessOptions } =
    useFitnessAppAutomationTask(productDetails);

  useEffect(() => {
    if (productAutomation?.tasks?.length) {
      const taskAfterProductPurchase = productAutomation.tasks.filter((task) =>
        task.triggers.includes(ProductEventType.ProductPurchased),
      );

      const revertedTask = taskAfterProductPurchase
        .map(
          (
            task: EmailClientAutomationTask | DiscordAutomationTask | ProductAutomationTask | FitnessAppAutomationTask,
          ) => {
            if (task.action.type === EmailClientTask.AddToLists) {
              return {
                ...task.action,
                type: EmailClientTask.RemoveFromLists,
                emailLists: task.action.emailLists.map(
                  (list) =>
                    `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                ),
              };
            }

            if (task.action.type === EmailClientTask.RemoveFromLists) {
              return {
                ...task.action,
                type: EmailClientTask.AddToLists,
                emailLists: task.action.emailLists.map(
                  (list) =>
                    `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                ),
              };
            }

            if (task.action.type === EmailClientTask.AddTags && task.action.tags.length) {
              return {
                ...task.action,
                type: EmailClientTask.RemoveTags,
                emailClients: task.action.emailClients.map(
                  (clientEmail) => `${clientEmail.client}${LIST_ITEM_SEPARATOR}${clientEmail.account}`,
                ),
              };
            }

            if (task.action.type === EmailClientTask.RemoveTags && task.action.tags.length) {
              return {
                ...task.action,
                type: EmailClientTask.AddTags,
                emailClients: task.action.emailClients.map(
                  (clientEmail) => `${clientEmail.client}${LIST_ITEM_SEPARATOR}${clientEmail.account}`,
                ),
              };
            }

            if (task.action.type === DiscordIntegrationTask.AddToServer) {
              return {
                ...task.action,
                type: DiscordIntegrationTask.RemoveFromServer,
              };
            }

            if (task.action.type === FitnessAppTask.AddClientToApp) {
              return {
                ...task.action,
                type: FitnessAppTask.RemoveClientFromApp,
              };
            }
          },
        )
        .filter((task) => Boolean(task));

      if (revertedTask.length) {
        formController?.setFieldsValue({
          tasks: revertedTask as ProductClientArchiveFormModel["tasks"],
        });
        selectTasksType((revertedTask as ProductClientArchiveFormModel["tasks"]).map((task) => task.type));
      }
    }
  }, [productAutomation, formController]);

  const renderTaskAction = (index: number, name: number) => {
    const selectedTaskType = selectedTasksType[index];
    if (selectedTaskType === FitnessAppTask.AddClientToApp) {
      return (
        <>
          <Form.Item
            name={["action", "accessType"]}
            label={t<string>("productAutomation.form.accessType")}
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Select options={appAccessOptions} />
          </Form.Item>
          <Form.Item
            name={["action", "programAutomation"]}
            label={t<string>("productAutomation.form.programAutomation")}
          >
            <Select
              allowClear
              options={programAutomationOptions}
              placeholder={t("productAutomation.form.selectAutomationProgram")}
            />
          </Form.Item>

          {Boolean(selectedProgramAutomation) && (
            <Form.Item
              name={["action", "startAutomationFrom"]}
              label={t<string>("productAutomation.form.startAutomationFrom.label")}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={AutomationStartPoint.AfterPurchase}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.AfterPurchase}`)}
                  </Radio>
                  <Radio value={AutomationStartPoint.StartOfWeek}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.StartOfWeek}`)}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item name={["action", "tags"]} label={t<string>("productAutomation.form.clientTags")}>
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === FitnessAppTask.AddProgramAutomationToClient) {
      return (
        <>
          <Form.Item
            name={["action", "programAutomation"]}
            label={t<string>("productAutomation.form.programAutomation")}
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Select
              allowClear
              options={programAutomationOptions}
              placeholder={t("productAutomation.form.selectAutomationProgram")}
            />
          </Form.Item>

          {Boolean(selectedProgramAutomation) && (
            <Form.Item
              name={["action", "startAutomationFrom"]}
              label={t<string>("productAutomation.form.startAutomationFrom.label")}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={AutomationStartPoint.AfterPurchase}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.AfterPurchase}`)}
                  </Radio>
                  <Radio value={AutomationStartPoint.StartOfWeek}>
                    {t(`productAutomation.form.startAutomationFrom.${AutomationStartPoint.StartOfWeek}`)}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
        </>
      );
    }

    if (
      selectedTaskType === DiscordIntegrationTask.RemoveFromServer ||
      selectedTaskType === DiscordIntegrationTask.AddToServer
    ) {
      return (
        <>
          <Form.Item
            name={[name, "serverId"]}
            label={t<string>("products:form.discordServerId")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              placeholder={t("products:form.selectDiscordAccount")}
              notFoundContent={false}
              optionFilterProp="title"
              optionLabelProp="title"
              allowClear
            >
              {discordIntegration.map((account) => (
                <Select.Option value={account.guildId} title={account.guild.name} key={account.guildId}>
                  {account.guild.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    }
    if (selectedTaskType === EmailClientTask.AddTags) {
      return (
        <>
          <Form.Item
            name={[name, "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={emailClients}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
          <Form.Item
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={[name, "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveTags) {
      return (
        <>
          <Form.Item
            name={[name, "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={emailClients}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
          <Form.Item
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={[name, "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.AddToLists) {
      return (
        <Form.Item
          tooltip={t<string>("products:form.emailListsInfo")}
          name={[name, "emailLists"]}
          label={t<string>("products:form.emailLists")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <TreeSelect
            style={{ maxWidth: 700 }}
            treeData={lists}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
          />
        </Form.Item>
      );
    }

    if (selectedTaskType === EmailClientTask.AddFieldValues) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={[name, "emailLists"]}
            label={t<string>("products:productAutomation.form.emailListsOrAccount")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
          <Form.Item
            label={t("productAutomation.form.fieldValues")}
            tooltip={t("productAutomation.form.fieldValuesTooltip")}
          >
            <Form.List name={[name, "values"]} initialValue={[{ key: "", value: "" }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                      <Space style={{ width: "100%" }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          style={{ flex: 1, width: 220, marginBottom: 5 }}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Input placeholder={t<string>("productAutomation.form.bodyPlaceholder")} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ flex: 1, width: 235, marginBottom: 5 }}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <AutoComplete options={[]} placeholder={t("productAutomation.form.valuePlaceholder")} />
                        </Form.Item>
                        {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : null}
                      </Space>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          key: "",
                          value: "",
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("productAutomation.form.addField")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveFromLists) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={[name, "emailLists"]}
            label={t<string>("products:form.emailLists")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={lists}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return (
    <Form<ProductClientArchiveFormModel>
      name="archive-client-form"
      form={formController}
      onFinish={onSubmit}
      validateTrigger={["onSubmit"]}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        archive: true,
        tasks: [],
        cancelSubscription: client.paymentType === "recurring",
        refundType: RefundType.None,
      }}
    >
      <Form.Item name="archive" label={t("clientDetails.immediatelyRevokeAccess")} valuePropName="checked">
        <Switch defaultChecked disabled />
      </Form.Item>

      {client.paymentType === "recurring" && lastOrder?.price?.amount ? (
        <Form.Item
          name="cancelSubscription"
          label={t("clientDetails.cancelStripeSubscription")}
          valuePropName="checked"
        >
          <Switch defaultChecked />
        </Form.Item>
      ) : null}

      {client.paymentType !== "free" && lastOrder?.price?.amount ? (
        <Form.Item name="refundType" label={t("clientDetails.refund.label")}>
          <Select<RefundType>
            onSelect={(value: RefundType) => setRefund(value || RefundType.None)}
            options={refundTypeOptions(
              t,
              round(lastOrder.price.amount / 100, 2),
              lastOrder.price.currency?.toUpperCase() ?? "PLN",
              lastOrder,
            )}
          />
        </Form.Item>
      ) : null}

      {selectedRefund !== RefundType.None && (
        <Alert
          type="warning"
          showIcon
          message={t("clientDetails.refund.manualInvoiceCorrect")}
          style={{ marginBottom: 10 }}
        />
      )}

      <Form.Item label={t("clientDetails.tasks")} />
      <Form.List name="tasks">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div className="relative mb-5 border-dashed border-gray-200 bg-gray-50 p-6 pb-10" key={field.key}>
                    <Form.Item
                      name={[field.name, "type"]}
                      label={t<string>("productAutomation.form.taskType")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Select<ProductClientArchiveFormModel["tasks"][number]["type"]>
                        options={[
                          ...emailAutomationTaskOptions,
                          ...discordAutomationTaskOptions,
                          ...fitnessAppTaskOptions,
                        ]}
                        onSelect={(type: ProductClientArchiveFormModel["tasks"][number]["type"]) =>
                          selectTasksType((prev) => {
                            const clone = prev.slice();
                            clone[index] = type;
                            return clone;
                          })
                        }
                      />
                    </Form.Item>
                    {renderTaskAction(index, field.name)}
                    <div className="right-4.5 absolute bottom-2.5">
                      <DeleteOutlined
                        style={{ color: "#f64e60", fontSize: 18 }}
                        onClick={() => {
                          selectTasksType((prev) => prev.filter((item, i) => i !== index));
                          remove(field.name);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                style={{
                  flexBasis: "100%",
                  marginTop: 16,
                }}
              >
                <Row style={{ width: "100%" }}>
                  <Col xs={12} offset={8}>
                    <Button
                      type="primary"
                      onClick={() => {
                        add({});
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      {fields.length
                        ? t<string>("clientDetails.addAnotherTask")
                        : t<string>("clientDetails.addFirstTask")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};
