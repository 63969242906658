import React, { useEffect } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

interface LanguageSwitcherProps {
  lang?: "pl" | "en";
}

const LanguageSwitcher = ({ lang }: LanguageSwitcherProps): React.ReactElement => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      void i18n.changeLanguage(lang);
    }
  }, [lang]);

  const onLangChange = async (lang: string) => {
    await i18n.changeLanguage(lang);
    // if (data) {
    //   dispatch(
    //     userActions.updateUserData({
    //       uid: data.uid,
    //       lang: lang as Language,
    //     }),
    //   );
    // }
  };

  return (
    <Select value={lang || i18n.language} onChange={onLangChange} popupMatchSelectWidth={false}>
      <Select.Option value="pl" key="pl">
        <span className="flag-icon flag-icon-pl" />
        🇵🇱 PL
      </Select.Option>
      <Select.Option value="en" key="en">
        <span className="flag-icon flag-icon-gb" />
        🇺🇸 EN
      </Select.Option>
    </Select>
  );
};

export default LanguageSwitcher;
