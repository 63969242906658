import React, { useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions } from "@fitness-app/app-store";
import {
  ConversionTrackingClient,
  type MetaConversionApiIntegration,
} from "@fitness-app/data-models/entities/ProductsSettings";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import GenericIntegrationForm from "~/components/GenericIntegrationForm/GenericIntegrationForm";
import ModalForm from "~/components/ModalForm/ModalForm";
import { useLoading } from "~/hooks/useLoading";
import { useAppDispatch } from "~/store/initializeStore";

interface OwnProps {
  integration: null | MetaConversionApiIntegration[];
}

export interface MetaConversionApiIntegrationModel {
  id: string;
  name: string;
  pixelId: string;
  accessToken: string;
  trackPurchase: boolean;
  trackInitCheckout: boolean;
  trackAddToCart: boolean;
}

type Props = OwnProps;

const ADD_NEW_ITEM = "ADD_NEW_ITEM";

const NotionIntegration: FunctionComponent<Props> = ({ integration }) => {
  const { t } = useTranslation(["settings", "common", "dashboard"]);
  const [loading, startLoading, stopLoading] = useLoading();
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();

  const [selectAccountId, changeSelectedAccount] = useState<undefined | string>(undefined);

  const selectedAccount = useMemo(() => {
    return integration?.find((account) => account.id === selectAccountId);
  }, [selectAccountId, integration]);

  const handleFormSubmit = async (data: MetaConversionApiIntegrationModel) => {
    startLoading();
    const id = selectedAccount?.id || generateUniqId();
    await dispatch(
      productsSettingsActions.integrateWithExternalApi({
        payload: { data: { ...data, id }, client: ConversionTrackingClient.MetaConversionApi },
        onSuccess: () => {
          void message.success(t<string>(selectedAccount ? "dashboard:meta.editSuccess" : "dashboard:meta.success"));
          toggleModal(false);
          handleChange(id);
        },
        onFailure: (errorCode) => {
          if (errorCode === 403) {
            void message.error(t<string>("dashboard:meta.accessError"));
            return;
          }
          void message.error(t<string>("dashboard:meta.error"));
        },
      }),
    );
    stopLoading();
  };

  const handleChange = (value?: string | undefined) => {
    if (value === ADD_NEW_ITEM || !value) {
      changeSelectedAccount(undefined);
      toggleModal(true);
      return;
    }
    changeSelectedAccount(value);
  };

  const handleRemoveIntegration = async () => {
    if (!selectedAccount) {
      return;
    }
    void message.loading(t<string>("messages.loading.removeAccount"));
    await dispatch(
      productsSettingsActions.removeIntegrationWithExternalApi({
        payload: { id: selectedAccount.id, client: ConversionTrackingClient.MetaConversionApi },
        onFailure: () => {
          message.destroy();
          void message.error(t<string>("dashboard:meta.removeError"));
        },
        onSuccess: () => {
          message.destroy();
          void message.success(t<string>("dashboard:meta.removeSuccess"));
        },
      }),
    );
  };

  return (
    <Space direction="vertical" size={24} className="mt-10">
      <Typography.Title level={4}>{t<string>("emailClients.activeCampaign.connectedAccounts")}</Typography.Title>
      <Select<string>
        size="large"
        placeholder={t<string>("selectAccount")}
        style={{ minWidth: 400 }}
        notFoundContent={false}
        onChange={handleChange}
        onSelect={handleChange}
        value={selectedAccount?.id}
        optionFilterProp="title"
        optionLabelProp="title"
      >
        {integration?.map((account) => (
          <Select.Option value={account.id} title={account.name} key={account.id}>
            {account.name}
          </Select.Option>
        ))}
        {
          <Select.Option value={ADD_NEW_ITEM} title={t<string>("addNewAccount")}>
            <Button
              type="link"
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
            >
              <PlusOutlined /> {t<string>("addNewAccount")}
            </Button>
          </Select.Option>
        }
      </Select>
      {selectedAccount && (
        <>
          <Space direction="horizontal" align="end" style={{ width: "100%", justifyContent: "flex-end" }}>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => toggleModal(true)}>
              {t<string>("common:button.edit")}
            </Button>
            <Popconfirm
              onConfirm={handleRemoveIntegration}
              title={t<string>("emailClients.deleteWarning")}
              okText={t<string>("common:button.delete")}
              okButtonProps={{ danger: true }}
            >
              <Button type="dashed" danger icon={<DeleteOutlined />}>
                {t<string>("common:button.delete")}
              </Button>
            </Popconfirm>
          </Space>
        </>
      )}

      <ModalForm
        visible={showModal}
        loading={loading}
        onCancel={() => toggleModal(false)}
        title={selectedAccount ? t<string>("editAccount") : t<string>("addNewAccount")}
      >
        <GenericIntegrationForm<MetaConversionApiIntegrationModel>
          fields={{
            id: null,
            name: {
              label: t<string>("conversionTracking.meta.name"),
              tooltip: t<string>("conversionTracking.meta.nameTooltip"),
              default: "Meta Conversion API",
              required: true,
              type: "text",
            },
            trackPurchase: {
              label: t<string>("conversionTracking.meta.trackPurchase"),
              required: true,
              type: "boolean",
              default: true,
            },
            trackInitCheckout: {
              label: t<string>("conversionTracking.meta.trackInitiateCheckout"),
              required: true,
              type: "boolean",
              default: true,
            },
            trackAddToCart: {
              label: t<string>("conversionTracking.meta.trackAddToCart"),
              required: true,
              type: "boolean",
              default: true,
            },
            pixelId: {
              label: t<string>("conversionTracking.meta.pixelId"),
              required: true,
              autofocus: true,
              type: "text",
            },
            accessToken: {
              label: t<string>("conversionTracking.meta.accessToken"),
              sensitive: true,
              required: !selectedAccount,
              type: "text",
            },
          }}
          onSubmit={handleFormSubmit}
          model={selectedAccount ? { ...selectedAccount, accessToken: "" } : undefined}
        />
      </ModalForm>
    </Space>
  );
};

export default NotionIntegration;
