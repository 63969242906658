import React from "react";

import { cn } from "@fitness-app/utils/src/styles/cn";

interface CalendarContentProps {
  buttonText: string;
  handleOnClick: () => void;
  children: React.ReactNode;
  className?: string;
  buttonClassName?: string;
}

const CalendarContent = ({ handleOnClick, buttonText, children, className, buttonClassName }: CalendarContentProps) => {
  return (
    <div className={cn("flex justify-between", className)}>
      <div>{children}</div>
      <div className={buttonClassName}>
        <a role="button" onClick={handleOnClick}>
          {buttonText}
        </a>
      </div>
    </div>
  );
};

export default CalendarContent;
