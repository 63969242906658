import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchExercisesRecords } from "./actions/fetchExercisesRecords";
import { fetchExerciseStats } from "./actions/fetchExerciseStats";
import { TRAINEE_WORKOUTS_STATS_REDUCER_NAME, type TraineeWorkoutsStatsReducer } from "./types";

const initialState: TraineeWorkoutsStatsReducer = {
  exercisesRecordsList: [],
  exercisesRecordsListStatus: null,

  exerciseStatsList: [],
  exerciseStatsListStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_WORKOUTS_STATS_REDUCER_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExercisesRecords.pending, (state) => {
      state.exercisesRecordsListStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchExercisesRecords.fulfilled, (state, { payload }) => {
      state.exercisesRecordsListStatus = RequestStatus.SUCCESS;
      state.exercisesRecordsList = payload;
    });
    builder.addCase(fetchExercisesRecords.rejected, (state) => {
      state.exercisesRecordsListStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchExerciseStats.pending, (state) => {
      state.exerciseStatsListStatus = RequestStatus.FETCHING;
      state.exerciseStatsList = [];
    });
    builder.addCase(fetchExerciseStats.fulfilled, (state, { payload }) => {
      state.exerciseStatsListStatus = RequestStatus.SUCCESS;
      state.exerciseStatsList = payload;
    });
    builder.addCase(fetchExerciseStats.rejected, (state) => {
      state.exerciseStatsListStatus = RequestStatus.FAILED;
    });
  },
});

export default reducerSlice.reducer;
