import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENTS_REDUCER_NAME } from "../types";

export const fetchProductClientsNextPage = createAsyncThunk<
  { isLastPage: boolean; list: ProductClient[]; page: number },
  { productId: string; requestedPage: number },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENTS_REDUCER_NAME}/fetchProductClientsNextPage`,
  async ({ productId, requestedPage }, { rejectWithValue, extra: { db }, getState }) => {
    const { listSize, page } = getState().productClients;

    const { error, data } = await db
      .from("product_client")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("productId", productId)
      .range(page * listSize, listSize * (requestedPage + 1) - 1)
      .limit(listSize)
      .returns<ProductClient[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return {
      isLastPage: data.length < listSize,
      list: data,
      page: requestedPage,
    };
  },
);
