import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type ExerciseStats } from "@fitness-app/data-models/entities/ExerciseStats";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_WORKOUTS_STATS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  dateStart: Date;
  dateEnd: Date;
  exerciseId: string;
};

export const fetchExerciseStats = createAsyncThunk<ExerciseStats[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_WORKOUTS_STATS_REDUCER_NAME}/fetchExerciseStats`,
  async ({ traineeId, dateStart, dateEnd, exerciseId }, { rejectWithValue, extra: { db } }) => {
    const startDate = dayjs(dateStart);
    const endDate = dayjs(dateEnd);

    const { error, data } = await db
      .from("workout_exercise_stats")
      .select("*")
      .order("eventDate")
      .eq("traineeId", traineeId)
      .eq("exerciseId", exerciseId)
      .gte("eventDate", `${startDate.format("YYYY-MM-DD")}`)
      .lte("eventDate", `${endDate.format("YYYY-MM-DD")}`)
      .returns<ExerciseStats[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data || [];
  },
);
