import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ProductsSettings } from "@fitness-app/data-models/entities/ProductsSettings";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchProductsSettings } from "./actions/fetchProductsSettings";
import { PRODUCTS_SETTINGS_REDUCER_NAME, type ProductsSettingsReducer } from "./types";

const initialState: ProductsSettingsReducer = {
  error: null,
  status: null,
  data: null,
  showProductList: false,
};

const productsSettingsSlice = createSlice({
  name: PRODUCTS_SETTINGS_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToIntegrationStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToIntegrationSuccess(state) {
      state.status = RequestStatus.SUBSCRIBED;
    },
    subscribeToIntegrationFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromIntegration(state) {
      state.status = null;
      state.error = null;
      state.data = null;
    },
    updateProductsSettings(state, { payload }: PayloadAction<ProductsSettings>) {
      if (state.data) {
        state.data = {
          ...state.data,
          ...payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsSettings.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.status = RequestStatus.SUBSCRIBED;
    });
  },
});

export const {
  subscribeToIntegrationFailed,
  subscribeToIntegrationStarted,
  subscribeToIntegrationSuccess,
  unsubscribeFromIntegration,
  updateProductsSettings,
} = productsSettingsSlice.actions;

export default productsSettingsSlice.reducer;
