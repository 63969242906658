import { type AppThunk } from "../../../index";
import { toggleChannelView as action } from "../reducer";
import { markChatMessagesAsRead } from "./markChatMessagesAsRead";

export const toggleChannelView =
  (channelId: string): AppThunk =>
  (dispatch, getState) => {
    const { activeChannels, messages } = getState().chat;

    const foundedChannel = activeChannels.find((channel) => channel.id === channelId);

    if (!foundedChannel) {
      return;
    }

    dispatch(action({ ...foundedChannel, minimized: !foundedChannel.minimized }));

    if (foundedChannel.minimized) {
      void dispatch(
        markChatMessagesAsRead({
          channelId,
          messages: messages[channelId] || [],
        }),
      );
    }
  };
