import React from "react";
import { Alert, Col, Grid, Image, Rate, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { MeasurementType, type BodyMeasurement, type Measurement } from "@fitness-app/data-models/entities/Measurement";

interface MeasurementContentProps {
  measurement: Measurement;
}

const ItemContent = ({ event }: { event: Measurement }) => {
  const { t } = useTranslation("trainees");
  const { md } = Grid.useBreakpoint();

  if (event.type === MeasurementType.WeightMeasurement) {
    return <Alert message={`Nowy pomiar: ${event.data.weight} kg`} />;
  }

  if (event.type === MeasurementType.BodyMeasurements) {
    const alertLabel = `Klient zaaktualizował pomiary swojego ciała`;

    const translatedData = Object.keys(event.data)
      .map((key) => {
        const bodyKey = key as BodyMeasurement;
        if (!event.data[bodyKey]) {
          return null;
        }
        return `${t(`${bodyKey}`)} - ${event.data[bodyKey]}${bodyKey === "bf" ? "%" : " cm"}`;
      })
      .filter(Boolean);

    return (
      <Alert
        message={alertLabel}
        description={
          <div className="flex flex-col gap-y-1" style={{ maxWidth: "400px" }}>
            {translatedData.map((item) => (
              <span key={item}>{item}</span>
            ))}
          </div>
        }
      />
    );
  }

  if (event.type === MeasurementType.FeedbackAboutTraining) {
    return (
      <Alert
        message={
          <Row justify="center" align="middle">
            <Col span={8}>Ocena treningu:</Col>
            <Col span={16}>
              <div className="flex items-center gap-x-2">
                <Rate disabled defaultValue={event.data.rate ?? 0} />
                <span>{t(`workouts:clientRatingOptionsNumber.${event.data.rate}`)}</span>
              </div>
            </Col>
            <Col span={8}>Komentarz:</Col>
            <Col span={16}>
              <span>{event.data.comment || "-"}</span>
            </Col>
          </Row>
        }
      />
    );
  }

  if (event.type === MeasurementType.HydrationMeasurement) {
    return <Alert message={`Nowa porcja: ${event.data.portion} ml`} />;
  }

  if (event.type === MeasurementType.ClientPhoto) {
    return (
      <Image.PreviewGroup>
        <Space size={12} direction={md ? "horizontal" : "vertical"} style={{ width: "100%", flexWrap: "wrap" }}>
          {event.data.content.map((photo) => (
            <div key={photo.photoId} className="flex flex-col gap-y-2">
              <div>
                <Image
                  className="rounded-md"
                  height={60}
                  src={photo.thumbnailUrl || photo.url}
                  preview={{ src: photo.url }}
                />
              </div>
              <Typography.Text className="text-center" type="secondary">
                {t(`photos.type.${photo.type}`)}
              </Typography.Text>
            </div>
          ))}
        </Space>
      </Image.PreviewGroup>
    );
  }

  return null;
};

const MeasurementContent = ({ measurement }: MeasurementContentProps) => {
  return (
    <div className="max-w-[400px]">
      <ItemContent event={measurement} />
    </div>
  );
};

export default MeasurementContent;
