import { type AppThunk } from "../../../index";
import { unsubscribeFromTeam as action } from "../reducer";

export const unsubscribeFromTeam =
  (): AppThunk =>
  async (dispatch, _, { subscriptions, db }) => {
    if (subscriptions.realtimeTeamListChannel) {
      await db.removeChannel(subscriptions.realtimeTeamListChannel);
      subscriptions.teamListChannelName = null;
    }
    dispatch(action());
  };
