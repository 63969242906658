import React, { useEffect, useMemo, type FunctionComponent } from "react";
import { CheckOutlined, MoreOutlined, TeamOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Dropdown, Space, Tabs, type MenuProps, type TabsProps } from "antd";
import { useTranslation } from "react-i18next";

import { chatActions } from "@fitness-app/app-store";
import { ChatStatus } from "@fitness-app/data-models/entities/Chat";

import ChatChannelsList from "~/modules/Chat/ChatChannelsList";
import { SendMessageToMultipleUsersModal } from "~/modules/Chat/SendMessageToMultipleUsers/SendMessageToMultipleUsersModal";
import UnreadChatsList from "~/modules/Chat/UnreadChatsList";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

type Props = OwnProps;

const ChatDrawer: FunctionComponent<Props> = ({ toggleDrawer, isOpen }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const { channelsListStatus, channels, archivedChannels, archivedListStatus, unreadMessages } = useAppSelector(
    (store) => store.chat,
  );
  const totalUnread = useAppSelector((store) => store.chat.unreadMessages?.total);

  const [activeTab, setActiveTab] = React.useState<ChatStatus | "unread">("unread");
  const [showMultipleUsersModal, setShowMultipleUsersModal] = React.useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(chatActions.subscribeToUnreadMessages());
    }
  }, [isOpen]);

  const items: TabsProps["items"] = [
    {
      key: "unread",
      label: (
        <Space>
          Nieodczytane
          <Badge count={totalUnread} overflowCount={99} />
        </Space>
      ),
      children: activeTab === "unread" ? <UnreadChatsList changeTab={setActiveTab} /> : null,
    },
    {
      key: ChatStatus.Active,
      label: (
        <Space>
          {t("chatDrawer.channel.active")}
          <Badge count={0} overflowCount={99} />
        </Space>
      ),
      children:
        activeTab === ChatStatus.Active ? (
          <ChatChannelsList
            changeTab={setActiveTab}
            channels={channels}
            status={channelsListStatus}
            chatStatus={ChatStatus.Active}
          />
        ) : null,
    },
    {
      key: ChatStatus.Archived,
      label: <span>{t("chatDrawer.channel.archived")}</span>,
      children:
        activeTab === ChatStatus.Archived ? (
          <ChatChannelsList
            changeTab={setActiveTab}
            channels={archivedChannels}
            status={archivedListStatus}
            chatStatus={ChatStatus.Archived}
          />
        ) : null,
    },
  ];

  const dropdown: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: t("chatDrawer.sendToMultiple"),
        icon: <TeamOutlined />,
        onClick: () => setShowMultipleUsersModal(true),
      },
      {
        key: "2",
        label: t("notificationsDrawer.markAllAsRead"),
        icon: <CheckOutlined />,
        disabled: !unreadMessages || (unreadMessages.total === 0 && unreadMessages.unreadMessages?.length === 0),
        onClick: () => {
          void dispatch(chatActions.markAllAsRead());
        },
      },
    ],
    [t, unreadMessages, dispatch],
  );

  return (
    <Drawer
      title={t("chatDrawer.title")}
      extra={
        <Dropdown menu={{ items: dropdown }}>
          <Button type="text">
            <Space>
              <MoreOutlined className="rotate-90 text-xl" />
            </Space>
          </Button>
        </Dropdown>
      }
      placement="right"
      onClose={() => toggleDrawer()}
      open={isOpen}
      mask={false}
      maskClosable={false}
      width={380}
      bodyStyle={{ padding: 0, paddingTop: 0, height: "auto" }}
      className="drawer max-w-full"
    >
      <Tabs
        tabBarStyle={{ padding: "0 16px", backgroundColor: "white" }}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as ChatStatus)}
        items={items}
      />
      <SendMessageToMultipleUsersModal onClose={() => setShowMultipleUsersModal(false)} open={showMultipleUsersModal} />
    </Drawer>
  );
};

export default ChatDrawer;
