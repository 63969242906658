import React, { useState } from "react";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import ReactPlayer from "react-player";

interface VideoPlayerProps {
  videoUrl: string | null;
  onClose?: () => void;
  visible: boolean;
  hideControl?: boolean;
}

const VideoPlayer = ({ videoUrl, onClose, visible, hideControl }: VideoPlayerProps): React.ReactElement => {
  const [state, toggleState] = useState<"idle" | "pause" | "playing">("idle");

  if (videoUrl?.endsWith(".gif")) {
    return (
      <Modal
        title="Podgląd video"
        visible={visible}
        destroyOnClose
        width={750}
        footer={null}
        onCancel={() => {
          onClose?.();
        }}
      >
        <div className="flex w-full justify-center">
          <img src={videoUrl} className="h-full w-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title="Podgląd video"
      open={visible}
      destroyOnClose
      width={750}
      footer={null}
      onCancel={() => {
        onClose?.();
      }}
    >
      <div className="flex w-full justify-center">
        {videoUrl ? (
          <div>
            <ReactPlayer
              autoPlay
              style={{ maxWidth: "90vw" }}
              url={videoUrl}
              playing={state === "playing"}
              config={{
                vimeo: { playerOptions: { controls: true } },
                youtube: {
                  playerVars: { controls: 1 },
                },
                file: {
                  attributes: { controls: true },
                },
              }}
              onPlay={() => toggleState("playing")}
              onPause={() => toggleState("pause")}
            />
            {!hideControl && (
              <div className="mt-4 flex justify-center">
                <Button
                  icon={state === "playing" ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleState(state === "playing" ? "pause" : "playing");
                  }}
                >
                  {state === "playing" ? "pause" : "play"}
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default VideoPlayer;
