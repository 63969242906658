import React, { useEffect } from "react";
import { Card, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { traineeProgramActions } from "@fitness-app/app-store";

import TraineeProgramBuilder from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramBuilder";
import TraineeProgramInfo from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramInfo";
import TraineeProgramSchedule from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramSchedule";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const TraineeTrainingProgram = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["trainees"]);
  const traineeProfile = useAppSelector((store) => store.trainee.profile);

  const traineeId = traineeProfile?.id;
  const currentProgramId = traineeProfile?.activeTrainingProgramId;

  useEffect(() => {
    if (traineeId) {
      void dispatch(traineeProgramActions.fetchScheduledPrograms({ traineeId }));
    }
  }, [dispatch, traineeId, currentProgramId]);

  return (
    <Card bordered={false} className="!shadow-none">
      <Tabs tabPosition="left" defaultActiveKey="programInfo">
        <Tabs.TabPane tab={t("program.programInfoTitle")} key="programInfo">
          <TraineeProgramInfo />
        </Tabs.TabPane>
        {traineeProfile?.activeTrainingProgramId ? (
          <>
            <Tabs.TabPane tab={t("program.trainingDays")} key="workouts">
              <TraineeProgramBuilder assignedProgramId={traineeProfile.activeTrainingProgramId} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("program.scheduleTitle")} key="schedule" forceRender>
              <TraineeProgramSchedule />
            </Tabs.TabPane>
          </>
        ) : null}
      </Tabs>
    </Card>
  );
};

export default TraineeTrainingProgram;
