import React, { useState, type FunctionComponent } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Row, Segmented, Space, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus } from "@fitness-app/app-store";
import { InvoicesClient } from "@fitness-app/data-models/entities/Product";

import { invoicesOptions } from "~/constants/invoicesOptions";
import InFaktIntegration from "~/modules/Products/ProductsSettings/Invoices/clients/inFakt/InFaktIntegration";
import { useAppSelector } from "~/store/initializeStore";
import FakturowniaIntegration from "./clients/Fakturownia/FakturowniaIntegration";
import IFirmaIntegration from "./clients/iFirma/IFirmaIntegration";
import WFirmaIntegration from "./clients/wFirma/WFirmaIntegration";

const InvoicesClientsPanel: FunctionComponent = () => {
  const { t } = useTranslation(["settings", "common"]);

  const { data, status } = useAppSelector((state) => state.productsSettings);
  const [selectedClient, setClient] = useState<InvoicesClient>(InvoicesClient.Fakturownia);

  const renderActiveIntegration = () => {
    if (selectedClient === InvoicesClient.Fakturownia) {
      return <FakturowniaIntegration integration={data?.invoices?.[InvoicesClient.Fakturownia] || null} />;
    }

    if (selectedClient === InvoicesClient.iFirma) {
      return <IFirmaIntegration integration={data?.invoices?.[InvoicesClient.iFirma] || null} />;
    }

    if (selectedClient === InvoicesClient.inFakt) {
      return <InFaktIntegration integration={data?.invoices?.[InvoicesClient.inFakt] || null} />;
    }

    if (selectedClient === InvoicesClient.wFirma) {
      return <WFirmaIntegration integration={data?.invoices?.[InvoicesClient.wFirma] || null} />;
    }

    return null;
  };

  return (
    <div className="flex flex-col items-center pt-8">
      <Space direction="vertical" size={24}>
        <Row gutter={24} justify="center">
          <Spin spinning={status === RequestStatus.SUBSCRIBING}>
            <Segmented
              size="large"
              options={invoicesOptions.map((client) => ({
                value: client.value,
                disabled: client.disabled,
                label: (
                  <div>
                    {t(`common:invoices.${client.value}`)}{" "}
                    {data?.invoices && client.value in data?.invoices && data.invoices[client.value]?.length ? (
                      <CheckCircleOutlined style={{ color: "#1bc5bd" }} />
                    ) : null}
                  </div>
                ),
              }))}
              value={selectedClient}
              onChange={(value) => setClient(value as InvoicesClient)}
            />
          </Spin>
        </Row>
      </Space>

      {renderActiveIntegration()}
    </div>
  );
};

export default InvoicesClientsPanel;
