import React, { useEffect, type FunctionComponent } from "react";
import { CheckOutlined, CloseOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tag, Typography, type TableProps } from "antd";
import isEqual from "lodash.isequal";
import truncate from "lodash.truncate";
import uniq from "lodash.uniq";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RequestStatus, traineesActions } from "@fitness-app/app-store";
import { getTrainersTeam } from "@fitness-app/app-store/src/store/reducers/team/selectors";
import { type AppAccessType } from "@fitness-app/data-models/entities/Automation";
import {
  type TraineeStatus,
  type TraineeWithAssignedTrainerAndProduct,
} from "@fitness-app/data-models/entities/Trainee";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useTagOptions } from "~/hooks/useTagOptions";
import TraineeStatusBadge from "~/modules/Trainee/components/TraineeStatusBadge/TraineeStatusBadge";
import {
  AppAccessTypeBadge,
  createAppAccessTypeOptions,
  createStatusOptions,
} from "~/modules/Trainee/helpers/createStatusOptions";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { createTrainerOptions } from "./helpers/createTrainerOptions";

interface OwnProps {
  isSearchMode: boolean;
}

type Props = OwnProps;

const TraineesTable: FunctionComponent<Props> = ({ isSearchMode }) => {
  const { t } = useTranslation("trainees");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isRegularTrainer, isOwner, memberId } = useUserClaims();
  const { filters: traineeTags } = useTagOptions("traineeTags");

  const {
    listStatus,
    list,
    page,
    listSize,
    totalPages,
    filters: { currentAssignedTrainerFilter, currentStatusFilter, currentAccessType, currentFilteredTags },
  } = useAppSelector((store) => store.trainees);
  const trainers = useAppSelector(getTrainersTeam);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get("page");

    void dispatch(
      traineesActions.fetchTrainees(
        memberId
          ? { assignedTrainerId: memberId, page: page ? Number(page) : undefined }
          : { page: page ? Number(page) : undefined },
      ),
    );
  }, [dispatch, memberId]);

  const handleChange: TableProps<TraineeWithAssignedTrainerAndProduct>["onChange"] = (pagination, filters) => {
    if (isRegularTrainer) {
      if (!isEqual(currentStatusFilter, filters.status)) {
        const newStatusFilter = filters.status as TraineeStatus[] | null;

        void dispatch(
          traineesActions.fetchTrainees({
            statuses: newStatusFilter?.length ? newStatusFilter : null,
            assignedTrainerId: memberId,
            page: 1,
          }),
        );
      } else if (!isEqual(currentFilteredTags, filters.tags)) {
        void dispatch(
          traineesActions.fetchTrainees({
            assignedTrainerId: memberId,
            tags: filters.tags as string[],
            page: 1,
          }),
        );
      } else {
        if (pagination.current && pagination.current !== page) {
          setSearchParams({ page: String(pagination.current || 1) });
          void dispatch(
            traineesActions.fetchTrainees(
              memberId ? { assignedTrainerId: memberId, page: pagination.current } : { page: pagination.current },
            ),
          );
        }
      }
    } else {
      if (!isEqual(currentAssignedTrainerFilter ? [currentAssignedTrainerFilter] : null, filters.assignedTrainer)) {
        const newTrainerFilter = filters.assignedTrainer?.[0] || null;
        void dispatch(
          traineesActions.fetchTrainees({
            statuses: currentStatusFilter,
            assignedTrainerId: (newTrainerFilter as string) || null,
            page: 1,
          }),
        );
      } else if (!isEqual(currentStatusFilter, filters.status)) {
        const newStatusFilter = filters.status as TraineeStatus[] | null;

        void dispatch(
          traineesActions.fetchTrainees({
            statuses: newStatusFilter?.length ? newStatusFilter : null,
            assignedTrainerId: currentAssignedTrainerFilter || null,
            page: 1,
          }),
        );
      } else if (!isEqual(currentFilteredTags, filters.tags)) {
        void dispatch(
          traineesActions.fetchTrainees({
            tags: filters.tags as string[],
            assignedTrainerId: currentAssignedTrainerFilter || null,
            page: 1,
          }),
        );
      } else if (!isEqual(currentAccessType, filters.type)) {
        const newAccessType = filters.type as AppAccessType[] | null;

        void dispatch(
          traineesActions.fetchTrainees({
            accessType: newAccessType?.length ? newAccessType : null,
            assignedTrainerId: currentAssignedTrainerFilter || null,
            page: 1,
          }),
        );
      } else {
        if (pagination.current && pagination.current !== page) {
          setSearchParams({ page: String(pagination.current || 1) });
          void dispatch(
            traineesActions.fetchTrainees(
              memberId ? { assignedTrainerId: memberId, page: pagination.current } : { page: pagination.current },
            ),
          );
        }
      }
    }
  };

  return (
    <>
      <Table<TraineeWithAssignedTrainerAndProduct>
        dataSource={list}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        locale={{
          emptyText:
            isSearchMode || currentAssignedTrainerFilter !== undefined || currentStatusFilter?.length
              ? t("emptyStateForFilter")
              : t("emptyState"),
        }}
        loading={listStatus === RequestStatus.FETCHING || listStatus === RequestStatus.UPDATING}
        pagination={{
          current: page,
          pageSize: listSize,
          total: totalPages * listSize,
          pageSizeOptions: ["50"],
          showSizeChanger: false,
        }}
        onChange={handleChange}
      >
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.index")}
          width={50}
          dataIndex="index"
          key="index"
          render={(_name, _row, i) => (page > 1 ? (page - 1) * listSize + i + 1 : i + 1)}
        />
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.name")}
          dataIndex="name"
          key="name"
          render={(_, row) => (
            <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.id}`)}>
              {row.firstName} {row.lastName}
            </Button>
          )}
        />
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.email")}
          dataIndex="email"
          ellipsis
          render={(email) => (
            <Typography.Link href={`mailto:${email}`} target="_blank" copyable>
              {email}
            </Typography.Link>
          )}
        />
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.status")}
          dataIndex="status"
          key="status"
          render={(status: TraineeStatus) => <TraineeStatusBadge status={status} />}
          filteredValue={currentStatusFilter}
          filters={createStatusOptions()}
        />
        {!isRegularTrainer && (
          <Table.Column<TraineeWithAssignedTrainerAndProduct>
            title={t("profileForm.accessType")}
            dataIndex="type"
            key="type"
            render={(type: AppAccessType) => <AppAccessTypeBadge type={type} />}
            filteredValue={currentAccessType}
            filters={createAppAccessTypeOptions()}
          />
        )}
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.workouts")}
          dataIndex="activeTrainingProgramId"
          key="activeTrainingProgramId"
          render={(activeTrainingProgramId) =>
            activeTrainingProgramId ? (
              <CheckOutlined style={{ color: "#25b10f" }} />
            ) : (
              <CloseOutlined type="close" style={{ color: "#ED1C24" }} />
            )
          }
        />
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.diet")}
          dataIndex="activeMealsPlanId"
          key="activeMealsPlanId"
          render={(activeMealsPlanId) =>
            activeMealsPlanId ? (
              <CheckOutlined style={{ color: "#25b10f" }} />
            ) : (
              <CloseOutlined style={{ color: "#ED1C24" }} />
            )
          }
        />
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.trainer")}
          dataIndex="assignedTrainer"
          key="assignedTrainer"
          render={(trainer: TraineeWithAssignedTrainerAndProduct["assignedTrainer"]) =>
            trainer ? <Tag color="magenta">{getAuthorName(trainer)}</Tag> : "-"
          }
          {...(isRegularTrainer
            ? {}
            : {
                filterMultiple: false,
                filterSearch: true,
                filteredValue: currentAssignedTrainerFilter ? [currentAssignedTrainerFilter] : null,
                filters: createTrainerOptions(trainers, t, false),
              })}
        />
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.tags")}
          dataIndex="tags"
          key="tags"
          filterMultiple
          filteredValue={currentFilteredTags}
          filters={traineeTags}
          render={(tags: string[]) =>
            tags?.length ? (
              <Space direction="vertical">
                {uniq(tags).map((tag, i) => (
                  <Tag color="blue" key={`${tag}-${i}`}>
                    {tag}
                  </Tag>
                ))}
              </Space>
            ) : (
              "-"
            )
          }
        />
        {isOwner && (
          <Table.Column<TraineeWithAssignedTrainerAndProduct>
            title={t("tableHeader.connectedProduct")}
            dataIndex="productId"
            key="productId"
            render={(_productId, row) =>
              row.productClient ? (
                <Button
                  type="link"
                  className="px-0"
                  onClick={() => navigate(`/products/${row.productClient?.productId}/clients/${row.email}`)}
                >
                  {truncate(row.productClient?.product.name || "-", {
                    length: 20,
                  })}
                </Button>
              ) : (
                "-"
              )
            }
          />
        )}
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.options")}
          dataIndex="action"
          key="action"
          render={(_, row) => (
            <Space size={4}>
              <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.id}`)}>
                {t(`common:details`)}
              </Button>
              <Button
                icon={<ExportOutlined />}
                title="Otwórz w nowy oknie"
                href={`${window.location.origin}/trainee/details/${row.id}`}
                target="_blank"
                type="link"
              />
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default TraineesTable;
