import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import cloneDeep from "lodash.clonedeep";

import { ProgramService, type WorkoutEventType } from "@fitness-app/data-models/domain/services/ProgramService";
import { weekdays } from "@fitness-app/data-models/domain/services/WorkoutService";
import { WorkoutSource } from "@fitness-app/data-models/entities/ClientWorkout";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { setProgramSchedule } from "../../traineeProgram/actions";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";
import { updateWorkoutActivity } from "./updateWorkoutActivity";

type Payload = {
  newDate: Date;
  event: WorkoutEventType;
};

export const dragEventToSpecifiedDay = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/dragEventToSpecifiedDay`,
  async ({ event, newDate }, { dispatch, getState, extra: { auth, analytics } }) => {
    const { selectedProgram } = getState().traineeProgram;
    if (!selectedProgram) {
      throw new Error("Client doesn't have active program");
    }

    const programSchedule = selectedProgram.workoutsSchedule?.schedule;

    const mProgramStartDate = dayjs(selectedProgram.startDate, "YYYY-MM-DD").startOf("day");
    const mProgramEndDate = dayjs(selectedProgram.endDate, "YYYY-MM-DD").endOf("day");
    const newEventDate = dayjs(newDate);
    const oldEventDate = dayjs(event.start);

    if (newEventDate.isBefore(mProgramStartDate)) {
      throw new Error("Trening nie może być zaplanowany wcześniej niż początek obecnego planu treningowego");
    }

    if (newEventDate.isAfter(mProgramEndDate)) {
      throw new Error("Trening nie może być zaplanowany później niż koniec obecnego planu treningowego");
    }

    const user = await getLoggedUser(auth);

    const isoWeek = newEventDate.isoWeek();
    const oldEventWeek = oldEventDate.isoWeek();
    const oldEventYear = oldEventDate.get("year");
    const year = newEventDate.get("year");

    const formattedEventDate = newEventDate.format("YYYY-MM-DD");
    const formattedEventMonth = newEventDate.format("YYYY-MM");

    const day = weekdays[newEventDate.isoWeekday()];
    const previousDay = weekdays[oldEventDate.isoWeekday()];

    const currentWeek = `${year}-${isoWeek}`;
    const previousWeek = `${oldEventYear}-${oldEventWeek}`;

    if (event.data.replicatedFromSchedule === false) {
      const updatedWorkout = {
        id: event.id,
        eventDate: formattedEventDate,
        eventMonth: formattedEventMonth,
        eventWeek: currentWeek,
        eventTimestamp: newEventDate.hour(12).toISOString(),
      };
      void dispatch(updateWorkoutActivity({ activity: updatedWorkout, id: event.metadata.id || event.id }));

      return;
    }

    const { week } = ProgramService.getScheduleForWeek(selectedProgram, newEventDate);

    if (!user) {
      throw new Error("user-is-not-logged");
    }

    if (!week) {
      throw new Error("Schedule doesnt exists");
    }

    if (currentWeek === previousWeek && day && previousDay) {
      const updatedWeek = cloneDeep(week);
      if (updatedWeek?.[day]?.workoutDay) {
        throw new Error("Nie można mieć dwóch zaplanowanych treningow w tym samym dniu");
      }
      if (updatedWeek?.[previousDay]) {
        delete updatedWeek[previousDay];
      }

      updatedWeek[day] = week[previousDay];
      const exceptionWeeks = cloneDeep({
        [`${currentWeek}`]: updatedWeek,
      });
      void dispatch(setProgramSchedule({ exceptionWeeks, programSchedule }));
    } else if (previousDay && day) {
      const { week: oldWeek } = ProgramService.getScheduleForWeek(selectedProgram, oldEventDate);
      const updatedWeek = cloneDeep(week);
      if (updatedWeek[day]?.workoutDay) {
        throw new Error("Nie można mieć dwóch zaplanowanych treningow w tym samym dniu");
      }
      const copyOfOldWeek = cloneDeep(oldWeek);

      if (copyOfOldWeek?.[previousDay]) {
        updatedWeek[day] = copyOfOldWeek[previousDay];
        delete copyOfOldWeek[previousDay];
      }

      const exceptionWeeks = cloneDeep({
        [currentWeek]: updatedWeek,
        [previousWeek]: copyOfOldWeek!,
      });
      void dispatch(setProgramSchedule({ exceptionWeeks, programSchedule }));
    }

    if (event.metadata.source === WorkoutSource.FromActivity) {
      const updatedWorkout = {
        eventDate: formattedEventDate,
        eventMonth: formattedEventMonth,
        eventWeek: currentWeek,
        eventTimestamp: newEventDate.hour(12).toISOString(),
      };
      void dispatch(updateWorkoutActivity({ activity: updatedWorkout, id: event.metadata.id || event.id }));
    }

    analytics.track("drag_event_to_specified_day");
  },
);
