import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { TeamMemberStatus, UserRole } from "@fitness-app/data-models";

import { useAuth } from "~/modules/Auth/AuthProvider";
import { useAppSelector } from "~/store/initializeStore";

interface ProtectedAuthProps {
  children: React.ReactElement;
}

const ProtectedAuth = ({ children }: ProtectedAuthProps): React.ReactElement => {
  const auth = useAuth();
  const location = useLocation();
  const customClaims = useAppSelector((state) => state.user.customClaims);
  if (!auth?.user && !location.pathname.startsWith("/auth")) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  if (
    !location.pathname.startsWith("/inactive") &&
    customClaims &&
    customClaims.role === UserRole.TEAM_MEMBER &&
    customClaims.status !== TeamMemberStatus.ACTIVE &&
    customClaims.status !== TeamMemberStatus.INVITED
  ) {
    return <Navigate to="/inactive" state={{ from: location }} replace />;
  }

  if (location.pathname.startsWith("/inactive") && customClaims?.status === TeamMemberStatus.ACTIVE) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!location.pathname.startsWith("/client") && customClaims?.role === UserRole.CLIENT) {
    return <Navigate to="/client" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedAuth;
