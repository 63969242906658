import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  productId: string;
  clientEmail: string;
  resume_at: number | null;
};

export const setExtendAccessPeriod = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_CLIENT_REDUCER}/setExtendAccessPeriod`,
  async (payload, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/extend-client-access-period", payload);
      analytics.track("extend_access_period", {
        productId: payload.productId,
        clientEmail: payload.clientEmail,
        extendAbout: payload.resume_at,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
