import { useQuery } from "@tanstack/react-query";
import keyBy from "lodash.keyby";

import {
  ExerciseRecordingStatus,
  type ExerciseRecordingWithExerciseInfo,
} from "@fitness-app/data-models/entities/ExerciseRecording";

import { supabase } from "~/store/initializeStore";

export const useTraineeExerciseRecordings = (traineeId: string) => {
  const { data, isLoading } = useQuery(["exercise_recordings", traineeId], {
    queryFn: async () => {
      const query = supabase
        .from("exercise_recording")
        .select(
          "*, exercise:parentExerciseId(name), plan:programId(name), feedback:feedbackId(createdAt,content,files)",
        )
        .eq("traineeId", traineeId)
        .in("status", [
          ExerciseRecordingStatus.SCHEDULED,
          ExerciseRecordingStatus.RECORDED,
          ExerciseRecordingStatus.RATED,
        ])
        .order("scheduledAt", { ascending: false })
        .limit(100);

      const { error, data } = await query.returns<ExerciseRecordingWithExerciseInfo[]>();
      if (error) {
        return [];
      }
      return data;
    },
  });

  return {
    data,
    isLoading,
  };
};

export const useTraineeExerciseRecordingsForProgram = (traineeId: string, programId: string) => {
  const { data, isLoading } = useQuery(["exercise_recordings", traineeId], {
    enabled: Boolean(traineeId && programId),
    queryFn: async () => {
      const query = supabase
        .from("exercise_recording")
        .select(
          "*, exercise:parentExerciseId(name), plan:programId(name), feedback:feedbackId(createdAt,content,files)",
        )
        .eq("traineeId", traineeId)
        .eq("programId", programId)
        .in("status", [
          ExerciseRecordingStatus.SCHEDULED,
          ExerciseRecordingStatus.RECORDED,
          ExerciseRecordingStatus.RATED,
        ])
        .order("scheduledAt", { ascending: false })
        .limit(100);

      const { error, data } = await query.returns<ExerciseRecordingWithExerciseInfo[]>();
      if (error) {
        return [];
      }
      return data;
    },
  });

  return {
    data,
    isLoading,
    byExerciseParentId: data ? keyBy(data, "parentExerciseId") : {},
    byExerciseId: data ? keyBy(data, "exerciseId") : {},
  };
};
