import { createSelector } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { type EmailClientTag } from "@fitness-app/data-models/entities/ProductsSettings";

import { type AppStore } from "../../../index";

const getIntegrations = (store: AppStore) => store.productsSettings.data;

export const getTagsOptions = createSelector([getIntegrations], (integration) => {
  if (!integration?.mailers) {
    return [];
  }

  return uniqBy(
    Object.values(integration.mailers)
      .flat()
      .map((account) => {
        return account?.tags.map((tagObj: EmailClientTag) => ({
          value: tagObj.tag,
          label: tagObj.tag,
        }));
      })
      .filter((item): item is { value: string; label: string }[] => !!item?.length)
      .flat(),
    "value",
  );
});
