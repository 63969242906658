import React from "react";
import { useTranslation } from "react-i18next";

interface FitnessFieldTooltipProps {
  unit?: string;
  bigValue?: boolean;
}

const SMALL_EXAMPLE_VALUES = [5, 7, 9, 10, 12];
const BIG_EXAMPLE_VALUES = [50, 70, 90, 100, 120];

const FitnessFieldTooltip = ({ unit = "", bigValue = false }: FitnessFieldTooltipProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const unitLabel = unit ? `[${unit}]` : "";
  const exampleValues = bigValue ? BIG_EXAMPLE_VALUES : SMALL_EXAMPLE_VALUES;
  return (
    <div>
      {t("fitnessTooltip.possibleOption")}
      <ol className="list-inside list-decimal">
        <li>
          {t("fitnessTooltip.singleValue")} {exampleValues[0]} {unitLabel}
        </li>
        <li>
          {t("fitnessTooltip.range")} {exampleValues[0]} - {exampleValues[1]} {unitLabel}
        </li>
        <li>
          {t("fitnessTooltip.perSet")} {exampleValues[0]}/{exampleValues[1]}/{exampleValues[2]} {unitLabel}
        </li>
        <li>
          {t("fitnessTooltip.perSetWithRange")} {exampleValues[0]}-{exampleValues[1]}/{exampleValues[2]}-
          {exampleValues[3]}/{exampleValues[3]}-{exampleValues[4]} {unitLabel}
        </li>
      </ol>
    </div>
  );
};

export default FitnessFieldTooltip;
