import React, { useCallback, useMemo, useState } from "react";
import { defaultDropAnimationSideEffects, DndContext, DragOverlay, type DropAnimation } from "@dnd-kit/core";
import { Collapse } from "antd";
import { produce } from "immer";
import keyBy from "lodash.keyby";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import {
  WORKOUT_EXERCISES_TYPE,
  type ProgramWorkout,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { prepareMergedWorkoutExercises } from "@fitness-app/utils/src/programs/prepareMergedWorkoutExercises";

import { DndItem } from "~/components/Dnd/DndItem";
import ExerciseInProgramItem from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/ExerciseInProgramItem";
import WorkoutDayMetadata from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/WorkoutDayMetadata";
import WorkoutExercises from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/WorkoutExercises";
import WorkoutActionProvider from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/providers/WorkoutActionProvider";
import { useProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";

interface WorkoutDayProps {
  workout: ProgramWorkout;
  programId: string | null;
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

const WorkoutDay = ({ workout, programId }: WorkoutDayProps) => {
  const { t } = useTranslation("workouts");
  const [activeId, setActiveId] = useState<string | null>(null);
  const { updateWorkoutInProgram } = useProgramBuilderContext();
  const exercisesMap = useMemo(
    () =>
      keyBy(
        [
          ...prepareMergedWorkoutExercises(workout.preWorkout).map((exe) => ({
            ...exe,
            type: WORKOUT_EXERCISES_TYPE.PRE_WORKOUT,
          })),
          ...prepareMergedWorkoutExercises(workout.exercises).map((exe) => ({
            ...exe,
            type: WORKOUT_EXERCISES_TYPE.NORMAL,
          })),
          ...prepareMergedWorkoutExercises(workout.postWorkout).map((exe) => ({
            ...exe,
            type: WORKOUT_EXERCISES_TYPE.POST_WORKOUT,
          })),
        ],
        "id",
      ),
    [workout],
  );

  const renderSortableItemDragOverlay = useCallback(
    (id: string) => (
      <DndItem value={id} dragOverlay>
        {exercisesMap[id] ? (
          <ExerciseInProgramItem
            exerciseInWorkout={exercisesMap[id]}
            onExerciseSelect={() => null}
            isSelected={false}
            deleteExerciseFromWorkout={() => null}
            splitSuperSet={() => null}
            addSeriesToExercise={() => null}
            removeSeriesFromExercise={() => null}
            changeNumberOfSeries={() => null}
            updateExerciseInWorkout={() => null}
          />
        ) : null}
      </DndItem>
    ),
    [exercisesMap],
  );
  const updateWorkout = useCallback((_entityId: string, workout: ProgramWorkout | WorkoutTemplate) => {
    void updateWorkoutInProgram({
      workout: workout as ProgramWorkout,
    });
  }, []);

  if (!programId) {
    return null;
  }

  const findPosition = (id: string) => {
    const exercise = exercisesMap[id];

    if (String(id).startsWith("PLACEHOLDER")) {
      const [, workoutId, type] = String(id).split("__");
      return {
        exerciseId: "PLACEHOLDER",
        orderKey: 0,
        workoutId,
        type: type as WorkoutExercisesType,
      } as const;
    }

    if (exercise) {
      return {
        exerciseId: exercise.id,
        orderKey: exercise.orderKey,
        isSuperSet: exercise.isSuperSet ?? false,
        mergedExercisesIds: exercise.isSuperSet ? exercise.mergedExercisesIds : [],
        type: exercise.type,
      };
    } else {
      return null;
    }
  };

  return (
    <div className="flex min-h-full flex-col rounded-md bg-gray-50/80 p-4">
      <WorkoutActionProvider selectedWorkout={workout} entityId={programId} updateWorkout={updateWorkout}>
        <WorkoutDayMetadata selectedWorkout={workout} programId={programId} />

        <DndContext
          onDragStart={({ active }) => {
            setActiveId(active.id as string);
          }}
          onDragOver={({ active, over }) => {
            const activePosition = findPosition(active.id as string);
            const overPosition = over ? findPosition(over.id as string) : null;

            if (activePosition?.type === overPosition?.type) {
              return;
            }

            if (
              overPosition?.exerciseId === "PLACEHOLDER" &&
              activePosition?.exerciseId &&
              activePosition.type !== overPosition.type
            ) {
              const exercise = exercisesMap[activePosition.exerciseId];
              if (!exercise) {
                return;
              }
              const idsToMove = exercise.isSuperSet ? exercise.mergedExercises : [exercise];
              const idsToRemove = exercise.isSuperSet ? exercise.mergedExercisesIds : [exercise.id];
              void updateWorkoutInProgram({
                workout: {
                  ...workout,
                  [overPosition.type]: [...workout[overPosition.type], ...idsToMove],
                  [activePosition.type]: workout[activePosition.type].filter((exe) => !idsToRemove.includes(exe.id)),
                  updatedAt: new Date().toISOString(),
                },
              });
              return;
            }
          }}
          onDragEnd={({ active, over }) => {
            const activePosition = findPosition(active.id as string);
            const overPosition = over ? findPosition(over.id as string) : null;

            if (activePosition?.exerciseId === "PLACEHOLDER") {
              setActiveId(null);
              return;
            }

            if (activePosition?.type && activePosition.type === overPosition?.type) {
              const nextState = produce(workout[activePosition.type], (draft) => {
                const activeIdNormalized = activePosition.isSuperSet
                  ? activePosition.mergedExercisesIds[0]
                  : activePosition.exerciseId;
                const activeIndex = draft.findIndex((item) => item.id === activeIdNormalized);
                const overId = overPosition?.isSuperSet
                  ? activePosition.orderKey > overPosition.orderKey
                    ? overPosition.mergedExercisesIds[0]
                    : overPosition.mergedExercisesIds.at(-1)
                  : overPosition?.exerciseId;
                const overIndex = overPosition.exerciseId ? draft.findIndex((item) => item.id === overId) : -1;

                if (overIndex > -1) {
                  // Save the item being dragged.
                  const activeItem = draft[activeIndex];

                  // Remove the item being dragged from its old position.
                  draft.splice(activeIndex, 1);

                  if (activeItem) {
                    // Insert the item being dragged into its new position.
                    draft.splice(overIndex, 0, activeItem);
                  }
                }
              });
              void updateWorkoutInProgram({
                workout: {
                  ...workout,
                  [activePosition.type]: nextState,
                  updatedAt: new Date().toISOString(),
                },
              });
            }

            setActiveId(null);
          }}
        >
          <Collapse defaultActiveKey={[WORKOUT_EXERCISES_TYPE.NORMAL]}>
            {(workout.isRestDay ? [WORKOUT_EXERCISES_TYPE.NORMAL] : Object.values(WORKOUT_EXERCISES_TYPE)).map(
              (key) => {
                const exercisesKey = key as WorkoutExercisesType;

                return (
                  <Collapse.Panel
                    key={exercisesKey}
                    header={
                      t(`exercisesTypeShort.${workout.isRestDay ? "restDay" : exercisesKey}`) +
                      ` (${workout[exercisesKey].length})`
                    }
                  >
                    <WorkoutExercises
                      exercises={workout[exercisesKey]}
                      exercisesType={exercisesKey}
                      workoutId={workout.id}
                    />
                  </Collapse.Panel>
                );
              },
            )}
          </Collapse>
          {createPortal(
            <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
              {activeId ? renderSortableItemDragOverlay(activeId) : null}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
      </WorkoutActionProvider>
    </div>
  );
};

export default WorkoutDay;
