import { type TeamMember } from "@fitness-app/data-models";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import {
  addTrainerToTeam,
  removeTrainerFromTeam,
  subscribeToTeamFailed,
  subscribeToTeamPending,
  subscribeToTeamSuccess,
  updateTrainerInTeam,
} from "../reducer";
import { fetchTeamMembers } from "./fetchTeamMembers";
import { unsubscribeFromTeam } from "./unsubscribeFromTeam";

export const subscribeToTeam =
  (): AppThunk =>
  async (dispatch, getState, { auth, subscriptions, db }) => {
    try {
      dispatch(subscribeToTeamPending());

      await dispatch(fetchTeamMembers());

      const { id } = await getLoggedUser(auth);

      if (subscriptions.teamListChannelName && subscriptions.realtimeTeamListChannel) {
        await db.removeChannel(subscriptions.realtimeTeamListChannel);
      }

      subscriptions.teamListChannelName = `team-list-${id}`;
      subscriptions.realtimeTeamListChannel = db.channel(subscriptions.teamListChannelName);

      subscriptions.realtimeTeamListChannel
        .on<TeamMember>(
          "postgres_changes",
          { event: "*", schema: "public", table: "team_member", filter: `trainer=eq.${id}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateTrainerInTeam(payload.new));
            }
            if (payload.eventType === "INSERT") {
              dispatch(addTrainerToTeam(payload.new));
            }
            if (payload.eventType === "DELETE" && payload.old.id) {
              dispatch(removeTrainerFromTeam(payload.old.id));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToTeamSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromTeam());
          }
        });
    } catch {
      dispatch(subscribeToTeamFailed());
    }
  };
