import dayjs from "dayjs";
import groupBy from "lodash.groupby";
import orderBy from "lodash.orderby";
import sortBy from "lodash.sortby";

import { type ChatMessage, type GroupedMessage } from "@fitness-app/data-models/entities/Chat";

export const groupMessageByDay = (messages: ChatMessage[], inverted = false): GroupedMessage[] => {
  const groupMessages = groupBy([...messages].reverse(), (item) => dayjs(item.createdAt).startOf("day").toISOString());

  const formatToMessagesArr = sortBy(
    Object.entries(groupMessages).map(([key, value]) => ({
      date: key,
      messages: value,
    })),
    "date",
  ).map((dayWithMessages) => ({
    messages: orderBy(dayWithMessages.messages, "createdAt"),
    date: dayjs(dayWithMessages.date).format("DD-MM-YYYY"),
  }));

  return inverted ? formatToMessagesArr.reverse() : formatToMessagesArr;
};
