import { type SupabaseClient } from "@supabase/supabase-js";

import { type Database } from "@fitness-app/supabase";

export const getLoggedUser = async (auth: SupabaseClient<Database>["auth"]) => {
  const { data, error } = await auth.getSession();

  if (!data?.session || error) {
    throw new Error("user-is-not-logged");
  }

  return {
    id: data.session.user.id,
    email: data.session.user.email || "",
    isAdmin: false,
    customClaims: data.session.user.app_metadata,
  };
};
