import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type UserProfile } from "@fitness-app/data-models";
import { type CustomClaims } from "@fitness-app/data-models/entities/UserProfile";

import { RequestStatus } from "../../../enums/requestStatus";
import { UserStatus, type AuthUser, type UserReducer } from "./types";

const initialState: UserReducer = {
  status: null,
  error: null,
  profile: null,
  registerError: null,
  profileStatus: null,
  passwordStatus: null,
  sendPasswordResetEmailStatus: null,
  sendLoginLinkStatus: null,
  appVersion: null,
  data: null,
  updateUserDataStatus: null,
  customClaims: null,
};

export const USER_REDUCER = "User";

const userSlice = createSlice({
  name: USER_REDUCER,
  initialState,
  reducers: {
    signUpStarted(state) {
      return {
        ...state,
        status: UserStatus.CREATING_NEW_ACCOUNT,
        error: null,
      };
    },
    signUpSuccess(state) {
      return {
        ...state,
        status: UserStatus.IS_LOGGED,
      };
    },
    signUpFailed(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        status: UserStatus.HAS_ERROR,
        registerError: payload,
      };
    },
    logInStarted(state) {
      return {
        ...state,
        status: UserStatus.LOGGING_IN,
        error: null,
      };
    },
    logInSuccess(state, { payload: { id, email, customClaims } }: PayloadAction<AuthUser>) {
      return {
        ...state,
        status: UserStatus.IS_LOGGED,
        customClaims: (customClaims as unknown as CustomClaims) || state.customClaims || null,
        data: {
          id,
          email,
        },
      };
    },
    logInFailed(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        status: UserStatus.HAS_ERROR,
        error: payload,
      };
    },
    logOutStarted(state) {
      return {
        ...state,
        error: null,
        status: UserStatus.LOGGING_OUT,
      };
    },
    logOutSuccess(state) {
      return {
        ...state,
        data: null,
        status: UserStatus.IS_LOGGED_OUT,
        profileStatus: null,
      };
    },
    logOutFailed(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        status: UserStatus.HAS_ERROR,
        error: payload,
      };
    },
    getUserDetailsStarted(state) {
      return {
        ...state,
        profileStatus: RequestStatus.FETCHING,
      };
    },
    getUserDetailsSuccess(state, { payload }: PayloadAction<UserProfile | undefined>) {
      return {
        ...state,
        profileStatus: RequestStatus.SUCCESS,
        profile: payload || null,
      };
    },
    getUserDetailsFailed(state, { payload: _payload }: PayloadAction<string>) {
      return {
        ...state,
        profileStatus: RequestStatus.FAILED,
      };
    },
    updateUserDetailsStarted(state) {
      state.updateUserDataStatus = RequestStatus.UPDATING;
    },
    updateUserDetailsSuccess(state, { payload }: PayloadAction<Partial<UserProfile> | undefined>) {
      state.updateUserDataStatus = RequestStatus.SUCCESS;
      if (payload && state.profile) {
        state.profile = {
          ...state.profile,
          ...payload,
        };
      }
    },
    updateUserDetailsFailed(state) {
      state.updateUserDataStatus = RequestStatus.FAILED;
    },
    resetPasswordStarted(state) {
      return {
        ...state,
        passwordStatus: RequestStatus.UPDATING,
        error: null,
      };
    },
    resetPasswordSuccess(state) {
      return {
        ...state,
        passwordStatus: RequestStatus.SUCCESS,
        error: null,
      };
    },
    resetPasswordFailed(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        passwordStatus: RequestStatus.FAILED,
        error: payload,
      };
    },
    sendPasswordResetStarted(state) {
      return {
        ...state,
        sendPasswordResetEmailStatus: RequestStatus.FETCHING,
        error: null,
      };
    },

    sendPasswordResetSuccess(state) {
      return {
        ...state,
        sendPasswordResetEmailStatus: RequestStatus.SUCCESS,
        error: null,
      };
    },

    sendLoginLinkStarted(state) {
      return {
        ...state,
        sendLoginLinkStatus: RequestStatus.FETCHING,
        error: null,
      };
    },

    sendLoginLinkSuccess(state) {
      return {
        ...state,
        sendLoginLinkStatus: RequestStatus.SUCCESS,
        error: null,
      };
    },

    resetErrors(state) {
      return {
        ...state,
        error: null,
        passwordStatus: null,
      };
    },
    resetUserStatus(state) {
      return {
        ...state,
        status: null,
      };
    },
    unsubscribeFromUserDetails(state) {
      state.profile = null;
      state.profileStatus = null;
    },
    finishRegisterStarted(state) {
      state.status = UserStatus.LOGGING_IN;
    },
    finishRegisterSuccess(state) {
      state.status = UserStatus.IS_LOGGED;
    },
    finishRegisterFailed(state) {
      state.status = UserStatus.HAS_ERROR;
    },
  },
});

export const {
  logInFailed,
  logInStarted,
  logInSuccess,
  logOutStarted,
  logOutFailed,
  logOutSuccess,
  getUserDetailsFailed,
  getUserDetailsStarted,
  getUserDetailsSuccess,
  unsubscribeFromUserDetails,
  updateUserDetailsFailed,
  updateUserDetailsStarted,
  updateUserDetailsSuccess,
  sendPasswordResetStarted,
  sendPasswordResetSuccess,
  signUpFailed,
  signUpStarted,
  signUpSuccess,
  sendLoginLinkSuccess,
  sendLoginLinkStarted,
  resetPasswordFailed,
  resetPasswordStarted,
  resetPasswordSuccess,
  resetErrors,
  finishRegisterStarted,
  finishRegisterFailed,
  finishRegisterSuccess,
} = userSlice.actions;

export default userSlice.reducer;
