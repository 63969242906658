import React from "react";
import { Card, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import TraineeBodyPhotos from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeBodyPhotos/TraineeBodyPhotos";
import TraineeBodyWeightMeasurements from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeBodyWeightMeasurements";
import { TraineeVideoRecordings } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeVideoRecordings/TraineeVideoRecordings";
import { useAppSelector } from "~/store/initializeStore";

const TraineeMeasurements = () => {
  const { t } = useTranslation(["trainees", "common"]);
  const [searchParams] = useSearchParams();
  const defaultActiveTab = searchParams.get("subTab") || "bodyMeasurements";
  const traineeId = useAppSelector((store) => store.trainee.profile?.id);

  return (
    <Card bordered={false} loading={!traineeId}>
      <Tabs tabPosition="left" defaultActiveKey={defaultActiveTab}>
        <Tabs.TabPane tab={t("measurements.title")} key="bodyMeasurements">
          {traineeId ? <TraineeBodyWeightMeasurements traineeId={traineeId} /> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("measurements.photos")} key="bodyPhotos">
          {traineeId && <TraineeBodyPhotos traineeId={traineeId} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab={t("measurements.videos")} key="videos">
          {traineeId && <TraineeVideoRecordings traineeId={traineeId} />}
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default TraineeMeasurements;
