import { createAsyncThunk } from "@reduxjs/toolkit";
import type dayjs from "dayjs";

import { type ClientMonthActivity } from "@fitness-app/data-models/entities/ClientActivity";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
};

export const fetchDailyActivities = createAsyncThunk<ClientMonthActivity[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/fetchDailyActivities`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { data, error } = await db
      .from("client_month_activity")
      .select("*")
      .eq("traineeId", payload.id)
      .gte("monthYear", payload.start.format("YYYY-MM"))
      .lte("monthYear", payload.end.format("YYYY-MM"))
      .returns<ClientMonthActivity[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
