import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProgramWorkout, type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";
import { addFromTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentProgram } from "../selectors/getCurrentProgram";
import { PROGRAM_BUILDER_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutIdToReplace: string;
  workoutTemplate: WorkoutTemplate | ProgramWorkout;
};

export const replaceWorkoutInProgram = createAsyncThunk<TrainingProgramDetails, Payload, AsyncThunkCreator<string>>(
  `${PROGRAM_BUILDER_REDUCER_NAME}/replaceWorkoutInProgram`,
  async (
    { programId, workoutTemplate, workoutIdToReplace },
    { rejectWithValue, getState, dispatch, extra: { db } },
  ) => {
    const currentProgram = getCurrentProgram(getState());

    const newWorkout = addFromTemplate(workoutTemplate);

    const updatedWorkouts = currentProgram.details.workouts.map((workout) =>
      workout.id === workoutIdToReplace ? { ...newWorkout, id: workoutIdToReplace } : workout,
    );

    dispatch(updateWorkoutsInProgram({ workouts: updatedWorkouts, programId }));

    const { data: programDetails, error } = await db
      .from("training_program_details")
      .update({
        workouts: updatedWorkouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<TrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return programDetails;
  },
);
