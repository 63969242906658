import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";
import { getErrorMessage } from "@fitness-app/utils";

import { type AppThunk } from "../../../index";
import {
  subscribeToProductClientFailed,
  subscribeToProductClientStarted,
  subscribeToProductClientSuccess,
  updateProductClient,
} from "../reducer";
import { fetchProductClient } from "./fetchProductClient";
import { unsubscribeFromProductClient } from "./unsubscribeFromProductClient";

export const subscribeToProductClient =
  (productId: string, clientEmail: string | null, id?: string): AppThunk =>
  async (dispatch, _getState, { subscriptions, db }) => {
    try {
      dispatch(subscribeToProductClientStarted());

      const client = await dispatch(fetchProductClient({ productId, clientEmail: clientEmail || "", id })).unwrap();

      if (subscriptions.productClientChannelName && subscriptions.realtimeProductClientChannel) {
        await db.removeChannel(subscriptions.realtimeProductClientChannel);
      }

      subscriptions.productClientChannelName = `product-client-${productId}-${clientEmail}`;
      subscriptions.realtimeProductClientChannel = db.channel(subscriptions.productClientChannelName);

      subscriptions.realtimeProductClientChannel
        .on<ProductClient>(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "product_client",
            filter: `id=eq.${client.id}`,
          },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductClient(payload.new));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToProductClientSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromProductClient());
          }
        });
    } catch (error) {
      dispatch(subscribeToProductClientFailed(getErrorMessage(error)));
    }
  };
