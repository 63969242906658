import { type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";

import { type RequestStatus } from "../../../enums/requestStatus";

export const EXERCISES_REDUCER_NAME = "exercises";

export interface ExercisesReducer {
  list: ExerciseWithVideo[];
  exercisesStatus: null | RequestStatus;
  searchText: string;
}
