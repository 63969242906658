import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProductResource } from "@fitness-app/data-models/entities/ProductResource";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_RESOURCES_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
};

export const fetchProductResources = createAsyncThunk<ProductResource[], Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/fetchProductResources`,
  async ({ productId }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("product_resource")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("productId", productId)
      .returns<ProductResource[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
