import React, { useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, message, Space, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { mealsPlanActions } from "@fitness-app/app-store";

import MealsPlanAttachments from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanAttachments/MealsPlanAttachments";
import { RevertLastChanges } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/RevertLastChanges";
import MealsPlanSchedule from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsPlanSchedule";
import MealsPlanShoppingList from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/MealsPlanShoppingList";
import MealsPlanSupplements from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSupplements/MealsPlanSupplements";
import MealsPlanTargets from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanTargets/MealsPlanTargets";
import MealsPlanTemplateProvider from "~/shared/providers/MealsPlanTemplateProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const TABS_KEY = ["schedule", "targets", "shoppingList", "supplements", "attachments"];

const MealsPlanDetails = (): React.ReactElement => {
  const { t } = useTranslation(["nutrition", "common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromUrl = searchParams.get("tab") || "";
  const { id } = useParams<{ id: string }>();
  const activeTab = TABS_KEY.includes(tabFromUrl) ? tabFromUrl : TABS_KEY[0];
  const numberOfAttachments = useAppSelector((state) => state.mealsPlans.selectedMealsPlan?.attachments || []).length;
  const dispatch = useAppDispatch();
  const [generatePdfLoading, setGeneratePdfLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(mealsPlanActions.clearSelectedMealsPlan());
    };
  }, [dispatch]);

  const generatePdf = async () => {
    if (!id) {
      return;
    }
    setGeneratePdfLoading(true);
    void message.loading({
      content: "Generowanie może potrwać nawet kilkanaście sekund...",
      key: "generatePdf",
      duration: 0,
    });

    const response = await dispatch(mealsPlanActions.generatePdfForTemplate({ nutritionId: id, download: false }));
    setGeneratePdfLoading(false);
    message.destroy("generatePdf");
    if (response.type.includes("fulfilled")) {
      window.open(response.payload);
    } else {
      void message.error(`Błąd podczas generowania PDF: ${response.payload}`);
    }
  };

  return (
    <MealsPlanTemplateProvider>
      <Card>
        <div className="p-6">
          <Tabs
            type="card"
            defaultActiveKey="schedule"
            className="tabs-reset"
            activeKey={activeTab}
            tabBarExtraContent={
              <Space size={12}>
                <RevertLastChanges />
                <Button
                  icon={<SaveOutlined />}
                  size="small"
                  disabled={generatePdfLoading}
                  loading={generatePdfLoading}
                  onClick={generatePdf}
                >
                  Zapisz do PDF
                </Button>
              </Space>
            }
            onChange={(tab) => setSearchParams({ tab })}
          >
            <Tabs.TabPane key="schedule" tab={t("mealsPlanDetails.schedule")}>
              <MealsPlanSchedule />
            </Tabs.TabPane>

            <Tabs.TabPane key="targets" tab={t("mealsPlanDetails.targets")}>
              <MealsPlanTargets />
            </Tabs.TabPane>

            <Tabs.TabPane key="shoppingList" tab={t("mealsPlanDetails.shoppingList")}>
              <MealsPlanShoppingList />
            </Tabs.TabPane>

            <Tabs.TabPane key="supplements" tab={t("mealsPlanDetails.supplements")}>
              <MealsPlanSupplements />
            </Tabs.TabPane>

            <Tabs.TabPane
              key="attachments"
              tab={`${t("mealsPlanDetails.attachments")}${numberOfAttachments > 0 ? ` (${numberOfAttachments})` : ""}`}
            >
              <MealsPlanAttachments planId={id!} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Card>
    </MealsPlanTemplateProvider>
  );
};

export default MealsPlanDetails;
