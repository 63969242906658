import React, { useCallback, useEffect, useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Modal,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  type MenuProps,
  type TableProps,
} from "antd";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { nutritionActions, RequestStatus } from "@fitness-app/app-store";
import { mealsPlanActions } from "@fitness-app/app-store/src/store/reducers/mealsPlans";
import { type MealsPlanWithCreator } from "@fitness-app/data-models/entities/MealsPlan";
import { generateMealsPlanDetails } from "@fitness-app/utils/src/nutrition/generateMealsPlanDetails";
import {
  transformAddMealPlanModelToMealsPlan,
  type AddMealPlanModel,
  type NutrientsTargets,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useTagOptions } from "~/hooks/useTagOptions";
import AddMealPlan from "~/modules/Nutrition/AddMealPlan/AddMealPlan";
import { MealsSearch } from "~/modules/Nutrition/MealsPlans/MealsSearch";
import { useTeamMembers } from "~/modules/Team/hooks/useTeamMembers";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const MealsPlans = () => {
  const { t } = useTranslation("nutrition");
  const [showForm, toggleForm] = useState(false);
  const dispatch = useAppDispatch();
  const { filters: tagsFilters } = useTagOptions("mealsPlanTags");
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(() => toggleForm(false));
  const user = useAppSelector((store) => store.user.data);
  const {
    list,
    listPage,
    listPerPage,
    listStatus,
    listTotalPages,
    filters: { currentAssignedTrainerFilter, currentFilteredTags },
  } = useAppSelector((store) => store.mealsPlans);
  const { isOwner } = useUserClaims();
  const [deleting, onSuccessDelete, onFailureDelete, onStartDeleting] = useEntityChange();
  const [duplicatingProgram, onSuccessDuplicated, onFailureDuplicated, onStartDuplicating] = useEntityChange();
  const { isTrainer, userId } = useUserRole();
  const [modal, contextHolder] = Modal.useModal();
  const { createTrainerOptions } = useTeamMembers();
  const navigate = useNavigate();

  useEffect(() => {
    void dispatch(mealsPlanActions.fetchMealsPlan());

    return () => {
      dispatch(nutritionActions.clearNutritionSearch());
    };
  }, [dispatch]);

  const onAddMealsPlan = async (data: AddMealPlanModel, nutrients: NutrientsTargets) => {
    onStart();
    try {
      const modelToSave = transformAddMealPlanModelToMealsPlan(data, nutrients, user?.id, isTrainer);

      await dispatch(
        mealsPlanActions.addMealsPlan({
          mealsPlan: modelToSave,
          mealsPlanDetails: generateMealsPlanDetails(modelToSave),
        }),
      ).unwrap();
      onSuccess();
    } catch {
      onFailure();
    }
  };

  const toggleSharedState = async (planId: string, shared: boolean) => {
    await dispatch(
      mealsPlanActions.updateMealsPlan({
        mealsPlan: {
          id: planId,
          shared,
          updatedAt: new Date().toISOString(),
        },
      }),
    ).unwrap();
  };

  const removeMealsPlan = async (id: string) => {
    onStartDeleting(id);
    try {
      await dispatch(mealsPlanActions.deleteMealsPlan({ planId: id })).unwrap();
      onSuccessDelete();
    } catch {
      onFailureDelete();
    }
  };

  const duplicatePlan = async (plan: MealsPlanWithCreator) => {
    onStartDuplicating(plan.id, t<string>("workouts:program.duplicating"));
    try {
      await dispatch(mealsPlanActions.duplicateMealsPlan(plan)).unwrap();
      onSuccessDuplicated();
    } catch {
      onFailureDuplicated();
    }
  };

  const getMenuItems = useCallback(
    (row: MealsPlanWithCreator): MenuProps["items"] => [
      {
        label: t("common:button.duplicate"),
        key: "duplicate",
        icon: <CopyOutlined />,
        onClick: () => {
          void duplicatePlan(row);
        },
      },
      {
        label: t("common:button.delete"),
        key: "delete",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => {
          void modal.confirm({
            title: t("mealsPlan.deleteWarning"),
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { danger: true },
            okText: t("common:button.delete"),
            onOk() {
              void removeMealsPlan(row.id);
            },
          });
        },
        disabled: !isTrainer && row.createdBy !== userId,
      },
    ],
    [t, isTrainer, userId],
  );

  const handleChange: TableProps<MealsPlanWithCreator>["onChange"] = (pagination, filters) => {
    if (!isOwner) {
      if (!isEqual(currentFilteredTags, filters.tags)) {
        void dispatch(
          mealsPlanActions.fetchMealsPlan({
            tags: filters.tags as string[],
            page: 1,
          }),
        );
      } else if (pagination.current && pagination.current !== listPage) {
        void dispatch(mealsPlanActions.fetchMealsPlan({ page: pagination.current }));
        return;
      }
    } else {
      if (!isEqual(currentAssignedTrainerFilter ? [currentAssignedTrainerFilter] : null, filters.creator)) {
        const newTrainerFilter = filters.creator?.[0] || null;
        void dispatch(
          mealsPlanActions.fetchMealsPlan({
            assignedTrainerId: (newTrainerFilter as string) || null,
            page: 1,
          }),
        );
      } else if (!isEqual(currentFilteredTags, filters.tags)) {
        void dispatch(
          mealsPlanActions.fetchMealsPlan({
            tags: filters.tags as string[],
            assignedTrainerId: currentAssignedTrainerFilter || null,
            page: 1,
          }),
        );
      } else if (pagination.current && pagination.current !== listPage) {
        void dispatch(mealsPlanActions.fetchMealsPlan({ page: pagination.current }));
        return;
      }
    }
  };

  return (
    <>
      <Card
        extra={
          <Button onClick={() => toggleForm(true)} type="primary">
            {t("mealsPlan.add")}
          </Button>
        }
      >
        <Space size={6}>
          <MealsSearch />

          <Space>
            <Switch
              checked={currentAssignedTrainerFilter === userId}
              onChange={(active) => {
                void dispatch(
                  mealsPlanActions.fetchMealsPlan({
                    assignedTrainerId: active ? userId : null,
                    page: 1,
                  }),
                );
              }}
            />
            <Typography.Text>Wyświetl tylko moje plany</Typography.Text>
          </Space>
        </Space>

        <Table<MealsPlanWithCreator>
          dataSource={list}
          rowKey="id"
          scroll={{ x: true }}
          loading={listStatus === RequestStatus.FETCHING}
          pagination={{
            pageSize: listPerPage,
            total: listTotalPages * listPerPage,
            current: listPage,
            showSizeChanger: false,
          }}
          onChange={handleChange}
        >
          <Table.Column<MealsPlanWithCreator> title={t("mealsPlan.name")} dataIndex="name" key="name" />
          <Table.Column<MealsPlanWithCreator>
            title={t("mealsPlan.targetCalories")}
            dataIndex="targetCalories"
            key="targetCalories"
          />
          <Table.Column<MealsPlanWithCreator>
            title={t("mealsPlan.dietaryPreference")}
            dataIndex="dietaryPreference"
            key="dietaryPreference"
            render={(dietaryPreference: MealsPlanWithCreator["dietaryPreference"]) =>
              t(`dietaryPreferenceEnum.${dietaryPreference}`)
            }
          />
          <Table.Column<MealsPlanWithCreator>
            title={t("mealsPlan.macroSplit")}
            dataIndex="macroSplit"
            key="macroSplit"
            render={(macroSplit: MealsPlanWithCreator["macroSplit"]) => t(`macroSplit.${macroSplit}`)}
          />
          <Table.Column<MealsPlanWithCreator>
            title={t("mealsPlan.tags")}
            dataIndex="tags"
            key="tags"
            filterMultiple
            filterSearch
            filters={tagsFilters}
            render={(tags: string[]) =>
              tags?.length ? (
                <Space direction="vertical">
                  {tags.map((tag, i) => (
                    <Tag key={`${tag}-${i}`}>{tag}</Tag>
                  ))}
                </Space>
              ) : (
                "-"
              )
            }
          />
          <Table.Column<MealsPlanWithCreator>
            title={t("mealsPlan.creator")}
            dataIndex="creator"
            key="creator"
            render={(creator: MealsPlanWithCreator["creator"]) =>
              creator ? `${creator?.firstName} ${creator?.lastName}` : "-"
            }
            {...(!isOwner
              ? {}
              : {
                  filterMultiple: false,
                  filterSearch: true,
                  filteredValue: currentAssignedTrainerFilter ? [currentAssignedTrainerFilter] : null,
                  filters: createTrainerOptions(true),
                })}
          />
          <Table.Column<MealsPlanWithCreator>
            title={t("workouts:program.shared")}
            dataIndex="shared"
            key="shared"
            render={(shared: boolean, row) => {
              return (
                <Switch
                  unCheckedChildren={<EyeInvisibleOutlined />}
                  checkedChildren={<ShareAltOutlined />}
                  disabled={!isTrainer && row.createdBy !== userId}
                  defaultChecked={shared}
                  onChange={(change) => {
                    void toggleSharedState(row.id, change);
                  }}
                />
              );
            }}
          />
          <Table.Column<MealsPlanWithCreator>
            title={t("common:list.options")}
            key="action"
            align="left"
            render={(_, row) => (
              <>
                <Dropdown.Button
                  menu={{ items: getMenuItems(row) }}
                  loading={duplicatingProgram === row.id || deleting === row.id}
                  onClick={() => {
                    navigate(`/nutrition/plans/${row.id}`);
                  }}
                >
                  {t("common:button.details")}
                </Dropdown.Button>
                {contextHolder}
              </>
            )}
          />
        </Table>
      </Card>
      <ModalForm
        open={showForm}
        loading={!!loading}
        onCancel={() => toggleForm(false)}
        title={t("mealsPlan.addNewTitle")}
        width={850}
      >
        <AddMealPlan onSubmit={onAddMealsPlan} />
      </ModalForm>
    </>
  );
};

export default MealsPlans;
