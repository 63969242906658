import React, { useEffect, useMemo, useRef } from "react";
import { FormOutlined } from "@ant-design/icons";
import { Cascader, Form, Input, Select } from "antd";
import { type CascaderRef } from "antd/es/cascader";
import { type FormInstance } from "antd/lib/form";
import deburr from "lodash.deburr";
import { useTranslation } from "react-i18next";

import { exercisesSelectors } from "@fitness-app/app-store";
import {
  ExerciseType,
  type ExerciseTypeEnum,
  type ExerciseWithVideo,
} from "@fitness-app/data-models/entities/Exercise";
import {
  WORKOUT_EXERCISES_TYPE,
  type ExerciseInProgramFormModel,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";

import CardioExerciseForm from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/CardioExerciseForm";
import PowerExerciseForm from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/PowerExerciseForm";
import { bodyPartOptions } from "~/modules/TrainingPrograms/tabs/ExercisesDatabase/ExerciseForm/types";
import { useAppSelector } from "~/store/initializeStore";
import { createExercisesOptions, exercisesOTypeOptions, getGroupedWorkoutsOptions } from "./types";

interface ExerciseInProgramFormProps {
  formController?: FormInstance<ExerciseInProgramFormModel>;
  onSubmit: (formData: ExerciseInProgramFormModel, exercise: ExerciseWithVideo) => void;
  model?: Partial<ExerciseInProgramFormModel> | null;
  workoutExercisesType: WorkoutExercisesType | null;
}

const ExerciseInProgramForm = ({
  model,
  onSubmit,
  formController,
  workoutExercisesType,
}: ExerciseInProgramFormProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const exercises = useAppSelector((store) => store.exercises.list);
  const exercisesMap = useAppSelector(exercisesSelectors.getExercisesMap);
  const inputRef = useRef<CascaderRef>(null);
  const selectedWorkoutId: string[] | string = Form.useWatch(["exerciseId"], formController);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    } else {
      formController?.setFieldsValue({
        exercisesType: workoutExercisesType || WORKOUT_EXERCISES_TYPE.NORMAL,
      });
    }
  }, [model]);
  const parsedId = Array.isArray(selectedWorkoutId) ? selectedWorkoutId.at(-1) : selectedWorkoutId;

  const exercisesOptions = useMemo(() => getGroupedWorkoutsOptions(exercises), [exercises]);
  const selectedExercise = exercisesMap[parsedId!] || null;

  useEffect(() => {
    if (selectedExercise && !model) {
      if (selectedExercise.type === ExerciseType.CARDIO) {
        formController?.setFieldsValue({
          numberOfSeries: 1,
          duration: {
            value: "0",
            format: "seconds",
          },
        });
      } else {
        formController?.setFieldsValue({
          numberOfSeries: 1,
          numberOfRepeats: "1",
          weight: "0",
          restTime: 60,
          rir: -1,
          tempo: "",
          ...(selectedExercise.type !== ExerciseType.POWER
            ? {
                duration: {
                  format: "seconds",
                  value: "0",
                },
              }
            : {
                duration: {
                  format: "seconds",
                  value: null,
                },
              }),
        });
      }
    }
  }, [selectedExercise, model]);

  const filter = (inputValue: string, options: { label: string | React.ReactNode }[]) => {
    return options.some((option) => {
      // @ts-expect-error ignore
      if (option.isLeaf && !option.children) {
        const label = deburr(String(option?.label || "").toLowerCase());

        const searchValue = deburr((inputValue || "").toLowerCase());

        return label.indexOf(searchValue) > -1;
      }
      return false;
    });
  };

  const renderForm = (type: ExerciseTypeEnum) => {
    if (type === ExerciseType.CARDIO) {
      return <CardioExerciseForm />;
    }
    return <PowerExerciseForm validateDurationField={type !== ExerciseType.POWER && model?.duration?.value !== null} />;
  };

  const handleSubmit = (model: ExerciseInProgramFormModel) => {
    if (selectedExercise) {
      onSubmit(model, selectedExercise);
    }
  };

  return (
    <Form<ExerciseInProgramFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        numberOfSeries: 1,
        comment: "",
        alternativeExercises: [],
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="exercisesType"
        hidden={!!model}
        label={t<string>("exerciseInProgram.exercisesType")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select options={exercisesOTypeOptions} />
      </Form.Item>
      <Form.Item
        name="exerciseId"
        hidden={!!model}
        label={t<string>("exerciseInProgram.exercise")}
        rules={[
          {
            required: true,
            message: t<string>("exerciseInProgram.selectToMoveForward"),
          },
        ]}
      >
        <Cascader
          options={exercisesOptions}
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  */
          showSearch={{ filter: filter as any }}
          autoFocus
          ref={inputRef}
          placeholder="Wybierz z listy lub wyszukaj ćwiczenie..."
        />
      </Form.Item>

      <div className="border-rounded-lg mt-2 border border-dashed border-gray-300 bg-gray-50 p-6">
        {selectedExercise ? (
          <div>
            <Form.Item label={t("exerciseInProgram.selectedExercise")}>
              <Input value={selectedExercise.name} disabled />
            </Form.Item>

            <Form.Item label={t("exerciseInProgram.bodyPart")}>
              <Select options={bodyPartOptions} value={selectedExercise.bodyParts} disabled mode="multiple" />
            </Form.Item>

            {renderForm(selectedExercise.type)}

            <Form.Item label={t("exerciseInProgram.comment")} name="comment">
              <Input.TextArea rows={3} />
            </Form.Item>

            <Form.Item label={t("exerciseInProgram.alternativeExercises")} name="alternativeExercises">
              <Select
                showSearch
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={createExercisesOptions(exercises)}
                mode="multiple"
                placeholder={t("exerciseInProgram.alternativeExercisesPlaceholder")}
              />
            </Form.Item>
          </div>
        ) : (
          <div className="flex min-h-[300px] items-center justify-center">
            <div className="text-center">
              <FormOutlined
                style={{
                  color: "#40a9ff",
                  fontSize: 32,
                  marginBottom: 5,
                }}
              />
              <h3 className="mb-3">{t("exerciseInProgram.selectExercise")}</h3>
              <p>{t("exerciseInProgram.formIsDependOnSelection")}</p>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};

export default ExerciseInProgramForm;
