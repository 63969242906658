import {
  type ClientTrainingProgram,
  type ClientTrainingProgramDetails,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TRAINEE_PROGRAM_REDUCER_NAME = "traineeProgram";

export interface TraineeProgramReducer {
  programDetails: null | ClientTrainingProgramDetails;
  programStatus: null | RequestStatus;
  programId: string | null;
  selectedProgram: null | ClientTrainingProgram;
  scheduledPrograms: ClientTrainingProgram[];
}
