import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { AUTOMATION_REDUCER_NAME } from "../types";

type Payload = string;

export const deleteProgramAutomation = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${AUTOMATION_REDUCER_NAME}/deleteProgramAutomation`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("program_automation").delete().eq("id", payload);

    if (error) {
      return rejectWithValue(error.message);
    }

    return payload;
  },
);
