import React, { useEffect, useState } from "react";
import { Switch } from "antd";

import { traineeMealsPlanActions } from "@fitness-app/app-store";

import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch } from "~/store/initializeStore";

interface TraineeDishesOccurrenceProps {
  traineeId: string;
  planId: string;
}

export const TraineeDishesOccurrence = ({ traineeId, planId }: TraineeDishesOccurrenceProps): React.ReactElement => {
  const [showOccurrence, setShowOccurrence] = useState(false);
  const dispatch = useAppDispatch();
  const {
    mealsMap: { dishes },
  } = useMealsPlanContext();

  useEffect(() => {
    if (showOccurrence && Object.keys(dishes).length) {
      void dispatch(
        traineeMealsPlanActions.fetchDishesOccurrence({
          planId: planId,
          traineeId: traineeId,
          names: Object.values(dishes).map((dish) => dish.name),
        }),
      );
    } else {
      dispatch(traineeMealsPlanActions.clearDishesOccurrence());
    }
  }, [dishes, showOccurrence]);

  return (
    <div className="flex justify-end gap-x-2">
      <Switch checked={showOccurrence} onChange={setShowOccurrence} />
      <span>Pokaż potwórzenia produktów i potraw</span>
    </div>
  );
};
