import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppConfigType, type AppVersionConfig } from "@fitness-app/data-models/entities/TrainerConfig";

import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchAppVersionConfig = createAsyncThunk<AppVersionConfig, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/fetchAppVersionConfig`,
  async (_, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("app_config")
      .select()
      .eq("type", AppConfigType.APP_VERSION)
      .single<AppVersionConfig>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
