import { createAsyncThunk } from "@reduxjs/toolkit";
import { type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentProgram } from "../selectors/getCurrentProgram";
import { PROGRAM_BUILDER_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutId: string;
};

export const removeWorkoutFromProgram = createAsyncThunk<TrainingProgramDetails, Payload, AsyncThunkCreator<string>>(
  `${PROGRAM_BUILDER_REDUCER_NAME}/removeWorkoutFromProgram`,
  async ({ programId, workoutId }, { rejectWithValue, getState, dispatch, extra: { db } }) => {
    const currentProgram = getCurrentProgram(getState());

    const updatedWorkouts = currentProgram.details.workouts.filter((workout) => workout.id !== workoutId);

    dispatch(updateWorkoutsInProgram({ workouts: updatedWorkouts, programId }));

    const { data: programDetails, error } = await db
      .from("training_program_details")
      .update({
        workouts: updatedWorkouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<TrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return programDetails;
  },
);
