import { type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import { KCAL_PER_NUTRIENT_GRAM, type MealsPlan } from "@fitness-app/data-models/entities/MealsPlan";

import { countNutrientGrams } from "./countNutrients";

export const getMealsPlanTargets = (
  selectedMealsPlan?: MealsPlan | ClientNutrition | null,
): {
  calories: number;
  macros: {
    carbs: number;
    fat: number;
    protein: number;
  };
} => {
  if (!selectedMealsPlan) {
    return {
      calories: 0,
      macros: {
        carbs: 0,
        fat: 0,
        protein: 0,
      },
    };
  }

  const proteinTarget = countNutrientGrams(
    selectedMealsPlan.targets.protein,
    selectedMealsPlan.targetCalories,
    KCAL_PER_NUTRIENT_GRAM.protein,
  );

  const carbsTarget = countNutrientGrams(
    selectedMealsPlan.targets.carbs,
    selectedMealsPlan.targetCalories,
    KCAL_PER_NUTRIENT_GRAM.carbs,
  );

  const fatTarget = countNutrientGrams(
    selectedMealsPlan.targets.fat,
    selectedMealsPlan.targetCalories,
    KCAL_PER_NUTRIENT_GRAM.fat,
  );

  return {
    calories: selectedMealsPlan.targetCalories,
    macros: {
      carbs: carbsTarget,
      fat: fatTarget,
      protein: proteinTarget,
    },
  };
};
