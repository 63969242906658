import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ProductResource } from "@fitness-app/data-models/entities/ProductResource";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchProductResources } from "./actions/fetchProductResources";
import { fetchResourcesCategories } from "./actions/fetchResourcesCategories";
import { PRODUCT_RESOURCES_REDUCER_NAME, type ProductResourcesReducer } from "./types";

const initialState: ProductResourcesReducer = {
  status: null,
  data: [],
  error: null,
  categories: [],
  categoriesStatus: null,
  searchTerm: null,
};

const productResourcesSlice = createSlice({
  name: PRODUCT_RESOURCES_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductResourcesStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = [];
    },
    subscribeToProductResourcesSuccess(state, { payload }: PayloadAction<ProductResource[]>) {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    },
    subscribeToProductResourcesFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
    },
    unsubscribeFromProductResources(state) {
      state.status = null;
      state.data = [];
      state.error = null;
    },
    clearSearchMode(state) {
      state.searchTerm = "";
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductResources.pending, (state) => {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchProductResources.fulfilled, (state, { payload }) => {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    });
    builder.addCase(fetchProductResources.rejected, (state, { payload }) => {
      state.status = RequestStatus.FAILED;
      state.error = payload || null;
    });
    builder.addCase(fetchResourcesCategories.pending, (state) => {
      state.categoriesStatus = RequestStatus.FETCHING;
      state.error = null;
      state.categories = [];
    });
    builder.addCase(fetchResourcesCategories.fulfilled, (state, { payload }) => {
      state.categoriesStatus = RequestStatus.SUCCESS;
      state.categories = payload;
    });
    builder.addCase(fetchResourcesCategories.rejected, (state, { payload }) => {
      state.categoriesStatus = RequestStatus.FAILED;
      state.error = payload || null;
    });
  },
});

export const {
  subscribeToProductResourcesStarted,
  subscribeToProductResourcesSuccess,
  subscribeToProductResourcesFailed,
  unsubscribeFromProductResources,
  setSearchTerm,
  clearSearchMode,
} = productResourcesSlice.actions;

export default productResourcesSlice.reducer;
