import React, { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { SurveyQuestionType } from "@fitness-app/data-models/entities/SurveyTemplate";

import i18nextConfig from "~/i18nextConfig";
import ABQuestion from "~/modules/SurveysTemplates/SurveyForm/questions/ABQuestion";
import SelectQuestion from "~/modules/SurveysTemplates/SurveyForm/questions/SelectQuestion";
import TextQuestion from "~/modules/SurveysTemplates/SurveyForm/questions/TextQuestion";
import { type SurveyQuestionFormModel } from "./types";

interface SurveyQuestionFormProps {
  formController?: FormInstance<SurveyQuestionFormModel>;
  onSubmit: (formData: SurveyQuestionFormModel) => void;
  model?: Partial<SurveyQuestionFormModel> | null;
}

const questionTypes = [
  {
    label: i18nextConfig.t(`surveys:questionType.${SurveyQuestionType.Text}`),
    value: SurveyQuestionType.Text,
  },
  {
    label: i18nextConfig.t(`surveys:questionType.${SurveyQuestionType.Select}`),
    value: SurveyQuestionType.Select,
  },
  {
    label: i18nextConfig.t(`surveys:questionType.${SurveyQuestionType.AB}`),
    value: SurveyQuestionType.AB,
  },
];

const SurveyQuestionForm = ({ model, onSubmit, formController }: SurveyQuestionFormProps) => {
  const { t } = useTranslation(["surveys", "common"]);
  const [questionType, setQuestionType] = useState(model?.question?.type || SurveyQuestionType.Text);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const renderQuestionForm = useCallback(() => {
    if (questionType === SurveyQuestionType.Select) {
      return <SelectQuestion />;
    }

    if (questionType === SurveyQuestionType.AB) {
      return <ABQuestion />;
    }

    return <TextQuestion />;
  }, [questionType]);

  const handleSubmit = (model: SurveyQuestionFormModel) => {
    if (model.question.type === SurveyQuestionType.Select) {
      model.question.multiSelect = model.question.multiSelect ?? false;
    }

    if (model.question.type === SurveyQuestionType.AB) {
      model.question.extendedAnswerA = model.question.extendedAnswerA ?? false;
      model.question.extendedAnswerB = model.question.extendedAnswerB ?? false;
    }

    onSubmit(model);
  };

  return (
    <Form<SurveyQuestionFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        question: {
          type: SurveyQuestionType.Text,
          title: "",
          desc: "",
          isRequired: false,
        },
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name={["question", "type"]}
        label={t("surveyDetailsForm.type")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          placeholder={t("surveyDetailsForm.selectFromList")}
          disabled={Boolean(model?.question?.type)}
          options={questionTypes}
          onSelect={(type: SurveyQuestionType) => setQuestionType(type)}
        />
      </Form.Item>

      {renderQuestionForm()}
    </Form>
  );
};

export default SurveyQuestionForm;
