import React, { type FunctionComponent } from "react";
import { Descriptions, Grid, type DescriptionsProps } from "antd";
import { useTranslation } from "react-i18next";

import { type Order } from "@fitness-app/data-models/entities/Order";
import { type CartSettings } from "@fitness-app/data-models/entities/ProductsSettings";
import { cn } from "@fitness-app/utils/src/styles/cn";

interface OwnProps {
  customConsents: CartSettings["customConsents"];
  metadata: Order["metadata"];
}

type Props = OwnProps;

const CustomConsentData: FunctionComponent<Props> = ({ customConsents, metadata }) => {
  const { t } = useTranslation(["products", "common"]);
  const { lg } = Grid.useBreakpoint();

  if (!customConsents) {
    return null;
  }

  const items: DescriptionsProps["items"] = customConsents?.map((item, index) => {
    const consent = customConsents?.find((item2) => item2.name === item.name);
    return {
      key: `customConsent-${index}`,
      span: 3,
      label: consent?.label,
      children: (
        <p className={cn("whitespace-nowrap", metadata?.[item.name] ? "text-green-500" : "text-red-500")}>
          {metadata?.[item.name] ? t<string>(`productConsents.agreed`) : t<string>(`productConsents.disagreed`)}
        </p>
      ),
    };
  });

  return (
    <Descriptions
      title={t<string>(`productConsents.title`)}
      bordered
      layout={lg ? "horizontal" : "vertical"}
      className="mt-3"
      items={items}
    />
  );
};

export default CustomConsentData;
