import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { getParentTrainerId } from "@fitness-app/app-store/src/store/reducers/user/selectors";
import { ClientNoteCategory, ClientNoteType, type ClientNote } from "@fitness-app/data-models/entities/ClientNote";

import UploadField from "~/components/UploadField/UploadField";
import { useAppSelector } from "~/store/initializeStore";
import { noteCategories, type ClientNoteFormModel } from "./types";

interface ClientNoteFormProps {
  formController?: FormInstance<ClientNoteFormModel>;
  onSubmit: (formData: ClientNoteFormModel) => void;
  model?: ClientNote | ClientNoteFormModel | Partial<ClientNoteFormModel> | null;
  traineeId: string;
}

function useNewTimelineItemForm(model?: Partial<ClientNote> | null) {
  const { t } = useTranslation("trainees");

  let showItemTypeSelect = true;
  let selectedItemType = ClientNoteType.Text;

  if (model?.content) {
    selectedItemType = ClientNoteType.Text;
    showItemTypeSelect = false;
  }

  const [noteType, setType] = useState<ClientNoteType>(selectedItemType);

  const createItemTypeOptions = () => {
    return Object.values(ClientNoteType).map((item) => {
      return {
        label: t(`notes.options.${item}`),
        value: item,
        disabled: item === ClientNoteType.Event,
      };
    });
  };

  return {
    viewModel: {
      showItemTypeSelect,
      noteType,
      options: createItemTypeOptions(),
      model,
    },
    operations: {
      setType,
    },
  };
}

const ClientNoteForm = ({ model, onSubmit, formController, traineeId }: ClientNoteFormProps) => {
  const { t } = useTranslation(["trainees", "common"]);
  const { viewModel, operations } = useNewTimelineItemForm(model);
  const parentId = useAppSelector(getParentTrainerId);

  useEffect(() => {
    if (model?.content) {
      formController?.setFieldsValue({ ...model, attachments: model.attachments || [] });
    } else {
      formController?.resetFields();
    }

    return () => {
      formController?.resetFields();
    };
  }, []);

  return (
    <Form
      name="new-timeline-item-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        type: viewModel.noteType,
        category: ClientNoteCategory.General,
        attachments: model?.attachments || [],
      }}
      onFinish={onSubmit}
    >
      {viewModel.showItemTypeSelect && (
        <>
          <Form.Item
            name="type"
            label={t("notes.type")}
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Select onChange={(value) => operations.setType(value)}>
              {viewModel.options.map((option) => (
                <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      <Form.Item
        name="category"
        label={t("notes.category")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Select options={noteCategories} />
      </Form.Item>

      <Form.Item
        tooltip={t<string>("products:form.fileAdditionalInfo")}
        name="attachments"
        label={t<string>("products:productResources.form.attachments")}
        valuePropName="fileList"
        getValueFromEvent={(e: unknown) => e}
        wrapperCol={{ span: 12 }}
      >
        <UploadField
          key={model?.attachments?.[0]?.uid || "default-uploader"}
          multiple
          bucket="users"
          fileList={model?.attachments || []}
          storageRef={`${parentId}/clients/${traineeId}/attachments`}
        />
      </Form.Item>

      <Divider />

      {viewModel.noteType === ClientNoteType.Event ? (
        <>
          <Form.Item
            name="eventType"
            label={t("notes.eventType")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t("notes.noteEventDescription")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </>
      ) : (
        <Form.Item
          name="content"
          label={t("notes.content")}
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      )}
    </Form>
  );
};

export default ClientNoteForm;
