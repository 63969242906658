import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Dish } from "@fitness-app/data-models/entities/Dish";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  dish: Partial<Dish>;
  dishId: string;
};

export const updateDish = createAsyncThunk<Dish | null, Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/updateDish`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("dish")
      .update({ ...payload.dish, updatedAt: new Date().toISOString() })
      .eq("id", payload.dishId)
      .select()
      .single<Dish>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_dish");

    return data;
  },
);
