import {
  type CreatorMonthStats,
  type CreatorStats,
  type ProductMonthStats,
  type ProductMonthStatsWithProductData,
  type ProductStats,
} from "@fitness-app/data-models/entities/Reports";

import { type RequestStatus } from "../../../enums/requestStatus";

export const STATISTICS_REDUCER_NAME = "statistics";

export interface StatisticsReducer {
  currentMonthStats: CreatorMonthStats | null;
  currentMonthStatsStatus: null | RequestStatus;
  creatorStats: CreatorStats | null;
  creatorStatsStatus: null | RequestStatus;
  productsMonthStats: ProductMonthStatsWithProductData[];
  productsMonthStatsStatus: null | RequestStatus;
  selectedProductStats: ProductStats | null;
  selectedProductMonthStats: ProductMonthStats | null;
  selectedProductStatsStatus: null | RequestStatus;
  selectedProductMonthStatsStatus: null | RequestStatus;
}
