import React, { useEffect, useState } from "react";
import { ClockCircleOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Popconfirm, Spin, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";

import { RequestStatus, traineeSurveysActions } from "@fitness-app/app-store";
import {
  CycleSurveyType,
  RecurringSurveyStatus,
  SurveyStatus,
  SurveyType,
  type ClientSurvey,
  type OneTimeSurvey,
  type RecurringSurvey,
} from "@fitness-app/data-models/entities/ClientSurvey";
import { type SurveyTemplate } from "@fitness-app/data-models/entities/SurveyTemplate";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import ModalForm from "~/components/ModalForm/ModalForm";
import AddSurveyForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/AddSurveyForm/AddSurveyForm";
import { useGenerateBaseClientSurvey } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/AddSurveyForm/generateBaseClientSurvey";
import { type AddSurveyFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/AddSurveyForm/types";
import { surveyStatusMapper } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/statusMapper";
import TraineeSurveyDetails from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/TraineeSurveyDetails";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

dayjs.extend(timezone);

const TraineeSurveys = () => {
  const { t } = useTranslation(["surveys", "products"]);
  const timezone = dayjs.tz.guess() || "Europe/Warsaw";

  const dispatch = useAppDispatch();
  const { profile: clientData } = useAppSelector((store) => store.trainee, shallowEqual);
  const { surveys, surveysStatus } = useAppSelector((store) => store.traineeSurveys);
  const [showSurveyForm, toggleSurveyForm] = useState(false);
  const [selectedSurvey, setSurveyDetails] = useState<null | ClientSurvey>(null);
  const [processingSurveyId, setProcessingSurveyId] = useState<null | string>(null);
  const { generateBaseClientSurvey } = useGenerateBaseClientSurvey();

  const traineeId = clientData?.id;

  useEffect(() => {
    if (traineeId) {
      void dispatch(traineeSurveysActions.fetchTraineeSurveys({ traineeId }));
    }
  }, [traineeId]);

  if (!clientData) {
    return null;
  }

  const status = clientData.status;

  const removeSurvey = async (surveyId: string) => {
    setProcessingSurveyId(surveyId);
    await dispatch(traineeSurveysActions.deleteTraineeSurvey({ traineeId: clientData.id, id: surveyId }));
    setProcessingSurveyId(null);
  };

  const renderSurveyTypeCell = (type: SurveyType, row: ClientSurvey) => {
    const label = t(`surveyType.${type || SurveyType.OneTime}`);

    if (type === SurveyType.Recurring && row.interval?.status === "active" && row.status === SurveyStatus.Scheduled) {
      const { unit, count, cycle } = row.interval;
      return (
        <span>
          {label}{" "}
          <Tooltip
            title={`${t("table.activeCycle")} ${t(`table.${unit}`, {
              count,
            })} ${t(`table.${cycle.type}`, {
              date: "date" in cycle ? cycle.date : "",
              numberOfCycles: "numberOfCycles" in cycle ? cycle.numberOfCycles : "",
              cycleNumber:
                row.interval.cycle.type === CycleSurveyType.CycleNumber ? row.interval.cycle.numberOfCycles : "",
            })}`}
          >
            <RedoOutlined />
          </Tooltip>
        </span>
      );
    }
    return label;
  };

  const handleFormSubmit = async (formModel: AddSurveyFormModel, template: SurveyTemplate) => {
    setProcessingSurveyId("any");

    let surveyToAdd: ClientSurvey | null = null;

    if (formModel.type === SurveyType.OneTime) {
      const clientOneTimeSurvey: OneTimeSurvey = {
        ...generateBaseClientSurvey(template),
        variables: formModel.variables || null,
        title: formModel.title,
        type: SurveyType.OneTime,
        editable: formModel.editable,
        interval: null,
        archived: false,
        date: formModel.sentDate ? formModel.sentDate.toISOString() : null,
        sentAt: null,
        status: formModel.sentDate ? SurveyStatus.Scheduled : SurveyStatus.New,
        cycleNumber: null,
      };

      surveyToAdd = clientOneTimeSurvey;
    } else {
      if (formModel.cycleType === CycleSurveyType.Infinite) {
        const infiniteCycle: RecurringSurvey = {
          ...generateBaseClientSurvey(template),
          variables: formModel.variables || null,
          title: formModel.title,
          type: SurveyType.Recurring,
          editable: formModel.editable,
          interval: {
            dayOfDelivery: formModel.dayOfDelivery || "monday",
            status: RecurringSurveyStatus.New,
            count: formModel.count || 2,
            unit: formModel.unit,
            timeOfDelivery: formModel.timeOfDelivery
              ? [
                  formModel.timeOfDelivery.get("hour"),
                  formModel.timeOfDelivery.get("minutes"),
                  formModel.timeOfDelivery.utcOffset(),
                  timezone,
                ]
              : [0, 0, 0],
            cycle: {
              type: CycleSurveyType.Infinite,
            },
          },
          archived: false,
          date: null,
          sentAt: null,
          status: SurveyStatus.New,
          cycleNumber: 1,
        };

        surveyToAdd = infiniteCycle;
      }

      if (formModel.cycleType === CycleSurveyType.CycleNumber) {
        const infiniteCycle: RecurringSurvey = {
          ...generateBaseClientSurvey(template),
          variables: formModel.variables || null,
          title: formModel.title,
          type: SurveyType.Recurring,
          editable: formModel.editable,
          interval: {
            dayOfDelivery: formModel.dayOfDelivery || "monday",
            status: RecurringSurveyStatus.New,
            count: formModel.count || 2,
            unit: formModel.unit,
            timeOfDelivery: formModel.timeOfDelivery
              ? [
                  formModel.timeOfDelivery.get("hour"),
                  formModel.timeOfDelivery.get("minutes"),
                  formModel.timeOfDelivery.utcOffset(),
                  timezone,
                ]
              : [0, 0, 0],
            cycle: {
              type: CycleSurveyType.CycleNumber,
              numberOfCycles: formModel.cycleTypeNumber || 1,
            },
          },
          archived: false,
          date: null,
          sentAt: null,
          status: SurveyStatus.New,
          cycleNumber: 1,
        };

        surveyToAdd = infiniteCycle;
      }

      if (formModel.cycleType === CycleSurveyType.UntilDate) {
        const infiniteCycle: RecurringSurvey = {
          ...generateBaseClientSurvey(template),
          variables: formModel.variables || null,
          title: formModel.title,
          type: SurveyType.Recurring,
          editable: formModel.editable,
          interval: {
            dayOfDelivery: formModel.dayOfDelivery || "monday",
            status: RecurringSurveyStatus.New,
            count: formModel.count || 2,
            unit: formModel.unit,
            timeOfDelivery: formModel.timeOfDelivery
              ? [
                  formModel.timeOfDelivery.get("hour"),
                  formModel.timeOfDelivery.get("minutes"),
                  formModel.timeOfDelivery.utcOffset(),
                  timezone,
                ]
              : [0, 0, 0],
            cycle: {
              type: CycleSurveyType.UntilDate,
              date: formModel.untilDate ? formModel.untilDate.toISOString() : dayjs().add(1, "month").toISOString(),
            },
          },
          archived: false,
          date: null,
          sentAt: null,
          status: SurveyStatus.New,
          cycleNumber: 1,
        };

        surveyToAdd = infiniteCycle;
      }
    }

    if (surveyToAdd) {
      await dispatch(traineeSurveysActions.addTraineeSurvey({ survey: surveyToAdd }));
      setProcessingSurveyId(null);
      toggleSurveyForm(false);
    }
  };

  return (
    <Card
      bordered={false}
      className="!shadow-none"
      extra={
        status === TraineeStatus.ACTIVE && (
          <Button icon={<PlusOutlined />} onClick={() => toggleSurveyForm(true)} type="primary">
            {t("table.addToClient")}
          </Button>
        )
      }
    >
      <Table
        dataSource={surveys}
        locale={{
          emptyText: t("emptyState"),
        }}
        loading={surveysStatus === RequestStatus.FETCHING}
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
      >
        <Table.Column title={t("table.title.name")} dataIndex="title" key="title" />
        <Table.Column<ClientSurvey>
          title={t("table.title.sentDate")}
          dataIndex="sentAt"
          key="sentAt"
          render={(value, row) =>
            value || row.date ? (
              <span>
                {dayjs(value || row.date).format("DD.MM.YYYY HH:mm")}{" "}
                {row.status === SurveyStatus.Scheduled && (
                  <Tooltip title={t("table.title.plannedDate")}>
                    <ClockCircleOutlined />
                  </Tooltip>
                )}{" "}
              </span>
            ) : (
              t("inProcessing")
            )
          }
        />
        <Table.Column
          title={t("table.title.answerDate")}
          dataIndex="answeredAt"
          key="answeredAt"
          render={(value) => (value ? dayjs(value).format("DD.MM.YYYY HH:mm") : "-")}
        />
        <Table.Column title={t("table.title.type")} dataIndex="type" key="type" render={renderSurveyTypeCell} />
        <Table.Column
          title={t("table.title.status")}
          dataIndex="status"
          key="status"
          render={(status: SurveyStatus) => (
            <Badge status={surveyStatusMapper[status].badge} text={t(surveyStatusMapper[status].label)} />
          )}
        />
        <Table.Column<ClientSurvey>
          title="Opcje"
          key="action"
          render={(text, record) => (
            <>
              <a role="button" onClick={() => setSurveyDetails(record)} data-ctx="details" style={{ marginRight: 10 }}>
                {t("common:button.details")}
              </a>
              {!record.notRemovable &&
              record.type !== SurveyType.Initial &&
              ![SurveyStatus.Fulfilled, SurveyStatus.PartiallyFulfilled].includes(record.status) ? (
                <Popconfirm
                  title={t("table.deleteConfirm")}
                  okText={t("common:yes")}
                  cancelText={t("common:yes")}
                  placement="left"
                  onConfirm={() => removeSurvey(record.id)}
                >
                  {record.id === processingSurveyId ? (
                    <Spin size="small" />
                  ) : (
                    <a role="button" data-ctx="remove-supplement">
                      {t("common:button.delete")}
                    </a>
                  )}
                </Popconfirm>
              ) : null}
            </>
          )}
        />
      </Table>
      <ModalForm
        open={showSurveyForm}
        onCancel={() => toggleSurveyForm(false)}
        title={t("addSurvey")}
        loading={!!processingSurveyId}
      >
        <AddSurveyForm onSubmit={handleFormSubmit} />
      </ModalForm>
      <TraineeSurveyDetails survey={selectedSurvey} onCancel={() => setSurveyDetails(null)} />
    </Card>
  );
};

export default TraineeSurveys;
