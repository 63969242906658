import React, { useMemo } from "react";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import { traineeActivitiesActions, traineeProgramActions } from "@fitness-app/app-store";
import { ProgramService } from "@fitness-app/data-models/domain/services/ProgramService";
import { type ExceptionWeek } from "@fitness-app/data-models/entities/TrainingProgram";

import ModalForm from "~/components/ModalForm/ModalForm";
import CustomWeekTrainingSchedule from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/EditTraineeWeekSchedule/CustomWeekTrainingSchedule";
import { type CustomWeekTrainingScheduleModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/EditTraineeWeekSchedule/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface EditTraineeWeekScheduleProps {
  currentDate: Date;
}

dayjs.extend(isoWeek);

const EditTraineeWeekSchedule = ({ currentDate }: EditTraineeWeekScheduleProps) => {
  const { modalEditWeekScheduleShown } = useAppSelector((state) => state.traineeActivities);
  const dispatch = useAppDispatch();
  const { programDetails, selectedProgram } = useAppSelector((state) => state.traineeProgram);

  const closeModal = () => {
    dispatch(traineeActivitiesActions.setModalEditWeekScheduleHidden());
  };

  const date = useMemo(() => dayjs(currentDate), [currentDate]);

  const updateProgramSchedule = async (newSchedule: CustomWeekTrainingScheduleModel) => {
    const exceptionWeeks: Record<string, ExceptionWeek> = {
      [`${date.get("year")}-${date.isoWeek()}`]: newSchedule.weeks[0],
    };
    await dispatch(traineeProgramActions.setProgramSchedule({ exceptionWeeks }));
    closeModal();
  };

  const model = useMemo(() => {
    const { week: selectedWeek } = ProgramService.getScheduleForWeek(selectedProgram, dayjs(currentDate));

    if (selectedWeek) {
      return {
        weeks: [selectedWeek],
      };
    }

    return null;
  }, [currentDate]);

  if (!programDetails || !selectedProgram) {
    return null;
  }

  return (
    <ModalForm
      title={`Edycja harmonogramu treningu (${date.startOf("week").format("DD-MM-YYYY")} - ${date
        .endOf("week")
        .format("DD-MM-YYYY")})`}
      open={modalEditWeekScheduleShown}
      onCancel={closeModal}
    >
      <CustomWeekTrainingSchedule
        onSubmit={updateProgramSchedule}
        programWorkouts={programDetails}
        model={model}
        selectedYear={currentDate.getFullYear()}
        selectedWeekOfYear={dayjs(currentDate).isoWeek()}
      />
    </ModalForm>
  );
};

export default EditTraineeWeekSchedule;
