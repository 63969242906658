import React, { useEffect, type FunctionComponent } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type Nullable } from "@fitness-app/utils/src/types/utils";

import { type TPayIntegrationFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<TPayIntegrationFormModel>;
  onSubmit: (formData: TPayIntegrationFormModel) => void;
  model?: TPayIntegrationFormModel | null;
}

type Props = OwnProps;

const TPayIntegrationForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["settings", "common"]);

  useEffect(() => {
    if (!model) {
      formController?.resetFields();
    } else {
      formController?.setFieldsValue(model);
    }
  }, [formController, model]);

  const initialValues: Nullable<TPayIntegrationFormModel> = {
    name: "TPay",
    clientId: null,
    clientSecret: null,
    safetyKey: null,
    posId: null,
  };

  return (
    <div>
      <Form<TPayIntegrationFormModel>
        name="tpay-integration-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        initialValues={initialValues}
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="name"
          label={t<string>("settings:paymentGateway.tpay.accountName")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input disabled value={model?.name} />
        </Form.Item>
        <Form.Item
          name="posId"
          label={t<string>("settings:paymentGateway.tpay.posId")}
          tooltip={t<string>("settings:paymentGateway.tpay.posIdTooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus={!!model} />
        </Form.Item>

        <Form.Item
          name="clientId"
          label={t<string>("settings:paymentGateway.tpay.clientId")}
          tooltip={t<string>("settings:paymentGateway.tpay.clientIdTooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="clientSecret"
          tooltip={t<string>("settings:paymentGateway.tpay.clientSecretTooltip")}
          label={t<string>("settings:paymentGateway.tpay.clientSecret")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="safetyKey"
          tooltip={t<string>("settings:paymentGateway.tpay.safetyKeyTooltip")}
          label={t<string>("settings:paymentGateway.tpay.safetyKey")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
};

export default TPayIntegrationForm;
