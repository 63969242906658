import React, { useEffect, useRef } from "react";
import { Form, Input, Space, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { useWorkoutActionProviderContext } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/providers/WorkoutActionProvider";

export interface WorkoutDayNameProps {
  selectedWorkout: ProgramWorkout;
  programId: string;
}

export interface WorkoutMetadataForm {
  name: string;
  description: string;
  isRestDay: boolean;
}

const WorkoutDayMetadata = ({ selectedWorkout }: WorkoutDayNameProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const [formController] = Form.useForm<WorkoutMetadataForm>();
  const { updateWorkoutMetadata } = useWorkoutActionProviderContext();
  const name = Form.useWatch("name", formController);
  const description = Form.useWatch("description", formController);
  const isRestDay = Form.useWatch("isRestDay", formController);
  const [debouncedName] = useDebounce(name, 500) || "";
  const [debouncedDescValue] = useDebounce(description, 500) || "";

  const originalRestDay = selectedWorkout.isRestDay;

  const updateRef = useRef(updateWorkoutMetadata);

  useEffect(() => {
    updateRef.current = updateWorkoutMetadata;
  }, [updateWorkoutMetadata]);

  useEffect(() => {
    if (typeof isRestDay === "boolean" && isRestDay !== originalRestDay) {
      void updateRef.current({ isRestDay });
    }
  }, [originalRestDay, isRestDay]);

  const selectedWorkoutName = selectedWorkout.name;
  const selectedWorkoutDescription = selectedWorkout.description;

  useEffect(() => {
    const trimmedValue = debouncedName?.trim();
    if (trimmedValue && trimmedValue !== selectedWorkoutName) {
      void updateRef.current({ name: trimmedValue });
    }
  }, [debouncedName, selectedWorkoutName, t]);

  useEffect(() => {
    if (typeof debouncedDescValue === "string" && debouncedDescValue != selectedWorkoutDescription) {
      void updateRef.current({ description: debouncedDescValue });
    }
  }, [debouncedDescValue, selectedWorkoutDescription]);

  return (
    <Space direction="vertical" style={{ width: "100%", margin: "20px 0" }}>
      <Form<WorkoutMetadataForm>
        layout="inline"
        form={formController}
        className="gap-y-3"
        initialValues={{
          name: selectedWorkout.name,
          description: selectedWorkout.description,
          isRestDay: selectedWorkout.isRestDay ?? false,
        }}
      >
        <Form.Item
          label={t("workoutMetadata.title")}
          name="name"
          rules={[
            {
              min: 1,
              max: 300,
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("workoutMetadata.description")}
          name="description"
          rules={[
            {
              min: 0,
              max: 500,
              required: false,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input.TextArea style={{ width: 300 }} rows={1} />
        </Form.Item>

        <Form.Item label={t("workoutMetadata.isRestDay")} name="isRestDay" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Space>
  );
};

export default WorkoutDayMetadata;
