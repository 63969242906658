import { configureStore, type Action, type Middleware } from "@reduxjs/toolkit";
import { type RealtimeChannel, type SupabaseClient } from "@supabase/supabase-js";
import { type AxiosInstance } from "axios";
import { type Database as RealtimeDatabase } from "firebase/database";
import { MeiliSearch, type Index } from "meilisearch";
import type PartySocket from "partysocket";
import { createLogger } from "redux-logger";
import { type ThunkAction } from "redux-thunk";

import { type AnalyticsService } from "@fitness-app/data-models/domain/services/AnalyticsService";
import { type ErrorTrackingService } from "@fitness-app/data-models/domain/services/ErrorTrackingService";
import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { type Database } from "@fitness-app/supabase";

import { createRootReducer } from "./reducers";
import { type AutomationLogsReducer } from "./reducers/automationLogs/types";
import { type AutomationTemplatesReducer } from "./reducers/automationTemplates/types";
import { type BotsConfigReducer } from "./reducers/botsConfig/types";
import { type ChatReducer } from "./reducers/chat/types";
import { type ClientProductsReducer } from "./reducers/clientProducts/types";
import { type ClientScheduledTasksReducer } from "./reducers/clientScheduledTasks/types";
import { type ExercisesReducer } from "./reducers/exercises/types";
import { type LiveWorkoutReducer } from "./reducers/liveWorkout/types";
import { type MealsPlansReducer } from "./reducers/mealsPlans/types";
import { type MediaLibraryReducer } from "./reducers/mediaLibrary/types";
import { type NotificationsReducer } from "./reducers/notifications/types";
import { type NutritionReducer } from "./reducers/nutrition/types";
import { type ProductReducer } from "./reducers/product/types";
import { type ProductAutomationReducer } from "./reducers/productAutomation/types";
import { type ProductClientReducer } from "./reducers/productClient/types";
import { type ProductClientsReducer } from "./reducers/productClients/types";
import { type ProductOrdersReducer } from "./reducers/productOrders/types";
import { type ProductResourcesReducer } from "./reducers/productResources/types";
import { type ProductsReducer } from "./reducers/products/types";
import { type ProductsSettingsReducer } from "./reducers/productsSettings/types";
import { type ProgramAutomationReducer } from "./reducers/programAutomation/types";
import { type ProgramBuilderReducer } from "./reducers/programBuilder/types";
import { type ProgramsReducer } from "./reducers/programs/types";
import { type StatisticsReducer } from "./reducers/statistics/types";
import { type SupplementsTemplatesReducer } from "./reducers/supplementsTemplates/types";
import { type SurveysTemplatesReducer } from "./reducers/surveysTemplates/types";
import { type TeamReducer } from "./reducers/team/types";
import { type TraineeReducer } from "./reducers/trainee/types";
import { type TraineeActivitiesReducer } from "./reducers/traineeActivities/types";
import { type TraineeMealsPlansReducer } from "./reducers/traineeMealsPlans/types";
import { type TraineeMeasurementsReducer } from "./reducers/traineeMeasurements/types";
import { type TraineeNotesReducer } from "./reducers/traineeNotes/types";
import { type TraineeNutritionTrackerReducer } from "./reducers/traineeNutritionTracker/types";
import { type TraineeProgramReducer } from "./reducers/traineeProgram/types";
import { type TraineesReducer } from "./reducers/trainees/types";
import { type TraineeSurveysReducer } from "./reducers/traineeSurveys/types";
import { type TraineeWorkoutsStatsReducer } from "./reducers/traineeWorkoutsStats/types";
import { type UserReducer } from "./reducers/user/types";
import { type WorkoutTemplatesReducer } from "./reducers/workoutTemplates/types";

export interface AppStore {
  user: UserReducer;
  team: TeamReducer;
  products: ProductsReducer;
  productsSettings: ProductsSettingsReducer;
  productAutomation: ProductAutomationReducer;
  product: ProductReducer;
  productClients: ProductClientsReducer;
  productOrders: ProductOrdersReducer;
  productResources: ProductResourcesReducer;
  automationLogs: AutomationLogsReducer;
  productClient: ProductClientReducer;
  nutrition: NutritionReducer;
  mealsPlans: MealsPlansReducer;
  exercises: ExercisesReducer;
  programs: ProgramsReducer;
  programBuilder: ProgramBuilderReducer;
  workoutTemplates: WorkoutTemplatesReducer;
  surveysTemplates: SurveysTemplatesReducer;
  botsConfig: BotsConfigReducer;
  automationTemplates: AutomationTemplatesReducer;
  programAutomation: ProgramAutomationReducer;
  mediaLibrary: MediaLibraryReducer;
  notifications: NotificationsReducer;
  chat: ChatReducer;
  trainees: TraineesReducer;
  trainee: TraineeReducer;
  traineeNotes: TraineeNotesReducer;
  traineeMeasurements: TraineeMeasurementsReducer;
  traineeSurveys: TraineeSurveysReducer;
  traineeProgram: TraineeProgramReducer;
  clientTasks: ClientScheduledTasksReducer;
  traineeActivities: TraineeActivitiesReducer;
  traineeMealsPlan: TraineeMealsPlansReducer;
  clientProducts: ClientProductsReducer;
  supplementsTemplates: SupplementsTemplatesReducer;
  traineeNutritionTracker: TraineeNutritionTrackerReducer;
  traineeWorkoutsStats: TraineeWorkoutsStatsReducer;
  statistics: StatisticsReducer;
  liveWorkout: LiveWorkoutReducer;
}

export interface StoreDependencies {
  analytics: AnalyticsService;
  errorTrackingService?: ErrorTrackingService;
  db: SupabaseClient<Database>;
  auth: SupabaseClient<Database>["auth"];
  productApi: AxiosInstance;
  integrationApi: AxiosInstance;
  secretsApi: AxiosInstance;
  invoicesApi: AxiosInstance;
  mediaLibraryApi: AxiosInstance;
  trainersApi: AxiosInstance;
  automationApi: AxiosInstance;
  notificationsApi?: AxiosInstance;
  traineeApi?: AxiosInstance;
  chatApi?: AxiosInstance;
  nutritionApi?: AxiosInstance;
  nutritionPdfApi?: AxiosInstance;
  realtimeDatabase: RealtimeDatabase;
  searchIndex: MeiliSearch;
  ingredientsSearchIndex: Index<Ingredient>;
  dishesSearchIndex: Index<Dish>;
  appVersion: string;
  config: {
    searchIndexHost: string;
    searchIndexApiKey: string;
    realtimeServerUrl: string;
    dishesIndexName: string;
    ingredientsIndexName: string;
  };
}

type UserDetailsChannel = {
  userDetailsChannelName?: string | null;
  realtimeUserDetailsChannel?: RealtimeChannel;
  notificationsChannelName?: string | null;
  realtimeNotificationsChannel?: RealtimeChannel;
};

type TeamListChannel = {
  teamListChannelName?: string | null;
  realtimeTeamListChannel?: RealtimeChannel | null;
  teamMemberListChannelName?: string | null;
  realtimeTeamMemberListChannel?: RealtimeChannel | null;
};

type ProductsListChannel = {
  productsListChannelName?: string | null;
  realtimeProductsListChannel?: RealtimeChannel | null;
  productListChannelName?: string | null;
  realtimeProductListChannel?: RealtimeChannel | null;
  realtimeProductAutomationChannel?: RealtimeChannel | null;
  productAutomationChannelName?: string | null;
  realtimeProductClientChannel?: RealtimeChannel | null;
  productClientChannelName?: string | null;
};

type ProductsSettingsListChannel = {
  productsSettingsListChannelName?: string | null;
  realtimeProductsSettingsListChannel?: RealtimeChannel | null;
};

type MediaLibraryListChannel = {
  mediaLibraryListChannelName?: string | null;
  realtimeMediaLibraryListChannel?: RealtimeChannel | null;
};

type TraineeChannel = {
  traineeChannelName?: string | null;
  realtimeTraineeChannel?: RealtimeChannel | null;
};

type ChatListener = {
  channels: Record<
    string,
    {
      realtimeChannelName: string | null;
      realtimeChannel: RealtimeChannel | null;
    }
  >;
  unreadMessagesRealtimeChannel?: RealtimeChannel | null;
  unreadMessagesChannelName?: string | null;
  latestChatRealtimeChannel?: RealtimeChannel | null;
  latestChatChannelName?: string | null;
};

export type Parties = {
  chatRooms: Record<string, PartySocket | null>;
  chatRoomsNative: Record<string, WebSocket | null | undefined>;
};

type Subscriptions = UserDetailsChannel &
  TeamListChannel &
  ProductsListChannel &
  ProductsSettingsListChannel &
  MediaLibraryListChannel &
  TraineeChannel &
  ChatListener;
export interface StoreUtils {
  subscriptions: Subscriptions;
  parties: Parties;
}

export type ActionParams<T = undefined, R = undefined> = {
  payload: T;

  onSuccess?: (data?: R) => void;

  onFailure?: (errorCode?: number) => void;
};

export type AsyncThunkCreator<T = null, _PendingMeta = Record<string, never>, RejectedMeta = unknown> = {
  extra: StoreDependencies & StoreUtils;
  state: AppStore;
  rejectValue: T;
  rejectedMeta: RejectedMeta;
};

export type AppThunk<T = void> = ThunkAction<T, AppStore, StoreDependencies & StoreUtils, Action<string>>;

export const createStore = (
  storeDependencies: Omit<StoreDependencies, "searchIndex" | "ingredientsSearchIndex" | "dishesSearchIndex">,
) => {
  const middleware: Middleware[] = [];
  const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
  if (IS_DEVELOPMENT) {
    middleware.push(createLogger({ collapsed: true }));
  }

  const client = new MeiliSearch({
    host: storeDependencies.config.searchIndexHost,
    apiKey: storeDependencies.config.searchIndexApiKey,
  });

  const store = configureStore({
    reducer: createRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: {
            ...storeDependencies,
            subscriptions: {
              channels: {},
            },
            parties: {
              chatRooms: {},
              chatRoomsNative: {},
            },
            ingredientsSearchIndex: client.index(storeDependencies.config.ingredientsIndexName),
            dishesSearchIndex: client.index(storeDependencies.config.dishesIndexName),
            searchIndex: client,
          },
        },
      }).concat(middleware),
  });

  return { store };
};
