import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_SCHEDULED_TASKS_REDUCER_NAME } from "../types";

type Payload = string;

export const removeClientTask = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${CLIENT_SCHEDULED_TASKS_REDUCER_NAME}/removeClientTask`,
  async (payload, { extra: { db, analytics } }) => {
    await db.from("program_automation_task").delete().eq("id", payload);

    analytics.track("remove_client_task");
  },
);
