import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { PROGRAMS_REDUCER_NAME } from "../types";

type Payload = {
  program: Partial<TrainingProgram>;
  programId: string;
};

export const updateProgram = createAsyncThunk<TrainingProgram | null, Payload, AsyncThunkCreator<string>>(
  `${PROGRAMS_REDUCER_NAME}/updateProgram`,
  async ({ program, programId }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("training_program")
      .update(program)
      .eq("id", programId)
      .select()
      .returns<TrainingProgram[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_workout_program");

    return data[0] || null;
  },
);
