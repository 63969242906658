import { type WorkoutTemplateWithCreator } from "@fitness-app/data-models/entities/WorkoutTemplate";

import { type RequestStatus } from "../../../enums/requestStatus";

export const WORKOUT_TEMPLATES_REDUCER_NAME = "workoutTemplates";

export interface WorkoutTemplatesReducer {
  list: WorkoutTemplateWithCreator[];
  listStatus: null | RequestStatus;
  tags: string[];
  creators: { label: string; value: string }[];
}
