import { SurveyStatus } from "@fitness-app/data-models/entities/ClientSurvey";

export const surveyStatusMapper = {
  [SurveyStatus.New]: {
    badge: "warning",
    label: "surveyStatus.new",
  },
  [SurveyStatus.Sent]: {
    badge: "processing",
    label: "surveyStatus.sent",
  },
  [SurveyStatus.Open]: {
    badge: "processing",
    label: "surveyStatus.open",
  },
  [SurveyStatus.Scheduled]: {
    badge: "processing",
    label: "surveyStatus.scheduled",
  },
  [SurveyStatus.PartiallyFulfilled]: {
    badge: "warning",
    label: "surveyStatus.partiallyFulfilled",
  },
  [SurveyStatus.Archived]: {
    badge: "warning",
    label: "surveyStatus.archived",
  },
  [SurveyStatus.Fulfilled]: {
    badge: "success",
    label: "surveyStatus.fulfilled",
  },
} as const;
