import { createAsyncThunk } from "@reduxjs/toolkit";
import { type EmailsAutomation } from "@fitness-app/data-models/entities/Automation";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_AUTOMATION_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  emails: EmailsAutomation;
};

export const updateProductAutomationEmails = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<unknown>>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationEmails`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error("product automation data not exists");
      }

      await db
        .from("product_automation")
        .update({
          emails: payload.emails,
          updatedAt: new Date().toISOString(),
        })
        .eq("productId", payload.productId);

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  },
);
