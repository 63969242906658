import React, { useCallback, useEffect, useRef, useState } from "react";
import { Skeleton, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import TabsContainer from "~/components/TabsContainer/TabsContainer";
import TraineeCalendar from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/TraineeCalendar";
import TraineeMeasurements from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeMeasurements";
import TraineeNotes from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/TraineeNotes";
import TraineeNutrition from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutrition";
import TraineeProducts from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeProducts";
import TraineeResults from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/TraineeResults";
import TraineeSettings from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSettings/TraineeSettings";
import TraineeSurveys from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/TraineeSurveys";
import TraineeTrainingProgram from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeTrainingProgram";
import { useAppSelector } from "~/store/initializeStore";

const features = [
  {
    label: "results",
    key: "results",
    Component: TraineeResults,
  },
  {
    label: "programs",
    key: "programs",
    Component: TraineeTrainingProgram,
  },
  {
    label: "calendar",
    key: "calendar",
    Component: TraineeCalendar,
  },
  {
    label: "nutritionPlan",
    key: "nutrition",
    Component: TraineeNutrition,
  },
  {
    label: "measurement",
    key: "measurements",
    Component: TraineeMeasurements,
  },
  {
    label: "surveys",
    key: "surveys",
    Component: TraineeSurveys,
  },
  {
    label: "notes",
    key: "notes",
    Component: TraineeNotes,
  },
  {
    label: "products",
    key: "products",
    Component: TraineeProducts,
  },
  {
    label: "settings",
    key: "settings",
    Component: TraineeSettings,
  },
];

const TraineeFeatures = () => {
  const profile = useAppSelector((store) => store.trainee.profile);
  const { t } = useTranslation("trainees");
  const [search, setSearchParams] = useSearchParams();
  const defaultActiveTab = search.get("tab") || features[0]?.key;
  const prevParamTab = useRef<string | null>(null);
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  useEffect(() => {
    if (defaultActiveTab !== prevParamTab.current && defaultActiveTab) {
      setActiveTab(defaultActiveTab);
    }
    prevParamTab.current = defaultActiveTab || null;
  }, [defaultActiveTab]);

  const handleTabChange = useCallback((key: string) => {
    setActiveTab(key);
    setSearchParams({ tab: key });
  }, []);

  if (!profile) {
    return <Skeleton paragraph={{ rows: 5 }} />;
  }

  return (
    <TabsContainer>
      <Tabs
        destroyInactiveTabPane
        tabPosition="top"
        className="min-h-[800px]"
        type="card"
        activeKey={activeTab}
        onChange={handleTabChange}
      >
        {features.map((Feature) => (
          <Tabs.TabPane tab={t(`features.${Feature.label}`)} key={Feature.key}>
            <Feature.Component />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </TabsContainer>
  );
};

export default TraineeFeatures;
