import { createAsyncThunk } from "@reduxjs/toolkit";

import { type SurveyTemplate, type SurveyTemplateWithCreator } from "@fitness-app/data-models/entities/SurveyTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { SURVEYS_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = {
  surveyTemplate: Partial<(SurveyTemplate & { creator?: never }) | SurveyTemplateWithCreator>;
  surveyTemplateId: string;
  optimisticUpdate?: boolean;
};

export const editSurveyTemplate = createAsyncThunk<SurveyTemplate, Payload, AsyncThunkCreator<string>>(
  `${SURVEYS_TEMPLATES_REDUCER_NAME}/editSurveyTemplate`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { creator, ...rest } = payload.surveyTemplate;
    const { error, data } = await db
      .from("survey_template")
      .update({ ...rest, updatedAt: new Date().toISOString() })
      .eq("id", payload.surveyTemplateId)
      .select()
      .single<SurveyTemplate>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("edit_survey_template");

    return data;
  },
);
