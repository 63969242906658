import React from "react";
import { Progress } from "antd";

interface MacroTrackerProps {
  color: string;
  percentValue: number;
  title: string;
  nutritionValue: number;
  dailyNeeds: number;
  unit: string;
  className?: string;
}

const MacroTracker: React.FC<MacroTrackerProps> = ({
  color,
  percentValue,
  title,
  nutritionValue,
  dailyNeeds,
  unit,
  className,
}) => (
  <div className={`flex flex-col ${className}`}>
    <div className="flex justify-between">
      <div>{title}</div>
      <div>
        {nutritionValue}/{dailyNeeds}
        {unit}
      </div>
    </div>
    <Progress type="line" percent={percentValue} strokeColor={color} showInfo={false} />
  </div>
);

export default MacroTracker;
