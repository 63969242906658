import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientMealsPlanDetails } from "@fitness-app/data-models/entities/ClientNutrition";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  planId: string;
};

export const fetchMealsPlanDetails = createAsyncThunk<
  ClientMealsPlanDetails | null,
  Payload,
  AsyncThunkCreator<string>
>(`${MEALS_PLANS_REDUCER_NAME}/fetchMealsPlanDetails`, async (payload, { rejectWithValue, extra: { db } }) => {
  const query = db.from("client_meals_plan_details").select("*").eq("planId", payload.planId);

  const { error, data } = await query.single<ClientMealsPlanDetails>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return data;
});
