import { createAsyncThunk } from "@reduxjs/toolkit";

import { SurveyStatus, type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";
import { markSurveyAsOpened } from "./markSurveyAsOpened";

type Payload = {
  traineeId: string;
  id: string;
  isClient?: boolean;
};

export const fetchTraineeSurvey = createAsyncThunk<ClientSurvey | null, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/fetchTraineeSurvey`,
  async (payload, { rejectWithValue, dispatch, extra: { db } }) => {
    const { error, data } = await db
      .from("client_survey")
      .select("*")
      .eq("traineeId", payload.traineeId)
      .eq("id", payload.id)
      .maybeSingle<ClientSurvey>();

    if (error) {
      return rejectWithValue(error.message);
    }

    if (payload.isClient && data?.status === SurveyStatus.Sent) {
      void dispatch(markSurveyAsOpened({ survey: data }));
    }

    return data;
  },
);
