import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Dish } from "@fitness-app/data-models/entities/Dish";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
};

export const fetchDishes = createAsyncThunk<
  { data: Dish[]; page: number; totalPages: number },
  Payload | void,
  AsyncThunkCreator<string>
>(`${NUTRITION_REDUCER_NAME}/fetchDishes`, async (payload, { rejectWithValue, getState, extra: { db } }) => {
  const { dishesPerPage, dishFilters } = getState().nutrition;

  const currentPage = payload?.page ?? 1;

  const start = (currentPage - 1) * dishesPerPage;
  const stop = currentPage * dishesPerPage - 1;

  let query = db.from("dish").select("*", { count: "exact" });

  if (dishFilters.dishTags.length) {
    query = query.in("tags", dishFilters.dishTags);
  }
  if (dishFilters.dishTypes.length) {
    query = query.in("dishTypes", dishFilters.dishTypes);
  }
  if (dishFilters.mealTypes.length) {
    query = query.in("mealTypes", dishFilters.mealTypes);
  }

  const { error, data, count } = await query
    .range(start, stop)
    .order("createdAt", { ascending: false })
    .returns<Dish[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return { data, page: currentPage, totalPages: count ? Math.ceil(count / dishesPerPage) : 0 };
});
