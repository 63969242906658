import React, { useState, type FunctionComponent } from "react";
import { InfoCircleOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Empty, message, Popconfirm, Popover, Space, Switch, Table, Tooltip } from "antd";
import camelCase from "lodash.camelcase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { RequestStatus, type AppStore, type ProductWithStats } from "@fitness-app/app-store";
import { productActions } from "@fitness-app/app-store/src/store/reducers/product";
import { ProductChargeType, ProductStatus, type Product } from "@fitness-app/data-models/entities/Product";
import { getProductLink } from "@fitness-app/utils/src/products/getProductLink";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useProductSubmit } from "~/modules/Products/hooks/useProductSubmit";
import ProductPrices from "~/modules/Products/Product/ProductPrices/ProductPrices";
import { type ProductFormModel } from "~/modules/Products/ProductForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import ProductForm from "../ProductForm/ProductForm";

const ProductsList: FunctionComponent = () => {
  const { t } = useTranslation(["products", "common", "subscription"]);
  const [showProductForm, toggleProductForm] = useState(false);
  const { addingNewProduct, productsListStatus, productsListData } = useAppSelector((store) => store.products);
  const { productId, productDetailsStatus } = useAppSelector((store: AppStore) => store.product);
  const dispatch = useAppDispatch();

  const handleArchiveProduct = async (productId: string) => {
    void message.loading(t("messages.loading.archiveProduct"));

    const onSuccess = () => {
      void message.success(t("messages.success.archiveProduct"));
    };
    const onFailure = () => {
      void message.success(t("messages.error.archiveProduct"));
    };
    await dispatch(
      productActions.archiveProduct({
        payload: { id: productId },
        onFailure,
        onSuccess,
      }),
    );
  };

  const handleRemoveProduct = async (productId: string) => {
    void message.loading({
      content: t("messages.loading.removeProduct"),
      duration: 0,
      key: "removeProduct",
    });

    const onSuccess = () => {
      message.destroy("removeProduct");
      void message.success(t("messages.success.removeProduct"));
    };
    const onFailure = () => {
      message.destroy("removeProduct");
      void message.error(t("messages.error.removeProduct"));
    };
    await dispatch(
      productActions.removeProduct({
        payload: { id: productId },
        onFailure,
        onSuccess,
      }),
    );
  };

  const { handleSubmit: onAddNewProduct } = useProductSubmit(() => {
    toggleProductForm(false);
  });

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={24}>
      <Card
        bordered={false}
        loading={productsListStatus === RequestStatus.SUBSCRIBING}
        extra={
          productsListData.length ? (
            <Tooltip title={null}>
              <Button type="primary" onClick={() => toggleProductForm(true)} icon={<PlusOutlined />}>
                {t<string>("products:button.addProduct")}
              </Button>
            </Tooltip>
          ) : null
        }
      >
        <Table
          loading={productsListStatus === RequestStatus.SUBSCRIBING}
          dataSource={productsListData}
          scroll={{ x: true }}
          expandable={{
            expandedRowRender: (rowData) => (
              <ProductPrices
                prices={rowData.prices}
                productId={rowData.id}
                productName={rowData.name}
                productSlug={rowData.slug || ""}
              />
            ),
            rowExpandable: (row) => row.chargeType !== ProductChargeType.FREE && row.status === ProductStatus.ACTIVE,
          }}
          rowKey="id"
          pagination={{
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t<string>("common:emptyList")}>
                <Tooltip title={null}>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => toggleProductForm(true)}
                    type="primary"
                    className="mt-2"
                  >
                    {t("addNewProduct")}
                  </Button>
                </Tooltip>
              </Empty>
            ),
          }}
        >
          <Table.Column<ProductWithStats>
            title={t("list.columns.name")}
            dataIndex="name"
            key="name"
            render={(name, row) => (
              <span className="flex items-center">
                {row.images?.length ? <img alt="product" src={row.images[0]} className="h-auto max-w-[50px]" /> : null}
                <h4 className="ml-6 mt-2">{name}</h4>
              </span>
            )}
          />
          <Table.Column<ProductWithStats>
            title={t("list.columns.description")}
            dataIndex="description"
            key="description"
            render={(rowText: string) =>
              rowText ? (
                <Popover content={rowText} overlayStyle={{ maxWidth: 300 }}>
                  <InfoCircleOutlined style={{ fontSize: 20 }} />
                </Popover>
              ) : null
            }
          />
          <Table.Column<ProductWithStats>
            title={t("list.columns.paymentType")}
            dataIndex="chargeType"
            key="chargeType"
            render={(_, row) => t(`chargeType.${row.chargeType === ProductChargeType.FREE ? "free" : "paid"}`)}
          />
          <Table.Column<ProductWithStats>
            title={t("list.columns.type")}
            dataIndex="type"
            key="type"
            /* eslint-disable-next-line */
            render={(type) => t(`productType.${camelCase(type)}`)}
          />
          <Table.Column<ProductWithStats>
            title={t("list.columns.totalClients")}
            dataIndex="totalClients"
            key="totalClients"
            render={(_, row) => row.product_stats?.totalClients ?? 0}
          />
          <Table.Column<ProductWithStats>
            title={t("list.columns.link")}
            dataIndex="link"
            key="link"
            render={(_, row) => (
              <Tooltip title={t("list.copyToClipboard")}>
                <LinkOutlined
                  style={{ fontSize: 22 }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      getProductLink({
                        productId: row.id,
                        domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
                        slug: row.slug || "",
                      }),
                    )
                  }
                />
              </Tooltip>
            )}
          />
          <Table.Column<Product>
            title={t("list.columns.active")}
            dataIndex="active"
            key="active"
            render={(active: boolean, row) => (
              <Switch
                checked={row.status === ProductStatus.ACTIVE}
                onChange={() =>
                  dispatch(
                    productActions.updateProduct({
                      payload: {
                        id: row.id,
                        status: row.status === ProductStatus.ACTIVE ? ProductStatus.INACTIVE : ProductStatus.ACTIVE,
                        description: row.description ?? "",
                        images: row.images,
                        name: row.name,
                      },
                    }),
                  )
                }
                loading={productDetailsStatus === RequestStatus.UPDATING && productId === row.id}
              />
            )}
          />
          <Table.Column<ProductWithStats>
            title={t("list.columns.options")}
            key="action"
            align="left"
            render={({ id }, row) => (
              <Space>
                <Link to={`/products/${id}`}>{t("list.columns.details")}</Link>
                {row.product_stats?.totalClients ? (
                  <Popconfirm
                    overlayStyle={{ maxWidth: 400 }}
                    placement="leftTop"
                    title={t("mailing.archiveWarning")}
                    onConfirm={() => handleArchiveProduct(row.id)}
                  >
                    <Button type="link" danger>
                      {t("common:button.archive")}
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    overlayStyle={{ maxWidth: 400 }}
                    placement="leftTop"
                    title={t("mailing.deleteWarning")}
                    onConfirm={() => handleRemoveProduct(row.id)}
                  >
                    <Button type="link" danger>
                      {t("common:button.delete")}
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
        <ModalForm<ProductFormModel>
          visible={showProductForm}
          onCancel={() => toggleProductForm(false)}
          title={t<string>("products:addNewProduct")}
          loading={addingNewProduct}
          width={900}
        >
          <ProductForm onSubmit={onAddNewProduct} />
        </ModalForm>
      </Card>
    </Space>
  );
};

export default ProductsList;
