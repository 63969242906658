export const COUNTRIES = [
  {
    name: "Andorra",
    dialCode: "+376",
    isoCode: "AD",
  },
  {
    name: "Argentina",
    dialCode: "+54",
    isoCode: "AR",
  },
  {
    name: "Armenia",
    dialCode: "+374",
    isoCode: "AM",
  },
  {
    name: "Australia",
    dialCode: "+61",
    isoCode: "AU",
  },
  {
    name: "Austria",
    dialCode: "+43",
    isoCode: "AT",
  },
  {
    name: "Bangladesh",
    dialCode: "+880",
    isoCode: "BD",
  },
  {
    name: "Belarus",
    dialCode: "+375",
    isoCode: "BY",
  },
  {
    name: "Belgium",
    dialCode: "+32",
    isoCode: "BE",
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    isoCode: "BA",
  },
  {
    name: "Brazil",
    dialCode: "+55",
    isoCode: "BR",
  },
  {
    name: "Bulgaria",
    dialCode: "+359",
    isoCode: "BG",
  },
  {
    name: "Canada",
    dialCode: "+1",
    isoCode: "CA",
  },
  {
    name: "China",
    dialCode: "+86",
    isoCode: "CN",
  },
  {
    name: "Croatia",
    dialCode: "+385",
    isoCode: "HR",
  },
  {
    name: "Czech Republic",
    dialCode: "+420",
    isoCode: "CZ",
  },
  {
    name: "Denmark",
    dialCode: "+45",
    isoCode: "DK",
  },
  {
    name: "Estonia",
    dialCode: "+372",
    isoCode: "EE",
  },
  {
    name: "Finland",
    dialCode: "+358",
    isoCode: "FI",
  },
  {
    name: "France",
    dialCode: "+33",
    isoCode: "FR",
  },
  {
    name: "Georgia",
    dialCode: "+995",
    isoCode: "GE",
  },
  {
    name: "Germany",
    dialCode: "+49",
    isoCode: "DE",
  },
  {
    name: "Greece",
    dialCode: "+30",
    isoCode: "GR",
  },
  {
    name: "Hong Kong",
    dialCode: "+852",
    isoCode: "HK",
  },
  {
    name: "Hungary",
    dialCode: "+36",
    isoCode: "HU",
  },
  {
    name: "Iceland",
    dialCode: "+354",
    isoCode: "IS",
  },
  {
    name: "India",
    dialCode: "+91",
    isoCode: "IN",
  },
  {
    name: "Ireland",
    dialCode: "+353",
    isoCode: "IE",
  },
  {
    name: "Israel",
    dialCode: "+972",
    isoCode: "IL",
  },
  {
    name: "Italy",
    dialCode: "+39",
    isoCode: "IT",
  },
  {
    name: "Japan",
    dialCode: "+81",
    isoCode: "JP",
  },
  {
    name: "Kazakhstan",
    dialCode: "+77",
    isoCode: "KZ",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    isoCode: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    isoCode: "KR",
  },
  {
    name: "Latvia",
    dialCode: "+371",
    isoCode: "LV",
  },
  {
    name: "Liechtenstein",
    dialCode: "+423",
    isoCode: "LI",
  },
  {
    name: "Lithuania",
    dialCode: "+370",
    isoCode: "LT",
  },
  {
    name: "Luxembourg",
    dialCode: "+352",
    isoCode: "LU",
  },
  {
    name: "Malta",
    dialCode: "+356",
    isoCode: "MT",
  },
  {
    name: "Moldova",
    dialCode: "+373",
    isoCode: "MD",
  },
  {
    name: "Monaco",
    dialCode: "+377",
    isoCode: "MC",
  },
  {
    name: "Montenegro",
    dialCode: "+382",
    isoCode: "ME",
  },
  {
    name: "Netherlands",
    dialCode: "+31",
    isoCode: "NL",
  },
  {
    name: "North Macedonia",
    dialCode: "+389",
    isoCode: "MK",
  },
  {
    name: "Norway",
    dialCode: "+47",
    isoCode: "NO",
  },
  {
    name: "Poland",
    dialCode: "+48",
    isoCode: "PL",
  },
  {
    name: "Portugal",
    dialCode: "+351",
    isoCode: "PT",
  },
  {
    name: "Romania",
    dialCode: "+40",
    isoCode: "RO",
  },
  {
    name: "Russia",
    dialCode: "+7",
    isoCode: "RU",
  },
  {
    name: "Slovakia",
    dialCode: "+421",
    isoCode: "SK",
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    isoCode: "SI",
  },
  {
    name: "Spain",
    dialCode: "+34",
    isoCode: "ES",
  },
  {
    name: "Sweden",
    dialCode: "+46",
    isoCode: "SE",
  },
  {
    name: "Switzerland",
    dialCode: "+41",
    isoCode: "CH",
  },
  {
    name: "Ukraine",
    dialCode: "+380",
    isoCode: "UA",
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    isoCode: "GB",
  },
  {
    name: "United States",
    dialCode: "+1",
    isoCode: "US",
  },
];
