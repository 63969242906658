import cloneDeep from "lodash.clonedeep";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";

import {
  TrainingStatus,
  type ExerciseInWorkout,
  type SuperSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

export const prepareMergedWorkoutExercises = (
  workoutExercises: ExerciseInWorkout[],
): ((ExerciseInWorkout & { orderKey: number; isSuperSet?: false }) | SuperSet)[] => {
  let program = cloneDeep(workoutExercises);

  program = program.map((item, i) => ({ ...item, orderKey: i }));
  const grouped = groupBy(
    program.map((item, index) => ({ ...item, orderKey: index })),
    (item) => item.superSetId || item.id,
  );
  const groupedArr = Object.entries(grouped).map(([groupId, group]) => {
    if (group.length === 1) {
      // if is not superset
      return group[0] as ExerciseInWorkout & { orderKey: number };
    }

    const firstItem = group[0];

    return {
      isSuperSet: true as const,
      orderKey: firstItem?.orderKey ?? 0,
      superSetId: Number(groupId),
      key: Number(groupId),
      id: groupId,
      workout: `Superseria (${group.length})`,
      numberOfSeries: firstItem?.numberOfSeries ?? 1,
      mergedExercises: group,
      mergedExercisesIds: group.map((exercise) => exercise.id),
      status: TrainingStatus.NEW,
    };
  });

  return sortBy(groupedArr, "orderKey");
};
