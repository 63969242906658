import React, { useState, type FunctionComponent } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Input, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productActions } from "@fitness-app/app-store";
import { type ProductPrice } from "@fitness-app/data-models/entities/Product";

import { useEntityChange } from "~/hooks/useEntityChange";
import { useAppDispatch } from "~/store/initializeStore";

interface OwnProps {
  label?: string;
  id: string;
  productId: string;
  type: ProductPrice["type"];
  accessPeriod: ProductPrice["accessPeriod"] | null;
  name?: string;
}

type Props = OwnProps;

const EditPriceLabel: FunctionComponent<Props> = ({ label, type, accessPeriod, productId, id }) => {
  const { t } = useTranslation("products");
  const getDefaultLabel = () => {
    return `${t(`paymentTypeOptions.${type}`)}${
      type === "recurring" || (type === "one_time" && accessPeriod)
        ? ` (${t(`${type}.${accessPeriod?.interval || accessPeriod?.interval}`, {
            count: accessPeriod?.interval_count,
          })})`
        : ""
    }`;
  };
  const [inputValue, setInputValue] = useState(label || getDefaultLabel());
  const [editMode, toggleEditMode] = useState(false);
  const [loader, onSuccess, _, onStart] = useEntityChange(() => {
    toggleEditMode(false);
  });
  const dispatch = useAppDispatch();

  const onSave = async () => {
    const defaultLabel = getDefaultLabel();
    onStart();
    if (!inputValue.length || inputValue === defaultLabel) {
      await dispatch(
        productActions.updateProductPriceLabel({
          payload: {
            productId,
            priceId: id,
            customLabel: "",
          },
        }),
      );
      setInputValue(defaultLabel);
    } else {
      await dispatch(
        productActions.updateProductPriceLabel({
          payload: {
            productId,
            priceId: id,
            customLabel: inputValue,
          },
        }),
      );
    }

    onSuccess();
  };

  if (!editMode) {
    return (
      <Space direction="horizontal" size={4}>
        <Typography.Text type="secondary">{t("productDetails.customPriceLabel")}</Typography.Text>
        <Typography.Text style={{ marginLeft: 5 }}>{label || t("productDetails.defaultLabel")}</Typography.Text>
        <EditOutlined onClick={() => toggleEditMode((prev) => !prev)} />
      </Space>
    );
  }
  return (
    <Space direction="horizontal" size={6}>
      <Input
        style={{ minWidth: 350 }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        disabled={!!loader}
      />
      <CheckOutlined style={{ color: "#1bc5bd" }} onClick={onSave} />
      <CloseOutlined style={{ color: "#f64e60" }} onClick={() => toggleEditMode(false)} />
    </Space>
  );
};

export default EditPriceLabel;
