import React, { useEffect, type FunctionComponent } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { automationTemplatesActions, automationTemplatesSelectors, teamActions } from "@fitness-app/app-store";
import { getUserInitials } from "@fitness-app/utils";
import { createScheduleOptions } from "@fitness-app/utils/src/surveys/createInitialSurvey";

import { DaysSummary } from "~/components/DaysSummary/DaysSummary";
import { useAuthorOptions } from "~/hooks/trainer/useAuthorOptions";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useGeneratedDaysForMessages } from "~/modules/Automation/AutomatedMessagesForm/useGeneratedDaysForMessages";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { weekDayLabels, type MessageComposeFormModel, type WeekdayLabels } from "./types";

interface OwnProps {
  formController?: FormInstance<MessageComposeFormModel>;
  onSubmit: (formData: MessageComposeFormModel) => void;
  model?: Partial<MessageComposeFormModel>;
}

type Props = OwnProps;

export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 23];

const validatePickerTime = () => DISABLED_HOURS;

export const dayOfWeekAsInteger = (day: WeekdayLabels) => weekDayLabels.indexOf(day);

const MessageComposeForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["dashboard", "common", "products"]);
  const templates = useAppSelector(automationTemplatesSelectors.getAutomationTemplatesMessages);
  const sentDate = Form.useWatch("sentDate", formController);
  const numberOfRepeats = Form.useWatch("numberOfRepeats", formController);
  const daysOfDelivery = Form.useWatch("daysOfDelivery", formController);
  const sentTime = Form.useWatch("sentTime", formController);
  const sendImmediately = Form.useWatch("sendImmediately", formController);
  const { isTeamMember } = useUserRole();
  const { trainerId } = useUserClaims();
  const userDetails = useAppSelector((store) => store.user.profile);
  const dispatch = useAppDispatch();
  const authorOptions = useAuthorOptions(model, true, false);

  const today = dayjs();

  useEffect(() => {
    void dispatch(automationTemplatesActions.fetchAutomationTemplates());
    void dispatch(teamActions.fetchTeamMembers());
  }, []);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  useEffect(() => {
    if (sentDate) {
      formController?.setFieldsValue({
        daysOfDelivery: [weekDayLabels[sentDate.get("day")] || "monday"],
      });
    }
  }, [sentDate]);

  const generatedDaysOfDelivery = useGeneratedDaysForMessages({
    sentDate,
    daysOfDelivery,
    numberOfRepeats,
  });

  const handleSubmit = (formData: MessageComposeFormModel) => {
    const foundAuthorData = authorOptions.find((author) => author.value === formData.authorId);
    if (!foundAuthorData) {
      onSubmit(formData);

      return;
    }
    onSubmit({
      ...formData,
      authorRole: foundAuthorData.role,
      authorName: foundAuthorData.label,
      authorAvatar: foundAuthorData.avatarUrl || null,
    });
  };

  const hydrateForm = (templateName: string | null) => {
    const template = templates.find((template) => template.name === templateName);

    if (template) {
      const author = authorOptions.find((author) => author.value === template.data.authorId);
      const fieldsValues = {
        name: template.name,
        messages: template.data.messages,
        saveAsTemplate: false,
        authorId:
          isTeamMember && template.data.authorId === trainerId
            ? userDetails?.id || undefined
            : author
            ? template.data.authorId
            : undefined,
        sentTime: dayjs().set("hour", template.data.sentTime.hours).set("minutes", template.data.sentTime.minutes),
      };

      formController?.setFieldsValue(fieldsValues);
    }
  };

  return (
    <Form<MessageComposeFormModel>
      name="message-compose-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      form={formController}
      onFinish={handleSubmit}
      initialValues={{
        saveAsTemplate: false,
        name: "",
        numberOfRepeats: 1,
        sendImmediately: true,
      }}
    >
      <Form.Item name="name" label={t("form.searchInTemplates")}>
        <AutoComplete
          showSearch
          allowClear
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={(inputValue, option) => {
            if (option?.children) {
              // @ts-expect-error ignore
              return (option.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
            }
            return false;
          }}
          onSelect={(value: string) => hydrateForm(value)}
        >
          {templates.map((template) => (
            <Select.Option key={template.id} value={template.name}>
              {template.name}
            </Select.Option>
          ))}
        </AutoComplete>
      </Form.Item>

      <Form.Item name="sendImmediately" label={t<string>("form.isSendRightNow")} valuePropName="checked">
        <Switch />
      </Form.Item>

      {!sendImmediately && (
        <>
          <Form.Item
            name="sentTime"
            label={t("automatedMessageForm.sentTime")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker.TimePicker
              minuteStep={15}
              allowClear={false}
              format="HH:mm"
              disabledHours={validatePickerTime}
            />
          </Form.Item>

          <Form.Item
            name="sentDate"
            label={t("automatedMessageForm.sentDate")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker format="DD.MM.YYYY" disabledDate={(current) => dayjs(current).isBefore(today, "day")} />
          </Form.Item>
          <Form.Item name="daysOfDelivery" label={t("automatedMessageForm.dayOfDelivery")}>
            <Checkbox.Group>
              <Row>
                {createScheduleOptions((item) => t(`${item}`)).map((item) => (
                  <Col span={8} key={item.value}>
                    <Checkbox
                      value={item.value}
                      style={{ lineHeight: "32px" }}
                      disabled={sentDate?.get("day") === dayOfWeekAsInteger(item.value)}
                    >
                      {item.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="numberOfRepeats" label={t("automatedMessageForm.numberOfRepeats")}>
            <InputNumber min={1} max={30} />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="authorId"
        label={t("automatedMessageForm.messageAuthor")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select placeholder={t("automatedMessageForm.messageAuthorPlaceholder")} style={{ maxWidth: 250 }}>
          {authorOptions.map((author) => (
            <Select.Option value={author.value} label={author.label} key={author.value}>
              <Space direction="horizontal">
                <Avatar src={author.avatarUrl} size={26}>
                  {getUserInitials(author.label)}
                </Avatar>{" "}
                {author.label}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={t("automatedMessageForm.messages")} tooltip={t("automatedMessageForm.dynamicValue")}>
        <Form.List name="messages" initialValue={[""]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div
                  key={field.key}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "baseline",
                  }}
                >
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    key={field.key}
                    rules={[
                      {
                        required: true,
                        message: t<string>("common:validationErrors.fieldIsRequired"),
                      },
                    ]}
                    style={{ width: "90%" }}
                  >
                    <Input.TextArea rows={2} placeholder={t<string>("automatedMessageForm.placeholderMessage")} />
                  </Form.Item>
                  {fields.length > 1 && (
                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginLeft: 12 }} />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ marginTop: 10 }}>
                  {t("common:button.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {generatedDaysOfDelivery.length ? (
          <DaysSummary generatedDates={generatedDaysOfDelivery} selectedTime={sentTime} />
        ) : null}
      </Form.Item>
    </Form>
  );
};

export default MessageComposeForm;
