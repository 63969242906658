import { type UserProfile } from "@fitness-app/data-models";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import { updateUserDetailsFailed, updateUserDetailsStarted, updateUserDetailsSuccess } from "../reducer";

export const updateUserProfile =
  (userData: Partial<UserProfile>): AppThunk =>
  async (dispatch, getState, { db, auth }) => {
    try {
      const user = await getLoggedUser(auth);

      dispatch(updateUserDetailsStarted());

      const { error } = await db
        .from("user_profile")
        .update({ ...userData })
        .eq("id", user.id);

      if (error) {
        return dispatch(updateUserDetailsFailed());
      }

      dispatch(updateUserDetailsSuccess());
    } catch (e) {
      dispatch(updateUserDetailsFailed());
    }
  };
