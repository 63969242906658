import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import filter from "lodash.filter";
import isEmpty from "lodash.isempty";
import keyBy from "lodash.keyby";

import { WorkoutSource } from "../../entities/ClientWorkout";
import { type RejectedTrainingFeedbackData, type TrainingFeedbackData } from "../../entities/Measurement";
import { ActivityEventType, type WorkoutActivityEventWithFeedback } from "../../entities/ProgramActivity";
import {
  TrainingStatus,
  type ClientTrainingProgram,
  type ClientTrainingProgramDetails,
  type ProgramSchedule,
  type ProgramWorkout,
} from "../../entities/TrainingProgram";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export type ProgramWorkoutWithMetadata = ProgramWorkout & {
  status: TrainingStatus | null;
  source: WorkoutSource;
  activityId?: string;
  workoutRate?: TrainingFeedbackData | RejectedTrainingFeedbackData | null;
  feedbackId?: string | null;
};

export type GetWorkoutsForDayParams = {
  selectedDate: dayjs.Dayjs;
  clientProgram?: ClientTrainingProgram | null;
  activitiesForSelectedDate:
    | Record<string, WorkoutActivityEventWithFeedback>
    | WorkoutActivityEventWithFeedback[]
    | null;
  showOnlyForCurrentProgram?: boolean;
  programDetails: ClientTrainingProgramDetails | null;
};

export const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export class WorkoutService {
  static findCurrentWeekScheduleIndex(schedule: ProgramSchedule | null, startDate: string, currentDay: dayjs.Dayjs) {
    let currentWeekIndex = 0;
    if (schedule && schedule.length > 1) {
      const weekAIndex = dayjs(startDate).isoWeek() % 2;
      const weekBIndex = dayjs(startDate).add(1, "weeks").isoWeek() % 2;
      const weekMap = {
        0: weekAIndex,
        1: weekBIndex,
      };
      currentWeekIndex = weekMap[(currentDay.isoWeek() % 2) as 0 | 1];
    }
    return currentWeekIndex;
  }

  static getWorkoutStatus(
    workout: ProgramWorkout,
    workoutDate: dayjs.Dayjs,
    fromSchedule?: boolean,
  ): {
    status: TrainingStatus | null;
  } {
    if (!workout) {
      return {
        status: null,
      };
    }
    const isBeforeToday = workoutDate?.isBefore(dayjs().endOf("day"), "day");
    const { exercises, preWorkout, postWorkout } = workout;
    const allExercises = [...preWorkout, ...exercises, ...postWorkout];

    if (!allExercises.length) {
      return {
        status: TrainingStatus.EMPTY,
      };
    }

    if (fromSchedule) {
      if (isBeforeToday) {
        return {
          status: TrainingStatus.PASSED,
        };
      }
      return {
        status: TrainingStatus.NEW,
      };
    }

    const workoutsCount = allExercises.length;

    const fulfilledWorkouts = filter(allExercises, {
      status: TrainingStatus.FULFILLED,
    }).length;
    const partiallyFulfilled = filter(allExercises, {
      status: TrainingStatus.PARTIALLY_FULFILLED,
    }).length;
    const rejectedWorkouts = filter(allExercises, {
      status: TrainingStatus.REJECTED,
    }).length;

    if (workoutsCount === fulfilledWorkouts) {
      return {
        status: TrainingStatus.FULFILLED,
      };
    }
    if (
      (fulfilledWorkouts && workoutsCount > fulfilledWorkouts) ||
      (partiallyFulfilled && workoutsCount > partiallyFulfilled)
    ) {
      return {
        status: TrainingStatus.PARTIALLY_FULFILLED,
      };
    }
    if (!fulfilledWorkouts && isBeforeToday) {
      return {
        status: TrainingStatus.PASSED,
      };
    }
    if (workoutsCount === rejectedWorkouts) {
      return {
        status: TrainingStatus.REJECTED,
      };
    }
    return {
      status: TrainingStatus.NEW,
    };
  }

  static getWorkoutsForDay({
    selectedDate,
    activitiesForSelectedDate,
    clientProgram,
    showOnlyForCurrentProgram,
    programDetails,
  }: GetWorkoutsForDayParams): ProgramWorkoutWithMetadata[] {
    if (!clientProgram) {
      return [];
    }
    const mProgramStartDate = dayjs(clientProgram.startDate, "YYYY-MM-DD").startOf("day");
    const mProgramEndDate = dayjs(clientProgram.endDate, "YYYY-MM-DD").endOf("day");
    // case for date outside the current program
    const workouts: ProgramWorkoutWithMetadata[] = [];
    if (!selectedDate.isSameOrAfter(mProgramStartDate) || !selectedDate.isSameOrBefore(mProgramEndDate)) {
      if (activitiesForSelectedDate) {
        Object.values(activitiesForSelectedDate)
          .filter(
            (event) =>
              event.type === ActivityEventType.Workout && event.eventDate === selectedDate.format("YYYY-MM-DD"),
          )
          .forEach(({ data: workout, id, workoutRate, feedbackId }) => {
            workouts.push({
              ...workout,
              status: this.getWorkoutStatus(workout, selectedDate).status,
              source: WorkoutSource.FromActivity,
              activityId: id,
              workoutRate: workoutRate || null,
              feedbackId,
            });
          });

        return workouts;
      } else {
        return [];
      }
    }
    let shouldSkipScheduledWorkouts = false;

    if (
      activitiesForSelectedDate &&
      !isEmpty(activitiesForSelectedDate) &&
      Object.values(activitiesForSelectedDate).some((event) => event.eventDate === selectedDate.format("YYYY-MM-DD"))
    ) {
      Object.values(activitiesForSelectedDate)
        .filter(
          (event) => event.type === ActivityEventType.Workout && event.eventDate === selectedDate.format("YYYY-MM-DD"),
        )
        .forEach(({ data: workout, id, programId, workoutRate, feedbackId }) => {
          if (workout.replicatedFromSchedule && programId === clientProgram.id) {
            shouldSkipScheduledWorkouts = true;
          }
          if (!showOnlyForCurrentProgram || programId === clientProgram.id) {
            workouts.push({
              ...workout,
              status: this.getWorkoutStatus(workout, selectedDate).status,
              source: WorkoutSource.FromActivity,
              activityId: id,
              workoutRate: workoutRate || null,
              feedbackId,
            });
          }
        });
    }
    if (shouldSkipScheduledWorkouts) {
      return workouts;
    }
    const isoWeekOfSelectedDate = selectedDate.isoWeek();
    const dateYear = dayjs(selectedDate).get("year");
    const isoWeekString = `${dateYear}-${isoWeekOfSelectedDate}`;

    const exceptionWeekSchedule = clientProgram.workoutsSchedule?.exceptionWeeks?.[isoWeekString] || null;
    const dayOfWeek = weekdays[selectedDate.day()];

    if (!programDetails) {
      return workouts;
    }

    const workoutsById = keyBy(programDetails.workouts, "id");
    const archivedWorkoutsById = keyBy(programDetails.archivedWorkouts, "id") ?? {};

    if (exceptionWeekSchedule) {
      const workoutIdForDay = dayOfWeek ? exceptionWeekSchedule[dayOfWeek]?.workoutDay : null;
      const regularWorkout = workoutIdForDay ? workoutsById[workoutIdForDay] : null;
      if (workoutIdForDay && regularWorkout) {
        workouts.push({
          ...regularWorkout,
          status: this.getWorkoutStatus(regularWorkout, selectedDate).status,
          source: WorkoutSource.FromException,
        });
      }
      const archivedWorkout = workoutIdForDay ? archivedWorkoutsById?.[workoutIdForDay] : null;
      if (workoutIdForDay && archivedWorkout) {
        workouts.push({
          ...archivedWorkout,
          status: this.getWorkoutStatus(archivedWorkout, selectedDate).status,
          source: WorkoutSource.FromException,
        });
      }
    } else {
      const currentWeekIndex = this.findCurrentWeekScheduleIndex(
        clientProgram?.workoutsSchedule?.schedule ?? null,
        clientProgram.startDate,
        selectedDate,
      );

      const workoutIdForDay = dayOfWeek
        ? clientProgram.workoutsSchedule?.schedule[currentWeekIndex]?.[dayOfWeek]?.workoutDay
        : null;
      const scheduledWorkout = workoutIdForDay && workoutsById[workoutIdForDay];
      if (workoutIdForDay && scheduledWorkout) {
        workouts.push({
          ...scheduledWorkout,
          status: this.getWorkoutStatus(scheduledWorkout, selectedDate).status,
          source: WorkoutSource.FromSchedule,
        });
      }
    }
    return workouts;
  }
}
