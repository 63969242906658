import { ClientNoteCategory, type ClientNoteType } from "@fitness-app/data-models/entities/ClientNote";

import i18nextConfig from "~/i18nextConfig";

export interface ClientNoteFormModel {
  content: string;
  type: ClientNoteType;
  category: ClientNoteCategory;
  attachments: { url: string; name: string; uid: string; protected?: boolean }[];
}

export const noteCategories = Object.values(ClientNoteCategory).map((value) => ({
  value,
  label: i18nextConfig.t(`trainees:notes.categories.${value}`),
}));
