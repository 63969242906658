import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, DatePicker, Space, Tooltip, Typography } from "antd";
import dayjs, { type Dayjs } from "dayjs";

import { RequestStatus, traineeActions } from "@fitness-app/app-store";

import ActivityCalendar from "~/components/ActivityCalendar";
import { type ThemeInput } from "~/components/ActivityCalendar/types";
import { getActivitiesDate } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/data";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const explicitTheme: ThemeInput = {
  light: ["#F0F0F0", "#bbf7d0", "#86efac", "#22c55e", "#15803d"],
  dark: ["#F0F0F0", "#bbf7d0", "#86efac", "#22c55e", "#15803d"],
};

const TraineeActivityChart = () => {
  const [dateRange, setDateRange] = useState<null | [Dayjs | null, Dayjs | null]>([
    dayjs().subtract(1, "year").startOf("day"),
    dayjs().endOf("month"),
  ]);
  const { profile: client, clientActivitiesLog, clientActivitiesLogStatus } = useAppSelector((state) => state.trainee);
  const dispatch = useAppDispatch();

  const clientId = client?.id;
  useEffect(() => {
    const [start, end] = dateRange || [];
    if (start && end && clientId) {
      void dispatch(traineeActions.fetchDailyActivities({ start, end, id: clientId }));
    }
  }, [dateRange, clientId]);

  const getDisabledDate = useCallback((current: dayjs.Dayjs) => {
    return current && (current > dayjs().endOf("year") || current.get("year") < 2022);
  }, []);

  const activities = useMemo(() => getActivitiesDate(dateRange, clientActivitiesLog), [clientActivitiesLog, dateRange]);

  return (
    <Space style={{ width: "100%" }} direction="vertical" size={16}>
      <Card
        type="inner"
        title="Mapa aktywności"
        extra={
          <DatePicker.RangePicker
            value={dateRange}
            format="DD.MM.YYYY"
            picker="month"
            disabledDate={getDisabledDate}
            renderExtraFooter={() => (
              <Typography.Text type="secondary">Dane zbierane są od 1 stycznia 2022</Typography.Text>
            )}
            ranges={{
              "Obecny miesiąc": [dayjs().startOf("month"), dayjs().endOf("month")],
              "3 miesiące": [dayjs().subtract(2, "month").startOf("month"), dayjs().endOf("month")],
              Rok: [dayjs().subtract(1, "year").startOf("day"), dayjs().endOf("month")],
            }}
            onChange={(values) => setDateRange(Array.isArray(values) && values.length === 2 ? values : null)}
          />
        }
      >
        <div className="flex justify-center py-12">
          <ActivityCalendar
            labels={{
              legend: {
                less: "Mniej",
                more: "Więcej",
              },
              months: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
              totalCount: "{{count}} aktywności",
              weekdays: ["Niedz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],
            }}
            blockSize={14}
            renderBlock={(block, activity) => (
              <Tooltip title={`${activity.count} aktywności w ${activity.date}`}>{block}</Tooltip>
            )}
            loading={clientActivitiesLogStatus === RequestStatus.FETCHING}
            showWeekdayLabels
            weekStart={1}
            theme={explicitTheme}
            data={activities}
          />
        </div>
      </Card>
    </Space>
  );
};

export default TraineeActivityChart;
