import React, { memo, useEffect, useState } from "react";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Empty, List, Popconfirm, Space, Typography } from "antd";
import orderBy from "lodash.orderby";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { programAutomationActions, teamActions } from "@fitness-app/app-store";
import {
  DietaryPreference,
  type MealsPlan,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";
import {
  ScheduledNutritionType,
  type ScheduledNutritionByCurrentWeight,
} from "@fitness-app/data-models/entities/ProgramAutomation";

import ModalForm from "~/components/ModalForm/ModalForm";
import ScheduledNutritionForm from "~/modules/Automation/ProgramAutomation/components/ScheduledNutrition/ScheduledNutritionForm";
import { type ScheduledNutritionFormModel } from "~/modules/Automation/ProgramAutomation/components/ScheduledNutrition/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const getNutritionName = (
  nutritionId: string | undefined | { id: string },
  mealsPlanById: Record<string, MealsPlan>,
) => {
  if (!nutritionId) {
    return "";
  }

  if (typeof nutritionId !== "string") {
    nutritionId = nutritionId.id;
  }

  const nutrition = mealsPlanById[nutritionId];
  return nutrition ? nutrition.name : "";
};

const ScheduledNutritionPlans = () => {
  const selectedAutomation = useAppSelector((store) => store.programAutomation.selectedAutomation);
  const { t } = useTranslation(["automation", "common"]);
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();
  const [saving, toggleSaving] = useState(false);

  useEffect(() => {
    void dispatch(teamActions.fetchTrainerConfig());
  }, []);

  if (!selectedAutomation) {
    return null;
  }

  const sortedScheduledNutritionPlans = orderBy(selectedAutomation.scheduledNutritionPlan || [], ["startWeek"]);

  const removeScheduledProgram = (id: string) => {
    const updatedScheduled = selectedAutomation.scheduledNutritionPlan?.filter((item) => item.id !== id) || [];
    void dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          scheduledNutritionPlan: updatedScheduled,
        },
      }),
    );
  };

  const onSaveSchedule = async (
    model: ScheduledNutritionFormModel,
    mealsPlanById: Record<string, MealsPlanWithCreator>,
  ) => {
    toggleSaving(true);
    const schedule = selectedAutomation.scheduledNutritionPlan || [];

    delete model.tags;

    const newSchedule: ScheduledNutritionByCurrentWeight = {
      type: ScheduledNutritionType.ByCurrentWeight,
      startWeek: model.startWeek || 1,
      endWeek: model.endWeek || null,
      additionalComment: model.additionalComment || "",
      additionalAttachments: [],
      sendDate: null,
      id: uuid(),
      ranges: model.ranges.map((range, index) => ({
        from: index === 0 ? null : range.from,
        to: index == model.ranges.length - 1 ? null : range.to,
        nutrition: {
          [DietaryPreference.NoPreference]: {
            id: range.nutrition[DietaryPreference.NoPreference],
            name: getNutritionName(range.nutrition[DietaryPreference.NoPreference], mealsPlanById),
          },
          [DietaryPreference.SemiVegetarian]: range.nutrition[DietaryPreference.SemiVegetarian]
            ? {
                id: range.nutrition[DietaryPreference.SemiVegetarian],
                name: getNutritionName(range.nutrition[DietaryPreference.SemiVegetarian], mealsPlanById),
              }
            : null,
          [DietaryPreference.Vegetarian]: range.nutrition[DietaryPreference.Vegetarian]
            ? {
                id: range.nutrition[DietaryPreference.Vegetarian],
                name: getNutritionName(range.nutrition[DietaryPreference.Vegetarian], mealsPlanById),
              }
            : null,
          [DietaryPreference.Vegan]: range.nutrition[DietaryPreference.Vegan]
            ? {
                id: range.nutrition[DietaryPreference.Vegan],
                name: getNutritionName(range.nutrition[DietaryPreference.Vegan], mealsPlanById),
              }
            : null,
        },
      })) as unknown as ScheduledNutritionByCurrentWeight["ranges"],
    };
    const updatedSchedule = [...schedule, newSchedule];

    const result = await dispatch(
      programAutomationActions.updateAutomationTemplate({
        automationId: selectedAutomation.id,
        automation: {
          scheduledNutritionPlan: updatedSchedule,
        },
      }),
    );

    toggleSaving(false);

    if (result.meta.requestStatus === "fulfilled") {
      toggleModal(false);
    }
  };

  return (
    <>
      <Collapse>
        <Collapse.Panel key="nutrition" header={t("nutrition.plannedAutomation")}>
          <List
            bordered={false}
            rowKey="id"
            locale={{
              emptyText: (
                <Empty description={t("nutrition.emptyState")}>
                  <Button icon={<PlusOutlined />} type="primary" onClick={() => toggleModal(true)}>
                    {t("nutrition.addMealsPlan")}
                  </Button>
                </Empty>
              ),
            }}
            dataSource={sortedScheduledNutritionPlans}
            footer={
              sortedScheduledNutritionPlans.length ? (
                <div className="flex items-center justify-center">
                  <Button icon={<PlusOutlined />} size="small" type="primary" onClick={() => toggleModal(true)}>
                    {t("nutrition.addAnotherSchedule")}
                  </Button>
                </div>
              ) : null
            }
            renderItem={(routine, index) => (
              <List.Item
                actions={[
                  <Popconfirm
                    key="remove"
                    title={t("nutrition.confirmDelete")}
                    okText={t("common:yes")}
                    cancelText={t("common:no")}
                    placement="left"
                    onConfirm={() => removeScheduledProgram(routine.id)}
                  >
                    <Typography.Link type="danger">{t("common:button.delete")}</Typography.Link>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space direction="horizontal" size={8}>
                      <CalendarOutlined />
                      <Typography.Text>
                        {(sortedScheduledNutritionPlans[index + 1] || routine.endWeek) &&
                        (routine.startWeek !== (routine.endWeek ?? 0) + 1 ||
                          routine.endWeek === sortedScheduledNutritionPlans[index + 1]?.startWeek)
                          ? t("nutrition.dietsFromTo", {
                              weekFrom: routine.startWeek,
                              weekTo:
                                routine.endWeek ||
                                (
                                  sortedScheduledNutritionPlans[index + 1] || {
                                    startWeek: 0,
                                  }
                                ).startWeek - 1,
                            })
                          : t("nutrition.dietsFrom", {
                              week: routine.startWeek,
                            })}
                      </Typography.Text>
                    </Space>
                  }
                  description={
                    <div className="py-3">
                      {routine.ranges.map((range, index) => (
                        <div key={index}>
                          <Typography.Text strong>
                            {range.from ? t("nutrition.rangeKgFrom", { from: range.from }) : ""}
                            {range.from && range.to ? " - " : ""}
                            {range.to ? t("nutrition.rangeKgTo", { to: range.to }) : ""}
                          </Typography.Text>

                          {Object.entries(range.nutrition).map(([id, nutrition]) => (
                            <div className="px-2" key={id}>
                              {t(`nutrition:dietaryPreferenceEnum.${id || 1}`)} -{" "}
                              {nutrition?.name || <span className="underline">{t("nutrition.unset")}</span>}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Collapse.Panel>
      </Collapse>
      <ModalForm
        open={showModal}
        loading={saving}
        onCancel={() => toggleModal(false)}
        title={t("nutrition.addMealsPlan")}
        width={950}
      >
        <ScheduledNutritionForm onSubmit={onSaveSchedule} selectedAutomation={selectedAutomation} />
      </ModalForm>
    </>
  );
};

export default memo(ScheduledNutritionPlans);
