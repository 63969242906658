import React from "react";
import { CheckOutlined, DeleteOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Badge, Button, Popconfirm, Popover, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { type ScheduledSurveyAutomation } from "@fitness-app/data-models/entities/AutomationTemplate";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { getMinutesWithLeadingZero } from "~/modules/Automation/utils/getMinutesWithLeadingZero";

interface SurveyAutomationItemProps {
  item: ScheduledSurveyAutomation;
  onRemove?: () => void;
  onEdit?: () => void;
  status?: ProgramAutomationTaskStatus;
}

const statusMapper = {
  [ProgramAutomationTaskStatus.Upcoming]: "processing",
  [ProgramAutomationTaskStatus.Done]: "success",
  [ProgramAutomationTaskStatus.Rejected]: "error",
  [ProgramAutomationTaskStatus.Canceled]: "warning",
} as const;

export const SurveyAutomationItem = ({ item, onRemove, status }: SurveyAutomationItemProps): React.ReactElement => {
  const { t } = useTranslation("automation");
  const minutesWithLeadingZero = getMinutesWithLeadingZero(item.sentTime.minutes);

  // const handleEdit: React.MouseEventHandler<HTMLElement> = (event) => {
  //   event.stopPropagation();
  //   onEdit?.();
  // };

  return (
    <Popover
      title={item.source ? t("events.scheduleSurveyFuture") : t("events.scheduleSurvey")}
      content={
        <Space direction="vertical">
          {status && (
            <Space>
              <Typography.Text type="secondary">{t("events.status")}</Typography.Text>
              <Badge status={statusMapper[status] || "default"} text={t(`eventsStatus.${status || "upcoming"}`)} />
            </Space>
          )}
          <Space>
            <Typography.Text type="secondary">{t("events.surveyName")}:</Typography.Text>
            <Typography.Text>
              <Link to={`/surveys/details/${item.survey.templateId}`}>{item.name}</Link>
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("events.sentTime")}</Typography.Text>
            <Typography.Text>
              {item.sentTime.hours}:{minutesWithLeadingZero}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("events.questionsCount")}</Typography.Text>
            <Typography.Text>{Object.keys(item.survey.questions).length || 0}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("events.surveyType")}</Typography.Text>
            <Typography.Text>{t(`surveys:surveyType:${item.survey.type}`)}</Typography.Text>
          </Space>
          {"cycleNumber" in item.survey && item.cycleNumber ? (
            <Space>
              <Typography.Text type="secondary">{t("events.cycleNumber")}</Typography.Text>
              <Typography.Text>{item.cycleNumber}</Typography.Text>
            </Space>
          ) : null}

          {item.source ? (
            <Typography.Text type="secondary" className="text-xs">
              {t("events.removeParentSurvey")}
            </Typography.Text>
          ) : (
            <Space>
              {/*{onEdit && status !== ProgramAutomationTaskStatus.Done && (*/}
              {/*  <Button icon={<EditOutlined style={{ pointerEvents: "none" }} />} type="link" onClick={handleEdit} />*/}
              {/*)}*/}
              {onRemove && status !== ProgramAutomationTaskStatus.Done && (
                <Popconfirm
                  title={t("automatedMessageForm.deleteEvent")}
                  okText={t("common:switch.yes")}
                  cancelText={t("common:button.cancel")}
                  placement="top"
                  onConfirm={onRemove}
                >
                  <Button danger icon={<DeleteOutlined style={{ pointerEvents: "none" }} />} type="text" />
                </Popconfirm>
              )}
            </Space>
          )}
        </Space>
      }
    >
      <FileDoneOutlined className={cn("mr-2", item.source && "text-gray-500")} />
      <Typography.Text>
        {item.sentTime.hours}:{minutesWithLeadingZero}
      </Typography.Text>
      {status === ProgramAutomationTaskStatus.Done && <CheckOutlined className="ml-2 text-green-500" />}
    </Popover>
  );
};
