import { useQuery } from "@tanstack/react-query";

import { ChatStatus, type ChatChannel } from "@fitness-app/data-models/entities/Chat";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { supabase } from "~/store/initializeStore";

export const useTraineeChats = (userId?: string | null) => {
  const { userId: trainerUserId } = useUserRole();
  const { data, isLoading } = useQuery(["chat_channels", userId, trainerUserId], {
    enabled: !!userId,
    queryFn: async () => {
      const query = supabase
        .from("chat_channel")
        .select("*")
        .order("updatedAt", { ascending: false })
        .contains("assignedUsers", [userId, trainerUserId])
        .eq("status", ChatStatus.Active)
        .limit(100);

      const { error, data } = await query.returns<ChatChannel[]>();
      if (error) {
        return [];
      }
      return data;
    },
  });

  return {
    isLoading,
    data,
  };
};
