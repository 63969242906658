import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { type Meal } from "@fitness-app/data-models/entities/MealsPlan";

dayjs.extend(customParseFormat);

export const getMealHour = (hour: Meal["hour"]): string => {
  if (Array.isArray(hour)) {
    return `${dayjs(String(hour[0]), ["H", "H.m"]).format("HH:mm")} - ${dayjs(String(hour[1]), ["H", "H.m"]).format(
      "HH:mm",
    )}`;
  }

  if (hour) {
    return dayjs(String(hour), ["H", "H.m"]).format("HH:mm");
  }

  return "";
};
