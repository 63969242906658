import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import { logInFailed, logInStarted, logInSuccess } from "../reducer";
import { UserStatus } from "../types";
import { subscribeToUserProfile } from "./subscribeToUserProfile";

export interface LoginData {
  email: string;
  password: string;
}

export const logInUser =
  ({ email, password }: LoginData, callback?: () => void): AppThunk =>
  async (dispatch, getState, { db, auth }) => {
    const { user } = getState();

    if (user.status === UserStatus.LOGGING_IN || user.data?.email === email) {
      return;
    }
    dispatch(logInStarted());
    try {
      const res = await db.auth.signInWithPassword({ email, password });

      if (res.error) {
        dispatch(logInFailed(res.error.message));
        return;
      }

      if (!res.data.user?.email) {
        return;
      }
      const { id, email: userEmail } = res.data.user;
      const customClaims = (await getLoggedUser(auth)).customClaims;

      dispatch(
        logInSuccess({
          id,
          email: userEmail,
          customClaims,
        }),
      );
      dispatch(subscribeToUserProfile(id));
      if (callback) {
        callback();
      }
    } catch (e) {
      dispatch(logInFailed("500"));
    }
  };
