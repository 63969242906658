import { createAsyncThunk } from "@reduxjs/toolkit";

import { getError, getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export type Payload = {
  name: string;
  posId: string;
  clientId: string;
  clientSecret: string;
  safetyKey: string;
};

export const integrateWithTPay = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/integrateWithTpay`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { integrationApi, analytics } }) => {
    try {
      await integrationApi.post("/integrate-with-tpay", payload);

      analytics.track("integrate-with-tpay");
      onSuccess?.();
    } catch (e) {
      onFailure?.(getError(e).status);

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
