import React from "react";
import { useSortable } from "@dnd-kit/sortable";

import { useMountStatus } from "~/components/Dnd/hooks/useMountStatus";
import { DndItem } from "./DndItem";

interface SortableItemProps {
  containerId: string;
  id: string;
  index: number;
  handle?: boolean;
  disabled?: boolean;
  style?: (args: {
    index: number;
    value: string;
    isDragging: boolean;
    isSorting: boolean;
    overIndex: number;
    containerId: string;
  }) => React.CSSProperties;
  getIndex(id: string): number;
  wrapperStyle?: ({ index }: { index: number }) => React.CSSProperties;
  children: React.ReactNode;
  handleStyle?: React.CSSProperties;
}

export function SortableItem({
  disabled,
  id,
  index,
  handle,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  children,
  handleStyle,
}: SortableItemProps) {
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging, isSorting, over, overIndex, transform, transition } =
    useSortable({
      id,
    });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <DndItem
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef, style: handleStyle } : undefined}
      index={index}
      wrapperStyle={wrapperStyle ? wrapperStyle({ index }) : {}}
      style={
        style
          ? style({
              index,
              value: id,
              isDragging,
              isSorting,
              overIndex: over ? getIndex(over.id as string) : overIndex,
              containerId,
            })
          : {}
      }
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
    >
      {children}
    </DndItem>
  );
}
