import React from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { useDefaultTemplateVariables } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/useDefaultVariables";

type AutomationVariablesFormModel = Record<string, string | number>;

interface AutomationVariablesFormProps {
  formController?: FormInstance<AutomationVariablesFormModel>;
  onSubmit: (formData: AutomationVariablesFormModel) => void;
  model?: Partial<AutomationVariablesFormModel> | null;
  variables: string[];
}

const AutomationVariablesForm = ({ model, onSubmit, formController, variables }: AutomationVariablesFormProps) => {
  const { t } = useTranslation(["common"]);

  useDefaultTemplateVariables(variables, model || null, formController!);

  return (
    <Form<AutomationVariablesFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      className="pt-3"
      layout="horizontal"
      form={formController}
      initialValues={{
        ...model,
      }}
      onFinish={onSubmit}
    >
      <Row>
        <Col
          xs={{
            span: 24,
            offset: 0,
          }}
          sm={{
            span: 10,
            offset: 8,
          }}
        >
          <Typography.Title level={5}>Zmienne dla automatyzacji</Typography.Title>
        </Col>
      </Row>

      {variables.map((variableName) => (
        <Form.Item
          key={variableName}
          label={variableName}
          name={[variableName]}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      ))}
    </Form>
  );
};

export default AutomationVariablesForm;
