import { useCallback } from "react";
import isEmpty from "lodash.isempty";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { getTrainersTeam } from "@fitness-app/app-store/src/store/reducers/team/selectors";
import { TeamMemberRole, TeamMemberStatus } from "@fitness-app/data-models/entities/TeamMember";

import { EMPTY_TRAINER_FIELD } from "~/modules/Trainee/helpers/createTrainerOptions";
import { useAppSelector } from "~/store/initializeStore";

export const useTeamMembers = () => {
  const trainers = useAppSelector(getTrainersTeam);
  const { t } = useTranslation("trainees");

  const createTrainerOptions = useCallback(
    (withOwner = true) => {
      if (!trainers || isEmpty(trainers)) {
        return [];
      }
      const trainerOptions = [];
      trainers
        .filter((trainer) => (withOwner ? trainer : trainer.role !== TeamMemberRole.ACCOUNT_OWNER))
        .forEach((trainer) => {
          if (trainer.selectable) {
            const text = `${trainer.firstName} ${trainer.lastName}`;
            trainerOptions.push({
              value: trainer.userId || trainer.id,
              text: trainer.status === TeamMemberStatus.ACTIVE ? text : `${text} (nieaktywny)`,
            });
          }
        });
      trainerOptions.push({
        value: EMPTY_TRAINER_FIELD,
        text: t("trainerOptions.noTrainer"),
      });
      return trainerOptions;
    },
    [t, trainers],
  );

  return {
    createTrainerOptions,
    trainers,
    byId: keyBy(trainers, "id"),
  };
};
