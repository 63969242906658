import React, { useMemo } from "react";
import { Card, Empty } from "antd";
import isEmpty from "lodash.isempty";
import keyBy from "lodash.keyby";
import { Area, Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { type ExerciseStats } from "@fitness-app/data-models/entities/ExerciseStats";
import { formatProgressData } from "@fitness-app/utils/src/exercises/formatExerciseProgressData";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { DefaultUnits } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/data";
import ProgressGraphTooltip from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/ProgressGraphTooltip";

interface ExerciseProgressChartProps {
  exerciseStats: ExerciseStats[];
  isLoading: boolean;
  selectedExercise: string | null;
}

const graphColors = {
  madeExercises: "#25b10f",
  scheduledExercises: "#00AEEF",
  progressArea: "#ADF0D4",
  progressAreaStroke: "#27B08B",
  maxArea: "#74C7A8",
  maxAreaStroke: "#00797A",
  tooltipBackground: "#FFFFFF",
  tooltipBorder: "#CCCCCC",
};
export const ExerciseProgressChart = ({
  isLoading,
  exerciseStats,
  selectedExercise,
}: ExerciseProgressChartProps): React.ReactElement => {
  const chartData = useMemo(() => {
    if (!exerciseStats.length) {
      return [];
    }
    return formatProgressData(exerciseStats);
  }, [exerciseStats]);

  const statsByDate = useMemo(() => {
    return keyBy(exerciseStats, "eventDate");
  }, [exerciseStats]);

  return (
    <Card title="Progresja" type="inner" className="relative" loading={isLoading}>
      <ResponsiveContainer width="100%" height={300}>
        {chartData.length ? (
          <ComposedChart data={chartData} data-ctx="progress-areachart" syncMethod="value">
            <XAxis
              dataKey="date"
              domain={[0, "auto"]}
              tickLine={false}
              tick={{ transform: "translate(0, 6)" }}
              fill=""
              stroke=""
              className={cn(
                // common
                "text-tremor-label",
                // light
                "fill-tremor-content",
                // dark
                "dark:fill-dark-tremor-content",
              )}
              minTickGap={5}
            />
            <YAxis
              unit={` powt.`}
              domain={[0, "dataMax"]}
              yAxisId="left"
              orientation="left"
              type="number"
              dataKey="repeats"
              allowDecimals={false}
              tick={{ transform: "translate(-3, 0)" }}
              axisLine={false}
              tickLine={false}
              className={cn(
                // common
                "text-tremor-label",
                // light
                "fill-tremor-content",
                // dark
                "dark:fill-dark-tremor-content",
              )}
            />
            <YAxis
              dataKey="progress"
              unit={` ${DefaultUnits.Weight}`}
              domain={[0, "dataMax + 1"]}
              yAxisId="right"
              type="number"
              orientation="right"
              tick={{ transform: "translate(-3, 0)" }}
              axisLine={false}
              tickLine={false}
              className={cn(
                // common
                "text-tremor-label",
                // light
                "fill-tremor-content",
                // dark
                "dark:fill-dark-tremor-content",
              )}
            />
            <CartesianGrid
              strokeDasharray="3 3"
              className={cn(
                // common
                "stroke-1",
                // light
                "stroke-tremor-border",
                // dark
                "dark:stroke-dark-tremor-border",
              )}
              horizontal={true}
              vertical={false}
            />
            <Bar dataKey="repeats" barSize={20} fill="#0090FF" yAxisId="left" />
            <Area
              type="linear"
              yAxisId="right"
              dataKey="progress"
              unit={DefaultUnits.Weight}
              stroke={graphColors.progressAreaStroke}
              fill={graphColors.progressArea}
              activeDot={{ r: 8 }}
            />

            {selectedExercise && !isEmpty(chartData) && (
              <Tooltip
                wrapperStyle={{
                  zIndex: 100,
                }}
                content={(props) => (
                  <ProgressGraphTooltip
                    chartData={chartData}
                    exerciseData={statsByDate[props.label]}
                    {...(props as any)}
                  />
                )}
              />
            )}
          </ComposedChart>
        ) : (
          <Empty className="pt-12" image={Empty.PRESENTED_IMAGE_SIMPLE} description="Brak danych do wyświetlenia" />
        )}
      </ResponsiveContainer>
    </Card>
  );
};
