import orderBy from "lodash.orderby";
import round from "lodash.round";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { type IngredientMeasure, type Measure } from "@fitness-app/data-models/entities/Ingredient";

interface MeasureMultiplier {
  multiplier: number;
  measureId: number;
  measureName: string;
  localizedMeasureName: string;
  energyPerUnit: number;
  weightPerUnit: number;
  weightTotal: number;
}

export const calculateMeasureMultiplier = (
  ingredient: DishIngredient,
  measureMap: Record<number, IngredientMeasure>,
  overrideMeasureId?: number | null,
): MeasureMultiplier => {
  if (overrideMeasureId) {
    const measure = measureMap[overrideMeasureId] || measureMap[0];
    const weightPerUnit = ingredient.measures.find((m) => m.id === measure?.id)?.weightPerUnit || 100;

    return {
      measureId: overrideMeasureId,
      measureName: measure?.name || ingredient.measure,
      localizedMeasureName: measure?.name || ingredient.measure,
      energyPerUnit: round((ingredient.nutrients.calories / ingredient.measureWeight) * 100, 1),
      weightPerUnit,
      weightTotal: ingredient.portion,
      multiplier: round(ingredient.portion / weightPerUnit, 1),
    };
  }

  const measure = ingredient.measureId ? measureMap[ingredient.measureId] : null;

  if (ingredient.measureId === null || !measure) {
    return {
      measureId: 1,
      measureName: "g",
      localizedMeasureName: "grams",
      energyPerUnit: ingredient.nutrients.calories,
      weightPerUnit: 100,
      weightTotal: 100,
      multiplier: 1,
    };
  }

  const weightPerUnit =
    ingredient.measures.find((m) => m.id === measure.id)?.weightPerUnit || ingredient.measureWeight || 100;

  return {
    measureId: measure.id,
    measureName: measure.name || ingredient.measure,
    localizedMeasureName: ingredient.measure,
    energyPerUnit: round((ingredient.nutrients.calories / ingredient.measureWeight) * 100, 1),
    weightPerUnit,
    weightTotal: ingredient.portion,
    multiplier: round(ingredient.portion / weightPerUnit, 1),
  };
};

function findBestMeasureForPortion(productMeasures: Measure[], product: DishIngredient): null | Measure {
  // Calculate the energy density of the product's portion
  const targetEnergyDensity = product.portionCalories / product.portion;

  let bestMeasure: null | Measure = null;
  let smallestDifference = Infinity;

  productMeasures.forEach((measure) => {
    // Calculate the difference in weight between the product's portion and the measure's weightPerUnit
    const weightDifference = Math.abs(product.portion - measure.weightPerUnit);

    // Calculate the energy density for the current measure
    const measureEnergyDensity = measure.energyPerUnit / measure.weightPerUnit;

    // Calculate the difference in energy density between the product's portion and the measure's energyPerUnit
    const energyDensityDifference = Math.abs(targetEnergyDensity - measureEnergyDensity);

    // Prioritize measures that have a smaller difference in weight and energy density
    const totalDifference = weightDifference + energyDensityDifference;

    if (totalDifference < smallestDifference) {
      bestMeasure = measure;
      smallestDifference = totalDifference;
    }
  });

  return bestMeasure;
}

export const calculateBestMeasureMultiplier = (
  ingredient: DishIngredient,
  measureMap: Record<number, IngredientMeasure>,
): MeasureMultiplier => {
  const measure = ingredient.measureId ? measureMap[ingredient.measureId] : null;

  if (ingredient.measureId === null || !measure) {
    return {
      measureId: 1,
      measureName: "g",
      localizedMeasureName: "grams",
      energyPerUnit: ingredient.nutrients.calories,
      weightPerUnit: 100,
      weightTotal: 100,
      multiplier: 1,
    };
  }

  const weightPerUnit =
    ingredient.measures.find((m) => m.id === measure.id)?.weightPerUnit || ingredient.measureWeight || 100;

  if (ingredient.measures.length) {
    const sorted = orderBy(ingredient.measures, "weightPerUnit", "asc");

    const bestMeasure = findBestMeasureForPortion(sorted, ingredient);

    if (bestMeasure && bestMeasure.id !== 1) {
      return {
        measureId: bestMeasure.id,
        measureName: measureMap[bestMeasure.id]?.name || ingredient.measure,
        localizedMeasureName:
          ingredient.measureWeight === weightPerUnit ? ingredient.measure : bestMeasure.name || ingredient.measure,
        energyPerUnit: round((ingredient.nutrients.calories / ingredient.measureWeight) * 100, 1),
        weightPerUnit: bestMeasure.weightPerUnit,
        weightTotal: ingredient.portion,
        multiplier: round(ingredient.portion / bestMeasure.weightPerUnit, 1),
      };
    }
  }

  return {
    measureId: measure.id,
    measureName: measure.name || ingredient.measure,
    localizedMeasureName: ingredient.measureWeight === weightPerUnit ? ingredient.measure : "",
    energyPerUnit: round((ingredient.nutrients.calories / ingredient.measureWeight) * 100, 1),
    weightPerUnit,
    weightTotal: ingredient.portion,
    multiplier: round(ingredient.portion / weightPerUnit, 1),
  };
};
