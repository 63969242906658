import { type Database } from "@fitness-app/supabase";

import { type TrainingFeedback } from "./Measurement";
import { type ProgramWorkout } from "./TrainingProgram";

export type ClientWorkoutActivity = Database["public"]["Tables"]["client_workout_activity"]["Row"];

export enum ActivityEventType {
  Workout = "workout",
}

export interface ActivityEvent<T extends object> extends Omit<ClientWorkoutActivity, "data"> {
  id: string;
  type: ActivityEventType;
  createdAt: string;
  updatedAt: string;
  clientEmail: string;
  traineeId: string;
  userId: string | null;
  data: T;
  eventDate: string;
  eventMonth: string;
  eventWeek: string;
  eventTimestamp: string;
}

export interface WorkoutActivityEvent extends ActivityEvent<ProgramWorkout> {
  traineeId: string;
  programId: string | null;
  workoutId: string;
  type: ActivityEventType.Workout;
}

export interface WorkoutActivityEventWithFeedback extends WorkoutActivityEvent {
  workoutRate?: null | TrainingFeedback["data"];
}

export type ProgramActivity = Record<string, WorkoutActivityEventWithFeedback[]>;
