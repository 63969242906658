import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AppAccessType } from "@fitness-app/data-models/entities/Automation";
import {
  type TraineeStatus,
  type TraineeWithAssignedTrainerAndProduct,
} from "@fitness-app/data-models/entities/Trainee";

import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEES_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
  assignedTrainerId?: string | null;
  statuses?: TraineeStatus[] | null;
  accessType?: AppAccessType[] | null;
  tags?: string[] | null;
} | void;

export const fetchTrainees = createAsyncThunk<
  {
    page: number;
    list: TraineeWithAssignedTrainerAndProduct[];
    totalPages: number;
    count: number | null;
  },
  Payload,
  AsyncThunkCreator<string>
>(`${TRAINEES_REDUCER_NAME}/fetchTrainees`, async (payload, { rejectWithValue, extra: { db }, getState }) => {
  const { listSize, filters, searchTerm } = getState().trainees;

  const { start, stop, currentPage } = getPageRange(payload?.page, listSize);

  let query = db
    .from("trainee")
    .select(
      "*, assignedTrainer:assignedTrainerId(id, firstName, lastName, userId, email), productClient:productId(product(name), productId)",
      { count: "exact" },
    )
    .order("createdAt", { ascending: false });

  if (payload?.assignedTrainerId) {
    if (payload.assignedTrainerId === "none") {
      query = query.is("assignedTrainerId", null);
    } else {
      query = query.eq("assignedTrainerId", payload.assignedTrainerId);
    }
  } else if (filters.currentAssignedTrainerFilter) {
    query = query.eq("assignedTrainerId", filters.currentAssignedTrainerFilter);
  }

  if (payload?.statuses?.length) {
    query = query.in("status", payload.statuses);
  }

  if (payload?.tags?.length) {
    query = query.or(payload.tags.map((tag) => `tags.cs.["${tag}"]`).join(","));
  }

  if (payload?.accessType?.length) {
    query = query.in("type", payload.accessType);
  }

  if (!payload?.statuses && filters.currentStatusFilter?.length) {
    query = query.in("status", filters.currentStatusFilter);
  }

  if (!payload?.tags && filters.currentFilteredTags?.length) {
    query = query.or(filters.currentFilteredTags.map((tag) => `tags.cs.["${tag}"]`).join(","));
  }

  if (!payload?.accessType && filters.currentAccessType?.length) {
    query = query.in("type", filters.currentAccessType);
  }

  if (searchTerm) {
    query = query.or(`email.ilike.%${searchTerm}%,lastName.ilike.%${searchTerm}%,firstName.ilike.%${searchTerm}%`);
  }

  const { error, data, count } = await query
    .range(start, stop)
    .limit(listSize)
    .returns<TraineeWithAssignedTrainerAndProduct[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return {
    list: data,
    page: currentPage,
    totalPages: count ? Math.ceil(count / listSize) : 0,
    count,
  };
});
