import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type TrainingProgram,
  type TrainingProgramDetails,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { PROGRAM_BUILDER_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
};

export const validateProgramWithDetails = createAsyncThunk<
  { plan: TrainingProgramWithCreator; details: TrainingProgramDetails },
  Payload,
  AsyncThunkCreator<string>
>(`${PROGRAM_BUILDER_REDUCER_NAME}/validateProgramWithDetails`, async (payload, { rejectWithValue, extra: { db } }) => {
  const query = db
    .from("training_program")
    .select("*, creator:createdBy(id, firstName, lastName), details:training_program_details (*)")
    .eq("id", payload.id);

  const { error, data } = await query.single();

  if (error) {
    return rejectWithValue(error.message);
  }
  const { details, creator, ...rest } = data;

  const parsedCreator = creator as unknown as TrainingProgramWithCreator["creator"];
  const programDetails = Array.isArray(details) ? details[0] : details;

  return {
    plan: { ...(rest as TrainingProgram), creator: parsedCreator },
    details: programDetails as TrainingProgramDetails,
  };
});
