import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { removeClientTask } from "../../clientScheduledTasks/actions";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  mealsPlanId: string;
  traineeId: string;
  taskId?: string | null;
};

export const deleteScheduledNutrition = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/deleteScheduledNutrition`,
  async (payload, { dispatch, extra: { db, analytics } }) => {
    await Promise.all([
      db
        .from("client_meals_plan")
        .delete()
        .eq("id", payload.mealsPlanId)
        .eq("traineeId", payload.traineeId)
        .throwOnError(),
      db
        .from("client_meals_plan_details")
        .delete()
        .eq("planId", payload.mealsPlanId)
        .eq("traineeId", payload.traineeId)
        .throwOnError(),
    ]);

    if (payload.taskId) {
      await dispatch(removeClientTask(payload.taskId)).unwrap();
    }

    analytics.track("delete_scheduled_nutrition");
  },
);
