import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Product } from "@fitness-app/data-models/entities/Product";
import { type ProductStats } from "@fitness-app/data-models/entities/ProductStats";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchProduct } from "./actions/fetchProduct";
import { updateProduct } from "./actions/updateProduct";
import { updateProductPrices } from "./actions/updateProductPrices";
import { updateProductPricesOrder } from "./actions/updateProductPricesOrder";
import { PRODUCT_REDUCER_NAME, type ProductReducer } from "./types";

const initialState: ProductReducer = {
  productDetailsStatus: null,
  details: null,
  productError: null,
  productId: null,
  productPricesActions: null,
};

const productSlice = createSlice({
  name: PRODUCT_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductStarted(state, { payload }: PayloadAction<string>) {
      state.productDetailsStatus = RequestStatus.SUBSCRIBING;
      state.productError = null;
      state.productId = payload || null;
    },
    subscribeToProductSuccess(state) {
      state.productDetailsStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToProductFailed(state, { payload }: PayloadAction<Error>) {
      state.productDetailsStatus = RequestStatus.FAILED;
      state.productError = payload;
    },
    unsubscribeFromProductDetails() {
      return initialState;
    },
    removeProduct(state, { payload }: PayloadAction<string>) {
      if (state.details?.id === payload) {
        state.details = null;
      }
    },
    updateProductDetails(state, { payload }: PayloadAction<Product>) {
      if (state.details) {
        state.details = {
          ...state.details,
          ...payload,
        };
      }
    },
    updateProductDetailsStats(
      state,
      {
        payload,
      }: PayloadAction<{
        product_stats?: Pick<ProductStats, "totalClients" | "activeClients" | "archivedClients">;
        id: string;
      }>,
    ) {
      if (state.details && state.details.id === payload.id) {
        state.details = {
          ...state.details,
          ...payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProductPrices.pending, (state, { meta }) => {
      state.productPricesActions = meta.arg.payload.prices.map((payload) => ({
        action: payload.action,
        id: "price" in payload ? payload.price.id ?? "" : payload.priceId,
      }));
    });
    builder.addCase(updateProductPrices.fulfilled, (state) => {
      state.productPricesActions = null;
    });
    builder.addCase(updateProductPrices.rejected, (state) => {
      state.productPricesActions = null;
    });
    builder.addCase(updateProduct.pending, (state, { meta }) => {
      state.productDetailsStatus = RequestStatus.UPDATING;
      state.productId = meta.arg.payload.id || null;
    });
    builder.addCase(updateProduct.fulfilled, (state) => {
      state.productDetailsStatus = RequestStatus.SUBSCRIBED;
    });
    builder.addCase(updateProduct.rejected, (state) => {
      state.productDetailsStatus = RequestStatus.FAILED;
    });
    builder.addCase(updateProductPricesOrder.pending, (state, payload) => {
      if (state.details?.id === payload.meta.arg.productId && state.details.prices) {
        state.details.prices = payload.meta.arg.prices;
      }
    });
    builder.addCase(fetchProduct.fulfilled, (state, { payload }) => {
      state.details = payload;
    });
  },
});

export const {
  subscribeToProductFailed,
  subscribeToProductSuccess,
  subscribeToProductStarted,
  unsubscribeFromProductDetails,
  updateProductDetails,
  removeProduct,
  updateProductDetailsStats,
} = productSlice.actions;

export default productSlice.reducer;
