import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  programId: string;
  program: Partial<ClientTrainingProgram>;
};

export const updateTraineeProgram = createAsyncThunk<ClientTrainingProgram, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/updateTraineeProgram`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("client_training_program")
      .update({
        ...payload.program,
        updatedAt: new Date().toISOString(),
      })
      .eq("id", payload.programId)
      .select("*")
      .single<ClientTrainingProgram>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
