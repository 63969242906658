import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { PROGRAMS_REDUCER_NAME } from "../types";

type Payload = string;

export const removeProgram = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PROGRAMS_REDUCER_NAME}/removeProgram`,
  async (programId, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("training_program").delete().eq("id", programId);

    if (error) {
      return rejectWithValue(error.message);
    }
  },
);
