import React, { useState, type FunctionComponent } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Divider, Space } from "antd";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

import { productClientActions } from "@fitness-app/app-store";
import { type ClientInvoiceData } from "@fitness-app/data-models/entities/Order";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import InvoiceDataInfo from "~/modules/Products/components/InvoiceDataInfo/InvoiceDataInfo";
import InvoiceFormData from "~/modules/Products/components/InvoiceFormData/InvoiceFormData";
import { useAppDispatch } from "~/store/initializeStore";

interface OwnProps {
  productClient: ProductClient;
  ownerView?: boolean;
}

type Props = OwnProps;

const ProductClientInvoiceData: FunctionComponent<Props> = ({ productClient, ownerView }) => {
  const { t } = useTranslation(["products", "client", "common"]);
  const [showInvoiceForm, toggleInvoiceForm] = useState(false);
  const dispatch = useAppDispatch();
  const [loader, onSuccess, onFailure, onStart] = useEntityChange(() => {
    toggleInvoiceForm(false);
  });

  const updateInvoiceData = async (data: ClientInvoiceData) => {
    if (isEqual(data, productClient.client)) {
      toggleInvoiceForm(false);
      return;
    }
    onStart();
    await dispatch(
      productClientActions.updateInvoiceData({
        payload: {
          invoiceData: data,
          clientEmail: ownerView ? productClient.email : undefined,
        },
        onSuccess,
        onFailure,
      }),
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size={12}>
      <Divider orientation="left">{t("client:invoice.title")}</Divider>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button icon={<EditOutlined />} type="primary" onClick={() => toggleInvoiceForm(true)}>
          {t("common:button.edit")}
        </Button>
      </div>

      <InvoiceDataInfo orderClient={productClient.client} withTitle={false} />

      <ModalForm<ClientInvoiceData>
        open={showInvoiceForm}
        onCancel={() => toggleInvoiceForm(false)}
        title={t("client:invoice.formTitle")}
        okText={t("client:invoice.update")}
        loading={!!loader}
      >
        <InvoiceFormData onSubmit={updateInvoiceData} model={productClient.client} />
      </ModalForm>
    </Space>
  );
};

export default ProductClientInvoiceData;
