import React from "react";
import { PlusOutlined, RetweetOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { type WorkoutExercisesType } from "@fitness-app/data-models/entities/TrainingProgram";

import { useWorkoutActionProviderContext } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/providers/WorkoutActionProvider";
import { useProgramIsTouched } from "~/shared/providers/ProgramBuilderProvider";

interface WorkoutDayFooterProps {
  visible?: boolean;
  onMergeIntoSuperSet?: () => void;
  exercisesType: WorkoutExercisesType;
}

const WorkoutDayFooter = ({ visible = true, onMergeIntoSuperSet, exercisesType }: WorkoutDayFooterProps) => {
  const { t } = useTranslation("workouts");
  const { openExerciseForm } = useWorkoutActionProviderContext();
  const touched = useProgramIsTouched();

  if (!visible || touched) {
    return null;
  }

  return (
    <div className="mt-4 flex items-center  justify-between">
      <div className="flex-1">
        <Button
          size="small"
          type="primary"
          icon={<RetweetOutlined />}
          onClick={onMergeIntoSuperSet}
          disabled={!onMergeIntoSuperSet}
        >
          {t("workout.mergeIntoSuperies")}
        </Button>
      </div>

      <div className="flex-1 text-center">
        <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => openExerciseForm(exercisesType)}>
          {t("workout.addExercise")}
        </Button>
      </div>

      <div className="flex-1" />
    </div>
  );
};

export default WorkoutDayFooter;
