import { keyBy } from "lodash";
import ceil from "lodash.ceil";
import groupBy from "lodash.groupby";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import {
  type IngredientOnShoppingList,
  type IngredientWithPortion,
  type Measure,
} from "@fitness-app/data-models/entities/Ingredient";
import { type Meal, type NutritionPlanDay } from "@fitness-app/data-models/entities/MealsPlan";

export function selectAppropriateMeasure(ingredient: IngredientWithPortion): [Measure | null, number] {
  // If no measures available, return null and 0 multiplier
  if (ingredient.measures?.length === 0) {
    return [null, 0];
  }

  // Sort measures in ascending order based on their weight
  const sortedMeasures = [...ingredient.measures].sort((m1, m2) => m1.weightPerUnit - m2.weightPerUnit);

  // Find the first measure that is greater than or equal to the portion and calculate multiplier
  for (let i = 0; i < sortedMeasures.length; i++) {
    const weightPerUnit = sortedMeasures[i]?.weightPerUnit || 1;
    const multiplier = ceil(ingredient.portion / weightPerUnit, 1) || 1;

    if (weightPerUnit >= ingredient.portion) {
      return [sortedMeasures[i] || null, multiplier];
    }
  }

  // If no suitable measure is found, return the largest available one and calculate multiplier
  const largestMeasure = sortedMeasures[sortedMeasures.length - 1];
  const weightPerUnit = largestMeasure?.weightPerUnit || 1;

  const multiplier = ceil(ingredient.portion / weightPerUnit, 1) || 1;

  return [largestMeasure || null, multiplier];
}

const fixKalcmarCategory: Record<number, number> = {
  1: 16,
  2: 4,
  3: 2,
  4: 3,
  5: 8,
  6: 15,
  7: 6,
  8: 5,
  9: 9,
  10: 16,
  11: 1,
  12: 81,
  13: 93,
};

const mergeIntoOneCategory = (category: number, ingredient: IngredientWithPortion | DishIngredient) => {
  if (category === 61 || category === 17 || category === 18 || category === 96) {
    return 1;
  }

  if (category === 28 || category === 34 || category === 82 || category === 37 || category === 41 || category === 35) {
    return 5;
  }

  if (category === 22) {
    return 2;
  }

  if (category === 32) {
    return 4;
  }

  if (category === 53) {
    return 52;
  }

  if (category === 47) {
    return 92;
  }

  if (ingredient.name.toLowerCase().includes("orzechy") || category === 43 || category === 42) {
    return 6;
  }

  return category;
};

export const getShoppingList = (meals: (Meal & { dayId: string })[]) => {
  const ingredients: IngredientOnShoppingList[] = meals
    .map((meal) =>
      meal.dishes.map((dish) =>
        dish.type === "ingredient"
          ? {
              ...dish,
              ...(dish.source === "kcalmar" && dish.category
                ? {
                    mainCategory:
                      fixKalcmarCategory[[1, 2, 3, 4].includes(dish.category) ? dish.category + 1 : dish.category] ||
                      16,
                  }
                : { mainCategory: dish.category ? fixKalcmarCategory[dish.category] || 16 : dish.mainCategory }),
              inMeals: [
                {
                  dayId: meal.dayId,
                  mealName: meal.name,
                  mealType: meal.type,
                  dishName: dish.name,
                  dishId: dish.id,
                  mealId: meal.id,
                  dishPreparationSteps: [],
                },
              ],
              kcalmar: null,
            }
          : dish.ingredients
              .map((ingredient) => ({
                ...ingredient,
                ...(ingredient.source === "kcalmar" && ingredient.category
                  ? {
                      mainCategory:
                        fixKalcmarCategory[
                          [1, 2, 3, 4].includes(ingredient.category) ? ingredient.category + 1 : ingredient.category
                        ] || 16,
                    }
                  : {
                      mainCategory: ingredient.category
                        ? fixKalcmarCategory[ingredient.category] || 16
                        : ingredient.mainCategory,
                    }),
                inMeals: [
                  {
                    dayId: meal.dayId,
                    mealName: meal.name,
                    mealType: meal.type,
                    dishName: dish.name,
                    dishId: dish.id,
                    mealId: meal.id,
                    dishPreparationSteps: dish.preparationSteps,
                  },
                ],
                kcalmar: null,
              }))
              .map((ig) => ({ ...ig, mainCategory: mergeIntoOneCategory(ig.mainCategory || 16, ig) })),
      ),
    )
    .flat(2);

  const grouped = Object.values(groupBy<IngredientOnShoppingList>(ingredients, "ingredientId")).map(
    (filteredProducts) => {
      if (filteredProducts.length === 1) {
        return filteredProducts[0];
      }

      return filteredProducts.reduce(
        (totalPortion, currentProduct) => {
          return {
            ...totalPortion,
            ...currentProduct,
            portion: totalPortion.portion + currentProduct.portion,
            inMeals: [...totalPortion.inMeals, ...currentProduct.inMeals],
          };
        },
        { portion: 0, inMeals: [] } as unknown as IngredientOnShoppingList,
      );
    },
  );

  return {
    all: grouped as IngredientOnShoppingList[],
    byCategory: groupBy(grouped, "mainCategory") as Record<string, IngredientOnShoppingList[]>,
  };
};

export const getIngredientInMeals = (ingredient: IngredientOnShoppingList, days: NutritionPlanDay[]) => {
  const daysIds = keyBy(days, "id");

  const ingredientByDishes = groupBy(ingredient.inMeals, "dishId");

  return Object.values(ingredientByDishes).map((dish) =>
    dish.map((item) => ({ ...item, name: daysIds[item.dayId]?.name || "" })),
  );
};
