import React from "react";
import { Text, View } from "@react-pdf/renderer";

import { styles } from "./styles";

export const MACROS_COLORS = {
  calories: "#978365",
  protein: "#0A4481",
  carbs: "#e5484d",
  fat: "#FFE629",
};

export const Tag = ({ children }: { children: React.ReactNode }) => (
  <View style={styles.tag}>
    <Text style={styles.tagText}>{children}</Text>
  </View>
);

const renderDot = (color: string, size = 6) => ({
  backgroundColor: color,
  width: size,
  height: size,
  borderRadius: size / 2,
});

export const Dot = ({ color, size }: { color: string; size: number }) => <View style={renderDot(color, size)} />;

export const Spacer = ({ size = 10 }) => <View style={{ marginBottom: size }} />;
