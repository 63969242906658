import React from "react";

import ProgramBuilder from "~/modules/TrainingPrograms/ProgramBuilder/ProgramBuilder";
import TraineeProgramProvider from "~/shared/providers/TraineeProgramProvider";

interface TraineeProgramBuilderProps {
  assignedProgramId: string;
}

const TraineeProgramBuilder = ({ assignedProgramId }: TraineeProgramBuilderProps) => {
  return (
    <TraineeProgramProvider assignedProgramId={assignedProgramId}>
      <ProgramBuilder />
    </TraineeProgramProvider>
  );
};

export default TraineeProgramBuilder;
