import React, { useEffect } from "react";
import { Badge, Button, Card, Space, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { productClientsActions, RequestStatus } from "@fitness-app/app-store";
import { PaymentTypeOption } from "@fitness-app/data-models/entities/Product";
import { ClientArchiveReason, type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { FREE_STATUS_MAPPER, ONE_TIME_STATUS_MAPPER } from "~/modules/Products/constants/oneTimeStatusMapper";
import { PaymentTypeTag } from "~/modules/Products/Product/ProductClientsList/components/PaymentTypeTag";
import ProductOneTimePaymentInfo from "~/modules/Products/Product/ProductClientsList/components/ProductOneTimePaymentInfo/ProductOneTimePaymentInfo";
import ProductSubscriptionInfo from "~/modules/Products/Product/ProductClientsList/components/ProductSubscriptionInfo/ProductSubscriptionInfo";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const TraineeProducts = () => {
  const { t } = useTranslation(["products", "trainees"]);
  const traineeEmail = useAppSelector((store) => store.trainee.profile?.email);
  const navigate = useNavigate();
  const { isTrainer } = useUserRole();

  const dispatch = useAppDispatch();
  const { list, listStatus, page, listSize } = useAppSelector((store) => store.productClients);

  useEffect(() => {
    if (traineeEmail) {
      void dispatch(productClientsActions.fetchProductClients({ clientEmail: traineeEmail }));
    }
  }, [traineeEmail]);

  useEffect(() => {
    return () => {
      dispatch(productClientsActions.clearProductClients());
    };
  }, []);

  return (
    <Card bordered={false} className="!shadow-none">
      <div className="p-4">
        <Table<ProductClient>
          dataSource={list}
          rowKey="email"
          size="middle"
          scroll={{ x: true }}
          locale={{
            emptyText: t("productClients.emptyState"),
          }}
          loading={listStatus === RequestStatus.FETCHING || listStatus === RequestStatus.UPDATING}
          pagination={false}
        >
          <Table.Column<ProductClient>
            title={t("productTransactions.tableHeader.index")}
            width={50}
            dataIndex="index"
            key="index"
            render={(name, row, i) => (page > 1 ? (page - 1) * listSize + i + 1 : i + 1)}
          />
          <Table.Column<ProductClient>
            title={t("productDetails.name")}
            dataIndex="name"
            key="name"
            render={(_, row) => row.product.name}
          />
          <Table.Column
            title={t("productClients.tableHeader.createdAt")}
            dataIndex="createdAt"
            key="createdAt"
            render={(createdAt: string) => dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
          />
          <Table.Column<ProductClient>
            title={t("productTransactions.tableHeader.status")}
            dataIndex="status"
            key="status"
            render={(status: ProductClient["status"], productClient) =>
              productClient.paymentType === PaymentTypeOption.Free ? (
                <Badge status={FREE_STATUS_MAPPER[status]} text={t(`common:productStatus.${status}`)} />
              ) : (
                <Badge
                  status={ONE_TIME_STATUS_MAPPER[status]}
                  text={
                    productClient.archivedInfo?.reason === ClientArchiveReason.Refund
                      ? t(`common:subscriptionStatus.archived`)
                      : t(`common:subscriptionStatus.${status}`)
                  }
                />
              )
            }
          />
          <Table.Column<ProductClient>
            title={t("productClients.tableHeader.paymentType")}
            dataIndex="paymentType"
            key="paymentType"
            render={(type: (typeof PaymentTypeOption)[keyof typeof PaymentTypeOption], row) => (
              <Space>
                <PaymentTypeTag productClient={row} />
                {!row.upsellToProduct && row.paymentType === PaymentTypeOption.Recurring ? (
                  <ProductSubscriptionInfo accessPeriod={row.accessPeriod} status={row.status} />
                ) : null}
                {!row.upsellToProduct &&
                row.paymentType === PaymentTypeOption.OneTime &&
                row.accessPeriod?.type === "one_time" ? (
                  <ProductOneTimePaymentInfo accessPeriod={row.accessPeriod} showStrategyInfo />
                ) : null}
                {row.upsellToProduct && (
                  <>
                    <Tag color="green">Upsell do: {row.upsellToProduct.productName}</Tag>
                    <Button
                      type="link"
                      size="small"
                      onClick={() => navigate(`/products/${row.upsellToProduct?.productId}/clients/${row.email}`)}
                    >
                      Przejdź
                    </Button>
                  </>
                )}
              </Space>
            )}
          />
          {isTrainer && (
            <Table.Column<ProductClient>
              title={t("productClients.tableHeader.options")}
              dataIndex="action"
              key="action"
              render={(_, row) => (
                <Space size={4}>
                  <Button type="link" onClick={() => navigate(`/products/${row.productId}/clients/${row.id}`)}>
                    {t(`common:details`)}
                  </Button>
                </Space>
              )}
            />
          )}
        </Table>
      </div>
    </Card>
  );
};

export default TraineeProducts;
