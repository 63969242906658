import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientNote, type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_NOTES_REDUCER_NAME } from "../types";

type Payload = {
  note: ClientNote;
};

export const addClientNote = createAsyncThunk<ClientNoteWithAuthorData, Payload, AsyncThunkCreator<string>>(
  `${CLIENT_NOTES_REDUCER_NAME}/addClientNote`,
  async ({ note }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("client_note")
      .insert(note)
      .select("*, author:authorId(avatarUrl, firstName, lastName)")
      .single<ClientNoteWithAuthorData>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_client_note");

    return data;
  },
);
