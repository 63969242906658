import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import useResizeObserver from "use-resize-observer";

import { type SliderDirection } from "~/components/Upload/types";
import { getScrollPointToShowNextElement } from "./getScrollPointToShowNextElement";

interface HookConfig {
  additionalLeftOffset?: number;
  additionalRightOffset?: number;
  itemOffset: number;
  scrollToRightOnItemsChange?: boolean;
}

interface UseHorizontalSliderReturnType {
  isArrowLeftVisible: boolean;
  isArrowRightVisible: boolean;
  handleScroll: React.EventHandler<React.UIEvent<HTMLUListElement>>;
  moveToNextItem: (direction: SliderDirection) => () => void;
  contentRef: React.RefObject<HTMLUListElement> | React.LegacyRef<HTMLUListElement> | undefined;
  containerRef: (instance: null | HTMLDivElement) => void;
}

export function useHorizontalSlider<T extends unknown[]>(
  items: T,
  { additionalLeftOffset = 0, additionalRightOffset = 0, itemOffset, scrollToRightOnItemsChange = false }: HookConfig,
): UseHorizontalSliderReturnType {
  const contentRef = useRef<HTMLUListElement>(null);
  const [isArrowLeft, setArrowLeft] = useState(false);
  const [isArrowLeftVisible] = useDebounce(isArrowLeft, 100);
  const [isArrowRight, setArrowRight] = useState(false);
  const [isArrowRightVisible] = useDebounce(isArrowRight, 100);
  const { ref, width } = useResizeObserver<HTMLDivElement>();
  const previousNumberOfItems = useRef(0);

  const shouldShowArrow = (direction: SliderDirection): boolean => {
    if (!contentRef.current) {
      return false;
    }

    const { scrollLeft, scrollWidth, clientWidth } = contentRef.current;
    const baseScrollPosition = direction === "left" ? scrollLeft : scrollWidth - clientWidth - scrollLeft;

    return baseScrollPosition > 1;
  };

  const switchArrowsDisplay = useCallback(() => {
    setArrowLeft(shouldShowArrow("left"));
    setArrowRight(shouldShowArrow("right"));
  }, []);

  useEffect(() => {
    switchArrowsDisplay();
  }, [width, switchArrowsDisplay]);

  useEffect(() => {
    if (previousNumberOfItems.current !== items.length) {
      switchArrowsDisplay();

      const shouldScrollToTheLatestAddedItem =
        scrollToRightOnItemsChange && previousNumberOfItems.current < items.length && previousNumberOfItems.current > 0;

      if (contentRef.current && shouldScrollToTheLatestAddedItem) {
        const newScrollPoint = contentRef.current.scrollWidth - contentRef.current.clientWidth;
        contentRef.current.scrollLeft =
          newScrollPoint > contentRef.current.scrollLeft ? newScrollPoint : contentRef.current.scrollLeft;
      }
    }

    previousNumberOfItems.current = items.length;
  }, [items, switchArrowsDisplay, previousNumberOfItems, scrollToRightOnItemsChange]);

  const handleScroll: React.EventHandler<React.UIEvent<HTMLUListElement>> = () => {
    switchArrowsDisplay();
  };

  const moveToNextItem = (direction: SliderDirection) => (): void => {
    if (!contentRef.current) {
      return undefined;
    }

    const itemsWidth = Array.from(contentRef.current.children).map((item) => item.getBoundingClientRect().width);

    const additionalOffsetToPresentProperWithArrowButtons =
      direction === "left" ? additionalLeftOffset : additionalRightOffset;

    contentRef.current.scrollLeft = getScrollPointToShowNextElement({
      itemOffset,
      currentScrollLeftPosition: contentRef.current.scrollLeft,
      itemsListWidth: itemsWidth,
      clientWidth: contentRef.current.clientWidth,
      additionalOffset: additionalOffsetToPresentProperWithArrowButtons,
      direction,
    });

    switchArrowsDisplay();
    return undefined;
  };

  return {
    contentRef,
    isArrowRightVisible,
    isArrowLeftVisible,
    containerRef: ref,
    handleScroll,
    moveToNextItem,
  };
}
