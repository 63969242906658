import React, { useCallback, useState } from "react";

import { updateChatMessage } from "@fitness-app/app-store/src/store/reducers/chat/actions";
import { type ChatMessage } from "@fitness-app/data-models/entities/Chat";

import { useAppDispatch } from "~/store/initializeStore";

export const ChatMessageEditContext = React.createContext({
  updateMessageContent: (_content: string) => {
    return;
  },
  messageToEdit: null as null | ChatMessage,
  setMessageToEdit: (_message: null | ChatMessage) => {
    return;
  },
});

export const useChatMessageEditContext = () => React.useContext(ChatMessageEditContext);

const ChatMessageEditProvider = ({ children }: { children: React.ReactElement }) => {
  const [messageToEdit, setMessageToEdit] = useState<null | ChatMessage>(null);
  const dispatch = useAppDispatch();

  const updateMessageContent = useCallback(
    (content: string) => {
      if (messageToEdit && content.trim().length > 0) {
        void dispatch(
          updateChatMessage({
            id: messageToEdit.id,
            channelId: messageToEdit.channelId,
            authorId: messageToEdit.authorId,
            content,
          }),
        );
        setMessageToEdit(null);
      }
    },
    [messageToEdit, dispatch],
  );

  return (
    <>
      <ChatMessageEditContext.Provider
        value={{
          messageToEdit,
          updateMessageContent,
          setMessageToEdit,
        }}
      >
        {children}
      </ChatMessageEditContext.Provider>
    </>
  );
};

export default ChatMessageEditProvider;
