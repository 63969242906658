import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type ClientMealsPlanDetails, type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import {
  ProgramAutomationTaskStatus,
  ScheduledNutritionType,
  type ProgramAutomationTask,
} from "@fitness-app/data-models/entities/ProgramAutomation";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { addClientTasks } from "../../clientScheduledTasks/actions";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  nutrition: ClientNutrition;
  mealsPlanDetails: ClientMealsPlanDetails;
  traineeId: string;
};

export const addScheduledNutritionToTrainee = createAsyncThunk<ClientNutrition, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/addScheduledNutritionToTrainee`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, auth, analytics } }) => {
    const trainee = getState().trainee.profile;
    const user = await getLoggedUser(auth);

    if (trainee?.id !== payload.traineeId) {
      throw new Error("Trainee not found");
    }

    const sendDate = dayjs(payload.nutrition.startAt).set("hour", 6);
    const taskId = generateUniqId();

    const task: ProgramAutomationTask = {
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: ProgramAutomationTaskStatus.Upcoming,
      programAutomationId: "manual",
      programAutomationName: "",
      id: taskId,
      sequenceId: generateUniqId(),
      clientEmail: trainee.email,
      traineeId: trainee.id,
      addedBy: user.id,
      task: {
        type: ScheduledNutritionType.AssignedByTrainer,
        startDate: payload.nutrition.startAt,
        endDate: payload.nutrition.endAt,
        mealsPlanId: payload.nutrition.id,
        mealsPlanName: payload.nutrition.name,
        id: taskId,
      },
      sendDate: sendDate.toISOString(),
      sendAt: sendDate.unix(),
      sentAt: null,
      errorMessage: null,
    };

    payload.nutrition.metadata = {
      ...((payload.nutrition.metadata as object) || {}),
      taskId: taskId,
    };

    const { error, data } = await db
      .from("client_meals_plan")
      .insert(payload.nutrition)
      .select("*")
      .single<ClientNutrition>();

    if (error || !data) {
      return rejectWithValue(error.message);
    }

    const { error: error2 } = await db
      .from("client_meals_plan_details")
      .insert(payload.mealsPlanDetails)
      .select("*")
      .returns<ClientMealsPlanDetails[]>();

    if (error2) {
      return rejectWithValue(error2.message);
    }

    await dispatch(addClientTasks({ tasks: [task] }));
    analytics.track("add_scheduled_nutrition_to_trainee");

    return data;
  },
);
