import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Steps } from "antd";

import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import { useCampaignCreate } from "~/modules/Campaigns/CampaignForm/useCampaignCreate";
import ComposeCampaignForm from "./ComposeCampaignForm";
import { ComposeCampaignTarget } from "./ComposeCampaignTarget";
import { type CampaignMessageComposeFormModel, type SelectedUsersListItem } from "./types";

interface SendMessageToMultipleUsersModalProps {
  open: boolean;
  onClose: () => void;
}

enum Step {
  SelectUsers,
  WriteMessage,
}

export const CampaignForm = ({ open, onClose }: SendMessageToMultipleUsersModalProps): React.ReactElement => {
  const [currentStep, setStep] = useState(Step.SelectUsers);
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsersListItem[]>([]);
  const [formController] = Form.useForm<CampaignMessageComposeFormModel>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<string[]>([]);
  const allowedToSend = filteredProducts.length !== 0 || selectedTags.length !== 0 || selectedUsers.length !== 0;
  const [tagCondition, setTagCondition] = React.useState<"hasTag" | "notHasTag" | false>(false);
  const [filterByProducts, toggleFilterByProduct] = useState(false);
  const { loading, addNewCampaign } = useCampaignCreate(onClose);
  const [statuses, setStatuses] = useState<TraineeStatus[]>([TraineeStatus.ACTIVE]);

  useEffect(() => {
    formController.resetFields();
    if (!open) {
      setSelectedUsers([]);
      setStep(Step.SelectUsers);
      setTagCondition(false);
      setSelectedTags([]);
      setFilteredProducts([]);
      setStatuses([TraineeStatus.ACTIVE]);
    }
  }, [open]);

  const steps = [Step.SelectUsers, Step.SelectUsers];

  return (
    <Modal
      destroyOnClose
      open={open}
      title="Zaplanuj kampanię"
      onCancel={onClose}
      width={800}
      footer={
        <div className="flex items-center justify-end gap-3">
          {currentStep > 0 && <Button onClick={() => setStep((prev) => prev - 1)}>Cofnij</Button>}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => setStep((prev) => prev + 1)} disabled={!allowedToSend}>
              Dalej
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={() => formController.submit()} loading={!!loading}>
              Dodaj
            </Button>
          )}
        </div>
      }
    >
      <div className="py-3">
        <Steps
          current={currentStep}
          items={[
            {
              title: "Wybór grupy docelowej",
            },
            {
              title: "Treść kampanii",
            },
          ]}
        />
      </div>
      <div>
        {currentStep === Step.SelectUsers ? (
          <ComposeCampaignTarget
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            changeCondition={setTagCondition}
            condition={tagCondition}
            filterByProducts={filterByProducts}
            toggleFilterByProduct={toggleFilterByProduct}
            setFilterByProducts={setFilteredProducts}
            filteredProductIds={filteredProducts}
            statuses={statuses}
            setStatuses={setStatuses}
          />
        ) : (
          <ComposeCampaignForm
            onSubmit={(model) =>
              addNewCampaign(model, { filteredProducts, tagCondition, selectedTags, selectedUsers, statuses })
            }
            formController={formController}
          />
        )}
      </div>
    </Modal>
  );
};
