import cloneDeep from "lodash.clonedeep";

import { ExerciseType, type ExerciseTypeEnum } from "@fitness-app/data-models/entities/Exercise";
import { type ExerciseAggregateStats } from "@fitness-app/data-models/entities/ExerciseStats";
import {
  TrainingStatus,
  WeightHint,
  type DurationFormat,
  type ExerciseInWorkout,
  type ExerciseSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

import setHintValuesForSets from "./setHintValuesForSets/setHintValuesForSets";

export interface EnhancedWorkoutSet {
  repeats: number | null;
  weight: number | null;
  duration: null | {
    format: DurationFormat;
    value: number | [number, number] | null;
  };
  status: TrainingStatus;
  withHint?: WeightHint;
}

export interface SetModel extends EnhancedWorkoutSet {
  seriesKey: number;
  exerciseType: ExerciseTypeEnum;
  exerciseId: string;
  key?: string;
  name: string;
  restTime?: number;
}

export interface SuperSetModel extends EnhancedWorkoutSet {
  seriesKey: number;
  exerciseType: ExerciseTypeEnum;
  exerciseId: string;
  key: string;
  name: string;
  restTime: number;
}

export const getPropertiesValue = (property: number | [number, number], index?: number) => {
  if (Array.isArray(property)) {
    return property[typeof index === "number" ? index : 1] ?? null;
  }
  return property ?? null;
};

export const prepareSetsFormModel = (
  sets: Record<string, ExerciseSet>,
  exerciseInWorkout: ExerciseInWorkout,
  weightHint = WeightHint.DEFAULT,
  records?: ExerciseAggregateStats | null,
): SetModel[] => {
  const workoutSetWithHints = setHintValuesForSets({
    sets,
    hintType: weightHint,
    records,
    exercise: exerciseInWorkout,
  });

  return Object.entries(cloneDeep(workoutSetWithHints)).map(([key, set]) => ({
    ...set,
    exerciseType: exerciseInWorkout.exercise.type,
    exerciseId: exerciseInWorkout.id,
    name: exerciseInWorkout.exercise.name,
    seriesKey: Number(key),
    status: set.status || TrainingStatus.NEW,
    weight:
      typeof set.weight === "number" || Array.isArray(set.weight)
        ? getPropertiesValue(set.weight, 0)
        : exerciseInWorkout.exercise.type === ExerciseType.POWER
        ? 0
        : null,
    duration:
      typeof set.duration?.value !== "number"
        ? null
        : {
            ...set.duration,
            value: getPropertiesValue(set.duration.value ?? 0, 0),
          },
    repeats: set.repeats ? getPropertiesValue(set.repeats, 0) : null,
  }));
};
