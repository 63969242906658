import React, { useEffect, useMemo } from "react";
import { Alert, Button } from "antd";
import orderBy from "lodash.orderby";
import uniqBy from "lodash.uniqby";
import { useNavigate } from "react-router-dom";

import { traineeActions } from "@fitness-app/app-store";
import { compareClientAppVersion } from "@fitness-app/utils/src/users/compareClientAppVersion";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeAppVersionProps {
  userId: string;
  traineeId: string;
}

export const TraineeAppVersion = ({ userId, traineeId }: TraineeAppVersionProps) => {
  const dispatch = useAppDispatch();
  const { userStatus } = useAppSelector((state) => state.trainee);
  const appVersionConfig = useAppSelector((state) => state.team.appVersionConfig);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      void dispatch(traineeActions.fetchTraineeStatus({ userId }));
    }
  }, [userId]);

  const moreButton = useMemo(() => {
    return (
      <Button
        type="link"
        size="small"
        onClick={() => navigate(`/trainee/details/${traineeId}?tab=settings&settingsTab=devices`)}
      >
        Więcej informacji
      </Button>
    );
  }, [traineeId, navigate]);

  const userDevices = userStatus?.devices || [];

  const latestDevice = useMemo(() => {
    const sorted = uniqBy(orderBy(userDevices, "lastSeen", "desc"), "name") || [];
    const mobile = sorted.find((device) => device.type === "mobile");

    return mobile || null;
  }, [userDevices]);

  if (!appVersionConfig || !latestDevice) {
    return null;
  }

  const versionForSystem =
    latestDevice.manufacturer?.toLowerCase() === "apple" ? appVersionConfig.data.ios : appVersionConfig.data.android;

  if (!versionForSystem) {
    return null;
  }

  const versionStatus = compareClientAppVersion({
    currentUserVersion: latestDevice.version,
    requiredVersion: versionForSystem.minRequired,
    currentAvailableVersion: versionForSystem.current,
    versionsWithIssues: versionForSystem.versionsWithIssues,
  });

  if (versionStatus?.status === "critical") {
    return (
      <Alert
        banner
        showIcon
        className="flex justify-center"
        message="Klient korzysta z wersji aplikacji, która jest zbyt stara - wymagana aktualizacja."
        type="error"
        action={moreButton}
      />
    );
  }

  if (versionStatus?.status === "withIssues") {
    return (
      <Alert
        banner
        showIcon
        className="flex justify-center"
        message="Klient korzysta z wersji aplikacji, która posiada błędy - wymagana aktualizacja."
        type="error"
        action={moreButton}
      />
    );
  }

  if (versionStatus?.status === "outdated") {
    return (
      <Alert
        banner
        showIcon
        className="flex justify-center"
        message="Klient korzysta z wersji aplikacji, która nie jest najnowsza"
        type="warning"
        action={moreButton}
      />
    );
  }

  return (
    <Alert
      banner
      showIcon
      className="flex justify-center"
      message="Klient korzysta z najnowszej wersji aplikacji"
      type="info"
    />
  );
};
