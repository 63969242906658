import { useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export function useEntityChange(
  successCallback?: () => void,
): [
  boolean | string,
  (message?: string | number, type?: "success" | "warning") => void,
  (message?: string | number) => void,
  (id?: string | undefined, loadingMessage?: string) => void,
  () => void,
] {
  const [loading, toggleLoader] = useState<boolean | string>(false);
  const { t } = useTranslation("common");
  const messageId = "entityMessageId";

  const onStart = (id?: string, loadingMessage = t("messages.loading.default")) => {
    toggleLoader(id ?? true);
    void message.loading({ duration: 0, content: loadingMessage, key: messageId });
  };

  const onSuccess = (
    successMessage: string | number = t<string>("messages.success.default"),
    type?: "success" | "warning",
  ) => {
    toggleLoader(false);
    message.destroy(messageId);
    if (type === "warning") {
      void message.warning(successMessage);
    } else {
      void message.success(successMessage);
    }
    successCallback?.();
  };
  const onFailure = (errorMessage: string | number = t<string>("messages.error.default")) => {
    toggleLoader(false);
    message.destroy(messageId);
    void message.error(errorMessage);
  };

  const onEnd = () => {
    toggleLoader(false);
    message.destroy(messageId);
  };

  return [loading, onSuccess, onFailure, onStart, onEnd];
}
