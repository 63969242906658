import { type Database } from "@fitness-app/supabase";

export type ProductResourceEntity = Database["public"]["Tables"]["product_resource"]["Row"];
export type ProductResourceCategory = Database["public"]["Tables"]["product_resource_category"]["Row"];
export type ProductResourceStatusEnum = Database["public"]["Enums"]["ProductResourceStatus"];
export type ProductResourceTypeEnum = Database["public"]["Enums"]["ProductResourceType"];

export const ProductResourceStatus: Record<
  Database["public"]["Enums"]["ProductResourceStatus"],
  Database["public"]["Enums"]["ProductResourceStatus"]
> = {
  DRAFT: "DRAFT",
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
} as const;

export const ProductResourceType: Record<
  Database["public"]["Enums"]["ProductResourceType"],
  Database["public"]["Enums"]["ProductResourceType"]
> = {
  IN_APP: "IN_APP",
  NOTION: "NOTION",
} as const;

export type ProductResourceBase = ProductResourceEntity;

export interface InAppResource extends ProductResourceBase {
  attachments: { url: string; name: string; uid: string }[];
  type: "IN_APP";
}

export type InAppResourcePayload = Omit<InAppResource, "id" | "createdAt" | "updatedAt" | "notifications">;

export type ProductResource = InAppResource;
