import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ProductAutomation } from "@fitness-app/data-models/entities/Automation";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchProductAutomation } from "./actions/fetchProductAutomation";
import { PRODUCT_AUTOMATION_REDUCER_NAME, type ProductAutomationReducer } from "./types";

const initialState: ProductAutomationReducer = {
  status: null,
  data: null,
  error: null,
};

const productAutomationSlice = createSlice({
  name: PRODUCT_AUTOMATION_REDUCER_NAME,
  initialState,
  reducers: {
    subscribeToProductAutomationStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
      state.data = null;
    },
    subscribeToProductAutomationSuccess(state) {
      state.status = RequestStatus.SUBSCRIBED;
    },
    subscribeToProductAutomationFailed(state, { payload }: PayloadAction<Error>) {
      state.status = RequestStatus.FAILED;
      state.error = payload.message;
    },
    unsubscribeFromProductAutomation(state) {
      state.status = null;
      state.data = null;
      state.error = null;
    },
    updateProductAutomation(state, { payload }: PayloadAction<ProductAutomation>) {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductAutomation.fulfilled, (state, { payload }) => {
      state.status = RequestStatus.SUBSCRIBED;
      state.data = payload;
    });
  },
});

export const {
  subscribeToProductAutomationFailed,
  subscribeToProductAutomationStarted,
  subscribeToProductAutomationSuccess,
  unsubscribeFromProductAutomation,
  updateProductAutomation,
} = productAutomationSlice.actions;

export default productAutomationSlice.reducer;
