import React, { useCallback, useMemo, useState } from "react";
import { DeleteOutlined, DownOutlined, RedoOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Modal, type MenuProps } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus, workoutTemplatesActions } from "@fitness-app/app-store";
import { TrainingStatus, type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplateWithCreator } from "@fitness-app/data-models/entities/WorkoutTemplate";

import ModalForm from "~/components/ModalForm/ModalForm";
import WorkoutDay from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/WorkoutDay";
import AddWorkoutTemplateForm from "~/modules/TrainingPrograms/ProgramDayTemplates/AddWorkoutTemplateForm";
import ProgramDayTemplates from "~/modules/TrainingPrograms/ProgramDayTemplates/ProgramDayTemplates";
import { type AddWorkoutTemplateFormModel } from "~/modules/TrainingPrograms/ProgramDayTemplates/types";
import { useProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const WorkoutDayBuilder = ({ workout }: { workout: ProgramWorkout }) => {
  const { t } = useTranslation(["workouts", "common"]);
  const { data } = useAppSelector((state) => state.traineeActivities);
  const { programStatus, programDetails, programId, removeWorkoutFromProgram, replaceWorkoutInProgram } =
    useProgramBuilderContext();
  const dispatch = useAppDispatch();
  const [selectionType, setSelectionType] = useState<null | "checkbox" | "radio">(null);
  const [showWorkoutTemplatesModal, toggleWorkoutTemplatesModal] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<WorkoutTemplateWithCreator[]>([]);
  const [showTemplateForm, toggleTemplateForm] = useState(false);

  const programWorkouts = useMemo(() => programDetails?.workouts || [], [programDetails?.workouts]);

  const overwriteWorkout = useCallback(
    (workout: ProgramWorkout) => {
      if (programId) {
        void replaceWorkoutInProgram({ workoutIdToReplace: workout.id, workoutTemplate: workout });
      }
    },
    [programId],
  );

  const handleRemove = () => {
    const showConfirm = () => {
      Modal.confirm({
        title: "Czy na pewno chcesz usunąć trening w danym dniu?",
        content: "Nie będziesz mógł odzyskać tych danych!",
        okText: "Potwierdź",
        cancelText: "Anuluj",
        onOk: () => removeWorkoutFromProgram({ workoutId: workout.id }),
        onCancel() {},
      });
    };
    showConfirm();
  };

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        danger: true,
        icon: <DeleteOutlined />,
        onClick: handleRemove,
        label: t("workoutMenu.deleteWorkout"),
      },
      {
        key: "2",
        icon: <RedoOutlined />,
        onClick: () => {
          setSelectionType("radio");
          toggleWorkoutTemplatesModal(true);
        },
        label: t("workoutMenu.replaceFromTemplate"),
      },
      {
        key: "3",
        icon: <SaveOutlined />,
        onClick: () => toggleTemplateForm(true),
        label: t("workoutMenu.saveAsTemplate"),
      },
      ...programWorkouts?.map((workout) => ({
        key: workout.id,
        onClick: () => overwriteWorkout(workout),
        label: t("workoutMenu.overwriteWorkout", { name: workout.name }),
        icon: <RedoOutlined />,
      })),
    ],
    [overwriteWorkout, programWorkouts, t],
  );

  const onAddWorkoutFromTemplates = () => {
    if (selectedTemplates[0]) {
      void replaceWorkoutInProgram({
        workoutIdToReplace: workout.id,
        workoutTemplate: selectedTemplates[0],
      });
    }

    toggleWorkoutTemplatesModal(false);
    setSelectionType(null);
    setSelectedTemplates([]);
  };

  const saveWorkoutAsTemplate = (formData: AddWorkoutTemplateFormModel, workout: ProgramWorkout | undefined) => {
    void dispatch(
      workoutTemplatesActions.addWorkoutTemplate({
        template: formData,
        workout,
      }),
    );
    toggleTemplateForm(false);
  };

  const status = data.workoutStatus;
  const isTouched = data.workoutStatus
    ? [TrainingStatus.REJECTED, TrainingStatus.PARTIALLY_FULFILLED, TrainingStatus.FULFILLED].includes(status!)
    : false;

  return (
    <div>
      <Card
        loading={programStatus === RequestStatus.FETCHING}
        className="min-h-[600px]"
        extra={
          <Dropdown menu={{ items }} disabled={isTouched}>
            <Button size="small" style={{ marginBottom: 10 }}>
              Opcje <DownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <WorkoutDay workout={workout} programId={programId || null} />
      </Card>
      <Modal
        bodyStyle={{
          padding: 0,
        }}
        cancelText="Anuluj"
        okText={selectionType === "checkbox" ? "Wybierz" : "Zastąp"}
        width={950}
        open={showWorkoutTemplatesModal}
        onCancel={() => {
          toggleWorkoutTemplatesModal(false);
          setSelectionType(null);
        }}
        destroyOnClose
        okButtonProps={{
          disabled: !selectedTemplates?.length,
        }}
        onOk={onAddWorkoutFromTemplates}
      >
        <ProgramDayTemplates
          selectMode
          selectionType={selectionType || undefined}
          onRowSelect={(selectedTemplates) => setSelectedTemplates(selectedTemplates)}
        />
      </Modal>
      <ModalForm
        open={showTemplateForm}
        onCancel={() => toggleTemplateForm(false)}
        okText="Dodaj"
        title="Dodaj trening do szablonów"
      >
        <AddWorkoutTemplateForm
          onSubmit={(formData) => saveWorkoutAsTemplate(formData, workout)}
          model={
            workout
              ? {
                  name: workout.name,
                  description: workout.description,
                }
              : undefined
          }
        />
      </ModalForm>
    </div>
  );
};

export default WorkoutDayBuilder;
