import { createAsyncThunk } from "@reduxjs/toolkit";

import { type SupplementsTemplate } from "@fitness-app/data-models/entities/SupplementTemplate";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { SUPPLEMENTS_TEMPLATES_REDUCER_NAME } from "../types";
import { fetchSupplementsTemplates } from "./fetchSupplementsTemplates";

type Payload = {
  template: Omit<SupplementsTemplate, "createdBy" | "createdAt" | "updatedAt" | "id">;
};

export const addSupplementsTemplate = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${SUPPLEMENTS_TEMPLATES_REDUCER_NAME}/addSupplementsTemplate`,
  async ({ template }, { dispatch, rejectWithValue, extra: { db, auth, analytics } }) => {
    const loggedUser = await getLoggedUser(auth);

    const { error } = await db.from("supplements_template").insert({
      ...template,
      id: generateUniqId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: loggedUser.id,
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_supplements_template");

    void dispatch(fetchSupplementsTemplates());
  },
);
