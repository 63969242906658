import { createAsyncThunk } from "@reduxjs/toolkit";

import { type WorkoutActivityEvent } from "@fitness-app/data-models/entities/ProgramActivity";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  activity: Partial<WorkoutActivityEvent>;
};

export const updateWorkoutActivity = createAsyncThunk<WorkoutActivityEvent, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/updateWorkoutActivity`,
  async (payload, { rejectWithValue, extra: { db, analytics, errorTrackingService } }) => {
    const { data, error } = await db
      .from("client_workout_activity")
      .update({
        ...payload.activity,
        updatedAt: new Date().toISOString(),
      })
      .eq("id", payload.id)
      .select("*")
      .single<WorkoutActivityEvent>();

    if (error) {
      errorTrackingService?.recordError(error, "updateWorkoutActivity");
      return rejectWithValue(error.message);
    }

    analytics.track("update_workout_activity");

    return data;
  },
);
