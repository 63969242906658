import get from "lodash.get";
import times from "lodash.times";

import { ExerciseType, type Exercise } from "@fitness-app/data-models/entities/Exercise";
import {
  type ExerciseDuration,
  type ExerciseInWorkout,
  type ExerciseSet,
  type SuperSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

import i18next from "~/i18nextConfig";

export const formatDuration = (duration: ExerciseInWorkout["duration"]): number | [number, number] | null => {
  if (!duration || typeof duration.value !== "number") {
    return 0;
  }
  if (duration && Array.isArray(duration.value)) {
    return [Math.floor(duration.value[0] as number), Math.floor(duration.value[1] as number)];
  }
  return Math.floor(duration.value);
};

export const createExerciseInfo = (data?: number | [number, number] | null, unit = "") => {
  if (data === 0) return `${data}${unit}`;
  if (!data) return "-";
  if (!Array.isArray(data)) return `${data}${unit}`;
  if (data[0] === data[1]) return `${data[0]}${unit}`;
  return `${data[0]} - ${data[1]}${unit}`;
};

export const createWeightDurationCell = (exerciseInWorkout: ExerciseInWorkout) => {
  if (exerciseInWorkout.exercise.type === ExerciseType.POWER || !exerciseInWorkout.duration?.value) {
    return createExerciseInfo(exerciseInWorkout.weight, " kg");
  }
  const unit = exerciseInWorkout?.duration.format || "seconds";
  if (exerciseInWorkout.weight && exerciseInWorkout.duration) {
    return `${createExerciseInfo(exerciseInWorkout.weight, " kg")} / ${createExerciseInfo(
      formatDuration(exerciseInWorkout.duration),
      ` ${i18next.t(`workouts:duration.${unit}`)}`,
    )}`;
  }
  if (exerciseInWorkout.duration) {
    return createExerciseInfo(formatDuration(exerciseInWorkout.duration), ` ${i18next.t(`workouts:duration.${unit}`)}`);
  }
  return "-";
};

export const createDurationCell = (duration: ExerciseDuration) => {
  const unit = duration?.format || "seconds";
  if (duration) {
    return createExerciseInfo(formatDuration(duration), ` ${i18next.t(`workouts:duration.${unit}`)}`);
  }
  return "-";
};

export interface RestAfterExercise {
  type: "restAfterExercise";
  colSpan: number;
  restTime: number;
  rowKey: string;
  rowSpan: number;
  series: number;
}

export interface RestAfterSeries {
  type: "restAfterSeries";
  colSpan: number;
  restTime: number;
  rowKey: string;
  rowSpan?: never;
}

export type ExerciseLineSet = ExerciseSet &
  Pick<Exercise, "name" | "type" | "iconName"> & {
    series: number;
    restTime: number;
    rowKey: string;
    rowSpan: number;
    colSpan?: never;
    exercise: ExerciseInWorkout;
  };

export type MergedSeriesData = ExerciseLineSet | RestAfterExercise | RestAfterSeries;
export const prepareSuperSetData = (superSet: SuperSet, colSpan = 4) => {
  const mergedSeries: MergedSeriesData[] = [];
  times(superSet.numberOfSeries, (index: number) => {
    const numberOfSeries = index + 1;
    superSet.mergedExercises.forEach((exercise, exerciseIndex) => {
      const numberOfRestAfterSet = superSet.mergedExercises.length - 1;
      mergedSeries.push({
        ...exercise.set[numberOfSeries],
        repeats: exercise.set[numberOfSeries]?.repeats ?? 0,
        weight: exercise.set[numberOfSeries]?.weight ?? 0,
        type: exercise.exercise.type,
        iconName: exercise.exercise.iconName,
        name: get(exercise, "exercise.name"),
        restTime: exercise.restTime ?? 60,
        rowKey: `${index}-${exerciseIndex}-${exercise.id}`,
        series: numberOfSeries,
        rowSpan: exerciseIndex === 0 ? superSet.mergedExercises.length + numberOfRestAfterSet : 0,
        exercise,
      });
      if (exerciseIndex < superSet.mergedExercises.length - 1) {
        mergedSeries.push({
          type: "restAfterExercise",
          colSpan: colSpan - 1,
          restTime: exercise.restTime ?? 60,
          rowKey: `${index}-${exerciseIndex}-rest-${exercise.id}`,
          series: numberOfSeries,
          rowSpan: 0,
        });
      }
    });
    if (index < superSet.numberOfSeries - 1)
      mergedSeries.push({
        type: "restAfterSeries",
        colSpan,
        rowKey: `${index}-rest-series`,
        restTime: mergedSeries?.at(-1)?.restTime ?? 60,
      });
  });
  return mergedSeries;
};
