import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = string;

export const deleteDish = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/deleteDish`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("dish").delete().eq("id", payload);

    if (error) {
      return rejectWithValue(error.message);
    }

    return payload;
  },
);
