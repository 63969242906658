import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type ChatChannelWithLastMessage,
  type ChatStatus,
  type ChatType,
} from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";
import { subscribeToLatestChannel } from "./subscribeToLatestChannel";

type Payload = {
  status: ChatStatus;
  searchValue?: string;
  type?: ChatType[];
};

export const fetchInitialChatListPage = createAsyncThunk<
  ChatChannelWithLastMessage[],
  Payload,
  AsyncThunkCreator<string>
>(
  `${CHAT_REDUCER_NAME}/fetchInitialChatListPage`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { channelsSize } = getState().chat;

    let query = db
      .from("chat_channel")
      .select(
        "*, lastMessage:lastMessageId(id, content, createdAt), profiles:user_profile!inner(avatarUrl, firstName, lastName, id, email))",
      )
      .order("updatedAt", { ascending: false })
      .contains("assignedUsers", [currentUser.id])
      .eq("status", payload.status);

    if (payload.searchValue) {
      query = query = query.or(
        `lastName.ilike.%${payload.searchValue}%,email.ilike.%${payload.searchValue}%,firstName.ilike.%${payload.searchValue}%`,
        {
          referencedTable: "user_profile",
        },
      );
    }

    if (payload.type) {
      query = query.in("type", payload.type);
    }

    const { error, data } = await query.limit(channelsSize).returns<ChatChannelWithLastMessage[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    dispatch(subscribeToLatestChannel());

    return data;
  },
);
