import React, { memo, useMemo, useState } from "react";
import { ExperimentOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Space, Tabs } from "antd";
import dayjs from "dayjs";
import truncate from "lodash.truncate";
import { Salad } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import ChatMessageFormProvider from "~/modules/Chat/components/ChatNotificationContext/ChatMessageFormContext";
import { useAddNutritionToTrainee } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useAddNutritionToTrainee";
import { useArchivedNutritionPlans } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useArchivedNutritionPlans";
import { useGenerateNutrition } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useGenerateNutrition";
import { useScheduledNutritionPlans } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useScheduledNutritionPlans";
import { TraineeNutritionCreator } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionCreator";
import { TraineeNutritionDraftsList } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionDraftsList";
import TraineeNutritionForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionForm";
import TraineeNutritionGeneratorForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionGeneratorForm";
import TraineeNutritionTabs from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineeNutritionTabs";
import { useAppSelector } from "~/store/initializeStore";

const TraineeNutrition = () => {
  const { t } = useTranslation(["nutrition", "common"]);
  const traineeProfile = useAppSelector((store) => store.trainee.profile);
  const { isTrainer, isTeamMember, capabilities } = useUserRole();
  const [showNutritionForm, toggleNutritionForm] = useState<boolean | "scheduled">(false);
  const [showNutritionGenerator, toggleNutritionGenerator] = useState<boolean | "scheduled">(false);
  const { scheduled } = useScheduledNutritionPlans(traineeProfile?.id);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromUrl = searchParams.get("planId") || "";
  const template = searchParams.get("template") || "";
  const activeTab = tabFromUrl || traineeProfile?.activeMealsPlanId || undefined;
  const { archived } = useArchivedNutritionPlans(traineeProfile?.id);
  const [showArchived, setShowArchived] = useState(false);
  const { loading, addNutritionToTrainee } = useAddNutritionToTrainee(showNutritionForm, traineeProfile, () => {
    toggleNutritionForm(false);
  });
  const { generating, generateNutrition } = useGenerateNutrition(() => {
    toggleNutritionGenerator(false);
  });

  const onEdit = (_targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
    if (action === "add") {
      toggleNutritionForm("scheduled");
    }
  };

  const initialChatMessageAboutNutritionUpdate = useMemo(
    () =>
      `Hej ${
        traineeProfile?.firstName || ""
      }, Twój plan żywieniowy został właśnie zaktualizowany. Najnowsze zmiany są już dostępne w aplikacji.`,
    [traineeProfile],
  );

  if (template && tabFromUrl && traineeProfile) {
    return (
      <TraineeNutritionCreator
        traineeId={traineeProfile.id}
        nutritionId={tabFromUrl}
        openCurrent={(id?: string) =>
          setSearchParams({
            planId: id || traineeProfile.activeMealsPlanId || "empty",
            tab: "nutrition",
            nutritionTab: "schedule",
          })
        }
      />
    );
  }

  if (!traineeProfile?.activeMealsPlanId) {
    return (
      <div className="py-16">
        <Empty
          image={<Salad size={48} color="#333" className="align-text-bottom" />}
          imageStyle={{ marginBottom: 0 }}
          description={
            <Space direction="vertical" size={12}>
              <span>{t("trainee.lackOfNutrition")}</span>
              <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => toggleNutritionForm(true)}>
                {t("trainee.addNutrition")}
              </Button>
              {isTrainer || (isTeamMember && capabilities.nutritionGenerator) ? (
                <Button
                  size="small"
                  type="primary"
                  icon={<ExperimentOutlined />}
                  onClick={() => toggleNutritionGenerator(true)}
                >
                  Generuj dietę
                </Button>
              ) : null}
              {traineeProfile && <TraineeNutritionDraftsList traineeId={traineeProfile.id} />}
            </Space>
          }
        />
        <ModalForm
          open={Boolean(showNutritionForm)}
          loading={!!loading}
          onCancel={() => toggleNutritionForm(false)}
          title={t("mealsPlan.addNewTitle")}
          width={850}
        >
          <TraineeNutritionForm traineeId={traineeProfile?.id || ""} onSubmit={addNutritionToTrainee} />
        </ModalForm>
        <ModalForm
          open={Boolean(showNutritionGenerator)}
          loading={!!loading}
          onCancel={() => toggleNutritionGenerator(false)}
          title="Generuj nowy jadłospis"
          width={850}
          okText="Generuj"
        >
          <TraineeNutritionGeneratorForm traineeId={traineeProfile?.id || ""} onSubmit={generateNutrition} />
        </ModalForm>
      </div>
    );
  }

  return (
    <ChatMessageFormProvider initialMessage={initialChatMessageAboutNutritionUpdate}>
      <div className="min-h-[600px] p-4">
        <Tabs
          tabPosition="top"
          activeKey={activeTab}
          type="editable-card"
          onEdit={onEdit}
          tabBarExtraContent={{
            left: archived?.length ? (
              <Button
                type="dashed"
                size="small"
                className="mb-2 mr-2"
                onClick={() => {
                  if (showArchived) {
                    setSearchParams({
                      planId: traineeProfile.activeMealsPlanId || "empty",
                      tab: "nutrition",
                      nutritionTab: "schedule",
                    });
                  }
                  setShowArchived((prev) => !prev);
                }}
              >
                {showArchived ? "Ukryj archiwalne" : "Pokaż archiwalne"}
              </Button>
            ) : null,
          }}
          onChange={(planId) => setSearchParams({ planId, tab: "nutrition", nutritionTab: "schedule" })}
        >
          {showArchived
            ? archived?.map((plan) => (
                <Tabs.TabPane
                  destroyInactiveTabPane
                  closable={false}
                  tab={`Od ${dayjs(plan.startAt).format("DD.MM.YYYY")} - ${truncate(plan.name, { length: 20 })}`}
                  key={plan.id}
                >
                  <TraineeNutritionTabs
                    traineeId={traineeProfile.id}
                    assignedPlanId={plan.id}
                    type={ClientNutritionStatus.Archived}
                    openCurrent={() =>
                      setSearchParams({
                        planId: traineeProfile.activeMealsPlanId || "empty",
                        tab: "nutrition",
                        nutritionTab: "schedule",
                      })
                    }
                  />
                </Tabs.TabPane>
              ))
            : null}
          <Tabs.TabPane
            closable={false}
            destroyInactiveTabPane
            tab={
              traineeProfile.nutrition
                ? `Aktualna dieta (od ${dayjs(traineeProfile.nutrition?.startAt).format("DD.MM")})`
                : "Aktualna dieta"
            }
            key={traineeProfile.activeMealsPlanId}
          >
            <TraineeNutritionTabs
              type={ClientNutritionStatus.InProgress}
              traineeId={traineeProfile.id}
              assignedPlanId={traineeProfile.activeMealsPlanId}
              openCurrent={(id?: string) =>
                setSearchParams({
                  planId: id || traineeProfile.activeMealsPlanId || "empty",
                  tab: "nutrition",
                  nutritionTab: "schedule",
                })
              }
            />
          </Tabs.TabPane>
          {scheduled?.map((plan) => (
            <Tabs.TabPane
              destroyInactiveTabPane
              closable={false}
              tab={`Od ${dayjs(plan.startAt).format("DD.MM.YYYY")} - ${truncate(plan.name, { length: 20 })}`}
              key={plan.id}
            >
              <TraineeNutritionTabs
                traineeId={traineeProfile.id}
                type={ClientNutritionStatus.Scheduled}
                assignedPlanId={plan.id}
                openCurrent={() =>
                  setSearchParams({
                    planId: traineeProfile.activeMealsPlanId || "empty",
                    tab: "nutrition",
                    nutritionTab: "schedule",
                  })
                }
              />
            </Tabs.TabPane>
          ))}
        </Tabs>

        <ModalForm
          open={Boolean(showNutritionForm)}
          loading={!!loading}
          onCancel={() => toggleNutritionForm(false)}
          title={t("mealsPlan.addNewTitle")}
          width={850}
        >
          <TraineeNutritionForm
            inFuture={showNutritionForm === "scheduled"}
            traineeId={traineeProfile?.id || ""}
            onSubmit={addNutritionToTrainee}
          />
        </ModalForm>

        <ModalForm
          open={Boolean(showNutritionGenerator)}
          loading={!!generating}
          onCancel={() => toggleNutritionGenerator(false)}
          title="Generuj nowy jadłospis"
          okText="Generuj"
          width={850}
        >
          <TraineeNutritionGeneratorForm traineeId={traineeProfile?.id || ""} onSubmit={generateNutrition} />
        </ModalForm>
      </div>
    </ChatMessageFormProvider>
  );
};

export default memo(TraineeNutrition);
