import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { AuthorTypeEnum } from "@fitness-app/data-models/entities/AuthorTypeEnum";
import { MeasurementType, type TrainingFeedback } from "@fitness-app/data-models/entities/Measurement";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutActivityRate } from "../../traineeActivities/reducer";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  data: TrainingFeedback["data"];
  eventDate: string;
  traineeId: string;
  id?: null | string;
  userId?: string | null;
  activityId?: string | null;
};

export const upsertTrainingFeedback = createAsyncThunk<TrainingFeedback, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/upsertTrainingFeedback`,
  async (payload, { rejectWithValue, dispatch, extra: { db, auth, analytics, errorTrackingService } }) => {
    const user = await getLoggedUser(auth);

    if (payload.id) {
      const { data, error } = await db
        .from("measurement")
        .update({
          data: payload.data,
          updatedAt: new Date().toISOString(),
        })
        .eq("id", payload.id)
        .select("*")
        .single<TrainingFeedback>();

      if (error) {
        errorTrackingService?.recordError(error, "updateTrainingFeedback");
        return rejectWithValue(error.message);
      }

      analytics.track("update_training_rate", { rate: payload.data.rate, status: payload.data.trainingStatus });

      dispatch(updateWorkoutActivityRate(data));
      return data;
    }

    const measurement: TrainingFeedback = {
      data: payload.data,
      type: MeasurementType.FeedbackAboutTraining,
      eventDate: payload.eventDate,
      eventTimestamp: dayjs(payload.eventDate, "YYYY-MM-DD").hour(12).toISOString(),
      traineeId: payload.traineeId,
      creator: user.id === payload.userId ? AuthorTypeEnum.Client : AuthorTypeEnum.Trainer,
      id: generateUniqId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      userId: payload.userId || null,
    };

    const { error, data } = await db.from("measurement").insert(measurement).select("*").single<TrainingFeedback>();

    if (payload.activityId) {
      await db.from("client_workout_activity").update({ feedbackId: measurement.id }).eq("id", payload.activityId);
    }

    if (error) {
      errorTrackingService?.recordError(error, "addTrainingFeedback");
      return rejectWithValue(error.message);
    }

    analytics.track("add_training_rate", { rate: payload.data.rate, status: payload.data.trainingStatus });
    dispatch(updateWorkoutActivityRate(data));

    return data;
  },
);
