import React, { useState } from "react";
import { VideoCameraAddOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { type RcFile } from "antd/lib/upload";
import { type UploadFile } from "antd/lib/upload/interface";
import deburr from "lodash.deburr";
import last from "lodash.last";
import { useTranslation } from "react-i18next";
import * as tus from "tus-js-client";
import { v4 as uuid } from "uuid";

import { mediaLibraryActions } from "@fitness-app/app-store";
import { type MediaLibraryResourceType } from "@fitness-app/data-models/entities/MediaLibrary";

import {
  type UploadProgressEvent,
  type UploadRequestError,
  type UploadRequestHeader,
  type UploadRequestMethod,
} from "~/components/UploadField/types";
import { useAppDispatch } from "~/store/initializeStore";

interface VideoUploadProps {
  limitInMB?: number;
  height?: number;
  multiple?: boolean;
  fileList?: { url: string; name: string; uid: string }[];
  type: MediaLibraryResourceType;
}

type BeforeUploadFileType = File | Blob | boolean | string;

export interface UploadRequestOption<T = File> {
  onProgress?: (event: UploadProgressEvent) => void;
  onError?: (event: UploadRequestError | ProgressEvent, body?: T) => void;
  onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
  data?: object;
  filename?: string;
  file: Exclude<BeforeUploadFileType, File | boolean> | RcFile;
  withCredentials?: boolean;
  action: string;
  headers?: UploadRequestHeader;
  method: UploadRequestMethod;
}

const VideoUploadList = ({
  limitInMB = 4000,
  height,
  multiple,
  fileList: defaultFileList,
  type,
}: VideoUploadProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const [fileList, updateFileList] = useState<UploadFile<File>[]>(() => {
    if (defaultFileList?.length) {
      return defaultFileList.map(
        (item) =>
          ({
            url: item.url,
            name: item.name,
            size: 0,
            uid: item.uid,
            status: "done",
          }) as UploadFile<File>,
      );
    }

    return [];
  });
  const dispatch = useAppDispatch();

  const uploadToStorage = async ({ file }: UploadRequestOption) => {
    if (typeof file === "string" || (file instanceof Blob && !("uid" in file))) {
      void message.error("Nie można przesłać pliku");
      return;
    }

    try {
      const id = uuid();

      updateFileList((prev) => [
        ...prev,
        {
          type: file.type,
          name: deburr(file.name),
          uid: file.uid,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          size: file.size,
          percent: 0,
          status: "uploading",
        },
      ]);

      const response = await dispatch(
        mediaLibraryActions.addFileToMediaLibrary({
          type: file.type,
          title: deburr(file.name),
          id,
          size: file.size,
          resourceType: type,
        }),
      ).unwrap();

      const upload = new tus.Upload(file, {
        endpoint: "https://video.bunnycdn.com/tusupload",
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: response.signature,
          AuthorizationExpire: String(response.expire),
          VideoId: response.id,
          LibraryId: response.libraryId,
        },
        metadata: {
          filetype: file.type,
          title: deburr(file.name),
          entityId: id,
        },
        onError(error) {
          updateFileList((prev) =>
            prev.map((savedFile) =>
              savedFile.uid === file.uid ? { ...savedFile, status: "error", error } : savedFile,
            ),
          );
        },
        onProgress(bytesUploaded, bytesTotal) {
          const percent = Math.round((bytesUploaded / bytesTotal) * 100);
          updateFileList((prev) =>
            prev.map((savedFile) => (savedFile.uid === file.uid ? { ...savedFile, percent } : savedFile)),
          );
        },
        onSuccess() {
          updateFileList((prev) => prev.filter((savedFile) => savedFile.uid !== file.uid));
        },
      });

      void upload.findPreviousUploads().then(function (previousUploads) {
        if (previousUploads.length && previousUploads[0]) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        upload.start();
      });
    } catch (e) {
      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            return {
              ...savedFile,
              status: "error",
            };
          }

          return savedFile;
        }),
      );
    }
  };

  const beforeUpload = (file: RcFile, _: RcFile[]) => {
    const validationType =
      file.type === "video/mp4" ||
      file.type === "video/mpeg" ||
      file.type === "video/3gpp" ||
      file.type === "video/ogg" ||
      file.type === "video/mp2t" ||
      file.type === "video/webm" ||
      file.type === "video/quicktime" ||
      file.type === "video/x-msvideo";
    const extension = last(file.name.split("."))?.toLowerCase();
    const allowedExtension = ["mov", "mp4"];
    if (!extension || (!validationType && !allowedExtension.includes(extension))) {
      void message.error("Rozszerzenie pliku jest niedopuszczalne, wyślij plik w formacie mp4/mpeg/mov/webm/ogg/ts.");
    }
    const isToLarge = file.size / 1024 / 1024 > limitInMB;
    if (isToLarge) {
      void message.error(`Plik nie może być większy niż ${limitInMB}MB.`);
    }

    return Boolean((validationType || (extension && allowedExtension.includes(extension))) && !isToLarge);
  };

  const renderFileStatus = () => {
    return (
      <>
        <p className="ant-upload-drag-icon">
          <VideoCameraAddOutlined />
        </p>
        <p className="ant-upload-text">{t("upload.clickOrDrag")}</p>
        <p className="ant-upload-hint">{t("upload.videoUpload")}</p>
      </>
    );
  };

  return (
    <Upload.Dragger
      className="custom-dragger"
      beforeUpload={beforeUpload}
      customRequest={uploadToStorage}
      multiple={multiple ?? false}
      fileList={fileList}
      style={{ minHeight: height || 400, width: "100%", display: "flex" }}
    >
      <div className="flex flex-col items-center justify-center">{renderFileStatus()}</div>
    </Upload.Dragger>
  );
};

export default VideoUploadList;
