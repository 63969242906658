import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";
import { createProgramDetailsTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { PROGRAMS_REDUCER_NAME } from "../types";
import { fetchPrograms } from "./fetchPrograms";

type Payload = {
  program: TrainingProgram;
};

export const addProgram = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${PROGRAMS_REDUCER_NAME}/addProgram`,
  async ({ program }, { dispatch, rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db.from("training_program").insert(program);

    await db.from("training_program_details").insert(createProgramDetailsTemplate(program.id));

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_workout_program");

    void dispatch(fetchPrograms());

    return program.id;
  },
);
