import React, { useEffect, useMemo, useState } from "react";
import { LineChart } from "@tremor/react";
import { Alert, Card, Select } from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash.isempty";
import sortBy from "lodash.sortby";
import { useTranslation } from "react-i18next";

import { RequestStatus, traineeMeasurementsActions } from "@fitness-app/app-store";
import { BodyMeasurement, MeasurementType, type BodyMeasurements } from "@fitness-app/data-models/entities/Measurement";
import { BODY_FIELDS } from "@fitness-app/data-models/shared/BodyFields";
import { getErrorMessage } from "@fitness-app/utils";

import { type CommonChartProps } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const valueFormatterAbsolute = (number: number) => `${Intl.NumberFormat("pl").format(number).toString()} cm`;
const excludedMeasurements = [
  BodyMeasurement.BODY_FAT,
  BodyMeasurement.NECK,
  BodyMeasurement.CALF_LEFT,
  BodyMeasurement.CALF_RIGHT,
];

type BodyMeasurementsChartProps = CommonChartProps;
const BodyMeasurementsChart = ({ range }: BodyMeasurementsChartProps) => {
  const { t } = useTranslation(["trainees", "common"]);
  const [selectedBodyPart, selectBodyPart] = useState<BodyMeasurement[]>([
    ...Object.values(BodyMeasurement).filter((value) => !excludedMeasurements.includes(value)),
  ]);
  const traineeId = useAppSelector((state) => state.trainee.profile?.id || "");
  const dispatch = useAppDispatch();
  const [measurements, setMeasurements] = useState<BodyMeasurements[]>([]);
  const [requestState, setRequestState] = useState<{ status: RequestStatus; error: null | string }>({
    error: null,
    status: RequestStatus.IDLE,
  });

  const chartData: { [key: string]: number | string; date: string }[] = useMemo(() => {
    return measurements.map((measurement) => ({
      date: dayjs(measurement.eventTimestamp).format("DD.MM.YYYY"),
      ...Object.keys(measurement.data).reduce<Record<string, number>>((acc, key) => {
        if (selectedBodyPart.includes(key as BodyMeasurement)) {
          acc[t(`table.${key}`)] = measurement.data[key as BodyMeasurement] || 0;
        }
        return acc;
      }, {}),
    }));
  }, [measurements]);

  const options = sortBy(
    BODY_FIELDS.filter((value) => !excludedMeasurements.includes(value.name)).map((value) => value.name),
  );

  const fetchMeasurements = async (dateStart: Date, dateEnd: Date) => {
    try {
      setRequestState({ error: null, status: RequestStatus.FETCHING });
      const response = await dispatch(
        traineeMeasurementsActions.fetchMeasurementsForRangeAndType({
          dateStart,
          dateEnd,
          type: MeasurementType.BodyMeasurements,
          traineeId,
        }),
      ).unwrap();
      setMeasurements(response as BodyMeasurements[]);
      setRequestState({ error: null, status: RequestStatus.SUCCESS });
    } catch (e) {
      setRequestState({ error: getErrorMessage(e), status: RequestStatus.FAILED });
    }
  };

  useEffect(() => {
    if (range?.[0] && range?.[1]) {
      void fetchMeasurements(range[0].startOf("day").toDate(), range[1].endOf("day").toDate());
    }
  }, [range]);

  return (
    <Card
      title="Obwody ciała"
      type="inner"
      className="relative"
      loading={requestState.status === RequestStatus.FETCHING}
      extra={
        <Select
          mode="multiple"
          placeholder="Wybierz część ciała"
          labelInValue
          value={selectedBodyPart.map((item) => ({ value: item, label: t(`table.${item}`) }))}
          style={{ marginLeft: "15px", width: 300 }}
          onChange={(selected: { value: BodyMeasurement; label: string }[]) =>
            selectBodyPart(selected.map((item) => item.value))
          }
          allowClear
          notFoundContent={null}
          maxTagCount="responsive"
        >
          {options.map((item) => (
            <Select.Option key={item} value={item} label={t<string>(`table.${item}`)}>
              {t(`table.${item}`)}
            </Select.Option>
          ))}
        </Select>
      }
    >
      <LineChart
        className="mt-8 h-80"
        data={chartData}
        index="date"
        categories={selectedBodyPart.map((item) => t(`table.${item}`))}
        showLegend={true}
        valueFormatter={valueFormatterAbsolute}
        yAxisWidth={60}
        curveType="monotone"
        autoMinValue
        intervalType={"preserveStartEnd"}
        allowDecimals={false}
      />

      {requestState.status === RequestStatus.FAILED && (
        <Alert
          message={requestState.error || "Wystąpił błąd podczas pobierania danych"}
          type="error"
          showIcon
          className="absolute left-10 right-10 top-[55%]"
        />
      )}
      {isEmpty(chartData) && requestState.status !== RequestStatus.FAILED && (
        <Alert
          message="Brak informacji na temat pomiarów ciała dla wybranego okresu"
          type="warning"
          showIcon
          className="absolute left-10 right-10 top-[55%]"
        />
      )}
    </Card>
  );
};

export default BodyMeasurementsChart;
