import React from "react";
import { Badge, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { ExerciseRecordingStatus } from "@fitness-app/data-models/entities/ExerciseRecording";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import TraineeStatusBadge from "~/modules/Trainee/components/TraineeStatusBadge/TraineeStatusBadge";

export const createStatusOptions = () => {
  const list: { value: TraineeStatus; text: React.ReactElement }[] = [];
  Object.values(TraineeStatus).forEach((item) => {
    list.push({ value: item, text: <TraineeStatusBadge status={item} /> });
  });
  return list;
};

export const AppAccessTypeBadge = ({ type }: { type: AppAccessType }) => {
  const { t } = useTranslation("trainees");

  return <Tag color={type === AppAccessType.FullAccess ? "success" : "warning"}>{t(`accessType.${type}`)}</Tag>;
};
export const createAppAccessTypeOptions = () => {
  const list: { value: AppAccessType; text: React.ReactElement }[] = [];
  Object.values(AppAccessType).forEach((item) => {
    list.push({ value: item, text: <AppAccessTypeBadge type={item} /> });
  });
  return list;
};

const statusMapper: Partial<Record<ExerciseRecordingStatus, { status: "processing" | "success" }>> = {
  [ExerciseRecordingStatus.RECORDED]: {
    status: "processing",
  },
  [ExerciseRecordingStatus.RATED]: {
    status: "success",
  },
} as const;

export const createRecordingStatusOptions = (t: (text: string) => string) => {
  const list: { value: ExerciseRecordingStatus; text: React.ReactElement }[] = [];
  [ExerciseRecordingStatus.RATED, ExerciseRecordingStatus.RECORDED, ExerciseRecordingStatus.SCHEDULED].forEach(
    (item) => {
      list.push({
        value: item,
        text: (
          <Badge
            status={statusMapper[item]?.status || "default"}
            text={t(`exerciseRecordingStatus.${item}`)}
            className="whitespace-nowrap"
          />
        ),
      });
    },
  );
  return list;
};
