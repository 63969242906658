import { type ProgramAutomationTask } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type RequestStatus } from "../../../enums/requestStatus";

export const CLIENT_SCHEDULED_TASKS_REDUCER_NAME = "clientScheduledTasks";

type Year = number;
type Month = number;
export type SelectedMonth = `${Year}-${Month}`;

export interface ClientScheduledTasksReducer {
  list: ProgramAutomationTask[];
  listStatus: RequestStatus | null;
  selectedMonth: null | SelectedMonth;
  selectedTask: ProgramAutomationTask | null;
}
