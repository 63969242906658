import { useAppSelector } from "../store/initializeStore";

export const useTagOptions = (
  key:
    | "mealsPlanTags"
    | "trainingProgramTags"
    | "mealDaysTags"
    | "traineeTags"
    | "workoutTags"
    | "specializationTags"
    | "exercisesTags",
) => {
  const { trainerConfig } = useAppSelector((state) => state.team);

  const options = (trainerConfig?.[key] ?? []).map((tag) => ({ label: tag, value: tag }));
  const filters = (trainerConfig?.[key] ?? []).map((tag) => ({ text: tag, value: tag }));

  return { options, filters };
};
