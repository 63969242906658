import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Product } from "@fitness-app/data-models/entities/Product";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { type ProductWithStats } from "../../products/types";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = string;

export const fetchProduct = createAsyncThunk<Product, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_REDUCER_NAME}/fetchProduct`,
  async (productId, { rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("product")
      .select("*, product_stats(activeClients, archivedClients, totalClients)")
      .order("createdAt", { ascending: false })
      .eq("owner", currentUser.id)
      .eq("id", productId)
      .single<ProductWithStats>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
