import { type TeamMember } from "@fitness-app/data-models";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import { getUserClaims } from "../../user/selectors";
import {
  subscribeToTeamMemberFailed,
  subscribeToTeamMemberPending,
  subscribeToTeamMemberSuccess,
  updateTeamMember,
} from "../reducer";
import { fetchTeamMember } from "./fetchTeamMember";

export const subscribeToTeamMember =
  (memberIdFromParam?: string): AppThunk =>
  async (dispatch, getState, { db, auth, subscriptions }) => {
    try {
      dispatch(subscribeToTeamMemberPending());
      const memberId = getUserClaims(getState()).memberId || memberIdFromParam;

      if (!memberId) {
        dispatch(subscribeToTeamMemberFailed());
        return;
      }

      await dispatch(fetchTeamMember(memberId));

      const { id } = await getLoggedUser(auth);

      if (subscriptions.teamMemberListChannelName && subscriptions.realtimeTeamMemberListChannel) {
        await db.removeChannel(subscriptions.realtimeTeamMemberListChannel);
      }

      subscriptions.teamMemberListChannelName = `team-member-${id}`;
      subscriptions.realtimeTeamMemberListChannel = db.channel(subscriptions.teamMemberListChannelName);

      subscriptions.realtimeTeamMemberListChannel
        .on<TeamMember>(
          "postgres_changes",
          { event: "*", schema: "public", table: "team_member", filter: `id=eq.${memberId}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateTeamMember(payload.new));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToTeamMemberSuccess());
          }
        });
    } catch {
      dispatch(subscribeToTeamMemberFailed());
    }
  };
