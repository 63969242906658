import {
  type ClientNotifications,
  type ClientReminders,
  type OwnerNotifications,
  type TrainerNotifications,
} from "@fitness-app/data-models/entities/Notification";

import { type RequestStatus } from "../../../enums/requestStatus";

export const NOTIFICATIONS_REDUCER_NAME = "notifications";

export enum NotificationAction {
  MarkAsRead = "MARK_AS_READ",
}

export interface NotificationsReducer {
  notifications: (ClientNotifications | OwnerNotifications | TrainerNotifications | ClientReminders)[];
  listStatus: null | RequestStatus;
  totalUnread: number;
  filter: "all" | "unread";
  performingAction: null | {
    action: NotificationAction;
    notificationId?: string;
  };
  page: number;
  pageSize: number;
  totalPages: number;
}
