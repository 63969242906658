export const createClientDiscordIntegrationLink = ({
  clientId,
  functionDomain,
  state,
}: {
  clientId: string;
  functionDomain: string;
  state: string;
}) => {
  const redirectUri = encodeURIComponent(`${functionDomain}/integrations-verify/discord/redirect`);

  return `https://discord.com/oauth2/authorize?permissions=0&response_type=code&client_id=${clientId}&state=${state}&scope=guilds.join%20identify&redirect_uri=${redirectUri}`;
};

export const createBotDiscordIntegrationLink = ({ clientId, serverId }: { clientId: string; serverId: string }) => {
  return `https://discord.com/api/oauth2/authorize?client_id=${clientId}&permissions=103347653687&scope=bot&disable_guild_select=true&guild_id=${serverId}`;
};

export const createClientDiscordIntegrationLinkForEmail = ({
  functionDomain,
  state,
  source,
}: {
  functionDomain: string;
  state: string;
  source?: "api" | "panel";
}) => `${functionDomain}/integrations-verify/discord/redirect-to-discord?state=${state}&source=${source || "unknown"}`;
