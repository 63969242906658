import React from "react";
import { Card } from "@tremor/react";
import { DatePicker, Select } from "antd";
import { type RangePickerProps } from "antd/es/date-picker";
import dayjs, { type Dayjs } from "dayjs";
import { AreaChart, CreditCard, DollarSign, ShoppingCart } from "lucide-react";
import { useTranslation } from "react-i18next";

import i18nextConfig from "~/i18nextConfig";
import { useOrdersData } from "~/modules/Dashboard/AppStats/hooks/useOrdersData";
import { ALL_PRODUCTS_ITEM_ID, useProductsList } from "~/modules/Dashboard/AppStats/hooks/useProductsList";
import { useSessionData } from "~/modules/Dashboard/AppStats/hooks/useSessionData";
import { getPriceFormatter } from "~/modules/Products/utils/getPriceFormatter";

const rangePresets: RangePickerProps["presets"] = [
  { label: i18nextConfig.t("dashboard:stats.thisMonth"), value: [dayjs().startOf("month"), dayjs()] },
  { label: i18nextConfig.t("dashboard:stats.lastDays", { count: 7 }), value: [dayjs().add(-7, "d"), dayjs()] },
  { label: i18nextConfig.t("dashboard:stats.lastDays", { count: 14 }), value: [dayjs().add(-14, "d"), dayjs()] },
  { label: i18nextConfig.t("dashboard:stats.lastDays", { count: 30 }), value: [dayjs().add(-30, "d"), dayjs()] },
  { label: i18nextConfig.t("dashboard:stats.lastDays", { count: 90 }), value: [dayjs().add(-90, "d"), dayjs()] },
];

const { RangePicker } = DatePicker;

const priceFormatter = getPriceFormatter("PLN");

export const SalesSummary = (): React.ReactElement => {
  const { productsList } = useProductsList();
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([ALL_PRODUCTS_ITEM_ID]);
  const [selectedRange, setSelectedRange] = React.useState<[Dayjs | null, Dayjs | null] | null>(
    (rangePresets[0]?.value as [Dayjs | null, Dayjs | null]) || null,
  );
  const { sessions, sessionChange, conversionPrevious, conversion } = useSessionData(selectedProducts, selectedRange);
  const { finalized, totalAmount, salesChange, totalAmountChange } = useOrdersData(selectedProducts, selectedRange);
  const { t } = useTranslation("dashboard");

  const handleChange = (value: string[]) => {
    if (value.length > 1) {
      setSelectedProducts(value.filter((item) => item !== ALL_PRODUCTS_ITEM_ID));
    } else {
      setSelectedProducts(value);
    }
  };

  const conversionChange = conversionPrevious !== null ? conversion - conversionPrevious : null;

  return (
    <div className="space-y-4">
      <div className="flex justify-end gap-3">
        <Select
          mode="multiple"
          defaultValue={[ALL_PRODUCTS_ITEM_ID]}
          onChange={handleChange}
          value={selectedProducts}
          style={{ minWidth: 320, maxWidth: 500 }}
          options={productsList}
        />

        <RangePicker
          value={selectedRange}
          presets={rangePresets}
          onChange={(dates) => setSelectedRange(dates as [Dayjs | null, Dayjs | null] | null)}
        />
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="text-sm font-medium">Total Revenue</div>
            <DollarSign size={16} />
          </div>
          <div>
            <div className="text-2xl font-bold">{priceFormatter.format(totalAmount)}</div>
            {typeof totalAmountChange === "number" ? (
              <p className="text-xs text-muted-foreground">
                {totalAmountChange > 0 ? "+" : ""}
                {priceFormatter.format(totalAmountChange)} {t("stats.toPreviousPeriod")}
              </p>
            ) : null}
          </div>
        </Card>
        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="text-sm font-medium">Sprzedaż i odnowienia</div>
            <CreditCard size={16} />
          </div>
          <div>
            <div className="text-2xl font-bold">+{finalized.length}</div>
            {typeof salesChange === "number" ? (
              <p className="text-xs text-muted-foreground">
                {salesChange > 0 ? "+" : ""}
                {salesChange}% {t("stats.toPreviousPeriod")}
              </p>
            ) : null}
          </div>
        </Card>
        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="text-sm font-medium">Odsłony koszyka</div>
            <ShoppingCart size={16} />
          </div>
          <div>
            <div className="text-2xl font-bold">{sessions?.length ?? 0}</div>
            {typeof sessionChange === "number" ? (
              <p className="text-xs text-muted-foreground">
                {sessionChange > 0 ? "+" : ""}
                {sessionChange}% {t("stats.toPreviousPeriod")}
              </p>
            ) : null}
          </div>
        </Card>
        <Card>
          <div className="flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="text-sm font-medium">Konwersja</div>
            <AreaChart size={16} />
          </div>
          <div>
            <div className="text-2xl font-bold">{conversion}%</div>
            {typeof conversionChange === "number" ? (
              <p className="text-xs text-muted-foreground">
                {conversionChange > 0 ? "+" : ""}
                {conversionChange}% {t("stats.toPreviousPeriod")}
              </p>
            ) : null}
          </div>
        </Card>
      </div>
    </div>
  );
};
