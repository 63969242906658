import round from "lodash.round";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { KCAL_PER_NUTRIENT_GRAM } from "@fitness-app/data-models/entities/MealsPlan";

export const countNutrientGrams = (nutrientPercentage: number, sumOfCalories: number, divider: 9 | 4) =>
  Math.round((sumOfCalories * nutrientPercentage) / 100 / divider);

export const countCalories = (nutrients: { fat: number; protein: number; carbs: number; fiber: number }) =>
  round(
    Object.keys(nutrients).reduce(
      (prevValue, current) =>
        prevValue +
        nutrients[current as keyof typeof nutrients] *
          KCAL_PER_NUTRIENT_GRAM[current as keyof typeof KCAL_PER_NUTRIENT_GRAM],
      0,
    ),
    1,
  );

export const countCaloriesByIngredients = (ingredients: DishIngredient[]) =>
  round(
    ingredients.reduce((prevValue, current) => (current.nutrients.calories * current.portion) / 100 + prevValue, 0),
    1,
  );

export const calculateForPortion = (
  nutrient: number,
  { portionCount = 1, portions = 1, precision = 1 }: { portions: number; portionCount: number; precision?: number },
) => {
  if (portionCount === portions) {
    return nutrient;
  }
  return round((nutrient / portions) * portionCount, precision);
};
