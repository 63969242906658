import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";
import { fetchTrainee } from "./fetchTrainee";

type Payload = {
  traineeId: string;
};

export const archiveTraineeProfile = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/archiveTraineeProfile`,
  async (payload, { dispatch, extra: { trainersApi, analytics } }) => {
    await trainersApi.post("/trainee/archive-trainee", {
      id: payload.traineeId,
    });

    await dispatch(fetchTrainee({ id: payload.traineeId, refetch: true }));

    analytics.track("archive_trainee_profile_manual");
  },
);
