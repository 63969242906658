import React, { useCallback, useEffect, useRef, useState, type FunctionComponent } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { type RcFile } from "antd/lib/upload";
import { type UploadFile } from "antd/lib/upload/interface";
import deburr from "lodash.deburr";
import { useTranslation } from "react-i18next";

import { supabase } from "~/store/initializeStore";
import { Logger } from "~/utils/Logger";

export interface RcCustomRequestOptions {
  onProgress: (
    event: {
      percent: number;
    },
    file: RcFile,
  ) => void;
  onError: (error: Error, response?: unknown, file?: RcFile) => void;
  onSuccess: (response: object, file: RcFile) => void;
  data: object;
  filename: string;
  file: RcFile;
  withCredentials: boolean;
  action: string;
  headers: object;
}

export interface TransformOptions {
  /**
   * The width of the image in pixels.
   */
  width?: number;
  /**
   * The height of the image in pixels.
   */
  height?: number;
  /**
   * The resize mode can be cover, contain or fill. Defaults to cover.
   * Cover resizes the image to maintain it's aspect ratio while filling the entire width and height.
   * Contain resizes the image to maintain it's aspect ratio while fitting the entire image within the width and height.
   * Fill resizes the image to fill the entire width and height. If the object's aspect ratio does not match the width and height, the image will be stretched to fit.
   */
  resize?: "cover" | "contain" | "fill";
  /**
   * Set the quality of the returned image.
   * A number from 20 to 100, with 100 being the highest quality.
   * Defaults to 80
   */
  quality?: number;
  /**
   * Specify the format of the image requested.
   *
   * When using 'origin' we force the format to be the same as the original image.
   * When this option is not passed in, images are optimized to modern image formats like Webp.
   */
  format?: "origin";
}

interface OwnProps {
  storageRef: string;
  onChange?: (urls: (string | undefined)[], files: UploadFile<File>[]) => void;
  onDelete?: (file: UploadFile<File>) => void;
  fileList?: string[] | [{ url: string }];
  multiple?: boolean;
  withCrop?: boolean;
  disabled?: boolean;
  fileName?: string;
  idAsName?: boolean;
  bucket?: string;
  icon?: typeof UploadOutlined;
  className?: string;
  transformOptions?: TransformOptions;
}

type Props = OwnProps;

const beforeUpload = (file: RcFile) => {
  const validationType =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/gif" ||
    file.type === "image/webp" ||
    file.type === "image/png";
  if (!validationType) {
    void message.error("Rozszerzenie pliku jest niedopuszczalne, wyślij zdjęcie w formacie jpg/gif/png");
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    void message.error("Zdjęcie nie może być większe niż 1MB");
  }

  return validationType && isLt1M;
};

interface File extends RcFile {
  url: string | null;
}

const EXPIRES_IN_20_YEARS = 631138519;

const ImageUploadField: FunctionComponent<Props> = ({
  storageRef,
  onChange,
  multiple,
  fileList: defaultFileList,
  withCrop = true,
  disabled = false,
  fileName,
  onDelete,
  idAsName = false,
  icon: Icon = UploadOutlined,
  bucket = "shared",
  className,
  transformOptions,
}) => {
  const { t } = useTranslation("common");
  const hasInitialMount = useRef(false);

  const [fileList, updateFileList] = useState<UploadFile<File>[]>(() => {
    if (defaultFileList?.length) {
      return [
        {
          url: typeof defaultFileList[0] === "string" ? defaultFileList[0] : defaultFileList[0].url,
          name: "default",
          size: 0,
          uid: "-1",
          status: "done",
          type: "image/png",
        },
      ];
    }

    return [];
  });

  useEffect(() => {
    if (defaultFileList?.length) {
      if (hasInitialMount.current) {
        return;
      }
      updateFileList([
        {
          url: typeof defaultFileList[0] === "string" ? defaultFileList[0] : defaultFileList[0].url,
          name: "default",
          size: 0,
          uid: "-1",
          status: "done",
          type: "image/png",
        } as UploadFile<File>,
      ]);
    } else {
      updateFileList([]);
    }
    hasInitialMount.current = true;
  }, [defaultFileList]);

  const [preview, setPreviewImg] = useState<string | null>(null);

  const onPreview = useCallback(
    (file: UploadFile<File>) => {
      setPreviewImg((file?.url || file?.thumbUrl) ?? null);
    },
    [setPreviewImg],
  );

  const getFileName = (file: RcFile | UploadFile<File>) => {
    if (fileName) {
      return `${deburr(fileName)}.${file.name.split(".").pop()}`;
    }

    return idAsName
      ? file.uid
      : `${file.uid}-${deburr(file.name.replaceAll(" ", "_").replaceAll("-", "_")).replaceAll("~", "_")}`;
  };

  const uploadToStorage = async ({ file, onSuccess }: RcCustomRequestOptions) => {
    // const ref = `https://${import.meta.env.VITE_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/upload/resumable`;
    // const token =
    //   (await supabase.auth.getSession())?.data.session?.access_token || import.meta.env.VITE_SUPABASE_ANON_KEY;

    updateFileList((prev) => [
      ...prev,
      {
        type: file.type,
        name: getFileName(file),
        uid: file.uid,
        lastModified: file.lastModified,
        size: file.size,
        percent: 0,
        status: "uploading",
      },
    ]);

    const ref = `${storageRef}/${getFileName(file)}`;

    try {
      const { error: uploadError } = await supabase.storage.from(bucket).upload(ref, file, {
        cacheControl: "3600",
        upsert: true,
      });

      if (uploadError) {
        void message.error(t("validationErrors.cannotAddImage"));
        updateFileList((prev) =>
          prev.map((savedFile) => {
            if (savedFile.uid === file.uid) {
              return {
                ...savedFile,
                error: uploadError.message,
                status: "error",
              };
            }

            return savedFile;
          }),
        );
        return;
      }

      let url = "";

      if (bucket === "shared") {
        const {
          data: { publicUrl },
        } = supabase.storage.from(bucket).getPublicUrl(ref, {
          transform: transformOptions,
        });
        url = publicUrl;
      } else {
        const { data, error } = await supabase.storage.from(bucket).createSignedUrl(ref, EXPIRES_IN_20_YEARS, {
          transform: transformOptions,
        });
        if (error) {
          throw new Error(error.message);
        }
        url = data.signedUrl;
      }

      onSuccess({}, file);
      onChange?.(
        [...fileList.map((item) => item.url), url],
        [
          ...fileList,
          {
            status: "done",
            type: file.type,
            name: getFileName(file),
            uid: file.uid,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            size: file.size,
            url,
          },
        ],
      );
      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            return {
              ...savedFile,
              status: "done",
              url,
            };
          }

          return savedFile;
        }),
      );
    } catch (e) {
      void message.error(t("validationErrors.cannotAddImage"));
      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            return {
              ...savedFile,
              status: "error",
            };
          }

          return savedFile;
        }),
      );
    }

    // const upload = new tus.Upload(file, {
    //   endpoint: ref,
    //   retryDelays: [0, 3000, 5000, 10000, 20000],
    //   headers: {
    //     authorization: `Bearer ${token}`,
    //     "x-upsert": "true", // optionally set upsert to true to overwrite existing files
    //   },
    //   uploadDataDuringCreation: true,
    //   metadata: {
    //     bucketName: storageRef,
    //     objectName: file.name,
    //     contentType: file.type,
    //     cacheControl: "3600",
    //   },
    //   chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
    //   onError: function () {
    //     void message.error(t("validationErrors.cannotAddImage"));
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             status: "error",
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //   },
    //   onProgress: function (bytesUploaded, bytesTotal) {
    //     const percentage = (bytesUploaded / bytesTotal) * 100;
    //
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             percent: percentage,
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //   },
    //   onSuccess: function () {
    //     onSuccess({}, file);
    //     onChange?.(
    //       [...fileList.map((item) => item.url), upload.url || ""],
    //       [
    //         ...fileList,
    //         {
    //           status: "done",
    //           type: file.type,
    //           name: file.name,
    //           uid: file.uid,
    //           lastModified: file.lastModified,
    //           lastModifiedDate: file.lastModifiedDate,
    //           size: file.size,
    //           url: upload.url || "",
    //         },
    //       ],
    //     );
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             status: "done",
    //             url: upload.url || "",
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //   },
    // });
    //
    // // Check if there are any previous uploads to continue.
    // upload.findPreviousUploads().then(function (previousUploads) {
    //   // Found previous uploads so we select the first one.
    //   if (previousUploads.length) {
    //     upload.resumeFromPreviousUpload(previousUploads[0]);
    //   }
    //
    //   // Start the upload
    //   upload.start();
    // });
    //
    // upload.start();
  };

  const onRemove = async (file: UploadFile<File>) => {
    updateFileList((prev) => prev.filter((savedFile) => savedFile.url !== file.url));
    onChange?.(
      fileList.map((item) => item?.url || "").filter((url) => url && url !== file.url),
      fileList.filter((item) => item?.url !== file.url),
    );
    try {
      if (file.uid && (file.status === "done" || file.uid === "-1")) {
        if (file.uid === "-1" && file.url) {
          if (fileName) {
            await supabase.storage.from(bucket).remove([`${storageRef}/${fileName}`]);
          }
          onDelete?.(file);
        } else {
          await supabase.storage.from(bucket).remove([`${storageRef}/${file.name}`]);
          onDelete?.(file);
        }
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  if (!withCrop) {
    return (
      <>
        <Upload
          name="logo"
          listType="picture-card"
          beforeUpload={beforeUpload}
          onPreview={onPreview}
          onRemove={onRemove}
          fileList={fileList}
          multiple={multiple}
          // @ts-expect-error wrong return type
          customRequest={uploadToStorage}
        >
          {!multiple && fileList.length >= 1 ? null : <Button type="text" icon={<Icon />} />}
        </Upload>
        <Modal open={!!preview} footer={null} onCancel={() => setPreviewImg(null)}>
          <img alt="example" style={{ width: "100%", marginTop: 20 }} src={preview ?? undefined} />
        </Modal>
      </>
    );
  }

  return (
    <div className={className}>
      <ImgCrop>
        <Upload
          name="logo"
          listType="picture-card"
          beforeUpload={beforeUpload}
          onPreview={onPreview}
          onRemove={onRemove}
          fileList={fileList}
          multiple={multiple}
          // @ts-expect-error wrong return type
          customRequest={uploadToStorage}
          disabled={disabled}
        >
          {!multiple && fileList.length >= 1 ? null : <Button type="text" icon={<Icon />} />}
        </Upload>
      </ImgCrop>
      <Modal open={!!preview} footer={null} onCancel={() => setPreviewImg(null)}>
        <img alt="example" style={{ width: "100%", marginTop: 15 }} src={preview ?? undefined} />
      </Modal>
    </div>
  );
};

export default ImageUploadField;
