import { createAsyncThunk } from "@reduxjs/toolkit";
import { type CreateProductDTO } from "@fitness-app/data-models/entities/Product";
import { getErrorMessage } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_REDUCER_NAME } from "../types";

export const addProduct = createAsyncThunk<void, ActionParams<CreateProductDTO>, AsyncThunkCreator<string>>(
  `${PRODUCTS_REDUCER_NAME}/addProduct`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { analytics, auth, productApi } }) => {
    try {
      await getLoggedUser(auth);

      await productApi.post("/add-product", payload);
      analytics.track("created_new_product", {
        type: payload.type,
        paymentType: payload.chargeType,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
