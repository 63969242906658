import React, { useEffect, useState } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Col, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from "antd";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { teamActions, teamSelectors } from "@fitness-app/app-store";
import { TeamMemberRole, TeamMemberStatus, type TeamMember } from "@fitness-app/data-models";
import { type TeamMemberWithUser } from "@fitness-app/data-models/entities/TeamMember";
import { getUserInitials } from "@fitness-app/utils";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useTagOptions } from "~/hooks/useTagOptions";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import TeamTrainerForm from "./TeamTrainerForm";
import { type TeamTrainerFormModel } from "./types";

const badgeStatus = {
  [TeamMemberStatus.PENDING]: "processing",
  [TeamMemberStatus.ACTIVE]: "success",
  [TeamMemberStatus.INACTIVE]: "error",
  [TeamMemberStatus.INVITED]: "warning",
} as const;

const TeamMemberList = (): React.ReactElement => {
  const { t } = useTranslation(["team", "common"]);
  const dispatch = useAppDispatch();
  const trainers = useAppSelector(teamSelectors.getTrainersTeam) ?? [];
  const listStatus = useAppSelector((state) => state.team.listStatus);
  const [showLoader, toggleLoader] = useState(false);
  const [model, setModel] = useState<TeamTrainerFormModel | null>(null);
  const { filters: tagsFilters } = useTagOptions("specializationTags");

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (listStatus === null) {
      dispatch(teamActions.subscribeToTeam());
    }
    void dispatch(teamActions.fetchTrainerConfig());
  }, [listStatus, dispatch]);
  const openForm = () => setIsOpen(true);
  const closeForm = () => {
    setIsOpen(false);
    setModel(null);
  };

  const addTrainer = async (trainer: TeamTrainerFormModel) => {
    toggleLoader(true);

    if (model) {
      await dispatch(
        teamActions.updateTrainerInTeam({
          memberId: model.id,
          data: {
            role: trainer.role || model.role,
            specializationTags: trainer.specializationTags || [],
            status: trainer.status ? TeamMemberStatus.ACTIVE : TeamMemberStatus.INACTIVE,
          },
        }),
      );
    } else {
      const trainerData = {
        ...trainer,
        status: trainer.status ? TeamMemberStatus.PENDING : TeamMemberStatus.INACTIVE,
        id: uuid(),
      };
      await dispatch(teamActions.addTrainerToTeam(trainerData));
    }
    toggleLoader(false);
    setModel(null);
    closeForm();
  };

  const openEditForm = (selected: TeamMember) => {
    setModel({
      ...selected,
      specializationTags: selected.specializationTags || [],
      status: selected.status === TeamMemberStatus.ACTIVE,
    });
    setIsOpen(true);
  };

  const removeTrainer = async (trainerId: string) => {
    await dispatch(teamActions.removeTrainerFromTeam(trainerId));
  };

  return (
    <Row>
      <Col xs={24}>
        <Card
          title={t("team")}
          extra={
            <Button type="primary" icon={<PlusOutlined />} onClick={openForm}>
              {t("common.addTrainer")}
            </Button>
          }
        >
          <Table dataSource={trainers} pagination={{ pageSize: 50 }} rowKey="id" scroll={{ x: true }}>
            <Table.Column
              key="id"
              dataIndex="id"
              title="ID"
              ellipsis
              render={(id: string) => (
                <Typography.Paragraph
                  copyable={{ tooltips: [id, t("common:copied")], text: id }}
                  style={{ marginBottom: 0 }}
                >
                  {truncate(id, { length: 10 })}
                </Typography.Paragraph>
              )}
            />
            <Table.Column<TeamMemberWithUser>
              title={t("common.name")}
              width="15%"
              key="name"
              dataIndex="name"
              render={(_, row) => {
                const name = `${row.firstName ?? ""} ${row.lastName ?? ""}`;
                return (
                  <Space direction="horizontal">
                    <Avatar src={row.user?.avatarUrl}>{getUserInitials(name)}</Avatar>
                    <span>{name}</span>
                  </Space>
                );
              }}
            />
            <Table.Column<TeamMemberWithUser>
              title={t("common.email")}
              key="email"
              ellipsis
              dataIndex="email"
              render={(email: string) => (
                <Typography.Paragraph
                  copyable={{
                    tooltips: [t("common:copy"), t("common:copied")],
                    text: email,
                  }}
                  style={{ marginBottom: 0 }}
                >
                  {email}
                </Typography.Paragraph>
              )}
            />
            <Table.Column<TeamMemberWithUser>
              title={t("common.role")}
              key="role"
              dataIndex="role"
              render={(role: string) => t(`role.${role.toLowerCase()}`)}
            />
            <Table.Column<TeamMember>
              title={t("common.status")}
              key="status"
              dataIndex="status"
              render={(status: TeamMember["status"], row) => {
                if (row.selectable === null) {
                  return <Badge status={badgeStatus[status]} text={t(`status.${status.toLowerCase()}`)} />;
                }

                if (!row.selectable) {
                  return (
                    <Space direction="horizontal">
                      <Badge status="error" text={t("status.notSelectable")} />
                      <Tooltip title={t("cannotCreateAccount")}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  );
                }

                return <Badge status={badgeStatus[status]} text={t(`status.${status.toLowerCase()}`)} />;
              }}
            />
            <Table.Column<TeamMemberWithUser>
              title={t("common.clientsNumber")}
              key="assignedTrainees"
              dataIndex="assignedTrainees"
              render={(assignedTrainees: string[]) => assignedTrainees?.length ?? 0}
            />

            <Table.Column<TeamMemberWithUser>
              title={t("membersSpecializations")}
              dataIndex="specializationTags"
              key="specializationTags"
              filterMultiple
              filterSearch
              filters={tagsFilters}
              onFilter={(value, record) => (record.specializationTags || []).includes(value as string)}
              render={(tags: string[]) =>
                tags?.length ? (
                  <Space direction="vertical">
                    {tags.map((tag, i) => (
                      <Tag key={`${tag}-${i}`}>{tag}</Tag>
                    ))}
                  </Space>
                ) : (
                  "-"
                )
              }
            />

            <Table.Column<TeamMemberWithUser>
              title={t("common:options")}
              key="options"
              dataIndex="options"
              render={(text, record) =>
                record.role === TeamMemberRole.ACCOUNT_OWNER ? (
                  "-"
                ) : (
                  <div>
                    <Button onClick={() => openEditForm(record)} type="link" disabled={!record.selectable}>
                      {t("common:button.edit")}
                    </Button>
                    <Popconfirm
                      title={t("removeTrainerConfirm")}
                      okText={t("common:yes")}
                      cancelText={t("common:no")}
                      placement="left"
                      onConfirm={() => removeTrainer(record.id)}
                    >
                      <Button danger type="link" disabled={record?.disabled || false}>
                        {t("common:button.delete")}
                      </Button>
                    </Popconfirm>
                  </div>
                )
              }
            />
          </Table>
        </Card>
      </Col>
      <ModalForm
        title={model ? t("editTrainer") : t("common.addTrainer")}
        open={isOpen}
        onCancel={closeForm}
        loading={showLoader}
      >
        <TeamTrainerForm onSubmit={addTrainer} model={model || undefined} />
      </ModalForm>
    </Row>
  );
};

export default TeamMemberList;
