import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientNote } from "@fitness-app/data-models/entities/ClientNote";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_NOTES_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  noteId: string;
  authorId: string | null;
  note: Partial<ClientNote>;
};

export const editClientNote = createAsyncThunk<ClientNote, Payload, AsyncThunkCreator<string>>(
  `${CLIENT_NOTES_REDUCER_NAME}/editClientNote`,
  async ({ traineeId, noteId, note }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("client_note")
      .update({
        ...note,
        updatedAt: new Date().toISOString(),
      })
      .match({ id: noteId, traineeId })
      .select("*")
      .single<ClientNote>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("edit_client_note");

    return data;
  },
);
