import React, { useEffect, useState, type FunctionComponent } from "react";
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  TreeSelect,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import groupBy from "lodash.groupby";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { productClientActions } from "@fitness-app/app-store";
import { ProductEventType } from "@fitness-app/data-models/businessEvents/domain/product/ProductEvents";
import {
  DiscordIntegrationTask,
  EmailClientTask,
  FitnessAppTask,
  TaskType,
  type AutomationTaskWithoutTriggers,
  type DiscordAutomationTask,
  type EmailClientAutomationTask,
  type FieldValuesEmailClient,
  type FieldValuesEmailList,
  type FitnessAppAutomationTask,
  type ProductAutomation,
  type ProductAutomationTask,
  type ProductTask,
} from "@fitness-app/data-models/entities/Automation";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";
import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";
import { replaceDoubleBraces } from "@fitness-app/utils";

import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { useEmailLists } from "~/hooks/useEmailLists";
import { useDiscordIntegration } from "~/modules/Products/Product/ProductAutomation/hooks/useDiscordIntegration";
import { useAppDispatch } from "~/store/initializeStore";
import { type ProductClientEmailChangeFormModel } from "./types";

interface Props {
  formController?: FormInstance<ProductClientEmailChangeFormModel>;
  onSubmit: (formData: ProductClientEmailChangeFormModel) => void;
  client: ProductClient;
  productAutomation: ProductAutomation | null;
}

export const useProductClientEmailChange = () => {
  const [showChangeEmailModal, toggleChangeEmailModel] = useState(false);
  const [processing, toggleProcessing] = useState(false);
  const { t } = useTranslation("common");
  const [selectedClient, setClient] = useState<null | ProductClient>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedClient) {
      toggleChangeEmailModel(true);
    } else {
      toggleChangeEmailModel(false);
    }
  }, [selectedClient]);

  const handleSubmit = async (model: ProductClientEmailChangeFormModel) => {
    if (!selectedClient) {
      return;
    }

    const tasksForOldEmail = model.tasksForOldEmail.map((task) => {
      if (task.type === DiscordIntegrationTask.RemoveFromServer) {
        return {
          action: task,
          type: TaskType.DiscordIntegration,
        };
      }

      if (task.type === DiscordIntegrationTask.RemoveRole) {
        return {
          action: task,
          type: TaskType.DiscordIntegration,
        };
      }

      if (task.type === EmailClientTask.RemoveTags) {
        return {
          type: TaskType.EmailClient,
          action: {
            ...task,
            emailClients: task.emailClients.map((clientStr) => {
              const [client, account] = clientStr.split(LIST_ITEM_SEPARATOR);
              return {
                client: client as EmailClient,
                account,
              };
            }),
          },
        };
      }

      if (task.type === EmailClientTask.RemoveFromLists) {
        const lists = task.emailLists.map((listStr) => {
          const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          };
        });
        const groupByClient = groupBy(lists, "client");

        if (EmailClient.GetResponse in groupByClient && groupByClient[EmailClient.GetResponse]?.length > 1) {
          void message.warning("getResponseListWarning");
          return;
        }

        return {
          type: TaskType.EmailClient,
          action: {
            ...task,
            emailLists: lists,
          },
        };
      }
    }) as (AutomationTaskWithoutTriggers | undefined)[];

    const tasksForNewEmail = model.tasksForNewEmail.map((task) => {
      if (
        task.type === DiscordIntegrationTask.RemoveFromServer ||
        task.type === DiscordIntegrationTask.AddToServer ||
        task.type === DiscordIntegrationTask.RemoveRole ||
        task.type === DiscordIntegrationTask.AddRole
      ) {
        return {
          action: task,
          type: TaskType.DiscordIntegration,
        };
      }

      if (
        task.type === FitnessAppTask.AddClientToApp ||
        task.type === FitnessAppTask.RemoveClientFromApp ||
        task.type === FitnessAppTask.AddProgramAutomationToClient
      ) {
        return {
          action: task,
          type: TaskType.FitnessApp,
        };
      }

      if (task.type === EmailClientTask.AddTags || task.type === EmailClientTask.RemoveTags) {
        return {
          type: TaskType.EmailClient,
          action: {
            ...task,
            emailClients: task.emailClients.map((clientStr) => {
              const [client, account] = clientStr.split(LIST_ITEM_SEPARATOR);
              return {
                client: client as EmailClient,
                account,
              };
            }),
          },
        };
      }

      if (task.type === EmailClientTask.AddToLists || task.type === EmailClientTask.RemoveFromLists) {
        const lists = task.emailLists.map((listStr) => {
          const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          };
        });
        const groupByClient = groupBy(lists, "client");

        if (EmailClient.GetResponse in groupByClient && groupByClient[EmailClient.GetResponse]?.length > 1) {
          void message.warning("getResponseListWarning");
          return;
        }

        return {
          type: TaskType.EmailClient,
          action: {
            ...task,
            emailLists: lists,
          },
        };
      }

      const lists: (FieldValuesEmailList | FieldValuesEmailClient)[] = task.emailLists.map((listStr) => {
        const [client, account, id, name] = listStr.split(LIST_ITEM_SEPARATOR);
        if (id && name) {
          return {
            client: client as EmailClient,
            account,
            id,
            name,
          } as FieldValuesEmailList;
        }
        return {
          client: client as EmailClient,
          account,
        } as FieldValuesEmailClient;
      });
      const groupByClient = groupBy(lists, "client");

      if (EmailClient.GetResponse in groupByClient && groupByClient[EmailClient.GetResponse]?.length > 1) {
        void message.warning("getResponseListWarning");
        return;
      }
      return {
        type: TaskType.EmailClient,
        action: {
          ...task,
          emailLists: lists,
        },
      };
    }) as (AutomationTaskWithoutTriggers | undefined)[];

    if (model.oldEmail === model.newEmail.trim()) {
      void message.warning(t("emailsAreTheSame"));
      return;
    }

    const reformattedModel = {
      tasksForNewEmail: tasksForNewEmail.filter((task): task is AutomationTaskWithoutTriggers => Boolean(task?.type)),
      tasksForOldEmail: tasksForOldEmail.filter((task): task is AutomationTaskWithoutTriggers => Boolean(task?.type)),
      oldEmail: selectedClient?.email,
      newEmail: model.newEmail.trim().toLowerCase(),
      productId: selectedClient?.productId,
      sendEmailNotification: model.sendEmailNotification,
    };

    try {
      toggleProcessing(true);
      await dispatch(productClientActions.changeProductClientEmail(reformattedModel)).unwrap();
      toggleProcessing(false);
      toggleChangeEmailModel(false);
      navigate(`/products/${selectedClient.productId}/clients/${model.newEmail}`);
    } catch (e) {
      toggleProcessing(false);
      void message.error(t("messages.error.default"));
    }
  };

  return {
    selectedClientToChangeEmail: selectedClient,
    showChangeEmailModal,
    setClientToEmailChange: setClient,
    handleEmailChangeSubmit: handleSubmit,
    processingChangingEmail: processing,
  };
};

export const ProductClientEmailChange: FunctionComponent<Props> = ({
  formController,
  onSubmit,
  productAutomation,
  client,
}) => {
  const { t } = useTranslation(["products", "common"]);
  const { tags, lists, emailClients, listOrAccountOptions, emailAutomationTaskOptions } = useEmailLists();
  const { discordAutomationTaskOptions, discordIntegration } = useDiscordIntegration();
  const [selectedTasksTypeForOldEmail, selectTasksTypeForOldEmail] = useState<
    (EmailClientTask | DiscordIntegrationTask | ProductTask)[]
  >([]);
  const [selectedTasksTypeForNewEmail, selectTasksTypeForNewEmail] = useState<
    (EmailClientTask | DiscordIntegrationTask | ProductTask | FitnessAppTask)[]
  >([]);

  useEffect(() => {
    if (productAutomation?.tasks?.length) {
      const taskAfterProductPurchase = productAutomation.tasks.filter(
        (task) => task.triggers.includes(ProductEventType.ProductPurchased) && task.protected,
      );

      const revertedTaskForOldEmail = taskAfterProductPurchase
        .map(
          (
            task: EmailClientAutomationTask | DiscordAutomationTask | ProductAutomationTask | FitnessAppAutomationTask,
          ) => {
            if (task.action.type === EmailClientTask.AddToLists) {
              return {
                ...task.action,
                type: EmailClientTask.RemoveFromLists,
                emailLists: task.action.emailLists.map(
                  (list) =>
                    `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                ),
              };
            }

            if (task.action.type === EmailClientTask.AddTags && task.action.tags.length) {
              return {
                ...task.action,
                type: EmailClientTask.RemoveTags,
                emailClients: task.action.emailClients.map(
                  (clientEmail) => `${clientEmail.client}${LIST_ITEM_SEPARATOR}${clientEmail.account}`,
                ),
              };
            }

            if (task.action.type === DiscordIntegrationTask.AddToServer) {
              return {
                ...task.action,
                type: DiscordIntegrationTask.RemoveFromServer,
              };
            }
          },
        )
        .filter((task): task is ProductClientEmailChangeFormModel["tasksForOldEmail"][number] => Boolean(task));

      if (revertedTaskForOldEmail.length) {
        formController?.setFieldsValue({
          tasksForOldEmail: revertedTaskForOldEmail,
        });
        selectTasksTypeForOldEmail(revertedTaskForOldEmail.map((task) => task.type));
      }

      const tasksForNewEmail = taskAfterProductPurchase
        .map(
          (
            task: EmailClientAutomationTask | DiscordAutomationTask | ProductAutomationTask | FitnessAppAutomationTask,
          ) => {
            if (
              task.action.type === EmailClientTask.AddToLists ||
              task.action.type === EmailClientTask.RemoveFromLists
            ) {
              return {
                ...task.action,
                emailLists: task.action.emailLists.map(
                  (list) =>
                    `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                ),
              };
            }

            if (task.action.type === EmailClientTask.AddTags || task.action.type === EmailClientTask.RemoveTags) {
              return {
                ...task.action,
                emailClients: task.action.emailClients.map(
                  (clientEmail) => `${clientEmail.client}${LIST_ITEM_SEPARATOR}${clientEmail.account}`,
                ),
              };
            }

            if (task.action.type === EmailClientTask.AddFieldValues) {
              return {
                ...task.action,
                values: Object.entries(task.action.values).map(([key, value]) => ({
                  key,
                  value: replaceDoubleBraces(String(value), {
                    clientRefCode: client.refCode ?? "",
                    productId: client.productId,
                  }),
                })),
                emailLists: task.action.emailLists.map(
                  (clientEmail) => `${clientEmail.client}${LIST_ITEM_SEPARATOR}${clientEmail.account}`,
                ),
              };
            }
          },
        )
        .filter((task) => Boolean(task));

      if (tasksForNewEmail.length) {
        formController?.setFieldsValue({
          tasksForNewEmail: tasksForNewEmail as ProductClientEmailChangeFormModel["tasksForNewEmail"],
        });
        selectTasksTypeForNewEmail(
          tasksForNewEmail.map((task) => (task as ProductClientEmailChangeFormModel["tasksForNewEmail"][number]).type),
        );
      }
    }
  }, [productAutomation, formController]);

  const renderTaskAction = (index: number, name: number, forNewEmail = false) => {
    const selectedTaskType = (forNewEmail ? selectedTasksTypeForNewEmail : selectedTasksTypeForOldEmail)[index];
    if (
      selectedTaskType === DiscordIntegrationTask.RemoveFromServer ||
      selectedTaskType === DiscordIntegrationTask.AddToServer
    ) {
      return (
        <>
          <Form.Item
            name={[name, "serverId"]}
            label={t<string>("products:form.discordServerId")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              placeholder={t("products:form.selectDiscordAccount")}
              notFoundContent={false}
              optionFilterProp="title"
              optionLabelProp="title"
              allowClear
            >
              {discordIntegration.map((account) => (
                <Select.Option value={account.guildId} title={account.guild.name} key={account.guildId}>
                  {account.guild.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    }
    if (selectedTaskType === EmailClientTask.AddTags) {
      return (
        <>
          <Form.Item
            name={[name, "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={emailClients}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
          <Form.Item
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={[name, "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveTags) {
      return (
        <>
          <Form.Item
            name={[name, "emailClients"]}
            label={t<string>("products:form.emailClients")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={emailClients}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
          <Form.Item
            tooltip={t<string>("products:form.clientTagsAdditional")}
            name={[name, "tags"]}
            label={t<string>("products:form.clientTags")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select
              notFoundContent={false}
              options={tags}
              mode="tags"
              placeholder={t<string>("products:form.clientTagsPlaceholder")}
            />
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.AddToLists) {
      return (
        <Form.Item
          tooltip={t<string>("products:form.emailListsInfo")}
          name={[name, "emailLists"]}
          label={t<string>("products:form.emailLists")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <TreeSelect
            style={{ maxWidth: 700 }}
            treeData={lists}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            treeCheckable
          />
        </Form.Item>
      );
    }

    if (selectedTaskType === EmailClientTask.AddFieldValues) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={[name, "emailLists"]}
            label={t<string>("products:productAutomation.form.emailListsOrAccount")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={listOrAccountOptions}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
          <Form.Item
            label={t("productAutomation.form.fieldValues")}
            tooltip={t("productAutomation.form.fieldValuesTooltip")}
          >
            <Form.List name={[name, "values"]} initialValue={[{ key: "", value: "" }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                      <Space style={{ width: "100%" }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          style={{ flex: 1, width: 220, marginBottom: 5 }}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Input placeholder={t<string>("productAutomation.form.bodyPlaceholder")} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          style={{ flex: 1, width: 235, marginBottom: 5 }}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <AutoComplete options={[]} placeholder={t("productAutomation.form.valuePlaceholder")} />
                        </Form.Item>
                        {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(name)} /> : null}
                      </Space>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          key: "",
                          value: "",
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("productAutomation.form.addField")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    }

    if (selectedTaskType === EmailClientTask.RemoveFromLists) {
      return (
        <>
          <Form.Item
            tooltip={t<string>("products:form.emailListsInfo")}
            name={[name, "emailLists"]}
            label={t<string>("products:form.emailLists")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <TreeSelect
              style={{ maxWidth: 700 }}
              treeData={lists}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              treeCheckable
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return (
    <Form<ProductClientEmailChangeFormModel>
      name="change-email-client-form"
      form={formController}
      onFinish={onSubmit}
      validateTrigger={["onSubmit"]}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        oldEmail: client.email,
        newEmail: "",
        tasksForOldEmail: [],
        tasksForNewEmail: [],
        sendEmailNotification: true,
      }}
    >
      <Form.Item name="oldEmail" label={t("clientDetails.oldEmail")}>
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="newEmail"
        label={t("clientDetails.newEmail")}
        validateTrigger={["onChange"]}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            type: "email",
            message: t<string>("common:validationErrors.wrongEmail"),
            transform: (value: string) => value.trim(),
          },
        ]}
      >
        <Input placeholder={t<string>("clientDetails.typeNewEmail")} />
      </Form.Item>

      <Form.Item name="sendEmailNotification" label={t("clientDetails.sendEmailNotification")} valuePropName="checked">
        <Switch defaultChecked />
      </Form.Item>

      <Row>
        <Col offset={8}>
          <Alert showIcon message={t("clientDetails.optionalAutomationForEmailChange")} />
        </Col>
      </Row>
      <Divider />
      <Form.Item
        labelCol={{ span: 14 }}
        style={{ fontWeight: "bold" }}
        label={t("clientDetails.tasksForEmail", { email: client.email })}
      />
      <Form.List name="tasksForOldEmail">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div className="relative mb-5 border-dashed border-gray-200 bg-gray-50 p-6 pb-10" key={field.key}>
                    <Form.Item
                      name={[field.name, "type"]}
                      label={t<string>("productAutomation.form.taskType")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Select<ProductClientEmailChangeFormModel["tasksForOldEmail"][number]["type"]>
                        options={[...emailAutomationTaskOptions, ...discordAutomationTaskOptions]}
                        onSelect={(type: ProductClientEmailChangeFormModel["tasksForOldEmail"][number]["type"]) =>
                          selectTasksTypeForOldEmail((prev) => {
                            const clone = prev.slice();
                            clone[index] = type;
                            return clone;
                          })
                        }
                      />
                    </Form.Item>
                    {renderTaskAction(index, field.name)}
                    <div className="absolute bottom-2.5 right-6">
                      <DeleteOutlined
                        style={{ color: "#f64e60", fontSize: 18 }}
                        onClick={() => {
                          remove(field.name);
                          selectTasksTypeForOldEmail((prev) => prev.filter((item, i) => i !== index));
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                style={{
                  flexBasis: "100%",
                  marginTop: 16,
                }}
              >
                <Row style={{ width: "100%" }}>
                  <Col xs={12} offset={8}>
                    <Button
                      type="primary"
                      onClick={() => {
                        add({ email: client.email });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      {fields.length
                        ? t<string>("clientDetails.addAnotherTask")
                        : t<string>("clientDetails.addFirstTask")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </Form.List>
      <Divider />
      <Form.Item labelCol={{ span: 14 }} style={{ fontWeight: "bold" }} label={t("clientDetails.tasksForNewEmail")} />
      <Form.List name="tasksForNewEmail">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div className="relative mb-5 border-dashed border-gray-200 bg-gray-50 p-6 pb-10" key={field.key}>
                    <Form.Item
                      name={[field.name, "type"]}
                      label={t<string>("productAutomation.form.taskType")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Select<ProductClientEmailChangeFormModel["tasksForOldEmail"][number]["type"]>
                        options={[...emailAutomationTaskOptions, ...discordAutomationTaskOptions]}
                        onSelect={(type: ProductClientEmailChangeFormModel["tasksForOldEmail"][number]["type"]) =>
                          selectTasksTypeForNewEmail((prev) => {
                            const clone = prev.slice();
                            clone[index] = type;
                            return clone;
                          })
                        }
                      />
                    </Form.Item>
                    {renderTaskAction(index, field.name, true)}
                    <div className="absolute bottom-2.5 right-6">
                      <DeleteOutlined
                        style={{ color: "#f64e60", fontSize: 18 }}
                        onClick={() => {
                          remove(field.name);
                          selectTasksTypeForNewEmail((prev) => prev.filter((item, i) => i !== index));
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                style={{
                  flexBasis: "100%",
                  marginTop: 16,
                }}
              >
                <Row style={{ width: "100%" }}>
                  <Col xs={12} offset={8}>
                    <Button
                      type="primary"
                      onClick={() => {
                        add({ email: client.email });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      {fields.length
                        ? t<string>("clientDetails.addAnotherTask")
                        : t<string>("clientDetails.addFirstTask")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};
