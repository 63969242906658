import React, { useState } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Badge, Button, Card, Descriptions, Space, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus, traineeActions } from "@fitness-app/app-store";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import ModalForm from "~/components/ModalForm/ModalForm";
import {
  defaultNotificationsSettings,
  NOTIFICATIONS_FREQUENCY_LABELS,
  type ClientNotificationsSettingsFormModel,
} from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSettings/TraineeNotificationsData";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import TraineeNotificationsSettingsForm from "./TraineeNotificationsSettingsForm";

const TraineeNotifications = () => {
  const { t } = useTranslation(["trainees", "surveys"]);
  const { traineeSettings, traineeSettingsStatus } = useAppSelector((store) => store.trainee);
  const { profile: traineeData } = useAppSelector((store) => store.trainee);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showLoader, toggleLoader] = useState(false);
  const dispatch = useAppDispatch();

  if (traineeData?.status !== TraineeStatus.ACTIVE) {
    return (
      <div style={{ padding: 20 }}>
        <Alert message={t("shouldHasActiveStatus")} description={t("featureNotAvailable")} showIcon type="warning" />
      </div>
    );
  }

  const clientNotificationsSettings = traineeSettings?.notificationsSettings || defaultNotificationsSettings;

  const handleSubmit = async (formData: ClientNotificationsSettingsFormModel) => {
    if (!traineeData.id) {
      return;
    }
    toggleLoader(true);
    await dispatch(
      traineeActions.updateTraineeNotificationsSettings({
        notificationsSettings: { ...formData, allowNotifications: clientNotificationsSettings.allowNotifications },
        id: traineeData.id,
      }),
    );
    toggleLoader(false);
    setIsFormVisible(false);
  };

  return (
    <Card
      bordered={false}
      loading={traineeSettingsStatus === RequestStatus.FETCHING}
      className="!shadow-none"
      extra={
        clientNotificationsSettings ? (
          <Button size="small" icon={<PlusOutlined />} type="primary" onClick={() => setIsFormVisible(true)}>
            {t("notifications.editSettings")}
          </Button>
        ) : null
      }
    >
      <Descriptions title={t("notifications.settings")} bordered column={1} labelStyle={{ width: 350 }}>
        <Descriptions.Item label={t("notifications.allowNotifications")}>
          <Space align="center" size={12}>
            <Badge
              text={
                clientNotificationsSettings.allowNotifications === null
                  ? t("notifications.notChecked")
                  : clientNotificationsSettings.allowNotifications
                  ? t("notifications.active")
                  : t("notifications.inactive")
              }
              status={
                clientNotificationsSettings.allowNotifications === null
                  ? "warning"
                  : clientNotificationsSettings.allowNotifications
                  ? "processing"
                  : "error"
              }
            />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("notifications.notificationsAboutTraining")}>
          <Space align="center" size={12}>
            <Badge
              text={
                clientNotificationsSettings.activeWorkoutsReminder
                  ? t("notifications.active")
                  : t("notifications.inactive")
              }
              status={clientNotificationsSettings.activeWorkoutsReminder ? "processing" : "warning"}
            />
            {clientNotificationsSettings.activeWorkoutsReminder && (
              <Tooltip title={t("notifications.aboutTrainingNotification")}>
                <InfoCircleOutlined style={{ fontSize: 20, color: "#6584e7" }} />
              </Tooltip>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("notifications.notificationsAboutWeightMeasurement")}>
          <Space align="center" size={12}>
            <Badge
              text={
                clientNotificationsSettings.activeWeightMeasurementReminder
                  ? t("notifications.active")
                  : t("notifications.inactive")
              }
              status={clientNotificationsSettings.activeWeightMeasurementReminder ? "processing" : "warning"}
            />
            {clientNotificationsSettings.activeWeightMeasurementReminder && (
              <Tooltip title={t("notifications.aboutWeightNotification")}>
                <InfoCircleOutlined style={{ fontSize: 20, color: "#6584e7" }} />
              </Tooltip>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("notifications.reminderAboutWeightMeasurement")}>
          {clientNotificationsSettings.activeWeightMeasurementReminder
            ? t(NOTIFICATIONS_FREQUENCY_LABELS[clientNotificationsSettings.weightMeasurementFrequency])
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label={t("notifications.notificationsAboutBodyMeasurement")}>
          <Space align="center" size={12}>
            <Badge
              text={
                clientNotificationsSettings.activeBodyMeasurementReminder
                  ? t("notifications.active")
                  : t("notifications.inactive")
              }
              status={clientNotificationsSettings.activeBodyMeasurementReminder ? "processing" : "warning"}
            />
            {clientNotificationsSettings.activeBodyMeasurementReminder && (
              <Tooltip title={t("notifications.aboutBodyNotification")}>
                <InfoCircleOutlined style={{ fontSize: 20, color: "#6584e7" }} />
              </Tooltip>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("notifications.reminderAboutBodyMeasurement")}>
          {clientNotificationsSettings.activeBodyMeasurementReminder
            ? t(NOTIFICATIONS_FREQUENCY_LABELS[clientNotificationsSettings.bodyMeasurementFrequency])
            : "-"}
        </Descriptions.Item>
      </Descriptions>

      <div className="mt-6 pl-2.5">
        <Typography.Text type="secondary">{t("notifications.clientInfo")}</Typography.Text>
      </div>

      <ModalForm
        visible={isFormVisible}
        loading={showLoader}
        onCancel={() => setIsFormVisible(false)}
        title={t("notifications.formTitle")}
      >
        <TraineeNotificationsSettingsForm onSubmit={handleSubmit} model={clientNotificationsSettings} />
      </ModalForm>
    </Card>
  );
};

export default TraineeNotifications;
