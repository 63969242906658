import React, { useState } from "react";
import { CalendarOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Empty, List, Popconfirm, Space, Typography } from "antd";
import dayjs from "dayjs";
import cloneDeep from "lodash.clonedeep";
import keyBy from "lodash.keyby";
import sortBy from "lodash.sortby";

import { traineeProgramActions } from "@fitness-app/app-store";
import { type Trainee } from "@fitness-app/data-models/entities/Trainee";
import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import ModalForm from "~/components/ModalForm/ModalForm";
import { type ProgramScheduleFormModel } from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import ScheduledClientProgramsDates from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/ScheduledClientProgramsDates";
import ScheduledClientProgramStepper from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/ScheduledClientProgramStepper";
import { type ScheduledClientProgramsDatesModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeScheduledProgramsProps {
  scheduledPrograms: ClientTrainingProgram[];
  trainee: Trainee;
}

export const useScheduledClientProgramStepper = (scheduledPrograms: ClientTrainingProgram[], client: Trainee) => {
  const [visible, toggleVisibility] = useState(false);
  const [editedRoutine, setRoutineModel] = useState<null | ClientTrainingProgram>(null);
  const dispatch = useAppDispatch();
  const [programDatesModel, setProgramDatesModel] = useState<ScheduledClientProgramsDatesModel | null>(null);

  const closeModal = () => {
    toggleVisibility(false);
    setRoutineModel(null);
    if (client.activeTrainingProgramId) {
      void dispatch(traineeProgramActions.fetchProgramWithDetails({ id: client.activeTrainingProgramId }));
    }
  };

  const openModal = (program: ClientTrainingProgram) => {
    setRoutineModel(program);
    toggleVisibility(true);
  };

  const removeScheduledProgram = (programId: string) => {
    void dispatch(traineeProgramActions.removeScheduledProgram({ programId }));
  };

  const updateSchedule = async (programId: string, model: ProgramScheduleFormModel) => {
    const program = scheduledPrograms.find((p) => p.id === programId);
    if (program) {
      await dispatch(
        traineeProgramActions.updateScheduledProgram({
          traineeId: client.id,
          programId,
          program: {
            workoutsSchedule: program.workoutsSchedule
              ? {
                  ...program.workoutsSchedule,
                  schedule: model.weeks,
                }
              : null,
          },
        }),
      );
    }
  };

  const editScheduledPrograms = () => {
    setProgramDatesModel({
      programs: cloneDeep(
        sortBy(scheduledPrograms, (item) => dayjs(item.startDate, "YYYY-MM-DD").unix()).map(
          ({ startDate, endDate, ...program }) => ({
            ...program,
            startDate: dayjs(startDate, "YYYY-MM-DD").startOf("day"),
            endDate: dayjs(endDate, "YYYY-MM-DD").endOf("day"),
          }),
        ),
      ),
    });
  };

  const editScheduledProgramsDate = async (formModel: ScheduledClientProgramsDatesModel) => {
    const programsById = keyBy(formModel.programs, "id");
    const updatedPrograms = scheduledPrograms.map((p) => {
      const selectedProgram = programsById[p.id];
      if (selectedProgram) {
        return {
          ...p,
          startDate: selectedProgram.startDate.format("YYYY-MM-DD"),
          endDate: selectedProgram.endDate.format("YYYY-MM-DD"),
          duration: Math.ceil(selectedProgram.endDate.diff(selectedProgram.startDate, "weeks")),
        };
      } else {
        return p;
      }
    });

    await Promise.all(
      updatedPrograms.map((program) =>
        dispatch(
          traineeProgramActions.updateScheduledProgram({ program, programId: program.id, traineeId: client.id }),
        ),
      ),
    );
    closeEditingScheduledPrograms();
  };

  const closeEditingScheduledPrograms = () => {
    setProgramDatesModel(null);
  };

  return {
    visible,
    closeModal,
    openModal,
    routineModel: editedRoutine,
    removeScheduledProgram,
    updateSchedule,
    editScheduledPrograms,
    programDatesModel,
    closeEditingScheduledPrograms,
    editScheduledProgramsDate,
  };
};

const TraineeScheduledPrograms = ({ scheduledPrograms, trainee }: TraineeScheduledProgramsProps) => {
  const {
    visible,
    closeModal,
    openModal,
    routineModel,
    removeScheduledProgram,
    updateSchedule,
    editScheduledPrograms,
    programDatesModel,
    closeEditingScheduledPrograms,
    editScheduledProgramsDate,
  } = useScheduledClientProgramStepper(scheduledPrograms, trainee);
  const { selectedProgram } = useAppSelector((store) => store.traineeProgram);
  return (
    <div className="mb-8 mt-8">
      <Space direction="vertical" style={{ width: "100%" }} size={16}>
        <Typography.Title level={5}>Zaplanowane plany treningowe w przyszłości:</Typography.Title>

        <List
          itemLayout="horizontal"
          bordered
          locale={{
            emptyText: <Empty description="Brak zaplanowanych programów treningów w przyszłości" />,
          }}
          dataSource={scheduledPrograms}
          renderItem={(routine) => (
            <List.Item
              actions={[
                <Typography.Link key="edit" onClick={() => openModal(routine)}>
                  edytuj
                </Typography.Link>,
                <Popconfirm
                  key="remove"
                  title="Czy na pewno chcesz usunąc ten program?"
                  okText="Tak"
                  cancelText="Nie"
                  placement="left"
                  onConfirm={() => removeScheduledProgram(routine.id)}
                >
                  <Typography.Link type="danger">usuń</Typography.Link>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                title={<Typography.Text strong>{routine.name}</Typography.Text>}
                description={
                  <Space direction="horizontal" size={8}>
                    <CalendarOutlined />
                    <Typography.Text type="secondary">
                      Plan od {dayjs(routine.startDate, "YYYY-MM-DD").format("DD.MM.YYYY")} do{" "}
                      {dayjs(routine.endDate, "YYYY-MM-DD").format("DD.MM.YYYY")}
                    </Typography.Text>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
        <div className="mt-6 flex items-center justify-center">
          <Button icon={<EditOutlined />} type="primary" onClick={editScheduledPrograms}>
            Edytuj harmonogram planów
          </Button>
        </div>
      </Space>
      {routineModel && (
        <ScheduledClientProgramStepper
          visible={visible}
          handleClose={closeModal}
          program={routineModel}
          onScheduleSave={(model) => updateSchedule(routineModel.id, model)}
        />
      )}
      {programDatesModel && selectedProgram?.id === trainee.activeTrainingProgramId && (
        <ModalForm
          open
          onCancel={closeEditingScheduledPrograms}
          title="Edytuj harmonogram zaplanowanych programów"
          width={800}
        >
          <ScheduledClientProgramsDates
            onSubmit={editScheduledProgramsDate}
            model={programDatesModel}
            activeProgram={selectedProgram}
          />
        </ModalForm>
      )}
    </div>
  );
};

export default TraineeScheduledPrograms;
