import React, { useEffect, useMemo, useState } from "react";
import { CheckOutlined, CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Divider, Form, Popconfirm, Row, Select, Table, Typography } from "antd";
import cloneDeep from "lodash.clonedeep";
import sortBy from "lodash.sortby";
import truncate from "lodash.truncate";
import uniq from "lodash.uniq";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { automationTemplatesActions, RequestStatus, surveysTemplatesActions } from "@fitness-app/app-store";
import {
  type SurveyQuestion,
  type SurveyQuestionType,
  type SurveyTemplate,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import DraggableTable from "~/components/DraggableTable/DraggableTable";
import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useFormHandler } from "~/hooks/useFormHandler";
import SurveyExampleAnswer from "~/modules/SurveysTemplates/SurveyDetails/SurveyExampleAnswer";
import SurveyQuestionForm from "~/modules/SurveysTemplates/SurveyQuestionForm/SurveyQuestionForm";
import { type SurveyQuestionFormModel } from "~/modules/SurveysTemplates/SurveyQuestionForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const { Column } = Table;

const SurveyDetails = ({
  surveyTemplate,
  withoutCard = false,
}: {
  surveyTemplate?: SurveyTemplate;
  withoutCard?: boolean;
}) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["surveys", "common"]);

  const dispatch = useAppDispatch();
  const [isSurveyActionPending, toggleSurveyActionFlag] = useState(false);
  const { openFormModal, showModalForm, hideFormModal, formModel, editedEntity, setEditedEntity } = useFormHandler<
    SurveyQuestionFormModel,
    string
  >();
  const { selectedSurveyStatus, selectedSurvey: selectedFromStore } = useAppSelector((store) => store.surveysTemplates);
  const { isTrainer, userId } = useUserRole();
  const selectedSurvey = surveyTemplate || selectedFromStore;
  useEffect(() => {
    if (id) {
      void dispatch(surveysTemplatesActions.fetchSurveyTemplate(id));
    }
    void dispatch(automationTemplatesActions.fetchAutomationTemplates());
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(surveysTemplatesActions.clearFetchedSurveyTemplate());
    };
  }, []);

  const sortedDataSource: SurveyQuestion[] = useMemo(
    () =>
      selectedSurvey
        ? sortBy(
            Object.entries(selectedSurvey.questions).map(([id, question]) => ({
              ...question,
              id,
            })),
            ["orderKey"],
          )
        : [],
    [selectedSurvey],
  );

  const onSubmitForm = async (submitData: SurveyQuestionFormModel) => {
    toggleSurveyActionFlag(true);
    if (editedEntity && selectedSurvey) {
      await dispatch(
        surveysTemplatesActions.editSurveyTemplate({
          surveyTemplate: {
            ...selectedSurvey,
            questions: {
              ...selectedSurvey.questions,
              [editedEntity]: {
                ...(selectedSurvey?.questions[editedEntity] ?? {}),
                ...submitData.question,
              },
            },
          },
          surveyTemplateId: selectedSurvey.id,
        }),
      );
    } else if (selectedSurvey) {
      const lastItem = sortedDataSource[sortedDataSource.length - 1];
      const orderKey = lastItem ? lastItem.orderKey + 1 : 0;
      const questionId = uuid();
      await dispatch(
        surveysTemplatesActions.editSurveyTemplate({
          surveyTemplate: {
            ...selectedSurvey,
            questions: {
              ...selectedSurvey.questions,
              [questionId]: {
                ...submitData.question,
                id: questionId,
                orderKey,
              },
            },
          },
          surveyTemplateId: selectedSurvey.id,
        }),
      );
    }
    toggleSurveyActionFlag(false);
    hideFormModal();
  };

  const handleChangeSurveysQuestionsOrder = async (
    draggabledId: string,
    destinationIndex: number,
    sourceIndex: number,
  ) => {
    if (!selectedSurvey) {
      return;
    }

    const surveysQuestionsIds = sortBy(Object.values(selectedSurvey.questions), "orderKey").map(
      (question) => question.id,
    );
    if (!surveysQuestionsIds.includes(draggabledId)) {
      return;
    }

    surveysQuestionsIds.splice(sourceIndex, 1);
    surveysQuestionsIds.splice(destinationIndex, 0, draggabledId);

    const updatedQuestions = Object.fromEntries(
      surveysQuestionsIds.map((id, index) => [id, { ...selectedSurvey.questions[id]!, orderKey: index }]),
    );

    await dispatch(
      surveysTemplatesActions.editSurveyTemplate({
        surveyTemplate: {
          ...selectedSurvey,
          questions: updatedQuestions,
        },
        surveyTemplateId: selectedSurvey.id,
        optimisticUpdate: true,
      }),
    );
  };

  const saveGlobalVariables = async ({ globalVariables }: { globalVariables: string[] }) => {
    if (!selectedSurvey) {
      return;
    }
    await dispatch(
      surveysTemplatesActions.editSurveyTemplate({
        surveyTemplate: {
          globalVariables,
        },
        surveyTemplateId: selectedSurvey.id,
        optimisticUpdate: true,
      }),
    );
  };

  const removeQuestion = async (questionId: string) => {
    if (!selectedSurvey) {
      return;
    }

    const surveysQuestionsIds = sortBy(Object.values(selectedSurvey.questions), "orderKey")
      .map((question) => question.id)
      .filter((id) => id !== questionId);
    const updatedQuestions = Object.fromEntries(
      surveysQuestionsIds.map((id, index) => [id, { ...selectedSurvey.questions[id]!, orderKey: index }]),
    );

    const automation = cloneDeep(selectedSurvey.automation);

    if (automation?.[questionId]) {
      delete automation[questionId];
    }

    await dispatch(
      surveysTemplatesActions.editSurveyTemplate({
        surveyTemplate: {
          ...selectedSurvey,
          questions: updatedQuestions,
          automation,
        },
        surveyTemplateId: selectedSurvey.id,
        optimisticUpdate: true,
      }),
    );
  };

  const variables = useMemo(() => {
    if (!selectedSurvey?.automation) {
      return null;
    }

    return uniq(
      Object.values(selectedSurvey.automation)
        .map((automations) => automations.map((automation) => automation.tasks.map((task) => task.variables)).flat(2))
        .flat(2),
    )
      .filter((variable): variable is string => typeof variable === "string")
      .sort();
  }, [selectedSurvey]);

  return (
    <Row className="p-6">
      <Col xs={24}>
        <Card
          size={withoutCard ? "small" : undefined}
          title={withoutCard ? undefined : selectedSurvey?.title}
          loading={selectedSurveyStatus === RequestStatus.SUBSCRIBING}
          extra={
            isTrainer || userId === selectedSurvey?.createdBy ? (
              <Button
                size={withoutCard ? "small" : "middle"}
                type={withoutCard ? "dashed" : "primary"}
                icon={<PlusOutlined />}
                onClick={() => openFormModal()}
              >
                {t("surveyDetailsForm.addQuestion")}
              </Button>
            ) : null
          }
        >
          {selectedSurvey ? (
            <DraggableTable<SurveyQuestion>
              dataSource={sortedDataSource}
              dndDisabled={sortedDataSource.length === 0}
              onUpdateListOrder={handleChangeSurveysQuestionsOrder}
              rowKey="id"
              pagination={false}
              locale={{
                emptyText: t("surveyDetailsForm.emptyList"),
              }}
              expandable={{
                expandedRowRender: (row) => (
                  <SurveyExampleAnswer
                    key={`survey-question-${row.id}`}
                    question={row}
                    survey={selectedSurvey}
                    surveyId={selectedSurvey?.id ?? ""}
                    model={selectedSurvey?.automation?.[row.id]}
                  />
                ),
              }}
            >
              {isTrainer || selectedSurvey.createdBy === userId ? <Column title="" key="sort" width="5%" /> : null}

              <Column
                title={t("surveyDetailsForm.no")}
                align="center"
                key="index"
                width="5%"
                render={(_, __, index) => index + 1}
              />
              <Column
                title={t("surveyDetailsForm.question")}
                dataIndex="title"
                key="title"
                width="35%"
                render={(rowText: string) => truncate(rowText, { length: 80 })}
              />
              <Column
                title={t("surveyDetailsForm.questionType")}
                dataIndex="type"
                key="type"
                width="30%"
                render={(type: SurveyQuestionType) => t(`questionType.${type}`)}
              />
              <Column
                title={t("surveyDetailsForm.questionType")}
                dataIndex="isRequired"
                key="isRequired"
                render={(isRequired) =>
                  isRequired ? (
                    <CheckOutlined style={{ color: "#25b10f" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#d6d6d6" }} />
                  )
                }
              />
              <Column<SurveyQuestion>
                title={t("surveyDetailsForm.hasAutomation")}
                dataIndex="hasAutomation"
                key="hasAutomation"
                render={(_, row) =>
                  selectedSurvey.automation?.[row.id] ? (
                    <CheckOutlined style={{ color: "#25b10f" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#d6d6d6" }} />
                  )
                }
              />
              {isTrainer || userId === selectedSurvey.createdBy ? (
                <Column
                  title={t("common:button.options")}
                  dataIndex=""
                  key=""
                  width="30%"
                  align="center"
                  render={(_, rowData: SurveyQuestion) => (
                    <>
                      <Typography.Link
                        role="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditedEntity(rowData.id);
                          openFormModal({ question: rowData });
                        }}
                      >
                        {t("common:button.edit")}
                      </Typography.Link>
                      <Divider type="vertical" />
                      <Popconfirm
                        title={t("surveyDetailsForm.deleteConfirm")}
                        okText={t("common:yes")}
                        cancelText={t("common:no")}
                        placement="left"
                        onConfirm={() => removeQuestion(rowData.id)}
                      >
                        <Typography.Link type="danger" role="button">
                          {t("common:button.delete")}
                        </Typography.Link>
                      </Popconfirm>
                    </>
                  )}
                />
              ) : null}
            </DraggableTable>
          ) : null}

          {variables && variables.length > 0 ? (
            <div className="mt-6 max-w-3xl gap-y-4">
              <Alert
                showIcon
                message="Zdefiniuj zmienne, które są jednakowe dla wielu klientów tego samego trenera i można je zaktualizować w jednym miejscu."
              />
              <Form<{ globalVariables: string[] }>
                layout="vertical"
                className="mt-3"
                onFinish={saveGlobalVariables}
                disabled={!isTrainer}
                initialValues={{
                  globalVariables: selectedSurvey?.globalVariables || [],
                }}
              >
                <Form.Item name="globalVariables" label="Zmienne globalne">
                  <Select
                    className="max-w-lg"
                    options={variables.map((value) => ({ value, label: value }))}
                    mode="multiple"
                  />
                </Form.Item>

                <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                  Aktualizuj
                </Button>
              </Form>
            </div>
          ) : null}
        </Card>
      </Col>
      <ModalForm
        open={showModalForm}
        width={500}
        loading={isSurveyActionPending}
        onCancel={hideFormModal}
        title={formModel ? t("surveyDetailsForm.editQuestion") : t("surveyDetailsForm.addQuestion")}
      >
        <SurveyQuestionForm onSubmit={onSubmitForm} model={formModel} />
      </ModalForm>
    </Row>
  );
};

export default SurveyDetails;
