import { type AppThunk } from "../../../index";
import { unsubscribeFromProductDetails as actionCreator } from "../reducer";

export const unsubscribeFromProductDetails =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    if (subscriptions.realtimeProductListChannel) {
      await db.removeChannel(subscriptions.realtimeProductListChannel);
      subscriptions.productsListChannelName = null;
    }
    dispatch(actionCreator());
  };
