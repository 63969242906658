import { type Action, type ThunkDispatch } from "@reduxjs/toolkit";
import debounce from "lodash.debounce";

import { type UnreadMessages } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppStore, type AppThunk, type StoreDependencies, type StoreUtils } from "../../../index";
import { filterOutReadMessages } from "../helpers/filterOutReadMessages";
import {
  subscribeToUnreadMessagesFailed,
  subscribeToUnreadMessagesStarted,
  subscribeToUnreadMessagesSuccess,
  updateUnreadMessages,
} from "../reducer";
import { fetchUnreadMessages } from "./fetchUnreadMessages";
import { markChatMessagesAsRead } from "./markChatMessagesAsRead";
import { unsubscribeFromUnreadMessages } from "./unsubscribeFromUnreadMessages";

const debouncedMarkAsRead = (
  toMarkAsRead: Record<string, string[]>,
  _: UnreadMessages,
  dispatch: ThunkDispatch<AppStore, StoreDependencies & StoreUtils, Action<string>>,
) => {
  Object.entries(toMarkAsRead).forEach(([channelId, messages]) => {
    void dispatch(
      markChatMessagesAsRead({
        channelId,
        messages: messages.map((messageId) => ({ id: messageId })),
      }),
    );
  });
};

const debouncedCallback = debounce(debouncedMarkAsRead, 1000);

export const subscribeToUnreadMessages =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, auth, db }) => {
    try {
      dispatch(subscribeToUnreadMessagesStarted());

      void dispatch(fetchUnreadMessages());

      const { id } = await getLoggedUser(auth);
      const channelName = `chat-unread-messages-${id}`;

      if (subscriptions.unreadMessagesRealtimeChannel) {
        await db.removeChannel(subscriptions.unreadMessagesRealtimeChannel);
      }

      const subscription = db.channel(channelName);
      subscriptions.unreadMessagesRealtimeChannel = subscription;
      subscriptions.unreadMessagesChannelName = channelName;

      subscription
        .on<UnreadMessages>(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "unread_messages",
            filter: `id=eq.${id}`,
          },
          (payload) => {
            if (payload.eventType === "DELETE") {
              dispatch(unsubscribeFromUnreadMessages());
              return;
            }

            const unreadData = payload.new;

            const { messages, activeChannels, messagesToMark } = getState().chat;
            const filteredOut = filterOutReadMessages(unreadData.perChannel, messages, activeChannels, messagesToMark);
            if (Object.keys(filteredOut.toMarkAsRead).length) {
              debouncedCallback(filteredOut.toMarkAsRead, unreadData, dispatch);
            }

            if (payload.eventType === "INSERT") {
              dispatch(
                updateUnreadMessages({
                  ...unreadData,
                  perChannel: filteredOut.unreadMessagesPerChannel,
                  total: filteredOut.total,
                  _perChannel: unreadData.perChannel,
                }),
              );
            }
            if (payload.eventType === "UPDATE") {
              dispatch(
                updateUnreadMessages({
                  ...unreadData,
                  perChannel: filteredOut.unreadMessagesPerChannel,
                  total: filteredOut.total,
                  _perChannel: unreadData.perChannel,
                }),
              );
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToUnreadMessagesSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromUnreadMessages());
          }
        });
    } catch (error) {
      dispatch(subscribeToUnreadMessagesFailed());
    }
  };
