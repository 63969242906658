import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export const generateLinkForIntegration = createAsyncThunk<void, ActionParams<void>, AsyncThunkCreator<string>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/generateLinkForIntegration`,
  async ({ onSuccess, onFailure }, { rejectWithValue, extra: { integrationApi, analytics, auth } }) => {
    try {
      const currentUser = await getLoggedUser(auth);
      const res = await integrationApi.patch<{ url: string }>("/create-stripe-integration-link", {
        uid: currentUser.id,
        email: currentUser.email,
      });

      window.open(res.data.url);
      analytics.track("generate_stripe_integration_link");
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
