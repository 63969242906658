import React, { useEffect, useLayoutEffect, useRef, useState, type FunctionComponent } from "react";
import { Alert, Divider, Spin, Typography } from "antd";
import classNames from "classnames";
import isEqual from "lodash.isequal";
import last from "lodash.last";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

import { chatActions, chatSelectors, RequestStatus, type AppStore } from "@fitness-app/app-store";
import { ChatType, type ChatChannelWithMeta, type GroupedMessage } from "@fitness-app/data-models/entities/Chat";

import { UploadDndDropzone } from "~/components/Upload/UploadDndZone";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import ChatInput from "~/modules/Chat/components/ChatInput";
import ChatMessagesList from "~/modules/Chat/components/ChatMessagesList/ChatMessagesList";
import { scrollToBottom } from "~/modules/Chat/helpers/scrollToBottom";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  fullScreen?: boolean;
  channel: ChatChannelWithMeta;
  minimized?: boolean;
}

type Props = OwnProps;

export const getTheNewestMessage = (data: GroupedMessage[]) => last(last(data)?.messages) || null;

const messagesSelector = (channel: ChatChannelWithMeta) => (store: AppStore) =>
  chatSelectors.getChannelMessages(store, { channel, inverted: false });

const ChatWindow: FunctionComponent<Props> = ({ fullScreen, channel, minimized }) => {
  const { t } = useTranslation("chat");
  const messages = useSelector(messagesSelector(channel));
  const unreadMessagesRef = useRef<HTMLDivElement>(null);
  const unreadMessages = useAppSelector((store) => store.chat.unreadMessages?.perChannel[channel.id], shallowEqual);
  const channelStatus = useAppSelector((store) => store.chat.channelsStatus[channel.id], shallowEqual);
  const [firstNewMessage, setFirstNewMessage] = useState("");
  const lastElement = useRef(getTheNewestMessage(messages.groupedByDay));
  const { isClient } = useUserRole();
  const messageContainerRef = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (unreadMessages) {
      setFirstNewMessage(unreadMessages[0] || "");
    }
    setTimeout(() => {
      scrollToBottom(messageContainerRef, true);
    }, 200);
  }, [firstNewMessage]);

  useEffect(() => {
    void dispatch(chatActions.getInitialChannelMessages({ channelId: channel.id }));
    return () => {
      dispatch(chatActions.unsubscribeFromChannelMessages(channel.id));
    };
  }, [channel.id, dispatch]);

  useLayoutEffect(() => {
    const latestMessage = getTheNewestMessage(messages.groupedByDay);
    const hasNewMessage = !isEqual(lastElement.current?.id, latestMessage?.id);
    const hasNewReaction = !isEqual(lastElement.current?.reactions, latestMessage?.reactions);
    const isInitialFetch = hasNewMessage && !lastElement.current?.id && latestMessage?.id;

    if (firstNewMessage && isInitialFetch) {
      unreadMessagesRef.current?.scrollIntoView();
      setTimeout(() => {
        lastElement.current = latestMessage;
      }, 2000);

      return;
    }

    if (isInitialFetch) {
      setTimeout(() => {
        scrollToBottom(messageContainerRef, true);
      }, 300);
      lastElement.current = latestMessage;

      return;
    }

    lastElement.current = latestMessage;
    if (hasNewMessage || hasNewReaction) {
      scrollToBottom(messageContainerRef, true);
    }
  }, [messages.groupedByDay, firstNewMessage]);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const shouldFetchPreviousPage =
      !channelStatus?.isLastPage && !channelStatus?.fetchingNextPage && !channelStatus?.fetchingFirstPage;
    if (target.scrollTop === 0 && shouldFetchPreviousPage) {
      const scrollHeightBeforeNewMessages = target.scrollHeight;
      await dispatch(chatActions.getNextChannelMessages({ channelId: channel.id }));

      target.scrollTop = target.scrollHeight - scrollHeightBeforeNewMessages;
    }
  };

  const scrollOnResize = () => {
    scrollToBottom(messageContainerRef, false);
  };

  const renderChatContent = (style?: React.CSSProperties) => {
    if (channelStatus?.status === RequestStatus.FAILED) {
      return (
        <div className="relative flex h-36 flex-1 items-center justify-center">
          <Alert showIcon message="error" type="error" />
        </div>
      );
    }
    if (!messages.groupedByDay?.length && channelStatus?.fetchingFirstPage) {
      return (
        <div className="relative flex h-36 flex-1 items-center justify-center">
          <Spin />
        </div>
      );
    }

    if (!messages.groupedByDay?.length) {
      return (
        <div className="relative flex h-36 flex-1 items-center justify-center">
          <Alert message={t("channel.sendFirstMessage")} type="info" showIcon />
        </div>
      );
    }
    return (
      <div
        className={classNames("relative flex overflow-y-auto overflow-x-hidden overscroll-none", {
          "client-chat-window": fullScreen,
        })}
        style={{ height: fullScreen ? "unset" : 380, ...(style ? style : {}) }}
        ref={messageContainerRef}
        onScroll={handleScroll}
      >
        <ChatMessagesList
          messages={messages}
          firstNewMessage={firstNewMessage}
          channel={channel}
          unreadRef={unreadMessagesRef}
          channelStatus={
            channelStatus || {
              isLastPage: false,
              currentPage: 1,
              fetchingFirstPage: true,
              fetchingNextPage: false,
              status: RequestStatus.SUBSCRIBING,
            }
          }
        />
      </div>
    );
  };

  if (fullScreen) {
    return (
      <div className="relative mb-6 flex h-full flex-col">
        {renderChatContent({ height: "auto", padding: "0 36px" })}

        <div className="relative px-4">
          {isClient && channel.type === ChatType.OneWay ? (
            <div className="text-center">
              <Divider style={{ margin: "12px 0" }} />
              <Typography.Text type="secondary">{channel.name || t("type.oneWay")}</Typography.Text>
            </div>
          ) : (
            <ChatInput channelId={channel.id} channel={channel} />
          )}
        </div>
      </div>
    );
  }

  return (
    <UploadDndDropzone>
      <div
        style={{
          padding: "0",
          height: 456,
          overflowX: "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {renderChatContent()}

        {isClient && channel.type === ChatType.OneWay ? (
          <div className="text-center">
            <Divider style={{ margin: "12px 0" }} />
            <Typography.Text type="secondary">{channel.name || t("type.oneWay")}</Typography.Text>
          </div>
        ) : (
          <ChatInput channel={channel} channelId={channel.id} minimized={minimized} onResize={scrollOnResize} />
        )}
      </div>
    </UploadDndDropzone>
  );
};

export default ChatWindow;
