import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Dayjs } from "dayjs";
import { type Order } from "@fitness-app/data-models/entities/Order";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_ORDERS_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  selectedMonth: Dayjs;
  ownerId?: string;
};

export const fetchOrders = createAsyncThunk<Order[], Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_ORDERS_REDUCER_NAME}/fetchOrders`,
  async ({ productId, selectedMonth }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("product_order")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("product", productId)
      .gte("createdAt", selectedMonth.startOf("month").toISOString())
      .lte("createdAt", selectedMonth.endOf("month").toISOString())
      .returns<Order[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
