import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs, { type Dayjs } from "dayjs";

import { type ProductMonthStats } from "@fitness-app/data-models/entities/Reports";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { getParentTrainerId } from "../../user/selectors";
import { STATISTICS_REDUCER_NAME } from "../types";

export const fetchProductMonthStats = createAsyncThunk<
  ProductMonthStats | null,
  { productId: string; date?: Dayjs; ownerId?: string },
  AsyncThunkCreator<string>
>(
  `${STATISTICS_REDUCER_NAME}/fetchProductMonthStats`,
  async ({ productId, date }, { rejectWithValue, getState, extra: { db } }) => {
    try {
      const parentId = getParentTrainerId(getState());

      if (!parentId) {
        throw new Error("user-is-not-logged");
      }

      const { data, error } = await db
        .from("product_month_stats")
        .select("*")
        .eq("creator", parentId)
        .eq("productId", productId)
        .eq("monthYear", date ? date.format("YYYY-M") : dayjs().format("YYYY-M"))
        .maybeSingle<ProductMonthStats>();

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
