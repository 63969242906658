// Unread message received for one of the open chats should not increase unread messages' counter,
// and should be immediately mark as read.

import { type ChatChannel, type ChatMessage } from "@fitness-app/data-models/entities/Chat";

export const filterOutReadMessages = (
  unreadMessagesPerChannel: Record<string, string[]>,
  messages: Record<string, ChatMessage[]>,
  activeChannels: (ChatChannel & { minimized?: boolean })[],
  messagesToMark: Record<string, string[]>,
) => {
  const toMarkAsRead: Record<string, string[]> = {};
  const activeChannelWithoutMinimized = activeChannels.filter((item) => !item.minimized);
  const newUnread = Object.keys(unreadMessagesPerChannel).reduce(
    (accumulator, chatRoomId) => {
      if (
        !activeChannelWithoutMinimized.find((item) => item.id === chatRoomId) &&
        !messagesToMark[chatRoomId]?.length
      ) {
        accumulator[chatRoomId] = unreadMessagesPerChannel[chatRoomId] || [];

        return accumulator;
      }

      const unread = unreadMessagesPerChannel[chatRoomId] || [];

      const messagesForChat = messages[chatRoomId]?.map((message) => message.id) || [];
      const messagesToMarkPerRoom = messagesToMark[chatRoomId] || [];

      const withoutOpen: string[] = [];

      unread.forEach((messageId) => {
        if (messagesToMarkPerRoom.includes(messageId)) {
          toMarkAsRead[chatRoomId] = [...(toMarkAsRead[chatRoomId] || []), messageId];
        } else if (!messagesForChat.includes(messageId)) {
          withoutOpen.push(messageId);
        }
      });

      accumulator[chatRoomId] = withoutOpen;

      return accumulator;
    },
    {} as Record<string, string[]>,
  );

  return {
    unreadMessagesPerChannel: newUnread,
    toMarkAsRead,
    total: Object.values(newUnread).reduce((prev, current) => prev + current.length, 0),
  };
};
