import { useState } from "react";
import { message } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { programAutomationActions } from "@fitness-app/app-store";
import {
  TemplateType,
  type OneTimeSurveyTemplate,
  type RecurringSurveyTemplate,
  type ScheduledSurveyAutomation,
} from "@fitness-app/data-models/entities/AutomationTemplate";
import { CycleSurveyType, RecurringSurveyStatus, SurveyType } from "@fitness-app/data-models/entities/ClientSurvey";
import { type WeekdayNumberToString } from "@fitness-app/data-models/entities/ProgramAutomation";
import { type SurveyTemplate } from "@fitness-app/data-models/entities/SurveyTemplate";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { type AddSurveyTemplateFormModel } from "~/modules/Automation/ProgramAutomation/components/AddSurveyTemplateForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

dayjs.extend(timezone);

const generateTemplate = (template: SurveyTemplate) => {
  return {
    id: generateUniqId(),
    name: template.name,
    description: template.description,
    templateId: template.id,
    questions: template.questions,
    automation: template.automation || null,
    answers: null,
    notRemovable: false,
  };
};

const getVariables = (model: AddSurveyTemplateFormModel) => {
  if (!model.variables) {
    return null;
  }
  return Object.fromEntries(Object.entries(model.variables).map(([key, value]) => [key, value || ""]));
};

export const useSurveyTemplateForm = (
  selected: null | {
    week: string;
    day: WeekdayNumberToString;
  },
  setCell: () => void,
) => {
  const { selectedAutomation } = useAppSelector((store) => store.programAutomation);
  const [showSurveyForm, toggleSurveyForm] = useState(false);
  const dispatch = useAppDispatch();
  const [addingSurvey, toggleStatus] = useState(false);
  const { t } = useTranslation(["automation", "common"]);

  const handleFormSubmit = async (formModel: AddSurveyTemplateFormModel, template: SurveyTemplate) => {
    let surveyToAdd: ScheduledSurveyAutomation | null = null;
    toggleStatus(true);

    const timezone = dayjs.tz.guess() || "Europe/Warsaw";

    if (formModel.type === SurveyType.OneTime) {
      const clientOneTimeSurvey: OneTimeSurveyTemplate = {
        ...generateTemplate(template),
        variables: getVariables(formModel),
        title: formModel.title,
        type: SurveyType.OneTime,
        editable: formModel.editable,
        interval: null,
      };

      surveyToAdd = {
        type: TemplateType.ScheduledSurvey,
        survey: clientOneTimeSurvey,
        id: generateUniqId(),
        name: formModel.title,
        templateId: null,
        comment: "",
        sentTime: {
          hours: formModel.timeOfDelivery.get("hour"),
          minutes: formModel.timeOfDelivery.get("minutes"),
          offset: formModel.timeOfDelivery.utcOffset(),
          timezone,
        },
      };
    } else {
      if (formModel.cycleType === CycleSurveyType.Infinite) {
        const infiniteCycle: RecurringSurveyTemplate = {
          ...generateTemplate(template),
          variables: getVariables(formModel),
          title: formModel.title,
          type: SurveyType.Recurring,
          editable: formModel.editable,
          interval: {
            dayOfDelivery: formModel.dayOfDelivery || "monday",
            status: RecurringSurveyStatus.New,
            count: formModel.count || 2,
            unit: formModel.unit,
            timeOfDelivery: formModel.timeOfDelivery
              ? [
                  formModel.timeOfDelivery.get("hour"),
                  formModel.timeOfDelivery.get("minutes"),
                  formModel.timeOfDelivery.utcOffset(),
                  timezone,
                ]
              : [0, 0, 0],
            cycle: {
              type: CycleSurveyType.Infinite,
            },
          },
          cycleNumber: 1,
        };

        surveyToAdd = {
          id: generateUniqId(),
          name: formModel.title,
          templateId: null,
          comment: "",
          type: TemplateType.ScheduledSurvey,
          survey: infiniteCycle,
          sentTime: {
            hours: formModel.timeOfDelivery.get("hour"),
            minutes: formModel.timeOfDelivery.get("minutes"),
            offset: formModel.timeOfDelivery.utcOffset(),
            timezone,
          },
        };
      }

      if (formModel.cycleType === CycleSurveyType.CycleNumber) {
        const infiniteCycle: RecurringSurveyTemplate = {
          ...generateTemplate(template),
          variables: getVariables(formModel),
          title: formModel.title,
          type: SurveyType.Recurring,
          editable: formModel.editable,
          interval: {
            dayOfDelivery: formModel.dayOfDelivery || "monday",
            status: RecurringSurveyStatus.New,
            count: formModel.count || 2,
            unit: formModel.unit,
            timeOfDelivery: formModel.timeOfDelivery
              ? [
                  formModel.timeOfDelivery.get("hour"),
                  formModel.timeOfDelivery.get("minutes"),
                  formModel.timeOfDelivery.utcOffset(),
                  timezone,
                ]
              : [0, 0, 0, timezone],
            cycle: {
              type: CycleSurveyType.CycleNumber,
              numberOfCycles: formModel.cycleTypeNumber || 1,
            },
          },
          cycleNumber: 1,
        };

        surveyToAdd = {
          id: generateUniqId(),
          name: formModel.title,
          templateId: null,
          comment: "",
          type: TemplateType.ScheduledSurvey,
          survey: infiniteCycle,
          sentTime: {
            hours: formModel.timeOfDelivery.get("hour"),
            minutes: formModel.timeOfDelivery.get("minutes"),
            offset: formModel.timeOfDelivery.utcOffset(),
            timezone,
          },
        };
      }

      if (formModel.cycleType === CycleSurveyType.UntilDate) {
        const infiniteCycle: RecurringSurveyTemplate = {
          ...generateTemplate(template),
          variables: getVariables(formModel),
          title: formModel.title,
          type: SurveyType.Recurring,
          editable: formModel.editable,
          interval: {
            dayOfDelivery: formModel.dayOfDelivery || "monday",
            status: RecurringSurveyStatus.New,
            count: formModel.count || 2,
            unit: formModel.unit,
            timeOfDelivery: formModel.timeOfDelivery
              ? [
                  formModel.timeOfDelivery.get("hour"),
                  formModel.timeOfDelivery.get("minutes"),
                  formModel.timeOfDelivery.utcOffset(),
                  timezone,
                ]
              : [0, 0, 0],
            cycle: {
              type: CycleSurveyType.UntilDate,
              date: formModel.untilDate ? formModel.untilDate.toISOString() : dayjs().add(1, "month").toISOString(),
            },
          },
          cycleNumber: 1,
        };

        surveyToAdd = {
          id: generateUniqId(),
          name: formModel.title,
          templateId: null,
          comment: "",
          type: TemplateType.ScheduledSurvey,
          survey: infiniteCycle,
          sentTime: {
            hours: formModel.timeOfDelivery.get("hour"),
            minutes: formModel.timeOfDelivery.get("minutes"),
            offset: formModel.timeOfDelivery.utcOffset(),
            timezone,
          },
        };
      }
    }

    if (surveyToAdd) {
      if (!selected || !selectedAutomation) {
        throw new Error("No selected cell");
      }
      const weekToUpdate = cloneDeep(selectedAutomation.schedule[selected.week]) || {};
      const itemsToUpdate = weekToUpdate[selected.day];
      weekToUpdate[selected.day] = {
        id: weekToUpdate[selected.day]?.id ?? uuid(),
        items: itemsToUpdate?.items ? [...itemsToUpdate.items, surveyToAdd] : [surveyToAdd],
      };
      try {
        await dispatch(
          programAutomationActions.updateAutomationTemplate({
            automationId: selectedAutomation.id,
            automation: {
              id: selectedAutomation.id,
              schedule: {
                ...selectedAutomation.schedule,
                [selected.week]: weekToUpdate,
              },
            },
          }),
        ).unwrap();
        toggleSurveyForm(false);
        setCell();
      } catch (e) {
        void message.error(t("saveError"));
      } finally {
        toggleStatus(false);
      }
    }
  };

  return {
    onSendSurveyForm: handleFormSubmit,
    showSurveyForm,
    toggleSurveyForm,
    addingSurvey,
  };
};
