import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchProductClients } from "./actions/fetchProductClients";
import { fetchProductClientsNextPage } from "./actions/fetchProductClientsNextPage";
import { PRODUCT_CLIENTS_REDUCER_NAME, type ProductClientsReducer } from "./types";

const initialState: ProductClientsReducer = {
  page: 0,
  listSize: 25,
  productId: null,
  list: [],
  listStatus: null,
  isLastPage: false,
  totalPages: 0,
  searchStatus: null,
  searchTerm: "",
  filters: {
    statuses: ["active"],
  },
};

const reducerSlice = createSlice({
  initialState,
  name: PRODUCT_CLIENTS_REDUCER_NAME,
  reducers: {
    updateStatusesFilter(state, { payload }: PayloadAction<ProductClient["status"][]>) {
      state.filters.statuses = payload;
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
    clearProductClients() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductClients.pending, (state) => {
      if (!state.listStatus) {
        state.listStatus = RequestStatus.FETCHING;
        state.list = [];
      }
    });
    builder.addCase(fetchProductClients.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.page = payload.page;
      state.isLastPage = payload.isLastPage;
      state.list = payload.list;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(fetchProductClients.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchProductClientsNextPage.fulfilled, (state, { payload }) => {
      state.page = payload.page;
      state.isLastPage = payload.isLastPage;
      state.list = [...state.list, ...payload.list];
    });
  },
});

export const { updateStatusesFilter, setSearchTerm, clearProductClients } = reducerSlice.actions;

export default reducerSlice.reducer;
