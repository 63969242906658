import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload =
  | { traineeNutritionId: string; download?: boolean; traineeId: string; breakable?: boolean }
  | { nutritionId: string; download?: boolean; breakable?: boolean };

export const generatePdfForTemplate = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/generatePdfForTemplate`,
  async (payload, { extra: { nutritionPdfApi, analytics } }) => {
    if (!nutritionPdfApi) {
      throw new Error("nutritionApi-is-not-defined");
    }

    const response = await nutritionPdfApi.post<{ url: string }>("/generate-pdf", payload);

    analytics.track("generate_pdf_for_template");

    return response.data.url;
  },
);
