import { useQuery } from "@tanstack/react-query";

import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";

import { supabase } from "~/store/initializeStore";

export const useScheduledNutritionPlans = (traineeId?: string) => {
  const { data } = useQuery(["scheduledNutrition", traineeId], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!traineeId) {
        return;
      }
      const { data } = await supabase
        .from("client_meals_plan")
        .select("id, name, createdAt, startAt, templateId, template:templateId(name, id)")
        .order("startAt", { ascending: true })
        .eq("traineeId", traineeId)
        .eq("status", ClientNutritionStatus.Scheduled)
        .throwOnError();

      return data;
    },
  });

  return {
    scheduled: data,
  };
};
