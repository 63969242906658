import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TeamMember } from "@fitness-app/data-models";

import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = string;

export const fetchTeamMember = createAsyncThunk<TeamMember, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/fetchTeamMember`,
  async (memberId, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("team_member")
      .select()
      .order("createdAt", { ascending: true })
      .eq("id", memberId)
      .single<TeamMember>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
