import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductStats } from "@fitness-app/data-models/entities/Reports";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { getParentTrainerId } from "../../user/selectors";
import { STATISTICS_REDUCER_NAME } from "../types";

export const fetchProductStats = createAsyncThunk<
  ProductStats | null,
  { productId: string; ownerId?: string },
  AsyncThunkCreator<string>
>(
  `${STATISTICS_REDUCER_NAME}/fetchProductStats`,
  async ({ productId }, { getState, rejectWithValue, extra: { db } }) => {
    try {
      const parentId = getParentTrainerId(getState());

      if (!parentId) {
        throw new Error("user-is-not-logged");
      }

      const { data, error } = await db
        .from("product_stats")
        .select("*")
        .eq("owner", parentId)
        .eq("productId", productId)
        .maybeSingle<ProductStats>();

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
