import { type Database } from "@fitness-app/supabase";

import { type SubscriberIntegrationClient } from "./Automation";
import { type TeamMemberRoleEnum, type TeamMemberStatusEnum } from "./TeamMember";

export interface IDiscordIntegrationRequested {
  createdAt: Date;
  updatedAt: Date;
  state: string;
  status: "requested" | "failed";
  version?: number;
  source?: "panel" | "api";
}

export interface IDiscordIntegrationCompleted {
  createdAt: Date;
  updatedAt: Date;
  state: string;
  status: "completed";
  apiTokenPath: string;
  apiRefreshTokenPath: string;
  discordUser: {
    username: string;
    id: string;
  };
  version?: number;
  source?: "panel" | "api";
}

export type UserProfileEntity = Database["public"]["Tables"]["user_profile"]["Row"];
export type UserProfile = Omit<UserProfileEntity, "clientIntegrations"> & {
  clientIntegrations?: {
    [SubscriberIntegrationClient.Discord]?: IDiscordIntegrationRequested | IDiscordIntegrationCompleted;
  } | null;
};

export type UserRoleEnum = Database["public"]["Enums"]["Role"];
export const UserRole: Record<Database["public"]["Enums"]["Role"], Database["public"]["Enums"]["Role"]> = {
  CLIENT: "CLIENT",
  TRAINER: "TRAINER",
  TEAM_MEMBER: "TEAM_MEMBER",
  BOT: "BOT",
  SYSTEM: "SYSTEM",
} as const;

export type UserLangEnum = Database["public"]["Enums"]["Lang"];
export const UserLang: Record<Database["public"]["Enums"]["Lang"], Database["public"]["Enums"]["Lang"]> = {
  PL: "PL",
  EN: "EN",
};

export enum UserActivityStatus {
  Online = "online",
  Offline = "offline",
}

export interface TeamMemberCustomClaims {
  role: "TEAM_MEMBER";
  status: TeamMemberStatusEnum;
  memberId: string;
  trainerId: string;
  memberRole: TeamMemberRoleEnum;
}

export interface TrainerCustomClaims {
  role: "TRAINER";
  status?: never;
  memberId?: never;
  memberRole?: never;
  trainerId?: never;
}

export type CustomClaims = TeamMemberCustomClaims | TrainerCustomClaims;
