import {
  TrainingProgramLevel,
  TrainingProgramType,
  type TrainingProgramLevelEnum,
  type TrainingProgramTypeEnum,
} from "@fitness-app/data-models/entities/TrainingProgram";

import i18next from "~/i18nextConfig";

export interface ProgramFormModel {
  name: string;
  level: TrainingProgramLevelEnum | null;
  type: TrainingProgramTypeEnum | null;
  comment: string;
  tags: string[];
}

export const programLevelOptions = [
  {
    label: i18next.t(`workouts:programLevel.${TrainingProgramLevel.BEGINNER}`),
    value: TrainingProgramLevel.BEGINNER,
  },
  {
    label: i18next.t(`workouts:programLevel.${TrainingProgramLevel.INTERMEDIATE}`),
    value: TrainingProgramLevel.INTERMEDIATE,
  },
  {
    label: i18next.t(`workouts:programLevel.${TrainingProgramLevel.ADVANCED}`),
    value: TrainingProgramLevel.ADVANCED,
  },
];

export const programTypeOptions = [
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.FULL_BODY_WORKOUT}`),
    value: TrainingProgramType.FULL_BODY_WORKOUT,
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.PUSH_PULL}`),
    value: TrainingProgramType.PUSH_PULL,
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.SPLIT}`),
    value: TrainingProgramType.SPLIT,
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.BETTER_SHAPE}`),
    value: TrainingProgramType.BETTER_SHAPE,
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType["5X5"]}`),
    value: TrainingProgramType["5X5"],
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.AB_ROUTINE}`),
    value: TrainingProgramType.AB_ROUTINE,
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.FITNESS}`),
    value: TrainingProgramType.FITNESS,
  },
  {
    label: i18next.t(`workouts:programType.${TrainingProgramType.OTHER}`),
    value: TrainingProgramType.OTHER,
  },
];
