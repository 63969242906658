import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../../../enums/requestStatus";
import { addTemplate } from "./actions/addTemplate";
import { deleteTemplate } from "./actions/deleteTemplate";
import { fetchAutomationTemplates } from "./actions/fetchAutomationTemplates";
import { updateTemplate } from "./actions/updateTemplate";
import { TEMPLATES_REDUCER_NAME, type AutomationTemplatesReducer } from "./types";

const initialState: AutomationTemplatesReducer = {
  status: null,
  listData: [],
  error: null,
};

const templatesSlice = createSlice({
  name: TEMPLATES_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAutomationTemplates.pending, (state) => {
      state.status = RequestStatus.FETCHING;
      state.error = null;
    });
    builder.addCase(fetchAutomationTemplates.fulfilled, (state, { payload }) => {
      state.status = RequestStatus.SUCCESS;
      state.listData = payload;
    });
    builder.addCase(fetchAutomationTemplates.rejected, (state, { payload }) => {
      state.status = RequestStatus.FAILED;
      state.error = payload || null;
    });
    builder.addCase(addTemplate.fulfilled, (state, { payload }) => {
      state.listData.unshift(payload);
    });
    builder.addCase(updateTemplate.fulfilled, (state, { payload }) => {
      state.listData = state.listData.map((template) => {
        if (template.id === payload.id) {
          return payload;
        }

        return template;
      });
    });
    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      state.listData = state.listData.filter((template) => template.id !== payload);
    });
  },
});

export default templatesSlice.reducer;
