import { createAsyncThunk } from "@reduxjs/toolkit";

import { MeasurementType, type WeightMeasurement } from "@fitness-app/data-models/entities/Measurement";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
};

export const fetchLatestWeightMeasurement = createAsyncThunk<
  WeightMeasurement[] | null,
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/fetchLatestWeightMeasurement`,
  async ({ traineeId }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("measurement")
      .select("*")
      .order("eventTimestamp", { ascending: false })
      .eq("traineeId", traineeId)
      .in("type", [MeasurementType.WeightMeasurement])
      .limit(2)
      .returns<WeightMeasurement[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
