import { ClientActivityFlag } from "@fitness-app/data-models/entities/ClientActivity";

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";

export const flagColor = {
  [ClientActivityFlag.Idle]: "rgba(224, 224, 226, 0.8)",
  [ClientActivityFlag.Danger]: "#ED1C24",
  [ClientActivityFlag.Warning]: "#faad14",
  [ClientActivityFlag.Safe]: "#52c41a",
  [ClientActivityFlag.VerySafe]: "#52c41a",
};
