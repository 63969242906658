import React, { useEffect, useMemo, useRef, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Divider, Form, Input, InputNumber, Select, Switch, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import { orderBy } from "lodash";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { DietaryPreference, type MealsPlanWithCreator } from "@fitness-app/data-models/entities/MealsPlan";
import { type ProgramAutomation } from "@fitness-app/data-models/entities/ProgramAutomation";
import { getName } from "@fitness-app/data-models/utils/getAuthorName";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useTagOptions } from "~/hooks/useTagOptions";
import { supabase } from "~/store/initializeStore";
import { type ScheduledNutritionFormModel } from "./types";

interface ScheduledNutritionFormProps {
  formController?: FormInstance<ScheduledNutritionFormModel>;
  onSubmit: (formData: ScheduledNutritionFormModel, mealsPlanById: Record<string, MealsPlanWithCreator>) => void;
  model?: Partial<ScheduledNutritionFormModel> | null;
  selectedAutomation: ProgramAutomation;
  withoutWeeksLimit?: boolean;
}

const ScheduledNutritionForm = ({
  model,
  onSubmit,
  formController,
  selectedAutomation,
  withoutWeeksLimit = false,
}: ScheduledNutritionFormProps) => {
  const { options: tagsOptions } = useTagOptions("mealsPlanTags");
  const { t } = useTranslation(["automation", "common"]);
  const ranges = Form.useWatch("ranges", formController);
  const tags = Form.useWatch("tags", formController);
  const [filter, changeFilter] = useState<null | "only_author">("only_author");
  const [searchValue, setSearchValue] = useState("");
  const { userId } = useUserRole();
  const aggregation = useRef({});

  const { data, isLoading } = useQuery(["searchNutritionPlan", filter, searchValue, tags], {
    keepPreviousData: true,
    queryFn: async () => {
      let query = supabase
        .from("meals_plan")
        .select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

      if (filter && filter === "only_author") {
        query = query.eq("createdBy", userId);
      }

      if (searchValue && searchValue.length > 0) {
        query = query.ilike("name", `%${searchValue}%`);
      }

      if (tags?.length) {
        query = query.or(tags.map((tag) => `tags.cs.["${tag}"]`).join(","));
      }

      const { error, data } = await query
        .order("createdAt", { ascending: false })
        .range(0, 50)
        .returns<MealsPlanWithCreator[]>();

      if (error) {
        throw new Error(error.message);
      }

      aggregation.current = {
        ...aggregation.current,
        ...keyBy(data, "id"),
      };

      return data;
    },
  });
  const currentNumberOfScheduledWeeks = Object.keys(selectedAutomation.schedule).length;

  const mealsPlanOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    return orderBy(
      data.map((mealsPlan) => ({
        ...mealsPlan,
        label: mealsPlan.name,
        value: mealsPlan.id,
      })),
      "label",
      "asc",
    );
  }, [data]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    } else {
      const lastItem = selectedAutomation.scheduledNutritionPlan?.at(-1);
      formController?.setFieldsValue({
        startWeek: lastItem ? (lastItem?.endWeek || 1) + 1 : 1,
      });
    }
  }, [model]);

  const onPlanSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
  }, 300);

  return (
    <Form<ScheduledNutritionFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        startWeek: 1,
        endWeek: currentNumberOfScheduledWeeks,
        additionalComment: "",
        ranges: [],
      }}
      onFinish={(formData) => onSubmit(formData, aggregation.current)}
    >
      <>
        <Form.Item
          name="startWeek"
          label={t<string>("nutrition.form.from")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <InputNumber min={1} max={withoutWeeksLimit ? undefined : currentNumberOfScheduledWeeks} />
        </Form.Item>

        <Form.Item name="endWeek" label={t<string>("nutrition.form.to")}>
          <InputNumber min={1} max={withoutWeeksLimit ? undefined : currentNumberOfScheduledWeeks} />
        </Form.Item>
      </>

      <Form.Item name="tags" label="Filtruj przez tagi">
        <Select notFoundContent={false} options={tagsOptions} mode="multiple" />
      </Form.Item>

      <Form.Item label={t<string>("nutrition.form.ranges")}>
        <Form.List name="ranges">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key} className="flex w-full flex-col">
                  <div className="flex gap-x-4">
                    <Form.Item
                      name={[field.name, "from"]}
                      rules={[
                        {
                          required: index !== 0,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <InputNumber
                        min={index === 0 ? undefined : ranges[index - -1]?.to ?? undefined}
                        placeholder={t<string>("nutrition.form.fromPlaceholder")}
                        addonAfter="kg"
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "to"]}
                      rules={[
                        {
                          required: ranges?.length - 1 !== index,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <InputNumber placeholder={t<string>("nutrition.form.toPlaceholder")} addonAfter="kg" />
                    </Form.Item>
                  </div>

                  <Form.Item label="Wyświetl tylko moje szablony">
                    <Switch
                      value={Boolean(filter)}
                      onChange={(checked) => changeFilter(checked ? "only_author" : null)}
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t<string>("common:validationErrors.fieldIsRequired"),
                      },
                    ]}
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    label={t(`nutrition:dietaryPreferenceEnum.${DietaryPreference.NoPreference}`)}
                    name={[field.name, "nutrition", DietaryPreference.NoPreference]}
                    validateStatus={isLoading ? "validating" : undefined}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder={t("nutrition.form.selectNutrition")}
                      disabled={!!model}
                      dropdownMatchSelectWidth={false}
                      onSearch={onPlanSearch}
                      optionLabelProp="label"
                      optionFilterProp="label"
                    >
                      {mealsPlanOptions.map((option) => (
                        <Select.Option value={option.value} label={option.label} key={option.value}>
                          <div className={cn("flex flex-col gap-y-2")}>
                            <span>
                              {option.label} {getName(option.creator, undefined, true)}
                            </span>
                            <Typography.Text className="text-xs" type="secondary">
                              {option.targetCalories} kcal -{" "}
                              {t(`nutrition:dietaryPreferenceEnum.${option.dietaryPreference}`)} -{" "}
                              {t(`nutrition:macroSplit.${option.macroSplit}`)} -{" "}
                              {t(`nutrition:mealsPerDay`, { count: option.mealsSchema })} -{" "}
                            </Typography.Text>
                            {option.tags?.length ? (
                              <Typography.Text className="text-xs" type="secondary">
                                Tagi: {option.tags?.join(", ")}
                              </Typography.Text>
                            ) : null}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    label={t(`nutrition:dietaryPreferenceEnum.${DietaryPreference.SemiVegetarian}`)}
                    name={[field.name, "nutrition", DietaryPreference.SemiVegetarian]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder={t("nutrition.form.selectNutrition")}
                      disabled={!!model}
                      dropdownMatchSelectWidth={false}
                      onSearch={onPlanSearch}
                      optionLabelProp="label"
                      optionFilterProp="label"
                    >
                      {mealsPlanOptions.map((option) => (
                        <Select.Option value={option.value} label={option.label} key={option.value}>
                          <div className={cn("flex flex-col gap-y-2")}>
                            <span>
                              {option.label} {getName(option.creator, undefined, true)}
                            </span>
                            <Typography.Text className="text-xs" type="secondary">
                              {option.targetCalories} kcal -{" "}
                              {t(`nutrition:dietaryPreferenceEnum.${option.dietaryPreference}`)} -{" "}
                              {t(`nutrition:macroSplit.${option.macroSplit}`)} -{" "}
                              {t(`nutrition:mealsPerDay`, { count: option.mealsSchema })} -{" "}
                            </Typography.Text>
                            {option.tags?.length ? (
                              <Typography.Text className="text-xs" type="secondary">
                                Tagi: {option.tags?.join(", ")}
                              </Typography.Text>
                            ) : null}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    label={t(`nutrition:dietaryPreferenceEnum.${DietaryPreference.Vegetarian}`)}
                    name={[field.name, "nutrition", DietaryPreference.Vegetarian]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder={t("nutrition.form.selectNutrition")}
                      disabled={!!model}
                      dropdownMatchSelectWidth={false}
                      onSearch={onPlanSearch}
                      optionLabelProp="label"
                      optionFilterProp="label"
                    >
                      {mealsPlanOptions.map((option) => (
                        <Select.Option value={option.value} label={option.label} key={option.value}>
                          <div className={cn("flex flex-col gap-y-2")}>
                            <span>
                              {option.label} {getName(option.creator, undefined, true)}
                            </span>
                            <Typography.Text className="text-xs" type="secondary">
                              {option.targetCalories} kcal -{" "}
                              {t(`nutrition:dietaryPreferenceEnum.${option.dietaryPreference}`)} -{" "}
                              {t(`nutrition:macroSplit.${option.macroSplit}`)} -{" "}
                              {t(`nutrition:mealsPerDay`, { count: option.mealsSchema })} -{" "}
                            </Typography.Text>
                            {option.tags?.length ? (
                              <Typography.Text className="text-xs" type="secondary">
                                Tagi: {option.tags?.join(", ")}
                              </Typography.Text>
                            ) : null}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    labelAlign="left"
                    labelCol={{ span: 10 }}
                    label={t(`nutrition:dietaryPreferenceEnum.${DietaryPreference.Vegan}`)}
                    name={[field.name, "nutrition", DietaryPreference.Vegan]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder={t("nutrition.form.selectNutrition")}
                      disabled={!!model}
                      dropdownMatchSelectWidth={false}
                      onSearch={onPlanSearch}
                      optionLabelProp="label"
                      optionFilterProp="label"
                    >
                      {mealsPlanOptions.map((option) => (
                        <Select.Option value={option.value} label={option.label} key={option.value}>
                          <div className={cn("flex flex-col gap-y-2")}>
                            <span>
                              {option.label} {getName(option.creator, undefined, true)}
                            </span>
                            <Typography.Text className="text-xs" type="secondary">
                              {option.targetCalories} kcal -{" "}
                              {t(`nutrition:dietaryPreferenceEnum.${option.dietaryPreference}`)} -{" "}
                              {t(`nutrition:macroSplit.${option.macroSplit}`)} -{" "}
                              {t(`nutrition:mealsPerDay`, { count: option.mealsSchema })} -{" "}
                            </Typography.Text>
                            {option.tags?.length ? (
                              <Typography.Text className="text-xs" type="secondary">
                                Tagi: {option.tags?.join(", ")}
                              </Typography.Text>
                            ) : null}
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    className="text-red-500"
                    onClick={() => remove(field.name)}
                    style={{ marginLeft: 12 }}
                  />
                  <Divider />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      from: ranges.at(-1)?.to || null,
                      to: null,
                      nutrition: { 1: null, 2: null, 3: null, 4: null },
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                  style={{ marginTop: 10 }}
                >
                  {t("common:button.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item name="additionalComment" label={t("nutrition.form.additionalComment")}>
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

export default ScheduledNutritionForm;
