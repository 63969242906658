import { CalendarOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Space } from "antd";
import dayjs from "dayjs";

export const DaysSummary = ({
  generatedDates,
  selectedTime,
}: {
  generatedDates: Date[];
  selectedTime?: dayjs.Dayjs;
}) => (
  <Row data-ctx="days-summary-container">
    <Divider />
    <h4>Podsumowanie zaplanowanej wysyłki:</h4>
    <Col xs={24} data-ctx="generated-dates">
      {generatedDates.map((myDate) => (
        <Row key={myDate.getTime()}>
          <Col xs={24}>
            <Space size={4}>
              <CalendarOutlined />
              {`${dayjs(myDate).locale("pl").format("dddd")}, ${dayjs(myDate).locale("pl").format("LL")} `}
              {selectedTime && <span>{`o godz. ${selectedTime.locale("pl").format("LT")}`}</span>}
            </Space>
          </Col>
        </Row>
      ))}
    </Col>
  </Row>
);
