import React, { useEffect, useMemo, useState } from "react";
import { ZoomInOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
  Switch,
  Typography,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { UserRole } from "@fitness-app/data-models";
import { type ClientMealsPlanDetails } from "@fitness-app/data-models/entities/ClientNutrition";
import {
  DietaryPreference,
  KCAL_PER_NUTRIENT_GRAM,
  MacroSplit,
  MacroSplitRanges,
  MealsPerDay,
  TargetGender,
  type MealsPlan,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";
import { getName } from "@fitness-app/data-models/utils/getAuthorName";
import { countNutrientGrams } from "@fitness-app/utils/src/nutrition/countNutrients";
import {
  type NutrientsTargets,
  type TraineeNutritionFormModel,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useMealPlanOptions } from "~/modules/Nutrition/hooks/useMealPlanOptions";
import { useDraftNutritionPlans } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useDraftNutritionPlans";
import { useUsedNutritionTemplates } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/hooks/useUsedNutritionTemplates";
import { NutritionTemplateViewer } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/NutritionTemplateViewer";
import { useMealsPlanContextNullable } from "~/shared/providers/MealsPlanProvider";
import { supabase, useAppSelector } from "~/store/initializeStore";

interface TraineeNutritionFormProps {
  formController?: FormInstance<TraineeNutritionFormModel>;
  onSubmit: (
    formData: TraineeNutritionFormModel,
    targets: NutrientsTargets,
    supplements?: ClientMealsPlanDetails["supplements"],
    draft?: null | Omit<MealsPlan, "createdBy" | "shared">,
  ) => void;
  model?: Partial<TraineeNutritionFormModel> | null;
  traineeId: string;
  inFuture?: boolean;
}

const TraineeNutritionForm = ({ model, onSubmit, formController, traineeId, inFuture }: TraineeNutritionFormProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const { usedTemplates } = useUsedNutritionTemplates(traineeId);
  const { dietaryPreferences, mealsPerDay, macroSplits } = useMealPlanOptions();
  const activeMealsPlanId = useAppSelector((store) => store.trainee.profile?.activeMealsPlanId);
  const context = useMealsPlanContextNullable();
  const [protein, setProtein] = useState(model?.targets?.protein ?? 30);
  const [carbs, setCarbs] = useState(model?.targets?.carbs ?? 40);
  const [fat, setFat] = useState(model?.targets?.fat ?? 30);
  const macroSplit = Form.useWatch(["macroSplit"], formController);
  const targetCalories = Form.useWatch(["targetCalories"], formController);
  const selectedTemplate = Form.useWatch(["selectedTemplate"], formController);
  const [isDraft, setIsDraft] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const { userId, role } = useUserRole();
  const [filter, changeFilter] = useState<null | "only_author">(null);
  const [showTemplateDetails, setShowTemplateDetails] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { drafts } = useDraftNutritionPlans(traineeId);
  const { data, isLoading } = useQuery(["searchNutritionPlan", filter, searchValue], {
    keepPreviousData: true,
    queryFn: async () => {
      let query = supabase
        .from("meals_plan")
        .select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

      if (role !== UserRole.TRAINER && !filter) {
        query = query.or(`createdBy.eq.${userId}, shared.is.${true}`);
      }

      if (filter && filter === "only_author") {
        query = query.eq("createdBy", userId);
      }

      if (searchValue && searchValue.length > 0) {
        query = query.ilike("name", `%${searchValue}%`);
      }

      const { error, data } = await query
        .order("createdAt", { ascending: false })
        .range(0, 50)
        .returns<MealsPlanWithCreator[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  const onPlanSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
  }, 300);

  const mealsPlanOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!userId) {
      return data.map((mealsPlan) => ({
        ...mealsPlan,
        label: mealsPlan.name,
        value: mealsPlan.id,
        isDraft: false,
        used: usedTemplates[mealsPlan.id] || null,
      }));
    }
    const ordered = orderBy(
      data.map((mealsPlan) => ({
        ...mealsPlan,
        isDraft: false,
        label: mealsPlan.name,
        value: mealsPlan.id,
        used: usedTemplates[mealsPlan.id] || null,
      })),
      (item) => item.createdBy === userId,
      "desc",
    );

    if (drafts?.length) {
      const draftOptions = drafts.map((draft) => ({
        ...draft,
        label: `Draft klienta: ${draft.name}`,
        value: draft.id,
        isDraft: true,
        used: null,
      }));
      return [...draftOptions, ...ordered];
    }

    return ordered;
  }, [data, usedTemplates, drafts]);

  useEffect(() => {
    if (model && submitCount === 0) {
      formController?.setFieldsValue(model);
      setFat(model.targets?.fat ?? 30);
      setCarbs(model.targets?.carbs ?? 40);
      setProtein(model.targets?.protein ?? 30);
    }
  }, [model, submitCount]);

  useEffect(() => {
    if (macroSplit) {
      const { protein, carbs, fat } = MacroSplitRanges[macroSplit];
      setProtein(protein);
      setCarbs(carbs);
      setFat(fat);
    }
  }, [macroSplit]);

  const update = (type: "protein" | "carbs" | "fat", value: number | null) => {
    if (type === "protein" && value !== null) {
      setProtein(value);
      if (protein + carbs + fat > 100) {
        carbs > 0 ? setCarbs(100 - value - fat) : setFat(100 - value - carbs);
      }
    } else if (type === "carbs" && value !== null) {
      setCarbs(value);
      if (protein + carbs + fat > 100) {
        fat > 0 ? setFat(100 - value - protein) : setProtein(100 - value - fat);
      }
    } else if (value !== null) {
      setFat(value);
      if (protein + carbs + fat > 100) {
        protein > 0 ? setProtein(100 - value - carbs) : setCarbs(100 - value - protein);
      }
    }
  };

  const handleSubmit = (model: TraineeNutritionFormModel) => {
    setSubmitCount((prev) => prev + 1);

    const draft =
      Boolean(model.selectedTemplate && isDraft) &&
      mealsPlanOptions.find((option) => option.value === model.selectedTemplate);

    onSubmit(
      model,
      {
        carbs,
        protein,
        fat,
      },
      context?.planDetails.supplements || [],
      draft || null,
    );
  };

  const onTemplateSelect = (value: string, _option: (typeof mealsPlanOptions)[number]) => {
    const selectedOption = mealsPlanOptions.find((option) => option.value === value);

    setIsDraft(Boolean(selectedOption?.isDraft));

    if (selectedOption) {
      formController?.setFieldsValue({
        name: `Dieta ${dayjs().locale("pl").format("MMMM")} ${dayjs().format("YYYY")}`,
        targetCalories: selectedOption.targetCalories,
        dietaryPreference: selectedOption.dietaryPreference,
        mealsSchema: selectedOption.mealsSchema,
        macroSplit: selectedOption.macroSplit,
        targets: {
          gender: selectedOption.targets.gender || TargetGender.UNISEX,
          carbs: selectedOption.targets.carbs,
          protein: selectedOption.targets.protein,
          fat: selectedOption.targets.fat,
        },
      });
    }
  };

  return (
    <>
      <Form<TraineeNutritionFormModel>
        name="form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        form={formController}
        initialValues={{
          targetCalories: 2000,
          dietaryPreference: DietaryPreference.NoPreference,
          mealsSchema: MealsPerDay.THREE,
          macroSplit: MacroSplit.BALANCED,
          targets: {
            gender: TargetGender.UNISEX,
          },
          copySupplementsPlan: true,
          ...(inFuture ? {} : { hasActiveNutrition: true }),
        }}
        onFinish={handleSubmit}
      >
        <>
          {inFuture && (
            <Form.Item
              name="startAt"
              label="Data rozpoczęcia"
              rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
            >
              <DatePicker
                placeholder="Wybierze datę"
                format="DD.MM.YYYY"
                showToday={false}
                disabledDate={(current) => current && current < dayjs().endOf("day")}
              />
            </Form.Item>
          )}

          <Form.Item label="Wyświetl tylko moje szablony">
            <Switch value={Boolean(filter)} onChange={(checked) => changeFilter(checked ? "only_author" : null)} />
          </Form.Item>

          <Form.Item
            validateStatus={isLoading ? "validating" : undefined}
            name="selectedTemplate"
            label={t<string>("mealPlanForm.selectedTemplate")}
            help={
              selectedTemplate ? (
                <Button
                  type="link"
                  size="small"
                  className="mb-3"
                  icon={<ZoomInOutlined />}
                  onClick={() => setShowTemplateDetails(true)}
                >
                  Pokaż szczegóły szablonu
                </Button>
              ) : null
            }
          >
            <Select
              optionLabelProp="label"
              optionFilterProp="label"
              placeholder={t("mealPlanForm.searchTemplate")}
              showSearch
              allowClear
              onSearch={onPlanSearch}
              onSelect={onTemplateSelect}
              onClear={() => {
                setIsDraft(false);
                formController?.setFieldsValue({
                  targetCalories: 2000,
                  dietaryPreference: DietaryPreference.NoPreference,
                  mealsSchema: MealsPerDay.THREE,
                  macroSplit: MacroSplit.BALANCED,
                  targets: {
                    carbs: 40,
                    protein: 30,
                    fat: 30,
                  },
                });
              }}
            >
              {mealsPlanOptions.map((option) => (
                <Select.Option
                  value={option.value}
                  label={option.label}
                  key={option.value}
                  className={cn(option.used ? "bg-yellow-100" : "")}
                >
                  <div className={cn("flex flex-col gap-y-2")}>
                    <span>
                      {option.label} {"creator" in option ? getName(option.creator, undefined, true) : ""}
                    </span>
                    <Typography.Text className="text-xs" type="secondary">
                      {option.targetCalories} kcal - {t(`dietaryPreferenceEnum.${option.dietaryPreference}`)} -{" "}
                      {t(`macroSplit.${option.macroSplit}`)} - {t(`mealsPerDay`, { count: option.mealsSchema })}
                    </Typography.Text>
                    {option.tags?.length ? (
                      <Typography.Text className="text-xs" type="secondary">
                        Tagi: {option.tags?.join(", ")}
                      </Typography.Text>
                    ) : null}
                    {option.used && (
                      <Typography.Text className="text-xs" type="secondary">
                        Szablon użyty: {dayjs(option.used.startAt).format("DD.MM.YYYY")} - {option.used.name}
                      </Typography.Text>
                    )}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
        <Form.Item
          name="name"
          label={t<string>("mealPlanForm.name")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          name="dietaryPreference"
          label={t<string>("mealPlanForm.dietaryPreference")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select disabled={isDraft} options={dietaryPreferences} placeholder={t("common:selectPlaceholder")} />
        </Form.Item>
        <Form.Item
          name="macroSplit"
          label={t<string>("mealPlanForm.macroSplit")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select disabled={isDraft} options={macroSplits} placeholder={t("common:selectPlaceholder")} />
        </Form.Item>
        <Form.Item
          name="mealsSchema"
          label={t<string>("mealPlanForm.mealsPerDay")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            disabled={Boolean(selectedTemplate || isDraft)}
            options={mealsPerDay}
            placeholder={t("common:selectPlaceholder")}
          />
        </Form.Item>

        <Form.Item
          name="targetCalories"
          label={t<string>("mealPlanForm.targetCalories")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <InputNumber disabled={isDraft} min={0} max={5000} precision={0} />
        </Form.Item>

        {!inFuture && (
          <Form.Item
            name="hasActiveNutrition"
            label="Udostępnij dietę klientowi"
            tooltip="Możesz udostępnić dietę natychmiast pod dodaniu lub w późniejszym czasie."
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        )}

        {activeMealsPlanId && (
          <Form.Item label="Kopiuj suplementacje z obecnej diety" valuePropName="checked" name="copySupplementsPlan">
            <Switch />
          </Form.Item>
        )}

        <Row className="my-4 justify-center">
          <Col span={12}>
            <Typography.Text strong>{t("nutrients.protein")}</Typography.Text>
            <Slider
              disabled={isDraft}
              min={0}
              max={100}
              value={protein}
              onChange={(value) => update("protein", value)}
            />
          </Col>
          <Col span={5}>
            <InputNumber
              addonAfter={
                <div className="w-[40px]">{`${countNutrientGrams(
                  protein,
                  targetCalories ?? 0,
                  KCAL_PER_NUTRIENT_GRAM.protein,
                )} g`}</div>
              }
              min={0}
              max={100}
              disabled={isDraft}
              className="ml-1 mt-4"
              value={protein}
              formatter={(value) => `${value}%`}
              parser={(value) => (value !== undefined ? Number(value.replace("%", "")) : 0)}
              onChange={(value) => update("protein", value)}
            />
          </Col>
          <Col span={12}>
            <Typography.Text strong>{t("nutrients.carbohydrates")}</Typography.Text>
            <Slider disabled={isDraft} min={0} max={100} value={carbs} onChange={(value) => update("carbs", value)} />
          </Col>
          <Col span={5}>
            <InputNumber
              disabled={isDraft}
              className="ml-1 mt-4"
              addonAfter={
                <div className="w-[40px]">{`${countNutrientGrams(
                  carbs,
                  targetCalories ?? 0,
                  KCAL_PER_NUTRIENT_GRAM.carbs,
                )} g`}</div>
              }
              formatter={(value) => `${value}%`}
              parser={(value) => (value !== undefined ? Number(value.replace("%", "")) : 0)}
              min={0}
              max={100}
              value={carbs}
              onChange={(value) => update("carbs", value)}
            />
          </Col>
          <Col span={12}>
            <Typography.Text strong>{t("nutrients.fat")}</Typography.Text>
            <Slider disabled={isDraft} min={0} max={100} value={fat} onChange={(value) => update("fat", value)} />
          </Col>
          <Col span={5}>
            <InputNumber
              disabled={isDraft}
              className="ml-1 mt-4"
              addonAfter={
                <div className="w-[40px]">{`${countNutrientGrams(
                  fat,
                  targetCalories ?? 0,
                  KCAL_PER_NUTRIENT_GRAM.fat,
                )} g`}</div>
              }
              formatter={(value) => `${value}%`}
              parser={(value) => (value !== undefined ? Number(value.replace("%", "")) : 0)}
              min={0}
              max={100}
              value={fat}
              onChange={(value) => update("fat", value)}
            />
          </Col>
        </Row>
      </Form>

      <Drawer
        title="Szczegóły szablonu"
        placement="bottom"
        onClose={() => setShowTemplateDetails(false)}
        open={showTemplateDetails}
        size="large"
        height={"80%"}
      >
        {selectedTemplate && <NutritionTemplateViewer templatedId={selectedTemplate} isDraft={isDraft} />}
      </Drawer>
    </>
  );
};

export default TraineeNutritionForm;
