import Fingerprint2 from "@fingerprintjs/fingerprintjs";

const randomString = (length: number) => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length - 1; i > 0; i -= 1) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export async function getDeviceFingerprint() {
  try {
    if (typeof window === "undefined") {
      return randomString(32);
    }
    const fpPromise = Fingerprint2.load({
      monitoring: false,
    });

    const fingerprint = localStorage?.getItem("fitness_app_fingerprint");
    if (!fingerprint) {
      const agent = await fpPromise;
      let newFingerprint = (await agent.get()).visitorId;

      if (!newFingerprint) {
        newFingerprint = randomString(32);
      }
      localStorage?.setItem("fitness_app_fingerprint", newFingerprint);
      return newFingerprint;
    }
    return fingerprint;
  } catch (e) {
    return randomString(32);
  }
}
