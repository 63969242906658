import { type ChatChannel, type ChatChannelWithLastMessage } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import { subscribeToLatestChannelFailed, subscribeToLatestChannelSuccess, updateChannel } from "../reducer";
import { unsubscribeFromLatestChannel } from "./unsubscribeFromLatestChannel";

export const subscribeToLatestChannel =
  (): AppThunk =>
  async (dispatch, _getState, { db, auth, subscriptions }) => {
    try {
      const { id } = await getLoggedUser(auth);
      const channelName = `chat-latest-${id}`;

      if (subscriptions.latestChatRealtimeChannel) {
        await db.removeChannel(subscriptions.latestChatRealtimeChannel);
      }

      const subscription = db.channel(channelName);
      subscriptions.latestChatRealtimeChannel = subscription;
      subscriptions.latestChatChannelName = channelName;

      subscription
        .on<ChatChannel>(
          "postgres_changes",
          { event: "*", schema: "public", table: "chat_channel" },
          async (payload) => {
            if (payload.eventType === "INSERT" || payload.eventType === "UPDATE") {
              const { data } = await db
                .from("chat_channel")
                .select(
                  "*, lastMessage:lastMessageId(id, content, createdAt), profiles:user_profile(avatarUrl, firstName, lastName, id, email))",
                )
                .eq("id", payload.new.id)
                .single<ChatChannelWithLastMessage>();

              dispatch(updateChannel(data));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToLatestChannelSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromLatestChannel());
          }
        });
    } catch (error) {
      dispatch(subscribeToLatestChannelFailed());
    }
  };
