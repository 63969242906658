import { type Stripe } from "stripe";

import { type Database } from "@fitness-app/supabase";

import { type Currency, type InvoicesClient, type OrderPaymentType, type ProductSubscriptionInterval } from "./Product";

export type OrderStatus = Database["public"]["Enums"]["OrderStatus"];

export type SessionMetadataEntity = Database["public"]["Tables"]["session_metadata"]["Row"];
export type OrderEntity = Database["public"]["Tables"]["product_order"]["Row"];

export type SessionMetadata = Omit<SessionMetadataEntity, "metadata" | "renewal"> & {
  metadata?: Record<string, string | number | null | boolean> & {
    isMetaConversionAvailable?: boolean | null;
    fbp?: string | null;
    fbc?: string | null;
    utm_source?: string | null;
  };
  renewal?: boolean | null;
};

export const OrderStatusOption = {
  Created: "CREATED",
  Paid: "PAID",
  Finalized: "FREE_FINALIZED",
  Failed: "FAILED",
  Imported: "IMPORTED",
  Refunded: "REFUNDED",
} as const;

export interface ClientInvoiceData {
  firstName: string;
  lastName: string;
  companyName: string;
  nip: string;
  street: string;
  postalCode: string;
  city: string;
  country?: string;
  isLegalEntity?: boolean;
}

export interface InsufficientFunds {
  status: "insufficient_funds";
}

export interface ConfirmTransaction {
  status: "confirm_transaction";
  url: string;
}

export type RequiresAction = InsufficientFunds | ConfirmTransaction;

export interface SuccessRefund {
  timestamp: number;
  amount: number;
  currency: Currency;
  creatorId: string;
  refundId: string | "manual";
}

export interface FailedRefund {
  creatorId: string;
  errorMessage: string;
  timestamp: number;
}

export type Refund = SuccessRefund | FailedRefund;
export type PaidLastOrder = {
  amount: number;
  currency: Currency;
  priceId: string;
  orderId: string;
  free: false;
  paymentGatewayInvoiceId?: string | null;
  customInvoiceName?: string;
};

export type FreeLastOrder = {
  orderId: string;
  free: true;
  priceId: null;
  amount?: 0;
  current?: never;
  currency?: never;
};

export type LastOrder = PaidLastOrder | FreeLastOrder;

export type OrderItem = LastOrder & {
  productName: string;
  accessPeriod?: {
    startAt: number;
    endAt: number;
  } | null;
};

export type BaseOrder = Omit<
  OrderEntity,
  | "client"
  | "connectedInvoice"
  | "discounts"
  | "metadata"
  | "refund"
  | "paymentType"
  | "price"
  | "requiresAction"
  | "previousSubscriptionPrice"
> & {
  paymentType: OrderPaymentType;
  ownerEmail: string;
  price: OrderItem | null;
  client: ClientInvoiceData;
  session: string | null;
  connectedInvoice?:
    | null
    | {
        id: string | number;
        number: string | number;
        client: InvoicesClient;
        accountUrl: string;
        accountName: string;
        isReceipt?: boolean;
      }
    | { errorMessage: string; timestamp: number; errorData?: null | { message?: string; details?: string } | string };
  requiresAction?: RequiresAction;
  metadata?: Record<string, string | number>;
  discounted: boolean;
  discounts?: Stripe.LineItem.Discount[] | Stripe.Discount[] | null;
  refund: Refund | null;
  paymentGatewayInvoiceId?: string | null;
};

export interface FreeOrder extends BaseOrder {
  price: null;
  priceId: null;
}
export interface PaidOrder extends BaseOrder {
  price: OrderItem;
  priceId: string;
}

export interface OrderWithProration extends BaseOrder {
  priceId: string;
  previousSubscriptionPrice: {
    amount: number;
    currency: Currency;
    id: string;
    interval_count: number;
    interval: ProductSubscriptionInterval;
  };
  price: {
    productName: string;
    accessPeriod?: {
      startAt: number;
      endAt: number;
    } | null;
    amount: number;
    currency: Currency;
    priceId: string;
    orderId: string;
    free: false;
    paymentGatewayInvoiceId?: string | null;
    customInvoiceName?: string;
  } & {
    baseAmount: number;
    prorated: boolean;
    interval_count: number;
    interval: ProductSubscriptionInterval;
  };
}

export type Order = BaseOrder | PaidOrder | OrderWithProration | FreeOrder;
