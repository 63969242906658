import { onDisconnect, onValue, ref, serverTimestamp, set } from "firebase/database";

import { UserActivityStatus } from "@fitness-app/data-models/entities/UserProfile";
import { getBrowserName, isMobile } from "@fitness-app/utils/src/users/getBrowserName";
import { getDeviceFingerprint } from "@fitness-app/utils/src/users/getDeviceFingerprint";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";

export const setUserPresence =
  (): AppThunk =>
  async (dispatch, getState, { realtimeDatabase, auth, appVersion }) => {
    const { id } = await getLoggedUser(auth);
    const userStatusDatabaseRef = ref(realtimeDatabase, `/status/${id}`);
    const presenceRef = ref(realtimeDatabase, ".info/connected");

    const device = {
      fingerprint: typeof window !== "undefined" && window ? await getDeviceFingerprint() : null,
      lastSeen: serverTimestamp(),
      type: isMobile() ? "mobile" : "desktop",
      name: `${getBrowserName()} - ${
        // @ts-expect-error ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        navigator.userAgentData?.platform || navigator.platform
      }`,
      version: `${appVersion} (web)`,
    };

    const isOfflineForDatabase = {
      state: UserActivityStatus.Offline,
      lastChanged: serverTimestamp(),
      appVersion: `${appVersion} (web)`,
      device,
    };

    const isOnlineForDatabase = {
      state: UserActivityStatus.Online,
      lastChanged: serverTimestamp(),
      appVersion: `${appVersion} (web)`,
      device,
    };

    return onValue(presenceRef, (snapshot) => {
      if (snapshot.val() == false) {
        return;
      }

      onDisconnect(userStatusDatabaseRef)
        .set(isOfflineForDatabase)
        .then(() => {
          void set(userStatusDatabaseRef, isOnlineForDatabase);
        })
        .catch((e) => {
          console.log("Error with user status", e);
        });
    });
  };
