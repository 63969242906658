import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProgramAutomation } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../../index";
import { AUTOMATION_REDUCER_NAME } from "../types";

type Payload = ProgramAutomation;

export const addProgramAutomation = createAsyncThunk<ProgramAutomation, Payload, AsyncThunkCreator<string>>(
  `${AUTOMATION_REDUCER_NAME}/addProgramAutomation`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db.from("program_automation").insert(payload).select("*").single<ProgramAutomation>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
