import React, { useCallback, useEffect, useState, type FunctionComponent } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { type RcFile } from "antd/lib/upload";
import { type UploadFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";

import { supabase } from "~/store/initializeStore";
import { Logger } from "~/utils/Logger";

export interface RcCustomRequestOptions {
  onProgress: (
    event: {
      percent: number;
    },
    file: RcFile,
  ) => void;
  onError: (error: Error, response?: unknown, file?: RcFile) => void;
  onSuccess: (response: object, file: RcFile) => void;
  data: object;
  filename: string;
  file: RcFile;
  withCredentials: boolean;
  action: string;
  headers: object;
}

interface OwnProps {
  storageRef: string;
  onChange?: (urls: (string | undefined)[], files: UploadFile<File>[]) => void;
  onDelete?: (file: UploadFile<File>) => void;
  fileList?: string[] | [{ url: string }];
  multiple?: boolean;
  withCrop?: boolean;
  disabled?: boolean;
  fileName?: string;
  idAsName?: boolean;
  bucket?: string;
  icon?: typeof UploadOutlined;
  className?: string;
}

type Props = OwnProps;

const EXPIRES_IN_20_YEARS = 631138519;

const beforeUpload = (file: RcFile) => {
  const validationType =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/gif" ||
    file.type === "image/webp" ||
    file.type === "image/png";
  if (!validationType) {
    void message.error("Rozszerzenie pliku jest niedopuszczalne, wyślij zdjęcie w formacie jpg/gif/png");
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    void message.error("Zdjęcie nie może być większe niż 1MB");
  }

  return validationType && isLt1M;
};

interface File extends RcFile {
  url: string | null;
}

const AvatarUploadField: FunctionComponent<Props> = ({
  storageRef,
  onChange,
  multiple,
  fileList: defaultFileList,
  withCrop = true,
  disabled = false,
  fileName,
  onDelete,
  idAsName = false,
  icon: Icon = UploadOutlined,
  bucket = "shared",
  className,
}) => {
  const { t } = useTranslation("common");

  const [fileList, updateFileList] = useState<UploadFile<File>[]>(() => {
    if (defaultFileList?.length) {
      return [
        {
          url: typeof defaultFileList[0] === "string" ? defaultFileList[0] : defaultFileList[0].url,
          name: "default",
          size: 0,
          uid: "-1",
          status: "done",
          type: "image/png",
        },
      ];
    }

    return [];
  });

  useEffect(() => {
    if (defaultFileList?.length) {
      updateFileList([
        {
          url: typeof defaultFileList[0] === "string" ? defaultFileList[0] : defaultFileList[0].url,
          name: "default",
          size: 0,
          uid: "-1",
          status: "done",
          type: "image/png",
        } as UploadFile<File>,
      ]);
    } else {
      updateFileList([]);
    }
  }, [defaultFileList]);

  const [preview, setPreviewImg] = useState<string | null>(null);

  const onPreview = useCallback(
    (file: UploadFile<File>) => {
      setPreviewImg((file?.url || file?.thumbUrl) ?? null);
    },
    [setPreviewImg],
  );

  const getFileName = (file: RcFile | UploadFile<File>) => {
    if (fileName) {
      return `${fileName}.${file.name.split(".").pop()}`;
    }

    return idAsName ? file.uid : `${file.uid}-${file.name.replaceAll("..", "")}`;
  };

  const uploadToStorage = async ({ file, onSuccess }: RcCustomRequestOptions) => {
    updateFileList((prev) => [
      ...prev,
      {
        type: file.type,
        name: file.name,
        uid: file.uid,
        lastModified: file.lastModified,
        size: file.size,
        percent: 0,
        status: "uploading",
      },
    ]);

    try {
      const ref = `${storageRef}/${file.name}`;

      const { error: uploadError } = await supabase.storage.from(bucket).upload(ref, file, {
        cacheControl: "3600",
        upsert: true,
      });

      if (uploadError) {
        void message.error(t("validationErrors.cannotAddImage"));
        updateFileList((prev) =>
          prev.map((savedFile) => {
            if (savedFile.uid === file.uid) {
              return {
                ...savedFile,
                error: uploadError.message,
                status: "error",
              };
            }

            return savedFile;
          }),
        );
        return;
      }

      let url = "";

      if (bucket === "shared") {
        const {
          data: { publicUrl },
        } = supabase.storage.from(bucket).getPublicUrl(ref, {
          transform: {
            width: 300,
            height: 300,
            quality: 90,
            resize: "cover",
          },
        });
        url = publicUrl;
      } else {
        const { data, error } = await supabase.storage.from(bucket).createSignedUrl(ref, EXPIRES_IN_20_YEARS, {
          transform: {
            width: 300,
            height: 300,
            quality: 90,
            resize: "cover",
          },
        });
        if (error) {
          throw new Error(error.message);
        }
        url = data.signedUrl;
      }

      onSuccess({}, file);
      onChange?.(
        [...fileList.map((item) => item.url), url],
        [
          ...fileList,
          {
            status: "done",
            type: file.type,
            name: file.name,
            uid: file.uid,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            size: file.size,
            url: url,
          },
        ],
      );
      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            return {
              ...savedFile,
              status: "done",
              url: url,
            };
          }

          return savedFile;
        }),
      );
    } catch (error) {
      void message.error(t("validationErrors.cannotAddImage"));
      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            return {
              ...savedFile,
              status: "error",
            };
          }

          return savedFile;
        }),
      );
    }
  };

  const onRemove = async (file: UploadFile<File>) => {
    updateFileList((prev) => prev.filter((savedFile) => savedFile.url !== file.url));
    onChange?.(
      fileList.map((item) => item?.url || "").filter((url) => url && url !== file.url),
      fileList.filter((item) => item?.url !== file.url),
    );
    try {
      if (file.uid && (file.status === "done" || file.uid === "-1")) {
        if (file.uid === "-1" && file.url) {
          if (fileName) {
            await supabase.storage.from(bucket).remove([`${storageRef}/${getFileName(file)}`]);
          }
          // const ref = firebase.storage().refFromURL(file.url);
          // await ref.delete();
          onDelete?.(file);
        } else {
          await supabase.storage.from(bucket).remove([`${storageRef}/${getFileName(file)}`]);
          onDelete?.(file);
        }
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  if (!withCrop) {
    return (
      <>
        <Upload
          name="logo"
          listType="picture-card"
          beforeUpload={beforeUpload}
          onPreview={onPreview}
          onRemove={onRemove}
          fileList={fileList}
          multiple={multiple}
          // @ts-expect-error wrong return type
          customRequest={uploadToStorage}
        >
          {!multiple && fileList.length >= 1 ? null : <Button type="text" icon={<Icon />} />}
        </Upload>
        <Modal open={!!preview} footer={null} onCancel={() => setPreviewImg(null)}>
          <img alt="example" style={{ width: "100%", marginTop: 20 }} src={preview ?? undefined} />
        </Modal>
      </>
    );
  }

  return (
    <div className={className}>
      <ImgCrop>
        <Upload
          name="logo"
          listType="picture-card"
          beforeUpload={beforeUpload}
          onPreview={onPreview}
          onRemove={onRemove}
          fileList={fileList}
          multiple={multiple}
          // @ts-expect-error wrong return type
          customRequest={uploadToStorage}
          disabled={disabled}
        >
          {!multiple && fileList.length >= 1 ? null : <Button type="text" icon={<Icon />} />}
        </Upload>
      </ImgCrop>
      <Modal open={!!preview} footer={null} onCancel={() => setPreviewImg(null)}>
        <img alt="example" style={{ width: "100%", marginTop: 15 }} src={preview ?? undefined} />
      </Modal>
    </div>
  );
};

export default AvatarUploadField;
