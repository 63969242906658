import React from "react";
import { Card, Tabs } from "antd";

import TraineeActivityChart from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/TraineeActivityChart";
import TraineeMeasurementsCharts from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/TraineeMeasurementsCharts";
import TraineeNutritionCharts from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/TraineeNutritionCharts";
import { TraineeWorkoutsStats } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/TraineeWorkoutsStats";

const TraineeResults = () => {
  return (
    <Card>
      <Tabs destroyInactiveTabPane tabPosition="left">
        <Tabs.TabPane tab="Aktywność klienta" key="activity">
          <TraineeActivityChart />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pomiary" key="measurements">
          <TraineeMeasurementsCharts />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Treningi" key="workouts">
          <TraineeWorkoutsStats />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dieta" key="nutrition">
          <TraineeNutritionCharts />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default TraineeResults;
