import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message as toast } from "antd";

import { ExerciseRecordingStatus, type ExerciseRecording } from "@fitness-app/data-models/entities/ExerciseRecording";

import { analyticsService, supabase } from "~/store/initializeStore";

export const useUpdateExerciseRecording = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    retry: 5,
    async mutationFn(data: Partial<ExerciseRecording> & { id: string }) {
      const { id, ...rest } = data;
      return supabase
        .from("exercise_recording")
        .update({ ...rest, updatedAt: new Date().toISOString() })
        .eq("id", id);
    },
    async onSuccess({ error }) {
      if (error) {
        void toast.error(error.message);
      } else {
        analyticsService.track("exercise_recording_feedback_added");
        await queryClient.invalidateQueries(["exercise_recordings"]);
      }
    },
  });

  const addExerciseRecordingFeedback = async (recordingId: string, feedbackId: string) => {
    return mutation.mutateAsync({
      status: ExerciseRecordingStatus.RATED,
      id: recordingId,
      feedbackId,
      updatedAt: new Date().toISOString(),
    });
  };

  return {
    addExerciseRecordingFeedback,
  };
};
