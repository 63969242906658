import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type ConversionTrackingClient,
  type IntegrationClient,
} from "@fitness-app/data-models/entities/ProductsSettings";
import { getError, getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export type Payload = {
  client: IntegrationClient | ConversionTrackingClient;
  data: Record<string, unknown>;
};

export const integrateWithExternalApi = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/integrateWithExternalApi`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { integrationApi, analytics } }) => {
    try {
      await integrationApi.post("/integrate-with-external-api", payload);

      analytics.track("integrate-with-external-api", { client: payload.client });
      onSuccess?.();
    } catch (e) {
      onFailure?.(getError(e).status);

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
