import { FileValidationError, type FileData, type UploadValidatorConfig, type UploadValidatorResult } from "../types";

// 10MB
export const MAX_FILE_SIZE_IN_BYTES = 10485760;
export const MAX_FILES_LIST_SIZE_IN_BYTES = 26214400;
export const EXCLUDED_EXTENSIONS = ["exe", "bin"];

function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const uploadValidator = (
  file: FileData,
  filesList: FileData[],
  config: UploadValidatorConfig = {},
): UploadValidatorResult => {
  if (config.allowedMimeTypes && !config.allowedMimeTypes.includes(file.contentType)) {
    return {
      error: FileValidationError.WRONG_MIME_TYPE,
      contentType: file.contentType,
      allowedMimeTypes: config.allowedMimeTypes,
    };
  }
  if (config.allowedExtensions && !config.allowedExtensions.includes(file.extension)) {
    return {
      error: FileValidationError.WRONG_EXTENSION,
      extension: file.extension,
      allowedExtensions: config.allowedExtensions,
    };
  }
  if ((config.excludedExtensions || EXCLUDED_EXTENSIONS).includes(file.extension)) {
    return {
      error: FileValidationError.WRONG_EXTENSION,
      extension: file.extension,
      excludedExtensions: config.excludedExtensions || EXCLUDED_EXTENSIONS,
    };
  }
  const maxFileSize = config.maxFileSize || MAX_FILE_SIZE_IN_BYTES;
  if (file.size > maxFileSize) {
    return {
      error: FileValidationError.MAX_FILE_SIZE,
      maxFileSize: formatBytes(maxFileSize),
    };
  }

  const listLength = filesList.length + 1;

  if (config.maxFilesListLength && listLength > config.maxFilesListLength) {
    return {
      error: FileValidationError.MAX_FILES_LIST_LENGTH,
      maxFilesListLength: config.maxFilesListLength,
    };
  }

  const sizeOfAllList =
    filesList.map((fileOnList) => fileOnList.size).reduce((prev, curr) => prev + curr, 0) + file.size;

  const maxFilesListSize = config.maxFilesListSize || MAX_FILES_LIST_SIZE_IN_BYTES;

  if (sizeOfAllList > maxFilesListSize) {
    return {
      error: FileValidationError.MAX_FILES_LIST_SIZE,
      maxFilesListSize: formatBytes(maxFilesListSize),
    };
  }

  return false;
};
