import { createAsyncThunk } from "@reduxjs/toolkit";
import { type AutomationTemplate } from "@fitness-app/data-models/entities/AutomationTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { TEMPLATES_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchAutomationTemplates = createAsyncThunk<AutomationTemplate[], Payload, AsyncThunkCreator<string>>(
  `${TEMPLATES_REDUCER_NAME}/fetchAutomationTemplates`,
  async (_payload, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("automation_template")
      .select("*")
      .order("createdAt", { ascending: false })
      .returns<AutomationTemplate[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
