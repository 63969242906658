import {
  type ClientActivityEvent,
  type ClientActivityWithScore,
  type ClientMonthActivity,
} from "@fitness-app/data-models/entities/ClientActivity";
import { type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";
import { type TraineeSettings } from "@fitness-app/data-models/entities/TraineeSettings";
import { type UserStatus } from "@fitness-app/data-models/entities/UserStatus";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TRAINEE_REDUCER_NAME = "trainee";

export interface TraineeReducer {
  profile: TraineeProfile | null;
  profileStatus: null | RequestStatus;
  showUpdateProfileData: boolean;

  traineeSettings: TraineeSettings | null;
  traineeSettingsStatus: RequestStatus | null;

  userStatus: UserStatus | null;
  userStatusStatus: RequestStatus | null;

  clientActivitiesLogStatus: RequestStatus | null;
  clientActivitiesLog: ClientMonthActivity[];

  clientActivityReport: ClientActivityWithScore | null;
  clientActivityReportStatus: RequestStatus | null;

  clientActivityEvents: ClientActivityEvent[];
  clientActivityEventsStatus: RequestStatus | null;
  page: number;
  totalPages: number;
  pageSize: number;
}
