import { createSlice } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { RequestStatus } from "../../../enums/requestStatus";
import { addSurveyTemplate } from "./actions/addSurveyTemplate";
import { archiveSurveyTemplate } from "./actions/archiveSurveyTemplate";
import { editSurveyTemplate } from "./actions/editSurveyTemplate";
import { fetchSurveysTemplates } from "./actions/fetchSurveysTemplates";
import { fetchSurveyTemplate } from "./actions/fetchSurveyTemplate";
import { SURVEYS_TEMPLATES_REDUCER_NAME, type SurveysTemplatesReducer } from "./types";

const initialState: SurveysTemplatesReducer = {
  list: [],
  listStatus: null,
  selectedSurvey: null,
  selectedSurveyStatus: null,
  creators: [],
};

const reducerSlice = createSlice({
  initialState,
  name: SURVEYS_TEMPLATES_REDUCER_NAME,
  reducers: {
    clearFetchedSurveyTemplate(state) {
      state.selectedSurvey = null;
      state.selectedSurveyStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSurveysTemplates.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchSurveysTemplates.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchSurveysTemplates.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.list = payload;
      state.creators = uniqBy(
        payload.reduce<{ label: string; value: string }[]>((prev, current) => {
          if (current.creator) {
            prev.push({
              label: `${current.creator.firstName} ${current.creator.lastName}`,
              value: current.creator.id,
            });
          }
          return prev;
        }, []),
        "value",
      );
    });
    builder.addCase(addSurveyTemplate.fulfilled, (state, { payload }) => {
      state.list = [...state.list, payload];
    });
    builder.addCase(archiveSurveyTemplate.fulfilled, (state, { payload }) => {
      state.list = state.list.filter((survey) => survey.id !== payload);
    });
    builder.addCase(editSurveyTemplate.pending, (state, { meta }) => {
      if (meta.arg.optimisticUpdate) {
        state.list = state.list.map((survey) => {
          if (survey.id === meta.arg.surveyTemplateId) {
            return { ...survey, ...meta.arg.surveyTemplate };
          }

          return survey;
        });
        if (state.selectedSurvey?.id === meta.arg.surveyTemplateId) {
          state.selectedSurvey = { ...state.selectedSurvey, ...meta.arg.surveyTemplate };
        }
      }
    });
    builder.addCase(editSurveyTemplate.fulfilled, (state, { payload }) => {
      state.list = state.list.map((survey) => {
        if (survey.id === payload.id) {
          return payload;
        }

        return survey;
      });

      if (state.selectedSurvey?.id === payload.id) {
        state.selectedSurvey = payload;
      }
    });
    builder.addCase(fetchSurveyTemplate.pending, (state) => {
      state.selectedSurveyStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchSurveyTemplate.rejected, (state) => {
      state.selectedSurveyStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchSurveyTemplate.fulfilled, (state, { payload }) => {
      state.selectedSurveyStatus = RequestStatus.SUCCESS;
      state.selectedSurvey = payload;
    });
  },
});

export const { clearFetchedSurveyTemplate } = reducerSlice.actions;
export default reducerSlice.reducer;
