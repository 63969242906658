import { type ThunkDispatch } from "@reduxjs/toolkit";
import { createClient } from "@supabase/supabase-js";
import axios, { type AxiosInstance } from "axios";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { MeiliSearch } from "meilisearch";
import { useDispatch, useSelector, type TypedUseSelectorHook } from "react-redux";
import { type AnyAction } from "redux";

import { createStore, type AppStore, type StoreDependencies, type StoreUtils } from "@fitness-app/app-store";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { type Database } from "@fitness-app/supabase";

import { WebAnalyticsService } from "~/store/webAnalyticsService";

export const supabase = createClient<Database>(
  import.meta.env.VITE_SUPABASE_PROJECT_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
);

export const analyticsService = new WebAnalyticsService({
  mixPanelApiKey: import.meta.env.VITE_MIXPANEL_TOKEN,
  postHogApiKey: import.meta.env.VITE_POSTHOG_API_KEY,
});

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const addAuthHeader = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(async (config) => {
    const token = (await supabase.auth.getSession())?.data?.session?.access_token;

    if (config.headers && token) {
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    } else {
      return config;
    }
  });
};

const integrationApi = axios.create({
  baseURL: import.meta.env.VITE_INTEGRATION_API_URL,
});
const productApi = axios.create({
  baseURL: import.meta.env.VITE_PRODUCTS_API_URL,
});
const invoicesApi = axios.create({
  baseURL: import.meta.env.VITE_INVOICES_API_URL,
});
const mediaLibraryApi = axios.create({
  baseURL: import.meta.env.VITE_MEDIA_LIBRARY_API_URL,
});
export const secretsApi = axios.create({
  baseURL: import.meta.env.VITE_SECRETS_API_URL,
});

export const trainersApi = axios.create({
  baseURL: import.meta.env.VITE_TRAINERS_API_URL,
});

export const automationApi = axios.create({
  baseURL: import.meta.env.VITE_AUTOMATION_API_URL,
});

export const notificationsApi = axios.create({
  baseURL: import.meta.env.VITE_NOTIFICATIONS_API_URL,
});

export const traineeApi = axios.create({
  baseURL: import.meta.env.VITE_TRAINEE_API_URL,
});

export const chatApi = axios.create({
  baseURL: import.meta.env.VITE_CHAT_API_URL,
});

export const nutritionApi = axios.create({
  baseURL: import.meta.env.VITE_NUTRITION_API_URL,
});

export const nutritionPdfApi = axios.create({
  baseURL: import.meta.env.VITE_NUTRITION_PDF_API_URL,
});

addAuthHeader(integrationApi);
addAuthHeader(productApi);
addAuthHeader(secretsApi);
addAuthHeader(invoicesApi);
addAuthHeader(mediaLibraryApi);
addAuthHeader(trainersApi);
addAuthHeader(automationApi);
addAuthHeader(notificationsApi);
addAuthHeader(traineeApi);
addAuthHeader(chatApi);
addAuthHeader(nutritionApi);
addAuthHeader(nutritionPdfApi);

const client = new MeiliSearch({
  host: import.meta.env.VITE_MEILISEARCH_HOST,
  apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY,
});

const searchIndexConfig = {
  dishesIndexName: import.meta.env.VITE_APP_ENV === "production" ? "dishes" : "dishes-dev",
  ingredientsIndexName: import.meta.env.VITE_APP_ENV === "production" ? "ingredients" : "ingredients-dev",
};

export const ingredientsSearchIndex = client.index<Ingredient>(searchIndexConfig.ingredientsIndexName);

export const { store } = createStore({
  analytics: analyticsService,
  db: supabase,
  auth: supabase.auth,
  integrationApi,
  productApi,
  secretsApi,
  invoicesApi,
  mediaLibraryApi,
  trainersApi,
  automationApi,
  notificationsApi,
  traineeApi,
  chatApi,
  nutritionApi,
  nutritionPdfApi,
  realtimeDatabase: getDatabase(app),
  appVersion: import.meta.env.PACKAGE_VERSION,
  config: {
    searchIndexApiKey: import.meta.env.VITE_MEILISEARCH_API_KEY,
    searchIndexHost: import.meta.env.VITE_MEILISEARCH_HOST,
    realtimeServerUrl: import.meta.env.VITE_REALTIME_SERVER_URL,
    dishesIndexName: import.meta.env.VITE_APP_ENV === "production" ? "dishes" : "dishes-dev",
    ingredientsIndexName: import.meta.env.VITE_APP_ENV === "production" ? "ingredients" : "ingredients-dev",
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<AppStore, StoreDependencies & StoreUtils, AnyAction>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
