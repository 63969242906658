import uniqBy from "lodash.uniqby";
import { createSelector } from "reselect";

import { type ChannelWithMessages, type ChatChannel } from "@fitness-app/data-models/entities/Chat";
import { groupMessageByDay } from "@fitness-app/utils/src/chat/groupMessagesByDay";

import { type AppStore } from "../../../index";

const getMessages = (store: AppStore, { channel }: { channel: ChatChannel }) => store.chat.messages[channel.id] || [];
const getProps = (_: AppStore, props: { channel: ChatChannel; inverted: boolean }) => props;

export const getChannelMessages = createSelector(
  [getMessages, getProps],
  (messages, { channel, inverted }): ChannelWithMessages => {
    if (!messages?.length) {
      return {
        channelId: channel?.id,
        groupedByDay: [],
      };
    }

    return {
      channelId: channel?.id,
      groupedByDay: groupMessageByDay(uniqBy(messages, "id"), inverted),
    };
  },
);
