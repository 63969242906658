import { createAsyncThunk } from "@reduxjs/toolkit";

import { type SupplementsTemplateWithCreator } from "@fitness-app/data-models/entities/SupplementTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { SUPPLEMENTS_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchSupplementsTemplates = createAsyncThunk<
  SupplementsTemplateWithCreator[],
  Payload,
  AsyncThunkCreator<string>
>(
  `${SUPPLEMENTS_TEMPLATES_REDUCER_NAME}/fetchSupplementsTemplates`,
  async (_payload, { rejectWithValue, extra: { db } }) => {
    const query = db
      .from("supplements_template")
      .select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" })
      .order("createdAt", { ascending: false })
      .returns<SupplementsTemplateWithCreator[]>();

    const { data, error } = await query;

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
