import React, { useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Space } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";
import { recalculateScheduledProgramDates } from "@fitness-app/utils/src/programs/recalculateScheduledProgramDates";

import { type ScheduledClientProgramsDatesModel } from "./types";

interface ScheduledClientProgramsDatesProps {
  formController?: FormInstance<ScheduledClientProgramsDatesModel>;
  onSubmit: (formData: ScheduledClientProgramsDatesModel) => void;
  model: ScheduledClientProgramsDatesModel;
  activeProgram: ClientTrainingProgram | null;
}

export const DATE_FORMAT = "YYYY-MM-DD";

const ScheduledClientProgramsDates = ({
  model,
  onSubmit,
  formController,
  activeProgram,
}: ScheduledClientProgramsDatesProps) => {
  const { t } = useTranslation(["automation", "common"]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const disabledDate = (current: dayjs.Dayjs) => {
    if (!activeProgram) {
      return current && current < dayjs().endOf("day");
    } else {
      return current && current < dayjs(activeProgram.endDate, DATE_FORMAT).endOf("day");
    }
  };

  const updateDates = (props: { id: string; startDate: dayjs.Dayjs } | { id: string; endDate: dayjs.Dayjs }) => {
    if (model?.programs && formController) {
      const recalculatedPrograms = recalculateScheduledProgramDates(
        "endDate" in props
          ? { ...props, endDate: props.endDate.format(DATE_FORMAT) }
          : { ...props, startDate: props.startDate.format(DATE_FORMAT) },
        formController
          .getFieldValue("programs")
          .map((program: ScheduledClientProgramsDatesModel["programs"][number]) => ({
            ...program,
            startDate: program.startDate.format(DATE_FORMAT),
            endDate: program.endDate.format(DATE_FORMAT),
          })),
      );
      formController?.setFieldsValue({
        programs: model.programs.map((program, index) => ({
          ...program,
          startDate: dayjs(recalculatedPrograms[index]?.startDate, DATE_FORMAT),
          endDate: dayjs(recalculatedPrograms[index]?.endDate, DATE_FORMAT),
        })),
      });
    }
  };

  return (
    <Form<ScheduledClientProgramsDatesModel>
      name="form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      form={formController}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Form.List
        name="programs"
        rules={[
          {
            validator: async (_, levels) => {
              if (!levels || levels.length < 1) {
                return Promise.reject(new Error("At least 1 level"));
              }
            },
          },
        ]}
      >
        {(fields, { add }) => {
          const lastIndex = fields.length - 1;
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div key={field.name}>
                    <Form.Item
                      name={[field.name, "name"]}
                      label={
                        <div style={{ minWidth: 100 }}>
                          {index + 1}: {t("editSchedule.name")}
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: t<string>("validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Input disabled style={{ width: "80%" }} />
                    </Form.Item>
                    <Space key={field.key} style={{ width: "100%" }} align="baseline">
                      <Form.Item
                        name={[field.name, "startDate"]}
                        label={t("editSchedule.startDate")}
                        rules={[
                          {
                            required: true,
                            message: t<string>("validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          format="DD-MM-YYYY"
                          allowClear={false}
                          onChange={(date) =>
                            date ? updateDates({ id: model.programs[index]?.id || "", startDate: date }) : undefined
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "endDate"]}
                        label={t("editSchedule.endDate")}
                        rules={[
                          {
                            required: true,
                            message: t<string>("validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          format="DD-MM-YYYY"
                          allowClear={false}
                          onChange={(date) =>
                            date ? updateDates({ id: model.programs[index]?.id || "", endDate: date }) : undefined
                          }
                        />
                      </Form.Item>
                    </Space>
                  </div>
                );
              })}
              <div style={{ flexBasis: "100%", marginTop: 16, display: "none" }}>
                <Row style={{ width: "100%" }}>
                  <Col xs={18}>
                    <Button
                      type="dashed"
                      onClick={() => add(undefined, lastIndex ? lastIndex - 1 : 0)}
                      block
                      icon={<PlusOutlined />}
                    >
                      Dodaj kolejny program
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default ScheduledClientProgramsDates;
