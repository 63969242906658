import { createAsyncThunk } from "@reduxjs/toolkit";

import { ClientActivityWithCalculatedScore } from "@fitness-app/data-models/domain/services/ClientActivityWithCalculatedScore";
import { type ClientActivity, type ClientActivityWithScore } from "@fitness-app/data-models/entities/ClientActivity";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
};

export const fetchClientActivity = createAsyncThunk<ClientActivityWithScore | null, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/fetchClientActivity`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { data, error } = await db
      .from("client_activity")
      .select("*")
      .eq("traineeId", payload.id)
      .maybeSingle<ClientActivity>();

    if (error) {
      return rejectWithValue(error.message);
    }

    const report = data ? new ClientActivityWithCalculatedScore(data).calculateScore().getReport() : null;

    return report;
  },
);
