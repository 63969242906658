import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { botsConfigActions } from "@fitness-app/app-store";

import Automation from "~/modules/Automation/Automation";
import ProgramAutomationDetails from "~/modules/Automation/ProgramAutomation/ProgramAutomationDetails";
import { useAppDispatch } from "~/store/initializeStore";

const AutomationRouter = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(botsConfigActions.fetchChatBots());
  }, [dispatch]);

  return (
    <Routes>
      <Route index element={<Automation />} />
      <Route path="/details/:id" element={<ProgramAutomationDetails />} />
      <Route path="/:tab/*" element={<Automation />} />
    </Routes>
  );
};

export default AutomationRouter;
