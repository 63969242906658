import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProductResourceCategory } from "@fitness-app/data-models/entities/ProductResource";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_RESOURCES_REDUCER_NAME } from "../types";

export const fetchResourcesCategories = createAsyncThunk<ProductResourceCategory[], void, AsyncThunkCreator<string>>(
  `${PRODUCT_RESOURCES_REDUCER_NAME}/fetchResourcesCategories`,
  async (_, { rejectWithValue, extra: { db, auth } }) => {
    const { id } = await getLoggedUser(auth);

    const { error, data } = await db
      .from("product_resource_category")
      .select("*")
      .order("createdAt", { ascending: false })
      .eq("owner", id);

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
