import React, { type FunctionComponent } from "react";
import { Alert, Button, Form, Input, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { userActions, UserStatus } from "@fitness-app/app-store";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface LoginFormModel {
  email: string;
  password: string;
}

const Login: FunctionComponent = () => {
  const { t } = useTranslation(["auth", "common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formController] = Form.useForm<LoginFormModel>();
  const { status: userStatus, error } = useAppSelector((store) => store.user);

  const onFinish = (values: LoginFormModel) => {
    dispatch(userActions.logInUser(values));
  };

  const generateForgotPasswordLink = () => {
    const email = formController.getFieldValue("email") as string;
    if (email) {
      return `/auth/password-reminder?email=${email}`;
    }
    return "/auth/password-reminder";
  };

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="min-w-[350px] p-4">
        <Typography.Title level={4} className="pb-8">
          {t("loginPanel")}
        </Typography.Title>
        {userStatus === UserStatus.HAS_ERROR ? (
          <Alert
            className="mb-10"
            type={userStatus === UserStatus.HAS_ERROR ? "error" : "info"}
            message={userStatus === UserStatus.HAS_ERROR ? error : <Trans t={t} i18nKey="common:startJourney" />}
          />
        ) : null}

        <Form<LoginFormModel>
          name="login-form"
          form={formController}
          layout="vertical"
          onFinish={onFinish}
          size="large"
          validateTrigger={["onSubmit", "onBlur"]}
        >
          <Form.Item
            name="email"
            label={t("login")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
              {
                type: "email",
                message: t<string>("validationErrors.wrongEmail"),
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("password")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button
            size="small"
            style={{
              width: "100%",
              margin: "10px 0 30px",
              color: "#8950fc",
              fontWeight: "bold",
              fontSize: 16,
              whiteSpace: "normal",
              display: "none",
            }}
            onClick={() => navigate(`/auth/login-by-link?email=${formController.getFieldValue("email") ?? ""}`)}
            type="link"
            tabIndex={-1}
            danger
          >
            {t("common:button.loginByLink")}
          </Button>

          <Button
            size="small"
            style={{
              width: "100%",
              margin: "10px 0 30px",
              whiteSpace: "normal",
            }}
            onClick={() =>
              navigate(
                `/auth/login-by-link?email=${
                  formController.getFieldValue("email") ? encodeURI(formController.getFieldValue("email")) ?? "" : ""
                }`,
              )
            }
            type="link"
            tabIndex={-1}
          >
            {t("common:button.loginByLink")}
          </Button>

          <div className="mt-8 flex items-center	justify-between">
            <div className="basis-8/12">
              <Button onClick={() => navigate(generateForgotPasswordLink())} type="link">
                {t("common:button.forgotPassword")}
              </Button>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              block
              className="basis-4/12"
              loading={userStatus === UserStatus.LOGGING_IN}
            >
              {t("common:button.login")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
