import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ProgramDayTemplates from "~/modules/TrainingPrograms/ProgramDayTemplates/ProgramDayTemplates";
import ExercisesDatabase from "~/modules/TrainingPrograms/tabs/ExercisesDatabase/ExercisesDatabase";
import ProgramsList from "~/modules/TrainingPrograms/tabs/ProgramsList";

const TABS_KEY = ["plans", "templates", "exercises"];

const TrainingPrograms = () => {
  const { t } = useTranslation(["workouts", "common"]);

  const { tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const tabFromUrl = tab || location.pathname.split("/").slice(-1)[0] || "";

  const activeTab = TABS_KEY.includes(tabFromUrl) ? tabFromUrl : "plans";

  useEffect(() => {
    if (!TABS_KEY.includes(tabFromUrl)) {
      navigate(`/programs/plans`);
    }
  }, [tabFromUrl]);

  return (
    <div className="p-6">
      <Tabs
        type="card"
        defaultActiveKey="exercises"
        className="tabs-reset"
        activeKey={activeTab}
        onChange={(tab) => navigate(`/programs/${tab}`)}
      >
        <Tabs.TabPane key="plans" tab={t("plans")}>
          <ProgramsList />
        </Tabs.TabPane>

        <Tabs.TabPane key="templates" tab={t("templates")}>
          <ProgramDayTemplates />
        </Tabs.TabPane>

        <Tabs.TabPane key="exercises" tab={t("exercises")}>
          <ExercisesDatabase />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default TrainingPrograms;
