import { createSlice } from "@reduxjs/toolkit";

import { PerformingAction } from "../../../enums/performingAction";
import { RequestStatus } from "../../../enums/requestStatus";
import {
  addProgramAutomation,
  deleteProgramAutomation,
  fetchProgramAutomation,
  fetchProgramAutomations,
  updateAutomationTemplate,
} from "./actions";
import { AUTOMATION_REDUCER_NAME, type ProgramAutomationReducer } from "./types";

const initialState: ProgramAutomationReducer = {
  listStatus: null,
  data: [],
  selectedAutomation: null,
  error: null,
  performingAction: null,
  itemStatus: null,
};

const automationSlice = createSlice({
  name: AUTOMATION_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProgramAutomations.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchProgramAutomations.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.data = payload;
    });
    builder.addCase(fetchProgramAutomations.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchProgramAutomation.pending, (state) => {
      state.itemStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchProgramAutomation.fulfilled, (state, { payload }) => {
      state.itemStatus = RequestStatus.SUCCESS;
      state.selectedAutomation = payload;
    });
    builder.addCase(fetchProgramAutomation.rejected, (state) => {
      state.itemStatus = RequestStatus.FAILED;
    });
    builder.addCase(addProgramAutomation.pending, (state) => {
      state.performingAction = PerformingAction.Adding;
    });
    builder.addCase(addProgramAutomation.fulfilled, (state, { payload }) => {
      state.performingAction = null;

      state.data.unshift(payload);
    });
    builder.addCase(addProgramAutomation.rejected, (state) => {
      state.performingAction = null;
    });
    builder.addCase(deleteProgramAutomation.pending, (state) => {
      state.performingAction = PerformingAction.Removing;
    });
    builder.addCase(deleteProgramAutomation.fulfilled, (state) => {
      state.performingAction = null;

      state.data = state.data.filter((automation) => automation.id !== state.selectedAutomation?.id);
    });
    builder.addCase(deleteProgramAutomation.rejected, (state) => {
      state.performingAction = null;
    });
    builder.addCase(updateAutomationTemplate.pending, (state, { meta }) => {
      state.performingAction = PerformingAction.Editing;
      state.performingAction = null;
      state.data = state.data.map((automation) => {
        if (automation.id === meta.arg.automationId) {
          return {
            ...automation,
            ...meta.arg.automation,
          };
        }

        return automation;
      });

      if (state.selectedAutomation?.id === meta.arg.automationId) {
        state.selectedAutomation = {
          ...state.selectedAutomation,
          ...meta.arg.automation,
        };
      }
    });
    builder.addCase(updateAutomationTemplate.fulfilled, (state, { payload }) => {
      state.performingAction = null;
      state.data = state.data.map((automation) => {
        if (automation.id === state.selectedAutomation?.id) {
          return {
            ...automation,
            ...payload,
          };
        }

        return automation;
      });

      if (state.selectedAutomation?.id === payload.id) {
        state.selectedAutomation = {
          ...state.selectedAutomation,
          ...payload,
        };
      }
    });
    builder.addCase(updateAutomationTemplate.rejected, (state) => {
      state.performingAction = null;
    });
  },
});

export default automationSlice.reducer;
