import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { programAutomationActions, RequestStatus } from "@fitness-app/app-store";
import { AppAccessType, FitnessAppTask } from "@fitness-app/data-models/entities/Automation";
import { ProductType, type Product } from "@fitness-app/data-models/entities/Product";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const useFitnessAppAutomationTask = (product: Product | null) => {
  const { t } = useTranslation(["common", "products"]);
  const { listStatus, data } = useAppSelector((store) => store.programAutomation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (listStatus !== RequestStatus.SUCCESS) {
      void dispatch(programAutomationActions.fetchProgramAutomations());
    }
  }, [dispatch]);

  const fitnessAppTaskOptions = useMemo(() => {
    if (!product || product.type === ProductType.ONLY_CONTENT) {
      return [];
    }

    if (product.type === ProductType.ADDON) {
      return [
        {
          label: t(`common:fitnessAppTask.${FitnessAppTask.AddProgramAutomationToClient}`),
          value: FitnessAppTask.AddProgramAutomationToClient,
        },
      ];
    }

    return [FitnessAppTask.AddClientToApp, FitnessAppTask.RemoveClientFromApp].map((value) => ({
      label: t(`common:fitnessAppTask.${value}`),
      value,
    }));
  }, [t]);

  const programAutomationOptions = useMemo(() => {
    return data.map((automation) => ({
      label: automation.name,
      value: automation.id,
    }));
  }, [data]);

  const appAccessOptions = useMemo(() => {
    return Object.values(AppAccessType).map((access) => ({
      value: access,
      label: t(`accessType.${access}`),
    }));
  }, [t, product]);

  return {
    fitnessAppTaskOptions,
    programAutomationOptions,
    appAccessOptions,
  };
};
