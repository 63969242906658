import { type AppThunk } from "../../../index";
import { sendPasswordResetStarted, sendPasswordResetSuccess } from "../reducer";

export const sendPasswordResetLink =
  (email: string, redirectTo?: string): AppThunk =>
  async (dispatch, _, { auth }) => {
    dispatch(sendPasswordResetStarted());

    await auth.resetPasswordForEmail(email, {
      redirectTo,
    });

    dispatch(sendPasswordResetSuccess());
  };
