import React, { useEffect, useState, type FunctionComponent } from "react";
import { Button, Card, Divider, Empty, Popconfirm, Table } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { automationTemplatesActions, automationTemplatesSelectors, RequestStatus } from "@fitness-app/app-store";
import { TemplateType, type ScheduledMessagesTemplate } from "@fitness-app/data-models/entities/AutomationTemplate";

import ModalForm from "~/components/ModalForm/ModalForm";
import AutomatedMessagesForm from "~/modules/Automation/AutomatedMessagesForm/AutomatedMessagesForm";
import { FormMode, type AutomatedMessagesFormModel } from "~/modules/Automation/AutomatedMessagesForm/types";
import { useAppDispatch } from "~/store/initializeStore";

type MessageTemplateListItem = ScheduledMessagesTemplate;

const useMessageTemplates = () => {
  const [isFormVisible, setFormVisibility] = useState(false);
  const [messageTemplate, setMessageTemplate] = useState<MessageTemplateListItem | null>(null);
  const templates = useSelector(automationTemplatesSelectors.getAutomationTemplatesMessages);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(automationTemplatesActions.fetchAutomationTemplates());
  }, [dispatch]);

  const showForm = (model?: MessageTemplateListItem) => {
    if (model) {
      setMessageTemplate(model);
    }

    setFormVisibility(true);
  };

  const hideForm = () => {
    setMessageTemplate(null);
    setFormVisibility(false);
  };

  const getFormModel = () => {
    if (!messageTemplate) {
      return {
        mode: FormMode.Template,
      };
    }

    return {
      mode: FormMode.Template,
      id: messageTemplate.id,
      name: messageTemplate.name,
      comment: messageTemplate.comment,
      sentTime: dayjs()
        .set("hour", messageTemplate.data.sentTime.hours)
        .set("minutes", messageTemplate.data.sentTime.minutes),
      messages: messageTemplate.data.messages,
      authorId: messageTemplate.data.authorId,
    };
  };

  const handleSubmit = async (formData: AutomatedMessagesFormModel) => {
    const payload: ScheduledMessagesTemplate = {
      id: formData.id || uuid(),
      name: formData.name,
      comment: formData.comment || "",
      type: TemplateType.ScheduledMessages,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      data: {
        attachments: [],
        messages: formData.messages,
        authorId: formData.authorId,
        authorRole: formData.authorRole,
        authorName: formData.authorName,
        sentTime: {
          hours: formData.sentTime.get("hour"),
          minutes: formData.sentTime.get("minutes"),
        },
      },
    };

    if (formData.id) {
      const updatePayload = {
        ...payload,
        id: formData.id,
      };
      await dispatch(
        automationTemplatesActions.updateTemplate({
          id: updatePayload.id,
          template: updatePayload,
        }),
      );
    } else {
      await dispatch(automationTemplatesActions.addTemplate(payload));
    }

    setFormVisibility(false);
  };

  const duplicate = async ({ id, ...rest }: MessageTemplateListItem) => {
    const duplicate = {
      ...rest,
      id: uuid(),
      name: `${rest.name} (1)`,
    };

    await dispatch(automationTemplatesActions.addTemplate(duplicate));
  };

  const deleteTemplate = async (id: string) => {
    await dispatch(automationTemplatesActions.deleteTemplate(id));
  };

  return {
    model: {
      data: templates,
      isFormVisible,
    },
    operations: {
      showForm,
      hideForm,
      getFormModel,
      handleSubmit,
      duplicate,
      deleteTemplate,
    },
  };
};

const MessageTemplatesList: FunctionComponent = () => {
  const { model, operations } = useMessageTemplates();
  const { t } = useTranslation(["automation", "common"]);

  return (
    <Card
      loading={status === RequestStatus.FETCHING}
      bordered={false}
      extra={
        <Button type="primary" onClick={() => operations.showForm()}>
          {t("common:button.add")}
        </Button>
      }
    >
      <Table
        loading={false}
        dataSource={model.data}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common:emptyList")} />,
        }}
      >
        <Table.Column<MessageTemplateListItem>
          title={t("messages.list.name")}
          key="name"
          dataIndex="name"
          defaultSortOrder="ascend"
          sorter={(recordA, recordB) => (recordA.name < recordB.name ? -1 : 1)}
        />
        <Table.Column<MessageTemplateListItem>
          title={t("messages.list.comment")}
          key="comment"
          dataIndex="comment"
          ellipsis
        />
        <Table.Column<MessageTemplateListItem>
          title={t("common:details")}
          key="action"
          align="center"
          render={(_, model) => (
            <>
              <Button type="link" onClick={() => operations.showForm(model)}>
                {t("common:details")}
              </Button>
              <Divider type={"vertical"} />
              <Button type="link" onClick={() => operations.duplicate(model)}>
                {t("common:button.duplicate")}
              </Button>
              <Divider type={"vertical"} />
              <Popconfirm
                title={t("automatedMessageForm.deleteEvent")}
                okText={t("common:switch.yes")}
                cancelText={t("common:button.cancel")}
                placement="top"
                onConfirm={() => operations.deleteTemplate(model.id)}
              >
                <Button type="link" danger>
                  {t("common:button.delete")}
                </Button>
              </Popconfirm>
            </>
          )}
        />
      </Table>
      <ModalForm
        open={model.isFormVisible}
        onCancel={operations.hideForm}
        title={operations.getFormModel() ? t("templates.addTemplate") : t("templates.editTemplate")}
        loading={false}
      >
        <AutomatedMessagesForm model={operations.getFormModel()} onSubmit={operations.handleSubmit} />
      </ModalForm>
    </Card>
  );
};

export default MessageTemplatesList;
