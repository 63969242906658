import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, Empty, Select, Space } from "antd";

import { getTraineeId } from "@fitness-app/app-store/src/store/reducers/trainee/selectors/getTraineeId";

import { ExerciseProgressChart } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/ExerciseProgressChart";
import { useExerciseStats } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/hooks/useExerciseStats";
import { supabase, useAppSelector } from "~/store/initializeStore";

export const TraineeWorkoutsStats = (): React.ReactElement => {
  const traineeId = useAppSelector(getTraineeId);
  const { setExercise, fetchingExerciseStats, exerciseStats, selectedExercise } = useExerciseStats(traineeId);
  const { data, isLoading } = useQuery(["exercisesList", traineeId], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!traineeId) {
        return null;
      }
      const { error, data } = await supabase
        .from("workout_aggregate_exercise_stats")
        .select("id, exerciseId, createdAt, updatedAt, name, records")
        .eq("traineeId", traineeId)
        .order("updatedAt", { ascending: false });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  return (
    <Card
      loading={isLoading}
      extra={
        <Select
          style={{ width: 350 }}
          showSearch
          onSelect={(value: string | null) => setExercise(value)}
          placeholder="Wybierz ćwiczenie z listy"
          // @ts-expect-error - fix this
          filterOption={(input, option) => option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {(data || []).map((exercise) => (
            <Select.Option key={exercise.id} value={exercise.exerciseId} title={exercise.name}>
              {exercise.name}
            </Select.Option>
          ))}
        </Select>
      }
    >
      <Space direction="vertical" size={12} style={{ width: "100%" }}>
        {selectedExercise ? (
          <ExerciseProgressChart
            exerciseStats={exerciseStats}
            isLoading={fetchingExerciseStats}
            selectedExercise={selectedExercise}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Wybierz ćwiczenie z listy" />
        )}
      </Space>
    </Card>
  );
};
