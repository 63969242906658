import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ProductAutomation } from "@fitness-app/data-models/entities/Automation";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_AUTOMATION_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  integrations: ProductAutomation["productIntegrations"];
};

export const updateProductAutomationIntegrations = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationIntegrations`,
  async (payload, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error("product automation data not exists");
      }

      await db
        .from("product_automation")
        .update({
          productIntegrations: payload,
          updatedAt: new Date().toISOString(),
        })
        .eq("productId", payload.productId);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
