import React, { useMemo, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, message, Popover, Select } from "antd";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { type ProductPrice } from "@fitness-app/data-models/entities/Product";
import { getProductLinkForMultiplePrices } from "@fitness-app/utils/src/products/getProductLink";

import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";

interface CreateCheckoutVariantLinkProps {
  prices: ProductPrice[];
  productId: string;
  productSlug?: string;
}

export const CreateCheckoutVariantLink = ({
  prices,
  productSlug,
  productId,
}: CreateCheckoutVariantLinkProps): React.ReactElement => {
  const { t } = useTranslation(["products", "common"]);
  const options = useProductPricesOptions();
  const [selectedPrices, setSelectedPrices] = useState<string[]>([]);

  const priceById = useMemo(() => keyBy(prices, "id"), [prices]);

  const link = useMemo(() => {
    if (selectedPrices.length === 0) {
      return "";
    }

    return getProductLinkForMultiplePrices({
      productId,
      domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
      slug: productSlug,
      checkoutId: selectedPrices.map((priceId) =>
        priceById[priceId]?.hidden ? priceById[priceId].checkoutId || priceId : priceId,
      ),
    });
  }, [selectedPrices]);

  return (
    <Popover
      onVisibleChange={(visible) => {
        if (!visible) {
          setSelectedPrices([]);
        }
      }}
      overlayStyle={{
        width: 500,
      }}
      content={
        <div className="flex flex-col gap-3">
          <Select
            value={selectedPrices}
            onChange={setSelectedPrices}
            mode="multiple"
            placeholder="Wybierz ceny, które mają być dostępne w linku"
            options={options}
          />
          <Input
            value={link}
            disabled={!link}
            onChange={(_value) => {}}
            placeholder="Wybierz ceny, aby otrzymać link do skopiowania"
            addonAfter={
              <CopyOutlined
                onClick={() => {
                  if (link) {
                    void navigator.clipboard.writeText(link);
                    void message.success(t("common:copied"));
                  }
                }}
              />
            }
          />
        </div>
      }
      title="Utwórz link do koszyka z wybranymi wariantami"
    >
      <Button>Utwórz link do koszyka</Button>
    </Popover>
  );
};
