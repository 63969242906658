import { createAsyncThunk } from "@reduxjs/toolkit";

import { NotificationStatus } from "@fitness-app/data-models/entities/Notification";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { setNumberOfUnreadNotifications } from "../reducer";
import { NOTIFICATIONS_REDUCER_NAME } from "../types";

type Payload = void;

export const refreshUnreadNotifications = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${NOTIFICATIONS_REDUCER_NAME}/refreshUnreadNotifications`,
  async (_payload, { dispatch, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { count } = await db
      .from("notification")
      .select("id, ownerId, status", { count: "exact", head: true })
      .eq("ownerId", currentUser.id)
      .eq("status", NotificationStatus.UNREAD)
      .limit(120);

    dispatch(setNumberOfUnreadNotifications(count ?? 0));
  },
);
