import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Product } from "@fitness-app/data-models/entities/Product";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_REDUCER_NAME, type ProductWithStats } from "../types";

type Payload = void;

export const fetchProducts = createAsyncThunk<Product[], Payload, AsyncThunkCreator<string>>(
  `${PRODUCTS_REDUCER_NAME}/fetchProducts`,
  async (member, { rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("product")
      .select("*, product_stats(activeClients, archivedClients, totalClients)")
      .order("createdAt", { ascending: false })
      .eq("owner", currentUser.id)
      .returns<ProductWithStats[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
