import React from "react";
import { Form, InputNumber, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

import FitnessFieldTooltip from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramForm/FitnessFieldTooltip";
import { durationFormatOptions, numberOfSeriesOptions } from "./types";

interface CardioExerciseFormProps {
  isSuperSet?: boolean;
}

const CardioExerciseForm = ({ isSuperSet }: CardioExerciseFormProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  return (
    <>
      <Form.Item label={t("exerciseInProgram.numberOfSeries")} name="numberOfSeries">
        <Select options={numberOfSeriesOptions} disabled={isSuperSet} defaultValue={1} style={{ maxWidth: 100 }} />
      </Form.Item>

      <Form.Item
        label={t("exerciseInProgram.duration")}
        tooltip={<FitnessFieldTooltip bigValue unit={t<string>("duration.minutesAbv")} />}
      >
        <Space.Compact>
          <Form.Item
            name={["duration", "value"]}
            noStyle
            rules={[{ required: true, type: "number", message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <InputNumber style={{ width: 100 }} min={0} max={9999} />
          </Form.Item>
          <Form.Item
            name={["duration", "format"]}
            noStyle
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Select
              options={durationFormatOptions}
              style={{ width: 100 }}
              placeholder={t("exerciseInProgram.selectFormat")}
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item label={t("exerciseInProgram.tempo")} name="tempo">
        <InputNumber style={{ width: "50%" }} min={1} max={50} addonAfter="km/h" />
      </Form.Item>
    </>
  );
};

export default CardioExerciseForm;
