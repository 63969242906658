import { type AppThunk } from "../../../index";
import { unsubscribeFromIntegration as actionCreator } from "../reducer";

export const unsubscribeFromProductsSettings =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    if (subscriptions.realtimeProductsSettingsListChannel) {
      await db.removeChannel(subscriptions.realtimeProductsSettingsListChannel);
      subscriptions.productsSettingsListChannelName = null;
    }
    dispatch(actionCreator());
  };
