import React, { useState, type FunctionComponent } from "react";
import { Radio } from "antd";

import { PaymentClient, type PaymentClientEnum } from "@fitness-app/data-models/entities/Product";

import TPayIntegrationPanel from "~/modules/Products/ProductsSettings/PaymentGateways/clients/tpay/TPayIntegrationPanel";
import { useAppSelector } from "~/store/initializeStore";
import TPayIcon from "./clients/tpay/tpay-logo.png";
import PaymentIntegration from "./PaymentIntegration";
import StripeIcon from "./stripe.svg";

const PaymentGateways: FunctionComponent = () => {
  const { data } = useAppSelector((state) => state.productsSettings);
  const [selectedIntegration, setIntegration] = useState<PaymentClientEnum>(PaymentClient.STRIPE);

  const integrationElements: { [K in PaymentClientEnum]?: JSX.Element } = {
    [PaymentClient.STRIPE]: <PaymentIntegration connected={!!data?.isStripeConnected} />,
    [PaymentClient.TPAY]: <TPayIntegrationPanel tpayClientId={data?.tpay?.clientId} />,
  };

  return (
    <div className="mx-auto my-12 flex max-w-[800px] flex-col text-center">
      <Radio.Group size="large" value={selectedIntegration} onChange={(e) => setIntegration(e.target.value)}>
        <Radio.Button value={PaymentClient.STRIPE}>
          <img
            src={StripeIcon}
            alt={`stripe-icon`}
            className="h-full w-full max-w-[200px] object-contain	align-text-bottom	"
          />
        </Radio.Button>
        <Radio.Button value={PaymentClient.TPAY}>
          <img
            src={TPayIcon}
            alt={`stripe-icon`}
            className="h-full w-full max-w-[200px] object-contain	align-text-bottom	"
          />
        </Radio.Button>
      </Radio.Group>
      {integrationElements[selectedIntegration]}
    </div>
  );
};

export default PaymentGateways;
