import { type Webhook } from "@fitness-app/data-models/entities/Automation";

import {
  type GetPayloadItem,
  type PostPayloadItem,
} from "~/modules/Products/Product/ProductAutomation/components/WebhookForm/types";

export const formatWebhookPayloadFromModel = (model?: PostPayloadItem[] | GetPayloadItem[]) => {
  if (!model) {
    return null;
  }
  const payloadObj: Webhook["payload"] = {};
  model.forEach((item: PostPayloadItem | GetPayloadItem) => {
    if ("type" in item) {
      payloadObj[item.key] = {
        type: item.type as never,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value: item.type === "number" ? Number(item.value) : (String(item.value) as any),
      };
    } else {
      payloadObj[item.key] = item.value;
    }
  });

  return payloadObj;
};

export const formatWebhookPayloadIntoModel = (model?: Webhook["payload"]) => {
  if (!model) {
    return;
  }
  return Object.entries(model).map(([key]) => {
    const value = model[key];
    return {
      key,
      value: !value || typeof value === "string" ? value : value.value,
      ...(typeof value === "string" || !value ? {} : { type: value.type }),
    };
  }) as PostPayloadItem[] | GetPayloadItem[];
};
