import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";

type Payload = {
  survey: Partial<ClientSurvey>;
  surveyId: string;
};

export const updateSurvey = createAsyncThunk<ClientSurvey, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/updateSurvey`,
  async (payload, { rejectWithValue, extra: { db, errorTrackingService, analytics } }) => {
    const survey: Partial<ClientSurvey> = {
      ...payload.survey,
      updatedAt: new Date().toISOString(),
    };

    const { error, data } = await db
      .from("client_survey")
      .update({
        ...survey,
      })
      .eq("id", payload.surveyId)
      .select("*")
      .single<ClientSurvey>();

    if (error) {
      errorTrackingService?.recordError(error, "updateSurvey");
      return rejectWithValue(error.message);
    }

    analytics.track("updated_trainee_survey");

    return data;
  },
);
