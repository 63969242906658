import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = { priceId: string; productId: string; customLabel: string };

export const updateProductPriceLabel = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCT_REDUCER_NAME}/updateProductPriceLabel`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { product } = getState();

      if (product.details?.id !== payload.productId) {
        throw new Error("different-product-id");
      }

      if (!product.details.prices?.length) {
        throw new Error("product-without-prices");
      }

      const updatedPrices = product.details.prices.map((price) =>
        price.id === payload.priceId ? { ...price, customLabel: payload.customLabel } : price,
      );

      const { error } = await db.from("product").update({ prices: updatedPrices }).eq("id", payload.productId);

      if (error) {
        throw new Error(error.message);
      }

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
