/* eslint-disable */
// @ts-nocheck
import CheckList from "@editorjs/checklist";
import Code from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import Table from "@editorjs/table";
import Warning from "@editorjs/warning";
import { message } from "antd";
import { v4 as uuid } from "uuid";

import { supabase } from "~/store/initializeStore";
import i18n from "../../i18nextConfig";

export const EDITOR_JS_TOOLS = {
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        codepen: true,
        vimeo: {
          regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
          embedUrl: "https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0",
          html: '<iframe allowfullscreen style="width:100%;" height="320" frameborder="0" ></iframe>',
          height: 320,
          width: 580,
        },
        imgur: true,
        gfycat: true,
        twitter: true,
        instagram: true,
        bunny: {
          regex: /https:\/\/iframe\.mediadelivery\.net\/play\/(\d+\/[a-zA-Z0-9-]+)/,
          embedUrl:
            "https://iframe.mediadelivery.net/embed/<%= remote_id %>?autoplay=false&loop=false&muted=false&preload=true&responsive=true",
          html: '<iframe loading="lazy" style="width:100%;" height="320" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture" allowfullscreen></iframe>',
          height: 320, // Adjust if necessary
          width: 580, // Adjust if necessary
        },
      },
    },
  },
  table: Table,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: i18n.t("products:resourcePlaceholder"),
    },
  },
  list: List,
  warning: Warning,
  code: Code,
  image: Image,
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
};

export const createTools = ({ storageRef }: { storageRef: string }) => ({
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        codepen: true,
        vimeo: {
          regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
          embedUrl: "https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0",
          html: '<iframe allowfullscreen webkitallowfullscreen mozallowfullscreen style="width:100%;" height="320" frameborder="0" ></iframe>',
          height: 320,
          width: 580,
        },
        imgur: true,
        gfycat: true,
        twitter: true,
        instagram: true,
        bunny: {
          regex: /https:\/\/iframe\.mediadelivery\.net\/play\/(\d+\/[a-zA-Z0-9-]+)/,
          embedUrl:
            "https://iframe.mediadelivery.net/embed/<%= remote_id %>?autoplay=false&loop=false&muted=false&preload=true&responsive=true",
          html: '<iframe loading="lazy" style="width:100%;" height="320" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture" allowfullscreen></iframe>',
          height: 320, // Adjust if necessary
          width: 580, // Adjust if necessary
        },
      },
    },
  },
  table: Table,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: i18n.t("products:resourcePlaceholder"),
    },
  },
  list: List,
  warning: Warning,
  code: Code,
  image: {
    class: Image,
    config: {
      uploader: {
        /**
         * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
         * @param {string} url - pasted image URL
         * @return {Promise.<{success, file: {url}}>}
         */
        uploadByUrl(url: string) {
          // your ajax request for uploading
          return Promise.resolve({
            success: 1,
            file: {
              url,
              // any other image data you want to store, such as width, height, color, extension, etc
            },
          });
        },
        async uploadByFile(file: File) {
          // your own uploading logic here
          void message.loading(i18n.t("products:sending"));
          const ref = `${storageRef}/${uuid()}-${file.name}`;
          const { error: uploadError } = await supabase.storage.from("users").upload(ref, file, {
            cacheControl: "3600",
            upsert: true,
          });
          message.destroy();
          if (uploadError) {
            void message.error(uploadError.message);
            return;
          }
          const { data, error } = await supabase.storage.from("users").createSignedUrl(ref, 631138519);

          if (error) {
            void message.error(error.message);
            return;
          }

          return {
            success: 1,
            file: {
              url: data.signedUrl,
              size: file.size,
              name: file.name,
              type: file.type,
            },
          };
        },
      },
    },
  },
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
});
