import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import QuestionBase from "~/modules/SurveysTemplates/SurveyForm/questions/QuestionBase";

const SelectQuestion = () => {
  const { t } = useTranslation("surveys");
  return (
    <div>
      <QuestionBase />
      <Form.Item valuePropName="checked" name={["question", "multiSelect"]} label=" " colon={false}>
        <Checkbox>{t("questionForm.multiSelect")}</Checkbox>
      </Form.Item>

      <Form.List
        name={["question", "answers"]}
        rules={[
          {
            validator: async (_, names: string[]) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error("Pytanie musi zawierać przynajmniej 2 odpowiedzi"));
              }
            },
          },
          {
            validator: async (_, names: string[]) => {
              if (!names || names.length > 20) {
                return Promise.reject(new Error("Pytanie może zawierać maksymalnie 20 odpowiedzi"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <div
                key={field.key}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "baseline",
                }}
              >
                <Form.Item
                  {...field}
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 14 }}
                  name={[field.name]}
                  label={t("questionForm.answer")}
                  rules={[
                    {
                      required: true,
                      message: t<string>("common:validationErrors.fieldIsRequired"),
                    },
                  ]}
                  style={{ width: "90%" }}
                >
                  <Input.TextArea rows={2} />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => remove(field.name)}
                  style={{ marginLeft: 12 }}
                  className="text-red-500"
                />
              </div>
            ))}
            <Form.Item label=" " colon={false}>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ marginTop: 10 }}>
                {t("questionForm.addAnswer")}
              </Button>
            </Form.Item>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </div>
  );
};

export default SelectQuestion;
