import {
  type ChatChannelWithLastMessage,
  type ChatChannelWithMeta,
  type ChatMessage,
  type ChatMessageWithMetadata,
  type UnreadMessages,
} from "@fitness-app/data-models/entities/Chat";

import { type RequestStatus } from "../../../enums/requestStatus";

export type SubscribeActionType = "added" | "removed" | "modified" | undefined;

export const CHAT_REDUCER_NAME = "chat";

export interface ChannelStatus {
  status: RequestStatus.SUBSCRIBING | RequestStatus.SUBSCRIBED | RequestStatus.FAILED | RequestStatus.IDLE | null;
  fetchingFirstPage: boolean;
  isLastPage: boolean;
  fetchingNextPage: boolean;
  currentPage: number;
}

export interface ChatReducer {
  error: Error | null;
  status: RequestStatus | null;
  activeChannels: ChatChannelWithMeta[];
  channels: ChatChannelWithLastMessage[];
  multipleMessageStatus: RequestStatus | null;
  unreadMessages:
    | null
    | (UnreadMessages & {
        _perChannel: Record<string, string[]>;
      });
  messagesToMark: Record<string, string[]>;
  messagesBatchSize: number;
  channelsSize: number;
  unreadChannels: ChatChannelWithLastMessage[];
  unreadChannelsStatus: RequestStatus | null;
  channelsListStatus: {
    status: RequestStatus | null;
    fetchingInitialPage: boolean;
    fetchingNextPage: boolean;
    isLastPage: boolean;
    fetchingExtraChannel: boolean;
    currentPage: number;
  };
  archivedChannels: ChatChannelWithLastMessage[];
  archivedListStatus: {
    status: RequestStatus | null;
    fetchingInitialPage: boolean;
    fetchingNextPage: boolean;
    isLastPage: boolean;
    fetchingExtraChannel: boolean;
    currentPage: number;
  };
  channelsStatus: Record<string, ChannelStatus>;
  messages: Record<string, (ChatMessage | ChatMessageWithMetadata)[]>;
  unreadMessagesStatus: RequestStatus | null;
  updatingChannelId: null | string;
  openChannelStatus: RequestStatus;
  sendRetriesCount: number;
}
