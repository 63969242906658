export enum RequestStatus {
  IDLE = "IDLE",
  FETCHING = "FETCHING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  SUBSCRIBING = "SUBSCRIBING",
  SUBSCRIBED = "SUBSCRIBED",
  UPDATING = "UPDATING",
  SEARCHING = "SEARCHING",
  ADDING = "ADDING",
  CLOSED = "CLOSED",
}
