import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import uniq from "lodash.uniq";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { supabase } from "~/store/initializeStore";

export const useSurveysVariables = () => {
  const { userId } = useUserRole();
  const { data, isLoading } = useQuery(["surveysVariables", userId], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!userId) {
        return null;
      }
      const { error, data } = await supabase
        .from("survey_template")
        .select("id, globalVariables")
        .returns<{ id: string; globalVariables: null | string[] }[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  const variables = useMemo(() => {
    if (!data) {
      return null;
    }
    const automation = data
      .map((survey) => survey.globalVariables)
      .flat()
      .filter((variable): variable is string => Boolean(variable?.length));

    if (!automation) {
      return null;
    }

    return uniq(automation).sort();
  }, [data]);

  return { data, isLoading, variables };
};
