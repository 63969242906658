import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import {
  ClientNutritionStatus,
  type ClientMealsPlanDetails,
  type ClientNutrition,
} from "@fitness-app/data-models/entities/ClientNutrition";
import {
  ProgramAutomationTaskStatus,
  ScheduledNutritionType,
  type ProgramAutomationTask,
} from "@fitness-app/data-models/entities/ProgramAutomation";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { addClientTasks, updateClientTask } from "../../clientScheduledTasks/actions";
import { MEALS_PLANS_REDUCER_NAME } from "../types";
import { fetchMealsPlanWithDetails } from "./fetchMealsPlanWithDetails";

type Payload = {
  mealsPlan: Partial<ClientNutrition> & { id: string };
  mealsPlanDetails?:
    | (Partial<ClientMealsPlanDetails> & { id: string; planId?: string })
    | (Partial<ClientMealsPlanDetails> & { planId: string; id?: string })
    | null;
};

export const updateMealsPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/updateMealsPlan`,
  async (payload, { rejectWithValue, dispatch, getState, extra: { db, auth } }) => {
    const { error } = await db.from("client_meals_plan").update(payload.mealsPlan).eq("id", payload.mealsPlan.id);
    const { trainee } = getState();
    const user = await getLoggedUser(auth);

    if (error) {
      return rejectWithValue(error.message);
    }

    if (payload.mealsPlanDetails) {
      const id = payload.mealsPlanDetails?.id || payload.mealsPlan.id;

      await db
        .from("client_meals_plan_details")
        .update(payload.mealsPlanDetails)
        .eq(payload.mealsPlanDetails?.id ? "id" : "planId", id);
    }

    if (
      payload.mealsPlan.metadata?.taskId &&
      (payload.mealsPlan.status === ClientNutritionStatus.Scheduled ||
        getState().traineeMealsPlan.selectedMealsPlan?.status === ClientNutritionStatus.Scheduled) &&
      payload.mealsPlan.startAt
    ) {
      const sendDate = dayjs(payload.mealsPlan.startAt).set("hour", 6);
      await dispatch(
        updateClientTask({
          id: payload.mealsPlan.metadata.taskId,
          task: {
            sendDate: sendDate.toISOString(),
            sendAt: sendDate.unix(),
            updatedAt: new Date().toISOString(),
          },
        }),
      ).unwrap();
    } else if (
      trainee.profile &&
      trainee.profile?.id === payload.mealsPlan.traineeId &&
      !payload.mealsPlan.metadata?.taskId &&
      (payload.mealsPlan.status === ClientNutritionStatus.Scheduled ||
        getState().traineeMealsPlan.selectedMealsPlan?.status === ClientNutritionStatus.Scheduled) &&
      payload.mealsPlan.startAt
    ) {
      const sendDate = dayjs(payload.mealsPlan.startAt).set("hour", 6);
      const taskId = generateUniqId();

      const task: ProgramAutomationTask = {
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        status: ProgramAutomationTaskStatus.Upcoming,
        programAutomationId: "manual",
        programAutomationName: "",
        id: taskId,
        sequenceId: generateUniqId(),
        clientEmail: trainee.profile.email,
        traineeId: trainee.profile.id,
        addedBy: user.id,
        task: {
          type: ScheduledNutritionType.AssignedByTrainer,
          startDate: payload.mealsPlan.startAt,
          endDate: payload.mealsPlan.endAt || null,
          mealsPlanId: payload.mealsPlan.id,
          mealsPlanName: payload.mealsPlan.name || "",
          id: taskId,
        },
        sendDate: sendDate.toISOString(),
        sendAt: sendDate.unix(),
        sentAt: null,
        errorMessage: null,
      };

      await dispatch(addClientTasks({ tasks: [task] }));

      await db
        .from("client_meals_plan")
        .update({
          metadata: {
            ...(payload.mealsPlan.metadata || {}),
            taskId,
          },
          updatedAt: new Date().toISOString(),
        })
        .eq("id", payload.mealsPlan.id)
        .throwOnError();
    }

    void dispatch(fetchMealsPlanWithDetails({ id: payload.mealsPlan.id }));
  },
);
