import { createSelector } from "reselect";
import { TemplateType } from "@fitness-app/data-models/entities/AutomationTemplate";

import { type AppStore } from "../../../index";

const getTemplates = (state: AppStore) => state.automationTemplates.listData;

export const getAutomationTemplatesMessages = createSelector([getTemplates], (templates) => {
  return templates.filter((template) => template.type === TemplateType.ScheduledMessages);
});
