import React, { memo, useState } from "react";
import { Button, Input, Space } from "antd";
import { useTranslation } from "react-i18next";

import { programsActions, RequestStatus } from "@fitness-app/app-store";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const ProgramSearch = memo((): React.ReactElement => {
  const { t } = useTranslation("nutrition");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((store) => store.programs.filters.searchTerm);
  const listStatus = useAppSelector((store) => store.programs.listStatus);
  const isSearchMode = Boolean(searchTerm);

  const onClear = () => {
    setSearchValue("");
    void dispatch(programsActions.fetchPrograms({ searchTerm: null }));
  };

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (isSearchMode) {
        onClear();
      }
      return;
    }

    void dispatch(programsActions.fetchPrograms({ searchTerm: trimmed }));
  };

  return (
    <Space size={6}>
      <Input.Search
        placeholder={t<string>("common:button.search")}
        onSearch={onSearch}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{ width: 300, margin: "20px 0" }}
        loading={listStatus === RequestStatus.FETCHING}
      />
      {Boolean(searchTerm) && (
        <Button onClick={onClear} type="link">
          {t("products:search.reset")}
        </Button>
      )}
    </Space>
  );
});

ProgramSearch.displayName = "ProgramSearch";
