import React, { useEffect, type FunctionComponent } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";

import { type MailChimpIntegrationFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<MailChimpIntegrationFormModel>;
  onSubmit: (formData: MailChimpIntegrationFormModel) => void;
  model?: MailChimpIntegrationFormModel | null;
}

type Props = OwnProps;

const MailChimpIntegrationForm: FunctionComponent<Props> = ({ onSubmit, formController, model }) => {
  const { t } = useTranslation(["settings", "common"]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <div className="min-w-[450px]">
      <Form<MailChimpIntegrationFormModel>
        name="mail-chimp-integration-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        size="large"
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="url"
          label={t("mailChimp.account")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("mailChimp.accountNamePlaceholder")} />
        </Form.Item>
        <Form.Item
          name="serverPrefix"
          label={t("mailChimp.serverPrefix")}
          tooltip={t("mailChimp.serverPrefixTooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("mailChimp.serverPrefixPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="apiKey"
          label={t("mailChimp.apiKey")}
          tooltip={t("mailChimp.tooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
};

export default MailChimpIntegrationForm;
