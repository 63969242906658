import { type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TRAINEE_SURVEYS_REDUCER_NAME = "traineeSurveys";

export interface TraineeSurveysReducer {
  selectedSurvey: null | ClientSurvey;
  selectedSurveyStatus: null | RequestStatus;

  surveys: ClientSurvey[];
  surveysStatus: null | RequestStatus;
}
