import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type MealsPlan,
  type MealsPlanDetails,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  mealsPlan: MealsPlan;
  mealsPlanDetails: MealsPlanDetails;
};

export const addMealsPlan = createAsyncThunk<
  {
    plan: MealsPlanWithCreator | null;
    details: MealsPlanDetails | null;
  } | null,
  Payload,
  AsyncThunkCreator<string>
>(
  `${MEALS_PLANS_REDUCER_NAME}/addMealsPlan`,
  async (payload, { rejectWithValue, getState, extra: { db, analytics } }) => {
    const { error, data } = await db.from("meals_plan").insert(payload.mealsPlan).select().returns<MealsPlan[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    const { error: error2, data: mealsPlanDetailsList } = await db
      .from("meals_plan_details")
      .insert(payload.mealsPlanDetails)
      .select()
      .returns<MealsPlanDetails[]>();

    if (error2) {
      return rejectWithValue(error2.message);
    }

    const profile = getState().user.profile;

    const added = data[0];

    analytics.track("add_meals_plan");

    return {
      plan: added
        ? {
            ...added,
            creator: {
              id: added.createdBy ?? "",
              firstName: profile?.firstName ?? "",
              lastName: profile?.lastName ?? "",
            },
          }
        : null,
      details: mealsPlanDetailsList[0] || null,
    } as const;
  },
);
