import React, { type FunctionComponent } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { type Order } from "@fitness-app/data-models/entities/Order";

interface OwnProps {
  invoice: Order["connectedInvoice"];
  withId?: boolean;
  hideError?: boolean;
}

type Props = OwnProps;

const InvoiceNumberInfo: FunctionComponent<Props> = ({ invoice, withId = false, hideError = false }) => {
  const { t } = useTranslation("common");
  if (!invoice) {
    return <span>-</span>;
  }

  if ("errorMessage" in invoice) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedError = invoice.errorData
      ? typeof invoice.errorData === "string"
        ? JSON.parse(invoice.errorData)
        : invoice.errorData?.message
      : "Unknown error";

    return (
      <Tooltip
        title={
          hideError
            ? ""
            : `${invoice.errorMessage} - ${
                typeof parsedError === "string"
                  ? parsedError
                  : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    parsedError?.message || JSON.stringify(parsedError)
              }`
        }
      >
        <Space>
          <Badge status="error" text={t("invoices.errorWhenIssuing")} />
          {!hideError && <InfoCircleOutlined />}
        </Space>
      </Tooltip>
    );
  }
  return (
    <span>
      {t("number")}: {invoice.number} {withId ? ` / ID: ${invoice.id} (${t(`invoices.${invoice.client}`)})` : ""}
    </span>
  );
};

export default InvoiceNumberInfo;
