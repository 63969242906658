import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TraineeSettings } from "@fitness-app/data-models/entities/TraineeSettings";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload =
  | {
      id: string;
    }
  | {
      userId: string;
    };

export const fetchTraineeSettings = createAsyncThunk<TraineeSettings, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/fetchTraineeSettings`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { data, error } = await db
      .from("trainee_settings")
      .select("*")
      .eq("id" in payload ? "id" : "userId", "id" in payload ? payload.id : payload.userId)
      .single<TraineeSettings>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
