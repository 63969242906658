import { useMemo } from "react";
import { UserRole } from "@fitness-app/data-models";

import { useAppSelector } from "~/store/initializeStore";

export const useAllowAuthorSelector = () => {
  const user = useAppSelector((state) => state.user.profile);

  return useMemo(() => {
    if (!user) {
      return false;
    }
    if (user.role === UserRole.TRAINER) {
      return true;
    }

    if (user.role === UserRole.TEAM_MEMBER) {
      return true;
    }

    return false;
  }, [user]);
};
