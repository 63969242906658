import { OrderStatusOption } from "@fitness-app/data-models/entities/Order";

export const tagColorMapper = {
  [OrderStatusOption.Created]: "#108ee9",
  [OrderStatusOption.Paid]: "#87d068",
  [OrderStatusOption.Finalized]: "#2db7f5",
  [OrderStatusOption.Imported]: "#2db7f5",
  [OrderStatusOption.Failed]: "#f64e60",
  [OrderStatusOption.Refunded]: "#f64e60",
};
