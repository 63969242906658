import React from "react";
import { Alert, Checkbox, Divider, Empty } from "antd";

import {
  TrainingStatus,
  type ExerciseInWorkout,
  type ProgramWorkout,
} from "@fitness-app/data-models/entities/TrainingProgram";

import ExerciseInfo from "~/components/ExerciseInfo/ExerciseInfo";

interface WorkoutCalendarItemProps {
  workout: ProgramWorkout;
  type: "info" | "success" | "warning" | "error" | null;
}

const alertMessage = {
  warning: "Trening wykonany częściowo",
  error: "Trening niewykonany",
  success: "Trening wykonany",
};

const WorkoutCalendarItem = ({ workout, type }: WorkoutCalendarItemProps) => {
  const allExercises = [...workout.preWorkout, ...workout.exercises, ...workout.postWorkout];
  if (!allExercises.length) {
    return (
      <Empty imageStyle={{ height: 30 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description="Lista ćwiczeń jest pusta" />
    );
  }

  const renderList = (exercisesList: ExerciseInWorkout[], label: string, hideEmpty = false) => {
    if (hideEmpty && !exercisesList.length) {
      return null;
    }
    return (
      <>
        <Divider plain style={{ marginTop: -5 }}>
          {label}
        </Divider>
        <ul>
          {exercisesList.map((set) => (
            <li key={set.id} className="flex gap-x-2">
              <Checkbox disabled checked={set.status === TrainingStatus.FULFILLED} />
              <span>{set.exercise.name}</span>
              <div className="flex gap-2 text-gray-700">
                <ExerciseInfo exerciseInWorkout={set} />
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className="min-w-[300px]">
      {renderList(workout.preWorkout, "Rozgrzewka", true)}
      {renderList(workout.exercises, "Trening")}
      {renderList(workout.postWorkout, "Po treningu", true)}
      {type && type !== "info" && (
        <Alert message={alertMessage[type]} type={type} showIcon style={{ marginBottom: "10px", marginTop: 10 }} />
      )}
    </div>
  );
};

export default WorkoutCalendarItem;
