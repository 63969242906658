import React, { useState } from "react";
import { Badge, Modal, Space } from "antd";
import dayjs from "dayjs";
import keyBy from "lodash.keyby";

import { traineeActivitiesActions } from "@fitness-app/app-store";
import { TrainingStatus, type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import ModalForm from "~/components/ModalForm/ModalForm";
import WorkoutDayBuilder from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeCalendar/components/EditTraineeWorkoutDay/WorkoutDayBuilder";
import TraineeWorkoutDayProvider from "~/shared/providers/TraineeWorkoutDayProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const trainingAlert = {
  warning: "Trening wykonany częściowo",
  error: "Trening niewykonany",
  success: "Trening wykonany",
  processing: "Trening zaplanowany",
  default: "Trening zaplanowany",
} as const;

export const trainingStatus = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  PROCESSING: "processing",
  DEFAULT: "default",
} as const;

const returnProperTrainingStatus = (
  workoutDay: ExerciseInWorkout[],
  day = dayjs(),
): (typeof trainingStatus)[keyof typeof trainingStatus] => {
  const workoutsCount = Object.keys(workoutDay).length;
  const fulfilledWorkouts = workoutDay.filter((exercise) => exercise.status === TrainingStatus.FULFILLED).length;
  const partiallyFulfilled = workoutDay.filter(
    (exercise) => exercise.status === TrainingStatus.PARTIALLY_FULFILLED,
  ).length;
  const rejectedWorkouts = workoutDay.filter((exercise) => exercise.status === TrainingStatus.REJECTED).length;

  if (workoutsCount === fulfilledWorkouts) return trainingStatus.SUCCESS;
  if (fulfilledWorkouts && workoutsCount > fulfilledWorkouts) return trainingStatus.WARNING;
  if (partiallyFulfilled && workoutsCount > partiallyFulfilled) return trainingStatus.WARNING;
  if (!fulfilledWorkouts && day.isBefore(dayjs().endOf("day"), "day")) return trainingStatus.ERROR;
  if (workoutsCount === rejectedWorkouts) return trainingStatus.ERROR;
  return trainingStatus.PROCESSING;
};

const EditTraineeWorkoutDay = () => {
  const { workoutEditModalShown, data, workoutEditModalTouched } = useAppSelector((state) => state.traineeActivities);
  const { selectedProgram } = useAppSelector((state) => state.traineeProgram);
  const dispatch = useAppDispatch();
  const [saving, toggleSaving] = useState(false);

  const closeModal = () => {
    dispatch(traineeActivitiesActions.setWorkoutEditModalHidden());
  };

  const handleClose = () => {
    if (workoutEditModalTouched) {
      const showConfirm = () => {
        Modal.confirm({
          title: "Czy na pewno chcesz zamknąć modal aktualizacji treningu?",
          content: "Wszelkie zmiany zostaną utracone!",
          okText: "Potwierdź",
          cancelText: "Anuluj",
          onOk: closeModal,
          onCancel() {},
        });
      };
      showConfirm();
    } else {
      closeModal();
    }
  };

  const renderTitle = () => {
    const momentDate = dayjs(data.currentDay);
    if (data.isNewWorkout) {
      return `Dodaj dzień treningowy - ${momentDate.format("DD-MM-YYYY")}`;
    }
    const trainingStatus = returnProperTrainingStatus(data.currentWorkout?.exercises || [], momentDate);
    return (
      <Space size={16}>
        <span>
          {data.currentWorkout?.name || "Dzień treningowy"} ({momentDate.format("DD-MM-YYYY")})
        </span>
        <Badge
          status={trainingStatus}
          text={trainingStatus ? trainingAlert[trainingStatus] : trainingAlert.processing}
        />
      </Space>
    );
  };

  const onSaveWorkout = async () => {
    toggleSaving(true);
    await dispatch(traineeActivitiesActions.saveWorkoutToSpecifiedDay());
    toggleSaving(false);
  };

  if (!selectedProgram || !data.currentWorkout) {
    return null;
  }

  return (
    <ModalForm
      open={workoutEditModalShown}
      onCancel={handleClose}
      title={renderTitle()}
      width={900}
      onSave={onSaveWorkout}
      destroyOnClose
      loading={saving}
    >
      <TraineeWorkoutDayProvider
        recordings={data.recordings ? keyBy(data.recordings, "parentExerciseId") : undefined}
        assignedProgramId={selectedProgram.id}
      >
        <WorkoutDayBuilder workout={data.currentWorkout} />
      </TraineeWorkoutDayProvider>
    </ModalForm>
  );
};

export default EditTraineeWorkoutDay;
