import React, { useState } from "react";
import { Card, Dropdown, Modal } from "antd";
import uniqBy from "lodash.uniqby";

import { type SupplementsTemplate } from "@fitness-app/data-models/entities/SupplementTemplate";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useFormHandler } from "~/hooks/useFormHandler";
import MealsPlanSuplementsList from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSupplements/MealsPlanSuplementsList";
import NutritionSupplementForm from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/NutritionSupplementForm/NutritionSupplementForm";
import { type NutritionSupplementFormModel } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/NutritionSupplementForm/types";
import SupplementsTemplates from "~/modules/Nutrition/SupplementsTemplates/SupplementsTemplates";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";

const MealsPlanSupplements = ({ readonly }: { readonly?: boolean }): React.ReactElement => {
  const { planDetails, planId, updateMealsPlan } = useMealsPlanContext();

  const { hideFormModal, showModalForm, openFormModal } = useFormHandler<NutritionSupplementFormModel>();
  const [processedItemId, setProcessedItemId] = useState<string | null>(null);
  const supplements = planDetails.supplements || [];
  const [showSupplementsTemplatesModal, toggleSupplementsTemplatesModal] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<SupplementsTemplate[]>([]);

  const removeSupplement = async (id: string) => {
    setProcessedItemId(id);
    const updatedSupplementsList = supplements.filter((item) => item.id !== id);
    await updateMealsPlan({
      mealsPlan: { id: planId },
      mealsPlanDetails: { id: planDetails.id, supplements: updatedSupplementsList },
    });
    setProcessedItemId(null);
  };

  const addSupplement = async (data: NutritionSupplementFormModel) => {
    setProcessedItemId("new");
    const updatedSupplementsList = supplements.concat({
      ...data,
      id: generateUniqId(),
    });
    await updateMealsPlan({
      mealsPlan: { id: planId },
      mealsPlanDetails: { id: planDetails.id, supplements: updatedSupplementsList },
    });
    setProcessedItemId(null);
    hideFormModal();
  };

  const onAddSupplementsFromTemplate = async () => {
    setProcessedItemId("new");
    const updatedSupplementsList = uniqBy(
      [...(planDetails.supplements || []), ...selectedTemplates.map((item) => item.supplements).flat()],
      "name",
    );

    await updateMealsPlan({
      mealsPlan: { id: planId },
      mealsPlanDetails: {
        id: planDetails.id,
        supplements: updatedSupplementsList,
        updatedAt: new Date().toISOString(),
      },
    });
    setProcessedItemId(null);
    toggleSupplementsTemplatesModal(false);
    setSelectedTemplates([]);
  };

  return (
    <Card
      extra={
        <Dropdown.Button
          type="primary"
          disabled={readonly}
          onClick={() => openFormModal()}
          menu={{
            items: [
              {
                key: "1",
                label: "Dodaj szablon suplementów",
              },
            ],
            onClick: () => toggleSupplementsTemplatesModal(true),
          }}
        >
          Dodaj nowy suplement
        </Dropdown.Button>
      }
      className="!shadow-none"
      bordered={false}
    >
      <MealsPlanSuplementsList
        editable={!readonly}
        supplements={supplements}
        processedItemId={processedItemId}
        removeSupplement={removeSupplement}
      />

      <ModalForm
        open={showModalForm}
        title="Dodaj suplement do diety"
        onCancel={hideFormModal}
        loading={Boolean(processedItemId)}
      >
        <NutritionSupplementForm onSubmit={addSupplement} />
      </ModalForm>

      <Modal
        bodyStyle={{
          padding: 0,
        }}
        cancelText="Anuluj"
        okText="Wybierz"
        width={950}
        confirmLoading={Boolean(processedItemId)}
        open={showSupplementsTemplatesModal}
        onCancel={() => {
          toggleSupplementsTemplatesModal(false);
          setSelectedTemplates([]);
        }}
        destroyOnClose
        okButtonProps={{
          disabled: !selectedTemplates?.length,
        }}
        onOk={onAddSupplementsFromTemplate}
      >
        <SupplementsTemplates
          selectMode
          selectionType="checkbox"
          onRowSelect={(selectedTemplates) => setSelectedTemplates(selectedTemplates)}
        />
      </Modal>
    </Card>
  );
};

export default MealsPlanSupplements;
