import React, { useState } from "react";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";

import TextAnswer from "~/components/SurveyDetails/SurveyAnswers/TextAnswer";

interface ABAnswerProps {
  disabled?: boolean;
  value?: string | boolean | null;
  extendedAnswer?: string | null;
  extendedAnswerA?: boolean;
  extendedAnswerB?: boolean;
}

const ABAnswer = ({ extendedAnswer, extendedAnswerA, extendedAnswerB, value, disabled = false }: ABAnswerProps) => {
  const { t } = useTranslation("surveys");
  const [controlledValue, setValue] = useState<undefined | 0 | 1>(undefined);
  const hasValue = value !== undefined;
  const props = hasValue || controlledValue !== undefined ? { value: value || controlledValue ? 1 : 0 } : {};
  const showTextArea = () => {
    if (extendedAnswer || (extendedAnswerA && extendedAnswerB)) {
      return true;
    }
    if (extendedAnswerA && controlledValue === 1) {
      return true;
    }
    return !!(extendedAnswerB && controlledValue === 0);
  };
  return (
    <div className="flex flex-col justify-center">
      <Radio.Group
        {...props}
        disabled={Boolean(value) || disabled}
        onChange={(e) => setValue(e.target.value as undefined | 0 | 1)}
        className="mx-auto my-1.5"
      >
        <Radio className="!text-gray-800" value={1}>
          {t("yes")}
        </Radio>
        <Radio className="!text-gray-800" value={0}>
          {t("no")}
        </Radio>
      </Radio.Group>
      {showTextArea() && (
        <div className="mt-2.5">
          <TextAnswer value={extendedAnswer || ""} disabled={Boolean(extendedAnswer)} />
        </div>
      )}
    </div>
  );
};

export default ABAnswer;
