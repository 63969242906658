import React from "react";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Popconfirm, Spin, Table } from "antd";

import { type Supplement } from "@fitness-app/data-models/entities/MealsPlan";

interface NutritionSupplementsListProps {
  supplements?: Supplement[] | null;
  editable?: boolean;
  removeSupplement?: (id: string) => void;
  processedItemId?: string | null;
}

const NutritionSupplementsList = ({
  supplements,
  editable = false,
  removeSupplement,
  processedItemId,
}: NutritionSupplementsListProps) => {
  return (
    <Table
      scroll={{ x: true }}
      rowKey="id"
      pagination={false}
      dataSource={supplements || []}
      locale={{
        emptyText: <div>Lista dodanych suplementów jest pusta</div>,
      }}
    >
      <Table.Column title="Nazwa" dataIndex="name" key="name" />
      <Table.Column title="Dawkowanie" dataIndex="dose" key="dose" />
      <Table.Column title="Komentarz" dataIndex="comment" key="comment" />
      <Table.Column
        title="Link do sklepu"
        dataIndex="url"
        key="url"
        render={(value: string | null) =>
          value ? (
            <a href={value} target="_blank" rel="noopener noreferrer">
              <ShoppingCartOutlined style={{ fontSize: 25 }} />
            </a>
          ) : (
            "-"
          )
        }
      />
      {editable && (
        <Table.Column<Supplement>
          title="Opcje"
          key="action"
          render={(_text, record) => (
            <Popconfirm
              title="Czy na pewno chcesz usunąc wybraną pozycje?"
              okText="Tak"
              cancelText="Nie"
              placement="left"
              onConfirm={() => removeSupplement?.(record.id)}
            >
              {record.id === processedItemId ? (
                <Spin size="small" />
              ) : (
                <a role="button" className="text-red-500" data-ctx="remove-supplement">
                  Usuń
                </a>
              )}
            </Popconfirm>
          )}
        />
      )}
    </Table>
  );
};

export default NutritionSupplementsList;
