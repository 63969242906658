import React, { useMemo } from "react";
import {
  DatabaseOutlined,
  DownOutlined,
  InfoCircleOutlined,
  TagOutlined,
  UnorderedListOutlined,
  UpOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Disclosure } from "@headlessui/react";
import { Tooltip, Typography } from "antd";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import {
  DiscordIntegrationTask,
  EmailClientTask,
  FitnessAppTask,
  ProductTask,
  TaskType,
  type DiscordIntegration,
} from "@fitness-app/data-models/entities/Automation";
import {
  ProductAutomationStatus,
  type DiscordTaskLog,
  type EmailTaskLog,
  type FitnessTaskLog,
  type ProductAutomationLog,
} from "@fitness-app/data-models/entities/ProductAutomationLog";

import { useAppSelector } from "~/store/initializeStore";

interface LogContentItemProps {
  log: ProductAutomationLog;
}

const emailIconMapper = {
  [EmailClientTask.AddTags]: <TagOutlined />,
  [EmailClientTask.RemoveTags]: <TagOutlined />,
  [EmailClientTask.AddToLists]: <UnorderedListOutlined />,
  [EmailClientTask.RemoveFromLists]: <UnorderedListOutlined />,
  [EmailClientTask.AddFieldValues]: <DatabaseOutlined />,
};

const discordIconMapper = {
  [DiscordIntegrationTask.AddToServer]: <UserAddOutlined />,
  [DiscordIntegrationTask.RemoveFromServer]: <UserDeleteOutlined />,
  [DiscordIntegrationTask.AddRole]: <UserSwitchOutlined />,
  [DiscordIntegrationTask.RemoveRole]: <UserSwitchOutlined />,
};

const fitnessAppMapper = {
  [FitnessAppTask.AddClientToApp]: <UserAddOutlined />,
  [FitnessAppTask.RemoveClientFromApp]: <UserDeleteOutlined />,
  [FitnessAppTask.AddProgramAutomationToClient]: <UserSwitchOutlined />,
};
const LogContentItem = ({ log }: LogContentItemProps) => {
  const { t } = useTranslation(["products", "common"]);
  const serversById: Record<string, DiscordIntegration> = {};
  const products = useAppSelector((store) => store.products.productsListData);

  const byId = useMemo(() => keyBy(products, "id"), [products]);

  const renderErrorMessage = (log: ProductAutomationLog) => {
    if (log.status === ProductAutomationStatus.Error) {
      const title = (
        <div>
          <p>
            {t("automationLogs.errorMessage")}: <b>{log.errorMessage}</b>
          </p>
          <p>
            {t("automationLogs.errorCode")}: <b>{log.errorCode}</b>
          </p>
        </div>
      );

      return (
        <Tooltip title={title} color="red">
          <Typography.Text type="danger">
            <InfoCircleOutlined className="mr-1" /> {t("automationLogs.additionalInfo")}
          </Typography.Text>
        </Tooltip>
      );
    }
    return null;
  };

  const renderWarningMessage = (log: ProductAutomationLog) => {
    if (log.status === ProductAutomationStatus.Warning) {
      const title = (
        <div>
          <p>
            {t("automationLogs.requireAction.owner.nextStepOwner")}:{" "}
            <b>{t(`automationLogs.requireAction.owner.${log.requireAction?.nextStepOwner}`)}</b>
          </p>
          <p>
            {t("automationLogs.requireAction.title")}:{" "}
            <b>{t(`automationLogs.requireAction.status.${log.requireAction?.status}`)}</b>
          </p>
        </div>
      );

      return (
        <Tooltip title={title} color="orange">
          <Typography.Text type="warning">
            <InfoCircleOutlined className="mr-1" /> {t("automationLogs.additionalInfo")}
          </Typography.Text>
        </Tooltip>
      );
    }
    return null;
  };

  const fitnessAppTaskAction = (task: FitnessTaskLog) => {
    if (task.action.type === FitnessAppTask.AddClientToApp || task.action.type === FitnessAppTask.RemoveClientFromApp) {
      return (
        <div className="mb-2">
          {fitnessAppMapper[task.action.type]} {t("automationLogs.programName")}:
          {task.action.type === FitnessAppTask.AddClientToApp || task.action.type === FitnessAppTask.RemoveClientFromApp
            ? ` ${task.action.programAutomation?.name}  ${
                task.action.accessType ? `(${t(`common:accessType.${task.action.accessType}`)})` : ""
              }`
            : "-"}
        </div>
      );
    }

    if (task.action.type === FitnessAppTask.AddProgramAutomationToClient) {
      return (
        <div className="mb-2">
          {fitnessAppMapper[task.action.type]} {t("automationLogs.programName")}: {task.action.programAutomation.name}
        </div>
      );
    }

    return null;
  };

  const discordTaskAction = (task: DiscordTaskLog) => {
    if (
      task.action.type === DiscordIntegrationTask.AddToServer ||
      task.action.type === DiscordIntegrationTask.RemoveFromServer
    ) {
      return (
        <div className="mb-2">
          {discordIconMapper[task.action.type]} {t("automationLogs.server")}:{" "}
          {serversById[task.action.serverId]
            ? `${serversById[task.action.serverId]?.guild.name} (${task.action.serverId})`
            : task.action.serverId}
        </div>
      );
    }

    if (task.action.type === DiscordIntegrationTask.AddRole || task.action.type === DiscordIntegrationTask.RemoveRole) {
      return (
        <div className="mb-2">
          {discordIconMapper[task.action.type]} {t("automationLogs.role")}: {task.action.roleName}(
          {serversById[task.action.serverId]?.guild.name || task.action.serverId})
        </div>
      );
    }

    return null;
  };

  const emailTaskAction = (task: EmailTaskLog) => {
    if (task.action.type === EmailClientTask.AddTags || task.action.type === EmailClientTask.RemoveTags) {
      return (
        <ul className="my-2">
          {task.action.tags.map((tag) => (
            <li key={tag}>
              {emailIconMapper[task.action.type]}
              <span className="ml-2">{tag}</span>
            </li>
          ))}
        </ul>
      );
    }

    if (task.action.type === EmailClientTask.AddToLists || task.action.type === EmailClientTask.RemoveFromLists) {
      return (
        <ul className="my-2">
          {task.action.emailLists.map((list) => (
            <li key={list.name}>
              {emailIconMapper[task.action.type]}
              <span className="ml-2">{list.name}</span>
            </li>
          ))}
        </ul>
      );
    }

    if (task.action.type === EmailClientTask.AddFieldValues) {
      return (
        <ul className="mb-2">
          {Object.entries(task.action.values).map(([key, value]) => (
            <li key={key}>
              {emailIconMapper[task.action.type]}
              <span className="ml-2">
                {key}: {value}
              </span>
            </li>
          ))}
        </ul>
      );
    }

    return null;
  };

  if (log.task.type === TaskType.EmailClient) {
    const task = log.task;
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="pb-2">
              <span>{t(`common:${log.task.type}.${log.task.action?.type}`)}</span>
              {open ? <UpOutlined className="ml-2" /> : <DownOutlined className="ml-2" />}
            </Disclosure.Button>
            <Disclosure.Panel>
              <p>
                {t("automationLogs.account")}: {task.emailClient} - {task.accountId}
              </p>
              {emailTaskAction(task)}
              {renderErrorMessage(log)}
              {renderWarningMessage(log)}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  if (log.task.type === TaskType.DiscordIntegration) {
    const task = log.task;
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="pb-2">
              <span>{t(`common:${log.task.type}.${log.task.action?.type}`)}</span>
              {open ? <UpOutlined className="ml-2" /> : <DownOutlined className="ml-2" />}
            </Disclosure.Button>
            <Disclosure.Panel>
              {discordTaskAction(task)}
              {renderErrorMessage(log)}
              {renderWarningMessage(log)}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  if (log.task.type === TaskType.FitnessApp) {
    const task = log.task;
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="pb-2">
              <span>{t(`common:${log.task.type}.${log.task.action?.type}`)}</span>
              {open ? <UpOutlined className="ml-2" /> : <DownOutlined className="ml-2" />}
            </Disclosure.Button>
            <Disclosure.Panel>
              {fitnessAppTaskAction(task)}
              {renderErrorMessage(log)}
              {renderWarningMessage(log)}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  if (!log.task.action) {
    return null;
  }

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="pb-2">
            <span>{t(`common:${log.task.type}.${log.task.action?.type}`)}</span>
            {open ? <UpOutlined className="ml-2" /> : <DownOutlined className="ml-2" />}
          </Disclosure.Button>
          <Disclosure.Panel>
            {log.task.type === TaskType.ProductTask && log.task.action?.type === ProductTask.ImportIntoProduct && (
              <span>
                {t("importedTo")}{" "}
                {log.task.action.products.map((p) => byId[p.productId]?.name || p.productId).join(",")}
              </span>
            )}
            {log.task.type === TaskType.ProductTask && log.task.action.type === ProductTask.RemoveFromProduct && (
              <span>
                {t("removedFrom")}{" "}
                {log.task.action.products.map((p) => byId[p.productId]?.name || p.productId).join(",")}
              </span>
            )}
            {renderErrorMessage(log)}
            {renderWarningMessage(log)}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default LogContentItem;
