import React from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { useTranslation } from "react-i18next";

import {
  type ExerciseInWorkout,
  type ExerciseSet as IExerciseSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

import {
  createExerciseInfo,
  formatDuration,
} from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/helpers";

interface ExerciseSetProps {
  data: ExerciseInWorkout & { orderKey: number; isSuperSet?: false };
  addSeriesToExercise: () => void;
  removeSeriesFromExercise: (setNumber: number) => void;
  updateExerciseInWorkout: (exercise: ExerciseInWorkout) => void;
}

const ExerciseSet = ({
  data,
  addSeriesToExercise,
  removeSeriesFromExercise,
  updateExerciseInWorkout,
}: ExerciseSetProps) => {
  const { t } = useTranslation("workouts");

  const setArray: (IExerciseSet & { key: string; setNumber: number })[] = Object.keys(data.set).map((key) => ({
    ...data.set[key],
    repeats: data.set[key]?.repeats ?? 0,
    weight: data.set[key]?.weight ?? 0,
    key,
    setNumber: Number(key),
  }));

  return (
    <div className="flex flex-col gap-4 py-4">
      <Table dataSource={setArray} rowKey="key" pagination={false} size="small">
        <Table.Column title="Seria" dataIndex="setNumber" key="setNumber" />
        <Table.Column<IExerciseSet & { key: string; setNumber: number }>
          title="Powtórzenia"
          dataIndex="repeats"
          key="repeats"
          render={(repeats: IExerciseSet["repeats"]) => createExerciseInfo(repeats)}
        />
        <Table.Column<IExerciseSet & { key: string; setNumber: number }>
          title="Obciąż./Czas trwania"
          dataIndex="weight"
          key="weight"
          render={(weight: IExerciseSet["weight"], record) => (
            <div className="flex gap-x-2">
              <div>{createExerciseInfo(weight, " kg")}</div>
              {record.duration?.value && (
                <>
                  <span className="text-gray-700">|</span>
                  <div>
                    {createExerciseInfo(formatDuration(record.duration), ` ${t(`duration.${record.duration.format}`)}`)}
                  </div>
                </>
              )}
            </div>
          )}
        />
        <Table.Column<IExerciseSet & { key: string; setNumber: number }>
          title="Opcje"
          dataIndex="action"
          key="action"
          render={(_, row) => (
            <Space>
              <Button size="small" icon={<EditOutlined />} type="text" onClick={() => updateExerciseInWorkout(data)} />
              {setArray.length > 1 && (
                <Button
                  size="small"
                  icon={<DeleteOutlined className="text-red-500" />}
                  danger
                  type="text"
                  onClick={() => removeSeriesFromExercise(row.setNumber)}
                />
              )}
            </Space>
          )}
        />
      </Table>

      <div>
        <Button size="small" icon={<PlusOutlined />} type="dashed" onClick={addSeriesToExercise}>
          {t("exerciseInProgram.addSet")}
        </Button>
      </div>
    </div>
  );
};

export default ExerciseSet;
