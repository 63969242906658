import React from "react";

import { MACROS_COLORS } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineePdfGenerator/components/shared";
import MacroTracker from "./MacroTracker";

interface MacrosNeeds {
  macros: {
    protein: number;
    carbs: number;
    fat: number;
  };
  calories: number;
}

interface NutritionListProps {
  caloriesValue: number;
  carbsValue: number;
  fatValue: number;
  proteinValue: number;
  macrosNeeds: MacrosNeeds;
}

const calculateMacroValuesToPercentages = (value: number, needs: number) => Math.floor((value * 100) / needs);

const NutritionDayTargets: React.FC<NutritionListProps> = ({
  caloriesValue,
  carbsValue,
  fatValue,
  proteinValue,
  macrosNeeds: { macros, calories },
}) => (
  <div>
    <MacroTracker
      color={MACROS_COLORS.calories}
      percentValue={calculateMacroValuesToPercentages(caloriesValue, calories)}
      title="Kalorie"
      nutritionValue={caloriesValue}
      dailyNeeds={calories}
      unit="kcal"
      className="font-medium"
    />
    <div className="flex flex-1 gap-x-4">
      <MacroTracker
        color={MACROS_COLORS.protein}
        percentValue={calculateMacroValuesToPercentages(proteinValue, macros.protein)}
        title="B:"
        nutritionValue={proteinValue}
        dailyNeeds={macros.protein}
        unit="g"
        className="flex-1 font-medium"
      />
      <MacroTracker
        color={MACROS_COLORS.carbs}
        percentValue={calculateMacroValuesToPercentages(carbsValue, macros.carbs)}
        title="W:"
        nutritionValue={carbsValue}
        dailyNeeds={macros.carbs}
        unit="g"
        className="flex-1 font-medium"
      />
      <MacroTracker
        color={MACROS_COLORS.fat}
        percentValue={calculateMacroValuesToPercentages(fatValue, macros.fat)}
        title="T:"
        nutritionValue={fatValue}
        className="flex-1 font-medium"
        dailyNeeds={macros.fat}
        unit="g "
      />
    </div>
  </div>
);

export default NutritionDayTargets;
