import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  searchTerm: string;
  selectedFilter?: "all" | "dishes" | "ingredients";
};

export const searchDishOrIngredient = createAsyncThunk<
  {
    dishes: { data: Dish[]; page: number; totalPages: number };
    ingredients: { data: Ingredient[]; page: number; totalPages: number };
  },
  Payload,
  AsyncThunkCreator<string>
>(
  `${NUTRITION_REDUCER_NAME}/searchDishOrIngredient`,
  async (
    { searchTerm },
    { rejectWithValue, getState, extra: { dishesSearchIndex, ingredientsSearchIndex, searchIndex } },
  ) => {
    try {
      const { dishesPerPage, ingredientsPerPage } = getState().nutrition;

      const response = await searchIndex.multiSearch({
        queries: [
          { indexUid: dishesSearchIndex.uid, q: searchTerm, limit: dishesPerPage },
          {
            indexUid: ingredientsSearchIndex.uid,
            q: searchTerm,
            limit: ingredientsPerPage,
            attributesToCrop: ["kcalmar"],
            sort: searchTerm ? ["rating:desc", "source:desc"] : undefined,
          },
        ],
      });

      const dishesResults = response.results.find((result) => result.indexUid === dishesSearchIndex.uid);
      const ingredientsResults = response.results.find((result) => result.indexUid === ingredientsSearchIndex.uid);

      return {
        dishes: {
          data: dishesResults ? (dishesResults.hits as Dish[]) : [],
          page: dishesResults?.offset ? Math.ceil(dishesResults.offset / dishesPerPage) + 1 : 1,
          totalPages: dishesResults?.estimatedTotalHits
            ? Math.ceil(dishesResults?.estimatedTotalHits / dishesPerPage)
            : 1,
        },
        ingredients: {
          data: ingredientsResults ? (ingredientsResults.hits as Ingredient[]) : [],
          page: ingredientsResults?.offset ? Math.ceil(ingredientsResults.offset / ingredientsPerPage) + 1 : 1,
          totalPages: ingredientsResults?.estimatedTotalHits
            ? Math.ceil(ingredientsResults?.estimatedTotalHits / ingredientsPerPage)
            : 1,
        },
      };
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);
