import React, { useMemo } from "react";
import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined } from "@ant-design/icons";

import { type Meal } from "@fitness-app/data-models/entities/MealsPlan";
import { calculateTargetFulfill } from "@fitness-app/utils/src/nutrition/calculateTargetFulfill";
import { getMealsPlanTargets } from "@fitness-app/utils/src/nutrition/getMealsPlanTargets";
import { reduceMealsNutrients } from "@fitness-app/utils/src/nutrition/reduceMealsNutrients";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";

interface MealDaySummaryProps {
  meals: Meal[];
  overrides?: Record<
    string,
    {
      protein: number;
      carbs: number;
      fat: number;
      calories: number;
    }
  >;
}

const iconMapper = {
  ok: <CheckOutlined />,
  tooLow: <ArrowDownOutlined />,
  tooHigh: <ArrowUpOutlined />,
};

const getDirection = ({ current, target }: { current: number; target: number }) => {
  const percentage = calculateTargetFulfill(current, target);

  if (percentage >= 90 && percentage <= 110) {
    return "ok";
  }
  if (percentage < 90) {
    return "tooLow";
  }
  return "tooHigh";
};

const getTargetStatus = ({ current, target }: { current: number; target: number }) => {
  const percentage = calculateTargetFulfill(current, target);

  if (percentage >= 90 && percentage <= 110) {
    return "ok";
  }
  if ((percentage < 90 && percentage > 50) || (percentage > 110 && percentage < 150)) {
    return "close";
  }
  return "small";
};

const NutrientRow = ({
  current,
  target,
  children,
  unit = "g",
}: {
  unit?: string;
  current: number;
  target: number;
  children: React.ReactNode;
}) => {
  const status = getTargetStatus({ current, target });
  return (
    <>
      <span>{children}</span>
      <span
        className={cn(
          "font-medium",
          status === "ok" && "text-green-500",
          status === "close" && "text-amber-500",
          status === "small" && "text-red-500",
        )}
      >
        {current}
      </span>
      <span>
        / {target} {unit}
        <span
          className={cn(
            "ml-2",
            status === "ok" && "text-green-500",
            status === "close" && "text-amber-500",
            status === "small" && "text-red-500",
          )}
        >
          {iconMapper[getDirection({ current, target })]}
        </span>
      </span>
    </>
  );
};

const MealDaySummary = ({ meals, overrides }: MealDaySummaryProps) => {
  const { calories, fat, protein, carbs } = useMemo(
    () => reduceMealsNutrients(meals, false, overrides),
    [meals, overrides],
  );

  const { plan: selectedMealsPlan } = useMealsPlanContext();
  const targets = useMemo(() => getMealsPlanTargets(selectedMealsPlan), [selectedMealsPlan]);

  if (!selectedMealsPlan) {
    return null;
  }

  return (
    <div className="flex flex-col px-2">
      <div className="grid auto-cols-min grid-cols-[25px_50px_2fr]">
        <NutrientRow current={protein} target={targets.macros.protein}>
          B:
        </NutrientRow>
        <NutrientRow current={carbs} target={targets.macros.carbs}>
          W:
        </NutrientRow>
        <NutrientRow current={fat} target={targets.macros.fat}>
          T:
        </NutrientRow>
        <NutrientRow current={calories} target={targets.calories} unit="kcal">
          K:
        </NutrientRow>
      </div>
    </div>
  );
};

export default MealDaySummary;
