import React, { type FunctionComponent } from "react";
import { CheckOutlined, DeleteOutlined, TagOutlined } from "@ant-design/icons";
import { Button, Popover, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { EmailClientTask } from "@fitness-app/data-models/entities/Automation";
import { type ScheduledEmailIntegrationAutomation } from "@fitness-app/data-models/entities/AutomationTemplate";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";

import { getMinutesWithLeadingZero } from "~/modules/Automation/utils/getMinutesWithLeadingZero";

interface OwnProps {
  item: ScheduledEmailIntegrationAutomation;
  onRemove: () => void;
  status?: ProgramAutomationTaskStatus;
}

type Props = OwnProps;

const EmailClientTaskItem: FunctionComponent<Props> = ({ item, onRemove, status }) => {
  const { t } = useTranslation("automation");
  const minutesWithLeadingZero = getMinutesWithLeadingZero(item.sentTime.minutes);

  return (
    <Popover
      title="Zadania powiązane z listą e-mailową"
      content={
        <Space direction="vertical">
          <Space>
            <Typography.Text type="secondary">Typ zadania:</Typography.Text>
            <Typography.Text>{t(`common:emailClientTask.${item.action.type}`)}</Typography.Text>
          </Space>
          {item.action.type === EmailClientTask.AddTags ||
            (item.action.type === EmailClientTask.RemoveTags && (
              <Space>
                <Typography.Text type="secondary">Tagi</Typography.Text>
                <Typography.Text>{item.action.tags.join(", ")}</Typography.Text>
              </Space>
            ))}
          {(item.action.type === EmailClientTask.AddTags || item.action.type === EmailClientTask.RemoveTags) && (
            <Space>
              <Typography.Text type="secondary">Tagi:</Typography.Text>
              <Typography.Text>{item.action.tags.join(", ")}</Typography.Text>
            </Space>
          )}
          {(item.action.type === EmailClientTask.AddToLists ||
            item.action.type === EmailClientTask.RemoveFromLists) && (
            <Space>
              <Typography.Text type="secondary">Powiązane listy</Typography.Text>
              <Typography.Text>
                {item.action.emailLists.map((item) => `${item.client} - ${item.account} - ${item.name}`).join(", ")}
              </Typography.Text>
            </Space>
          )}
          {item.action.type === EmailClientTask.AddFieldValues && (
            <Space>
              <Typography.Text type="secondary">Powiązane konta</Typography.Text>
              <Typography.Text>
                {item.action.emailLists.map((item) => `${item.client} - ${item.account}`).join(", ")}
              </Typography.Text>
            </Space>
          )}
          {(item.action.type === EmailClientTask.RemoveTags || item.action.type === EmailClientTask.AddTags) && (
            <Space>
              <Typography.Text type="secondary">Powiązane listy</Typography.Text>
              <Typography.Text>{item.action.emailClients.map((item) => `${item.client}`).join(", ")}</Typography.Text>
            </Space>
          )}
          {item.action.type === EmailClientTask.AddFieldValues && (
            <Space>
              <Typography.Text type="secondary">Pola</Typography.Text>
              <Typography.Text>{JSON.stringify(item.action.values)}</Typography.Text>
            </Space>
          )}
          <Space>
            <Typography.Text type="secondary">Czas operacji</Typography.Text>
            <Typography.Text>
              {item.sentTime.hours}:{minutesWithLeadingZero}
            </Typography.Text>
          </Space>
          <Button danger icon={<DeleteOutlined />} type="text" onClick={onRemove} />
        </Space>
      }
    >
      <TagOutlined style={{ marginRight: 8 }} />
      <span>
        {item.sentTime.hours}:{minutesWithLeadingZero}
      </span>

      {status === ProgramAutomationTaskStatus.Done && <CheckOutlined className="ml-2 text-green-500" />}
    </Popover>
  );
};

export default EmailClientTaskItem;
