import { type Database } from "@fitness-app/supabase";

import {
  type DietaryPreference,
  type MacroSplit,
  type MealPlansTargets,
  type MealsPerDay,
  type NutritionAttachment,
  type NutritionPlanDay,
  type Supplement,
} from "./MealsPlan";

export type ClientMealsPlanEntity = Database["public"]["Tables"]["client_meals_plan"]["Row"];
export type ClientMealsPlanDetailsEntity = Database["public"]["Tables"]["client_meals_plan_details"]["Row"];

export interface ClientMealsPlanDetails extends Omit<ClientMealsPlanDetailsEntity, "supplements" | "weeks"> {
  id: string;
  createdAt: string;
  updatedAt: string;
  planId: string;
  supplements: null | Supplement[];
  weeks: { weekNumber: number; days: NutritionPlanDay[] }[];
}

export enum ClientNutritionStatus {
  Scheduled = "scheduled",
  InProgress = "in_progress",
  Archived = "archived",
  Template = "template",
}

export interface ClientNutrition
  extends Omit<
    ClientMealsPlanEntity,
    "attachments" | "targets" | "dietaryPreference" | "macroSplit" | "mealsSchema" | "metadata"
  > {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  dietaryPreference: DietaryPreference;
  macroSplit: MacroSplit;
  comment: string;
  targetCalories: number;
  startAt: string;
  targets: MealPlansTargets;
  attachments: NutritionAttachment[];
  mealsSchema: MealsPerDay;
  status:
    | ClientNutritionStatus.Scheduled
    | ClientNutritionStatus.InProgress
    | ClientNutritionStatus.Archived
    | ClientNutritionStatus.Template;
  tags: string[] | null;
  metadata: null | Record<string, string>;
}

export interface ClientNutritionTemplate extends Omit<ClientNutrition, "status" | "startAt"> {
  status: ClientNutritionStatus.Template;
  startAt: null;
}

export type ClientNutritionAll = ClientNutrition | ClientNutritionTemplate;
