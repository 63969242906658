/* eslint-disable */
// @ts-nocheck
import { groupBy, isEmpty, map, sortBy } from "lodash";

import {
  TrainingStatus,
  type ExerciseInWorkout,
  type SuperSet,
  type WithMetadata,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";

export const mergeExerciseWithSuperSeries = (
  program: ExerciseInWorkout[],
  idKey = "id",
  type: WorkoutExercisesType,
  workoutId: string,
  isRestDay?: boolean,
): ((ExerciseInWorkout & WithMetadata) | (SuperSet & WithMetadata))[] => {
  if (!program || isEmpty(program)) {
    return [];
  }
  const withOrderKey = program.map((item, i) => ({ ...item, orderKey: i }));
  const firstId = program[0]?.id;
  const grouped = groupBy(withOrderKey, (item) => item.superSetId || item[idKey] || item.orderKey);
  const groupedArr = map(grouped, (item, key) => {
    if (item.length === 1) {
      // if is not superset
      if (item[0] && key === firstId) {
        return {
          ...item[0],
          title: type,
          workoutId,
          exercisesType: type,
          isRestDay,
        };
      }
      return {
        ...item[0],
        workoutId,
        exercisesType: type,
      };
    }
    const mapExerciseStatusToSuperSetStatus = (exercisesToMerge: ExerciseInWorkout[]): TrainingStatus => {
      if (exercisesToMerge.some((exercise) => exercise.status && exercise.status !== TrainingStatus.NEW)) {
        const numberOfExercises = exercisesToMerge.length;
        const fulfilledExercises = exercisesToMerge.filter((exercise) => exercise.status === TrainingStatus.FULFILLED);
        if (numberOfExercises === fulfilledExercises.length) {
          return TrainingStatus.FULFILLED;
        }
        const rejectedExercises = exercisesToMerge.filter((exercise) => exercise.status === TrainingStatus.REJECTED);
        if (numberOfExercises === rejectedExercises.length) {
          return TrainingStatus.REJECTED;
        }
        return TrainingStatus.PARTIALLY_FULFILLED;
      }
      return TrainingStatus.NEW;
    };
    const { numberOfRepeats } = item[0];
    let aggregateNumberOfRepeats = null;
    const everySeriesHasFlatRepeats = item.every((exercise) => typeof exercise.numberOfRepeats === "number");

    if (everySeriesHasFlatRepeats) {
      aggregateNumberOfRepeats = item.reduce((prev, curr) => prev + (curr.numberOfRepeats as number), 0);
    }

    return {
      isSuperSet: true,
      orderKey: item[0].orderKey,
      superSetId: item[0].superSetId,
      key: item[0].superSetId,
      id: item[0].superSetId,
      aggregateNumberOfRepeats,
      numberOfSeries: item[0].numberOfSeries,
      numberOfRepeats,
      exercisesType: type,
      mergedExercises: item,
      mergedExercisesIds: item.map((exercise) => exercise[idKey]),
      status: mapExerciseStatusToSuperSetStatus(item),
      ...(item[0].id === firstId
        ? { title: type, workoutId, exercisesType: type }
        : { workoutId, exercisesType: type }),
    } as SuperSet & WithMetadata;
  });

  return sortBy(groupedArr, "orderKey");
};
