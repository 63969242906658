import { Progress, Space, Typography } from "antd";

import { type WeekActivities } from "@fitness-app/data-models/entities/ClientActivity";

const WorkoutWeekProgress = ({ weekOfActivities, label }: { weekOfActivities: WeekActivities; label: string }) => {
  if (!weekOfActivities) {
    return null;
  }

  const total = weekOfActivities.activitiesScheduled ?? weekOfActivities.activitiesCompleted;

  return (
    <Space direction="vertical">
      <Typography.Text type="secondary" strong>
        {label}
      </Typography.Text>
      <Progress
        type="circle"
        percent={Math.round((weekOfActivities.activitiesCompleted / total) * 100)}
        width={60}
        format={() => `${weekOfActivities.activitiesCompleted} / ${total}`}
      />
    </Space>
  );
};

export default WorkoutWeekProgress;
