import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DiscordIntegrationTask } from "@fitness-app/data-models/entities/Automation";

import { useAppSelector } from "~/store/initializeStore";

export const useDiscordIntegration = () => {
  const productAutomation = useAppSelector((store) => store.productAutomation.data);
  const discordIntegration = useMemo(() => {
    return productAutomation?.productIntegrations?.discord ?? [];
  }, [productAutomation]);

  const { t } = useTranslation(["common", "products"]);

  const discordAutomationTaskOptions = useMemo(() => {
    if (!discordIntegration.length) {
      return [];
    }

    return Object.values(DiscordIntegrationTask).map((value) => ({
      label: t(`common:discordIntegrationTask.${value}`),
      value,
    }));
  }, [discordIntegration, t]);

  return {
    discordIntegration,
    discordAutomationTaskOptions,
  };
};
