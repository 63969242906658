"use client";

import * as React from "react";
import { message } from "antd";
import * as tus from "tus-js-client";

import { mediaLibraryActions } from "@fitness-app/app-store";
import { UnreachableCaseError } from "@fitness-app/utils/src/errors/UnreachableError";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";
import { normalizeFileName } from "@fitness-app/utils/src/upload/normalizeFileName";

import i18nextConfig from "~/i18nextConfig";
import { supabase, useAppDispatch } from "~/store/initializeStore";
import { createFileShortcutName } from "./helpers/createFileShorcutName";
import { getExtension } from "./helpers/getExtension";
import { uploadValidator } from "./helpers/uploadValidator";
import {
  DEFAULT_LABELS,
  FileValidationError,
  UploadError,
  UploadFileStatus,
  type FileData,
  type UploadFilesContextInterface,
  type UploadProviderProps,
  type UploadValidatorErrorResult,
} from "./types";

const UploadFilesContext = React.createContext<UploadFilesContextInterface | undefined>(undefined);

const useUploadFilesContext = (): UploadFilesContextInterface => {
  const uploadFilesContext = React.useContext(UploadFilesContext);

  if (!uploadFilesContext) {
    throw new Error("No UploadFilesContext.Provider found when calling useUploadFilesContext.");
  }

  return uploadFilesContext;
};

const EXPIRES_IN_20_YEARS = 631138519;

const getErrorTranslation = (validatorResult: UploadValidatorErrorResult) => {
  switch (validatorResult.error) {
    case FileValidationError.MAX_FILE_SIZE:
      return i18nextConfig.t("common:validationErrors.maxFileSize", {
        size: validatorResult.maxFileSize,
      });
    case FileValidationError.MAX_FILES_LIST_LENGTH:
      return i18nextConfig.t("common:validationErrors.maxFilesListLength", {
        length: validatorResult.maxFilesListLength,
      });
    case FileValidationError.MAX_FILES_LIST_SIZE:
      return i18nextConfig.t("common:validationErrors.maxFilesListSize", {
        size: validatorResult.maxFilesListSize,
      });
    case FileValidationError.WRONG_EXTENSION:
      return i18nextConfig.t("common:validationErrors.wrongExtension", {
        ext: validatorResult.extension,
      });
    case FileValidationError.WRONG_MIME_TYPE:
      return i18nextConfig.t("common:validationErrors.wrongMimeType", {
        types: validatorResult.allowedMimeTypes.join(", "),
      });
    default:
      throw new UnreachableCaseError(validatorResult);
  }
};

const UploadFilesProvider = ({
  children,
  onSuccess,
  onError,
  onRemove,
  validatorConfig,
  labels = DEFAULT_LABELS,
  storageConfig,
}: UploadProviderProps): React.ReactElement => {
  const [files, setFiles] = React.useState<FileData[]>([]);
  const filesQueue = React.useRef<Record<string, () => void>>({}).current;
  const notifiedFiles = React.useRef<string[]>([]);
  const dispatch = useAppDispatch();

  const onFileUpload = React.useCallback(
    async (file: File & { duration?: number }, multiple = true, convertToMp3?: boolean) => {
      const fileData: FileData = {
        uid: generateUniqId(),
        bytesTransferred: 0,
        shortName: createFileShortcutName(file.name, 30),
        originalName: file.name,
        status: UploadFileStatus.UPLOADING,
        extension: getExtension(file.name),
        contentType: file.type,
        size: file.size,
        duration: "duration" in file && file.duration ? file.duration : null,
        progress: null,
        thumbUrl: null,
      };
      const fileId = fileData.uid;
      const validationError = uploadValidator(fileData, files, validatorConfig);

      if (validationError) {
        const translatedError = getErrorTranslation(validationError);

        fileData.status = UploadFileStatus.ERROR;
        fileData.errorMessage = translatedError;

        void message.error(translatedError);

        setFiles((prev) => (multiple ? [...prev, fileData] : [fileData]));
        onError?.(fileData, validationError);
        return;
      }

      setFiles((prev) => (multiple ? [...prev, fileData] : [fileData]));

      const ref = `https://${import.meta.env.VITE_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/upload/resumable`;
      const token =
        (await supabase.auth.getSession())?.data.session?.access_token || import.meta.env.VITE_SUPABASE_ANON_KEY;

      const objectName = `${storageConfig.objectPath}/${normalizeFileName(file.name)}`;

      const upload: tus.Upload = new tus.Upload(file, {
        endpoint: ref,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        headers: {
          authorization: `Bearer ${token}`,
          "x-upsert": "true", // optionally set upsert to true to overwrite existing files
        },
        uploadDataDuringCreation: true,
        metadata: {
          bucketName: storageConfig.bucketName || "shared",
          objectName,
          contentType: file.type,
          cacheControl: "3600",
          duration: file.duration ? String(file.duration) : "",
        },
        chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
        onError: (error) => rejected(error),
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = (bytesUploaded / bytesTotal) * 100;
          filesQueue[fileId] = () => void upload.abort?.(true);
          setFiles((prev) => [
            ...prev.map((savedFile) => {
              if (savedFile.uid !== fileId) {
                return savedFile;
              }
              return {
                ...savedFile,
                progress: Math.round(percentage),
              };
            }),
          ]);
        },
        onSuccess: () => void resolved(upload),
      });

      upload.start();

      const resolved = async (data?: tus.Upload): Promise<boolean> => {
        if (!data) {
          return false;
        }

        let url = data.url || "";
        let thumbUrl = fileData.thumbUrl || null;

        if (convertToMp3) {
          try {
            const { path } = await dispatch(
              mediaLibraryActions.convertFileToMp3({
                id: fileId,
                name: fileData.originalName,
                bucketName: storageConfig.bucketName,
                path: objectName,
              }),
            ).unwrap();

            const { data, error } = await supabase.storage
              .from(storageConfig.bucketName)
              .createSignedUrl(path || objectName, EXPIRES_IN_20_YEARS);
            if (error) {
              return rejected(error);
            }
            url = data.signedUrl;
          } catch {
            // do nothing
          }
        } else if (storageConfig.isPublicBucket) {
          const {
            data: { publicUrl },
          } = supabase.storage.from(storageConfig.bucketName).getPublicUrl(objectName);

          url = publicUrl || data.url || "";
        } else {
          const isImage = fileData?.contentType.includes("image");
          const { data, error } = await supabase.storage.from(storageConfig.bucketName).createSignedUrl(
            objectName,
            EXPIRES_IN_20_YEARS,
            isImage
              ? {
                  transform: {
                    format: "origin",
                    width: 1200,
                    resize: "contain",
                  },
                }
              : undefined,
          );

          if (error) {
            return rejected(error);
          }

          if (isImage) {
            const { data } = await supabase.storage.from(storageConfig.bucketName).createSignedUrl(
              objectName,
              EXPIRES_IN_20_YEARS,
              isImage
                ? {
                    transform: {
                      width: 200,
                      height: 200,
                      quality: 50,
                      resize: "cover",
                    },
                  }
                : undefined,
            );

            thumbUrl = data?.signedUrl || null;
          }
          url = data.signedUrl;
        }

        setFiles((prev) => [
          ...prev.map((savedFile) => {
            if (savedFile.uid !== fileId) {
              return savedFile;
            }
            const updatedFile = {
              ...savedFile,
              url: url || data.url || "",
              thumbUrl,
              status: UploadFileStatus.SUCCESS,
            };

            if (savedFile.status !== UploadFileStatus.SUCCESS && !notifiedFiles.current.includes(savedFile.uid)) {
              onSuccess?.(updatedFile);
              // avoid duplicated invocation of callback
              notifiedFiles.current.push(savedFile.uid);
            }

            return updatedFile;
          }),
        ]);
        return true;
      };

      const rejected = (error: string | Error): boolean => {
        if (error instanceof Error) {
          if (error.message === UploadError.CANCELED) {
            return false;
          }
          setFiles((prev) => [
            ...prev.map((savedFile) => {
              if (savedFile.uid !== fileId) {
                return savedFile;
              }
              const updatedFile = {
                ...savedFile,
                status: UploadFileStatus.ERROR,
                errorMessage: error.message,
              };
              onError?.(updatedFile, { error: UploadError.FAILED_UPLOAD });
              return updatedFile;
            }),
          ]);
          return false;
        }

        setFiles((prev) => [
          ...prev.map((savedFile) => {
            if (savedFile.uid !== fileId) {
              return savedFile;
            }
            const updatedFile = {
              ...savedFile,
              status: UploadFileStatus.ERROR,
              errorMessage: String(error),
            };
            onError?.(updatedFile, { error: UploadError.FAILED_UPLOAD });
            return updatedFile;
          }),
        ]);

        return false;
      };

      // uploadPromise.then(resolved, rejected);
    },
    [filesQueue, setFiles, files, onError, onSuccess, validatorConfig],
  );

  const onUploadFiles = React.useCallback(
    (selectedFiles: FileList | File[], multiple?: boolean, convertToMp3?: boolean): void => {
      [...Array.from(selectedFiles)].forEach((file) => {
        void onFileUpload(file, multiple, convertToMp3);
      });
    },
    [onFileUpload],
  );

  React.useEffect(() => {
    const onPaste = (event: ClipboardEvent): void => {
      event.stopPropagation();
      if (event.clipboardData?.files) {
        onUploadFiles(event.clipboardData.files);
      }
    };

    window.addEventListener("paste", onPaste);

    return () => {
      window.removeEventListener("paste", onPaste);
    };
  }, [onUploadFiles]);

  const removeFile = React.useCallback(
    (file: FileData): void => {
      const fileId = file.uid;
      if (filesQueue[fileId] && file.status !== UploadFileStatus.SUCCESS) {
        filesQueue[fileId]?.();
        delete filesQueue[fileId];
      }

      setFiles((prev) =>
        prev.filter((file) => {
          return file.uid !== fileId;
        }),
      );

      if (file.status === UploadFileStatus.SUCCESS) {
        const objectName = `${storageConfig.objectPath}/${file.originalName}`;
        void supabase.storage.from(storageConfig.bucketName).remove([objectName]);
      }

      if (onRemove) {
        onRemove(file);
      }
    },
    [setFiles, filesQueue, onRemove, storageConfig],
  );

  const resetFilesList = React.useCallback(
    (withRemove = false) => {
      if (withRemove) {
        files.forEach((file) => {
          removeFile(file);
        });
        setFiles([]);
      } else {
        setFiles([]);
      }
    },
    [files, removeFile],
  );

  const value = React.useMemo(
    () => ({
      onUploadFiles,
      files,
      removeFile,
      resetFilesList,
      labels,
      validatorConfig,
    }),
    [removeFile, onUploadFiles, files, resetFilesList, labels, validatorConfig],
  );

  return <UploadFilesContext.Provider value={value}>{children}</UploadFilesContext.Provider>;
};

export { UploadFilesContext, useUploadFilesContext, UploadFilesProvider };
