import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../..";
import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = string;

export const removeTrainerFromTeam = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/removeTrainerFromTeam`,
  async (payload, { rejectWithValue, extra: { db, auth, analytics } }) => {
    const { id } = await getLoggedUser(auth);

    const { error } = await db.from("team_member").delete().eq("id", payload);

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("trainer-removed-from-team", { trainerId: id, memberId: payload });
  },
);
