export interface Attachment {
  timestamp?: string;
  uid: string;
  name: string;
  status: "done";
  url: string;
  storageName: string;
}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  UNISEX = "unisex",
}
