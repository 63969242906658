import { type AppThunk } from "../../../index";
import { unsubscribeFromUserDetails as unsubscribeAction } from "../reducer";

export const unsubscribeFromUserProfile =
  (): AppThunk =>
  (dispatch, _, { subscriptions, db }) => {
    dispatch(unsubscribeAction());
    if (subscriptions.realtimeUserDetailsChannel) {
      subscriptions.userDetailsChannelName = null;
      return db.removeChannel(subscriptions.realtimeUserDetailsChannel);
    }
  };
