import round from "lodash.round";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import {
  type Ingredient,
  type IngredientWithPortion,
  type Nutrients,
} from "@fitness-app/data-models/entities/Ingredient";

export const sumNutrients = (
  ingredients: (DishIngredient | IngredientWithPortion)[],
  key: keyof Nutrients,
  precision = 1,
) => {
  if (ingredients.length === 0) {
    return 0;
  }
  return round(
    ingredients.reduce((prevValue, current) => {
      const value = current.nutrients?.[key];
      if (!value) {
        return prevValue;
      }

      return prevValue + (current.portion / 100) * value;
    }, 0),
    precision,
  );
};

export const getNutrientsInPortion = (
  ingredient: IngredientWithPortion | (Ingredient & { portion: number }),
  key: keyof Nutrients,
  precision = 1,
) => {
  const value = ingredient.nutrients[key];
  if (!value) {
    return 0;
  }

  return round((ingredient.portion / 100) * value, precision);
};
