import { createAsyncThunk } from "@reduxjs/toolkit";
import { getError } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export type Payload = {
  productId: string;
  serverId: string;
};

export const verifyDiscordIntegration = createAsyncThunk<void, Payload, AsyncThunkCreator<number>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/verifyDiscordIntegration`,
  async (payload, { rejectWithValue, extra: { integrationApi, analytics } }) => {
    try {
      await integrationApi.post("check-discord-integration", payload);

      analytics.track("integrated_with_discord");
    } catch (e) {
      return rejectWithValue(getError(e).status);
    }
  },
);
