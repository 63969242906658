import cloneDeep from "lodash.clonedeep";
import { type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { reduceExerciseProperty } from "./reduceExerciseProperty";

export const addSeriesToExercise = (exercise: ExerciseInWorkout): ExerciseInWorkout => {
  const numberOfSeries = Object.keys(exercise.set).length;
  const updatedSet: ExerciseInWorkout["set"] = {
    ...exercise.set,
    [numberOfSeries + 1]: {
      ...cloneDeep(exercise.set[`${numberOfSeries}`]),
    },
  };
  return {
    ...exercise,
    set: updatedSet,
    numberOfSeries: Object.keys(updatedSet).length,
    numberOfRepeats: exercise.numberOfRepeats ? reduceExerciseProperty(updatedSet, "repeats", true) : null,
    weight: exercise.weight ? reduceExerciseProperty(updatedSet, "weight") : null,
    duration: exercise.duration
      ? {
          value: reduceExerciseProperty(updatedSet, "duration"),
          format: exercise.duration.format,
        }
      : null,
  };
};
