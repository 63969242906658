import React, { useEffect } from "react";
import { Form, Input, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type ChatConfigurationFormModel } from "./types";

interface ChatConfigurationFormProps {
  formController?: FormInstance<ChatConfigurationFormModel>;
  onSubmit: (formData: ChatConfigurationFormModel) => void;
  model?: Partial<ChatConfigurationFormModel> | null;
}

const ChatConfigurationForm = ({ model, onSubmit, formController }: ChatConfigurationFormProps) => {
  const { t } = useTranslation(["automation", "common"]);
  const createMarketingChannel = Form.useWatch("createMarketingChannel", formController);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  useEffect(() => {
    if (typeof createMarketingChannel === "boolean" && !createMarketingChannel) {
      formController?.setFieldsValue({
        sendByDefaultMessageFromAutomationToMarketingChannel: false,
      });
    }
  }, [createMarketingChannel]);

  return (
    <Form<ChatConfigurationFormModel>
      name="form"
      layout="vertical"
      form={formController}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Form.Item
        valuePropName="checked"
        name="createMarketingChannel"
        tooltip={t<string>("chatConfig.createMarketingChannelDescription")}
        label={t<string>("chatConfig.createMarketingChannel")}
      >
        <Switch />
      </Form.Item>

      <Form.Item
        valuePropName="checked"
        name="sendByDefaultMessageFromAutomationToMarketingChannel"
        label={t<string>("chatConfig.sendByDefaultMessageFromAutomationToMarketingChannel")}
      >
        <Switch disabled={!createMarketingChannel} />
      </Form.Item>

      <Form.Item name="marketingChannelTitle" label={t<string>("chatConfig.marketingChannelTitle")}>
        <Input disabled={!createMarketingChannel} placeholder={t<string>("chatConfig.marketingChannelPlaceholder")} />
      </Form.Item>
    </Form>
  );
};

export default ChatConfigurationForm;
