import { type TrainingProgram, type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PROGRAM_BUILDER_REDUCER_NAME = "programBuilder";

export interface ProgramBuilderReducer {
  programDetails: null | TrainingProgramDetails;
  programStatus: null | RequestStatus;
  programId: string | null;
  selectedProgram: null | TrainingProgram;
  validatedProgram: null | TrainingProgramDetails;
}
