import React, { useEffect, useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Form, Input, Select, Space } from "antd";
import { type TFunction } from "i18next";
import capitalize from "lodash.capitalize";
import { useTranslation } from "react-i18next";

import { productsActions, productsSettingsActions, RequestStatus } from "@fitness-app/app-store";
import { CheckoutTheme, type CartSettings } from "@fitness-app/data-models/entities/ProductsSettings";

import { useEntityChange } from "~/hooks/useEntityChange";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export interface CheckoutSettingsForm {
  termsUrl: string;
  privacyPolicyUrl: string;
  checkoutTheme?: CheckoutTheme;
  claim: string;
  customConsents: {
    name: string;
    label: string;
    required: boolean;
    url: string | null;
    applyToAllProducts: boolean;
    products: string[];
  }[];
}

const themeOptions = (t: TFunction) => [
  {
    value: CheckoutTheme.Indigo,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Indigo}`),
    color: "rgb(49, 46, 129)",
  },
  {
    value: CheckoutTheme.Black,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Black}`),
    color: "rgb(0, 0, 0)",
  },
  {
    value: CheckoutTheme.Pink,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Pink}`),
    color: "rgb(131, 24, 67)",
  },
  {
    value: CheckoutTheme.Rose,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Rose}`),
    color: "rgb(136, 19, 55)",
  },
  {
    value: CheckoutTheme.Emerald,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Emerald}`),
    color: "rgb(6, 78, 59)",
  },
  {
    value: CheckoutTheme.Teal,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Teal}`),
    color: "rgb(18, 78, 74)",
  },
  {
    value: CheckoutTheme.Cyan,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Cyan}`),
    color: "rgb(22, 78, 99)",
  },
  {
    value: CheckoutTheme.Sky,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Sky}`),
    color: "rgb(12, 74, 110)",
  },
  {
    value: CheckoutTheme.Blue,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Blue}`),
    color: "rgb(31, 57, 138)",
  },
  {
    value: CheckoutTheme.Violet,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Violet}`),
    color: "rgb(76, 29, 149)",
  },
  {
    value: CheckoutTheme.Purple,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Purple}`),
    color: "rgb(88, 28, 135)",
  },
  {
    value: CheckoutTheme.Fuchsia,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Fuchsia}`),
    color: "rgb(112, 26, 117)",
  },
  {
    value: CheckoutTheme.Gray,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Gray}`),
    color: "rgb(55, 65, 81)",
  },
  {
    value: CheckoutTheme.Yellow,
    label: t(`checkoutTheme.variantColor.${CheckoutTheme.Yellow}`),
    color: "rgb(250, 204, 21)",
  },
];

const CheckoutSettings: FunctionComponent = () => {
  const { t } = useTranslation(["settings", "common"]);
  const userDetails = useAppSelector((store) => store.user.profile);
  const { data } = useAppSelector((store) => store.productsSettings);
  const [formController] = Form.useForm<CheckoutSettingsForm>();

  const selectedTheme = Form.useWatch(["checkoutTheme"], formController);

  const cartData = data?.checkoutSettings?.cart;

  const [applyToAllProducts, updateApplyToAllProducts] = useState<boolean[]>(
    cartData?.pl.customConsents ? cartData.pl.customConsents.map((consent) => consent.applyToProducts === true) : [],
  );
  const { productsListData: products, productsListStatus } = useAppSelector((store) => store.products);

  const dispatch = useAppDispatch();
  const [loading, onSuccess, onFailure] = useEntityChange();

  useEffect(() => {
    if (productsListStatus !== RequestStatus.SUBSCRIBED) {
      dispatch(productsActions.subscribeToProducts());
    }
  }, [productsListStatus]);

  const creatorField = useMemo(() => {
    if (cartData) {
      return "creator" in cartData.pl
        ? cartData.pl.creator
        : userDetails
        ? `${userDetails.firstName} ${userDetails.lastName ?? ""}`
        : "";
    } else {
      return userDetails ? `${userDetails.firstName} ${userDetails.lastName ?? ""}` : "";
    }
  }, [cartData]);

  useEffect(() => {
    if (cartData) {
      formController.setFields([
        {
          name: "checkoutTheme",
          value: cartData.pl.checkoutTheme || CheckoutTheme.Indigo,
        },
        {
          name: "claim",
          value: cartData.pl.claim,
        },
        {
          name: "termsUrl",
          value: cartData.pl.termsUrl,
        },
        {
          name: "privacyPolicyUrl",
          value: cartData.pl.privacyPolicyUrl,
        },
        {
          name: "customConsents",
          value:
            cartData.pl.customConsents?.map((item) => ({
              ...item,
              applyToAllProducts: typeof item.applyToProducts === "boolean" ? item.applyToProducts : false,
              products: typeof item.applyToProducts !== "boolean" ? item.applyToProducts : [],
            })) ?? [],
        },
      ]);
    }
  }, [cartData]);

  const onSubmit = (formData: CheckoutSettingsForm) => {
    const formattedModel: CartSettings = {
      ...formData,
      customConsents: formData.customConsents.map(({ products, applyToAllProducts, ...item }) => ({
        ...item,
        applyToProducts: applyToAllProducts ? true : products ?? [],
      })),
    };
    void dispatch(
      productsSettingsActions.updateCheckoutSettings({
        payload: {
          cart: {
            ...(data?.checkoutSettings?.cart ?? {}),
            pl: formattedModel,
          },
        },
        onFailure,
        onSuccess,
      }),
    );
  };

  const themeVariants = themeOptions(t);

  return (
    <div className="mx-auto my-12 max-w-[550px]">
      <Form<CheckoutSettingsForm>
        name="active-campaign-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        validateTrigger={["onSubmit"]}
        initialValues={
          {
            claim:
              data?.checkoutSettings?.cart.pl.claim ||
              t("checkout.claimPlaceholder", {
                name: `${userDetails?.firstName} ${userDetails?.lastName ?? ""}`,
                location: "Warszawie",
              }),
            termsUrl: data?.checkoutSettings?.cart.pl.termsUrl || "",
            privacyPolicyUrl: data?.checkoutSettings?.cart.pl.privacyPolicyUrl || "",
            customConsents: [],
            creator: creatorField,
            checkoutTheme: CheckoutTheme.Indigo,
          } as CheckoutSettingsForm
        }
      >
        <Form.Item
          name="checkoutTheme"
          label={t("checkout.checkoutTheme")}
          extra={
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://${import.meta.env.BASE_URL}/test/example-checkout?theme=${selectedTheme || "indigo"}`}
            >
              <LinkOutlined /> {t("checkout.seeVariants")}
            </a>
          }
        >
          <Select>
            {themeVariants.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {capitalize(item.value)}
                {item.color && (
                  <div
                    style={{
                      position: "absolute",
                      right: "1.2rem",
                      top: ".35rem",
                      width: "1.2rem",
                      height: "1.2rem",
                      backgroundColor: item.color,
                      borderRadius: ".6rem",
                    }}
                  />
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="termsUrl"
          label={t("checkout.termsUrl")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
            {
              type: "url",
              message: t<string>("common:validationErrors.wrongUrl"),
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item
          name="privacyPolicyUrl"
          label={t("checkout.privacyPolicyUrl")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
            {
              type: "url",
              message: t<string>("common:validationErrors.wrongUrl"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="creator" label={t("checkout.creatorField")}>
          <Input />
        </Form.Item>

        <Form.Item
          name="claim"
          label={t("checkout.claim")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input.TextArea rows={8} />
        </Form.Item>

        <Divider />

        <Form.Item label={t("checkout.additionalConsents")}>
          <Form.List name="customConsents">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} className="mb-8 bg-gray-100 p-6">
                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                      name={[name, "name"]}
                      style={{ marginBottom: 10 }}
                    >
                      <Input placeholder={t<string>("checkout.consentName")} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "label"]}
                      style={{ marginBottom: 5 }}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Input.TextArea rows={4} placeholder={t<string>("checkout.consentContent")} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ marginBottom: 5 }}
                      name={[name, "required"]}
                      valuePropName="checked"
                      rules={[
                        {
                          required: false,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                    >
                      <Checkbox>{t("checkout.consentRequired")}</Checkbox>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "url"]} style={{ marginBottom: 10 }}>
                      <Input placeholder={t<string>("checkout.consentUrl")} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      style={{ marginBottom: 5 }}
                      name={[name, "applyToAllProducts"]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) =>
                          updateApplyToAllProducts((prev) => {
                            prev[key] = e.target.checked;
                            return [...prev];
                          })
                        }
                      >
                        {t("checkout.applyToAllProducts")}
                      </Checkbox>
                    </Form.Item>

                    {applyToAllProducts[key] ? null : (
                      <Form.Item {...restField} style={{ marginBottom: 5 }} name={[name, "products"]}>
                        <Select
                          options={products.map((product) => ({
                            value: product.id,
                            label: product.name,
                          }))}
                          placeholder={t("checkout.selectProducts")}
                          mode="multiple"
                        />
                      </Form.Item>
                    )}

                    <Space style={{ width: "100%", justifyContent: "flex-end" }} align="baseline">
                      <DeleteOutlined onClick={() => remove(name)} style={{ fontSize: 20, color: "#f64e60" }} />
                    </Space>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        label: "",
                        url: "",
                        required: false,
                        applyToAllProducts: true,
                        name: "",
                        productsIds: [],
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    {t("checkout.addNewConsent")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
          <Button loading={!!loading} type="primary" htmlType="submit" block className="mt-4">
            {t("common:button.save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CheckoutSettings;
