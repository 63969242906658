import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import NotFound from "~/components/NotFound/NotFound";
import AutomationRouter from "~/modules/Automation/AutomationRouter";
import { Campaigns } from "~/modules/Campaigns/Campaigns";
import Dashboard from "~/modules/Dashboard/Dashboard";
import NutritionRouter from "~/modules/Nutrition/NutritionRouter";
import ProductsRouter from "~/modules/Products/ProductsRouter";
import SurveysRouter from "~/modules/SurveysTemplates/SurveysRouter";
import TeamRouter from "~/modules/Team/TeamRouter";
import TraineeRouter from "~/modules/Trainee/TraineeRouter";
import TrainingProgramsRouter from "~/modules/TrainingPrograms/TrainingProgramsRouter";
import UserRouter from "~/modules/User/UserRouter";
import Chat from "../Chat/Chat";
import Home from "./Home";

const DashboardRouter = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<Dashboard />}>
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="/dashboard/*" element={<Home />} />
        <Route path="/trainee/*" element={<TraineeRouter />} />
        <Route path="/products/*" element={<ProductsRouter />} />
        <Route path="/programs/*" element={<TrainingProgramsRouter />} />
        <Route path="/surveys/*" element={<SurveysRouter />} />
        <Route path="/campaigns/*" element={<Campaigns />} />
        <Route path="/team/*" element={<TeamRouter />} />
        <Route path="/user/*" element={<UserRouter />} />
        <Route path="/nutrition/*" element={<NutritionRouter />} />
        <Route path="/automation/*" element={<AutomationRouter />} />
        <Route path="/chat/*" element={<Chat />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default DashboardRouter;
