import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, Drawer, Timeline } from "antd";
import orderBy from "lodash.orderby";

import { ClientNoteCategory, type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";

import { useTimelineItem } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/useTimelineItem";
import { supabase } from "~/store/initializeStore";

interface TraineeNutritionNotesProps {
  traineeId: string;
}

export const TraineeNutritionNotes = ({ traineeId }: TraineeNutritionNotesProps) => {
  const [open, setOpen] = useState(false);
  const { createTimelineItem } = useTimelineItem();

  const { data } = useQuery(["nutritionNotes", traineeId], {
    keepPreviousData: true,
    queryFn: async () => {
      const { data } = await supabase
        .from("client_note")
        .select("*, author:authorId(avatarUrl, firstName, lastName)")
        .order("createdAt", { ascending: false })
        .eq("traineeId", traineeId)
        .eq("category", ClientNoteCategory.Nutrition)
        .returns<ClientNoteWithAuthorData[]>();

      return data;
    },
  });

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <>
      <Button size="small" onClick={() => setOpen(true)}>
        Notatki ({data.length})
      </Button>
      <Drawer size="large" title="Notatki związane z dietą" onClose={() => setOpen(false)} open={open}>
        <Timeline mode="left">{orderBy(data, ["pinned"], ["desc"]).map(createTimelineItem)}</Timeline>
      </Drawer>
    </>
  );
};
