import { type Database } from "@fitness-app/supabase";

import { type AutomationStartPoint } from "./Automation";
import { type WeightHint } from "./TrainingProgram";

export type TraineeSettingsEntity = Database["public"]["Tables"]["trainee_settings"]["Row"];

export enum NotificationsFrequency {
  DAILY = "DAILY",
  ONCE_A_WEEK = "ONCE_A_WEEK",
  ONCE_A_TWO_WEEKS = "ONCE_A_TWO_WEEKS",
  ONCE_A_MONTH = "ONCE_A_MONTH",
}

export interface NotificationsSettings {
  allowNotifications: null | boolean;
  hideReadReminders: boolean;
  activeWeightMeasurementReminder: boolean;
  activeBodyMeasurementReminder: boolean;
  activeWorkoutsReminder: boolean;
  weightMeasurementFrequency: NotificationsFrequency;
  bodyMeasurementFrequency: NotificationsFrequency;
}

export interface AvailableOffer {
  images: string[];
  name: string;
  customUrl?: string;
  customButtonLabel?: string;
  description: string;
  id: string;
  features: string[];
  productId: string;
}

export interface TraineeOnboarding {
  isOnboardingCompleted: boolean;
  shouldRedirectToInitialSurvey: boolean;
  surveyId?: string | null;
}

export interface UpsellTo {
  productId: string;
  productName: string;
  checkoutId: string | null;
  presaleUrl?: string;
  linkLabel?: string;
}

export interface TraineeConfig {
  products?: { id: string; name: string }[];
  upsellTo?: UpsellTo[];
}

export interface OngoingAutomation {
  name: string;
  id: string;
  startAutomationFrom: AutomationStartPoint;
  productId: string;
}

export interface WorkoutSettings {
  weightHint?: WeightHint;
}

export interface TraineeSettings
  extends Omit<
    TraineeSettingsEntity,
    | "notificationsSettings"
    | "availableOffers"
    | "onboarding"
    | "config"
    | "ongoingAutomation"
    | "features"
    | "workoutSettings"
  > {
  notificationsSettings: NotificationsSettings;
  availableOffers: AvailableOffer[];
  onboarding: TraineeOnboarding;
  config: TraineeConfig;
  ongoingAutomation: OngoingAutomation[];
  features: string[];
  workoutSettings?: null | WorkoutSettings;
}
