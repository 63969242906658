import React, { type FunctionComponent } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";

interface OwnProps {
  addScheduledMessages: () => void;
  addEmailAction: () => void;
  addSurveyAction: () => void;
}

type Props = OwnProps;

const AddAutomationButton: FunctionComponent<Props> = ({ addScheduledMessages, addEmailAction, addSurveyAction }) => {
  const { t } = useTranslation(["automation", "dashboard"]);
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={addScheduledMessages}>
        {t("addAutomationMessage")}
      </Menu.Item>
      <Menu.Item key="2" onClick={addEmailAction}>
        {t("programAutomation.addEmailAction")}
      </Menu.Item>
      <Menu.Item key="3" onClick={addSurveyAction}>
        {t("programAutomation.addSurveyAction")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["contextMenu", "click"]}>
      <div className="3s text-center opacity-0 transition-opacity hover:opacity-100">
        <Button type="link" icon={<PlusOutlined />}>
          {t("programAutomation.addButton")}
        </Button>
      </div>
    </Dropdown>
  );
};

export default AddAutomationButton;
