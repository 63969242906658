import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TrainerConfig } from "@fitness-app/data-models/entities/TrainerConfig";

import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchTrainerConfig = createAsyncThunk<TrainerConfig, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/fetchTrainerConfig`,
  async (_, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db.from("trainer_config").select().eq("id", "shared").single<TrainerConfig>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
