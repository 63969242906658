import React from "react";
import { useTranslation } from "react-i18next";

import { ExerciseType } from "@fitness-app/data-models/entities/Exercise";
import { type ExerciseInWorkout } from "@fitness-app/data-models/entities/TrainingProgram";
import { reduceExerciseProperty } from "@fitness-app/utils/src/programs/reduceExerciseProperty";

import { createExerciseInfo } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/helpers";

interface ExerciseInfoProps {
  exerciseInWorkout: ExerciseInWorkout;
}

const renderWorkoutsProperties = (properties: number | [number, number] | number[] | null) => {
  if (!properties) {
    return "-";
  }
  if (typeof properties === "number") {
    return <span>{properties}</span>;
  }
  if (properties[1] === properties[0]) {
    return <span>{properties[0]}</span>;
  }
  return (
    <span>
      {properties[0]} - {properties[1]}
    </span>
  );
};

const ExerciseInfo = ({ exerciseInWorkout }: ExerciseInfoProps) => {
  const { t } = useTranslation("workouts");

  return (
    <>
      <span>{createExerciseInfo(exerciseInWorkout.numberOfSeries)} s.</span>
      <span>|</span>
      <span>
        {renderWorkoutsProperties(reduceExerciseProperty(exerciseInWorkout.set, "repeats"))} {t("repeatsAbv")}
      </span>
      <span>|</span>
      {exerciseInWorkout.exercise.type === ExerciseType.CARDIO ? null : (
        <span>{renderWorkoutsProperties(reduceExerciseProperty(exerciseInWorkout.set, "weight"))} kg</span>
      )}
      {exerciseInWorkout.duration?.value && typeof exerciseInWorkout.duration.value === "number" && (
        <>
          {exerciseInWorkout.exercise.type !== ExerciseType.CARDIO && <span>|</span>}
          <span>
            {renderWorkoutsProperties(reduceExerciseProperty(exerciseInWorkout.set, "duration"))}{" "}
            {t(`duration.${exerciseInWorkout.duration.format}`)}
          </span>
        </>
      )}
    </>
  );
};

export default ExerciseInfo;
