import { type AppThunk } from "../../../index";
import { sendLoginLinkStarted, sendLoginLinkSuccess } from "../reducer";

export const sendLoginLink =
  (email: string, continueUrl?: string): AppThunk =>
  async (dispatch, _, { auth }) => {
    dispatch(sendLoginLinkStarted());

    await auth.signInWithOtp({ email, options: { emailRedirectTo: continueUrl } });

    dispatch(sendLoginLinkSuccess());
  };
