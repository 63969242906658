import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChatStatus } from "@fitness-app/data-models/entities/Chat";

import { type AsyncThunkCreator } from "../../../index";
import { removeChannel, removeChannelFromList } from "../reducer";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  channelId: string;
  status: ChatStatus;
};

export const updateChannelStatus = createAsyncThunk<Payload, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/updateChannelStatus`,
  async (payload, { rejectWithValue, dispatch, extra: { db } }) => {
    const { error } = await db
      .from("chat_channel")
      .update({ status: payload.status, updatedAt: new Date().toISOString() })
      .eq("id", payload.channelId);

    if (error) {
      return rejectWithValue(error.message);
    }

    if (payload.status === ChatStatus.Archived) {
      dispatch(removeChannelFromList(payload.channelId));
      dispatch(removeChannel(payload.channelId));
    }

    return payload;
  },
);
