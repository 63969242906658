import { v4 as uuid } from "uuid";

import { type AutomationWeek, type WeekdaysNumber } from "@fitness-app/data-models/entities/ProgramAutomation";

export const createAutomationWeekTemplate = (week?: AutomationWeek) => {
  const weekObj: Partial<AutomationWeek> = {};
  Array.from({ length: 7 }).forEach((_, i) => {
    const key = (i + 1) as WeekdaysNumber;
    weekObj[key] = {
      id: uuid(),
      items: week?.[key] ? week[key]?.items ?? [] : [],
    };
  });

  return weekObj;
};
