import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import {
  type TrainingProgramLevelEnum,
  type TrainingProgramTypeEnum,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { getUserRole } from "../../user/selectors";
import { PROGRAMS_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
  filter?: "only_author";
  tags?: string[] | null;
  searchTerm?: string | null;
  addedBy?: string | null;
  programLevel?: TrainingProgramLevelEnum | null;
  programType?: TrainingProgramTypeEnum | null;
};

export const fetchPrograms = createAsyncThunk<
  { data: TrainingProgramWithCreator[]; page: number; totalPages: number },
  Payload | void,
  AsyncThunkCreator<string>
>(`${PROGRAMS_REDUCER_NAME}/fetchPrograms`, async (payload, { rejectWithValue, getState, extra: { db, auth } }) => {
  const currentUser = await getLoggedUser(auth);
  const { pagination, filters } = getState().programs;

  const role = getUserRole(getState());

  const currentPage = payload?.page ?? 1;

  const start = (currentPage - 1) * pagination.perPage;
  const stop = currentPage * pagination.perPage - 1;

  let query = db.from("training_program").select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

  if (role !== UserRole.TRAINER) {
    query = query.or(`createdBy.eq.${currentUser.id}, shared.is.${true}`);
  }

  if (payload?.addedBy) {
    if (payload.addedBy === "none") {
      query = query.is("createdBy", null);
    } else {
      query = query.eq("createdBy", payload.addedBy);
    }
  } else if (filters.addedBy) {
    query = query.eq("createdBy", filters.addedBy);
  }

  if (payload?.tags?.length) {
    query = query.or(payload.tags.map((tag) => `tags.cs.["${tag}"]`).join(","));
  }

  if (!payload?.tags && filters.tags?.length) {
    query = query.or(filters.tags.map((tag) => `tags.cs.["${tag}"]`).join(","));
  }

  const searchTerm = payload?.searchTerm || filters.searchTerm;

  if (searchTerm) {
    query = query.or(`name.ilike.%${searchTerm}%`);
  }

  const { data, error, count } = await query
    .eq("archived", false)
    .order("createdAt", { ascending: false })
    .range(start, stop)
    .returns<TrainingProgramWithCreator[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return { data, page: currentPage, totalPages: count ? Math.ceil(count / pagination.perPage) : 0 };
});
