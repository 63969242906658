import React, { useEffect, useState } from "react";
import { Form, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import {
  bodyMeasurementFrequencyOptions,
  weightMeasurementFrequencyOptions,
  type ClientNotificationsSettingsFormModel,
} from "./TraineeNotificationsData";

interface ClientNotificationsSettingsFormProps {
  formController?: FormInstance<ClientNotificationsSettingsFormModel>;
  onSubmit: (formData: ClientNotificationsSettingsFormModel) => void;
  model?: Partial<ClientNotificationsSettingsFormModel> | null;
}

const TraineeNotificationsSettingsForm = ({
  model,
  onSubmit,
  formController,
}: ClientNotificationsSettingsFormProps) => {
  const { t } = useTranslation(["trainees", "common"]);
  const [activeWeightReminder, toggleWeightReminder] = useState(model?.activeWeightMeasurementReminder ?? false);
  const [activeBodyReminder, toggleBodyReminder] = useState(model?.activeBodyMeasurementReminder ?? false);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue({ ...model });
    } else {
      formController?.resetFields();
    }
  }, [model]);

  return (
    <Form<ClientNotificationsSettingsFormModel>
      name="notifications-settings-form"
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 10 }}
      layout="horizontal"
      form={formController}
      onFinish={onSubmit}
      initialValues={
        model || {
          activeWorkoutsReminder: false,
          activeWeightMeasurementReminder: false,
          activeBodyMeasurementReminder: false,
          bodyMeasurementFrequency: null,
          weightMeasurementFrequency: null,
        }
      }
    >
      <Form.Item
        name="activeWorkoutsReminder"
        label={t("notifications.notificationsAboutTraining")}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="activeWeightMeasurementReminder"
        label={t("notifications.notificationsAboutWeightMeasurement")}
        valuePropName="checked"
      >
        <Switch onChange={toggleWeightReminder} />
      </Form.Item>

      <Form.Item name="weightMeasurementFrequency" label={t("notifications.reminderAboutWeightMeasurement")}>
        <Select options={weightMeasurementFrequencyOptions(t)} disabled={!activeWeightReminder} />
      </Form.Item>

      <Form.Item
        valuePropName="checked"
        name="activeBodyMeasurementReminder"
        label={t("notifications.notificationsAboutBodyMeasurement")}
      >
        <Switch onChange={toggleBodyReminder} />
      </Form.Item>

      <Form.Item name="bodyMeasurementFrequency" label={t("notifications.reminderAboutWeightMeasurement")}>
        <Select options={bodyMeasurementFrequencyOptions(t)} disabled={!activeBodyReminder} />
      </Form.Item>
    </Form>
  );
};

export default TraineeNotificationsSettingsForm;
