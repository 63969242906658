import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  invoiceRequest: boolean;
  email: string;
  productId: string;
};

export const updateInvoiceRequest = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCT_CLIENT_REDUCER}/updateInvoiceRequest`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db } }) => {
    try {
      const { error } = await db
        .from("product_client")
        .update({
          invoiceRequested: payload.invoiceRequest,
        })
        .eq("email", payload.email)
        .eq("productId", payload.productId);

      if (error) {
        onFailure?.();

        return rejectWithValue(error.message);
      }

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
