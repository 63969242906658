import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";

const preferableMeasureId = [9, 7, 4, 3, 2, 12, 8, 10];

export const findPreferableMeasure = (ingredient: Ingredient) => {
  return preferableMeasureId
    .map((id) => {
      return ingredient.measures.find((measure) => measure.id === id);
    })
    .filter(Boolean)[0];
};
