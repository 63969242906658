import { type AppThunk } from "../../../index";
import { unsubscribeFromNotifications as action } from "../reducer";

export const unsubscribeFromNotifications =
  (): AppThunk =>
  async (dispatch, _, { subscriptions, db }) => {
    if (subscriptions.realtimeNotificationsChannel) {
      await db.removeChannel(subscriptions.realtimeNotificationsChannel);
      subscriptions.notificationsChannelName = null;
    }
    dispatch(action());
  };
