import { ConversionTrackingClient } from "@fitness-app/data-models/entities/ProductsSettings";

import metaIcon from "./assets/meta-logo.svg";

interface ConversionTrackingOption {
  value: ConversionTrackingClient;
  disabled?: boolean;
  icon?: string;
  hidden?: boolean;
}
export const conversionTrackingOptions: ConversionTrackingOption[] = [
  {
    value: ConversionTrackingClient.MetaConversionApi,
    icon: metaIcon,
  },
];
