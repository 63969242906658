import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import difference from "lodash.difference";
import uniq from "lodash.uniq";
import uniqBy from "lodash.uniqby";

import {
  ChatStatus,
  type ChatChannelWithLastMessage,
  type ChatChannelWithMeta,
  type ChatMessage,
  type ChatMessageWithMetadata,
} from "@fitness-app/data-models/entities/Chat";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchChatListPageWithUnread } from "./actions";
import { fetchInitialChatListPage } from "./actions/fetchInitialChatListPage";
import { fetchUnreadMessages } from "./actions/fetchUnreadMessages";
import { getInitialChannelMessages } from "./actions/getInitialChannelMessages";
import { getNextChannelMessages } from "./actions/getNextChannelMessages";
import { getNextChatListPage } from "./actions/getNextChatListPage";
import { sendNewMessage } from "./actions/sendNewMessage";
import { updateChatMessage } from "./actions/updateChatMessage";
import { CHAT_REDUCER_NAME, type ChatReducer } from "./types";

const initialState: ChatReducer = {
  updatingChannelId: null,
  error: null,
  status: null,
  activeChannels: [],
  channels: [],
  archivedChannels: [],
  multipleMessageStatus: null,
  messagesBatchSize: 30,
  channelsSize: 50,
  unreadMessages: null,
  messagesToMark: {},
  channelsListStatus: {
    fetchingExtraChannel: false,
    fetchingInitialPage: false,
    fetchingNextPage: false,
    isLastPage: false,
    status: null,
    currentPage: 0,
  },
  archivedListStatus: {
    fetchingExtraChannel: false,
    fetchingInitialPage: false,
    fetchingNextPage: false,
    isLastPage: false,
    status: null,
    currentPage: 0,
  },
  messages: {},
  channelsStatus: {},
  unreadMessagesStatus: null,
  openChannelStatus: RequestStatus.IDLE,
  sendRetriesCount: 2,
  unreadChannels: [],
  unreadChannelsStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: CHAT_REDUCER_NAME,
  reducers: {
    addChannelToList(state, { payload }: PayloadAction<ChatChannelWithLastMessage>) {
      if (state.activeChannels.length >= 3) {
        state.activeChannels = [
          ...state.activeChannels.filter((channel) => channel.id !== payload.id).slice(1, 3),
          { ...payload, minimized: false },
        ];

        return;
      }
      state.activeChannels = [
        ...state.activeChannels.filter((channel) => channel.id !== payload.id),
        { ...payload, minimized: false },
      ];
    },
    removeChannelFromList(state, { payload }: PayloadAction<string>) {
      state.activeChannels = state.activeChannels.filter((channel) => channel.id !== payload);
    },
    removeChannel(state, { payload }: PayloadAction<string>) {
      state.channels = state.channels.filter((channel) => channel.id !== payload);
    },
    addMessageToChannel(
      state,
      { payload }: PayloadAction<{ message: ChatMessageWithMetadata | ChatMessage; channelId: string }>,
    ) {
      if (state.messages[payload.channelId]) {
        const channelMessages = state.messages[payload.channelId] || [];
        state.messages = {
          ...state.messages,
          [payload.channelId]: uniqBy([payload.message, ...channelMessages], "id"),
        };
      }
    },
    removeMessageFromChannel(state, { payload }: PayloadAction<{ messageId: string; channelId: string }>) {
      if (state.messages[payload.channelId]) {
        const channelMessages = state.messages[payload.channelId] || [];
        state.messages = {
          ...state.messages,
          [payload.channelId]: channelMessages.filter((message) => message.id !== payload.messageId),
        };
      }
    },
    updateMessageInChannel(
      state,
      {
        payload,
      }: PayloadAction<{ message: Partial<ChatMessageWithMetadata | ChatMessage> & { id: string }; channelId: string }>,
    ) {
      if (state.messages[payload.channelId]) {
        const channelMessages = state.messages[payload.channelId] || [];
        state.messages = {
          ...state.messages,
          [payload.channelId]: channelMessages.map((message) =>
            message.id === payload.message.id ? ({ ...message, ...payload.message } as ChatMessage) : message,
          ),
        };
      }
    },
    toggleChannelView(state, { payload }: PayloadAction<ChatChannelWithMeta>) {
      state.activeChannels = state.activeChannels.map((channel) => {
        if (channel.id === payload.id) {
          return payload;
        }

        return channel;
      });
    },
    subscribeToChannelSuccess(state, { payload }: PayloadAction<{ channelId: string; messages?: ChatMessage[] }>) {
      state.channelsStatus = {
        ...state.channelsStatus,
        [payload.channelId]: {
          fetchingFirstPage: false,
          isLastPage: false,
          fetchingNextPage: false,
          currentPage: 1,
          ...state.channelsStatus[payload.channelId],
          status: RequestStatus.SUBSCRIBED,
        },
      };
    },
    subscribeToChannelFailed(state, { payload }: PayloadAction<string>) {
      state.channelsStatus = {
        ...state.channelsStatus,
        [payload]: {
          fetchingFirstPage: false,
          isLastPage: false,
          fetchingNextPage: false,
          currentPage: 1,
          ...state.channelsStatus[payload],
          status: RequestStatus.FAILED,
        },
      };
    },
    unsubscribeFromChannel(state, { payload }: PayloadAction<string>) {
      state.channelsStatus = {
        ...state.channelsStatus,
        [payload]: {
          fetchingFirstPage: false,
          isLastPage: false,
          currentPage: 0,
          fetchingNextPage: false,
          status: null,
        },
      };
      if (state.messages[payload]) {
        state.messages = {
          ...state.messages,
          [payload]: [],
        };
      }
    },
    subscribeToUnreadMessagesStarted(state) {
      state.unreadMessagesStatus = RequestStatus.SUBSCRIBING;
    },
    subscribeToUnreadMessagesSuccess(state) {
      state.unreadMessagesStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToUnreadMessagesFailed(state) {
      state.unreadMessagesStatus = RequestStatus.FAILED;
    },
    unsubscribeFromUnreadMessages(state) {
      state.unreadMessagesStatus = null;
    },
    subscribeToLatestChannelFailed(state) {
      state.status = RequestStatus.FAILED;
    },
    subscribeToLatestChannelSuccess(state) {
      state.status = RequestStatus.SUBSCRIBED;
    },
    unsubscribeFromLatestChannel(state) {
      state.status = null;
    },
    updateUnreadMessages(state, { payload }: PayloadAction<ChatReducer["unreadMessages"]>) {
      state.unreadMessages = payload;
    },
    markChatMessagesAsReadSuccess(state, { payload }: PayloadAction<{ messages: string[]; channelId: string }>) {
      state.messagesToMark = {
        ...state.messagesToMark,
        [payload.channelId]: difference(state.messagesToMark[payload.channelId], payload.messages),
      };
    },
    updateChannel(state, { payload }: PayloadAction<ChatChannelWithLastMessage | null>) {
      state.channels = orderBy(
        state.channels.map((channel) => {
          if (channel.id === payload?.id) {
            return payload;
          }

          return channel;
        }),
        "updatedAt",
        "desc",
      );
    },
    updateMessagesToMark(state, { payload }: PayloadAction<{ messages: string[]; channelId: string }>) {
      state.messagesToMark = {
        ...state.messagesToMark,
        [payload.channelId]: uniq([...(state.messagesToMark[payload.channelId] || []), ...payload.messages]),
      };
    },
    setCurrentChannelStatus(state, { payload }: PayloadAction<RequestStatus>) {
      state.openChannelStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnreadMessages.fulfilled, (state, { payload }) => {
      state.unreadMessages = { ...payload, _perChannel: payload.perChannel };
    });
    builder.addCase(fetchChatListPageWithUnread.pending, (state) => {
      state.unreadChannelsStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchChatListPageWithUnread.fulfilled, (state, { payload }) => {
      state.unreadChannels = payload;
      state.unreadChannelsStatus = RequestStatus.SUCCESS;
    });
    builder.addCase(fetchChatListPageWithUnread.rejected, (state) => {
      state.unreadChannelsStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchInitialChatListPage.pending, (state, { meta }) => {
      if (meta.arg.status === ChatStatus.Archived) {
        state.archivedListStatus = {
          ...state.archivedListStatus,
          currentPage: 1,
          status: RequestStatus.SUBSCRIBING,
          fetchingInitialPage: true,
        };
      } else {
        state.channelsListStatus = {
          ...state.channelsListStatus,
          currentPage: 1,
          status: RequestStatus.SUBSCRIBING,
          fetchingInitialPage: true,
        };
      }
    });
    builder.addCase(fetchInitialChatListPage.fulfilled, (state, { meta, payload }) => {
      if (meta.arg.status === ChatStatus.Archived) {
        state.archivedListStatus = {
          ...state.archivedListStatus,
          status: RequestStatus.SUBSCRIBED,
          fetchingInitialPage: false,
          isLastPage: payload.length < state.channelsSize,
        };
        state.archivedChannels = payload;
      } else {
        state.channelsListStatus = {
          ...state.channelsListStatus,
          status: RequestStatus.SUBSCRIBED,
          fetchingInitialPage: false,
          isLastPage: payload.length < state.channelsSize,
        };
        state.channels = payload;
      }
    });
    builder.addCase(fetchInitialChatListPage.rejected, (state, { meta }) => {
      if (meta.arg.status === ChatStatus.Archived) {
        state.archivedListStatus = {
          ...state.archivedListStatus,
          status: RequestStatus.FAILED,
          fetchingInitialPage: false,
        };
      } else {
        state.channelsListStatus = {
          ...state.channelsListStatus,
          status: RequestStatus.FAILED,
          fetchingInitialPage: false,
        };
      }
    });
    builder.addCase(getNextChatListPage.pending, (state, { meta }) => {
      if (meta.arg === ChatStatus.Archived) {
        state.archivedListStatus = {
          ...state.archivedListStatus,
          fetchingNextPage: true,
        };
      } else {
        state.channelsListStatus = {
          ...state.channelsListStatus,
          fetchingNextPage: true,
        };
      }
    });
    builder.addCase(getNextChatListPage.fulfilled, (state, { meta, payload }) => {
      if (meta.arg === ChatStatus.Archived) {
        state.archivedListStatus = {
          ...state.channelsListStatus,
          fetchingNextPage: false,
          isLastPage: payload.isLastPage,
          currentPage: payload.page,
        };
        state.archivedChannels = [...state.channels, ...payload.data];
      } else {
        state.channelsListStatus = {
          ...state.channelsListStatus,
          fetchingNextPage: false,
          currentPage: payload.page,
          isLastPage: payload.isLastPage,
        };
        state.channels = [...state.channels, ...payload.data];
      }
    });
    builder.addCase(getNextChatListPage.rejected, (state, { meta }) => {
      if (meta.arg === ChatStatus.Archived) {
        state.archivedListStatus = {
          ...state.archivedListStatus,
          fetchingNextPage: false,
          isLastPage: true,
        };
      } else {
        state.channelsListStatus = {
          ...state.channelsListStatus,
          fetchingNextPage: false,
          isLastPage: true,
        };
      }
    });
    builder.addCase(getInitialChannelMessages.pending, (state, { meta }) => {
      state.channelsStatus = {
        ...state.channelsStatus,
        [meta.arg.channelId]: {
          status: meta.arg.withoutLoader ? RequestStatus.SUBSCRIBED : RequestStatus.SUBSCRIBING,
          currentPage: 1,
          fetchingFirstPage: !meta.arg.withoutLoader,
          isLastPage: false,
          fetchingNextPage: false,
        },
      };
    });
    builder.addCase(getInitialChannelMessages.fulfilled, (state, { payload, meta }) => {
      state.channelsStatus = {
        ...state.channelsStatus,
        [meta.arg.channelId]: {
          status: RequestStatus.SUBSCRIBED,
          currentPage: 1,
          fetchingFirstPage: false,
          isLastPage: payload.length < state.messagesBatchSize,
          fetchingNextPage: false,
        },
      };
      state.messages = {
        ...state.messages,
        [meta.arg.channelId]: payload,
      };
    });
    builder.addCase(getInitialChannelMessages.rejected, (state, { meta }) => {
      state.channelsStatus = {
        ...state.channelsStatus,
        [meta.arg.channelId]: {
          currentPage: 1,
          status: RequestStatus.FAILED,
          fetchingFirstPage: false,
          isLastPage: false,
          fetchingNextPage: false,
        },
      };
    });
    builder.addCase(getNextChannelMessages.pending, (state, { meta }) => {
      state.channelsStatus = {
        ...state.channelsStatus,
        [meta.arg.channelId]: {
          fetchingFirstPage: false,
          isLastPage: false,
          currentPage: 1,
          status: RequestStatus.SUBSCRIBED,
          ...state.channelsStatus[meta.arg.channelId],
          fetchingNextPage: true,
        },
      };
    });
    builder.addCase(getNextChannelMessages.fulfilled, (state, { meta, payload }) => {
      if (!payload) {
        state.channelsStatus = {
          ...state.channelsStatus,
          [meta.arg.channelId]: {
            fetchingFirstPage: false,
            isLastPage: false,
            currentPage: 1,
            status: RequestStatus.SUBSCRIBED,
            ...state.channelsStatus[meta.arg.channelId],
            fetchingNextPage: false,
          },
        };

        return state;
      }

      state.channelsStatus = {
        ...state.channelsStatus,
        [meta.arg.channelId]: {
          fetchingFirstPage: false,
          status: RequestStatus.SUBSCRIBED,
          ...state.channelsStatus[meta.arg.channelId],
          fetchingNextPage: false,
          isLastPage: payload.isLastPage,
          currentPage: payload.page,
        },
      };

      state.messages = {
        ...state.messages,
        [meta.arg.channelId]: [...(state.messages[meta.arg.channelId] || []), ...payload.messages],
      };
    });
    builder.addCase(getNextChannelMessages.rejected, (state, { meta }) => {
      state.channelsStatus = {
        ...state.channelsStatus,
        [meta.arg.channelId]: {
          fetchingFirstPage: false,
          isLastPage: false,
          currentPage: 1,
          status: RequestStatus.SUBSCRIBED,
          ...state.channelsStatus[meta.arg.channelId],
          fetchingNextPage: false,
        },
      };
    });
    builder.addCase(updateChatMessage.pending, (state, { meta }) => {
      if (state.messages[meta.arg.channelId]) {
        const channelMessages = state.messages[meta.arg.channelId] || [];
        state.messages = {
          ...state.messages,
          [meta.arg.channelId]: meta.arg.deleted
            ? channelMessages.filter((message) => message.id !== meta.arg.id)
            : channelMessages.map((message) =>
                message.id === meta.arg.id
                  ? {
                      ...message,
                      content: meta.arg.content || message.content,
                      reactions: meta.arg.reactions || message.reactions,
                      updatedAt: new Date().toISOString(),
                    }
                  : message,
              ),
        };
      }
    });
    builder.addCase(sendNewMessage.fulfilled, (state, { payload }) => {
      if (state.messages[payload.channelId]) {
        const channelMessages = state.messages[payload.channelId] || [];
        state.sendRetriesCount = 2;
        state.messages = {
          ...state.messages,
          [payload.channelId]: channelMessages.map((message) =>
            message.id === payload.id
              ? {
                  ...message,
                  status: "sent",
                }
              : message,
          ),
        };
      }
    });
    builder.addCase(sendNewMessage.pending, (state) => {
      state.sendRetriesCount = state.sendRetriesCount - 1 || 0;
    });
  },
});

export const {
  subscribeToChannelSuccess,
  addChannelToList,
  removeChannelFromList,
  toggleChannelView,
  removeChannel,
  addMessageToChannel,
  subscribeToChannelFailed,
  removeMessageFromChannel,
  updateMessageInChannel,
  unsubscribeFromChannel,
  subscribeToUnreadMessagesFailed,
  subscribeToUnreadMessagesStarted,
  subscribeToUnreadMessagesSuccess,
  unsubscribeFromUnreadMessages,
  updateUnreadMessages,
  markChatMessagesAsReadSuccess,
  subscribeToLatestChannelFailed,
  subscribeToLatestChannelSuccess,
  unsubscribeFromLatestChannel,
  updateChannel,
  updateMessagesToMark,
  setCurrentChannelStatus,
} = reducerSlice.actions;

export default reducerSlice.reducer;
