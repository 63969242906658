import { createSlice } from "@reduxjs/toolkit";

import { PerformingAction } from "../../../enums/performingAction";
import { RequestStatus } from "../../../enums/requestStatus";
import { addBotConfig } from "./actions/addBotConfig";
import { fetchChatBots } from "./actions/fetchChatBots";
import { updateBotConfig } from "./actions/updateBotConfig";
import { BOT_CONFIG_REDUCER_NAME, type BotsConfigReducer } from "./types";

const initialState: BotsConfigReducer = {
  list: [],
  performingAction: null,
  listStatus: null,
  error: null,
};

const botsConfigSlice = createSlice({
  name: BOT_CONFIG_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChatBots.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchChatBots.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.list = payload;
    });
    builder.addCase(fetchChatBots.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(addBotConfig.pending, (state) => {
      state.performingAction = PerformingAction.Adding;
    });
    builder.addCase(addBotConfig.fulfilled, (state, { payload }) => {
      state.performingAction = null;
      if (payload) {
        state.list.unshift(payload);
      }
    });
    builder.addCase(addBotConfig.rejected, (state) => {
      state.performingAction = null;
    });
    builder.addCase(updateBotConfig.pending, (state) => {
      state.performingAction = PerformingAction.Editing;
    });
    builder.addCase(updateBotConfig.fulfilled, (state, { payload }) => {
      state.performingAction = null;
      if (payload) {
        state.list = state.list.map((bot) => {
          if (bot.id === payload.id) {
            return payload;
          }
          return bot;
        });
      }
    });
    builder.addCase(updateBotConfig.rejected, (state) => {
      state.performingAction = null;
    });
  },
});

export default botsConfigSlice.reducer;
