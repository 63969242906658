import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import round from "lodash.round";

import { OrderStatusOption, type Order } from "@fitness-app/data-models/entities/Order";

import { type DateRange } from "~/modules/Dashboard/AppStats/hooks/types";
import { ALL_PRODUCTS_ITEM_ID } from "~/modules/Dashboard/AppStats/hooks/useProductsList";
import { supabase } from "~/store/initializeStore";

export const useOrdersData = (products: string[], range: DateRange) => {
  const { data, isLoading } = useQuery(["useOrdersData", products, range], {
    enabled: !!products.length && !!range,
    keepPreviousData: true,
    queryFn: async () => {
      if (!products.length) {
        return;
      }

      const monthStart = range ? range[0]?.startOf("day").toISOString() : null;
      const monthEnd = range ? range[1]?.endOf("day").toISOString() : null;

      if (!monthStart || !monthEnd) {
        return;
      }

      if (products.length === 1 && products[0] === ALL_PRODUCTS_ITEM_ID) {
        const { error, data } = await supabase
          .from("product_order")
          .select("id, status, connectedSubscription, product, price, priceId, session")
          .gte("createdAt", monthStart)
          .lte("createdAt", monthEnd)
          .order("createdAt", { ascending: false })
          .returns<Order[]>()
          .throwOnError();

        if (error) {
          throw new Error(error.message);
        }

        return data;
      }
      const { error, data } = await supabase
        .from("product_order")
        .select("id, status, connectedSubscription, product, price, priceId, session")
        .in("product", products)
        .gte("createdAt", monthStart)
        .lte("createdAt", monthEnd)
        .order("createdAt", { ascending: false })
        .returns<Order[]>()
        .throwOnError();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  const { data: previousPeriodData } = useQuery(["usePreviousOrdersData", products, range], {
    enabled: !!products.length && !!range,
    keepPreviousData: true,
    queryFn: async () => {
      if (!products.length) {
        return;
      }

      const monthStart = range ? range[0]?.startOf("day") : null;
      const monthEnd = range ? range[1]?.endOf("day") : null;

      if (!monthStart || !monthEnd) {
        return;
      }

      const diff = monthEnd.diff(monthStart, "days", false);
      const previousMonthStart = monthStart.subtract(diff + 1, "days").startOf("day");
      const previousMonthEnd = monthEnd.subtract(diff + 1, "days").endOf("day");

      if (products.length === 1 && products[0] === ALL_PRODUCTS_ITEM_ID) {
        const { error, data } = await supabase
          .from("product_order")
          .select("id, status, connectedSubscription, product, price, priceId, session")
          .gte("createdAt", previousMonthStart.toISOString())
          .lte("createdAt", previousMonthEnd.toISOString())
          .order("createdAt", { ascending: false })
          .returns<Order[]>()
          .throwOnError();

        if (error) {
          throw new Error(error.message);
        }

        return data;
      }
      const { error, data } = await supabase
        .from("product_order")
        .select("id, status, connectedSubscription, product, price, priceId, session")
        .in("product", products)
        .gte("createdAt", previousMonthStart.toISOString())
        .lte("createdAt", previousMonthEnd.toISOString())
        .order("createdAt", { ascending: false })
        .returns<Order[]>()
        .throwOnError();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  const purchased = useMemo(() => data?.filter((order) => order.status === OrderStatusOption.Paid) || [], [data]);
  const purchasedPrevious = useMemo(
    () => previousPeriodData?.filter((order) => order.status === OrderStatusOption.Paid) || [],
    [previousPeriodData],
  );
  const totalAmount = useMemo(
    () => purchased.reduce((acc, order) => acc + (order.price?.amount || 0) / 100, 0),
    [purchased],
  );
  const totalAmountPrevious = useMemo(
    () => purchasedPrevious.reduce((acc, order) => acc + (order.price?.amount || 0) / 100, 0),
    [purchasedPrevious],
  );

  return {
    isLoading,
    orders: data,
    finalized: purchased,
    totalAmount,
    totalAmountPrevious,
    salesChange: purchasedPrevious.length ? round((purchased.length / purchasedPrevious.length) * 100 - 100) : null,
    totalAmountChange: totalAmountPrevious ? round((totalAmount / totalAmountPrevious) * 100 - 100) : null,
  };
};
