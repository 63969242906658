import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { nutritionActions, supplementsTemplatesActions, teamActions } from "@fitness-app/app-store";

import MealsPlanDetails from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/MealsPlanDetails";
import Nutrition from "~/modules/Nutrition/Nutrition";
import { useAppDispatch } from "~/store/initializeStore";

const NutritionRouter = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(nutritionActions.fetchIngredientCategories());
    void dispatch(nutritionActions.fetchIngredientMeasures());
    void dispatch(nutritionActions.fetchIngredientGroups());
    void dispatch(supplementsTemplatesActions.fetchSupplementsTemplates());
    void dispatch(teamActions.fetchTrainerConfig());
  }, []);

  return (
    <Routes>
      <Route index element={<Nutrition />} />
      <Route path="/plans/:id" element={<MealsPlanDetails />} />
      <Route path="/:tab/*" element={<Nutrition />} />
    </Routes>
  );
};

export default NutritionRouter;
