import React, { useCallback, useEffect } from "react";
import { Modal } from "antd";

import { exercisesActions, traineeProgramActions, workoutTemplatesActions } from "@fitness-app/app-store";

import ProgramBuilderProvider, { type IProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface SharedProgramProviderProps {
  children: React.ReactElement;
  assignedProgramId: string;
}

const TraineeProgramProvider = ({ children, assignedProgramId: programId }: SharedProgramProviderProps) => {
  const { programStatus, programDetails, selectedProgram } = useAppSelector((state) => state.traineeProgram);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(exercisesActions.fetchExercises());
    void dispatch(workoutTemplatesActions.fetchWorkoutTemplates());
  }, [dispatch]);

  const duplicateWorkoutInProgram: IProgramBuilderContext["duplicateWorkoutInProgram"] = useCallback(
    async ({ workout }) => {
      await dispatch(
        traineeProgramActions.duplicateWorkoutInProgram({
          workout,
          programId,
        }),
      );
    },
    [dispatch, programId],
  );

  const fetchProgramWithDetails: IProgramBuilderContext["fetchProgramWithDetails"] = useCallback(async () => {
    await dispatch(traineeProgramActions.fetchProgramWithDetails({ id: programId }));
  }, [dispatch, programId]);

  const addNewWorkoutToProgram: IProgramBuilderContext["addNewWorkoutToProgram"] = useCallback(
    async ({ workoutId, workoutTemplates }) => {
      await dispatch(
        traineeProgramActions.addNewWorkoutToProgram({
          programId,
          workoutId,
          workoutTemplates,
        }),
      );
    },
    [dispatch, programId],
  );

  const removeWorkoutFromProgram: IProgramBuilderContext["removeWorkoutFromProgram"] = useCallback(
    async ({ workoutId }) => {
      const scheduledWorkoutKeys = selectedProgram?.workoutsSchedule?.schedule
        .map((week) => Object.values(week).map((item) => item.workoutDay))
        .flat();

      if (scheduledWorkoutKeys?.includes(workoutId)) {
        Modal.warning({
          title: "Nie można usunać treningu, który jest zaplanowany w harmonogramie.",
          content: "Zmień harmonogram aby usunąć wybrany trening.",
        });
        return false;
      }

      await dispatch(
        traineeProgramActions.removeWorkoutFromProgram({
          programId,
          workoutId,
        }),
      );
    },
    [dispatch, programId],
  );

  const replaceWorkoutInProgram: IProgramBuilderContext["replaceWorkoutInProgram"] = useCallback(
    async ({ workoutIdToReplace, workoutTemplate }) => {
      await dispatch(
        traineeProgramActions.replaceWorkoutInProgram({
          programId,
          workoutIdToReplace,
          workoutTemplate,
        }),
      );
    },
    [dispatch, programId],
  );

  const updateWorkoutInProgram: IProgramBuilderContext["updateWorkoutInProgram"] = useCallback(
    async ({ workout }) => {
      await dispatch(
        traineeProgramActions.updateWorkoutInProgram({
          programId,
          workout,
        }),
      );
    },
    [dispatch, programId],
  );

  return (
    <ProgramBuilderProvider
      programId={programId}
      programStatus={programStatus}
      selectedProgram={selectedProgram}
      programDetails={programDetails}
      duplicateWorkoutInProgram={duplicateWorkoutInProgram}
      fetchProgramWithDetails={fetchProgramWithDetails}
      addNewWorkoutToProgram={addNewWorkoutToProgram}
      removeWorkoutFromProgram={removeWorkoutFromProgram}
      replaceWorkoutInProgram={replaceWorkoutInProgram}
      updateWorkoutInProgram={updateWorkoutInProgram}
    >
      {children}
    </ProgramBuilderProvider>
  );
};

export default TraineeProgramProvider;
