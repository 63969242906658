import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import { mealsPlanActions, traineeActions, traineeMealsPlanActions } from "@fitness-app/app-store";
import { ClientNutritionStatus, type ClientMealsPlanDetails } from "@fitness-app/data-models/entities/ClientNutrition";
import { type MealsPlan, type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";
import { type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";
import { generateTraineeMealsPlanDetails } from "@fitness-app/utils/src/nutrition/generateMealsPlanDetails";
import {
  transformAddMealPlanModelToTraineeNutrition,
  type NutrientsTargets,
  type TraineeNutritionFormModel,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { supabase, useAppDispatch } from "~/store/initializeStore";

export const useAddNutritionToTrainee = (
  type: true | false | "scheduled",
  traineeProfile: TraineeProfile | null,
  onSuccessCallback?: () => void,
) => {
  const dispatch = useAppDispatch();
  const { userId } = useUserRole();

  const [loading, onSuccess, onFailure, onStart] = useEntityChange(() => {
    onSuccessCallback?.();
  });
  const [_searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const addNutritionToTrainee = async (
    formData: TraineeNutritionFormModel,
    targets: NutrientsTargets,
    _supplementsFromCurrentPlan?: ClientMealsPlanDetails["supplements"],
    draft?: null | Omit<MealsPlan, "createdBy" | "shared">,
  ) => {
    onStart();
    try {
      let supplements: ClientMealsPlanDetails["supplements"] = [];
      const traineeId = traineeProfile?.id || "";

      if (formData.copySupplementsPlan && traineeProfile?.activeMealsPlanId) {
        const response = await dispatch(
          traineeMealsPlanActions.fetchMealsPlanDetails({ planId: traineeProfile?.activeMealsPlanId }),
        ).unwrap();
        supplements = response?.supplements || [];
      }

      const modelToSave = transformAddMealPlanModelToTraineeNutrition({
        model: formData,
        nutrients: targets,
        traineeId,
        userId,
      });

      let details: MealsPlanDetails | null = null;

      if (formData.selectedTemplate && !draft) {
        const response = await dispatch(
          mealsPlanActions.fetchMealsPlanWithDetails({ id: formData.selectedTemplate }),
        ).unwrap();

        details = response.details[0] || null;
      }

      if (type === "scheduled") {
        const { data } = await supabase.from("client_meals_plan").select("id, name").match({
          traineeId,
          status: ClientNutritionStatus.Scheduled,
          startAt: modelToSave.startAt,
        });

        if (data?.length) {
          onFailure(`Istnieje już plan rozpoczynający się w dniu ${modelToSave.startAt}`);
          return;
        }

        let planId = "";

        if (draft) {
          await dispatch(
            traineeMealsPlanActions.updateMealsPlan({
              mealsPlan: {
                id: draft.id,
                status: ClientNutritionStatus.Scheduled,
                startAt: modelToSave.startAt,
              },
              mealsPlanDetails:
                formData.copySupplementsPlan && supplements?.length
                  ? {
                      planId: draft.id,
                      supplements,
                    }
                  : null,
            }),
          ).unwrap();

          planId = draft.id;
        } else {
          const { id } = await dispatch(
            traineeMealsPlanActions.addScheduledNutritionToTrainee({
              nutrition: modelToSave,
              mealsPlanDetails: generateTraineeMealsPlanDetails(modelToSave, traineeId, details, supplements),
              traineeId,
            }),
          ).unwrap();

          planId = id;
        }

        await queryClient.invalidateQueries({ queryKey: ["scheduledNutrition", traineeId] });
        await queryClient.invalidateQueries({ queryKey: ["archivedNutritionPlans", traineeId] });
        await queryClient.invalidateQueries({ queryKey: ["draftNutritionPlans", traineeId] });
        onSuccess();
        setSearchParams({ planId, tab: "nutrition", nutritionTab: "schedule" });
      } else {
        if (draft) {
          const startAt = dayjs().startOf("day").format("YYYY-MM-DD");
          await dispatch(
            traineeMealsPlanActions.updateMealsPlan({
              mealsPlan: {
                id: draft.id,
                status: ClientNutritionStatus.InProgress,
                startAt,
              },
              mealsPlanDetails:
                formData.copySupplementsPlan && supplements?.length
                  ? {
                      planId: draft.id,
                      supplements,
                    }
                  : null,
            }),
          ).unwrap();

          await dispatch(
            traineeActions.updateTrainee({
              id: traineeId,
              profile: {
                activeMealsPlanId: draft.id,
                hasActiveNutrition: formData.hasActiveNutrition ?? false,
                updatedAt: new Date().toISOString(),
              },
              additionalData: {
                nutrition: {
                  name: draft.name,
                  id: draft.id,
                  startAt,
                },
              },
            }),
          );
        } else {
          await dispatch(
            traineeMealsPlanActions.addNutritionToTrainee({
              nutrition: modelToSave,
              mealsPlanDetails: generateTraineeMealsPlanDetails(modelToSave, traineeId, details),
              shareWithClient: formData.hasActiveNutrition ?? false,
            }),
          ).unwrap();
        }

        await queryClient.invalidateQueries({ queryKey: ["archivedNutritionPlans", traineeId] });
        await queryClient.invalidateQueries({ queryKey: ["draftNutritionPlans", traineeId] });
        onSuccess();

        if (draft) {
          setSearchParams({ planId: draft.id, tab: "nutrition", nutritionTab: "schedule" });
        }
      }
    } catch (e) {
      onFailure();
    }
  };

  return {
    loading,
    addNutritionToTrainee,
  };
};
