import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { UserRole } from "@fitness-app/data-models";
import { type TrainingProgramWithCreator } from "@fitness-app/data-models/entities/TrainingProgram";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { supabase } from "~/store/initializeStore";

export const useTrainingPlanSearch = () => {
  const [filter, changeFilter] = useState<null | "only_author">(null);
  const [searchValue, setSearchValue] = useState("");
  const { userId, role } = useUserRole();

  const { data, isLoading } = useQuery(["searchTrainingPlan", filter, searchValue], {
    keepPreviousData: true,
    queryFn: async () => {
      let query = supabase
        .from("training_program")
        .select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

      if (role !== UserRole.TRAINER && !filter) {
        query = query.or(`createdBy.eq.${userId}, shared.is.${true}`);
      }

      if (filter && filter === "only_author") {
        query = query.eq("createdBy", userId);
      }

      if (searchValue && searchValue.length > 0) {
        query = query.ilike("name", `%${searchValue}%`);
      }

      const { error, data } = await query
        .order("createdAt", { ascending: false })
        .range(0, 50)
        .returns<TrainingProgramWithCreator[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  return {
    filter,
    changeFilter,
    searchValue,
    data,
    isLoading,
    setSearchValue,
  };
};
