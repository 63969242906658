import React from "react";
import { Route, Routes } from "react-router-dom";

import SetPassword from "~/modules/User/pages/SetPassword";

const UserRouter = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="set-password" element={<SetPassword />} />
    </Routes>
  );
};

export default UserRouter;
