import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { TEMPLATES_REDUCER_NAME } from "../types";

type Payload = string;

export const deleteTemplate = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${TEMPLATES_REDUCER_NAME}/deleteTemplate`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db.from("automation_template").delete().eq("id", payload);

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("delete_template_automation");

    return payload;
  },
);
