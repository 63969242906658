import { type AppThunk } from "../../../index";
import { unsubscribeFromProductAutomation as actionCreator } from "../reducer";

export const unsubscribeFromProductAutomation =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    if (subscriptions.realtimeProductAutomationChannel) {
      await db.removeChannel(subscriptions.realtimeProductAutomationChannel);
      subscriptions.productAutomationChannelName = null;
    }
    dispatch(actionCreator());
  };
