// forked https://github.com/samhirtarif/react-audio-recorder
/* eslint-disable */

import React, { Suspense, useEffect, useMemo, useRef, useState, type ReactElement } from "react";
import {
  AudioOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import { AudioVisualizer } from "~/components/AudioRecorder/AudioVisualizer";
import { type Props } from "./types";
import useAudioRecorder from "./useAudioRecorder";

const LiveAudioVisualizer = React.lazy(async () => {
  const { LiveAudioVisualizer } = await import("react-audio-visualize");
  return { default: LiveAudioVisualizer };
});

/**
 * Usage: https://github.com/samhirtarif/react-audio-recorder#audiorecorder-component
 *
 *
 * @prop `onRecordingComplete` Method that gets called when save recording option is clicked
 * @prop `recorderControls` Externally initilize hook and pass the returned object to this param, this gives your control over the component from outside the component.
 * https://github.com/samhirtarif/react-audio-recorder#combine-the-useaudiorecorder-hook-and-the-audiorecorder-component
 * @prop `audioTrackConstraints`: Takes a {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings#instance_properties_of_audio_tracks subset} of `MediaTrackConstraints` that apply to the audio track
 * @prop `onNotAllowedOrFound`: A method that gets called when the getUserMedia promise is rejected. It receives the DOMException as its input.
 * @prop `downloadOnSavePress` If set to `true` the file gets downloaded when save recording is pressed. Defaults to `false`
 * @prop `downloadFileExtension` File extension for the audio filed that gets downloaded. Defaults to `mp3`. Allowed values are `mp3`, `wav` and `webm`
 * @prop `showVisualizer` Displays a waveform visualization for the audio when set to `true`. Defaults to `false`
 * @prop `classes` Is an object with attributes representing classes for different parts of the component
 */

export const getRecordTime = (recordingTime: number): string =>
  `${Math.floor(recordingTime / 60)}:${String(recordingTime % 60).padStart(2, "0")}`;

export const convertToDownloadFileExtension = async (webmBlob: Blob, downloadFileExtension = "webm"): Promise<Blob> => {
  const FFmpeg = await import("@ffmpeg/ffmpeg");
  // @ts-expect-error ignore
  const ffmpeg = FFmpeg.createFFmpeg({ log: false });
  await ffmpeg.load();

  const inputName = "input.webm";
  const outputName = `output.${downloadFileExtension}`;

  ffmpeg.FS("writeFile", inputName, new Uint8Array(await webmBlob.arrayBuffer()));

  await ffmpeg.run("-i", inputName, outputName);

  const outputData = ffmpeg.FS("readFile", outputName);
  const outputBlob = new Blob([outputData.buffer], {
    type: `audio/${downloadFileExtension}`,
  });

  return outputBlob;
};

export const transformMediaFormat = async (blob: Blob, downloadFileExtension = "webm"): Promise<Blob> => {
  if (!crossOriginIsolated && downloadFileExtension !== "webm") {
    console.warn(
      `This website is not "cross-origin isolated". Audio will be downloaded in webm format, since mp3/wav encoding requires cross origin isolation. Please visit https://web.dev/cross-origin-isolation-guide/ and https://web.dev/coop-coep/ for information on how to make your website "cross-origin isolated"`,
    );
  }
  const fileExt = crossOriginIsolated ? downloadFileExtension : "webm";

  const downloadBlob = crossOriginIsolated ? await convertToDownloadFileExtension(blob, fileExt) : blob;

  return downloadBlob;
};

const AudioRecorder: (props: Props) => ReactElement = ({
  onRecordingComplete,
  onNotAllowedOrFound,
  recorderControls,
  audioTrackConstraints,
  downloadOnSavePress = false,
  downloadFileExtension = "webm",
  mediaRecorderOptions,
}: Props) => {
  const [savedBlob, setBlob] = useState<Blob>();
  const [savedRecordTime, setRecordedTime] = useState<number | null>(null);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = recorderControls ?? useAudioRecorder(audioTrackConstraints, onNotAllowedOrFound, mediaRecorderOptions);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [shouldSave, setShouldSave] = useState(false);
  const intervalRef = useRef<NodeJS.Timer>();
  const [currentPlayedTime, setCurrentPlayedTime] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current as unknown as number);
      }
    };
  }, []);

  const setAudioInterval = () => {
    if (audioRef.current) {
      intervalRef.current = setInterval(() => {
        if (audioRef.current?.ended) {
          clearInterval(intervalRef.current as unknown as number);
          setCurrentPlayedTime(0);
        } else {
          setCurrentPlayedTime(audioRef.current?.currentTime ?? 0);
        }
      }, 100);
    }
  };

  const stopAudioRecorder: (save?: boolean) => void = (save = true) => {
    setShouldSave(save);
    if (!save) {
      setBlob(undefined);
      setRecordedTime(null);
      setCurrentPlayedTime(0);
    } else {
      setRecordedTime(recordingTime);
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current as unknown as number);
    }
    stopRecording();
  };

  const downloadBlob = async (blob: Blob): Promise<void> => {
    if (!crossOriginIsolated && downloadFileExtension !== "webm") {
      console.warn(
        `This website is not "cross-origin isolated". Audio will be downloaded in webm format, since mp3/wav encoding requires cross origin isolation. Please visit https://web.dev/cross-origin-isolation-guide/ and https://web.dev/coop-coep/ for information on how to make your website "cross-origin isolated"`,
      );
    }
    const fileExt = crossOriginIsolated ? downloadFileExtension : "webm";

    const downloadBlob = crossOriginIsolated ? await convertToDownloadFileExtension(blob, fileExt) : blob;
    const url = URL.createObjectURL(downloadBlob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `audio.${fileExt}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  useEffect(() => {
    if ((shouldSave || recorderControls) && recordingBlob != null && onRecordingComplete != null) {
      onRecordingComplete(recordingBlob, savedRecordTime ?? 0);
      setBlob(recordingBlob);
      if (downloadOnSavePress) {
        void downloadBlob(recordingBlob);
      }
    }
  }, [recordingBlob]);

  const stableUrl = useMemo(() => {
    if (savedBlob) {
      return URL.createObjectURL(savedBlob);
    }
    return null;
  }, [savedBlob]);

  return (
    <div
      className="flex min-h-[48px] w-full items-center gap-x-2 rounded-lg border border-gray-200 px-2 py-1"
      data-testid="audio_recorder"
    >
      <span className="font-bold" data-testid="ar_timer">
        {getRecordTime(savedBlob && savedRecordTime ? savedRecordTime : recordingTime)}
      </span>
      {savedBlob && stableUrl ? (
        <span className="flex w-full flex-1 items-center">
          <Suspense fallback={<></>}>
            <audio
              src={stableUrl}
              className="invisible"
              id="audio-message"
              key={savedBlob.size}
              controls={false}
              autoPlay={false}
              onPlay={() => {
                setAudioInterval();
                setPlaying(true);
              }}
              onPause={() => {
                clearInterval(intervalRef.current as unknown as number);
                setPlaying(false);
              }}
              ref={audioRef}
              onEnded={() => {
                clearInterval(intervalRef.current as unknown as number);
                setCurrentPlayedTime(0);
              }}
              style={{ height: 0, width: 0 }}
            >
              <track default kind="captions" srcLang="pl" />
            </audio>
            <AudioVisualizer
              blob={savedBlob}
              barWidth={2}
              gap={1}
              width={160}
              height={30}
              currentTime={currentPlayedTime}
            />
          </Suspense>
        </span>
      ) : (
        <span className="flex w-full flex-1 items-center">
          {mediaRecorder ? (
            <Suspense fallback={<></>}>
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                barWidth={2}
                gap={2}
                width={150}
                height={24}
                fftSize={512}
                maxDecibels={-10}
                minDecibels={-80}
                smoothingTimeConstant={0.4}
              />
            </Suspense>
          ) : (
            <span className="text-gray-500">Zacznij nagrywanie</span>
          )}
        </span>
      )}

      {savedBlob ? (
        <>
          {playing ? (
            <PauseCircleOutlined onClick={() => audioRef.current?.pause()} />
          ) : (
            <PlayCircleOutlined onClick={() => audioRef.current?.play()} />
          )}
          <DeleteOutlined className="text-red-500" onClick={() => stopAudioRecorder(false)} />
        </>
      ) : (
        <>
          {isPaused || recordingTime === 0 ? (
            <AudioOutlined onClick={isPaused ? togglePauseResume : startRecording} />
          ) : (
            <PauseCircleOutlined onClick={togglePauseResume} />
          )}

          {isRecording && <SaveOutlined onClick={() => stopAudioRecorder(true)} />}

          <DeleteOutlined className="text-red-500" onClick={() => stopAudioRecorder(false)} />
        </>
      )}
    </div>
  );
};

export default AudioRecorder;
/* eslint-enable */
