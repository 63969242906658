import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type MealsPlan,
  type MealsPlanDetails,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
};

export const fetchMealsPlanWithDetails = createAsyncThunk<
  { plan: MealsPlanWithCreator; details: MealsPlanDetails[] },
  Payload,
  AsyncThunkCreator<string>
>(`${MEALS_PLANS_REDUCER_NAME}/fetchMealsPlanWithDetails`, async (payload, { rejectWithValue, extra: { db } }) => {
  const query = db
    .from("meals_plan")
    .select("*, creator:createdBy(id, firstName, lastName), details:meals_plan_details (*)")
    .eq("id", payload.id);

  const { error, data } = await query.single();

  if (error) {
    return rejectWithValue(error.message);
  }
  const { details, creator, ...rest } = data;

  const parsedCreator = creator as unknown as MealsPlanWithCreator["creator"];

  return {
    plan: { ...(rest as MealsPlan), creator: parsedCreator },
    details: details as MealsPlanDetails[],
  };
});
