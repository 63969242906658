import React, { type FunctionComponent } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Select, Typography, type FormInstance } from "antd";
import round from "lodash.round";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { calculateMeasureMultiplier } from "@fitness-app/utils/src/nutrition/calculateMeasureMultiplier";

import { SortableItem } from "~/components/Dnd/SortableItem";
import { type DishFormModel } from "~/modules/Nutrition/DishForm/types";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { type DishDetailsForm } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsProductProvider";

interface OwnProps {
  ingredient: DishIngredient;
  fieldKey: number;
  index: number;
  fieldName: number;
  onRemove: (index: number) => void;
  onMeasureSelect: (value: number) => void;
  formController: FormInstance<DishFormModel | DishDetailsForm>;
}

type Props = OwnProps;

const DishIngredientItem: FunctionComponent<Props> = ({
  ingredient,
  fieldKey,
  fieldName,
  index,
  onRemove,
  onMeasureSelect,
  formController,
}) => {
  const { measuresMap } = useNutritionMeasures();

  const calories = round((ingredient.nutrients.calories * ingredient.portion) / 100, 1);

  const protein = round((ingredient.nutrients.protein * ingredient.portion) / 100, 1);
  const fat = round((ingredient.nutrients.fat * ingredient.portion) / 100, 1);
  const carbs = round((ingredient.nutrients.carbohydrates * ingredient.portion) / 100, 1);

  return (
    <SortableItem
      key={ingredient.id}
      id={ingredient.id}
      index={fieldKey}
      containerId="ingredients"
      getIndex={() => fieldKey}
      handle
      handleStyle={{
        marginTop: 28,
        marginRight: 10,
      }}
    >
      <div className="flex flex-col" key={fieldKey}>
        <div>
          <Typography.Text strong>
            {index + 1}. {ingredient.name} {ingredient.brand ? `- ${ingredient.brand}` : ""}
          </Typography.Text>

          <dd className="flex gap-2 text-sm text-gray-700">
            <span>K: {calories} kcal</span>
            <span>|</span>
            <span>B: {protein} g</span>
            <span>|</span>
            <span>WW: {carbs} g</span>
            <span>|</span>
            <span>T: {fat} g</span>
          </dd>
        </div>
        <div className="mt-2 flex  gap-x-4">
          <Form.Item name={[fieldName, "portion"]}>
            <InputNumber
              disabled={ingredient.measureId !== 1}
              addonAfter="g"
              style={{ maxWidth: 150 }}
              onChange={(value) => {
                const { multiplier } = calculateMeasureMultiplier(
                  {
                    ...ingredient,
                    portion: value as number,
                  },
                  measuresMap,
                );

                formController.setFieldValue(["ingredients", fieldName, "multiplier"], multiplier);
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 22 }} className="flex-1 gap-1">
            <Form.Item name={[fieldName, "multiplier"]} noStyle hidden={ingredient.measureId === 1}>
              <InputNumber
                disabled={ingredient.measureId === 1}
                style={{ marginRight: 10 }}
                min={0.01}
                onChange={(value) => {
                  const { weightPerUnit } = calculateMeasureMultiplier(ingredient, measuresMap, ingredient.measureId);

                  formController.setFieldValue(
                    ["ingredients", fieldName, "portion"],
                    round(((value as number) ?? 0) * weightPerUnit, 1),
                  );
                }}
              />
            </Form.Item>
            <Form.Item name={[fieldName, "measureId"]} noStyle>
              <Select style={{ width: 150 }} onSelect={onMeasureSelect}>
                {ingredient.measures?.map((measure) => (
                  <Select.Option value={measure.id} key={measure.id}>
                    {`x ${measure.name.toLowerCase()}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <div>
            <Button type="text" icon={<DeleteOutlined className="text-red-600" />} onClick={() => onRemove(index)} />
          </div>
        </div>
      </div>
    </SortableItem>
  );
};

export default DishIngredientItem;
