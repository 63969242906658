import { type UserProfile } from "@fitness-app/data-models";
import { getErrorMessage } from "@fitness-app/utils";

import { type AppThunk } from "../../../index";
import {
  getUserDetailsFailed,
  getUserDetailsStarted,
  getUserDetailsSuccess,
  updateUserDetailsSuccess,
} from "../reducer";
import { setUserPresence } from "./setUserPresence";

export const subscribeToUserProfile =
  (id: string, isMobile = false): AppThunk =>
  async (dispatch, getState, { db, subscriptions, analytics, auth }) => {
    dispatch(getUserDetailsStarted());
    try {
      const { data, error } = await db.from("user_profile").select().eq("id", id).single();

      if (error) {
        dispatch(getUserDetailsFailed(error.message));
        return;
      }
      if (data) {
        analytics.identify(id, { email: data.email, name: `${data.firstName} ${data.lastName}`, role: data.role });
        analytics.updateUserProperties(id, { email: data.email });
        dispatch(getUserDetailsSuccess(data as UserProfile));
      }

      if (subscriptions.userDetailsChannelName && subscriptions.realtimeUserDetailsChannel) {
        await db.removeChannel(subscriptions.realtimeUserDetailsChannel);
      }

      if (!isMobile) {
        dispatch(setUserPresence());
      }

      subscriptions.userDetailsChannelName = `user-profile-${id}`;
      subscriptions.realtimeUserDetailsChannel = db.channel(subscriptions.userDetailsChannelName);

      subscriptions.realtimeUserDetailsChannel
        .on<UserProfile>(
          "postgres_changes",
          { event: "*", schema: "public", table: "user_profile", filter: `id=eq.${id}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              // refresh to gain new claims
              void auth.refreshSession();
              dispatch(updateUserDetailsSuccess(payload.new));
              if (payload.new.email !== payload.old.email) {
                analytics.updateUserProperties(id, { email: payload.new.email });
              }
            }
          },
        )
        .subscribe();
    } catch (e) {
      dispatch(getUserDetailsFailed(getErrorMessage(e)));
    }
  };
