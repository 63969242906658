import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  traineeId: string;
  keepOldWorkoutsWithoutStatus: boolean;
};

export const archiveTraineeProgram = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/archiveTraineeProgram`,
  async (payload, { extra: { traineeApi, analytics } }) => {
    if (!traineeApi) {
      throw new Error("traineeApi is not defined");
    }

    analytics.track("archive_trainee_program");

    return traineeApi.post("/archive-trainee-program", payload);
  },
);
