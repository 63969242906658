import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import orderBy from "lodash.orderby";

import {
  ExerciseRecordingStatus,
  type ExerciseRecordingWithExerciseInfo,
} from "@fitness-app/data-models/entities/ExerciseRecording";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { supabase } from "~/store/initializeStore";

export const useExerciseRecordings = () => {
  const [statusFilter, changeStatusFilter] = useState([ExerciseRecordingStatus.RECORDED]);
  const { isTrainer } = useUserRole();
  const { data, isLoading } = useQuery(["exercise_recordings", statusFilter], {
    queryFn: async () => {
      const query = supabase
        .from("exercise_recording_ownership")
        .select(
          isTrainer
            ? "*, recording:exerciseRecordingId!inner(*,trainee:traineeId(firstName,lastName,email,trainer:assignedTrainerId(email,userId,firstName,lastName)),exercise:parentExerciseId(name),plan:programId(name))"
            : "*, recording:exerciseRecordingId!inner(*,trainee:traineeId(firstName,lastName,email),exercise:parentExerciseId(name),plan:programId(name))",
        )
        .in("recording.status", statusFilter)
        .limit(300);

      const { error, data } = await query.returns<{ recording: ExerciseRecordingWithExerciseInfo }[]>();
      if (error) {
        return [];
      }
      return data;
    },
  });

  return {
    data: orderBy(data?.map((item) => item.recording) || [], "scheduledAt", "desc"),
    isLoading,
    count: data?.length ? data.filter((item) => item.recording.status === ExerciseRecordingStatus.RECORDED).length : 0,
    statusFilter,
    changeStatusFilter,
  };
};
