import { type ReactElement } from "react";

export type UploadValidatorErrorResult =
  | {
      error: FileValidationError.WRONG_MIME_TYPE;
      allowedMimeTypes: string[];
      contentType: string;
    }
  | {
      error: FileValidationError.WRONG_EXTENSION;
      allowedExtensions: string[];
      extension: string;
    }
  | {
      error: FileValidationError.WRONG_EXTENSION;
      excludedExtensions: string[];
      extension: string;
    }
  | {
      error: FileValidationError.MAX_FILE_SIZE;
      maxFileSize: string;
    }
  | {
      error: FileValidationError.MAX_FILES_LIST_LENGTH;
      maxFilesListLength: number;
    }
  | {
      error: FileValidationError.MAX_FILES_LIST_SIZE;
      maxFilesListSize: string;
    };

export type UploadValidatorResult = UploadValidatorErrorResult | false;
export enum UploadFileStatus {
  UPLOADING = "uploading",
  SUCCESS = "success",
  ERROR = "error",
  CANCELED = "canceled",
}

export type SliderDirection = "left" | "right";

export interface FileData {
  progress: null | number;
  status: UploadFileStatus;
  uid: string;
  url?: string;
  thumbUrl?: string | null;
  originalName: string;
  shortName: string;
  size: number;
  bytesTransferred?: number;
  extension: string;
  contentType: string;
  errorMessage?: string;
  duration?: number | null;
}

export interface FileWithId extends File {
  uid: string;
}

export enum UploadError {
  CANCELED = "ABORTED",
  FAILED_UPLOAD = "FAILED_UPLOAD",
}

export enum FileValidationError {
  WRONG_EXTENSION = "WRONG_EXTENSION",
  WRONG_MIME_TYPE = "WRONG_MIME_TYPE",
  MAX_FILE_SIZE = "MAX_FILE_SIZE",
  MAX_FILES_LIST_SIZE = "MAX_FILES_LIST_SIZE",
  MAX_FILES_LIST_LENGTH = "MAX_FILES_LIST_LENGTH",
}

export interface UploadFilesContextInterface {
  files: FileData[];
  onUploadFiles: (
    files: FileList | (File & { duration?: number })[],
    multiple?: boolean,
    convertToMp3?: boolean,
  ) => void;
  removeFile: (file: FileData) => void;
  resetFilesList: (withRemove?: boolean) => void;
  labels: Labels;
  validatorConfig?: UploadValidatorConfig;
}

export interface UploadApiResponse {
  size?: number;
  url?: string;
  extension?: string;
  contentType?: string;
  id: string | number;
}

export interface UploadValidatorConfig {
  excludedExtensions?: string[];
  allowedExtensions?: string[];
  maxFileSize?: number;
  maxFilesListSize?: number;
  maxFilesListLength?: number;
  allowedMimeTypes?: string[];
}

export interface Labels {
  browseFiles: string;
  reuploadFile: string;
  processing: string;
  success: string;
  error: string;
  placeholder: string;
  or: string;
}

export const DEFAULT_LABELS: Labels = {
  browseFiles: "Browse files",
  reuploadFile: "Re-upload file",
  processing: "Processing... Please wait",
  success: "File successfully uploaded",
  error: "File upload failed. Please try again",
  placeholder: "Drag and drop file here",
  or: "or",
};

export interface StorageConfig {
  bucketName: string;
  isPublicBucket: boolean;
  objectPath: string;
}
export interface UploadProviderPropsWithCustomRequest {
  children: ReactElement;
  onSuccess?: (file: FileData) => void;
  onRemove?: (file: FileData) => void;
  onError?: (file: FileData, error: UploadValidatorErrorResult | { error: UploadError }) => void;
  validatorConfig?: UploadValidatorConfig;
  labels?: Labels;
  storageConfig: StorageConfig;
}

export type UploadProviderProps = UploadProviderPropsWithCustomRequest;
