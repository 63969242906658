import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole, type UserRoleEnum } from "@fitness-app/data-models";
import {
  ChatStatus,
  MessageType,
  type ChatChannel,
  type ChatIncomingRealtimeEvents,
  type ChatMember,
  type ChatMessage,
  type ContextMessage,
  type MessageVisibleBy,
} from "@fitness-app/data-models/entities/Chat";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { addMessageToChannel } from "../reducer";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  channelId?: string;
  messageId?: string;
  selectedAuthor?: ChatMember | null;
  context: ContextMessage["context"];
  content: ChatMessage["content"];
  files?: ChatMessage["files"];
  visibleBy?: MessageVisibleBy[];
};

export const sendNewContextMessage = createAsyncThunk<ChatMessage, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/sendNewContextMessage`,
  async (payload, { rejectWithValue, dispatch, extra: { db, auth, parties } }) => {
    const currentUser = await getLoggedUser(auth);

    let channelId = payload.channelId || "";

    if (!channelId) {
      const { error, data } = await db
        .from("chat_channel")
        .select("*")
        .order("updatedAt", { ascending: false })
        .contains("assignedUsers", [currentUser.id])
        .eq("status", ChatStatus.Active)
        .single<ChatChannel>();

      if (error) {
        throw new Error(error.message);
      }

      channelId = data.id;
    }

    const message: ContextMessage = {
      type: MessageType.WithContext,
      content: payload.content,
      files: payload.files || [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      channelId,
      context: payload.context,
      authorRole: payload.selectedAuthor?.role || (currentUser.customClaims.role as UserRoleEnum) || UserRole.TRAINER,
      authorId: payload.selectedAuthor?.uid || currentUser.id,
      automated: false,
      sendBy: currentUser.id,
      visibleBy: payload.visibleBy || ["all"],
      seenBy: [currentUser.id],
      deleted: false,
      id: generateUniqId(),
    };

    dispatch(
      addMessageToChannel({
        channelId,
        message: {
          ...message,
          status: "sending",
        },
      }),
    );

    const socket = parties.chatRooms[channelId];

    if (socket) {
      const socketMessage: ChatIncomingRealtimeEvents = {
        type: "message",
        operation: "add",
        senderId: currentUser.id,
        data: message,
      };
      socket.send(JSON.stringify(socketMessage));
    }

    const { error, data } = await db.from("chat_message").insert(message).select("*").single<ChatMessage>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
