import { type RequestStatus } from "@fitness-app/data-models";
import {
  type LiveWorkoutData,
  type LiveWorkoutStatus,
  type Timer,
  type WorkoutStepType,
} from "@fitness-app/data-models/entities/LiveWorkout";
import { type WorkoutActivityEventWithFeedback } from "@fitness-app/data-models/entities/ProgramActivity";

export const LIVE_WORKOUT_REDUCER_NAME = "liveWorkout";

export interface LiveWorkoutReducer {
  workoutDate: string | null;
  workoutId: string | null;
  status: LiveWorkoutStatus;
  stepType: WorkoutStepType;
  step: number;
  totalSteps: null | number;
  isOpenModal: boolean;
  stepText: string;
  timer: Timer;
  workoutData: {
    totalExercises: number;
    currentExercise: number;
    doneExercises: number;
  };
  liveWorkout: null | LiveWorkoutData;
  activityStatus: null | RequestStatus;
  activity: null | WorkoutActivityEventWithFeedback;
}

export interface StartWorkoutPayload {
  workoutDate: string;
  stepType: WorkoutStepType;
  totalSteps: number;
  stepText: string;
  totalExercises: number;
  workout: LiveWorkoutData;
  step?: number;
  currentExercise?: number;
  doneExercises?: number;
}
