import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  productId: string;
  authorId: string;
  paymentGatewayCustomerId: string;
  paymentMethodId: string;
};

export const updatePaymentMethodForSubscription = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENT_REDUCER}/updatePaymentMethodForSubscription`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/update-client-payment-method", payload);
      analytics.track("update_payment_method", {
        productId: payload.productId,
        authorId: payload.authorId,
      });
      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
