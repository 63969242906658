import React, { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Alert, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";

import { productClientActions } from "@fitness-app/app-store";
import { type ClientInvoiceData, type Order } from "@fitness-app/data-models/entities/Order";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import InvoiceFormData, { type ExtendedModel } from "~/modules/Products/components/InvoiceFormData/InvoiceFormData";
import { useAppDispatch } from "~/store/initializeStore";

interface IssueInvoiceProps {
  order: Order;
}

const IssueInvoice = ({ order }: IssueInvoiceProps) => {
  const { t } = useTranslation(["products", "client", "common"]);
  const [showInvoiceForm, toggleInvoiceForm] = useState(false);
  const [loader, onSuccess, _, onStart, onEnd] = useEntityChange(() => {
    toggleInvoiceForm(false);
  });
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null | { message: string; details: string }>(null);

  const updateInvoiceData = async (data: ClientInvoiceData | ExtendedModel) => {
    onStart();
    setErrorMessage(null);
    try {
      if ("updateClientData" in data) {
        const { sellDate, issueDate, updateClientData, ...rest } = data;
        await dispatch(
          productClientActions.updateInvoiceData({
            payload: {
              invoiceData: rest,
              clientEmail: order.clientEmail,
              updateClientData,
              sellDate,
              issueDate,
              orderId: order.id,
            },
          }),
        );
      }

      await dispatch(
        productClientActions.issueInvoice({
          ownerId: order.owner ?? "",
          productId: order.product,
          orderId: order.id,
          updateClientData: "updateClientData" in data ? data.updateClientData : false,
          sendInvoiceToClient: "sendInvoiceToClient" in data ? data.sendInvoiceToClient : false,
        }),
      ).unwrap();

      onSuccess();
      toggleInvoiceForm(false);
    } catch (e) {
      if (e instanceof Error) {
        if ("code" in e && e.code === "invalid-argument") {
          setErrorMessage(JSON.parse(e.message) as { message: string; details: string });
        } else {
          setErrorMessage(e.message);
        }
      }

      onEnd();
    }
  };

  if (order.orderForImport || (order.connectedInvoice && "number" in order.connectedInvoice)) {
    return null;
  }

  return (
    <div className="ml-2 flex-1 text-right">
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: "1",
                label:
                  order.connectedInvoice && "errorMessage" in order.connectedInvoice
                    ? t("client:invoice.retryInvoiceIssue")
                    : t("client:invoice.issueInvoice"),
                onClick: () => toggleInvoiceForm(true),
              },
            ]}
          />
        }
      >
        <SettingOutlined />
      </Dropdown>
      <ModalForm<ExtendedModel>
        open={showInvoiceForm}
        onCancel={() => toggleInvoiceForm(false)}
        title={t("client:invoice.issueInvoiceTitle")}
        okText={t("client:invoice.issueInvoice")}
        loading={!!loader}
      >
        <InvoiceFormData onSubmit={updateInvoiceData} model={order.client} withInvoiceMetadata order={order}>
          {errorMessage && (
            <Alert
              showIcon
              style={{ marginBottom: 20 }}
              type="error"
              message={t("client:invoice.errorTitle")}
              description={
                typeof errorMessage === "string" ? (
                  errorMessage
                ) : (
                  <div>
                    <div>{errorMessage.message}</div>
                    <div>
                      {errorMessage.details
                        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          (JSON.parse(errorMessage.details)?.message as string) || "Unknown error"
                        : "Unknown error"}
                    </div>
                  </div>
                )
              }
            />
          )}
        </InvoiceFormData>
      </ModalForm>
    </div>
  );
};

export default IssueInvoice;
