import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductClientToImportDocument } from "@fitness-app/data-models/entities/ProductClientToImport";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = {
  list: Omit<ProductClientToImportDocument, "total" | "count">[];
  productId: string;
};

export const importClientsList = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_REDUCER_NAME}/importClientsList`,
  async (payload, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      const list = payload.list.map((client, index) => ({
        ...client,
        total: payload.list.length,
        count: index + 1,
      }));
      await productApi.post("/import-clients-to-product", { productId: payload.productId, clientsToImport: list });
      analytics.track("import_clients_to_product", {
        productId: payload.productId,
        clientsToImport: list.length,
      });
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
