import { createAsyncThunk } from "@reduxjs/toolkit";

import { type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  dayId: string;
  mealId: string;
  productId: string;
};

export const deleteProductFromMealsPlan = createAsyncThunk<MealsPlanDetails, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/deleteProductFromMealsPlan`,
  async (payload, { rejectWithValue, getState, extra: { db, analytics } }) => {
    const { mealsPlans } = getState();

    if (!mealsPlans.selectedMealsPlanDetails || mealsPlans.selectedMealsPlanDetails.length === 0) {
      return rejectWithValue("No meals plan details");
    }

    const [plan] = mealsPlans.selectedMealsPlanDetails;

    if (!plan) {
      return rejectWithValue("No meals plan");
    }

    const updatedDays = plan.weeks[0]?.days.map((day) =>
      day.id === payload.dayId
        ? {
            ...day,
            meals: day.meals.map((meal) =>
              meal.id === payload.mealId
                ? {
                    ...meal,
                    dishes: meal.dishes.filter((dish) => dish.id !== payload.productId),
                  }
                : meal,
            ),
          }
        : day,
    );

    const { error, data } = await db
      .from("meals_plan_details")
      .update({
        weeks: [
          {
            weekNumber: 1,
            days: updatedDays || [],
          },
        ],
        updatedAt: new Date().toISOString(),
      })
      .eq("id", plan.id)
      .select()
      .returns<MealsPlanDetails[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("delete_product_from_meals_plan");

    return data[0]!;
  },
);
