import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
  source?: Ingredient["source"];
  verified?: boolean;
};

export const fetchIngredients = createAsyncThunk<
  { data: Ingredient[]; page: number; totalPages: number },
  Payload | void,
  AsyncThunkCreator<string>
>(`${NUTRITION_REDUCER_NAME}/fetchIngredients`, async (payload, { rejectWithValue, getState, extra: { db } }) => {
  const { ingredientsPerPage, filteredCategories } = getState().nutrition;

  const currentPage = payload?.page ?? 1;

  const start = (currentPage - 1) * ingredientsPerPage;
  const stop = currentPage * ingredientsPerPage - 1;

  let query = db.from("ingredient").select("*", { count: "exact" });

  if (filteredCategories.length) {
    query = query.in("mainCategory", filteredCategories);
  }

  if (payload?.source) {
    query = query.eq("source", payload.source);
  }

  if (typeof payload?.verified === "boolean") {
    query = query.is("verified", payload.verified);
  }

  const { error, data, count } = await query
    .order("verified")
    .order("mainCategory")
    .order("name")
    .range(start, stop)
    .returns<Ingredient[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return { data, page: currentPage, totalPages: count ? Math.ceil(count / ingredientsPerPage) : 0 };
});
