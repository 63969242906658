import { createAsyncThunk } from "@reduxjs/toolkit";

import { ChatStatus, type ChatChannelWithLastMessage } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";
import { subscribeToLatestChannel } from "./subscribeToLatestChannel";

type Payload = string[];

export const fetchChatListPageWithUnread = createAsyncThunk<
  ChatChannelWithLastMessage[],
  Payload,
  AsyncThunkCreator<string>
>(
  `${CHAT_REDUCER_NAME}/fetchChatListPageWithUnread`,
  async (payload, { rejectWithValue, dispatch, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("chat_channel")
      .select(
        "*, lastMessage:lastMessageId(id, content, createdAt), profiles:user_profile(avatarUrl, firstName, lastName, id, email))",
      )
      .order("updatedAt", { ascending: false })
      .contains("assignedUsers", [currentUser.id])
      .eq("status", ChatStatus.Active)
      .in("id", payload)
      .returns<ChatChannelWithLastMessage[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    dispatch(subscribeToLatestChannel());

    return data;
  },
);
