import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Alert, Skeleton, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus } from "@fitness-app/app-store";
import { type MealsPlanDetails, type MealsPlanWithCreator } from "@fitness-app/data-models/entities/MealsPlan";

import MealsPlanAttachments from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanAttachments/MealsPlanAttachments";
import MealsPlanSchedule from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsPlanSchedule";
import MealsPlanShoppingList from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/MealsPlanShoppingList";
import MealsPlanSupplements from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSupplements/MealsPlanSupplements";
import MealsPlanTargets from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanTargets/MealsPlanTargets";
import MealsPlanTemplateViewerProvider from "~/shared/providers/MealsPlanTemplateViewerProvider";
import { supabase } from "~/store/initializeStore";

interface NutritionTemplateViewerProps {
  templatedId: string;
  isDraft: boolean;
}

export const NutritionTemplateViewer = ({ templatedId, isDraft }: NutritionTemplateViewerProps): React.ReactElement => {
  const { t } = useTranslation(["nutrition", "common"]);

  const { data, isLoading } = useQuery(["templateViewer", templatedId, isDraft], {
    keepPreviousData: true,
    queryFn: async () => {
      const query = isDraft
        ? supabase
            .from("client_meals_plan")
            .select("*, creator:addedBy(id, firstName, lastName), details:client_meals_plan_details (*)")
            .eq("id", templatedId)
        : supabase
            .from("meals_plan")
            .select("*, creator:createdBy(id, firstName, lastName), details:meals_plan_details (*)")
            .eq("id", templatedId);

      const { error, data } = await query.maybeSingle<MealsPlanWithCreator & { details: MealsPlanDetails[] }>();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });

  if (isLoading) {
    return <Skeleton active paragraph={{ rows: 10 }} />;
  }

  if (!data) {
    return (
      <div>
        <Alert showIcon type="error" message="Nie udało się pobrać szczegółów szablonu." />
      </div>
    );
  }

  const numberOfAttachments = data.attachments?.length || 0;

  return (
    <MealsPlanTemplateViewerProvider
      selectedMealsPlanStatus={RequestStatus.SUCCESS}
      selectedMealsPlan={data}
      selectedMealsPlanDetails={data.details}
    >
      <div className="p-6">
        <Tabs type="card" defaultActiveKey="schedule" className="tabs-reset">
          <Tabs.TabPane key="schedule" tab={t("mealsPlanDetails.schedule")}>
            <MealsPlanSchedule readonly />
          </Tabs.TabPane>

          <Tabs.TabPane key="targets" tab={t("mealsPlanDetails.targets")}>
            <MealsPlanTargets readonly />
          </Tabs.TabPane>

          <Tabs.TabPane key="shoppingList" tab={t("mealsPlanDetails.shoppingList")}>
            <MealsPlanShoppingList />
          </Tabs.TabPane>

          <Tabs.TabPane key="supplements" tab={t("mealsPlanDetails.supplements")}>
            <MealsPlanSupplements readonly />
          </Tabs.TabPane>

          {numberOfAttachments > 0 && (
            <Tabs.TabPane
              key="attachments"
              tab={`${t("mealsPlanDetails.attachments")}${numberOfAttachments > 0 ? ` (${numberOfAttachments})` : ""}`}
            >
              <MealsPlanAttachments readonly planId={templatedId} current={false} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    </MealsPlanTemplateViewerProvider>
  );
};
