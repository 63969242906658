import React, { type FunctionComponent } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";

import { type ConvertKitIntegrationFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<ConvertKitIntegrationFormModel>;
  onSubmit: (formData: ConvertKitIntegrationFormModel) => void;
  model?: ConvertKitIntegrationFormModel | null;
}

type Props = OwnProps;

const ConvertKitIntegrationForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["settings", "common"]);
  return (
    <div className="min-w-[450px]">
      <Form<ConvertKitIntegrationFormModel>
        name="convert-kit-campaign-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        initialValues={{ url: model?.url ?? "" }}
        size="large"
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="url"
          label={t("convertKit.account")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input
            placeholder={t<string>("convertKit.accountNamePlaceholder")}
            autoFocus={!model}
            disabled={!!model?.url}
          />
        </Form.Item>

        <Form.Item
          name="apiSecret"
          tooltip={t("convertKit.tooltip")}
          label={t("convertKit.apiSecret")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus={!!model} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConvertKitIntegrationForm;
