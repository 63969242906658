import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientNutritionDay } from "@fitness-app/data-models/entities/ClientNutritionDay";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_NUTRITION_TRACKER_REDUCER_NAME } from "../types";

type Payload = {
  nutritionDay: ClientNutritionDay;
};

export const upsertNutritionTrackedDay = createAsyncThunk<ClientNutritionDay, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_NUTRITION_TRACKER_REDUCER_NAME}/upsertNutritionTrackedDay`,
  async ({ nutritionDay }, { rejectWithValue, extra: { db, analytics, errorTrackingService } }) => {
    const { error, data } = await db
      .from("client_nutrition_day")
      .upsert(nutritionDay)
      .select("*")
      .single<ClientNutritionDay>();

    if (error?.message.includes("duplicate key value violates unique constraint")) {
      const { error: fetchError, data: savedEvent } = await db
        .from("client_nutrition_day")
        .select("*")
        .eq("traineeId", nutritionDay.traineeId)
        .eq("eventDate", nutritionDay.eventDate)
        .single<ClientNutritionDay>();

      if (fetchError) {
        errorTrackingService?.recordError(fetchError, "errorFetchingSavedNutritionDay");
        return rejectWithValue(fetchError.message);
      }

      const { data: upsertData, error: retryError } = await db
        .from("client_nutrition_day")
        .update({ ...savedEvent, ...nutritionDay })
        .eq("id", savedEvent.id)
        .select("*")
        .single<ClientNutritionDay>();

      if (retryError) {
        errorTrackingService?.recordError(retryError, "errorRetryingUpsertNutritionDay");
        return rejectWithValue(retryError.message);
      }

      analytics.track("saved_nutrition_tracked_day_retry");

      return upsertData;
    }

    if (error) {
      errorTrackingService?.log(`Error when upserting nutrition day: ${error.message}`);
      return rejectWithValue(error.message);
    }

    analytics.track("saved_nutrition_tracked_day");

    return data;
  },
);
