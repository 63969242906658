import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import { DEFAULT_MONTH_LABELS, MIN_DISTANCE_MONTH_LABELS, NAMESPACE } from "./constants";
import { type Activity, type Week } from "./types";

dayjs.extend(isoWeek);

interface Label {
  x: number;
  y: number;
  text: string;
}

export type WeekDay = number;
export function groupByWeeks(
  activities: Array<Activity>,
  weekStart: WeekDay = 0, // 0 = Sunday
): Array<Week> {
  if (activities.length === 0) {
    return [];
  }

  const normalizedActivities = fillHoles(activities);

  // Determine the first date of the calendar. If the first date is not the
  // set start weekday, the selected weekday one week earlier is used.
  const firstDate = dayjs(normalizedActivities[0]?.date, "YYYY-MM-DD").set("hour", 12);
  const firstCalendarDate = firstDate.isoWeekday() === weekStart ? firstDate : firstDate.startOf("isoWeek");

  // To correctly group activities by week, it is necessary to left-pad the
  // list because the first date might not be set start weekday.
  const paddedActivities = [
    ...(Array(firstDate.diff(firstCalendarDate, "day")).fill(undefined) as Array<Activity>),
    ...normalizedActivities,
  ];

  return Array(Math.ceil(paddedActivities.length / 7))
    .fill(undefined)
    .map((_, calendarWeek) => paddedActivities.slice(calendarWeek * 7, calendarWeek * 7 + 7))
    .filter((item) => item);
}

/**
 * The calendar expects a continuous sequence of days, so fill gaps with empty
 * activity data.
 */
function fillHoles(activities: Array<Activity>): Array<Activity> {
  const dateMap: Record<string, Activity> = {};
  for (const activity of activities) {
    dateMap[activity.date] = activity;
  }

  const rangeDays = Array.from(
    { length: dayjs(activities[activities.length - 1]!.date).diff(dayjs(activities[0]!.date), "day") + 1 },
    (_, i) => dayjs(activities[0]!.date).add(i, "day"),
  );

  return rangeDays.map((day) => {
    const date = day.format("YYYY-MM-DD");
    const value = dateMap[date];

    if (value) {
      return value;
    }

    return {
      date,
      count: 0,
      level: 0,
    };
  });
}

export function getMonthLabels(weeks: Array<Week>, monthNames: Array<string> = DEFAULT_MONTH_LABELS): Array<Label> {
  return weeks
    .reduce<Array<Label>>((labels, week, index) => {
      const firstWeekDay = week.find((day) => day !== undefined);

      if (!firstWeekDay) {
        throw new Error(`Unexpected error: Week is empty: [${week}].`);
      }

      const month = monthNames[dayjs(firstWeekDay.date).get("month")];
      const prev = labels[labels.length - 1];

      if (index === 0 || prev?.text !== month) {
        return [
          ...labels,
          {
            x: index || 0,
            y: 0,
            text: month || "",
          },
        ];
      }

      return labels;
    }, [])
    .filter((label, index, labels) => {
      if (index === 0) {
        return labels[1] && labels[1].x - label.x > MIN_DISTANCE_MONTH_LABELS;
      }

      return true;
    });
}

export function getClassName(name: string, styles?: string): string {
  if (styles) {
    return `${NAMESPACE}__${name} ${styles}`;
  }

  return `${NAMESPACE}__${name}`;
}

export function generateEmptyData(): Array<Activity> {
  const rangeDays = Array.from({ length: dayjs().endOf("year").diff(dayjs().startOf("year"), "day") + 1 }, (_, i) =>
    dayjs().startOf("year").add(i, "day"),
  );

  return rangeDays.map((date) => ({
    date: date.format("YYYY-MM-DD"),
    count: 0,
    level: 0,
  }));
}
