import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";

import {
  type AnalyticsService,
  type Properties,
  type UserProperties,
} from "@fitness-app/data-models/domain/services/AnalyticsService";

type Dependecies = {
  mixPanelApiKey: string;
  postHogApiKey: string;
};

export class WebAnalyticsService implements AnalyticsService {
  mixpanel: typeof mixpanel;
  posthog: typeof posthog;
  isLocalhost: boolean = window.location.hostname === "localhost";
  indentified: boolean | string = false;

  constructor(dependencies: Dependecies) {
    if (!this.isLocalhost) {
      posthog.init(dependencies.postHogApiKey, {
        api_host: "https://eu.posthog.com",
        autocapture: !this.isLocalhost,
        capture_pageleave: !this.isLocalhost,
        capture_pageview: !this.isLocalhost,
        capture_performance: !this.isLocalhost,
      });
    }

    mixpanel.init(dependencies.mixPanelApiKey);

    this.mixpanel = mixpanel;
    this.posthog = posthog;
  }

  track(event_name: string, properties?: Properties): void {
    this.mixpanel.track(event_name, properties);
    if (!this.isLocalhost) {
      this.posthog.capture(event_name, properties);
    }
  }

  identify(userId: string, userProperties?: UserProperties) {
    if (this.indentified === userProperties?.email) {
      // already identified
      return;
    }
    this.mixpanel.identify(userId);
    this.mixpanel.people.set_once({
      email: userProperties?.email || null,
      name: userProperties?.name || null,
      role: userProperties?.role || null,
    });
    if (!this.isLocalhost) {
      this.posthog.identify(userId, {
        email: userProperties?.email || null,
        name: userProperties?.name || null,
        role: userProperties?.role || null,
      });
    }

    this.indentified = userProperties?.email || false;
  }

  updateUserProperties(userId: string, userProperties: UserProperties): void {
    this.mixpanel.identify(userId);
    this.mixpanel.people.set({ email: userProperties.email || null });
    this.mixpanel.track("update_user_properties");
    if (!this.isLocalhost && userProperties.email !== this.indentified) {
      this.posthog.capture("update_user_properties", {
        $set: { email: userProperties.email || null },
      });
    }
  }

  reset(): void {
    this.mixpanel.reset();
    this.posthog.reset();
  }

  screen(name: string) {
    if (!this.isLocalhost) {
      this.posthog.capture("$pageview", { $screen_name: name });
    }
  }
}
