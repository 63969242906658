import dayjs from "dayjs";
import { createSelector } from "reselect";

import { MeasurementType, type BodyMeasurements } from "@fitness-app/data-models/entities/Measurement";
import { getBodyMeasurementsWithVariation } from "@fitness-app/utils/src/measurements/getBodyMeasurementsWithVariation";

import { type AppStore } from "../../../index";

const getDate = () => dayjs();
const getBodyMeasurement = (state: AppStore) =>
  state.traineeMeasurements.measurements.filter(
    (measurement): measurement is BodyMeasurements => measurement.type === MeasurementType.BodyMeasurements,
  );

export const getLatestBodyMeasurements = createSelector([getDate, getBodyMeasurement], (_, bodyMeasurement) => {
  return getBodyMeasurementsWithVariation(bodyMeasurement);
});
