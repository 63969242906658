import { createAsyncThunk } from "@reduxjs/toolkit";
import { type AutomationSettings } from "@fitness-app/data-models/entities/Automation";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_AUTOMATION_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  settings: AutomationSettings;
};

export const updateProductAutomationSettings = createAsyncThunk<
  void,
  ActionParams<Payload>,
  AsyncThunkCreator<unknown>
>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationSettings`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error("product automation data not exists");
      }

      await db
        .from("product_automation")
        .update({
          settings: { ...(data?.settings || {}), ...payload.settings },
          updatedAt: new Date().toISOString(),
        })
        .eq("productId", payload.productId);

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  },
);
