import { createSlice } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { RequestStatus } from "@fitness-app/data-models";

import { fetchNutritionTrackedWeek } from "./actions/fetchNutritionTrackedWeek";
import { upsertNutritionTrackedDay } from "./actions/upsertNutritionTrackedDay";
import { TRAINEE_NUTRITION_TRACKER_REDUCER_NAME, type TraineeNutritionTrackerReducer } from "./types";

const initialState: TraineeNutritionTrackerReducer = {
  selectedWeek: null,
  listStatus: null,
  fetchedWeekDays: [],
  fetchedWeek: null,
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_NUTRITION_TRACKER_REDUCER_NAME,
  reducers: {
    resetFetchedWeek: (state) => {
      state.fetchedWeekDays = [];
      state.listStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNutritionTrackedWeek.pending, (state, { meta }) => {
      if (!state.fetchedWeek || state.fetchedWeek !== meta.arg.date) {
        state.listStatus = RequestStatus.FETCHING;
      }
    });
    builder.addCase(fetchNutritionTrackedWeek.fulfilled, (state, { payload, meta }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.fetchedWeekDays = payload;
      state.fetchedWeek = meta.arg.date;
    });
    builder.addCase(fetchNutritionTrackedWeek.rejected, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(upsertNutritionTrackedDay.pending, (state, { meta }) => {
      state.fetchedWeekDays = uniqBy([meta.arg.nutritionDay, ...state.fetchedWeekDays], "eventDate");
    });
    builder.addCase(upsertNutritionTrackedDay.fulfilled, (state, { payload }) => {
      state.fetchedWeekDays = uniqBy([payload, ...state.fetchedWeekDays], "eventDate");
    });
  },
});

export const { resetFetchedWeek } = reducerSlice.actions;
export default reducerSlice.reducer;
