import { useQuery } from "@tanstack/react-query";
import keyBy from "lodash.keyby";

import { supabase } from "~/store/initializeStore";

export const useUsedNutritionTemplates = (traineeId: string) => {
  const { data } = useQuery(["usedNutritionTemplates", traineeId], {
    keepPreviousData: true,
    queryFn: async () => {
      const { data } = await supabase
        .from("client_meals_plan")
        .select("id, name, createdAt, startAt, templateId, template:templateId(name, id)")
        .order("createdAt", { ascending: false })
        .eq("traineeId", traineeId)
        .not("templateId", "is", null);

      return data;
    },
  });

  return {
    usedTemplates: keyBy(
      (data || []).filter((item) => item.template),
      "templateId",
    ),
  };
};
