import React, { type FunctionComponent } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type WelcomeMessagesFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<WelcomeMessagesFormModel>;
  onSubmit: (formData: WelcomeMessagesFormModel) => void;
  model?: WelcomeMessagesFormModel;
}

type Props = OwnProps;

const WelcomeMessagesForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["automation", "common"]);

  return (
    <Form<WelcomeMessagesFormModel>
      name="bot-config-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      form={formController}
      onFinish={onSubmit}
      initialValues={model}
    >
      <Form.Item label={t("botsConfig.welcomeMessages")}>
        <Form.List name="welcomeMessages">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => (
                <div
                  key={field.key}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "baseline",
                  }}
                >
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    rules={[
                      {
                        required: true,
                        message: t<string>("common:validationErrors.fieldIsRequired"),
                      },
                    ]}
                    style={{ width: "90%" }}
                    help={i === fields.length - 1 ? t("botsConfig.dynamicValue") : undefined}
                  >
                    <Input.TextArea rows={4} placeholder={t<string>("botsConfig.placeholderMessage")} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginLeft: 12 }} />
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ marginTop: 10 }}>
                  {t("common:button.add")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
};

export default WelcomeMessagesForm;
