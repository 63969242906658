import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductClientWithTraineeInfo } from "@fitness-app/data-models/entities/ProductClient";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

export const fetchProductClient = createAsyncThunk<
  ProductClientWithTraineeInfo,
  { productId: string; clientEmail: string; id?: never } | { productId: string; id: string; clientEmail?: string },
  AsyncThunkCreator<string>
>(
  `${PRODUCT_CLIENT_REDUCER}/fetchProductClient`,
  async ({ productId, clientEmail, id }, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("product_client")
      .select("*, trainee:traineeId(status)")
      .eq("productId", productId)
      .eq(clientEmail ? "email" : "id", clientEmail || id || "")
      .returns<ProductClientWithTraineeInfo[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    if (!data[0]) {
      return rejectWithValue("Product client not found");
    }

    return data[0] || null;
  },
);
