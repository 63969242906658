import { BodyMeasurement } from "../entities/Measurement";

export const BODY_FIELDS_FORM = [
  {
    name: BodyMeasurement.WAIST,
    unit: "cm",
  },
  {
    name: BodyMeasurement.CHEST,
    unit: "cm",
  },
  {
    name: BodyMeasurement.MIDDLE,
    unit: "cm",
  },
  {
    name: BodyMeasurement.HIPS,
    unit: "cm",
  },
  {
    name: BodyMeasurement.THIGH_LEFT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.THIGH_RIGHT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.BICEPS_LEFT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.BICEPS_RIGHT,
    unit: "cm",
  },
];

export const BODY_FIELDS = [
  {
    name: BodyMeasurement.BODY_FAT,
    unit: "%",
  },
  {
    name: BodyMeasurement.WAIST,
    unit: "cm",
  },
  {
    name: BodyMeasurement.CHEST,
    unit: "cm",
  },
  {
    name: BodyMeasurement.MIDDLE,
    unit: "cm",
  },
  {
    name: BodyMeasurement.HIPS,
    unit: "cm",
  },
  {
    name: BodyMeasurement.THIGH_LEFT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.THIGH_RIGHT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.BICEPS_LEFT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.BICEPS_RIGHT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.CALF_LEFT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.CALF_RIGHT,
    unit: "cm",
  },
  {
    name: BodyMeasurement.NECK,
    unit: "cm",
  },
];
