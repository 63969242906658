import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE, translations } from "@fitness-app/i18n";

void i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: DEFAULT_LANGUAGE,
    defaultNS: "common",
    ns: ["common", "auth", "products", "dashboard", "team"],
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
