import React, { useEffect, useRef } from "react";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { Route, Routes } from "react-router-dom";

import { productsActions, productsSettingsActions } from "@fitness-app/app-store";
import { RequestStatus } from "@fitness-app/data-models";

import Product from "~/modules/Products/Product/Product";
import ProductRedirect from "~/modules/Products/Product/ProductRedirect";
import Products from "~/modules/Products/Products";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ProductsRouter = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const documentVisible = useVisibilityChange();
  const afterInit = useRef(false);
  const productsListStatus = useAppSelector((store) => store.products.productsListStatus);

  useEffect(() => {
    if (documentVisible && productsListStatus !== RequestStatus.SUBSCRIBED && afterInit.current) {
      dispatch(productsActions.subscribeToProducts());
      dispatch(productsSettingsActions.subscribeToProductsSettings());
    }
  }, [documentVisible, productsListStatus]);

  useEffect(() => {
    dispatch(productsActions.subscribeToProducts());
    dispatch(productsSettingsActions.subscribeToProductsSettings());

    afterInit.current = true;

    return () => {
      dispatch(productsActions.unsubscribeFromProducts());
      dispatch(productsSettingsActions.unsubscribeFromProductsSettings());
    };
  }, []);

  return (
    <Routes>
      <Route index element={<Products />} />
      <Route path="/:id" element={<ProductRedirect />} />
      <Route path="/:id/:tab/*" element={<Product />} />
    </Routes>
  );
};

export default ProductsRouter;
