import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { type Gender } from "@fitness-app/data-models/entities/shared";

import i18nextConfig from "~/i18nextConfig";

export interface TraineeProfileFormModel {
  firstName: string;
  lastName: string;
  assignedTrainerId: string | null;
  comment: string | null;
  email: string;
  gender: Gender.MALE | Gender.FEMALE | null;
  tags: string[];
  type: AppAccessType;
  phoneNumber?: string | null;
}

export const accessTypeOptions = [
  {
    label: i18nextConfig.t("trainees:accessType.fullAccessOption"),
    value: AppAccessType.FullAccess,
  },
  {
    label: i18nextConfig.t("trainees:accessType.limitedOption"),
    value: AppAccessType.Limited,
  },
];
