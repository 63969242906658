import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { programBuilderActions } from "@fitness-app/app-store";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import ProgramBuilderProvider, { type IProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface SharedProgramProviderProps {
  children: React.ReactElement;
}

const SharedProgramProvider = ({ children }: SharedProgramProviderProps) => {
  const {
    programStatus,
    programDetails,
    programId: programIdFromStore,
    selectedProgram,
  } = useAppSelector((state) => state.programBuilder);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { isTrainer } = useUserRole();

  if (!id) {
    throw new Error("Program ID is required");
  }

  const programId: string = programIdFromStore || id;

  const duplicateWorkoutInProgram: IProgramBuilderContext["duplicateWorkoutInProgram"] = useCallback(
    async ({ workout }) => {
      await dispatch(
        programBuilderActions.duplicateWorkoutInProgram({
          workout,
          programId,
        }),
      );
    },
    [dispatch, programId],
  );

  const fetchProgramWithDetails: IProgramBuilderContext["fetchProgramWithDetails"] = useCallback(async () => {
    if (id) {
      await dispatch(programBuilderActions.fetchProgramWithDetails({ id }));
    }
  }, [dispatch, id]);

  const addNewWorkoutToProgram: IProgramBuilderContext["addNewWorkoutToProgram"] = useCallback(
    async ({ workoutId, workoutTemplates }) => {
      await dispatch(
        programBuilderActions.addNewWorkoutToProgram({
          programId,
          workoutId,
          workoutTemplates,
        }),
      );
    },
    [dispatch, programId],
  );

  const removeWorkoutFromProgram: IProgramBuilderContext["removeWorkoutFromProgram"] = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ workoutId }) => {
      void dispatch(
        programBuilderActions.removeWorkoutFromProgram({
          programId,
          workoutId,
        }),
      );
    },
    [dispatch, programId],
  );

  const replaceWorkoutInProgram: IProgramBuilderContext["replaceWorkoutInProgram"] = useCallback(
    async ({ workoutIdToReplace, workoutTemplate }) => {
      await dispatch(
        programBuilderActions.replaceWorkoutInProgram({
          programId,
          workoutIdToReplace,
          workoutTemplate,
        }),
      );
    },
    [dispatch, programId],
  );

  const updateWorkoutInProgram: IProgramBuilderContext["updateWorkoutInProgram"] = useCallback(
    async ({ workout }) => {
      await dispatch(
        programBuilderActions.updateWorkoutInProgram({
          programId,
          workout,
        }),
      );
    },
    [dispatch, programId],
  );

  return (
    <ProgramBuilderProvider
      programId={programId}
      programStatus={programStatus}
      selectedProgram={selectedProgram}
      programDetails={programDetails}
      duplicateWorkoutInProgram={duplicateWorkoutInProgram}
      fetchProgramWithDetails={fetchProgramWithDetails}
      addNewWorkoutToProgram={addNewWorkoutToProgram}
      removeWorkoutFromProgram={removeWorkoutFromProgram}
      replaceWorkoutInProgram={replaceWorkoutInProgram}
      updateWorkoutInProgram={updateWorkoutInProgram}
      showRecordingVideoSwitch={isTrainer}
    >
      {children}
    </ProgramBuilderProvider>
  );
};

export default SharedProgramProvider;
