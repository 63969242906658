import React, { useState } from "react";
import { EditOutlined, ScheduleOutlined } from "@ant-design/icons";
import { Divider, Form, Modal, Skeleton, Steps } from "antd";
import { useTranslation } from "react-i18next";

import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import ProgramWeekSchedule from "~/modules/Automation/ScheduleProgramStepper/steps/ProgramWeekSchedule";
import { type ProgramScheduleFormModel } from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import TraineeProgramBuilder from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramBuilder";
import { useAppSelector } from "~/store/initializeStore";

interface ScheduledClientProgramStepperProps {
  visible: boolean;
  handleClose: () => void;
  program: ClientTrainingProgram;
  onScheduleSave: (model: ProgramScheduleFormModel) => Promise<void> | void;
}

enum ProgramStep {
  UpdateWorkout,
  SetSchedule,
}

const ScheduledClientProgramStepper = ({
  visible,
  handleClose,
  program,
  onScheduleSave,
}: ScheduledClientProgramStepperProps) => {
  const [step, setStep] = useState(ProgramStep.UpdateWorkout);
  const [scheduleProgramControllerForm] = Form.useForm<ProgramScheduleFormModel>();
  const [saving, toggleSaving] = useState(false);
  const { t } = useTranslation("automation");
  const { programDetails } = useAppSelector((store) => store.traineeProgram);

  const programId = program.id;

  const onNext = () => {
    if (step === ProgramStep.SetSchedule) {
      scheduleProgramControllerForm.submit();
    }

    if (step === ProgramStep.UpdateWorkout) {
      setStep(ProgramStep.SetSchedule);
    }
  };

  const handleCancelButton = () => {
    if (step === ProgramStep.UpdateWorkout) {
      handleClose();
      return;
    }

    setStep((prev) => prev - 1);
  };

  const handleScheduleSave = async (model: ProgramScheduleFormModel) => {
    toggleSaving(true);
    await onScheduleSave(model);
    toggleSaving(false);
    handleClose();
  };

  const renderStep = () => {
    switch (step) {
      case ProgramStep.UpdateWorkout:
        return <TraineeProgramBuilder assignedProgramId={programId} />;

      case ProgramStep.SetSchedule:
        return programDetails ? (
          <ProgramWeekSchedule
            programWorkouts={programDetails}
            schedule={program.workoutsSchedule?.schedule}
            formController={scheduleProgramControllerForm}
            onSubmit={handleScheduleSave}
          />
        ) : (
          <Skeleton active paragraph={{ rows: 10 }} />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancelButton}
      onOk={onNext}
      destroyOnClose
      width={950}
      title="Edytuj zaplanowany plan treningowy"
      okButtonProps={{
        loading: saving,
      }}
      cancelText={step === ProgramStep.UpdateWorkout ? t("common:button.cancel") : t("common:button.prev")}
      okText={step === ProgramStep.SetSchedule ? t("common:button.add") : t("common:button.next")}
    >
      <Steps current={step}>
        <Steps.Step key={0} title="Edycja treningów" icon={<EditOutlined />} />
        <Steps.Step key={1} title="Edytuj harmonogram treningów" icon={<ScheduleOutlined />} />
      </Steps>
      <Divider />
      <div className="p-6">{renderStep()}</div>
    </Modal>
  );
};

export default ScheduledClientProgramStepper;
