import { type ProductAutomation } from "@fitness-app/data-models/entities/Automation";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PRODUCT_AUTOMATION_REDUCER_NAME = "ProductAutomation";

export interface ProductAutomationReducer {
  data: ProductAutomation | null;
  status: null | RequestStatus;
  error: null | string;
}
