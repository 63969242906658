import dayjs from "dayjs";

import { SurveyAction, SurveyActionOwner } from "@fitness-app/data-models/entities/ClientSurvey";
import { type SurveyTemplate } from "@fitness-app/data-models/entities/SurveyTemplate";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useAppSelector } from "~/store/initializeStore";

export const useGenerateBaseClientSurvey = () => {
  const { userId } = useUserRole();
  const profile = useAppSelector((store) => store.trainee.profile);

  const generateBaseClientSurvey = (template: SurveyTemplate) => {
    if (!profile) {
      throw new Error("Trainee profile is not fetched");
    }

    return {
      id: generateUniqId(),
      answeredAt: null,
      reminderId: null,
      createdAt: dayjs().toISOString(),
      updatedAt: dayjs().toISOString(),
      traineeId: profile.id,
      userId: profile.userId,
      name: template.name,
      description: template.description,
      templateId: template.id,
      questions: template.questions,
      automation: template.automation || null,
      answers: null,
      logs: [
        {
          action: SurveyAction.Created,
          date: dayjs().toISOString(),
          actionOwner: SurveyActionOwner.Trainer,
          actionOwnerId: userId,
        },
      ],
      notRemovable: false,
      addedBy: userId,
    };
  };

  return {
    generateBaseClientSurvey,
  };
};
