import React, { useState } from "react";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { DatePicker, message, Spin } from "antd";
import dayjs from "dayjs";

import { traineeProgramActions } from "@fitness-app/app-store";
import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";

import { useAppDispatch } from "~/store/initializeStore";

interface TraineeProgramTerminationProps {
  activeProgram: ClientTrainingProgram;
  traineeId: string;
}

const TraineeProgramTermination = ({ activeProgram, traineeId }: TraineeProgramTerminationProps) => {
  const [activeDatePicker, setActivePicker] = useState(false);
  const [showLoader, toggleLoader] = useState(false);
  const dispatch = useAppDispatch();
  const endDate = dayjs(activeProgram.endDate, "YYYY-MM-DD");
  const changeTerminationPlan = async (value: dayjs.Dayjs | null) => {
    if (!value) {
      void message.error("Plan musi mieć datę końcową");
      return;
    }
    toggleLoader(true);
    await dispatch(
      traineeProgramActions.updateProgramEndDate({
        endDate: value.format("YYYY-MM-DD"),
        traineeId,
        programId: activeProgram.id,
      }),
    );
    toggleLoader(false);
    setActivePicker(false);
  };

  if (showLoader) return <Spin />;

  return (
    <div className="flex items-center">
      {activeDatePicker ? (
        <DatePicker
          onChange={changeTerminationPlan}
          defaultValue={endDate}
          placeholder="Wybierz tydzień"
          disabled={showLoader}
          size="small"
          format="DD.MM.YYYY"
          allowClear={false}
          disabledDate={(current) =>
            current < dayjs().startOf("week") || current > dayjs().startOf("week").add(53, "week")
          }
        />
      ) : (
        <div>{activeProgram.endDate ? endDate.format("DD.MM.YYYY") : "-"}</div>
      )}
      {activeDatePicker ? (
        <CloseOutlined className="ml-5 cursor-pointer text-red-500" onClick={() => setActivePicker(false)} />
      ) : (
        <EditOutlined className="ml-5 cursor-pointer text-green-500" onClick={() => setActivePicker(true)} />
      )}
    </div>
  );
};

export default TraineeProgramTermination;
