import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  MeasurementType,
  type BodyMeasurements,
  type WeightMeasurement,
} from "@fitness-app/data-models/entities/Measurement";

import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  page?: number;
};

export const fetchBodyAndWeightMeasurements = createAsyncThunk<
  { data: (BodyMeasurements | WeightMeasurement)[]; currentPage: number; totalPages: number },
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/fetchBodyAndWeightMeasurements`,
  async ({ traineeId, page }, { rejectWithValue, getState, extra: { db } }) => {
    const { measurementsPageSize } = getState().traineeMeasurements;

    const { start, stop, currentPage } = getPageRange(page, measurementsPageSize);

    const { error, data, count } = await db
      .from("measurement")
      .select("*", { count: "exact" })
      .order("eventTimestamp", { ascending: false })
      .eq("traineeId", traineeId)
      .in("type", [MeasurementType.BodyMeasurements, MeasurementType.WeightMeasurement])
      .range(start, stop)
      .limit(measurementsPageSize)
      .returns<(BodyMeasurements | WeightMeasurement)[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return { data: data || [], currentPage, totalPages: count ? Math.ceil(count / measurementsPageSize) : 0 };
  },
);
