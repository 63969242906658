import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { teamActions } from "@fitness-app/app-store";

import TraineeList from "~/modules/Trainee/TraineeList";
import TraineeProfile from "~/modules/Trainee/TraineeProfile/TraineeProfile";
import { useAppDispatch } from "~/store/initializeStore";

const TraineeRouter = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    void dispatch(teamActions.fetchTrainerConfig());
    void dispatch(teamActions.fetchAppVersionConfig());
  }, []);

  return (
    <Routes>
      <Route index element={<TraineeList />} />
      <Route path="/details/:id" element={<TraineeProfile />} />
      <Route path="/:tab/*" element={<TraineeList />} />
    </Routes>
  );
};

export default TraineeRouter;
