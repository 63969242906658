import React from "react";

interface ListItemProps {
  iconPath?: string | React.ReactElement;
  title: string | React.ReactElement;
  desc?: string | React.ReactElement;
  endContent?: React.ReactElement;
}

const ListItem = ({ iconPath, title, endContent, desc }: ListItemProps) => {
  return (
    <div className="flex items-center px-2.5 py-5">
      {iconPath && (
        <div className="flex-1">
          {typeof iconPath === "string" ? (
            <img className="h-10 w-10" src={iconPath} alt={`${typeof title === "string" ? title : "title"}-img`} />
          ) : (
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-200 p-2">{iconPath}</div>
          )}
        </div>
      )}
      <div className="flex-[3] text-left text-sm">
        <div className="font-semibold text-gray-900">{title}</div>
        {desc && <div className="mt-1.5 w-full overflow-hidden text-gray-500">{desc}</div>}
      </div>
      <div className="flex-1">{endContent}</div>
    </div>
  );
};

export default ListItem;
