import React, { useEffect, type FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { productResourcesActions } from "@fitness-app/app-store";

import { useAppDispatch } from "~/store/initializeStore";
import ResourceEditor from "./components/ResourceEditor/ResourceEditor";
import ResourcesList from "./components/ResourcesList/ResourcesList";

interface OwnProps {
  ownerId?: string;
  readonly?: boolean;
  productId: string;
}

type Props = OwnProps;

const ProductResources: FunctionComponent<Props> = ({ productId }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (productId) {
      void dispatch(productResourcesActions.fetchProductResources({ productId }));
      void dispatch(productResourcesActions.fetchResourcesCategories());
    }
  }, [productId, dispatch]);

  return (
    <Routes>
      <Route index element={<ResourcesList productId={productId} />} />
      <Route path="/:resourceId" element={<ResourceEditor productId={productId} />} />
    </Routes>
  );
};

export default ProductResources;
