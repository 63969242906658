import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  SurveyAction,
  SurveyActionOwner,
  SurveyStatus,
  type ClientSurvey,
} from "@fitness-app/data-models/entities/ClientSurvey";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_SURVEYS_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  answers: ClientSurvey["answers"];
  logs: ClientSurvey["logs"];
};

export const sendTraineeSurvey = createAsyncThunk<ClientSurvey, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_SURVEYS_REDUCER_NAME}/sendTraineeSurvey`,
  async ({ answers, id, logs }, { rejectWithValue, extra: { db, auth, analytics, errorTrackingService } }) => {
    const authUser = await getLoggedUser(auth);

    const survey: Partial<ClientSurvey> = {
      answers,
      status: SurveyStatus.Fulfilled,
      answeredAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      logs: [
        ...logs,
        {
          date: new Date().toISOString(),
          action: SurveyAction.Fulfilled,
          actionOwner: SurveyActionOwner.Client,
          actionOwnerId: authUser.id,
        },
      ],
    };

    const { error, data } = await db
      .from("client_survey")
      .update(survey)
      .eq("id", id)
      .select("*")
      .single<ClientSurvey>();

    if (error) {
      errorTrackingService?.recordError(error, "sendTraineeSurvey");
      return rejectWithValue(error.message);
    }

    analytics.track("add_survey_answers", { answers: answers ? Object.keys(answers).length : 0 });

    return data;
  },
);
