import React, { type FunctionComponent } from "react";
import { Empty, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";

import { ChatStatus } from "@fitness-app/data-models/entities/Chat";

import { UploadFilesProvider } from "~/components/Upload/UploadProvider";
import ChatChannelsList from "~/modules/Chat/ChatChannelsList";
import ChatWindow from "~/modules/Chat/ChatWindow";
import { getUploadConfig } from "~/modules/Chat/uploadConfig";
import { useAppSelector } from "~/store/initializeStore";

const Chat: FunctionComponent = () => {
  const { t } = useTranslation("chat");
  const { activeChannels, channelsListStatus, channels } = useAppSelector((store) => store.chat, shallowEqual);
  const [channel] = activeChannels;

  return (
    <div className="chat-height relative z-0 flex flex-1 overflow-hidden">
      <main className="relative z-0 flex-1 overflow-y-hidden overscroll-none focus:outline-none xl:order-last">
        {channel ? (
          <UploadFilesProvider key={channel.id} {...getUploadConfig(channel.id)}>
            <ChatWindow channel={channel} fullScreen />
          </UploadFilesProvider>
        ) : (
          <div className="mt-24 xl:mt-36">
            <Empty description={t("selectChannel")} />
          </div>
        )}
      </main>
      <aside className="m-4 hidden w-96 flex-shrink-0 border-r border-gray-200 bg-white px-6 xl:order-first xl:flex xl:flex-col">
        <div className="py-4">
          <Typography.Title level={4}>{t("channels")}</Typography.Title>
        </div>
        <nav className="flex-1 overflow-y-auto " aria-label="Directory">
          <ChatChannelsList
            channels={channels}
            chatStatus={ChatStatus.Active}
            changeTab={() => null}
            status={channelsListStatus}
          />
        </nav>
      </aside>
    </div>
  );
};

export default Chat;
