import { type RequestStatus } from "@fitness-app/data-models";
import { type ExerciseAggregateStats, type ExerciseStats } from "@fitness-app/data-models/entities/ExerciseStats";

export const TRAINEE_WORKOUTS_STATS_REDUCER_NAME = "traineeWorkoutsStats";

export interface TraineeWorkoutsStatsReducer {
  exercisesRecordsList: ExerciseAggregateStats[];
  exercisesRecordsListStatus: null | RequestStatus;

  exerciseStatsList: ExerciseStats[];
  exerciseStatsListStatus: null | RequestStatus;
}
