import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  type SupplementsTemplate,
  type SupplementsTemplateWithCreator,
} from "@fitness-app/data-models/entities/SupplementTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { SUPPLEMENTS_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = {
  template: Partial<SupplementsTemplateWithCreator | (SupplementsTemplate & { creator?: never })>;
  templateId: string;
};

export const updateTemplate = createAsyncThunk<SupplementsTemplate | null, Payload, AsyncThunkCreator<string>>(
  `${SUPPLEMENTS_TEMPLATES_REDUCER_NAME}/updateTemplate`,
  async ({ template: { creator, ...rest }, templateId }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("supplements_template")
      .update(rest)
      .eq("id", templateId)
      .select()
      .single<SupplementsTemplate>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_supplements_template");

    return data || null;
  },
);
