import React, { type FunctionComponent } from "react";
import { type CalendarOutlined } from "@ant-design/icons";
import { Space } from "antd";

const IconText = ({ icon, text }: { icon: FunctionComponent<unknown> | typeof CalendarOutlined; text: string }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

export default IconText;
