import { useQuery } from "@tanstack/react-query";

import { CampaignStatus, CampaignType, type CampaignWithStats } from "@fitness-app/data-models/entities/Campaign";

import { supabase } from "~/store/initializeStore";

export const useCampaignsList = () => {
  const { data, isLoading, refetch, isError } = useQuery(["campaigns"], {
    queryFn: async () => {
      const { data, error } = await supabase
        .from("campaign")
        .select("*, stats:campaign_activity(type)")
        .eq("type", CampaignType.PopUp)
        .in("status", [
          CampaignStatus.Finished,
          CampaignStatus.Running,
          CampaignStatus.Scheduled,
          CampaignStatus.Cancelled,
        ])
        .order("createdAt", { ascending: false })
        .limit(100)
        .returns<CampaignWithStats[]>();

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        return [];
      }

      return data.map((campaign) => ({
        ...campaign,
        uniqueClicks: campaign.stats.filter((stat) => stat.type === "click").length,
        uniqueOpened: campaign.stats.filter((stat) => stat.type === "open").length,
      }));
    },
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
  };
};
