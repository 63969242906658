import { createAsyncThunk } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";

import {
  type ClientTrainingProgramDetails,
  type ProgramWorkout,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentTraineeProgram } from "../selectors/getCurrentProgram";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutId: string;
};

export const removeWorkoutFromProgram = createAsyncThunk<
  ClientTrainingProgramDetails,
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/removeWorkoutFromProgram`,
  async ({ programId, workoutId }, { rejectWithValue, getState, dispatch, extra: { db } }) => {
    const currentProgram = getCurrentTraineeProgram(getState());

    let removedWorkout: null | ProgramWorkout = null;

    const updatedWorkouts = currentProgram.details.workouts.filter((workout) => {
      if (workout.id === workoutId) {
        removedWorkout = workout;
      }
      return workout.id !== workoutId;
    });

    const archivedWorkouts = cloneDeep(currentProgram.details.archivedWorkouts || []);

    if (removedWorkout) {
      archivedWorkouts.push({
        ...(removedWorkout as ProgramWorkout),
        archivedAt: new Date().toISOString(),
      });
    }

    dispatch(updateWorkoutsInProgram({ workouts: updatedWorkouts, programId }));

    const { data: programDetails, error } = await db
      .from("client_training_program_details")
      .update({
        workouts: updatedWorkouts,
        archivedWorkouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<ClientTrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return programDetails;
  },
);
