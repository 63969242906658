import { type ExerciseTypeEnum, type ExerciseWithVideo } from "./Exercise";
import {
  type ExerciseInWorkout,
  type ExerciseSet,
  type SuperSet,
  type WeightHint,
  type WorkoutExercisesType,
} from "./TrainingProgram";

export enum LiveWorkoutStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ExerciseInWorkoutType {
  Normal = "normal",
  PreWorkout = "preWorkout",
  PostWorkout = "postWorkout",
  SuperSet = "superSet",
}

export enum RestType {
  AfterSeries = "afterSeries",
  AfterExercise = "afterExercise",
  AfterSuperSetExercise = "afterSuperSetExercise",
}

export enum WorkoutSliderType {
  ExerciseInfo = "exerciseInfo",
  StartInfo = "startInfo",
  EndInfo = "endInfo",
  ProgressInfo = "progressInfo",
}

export interface ExerciseInfo {
  type: WorkoutSliderType.ExerciseInfo;
  exercisesInfo: ExerciseInWorkout[];
  isSuperSet: boolean;
  workoutPart: WorkoutExercisesType;
}

export interface StartInfo {
  type: WorkoutSliderType.StartInfo;
  exercises: number;
  supersets: number;
  comment: string;
  name: string;
  workoutPart?: never;
}

export interface EndInfo {
  type: WorkoutSliderType.EndInfo;
  workoutPart?: never;
}

export interface ProgressInfo {
  type: WorkoutSliderType.ProgressInfo;
  workoutPart?: never;
}
interface Rest {
  restTime: number;
  type: RestType;
  rowKey: string;
}

export interface RestBetweenExercises extends Rest {
  type: RestType.AfterExercise;
  workoutPart?: never;
}

export interface RestBetweenExercisesInSet extends Rest {
  series: number;
  type: RestType.AfterSuperSetExercise;
  workoutPart: WorkoutExercisesType;
}

export interface RestBetweenSeries extends Rest {
  type: RestType.AfterSeries;
  workoutPart: WorkoutExercisesType;
}

export interface SuperSetSeries extends ExerciseSet {
  type: ExerciseInWorkoutType.SuperSet;
  name: string;
  exerciseId: string;
  rowKey: string;
  series: number;
  exerciseType: ExerciseTypeEnum;
  restTime: number;
  workoutId: string;
  withHint?: WeightHint;
  totalSeries: number;
  workoutPart: WorkoutExercisesType;
  activeWorkoutId: string;
  exercise: ExerciseWithVideo;
  exerciseNumber: number;
  numberOfExercises: number;
  exerciseInWorkout: ExerciseInWorkout;
  exerciseInSuperSetIds: string[];
}
export interface ExerciseSeries extends ExerciseSet {
  type: ExerciseInWorkoutType.Normal;
  name: string;
  restTime: number;
  exerciseId: string;
  rowKey: string;
  series: number;
  exerciseType: ExerciseTypeEnum;
  workoutId: string;
  withHint?: WeightHint;
  totalSeries: number;
  workoutPart: WorkoutExercisesType;
  activeWorkoutId: string;
  exercise: ExerciseWithVideo;
  exerciseInWorkout: ExerciseInWorkout;
}

export interface SuperSetInWorkout extends SuperSet {
  type: ExerciseInWorkoutType.SuperSet;
  series: SuperSetSeriesTypes[];
  workoutPart: WorkoutExercisesType;
  exerciseInSuperSetIds: string[];
}

export interface ExerciseInLiveWorkout extends ExerciseInWorkout {
  type: ExerciseInWorkoutType.Normal;
  series: ExerciseSeriesTypes[];
  workoutPart: WorkoutExercisesType;
}

export type SuperSetSeriesTypes = SuperSetSeries | RestBetweenExercisesInSet | RestBetweenSeries;
export type ExerciseSeriesTypes = ExerciseSeries | RestBetweenSeries;

export type LiveWorkoutData = (ExerciseInLiveWorkout | SuperSetInWorkout | RestBetweenExercises)[];

export type WorkoutSliderItem =
  | RestBetweenExercises
  | SuperSetSeriesTypes
  | ExerciseSeriesTypes
  | ExerciseInfo
  | StartInfo
  | EndInfo
  | ProgressInfo;

export type WorkoutSliderItemType = WorkoutSliderType | RestType | ExerciseInWorkoutType;
export interface Timer {
  secondsTotal: number;
  secondsLeft: number;
  active: boolean;
  unit: number;
}

export type WorkoutStepType = WorkoutSliderItemType | null;
