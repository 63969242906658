import { createAsyncThunk } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";

import {
  type ClientTrainingProgramDetails,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import groupInSuperSet from "@fitness-app/utils/src/programs/superSetHelpers/groupInSuperSet";

import { type AsyncThunkCreator } from "../../../index";
import { updateWorkoutsInProgram } from "../reducer";
import { getCurrentTraineeProgram } from "../selectors/getCurrentProgram";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";

type Payload = {
  programId: string;
  workoutId: string;
  exercisesToGroup: string[];
  exercisesType: WorkoutExercisesType;
};

export const groupExercisesIntoClientSuperSet = createAsyncThunk<
  ClientTrainingProgramDetails,
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/groupExercisesIntoClientSuperSet`,
  async (
    { programId, workoutId, exercisesType, exercisesToGroup },
    { dispatch, rejectWithValue, getState, extra: { db } },
  ) => {
    const currentProgram = getCurrentTraineeProgram(getState());

    const updatedWorkouts = cloneDeep(currentProgram.details.workouts);
    const workout = updatedWorkouts.find((workout) => workout.id);

    if (!workout) {
      return rejectWithValue("No workout to update");
    }

    const updatedExercises = groupInSuperSet({
      exercisesToGroup,
      exercises: workout[exercisesType] || [],
      fromArray: true,
      toArray: true,
    });

    const workouts = updatedWorkouts.map((workout) => {
      if (workout.id === workoutId) {
        return {
          ...workout,
          [exercisesType]: updatedExercises,
        };
      }
      return workout;
    });

    dispatch(updateWorkoutsInProgram({ workouts, programId }));

    const { data: programDetails, error } = await db
      .from("client_training_program_details")
      .update({
        workouts,
        updatedAt: new Date().toISOString(),
      })
      .eq("programId", programId)
      .select()
      .single<ClientTrainingProgramDetails>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return programDetails;
  },
);
