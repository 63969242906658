import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ScheduledItems, type WorkoutRoutine } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../../index";
import { fetchTrainee } from "../../trainee/actions";
import { getParentTrainerId } from "../../user/selectors";
import { AUTOMATION_REDUCER_NAME } from "../types";

type Payload = {
  events: ScheduledItems[];
  scheduledPrograms: WorkoutRoutine[];
  startDate: string;
  clientId: string;
  programAutomationId: string;
  programAutomationName: string;
};

export const addAutomationToClient = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${AUTOMATION_REDUCER_NAME}/addAutomationToClient`,
  async (payload, { getState, dispatch, extra: { automationApi } }) => {
    const parentTrainerId = getParentTrainerId(getState());
    await automationApi.post("/program-automation/add", { ...payload, trainerId: parentTrainerId });

    void dispatch(fetchTrainee({ refetch: true, id: payload.clientId }));
  },
);
