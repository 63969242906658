import { createAsyncThunk } from "@reduxjs/toolkit";
import { type CheckoutSettings } from "@fitness-app/data-models/entities/ProductsSettings";
import { getErrorMessage } from "@fitness-app/utils";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCTS_SETTINGS_REDUCER_NAME } from "../types";

export const updateCheckoutSettings = createAsyncThunk<void, ActionParams<CheckoutSettings>, AsyncThunkCreator<string>>(
  `${PRODUCTS_SETTINGS_REDUCER_NAME}/updateCheckoutSettings`,
  async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
      const { id } = await getLoggedUser(auth);

      const { error } = await db.from("products_settings").update({ checkoutSettings: payload }).eq("userId", id);

      if (error) {
        throw new Error(error.message);
      }

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
