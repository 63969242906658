import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { SUPPLEMENTS_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = string;

export const removeTemplate = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${SUPPLEMENTS_TEMPLATES_REDUCER_NAME}/removeTemplate`,
  async (templateId, { rejectWithValue, extra: { db } }) => {
    const { error } = await db.from("supplements_template").delete().eq("id", templateId);

    if (error) {
      return rejectWithValue(error.message);
    }
    return templateId;
  },
);
