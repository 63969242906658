import { useState } from "react";

import { traineeMeasurementsActions } from "@fitness-app/app-store";
import { type BodyMeasurements } from "@fitness-app/data-models/entities/Measurement";

import { useLoading } from "~/hooks/useLoading";
import { type BodyMeasurementFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/BodyMeasurementForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const useBodyMeasurementForm = () => {
  const [addingBodyMeasurement, startAddingBodyMeasurement, finishBodyWeightMeasurement] = useLoading();
  const [showBodyMeasurementModal, toggleBodyMeasurementModal] = useState(false);
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.trainee.profile);

  const onAddNewBodyMeasurement = async (formModel: BodyMeasurementFormModel, traineeId: string) => {
    startAddingBodyMeasurement();
    try {
      await dispatch(
        traineeMeasurementsActions.upsertMeasurement({
          data: formModel.data as BodyMeasurements["data"],
          eventDate: formModel.date.format("YYYY-MM-DD"),
          id: formModel.id,
          traineeId,
          userId: profile?.userId,
          previousMeasurement: formModel.previousMeasurement,
        }),
      ).unwrap();
      finishBodyWeightMeasurement();
      toggleBodyMeasurementModal(false);
    } catch {
      finishBodyWeightMeasurement();
    }
  };

  return {
    onAddNewBodyMeasurement,
    showBodyMeasurementModal,
    addingBodyMeasurement,
    toggleBodyMeasurementModal,
  };
};
