import { createSelector } from "reselect";

import { TeamMemberRole, type UserRoleEnum } from "@fitness-app/data-models";
import { type TeamMemberRoleEnum } from "@fitness-app/data-models/entities/TeamMember";

import { type AppStore } from "../../../index";

const getUser = (state: AppStore) => state.user;

type ReturnData = {
  trainerId: string | null;
  memberRole?: TeamMemberRoleEnum;
  memberId?: string;
  isRegularTrainer: boolean;
  isSharedTrainer: boolean;
  role: UserRoleEnum | null;
};

export const getUserClaims = createSelector([getUser], (user): ReturnData => {
  return {
    trainerId: user?.customClaims?.trainerId || null,
    memberRole: user?.customClaims?.memberRole,
    memberId: user?.customClaims?.memberId,
    role: user?.profile?.role || null,
    isRegularTrainer: user?.customClaims?.memberRole === TeamMemberRole.REGULAR_TRAINER,
    isSharedTrainer: user?.customClaims?.memberRole === TeamMemberRole.SHARED_TRAINER,
  };
});
