import React, { useEffect, useMemo, useRef, useState, type FunctionComponent } from "react";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import type EditorJS from "@editorjs/editorjs";
import { type OutputData } from "@editorjs/editorjs";
import { Button, Card, Divider, Form, Input, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { productResourcesActions } from "@fitness-app/app-store";
import {
  ProductResourceStatus,
  type ProductResourceStatusEnum,
} from "@fitness-app/data-models/entities/ProductResource";
import { DEFAULT_RESOURCE_CATEGORY } from "@fitness-app/utils/src/constants/defaultResourceCategory";

import Editor from "~/components/Editor/Editor";
import { createTools } from "~/components/Editor/tools";
import ImageUploadField from "~/components/ImageUploadField/ImageUploadField";
import UploadField from "~/components/UploadField/UploadField";
import { useEntityChange } from "~/hooks/useEntityChange";
import { productResourceStatusOptions } from "~/modules/Products/Product/ProductResources/shared";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

interface ResourceEditorModel {
  title: string;
  content: string | OutputData;
  attachments: { url: string; name: string; uid: string }[];
  images: string[];
  status: ProductResourceStatusEnum;
  category: string;
}

const ENTER_KEY = 13;

const ResourceEditor: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation(["products", "common"]);
  const [formController] = Form.useForm<ResourceEditorModel>();
  const editorRef = useRef<EditorJS>();
  const userDetails = useAppSelector((store) => store.user.profile);
  const { resourceId } = useParams<{ resourceId: string }>();
  const resources = useAppSelector((store) => store.productResources.data);
  const categories = useAppSelector((store) => store.productResources.categories);
  const editedResource = resources.find((item) => item.id === resourceId);
  const [categoryInput, setCategoryInputValue] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, onSuccess, onFailure] = useEntityChange(() => {
    navigate(-1);
  });

  const content = editedResource?.content;
  const resourceCategories = categories?.length
    ? [{ name: DEFAULT_RESOURCE_CATEGORY, default: true, id: "default" }, ...categories]
    : [{ name: DEFAULT_RESOURCE_CATEGORY, default: true, id: "default" }];

  const editorModel = useMemo(() => (content ? JSON.parse(content) : ""), [content]) as OutputData;

  const categoriesOptions = useMemo(() => {
    if (!resourceCategories?.length) {
      return [
        {
          value: DEFAULT_RESOURCE_CATEGORY,
          label: t("productResources.form.general"),
        },
      ];
    }
    return resourceCategories.map((category) =>
      "default" in category && category.default
        ? { value: category.name, label: t("productResources.form.general") }
        : { value: category.id, label: category.name },
    );
  }, [resourceCategories]);

  useEffect(() => {
    if (editedResource) {
      formController?.setFieldsValue({
        ...editedResource,
        status: editedResource.status || ProductResourceStatus.DRAFT,
        category: editedResource.category || DEFAULT_RESOURCE_CATEGORY,

        content: JSON.parse(editedResource.content ?? "") as OutputData,
      });
    }
  }, [resourceId, editedResource]);

  const storageRef = useMemo(() => `${userDetails?.id}/products/${productId}/resources`, [userDetails, productId]);

  const tools = createTools({ storageRef });

  if (!productId || !userDetails?.id || !tools) {
    return null;
  }

  const handleSubmit = async (data: ResourceEditorModel) => {
    const savedData = await editorRef.current?.save();
    const contentInJSON = JSON.stringify(savedData);

    if (editedResource) {
      const model = {
        ...data,
        id: editedResource.id,
        htmlContent: contentInJSON,
        content: contentInJSON,
      };

      void dispatch(
        productResourcesActions.updateProductResource({
          payload: { productId, model },
          onFailure,
          onSuccess,
        }),
      );
    } else {
      const model = {
        ...data,
        type: "IN_APP",
        category: data.category || "general",
        htmlContent: contentInJSON,
        content: contentInJSON,
      } as const;

      void dispatch(
        productResourcesActions.addProductResource({
          payload: { productId, model },
          onFailure,
          onSuccess,
        }),
      );
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const pressSendButton = event.keyCode === ENTER_KEY && !event.shiftKey;

    if (pressSendButton) {
      addCategory();
    }
  };

  const addCategory = () => {
    if (!categoryInput || categoryInput.length < 3) {
      void message.warning(t("common:validationErrors.minLength", { number: 3 }));
      return;
    }

    if (categoriesOptions.find((category) => category.label?.toLowerCase() === categoryInput.toLowerCase())) {
      void message.warning(t("productResources.form.uniqCategory", { number: 3 }));
      return;
    }

    void dispatch(
      productResourcesActions.addResourceCategory({
        model: {
          name: categoryInput,
        },
        productId,
      }),
    );

    const selectedCategory = categoryInput;

    setTimeout(() => {
      formController.setFieldsValue({
        category: selectedCategory,
      });
    }, 200);

    setCategoryInputValue("");
  };

  return (
    <Card
      style={{ minHeight: 500 }}
      extra={
        <Button
          loading={Boolean(loading)}
          icon={<SaveOutlined />}
          type="primary"
          onClick={() => formController?.submit()}
        >
          {editedResource ? t("button.update") : t("button.save")}
        </Button>
      }
    >
      <Form<ResourceEditorModel>
        layout="vertical"
        onFinish={handleSubmit}
        form={formController}
        initialValues={{
          title: "",
          content: editedResource?.content ?? "",
          images: editedResource?.images ?? [],
          attachments: editedResource?.attachments ?? [],
          status: editedResource?.status ?? ProductResourceStatus.DRAFT,
          category: editedResource?.category || DEFAULT_RESOURCE_CATEGORY,
        }}
      >
        <Form.Item
          name="title"
          label={t("productResources.form.title")}
          wrapperCol={{ span: 12 }}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
            {
              min: 5,
              message: t<string>("common:validationErrors.minLength", {
                number: 5,
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="category"
          label={t("productResources.form.category")}
          wrapperCol={{ xl: 6, lg: 12 }}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            options={categoriesOptions}
            placeholder={t("productResources.form.selectCategory")}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                  <Input
                    style={{ flex: "auto" }}
                    value={categoryInput}
                    size="small"
                    onKeyDown={handleInputKeyDown}
                    onChange={(e) => setCategoryInputValue(e.target.value)}
                  />
                  <a
                    style={{
                      flex: "none",
                      padding: "8px",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={addCategory}
                  >
                    <PlusOutlined /> {t("productResources.form.addCategory")}
                  </a>
                </div>
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          name="status"
          label={t("productResources.form.status")}
          wrapperCol={{ xl: 6, lg: 12 }}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
            {
              min: 5,
              message: t<string>("common:validationErrors.minLength", {
                number: 5,
              }),
            },
          ]}
        >
          <Select options={productResourceStatusOptions(t)} />
        </Form.Item>

        <Form.Item
          tooltip={t<string>("products:form.imageAdditionalInfo")}
          name="images"
          label={t<string>("productResources.form.images")}
          valuePropName="fileList"
          getValueFromEvent={(e: unknown) => e}
        >
          <ImageUploadField
            storageRef={`${userDetails.id}/products/${productId}/resources`}
            bucket="users"
            multiple={false}
            withCrop={false}
            fileList={editedResource?.images}
          />
        </Form.Item>

        <Form.Item
          tooltip={t<string>("products:form.fileAdditionalInfo")}
          name="attachments"
          label={t<string>("productResources.form.attachments")}
          valuePropName="fileList"
          getValueFromEvent={(e: unknown) => e}
          wrapperCol={{ span: 12 }}
        >
          <UploadField
            multiple
            bucket="users"
            fileList={editedResource?.attachments}
            storageRef={`${userDetails.id}/products/${productId}/resources`}
          />
        </Form.Item>

        <Form.Item name="content" label={t<string>("productResources.form.content")}>
          {editorModel || resourceId === "add" ? (
            <Editor
              tools={tools}
              data={editorModel}
              instanceRef={(instance) => {
                editorRef.current = instance;
              }}
            />
          ) : null}
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ResourceEditor;
