import React from "react";
import { Input } from "antd";

interface TextAnswerProps {
  disabled?: boolean;
  value?: string;
}

const TextAnswer = ({ value, disabled }: TextAnswerProps) => {
  const props = value !== undefined ? { value } : {};
  return (
    <Input.TextArea
      rows={3}
      className="width-[70%] mx-auto my-0 block !bg-white !text-gray-800"
      {...props}
      disabled={disabled}
    />
  );
};

export default TextAnswer;
