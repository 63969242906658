import { type Database } from "@fitness-app/supabase";

import { type Campaign } from "./Campaign";
import { type UnreadMessages } from "./Chat";
import { type SurveyType } from "./ClientSurvey";
import { type BodyMeasurements, type ClientPhoto, type TrainingFeedback, type WeightMeasurement } from "./Measurement";
import { type RequiresAction } from "./Order";
import { type Currency, type OrderPaymentType } from "./Product";
import { type ClientArchiveReason, type SubscriptionInterval } from "./ProductClient";

export type NotificationEntity = Database["public"]["Tables"]["notification"]["Row"];

export enum NotificationType {
  ClientTerminated = "client_terminated",
  ClientPurchaseProduct = "client_purchase_product",
  ClientRenewedSubscription = "client_renewed_product",
  ClientSubscriptionPlanUpdated = "client_subscription_plan_updated",
  ClientRenewedAccessPeriod = "client_renewed_access_to_product",
  ClientsImportFinished = "clients_import_finished",
  ClientCancelSubscriptionAtPeriodEnd = "client_cancel_subscription_at_period_end",
  ClientResumeSubscriptionAtPeriodEnd = "client_resume_subscription_at_period_end",
  ProductAccessPeriodEndingIn14Days = "Product_access_period_ending_in_14_days",
  ProductAccessPeriodEndingIn7Days = "Product_access_period_ending_in_7_days",
  ProductAccessPeriodEndingIn3Days = "Product_access_period_ending_in_3_days",
  ProductAccessPeriodEndingIn1Day = "Product_access_period_ending_in_1_day",
  ProductPaymentRequiredAction = "Product_payment_required_action",
  ProductSubscriptionEnd = "Product_subscription_end",
  ProductAccessPeriodEnd = "Product_access_period_end",
  VideoBeenUploaded = "video_been_uploaded",
  ClientSurveyAnswered = "client_survey_answered",
  ClientSurveyAnswersUpdated = "client_survey_answers_updated",
  ClientAddedWeightMeasurement = "client_added_weight_measurement",
  ClientAddedBodyMeasurements = "client_added_body_measurements",
  ClientAddedBodyPhotos = "client_added_body_photos",
  ClientRateWorkout = "client_rate_workout",
  ClientProgramEndingIn1Day = "client_program_ending_in_1_day",
  ClientProgramEndingIn1Week = "client_program_ending_in_1_week",
  ClientSentVideoRecording = "client_sent_video_recording",
}

export enum ClientReminderType {
  NewSurveyAdded = "new_survey_added",
  WeightMeasurementReminder = "weight_measurement_reminder",
  BodyMeasurementReminder = "body_measurement_reminder",
  AccessPeriodEndingReminder = "access_period_ending_reminder",
  NewWorkoutProgramReminder = "new_workout_program_reminder",
  NewPlanProgramReminder = "new_nutrition_plan_reminder",
  ExpectExerciseRecordingReminder = "expect_exercise_recording_reminder",
  PopOutMessage = "pop_out_message",
}

export enum NotificationStatus {
  READ = "read",
  UNREAD = "unread",
}

export type ConnectedClient = {
  userId: string | null;
  traineeId?: string | null;
  email: string;
  firstName: string | null;
  lastName: string | null;
  avatarUrl?: string | null;
};

export interface NotificationDocument<T = object> extends Omit<NotificationEntity, "data" | "connectedClient"> {
  connectedClient?: ConnectedClient | null;
  type: NotificationType | ClientReminderType;
  data?: T;
  status: NotificationStatus;
}

export interface GenericNotification<IType extends NotificationType | ClientReminderType, IData = object>
  extends NotificationDocument<IData> {
  type: IType;
  data: IData;
}

export interface InfoAboutPurchasedProduct {
  productId: string;
  productName: string;
  isFree: boolean;
  paymentType: OrderPaymentType | null;
  priceId: string | null;
  price: null | {
    amount: number;
    currency: Currency;
    interval_count?: number;
    interval?: SubscriptionInterval;
  };
}

export interface InfoAboutUploadedVideo {
  productId: string;
  productName: string;
  videoName: string;
  videoId: string;
  lessonId: string;
  lessonName: string;
}

export interface InfoAboutFinishedClientsImport {
  productId: string;
  productName: string;
  totalClients: number;
  importId: string;
  failedClients: number;
}

export interface ProductPaymentRequiredActionPayload {
  productId: string;
  productName: string;
  authorName: string;
  authorEmail: string;
  authorId: string;
  action: RequiresAction;
}

export interface ProductSubscriptionEndPayload {
  productId: string;
  productName: string;
  authorName: string;
  authorEmail: string;
  authorId: string;
  endAt: number;
  reason: ClientArchiveReason;
}

export interface CancelOrResumeSubscriptionPayload {
  productId: string;
  productName: string;
  clientName: string;
  clientEmail: string;
  authorId: string;
  periodEnd: number;
}

export interface NewSurveyAddedPayload {
  title: string;
  surveyId: string;
  type: SurveyType;
}

export interface InfoAboutSurveyAnswered {
  title: string;
  surveyId: string;
  type: SurveyType;
}

export interface InfoAboutWorkoutProgram {
  programId: string;
  programName: string;
  endDate: string;
  nextPlan: { name: string; id: string; startDate: string } | null;
}

export interface InfoAboutRequiredExerciseRecording {
  exerciseId: string;
  workoutId: string | null;
  exerciseName: string;
  exerciseParentId: string;
  date: string;
}

export interface ClientExerciseRecordingPayload {
  exerciseId: string;
  exerciseName: string;
  parentExerciseId: string;
  workoutId: string | null;
  workoutDate: string;
  programId: string | null;
}

export type PopOutMessagePayload = Campaign["data"] & { campaignId: string };

export type NewWeightMeasurementPayload = WeightMeasurement["data"];
export type NewBodyMeasurementsPayload = BodyMeasurements["data"];
export type NewBodyPhotosAddedPayload = ClientPhoto["data"];
export type NewTrainingFeedbackAddedPayload = TrainingFeedback["data"];

// notifications DTO

export interface PurchaseProductNotificationDto {
  action: NotificationType.ClientPurchaseProduct;
  payload: InfoAboutPurchasedProduct;
}

export interface RenewedProductNotificationDto {
  action: NotificationType.ClientRenewedSubscription;
  payload: InfoAboutPurchasedProduct;
}

export interface SubscriptionPlanUpdatedProductNotificationDto {
  action: NotificationType.ClientSubscriptionPlanUpdated;
  payload: InfoAboutPurchasedProduct;
}

export interface RenewedAccessProductNotificationDto {
  action: NotificationType.ClientRenewedAccessPeriod;
  payload: InfoAboutPurchasedProduct;
}

export type ClientsImportFinishedNotificationDto = {
  action: NotificationType.ClientsImportFinished;
  payload: InfoAboutFinishedClientsImport;
};

export type ProductPaymentRequiredActionNotificationDto = {
  action: NotificationType.ProductPaymentRequiredAction;
  payload: ProductPaymentRequiredActionPayload;
};

export type ProductSubscriptionEndNotificationDto = {
  action: NotificationType.ProductSubscriptionEnd;
  payload: ProductSubscriptionEndPayload;
};

export type ProductAccessPeriodEndNotificationDto = {
  action: NotificationType.ProductAccessPeriodEnd;
  payload: ProductSubscriptionEndPayload;
};

export interface VideoBeenUploadedNotificationDto {
  action: NotificationType.VideoBeenUploaded;
  payload: InfoAboutUploadedVideo;
}

export type ProductAccessPeriodEndingNotificationDto = {
  action:
    | NotificationType.ProductAccessPeriodEndingIn14Days
    | NotificationType.ProductAccessPeriodEndingIn7Days
    | NotificationType.ProductAccessPeriodEndingIn3Days
    | NotificationType.ProductAccessPeriodEndingIn1Day;
  payload: ProductSubscriptionEndPayload;
};

export type ClientResumeSubscriptionAtPeriodEndNotificationDto = {
  action: NotificationType.ClientResumeSubscriptionAtPeriodEnd;
  payload: CancelOrResumeSubscriptionPayload;
};

export type ClientCancelSubscriptionAtPeriodEndNotificationDto = {
  action: NotificationType.ClientCancelSubscriptionAtPeriodEnd;
  payload: CancelOrResumeSubscriptionPayload;
};

export type ClientAnsweredSurveyNotificationDto = {
  action: NotificationType.ClientSurveyAnswered;
  payload: InfoAboutSurveyAnswered;
};

export type ClientProgramEndingIn1WeekNotificationDto = {
  action: NotificationType.ClientProgramEndingIn1Week;
  payload: InfoAboutWorkoutProgram;
};

export type ClientProgramEndingIn1DayNotificationDto = {
  action: NotificationType.ClientProgramEndingIn1Day;
  payload: InfoAboutWorkoutProgram;
};

export type ClientSurveyAnswersUpdatedNotificationDto = {
  action: NotificationType.ClientSurveyAnswersUpdated;
  payload: InfoAboutSurveyAnswered;
};

export type NewSurveyAddedNotificationDto = {
  action: ClientReminderType.NewSurveyAdded;
  payload: NewSurveyAddedPayload;
};

export type ClientSentVideoRecordingNotificationDto = {
  action: NotificationType.ClientSentVideoRecording;
  payload: ClientExerciseRecordingPayload;
};

// notifications
export type SubscriptionPlanUpdatedProductNotification = GenericNotification<
  NotificationType.ClientSubscriptionPlanUpdated,
  InfoAboutPurchasedProduct
>;
export type VideoBeenUploadedNotification = GenericNotification<
  NotificationType.VideoBeenUploaded,
  InfoAboutUploadedVideo
>;

export type PurchaseProductNotification = GenericNotification<
  NotificationType.ClientPurchaseProduct,
  InfoAboutPurchasedProduct
>;

export type RenewedProductNotification = GenericNotification<
  NotificationType.ClientRenewedSubscription,
  InfoAboutPurchasedProduct
>;

export type RenewedAccessProductNotification = GenericNotification<
  NotificationType.ClientRenewedAccessPeriod,
  InfoAboutPurchasedProduct
>;

export type ClientsImportFinishedNotification = GenericNotification<
  NotificationType.ClientsImportFinished,
  InfoAboutFinishedClientsImport
>;

export type ProductPaymentRequiredActionNotification = GenericNotification<
  NotificationType.ProductPaymentRequiredAction,
  ProductPaymentRequiredActionPayload
>;

export type ProductSubscriptionEnd = GenericNotification<
  NotificationType.ProductSubscriptionEnd,
  ProductSubscriptionEndPayload
>;

export type ProductAccessPeriodEndNotification = GenericNotification<
  NotificationType.ProductAccessPeriodEnd,
  ProductSubscriptionEndPayload
>;

export type ProductAccessPeriodEndingNotification = GenericNotification<
  | NotificationType.ProductAccessPeriodEndingIn14Days
  | NotificationType.ProductAccessPeriodEndingIn7Days
  | NotificationType.ProductAccessPeriodEndingIn3Days
  | NotificationType.ProductAccessPeriodEndingIn1Day,
  ProductSubscriptionEndPayload
>;

export type ClientCancelSubscriptionAtPeriodEndNotification = GenericNotification<
  NotificationType.ClientCancelSubscriptionAtPeriodEnd,
  CancelOrResumeSubscriptionPayload
>;

export type ClientResumeSubscriptionAtPeriodEndNotification = GenericNotification<
  NotificationType.ClientResumeSubscriptionAtPeriodEnd,
  CancelOrResumeSubscriptionPayload
>;

export type ClientAnsweredSurveyNotification = GenericNotification<
  NotificationType.ClientSurveyAnswered,
  InfoAboutSurveyAnswered
>;

export type ClientSurveyAnswersUpdatedNotification = GenericNotification<
  NotificationType.ClientSurveyAnswersUpdated,
  InfoAboutSurveyAnswered
>;

export type ClientAddedWeightMeasurementNotification = GenericNotification<
  NotificationType.ClientAddedWeightMeasurement,
  NewWeightMeasurementPayload
>;

export type ClientAddedBodyMeasurementsNotification = GenericNotification<
  NotificationType.ClientAddedBodyMeasurements,
  NewBodyMeasurementsPayload
>;

export type ClientAddedBodyPhotosNotification = GenericNotification<
  NotificationType.ClientAddedBodyPhotos,
  NewBodyPhotosAddedPayload
>;

export type ClientAddedWorkoutRateNotification = GenericNotification<
  NotificationType.ClientRateWorkout,
  NewTrainingFeedbackAddedPayload
>;

export type ClientProgramEndingIn1WeekNotification = GenericNotification<
  NotificationType.ClientProgramEndingIn1Week,
  InfoAboutWorkoutProgram
>;

export type ClientProgramEndingIn1DayNotification = GenericNotification<
  NotificationType.ClientProgramEndingIn1Day,
  InfoAboutWorkoutProgram
>;

export type ClientSentVideoRecordingNotification = GenericNotification<
  NotificationType.ClientSentVideoRecording,
  ClientExerciseRecordingPayload
>;

export type NewSurveyAddedReminder = GenericNotification<ClientReminderType.NewSurveyAdded, NewSurveyAddedPayload>;
export type AddWeightMeasurementReminder = GenericNotification<ClientReminderType.WeightMeasurementReminder, null>;
export type AddBodyMeasurementsReminder = GenericNotification<ClientReminderType.BodyMeasurementReminder, null>;
export type ExpectExerciseRecordingReminder = GenericNotification<
  ClientReminderType.ExpectExerciseRecordingReminder,
  InfoAboutRequiredExerciseRecording
>;
export type PopOutClientNotification = GenericNotification<ClientReminderType.PopOutMessage, PopOutMessagePayload>;

export type PurchaseProductNotificationId = PurchaseProductNotification & { id: string };

export type WithId<T extends object> = T & { id: string };

export type OwnerNotifications =
  | WithId<PurchaseProductNotification>
  | WithId<RenewedProductNotification>
  | WithId<RenewedAccessProductNotification>
  | WithId<ClientsImportFinishedNotification>
  | WithId<ClientCancelSubscriptionAtPeriodEndNotification>
  | WithId<ClientResumeSubscriptionAtPeriodEndNotification>
  | WithId<SubscriptionPlanUpdatedProductNotification>
  | WithId<VideoBeenUploadedNotification>;

export type OwnerNotificationsDto =
  | PurchaseProductNotificationDto
  | RenewedProductNotificationDto
  | RenewedAccessProductNotificationDto
  | ClientsImportFinishedNotificationDto
  | ClientCancelSubscriptionAtPeriodEndNotificationDto
  | ClientResumeSubscriptionAtPeriodEndNotificationDto
  | SubscriptionPlanUpdatedProductNotificationDto
  | VideoBeenUploadedNotificationDto;

export type ClientNotificationsDto =
  | ProductPaymentRequiredActionNotificationDto
  | ProductSubscriptionEndNotificationDto
  | ProductAccessPeriodEndNotificationDto
  | ProductAccessPeriodEndingNotificationDto;

export type ClientNotifications =
  | WithId<ProductPaymentRequiredActionNotification>
  | WithId<ProductSubscriptionEnd>
  | WithId<ProductAccessPeriodEndNotification>
  | WithId<ProductAccessPeriodEndingNotification>;

export type TrainerNotifications = WithId<
  | ClientAnsweredSurveyNotification
  | ClientSurveyAnswersUpdatedNotification
  | ClientAddedBodyPhotosNotification
  | ClientAddedBodyMeasurementsNotification
  | ClientAddedWeightMeasurementNotification
  | ClientAddedWorkoutRateNotification
  | ClientProgramEndingIn1WeekNotification
  | ClientProgramEndingIn1DayNotification
  | ClientSentVideoRecordingNotification
>;

export type ClientReminders =
  | WithId<NewSurveyAddedReminder>
  | WithId<AddWeightMeasurementReminder>
  | WithId<AddBodyMeasurementsReminder>
  | WithId<ExpectExerciseRecordingReminder>
  | WithId<PopOutClientNotification>;

export type AppNotification = ClientNotifications | OwnerNotifications | ClientReminders | TrainerNotifications;

export interface InitialNotificationsStateEvent {
  type: "initial";
  unreadMessages: UnreadMessages | null;
  unreadNotifications: number;
}

export interface NewNotificationEvent {
  type: "newUnreadNotification";
  notificationType: AppNotification["type"];
  data: AppNotification;
}

export interface UpdatedNotificationEvent {
  type: "updatedNotification";
  notificationType: AppNotification["type"];
  data: AppNotification;
}

export type InComingHttpNotificationEvents = NewNotificationEvent | UpdatedNotificationEvent;

export type OutgoingNotificationsRealtimeEvents =
  | InitialNotificationsStateEvent
  | (NewNotificationEvent & { unreadNotifications: number })
  | (UpdatedNotificationEvent & { unreadNotifications: number });
