import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchTrainees } from "./actions/fetchTrainees";
import { fetchTraineesActivity } from "./actions/fetchTraineesActivity";
import { TRAINEES_REDUCER_NAME, type TraineesReducer } from "./types";

const initialState: TraineesReducer = {
  list: [],
  listSize: 25,
  page: 0,
  totalPages: 0,
  listStatus: null,
  searchStatus: null,
  searchTerm: "",
  filters: {
    currentAssignedTrainerFilter: null,
    currentStatusFilter: [TraineeStatus.ACTIVE, TraineeStatus.INACTIVE, TraineeStatus.TERMINATED],
    currentAccessType: [AppAccessType.FullAccess],
    currentFilteredTags: [],
  },
  traineesActivity: [],
  activitiesMetadata: {
    page: 0,
    totalPages: 0,
    listSize: 25,
    currentAssignedTrainerFilter: null,
    status: null,
    fetchingNextPage: false,
  },
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEES_REDUCER_NAME,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainees.pending, (state, { meta }) => {
      if (state.listStatus === null) {
        state.listStatus = RequestStatus.FETCHING;
        state.list = [];
      }

      if (meta.arg?.assignedTrainerId || meta.arg?.assignedTrainerId === null) {
        state.filters.currentAssignedTrainerFilter = meta.arg.assignedTrainerId;
      }
      if (Array.isArray(meta.arg?.statuses) || meta.arg?.statuses === null) {
        state.filters.currentStatusFilter = meta.arg?.statuses || null;
      }
      if (Array.isArray(meta.arg?.accessType) || meta.arg?.accessType === null) {
        state.filters.currentAccessType = meta.arg?.accessType || null;
      }
      if (Array.isArray(meta.arg?.tags) || meta.arg?.tags === null) {
        state.filters.currentFilteredTags = meta.arg?.tags || [];
      }
    });
    builder.addCase(fetchTrainees.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.page = payload.page;
      state.list = payload.list;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(fetchTrainees.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });

    builder.addCase(fetchTraineesActivity.pending, (state, { meta }) => {
      if (state.activitiesMetadata.status === null) {
        state.activitiesMetadata.status = RequestStatus.FETCHING;
        state.traineesActivity = [];
      }

      if ((meta.arg?.page && meta.arg.page > 1) || (meta.arg?.page === 1 && state.activitiesMetadata.page !== 1)) {
        state.activitiesMetadata.fetchingNextPage = true;
      }

      if (meta.arg?.assignedTrainerId || meta.arg?.assignedTrainerId === null) {
        state.activitiesMetadata.currentAssignedTrainerFilter = meta.arg.assignedTrainerId;
      }
    });
    builder.addCase(fetchTraineesActivity.fulfilled, (state, { payload }) => {
      state.activitiesMetadata.status = RequestStatus.SUCCESS;
      state.activitiesMetadata.page = payload.page;
      state.traineesActivity = payload.list;
      state.activitiesMetadata.totalPages = payload.totalPages;
      state.activitiesMetadata.fetchingNextPage = false;
    });
    builder.addCase(fetchTraineesActivity.rejected, (state) => {
      state.activitiesMetadata.status = RequestStatus.FAILED;
      state.activitiesMetadata.fetchingNextPage = false;
    });
  },
});

export const { setSearchTerm } = reducerSlice.actions;
export default reducerSlice.reducer;
