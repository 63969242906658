import { createAsyncThunk } from "@reduxjs/toolkit";
import { type AutomationTaskWithoutTriggers } from "@fitness-app/data-models/entities/Automation";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  oldEmail: string;
  newEmail: string;
  productId: string;
  tasksForOldEmail: AutomationTaskWithoutTriggers[];
  tasksForNewEmail: AutomationTaskWithoutTriggers[];
  sendEmailNotification: boolean;
};

export const changeProductClientEmail = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CLIENT_REDUCER}/changeProductClientEmail`,
  async (payload, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/change-product-client-email", payload);
      analytics.track("change_product_client_email", {
        productId: payload.productId,
      });
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
