import React, { useCallback, useRef, useState } from "react";
import { LinkOutlined, PlusOutlined, SearchOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Input, Popconfirm, Space, Table, Tag, Tooltip, type InputRef } from "antd";
import { type ColumnType } from "antd/es/table";
import { type FilterConfirmProps } from "antd/es/table/interface";
import { Dumbbell } from "lucide-react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { exercisesActions, RequestStatus } from "@fitness-app/app-store";
import { fetchTrainerConfig } from "@fitness-app/app-store/src/store/reducers/team/actions";
import {
  ExerciseType,
  ExerciseVideoType,
  type ExerciseBodyPartEnum,
  type ExerciseTypeEnum,
  type ExerciseWithVideo,
} from "@fitness-app/data-models/entities/Exercise";
import { MediaLibraryResourceType } from "@fitness-app/data-models/entities/MediaLibrary";
import { getError, getErrorMessage } from "@fitness-app/utils";
import { youtubeLinkValidator } from "@fitness-app/utils/src/exercises/youtubeLinkValidator";

import ModalForm from "~/components/ModalForm/ModalForm";
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useTagOptions } from "~/hooks/useTagOptions";
import MediaLibrary from "~/modules/MediaLibrary/MediaLibrary";
import ExerciseForm from "~/modules/TrainingPrograms/tabs/ExercisesDatabase/ExerciseForm/ExerciseForm";
import {
  bodyPartOptions,
  exerciseTypeOptions,
  type ExerciseFormModel,
} from "~/modules/TrainingPrograms/tabs/ExercisesDatabase/ExerciseForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ExercisesDatabase = () => {
  const [showExerciseForm, setShowExerciseForm] = useState(false);
  const { t } = useTranslation(["workouts", "common"]);
  const [editedModel, setEditedModel] = useState<null | (ExerciseFormModel & { id: string })>(null);

  const [addingExercise, onSuccess, onError, onStart] = useEntityChange(() => {
    setShowExerciseForm(false);
    setEditedModel(null);
  });
  const loggedUser = useAppSelector((store) => store.user.data);
  const { list, exercisesStatus } = useAppSelector((store) => store.exercises);
  const dispatch = useAppDispatch();
  const [videoLink, setVideoLink] = useState<string | null>(null);
  const [_searchText, setSearchText] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { filters: tagsFilters } = useTagOptions("exercisesTags");
  const { isOwner, isSharedTrainer, isRegularTrainer } = useUserClaims();

  const handleReset = (clearFilters?: () => void) => {
    clearFilters?.();
    setSearchText("");
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0] ?? "");
  };

  const getColumnSearchProps = useCallback(
    (): ColumnType<ExerciseWithVideo> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close, clearFilters }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.search")}
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters);
                confirm({ closeDropdown: false });
              }}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {t("common:button.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
      onFilter: (value, record) =>
        record.name
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    }),
    [handleSearch, handleReset, t],
  );

  const addExercise = async (model: ExerciseFormModel) => {
    onStart();
    try {
      if (editedModel) {
        await dispatch(
          exercisesActions.updateExercise({
            exercise: {
              ...model,
              type: model.type || ExerciseType.POWER,
              id: editedModel.id,
              updatedAt: new Date().toISOString(),
            },
          }),
        ).unwrap();
      } else {
        await dispatch(
          exercisesActions.addExercise({
            exercise: {
              ...model,
              videoId: model.videoId || null,
              type: model.type || ExerciseType.POWER,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              createdBy: loggedUser?.id || null,
              name: model.name.trim(),
              iconName: null,
              tags: model.tags || [],
              id: uuid(),
              updateConnectedExercises: model.updateConnectedExercises ?? false,
            },
          }),
        ).unwrap();
      }

      void dispatch(fetchTrainerConfig());

      onSuccess();
    } catch (e) {
      onError(
        getError(e).message.includes("duplicate key value violates unique constraint")
          ? "Ćwiczenie o tej nazwie już istnieje w bazie"
          : getErrorMessage(e),
      );
    }
  };

  return (
    <Card
      extra={
        <Space direction="horizontal">
          <MediaLibrary type={MediaLibraryResourceType.Exercise} />
          <Tooltip
            title={
              isRegularTrainer
                ? "Dodawanie ćwiczeń jest dostępne tylko dla head coachów. Jeżeli potrzebujesz dodać nowe ćwiczenie zgłoś to do niego."
                : ""
            }
          >
            <Button
              disabled={isRegularTrainer}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowExerciseForm(true)}
            >
              {t("addNewExercise")}
            </Button>
          </Tooltip>
        </Space>
      }
    >
      <Table
        dataSource={list}
        loading={exercisesStatus === RequestStatus.FETCHING}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          pageSize: 100,
          showSizeChanger: false,
        }}
      >
        <Table.Column<ExerciseWithVideo>
          title={t<string>("exercisesTable.name")}
          dataIndex="name"
          key="name"
          sorter={(a, b) => a.name.localeCompare(b.name)}
          {...getColumnSearchProps()}
          render={(name: string, row) => (
            <div className="flex items-center gap-2">
              <Avatar
                shape="square"
                size={80}
                src={row.video?.thumbnailUrl}
                className="shrink-0"
                icon={<Dumbbell className="mt-1.5" size={28} />}
              />
              {name}
            </div>
          )}
        />
        <Table.Column<ExerciseWithVideo>
          title={t("exercisesTable.type")}
          dataIndex="type"
          key="type"
          render={(type: string) => t(`exerciseType.${type}`)}
          filters={exerciseTypeOptions.map((part) => ({
            text: part.label,
            value: part.value,
          }))}
          onFilter={(value, record) => (record.type ? record.type.includes(value as ExerciseTypeEnum) : false)}
        />
        <Table.Column<ExerciseWithVideo>
          title={t("exercisesTable.bodyPart")}
          dataIndex="bodyParts"
          key="bodyParts"
          filters={bodyPartOptions.map((part) => ({
            text: part.label,
            value: part.value,
          }))}
          onFilter={(value, record) =>
            record.bodyParts ? record.bodyParts.includes(value as ExerciseBodyPartEnum) : false
          }
          render={(bodyParts: string[]) => (
            <div>
              {bodyParts.map((bodyPart) => (
                <Tag key={bodyPart}>{t(`bodyPart.${bodyPart}`)}</Tag>
              ))}
            </div>
          )}
        />
        <Table.Column<ExerciseWithVideo>
          title={t("exercisesTable.videoLink")}
          dataIndex="videoLink"
          key="videoLink"
          render={(videoLink: string | null, row) => {
            if (!videoLink && !row.video) {
              return "-";
            }
            const url = row.video?.hlsUrl || row.video?.mp4Url;
            if (url) {
              return (
                <YoutubeOutlined
                  className="text-green-500"
                  style={{ fontSize: 22 }}
                  onClick={() => setVideoLink(url)}
                />
              );
            }
            if (!videoLink) {
              return "-";
            }
            if (youtubeLinkValidator(videoLink) || row.videoType === ExerciseVideoType.VIMEO) {
              return (
                <YoutubeOutlined
                  className="text-green-500"
                  style={{ fontSize: 22 }}
                  onClick={() => setVideoLink(videoLink)}
                />
              );
            }
            return (
              <a href={videoLink} target="_blank" rel="noopener noreferrer" className="text-green-500">
                <LinkOutlined style={{ fontSize: 22 }} />
              </a>
            );
          }}
        />
        <Table.Column<ExerciseWithVideo>
          title={t("program.tags")}
          dataIndex="tags"
          key="tags"
          filterMultiple
          filterSearch
          filters={tagsFilters}
          onFilter={(value, record) => (record.tags || []).includes(value as string)}
          render={(tags: string[]) =>
            tags?.length ? (
              <Space direction="vertical">
                {tags.map((tag, i) => (
                  <Tag key={`${tag}-${i}`}>{tag}</Tag>
                ))}
              </Space>
            ) : (
              "-"
            )
          }
        />
        {(isOwner || isSharedTrainer) && (
          <Table.Column<ExerciseWithVideo>
            title={t("exercisesTable.options")}
            key="action"
            align="left"
            render={(_, row) => {
              return (
                <Space>
                  <Button
                    type="link"
                    onClick={() => {
                      setEditedModel({
                        name: row.name,
                        bodyParts: row.bodyParts || [],
                        type: row.type,
                        updateConnectedExercises: row.updateConnectedExercises ?? false,
                        description: row.description ?? "",
                        links: row.links || [],
                        videoLink: row.videoLink || null,
                        videoType: row.videoType || ExerciseVideoType.NONE,
                        instruction: row.instruction || [],
                        id: row.id,
                        videoId: row.videoId || null,
                        tags: row.tags || [],
                      });
                      setShowExerciseForm(true);
                    }}
                  >
                    {t("common:button.edit")}
                  </Button>
                  {isOwner && (
                    <Popconfirm
                      overlayStyle={{ maxWidth: 400 }}
                      placement="leftTop"
                      title={t("exercisesTable.deleteWarning")}
                      onConfirm={() => dispatch(exercisesActions.deleteExercise(row.id))}
                    >
                      <Button type="link" danger>
                        {t("common:button.delete")}
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              );
            }}
          />
        )}
      </Table>
      <ModalForm
        open={showExerciseForm}
        onCancel={() => {
          setShowExerciseForm(false);
          setEditedModel(null);
        }}
        title={editedModel ? t("exerciseForm.editExercise") : t("exerciseForm.addExercise")}
        loading={!!addingExercise}
      >
        <ExerciseForm onSubmit={addExercise} model={editedModel} />
      </ModalForm>
      <VideoPlayer visible={!!videoLink} videoUrl={videoLink} onClose={() => setVideoLink(null)} />
    </Card>
  );
};

export default ExercisesDatabase;
