import { createSelector } from "reselect";

import { type AppStore } from "../../../index";

const getProgram = (state: AppStore) => state.traineeProgram.selectedProgram;
const getProgramDetails = (state: AppStore) => state.traineeProgram.programDetails;

export const getCurrentTraineeProgram = createSelector([getProgram, getProgramDetails], (program, details) => {
  if (!program || !details) {
    throw new Error("Cannot get current program");
  }

  return {
    program,
    details,
  };
});
