import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_NOTES_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  noteId: string;
};

export const removeClientNote = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${CLIENT_NOTES_REDUCER_NAME}/removeClientNote`,
  async ({ traineeId, noteId }, { rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db.from("client_note").delete().match({ id: noteId, traineeId });

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("remove_client_note");
  },
);
