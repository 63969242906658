import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useDroppable } from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

interface TrashZoneProps {
  id: string;
}

const TrashZone = ({ id }: TrashZoneProps) => {
  const { t } = useTranslation("common");
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className="rounded-xl border-2 border-dashed border-gray-200 bg-gray-50"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        left: "50%",
        top: 80,
        width: 300,
        height: 60,
        borderColor: isOver ? "red" : "#DDD",
      }}
    >
      <div className="text-red-400">
        <DeleteOutlined /> {t("button.delete")}
      </div>
    </div>
  );
};

export default TrashZone;
