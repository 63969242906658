import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Alert, Button, Card, Empty, Popconfirm, Space, Switch, Tooltip } from "antd";
import isEmpty from "lodash.isempty";
import { useTranslation } from "react-i18next";

import { traineeProgramActions } from "@fitness-app/app-store";
import { TraineeStatus, type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";
import { type ClientTrainingProgram } from "@fitness-app/data-models/entities/TrainingProgram";
import { getErrorMessage } from "@fitness-app/utils";

import { useEntityChange } from "~/hooks/useEntityChange";
import TraineeProgramInfoConfig from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramInfoConfig";
import TraineeProgramStepper from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeProgramStepper";
import TraineeScheduledPrograms from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeScheduledPrograms";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ArchiveProgramButton = ({ traineeId, programId }: { traineeId: string; programId: string }) => {
  const dispatch = useAppDispatch();
  const [keepOldWorkoutsWithoutStatus, toggleFlag] = useState(true);
  const [_loading, onSuccess, onFailure, onStart] = useEntityChange();

  const onConfirm = async () => {
    onStart();
    try {
      await dispatch(
        traineeProgramActions.archiveTraineeProgram({
          traineeId,
          programId,
          keepOldWorkoutsWithoutStatus,
        }),
      ).unwrap();
      onSuccess();
    } catch (e) {
      onFailure(getErrorMessage(e));
    }
  };

  return (
    <Popconfirm
      title="Czy na pewno chcesz zarchiwizować ten plan?"
      description={
        <div className="mb-2 flex gap-x-2">
          Zachowaj niewykonane treningi
          <Tooltip title="Treningi z planu, które nie posiadają status zostana zachowane w kalendarzu jako niewykonane.">
            <InfoCircleOutlined />
          </Tooltip>
          <Switch onChange={toggleFlag} checked={keepOldWorkoutsWithoutStatus} />
        </div>
      }
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={onConfirm}
    >
      <Button size="small" danger icon={<DeleteOutlined />}>
        Archiwizuj plan
      </Button>
    </Popconfirm>
  );
};

const Content = ({
  assignedProgram,
  trainee,
  scheduledPrograms,
  toggleProgramStepper,
}: {
  assignedProgram: ClientTrainingProgram | null;
  trainee: TraineeProfile;
  scheduledPrograms: ClientTrainingProgram[];
  toggleProgramStepper: (open: boolean) => void;
}) => {
  const { t } = useTranslation(["trainees"]);

  const [showLoader, toggleLoader] = useState(false);
  const dispatch = useAppDispatch();
  const toggleProgramAccess = async (checked: boolean) => {
    if (assignedProgram) {
      toggleLoader(true);
      await dispatch(
        traineeProgramActions.toggleProgramAccess({
          traineeId: trainee.id,
          programId: assignedProgram.id,
          hasAccessToProgram: checked,
        }),
      );
      toggleLoader(false);
    }
  };

  if (!trainee.activeTrainingProgramId || !assignedProgram) {
    return (
      <div className="py-16">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Space direction="vertical" size={12}>
              <span>{t("program.addProgramToClient")}</span>
              <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => toggleProgramStepper(true)}>
                {t("program.addProgram")}
              </Button>
            </Space>
          }
        />
      </div>
    );
  }

  const renderAlert = () => {
    if (trainee.status === TraineeStatus.TERMINATED || trainee.status === TraineeStatus.ARCHIVED) {
      return <Alert message="Klient przestał być Twoim podopiecznym" type="error" showIcon />;
    }
    if (!trainee.status || trainee.status !== TraineeStatus.ACTIVE) {
      return (
        <Alert
          message="Klient nie aktywował konta w aplikacji"
          description={
            <div>
              <div>{"Skontaktuj się z klientem i przekaż informację o dostępie do aplikacji mobilnej"}</div>
            </div>
          }
          type="warning"
          showIcon
        />
      );
    }
    if (!assignedProgram || isEmpty(assignedProgram)) {
      return (
        <Alert
          message="Nie został jeszcze wybrany plan dla klienta"
          description="Aby wybrać plan należy skorzystać z powyższego przycisku"
          type="info"
          showIcon
        />
      );
    }
    if (trainee.status === TraineeStatus.ACTIVE && !trainee.hasActiveTrainingProgram) {
      return (
        <Alert
          message="Klient nie posiada dostępu do planu treningowego"
          description={
            <div>
              <div>{"Zmień ustawienia dostępu dla Twojego klienta"}</div>
              <Switch
                disabled={trainee.status !== TraineeStatus.ACTIVE}
                onChange={toggleProgramAccess}
                checked={trainee.hasActiveTrainingProgram || false}
                loading={showLoader}
              />
            </div>
          }
          type="warning"
          showIcon
        />
      );
    }
    if (trainee.status === TraineeStatus.ACTIVE && trainee.hasActiveTrainingProgram) {
      return <Alert message="Plan widoczny dla klienta" type="success" showIcon />;
    }
    return null;
  };

  return (
    <Card
      bordered={false}
      className="!shadow-none"
      extra={
        <Space size={12}>
          {trainee.activeTrainingProgramId ? (
            <ArchiveProgramButton traineeId={trainee.id} programId={trainee.activeTrainingProgramId} />
          ) : null}
          <Button size="small" type="primary" icon={<EditOutlined />} onClick={() => toggleProgramStepper(true)}>
            Zmień plan
          </Button>
        </Space>
      }
    >
      {renderAlert()}
      <TraineeProgramInfoConfig program={assignedProgram} trainee={trainee} />
      {scheduledPrograms.length ? (
        <TraineeScheduledPrograms trainee={trainee} scheduledPrograms={scheduledPrograms} />
      ) : null}
    </Card>
  );
};

const TraineeProgramInfo = () => {
  const trainee = useAppSelector((store) => store.trainee.profile);
  const { selectedProgram: assignedProgram, scheduledPrograms } = useAppSelector((store) => store.traineeProgram);
  const [showProgramStepper, toggleProgramStepper] = useState(false);

  if (!trainee) {
    return null;
  }

  return (
    <>
      <Content
        assignedProgram={assignedProgram}
        trainee={trainee}
        scheduledPrograms={scheduledPrograms}
        toggleProgramStepper={toggleProgramStepper}
      />
      <TraineeProgramStepper
        traineeId={trainee.id}
        onClose={() => toggleProgramStepper(false)}
        open={showProgramStepper}
      />
    </>
  );
};

export default TraineeProgramInfo;
