import { RequestStatus } from "@fitness-app/data-models";
import { TraineeActivityEventType } from "@fitness-app/data-models/businessEvents/domain/traineeActivity/TraineeActivityEvents";
import { type ClientActivityEvent } from "@fitness-app/data-models/entities/ClientActivity";
import { type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";
import { type Trainee } from "@fitness-app/data-models/entities/Trainee";

import { type AppThunk } from "../../../index";
import { traineeMeasurementsActions } from "../../traineeMeasurements";
import { addSurvey, updateSurvey } from "../../traineeSurveys/reducer";
import { addActivityEvent, updateActivityEvent } from "../reducer";
import { fetchTrainee } from "./fetchTrainee";

export const subscribeToTrainee =
  (traineeId: string): AppThunk =>
  async (dispatch, getState, { db, subscriptions }) => {
    try {
      const traineeProfile = getState().trainee.profile;

      await dispatch(fetchTrainee({ id: traineeId, refetch: traineeProfile?.id === traineeId }));

      if (subscriptions.traineeChannelName && subscriptions.realtimeTraineeChannel) {
        await db.removeChannel(subscriptions.realtimeTraineeChannel);
      }

      subscriptions.traineeChannelName = `trainee-${traineeId}`;
      subscriptions.realtimeTraineeChannel = db.channel(subscriptions.traineeChannelName);

      subscriptions.realtimeTraineeChannel
        .on<Trainee>(
          "postgres_changes",
          { event: "*", schema: "public", table: "trainee", filter: `id=eq.${traineeId}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              void dispatch(fetchTrainee({ id: traineeId, refetch: true }));
            }
          },
        )
        .on<ClientActivityEvent>(
          "postgres_changes",
          { event: "*", schema: "public", table: "client_activity_event", filter: `traineeId=eq.${traineeId}` },
          (payload) => {
            if (payload.eventType === "INSERT") {
              if (payload.new.eventName !== TraineeActivityEventType.TraineeSentChatMessage) {
                if (payload.new.eventName === TraineeActivityEventType.TraineeAddedWeightMeasurement) {
                  void dispatch(traineeMeasurementsActions.fetchLatestWeightMeasurement({ traineeId }));
                }

                dispatch(addActivityEvent(payload.new));
              }
            }

            if (payload.eventType === "UPDATE") {
              dispatch(updateActivityEvent(payload.new));
            }
          },
        )
        .on<ClientSurvey>(
          "postgres_changes",
          { event: "*", schema: "public", table: "client_survey", filter: `traineeId=eq.${traineeId}` },
          (payload) => {
            if (getState().traineeSurveys.surveysStatus === RequestStatus.SUCCESS) {
              if (payload.eventType === "INSERT") {
                dispatch(addSurvey(payload.new));
              }

              if (payload.eventType === "UPDATE") {
                dispatch(updateSurvey(payload.new));
              }
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            // dispatch(subscribeToTeamMemberSuccess());
          }
        });
    } catch {
      // ignore
    }
  };
