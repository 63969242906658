import React, { useEffect, useState, type FunctionComponent } from "react";
import { Comment } from "@ant-design/compatible";
import { ArrowLeftOutlined, PaperClipOutlined } from "@ant-design/icons";
import { type OutputData } from "@editorjs/editorjs";
import { Avatar, Button, Skeleton, Space, Typography } from "antd";
import dayjs from "dayjs";

import { type ProductAuthor } from "@fitness-app/data-models/entities/Product";
import { type ProductResource } from "@fitness-app/data-models/entities/ProductResource";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";
import { getUserInitials } from "@fitness-app/utils";

import Editor from "~/components/Editor/Editor";
import { EDITOR_JS_TOOLS } from "~/components/Editor/tools";

interface OwnProps {
  resource: ProductResource | null;
  author: ProductAuthor;
  withTitle?: boolean;
  goBack?: (resource: ProductResource) => void;
  withBorder?: boolean;
}

type Props = OwnProps;

const ResourceContent: FunctionComponent<Props> = ({ resource, author, withTitle = false, goBack, withBorder }) => {
  const [parsedResource, setParsedResource] = useState<null | OutputData>(null);
  useEffect(() => {
    if (resource?.content) {
      setParsedResource(null);
      setTimeout(() => {
        setParsedResource(JSON.parse(resource.content ?? "") as OutputData);
      }, 0);
    } else {
      setParsedResource(null);
    }
  }, [resource]);

  const authorName = getAuthorName(author);

  return (
    <Skeleton loading={!resource} active>
      {withTitle && resource && (
        <Space direction="vertical">
          {goBack && (
            <Button
              type="link"
              onClick={() => goBack(resource)}
              icon={<ArrowLeftOutlined />}
              style={{ paddingLeft: 0 }}
            >
              Powrót do listy
            </Button>
          )}
          <Typography.Title level={4}>{resource.title}</Typography.Title>
        </Space>
      )}

      {resource && (
        <Comment
          author={authorName}
          avatar={
            <Avatar src={author.avatar} alt="Han Solo">
              {getUserInitials(authorName)}
            </Avatar>
          }
          content={""}
          datetime={dayjs(resource.createdAt).format("HH:mm DD.MM.YYYY")}
        />
      )}
      <div style={{ padding: "16px 0" }}>
        {resource?.attachments?.length ? <Typography.Title level={5}>Załączniki do pobrania</Typography.Title> : null}
        {resource?.attachments.map((item) => (
          <div className="ant-upload-list-item-info" style={{ display: "flex" }} key={item.uid}>
            <div>
              <PaperClipOutlined />
            </div>
            <a
              style={{ paddingLeft: 10 }}
              target="_blank"
              rel="noopener noreferrer"
              className="ant-upload-list-item-name"
              href={item.url}
            >
              {item.name}
            </a>
          </div>
        ))}
      </div>

      {parsedResource && <Editor withBorder={withBorder} data={parsedResource} readOnly tools={EDITOR_JS_TOOLS} />}
    </Skeleton>
  );
};

export default ResourceContent;
