import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { AuthorTypeEnum } from "@fitness-app/data-models/entities/AuthorTypeEnum";
import { MeasurementType, type HydrationMeasurement } from "@fitness-app/data-models/entities/Measurement";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME } from "../types";

type Payload = {
  data: HydrationMeasurement["data"];
  eventDate: string;
  traineeId: string;
  id?: null | string;
  userId?: string | null;
};

export const upsertHydrationMeasurement = createAsyncThunk<HydrationMeasurement, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_MEASUREMENTS_REDUCER_NAME}/upsertHydrationMeasurement`,
  async (payload, { rejectWithValue, extra: { db, errorTrackingService, analytics } }) => {
    if (payload.id) {
      const { data, error } = await db
        .from("measurement")
        .update({
          data: payload.data,
          updatedAt: new Date().toISOString(),
        })
        .eq("id", payload.id)
        .select("*")
        .single<HydrationMeasurement>();

      if (error) {
        errorTrackingService?.recordError(error, "updateHydrationMeasurement");
        return rejectWithValue(error.message);
      }

      analytics.track("update_hydration_measurement", { data: payload.data });

      return data;
    }

    const measurement = {
      type: MeasurementType.HydrationMeasurement,
      data: payload.data,
      eventDate: payload.eventDate,
      eventTimestamp: dayjs(payload.eventDate, "YYYY-MM-DD").hour(12).toISOString(),
      traineeId: payload.traineeId,
      creator: AuthorTypeEnum.Client,
      id: generateUniqId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      userId: payload.userId || null,
    };

    const { error, data } = await db.from("measurement").insert(measurement).select("*").single<HydrationMeasurement>();

    if (error) {
      errorTrackingService?.recordError(error, "addHydrationMeasurement");

      return rejectWithValue(error.message);
    }

    analytics.track("add_hydration_measurement", { data: payload.data });

    return data;
  },
);
