import { type Product } from "@fitness-app/data-models/entities/Product";
import { type ProductStats } from "@fitness-app/data-models/entities/ProductStats";
import { getError } from "@fitness-app/utils";

import { type AppThunk } from "../../../index";
import {
  removeProduct,
  subscribeToProductFailed,
  subscribeToProductStarted,
  subscribeToProductSuccess,
  unsubscribeFromProductDetails,
  updateProductDetails,
  updateProductDetailsStats,
} from "../reducer";
import { fetchProduct } from "./fetchProduct";

export const subscribeToProduct =
  (productId: string): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    try {
      dispatch(subscribeToProductStarted(productId));

      void dispatch(fetchProduct(productId));

      if (subscriptions.productListChannelName && subscriptions.realtimeProductListChannel) {
        await db.removeChannel(subscriptions.realtimeProductListChannel);
      }

      subscriptions.productListChannelName = `product-details-${productId}`;
      subscriptions.realtimeProductListChannel = db.channel(subscriptions.productListChannelName);

      subscriptions.realtimeProductListChannel
        .on<Product>(
          "postgres_changes",
          { event: "*", schema: "public", table: "product", filter: `id=eq.${productId}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductDetails(payload.new));
            }
            if (payload.eventType === "DELETE" && payload.old.id) {
              dispatch(removeProduct(payload.old.id));
            }
          },
        )
        .on<ProductStats>(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "product_stats", filter: `productId=eq.${productId}` },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateProductDetailsStats({ product_stats: payload.new, id: payload.new.productId }));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToProductSuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromProductDetails());
          }
        });
    } catch (error) {
      dispatch(subscribeToProductFailed(getError(error)));
    }
  };
