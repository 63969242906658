import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Popover, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { EndAccessStrategy } from "@fitness-app/data-models/entities/Product";
import { type OneTimeProductAccessPeriod } from "@fitness-app/data-models/entities/ProductClient";

import { ONE_TIME_STATUS_MAPPER } from "~/modules/Products/constants/oneTimeStatusMapper";
import InfoAboutExtendedAccessPeriod from "~/modules/Products/Product/ProductClientsList/components/InfoAboutExtendedAccessPeriod/InfoAboutExtendedAccessPeriod";

interface OneTimePaymentInfoProps {
  accessPeriod: OneTimeProductAccessPeriod;
  showStrategyInfo?: boolean;
  refunded?: boolean;
}

const ProductOneTimePaymentInfo = ({ accessPeriod, showStrategyInfo = false, refunded }: OneTimePaymentInfoProps) => {
  const { t } = useTranslation(["client", "common", "products"]);
  return (
    <>
      <Popover
        title={t("oneTime.title")}
        content={
          <Space direction="vertical">
            <Space>
              <Typography.Text type="secondary">{t("subscription.status")}</Typography.Text>
              <Badge
                status={ONE_TIME_STATUS_MAPPER[accessPeriod.status ?? "active"]}
                text={
                  refunded
                    ? t("common:subscriptionStatus.archived")
                    : t(`common:subscriptionStatus.${accessPeriod.status ?? "active"}`)
                }
              />
            </Space>
            <Space>
              <Typography.Text type="secondary">{t("subscription.startFrom")}</Typography.Text>
              <Typography.Text>
                {dayjs.unix(accessPeriod.currentPeriodStart).format("DD.MM.YY (HH:mm)")}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text type="secondary">{t("subscription.endTo")}</Typography.Text>
              <Typography.Text>{dayjs.unix(accessPeriod.currentPeriodEnd).format("DD.MM.YY (HH:mm)")}</Typography.Text>
            </Space>
            <InfoAboutExtendedAccessPeriod accessPeriod={accessPeriod} />
            {accessPeriod.recurring?.interval && (
              <Space>
                <Typography.Text type="secondary">{t("oneTime.accessPeriod")}</Typography.Text>
                <Typography.Text>
                  {t(`products:one_time.${accessPeriod.recurring?.interval}`, {
                    count: accessPeriod.recurring?.interval_count,
                  })}
                </Typography.Text>
              </Space>
            )}
            {showStrategyInfo && (
              <Space>
                <Typography.Text type="secondary">{t("oneTime.endAccessStrategy")}</Typography.Text>
                <Typography.Text>
                  {t(`common:endAccessStrategy.${accessPeriod.endAccessStrategy ?? EndAccessStrategy.Cancel}`)}
                </Typography.Text>
              </Space>
            )}
          </Space>
        }
      >
        <InfoCircleOutlined style={{ fontSize: 18, marginTop: 2 }} />
      </Popover>
    </>
  );
};

export default ProductOneTimePaymentInfo;
