import React, { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, InputNumber, List, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { type Ingredient, type IngredientOnShoppingList } from "@fitness-app/data-models/entities/Ingredient";
import { getIngredientInMeals } from "@fitness-app/utils/src/nutrition/getShoppingList";

import { useSearchIngredients } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/components/useSearchIngredients";
import { Weekdays } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/MealsPlanShoppingList";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { type ReplaceIngredientFormModel } from "./ReplaceIngredient.types";

interface ReplaceIngredientFormProps {
  formController?: FormInstance<ReplaceIngredientFormModel>;
  onSubmit: (formData: ReplaceIngredientFormModel) => void;
  model?: Partial<ReplaceIngredientFormModel> | null;
  ingredient: IngredientOnShoppingList;
}

const ReplaceIngredientForm = ({ onSubmit, formController, ingredient }: ReplaceIngredientFormProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const { planDetails } = useMealsPlanContext();

  const { items, isFetching, setSearchValue, clearSearch } = useSearchIngredients();
  const [selectedIngredientForReplace, setSelectedIngredientForReplace] = useState<Ingredient | null>(null);

  const onIngredientSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
  }, 300);

  const nutritionPlanDays = planDetails.weeks[0]?.days;
  const ingredientDishes = getIngredientInMeals(ingredient, nutritionPlanDays || []);

  useEffect(() => {
    if (ingredientDishes.length) {
      formController?.setFieldsValue({
        dishes: ingredientDishes.map((dish) => ({
          name: dish[0]?.dishName,
          preparationSteps: dish[0]?.dishPreparationSteps || [],
          id: dish[0]?.dishId,
          dish,
        })),
      });
    }
  }, [ingredientDishes]);

  if (!nutritionPlanDays) {
    return;
  }

  return (
    <Form<ReplaceIngredientFormModel>
      name="form"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      form={formController}
      initialValues={{
        updatedIngredient: "",
        proportion: 100,
      }}
      onFinish={(model) => onSubmit({ ...model, selectedIngredientForReplace })}
    >
      <Form.Item
        name="updatedIngredient"
        label={t<string>("replaceIngredientForm.updatedIngredient")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        validateStatus={isFetching ? "validating" : undefined}
      >
        <Select
          allowClear
          showSearch
          onClear={() => {
            setSelectedIngredientForReplace(null);
            clearSearch();
          }}
          placeholder={t("replaceIngredientForm.updatedIngredientPlaceholder")}
          popupMatchSelectWidth={false}
          onSearch={onIngredientSearch}
          optionLabelProp="label"
          notFoundContent={null}
          optionFilterProp="label"
          onSelect={(value, option) => {
            if (option["data-value"]) {
              setSelectedIngredientForReplace(option["data-value"] as Ingredient);
            }
          }}
        >
          {items.map((option) => (
            <Select.Option value={option.id} label={option.name} key={option.id} data-value={option}>
              <List.Item className="group relative cursor-pointer">
                <List.Item.Meta
                  title={`${option.name} ${option.brand ? `- ${option.brand}` : ""}`}
                  description={
                    <div className="flex justify-between pt-2 font-light">
                      <dd className="text-xs text-gray-700">
                        B: {option.nutrients.protein} g | W: {option.nutrients.carbohydrates} g | T:{" "}
                        {option.nutrients.fat} g
                      </dd>
                      <dd className="text-xs text-gray-700">
                        {option.nutrients.calories} kcal ({option.packageSize || "100"}
                        g)
                      </dd>
                    </div>
                  }
                />
              </List.Item>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="proportion"
        label={t<string>("replaceIngredientForm.proportion")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <InputNumber
          formatter={(value) => `${value} g`}
          parser={(value) => value?.replace(" g", "") as unknown as any}
          min={0.01}
          max={5000}
          precision={1}
          addonAfter="równe 100g"
        />
      </Form.Item>

      <Alert
        showIcon
        type="warning"
        className="mb-3"
        message="Produkt zostanie zastąpiony w wszystkich posiłkach, w których występuje."
      />

      <div className="flex flex-col divide-y divide-gray-200 rounded-md bg-gray-100/80 p-2 py-3">
        <Form.List name="dishes">
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <div className="flex flex-col" key={field.key}>
                  <div className="grid grid-cols-2 items-center py-2">
                    <Form.Item className="mb-0" name={[field.name, "name"]}>
                      <Input />
                    </Form.Item>
                    <span className="flex justify-end">
                      <Weekdays days={ingredientDishes[index]?.map((item) => item.name) || []} />
                    </span>
                  </div>
                  <Form.Item label="Sposób przygotowania" className="py-3">
                    <Form.List name={[field.name, "preparationSteps"]}>
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field) => (
                              <Form.Item
                                {...field}
                                key={field.key}
                                rules={[
                                  {
                                    required: true,
                                    message: t<string>("common:validationErrors.fieldIsRequired"),
                                  },
                                ]}
                              >
                                <Input
                                  addonAfter={
                                    <Button
                                      size="small"
                                      type="text"
                                      icon={<DeleteOutlined className="text-red-500" />}
                                      onClick={() => remove(field.key)}
                                    />
                                  }
                                />
                              </Form.Item>
                            ))}
                            <Button shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add("")} />
                          </div>
                        );
                      }}
                    </Form.List>
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </div>
    </Form>
  );
};

export default ReplaceIngredientForm;
