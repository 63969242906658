import React, { memo, useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Dropdown, Empty, List, Space, type DropdownProps } from "antd";
import { useTranslation } from "react-i18next";

import { traineeActions } from "@fitness-app/app-store";
import { RequestStatus } from "@fitness-app/data-models";
import { TraineeEventType } from "@fitness-app/data-models/businessEvents/domain/trainee/TraineeEvents";
import { TraineeActivityEventType } from "@fitness-app/data-models/businessEvents/domain/traineeActivity/TraineeActivityEvents";

import ActivityEventItem from "~/modules/Trainee/TraineeProfile/components/ActivityEventItem";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

type FilterType = TraineeActivityEventType | TraineeEventType;

const ActivityFilters = memo(
  ({
    selectedKeys,
    setSelectedKeys,
  }: {
    selectedKeys: FilterType[];
    setSelectedKeys: (keys: FilterType[]) => void;
  }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation(["dashboard", "common"]);
    const filters = [
      TraineeActivityEventType.TraineeAddedWeightMeasurement,
      TraineeActivityEventType.TraineeAddedBodyMeasurements,
      TraineeActivityEventType.TraineeAddedBodyPhotos,
      TraineeActivityEventType.TraineeTrackedWaterConsumption,
      TraineeActivityEventType.TraineeAddedWorkoutRating,
      TraineeActivityEventType.TraineeSentSurveyAnswers,
      TraineeActivityEventType.TraineeSentExerciseRecording,
      TraineeEventType.TraineeAssignedToTrainer,
      TraineeEventType.TraineeRemovedFromTrainer,
      TraineeEventType.TraineeAddedToApp,
      TraineeEventType.TraineeActivatedAccount,
      TraineeEventType.TraineeAccountDeactivated,
      TraineeEventType.TraineeStartedWorkoutProgram,
      TraineeEventType.TraineeGotAccessToWorkoutProgram,
      TraineeEventType.TraineeEndedWorkoutProgram,
      TraineeEventType.TraineeStartedNutritionPlan,
      TraineeEventType.TraineeGotAccessToNutritionPlan,
      TraineeEventType.TraineeEndedNutritionPlan,
    ].map((event) => ({
      key: event,
      label: (
        <Checkbox onClick={(ev) => ev.stopPropagation()} checked={selectedKeys.includes(event)}>
          {t(`common:${event}`)}
        </Checkbox>
      ),
    }));

    const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
      if (info.source === "trigger" || nextOpen) {
        setOpen(nextOpen);
      }
    };

    return (
      <Space size={4}>
        {selectedKeys.length > 0 && (
          <Button onClick={() => setSelectedKeys([])} type="link">
            {t("common:button.reset")}
          </Button>
        )}
        <Dropdown
          onOpenChange={handleOpenChange}
          open={open}
          menu={{
            items: filters,
            selectable: true,
            selectedKeys,
            multiple: true,
            onDeselect: ({ selectedKeys }) => {
              setSelectedKeys(selectedKeys as FilterType[]);
            },
            onSelect: ({ selectedKeys }) => {
              setSelectedKeys(selectedKeys as FilterType[]);
            },
          }}
        >
          <Button type={selectedKeys.length ? "primary" : undefined} icon={<FilterOutlined />} />
        </Dropdown>
      </Space>
    );
  },
);

ActivityFilters.displayName = "ActivityFilters";

const LatestActivity = () => {
  const { t } = useTranslation("trainees");
  const profile = useAppSelector((store) => store.trainee.profile);
  const dispatch = useAppDispatch();
  const { clientActivityEventsStatus, clientActivityEvents, totalPages, page } = useAppSelector(
    (store) => store.trainee,
  );
  const [selectedKeys, setSelectedKeys] = useState<FilterType[]>([]);

  const traineeId = profile?.id;

  useEffect(() => {
    if (traineeId) {
      void dispatch(traineeActions.fetchClientActivityEvents({ id: traineeId, type: selectedKeys }));
    }

    return () => {
      dispatch(traineeActions.clearActivityEvents());
    };
  }, [traineeId, selectedKeys]);

  const onLoadMore = () => {
    if (traineeId) {
      void dispatch(traineeActions.fetchClientActivityEvents({ id: traineeId, page: page + 1 }));
    }
  };

  const loadMore =
    (clientActivityEventsStatus === RequestStatus.SUCCESS || clientActivityEventsStatus === RequestStatus.UPDATING) &&
    page < totalPages ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button loading={clientActivityEventsStatus === RequestStatus.UPDATING} onClick={onLoadMore}>
          Pobierz więcej
        </Button>
      </div>
    ) : null;

  return (
    <Card
      className="activity-card h-full"
      title={t("sections.latestActivity")}
      loading={clientActivityEventsStatus === RequestStatus.FETCHING}
      extra={<ActivityFilters selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />}
    >
      <List
        itemLayout="vertical"
        locale={{
          emptyText: (
            <Empty
              imageStyle={{ marginTop: 80 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("emptyActivityList")}
            />
          ),
        }}
        loadMore={loadMore}
        dataSource={clientActivityEvents}
        renderItem={(item) => <ActivityEventItem event={item} />}
      />
    </Card>
  );
};

export default LatestActivity;
