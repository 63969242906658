import React, { useEffect, useMemo, type MouseEvent } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions, productsSettingsSelectors } from "@fitness-app/app-store";
import { EmailClientTask } from "@fitness-app/data-models/entities/Automation";
import {
  EmailClient,
  type EmailClientIntegration,
  type EmailClientList,
} from "@fitness-app/data-models/entities/ProductsSettings";

import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const useEmailLists = () => {
  const tags = useAppSelector(productsSettingsSelectors.getTagsOptions);
  const { data: integrationData, status } = useAppSelector((store) => store.productsSettings);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "products"]);

  useEffect(() => {
    if (!status) {
      dispatch(productsSettingsActions.subscribeToProductsSettings());
    }
  }, [status]);

  const mailers = integrationData?.mailers;

  const emailAutomationTaskOptions = useMemo(
    () =>
      Object.values(EmailClientTask).map((value) => ({
        label: t(`common:emailClientTask.${value}`),
        value,
      })),
    [t],
  );

  const handleListRefresh = async (e: MouseEvent<HTMLSpanElement>, client: EmailClient) => {
    if (!mailers) {
      return;
    }
    e.stopPropagation();
    void message.loading(t("messages.loading.refreshLists"));

    if (client in mailers) {
      const accountToRefresh = mailers[client]?.map((account: EmailClientIntegration) => ({
        url: account.url,
        client: account.client,
      }));

      if (accountToRefresh) {
        await Promise.all(
          accountToRefresh.map((account) =>
            dispatch(
              productsSettingsActions.refreshConnectedListsWithEmailClient({
                payload: { url: account.url, client: account.client },
              }),
            ),
          ),
        );
      }
    }

    message.destroy();
  };

  const listOptions = useMemo(() => {
    if (!mailers) {
      return [];
    }
    return Object.entries(mailers).map(([key, list]) => {
      const emailClient = key as EmailClient;
      return {
        title: (
          <div>
            {t(`mailer.${emailClient}`)}{" "}
            <span style={{ color: "#2f90d6" }} onClick={(e) => handleListRefresh(e, emailClient)}>
              (
              <ReloadOutlined style={{ margin: "0 4px", color: "#2f90d6" }} />
              {t("button.refresh")})
            </span>
          </div>
        ),
        value: emailClient,
        key: emailClient,
        children: list
          ?.map(
            (account: EmailClientIntegration) =>
              account.lists?.map((list: EmailClientList) => ({
                label: list.name,
                title: `${list.name} (${emailClient})`,
                value: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                key: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
              })),
          )
          .flat(),
      };
    });
  }, [mailers, t]);

  // some client required adding tags/field by client and list, but other required only account id
  const listOrAccountOptions = useMemo(() => {
    if (!mailers) {
      return [];
    }
    const clientsWithNeedListId = [EmailClient.GetResponse, EmailClient.MailChimp, EmailClient.FreshMail];
    return Object.entries(mailers).map(([key, list]) => {
      const emailClient = key as EmailClient;
      return {
        title: (
          <div>
            {t(`mailer.${emailClient}`)}{" "}
            <span style={{ color: "#2f90d6" }} onClick={(e) => handleListRefresh(e, emailClient)}>
              (
              <ReloadOutlined style={{ margin: "0 4px", color: "#2f90d6" }} />
              {t("button.refresh")})
            </span>
          </div>
        ),
        value: emailClient,
        key: emailClient,
        children: clientsWithNeedListId.includes(emailClient)
          ? list
              ?.map(
                (account: EmailClientIntegration) =>
                  account.lists?.map((list: EmailClientList) => ({
                    label: list.name,
                    title: `${list.name} (${emailClient})`,
                    value: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                    key: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                  })),
              )
              .flat()
          : list?.map((account: EmailClientIntegration) => ({
              label: account.url,
              title: `${account.url} (${emailClient})`,
              value: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}`,
              key: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}`,
            })),
      };
    });
  }, [mailers, t]);

  const emailClients = useMemo(() => {
    if (!mailers) {
      return [];
    }

    return Object.entries(mailers).map(([key, list]) => {
      const emailClient = key as EmailClient;
      return {
        title: (
          <div>
            {t(`mailer.${emailClient}`)}{" "}
            <span style={{ color: "#2f90d6" }} onClick={(e) => handleListRefresh(e, emailClient)}>
              (
              <ReloadOutlined style={{ margin: "0 4px", color: "#2f90d6" }} />
              {t("button.refresh")})
            </span>
          </div>
        ),
        value: emailClient,
        key: emailClient,
        children: list?.map((account: EmailClientIntegration) => ({
          label: account.url,
          title: `${account.url} (${emailClient})`,
          value: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}`,
          key: `${emailClient}${LIST_ITEM_SEPARATOR}${account.url}`,
        })),
      };
    });
  }, [mailers, t]);

  return {
    tags,
    lists: listOptions.filter((item) => item.children?.length),
    handleListRefresh,
    listOrAccountOptions: listOrAccountOptions.filter((item) => item.children?.length),
    emailClients: emailClients.filter((item) => item.children?.length),
    emailAutomationTaskOptions,
  };
};
