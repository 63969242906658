import React, { useMemo } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Popconfirm, Space, Table, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { RequestStatus, surveysTemplatesActions } from "@fitness-app/app-store";
import { Gender } from "@fitness-app/data-models/entities/shared";
import {
  SurveyQuestionType,
  SurveyTemplateType,
  type SurveyQuestion,
  type SurveyTemplate,
  type SurveyTemplateWithCreator,
} from "@fitness-app/data-models/entities/SurveyTemplate";
import { createQuestions } from "@fitness-app/utils/src/surveys/createInitialSurvey";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useFormHandler } from "~/hooks/useFormHandler";
import { useLoading } from "~/hooks/useLoading";
import InitialSurvey from "~/modules/SurveysTemplates/InitialSurveyConfigurator/InitialSurvey";
import SurveyDetails from "~/modules/SurveysTemplates/SurveyDetails/SurveyDetails";
import SurveyForm from "~/modules/SurveysTemplates/SurveyForm/SurveyForm";
import { type SurveyFormModel } from "~/modules/SurveysTemplates/SurveyForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const InitialSurveyConfigurator = () => {
  const { openFormModal, setEditedEntity, editedEntity, showModalForm, hideFormModal, formModel } = useFormHandler<
    SurveyFormModel,
    SurveyTemplate
  >();
  const [savingSurvey, startSaving, stopSaving] = useLoading();
  const { userId } = useUserRole();

  const openSurveyEditForm = (survey: SurveyTemplateWithCreator) => {
    setEditedEntity(survey);
    openFormModal(survey);
  };

  const dispatch = useAppDispatch();
  const { t } = useTranslation("surveys");
  const { list: surveysList, listStatus } = useAppSelector((state) => state.surveysTemplates);

  const initialSurvey = useMemo(
    () => surveysList.filter((survey) => survey.type === SurveyTemplateType.INITIAL),
    [surveysList],
  );
  const onArchiveSurvey = async (surveyId: string) => {
    await dispatch(
      surveysTemplatesActions.archiveSurveyTemplate({
        surveyTemplateId: surveyId,
      }),
    );
  };

  const onFormSubmit = async (model: SurveyFormModel) => {
    const basicQuestion = createQuestions(t, Gender.FEMALE);

    const questions: Record<string, SurveyQuestion> = {};

    if (model.selectedBasicQuestions?.length) {
      model.selectedBasicQuestions.forEach((key, index) => {
        if (key in basicQuestion) {
          const selectedQuestion = basicQuestion[key as keyof typeof basicQuestion];
          if (
            editedEntity?.questions &&
            Object.values(editedEntity.questions).find((question) => question.key === selectedQuestion.key)
          ) {
            return;
          }

          if (selectedQuestion.type === SurveyQuestionType.AB) {
            const id = uuid();
            questions[id] = {
              type: SurveyQuestionType.AB,
              key: selectedQuestion.key,
              isRequired: true,
              protected: true,
              id,
              orderKey: index,
              title: selectedQuestion.title,
              desc: selectedQuestion.desc || "",
              extendedAnswerA: false,
              extendedAnswerB: false,
            };
          }

          if (selectedQuestion.type === SurveyQuestionType.Select) {
            const id = uuid();
            questions[id] = {
              type: SurveyQuestionType.Select,
              key: selectedQuestion.key,
              isRequired: true,
              protected: true,
              id,
              orderKey: index,
              title: selectedQuestion.title,
              desc: selectedQuestion.desc || "",
              multiSelect: "multiSelect" in selectedQuestion ? selectedQuestion.multiSelect : false,
              answers: "answers" in selectedQuestion ? selectedQuestion.answers : [],
            };
          }

          if (selectedQuestion.type === SurveyQuestionType.Text) {
            const id = uuid();
            questions[id] = {
              type: SurveyQuestionType.Text,
              key: selectedQuestion.key,
              isRequired: true,
              protected: true,
              id,
              orderKey: index,
              title: selectedQuestion.title,
              desc: selectedQuestion.desc || "",
            };
          }
        }
      });
    }

    startSaving();
    if (editedEntity) {
      await dispatch(
        surveysTemplatesActions.editSurveyTemplate({
          surveyTemplateId: editedEntity.id,
          surveyTemplate: {
            title: model.title,
            description: model.description,
            questions: { ...editedEntity.questions, ...questions },
          },
        }),
      );
      hideFormModal();
      stopSaving();
    } else {
      const template: SurveyTemplate = {
        title: model.title,
        description: model.description,
        type: SurveyTemplateType.INITIAL,
        id: uuid(),
        name: "",
        archived: false,
        automation: {},
        questions,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: userId,
      };
      await dispatch(surveysTemplatesActions.addSurveyTemplate({ surveyTemplate: template })).unwrap();
      hideFormModal();
      stopSaving();
    }
  };

  return (
    <Card>
      <InitialSurvey />
      <Card
        type="inner"
        title="Lista ankiet"
        style={{ marginTop: 20 }}
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => openFormModal()}>
            {t("addSurveyTemplate")}
          </Button>
        }
      >
        <Table
          rowKey="id"
          dataSource={initialSurvey}
          loading={listStatus === RequestStatus.FETCHING}
          locale={{
            emptyText: <div>{t("survey.emptyState")} </div>,
          }}
          scroll={{ x: true }}
          expandable={{
            expandedRowRender: (row) => <SurveyDetails withoutCard surveyTemplate={row} />,
          }}
        >
          <Table.Column title={t("survey.title")} dataIndex="title" key="title" ellipsis />
          <Table.Column
            title={t("survey.description")}
            dataIndex="desc"
            key="desc"
            render={(desc: string) => {
              if (!desc) {
                return "-";
              }
              return (
                <Tooltip title={desc}>
                  <InfoCircleOutlined />
                </Tooltip>
              );
            }}
          />
          <Table.Column
            title={t("survey.numberOfQuestions")}
            dataIndex=""
            key="questionNumber"
            render={(row: SurveyTemplateWithCreator) => Object.keys(row.questions).length}
          />
          <Table.Column<SurveyTemplateWithCreator>
            title={t("common:button.options")}
            key="action"
            align="center"
            render={(_, survey) => (
              <Space>
                <Typography.Link onClick={() => openSurveyEditForm(survey)}>{t("common:button.edit")}</Typography.Link>
                <Divider type="vertical" />
                <Popconfirm
                  title={t("survey.archiveDelete")}
                  okText={t("common:yes")}
                  cancelText={t("common:no")}
                  placement="left"
                  onConfirm={() => onArchiveSurvey(survey.id)}
                >
                  <Typography.Link type="danger">{t("common:button.archive")}</Typography.Link>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </Card>
      <ModalForm
        open={showModalForm}
        width={800}
        loading={savingSurvey}
        onCancel={hideFormModal}
        title={formModel ? t("survey.edit") : t("survey.add")}
      >
        <SurveyForm isInitialSurvey onSubmit={onFormSubmit} model={formModel} />
      </ModalForm>
    </Card>
  );
};

export default InitialSurveyConfigurator;
