import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { getParentTrainerId } from "@fitness-app/app-store/src/store/reducers/user/selectors";

import ImageUploadField from "~/components/ImageUploadField/ImageUploadField";
import { useAppSelector } from "~/store/initializeStore";
import { type BotConfigFormModel } from "./types";

interface BotConfigFormProps {
  formController?: FormInstance<BotConfigFormModel>;
  onSubmit: (formData: BotConfigFormModel) => void;
  model?: Partial<BotConfigFormModel> | null;
}

const BotConfigForm = ({ model, onSubmit, formController }: BotConfigFormProps) => {
  const { t } = useTranslation(["automation", "common"]);
  const parentTrainerId = useAppSelector(getParentTrainerId);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <Form<BotConfigFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        name: "",
        description: "",
        avatar: [],
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="name"
        label={t("botConfig.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        tooltip={t<string>("botConfig.photoTooltip")}
        name="avatar"
        label={t<string>("botConfig.avatar")}
        valuePropName="fileList"
        getValueFromEvent={(e: (string | undefined)[]) => e}
      >
        <ImageUploadField storageRef={`${parentTrainerId}/bots`} multiple={false} />
      </Form.Item>

      <Form.Item name="description" label={t("botsConfig.description")} extra={t("botsConfig.descriptionExtra")}>
        <Input.TextArea rows={3} />
      </Form.Item>
    </Form>
  );
};

export default BotConfigForm;
