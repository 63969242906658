import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENTS_REDUCER_NAME } from "../types";

export const fetchAllProductClients = createAsyncThunk<
  { list: ProductClient[]; count: number },
  { productId: string; type: "all" | "active" | "archived" },
  AsyncThunkCreator<string>
>(`${PRODUCT_CLIENTS_REDUCER_NAME}/fetchAllProductClients`, async (payload, { rejectWithValue, extra: { db } }) => {
  let query = db.from("product_client").select("*", { count: "exact" }).eq("productId", payload.productId);

  if (payload.type === "active" || payload.type === "archived") {
    query = query.eq("status", payload.type);
  }

  const { error, data, count } = await query.order("createdAt", { ascending: false }).returns<ProductClient[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return {
    list: data,
    count: count || 0,
  };
});
