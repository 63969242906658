import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AsyncThunkCreator } from "../../../index";
import { EXERCISES_REDUCER_NAME } from "../types";

type Payload = string;

export const deleteExercise = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${EXERCISES_REDUCER_NAME}/deleteExercise`,
  async (exerciseId, { rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db.from("exercise").delete().eq("id", exerciseId);

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("delete_exercise");
  },
);
