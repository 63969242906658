import React, { useState, type FunctionComponent } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Row, Segmented, Space } from "antd";
import { useTranslation } from "react-i18next";

import { EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";

import { mailerOptions } from "~/modules/Products/ProductsSettings/mailerOptions";
import { useAppSelector } from "~/store/initializeStore";
import ActiveCampaignIntegration from "../EmailIntegration/ActiveCampaign/ActiveCampaignIntegration";
import ConvertKitIntegration from "../EmailIntegration/ConvertKit/ConvertKitIntegration";
import FreshMailIntegration from "../EmailIntegration/FreshMail/FreshMailIntegration";
import GetResponseIntegration from "../EmailIntegration/GetResponse/GetResponseIntegration";
import MailChimpIntegration from "../EmailIntegration/MailChimp/MailChimpIntegration";
import MailerLiteIntegration from "../EmailIntegration/MailerLite/MailerLiteIntegration";

const EmailClients: FunctionComponent = () => {
  const { t } = useTranslation(["settings", "common"]);

  const { data } = useAppSelector((state) => state.productsSettings);
  const [selectedClient, setClient] = useState<EmailClient>(EmailClient.ActiveCampaign);

  if (!data) {
    return null;
  }

  const renderActiveIntegration = () => {
    if (selectedClient === EmailClient.ActiveCampaign) {
      return <ActiveCampaignIntegration integration={data.mailers?.[EmailClient.ActiveCampaign] || null} />;
    }

    if (selectedClient === EmailClient.ConvertKit) {
      return <ConvertKitIntegration integration={data?.mailers?.[EmailClient.ConvertKit] || null} />;
    }

    if (selectedClient === EmailClient.MailerLite) {
      return <MailerLiteIntegration integration={data?.mailers?.[EmailClient.MailerLite] || null} />;
    }

    if (selectedClient === EmailClient.MailChimp) {
      return <MailChimpIntegration integration={data?.mailers?.[EmailClient.MailChimp] || null} />;
    }

    if (selectedClient === EmailClient.GetResponse) {
      return <GetResponseIntegration integration={data?.mailers?.[EmailClient.GetResponse] || null} />;
    }

    if (selectedClient === EmailClient.FreshMail) {
      return <FreshMailIntegration integration={data?.mailers?.[EmailClient.FreshMail] || null} />;
    }

    return null;
  };

  return (
    <div className="flex min-w-[450px] flex-col items-center pt-8">
      <Space direction="vertical" size={24}>
        <Row gutter={24} justify="center">
          <Segmented
            size="large"
            options={mailerOptions.map((client) => ({
              value: client.value,
              label: (
                <div>
                  {t(`common:mailer.${client.value}`)}{" "}
                  {data.mailers && client.value in data.mailers && data.mailers?.[client.value]?.length ? (
                    <CheckCircleOutlined style={{ color: "#1bc5bd" }} />
                  ) : null}
                </div>
              ),
            }))}
            value={selectedClient}
            onChange={(value) => setClient(value as EmailClient)}
          />
        </Row>
      </Space>

      <div className="mt-6">{renderActiveIntegration()}</div>
    </div>
  );
};

export default EmailClients;
