import React, { useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, message, Popconfirm, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions } from "@fitness-app/app-store";
import { InvoicesClient } from "@fitness-app/data-models/entities/Product";
import { WFirmaIntegrationStatus, type WFirmaIntegration } from "@fitness-app/data-models/entities/ProductsSettings";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useLoading } from "~/hooks/useLoading";
import { useAppDispatch } from "~/store/initializeStore";
import { type WFirmaIntegrationFormModel } from "./types";
import WFirmaIntegrationForm from "./WFirmaIntegrationForm";

interface OwnProps {
  integration: WFirmaIntegration[] | null;
}

type Props = OwnProps;

const ADD_NEW_ITEM = "ADD_NEW_ITEM";

const statusMapper = {
  [WFirmaIntegrationStatus.Completed]: "success",
  [WFirmaIntegrationStatus.InProgress]: "warning",
  [WFirmaIntegrationStatus.Rejected]: "error",
} as const;

const WFirmaIntegrationPanel: FunctionComponent<Props> = ({ integration }) => {
  const { t } = useTranslation(["settings", "common"]);
  const [loading, startLoading, stopLoading] = useLoading();
  const [showModal, toggleModal] = useState(false);
  const [step, changeStep] = useState<0 | 1>(0);
  const dispatch = useAppDispatch();

  const [selectedAccountUrl, changeSelectedAccount] = useState<undefined | string>(undefined);

  const selectedAccount = useMemo(() => {
    return integration?.find((account) => account.url === selectedAccountUrl);
  }, [selectedAccountUrl, integration]);

  const handleRemoveIntegration = async () => {
    if (!selectedAccount) {
      return;
    }
    void message.loading(t("messages.loading.removeAccount"));

    const onSuccess = () => {
      message.destroy();
      void message.success(t("messages.success.removeAccount"));
      changeSelectedAccount(undefined);
    };
    const onFailure = () => {
      message.destroy();
      void message.error(t("messages.error.removeAccount"));
    };
    await dispatch(
      productsSettingsActions.removeIntegrationWithInvoicesClient({
        payload: { url: selectedAccount.url, client: selectedAccount.client },
        onFailure,
        onSuccess,
      }),
    );
  };

  const handleChange = (value?: string | undefined) => {
    if (value === ADD_NEW_ITEM || !value) {
      changeSelectedAccount(undefined);
      setTimeout(() => {
        toggleModal(true);
      }, 100);

      return;
    }
    changeSelectedAccount(value);
  };

  const handleSubmit = async (data: WFirmaIntegrationFormModel) => {
    if (step === 0) {
      changeStep(1);
      return;
    }
    startLoading();
    await dispatch(
      productsSettingsActions.integrateWithInvoicesClient({
        payload: { ...data, client: InvoicesClient.wFirma },
        onSuccess: () => {
          void message.success(t(selectedAccount ? "wfirma.editSuccess" : "wfirma.success"));
          toggleModal(false);
          handleChange(data.url);
        },
        onFailure: (errorCode) => {
          if (errorCode === 403) {
            void message.error(t("wfirma.accessError"));
            return;
          }
          void message.error(t("wfirma.error"));
        },
      }),
    );
    stopLoading();
  };

  return (
    <Space direction="vertical" size={24} className="transition-visibility mt-20 duration-300 ease-in-out">
      <Typography.Title level={4}>{t("emailClients.activeCampaign.connectedAccounts")}</Typography.Title>
      <Select
        size="large"
        placeholder={t("selectAccount")}
        style={{ minWidth: 400, maxWidth: 450 }}
        notFoundContent={false}
        onChange={handleChange}
        onSelect={handleChange}
        value={selectedAccount?.url ?? undefined}
        optionFilterProp="title"
        optionLabelProp="title"
      >
        {integration?.map((account) => (
          <Select.Option value={account.url} title={account.accountName} key={account.url}>
            {account.accountName} - {t(`common:invoices.${account.client}`)}
          </Select.Option>
        ))}
        {
          <Select.Option value={ADD_NEW_ITEM} title={t<string>("addNewAccount")}>
            <Button
              type="link"
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
            >
              <PlusOutlined /> {t<string>("addNewAccount")}
            </Button>
          </Select.Option>
        }
      </Select>
      {selectedAccount && (
        <>
          <Space align="center" size={8}>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {t("invoicesClient.integrationStatus")}
            </Typography.Paragraph>
            <Badge
              status={statusMapper[selectedAccount.status]}
              style={{ fontWeight: "bold" }}
              text={t(`invoices.wfirma.${selectedAccount.status}`)}
            />
          </Space>
          {selectedAccount.status !== WFirmaIntegrationStatus.Completed && (
            <div style={{ textAlign: "center" }}>
              {/*<Typography.Link*/}
              {/*  href={createWFirmaIntegrationLink({*/}
              {/*    clientId: selectedAccount.url,*/}
              {/*    functionDomain: process.env.REACT_APP_FUNCTION_DOMAIN as string,*/}
              {/*    userId: user?.uid || "",*/}
              {/*  })}*/}
              {/*  target="_blank"*/}
              {/*  strong*/}
              {/*  copyable={{*/}
              {/*    text: createWFirmaIntegrationLink({*/}
              {/*      clientId: selectedAccount.url,*/}
              {/*      functionDomain: process.env.REACT_APP_FUNCTION_DOMAIN as string,*/}
              {/*      userId: user?.uid || "",*/}
              {/*    }),*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {t("invoices.wfirma.finishIntegration")}*/}
              {/*</Typography.Link>*/}
            </div>
          )}

          <Space direction="horizontal" align="end" style={{ width: "100%", justifyContent: "flex-end" }}>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => {
                changeStep(1);
                toggleModal(true);
              }}
            >
              {t("common:button.edit")}
            </Button>
            <Popconfirm
              onConfirm={handleRemoveIntegration}
              title={t("invoicesClient.deleteWarning")}
              okText={t("common:button.delete")}
              okButtonProps={{ danger: true }}
              overlayStyle={{ maxWidth: 350 }}
            >
              <Button type="dashed" danger icon={<DeleteOutlined />}>
                {t("common:button.delete")}
              </Button>
            </Popconfirm>
          </Space>
        </>
      )}
      <ModalForm
        visible={showModal}
        loading={loading}
        onCancel={() => {
          toggleModal(false);
          changeStep(0);
        }}
        title={selectedAccount ? t("emailClients.editAccount") : t("emailClients.addNewAccount")}
        okText={step ? undefined : t("common:button.next")}
      >
        <WFirmaIntegrationForm
          onSubmit={handleSubmit}
          step={step}
          model={
            selectedAccount
              ? {
                  url: selectedAccount.url,
                  accountName: selectedAccount.accountName,
                  settings: selectedAccount.settings,
                  clientSecret: "",
                  companyId: selectedAccount.companyId,
                }
              : undefined
          }
        />
      </ModalForm>
    </Space>
  );
};

export default WFirmaIntegrationPanel;
