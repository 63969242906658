import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Trainee, type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  profile: Partial<Trainee>;
  additionalData?: Partial<TraineeProfile>;
};

export const updateTrainee = createAsyncThunk<Trainee | null, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/updateTrainee`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { data, error } = await db
      .from("trainee")
      .update({ ...payload.profile, updatedAt: new Date().toISOString() })
      .eq("id", payload.id)
      .select("*")
      .returns<Trainee[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_trainee");

    return data[0] ? { ...(payload.additionalData || {}), ...data[0] } : null;
  },
);
