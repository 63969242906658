import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";
import { TargetGender } from "@fitness-app/data-models/entities/MealsPlan";
import { generateUpdatedMealsPlanSchema } from "@fitness-app/utils/src/nutrition/generateMealsPlanDetails";
import {
  transformUpdatedMealPlanModelToMealsPlan,
  type AddMealPlanModel,
  type NutrientsTargets,
} from "@fitness-app/utils/src/nutrition/mealsPlanGenerators";

import { useEntityChange } from "~/hooks/useEntityChange";
import AddMealPlan from "~/modules/Nutrition/AddMealPlan/AddMealPlan";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";

const MealsPlanTargets = ({ readonly }: { readonly?: boolean }): React.ReactElement => {
  const { t } = useTranslation(["nutrition", "common"]);
  const [formController] = Form.useForm<AddMealPlanModel>();
  const { planDetails, plan, updateMealsPlan } = useMealsPlanContext();
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const queryClient = useQueryClient();

  const onUpdateMealsPlan = async (model: AddMealPlanModel, targets: NutrientsTargets) => {
    const modelToSave = transformUpdatedMealPlanModelToMealsPlan(plan, model, targets);

    const shouldUpdateNumberOfMeal = modelToSave.mealsSchema && modelToSave.mealsSchema !== plan.mealsSchema;

    onStart();

    try {
      await updateMealsPlan({
        mealsPlan: { metadata: "metadata" in plan ? plan.metadata : null, ...modelToSave },
        mealsPlanDetails:
          shouldUpdateNumberOfMeal && planDetails
            ? generateUpdatedMealsPlanSchema(planDetails, modelToSave.mealsSchema || plan.mealsSchema)
            : null,
      });
      if ("status" in plan && plan.status === ClientNutritionStatus.Scheduled) {
        await queryClient.invalidateQueries({ queryKey: ["scheduledNutrition", plan.traineeId] });
      }
      onSuccess();
    } catch {
      onFailure();
    }
  };

  const startAt =
    "status" in plan && plan.status === ClientNutritionStatus.Scheduled && plan.startAt
      ? dayjs(plan.startAt)
      : undefined;

  return (
    <div className="mx-auto max-w-[850px] py-12">
      <AddMealPlan
        onSubmit={onUpdateMealsPlan}
        formController={formController}
        disabled={readonly}
        startAt={startAt}
        model={{
          name: plan?.name,
          dietaryPreference: plan?.dietaryPreference,
          macroSplit: plan?.macroSplit,
          mealsSchema: plan?.mealsSchema,
          targetCalories: plan?.targetCalories,
          tags: plan?.tags || [],
          comment: plan?.comment || "",
          targets: {
            gender: plan?.targets?.gender || TargetGender.UNISEX,
            fat: plan?.targets?.fat,
            protein: plan?.targets?.protein,
            carbs: plan?.targets?.carbs,
            macroUnit: plan?.targets?.macroUnit,
            activityLevel: plan?.targets?.activityLevel,
          },
        }}
      />
      <div className="px-24 pt-4 text-right">
        <Button type="primary" onClick={() => formController.submit()} loading={Boolean(loading)} disabled={readonly}>
          {t("common:button.update")}
        </Button>
      </div>
    </div>
  );
};

export default MealsPlanTargets;
