import { type TFunction } from "i18next";

import { NotificationsFrequency } from "@fitness-app/data-models/entities/TraineeSettings";

export const NOTIFICATIONS_FREQUENCY_LABELS = {
  [NotificationsFrequency.DAILY]: "notifications.daily",
  [NotificationsFrequency.ONCE_A_WEEK]: "notifications.onceAWeek",
  [NotificationsFrequency.ONCE_A_TWO_WEEKS]: "notifications.onceATwoWeeks",
  [NotificationsFrequency.ONCE_A_MONTH]: "notifications.onceAMonth",
};

export const defaultNotificationsSettings = {
  allowNotifications: null,
  hideReadReminders: false,
  activeWeightMeasurementReminder: false,
  activeBodyMeasurementReminder: false,
  activeWorkoutsReminder: false,
  weightMeasurementFrequency: NotificationsFrequency.DAILY,
  bodyMeasurementFrequency: NotificationsFrequency.ONCE_A_WEEK,
};

export const weightMeasurementFrequencyOptions = (t: TFunction<string[]>) => [
  {
    value: NotificationsFrequency.DAILY,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.DAILY]),
  },
  {
    value: NotificationsFrequency.ONCE_A_WEEK,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.ONCE_A_WEEK]),
  },
  {
    value: NotificationsFrequency.ONCE_A_TWO_WEEKS,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.ONCE_A_TWO_WEEKS]),
  },
  {
    value: NotificationsFrequency.ONCE_A_MONTH,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.ONCE_A_MONTH]),
  },
];

export const bodyMeasurementFrequencyOptions = (t: TFunction<string[]>) => [
  {
    value: NotificationsFrequency.ONCE_A_WEEK,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.ONCE_A_WEEK]),
  },
  {
    value: NotificationsFrequency.ONCE_A_TWO_WEEKS,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.ONCE_A_TWO_WEEKS]),
  },
  {
    value: NotificationsFrequency.ONCE_A_MONTH,
    label: t(NOTIFICATIONS_FREQUENCY_LABELS[NotificationsFrequency.ONCE_A_MONTH]),
  },
];

export interface ClientNotificationsSettingsFormModel {
  weightMeasurementFrequency: NotificationsFrequency;
  bodyMeasurementFrequency: NotificationsFrequency;
  activeBodyMeasurementReminder: boolean;
  activeWeightMeasurementReminder: boolean;
  activeWorkoutsReminder: boolean;
  hideReadReminders: boolean;
}
