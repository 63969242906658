import isNaN from "lodash.isnan";
import isNumber from "lodash.isnumber";
import round from "lodash.round";
import toNumber from "lodash.tonumber";

// @object
// { type: 'error' | 'singleValue' | 'range' | 'perSet', }
// { value: string | number | [number, number] | number[] }

export const FITNESS_VALUE_TYPE = {
  SINGLE_VALUE: "singleValue",
  RANGE: "range",
  PER_SET: "perSet",
  ERROR: "error",
};

export const FITNESS_VALUE_ERRORS = {
  WRONG_VALUE_FORMAT: "wrongValueFormat",
  VALUE_CANNOT_BE_EMPTY: "valueCannotBeEmpty",
  WRONG_RANGE: "wrongRange",
  WRONG_RANGE_DIRECTION: "wrongRangeDirection",
  WRONG_SERIES_FORMAT: "wrongSeriesFormat",
  DIFFERENT_NUMBER_OF_SERIES: "differentNumberOfSeries",
};

const getNumber = (value: string, precision = 2, min: number, max: number) => {
  const parsedValue = toNumber(value.trim().replace(",", "."));
  return isNumber(parsedValue) && !isNaN(parsedValue) && parsedValue >= min && parsedValue <= max
    ? round(parsedValue, precision)
    : FITNESS_VALUE_ERRORS.WRONG_VALUE_FORMAT;
};

const getRangeValue = (stringValue: string, precision: number, min: number, max: number) => {
  const splitted = stringValue.split("-");
  if (splitted.length !== 2) {
    return FITNESS_VALUE_ERRORS.WRONG_VALUE_FORMAT;
  }
  const numberValues = splitted
    .map((value) => getNumber(value, precision, min, max))
    .filter((possibleNumber) => possibleNumber);
  if (numberValues.length !== 2) {
    return FITNESS_VALUE_ERRORS.WRONG_RANGE;
  }
  const endOfRange = numberValues ? numberValues[1] : null;
  const startOfRange = numberValues ? numberValues[0] : null;
  if (endOfRange && startOfRange && Number(endOfRange) <= Number(startOfRange)) {
    return FITNESS_VALUE_ERRORS.WRONG_RANGE_DIRECTION;
  }
  return numberValues;
};

const getValuePerSet = (
  stringValue: string,
  precision: number,
  numberOfSets: number | undefined,
  min: number,
  max: number,
) => {
  const splitted = stringValue.split("/");
  if (splitted.length < 2) {
    return FITNESS_VALUE_ERRORS.WRONG_VALUE_FORMAT;
  }

  const values = splitted.map((value) => {
    const range = getRangeValue(value, precision, min, max);
    if (typeof range !== "string" && range.every((num) => isNumber(num))) {
      return range;
    }
    return getNumber(value, precision, min, max);
  });
  const errors: string[] = [];
  values.forEach((item) => {
    if (typeof item === "string") {
      errors.push(item);
    }
  });
  if (errors.length) {
    return FITNESS_VALUE_ERRORS.WRONG_SERIES_FORMAT;
  }
  if (numberOfSets && values.length !== numberOfSets) {
    return FITNESS_VALUE_ERRORS.DIFFERENT_NUMBER_OF_SERIES;
  }
  return values;
};

type GetFitnessValueFromFieldConfig = {
  precision?: number;
  numberOfSets?: number;
  allowRange?: boolean;
  allowPerSet?: boolean;
  min?: number;
  max?: number;
};

export type ReturnOfFitnessField = ReturnType<typeof getFitnessValueFromField>;

export const getFitnessValueFromField = (
  value: string | number | null | [number, number],
  {
    precision = 2,
    numberOfSets,
    allowRange = true,
    allowPerSet = true,
    min = 0,
    max = 1000,
  }: GetFitnessValueFromFieldConfig = {},
) => {
  const errors: Array<string | (string | number | (string | number)[])[]> = [];
  if (typeof value === "number") {
    return {
      value: round(value, precision),
      type: FITNESS_VALUE_TYPE.SINGLE_VALUE,
    };
  }
  if (Array.isArray(value)) {
    return {
      value,
      type: FITNESS_VALUE_TYPE.RANGE,
    };
  }
  if (typeof value !== "string") {
    return {
      type: FITNESS_VALUE_TYPE.ERROR,
      value: FITNESS_VALUE_ERRORS.WRONG_VALUE_FORMAT,
    };
  }
  const trimmedValue = value.trim();
  if (!trimmedValue) {
    return {
      type: FITNESS_VALUE_TYPE.ERROR,
      value: FITNESS_VALUE_ERRORS.VALUE_CANNOT_BE_EMPTY,
    };
  }
  const range = getRangeValue(trimmedValue, precision, min, max);
  if (Array.isArray(range) && allowRange && range.every((num) => isNumber(num))) {
    return {
      type: FITNESS_VALUE_TYPE.RANGE,
      value: range,
    };
  }
  errors.push(range);
  const perSet = getValuePerSet(trimmedValue, precision, numberOfSets, min, max);
  if (allowPerSet && Array.isArray(perSet) && perSet) {
    return {
      type: FITNESS_VALUE_TYPE.PER_SET,
      value: perSet,
    };
  }
  errors.push(perSet);
  const singleValue = getNumber(trimmedValue, precision, min, max);
  return {
    value: singleValue,
    type: typeof singleValue === "number" ? FITNESS_VALUE_TYPE.SINGLE_VALUE : FITNESS_VALUE_TYPE.ERROR,
  };
};
