import { orderBy } from "lodash";
import groupBy from "lodash.groupby";
import toPairs from "lodash.topairs";
import zipObject from "lodash.zipobject";
import { createSelector } from "reselect";

import { type AppStore } from "../../../index";

export const getMergedMeasurements = (store: AppStore) => store.traineeMeasurements.measurements;

export const getMergedMeasurementsByDay = createSelector([getMergedMeasurements], (mergedMeasurements) => {
  if (!mergedMeasurements.length) {
    return [];
  }

  const groupedByDate = toPairs(groupBy(orderBy(mergedMeasurements, "eventDate", "desc"), "eventDate"));

  return groupedByDate
    .map((item) => zipObject(["date", "measurements"], item))
    .map((item) => ({
      date: item.date,
      ...(typeof item.measurements !== "string" && item.measurements
        ? item.measurements
            .map((measurement) => measurement.data)
            .reduce((prev, current) => ({ ...prev, ...current }), {})
        : {}),
    }));
});
