import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form, InputNumber } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

import { traineeMeasurementsActions, traineeMeasurementsSelectors } from "@fitness-app/app-store";
import { MeasurementType } from "@fitness-app/data-models/entities/Measurement";

import { useAppDispatch } from "~/store/initializeStore";
import { type WeightMeasurementFormModel } from "./types";

interface WeightMeasurementFormProps {
  formController?: FormInstance<WeightMeasurementFormModel>;
  onSubmit: (formData: WeightMeasurementFormModel) => void;
  model?: Partial<WeightMeasurementFormModel> | null;
  traineeId: string;
}

const WeightMeasurementForm = ({
  formController,
  onSubmit,
  model,
  traineeId,
}: WeightMeasurementFormProps): React.ReactElement => {
  const { t } = useTranslation(["trainees", "common"]);
  const [selectedDate, setDate] = useState(model?.date || dayjs());
  const [fetchingData, toggleLoader] = useState(false);
  const dispatch = useAppDispatch();
  const lastWeightData = useSelector(traineeMeasurementsSelectors.getLatestWeightMeasurement, shallowEqual);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const fetchMeasurementForSelectedDate = async (d: dayjs.Dayjs) => {
    try {
      toggleLoader(true);
      const result = await dispatch(
        traineeMeasurementsActions.fetchMeasurementForDay({
          eventDate: d.format("YYYY-MM-DD"),
          type: MeasurementType.WeightMeasurement,
          traineeId,
        }),
      ).unwrap();

      if (result && result.type === MeasurementType.WeightMeasurement) {
        formController?.setFieldsValue({
          id: result.eventDate === d.format("YYYY-MM-DD") ? result.id : null,
          weight: result.data.weight,
          previousMeasurement: result,
        });
      } else {
        formController?.setFieldsValue({
          weight: lastWeightData?.weight || 0,
          id: null,
          previousMeasurement: null,
        });
      }
      toggleLoader(false);
    } catch {
      toggleLoader(false);
    }
  };

  useEffect(() => {
    void fetchMeasurementForSelectedDate(selectedDate);
  }, [selectedDate]);

  const getDisabledDate = useCallback((current: dayjs.Dayjs) => current && current > dayjs().endOf("day"), []);

  return (
    <Form<WeightMeasurementFormModel>
      name="add-weight-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        weight: 0,
        date: selectedDate,
        id: null,
        previousMeasurement: null,
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="date"
        label={t<string>("addWeightForm.date")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <DatePicker
          disabledDate={getDisabledDate}
          allowClear={false}
          format="DD.MM.YYYY"
          onChange={(date) => (date ? setDate(date) : undefined)}
        />
      </Form.Item>

      <Form.Item
        name="weight"
        wrapperCol={{ span: 10 }}
        label={t<string>("addWeightForm.weight")}
        rules={[
          { required: true, message: t<string>("common:validationErrors.fieldIsRequired") },
          {
            required: true,
            type: "number",
            min: 1,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
        status={fetchingData ? "validating" : undefined}
      >
        <InputNumber addonAfter="kg" />
      </Form.Item>

      <Form.Item name="id" hidden preserve />

      <Form.Item hidden preserve name="previousMeasurement" />
    </Form>
  );
};

export default WeightMeasurementForm;
