import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_PROGRAM_REDUCER_NAME } from "../types";
import { fetchProgramWithDetails } from "./fetchProgramWithDetails";

type Payload = {
  endDate: string;
  programId: string;
  traineeId: string;
};

export const updateProgramEndDate = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_PROGRAM_REDUCER_NAME}/updateProgramEndDate`,
  async (payload, { rejectWithValue, dispatch, extra: { trainersApi, analytics } }) => {
    try {
      await trainersApi.post("/trainee/update-end-date", payload);
      analytics.track("update_program_end_date");
      void dispatch(fetchProgramWithDetails({ id: payload.programId }));
    } catch (e) {
      rejectWithValue(getErrorMessage(e));
    }
  },
);
