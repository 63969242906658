import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TeamMemberWithUser } from "@fitness-app/data-models/entities/TeamMember";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchTeamMembers = createAsyncThunk<TeamMemberWithUser[], Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/fetchTeamMembers`,
  async (member, { rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("team_member")
      .select("*, user:userId(firstName, lastName, avatarUrl), assignedTrainees:trainee(id)")
      .order("createdAt", { ascending: false })
      .eq("trainer", currentUser.id)
      .returns<TeamMemberWithUser[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
