import dayjs from "dayjs";
import forEach from "lodash.foreach";
import forEachRight from "lodash.foreachright";
import isEmpty from "lodash.isempty";
import orderBy from "lodash.orderby";
import round from "lodash.round";

import {
  type BodyMeasurement,
  type BodyMeasurementData,
  type BodyMeasurements,
} from "@fitness-app/data-models/entities/Measurement";

import { hasKey } from "../types/utils";

function isMeasurementValid(measurement: BodyMeasurementData, key: string) {
  if (hasKey(measurement, key)) {
    const measurementValue = measurement[key];
    if (measurementValue && measurementValue > 0) {
      return measurementValue;
    }
  }

  return false;
}

export const bodyMeasurementSorter = [
  "bf",
  "waist",
  "middle",
  "chest",
  "hips",
  "neck",
  "thighLeft",
  "thighRight",
  "bicepsLeft",
  "bicepsRight",
  "calfLeft",
  "calfRight",
];
export const getBodyMeasurementsWithVariation = (
  bodyMeasurement: BodyMeasurements[],
  filteredOut?: BodyMeasurement[],
) => {
  if (isEmpty(bodyMeasurement)) {
    return {
      isEmpty: true,
    };
  }
  const ordered = orderBy(bodyMeasurement, "eventDate", "desc");
  const newestMeasurement = ordered[0];

  if (!newestMeasurement) {
    return {
      date: null,
      withVariation: null,
      id: null,
      isEmpty: true,
    };
  }

  const withVariation = Object.keys(newestMeasurement.data)
    .map((key) => {
      let oldestValidMeasurementValue = 0;
      let newestValidMeasurementValue = 0;
      forEachRight(ordered, ({ data }) => {
        const validValue = isMeasurementValid(data, key);
        if (validValue) {
          oldestValidMeasurementValue = validValue;

          return false;
        }

        return true;
      });
      forEach(ordered, ({ data }) => {
        const validValue = isMeasurementValid(data, key);
        if (validValue) {
          newestValidMeasurementValue = validValue;

          return false;
        }

        return true;
      });

      return {
        name: key as keyof BodyMeasurementData,
        value: newestValidMeasurementValue,
        variation: round(newestValidMeasurementValue - oldestValidMeasurementValue, 1),
      };
    })
    .filter((item) => !filteredOut?.includes(item.name));

  return {
    date: dayjs(newestMeasurement.eventDate).format("DD.MM"),
    withVariation: orderBy(withVariation, (item) => bodyMeasurementSorter.indexOf(item.name)),
    id: newestMeasurement.id || null,
    isEmpty: false,
  };
};
