import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ChatBot } from "@fitness-app/data-models/entities/ChatBot";

import { type AsyncThunkCreator } from "../../../index";
import { BOT_CONFIG_REDUCER_NAME } from "../types";

type Payload = ChatBot;

export const addBotConfig = createAsyncThunk<ChatBot, Payload, AsyncThunkCreator<string>>(
  `${BOT_CONFIG_REDUCER_NAME}/addBotConfig`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db.from("chat_bot").insert(payload).select("*").single<ChatBot>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_bot_config");

    return data;
  },
);
