import React from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import AudioPlayer from "react-h5-audio-player";

import "react-h5-audio-player/lib/styles.css";

import { type FileMessageContent } from "@fitness-app/data-models/entities/Chat";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { getRecordTime } from "~/components/AudioRecorder/AudioRecorder";
import FileItem from "./FileItem";
import { useHorizontalSlider } from "./hooks/useHorizontalSlider";
import { useUploadFilesContext } from "./UploadProvider";

const ITEM_OFFSET = 15;

interface FileListProps {
  files?: FileMessageContent[];
  readonly?: boolean;
  position?: "left" | "right";
  showOnlyFirstAudio?: boolean;
}

const FilesList = ({
  files: uploadedFiles,
  readonly,
  position,
  showOnlyFirstAudio,
}: FileListProps): React.ReactElement | null => {
  const { files: uploadingFiles, removeFile } = useUploadFilesContext();

  const files = uploadedFiles || uploadingFiles;

  const { contentRef, containerRef, handleScroll, isArrowRightVisible, isArrowLeftVisible, moveToNextItem } =
    useHorizontalSlider(files, {
      additionalLeftOffset: 35,
      additionalRightOffset: 55,
      itemOffset: ITEM_OFFSET,
      scrollToRightOnItemsChange: true,
    });

  if (!files.length) {
    return null;
  }

  const firstFile = files[0];

  if (files.length === 1 && showOnlyFirstAudio && firstFile?.contentType.startsWith("audio")) {
    return (
      <div className="mx-1">
        <AudioPlayer
          autoPlay={false}
          layout="stacked"
          src={firstFile.url}
          showJumpControls={false}
          customAdditionalControls={[]}
          defaultDuration={firstFile.duration ? getRecordTime(firstFile.duration) : undefined}
          timeFormat="mm:ss"
        />
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={cn(
        "relative z-10 -mt-2.5 max-w-full overflow-x-hidden pb-2 pt-4",
        position === "right" && "flex justify-end",
      )}
    >
      {isArrowLeftVisible && (
        <div
          role="button"
          onClick={moveToNextItem("left")}
          className="absolute left-1 top-[42%] z-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-gray-400 bg-white shadow-md outline-none transition-all duration-200 ease-in-out hover:scale-110"
        >
          <ArrowLeftOutlined style={{ fontSize: 14 }} />
        </div>
      )}
      <ul
        ref={contentRef}
        onScroll={handleScroll}
        className="scrollbar flex snap-x list-none overflow-x-scroll scroll-smooth px-3 pt-2.5"
      >
        {files.map((file) => (
          <FileItem
            file={file}
            key={file.uid}
            onRemove={readonly ? undefined : removeFile}
            className="mr-[15px] last:mr-0"
          />
        ))}
      </ul>

      {isArrowRightVisible && (
        <div
          role="button"
          onClick={moveToNextItem("right")}
          className="absolute right-1 top-[42%] z-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-gray-400 bg-white shadow-md outline-none transition-all duration-200 ease-in-out hover:scale-110"
        >
          <ArrowRightOutlined style={{ fontSize: 14 }} />
        </div>
      )}
    </div>
  );
};

export default FilesList;
