import React from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import Dropzone from "react-dropzone";

import { useUploadFilesContext } from "~/components/Upload/UploadProvider";

interface UploadDndDropzoneProps {
  multiple?: boolean;
  children?: React.ReactNode;
}

const DROPZONE_OPTIONS = {
  role: "generic",
};

const UploadDndDropzone = ({ multiple = true, children }: UploadDndDropzoneProps) => {
  const { onUploadFiles, labels } = useUploadFilesContext();

  return (
    <Dropzone onDrop={(files): void => onUploadFiles(files, multiple)} noClick noKeyboard multiple={multiple}>
      {({ getRootProps, isDragActive }) => (
        <div {...getRootProps(DROPZONE_OPTIONS)}>
          {children}
          {isDragActive && (
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center gap-y-4 rounded-md bg-gray-900/80">
              <div>
                <CloudUploadOutlined style={{ fontSize: 56 }} className="text-white" />
              </div>
              <div className="ui-flex ui-flex-col ui-items-center ui-justify-center ui-gap-sm">
                <Typography.Text className="text-lg text-white" strong>
                  {labels.placeholder}
                </Typography.Text>
              </div>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export { UploadDndDropzone };
