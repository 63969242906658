import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { ClientNutritionStatus } from "@fitness-app/data-models/entities/ClientNutrition";

import { type AsyncThunkCreator } from "../../../index";
import { updateTrainee } from "../../trainee/actions";
import { clearMealsPlan } from "../reducer";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  mealsPlanId: string;
  traineeId: string;
};

export const archiveCurrentNutrition = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/archiveCurrentNutrition`,
  async (payload, { dispatch, rejectWithValue, extra: { db, analytics } }) => {
    const { error } = await db
      .from("client_meals_plan")
      .update({
        status: ClientNutritionStatus.Archived,
        updatedAt: new Date().toISOString(),
        endAt: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      })
      .eq("id", payload.mealsPlanId);

    if (error) {
      return rejectWithValue(error.message);
    }

    await dispatch(
      updateTrainee({
        id: payload.traineeId,
        profile: {
          activeMealsPlanId: null,
          hasActiveNutrition: false,
          updatedAt: new Date().toISOString(),
        },
        additionalData: {
          nutrition: null,
        },
      }),
    );
    dispatch(clearMealsPlan());
    analytics.track("archive_current_nutrition");
  },
);
