import React, { useEffect, useMemo } from "react";
import { Alert, Form, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { surveysTemplatesActions } from "@fitness-app/app-store";
import { RequestStatus } from "@fitness-app/data-models";
import { SurveyTemplateType } from "@fitness-app/data-models/entities/SurveyTemplate";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { type AssignInitialSurveyFormModel } from "./types";

interface AssignInitialSurveyFormProps {
  formController?: FormInstance<AssignInitialSurveyFormModel>;
  onSubmit: (formData: AssignInitialSurveyFormModel) => void;
  model?: Partial<AssignInitialSurveyFormModel> | null;
}

const AssignInitialSurveyForm = ({ model, onSubmit, formController }: AssignInitialSurveyFormProps) => {
  const { t } = useTranslation(["automation", "common"]);
  const dispatch = useAppDispatch();
  const { list, listStatus } = useAppSelector((store) => store.surveysTemplates);

  useEffect(() => {
    void dispatch(surveysTemplatesActions.fetchSurveysTemplates());
  }, []);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const options = useMemo(
    () =>
      list
        .filter((item) => item.type === SurveyTemplateType.INITIAL)
        .map((item) => ({ title: item.title, label: item.title, value: item.id })),
    [list],
  );

  const optionsAdditional = useMemo(
    () =>
      list
        .filter((item) => item.type !== SurveyTemplateType.INITIAL)
        .map((item) => ({ title: item.title, label: item.title, value: item.id })),
    [list],
  );

  return (
    <>
      <Form<AssignInitialSurveyFormModel>
        name="form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        labelWrap
        form={formController}
        initialValues={{
          id: null,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="id"
          label={t<string>("survey.initialSurveyTitle")}
          validateStatus={listStatus === RequestStatus.FETCHING ? "validating" : undefined}
        >
          <Select options={options} allowClear showSearch disabled={listStatus === RequestStatus.FETCHING} />
        </Form.Item>

        <Form.Item
          name="additionalSurveyId"
          label={t<string>("survey.additionalSurveyTitle")}
          validateStatus={listStatus === RequestStatus.FETCHING ? "validating" : undefined}
        >
          <Select options={optionsAdditional} allowClear showSearch disabled={listStatus === RequestStatus.FETCHING} />
        </Form.Item>
      </Form>
      <div className="mt-8">
        <Alert message={t("survey.additionalSurveyInfo")} showIcon />
      </div>
    </>
  );
};

export default AssignInitialSurveyForm;
