import { type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";

import { type RequestStatus } from "../../../enums/requestStatus";

export const CLIENT_NOTES_REDUCER_NAME = "traineeNotes";

export enum NoteAction {
  Adding = "adding",
  Updating = "updating",
}
export interface TraineeNotesReducer {
  dataStatus: null | RequestStatus;
  noteAction: null | NoteAction;
  data: ClientNoteWithAuthorData[];
  selectedNoteId: null | string;
  traineeId: string | null;
}
