import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { RequestStatus } from "../../../enums/requestStatus";
import {
  addDish,
  deleteDish,
  editIngredient,
  fetchDishes,
  fetchIngredientCategories,
  fetchIngredientGroups,
  fetchIngredientMeasures,
  fetchIngredients,
  searchDish,
  searchDishOrIngredient,
  updateDish,
} from "./actions";
import { deleteIngredient } from "./actions/deleteIngredient";
import { searchIngredient } from "./actions/searchIngredient";
import { NUTRITION_REDUCER_NAME, type NutritionReducer } from "./types";

const initialState: NutritionReducer = {
  ingredients: [],
  ingredientsStatus: null,
  ingredientsPage: 1,
  ingredientsPerPage: 100,
  ingredientsTotalPages: 0,
  ingredientGroups: [],
  ingredientCategories: [],
  ingredientMeasures: [],
  ingredientSearchTerm: null,
  filteredCategories: [],
  dishes: [],
  dishesPage: 1,
  dishesPerPage: 100,
  dishesTotalPages: 1,
  dishesStatus: null,
  dishSearchTerm: null,
  dishFilters: {
    mealTypes: [],
    dishTypes: [],
    dishTags: [],
  },
};

const nutritionSlice = createSlice({
  initialState,
  name: NUTRITION_REDUCER_NAME,
  reducers: {
    clearDishSearchTerm(state) {
      state.dishSearchTerm = null;
    },
    clearSearchTerm(state) {
      state.ingredientSearchTerm = null;
    },
    setFilteredCategories(state, { payload }: PayloadAction<number[]>) {
      state.filteredCategories = payload;
    },
    clearNutritionSearch() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIngredients.pending, (state) => {
      state.ingredientsStatus = RequestStatus.FETCHING;
      state.ingredients = [];
    });
    builder.addCase(fetchIngredients.fulfilled, (state, { payload }) => {
      state.ingredientsStatus = RequestStatus.SUCCESS;
      state.ingredientsPage = payload.page;
      state.ingredients = payload.data;
      state.ingredientsTotalPages = payload.totalPages;
    });
    builder.addCase(fetchIngredients.rejected, (state) => {
      state.ingredientsStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchDishes.pending, (state) => {
      state.dishesStatus = RequestStatus.FETCHING;
      state.dishes = [];
    });
    builder.addCase(fetchDishes.fulfilled, (state, { payload }) => {
      state.dishesStatus = RequestStatus.SUCCESS;
      state.dishesPage = payload.page;
      state.dishes = payload.data;
      state.dishesTotalPages = payload.totalPages;
    });
    builder.addCase(fetchDishes.rejected, (state) => {
      state.dishesStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchIngredientGroups.fulfilled, (state, { payload }) => {
      state.ingredientGroups = payload;
    });
    builder.addCase(fetchIngredientMeasures.fulfilled, (state, { payload }) => {
      state.ingredientMeasures = payload;
    });
    builder.addCase(fetchIngredientCategories.fulfilled, (state, { payload }) => {
      state.ingredientCategories = payload;
    });
    builder.addCase(searchIngredient.pending, (state, action) => {
      state.ingredientSearchTerm = action.meta.arg.searchTerm;
      state.ingredientsStatus = RequestStatus.SEARCHING;
    });
    builder.addCase(searchIngredient.fulfilled, (state, { payload }) => {
      state.ingredientsStatus = RequestStatus.SUCCESS;
      state.ingredientsPage = 1;
      state.ingredients = payload.data;
      state.ingredientsTotalPages = 1;
    });
    builder.addCase(searchIngredient.rejected, (state) => {
      state.ingredientsStatus = RequestStatus.FAILED;
    });
    builder.addCase(searchDish.pending, (state, action) => {
      state.dishSearchTerm = action.meta.arg.searchTerm;
      state.dishesStatus = RequestStatus.SEARCHING;
    });
    builder.addCase(searchDish.fulfilled, (state, { payload }) => {
      state.dishesStatus = RequestStatus.SUCCESS;
      state.dishesPage = 1;
      state.dishes = payload.data;
      state.dishesTotalPages = 1;
    });
    builder.addCase(searchDish.rejected, (state) => {
      state.dishesStatus = RequestStatus.FAILED;
    });
    builder.addCase(searchDishOrIngredient.pending, (state) => {
      state.dishesStatus = RequestStatus.SEARCHING;
      state.ingredientsStatus = RequestStatus.SEARCHING;
    });
    builder.addCase(searchDishOrIngredient.rejected, (state) => {
      state.dishesStatus = RequestStatus.FAILED;
      state.ingredientsStatus = RequestStatus.FAILED;
    });
    builder.addCase(searchDishOrIngredient.fulfilled, (state, { payload }) => {
      state.dishesStatus = RequestStatus.SUCCESS;
      state.ingredientsStatus = RequestStatus.SUCCESS;

      state.ingredientsPage = payload.ingredients.page;
      state.ingredients = payload.ingredients.data;
      state.ingredientsTotalPages = payload.ingredients.totalPages;

      state.dishesPage = payload.dishes.page;
      state.dishes = payload.dishes.data;
      state.dishesTotalPages = payload.dishes.totalPages;
    });
    builder.addCase(addDish.fulfilled, (state, { payload }) => {
      if (payload) {
        state.dishes.push(payload);
      }
    });
    builder.addCase(updateDish.fulfilled, (state, { payload }) => {
      if (payload) {
        state.dishes = state.dishes.map((dish) => (dish.id === payload.id ? { ...dish, ...payload } : dish));
      }
    });
    builder.addCase(deleteDish.fulfilled, (state, { payload }) => {
      state.dishes = state.dishes.filter((dish) => dish.id !== payload);
    });
    builder.addCase(deleteIngredient.fulfilled, (state, { payload }) => {
      state.ingredients = state.ingredients.filter((ingredient) => ingredient.id !== payload);
    });
    builder.addCase(editIngredient.fulfilled, (state, { payload }) => {
      if (payload) {
        state.ingredients = state.ingredients.map((ingredient) =>
          ingredient.id === payload.id ? { ...ingredient, ...payload } : ingredient,
        );
      }
    });
  },
});

export const { clearSearchTerm, setFilteredCategories, clearDishSearchTerm, clearNutritionSearch } =
  nutritionSlice.actions;
export default nutritionSlice.reducer;
