import { InvoicesClient } from "@fitness-app/data-models/entities/Product";

import { IS_DEV } from "~/constants/config";

export const invoicesOptions = [
  {
    value: InvoicesClient.Fakturownia,
  },
  {
    value: InvoicesClient.iFirma,
  },
  {
    value: InvoicesClient.inFakt,
  },
  {
    value: InvoicesClient.wFirma,
    disabled: IS_DEV,
  },
];
