import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import cloneDeep from "lodash.clonedeep";

import { ProgramService } from "@fitness-app/data-models/domain/services/ProgramService";
import { weekdays } from "@fitness-app/data-models/domain/services/WorkoutService";
import { WorkoutSource } from "@fitness-app/data-models/entities/ClientWorkout";

import { type AsyncThunkCreator } from "../../../index";
import { setProgramSchedule } from "../../traineeProgram/actions";
import { deleteActivity, setWorkoutEditModalHidden } from "../reducer";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";

type Payload = {
  workoutId: string;
};

export const deleteWorkoutFromDay = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/deleteWorkoutFromDay`,
  async (payload, { dispatch, getState, extra: { db } }) => {
    const { workoutSource, editedDocId, currentDay, currentWorkout } = getState().traineeActivities.data;

    const { profile } = getState().trainee;
    const { selectedProgram } = getState().traineeProgram;

    if (!profile || !currentWorkout || !selectedProgram) {
      throw new Error("No workout to update");
    }

    if (payload.workoutId !== currentWorkout.id) {
      throw new Error("Workout id mismatch");
    }

    const selectedDate = dayjs(currentDay, "YYYY-MM-DD");

    const { week } = ProgramService.getScheduleForWeek(selectedProgram, selectedDate);
    const day = weekdays[selectedDate.isoWeekday()];

    const isScheduledWorkout = day && week && week[day]?.workoutDay === currentWorkout.id;

    if (isScheduledWorkout) {
      const currentWeek = `${selectedDate.get("year")}-${selectedDate.isoWeek()}`;
      const updatedWeek = cloneDeep(week);
      delete updatedWeek[day];
      const exceptionWeeks = cloneDeep({
        [`${currentWeek}`]: updatedWeek,
      });
      await dispatch(
        setProgramSchedule({
          exceptionWeeks,
          programSchedule: selectedProgram.workoutsSchedule?.schedule,
        }),
      );
    }

    if (workoutSource === WorkoutSource.FromActivity && editedDocId) {
      await db.from("client_workout_activity").delete().eq("id", editedDocId);

      dispatch(deleteActivity(editedDocId));
    }

    dispatch(setWorkoutEditModalHidden());
  },
);
