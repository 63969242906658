import { useMemo } from "react";
import dayjs from "dayjs";
import isInteger from "lodash.isinteger";

import { type WeekdayLabels } from "~/modules/Automation/AutomatedMessagesForm/types";

export const generateDaysOfSent = ({
  sentDate,
  numberOfRepeats,
  daysOfDelivery,
}: {
  sentDate?: undefined | dayjs.Dayjs;
  numberOfRepeats?: number;
  daysOfDelivery?: WeekdayLabels[];
}) => {
  if (!sentDate || !numberOfRepeats || !daysOfDelivery) {
    return [];
  }
  const currentDate = sentDate.toDate();
  const maxDays = isInteger(numberOfRepeats) ? numberOfRepeats * 7 : 1;
  const newGeneratedDates = [sentDate.toDate()];

  const selectedDays = Object.fromEntries(daysOfDelivery.map((dayKey) => [dayKey, true]));

  let availableDays: Date[] = [];

  [...Array.from({ length: maxDays })].forEach(() => {
    availableDays = [...availableDays, new Date(currentDate.setDate(currentDate.getDate() + 1))];
  });
  availableDays = availableDays.filter((day) =>
    selectedDays ? selectedDays[dayjs(day).locale("en").format("dddd").toLowerCase()] : false,
  );

  return [...newGeneratedDates, ...availableDays.slice(0, numberOfRepeats - 1)];
};

export const useGeneratedDaysForMessages = ({
  sentDate,
  numberOfRepeats,
  daysOfDelivery,
}: {
  sentDate?: undefined | dayjs.Dayjs;
  numberOfRepeats?: number;
  daysOfDelivery?: WeekdayLabels[];
}) => {
  const generatedDaysOfDelivery = useMemo(
    () => generateDaysOfSent({ sentDate, numberOfRepeats, daysOfDelivery }),
    [sentDate, numberOfRepeats, daysOfDelivery],
  );

  return generatedDaysOfDelivery;
};
