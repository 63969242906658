import dayjs from "dayjs";
import { isEmpty, orderBy, round } from "lodash";
import { createSelector } from "reselect";

import { MeasurementType, type WeightMeasurement } from "@fitness-app/data-models/entities/Measurement";

import { type AppStore } from "../../../index";

const getMergedWeightMeasurement = (state: AppStore) =>
  state.traineeMeasurements.measurements.filter(
    (item): item is WeightMeasurement => item.type === MeasurementType.WeightMeasurement,
  );

const getLatestWeight = (state: AppStore) => state.traineeMeasurements.latestFetchedWeight;
const getProfile = (state: AppStore) => state.trainee.profile;

const calculateChange = (latest: WeightMeasurement | undefined, previous: WeightMeasurement | undefined) => {
  if (latest && previous) {
    return round(latest.data.weight - previous.data.weight, 1);
  }

  return null;
};

export const getLatestWeightMeasurement = createSelector(
  [getMergedWeightMeasurement, getLatestWeight, getProfile],
  (weightMeasurement, latestWeight, profile) => {
    const lastWeight = latestWeight?.[0];

    if (lastWeight) {
      return {
        date: dayjs(lastWeight.eventTimestamp),
        weight: lastWeight.data.weight,
        change: calculateChange(lastWeight, latestWeight[1]),
      };
    }

    if (isEmpty(weightMeasurement)) {
      return {
        date: profile ? dayjs(profile.createdAt) : null,
        weight: profile ? profile.weight : null,
        change: null,
      };
    }

    const ordered = orderBy([...weightMeasurement], "eventDate", "desc");

    const lastMeasurement = ordered[0];

    return {
      date: lastMeasurement ? dayjs(lastMeasurement.eventTimestamp) : null,
      weight: lastMeasurement ? lastMeasurement.data.weight : null,
      change: calculateChange(lastMeasurement, ordered[1]),
    };
  },
);
