import React, { useEffect, useMemo, useState } from "react";
import { BarChart } from "@tremor/react";
import { Card } from "antd";
import dayjs from "dayjs";

import { RequestStatus, traineeMeasurementsActions } from "@fitness-app/app-store";
import { getTraineeId } from "@fitness-app/app-store/src/store/reducers/trainee/selectors/getTraineeId";
import { type ClientNutritionWeek } from "@fitness-app/data-models/entities/ClientNutritionWeek";
import { getErrorMessage } from "@fitness-app/utils";

import { type CommonChartProps } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const valueFormatterAbsolute = (number: number) => `${Intl.NumberFormat("pl").format(number).toString()} kcal`;

export const NutritionCaloriesChart = ({ range }: CommonChartProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [measurements, setMeasurements] = useState<ClientNutritionWeek[]>([]);
  const [requestState, setRequestState] = useState<{ status: RequestStatus; error: null | string }>({
    error: null,
    status: RequestStatus.IDLE,
  });
  const traineeId = useAppSelector(getTraineeId);

  const chartData = useMemo(() => {
    const items = measurements.map((item) => item.data).flat();
    return items.map((item) => ({
      date: dayjs(item.eventDate).format("DD.MM.YYYY"),
      Kalorie: item.calories,
    }));
  }, [measurements]);

  const fetchMeasurements = async (dateStart: Date, dateEnd: Date, traineeId: string) => {
    try {
      setRequestState({ error: null, status: RequestStatus.FETCHING });
      const response = await dispatch(
        traineeMeasurementsActions.fetchNutritionSummaryForRange({
          dateStart,
          dateEnd,
          traineeId,
        }),
      ).unwrap();
      setMeasurements(response);
      setRequestState({ error: null, status: RequestStatus.SUCCESS });
    } catch (e) {
      setRequestState({ error: getErrorMessage(e), status: RequestStatus.FAILED });
    }
  };

  useEffect(() => {
    if (range?.[0] && range?.[1] && traineeId) {
      void fetchMeasurements(range[0].startOf("day").toDate(), range[1].endOf("day").toDate(), traineeId);
    }
  }, [range, traineeId]);

  return (
    <Card
      title="Spożyte kalorie"
      type="inner"
      className="relative"
      loading={requestState.status === RequestStatus.FETCHING}
    >
      <BarChart
        className="mt-8 h-80"
        data={chartData}
        index="date"
        colors={["rose"]}
        categories={["Kalorie"]}
        showLegend={false}
        valueFormatter={valueFormatterAbsolute}
        yAxisWidth={90}
        allowDecimals={false}
        intervalType={"preserveStartEnd"}
        noDataText="Brak danych do wyświetlenia"
      />
    </Card>
  );
};
