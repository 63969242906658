import { type Database } from "@fitness-app/supabase";

import { type CURRENT_ASSIGNED_TRAINER } from "../contants/currentAssignedTrainer";
import { type WithCreator } from "../utils/withCreator";
import { type EmailClientAutomationTask } from "./Automation";
import { type TemplateType } from "./AutomationTemplate";
import { type SurveyItemType } from "./ClientSurvey";
import { type ActivityLevel, type DietaryPreference } from "./MealsPlan";
import { type Gender } from "./shared";

export type SurveyTemplateEntity = Database["public"]["Tables"]["survey_template"]["Row"];

export type SurveyTemplateTypeEnum = Database["public"]["Enums"]["SurveyTemplateType"];

export const SurveyTemplateType: Record<SurveyTemplateTypeEnum, SurveyTemplateTypeEnum> = {
  INITIAL: "INITIAL",
  RECURRING: "RECURRING",
  MASTER: "MASTER",
};

export enum SurveyQuestionType {
  AB = "a/b",
  Select = "select",
  Text = "text",
}

export interface SurveyQuestionBasic {
  orderKey: number;
  title: string;
  id: string;
  desc: string;
  type: SurveyQuestionType;
  isRequired: boolean;
  key?: string;
  protected?: boolean;
  itemType?: SurveyItemType;
}

export interface ExtendedAnswerOption {
  title?: string;
  desc?: string;
  value: string | number;
  label?: string;
}

export interface SurveyQuestionText extends SurveyQuestionBasic {
  type: SurveyQuestionType.Text;
  multiline?: boolean;
}

export interface SurveyQuestionSelect extends SurveyQuestionBasic {
  type: SurveyQuestionType.Select;
  answers: Array<string | ExtendedAnswerOption>;
  multiSelect: boolean;
}

export interface SurveyQuestionAB extends SurveyQuestionBasic {
  type: SurveyQuestionType.AB;
  extendedAnswerA: boolean;
  extendedAnswerB: boolean;
}

export type SurveyQuestion = SurveyQuestionText | SurveyQuestionSelect | SurveyQuestionAB;
export type SurveyQuestionId = string;

export type SurveyQuestionWithAnswer = SurveyQuestion & {
  clientAnswers?:
    | {
        answer: string;
      }
    | { answer: boolean; extendedAnswer: string }
    | { answer: string[] | null };
};

export enum SurveyAutomationConditionType {
  AnyAnswer = "anyAnswer",
  SpecificAnswer = "specificAnswer",
}

export type SpecificAnswerCondition = {
  type: SurveyAutomationConditionType.SpecificAnswer;
  answers: string[] | boolean[];
};

export type AnyAnswerCondition = {
  type: SurveyAutomationConditionType.AnyAnswer;
};

export interface SurveyMessageAutomation {
  id: string;
  name: string;
  messages: string[];
  authorId: string;
  variables?: string[];
}

export interface SurveyMessageAutomation {
  type: TemplateType.ScheduledMessages;
  id: string;
  name: string;
  messages: string[];
  authorId: string | typeof CURRENT_ASSIGNED_TRAINER;
  variables?: string[];
}

export interface SurveyEmailAutomation {
  type: TemplateType.ScheduledEmails;
  id: string;
  subject: string;
  content: string;
  variables?: string[];
}

export interface SurveyEmailIntegrationAutomation extends Omit<EmailClientAutomationTask, "triggers"> {
  id: string;
  variables?: string[];
}

export type SurveyAutomationCondition = SpecificAnswerCondition | AnyAnswerCondition;

export type SurveyQuestionAutomation = {
  condition: SurveyAutomationCondition;
  tasks: (SurveyMessageAutomation | SurveyEmailIntegrationAutomation | SurveyEmailAutomation)[];
};

export interface SurveyTemplate extends Omit<SurveyTemplateEntity, "questions" | "automation" | "globalVariables"> {
  questions: Record<string, SurveyQuestion>;
  automation?: Record<SurveyQuestionId, SurveyQuestionAutomation[]>;
  globalVariables?: string[] | null;
}

export type SurveyTemplateWithCreator = WithCreator<SurveyTemplate>;

export enum WorkoutTarget {
  WEIGHT_LOSS = "WEIGHT_LOSS",
  BETTER_FIGURE = "BETTER_FIGURE",
  BETTER_SHAPE = "BETTER_SHAPE",
}

export enum Weekday {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export enum ClientExperience {
  NEWBIES = "newbies",
  MEDIUM = "medium",
  EXPERT = "expert",
}

export enum PlaceToWorkout {
  GYM = "GYM",
  HOME_WITH_EQUIPMENT = "HOME_WITH_EQUIPMENT",
  HOME = "HOME",
}

export interface InitialSurveyQuestions {
  height: number;
  weight: number;
  bodyFatPercentage?: string;
  gender: Gender.MALE | Gender.FEMALE;
  birthYear: number;
  experience: ClientExperience;
  startDate?: string | null;
  activityLevel: ActivityLevel;
  targets: [WorkoutTarget];
  schedule: Weekday | Weekday[];
  placeToWorkout: PlaceToWorkout;
  programToSelect?: string;
  dietaryPreference?: DietaryPreference;
}
