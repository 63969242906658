import React, { useMemo, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Space, Table, Typography } from "antd";
import isEmpty from "lodash.isempty";
import orderBy from "lodash.orderby";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import { traineeSurveysActions } from "@fitness-app/app-store";
import {
  SurveyIntervalUnit,
  SurveyStatus,
  SurveyType,
  type ClientSurvey,
  type SurveyABAnswer,
  type SurveyQuestionAB,
  type SurveySelectAnswer,
  type SurveyTextAnswer,
} from "@fitness-app/data-models/entities/ClientSurvey";
import {
  SurveyQuestionType,
  type SurveyQuestionSelect,
  type SurveyQuestionText,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import ABAnswer from "~/components/SurveyDetails/SurveyAnswers/ABAnswer";
import SelectAnswer from "~/components/SurveyDetails/SurveyAnswers/SelectAnswer";
import TextAnswer from "~/components/SurveyDetails/SurveyAnswers/TextAnswer";
import {
  useVariablesFromModel,
  type SurveyVariablesModal,
} from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/useDefaultVariables";
import { useAppDispatch } from "~/store/initializeStore";

interface SurveyDetailsProps {
  survey: ClientSurvey | null;
  onCancel: () => void;
}

const { Column } = Table;

export const questionTypeLabels = {
  [SurveyQuestionType.Text]: "Własna odpowiedź",
  [SurveyQuestionType.Select]: "Wybór opcji",
  [SurveyQuestionType.AB]: "Tak/nie",
};

const getSurveyDetailsTableData = (surveyDetails: ClientSurvey) => {
  if (!surveyDetails?.questions) {
    return [];
  }
  const questions = orderBy(
    Object.entries(surveyDetails.questions).map(([id, question]) => ({ ...question, id })),
    "orderKey",
  );
  if (!surveyDetails.answers || isEmpty(surveyDetails.answers)) {
    return questions;
  }
  return questions.map((question) => ({
    ...question,
    clientAnswers: surveyDetails.answers?.[question.id] || null,
  }));
};

const SurveyDetails = ({ survey, onCancel }: SurveyDetailsProps) => {
  const { t } = useTranslation("surveys");
  const [saving, toggleSaving] = useState(false);
  const [formController] = Form.useForm<SurveyVariablesModal>();
  const dispatch = useAppDispatch();
  const expandedTable = useMemo(() => Boolean(survey?.answers && !isEmpty(survey.answers)), [survey]);
  const renderAnswer = (
    question:
      | (SurveyQuestionAB & { clientAnswers: SurveyABAnswer })
      | (SurveyQuestionText & { clientAnswers: SurveyTextAnswer })
      | (SurveyQuestionSelect & { clientAnswers: SurveySelectAnswer }),
  ) => {
    if (!question.clientAnswers) {
      return <p>{t("noAnswerForQuestion")}</p>;
    }
    if (question.type === SurveyQuestionType.Text) {
      return <TextAnswer disabled value={String(question.clientAnswers.answer)} />;
    }
    if (question.type === SurveyQuestionType.AB) {
      return (
        <ABAnswer
          disabled
          value={question.clientAnswers.answer}
          extendedAnswer={question.clientAnswers.extendedAnswer}
        />
      );
    }
    if (question.type === SurveyQuestionType.Select) {
      return (
        <SelectAnswer
          options={question.answers}
          multiSelect={question.multiSelect}
          value={question.clientAnswers.answer}
          isInitial={survey?.type === SurveyType.Initial || survey?.type === SurveyType.OneTime}
        />
      );
    }
  };

  const { variables } = useVariablesFromModel(survey, formController);
  const showVariablesForm = survey && [SurveyStatus.Open, SurveyStatus.Scheduled].includes(survey.status) && variables;

  const handleSubmit = async (formData: SurveyVariablesModal) => {
    if (!survey) {
      return;
    }
    try {
      toggleSaving(true);
      await dispatch(
        traineeSurveysActions.updateSurvey({
          surveyId: survey.id,
          survey: {
            variables: formData.variables,
          },
        }),
      ).unwrap();
      void message.success("Zmienne zostały zaktualizowane");
      onCancel();
    } catch {
      void message.error("Wystąpił błąd podczas zapisywania zmiennych");
    } finally {
      toggleSaving(false);
    }
  };

  return (
    <Modal
      open={!!survey}
      title={t("surveyDetails")}
      width={750}
      onCancel={onCancel}
      footer={
        <Space>
          {showVariablesForm && (
            <Button loading={saving} key="submit" onClick={() => formController.submit()} data-ctx="close-modal">
              {t("common:button.save")}
            </Button>
          )}
          <Button key="back" onClick={onCancel} data-ctx="close-modal" type="primary">
            {t("common:button.close")}
          </Button>
        </Space>
      }
    >
      {survey && (
        <Table
          // @ts-expect-error ignore
          dataSource={getSurveyDetailsTableData(survey)}
          locale={{ emptyText: t("emptyState") }}
          pagination={false}
          size="middle"
          rowKey="id"
          expandedRowRender={renderAnswer}
          defaultExpandAllRows={expandedTable}
        >
          <Column title="Nr" align="center" key="index" width="5%" render={(_, __, index) => index + 1} />
          <Column
            title="Pytanie"
            dataIndex="title"
            key="title"
            width="35%"
            render={(rowText) => truncate(rowText, { length: 80 })}
          />
          <Column
            title="Typ pytania"
            dataIndex="type"
            key="type"
            width="30%"
            render={(type: SurveyQuestionType) => questionTypeLabels[type]}
          />
          <Column
            title="Obowiązkowe"
            dataIndex="isRequired"
            key="isRequired"
            render={(isRequired) =>
              isRequired ? (
                <CheckOutlined style={{ color: "#25b10f" }} />
              ) : (
                <CloseOutlined style={{ color: "#d6d6d6" }} />
              )
            }
          />
          <Column
            title="Odpowiedź"
            dataIndex="hasAnswer"
            key="hasAnswer"
            render={(_, row: any) =>
              row.clientAnswers ? (
                <CheckOutlined style={{ color: "#25b10f" }} />
              ) : (
                <CloseOutlined style={{ color: "#d6d6d6" }} />
              )
            }
          />
        </Table>
      )}

      {showVariablesForm ? (
        <div className="py-4">
          <Typography.Title level={5}>Zmienne dla automatyzacji</Typography.Title>

          <Form<SurveyVariablesModal>
            name="form"
            layout="vertical"
            form={formController}
            initialValues={{
              type: SurveyType.OneTime,
              sendImmediately: true,
              count: 1,
              unit: SurveyIntervalUnit.Month,
              editable: false,
            }}
            onFinish={handleSubmit}
          >
            {variables.map((variableName) => (
              <Form.Item
                key={variableName}
                label={variableName}
                name={["variables", variableName]}
                rules={[
                  {
                    required: true,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ))}
          </Form>
        </div>
      ) : null}
    </Modal>
  );
};

export default SurveyDetails;
