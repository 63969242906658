import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "@fitness-app/data-models";

import { fetchClientProducts } from "./actions/fetchClientProducts";
import { CLIENT_PRODUCTS_SLICE_NAME, type ClientProductsReducer } from "./types";

const initialState: ClientProductsReducer = {
  listStatus: null,
  list: [],
  listError: null,
};

const reducerSlice = createSlice({
  initialState,
  name: CLIENT_PRODUCTS_SLICE_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClientProducts.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchClientProducts.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.list = payload;
    });
    builder.addCase(fetchClientProducts.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
  },
});

export default reducerSlice.reducer;
