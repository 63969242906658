import { createSelector } from "@reduxjs/toolkit";
import keyBy from "lodash.keyby";
import last from "lodash.last";
import times from "lodash.times";

import {
  ExerciseInWorkoutType,
  RestType,
  type ExerciseInLiveWorkout,
  type ExerciseSeries,
  type LiveWorkoutData,
  type RestBetweenExercises,
  type RestBetweenExercisesInSet,
  type RestBetweenSeries,
  type SuperSetInWorkout,
  type SuperSetSeries,
} from "@fitness-app/data-models/entities/LiveWorkout";
import {
  WeightHint,
  WORKOUT_EXERCISES_TYPE,
  type ExerciseInWorkout,
  type SuperSet,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { mergeExerciseWithSuperSeries } from "@fitness-app/utils/src/programs/mergeExerciseWithSuperSeries";
import setHintValuesForSets from "@fitness-app/utils/src/programs/setHintValuesForSets/setHintValuesForSets";

import { type AppStore } from "../../../index";

const getWorkoutData = (store: AppStore) => store.liveWorkout.activity;
const getWeightHint = (store: AppStore) =>
  store.trainee.traineeSettings?.workoutSettings?.weightHint || WeightHint.LATEST;

const getExercisesRecords = (store: AppStore) => keyBy(store.traineeWorkoutsStats.exercisesRecordsList, "exerciseId");

export const prepareRichSuperSetData = (
  superSet: SuperSet,
  workoutPart: WorkoutExercisesType,
  activeWorkoutId: string,
) => {
  const mergedSeries: (SuperSetSeries | RestBetweenExercisesInSet | RestBetweenSeries)[] = [];
  times(superSet.numberOfSeries, (index) => {
    const numberOfSeries = index + 1;
    superSet.mergedExercises.forEach((exerciseInWorkout, exerciseIndex) => {
      mergedSeries.push({
        ...exerciseInWorkout.set[numberOfSeries]!,
        type: ExerciseInWorkoutType.SuperSet,
        name: exerciseInWorkout.exercise.name,
        restTime: exerciseInWorkout.restTime ?? 0,
        exerciseId: exerciseInWorkout.id,
        rowKey: `${index}-${exerciseIndex}-${exerciseInWorkout.id}`,
        series: numberOfSeries,
        exerciseType: exerciseInWorkout.exercise.type,
        workoutId: exerciseInWorkout.exercise.id,
        totalSeries: superSet.numberOfSeries,
        exerciseNumber: exerciseIndex + 1,
        numberOfExercises: superSet.mergedExercises.length,
        workoutPart,
        activeWorkoutId,
        exercise: exerciseInWorkout.exercise,
        exerciseInWorkout,
        exerciseInSuperSetIds: superSet.mergedExercisesIds,
      });
      if (exerciseIndex < superSet.mergedExercises.length - 1) {
        mergedSeries.push({
          type: RestType.AfterSuperSetExercise,
          restTime: exerciseInWorkout.restTime ?? 0,
          rowKey: `${index}-${exerciseIndex}-rest-${exerciseInWorkout.id}`,
          series: numberOfSeries,
          workoutPart,
        });
      }
    });
    if (index < superSet.numberOfSeries - 1) {
      mergedSeries.push({
        type: RestType.AfterSeries,
        rowKey: `${index}-rest-series`,
        restTime: last(mergedSeries)?.restTime ?? 60,
        workoutPart,
      });
    }
  });
  return mergedSeries;
};

export const prepareSetData = (
  item: ExerciseInLiveWorkout | (ExerciseInWorkout & { series?: never }),
  workoutPart: WorkoutExercisesType,
  activeWorkoutId: string,
) => {
  const mergedSeries: (ExerciseSeries | RestBetweenSeries)[] = [];
  Object.entries(item.set).forEach(([setKey, set]) => {
    const { series, ...rest } = item;
    mergedSeries.push({
      ...set,
      type: ExerciseInWorkoutType.Normal,
      name: item.exercise.name,
      restTime: item.restTime ?? 0,
      exerciseId: item.id,
      rowKey: `${setKey}-${item.id}`,
      series: Number(setKey),
      exerciseType: item.exercise.type,
      workoutId: item.exercise.id,
      totalSeries: item.numberOfSeries,
      workoutPart,
      activeWorkoutId,
      exercise: item.exercise,
      exerciseInWorkout: rest,
    });
    if (Number(setKey) < item.numberOfSeries) {
      mergedSeries.push({
        type: RestType.AfterSeries,
        restTime: item.restTime ?? 0,
        rowKey: `${setKey}-rest-${item.id}`,
        workoutPart,
      });
    }
  });
  return mergedSeries;
};

export const getLiveWorkout = createSelector(
  [getWorkoutData, getWeightHint, getExercisesRecords],
  (liveWorkout, weightHint, exercisesRecords) => {
    if (!liveWorkout) {
      return {
        liveWorkout: [],
        selectedWorkout: null,
      };
    }
    const selectedWorkout = { ...liveWorkout?.data, activityId: liveWorkout?.id };
    const mergedExercises = [
      ...(selectedWorkout.preWorkout
        ? mergeExerciseWithSuperSeries(
            selectedWorkout?.preWorkout,
            undefined,
            WORKOUT_EXERCISES_TYPE.PRE_WORKOUT,
            selectedWorkout.id,
          )
        : []),
      ...(selectedWorkout.exercises
        ? mergeExerciseWithSuperSeries(
            selectedWorkout?.exercises,
            undefined,
            WORKOUT_EXERCISES_TYPE.NORMAL,
            selectedWorkout.id,
          )
        : []),
      ...(selectedWorkout.postWorkout
        ? mergeExerciseWithSuperSeries(
            selectedWorkout?.postWorkout,
            undefined,
            WORKOUT_EXERCISES_TYPE.POST_WORKOUT,
            selectedWorkout.id,
          )
        : []),
    ];
    let withRest: LiveWorkoutData = [];
    const rich = mergedExercises.map((item) => {
      if (item.isSuperSet) {
        item.mergedExercises.forEach((exerciseInWorkout, i) => {
          const records = exercisesRecords?.[exerciseInWorkout.exercise.id];
          const workoutSetWithHints = setHintValuesForSets({
            sets: exerciseInWorkout.set,
            hintType: weightHint,
            records,
            exercise: exerciseInWorkout,
          });
          if (item.mergedExercises[i]) {
            item.mergedExercises[i].set = workoutSetWithHints;
          }
        });

        return {
          ...item,
          type: ExerciseInWorkoutType.SuperSet,
          series: prepareRichSuperSetData(item, item.exercisesType, item.workoutId),
          workoutPart: item.exercisesType,
          exerciseInSuperSetIds: item.mergedExercisesIds,
        } as SuperSetInWorkout;
      }
      const records = exercisesRecords?.[`${item.exercise.id}`];
      const workoutSetWithHints = setHintValuesForSets({
        sets: item.set,
        hintType: weightHint,
        records,
        exercise: item,
      });
      item.set = workoutSetWithHints;
      return {
        ...item,
        workoutPart: item.exercisesType,
        type: ExerciseInWorkoutType.Normal,
        series: prepareSetData(item, item.exercisesType, item.workoutId),
      } as ExerciseInLiveWorkout;
    });
    rich.forEach((item, i) => {
      const isLastItem = i === mergedExercises.length - 1;
      withRest = [
        ...withRest,
        ...(isLastItem
          ? [item]
          : [
              item,
              {
                restTime: 120,
                type: RestType.AfterExercise,
                rowKey: `${i}-${RestType.AfterExercise}`,
              } as RestBetweenExercises,
            ]),
      ];
    });
    return {
      liveWorkout: withRest,
      selectedWorkout,
    };
  },
);
