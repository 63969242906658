import { createAsyncThunk } from "@reduxjs/toolkit";

import { type MealTypeEnum } from "@fitness-app/data-models/entities/Dish";
import { type DishInMeal } from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

export const findSimilarDishes = createAsyncThunk<
  DishInMeal[],
  { dishId: string; nutritionId: string; prompt: string; mealType: MealTypeEnum },
  AsyncThunkCreator<string>
>(
  `${MEALS_PLANS_REDUCER_NAME}/findSimilarDishes`,
  async (payload, { rejectWithValue, getState, extra: { analytics, nutritionApi } }) => {
    const { profile: traineeProfile } = getState().trainee;

    const response = await nutritionApi?.post<{
      dishes: DishInMeal[];
    }>("/find-similar-dishes", { ...payload, traineeId: traineeProfile?.id });

    if (!response) {
      return rejectWithValue("Error while generating nutrition");
    }

    analytics.track("dishes_exchanged_with_ai", { options: response.data.dishes.length });

    return response.data.dishes;
  },
);
