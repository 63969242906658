import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Card, Table } from "antd";
import isEmpty from "lodash.isempty";
import orderBy from "lodash.orderby";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import { type InitialSurvey as InitialSurveyInterface } from "@fitness-app/data-models/entities/ClientSurvey";
import { type SurveyQuestion, type SurveyQuestionType } from "@fitness-app/data-models/entities/SurveyTemplate";
import { createInitialSurveyFromAnswers } from "@fitness-app/utils/src/surveys/createInitialSurvey";

import SurveyExampleAnswer from "~/modules/SurveysTemplates/SurveyDetails/SurveyExampleAnswer";
import { defaultAnswers } from "./InitialSurvey.data";

const { Column } = Table;

const getSurveyDetailsTableData = (surveyDetails: InitialSurveyInterface) => {
  if (!surveyDetails) {
    return [];
  }
  const questions: SurveyQuestion[] = orderBy(Object.values(surveyDetails.questions), "orderKey");
  if (isEmpty(surveyDetails.answers)) {
    return questions;
  }
  return questions.map((question) => ({
    ...question,
    clientAnswers: null,
  }));
};

const InitialSurvey = () => {
  const { t } = useTranslation(["surveys", "products"]);
  const surveyDetails = createInitialSurveyFromAnswers(defaultAnswers, new Date(), t);
  const tableData = getSurveyDetailsTableData(surveyDetails[0]!);

  return (
    <Card title={t("basicQuestions")} type="inner">
      <Table
        dataSource={tableData}
        locale={{ emptyText: t("emptyState") }}
        pagination={false}
        size="large"
        scroll={{ x: true }}
        rowKey="orderKey"
        expandable={{
          expandedRowRender: (row) => <SurveyExampleAnswer question={row} surveyId="initial" />,
        }}
      >
        <Column
          title={t("surveyDetailsForm.question")}
          align="center"
          key="index"
          width="5%"
          render={(_, __, index) => index + 1}
        />
        <Column
          title={t("surveyDetailsForm.question")}
          dataIndex="title"
          key="title"
          width="35%"
          render={(rowText: string) => truncate(rowText, { length: 80 })}
        />
        <Column
          title={t("surveyDetailsForm.questionType")}
          dataIndex="type"
          key="type"
          width="30%"
          render={(type: SurveyQuestionType) => t(`questionType.${type}`)}
        />
        <Column
          title={t("surveyDetailsForm.isRequired")}
          dataIndex="isRequired"
          key="isRequired"
          render={(isRequired) =>
            isRequired ? <CheckOutlined style={{ color: "#25b10f" }} /> : <CloseOutlined style={{ color: "#d6d6d6" }} />
          }
        />
      </Table>
    </Card>
  );
};

export default InitialSurvey;
