import React, { useEffect, useState, type FunctionComponent } from "react";
import { Button, Card, Divider, Empty, message, Popconfirm, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PerformingAction, programAutomationActions, RequestStatus } from "@fitness-app/app-store";
import { type ProgramAutomation } from "@fitness-app/data-models/entities/ProgramAutomation";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import AutomationTemplateForm from "./components/AutomationTemplateForm/AutomationTemplateForm";

type MessageTemplateListItem = ProgramAutomation;

const useAutomationList = () => {
  const { t } = useTranslation(["settings", "automation"]);
  const [isFormVisible, setFormVisibility] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(programAutomationActions.fetchProgramAutomations());
  }, [dispatch]);

  const showForm = (_?: ProgramAutomation) => {
    setFormVisibility(true);
  };

  const hideForm = () => {
    setFormVisibility(false);
  };

  const handleSubmit = async (payload: ProgramAutomation) => {
    await dispatch(programAutomationActions.addProgramAutomation(payload));
    hideForm();
  };

  const duplicate = async (formData: ProgramAutomation) => {
    void message.loading(t("automation:duplicate"));
    const programForDuplicate = await dispatch(programAutomationActions.fetchProgramAutomation(formData.id)).unwrap();
    const duplicated = {
      ...programForDuplicate,
      name: `${formData.name} (1)`,
      id: generateUniqId(),
    };

    delete duplicated.initialSurvey;
    delete duplicated.additionalSurvey;

    await dispatch(programAutomationActions.addProgramAutomation(duplicated));
    message.destroy();
  };

  const deleteTemplate = async (id: string) => {
    void message.loading(t("automation:removing"));
    await dispatch(programAutomationActions.deleteProgramAutomation(id));
    void dispatch(programAutomationActions.fetchProgramAutomations());
    message.destroy();
  };

  return {
    model: {
      isFormVisible,
    },
    operations: {
      showForm,
      hideForm,
      handleSubmit,
      duplicate,
      deleteTemplate,
    },
  };
};

const AutomationList: FunctionComponent = () => {
  const { model, operations } = useAutomationList();
  const { t } = useTranslation(["automation", "common"]);
  const { listStatus, data, performingAction } = useAppSelector((store) => store.programAutomation);
  const navigate = useNavigate();

  return (
    <Card
      loading={listStatus === RequestStatus.FETCHING}
      bordered={false}
      extra={
        <Button type="primary" onClick={() => operations.showForm()}>
          {t("common:button.add")}
        </Button>
      }
    >
      <Table
        loading={false}
        dataSource={data}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common:emptyList")} />,
        }}
      >
        <Table.Column<MessageTemplateListItem> title={t("programAutomation.list.name")} key="name" dataIndex="name" />
        <Table.Column<MessageTemplateListItem>
          title={t("programAutomation.list.duration")}
          key="duration"
          dataIndex="duration"
          render={(_, row) =>
            `${Object.keys(row.schedule).length} ${t("programAutomation.list.weeks", {
              count: Object.keys(row.schedule).length,
            })}`
          }
        />
        <Table.Column<MessageTemplateListItem>
          title={t("programAutomation.list.comment")}
          key="comment"
          dataIndex="comment"
          ellipsis
          render={(text: string) => text || "-"}
        />
        <Table.Column<MessageTemplateListItem>
          title={t("programAutomation.list.options")}
          key="action"
          align="center"
          render={(_, model) => (
            <>
              <Button type="link" onClick={() => navigate(`/automation/details/${model.id}`)}>
                {t("common:details")}
              </Button>
              <Divider type={"vertical"} />
              <Button type="link" onClick={() => operations.duplicate(model)}>
                {t("common:button.duplicate")}
              </Button>
              <Divider type={"vertical"} />
              <Popconfirm
                title={t("programAutomation.list.delete")}
                okText={t("common:switch.yes")}
                cancelText={t("common:button.cancel")}
                placement="top"
                onConfirm={() => operations.deleteTemplate(model.id)}
              >
                <Button type="link">{t("common:button.delete")}</Button>
              </Popconfirm>
            </>
          )}
        />
      </Table>
      <ModalForm
        open={model.isFormVisible}
        onCancel={operations.hideForm}
        title={t("programAutomation.form.title")}
        loading={performingAction === PerformingAction.Adding}
      >
        <AutomationTemplateForm onSubmit={operations.handleSubmit} />
      </ModalForm>
    </Card>
  );
};

export default AutomationList;
