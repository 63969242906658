import { createAsyncThunk } from "@reduxjs/toolkit";

import { type UnreadMessages } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { updateUnreadMessages } from "../reducer";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = void;

export const markAllAsRead = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/markAllAsRead`,
  async (_payload, { rejectWithValue, getState, dispatch, extra: { db, auth, analytics, errorTrackingService } }) => {
    const { id } = await getLoggedUser(auth);
    const unread = getState().chat.unreadMessages;
    if (!unread) {
      return;
    }

    const { error, data } = await db
      .from("unread_messages")
      .update({
        total: 0,
        perChannel: {},
        unreadMessages: [],
        updatedAt: new Date().toISOString(),
        lastEmailNotificationAboutUnread: null,
      })
      .eq("id", id)
      .select("*")
      .single<UnreadMessages>();

    if (error) {
      errorTrackingService?.recordError(error, "markAllAsRead action");
      return rejectWithValue(error.message);
    }
    analytics.track("mark_all_as_read");
    if (data) {
      dispatch(updateUnreadMessages({ ...data, _perChannel: {} }));
    }
  },
);
