import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationStatus } from "@fitness-app/data-models/entities/Notification";

import { type AsyncThunkCreator } from "../../../index";
import { NOTIFICATIONS_REDUCER_NAME } from "../types";

type Payload = string;

export const markAsRead = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${NOTIFICATIONS_REDUCER_NAME}/markAsRead`,
  async (payload, { rejectWithValue, extra: { db } }) => {
    const { error } = await db
      .from("notification")
      .update({ status: NotificationStatus.READ, updatedAt: new Date().toISOString() })
      .eq("id", payload);

    if (error) {
      return rejectWithValue(error.message);
    }
  },
);
