import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { useTagOptions } from "~/hooks/useTagOptions";
import { type AddWorkoutTemplateFormModel } from "./types";

interface AddWorkoutTemplateFormProps {
  formController?: FormInstance<AddWorkoutTemplateFormModel>;
  onSubmit: (formData: AddWorkoutTemplateFormModel) => void;
  model?: Partial<AddWorkoutTemplateFormModel> | null;
}

const AddWorkoutTemplateForm = ({ model, onSubmit, formController }: AddWorkoutTemplateFormProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const { filters: tags } = useTagOptions("workoutTags");

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <Form<AddWorkoutTemplateFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Form.Item
        name="name"
        label={t("workoutTemplates.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
            whitespace: true,
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="description"
        label={t("workoutTemplates.description")}
        rules={[
          {
            required: false,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item name="tags" label={t("workoutTemplates.tags")}>
        <Select notFoundContent={false} options={tags} mode="tags" />
      </Form.Item>
    </Form>
  );
};

export default AddWorkoutTemplateForm;
