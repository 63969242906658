import keyBy from "lodash.keyby";
import { createSelector } from "reselect";

import { type AppStore } from "../../../index";

const getExercises = (state: AppStore) => state.exercises.list;

export const getExercisesMap = createSelector([getExercises], (exercises) => {
  return keyBy(exercises, "id");
});
