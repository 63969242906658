import React, { createContext, useCallback, useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { clientNotesActions } from "@fitness-app/app-store/src/store/reducers/traineeNotes";
import { NoteAction } from "@fitness-app/app-store/src/store/reducers/traineeNotes/types";
import { UserRole } from "@fitness-app/data-models";
import { AuthorTypeEnum } from "@fitness-app/data-models/entities/AuthorTypeEnum";
import { ClientNoteCategory, ClientNoteType, type ClientNote } from "@fitness-app/data-models/entities/ClientNote";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import ClientNoteForm from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/ClientNoteForm";
import { type ClientNoteFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/types";
import { analyticsService, useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface ChatUtilitiesProviderContextProps {
  setNoteModel: (model: {
    traineeId: string;
    content: string;
    attachments?: { url: string; name: string; uid: string }[];
  }) => void;
}

const ChatUtilitiesContext = createContext<ChatUtilitiesProviderContextProps | undefined>(undefined);

export const useChatUtilitiesContext = () => {
  const context = useContext(ChatUtilitiesContext);
  if (!context) {
    throw new Error("useChatUtilitiesContext must be used within a ChatUtilitiesContext");
  }
  return context;
};

export const ChatUtilitiesProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [noteModel, setModel] = useState<(Partial<ClientNoteFormModel> & { traineeId: string }) | null>(null);
  const { role, userId } = useUserRole();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("trainees");
  const { noteAction } = useAppSelector((store) => store.traineeNotes);
  const queryClient = useQueryClient();

  const addNote = useCallback(
    async (formData: ClientNoteFormModel) => {
      if (noteModel?.traineeId) {
        const model: ClientNote = {
          attachments: formData.attachments || [],
          id: generateUniqId(),
          traineeId: noteModel.traineeId,
          content: formData.content,
          type: formData.type || ClientNoteType.Text,
          category: formData.category || ClientNoteCategory.General,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          pinned: false,
          authorType: role === UserRole.TRAINER ? AuthorTypeEnum.Admin : AuthorTypeEnum.Trainer,
          authorId: userId,
        };

        await dispatch(clientNotesActions.addClientNote({ note: model }));
        void analyticsService.track("add_client_note_from_chat");
        setIsOpen(false);
        setTimeout(() => {
          setModel(null);
        }, 300);
        void queryClient.invalidateQueries(["nutritionNotes", noteModel.traineeId]);
      }
    },
    [noteModel],
  );

  const setNoteModel = useCallback(
    (model: { traineeId: string; content: string; attachments?: { url: string; name: string; uid: string }[] }) => {
      setModel(model);
      setIsOpen(true);
    },
    [],
  );

  return (
    <ChatUtilitiesContext.Provider value={{ setNoteModel }}>
      {children}
      {noteModel && (
        <ModalForm
          open={isOpen}
          loading={noteAction === NoteAction.Adding || noteAction === NoteAction.Updating}
          onCancel={() => {
            setIsOpen(false);
            setTimeout(() => {
              setModel(null);
            }, 300);
          }}
          title={t("notes.formTitle")}
        >
          <ClientNoteForm onSubmit={addNote} model={noteModel} traineeId={noteModel.traineeId || "default"} />
        </ModalForm>
      )}
    </ChatUtilitiesContext.Provider>
  );
};
