import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ProductPrice } from "@fitness-app/data-models/entities/Product";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  prices: ProductPrice[];
};

export const updateProductPricesOrder = createAsyncThunk<void, Payload, AsyncThunkCreator<unknown>>(
  `${PRODUCT_REDUCER_NAME}/updateProductPricesOrder`,
  async (payload, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { product } = getState();

      if (product.details?.id !== payload.productId) {
        throw new Error("different-product-id");
      }

      if (!product.details.prices?.length) {
        throw new Error("product-without-prices");
      }

      const { error } = await db.from("product").update({ prices: payload.prices }).eq("id", payload.productId);

      if (error) {
        throw new Error(error.message);
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
