import React, { useRef, useState } from "react";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { useUploadFilesContext } from "./UploadProvider";

export interface UploadInputProps {
  children: React.ReactElement | string;
  multiple?: boolean;
  onInputClick?: () => void;
  disabled?: boolean;
}

const UploadInput = ({ children, multiple = true, onInputClick, disabled }: UploadInputProps): React.ReactElement => {
  const { onUploadFiles, validatorConfig } = useUploadFilesContext();
  const [uid, setNewId] = useState(generateUniqId());
  const inputFileRef = useRef<null | HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    if (files) {
      onUploadFiles(files, multiple);
      setNewId(generateUniqId());
    }
  };

  const onClick = (): void => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
      onInputClick?.();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  return (
    <div className="outline-none" tabIndex={0} role="button" onClick={onClick} onKeyDown={onKeyDown}>
      <input
        id="file-upload-input"
        data-testid="file-upload-input"
        type="file"
        disabled={disabled}
        ref={inputFileRef}
        onClick={(event): void => event.stopPropagation()}
        key={uid}
        className="hidden"
        multiple={multiple}
        onChange={onChange}
        accept={validatorConfig?.allowedMimeTypes?.join(",")}
      />
      {children}
    </div>
  );
};

export default UploadInput;
