import React, { useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, message, Row, Space, Typography } from "antd";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

import { traineeMealsPlanActions } from "@fitness-app/app-store";
import { getParentTrainerId } from "@fitness-app/app-store/src/store/reducers/user/selectors";
import { type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import { type MealsPlan, type NutritionAttachment } from "@fitness-app/data-models/entities/MealsPlan";
import { getErrorMessage } from "@fitness-app/utils";

import UploadField from "~/components/UploadField/UploadField";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface FormModel {
  attachments: MealsPlan["attachments"];
}

const MealsPlanAttachments = ({
  storageRef,
  planId,
  traineeId,
  current,
  readonly,
}: {
  storageRef?: string;
  planId: string;
  traineeId?: string;
  current?: boolean;
  readonly?: boolean;
}): React.ReactElement => {
  const { t } = useTranslation("common");
  const [formController] = Form.useForm<FormModel>();
  const { plan, updateMealsPlan } = useMealsPlanContext();
  const parentTrainerId = useAppSelector(getParentTrainerId);
  const dispatch = useAppDispatch();
  const [previousAttachments, setPreviousAttachments] = useState<
    {
      attachments: NutritionAttachment[];
      name: string;
      createdAt: string;
      status: ClientNutrition["status"];
      startAt: ClientNutrition["status"];
      endAt: ClientNutrition["endAt"];
    }[]
  >([]);
  const [fetching, setFetching] = useState(false);

  const onAttachmentsListChange = (updatedAttachments: { url: string; name: string; uid: string }[]) => {
    if (!isEqual(plan.attachments, updatedAttachments)) {
      void updateMealsPlan({
        mealsPlanDetails: null,
        mealsPlan: {
          id: plan.id,
          updatedAt: new Date().toISOString(),
          attachments: updatedAttachments.map((attachment) => ({
            ...attachment,
            status: "done",
            storageName: attachment.name,
          })),
        },
      });
    }
  };

  const fetchAllAttachments = async () => {
    if (!traineeId) return;
    try {
      setFetching(true);
      const response = await dispatch(
        traineeMealsPlanActions.fetchMealsPlanAttachments({ id: planId, traineeId }),
      ).unwrap();

      if (response.length === 0) {
        void message.warning("Nie znaleziono załączników z poprzednich diet");
      }

      setPreviousAttachments(response);
    } catch (e) {
      void message.error(`Wystąpił błąd podczas pobierania załączników z poprzednich diet: ${getErrorMessage(e)}`);
    } finally {
      setFetching(false);
    }
  };

  return (
    <div className="p-6">
      <Row>
        <Col xs={12}>
          <Form<FormModel>
            layout="vertical"
            form={formController}
            initialValues={{
              attachments: plan.attachments || [],
            }}
          >
            <Form.Item
              name="attachments"
              label={t("")}
              valuePropName="fileList"
              getValueFromEvent={(e) => e}
              wrapperCol={{ span: 12 }}
            >
              <UploadField
                multiple
                disabled={readonly}
                bucket="users"
                storageRef={storageRef || `${parentTrainerId}/mealsPlan/${plan.id}`}
                onChange={onAttachmentsListChange}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {traineeId && current && (
        <>
          <Divider />
          <div className="flex flex-col gap-y-4">
            <div>
              <Button loading={fetching} onClick={fetchAllAttachments} disabled={previousAttachments.length > 0}>
                Pobierz załączniki z poprzednich diet
              </Button>
            </div>

            <div>
              <Space direction="vertical">
                {previousAttachments.map((planAttachments, index) => {
                  return (
                    <div key={planAttachments.createdAt}>
                      <div className="mb-2">
                        <Typography.Text strong>
                          {planAttachments.name} ({planAttachments.startAt})
                        </Typography.Text>
                      </div>

                      {planAttachments.attachments.length ? (
                        <Space direction="vertical">
                          {planAttachments.attachments.map((attachment) => {
                            return (
                              <Typography.Link href={attachment.url} target="_blank" key={attachment.uid}>
                                {attachment.name} <LinkOutlined className="ml-2" />
                              </Typography.Link>
                            );
                          })}
                        </Space>
                      ) : (
                        <Typography.Text type="secondary">Brak załączników dla tego planu</Typography.Text>
                      )}
                      {index + 1 !== previousAttachments.length && <Divider />}
                    </div>
                  );
                })}
              </Space>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MealsPlanAttachments;
