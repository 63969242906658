import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientDishOccurrenceWithPlan } from "@fitness-app/data-models/entities/ClientDishOccurrence";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  planId: string;
  traineeId: string;
  names: string[];
};

export const fetchDishesOccurrence = createAsyncThunk<
  ClientDishOccurrenceWithPlan[] | null,
  Payload,
  AsyncThunkCreator<string>
>(`${MEALS_PLANS_REDUCER_NAME}/fetchDishesOccurrence`, async (payload, { rejectWithValue, extra: { db } }) => {
  const { error, data } = await db
    .from("client_dish_occurrence")
    .select("*, plan:planId(id, name, startAt, endAt)")
    .not("planId", "eq", payload.planId)
    .in("name", payload.names)
    .eq("traineeId", payload.traineeId)
    .returns<ClientDishOccurrenceWithPlan[]>();
  if (error) {
    return rejectWithValue(error.message);
  }

  return data;
});
