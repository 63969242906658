import React from "react";
import { useTranslation } from "react-i18next";

import {
  SurveyQuestionType,
  type SurveyQuestion,
  type SurveyQuestionAutomation,
  type SurveyTemplate,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import ABAnswer from "~/components/SurveyDetails/SurveyAnswers/ABAnswer";
import SelectAnswer from "~/components/SurveyDetails/SurveyAnswers/SelectAnswer";
import TextAnswer from "~/components/SurveyDetails/SurveyAnswers/TextAnswer";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import SurveyQuestionAutomationForm from "~/modules/SurveysTemplates/SurveyDetails/SurveyQuestionAutomationForm";

interface SurveyExampleAnswerProps {
  question: SurveyQuestion;
  surveyId: string;
  model?: SurveyQuestionAutomation[];
  survey?: SurveyTemplate;
}

const SurveyExampleAnswer = ({ question, surveyId, model, survey }: SurveyExampleAnswerProps) => {
  const { t } = useTranslation("surveys");
  const { isTrainer } = useUserRole();
  let Component = null;
  if (question.type === SurveyQuestionType.Text) {
    Component = <TextAnswer />;
  }
  if (question.type === SurveyQuestionType.AB) {
    Component = <ABAnswer extendedAnswerA={question.extendedAnswerA} extendedAnswerB={question.extendedAnswerB} />;
  }
  if (question.type === SurveyQuestionType.Select) {
    Component = <SelectAnswer options={question.answers} multiSelect={question.multiSelect} />;
  }

  if (Component) {
    return (
      <div>
        <p>{t("exampleAnswers")}</p>
        {Component}
        {isTrainer && "id" in question && survey && (
          <SurveyQuestionAutomationForm question={question} surveyId={surveyId} model={model} survey={survey} />
        )}
      </div>
    );
  }
  return null;
};

export default SurveyExampleAnswer;
