import {
  type TrainingProgramLevelEnum,
  type TrainingProgramTypeEnum,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PROGRAMS_REDUCER_NAME = "programs";

export interface ProgramsReducer {
  list: TrainingProgramWithCreator[];
  listStatus: null | RequestStatus;
  programStatus: null | RequestStatus;
  filters: {
    searchTerm: string | null;
    addedBy: string | null;
    isShared: boolean | null;
    programLevel: TrainingProgramLevelEnum | null;
    programType: TrainingProgramTypeEnum | null;
    tags: string[] | null;
  };
  pagination: {
    currentPage: number;
    totalPages: number;
    perPage: number;
  };
}
