import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";

import { type AsyncThunkCreator } from "../../../index";
import { clearFetchedProgram, fetchProgramWithDetails } from "../../traineeProgram/actions";
import { TRAINEE_REDUCER_NAME } from "../types";
import { fetchTraineeStatus } from "./fetchTraineeStatus";

type Payload =
  | {
      id: string;
      refetch?: boolean;
    }
  | { userId: string; refetch?: boolean };

export const fetchTrainee = createAsyncThunk<TraineeProfile, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_REDUCER_NAME}/fetchTrainee`,
  async (payload, { rejectWithValue, extra: { db }, dispatch }) => {
    const { data, error } = await db
      .from("trainee")
      .select(
        "userId" in payload
          ? "*, settings:trainee_settings(onboarding), assignedTrainer:assignedTrainerId(id, firstName, lastName, userId, email, profile:user_profile!team_member_userId_fkey(avatarUrl)), productClient:productId(product(name), productId, status, accessPeriod, productDuration, phoneNumber), user:userId(id, firstName, lastName, avatarUrl)"
          : "*, assignedTrainer:assignedTrainerId(id, firstName, lastName, userId, email, profile:user_profile!team_member_userId_fkey(avatarUrl)), productClient:productId(product(name), productId, status, accessPeriod, productDuration, phoneNumber), user:userId(id, firstName, lastName, avatarUrl), nutrition:activeMealsPlanId(name, id, startAt)",
      )
      .eq("id" in payload ? "id" : "userId", "userId" in payload ? payload.userId : payload.id)
      .single<TraineeProfile>();

    if (error) {
      return rejectWithValue(error.message);
    }

    if (data.userId) {
      void dispatch(fetchTraineeStatus({ userId: data.userId }));
    }

    if (data.activeTrainingProgramId) {
      void dispatch(fetchProgramWithDetails({ id: data.activeTrainingProgramId }));
    } else {
      dispatch(clearFetchedProgram());
    }

    return data;
  },
);
