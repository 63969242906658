import { type ProductsSettings } from "@fitness-app/data-models/entities/ProductsSettings";

import { type RequestStatus } from "../../../enums/requestStatus";

export interface ProductsSettingsReducer {
  status: null | RequestStatus;
  data: null | ProductsSettings;
  error: null | Error;
  showProductList: boolean;
}

export const PRODUCTS_SETTINGS_REDUCER_NAME = "ProductsSettings";
