import React, { useEffect, useMemo, useState } from "react";
import { BarChart } from "@tremor/react";
import { Card } from "antd";
import dayjs from "dayjs";

import { RequestStatus, traineeMeasurementsActions } from "@fitness-app/app-store";
import { getTraineeId } from "@fitness-app/app-store/src/store/reducers/trainee/selectors/getTraineeId";
import { MeasurementType, type HydrationMeasurement } from "@fitness-app/data-models/entities/Measurement";
import { getErrorMessage } from "@fitness-app/utils";

import { type CommonChartProps } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const valueFormatterAbsolute = (number: number) => `${Intl.NumberFormat("pl").format(number).toString()} ml`;

export const HydrationChart = ({ range }: CommonChartProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [measurements, setMeasurements] = useState<HydrationMeasurement[]>([]);
  const [requestState, setRequestState] = useState<{ status: RequestStatus; error: null | string }>({
    error: null,
    status: RequestStatus.IDLE,
  });
  const traineeId = useAppSelector(getTraineeId);

  const chartData = useMemo(() => {
    return measurements.map((item) => ({
      date: dayjs(item.eventDate).format("DD.MM.YYYY"),
      Nawodnienie: item.data.portion,
    }));
  }, [measurements]);

  const fetchMeasurements = async (dateStart: Date, dateEnd: Date, traineeId: string) => {
    try {
      setRequestState({ error: null, status: RequestStatus.FETCHING });
      const response = await dispatch(
        traineeMeasurementsActions.fetchMeasurementsForRangeAndType({
          dateStart,
          dateEnd,
          type: MeasurementType.HydrationMeasurement,
          traineeId,
        }),
      ).unwrap();
      setMeasurements(response as HydrationMeasurement[]);
      setRequestState({ error: null, status: RequestStatus.SUCCESS });
    } catch (e) {
      setRequestState({ error: getErrorMessage(e), status: RequestStatus.FAILED });
    }
  };

  useEffect(() => {
    if (range?.[0] && range?.[1] && traineeId) {
      void fetchMeasurements(range[0].startOf("day").toDate(), range[1].endOf("day").toDate(), traineeId);
    }
  }, [range, traineeId]);

  return (
    <Card
      title="Nawodnienie"
      type="inner"
      className="relative"
      loading={requestState.status === RequestStatus.FETCHING}
    >
      <BarChart
        className="mt-8 h-80"
        data={chartData}
        index="date"
        colors={["blue"]}
        categories={["Nawodnienie"]}
        showLegend={false}
        valueFormatter={valueFormatterAbsolute}
        yAxisWidth={90}
        autoMinValue
        allowDecimals={false}
        intervalType={"preserveStartEnd"}
        noDataText="Brak danych do wyświetlenia"
      />
    </Card>
  );
};
