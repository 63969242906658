import React, { useCallback, useMemo } from "react";
import { Alert, message, Skeleton } from "antd";
import { useParams } from "react-router-dom";

import { RequestStatus } from "@fitness-app/app-store";
import { getMealsMapHelper } from "@fitness-app/app-store/src/store/reducers/mealsPlans/selectors/getMealsMap";
import { type IngredientWithPortion } from "@fitness-app/data-models/entities/Ingredient";
import {
  type DishInMeal,
  type MealsPlan,
  type MealsPlanDetails,
  type MealsPlanWithCreator,
} from "@fitness-app/data-models/entities/MealsPlan";

import MealsPlanProvider from "~/shared/providers/MealsPlanProvider";

interface MealsPlanTemplateProviderProps {
  children: React.ReactElement;
  selectedMealsPlan: MealsPlanWithCreator | null;
  selectedMealsPlanStatus: RequestStatus;
  selectedMealsPlanDetails: MealsPlanDetails[] | null;
}

const MealsPlanTemplateViewerProvider = ({
  children,
  selectedMealsPlan,
  selectedMealsPlanStatus,
  selectedMealsPlanDetails,
}: MealsPlanTemplateProviderProps) => {
  const { id } = useParams();

  const mealsMap = useMemo(() => getMealsMapHelper(selectedMealsPlanDetails), [selectedMealsPlanDetails]);

  const [planDetails] = selectedMealsPlanDetails || [];

  const updateMealsPlan = useCallback(
    async (_props: {
      mealsPlan: Partial<MealsPlan> & { id: string };
      mealsPlanDetails: (Partial<MealsPlanDetails> & { id: string }) | null;
    }) => {
      void message.warning("Jesteś w trybie podglądu, nie możesz edytować planu.");
      await Promise.resolve();
    },
    [],
  );

  const planDetailsId = planDetails?.id;

  const updateMealsPlanDays = useCallback(
    async (_props: { days: MealsPlanDetails["weeks"][number]["days"] }) => {
      void message.warning("Jesteś w trybie podglądu, nie możesz edytować planu.");
      await Promise.resolve();
    },
    [planDetailsId],
  );

  const updateProductInMealsPlan = useCallback(
    async (_props: { dayId: string; mealId: string; product: DishInMeal | IngredientWithPortion }) => {
      void message.warning("Jesteś w trybie podglądu, nie możesz edytować planu.");
      await Promise.resolve();
    },
    [],
  );

  const deleteProductFromMealsPlan = useCallback(
    async (_props: { dayId: string; mealId: string; productId: string }) => {
      void message.warning("Jesteś w trybie podglądu, nie możesz edytować planu.");
      await Promise.resolve();
    },
    [],
  );

  if (!selectedMealsPlanStatus || (!planDetails && selectedMealsPlanStatus === RequestStatus.FETCHING)) {
    return <Skeleton className="p-5" paragraph={{ rows: 5 }} />;
  }

  if (!id || !planDetails || !selectedMealsPlan) {
    return <Alert type="error" />;
  }

  return (
    <MealsPlanProvider
      mealsMap={mealsMap}
      planId={id}
      planDetails={planDetails}
      plan={selectedMealsPlan}
      updateMealsPlan={updateMealsPlan}
      updateMealsPlanDays={updateMealsPlanDays}
      updateProductInMealsPlan={updateProductInMealsPlan}
      deleteProductFromMealsPlan={deleteProductFromMealsPlan}
    >
      {children}
    </MealsPlanProvider>
  );
};

export default MealsPlanTemplateViewerProvider;
