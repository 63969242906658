import { type ProductResource, type ProductResourceCategory } from "@fitness-app/data-models/entities/ProductResource";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PRODUCT_RESOURCES_REDUCER_NAME = "ProductResources";

export interface ProductResourcesReducer {
  data: ProductResource[];
  status: null | RequestStatus;
  error: null | string;
  categories: ProductResourceCategory[];
  categoriesStatus: null | RequestStatus;
  searchTerm: string | null;
}
