import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import {
  type TrainingProgramDetails,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { createProgramDetailsTemplate, duplicateCollection } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { type AsyncThunkCreator } from "../../../index";
import { PROGRAMS_REDUCER_NAME } from "../types";
import { fetchPrograms } from "./fetchPrograms";

type Payload = TrainingProgramWithCreator;

export const duplicateProgram = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PROGRAMS_REDUCER_NAME}/duplicateProgram`,
  async (program, { rejectWithValue, dispatch, getState, extra: { db, analytics } }) => {
    const { data } = getState().user;
    const { creator, ...rest } = program;
    const duplicatedProgram = {
      ...rest,
      createdBy: data?.id || null,
      id: uuid(),
      name: `${program.name} Kopia`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const { error } = await db.from("training_program").insert(duplicatedProgram);

    if (error) {
      return rejectWithValue(error.message);
    }

    const { data: programDetails } = await db
      .from("training_program_details")
      .select()
      .eq("programId", program.id)
      .single<TrainingProgramDetails>();

    const { error: erroOnSavingDetails } = await db.from("training_program_details").insert(
      programDetails
        ? {
            ...programDetails,
            id: uuid(),
            programId: duplicatedProgram.id,
            workouts: programDetails.workouts.map((workout) => ({
              ...workout,
              exercises: duplicateCollection(workout.exercises),
              postWorkout: duplicateCollection(workout.postWorkout),
              preWorkout: duplicateCollection(workout.preWorkout),
            })),
          }
        : createProgramDetailsTemplate(duplicatedProgram.id),
    );

    if (erroOnSavingDetails) {
      return rejectWithValue(erroOnSavingDetails.message);
    }

    analytics.track("duplicate_workout_program");

    void dispatch(fetchPrograms());
  },
);
