import React, { useEffect, useMemo, useState } from "react";
import { Form, Input, InputNumber, Select, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import { orderBy } from "lodash";
import { type DefaultOptionType } from "rc-select/lib/Select";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { programBuilderActions } from "@fitness-app/app-store";
import { type ProgramAutomation, type WorkoutRoutine } from "@fitness-app/data-models/entities/ProgramAutomation";
import {
  type TrainingProgramDetails,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { getName } from "@fitness-app/data-models/utils/getAuthorName";
import { cn } from "@fitness-app/utils/src/styles/cn";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import {
  type SelectProgramFormModel,
  type WorkoutRoutineWithDates,
} from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import { useTrainingPlanSearch } from "~/modules/TrainingPrograms/hooks/useTrainingPlanSearch";
import { useAppDispatch } from "~/store/initializeStore";

interface SelectProgramProps {
  selectedAutomation: ProgramAutomation;
  onSubmit: (model: SelectProgramFormModel) => void;
  formController: FormInstance<SelectProgramFormModel>;
  workoutRoutineModel?: WorkoutRoutine | WorkoutRoutineWithDates | null;
  withoutWeeksLimit?: boolean;
}

const SelectProgram = ({
  selectedAutomation,
  onSubmit,
  formController,
  workoutRoutineModel,
  withoutWeeksLimit,
}: SelectProgramProps) => {
  const { t } = useTranslation(["automation", "common"]);
  const dispatch = useAppDispatch();
  const [fetchedPlan, setFetchedPlan] = useState<{
    plan: TrainingProgramWithCreator;
    details: TrainingProgramDetails;
  } | null>(null);
  const { data, isLoading, setSearchValue } = useTrainingPlanSearch();
  const { userId } = useUserRole();

  useEffect(() => {
    if (workoutRoutineModel) {
      formController.setFieldsValue({
        program: workoutRoutineModel.name,
        startWeek: workoutRoutineModel.startWeek,
        endWeek: workoutRoutineModel.endWeek,
      });
    }

    return () => {
      formController.resetFields();
    };
  }, [workoutRoutineModel]);

  const onProgramSelect = async (_programId: string, option: DefaultOptionType) => {
    if ("key" in option) {
      const plan = await dispatch(
        programBuilderActions.validateProgramWithDetails({
          id: option.key as string,
        }),
      ).unwrap();
      setFetchedPlan(plan);
    }
  };

  const currentNumberOfScheduledWeeks = Object.keys(selectedAutomation.schedule).length;

  const handleSubmit = (model: SelectProgramFormModel) => {
    onSubmit({
      ...model,
      selectedProgram: fetchedPlan?.plan || null,
    });
  };

  const planOptions = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!userId) {
      return data.map((plan) => ({
        label: plan.name,
        value: plan.id,
        key: plan.id,
        createdBy: plan.createdBy,
        creator: plan.creator,
        tags: plan.tags,
      }));
    }
    const ordered = orderBy(
      data.map((plan) => ({
        label: plan.name,
        value: plan.id,
        key: plan.id,
        createdBy: plan.createdBy,
        creator: plan.creator,
        tags: plan.tags,
      })),
      (item) => item.createdBy === userId,
      "desc",
    );

    return ordered;
  }, [data]);

  const onPlanSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
  }, 300);

  return (
    <div>
      <Form<SelectProgramFormModel>
        name="schedule-program"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        form={formController}
        onFinish={handleSubmit}
        initialValues={{
          program: "",
          startWeek: 1,
          endWeek: currentNumberOfScheduledWeeks,
          additionalComment: "",
        }}
      >
        <Form.Item
          name="program"
          label="Wybierz program"
          validateStatus={isLoading ? "validating" : undefined}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            optionLabelProp="label"
            optionFilterProp="label"
            allowClear
            showSearch
            disabled={!!workoutRoutineModel}
            onSelect={onProgramSelect}
            onClear={() => {
              setFetchedPlan(null);
              formController?.setFieldsValue({
                program: "",
              });
            }}
            onSearch={onPlanSearch}
          >
            {planOptions.map((option) => (
              <Select.Option value={option.value} label={option.label} key={option.value}>
                <div className={cn("flex flex-col gap-y-2")}>
                  <span>{option.label}</span>
                  <Typography.Text className="text-xs" type="secondary">
                    Autor: {"creator" in option ? getName(option.creator, undefined, false) : "-"}
                  </Typography.Text>
                  {option.tags?.length ? (
                    <Typography.Text className="text-xs" type="secondary">
                      Tagi: {option.tags?.join(", ")}
                    </Typography.Text>
                  ) : null}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <>
          <Form.Item
            name="startWeek"
            label="Od tygodnia"
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber min={1} max={withoutWeeksLimit ? undefined : currentNumberOfScheduledWeeks} />
          </Form.Item>

          <Form.Item
            name="endWeek"
            label="Do tygodnia"
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber min={1} max={withoutWeeksLimit ? undefined : currentNumberOfScheduledWeeks} />
          </Form.Item>
        </>

        <Form.Item name="additionalComment" label="Uwagi do planu">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectProgram;
