import { type TFunction } from "i18next";

import {
  EndAccessStrategy,
  PaymentClient,
  PaymentTypeOption,
  ProductDurationType,
  ProductType,
  type PricePaymentType,
  type ProductTypeEnum,
} from "@fitness-app/data-models/entities/Product";

import { IS_DEV } from "~/constants/config";

export const BillingPeriod = {
  Daily: "1-day",
  Monthly: "1-month",
  Quarter: "3-month",
  Annually: "1-year",
  ToCancel: "to_cancel",
  Custom: "custom",
} as const;

export const createNumberOfCycleTypeOptions = (t: TFunction) => [
  {
    label: t("products:numberOfCycle.month"),
    value: "month",
  },
  {
    label: t("products:numberOfCycle.week"),
    value: "week",
  },
];

export const createIntervalOptions = (t: TFunction) => [
  {
    label: t("products:numberOfCycle.month"),
    value: "month",
  },
  {
    label: t("products:numberOfCycle.week"),
    value: "week",
  },
  {
    label: t("products:numberOfCycle.days"),
    value: "day",
  },
];

export const createProductPriceTypeOptions = (t: TFunction, productType: ProductTypeEnum) =>
  productType === ProductType.ONLY_CONTENT
    ? [
        {
          label: t("products:paymentTypeOptions.one_time"),
          value: PaymentTypeOption.OneTime,
        },
      ]
    : [
        {
          label: t("products:paymentTypeOptions.one_time"),
          value: PaymentTypeOption.OneTime,
        },
        {
          label: t("products:paymentTypeOptions.recurring"),
          value: PaymentTypeOption.Recurring,
        },
      ];

export const createProductDurationTypeOptions = (t: TFunction) => [
  {
    label: t(`products:productDurationType.${ProductDurationType.LastsFor}`),
    value: ProductDurationType.LastsFor,
  },
  {
    label: t(`products:productDurationType.${ProductDurationType.UntilDate}`),
    value: ProductDurationType.UntilDate,
  },
  {
    label: t(`products:productDurationType.${ProductDurationType.Infinite}`),
    value: ProductDurationType.Infinite,
  },
];

export const createProductTypeOptions = (t: TFunction) => [
  {
    label: t(`products:productType.${ProductType.PERSONAL_CLIENT_SERVICE.toLowerCase()}`),
    value: ProductType.PERSONAL_CLIENT_SERVICE,
  },
  {
    label: t(`products:productType.${ProductType.AUTOMATED_CLIENT_SERVICE.toLowerCase()}`),
    value: ProductType.AUTOMATED_CLIENT_SERVICE,
  },
  {
    label: t(`products:productType.${ProductType.ADDON.toLowerCase()}`),
    value: ProductType.ADDON,
  },
  {
    label: t(`products:productType.${ProductType.ONLY_CONTENT.toLowerCase()}`),
    value: ProductType.ONLY_CONTENT,
  },
];

export const createBillingPeriodOptions = (t: TFunction, paymentType: PricePaymentType | null) => [
  ...(paymentType === PaymentTypeOption.OneTime
    ? [
        {
          label: t("products:billingPeriod.toCancel"),
          value: BillingPeriod.ToCancel,
        },
      ]
    : []),
  ...(IS_DEV
    ? [
        {
          label: t(`products:billingPeriod.${paymentType}.daily`),
          value: BillingPeriod.Daily,
        },
      ]
    : []),
  {
    label: t(`products:billingPeriod.${paymentType}.monthly`),
    value: BillingPeriod.Monthly,
  },
  {
    label: t(`products:billingPeriod.${paymentType}.quarter`),
    value: BillingPeriod.Quarter,
  },
  {
    label: t(`products:billingPeriod.${paymentType}.yearly`),
    value: BillingPeriod.Annually,
  },
  {
    label: t(`products:billingPeriod.${paymentType}.custom`),
    value: BillingPeriod.Custom,
  },
];

export const createPaymentClientsOptions = (t: TFunction) => [
  {
    label: t(`common:paymentClient.${PaymentClient.STRIPE.toLowerCase()}`),
    value: PaymentClient.STRIPE,
  },
];

export const createEndAccessStrategy = (t: TFunction) => [
  {
    label: t(`common:endAccessStrategy.${EndAccessStrategy.Cancel}`),
    value: EndAccessStrategy.Cancel,
  },
  {
    label: t(`common:endAccessStrategy.${EndAccessStrategy.OfferWithTheSamePrice}`),
    value: EndAccessStrategy.OfferWithTheSamePrice,
  },
  {
    label: t(`common:endAccessStrategy.${EndAccessStrategy.OfferWithTheCurrentPrices}`),
    value: EndAccessStrategy.OfferWithTheCurrentPrices,
  },
];
