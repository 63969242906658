import { createAsyncThunk } from "@reduxjs/toolkit";
import { type UnreadMessages } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchUnreadMessages = createAsyncThunk<UnreadMessages, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/fetchUnreadMessages`,
  async (_payload, { rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);

    const response = await db.from("unread_messages").select("*").eq("id", currentUser.id).returns<UnreadMessages[]>();

    if (response.error) {
      return rejectWithValue(response.error.message);
    }

    let data = response.data[0];

    if (!data) {
      data = {
        id: currentUser.id,
        total: 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        perChannel: {},
        mutedChannels: [],
        mutedAll: false,
        unreadMessages: [],
      };
      await db.from("unread_messages").insert(data);
    }

    return data;
  },
);
