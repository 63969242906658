import { type RefObject } from "react";

const shouldScrollToBottomConsideringCurrentOffset = (currentNode: HTMLDivElement): boolean => {
  const OFFSET_FROM_BOTTOM_IN_PIXELS = 300;

  return currentNode.scrollTop + currentNode.clientHeight + OFFSET_FROM_BOTTOM_IN_PIXELS >= currentNode.scrollHeight;
};

export const scrollToBottom = (ref: RefObject<HTMLDivElement | null>, forceScrolling = false): undefined => {
  if (!ref?.current) {
    return undefined;
  }
  const currentNode = ref.current;
  if (forceScrolling || shouldScrollToBottomConsideringCurrentOffset(currentNode)) {
    currentNode.scrollTop = currentNode.scrollHeight;
  }
  return undefined;
};
