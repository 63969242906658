import React, { useEffect, type FunctionComponent } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";

import { type MailerLiteIntegrationFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<MailerLiteIntegrationFormModel>;
  onSubmit: (formData: MailerLiteIntegrationFormModel) => void;
  model?: MailerLiteIntegrationFormModel | null;
}

type Props = OwnProps;

const MailerLiteForm: FunctionComponent<Props> = ({ onSubmit, formController, model }) => {
  const { t } = useTranslation(["settings", "common"]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model, formController]);

  return (
    <div className="min-w-450px">
      <Form<MailerLiteIntegrationFormModel>
        name="mailer-lite-campaign-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        size="large"
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="url"
          label={t("mailerLite.account")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input placeholder={t<string>("mailerLite.accountNamePlaceholder")} />
        </Form.Item>
        <Form.Item
          name="apiKey"
          label={t("mailerLite.apiKey")}
          tooltip={t("mailerLite.tooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
};

export default MailerLiteForm;
