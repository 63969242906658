import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ProductClient, type ProductClientWithTraineeInfo } from "@fitness-app/data-models/entities/ProductClient";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchProductClient } from "./actions/fetchProductClient";
import { fetchProductClientOrders } from "./actions/fetchProductClientOrders";
import { fetchProductClientOrdersNextPage } from "./actions/fetchProductClientOrdersNextPage";
import { PRODUCT_CLIENT_REDUCER, type ProductClientReducer } from "./types";

const initialState: ProductClientReducer = {
  data: null,
  status: null,
  error: null,
  clientOrders: [],
  ordersPageSize: 30,
  ordersPage: 0,
  ordersStatus: null,
};

const newsletterSlice = createSlice({
  name: PRODUCT_CLIENT_REDUCER,
  initialState,
  reducers: {
    subscribeToProductClientStarted(state) {
      state.status = RequestStatus.SUBSCRIBING;
      state.error = null;
    },
    subscribeToProductClientSuccess(state) {
      state.status = RequestStatus.SUBSCRIBED;
    },
    subscribeToProductClientFailed(state, { payload }: PayloadAction<string>) {
      state.status = RequestStatus.FAILED;
      state.error = payload;
    },
    unsubscribeFromProductClient(state) {
      state.data = null;
      state.status = null;
      state.error = null;
      state.clientOrders = [];
    },
    updateProductClient(state, { payload }: PayloadAction<ProductClient>) {
      if (state.data) {
        state.data = {
          ...(state.data as ProductClientWithTraineeInfo),
          ...payload,
        } as ProductClientWithTraineeInfo;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductClient.fulfilled, (state, { payload }) => {
      state.data = payload;
    });
    builder.addCase(fetchProductClientOrders.pending, (state) => {
      state.ordersStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchProductClientOrders.fulfilled, (state, { payload }) => {
      state.clientOrders = payload;
      state.ordersStatus = RequestStatus.SUCCESS;
      state.ordersPage = 1;
    });
    builder.addCase(fetchProductClientOrders.rejected, (state) => {
      state.ordersStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchProductClientOrdersNextPage.fulfilled, (state, { payload }) => {
      state.clientOrders = [...state.clientOrders, ...payload.orders];
      state.ordersPage = payload.page;
    });
  },
});

export const {
  subscribeToProductClientFailed,
  subscribeToProductClientStarted,
  subscribeToProductClientSuccess,
  unsubscribeFromProductClient,
  updateProductClient,
} = newsletterSlice.actions;
export default newsletterSlice.reducer;
