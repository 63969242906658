import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PRODUCT_CLIENTS_REDUCER_NAME = "ProductClients";

export interface ProductClientsReducer {
  listStatus: RequestStatus | null;
  listSize: number;
  list: ProductClient[];
  page: number;
  isLastPage: boolean;
  totalPages: number;
  productId: string | null;
  searchStatus: RequestStatus | null;
  searchTerm: string;
  filters: {
    statuses: ProductClient["status"][];
  };
}
