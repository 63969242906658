import { type AppThunk } from "../../../index";
import { unsubscribeFromLatestChannel as actionCreator } from "../reducer";

export const unsubscribeFromLatestChannel =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    if (subscriptions.latestChatRealtimeChannel) {
      await db.removeChannel(subscriptions.latestChatRealtimeChannel);
      subscriptions.unreadMessagesChannelName = null;
    }
    dispatch(actionCreator());
  };
