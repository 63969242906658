import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  ingredient: Ingredient;
};

export const editIngredient = createAsyncThunk<Ingredient | null, Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/editIngredient`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { id, createdAt, createdBy, ...rest } = payload.ingredient;

    const { error, data } = await db.from("ingredient").update(rest).eq("id", id).select("*").returns<Ingredient[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_ingredient");

    return data[0] || null;
  },
);
