import { createAsyncThunk } from "@reduxjs/toolkit";
import { type Dayjs } from "dayjs";

import { type ProgramAutomationTask } from "@fitness-app/data-models/entities/ProgramAutomation";

import { type AsyncThunkCreator } from "../../../index";
import { CLIENT_SCHEDULED_TASKS_REDUCER_NAME, type SelectedMonth } from "../types";

type Payload = {
  traineeId: string;
  selectedDate: Dayjs;
};

export const fetchClientTasks = createAsyncThunk<
  { data: ProgramAutomationTask[]; selectedMonth: SelectedMonth },
  Payload,
  AsyncThunkCreator<string>
>(`${CLIENT_SCHEDULED_TASKS_REDUCER_NAME}/fetchClientTasks`, async (payload, { rejectWithValue, extra: { db } }) => {
  const selectedMonth: SelectedMonth = `${payload.selectedDate.get("year")}-${payload.selectedDate.get("month") + 1}`;

  const from = payload.selectedDate.subtract(1, "month").startOf("month").toISOString();
  const to = payload.selectedDate.add(1, "month").endOf("month").toISOString();

  const { error, data } = await db
    .from("program_automation_task")
    .select("*")
    .order("createdAt", { ascending: false })
    .eq("traineeId", payload.traineeId)
    .gte("sendDate", from)
    .lte("sendDate", to)
    .returns<ProgramAutomationTask[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return { selectedMonth, data };
});
