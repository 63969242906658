import React, { useEffect } from "react";
import { Button, Empty, List } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus, traineeMeasurementsActions, traineeMeasurementsSelectors } from "@fitness-app/app-store";

import TraineeBodyPhotoRow from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeBodyPhotos/TraineeBodyPhotoRow";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeBodyPhotosProps {
  traineeId: string;
}

const TraineeBodyPhotos = ({ traineeId }: TraineeBodyPhotosProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("trainees");

  const { photosCurrentPage, photosTotalPages, photosStatus } = useAppSelector((store) => store.traineeMeasurements);
  const photos = useAppSelector(traineeMeasurementsSelectors.getGroupedPhotosByDay);

  useEffect(() => {
    void dispatch(traineeMeasurementsActions.fetchTraineeBodyPhotos({ traineeId }));
  }, [traineeId]);

  const handleOnLeadMore = () => {
    if (traineeId) {
      void dispatch(traineeMeasurementsActions.fetchTraineeBodyPhotos({ traineeId, page: photosCurrentPage + 1 }));
    }
  };

  const renderShowMoreButton = () => {
    const showFetchMoreButton =
      photosCurrentPage < photosTotalPages && photosTotalPages > 1 && photosStatus === RequestStatus.SUCCESS;

    if (showFetchMoreButton) {
      return (
        <div className="mt-8 flex items-center justify-center p-2.5">
          <Button onClick={handleOnLeadMore}>{t("measurements.fetchMore")}</Button>
        </div>
      );
    }

    return null;
  };

  return (
    <List
      itemLayout="horizontal"
      locale={{
        emptyText: (
          <Empty
            imageStyle={{ marginTop: 80 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("photos.emptyState")}
          />
        ),
      }}
      loading={photosStatus === RequestStatus.FETCHING && photos.length === 0}
      rowKey={(row) => row.date}
      loadMore={renderShowMoreButton()}
      dataSource={photos}
      renderItem={(row) => <TraineeBodyPhotoRow {...row} />}
    />
  );
};

export default TraineeBodyPhotos;
