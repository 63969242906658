import { type ClientDishOccurrenceWithPlan } from "@fitness-app/data-models/entities/ClientDishOccurrence";
import { type ClientMealsPlanDetails, type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";

import { type RequestStatus } from "../../../enums/requestStatus";

export interface TraineeMealsPlansReducer {
  selectedMealsPlan: ClientNutrition | null;
  selectedMealsPlanStatus: null | RequestStatus;
  selectedMealsPlanDetails: ClientMealsPlanDetails[] | null;
  dishesOccurrence: ClientDishOccurrenceWithPlan[] | null;
  currentMealsPlanDetails: (ClientMealsPlanDetails & { name: string; startAt: string; endAt: string | null }) | null;
}

export const MEALS_PLANS_REDUCER_NAME = "traineeMealsPlans";
