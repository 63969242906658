import React, { useEffect, useState, type FunctionComponent } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Form, Input, Radio, Select, Space, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { AuthType } from "@fitness-app/data-models/entities/Automation";

import {
  paramFieldOptions,
  type WebhookFormModel,
} from "~/modules/Products/Product/ProductAutomation/components/WebhookForm/types";
import { createTriggerOptions } from "~/modules/Products/Product/ProductAutomation/triggers";
import { useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  formController?: FormInstance<WebhookFormModel>;
  onSubmit: (formData: WebhookFormModel) => void;
  model?: WebhookFormModel | null;
}

type Props = OwnProps;

export const methodOptions = [
  {
    value: "get",
    label: "get".toUpperCase(),
  },
  {
    value: "post",
    label: "post".toUpperCase(),
  },
];

export const addToOptions = [
  {
    value: "header",
    label: "Header",
  },
  {
    value: "query",
    label: "Query Params",
  },
];

export const authOptions = [
  {
    value: AuthType.None,
    label: "Brak",
  },
  {
    value: AuthType.BasicAuth,
    label: "Basic Auth",
  },
  {
    value: AuthType.BearerToken,
    label: "Bearer Token",
  },
  {
    value: AuthType.ApiKey,
    label: "API Key",
  },
];

const WebhookForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["products", "common"]);
  const [selectedMethod, setMethod] = useState<"get" | "post">(model?.method || "get");
  const productDetails = useAppSelector((store) => store.product.details);
  const [authType, setAuthType] = useState<null | AuthType>(model?.authType || null);

  useEffect(() => {
    if (model) {
      formController?.setFields([
        {
          name: "active",
          value: model.active,
        },
        {
          name: "method",
          value: model.method,
        },
        {
          name: "url",
          value: model.url,
        },
        {
          name: "triggers",
          value: model.triggers,
        },
        {
          name: "description",
          value: model.description ?? "",
        },
        {
          name: "authType",
          value: model.authType,
        },
        {
          name: "payloadAsParams",
          value: model.payloadAsParams ?? false,
        },
        {
          name: "payload",
          value: model.payload || [],
        },
      ]);

      if (model.auth && "token" in model.auth) {
        formController?.setFields([
          {
            name: ["auth", "token"],
            value: model.auth.token,
          },
        ]);
      }

      if (model.auth && "addTo" in model.auth) {
        formController?.setFields([
          {
            name: ["auth", "addTo"],
            value: model.auth.addTo,
          },
          {
            name: ["auth", "key"],
            value: model.auth.key,
          },
          {
            name: ["auth", "value"],
            value: model.auth.value,
          },
        ]);
      }

      if (model.auth && "username" in model.auth) {
        formController?.setFields([
          {
            name: ["auth", "username"],
            value: model.auth.username,
          },
          {
            name: ["auth", "password"],
            value: model.auth.password,
          },
        ]);
      }
    } else {
      formController?.resetFields();
    }
  }, [model]);

  const handleMethodChange = (method: "get" | "post") => {
    const payload = formController?.getFieldValue("payload") as WebhookFormModel["payload"];
    if (method === "post" && payload && payload.length > 0) {
      formController?.setFields([
        {
          name: "payload",
          value: payload.map((item) => ({ ...item, type: "string" })),
        },
      ]);
    } else if (payload) {
      formController?.setFields([
        {
          name: "payload",
          value: payload.map((item) => ({ key: item.key, value: item.value })),
        },
      ]);
    }
    setMethod(method);
  };

  const handleAuthChange = (type: AuthType) => {
    if (type === AuthType.None) {
      formController?.setFields([
        {
          name: "auth",
          value: null,
        },
      ]);
    }

    if (type === AuthType.ApiKey) {
      formController?.setFields([
        {
          name: ["auth", "key"],
          value: "Authorization",
        },
        {
          name: ["auth", "addTo"],
          value: "header",
        },
      ]);
    }

    if (type === AuthType.BasicAuth) {
      formController?.setFields([
        {
          name: ["auth", "username"],
          value: "",
        },
        {
          name: ["auth", "password"],
          value: "",
        },
      ]);
    }
    setAuthType(type);
  };

  const renderAuthType = () => {
    if (!authType && authType === AuthType.None) {
      return null;
    }
    if (authType === AuthType.ApiKey) {
      return (
        <>
          <Form.Item
            name={["auth", "key"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
            label={t<string>("productAutomation.form.apiKeyHeaderName")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["auth", "value"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
            label={t<string>("productAutomation.form.apiKey")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["auth", "addTo"]}
            label={t<string>("productAutomation.form.addTo")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <Select options={addToOptions} defaultActiveFirstOption />
          </Form.Item>
        </>
      );
    }

    if (authType === AuthType.BearerToken) {
      return (
        <Form.Item
          name={["auth", "token"]}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
          label={t<string>("productAutomation.form.token")}
        >
          <Input />
        </Form.Item>
      );
    }

    if (authType === AuthType.BasicAuth) {
      return (
        <>
          <Form.Item
            name={["auth", "username"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
            label={t<string>("productAutomation.form.username")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["auth", "password"]}
            rules={[
              {
                required: false,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
            label={t<string>("productAutomation.form.password")}
          >
            <Input.Password />
          </Form.Item>
        </>
      );
    }
  };

  return (
    <div>
      <Form<WebhookFormModel>
        name="free-access-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        form={formController}
        initialValues={{
          active: true,
          url: "",
          description: "",
          authType: AuthType.None,
          auth: null,
          method: "get",
          triggers: [],
          payload: [],
          payloadAsParams: false,
          ...model,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="url"
          label={t<string>("productAutomation.form.url")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
            {
              type: "url",
              message: t<string>("common:validationErrors.wrongUrl"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="description" label={t<string>("productAutomation.form.description")}>
          <Input.TextArea rows={3} placeholder={t<string>("productAutomation.form.descriptionPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="method"
          label={t<string>("productAutomation.form.method")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select onSelect={handleMethodChange} options={methodOptions} defaultActiveFirstOption />
        </Form.Item>

        <Form.Item
          name="triggers"
          label={t<string>("productAutomation.form.triggers")}
          tooltip={t<string>("productAutomation.form.triggersPlaceholder")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select mode="multiple" options={createTriggerOptions(t, productDetails?.type, productDetails?.chargeType)} />
        </Form.Item>

        <Form.Item
          name="authType"
          label={t<string>("productAutomation.form.authType")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select<AuthType>
            onSelect={(type: AuthType) => handleAuthChange(type)}
            options={authOptions}
            defaultActiveFirstOption
          />
        </Form.Item>

        {renderAuthType()}

        <Form.Item name="active" valuePropName="checked" label={t<string>("productAutomation.form.active")}>
          <Switch />
        </Form.Item>
        {selectedMethod === "post" && (
          <Form.Item
            name="payloadAsParams"
            valuePropName="checked"
            label={t<string>("productAutomation.form.payloadAsParams")}
          >
            <Switch />
          </Form.Item>
        )}
        <Form.Item
          label={selectedMethod === "get" ? t("productAutomation.form.params") : t("productAutomation.form.body")}
          tooltip={
            selectedMethod === "get"
              ? t("productAutomation.form.paramsTooltip")
              : t("productAutomation.form.bodyTooltip")
          }
        >
          <Form.List name="payload">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ marginBottom: 8, width: "100%" }}>
                    <Space style={{ width: "100%" }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "key"]}
                        style={{ flex: 1, width: 160, marginBottom: 5 }}
                        rules={[
                          {
                            required: true,
                            message: t<string>("common:validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={
                            selectedMethod === "get"
                              ? t<string>("productAutomation.form.paramsPlaceholder")
                              : t<string>("productAutomation.form.bodyPlaceholder")
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        style={{ flex: 1, width: 265, marginBottom: 5 }}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: t<string>("common:validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <AutoComplete
                          options={paramFieldOptions.map((option) => ({
                            label: t(`productAutomation.form.paramsOptions.${option}`),
                            value: option,
                          }))}
                          placeholder={t("productAutomation.form.valuePlaceholder")}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    {selectedMethod === "post" && (
                      <Form.Item
                        {...restField}
                        label={t("productAutomation.form.type")}
                        style={{ flex: 1, width: "100%" }}
                        name={[name, "type"]}
                        rules={[
                          {
                            required: true,
                            message: t<string>("common:validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <Radio.Group defaultValue="string">
                          <Radio value="string">{t("productAutomation.form.string")}</Radio>
                          <Radio value="number">{t("productAutomation.form.number")}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        key: "",
                        value: "",
                        ...(selectedMethod === "post" ? { type: "string" } : {}),
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    {selectedMethod === "get"
                      ? t("productAutomation.form.addParam")
                      : t("productAutomation.form.addField")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WebhookForm;
