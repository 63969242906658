import { type AppThunk } from "../../../index";
import { unsubscribeFromProducts as action } from "../reducer";

export const unsubscribeFromProducts =
  (): AppThunk =>
  async (dispatch, _, { subscriptions, db }) => {
    if (subscriptions.realtimeProductsListChannel) {
      await db.removeChannel(subscriptions.realtimeProductsListChannel);
      subscriptions.productsListChannelName = null;
    }
    dispatch(action());
  };
