import { createAsyncThunk } from "@reduxjs/toolkit";

import { type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  days: MealsPlanDetails["weeks"][number]["days"];
  id: string;
};

export const updatedMealsPlanDays = createAsyncThunk<MealsPlanDetails, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/updatedMealsPlanDays`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("meals_plan_details")
      .update({
        weeks: [
          {
            weekNumber: 1,
            days: payload.days,
          },
        ],
        updatedAt: new Date().toISOString(),
      })
      .eq("id", payload.id)
      .select()
      .returns<MealsPlanDetails[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_meals_plan_days");

    return data[0]!;
  },
);
