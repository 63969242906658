import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type ProgramWorkoutWithMetadata } from "@fitness-app/data-models/domain/services/WorkoutService";
import {
  ActivityEventType,
  type WorkoutActivityEvent,
  type WorkoutActivityEventWithFeedback,
} from "@fitness-app/data-models/entities/ProgramActivity";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { addActivity } from "../../traineeActivities/reducer";
import { LIVE_WORKOUT_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  workout: ProgramWorkoutWithMetadata;
  eventDate: string;
};

export const fetchSelectedActivity = createAsyncThunk<WorkoutActivityEvent, Payload, AsyncThunkCreator<string>>(
  `${LIVE_WORKOUT_REDUCER_NAME}/fetchSelectedActivity`,
  async (payload, { rejectWithValue, getState, dispatch, extra: { db, auth } }) => {
    const { currentWorkout, editedDocId } = getState().traineeActivities.data;
    const { selectedProgram } = getState().traineeProgram;

    const user = await getLoggedUser(auth);

    if (editedDocId) {
      const { error, data } = await db
        .from("client_workout_activity")
        .select(
          "*, workoutRate:feedbackId(data->activityId, data->workoutId, data->rate, data->comment, data->trainingStatus, data->programId)",
        )
        .order("createdAt", { ascending: false })
        .eq("traineeId", payload.traineeId)
        .eq("id", editedDocId)
        .maybeSingle<WorkoutActivityEventWithFeedback>();

      if (error || !data) {
        return rejectWithValue(error?.message || "Activity not found");
      }

      return data;
    }

    const date = dayjs(payload.eventDate, "YYYY-MM-DD").hour(12);

    let event: WorkoutActivityEvent = {
      id: generateUniqId(),
      type: ActivityEventType.Workout,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      eventDate: date.format("YYYY-MM-DD"),
      eventMonth: date.format("YYYY-MM"),
      eventWeek: `${date.format("YYYY")}-${date.isoWeek()}`,
      programId: selectedProgram && date.isSameOrAfter(selectedProgram.startDate, "day") ? selectedProgram.id : null,
      traineeId: payload.traineeId,
      clientEmail: user.email,
      userId: user.id,
      eventTimestamp: date.toISOString(),
      workoutId: currentWorkout?.id || payload.workout.id,
      data: {
        ...(currentWorkout || payload.workout),
        replicatedFromSchedule: true,
        updatedAt: new Date().toISOString(),
      },
      feedbackId: null,
    };

    const { error, data } = await db
      .from("client_workout_activity")
      .insert(event)
      .select("*")
      .single<WorkoutActivityEvent>();

    if (data) {
      event = data;
    }

    if (error) {
      return rejectWithValue(error.message);
    }

    dispatch(addActivity(event));

    return event;
  },
);
