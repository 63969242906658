import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ChatBot } from "@fitness-app/data-models/entities/ChatBot";

import { type AsyncThunkCreator } from "../../../index";
import { BOT_CONFIG_REDUCER_NAME } from "../types";

type Payload = {
  bot: Partial<ChatBot>;
  id: string;
};

export const updateBotConfig = createAsyncThunk<ChatBot, Payload, AsyncThunkCreator<string>>(
  `${BOT_CONFIG_REDUCER_NAME}/updateBotConfig`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db
      .from("chat_bot")
      .update(payload.bot)
      .eq("id", payload.id)
      .select("*")
      .single<ChatBot>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("update_bot_config");

    return data;
  },
);
