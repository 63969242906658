import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import pl from "antd/lib/locale/pl_PL";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./i18nextConfig";

import App from "./App";

import "./index.css";

import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import { Provider } from "react-redux";

import { store } from "./store/initializeStore";

import("dayjs/locale/pl");
dayjs.locale("pl");

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <ConfigProvider
      locale={pl}
      theme={{
        token: {
          colorPrimary: "#978365",
          fontFamily: "Inter",
        },
      }}
    >
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  </>,
);
