import { type MessageType } from "../../../entities/Chat";
import { type Meal } from "../../../entities/MealsPlan";
import {
  type BodyMeasurementData,
  type ClientPhoto,
  type HydrationMeasurementData,
  type RejectedTrainingFeedbackData,
  type TrainingFeedbackData,
  type WeightMeasurementData,
} from "../../../entities/Measurement";
import { type TrainingStatus } from "../../../entities/TrainingProgram";
import { type GenericBusinessEventDto } from "../../BusinessEventDto";
import { type GenericBusinessEvent } from "../BusinessEventEnvelope";

export enum TraineeActivityEventType {
  TraineeAddedBodyPhotos = "fitness-app.trainee-activity.trainee-added-body-photos",
  TraineeAddedBodyMeasurements = "fitness-app.trainee-activity.trainee-added-body-measurements",
  TraineeAddedWeightMeasurement = "fitness-app.trainee-activity.trainee-added-weight-measurement",
  TraineeTrackedMeal = "fitness-app.trainee.trainee-activity-tracked-meal",
  TraineeTrackedWaterConsumption = "fitness-app.trainee-activity.trainee-tracked-water-consumption",
  TraineeTrackedWorkout = "fitness-app.trainee-activity.trainee-tracked-workout",
  TraineeAddedWorkoutRating = "fitness-app.trainee-activity.trainee-added-workout-rating",
  TraineeAddedWorkoutComment = "fitness-app.trainee-activity.trainee-added-workout-comment",
  TraineeSentChatMessage = "fitness-app.trainee-activity.trainee-sent-chat-message",
  TraineeSignedInToApp = "fitness-app.trainee-activity.trainee-signed-in-to-app",
  TraineeSentSurveyAnswers = "fitness-app.trainee-activity.trainee-sent-survey-answers",
  TraineeActivityStateChanged = "fitness-app.trainee-activity.trainee-active-state-changed",
  TraineeSentExerciseRecording = "fitness-app.trainee-activity.trainee-sent-exercise-recording",
}

interface Trainee<UUID = string> {
  email: string;
  uid: UUID | null;
  id: string;
}

interface Trainer {
  uid: string;
  assignedTeamTrainer: null | string;
  assignedTeamTrainerUid?: null | string;
}

type BasePayload = {
  client: Trainee;
  trainer: Trainer;
  resourceId: string | null;
  date: number;
};

export type TraineeAddedBodyPhotosEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeAddedBodyPhotos,
  BasePayload & {
    data: ClientPhoto["data"];
  }
>;

export type TraineeAddedBodyPhotosEvent = GenericBusinessEvent<
  TraineeAddedBodyPhotosEventDto["eventType"],
  TraineeAddedBodyPhotosEventDto["payload"]
>;

export type TraineeAddedBodyMeasurementsEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeAddedBodyMeasurements,
  BasePayload & {
    data: BodyMeasurementData;
  }
>;

export type TraineeAddedBodyMeasurementsEvent = GenericBusinessEvent<
  TraineeAddedBodyMeasurementsEventDto["eventType"],
  TraineeAddedBodyMeasurementsEventDto["payload"]
>;

export type TraineeAddedWeighMeasurementEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeAddedWeightMeasurement,
  BasePayload & {
    data: WeightMeasurementData;
  }
>;

export type TraineeAddedWeighMeasurementEvent = GenericBusinessEvent<
  TraineeAddedWeighMeasurementEventDto["eventType"],
  TraineeAddedWeighMeasurementEventDto["payload"]
>;

export type TraineeTrackedMealEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeTrackedMeal,
  BasePayload & {
    data: Meal;
  }
>;

export type TraineeTrackedMealEvent = GenericBusinessEvent<
  TraineeTrackedMealEventDto["eventType"],
  TraineeTrackedMealEventDto["payload"]
>;

export type TraineeTrackedWaterConsumptionEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeTrackedWaterConsumption,
  BasePayload & {
    data: HydrationMeasurementData;
  }
>;

export type TraineeTrackedWaterConsumptionEvent = GenericBusinessEvent<
  TraineeTrackedWaterConsumptionEventDto["eventType"],
  TraineeTrackedWaterConsumptionEventDto["payload"]
>;

export type TraineeTrackedWorkoutEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeTrackedWorkout,
  BasePayload & {
    status: TrainingStatus;
  }
>;

export type TraineeTrackedWorkoutEvent = GenericBusinessEvent<
  TraineeTrackedWorkoutEventDto["eventType"],
  TraineeTrackedWorkoutEventDto["payload"]
>;

export type TraineeAddedWorkoutRatingEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeAddedWorkoutRating,
  BasePayload & {
    data: TrainingFeedbackData | RejectedTrainingFeedbackData;
  }
>;

export type TraineeAddedWorkoutRatingEvent = GenericBusinessEvent<
  TraineeAddedWorkoutRatingEventDto["eventType"],
  TraineeAddedWorkoutRatingEventDto["payload"]
>;

export type TraineeAddedWorkoutCommentEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeAddedWorkoutComment,
  BasePayload & {
    comment: string;
    exerciseId: string;
    exerciseName: string;
  }
>;

export type TraineeAddedWorkoutCommentEvent = GenericBusinessEvent<
  TraineeAddedWorkoutCommentEventDto["eventType"],
  TraineeAddedWorkoutCommentEventDto["payload"]
>;

export type TraineeSentChatMessageEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeSentChatMessage,
  BasePayload & {
    type: MessageType;
    filesNumber: number;
    length: number;
    isAudioMessage?: boolean;
  }
>;

export type TraineeSentChatMessageEvent = GenericBusinessEvent<
  TraineeSentChatMessageEventDto["eventType"],
  TraineeSentChatMessageEventDto["payload"]
>;

export type TraineeSignedInAppEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeSignedInToApp,
  BasePayload & {
    appVersion: string | null;
  }
>;

export type TraineeSignedInAppEvent = GenericBusinessEvent<
  TraineeSignedInAppEventDto["eventType"],
  TraineeSignedInAppEventDto["payload"]
>;

export type TraineeActivityStateChangedEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeActivityStateChanged,
  BasePayload & {
    appVersion: string | null;
  }
>;

export type TraineeActivityStateChangedEvent = GenericBusinessEvent<
  TraineeActivityStateChangedEventDto["eventType"],
  TraineeActivityStateChangedEventDto["payload"]
>;

export type TraineeSentSurveyAnswersEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeSentSurveyAnswers,
  BasePayload & {
    numberOfAnswers: number;
    surveyName: string;
    hasAutomation: boolean;
  }
>;

export type TraineeSentSurveyAnswersEvent = GenericBusinessEvent<
  TraineeSentSurveyAnswersEventDto["eventType"],
  TraineeSentSurveyAnswersEventDto["payload"]
>;

export type TraineeSentExerciseRecordingEventDto = GenericBusinessEventDto<
  TraineeActivityEventType.TraineeSentExerciseRecording,
  BasePayload & {
    exerciseName: string;
    parentExerciseId: string;
    exerciseId: string | null;
    workoutId: string | null;
    programId: string | null;
    workoutDate: string;
  }
>;

export type TraineeSentExerciseRecordingEvent = GenericBusinessEvent<
  TraineeSentExerciseRecordingEventDto["eventType"],
  TraineeSentExerciseRecordingEventDto["payload"]
>;

export type TraineeActivityEvents =
  | TraineeSignedInAppEvent
  | TraineeSentChatMessageEvent
  | TraineeAddedWorkoutCommentEvent
  | TraineeAddedWorkoutRatingEvent
  | TraineeTrackedWorkoutEvent
  | TraineeTrackedWaterConsumptionEvent
  | TraineeTrackedMealEvent
  | TraineeAddedWeighMeasurementEvent
  | TraineeAddedBodyMeasurementsEvent
  | TraineeAddedBodyPhotosEvent
  | TraineeSentSurveyAnswersEvent
  | TraineeActivityStateChangedEvent
  | TraineeSentExerciseRecordingEvent;

export type TraineeActivityEventsDto =
  | TraineeSentChatMessageEventDto
  | TraineeAddedWorkoutCommentEventDto
  | TraineeAddedWorkoutRatingEventDto
  | TraineeTrackedWorkoutEventDto
  | TraineeTrackedWaterConsumptionEventDto
  | TraineeTrackedMealEventDto
  | TraineeAddedWeighMeasurementEventDto
  | TraineeAddedBodyMeasurementsEventDto
  | TraineeAddedBodyPhotosEventDto
  | TraineeSentSurveyAnswersEventDto
  | TraineeSignedInAppEventDto
  | TraineeActivityStateChangedEventDto
  | TraineeSentExerciseRecordingEventDto;

export type TraineeActivityEventsPayload =
  | TraineeAddedWorkoutCommentEventDto["payload"]
  | TraineeAddedWorkoutRatingEventDto["payload"]
  | TraineeTrackedWorkoutEventDto["payload"]
  | TraineeTrackedWaterConsumptionEventDto["payload"]
  | TraineeTrackedMealEventDto["payload"]
  | TraineeAddedWeighMeasurementEventDto["payload"]
  | TraineeAddedBodyMeasurementsEventDto["payload"]
  | TraineeAddedBodyPhotosEventDto["payload"]
  | TraineeSentChatMessageEventDto["payload"]
  | TraineeSignedInAppEventDto["payload"]
  | TraineeSentSurveyAnswersEventDto["payload"]
  | TraineeActivityStateChangedEventDto["payload"]
  | TraineeSentExerciseRecordingEventDto["payload"];
