import React, { useEffect, useMemo, useRef, type FunctionComponent } from "react";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { Empty, List } from "antd";
import { useTranslation } from "react-i18next";

import { chatActions, RequestStatus } from "@fitness-app/app-store";
import { type ChatStatus } from "@fitness-app/data-models/entities/Chat";

import ChatChannelItem from "~/modules/Chat/ChatChannelItem";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  changeTab: (status: ChatStatus) => void;
}

type Props = OwnProps;

const ChatChannelsList: FunctionComponent<Props> = ({ changeTab }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["chat", "dashboard"]);
  const documentVisible = useVisibilityChange();
  const channels = useAppSelector((store) => store.chat.unreadChannels);
  const subscriptionStatus = useAppSelector((store) => store.chat.status);
  const fetchingStatus = useAppSelector((store) => store.chat.unreadChannelsStatus);
  const prevSubscriptionStatus = useRef<RequestStatus | null>(null);
  const unreadPerChannel = useAppSelector((store) => store.chat.unreadMessages?._perChannel || {});

  const chatsIds = useMemo(
    () => Object.keys(unreadPerChannel).filter((id) => unreadPerChannel[id] && (unreadPerChannel[id]?.length ?? 0) > 0),
    [unreadPerChannel],
  );

  useEffect(() => {
    if (
      documentVisible &&
      subscriptionStatus === RequestStatus.SUBSCRIBED &&
      prevSubscriptionStatus.current === RequestStatus.SUBSCRIBED
    ) {
      void dispatch(chatActions.fetchChatListPageWithUnread(chatsIds));
    }

    prevSubscriptionStatus.current = subscriptionStatus;
  }, [documentVisible, subscriptionStatus, dispatch, chatsIds]);

  useEffect(() => {
    void dispatch(chatActions.fetchChatListPageWithUnread(chatsIds));
  }, [chatsIds, dispatch]);

  return (
    <List
      itemLayout="horizontal"
      dataSource={channels}
      loading={fetchingStatus === RequestStatus.FETCHING && subscriptionStatus !== RequestStatus.SUBSCRIBED}
      renderItem={(channel) => <ChatChannelItem channel={channel} changeTab={changeTab} archived={false} />}
      locale={{
        emptyText: <Empty className="mt-12" description={t("dashboard:chatDrawer.emptyChannelList")} />,
      }}
      style={{ minHeight: 500 }}
      className="max-h-full overflow-y-auto px-4"
    />
  );
};

export default ChatChannelsList;
