import dayjs, { type Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";

import {
  ClientNutritionStatus,
  type ClientNutrition,
  type ClientNutritionTemplate,
} from "@fitness-app/data-models/entities/ClientNutrition";
import { type MealTypeEnum } from "@fitness-app/data-models/entities/Dish";
import {
  MEALS_SCHEMA,
  MealsPerDay,
  TargetGender,
  type ActivityLevel,
  type DietaryPreference,
  type DishInMeal,
  type MacroSplit,
  type MealsPlan,
  type MealsPlanDetails,
} from "@fitness-app/data-models/entities/MealsPlan";

import { generateUniqId } from "../helpers/generateUniqId";

export type MealsPlanRequestDto = {
  prompt?: string;
  dietaryPreference: DietaryPreference;
  mealsSchema: MealsPerDay;
  macroSplit: MacroSplit;
  name: string;
  targetCalories: number;
  targets: {
    carbs: number;
    fat: number;
    protein: number;
    gender?: TargetGender;
  };
  tags?: string[];
  comment?: string;
};

export type DishExchangeRequestDto = {
  prompt?: string;
  mealType: MealTypeEnum;
  dish: DishInMeal;
  nutrition: ClientNutrition | MealsPlan;
};

export const generateUpdatedMealsPlanSchema = (
  mealsPlanDetails: MealsPlanDetails,
  mealsSchema: MealsPerDay,
): Partial<MealsPlanDetails> & {
  days: MealsPlanDetails["weeks"][number]["days"];
  id: string;
} => {
  return {
    id: mealsPlanDetails.id,
    updatedAt: new Date().toISOString(),
    days:
      mealsPlanDetails.weeks[0]?.days.map((day) => ({
        ...day,
        meals: MEALS_SCHEMA[mealsSchema || MealsPerDay.THREE].map((meal) => {
          const alreadyExists = day.meals.find((m) => m.type === meal.type);
          if (alreadyExists) {
            return alreadyExists;
          }
          return {
            ...meal,
            id: generateUniqId(),
          };
        }),
      })) || [],
  };
};

export interface AddMealPlanModel {
  name: string;
  dietaryPreference: DietaryPreference;
  macroSplit: MacroSplit;
  targetCalories: number;
  mealsSchema: MealsPerDay;
  tags: string[];
  comment: string;
  targets: {
    gender: TargetGender;
    carbs?: number;
    fat?: number;
    protein?: number;
    macroUnit?: "percentage" | "grams";
    activityLevel?: ActivityLevel | null;
  };
  startAt?: Dayjs;
}

export interface NutrientsTargets {
  protein: number;
  carbs: number;
  fat: number;
}

export const transformAddMealPlanModelToMealsPlan = (
  model: AddMealPlanModel | MealsPlanRequestDto,
  nutrients: NutrientsTargets,
  userId?: string,
  isMainTrainer?: boolean,
): MealsPlan => {
  return {
    id: uuidv4(),
    name: model.name,
    createdBy: userId || null,
    tags: model.tags || [],
    shared: isMainTrainer ?? false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    metadata: {},
    dietaryPreference: model.dietaryPreference,
    macroSplit: model.macroSplit,
    comment: model.comment || "",
    targetCalories: model.targetCalories,
    targets: {
      protein: nutrients.protein,
      carbs: nutrients.carbs,
      fat: nutrients.fat,
      macroUnit: "percentage",
      gender: model.targets?.gender || TargetGender.UNISEX,
      activityLevel: null,
    },
    attachments: [],
    mealsSchema: model.mealsSchema,
  };
};

export const transformUpdatedMealPlanModelToMealsPlan = (
  savedMealPlan: MealsPlan | ClientNutrition,
  model: AddMealPlanModel,
  nutrients: NutrientsTargets,
): Partial<MealsPlan> & { id: string } => {
  return {
    id: savedMealPlan.id,
    ...model,
    ...("startAt" in savedMealPlan && savedMealPlan.startAt
      ? { startAt: dayjs(model.startAt).format("YYYY-MM-DD") }
      : {}),
    updatedAt: new Date().toISOString(),
    targets: {
      ...savedMealPlan.targets,
      ...model.targets,
      protein: nutrients.protein,
      carbs: nutrients.carbs,
      fat: nutrients.fat,
    },
  };
};

export interface TraineeNutritionFormModel {
  selectedTemplate: string | null;
  name: string;
  dietaryPreference: DietaryPreference;
  macroSplit: MacroSplit;
  targetCalories: number;
  mealsSchema: MealsPerDay;
  comment: string;
  tags: string[];
  targets: {
    gender: TargetGender;
    carbs?: number;
    fat?: number;
    protein?: number;
    macroUnit?: "percentage" | "grams";
    activityLevel?: ActivityLevel | null;
  };
  copySupplementsPlan: boolean;
  startAt?: Dayjs;
  hasActiveNutrition?: boolean;
}

export interface TraineeNutritionFormModel {
  selectedTemplate: string | null;
  name: string;
  dietaryPreference: DietaryPreference;
  macroSplit: MacroSplit;
  targetCalories: number;
  mealsSchema: MealsPerDay;
  comment: string;
  tags: string[];
  targets: {
    gender: TargetGender;
    carbs?: number;
    fat?: number;
    protein?: number;
    macroUnit?: "percentage" | "grams";
    activityLevel?: ActivityLevel | null;
  };
  copySupplementsPlan: boolean;
  startAt?: Dayjs;
  hasActiveNutrition?: boolean;
}

export interface TraineeNutritionGeneratorFormModel {
  name: string;
  prompt: string;
  dietaryPreference: DietaryPreference;
  macroSplit: MacroSplit;
  targetCalories: number;
  mealsSchema: MealsPerDay;
  comment: string;
  targets: {
    gender: TargetGender;
    carbs?: number;
    fat?: number;
    protein?: number;
    macroUnit?: "percentage" | "grams";
    activityLevel?: ActivityLevel | null;
  };
  copySupplementsPlan: boolean;
  tags?: never;
}

export function transformAddMealPlanModelToTraineeNutrition(props: {
  model: AddMealPlanModel | TraineeNutritionFormModel | TraineeNutritionGeneratorFormModel | MealsPlanRequestDto;
  nutrients: NutrientsTargets;
  userId?: string;
  traineeId: string;
  metadata?: Record<string, string>;
  status?: ClientNutritionStatus.Scheduled | ClientNutritionStatus.InProgress | ClientNutritionStatus.Archived;
}): ClientNutrition;

export function transformAddMealPlanModelToTraineeNutrition(props: {
  model: AddMealPlanModel | TraineeNutritionFormModel | TraineeNutritionGeneratorFormModel | MealsPlanRequestDto;
  nutrients: NutrientsTargets;
  userId?: string;
  traineeId: string;
  metadata?: Record<string, string>;
  status: ClientNutritionStatus.Template;
}): ClientNutritionTemplate;

export function transformAddMealPlanModelToTraineeNutrition({
  model,
  nutrients,
  userId,
  traineeId,
  metadata,
  status,
}: {
  model: AddMealPlanModel | TraineeNutritionFormModel | TraineeNutritionGeneratorFormModel | MealsPlanRequestDto;
  nutrients: NutrientsTargets;
  userId?: string;
  traineeId: string;
  metadata?: Record<string, string>;
  status?: ClientNutritionStatus;
}): ClientNutrition | ClientNutritionTemplate {
  return {
    id: generateUniqId(),
    traineeId,
    name: model.name,
    addedBy: userId || null,
    ...(status === ClientNutritionStatus.Template
      ? {
          startAt: null,
          status: ClientNutritionStatus.Template,
        }
      : {
          status:
            "startAt" in model && model.startAt ? ClientNutritionStatus.Scheduled : ClientNutritionStatus.InProgress,
          startAt:
            "startAt" in model && model.startAt
              ? model.startAt.startOf("day").format("YYYY-MM-DD")
              : dayjs().startOf("day").format("YYYY-MM-DD"),
        }),
    endAt: null,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    dietaryPreference: model.dietaryPreference,
    macroSplit: model.macroSplit,
    comment: model.comment || "",
    tags: model.tags || [],
    targetCalories: model.targetCalories,
    templateId: "selectedTemplate" in model ? model.selectedTemplate || null : null,
    targets: {
      protein: nutrients.protein,
      carbs: nutrients.carbs,
      fat: nutrients.fat,
      macroUnit: "percentage",
      gender: null,
      activityLevel: null,
    },
    attachments: [],
    mealsSchema: model.mealsSchema,
    lastNotificationSentAt: null,
    metadata: metadata || null,
  };
}
