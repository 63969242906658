import React, { Fragment, useMemo, type FunctionComponent, type RefObject } from "react";
import { Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { type ChannelStatus } from "@fitness-app/app-store/src/store/reducers/chat/types";
import { type ChannelWithMessages, type ChatChannelWithMeta } from "@fitness-app/data-models/entities/Chat";
import { hasTheSameDateString } from "@fitness-app/utils/src/chat/hasTheSameDateString";

import ChatMessage from "~/modules/Chat/components/ChatMessagesList/ChatMessage";
import { useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  messages: ChannelWithMessages;
  channelStatus: ChannelStatus;
  channel: ChatChannelWithMeta;
  unreadRef: RefObject<HTMLDivElement>;
  firstNewMessage: string;
}

type Props = OwnProps;

const ChatMessagesList: FunctionComponent<Props> = ({
  messages,
  firstNewMessage,
  channel,
  channelStatus,
  unreadRef,
}) => {
  const { t } = useTranslation("chat");
  const userId = useAppSelector((store) => store.user.data?.id);

  const authorsById = useMemo(() => {
    return Object.fromEntries(
      Object.values(channel.members).map((member) => {
        const profile = channel.profiles.find((profile) => profile.id === member.uid);
        return [member.uid, profile ? { ...member, ...profile } : member];
      }),
    );
  }, [channel]);

  return (
    <div className="w-full">
      {channelStatus?.fetchingNextPage ? (
        <Spin size="small" className="min-w-12 flex justify-center" />
      ) : (
        <p className="pt-4 text-center text-sm text-gray-700">{t("channel.beginningOfConversation")}</p>
      )}
      {messages.groupedByDay.map((group) => (
        <div key={group.date} className="relative mb-4">
          <Divider>{group.date}</Divider>
          {group.messages.map((message, i) => {
            const hasUnreadMessages = firstNewMessage === message.id;
            const shouldRenderMetaTag =
              i < 1 ||
              !(
                hasTheSameDateString(message.createdAt, group.messages[i - 1]?.createdAt) &&
                message.authorId === group.messages[i - 1]?.authorId
              );
            const incomingMessage = userId !== message.authorId;
            const authorData = authorsById[message.authorId];
            return (
              <Fragment key={message.id}>
                {hasUnreadMessages && (
                  <div ref={unreadRef}>
                    <Divider dashed orientation="right">
                      <span>Nowe wiadomości</span>
                    </Divider>
                  </div>
                )}
                <ChatMessage
                  incomingMessage={incomingMessage}
                  authorData={authorData}
                  channelId={channel.id}
                  message={message}
                  shouldRenderMetaTag={shouldRenderMetaTag}
                  authorsById={authorsById}
                />
              </Fragment>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ChatMessagesList;
