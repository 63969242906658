import { createAsyncThunk } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import { type AutomationTask } from "@fitness-app/data-models/entities/Automation";
import { getErrorMessage } from "@fitness-app/utils";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_AUTOMATION_REDUCER_NAME } from "../types";

type Payload = {
  productId: string;
  task: AutomationTask;
  action: "add" | "update" | "remove";
};

export const updateProductAutomationTask = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<string>>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationTask`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db }, getState }) => {
    try {
      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error("product automation data not exists");
      }

      let tasksToUpdate = cloneDeep(data.tasks) ?? [];

      if (payload.action === "remove") {
        tasksToUpdate = tasksToUpdate.filter((task) => task.id !== payload.task.id);
      }

      if (payload.action === "update") {
        tasksToUpdate = data.tasks?.map((savedTask) =>
          savedTask.id === payload.task.id ? payload.task : savedTask,
        ) ?? [payload.task];
      }

      if (payload.action === "add") {
        tasksToUpdate = [...(data?.tasks || []), payload.task];
      }

      await db
        .from("product_automation")
        .update({
          tasks: tasksToUpdate,
          updatedAt: new Date().toISOString(),
        })
        .eq("productId", payload.productId);

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(getErrorMessage(e));
    }
  },
);
