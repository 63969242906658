import React, { type FunctionComponent } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";

import { type ActiveCampaignIntegrationFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<ActiveCampaignIntegrationFormModel>;
  onSubmit: (formData: ActiveCampaignIntegrationFormModel) => void;
  model?: ActiveCampaignIntegrationFormModel | null;
}

type Props = OwnProps;

const ActiveCampaignIntegrationForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["settings", "common"]);
  return (
    <div className="min-w-[450px]">
      <Form<ActiveCampaignIntegrationFormModel>
        name="active-campaign-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        initialValues={{ url: model?.url ?? "" }}
        size="large"
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="url"
          label={t("activeCampaignForm.url")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus={!model} disabled={!!model?.url} />
        </Form.Item>

        <Form.Item
          name="apiKey"
          tooltip={t("activeCampaignForm.tooltip")}
          label={t("activeCampaignForm.apiKey")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus={!!model} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ActiveCampaignIntegrationForm;
