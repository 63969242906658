import React, { useEffect, useMemo } from "react";
import {
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs, { type Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import keyBy from "lodash.keyby";
import uniq from "lodash.uniq";
import { useTranslation } from "react-i18next";

import { RequestStatus, surveysTemplatesActions } from "@fitness-app/app-store";
import { CycleSurveyType, SurveyIntervalUnit, SurveyType } from "@fitness-app/data-models/entities/ClientSurvey";
import { type SurveyTemplate, type SurveyTemplateWithCreator } from "@fitness-app/data-models/entities/SurveyTemplate";
import { createScheduleOptions } from "@fitness-app/utils/src/surveys/createInitialSurvey";

import {
  createCycleTypeOptions,
  createIntervalOptions,
  createSurveyTypeOptions,
} from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/AddSurveyForm/survey.data";
import { useDefaultVariables } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/useDefaultVariables";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { type AddSurveyFormModel } from "./types";

dayjs.extend(utc);

interface AddSurveyFormProps {
  formController?: FormInstance<AddSurveyFormModel>;
  onSubmit: (formData: AddSurveyFormModel, template: SurveyTemplate) => void;
  model?: Partial<AddSurveyFormModel> | null;
}

const createSurveysOptions = (collection: SurveyTemplateWithCreator[]) =>
  collection.map((value) => ({
    label: value.title,
    value: value.id,
  }));
export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 23];

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AddSurveyForm = ({ model, onSubmit, formController }: AddSurveyFormProps) => {
  const { t } = useTranslation(["surveys", "common"]);
  const dispatch = useAppDispatch();
  const { list, listStatus } = useAppSelector((store) => store.surveysTemplates);
  const templatedId = Form.useWatch("templateId", formController);
  const surveyType = Form.useWatch("type", formController) as SurveyType;
  const sendImmediately = Form.useWatch("sendImmediately", formController);
  const cycleType: CycleSurveyType | undefined = Form.useWatch("cycleType", formController);

  const listById = useMemo(() => keyBy(list, "id"), [list]);

  const variables = useMemo(() => {
    const automation = listById[templatedId]?.automation;

    if (!templatedId || !automation) {
      return null;
    }

    return uniq(
      Object.values(automation)
        .map((automations) => automations.map((automation) => automation.tasks.map((task) => task.variables)).flat(2))
        .flat(2),
    )
      .filter((variable): variable is string => typeof variable === "string")
      .sort();
  }, [templatedId, listById]);

  useDefaultVariables(variables, formController!);

  useEffect(() => {
    void dispatch(surveysTemplatesActions.fetchSurveysTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  useEffect(() => {
    if (cycleType === CycleSurveyType.CycleNumber) {
      formController?.setFieldsValue({ cycleTypeNumber: 2 });
    }
    if (cycleType === CycleSurveyType.UntilDate) {
      formController?.setFieldsValue({ untilDate: dayjs().add(1, "week") });
    }
  }, [cycleType]);

  useEffect(() => {
    if (surveyType === SurveyType.Recurring) {
      formController?.setFieldsValue({ cycleType: CycleSurveyType.Infinite });
    }
  }, [surveyType]);

  useEffect(() => {
    if (templatedId) {
      const foundTemplate = list.find((template) => template.id === templatedId);

      if (foundTemplate) {
        formController?.setFieldsValue({ title: foundTemplate.title, description: foundTemplate.description || "" });
      }
    }
  }, [templatedId]);

  const validatePickerTime = (sentDate: Dayjs | null) => {
    if (sentDate?.isSame(undefined, "day")) {
      const currentHour = dayjs().get("hour");
      const disabledHoursForSelectedDay = Array.from(Array(currentHour), (_, i) => i + 1);
      return {
        disabledHours: () => uniq(DISABLED_HOURS.concat(disabledHoursForSelectedDay)),
      };
    }
    return {
      disabledHours: () => DISABLED_HOURS,
    };
  };

  const renderCycleTypeField = () => {
    if (cycleType === CycleSurveyType.Infinite) {
      return null;
    }
    if (cycleType === CycleSurveyType.CycleNumber) {
      return (
        <Form.Item label={t("form.cycleTypeNumber")} name="cycleTypeNumber">
          <InputNumber min={2} max={100} />
        </Form.Item>
      );
    }
    if (cycleType === CycleSurveyType.UntilDate) {
      return (
        <Form.Item name="untilDate" label={t("form.untilDate")}>
          <DatePicker format="DD.MM.YYYY" disabledDate={(current) => current.isBefore(dayjs().add(1, "week"), "day")} />
        </Form.Item>
      );
    }
  };

  const handleSubmit = (formData: AddSurveyFormModel) => {
    const foundTemplate = templatedId ? list.find((template) => template.id === templatedId) : null;

    if (foundTemplate) {
      onSubmit(formData, foundTemplate);
    }
  };

  return (
    <Form<AddSurveyFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        type: SurveyType.OneTime,
        sendImmediately: true,
        count: 1,
        unit: SurveyIntervalUnit.Month,
        editable: false,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="templateId"
        label={t<string>("form.selectSurvey")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Select
          showSearch
          notFoundContent={t("form.notFoundSurvey")}
          options={createSurveysOptions(list)}
          placeholder={t("common:selectPlaceholder")}
          autoFocus
          loading={listStatus === RequestStatus.FETCHING}
        />
      </Form.Item>

      {templatedId ? (
        <>
          <Form.Item
            name="title"
            label={t<string>("form.title")}
            tooltip={t<string>("form.titleVisibleForClient")}
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label={t<string>("form.desc")} tooltip={t<string>("form.descVisibleForClient")}>
            <Input.TextArea rows={2} />
          </Form.Item>

          <Form.Item
            name="editable"
            label={t<string>("form.editable")}
            valuePropName="checked"
            tooltip={t<string>("form.editableDesc")}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="type"
            label={t<string>("form.surveyType")}
            rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
          >
            <Select options={createSurveyTypeOptions(t)} placeholder={t("common:selectPlaceholder")} />
          </Form.Item>

          {surveyType === SurveyType.OneTime ? (
            <>
              <Form.Item name="sendImmediately" label={t<string>("form.isSendRightNow")} valuePropName="checked">
                <Switch />
              </Form.Item>
              {sendImmediately === false && (
                <Form.Item name="sentDate" label={t("form.sentDate")}>
                  <DatePicker
                    disabledTime={validatePickerTime}
                    format="DD.MM.YYYY HH:mm"
                    allowClear={false}
                    showTime={{
                      minuteStep: 15,
                      format: "HH:mm",
                    }}
                    disabledDate={(current) => current.isBefore(undefined, "day")}
                    showToday={false}
                  />
                </Form.Item>
              )}
            </>
          ) : (
            <>
              <Form.Item
                name="dayOfDelivery"
                label={t("form.dayOfDelivery")}
                rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
              >
                <Radio.Group size="small">
                  {createScheduleOptions(t).map((item) => (
                    <Radio.Button key={item.value} value={item.value}>
                      {item.label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="timeOfDelivery"
                label={t("form.timeOfDelivery")}
                rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
              >
                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  placeholder={t<string>("form.timeOfDeliveryPlaceholder")}
                  allowClear={false}
                  disabledTime={() => ({
                    disabledHours: () => DISABLED_HOURS,
                  })}
                />
              </Form.Item>

              <Form.Item label={t("form.surveyInterval")}>
                <Space.Compact>
                  <Form.Item
                    name="count"
                    noStyle
                    rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
                  >
                    <InputNumber min={1} style={{ width: "30%" }} />
                  </Form.Item>

                  <Form.Item
                    name="unit"
                    noStyle
                    rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
                  >
                    <Select
                      style={{ width: "52%" }}
                      placeholder={t("common:selectPlaceholder")}
                      options={createIntervalOptions(t)}
                    />
                  </Form.Item>
                </Space.Compact>
              </Form.Item>

              <Form.Item label={t("form.cycleType")} name="cycleType">
                <Select
                  style={{ width: "60%" }}
                  placeholder={t("common:selectPlaceholder")}
                  options={createCycleTypeOptions(t)}
                />
              </Form.Item>

              {renderCycleTypeField()}
            </>
          )}

          {variables && variables.length > 0 && (
            <div>
              <Divider />

              <Row>
                <Col
                  xs={{
                    span: 24,
                    offset: 0,
                  }}
                  sm={{
                    span: 10,
                    offset: 8,
                  }}
                >
                  <Typography.Title level={5}>Zmienne dla automatyzacji</Typography.Title>
                </Col>
              </Row>

              {variables.map((variableName) => (
                <Form.Item
                  key={variableName}
                  label={variableName}
                  name={["variables", variableName]}
                  rules={[
                    {
                      required: true,
                      message: t<string>("common:validationErrors.fieldIsRequired"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ))}
            </div>
          )}
        </>
      ) : (
        <Empty description="Wybierz szablon ankiety z listy" />
      )}
    </Form>
  );
};

export default AddSurveyForm;
