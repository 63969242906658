import React, { useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Table } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus, traineeMeasurementsActions, traineeMeasurementsSelectors } from "@fitness-app/app-store";
import { BODY_FIELDS } from "@fitness-app/data-models/shared/BodyFields";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeMeasurementsTableProps {
  traineeId: string;
}

const TraineeMeasurementsTable = ({ traineeId }: TraineeMeasurementsTableProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["trainees", "common"]);
  const mergedMeasurements = useAppSelector(traineeMeasurementsSelectors.getMergedMeasurementsByDay);

  const { measurementsStatus, measurementsCurrentPage, measurementsTotalPages, measurements } = useAppSelector(
    (store) => store.traineeMeasurements,
  );

  useEffect(() => {
    void dispatch(traineeMeasurementsActions.fetchBodyAndWeightMeasurements({ traineeId }));
  }, [traineeId]);

  const showFetchMoreButton =
    measurementsCurrentPage < measurementsTotalPages &&
    measurementsTotalPages > 1 &&
    measurementsStatus === RequestStatus.SUCCESS;

  const handleRemoveItem = async (row: { date: string }) => {
    const messageId = "remove";
    const ids = measurements.filter((item) => item.eventDate === row.date).map((item) => item.id);

    void message.loading({ content: t("measurements.removing"), key: messageId, duration: 0 });
    await dispatch(traineeMeasurementsActions.deleteMeasurement({ ids }));

    message.destroy(messageId);
  };

  return (
    <>
      <Table
        rowKey="date"
        scroll={{ x: true }}
        loading={measurementsStatus === RequestStatus.FETCHING}
        dataSource={mergedMeasurements}
        pagination={false}
        locale={{
          emptyText: t("measurements.emptyState"),
        }}
      >
        <Table.Column key="date" dataIndex="date" title={t("measurements.date")} width={120} />
        <Table.Column
          key="weight"
          dataIndex="weight"
          title={t("measurements.weight")}
          render={(value) => value || "-"}
        />

        {BODY_FIELDS.map((bodyPart) => (
          <Table.Column
            key={bodyPart.name}
            dataIndex={bodyPart.name}
            title={`${t(`table.${bodyPart.name}`)} (${bodyPart.unit})`}
            render={(value) => value || "-"}
          />
        ))}

        <Table.Column
          key="action"
          dataIndex="action"
          title="Opcje"
          render={(value, record: { date: string; creator: "trainer" | "client" }) => (
            <span>
              <Popconfirm
                title={t("measurements.confirmDelete")}
                okText={t("common:yes")}
                cancelText={t("common:no")}
                placement="left"
                onConfirm={() => handleRemoveItem(record)}
              >
                <DeleteOutlined style={{ color: "#f5222d" }} />
              </Popconfirm>
            </span>
          )}
        />
      </Table>

      <div className="mt-8 flex items-center justify-center p-2.5">
        {showFetchMoreButton && (
          <Button
            onClick={() =>
              dispatch(
                traineeMeasurementsActions.fetchBodyAndWeightMeasurements({
                  traineeId,
                  page: measurementsCurrentPage + 1,
                }),
              )
            }
            className="ml-2.5"
          >
            {t("measurements.fetchMore")}
          </Button>
        )}
      </div>
    </>
  );
};

export default TraineeMeasurementsTable;
