import React, { useState, type FunctionComponent } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Badge, Button, message, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions } from "@fitness-app/app-store";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useLoading } from "~/hooks/useLoading";
import TPayIntegrationForm from "~/modules/Products/ProductsSettings/PaymentGateways/clients/tpay/TPayIntegrationForm";
import { type TPayIntegrationFormModel } from "~/modules/Products/ProductsSettings/PaymentGateways/clients/tpay/types";
import { useAppDispatch } from "~/store/initializeStore";
import TPayIcon from "./tpay-logo.png";

interface OwnProps {
  tpayClientId?: string;
}

type Props = OwnProps;

const TPayIntegrationPanel: FunctionComponent<Props> = ({ tpayClientId }) => {
  const { t } = useTranslation(["settings", "common", "dashboard"]);
  const [loading, startLoading, stopLoading] = useLoading();
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();

  const handleSubmit = async (data: TPayIntegrationFormModel) => {
    startLoading();
    await dispatch(
      productsSettingsActions.integrateWithTPay({
        payload: { ...data, name: "TPay" },
        onSuccess: () => {
          void message.success(t<string>("dashboard:tPayIntegration.success"));
          toggleModal(false);
        },
        onFailure: (errorCode) => {
          if (errorCode === 403) {
            void message.error(t<string>("dashboard:tPayIntegration.accessError"));
            return;
          }
          void message.error(t<string>("dashboard:tPayIntegration.error"));
        },
      }),
    );
    stopLoading();
  };

  if (tpayClientId) {
    return (
      <div
        style={{
          maxWidth: 800,
          textAlign: "center",
          margin: "30px auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ maxWidth: 800, textAlign: "center" }}>
          <Space size={16} direction="vertical" align="center">
            <img src={TPayIcon} alt={`payu-icon`} className="h-14 self-center" />
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {t<string>("settings:clientIntegration.status")}
            </Typography.Title>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {t<string>("settings:paymentGateway.tpay.clientId")}: <strong>{tpayClientId}</strong>
            </Typography.Paragraph>
            <Badge status="success" text={t<string>("settings:clientIntegration.completed")} />
          </Space>
        </div>
      </div>
    );
  }

  return (
    <>
      <Space direction="vertical" align="center" size={24} className="my-8">
        <Button
          type="dashed"
          icon={<EditOutlined />}
          onClick={() => {
            toggleModal(true);
          }}
        >
          Dodaj integrację
        </Button>
      </Space>
      <ModalForm
        open={showModal}
        loading={loading}
        onCancel={() => {
          toggleModal(false);
        }}
        title={t<string>("settings:addNewAccount")}
      >
        <TPayIntegrationForm onSubmit={handleSubmit} model={undefined} />
      </ModalForm>
    </>
  );
};

export default TPayIntegrationPanel;
