import { type TFunction } from "i18next";

import { ProductResourceStatus } from "@fitness-app/data-models/entities/ProductResource";

export const productResourceStatusOptions = (t: TFunction) => [
  {
    label: t(`common:productResourceStatus.${ProductResourceStatus.DRAFT.toLowerCase()}`),
    value: ProductResourceStatus.DRAFT,
  },
  {
    label: t(`common:productResourceStatus.${ProductResourceStatus.AVAILABLE.toLowerCase()}`),
    value: ProductResourceStatus.AVAILABLE,
  },
  {
    label: t(`common:productResourceStatus.${ProductResourceStatus.UNAVAILABLE.toLowerCase()}`),
    value: ProductResourceStatus.UNAVAILABLE,
  },
];
