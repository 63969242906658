import { createSelector } from "reselect";
import { UserRole } from "@fitness-app/data-models";

import { type AppStore } from "../../../index";
import { getUserRole } from "./index";

const getUser = (state: AppStore) => state.user.data;
const getCustomClaims = (state: AppStore) => state.user.customClaims;

export const getParentTrainerId = createSelector(
  [getUser, getUserRole, getCustomClaims],
  (user, userRole, claims): string => {
    if (userRole === UserRole.CLIENT) {
      return "";
    }
    if (userRole === UserRole.TEAM_MEMBER && claims?.role === UserRole.TEAM_MEMBER && claims.trainerId) {
      return claims.trainerId;
    }

    if (!user?.id) {
      throw new Error("User is not logged");
    }

    return user.id;
  },
);
