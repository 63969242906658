import { type AppThunk } from "../../../index";
import { clearActivities } from "../../traineeActivities/reducer";
import { clearMealsPlan } from "../../traineeMealsPlans/reducer";
import { clearMeasurements } from "../../traineeMeasurements/reducer";
import { resetFetchedWeek } from "../../traineeNutritionTracker/reducer";
import { clearFetchedProgram } from "../../traineeProgram/reducer";
import { clearSurveys } from "../../traineeSurveys/reducer";
import { clearTrainerProfile as action } from "../reducer";

export const unsubscribeFromTrainee =
  (): AppThunk =>
  async (dispatch, _, { subscriptions, db }) => {
    if (subscriptions.realtimeTraineeChannel) {
      await db.removeChannel(subscriptions.realtimeTraineeChannel);
      subscriptions.traineeChannelName = null;
    }
    dispatch(clearActivities());
    dispatch(clearFetchedProgram());
    dispatch(clearMealsPlan());
    dispatch(clearMeasurements());
    dispatch(resetFetchedWeek());
    dispatch(action());
    dispatch(clearSurveys());
  };
