import { createSelector } from "reselect";

import { type AppStore } from "../../../index";

const getProgram = (state: AppStore) => state.programBuilder.selectedProgram;
const getProgramDetails = (state: AppStore) => state.programBuilder.programDetails;

export const getCurrentProgram = createSelector([getProgram, getProgramDetails], (program, details) => {
  if (!program || !details) {
    throw new Error("Cannot get current program");
  }

  return {
    program,
    details,
  };
});
