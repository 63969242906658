import { type SupplementsTemplateWithCreator } from "@fitness-app/data-models/entities/SupplementTemplate";

import { type RequestStatus } from "../../../enums/requestStatus";

export const SUPPLEMENTS_TEMPLATES_REDUCER_NAME = "supplementsTemplates";

export interface SupplementsTemplatesReducer {
  list: SupplementsTemplateWithCreator[];
  listStatus: null | RequestStatus;
  tags: string[];
  creators: { label: string; value: string }[];
}
