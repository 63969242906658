import { createAsyncThunk } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import {
  type APIKey,
  type BasicAuth,
  type BearerToken,
  type Webhook,
} from "@fitness-app/data-models/entities/Automation";

import { type ActionParams, type AsyncThunkCreator } from "../../../index";
import { PRODUCT_AUTOMATION_REDUCER_NAME } from "../types";

export type AuthToProcess = null | BasicAuth | APIKey | BearerToken;

type Payload = {
  productId: string;
  webhook: Webhook;
  action: "add" | "update" | "remove";
  authToProcess?: AuthToProcess;
};

export const updateProductAutomation = createAsyncThunk<void, ActionParams<Payload>, AsyncThunkCreator<unknown>>(
  `${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomation`,
  async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, secretsApi }, getState }) => {
    try {
      const { data } = getState().productAutomation;

      if (!data) {
        throw new Error("product automation data not exists");
      }

      let webhooksToUpdate = cloneDeep(data.webhooks) || [];

      if (payload.action === "remove") {
        webhooksToUpdate = webhooksToUpdate.filter((webhook) => webhook.id !== payload.webhook.id);
      }

      if (payload.action === "update") {
        webhooksToUpdate = data.webhooks
          ? data.webhooks.map((savedWebhook) =>
              savedWebhook.id === payload.webhook.id ? payload.webhook : savedWebhook,
            )
          : [];
      }

      if (payload.action === "add") {
        webhooksToUpdate = [...(data?.webhooks || []), payload.webhook];
      }

      if (payload.action === "remove" && payload.webhook.auth) {
        await secretsApi.post("/remove-secret", {
          productId: payload.productId,
          connectedResource: "webhook",
          resourceId: payload.webhook.id,
        });
      }

      await db
        .from("product_automation")
        .update({
          webhooks: webhooksToUpdate,
          updatedAt: new Date().toISOString(),
        })
        .eq("productId", payload.productId);

      if ((payload.action === "add" || payload.action === "update") && payload.authToProcess) {
        await secretsApi.post("/upsert-secret", {
          productId: payload.productId,
          connectedResource: "webhook",
          resourceId: payload.webhook.id,
          auth: payload.authToProcess,
        });
      }

      onSuccess?.();
    } catch (e) {
      onFailure?.();

      return rejectWithValue(e);
    }
  },
);
