import React, { type FunctionComponent } from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";

import { productClientActions } from "@fitness-app/app-store";
import { PaymentTypeOption } from "@fitness-app/data-models/entities/Product";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useAppDispatch } from "~/store/initializeStore";
import ProductClientFreeSettings from "./ProductClientFreeSettings";
import ProductClientOneTimeSettings from "./ProductClientOneTimeSettings";
import ProductClientSubscriptionSettings from "./ProductClientSubscriptionSettings";

interface OwnProps {
  productClient: ProductClient | null;
  ownerView?: boolean;
  readonly?: boolean;
}

type Props = OwnProps;

const ProductClientSettings: FunctionComponent<Props> = ({ productClient, ownerView }) => {
  const { t } = useTranslation(["client", "products", "common"]);
  const dispatch = useAppDispatch();
  if (!productClient) {
    return null;
  }

  const updateInvoiceRequest = (value: boolean) => {
    void dispatch(
      productClientActions.updateInvoiceRequest({
        payload: {
          invoiceRequest: value,
          email: productClient.email,
          productId: productClient.productId,
        },
      }),
    );
  };

  const renderSettings = () => {
    if (productClient.paymentType === PaymentTypeOption.Recurring) {
      return (
        <ProductClientSubscriptionSettings
          productClient={productClient}
          updateInvoiceRequest={updateInvoiceRequest}
          ownerView={ownerView}
        />
      );
    }
    if (
      productClient.paymentType === PaymentTypeOption.OneTime &&
      productClient.accessPeriod?.type !== "none_limited"
    ) {
      return (
        <ProductClientOneTimeSettings
          productClient={productClient}
          updateInvoiceRequest={updateInvoiceRequest}
          ownerView={ownerView}
        />
      );
    }
    if (productClient.paymentType === "free") {
      return <ProductClientFreeSettings productClient={productClient} />;
    }
    return null;
  };

  if (productClient.accessPeriod?.type === "none_limited") {
    return null;
  }

  return (
    <div>
      <Divider orientation="left">{t("settings.title")}</Divider>
      {renderSettings()}
    </div>
  );
};

export default ProductClientSettings;
