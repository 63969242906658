import { useQuery } from "@tanstack/react-query";

import { ClientNutritionStatus, type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";

import { supabase } from "~/store/initializeStore";

export const useDraftNutritionPlans = (traineeId?: string) => {
  const { data, isLoading } = useQuery(["draftNutritionPlans", traineeId], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!traineeId) {
        return;
      }
      const { data } = await supabase
        .from("client_meals_plan")
        .select(
          "id, name, createdAt, targetCalories, macroSplit, dietaryPreference, targets, mealsSchema, startAt, tags",
        )
        .order("startAt", { ascending: true })
        .eq("traineeId", traineeId)
        .eq("status", ClientNutritionStatus.Template)
        .returns<ClientNutrition[]>()
        .throwOnError();

      return data;
    },
  });

  return {
    drafts: data,
    isLoading,
  };
};
