import { type SliderDirection } from "../types";

interface Params {
  itemsListWidth: number[];
  direction: SliderDirection;
  clientWidth: number;
  currentScrollLeftPosition: number;
  itemOffset: number;
  additionalOffset?: number;
}

export const getScrollPointToShowNextElement = ({
  itemsListWidth,
  currentScrollLeftPosition,
  clientWidth,
  itemOffset,
  direction,
  additionalOffset = 0,
}: Params): number => {
  const cumulativeItemWidth: [number, number][] = [];

  for (const [index, value] of itemsListWidth.entries()) {
    if (index === 0) {
      cumulativeItemWidth[index] = [0, value + itemOffset];

      continue;
    }

    if (index === itemsListWidth.length - 1) {
      cumulativeItemWidth[index] = [
        // @ts-expect-error ignore
        cumulativeItemWidth[index - 1][1],
        // @ts-expect-error ignore
        cumulativeItemWidth[index - 1][1] + value,
      ];

      continue;
    }

    cumulativeItemWidth[index] = [
      // @ts-expect-error ignore
      cumulativeItemWidth[index - 1][1],
      // @ts-expect-error ignore
      cumulativeItemWidth[index - 1][1] + itemOffset + value,
    ];
  }

  const currentVisibleRightEdge = currentScrollLeftPosition + clientWidth;

  if (direction === "left") {
    const nextSnapIndex = cumulativeItemWidth.findIndex(
      (point) => point[0] < currentScrollLeftPosition && point[1] >= currentScrollLeftPosition,
    );
    const newScrollPosition = nextSnapIndex > 0 ? cumulativeItemWidth[nextSnapIndex]?.[0] : cumulativeItemWidth[0]?.[0];

    return (newScrollPosition || 0) - additionalOffset;
  }

  const nextSnapIndex = cumulativeItemWidth.findIndex(
    (point) => point[0] <= currentVisibleRightEdge && point[1] > currentVisibleRightEdge,
  );

  const newScrollPosition =
    nextSnapIndex > 0
      ? cumulativeItemWidth[nextSnapIndex]?.[1]
      : cumulativeItemWidth[cumulativeItemWidth.length - 1]?.[1];

  return (newScrollPosition || 0) + additionalOffset - clientWidth;
};
