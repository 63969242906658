import { createAsyncThunk } from "@reduxjs/toolkit";

import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";

import { type AsyncThunkCreator } from "../../../index";
import { NUTRITION_REDUCER_NAME } from "../types";

type Payload = {
  ingredient: Ingredient;
};

export const addIngredient = createAsyncThunk<Ingredient | null, Payload, AsyncThunkCreator<string>>(
  `${NUTRITION_REDUCER_NAME}/addIngredient`,
  async (payload, { rejectWithValue, extra: { db, analytics } }) => {
    const { error, data } = await db.from("ingredient").insert(payload.ingredient).select().returns<Ingredient[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    analytics.track("add_ingredient");

    return data[0] || null;
  },
);
