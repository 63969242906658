import React from "react";
import { Alert, Badge } from "antd";

import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

interface ClientStatusBadgeProps {
  status: TraineeStatus;
  banner?: boolean;
  extraAction?: React.ReactElement;
}

const statusMapper = {
  [TraineeStatus.ACTIVE]: {
    text: "Aktywny",
    status: "success",
  },
  [TraineeStatus.TERMINATED]: {
    text: "Nieopłacony",
    status: "warning",
  },
  [TraineeStatus.ARCHIVED]: {
    text: "Zarchiwizowany",
    status: "error",
  },
  [TraineeStatus.INACTIVE]: {
    text: "Nieaktywny",
    status: "warning",
  },
  processing: {
    text: "W trakcie przetwarzania",
    status: "processing",
  },
} as const;

const ClientStatusBadge = ({ status, banner, extraAction }: ClientStatusBadgeProps): React.ReactElement => {
  const config = statusMapper[status] || { text: "Nieaktywny", status: "warning" };
  if (banner) {
    return (
      <Alert
        banner
        className="flex justify-center"
        message={
          <span>
            {config.text} {extraAction}
          </span>
        }
        type={config.status}
      />
    );
  }
  return <Badge status={config.status} text={config.text} className="whitespace-nowrap" />;
};

export default ClientStatusBadge;
