import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ProgramWorkout } from "@fitness-app/data-models/entities/TrainingProgram";

import { RequestStatus } from "../../../enums/requestStatus";
import {
  duplicateWorkoutInProgram,
  fetchProgramWithDetails,
  removeScheduledProgram,
  removeWorkoutFromProgram,
  setProgramSchedule,
  updateScheduledProgram,
  updateTraineeProgram,
} from "./actions";
import { addNewWorkoutToProgram } from "./actions/addNewWorkoutToProgram";
import { fetchScheduledPrograms } from "./actions/fetchScheduledPrograms";
import { TRAINEE_PROGRAM_REDUCER_NAME, type TraineeProgramReducer } from "./types";

const initialState: TraineeProgramReducer = {
  programId: null,
  programStatus: null,
  programDetails: null,
  selectedProgram: null,
  scheduledPrograms: [],
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_PROGRAM_REDUCER_NAME,
  reducers: {
    updateWorkoutsInProgram(state, { payload }: PayloadAction<{ programId: string; workouts: ProgramWorkout[] }>) {
      if (state.programDetails?.programId === payload.programId) {
        state.programDetails.workouts = payload.workouts;
      }
    },
    clearFetchedProgram() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProgramWithDetails.pending, (state, { meta }) => {
      state.programStatus = RequestStatus.FETCHING;
      state.programId = meta.arg.id;
    });
    builder.addCase(fetchProgramWithDetails.rejected, (state) => {
      state.programStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchProgramWithDetails.fulfilled, (state, { payload }) => {
      state.programStatus = RequestStatus.SUCCESS;
      state.programDetails = payload.details;
      state.selectedProgram = payload.plan;
    });

    builder.addCase(duplicateWorkoutInProgram.fulfilled, (state, { payload }) => {
      if (state.programDetails && state.programDetails.programId === payload.programId) {
        state.programDetails = payload;
      }
    });
    builder.addCase(addNewWorkoutToProgram.fulfilled, (state, { payload }) => {
      if (state.programDetails && state.programDetails.programId === payload.programId) {
        state.programDetails = payload;
      }
    });

    builder.addCase(removeWorkoutFromProgram.fulfilled, (state, { payload }) => {
      if (state.programDetails && state.programDetails.programId === payload.programId) {
        state.programDetails = payload;
      }
    });
    builder.addCase(updateTraineeProgram.fulfilled, (state, { payload }) => {
      if (state.selectedProgram?.id === payload.id) {
        state.selectedProgram = { ...state.selectedProgram, ...payload };
      }
    });
    builder.addCase(setProgramSchedule.fulfilled, (state, { payload }) => {
      if (state.selectedProgram?.id === payload.id) {
        state.selectedProgram = { ...state.selectedProgram, ...payload };
      }
    });
    builder.addCase(fetchScheduledPrograms.fulfilled, (state, { payload }) => {
      state.scheduledPrograms = payload;
    });
    builder.addCase(removeScheduledProgram.fulfilled, (state, { meta }) => {
      state.scheduledPrograms = state.scheduledPrograms.filter((program) => program.id !== meta.arg.programId);
    });
    builder.addCase(updateScheduledProgram.fulfilled, (state, { payload }) => {
      state.scheduledPrograms = state.scheduledPrograms.map((program) => {
        if (program.id === payload.id) {
          return { ...program, ...payload };
        }

        return program;
      });
    });
  },
});

export const { updateWorkoutsInProgram, clearFetchedProgram } = reducerSlice.actions;
export default reducerSlice.reducer;
