import { createSelector } from "reselect";

import { type AppStore } from "../../../index";

export const getTrainee = (store: AppStore) => store.trainee.profile;

export const getTraineeId = createSelector([getTrainee], (trainee): string | null => {
  if (!trainee) {
    return null;
  }

  return trainee.id;
});
