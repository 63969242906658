import React, { useEffect } from "react";
import { ClockCircleOutlined, LaptopOutlined, MobileOutlined, TabletOutlined } from "@ant-design/icons";
import { Alert, Table } from "antd";
import dayjs from "dayjs";
import orderBy from "lodash.orderby";
import uniqBy from "lodash.uniqby";
import { useTranslation } from "react-i18next";

import { traineeActions } from "@fitness-app/app-store";
import { type UserDevice } from "@fitness-app/data-models/entities/UserStatus";

import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const typeIcon: Record<string, typeof MobileOutlined> = {
  mobile: MobileOutlined,
  tablet: TabletOutlined,
  desktop: LaptopOutlined,
};

const TraineeDevices = () => {
  const { t } = useTranslation(["trainees", "survey"]);

  const { profile, userStatus } = useAppSelector((state) => state.trainee);
  const dispatch = useAppDispatch();
  const userId = profile?.userId;

  useEffect(() => {
    if (userId) {
      void dispatch(traineeActions.fetchTraineeStatus({ userId }));
    }
  }, [userId]);

  if (!userStatus) {
    return (
      <div style={{ padding: 20 }}>
        <Alert message={t("devices.alertTitle")} description={t("devices.alertDescription")} showIcon type="warning" />
      </div>
    );
  }

  return (
    <div className="px-2 py-6">
      <Table dataSource={uniqBy(orderBy(userStatus.devices, "lastSeen", "desc"), "name") || []} pagination={false}>
        <Table.Column<UserDevice> dataIndex="name" key="name" title={t("devices.name")} />
        <Table.Column<UserDevice>
          dataIndex="type"
          key="type"
          title={t("devices.type")}
          render={(type: string) => {
            const DeviceIcon = typeIcon[type] || LaptopOutlined;
            return (
              <div className="flex gap-x-2">
                <DeviceIcon /> <span>{t(`devices.types.${type}`)}</span>
              </div>
            );
          }}
        />
        <Table.Column<UserDevice> dataIndex="version" key="version" title={t("devices.appVersion")} />
        <Table.Column<UserDevice>
          dataIndex="lastSeen"
          key="lastSeen"
          title={t("devices.lastSeen")}
          render={(timestamp: number) => (
            <div className="flex gap-x-2">
              <ClockCircleOutlined /> <span>{dayjs(timestamp).format("DD.MM.YYYY HH:mm")}</span>
            </div>
          )}
        />
      </Table>
    </div>
  );
};

export default TraineeDevices;
