import React from "react";
import { Select, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { type ExtendedAnswerOption } from "@fitness-app/data-models/entities/ClientSurvey";

interface SelectAnswerProps {
  multiSelect: boolean;
  value?: (string | number | ExtendedAnswerOption)[] | null;
  options: ({ value: string | number; title?: string; label?: string; desc?: string } | string)[];
  isInitial?: boolean;
}

const SelectAnswer = ({ value, multiSelect, options, isInitial }: SelectAnswerProps) => {
  const { t } = useTranslation("surveys");
  const props = value !== undefined ? { value } : {};
  return (
    <div className="flex items-center justify-center">
      <Select
        mode={multiSelect ? "multiple" : undefined}
        placeholder={t("selectAnswer")}
        {...props}
        style={{ minWidth: 180 }}
      >
        {options.map((option) =>
          typeof option === "string" ? (
            <Select.Option key={option} value={option} disabled={value}>
              {t(option)}
            </Select.Option>
          ) : (
            <Select.Option key={option.value} value={option.value} disabled={value}>
              <Tooltip title={t(option.desc || "")}>
                {isInitial ? t(option.title || option.label || "") : option.title || option.label}
              </Tooltip>
            </Select.Option>
          ),
        )}
      </Select>
    </div>
  );
};

export default SelectAnswer;
