import { type ProductAuthor } from "../entities/Product";

export const getAuthorName = (author?: ProductAuthor) => {
  if (!author) {
    return "";
  }
  if (author.creator) {
    return author.creator;
  }

  return `${author.firstName} ${author.lastName ?? ""}`;
};

export const getName = (
  author?: { firstName: string | null; lastName?: string | null } | null,
  fallback?: string,
  withBrackets?: boolean,
) => {
  if (!author) {
    return fallback ?? "";
  }

  if (withBrackets) {
    return `(${author.firstName ?? ""} ${author.lastName ?? ""})`.trim();
  }

  return `${author.firstName ?? ""} ${author.lastName ?? ""}`;
};
