import React, { memo, useCallback, useEffect, useMemo } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, List, Segmented, Space, Tag, type InputRef, type MenuProps } from "antd";
import { Apple, Soup, Utensils } from "lucide-react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { nutritionActions, RequestStatus } from "@fitness-app/app-store";
import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { sumNutrients } from "@fitness-app/utils/src/nutrition/sumNutrients";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useMealsProductContext } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsProductProvider";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const { Search } = Input;

interface AddDishToMealProps {
  searchInputRef: React.RefObject<InputRef>;
  onAddDish(dishOrIngredient: Dish | Ingredient, mealId?: string): void;
  selectedMealId: string | null;
}

export type FilterType = "all" | "dishes" | "ingredients";

const AddDishToMeal = ({ searchInputRef, onAddDish, selectedMealId }: AddDishToMealProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const dispatch = useAppDispatch();
  const { dishes, ingredients, ingredientsStatus, dishesStatus } = useAppSelector((state) => state.nutrition);
  const [selectedFilter, setSelectedFilter] = React.useState<FilterType>("all");
  const {
    mealsMap: { mealsByDay, meals },
  } = useMealsPlanContext();
  const { selectProduct } = useMealsProductContext();
  const { userId } = useUserRole();

  const selectedMeal = selectedMealId ? meals[selectedMealId] : null;

  const mealsOptionsByDay = useCallback(
    (dish: Dish | Ingredient) => {
      const items: MenuProps["items"] = mealsByDay.map((day) => ({
        label: t(`common:weekdays.${day.name}`),
        key: day.id,
        children: day.meals.map((meal) => ({
          label: meal.name || t(`mealType.${meal.type}`),
          key: meal.id,
          onClick: () => {
            onAddDish(dish, meal.id);
          },
        })),
      }));
      return items;
    },
    [mealsByDay, t],
  );

  const onSearch = (value = "") => {
    void dispatch(nutritionActions.searchDishOrIngredient({ searchTerm: value.trim() }));
  };

  useEffect(() => {
    void dispatch(nutritionActions.searchDishOrIngredient({ searchTerm: "" }));
  }, [dispatch]);

  const dataSource = useMemo(() => {
    if (selectedFilter === "all") {
      return [...dishes, ...ingredients];
    }
    if (selectedFilter === "dishes") {
      return dishes;
    }
    if (selectedFilter === "ingredients") {
      return ingredients;
    }
  }, [selectedFilter, dishes, ingredients]);

  return (
    <Space className="w-full" direction="vertical" size={16}>
      <Space className="w-full" direction="vertical" size={12}>
        <Search
          ref={searchInputRef}
          placeholder={t<string>("mealsSchedule.searchDishPlaceholder")}
          allowClear
          onSearch={onSearch}
          style={{ width: "100%" }}
          size="large"
        />
        <Segmented
          block
          value={selectedFilter}
          onChange={(value) => setSelectedFilter(value as FilterType)}
          options={[
            {
              label: t("mealsSearch.all"),
              value: "all",
              icon: <Utensils size={14} className="mt-px align-text-top" />,
            },
            {
              label: t("mealsSearch.dishes"),
              value: "dishes",
              icon: <Soup size={14} className="mt-px align-text-top" />,
            },
            {
              label: t("mealsSearch.ingredients"),
              value: "ingredients",
              icon: <Apple size={14} className="mt-px align-text-top" />,
            },
          ]}
        />
      </Space>

      <List
        className="w-full"
        loading={
          ingredientsStatus === RequestStatus.SEARCHING ||
          dishesStatus === RequestStatus.SEARCHING ||
          dishesStatus === RequestStatus.FETCHING ||
          ingredientsStatus === RequestStatus.FETCHING
        }
        itemLayout="horizontal"
        dataSource={dataSource}
        key="id"
        renderItem={(item) => {
          if (item.type === "ingredient") {
            return (
              <List.Item className="group relative cursor-pointer">
                <List.Item.Meta
                  avatar={<Apple size={14} className="mt-px align-text-top" />}
                  title={`${item.name} ${item.brand ? ` - ${item.brand}` : ""} (${item.packageSize || "100"}g)`}
                  description={
                    <div className="flex justify-between pt-2 font-light	">
                      <dd className="text-xs text-gray-700">
                        B: {item.nutrients.protein} g | W: {item.nutrients.carbohydrates} g | T:
                        {item.nutrients.fat} g
                      </dd>
                      <dd className="text-xs text-gray-700">{item.calories} kcal</dd>
                    </div>
                  }
                />
                <div className="ease invisible absolute inset-0 flex items-center justify-center bg-gray-100/70 transition-[visibility] duration-100 group-hover:visible">
                  <div className="flex gap-3">
                    <Dropdown menu={{ items: mealsOptionsByDay(item) }} trigger={["contextMenu"]}>
                      <Button
                        key="add"
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => onAddDish(item)}
                      />
                    </Dropdown>

                    <Button
                      key="add"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() =>
                        selectProduct(
                          {
                            ...item,
                            id: uuid(),
                            ingredientId: item.id,
                            portion: item.packageSize || 100,
                          },
                          selectedMeal?.id,
                          selectedMeal?.dayId,
                        )
                      }
                    />
                  </div>
                </div>
              </List.Item>
            );
          } else {
            const protein = sumNutrients(item.ingredients, "protein");
            const carbs = sumNutrients(item.ingredients, "carbohydrates");
            const fat = sumNutrients(item.ingredients, "fat");
            return (
              <List.Item className="group relative cursor-pointer">
                <List.Item.Meta
                  avatar={<Soup size={14} className="mt-px align-text-top" />}
                  title={item.name}
                  description={
                    <div className="flex flex-col gap-y-2">
                      <div className="flex justify-between pt-2 font-light">
                        <dd className="text-xs text-gray-700">
                          B: {protein} g | W: {carbs} g | T: {fat} g
                        </dd>
                        <dd className="text-xs text-gray-700">
                          {item.calories} kcal ({item.portionSize || "100"}g)
                        </dd>
                      </div>
                      {item.createdBy === userId && (
                        <div>
                          <Tag color="green">Twój szablon potrawy</Tag>
                        </div>
                      )}
                    </div>
                  }
                />
                <div className="ease invisible absolute inset-0 flex items-center justify-center bg-gray-100/70 transition-[visibility] duration-100 group-hover:visible">
                  <div className="flex gap-3">
                    <Dropdown menu={{ items: mealsOptionsByDay(item) }} trigger={["contextMenu"]}>
                      <Button
                        key="add"
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => onAddDish(item)}
                      />
                    </Dropdown>

                    <Button
                      key="add"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() =>
                        selectProduct({ ...item, parentId: item.id, id: uuid() }, selectedMeal?.id, selectedMeal?.dayId)
                      }
                    />
                  </div>
                </div>
              </List.Item>
            );
          }
        }}
      />
    </Space>
  );
};

export default memo(AddDishToMeal);
