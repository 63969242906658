import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Badge, Button, Card, List, Space, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { traineesActions } from "@fitness-app/app-store";
import {
  type TraineeStatus,
  type TraineeWithAssignedTrainer,
  type TraineeWithAssignedTrainerAndProduct,
} from "@fitness-app/data-models/entities/Trainee";

import TraineeStatusBadge from "~/modules/Trainee/components/TraineeStatusBadge/TraineeStatusBadge";
import { useAppDispatch } from "~/store/initializeStore";

export const TeamMemberDashboard = (): React.ReactElement => {
  const { t } = useTranslation("trainees");
  const [clients, setClients] = React.useState<TraineeWithAssignedTrainer[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    setLoading(true);
    void dispatch(traineesActions.fetchTraineesWithoutPlan())
      .unwrap()
      .then((trainees) => {
        setClients(trainees.list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <Space direction="vertical" size={24} className="w-full">
      <Card title="Lista zadań" loading={loading}>
        <Table<TraineeWithAssignedTrainer>
          dataSource={clients}
          rowKey="id"
          size="middle"
          scroll={{ x: true }}
          pagination={false}
          locale={{
            emptyText: t("emptyState"),
          }}
        >
          <Table.Column<TraineeWithAssignedTrainer>
            title={t("tableHeader.index")}
            width={50}
            dataIndex="index"
            key="index"
            render={(_name, _row, i) => i + 1}
          />
          <Table.Column<TraineeWithAssignedTrainer>
            title={t("tableHeader.name")}
            dataIndex="name"
            key="name"
            render={(_, row) => (
              <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.id}`)}>
                {row.firstName} {row.lastName}
              </Button>
            )}
          />
          <Table.Column<TraineeWithAssignedTrainer>
            title={t("tableHeader.email")}
            dataIndex="email"
            ellipsis
            render={(email) => (
              <Typography.Link href={`mailto:${email}`} target="_blank" copyable>
                {email}
              </Typography.Link>
            )}
          />
          <Table.Column<TraineeWithAssignedTrainer>
            title={t("tableHeader.status")}
            dataIndex="status"
            key="status"
            render={(status: TraineeStatus) => <TraineeStatusBadge status={status} />}
          />
          <Table.Column<TraineeWithAssignedTrainerAndProduct>
            title={t("tableHeader.workouts")}
            dataIndex="activeTrainingProgramId"
            key="activeTrainingProgramId"
            render={(activeTrainingProgramId, row) => {
              if (activeTrainingProgramId && row.hasActiveTrainingProgram) {
                return <CheckOutlined style={{ color: "#25b10f" }} />;
              }

              if (!activeTrainingProgramId) {
                return <Badge status="error" text="Brak dodanego planu" />;
              }

              return <Badge status="error" text="Plan nieudostępniony" />;
            }}
          />
          <Table.Column<TraineeWithAssignedTrainerAndProduct>
            title={t("tableHeader.diet")}
            dataIndex="activeMealsPlanId"
            key="activeMealsPlanId"
            render={(activeMealsPlanId, row) => {
              if (activeMealsPlanId && row.hasActiveNutrition) {
                return <CheckOutlined style={{ color: "#25b10f" }} />;
              }

              if (!activeMealsPlanId) {
                return <Badge status="error" text="Brak dodanej diety" />;
              }

              return <Badge status="error" text="Dieta nieudostępniona" />;
            }}
          />
        </Table>
      </Card>
      <Card title="Zaplanowane treningi">
        <List dataSource={[]} />
      </Card>
    </Space>
  );
};
