import React, { useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, EditOutlined, FolderOpenOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Alert, Button, message, Popconfirm, Select, Space, Tag, Tree, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productsSettingsActions } from "@fitness-app/app-store";
import { EmailClient, type IConvertKitIntegration } from "@fitness-app/data-models/entities/ProductsSettings";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useLoading } from "~/hooks/useLoading";
import { useAppDispatch } from "~/store/initializeStore";
import ConvertKitIntegrationForm from "./ConvertKitIntegrationForm";
import { type ConvertKitIntegrationFormModel } from "./types";

interface OwnProps {
  integration: IConvertKitIntegration[] | null;
}

type Props = OwnProps;

const ADD_NEW_ITEM = "ADD_NEW_ITEM";

const ConvertKitIntegration: FunctionComponent<Props> = ({ integration }) => {
  const { t } = useTranslation(["settings", "common"]);
  const [loading, startLoading, stopLoading] = useLoading();
  const [showModal, toggleModal] = useState(false);
  const dispatch = useAppDispatch();

  const [selectedAccountName, changeSelectedAccount] = useState<undefined | string>(undefined);

  const selectedAccount = useMemo(() => {
    return integration?.find((item) => item.url === selectedAccountName);
  }, [selectedAccountName, integration]);

  const handleFormSubmit = async (data: ConvertKitIntegrationFormModel) => {
    startLoading();
    await dispatch(
      productsSettingsActions.integrateWithEmailClient({
        payload: { ...data, client: EmailClient.ConvertKit },
        onSuccess: () => {
          void message.success(t(selectedAccount ? "convertKit.editSuccess" : "convertKit.success"));
          toggleModal(false);
          handleChange(data.url);
        },
        onFailure: (errorCode) => {
          if (errorCode === 403) {
            void message.error(t("convertKit.accessError"));
            return;
          }
          void message.error(t("convertKit.error"));
        },
      }),
    );
    stopLoading();
  };

  const handleChange = (value?: string | undefined) => {
    if (value === ADD_NEW_ITEM || !value) {
      changeSelectedAccount(undefined);
      toggleModal(true);
      return;
    }
    changeSelectedAccount(value);
  };

  const refreshConnectedLists = async () => {
    if (!selectedAccount) {
      return;
    }
    void message.loading(t("messages.loading.refreshLists"));

    const onSuccess = () => {
      message.destroy();
      void message.success(t("messages.success.refreshLists"));
    };
    const onFailure = () => {
      message.destroy();
      void message.error(t("messages.error.refreshLists"));
    };
    await dispatch(
      productsSettingsActions.refreshConnectedListsWithEmailClient({
        payload: { url: selectedAccount.url, client: EmailClient.ConvertKit },
        onFailure,
        onSuccess,
      }),
    );
  };

  const handleRemoveIntegration = async () => {
    if (!selectedAccount) {
      return;
    }
    void message.loading(t("messages.loading.removeAccount"));

    const onSuccess = () => {
      message.destroy();
      void message.success(t("messages.success.removeAccount"));
      changeSelectedAccount(undefined);
    };
    const onFailure = (code?: number) => {
      message.destroy();
      if (code === 400) {
        void message.error(t("messages.error.cannotRemoveConnectedAccount"));
      } else {
        void message.error(t("messages.error.removeAccount"));
      }
    };
    await dispatch(
      productsSettingsActions.removeIntegrationWithEmailClient({
        payload: { url: selectedAccount.url, client: EmailClient.ConvertKit },
        onFailure,
        onSuccess,
      }),
    );
  };

  return (
    <Space direction="vertical" size={24}>
      <Typography.Title level={4}>{t("emailClients.activeCampaign.connectedAccounts")}</Typography.Title>
      <Select
        size="large"
        placeholder={t("selectAccount")}
        style={{ minWidth: 600 }}
        notFoundContent={false}
        onChange={handleChange}
        onSelect={handleChange}
        value={selectedAccount?.url}
        optionFilterProp="title"
        optionLabelProp="title"
      >
        {(integration || []).map((account) => (
          <Select.Option value={account.url} title={account.url} key={account.url}>
            {account.url}
          </Select.Option>
        ))}
        {
          <Select.Option value={ADD_NEW_ITEM} title={t<string>("addNewAccount")}>
            <Button
              type="link"
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
            >
              <PlusOutlined /> {t("addNewAccount")}
            </Button>
          </Select.Option>
        }
      </Select>
      {selectedAccount && (
        <>
          <Space direction="horizontal" align="end" style={{ width: "100%", justifyContent: "flex-end" }}>
            <Button type="dashed" icon={<RedoOutlined />} onClick={refreshConnectedLists}>
              {t("common:button.refresh")}
            </Button>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => toggleModal(true)}>
              {t("common:button.edit")}
            </Button>
            <Popconfirm
              onConfirm={handleRemoveIntegration}
              title={t("emailClients.deleteWarning")}
              okText={t("common:button.delete")}
              okButtonProps={{ danger: true }}
            >
              <Button type="dashed" danger icon={<DeleteOutlined />}>
                {t("common:button.delete")}
              </Button>
            </Popconfirm>
          </Space>
          <div
            style={{
              maxWidth: 600,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography.Paragraph strong>{t("emailClients.convertKit.lists")}</Typography.Paragraph>
            {selectedAccount.lists.length ? (
              <Tree
                checkable={false}
                selectable={false}
                showIcon
                treeData={selectedAccount.lists.map((list) => ({
                  title: list.name,
                  value: list.id,
                  key: list.id,
                  icon: <FolderOpenOutlined />,
                }))}
              />
            ) : (
              <Space direction="vertical">
                <Typography.Text type="secondary">{t("emailClients.convertKit.emptyLists")}</Typography.Text>
                <Alert message={t("emailClients.convertKit.emptyListsWarn")} showIcon type="warning" />
              </Space>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <Typography.Paragraph strong>{t("emailClients.tags")}</Typography.Paragraph>
            {selectedAccount.tags.length ? (
              selectedAccount.tags.map((tagObj) => <Tag key={tagObj.id}>{tagObj.tag}</Tag>)
            ) : (
              <Typography.Text type="secondary">{t("emailClients.emptyTags")}</Typography.Text>
            )}
          </div>
        </>
      )}

      <ModalForm
        visible={showModal}
        loading={loading}
        onCancel={() => toggleModal(false)}
        title={selectedAccount ? t("emailClients.editAccount") : t("emailClients.addNewAccount")}
      >
        <ConvertKitIntegrationForm
          onSubmit={handleFormSubmit}
          model={selectedAccount ? { url: selectedAccount.url, apiSecret: "" } : undefined}
        />
      </ModalForm>
    </Space>
  );
};

export default ConvertKitIntegration;
