import React from "react";

import { MessageInContext } from "@fitness-app/data-models/entities/Chat";

import ChatMessageFormProvider, {
  useChatMessageForm,
} from "~/modules/Chat/components/ChatNotificationContext/ChatMessageFormContext";
import { TraineeVideoRecordingsTable } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeVideoRecordings/TraineeVideoRecordingsTable";
import { useTraineeExerciseRecordings } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeVideoRecordings/useTraineeExerciseRecordings";
import { useUpdateExerciseRecording } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeVideoRecordings/useUpdateExerciseRecording";

interface TraineeVideoRecordingsProps {
  traineeId: string;
}

export const TraineeVideoRecordings = ({ traineeId }: TraineeVideoRecordingsProps): React.ReactElement => {
  const { addExerciseRecordingFeedback } = useUpdateExerciseRecording();

  return (
    <ChatMessageFormProvider
      onSuccess={(id, context) => {
        if (context?.type === MessageInContext.AddRecordingFeedback) {
          return addExerciseRecordingFeedback(String(context.metadata.recordingId), id);
        }
      }}
    >
      <TraineeVideoRecordingsInner traineeId={traineeId} />
    </ChatMessageFormProvider>
  );
};

export const TraineeVideoRecordingsInner = ({ traineeId }: { traineeId: string }) => {
  const { data, isLoading } = useTraineeExerciseRecordings(traineeId);
  const { open } = useChatMessageForm();

  return (
    <TraineeVideoRecordingsTable
      data={data || []}
      isLoading={isLoading}
      onAddFeedback={(recording) => {
        open({
          type: MessageInContext.AddRecordingFeedback,
          metadata: {
            exerciseName: recording.exercise.name,
            recordingId: recording.id,
            url: recording.url,
            workoutId: recording.workoutId,
            exerciseDate: recording.scheduledAt,
          },
        });
      }}
    />
  );
};
