import { type AppThunk } from "../../../index";
import { unsubscribeFromUnreadMessages as actionCreator } from "../reducer";

export const unsubscribeFromUnreadMessages =
  (): AppThunk =>
  async (dispatch, getState, { subscriptions, db }) => {
    if (subscriptions.unreadMessagesRealtimeChannel) {
      await db.removeChannel(subscriptions.unreadMessagesRealtimeChannel);
      subscriptions.unreadMessagesChannelName = null;
    }
    dispatch(actionCreator());
  };
