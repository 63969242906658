import { createAsyncThunk } from "@reduxjs/toolkit";
import { type AutomationTaskWithoutTriggers, type RefundType } from "@fitness-app/data-models/entities/Automation";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  clientEmail: string;
  productId: string;
  tasks: AutomationTaskWithoutTriggers[];
  cancelSubscription?: boolean;
  refund?: RefundType;
};

export const archiveProductClient = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CLIENT_REDUCER}/archiveProductClient`,
  async (payload, { rejectWithValue, extra: { productApi, analytics } }) => {
    try {
      await productApi.post("/archive-product-client", payload);
      analytics.track("archive_product_client", {
        productId: payload.productId,
      });
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
