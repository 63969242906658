import React, { useCallback } from "react";
import { ClockCircleOutlined, FileOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Avatar, Card, Tag, Timeline, Tooltip, Typography, Upload } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  ClientNoteCategory,
  ClientNoteType,
  type ClientNoteWithAuthorData,
} from "@fitness-app/data-models/entities/ClientNote";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";
import { getUserInitials } from "@fitness-app/utils";

const mapCategoryColor = {
  [ClientNoteCategory.General]: undefined,
  [ClientNoteCategory.Training]: "blue",
  [ClientNoteCategory.Nutrition]: "gold",
  [ClientNoteCategory.Health]: "red",
  [ClientNoteCategory.Management]: "green",
};

export const useTimelineItem = () => {
  const { t } = useTranslation("trainees");

  const createTimelineItem = useCallback((item: ClientNoteWithAuthorData) => {
    if (item.type === ClientNoteType.Event) {
      return (
        <Timeline.Item
          key={item.id}
          position="left"
          dot={<ThunderboltOutlined style={{ color: "black", fontSize: "16px" }} />}
        >
          <Card title={t("students:timeline.items.lifeEvent")} size="small">
            <p>{item.type}</p>
            <p className="whitespace-pre-wrap">{item.content}</p>
            <Typography.Text type="secondary">
              {`${t("students:timeline.date")}: ${dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}`}
            </Typography.Text>
          </Card>
        </Timeline.Item>
      );
    }

    return (
      <Timeline.Item key={item.id} position="left" dot={<FileOutlined style={{ color: "black", fontSize: "16px" }} />}>
        <Card
          title={
            <div className="flex flex-col gap-y-2 py-1">
              <div className="flex gap-x-2">
                <Tooltip title={item.author ? getAuthorName(item.author) : t("notes.authorDeleted")}>
                  <Avatar size="small" src={item.author?.avatarUrl}>
                    {item.author ? getUserInitials(item.author) : t("notes.authorDeleted")}
                  </Avatar>
                </Tooltip>
                <Typography.Text>Notatka tekstowa</Typography.Text>
              </div>

              <div className="flex items-baseline gap-x-2">
                <Typography.Text type="secondary" className="text-sm">
                  <ClockCircleOutlined style={{ marginRight: 8, marginBottom: 10 }} />
                  {item.createdAt ? dayjs(item.createdAt).format("DD.MM.YYYY HH:mm") : ""}
                </Typography.Text>

                <Tag color={mapCategoryColor[item.category]}>
                  {t(`notes.categories.${item.category || ClientNoteCategory.General}`)}
                </Tag>
              </div>
            </div>
          }
          className={item.pinned ? "border border-yellow-500" : ""}
        >
          <p className="whitespace-pre-wrap">{item.content}</p>

          {item.attachments?.length ? <Upload fileList={item.attachments} disabled /> : null}
        </Card>
      </Timeline.Item>
    );
  }, []);

  return {
    createTimelineItem,
  };
};
