import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TraineeEventType } from "@fitness-app/data-models/businessEvents/domain/trainee/TraineeEvents";
import { TraineeActivityEventType } from "@fitness-app/data-models/businessEvents/domain/traineeActivity/TraineeActivityEvents";
import { type ClientActivityEvent } from "@fitness-app/data-models/entities/ClientActivity";

import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  page?: number;
  type?: (TraineeActivityEventType | TraineeEventType)[];
};

export const fetchClientActivityEvents = createAsyncThunk<
  { currentPage: number; totalPages: number; data: ClientActivityEvent[] },
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEE_REDUCER_NAME}/fetchClientActivityEvents`,
  async (payload, { rejectWithValue, getState, extra: { db } }) => {
    const { pageSize } = getState().trainee;

    const { start, stop, currentPage } = getPageRange(payload?.page, pageSize);

    let query = db.from("client_activity_event").select("*", { count: "exact" }).eq("traineeId", payload.id);

    if (payload.type?.length) {
      query = query.in("eventName", payload.type);
    } else {
      query = query.not("eventName", "in", `(${TraineeActivityEventType.TraineeSentChatMessage})`);
    }

    const { data, error, count } = await query
      .order("timestamp", { ascending: false })
      .range(start, stop)
      .limit(pageSize)
      .returns<ClientActivityEvent[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return { data, currentPage, totalPages: count ? Math.ceil(count / pageSize) : 0 };
  },
);
