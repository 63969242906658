export const replaceDoubleBraces = (
  str: string,
  replacements: Record<string, string | number | null | boolean | object>,
) => {
  return str.replace(/{{(.+?)}}/g, (_, g1: string) => String(replacements[g1]) || g1);
};

export const replaceStringValue = (str: string, replacements: Record<string, string>) =>
  str.replace(/{\w+}/g, (placeholderWithDelimiters) => {
    const placeholderWithoutDelimiters = (placeholderWithDelimiters || "").substring(
      1,
      placeholderWithDelimiters.length - 1,
    );
    const stringReplacement = replacements[placeholderWithoutDelimiters.trim()] || placeholderWithDelimiters;
    return stringReplacement;
  });

export const normalizeReplacements = (replacements: Record<string, string | number>) => {
  const normalizedReplacements: Record<string, string> = {};
  Object.keys(replacements).forEach((key) => {
    normalizedReplacements[key] = String(replacements[key]) || "";
  });
  return normalizedReplacements;
};
