import React, { useEffect, useMemo } from "react";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Popconfirm, Row, Space, Table, Tabs, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { RequestStatus, surveysTemplatesActions } from "@fitness-app/app-store";
import { type MealsPlanWithCreator } from "@fitness-app/data-models/entities/MealsPlan";
import {
  SurveyTemplateType,
  type SurveyTemplate,
  type SurveyTemplateWithCreator,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useFormHandler } from "~/hooks/useFormHandler";
import { useLoading } from "~/hooks/useLoading";
import InitialSurveyConfigurator from "~/modules/SurveysTemplates/InitialSurveyConfigurator/InitialSurveyConfigurator";
import SurveyForm from "~/modules/SurveysTemplates/SurveyForm/SurveyForm";
import { type SurveyFormModel } from "~/modules/SurveysTemplates/SurveyForm/types";
import { SurveysVariables } from "~/modules/SurveysTemplates/SurveysVariables/SurveysVariables";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const Surveys = () => {
  const { listStatus, list, creators } = useAppSelector((store) => store.surveysTemplates);
  const dispatch = useAppDispatch();
  const [savingSurvey, startSaving, stopSaving] = useLoading();
  const { openFormModal, setEditedEntity, editedEntity, showModalForm, hideFormModal, formModel } = useFormHandler<
    SurveyFormModel,
    SurveyTemplate
  >();
  const { isTrainer, userId, isTeamMember } = useUserRole();
  const navigate = useNavigate();
  const { t } = useTranslation(["surveys", "common"]);

  useEffect(() => {
    if (listStatus === null) {
      void dispatch(surveysTemplatesActions.fetchSurveysTemplates());
    }
  }, [dispatch, listStatus]);

  const filteredSurveysList = useMemo(
    () => list.filter((survey) => !survey?.type || survey?.type !== SurveyTemplateType.INITIAL),
    [list],
  );

  const openSurveyEditForm = (survey: SurveyTemplateWithCreator) => {
    setEditedEntity(survey);
    openFormModal(survey);
  };

  const onArchiveSurvey = async (surveyId: string) => {
    await dispatch(
      surveysTemplatesActions.archiveSurveyTemplate({
        surveyTemplateId: surveyId,
      }),
    );
  };

  const onFormSubmit = async (model: SurveyFormModel) => {
    startSaving();
    if (editedEntity) {
      await dispatch(
        surveysTemplatesActions.editSurveyTemplate({
          surveyTemplateId: editedEntity.id,
          surveyTemplate: model,
        }),
      );
      hideFormModal();
      stopSaving();
    } else {
      const template: SurveyTemplate = {
        ...model,
        type: SurveyTemplateType.RECURRING,
        id: uuid(),
        name: "",
        archived: false,
        automation: {},
        questions: {},
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: userId,
      };
      await dispatch(surveysTemplatesActions.addSurveyTemplate({ surveyTemplate: template })).unwrap();
      hideFormModal();
      stopSaving();
      navigate(`/surveys/details/${template.id}`);
    }
  };

  return (
    <Row className="p-6">
      <Col xs={24}>
        <Card
          loading={listStatus === RequestStatus.FETCHING}
          title={t("surveysTemplates")}
          extra={
            <Button type="primary" icon={<PlusOutlined />} onClick={() => openFormModal()}>
              {t("addSurveyTemplate")}
            </Button>
          }
        >
          <Tabs type="card" defaultActiveKey="surveyTemplate">
            <Tabs.TabPane tab={t("templates")} key="surveyTemplate">
              <Table
                rowKey="id"
                dataSource={filteredSurveysList}
                locale={{
                  emptyText: <div>{t("survey.emptyState")} </div>,
                }}
                scroll={{ x: true }}
              >
                <Table.Column title={t("survey.title")} dataIndex="title" key="title" ellipsis />
                <Table.Column
                  title={t("survey.description")}
                  dataIndex="desc"
                  key="desc"
                  render={(desc: string) => {
                    if (!desc) {
                      return "-";
                    }
                    return (
                      <Tooltip title={desc}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    );
                  }}
                />
                <Table.Column
                  title={t("survey.numberOfQuestions")}
                  dataIndex=""
                  key="questionNumber"
                  render={(row: SurveyTemplateWithCreator) => Object.keys(row.questions).length}
                />
                <Table.Column<MealsPlanWithCreator>
                  title={t("survey.creator")}
                  dataIndex="creator"
                  key="creator"
                  filters={creators.map((creator) => ({
                    text: creator.label,
                    value: creator.value,
                  }))}
                  onFilter={(value, record) => record.createdBy === value}
                  render={(creator: MealsPlanWithCreator["creator"]) =>
                    creator ? `${creator?.firstName} ${creator?.lastName}` : "-"
                  }
                />
                <Table.Column<SurveyTemplateWithCreator>
                  title={t("common:button.options")}
                  key="action"
                  align="center"
                  render={(_, survey) => (
                    <Space>
                      <Typography.Link onClick={() => navigate(`/surveys/details/${survey.id}`)}>
                        {t("common:button.details")}
                      </Typography.Link>
                      {isTrainer || survey.createdBy === userId ? (
                        <>
                          <Divider type="vertical" />
                          <Typography.Link onClick={() => openSurveyEditForm(survey)}>
                            {t("common:button.edit")}
                          </Typography.Link>
                          <Divider type="vertical" />
                          <Popconfirm
                            title={t("survey.archiveDelete")}
                            okText={t("common:yes")}
                            cancelText={t("common:no")}
                            placement="left"
                            onConfirm={() => onArchiveSurvey(survey.id)}
                          >
                            <Typography.Link type="danger">{t("common:button.archive")}</Typography.Link>
                          </Popconfirm>
                        </>
                      ) : null}
                    </Space>
                  )}
                />
              </Table>
            </Tabs.TabPane>
            {isTrainer ? (
              <Tabs.TabPane tab={t("survey.initialSurveys")} key="initialSurvey">
                <InitialSurveyConfigurator />
              </Tabs.TabPane>
            ) : null}
            {isTeamMember && (
              <Tabs.TabPane tab={t("survey.surveysVariables")} key="surveysVariables">
                <SurveysVariables />
              </Tabs.TabPane>
            )}
          </Tabs>
        </Card>
      </Col>
      <ModalForm
        open={showModalForm}
        title={formModel ? t("survey.edit") : t("survey.add")}
        onCancel={hideFormModal}
        loading={savingSurvey}
        width={850}
      >
        <SurveyForm onSubmit={onFormSubmit} model={formModel} />
      </ModalForm>
    </Row>
  );
};

export default Surveys;
