import React, { memo } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type ISurveyQuestionAutomationForm } from "~/modules/SurveysTemplates/SurveyDetails/SurveyQuestionAutomationForm";

interface SurveyAutomationEmailMessageFormProps {
  parentFieldName: string | number;
  formController?: FormInstance<ISurveyQuestionAutomationForm>;
}

const SurveyAutomationEmailMessageForm = ({
  parentFieldName,
}: SurveyAutomationEmailMessageFormProps): React.ReactElement => {
  const { t } = useTranslation(["dashboard", "common", "products"]);
  return (
    <div>
      <Form.Item
        name={[parentFieldName, "subject"]}
        label="Temat"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={[parentFieldName, "content"]}
        label="Treść e-maila"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input.TextArea rows={5} />
      </Form.Item>
    </div>
  );
};

export default memo(SurveyAutomationEmailMessageForm);
