import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { type ClientSurvey } from "@fitness-app/data-models/entities/ClientSurvey";

import { RequestStatus } from "../../../enums/requestStatus";
import { addTraineeSurvey } from "./actions/addTraineeSurvey";
import { deleteTraineeSurvey } from "./actions/deleteTraineeSurvey";
import { fetchTraineeSurvey } from "./actions/fetchTraineeSurvey";
import { fetchTraineeSurveys } from "./actions/fetchTraineeSurveys";
import { markSurveyAsOpened } from "./actions/markSurveyAsOpened";
import { TRAINEE_SURVEYS_REDUCER_NAME, type TraineeSurveysReducer } from "./types";

const initialState: TraineeSurveysReducer = {
  surveys: [],
  surveysStatus: null,

  selectedSurveyStatus: null,
  selectedSurvey: null,
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_SURVEYS_REDUCER_NAME,
  reducers: {
    clearSelectedSurvey(state) {
      state.selectedSurvey = null;
      state.selectedSurveyStatus = null;
    },
    clearSurveys(state) {
      state.surveys = [];
      state.surveysStatus = null;

      state.selectedSurvey = null;
      state.selectedSurveyStatus = null;
    },
    addSurvey(state, { payload }: PayloadAction<ClientSurvey>) {
      state.surveys = uniqBy([payload, ...state.surveys], "id");
    },
    updateSurvey(state, { payload }: PayloadAction<ClientSurvey>) {
      state.surveys = state.surveys.map((survey) => {
        if (survey.id === payload.id) {
          return payload;
        }

        return survey;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTraineeSurveys.pending, (state) => {
      state.surveysStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchTraineeSurveys.fulfilled, (state, { payload }) => {
      state.surveysStatus = RequestStatus.SUCCESS;
      state.surveys = payload;
    });
    builder.addCase(fetchTraineeSurveys.rejected, (state) => {
      state.surveysStatus = RequestStatus.FAILED;
    });
    builder.addCase(addTraineeSurvey.fulfilled, (state, { payload }) => {
      state.surveys.unshift(payload);
    });
    builder.addCase(deleteTraineeSurvey.fulfilled, (state, { meta }) => {
      state.surveys = state.surveys.filter((note) => note.id !== meta.arg.id);
    });

    builder.addCase(fetchTraineeSurvey.pending, (state) => {
      state.selectedSurveyStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchTraineeSurvey.fulfilled, (state, { payload }) => {
      state.selectedSurveyStatus = RequestStatus.SUCCESS;
      state.selectedSurvey = payload;
    });
    builder.addCase(fetchTraineeSurvey.rejected, (state) => {
      state.selectedSurveyStatus = RequestStatus.FAILED;
    });
    builder.addCase(markSurveyAsOpened.fulfilled, (state, { payload }) => {
      if (state.selectedSurvey?.id === payload.id) {
        state.selectedSurvey = {
          ...state.selectedSurvey,
          ...payload,
        };
      }
    });
  },
});

export const { clearSelectedSurvey, clearSurveys, addSurvey, updateSurvey } = reducerSlice.actions;
export default reducerSlice.reducer;
