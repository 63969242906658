import { type Database } from "@fitness-app/supabase";

import { type FileMessageContent } from "./Chat";

export type ExerciseRecordingEntity = Database["public"]["Tables"]["exercise_recording"]["Row"];
export type ExerciseRecordingOwnership = Database["public"]["Tables"]["exercise_recording_ownership"]["Row"];

export enum ExerciseRecordingStatus {
  PENDING = "pending",
  SCHEDULED = "scheduled",
  RECORDED = "recorded",
  RATED = "rated",
  ARCHIVED = "archived",
}

export interface ExerciseRecording extends Omit<ExerciseRecordingEntity, "status" | "metadata"> {
  status: ExerciseRecordingStatus;
  metadata:
    | null
    | ({ path?: string | null; addedAt?: string | null } & Record<string, string | number | null | boolean>);
}

export interface ExerciseRecordingWithExerciseInfo extends ExerciseRecording {
  exercise: {
    name: string;
  };
  plan: {
    name: string;
  } | null;
  feedback: {
    createdAt: string;
    content: string;
    files: FileMessageContent[];
  } | null;
  trainee?: {
    firstName: string;
    lastName: string;
    email: string;
    trainer?: {
      firstName: string;
      lastName: string;
      userId: string;
    } | null;
  };
}
