import React, { useState } from "react";
import { VideoCameraOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";

import {
  ExerciseRecordingStatus,
  type ExerciseRecordingWithExerciseInfo,
} from "@fitness-app/data-models/entities/ExerciseRecording";

import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";

interface ExerciseRecordingBadgeProps {
  exerciseRecording: ExerciseRecordingWithExerciseInfo;
  showName?: boolean;
}

export const ExerciseRecordingBadge = ({ exerciseRecording, showName = false }: ExerciseRecordingBadgeProps) => {
  const [video, setVideo] = useState<null | string>(null);

  if (exerciseRecording.status === ExerciseRecordingStatus.RECORDED) {
    return (
      <>
        <Tooltip title={showName ? `${exerciseRecording.exercise.name}: video przesłane` : "Video przesłane"}>
          <Tag
            icon={<VideoCameraOutlined />}
            color="success"
            onClick={(e) => {
              e.stopPropagation();
              setVideo(exerciseRecording.url);
            }}
          />
        </Tooltip>
        {video && (
          <div onClick={(e) => e.stopPropagation()}>
            <VideoPlayer hideControl videoUrl={video} visible={!!video} onClose={() => setVideo(null)} />
          </div>
        )}
      </>
    );
  }
  if (exerciseRecording.status === ExerciseRecordingStatus.RATED) {
    return (
      <>
        <Tooltip title={showName ? `${exerciseRecording.exercise.name}: video ocenione` : "Video ocenione"}>
          <Tag
            icon={<VideoCameraOutlined />}
            color="gold"
            onClick={(e) => {
              e.preventDefault();
              setVideo(exerciseRecording.url);
            }}
          />
        </Tooltip>
        {video && (
          <div onClick={(e) => e.stopPropagation()}>
            <VideoPlayer hideControl videoUrl={video} visible={!!video} onClose={() => setVideo(null)} />
          </div>
        )}
      </>
    );
  }
  if (exerciseRecording.status === ExerciseRecordingStatus.SCHEDULED) {
    return (
      <Tooltip title={showName ? `${exerciseRecording.exercise.name}: video zaplanowane` : "Video zaplanowane"}>
        <Tag color="processing" icon={<VideoCameraOutlined />} />
      </Tooltip>
    );
  }

  return null;
};
