import React, { useEffect, useMemo } from "react";
import { Form, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import keyBy from "lodash.keyby";

import { type TrainingProgramDetails } from "@fitness-app/data-models/entities/TrainingProgram";
import { createWorkoutDayOptions } from "@fitness-app/utils/src/programs/createWorkoutDayOptions";

import { week } from "~/constants/weekOptions";
import { useAppSelector } from "~/store/initializeStore";
import { type CustomWeekTrainingScheduleModel } from "./types";

dayjs.extend(isoWeek);

interface CustomWeekTrainingScheduleProps {
  formController?: FormInstance<CustomWeekTrainingScheduleModel>;
  onSubmit: (formData: CustomWeekTrainingScheduleModel) => void;
  model?: Partial<CustomWeekTrainingScheduleModel> | null;
  programWorkouts: TrainingProgramDetails;
  selectedWeekOfYear?: number;
  selectedYear?: number;
}

const weekInitial = {
  monday: { workoutDay: null },
  tuesday: { workoutDay: null },
  wednesday: { workoutDay: null },
  thursday: { workoutDay: null },
  friday: { workoutDay: null },
  saturday: { workoutDay: null },
  sunday: {
    workoutDay: null,
  },
};

const CustomWeekTrainingSchedule = ({
  model,
  onSubmit,
  formController,
  programWorkouts,
  selectedWeekOfYear,
  selectedYear,
}: CustomWeekTrainingScheduleProps) => {
  const traineeActivities = useAppSelector((store) => store.traineeActivities.activities);
  const activitiesByDate = keyBy(traineeActivities, "eventDate");

  const workoutsOptions = useMemo(() => {
    return createWorkoutDayOptions(programWorkouts.workouts ?? [], programWorkouts.archivedWorkouts ?? []);
  }, [programWorkouts]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model, formController]);

  const currentDay = dayjs().startOf("day");

  return (
    <div>
      <Form layout="vertical" onFinish={onSubmit} form={formController}>
        <Form.List initialValue={[weekInitial]} name="weeks">
          {(fields) => (
            <div style={{ display: "flex" }}>
              {fields.map((field) => (
                <div className="flex-1" key={field.name}>
                  {week.map((day, number) => {
                    let dayOfTheWeek = "";
                    let toCompare = null;

                    if (selectedWeekOfYear && selectedYear) {
                      toCompare = dayjs()
                        .isoWeekday(number + 1)
                        .week(selectedWeekOfYear)
                        .year(selectedYear);
                      dayOfTheWeek = toCompare.format("YYYY-MM-DD");
                    }
                    const dayPassed = toCompare?.isBefore(currentDay);
                    const hasActivity = Boolean(activitiesByDate[dayOfTheWeek]?.data?.replicatedFromSchedule);
                    return (
                      <Form.Item
                        {...field}
                        key={`${field.name}-${day.key}`}
                        name={[field.name, day.key, "workoutDay"]}
                        label={day.label}
                        wrapperCol={{ xs: 18 }}
                        tooltip={
                          (dayPassed && "Nie możesz edytować treningu w dniu który już minął") ||
                          (hasActivity && "Nie możesz edytować dnia, który został już zmodyfikowany")
                        }
                      >
                        <Select
                          disabled={Boolean(hasActivity || dayPassed)}
                          options={workoutsOptions}
                          placeholder="Wybierz dzień treningowy"
                          allowClear
                        />
                      </Form.Item>
                    );
                  })}
                </div>
              ))}
            </div>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default CustomWeekTrainingSchedule;
