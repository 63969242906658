import { createAsyncThunk } from "@reduxjs/toolkit";

import { type MealsPlan, type MealsPlanDetails } from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";
import { fetchMealsPlanWithDetails } from "./fetchMealsPlanWithDetails";

type Payload = {
  mealsPlan: Partial<MealsPlan> & { id: string };
  mealsPlanDetails?: (Partial<MealsPlanDetails> & { id: string }) | null;
};

export const updateMealsPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/updateMealsPlan`,
  async (payload, { rejectWithValue, dispatch, extra: { db, analytics } }) => {
    const { id: mealsPlanId, ...rest } = payload.mealsPlan;

    if (rest) {
      const { error } = await db.from("meals_plan").update(payload.mealsPlan).eq("id", mealsPlanId);

      if (error) {
        return rejectWithValue(error.message);
      }
    }

    if (payload.mealsPlanDetails) {
      const { error: errorDetails } = await db
        .from("meals_plan_details")
        .update(payload.mealsPlanDetails)
        .eq("id", payload.mealsPlanDetails?.id);

      if (errorDetails) {
        return rejectWithValue(errorDetails.message);
      }
    }

    analytics.track("update_meals_plan");

    void dispatch(fetchMealsPlanWithDetails({ id: payload.mealsPlan.id }));
  },
);
