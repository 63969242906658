import { message } from "antd";
import { useTranslation } from "react-i18next";

import { productsActions } from "@fitness-app/app-store";
import {
  EndAccessStrategy,
  PaymentTypeOption,
  ProductChargeType,
  ProductDurationType,
  type CreateProductDTO,
  type EndAccessOffers,
  type InvoicesClient,
  type OneTimePrice,
  type Product,
  type RecurringInterval,
  type RecurringPrice,
  type UpsellTo,
} from "@fitness-app/data-models/entities/Product";

import { LIST_ITEM_SEPARATOR } from "~/modules/Products/constants/listSeparator";
import { BillingPeriod } from "~/modules/Products/constants/productForm";
import { type ProductFormModel } from "~/modules/Products/ProductForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const getProductDuration = (
  type: ProductFormModel["productDurationType"],
  duration: ProductFormModel["productDuration"],
): Product["productDuration"] => {
  if (!type) {
    return null;
  }

  if (type === ProductDurationType.Infinite) {
    return { type: ProductDurationType.Infinite };
  }

  if (type === ProductDurationType.UntilDate && duration && "untilDate" in duration) {
    return {
      type: ProductDurationType.UntilDate,
      date: duration.untilDate.toISOString(),
    };
  }

  if (type === ProductDurationType.LastsFor && duration && "count" in duration) {
    return {
      type: ProductDurationType.LastsFor,
      unit: duration.unit,
      count: duration.count,
    };
  }

  return null;
};

export const useProductSubmit = (successCallback?: () => void) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector((store) => store.products.productsListData);
  const integration = useAppSelector((store) => store.productsSettings.data);
  const { t } = useTranslation(["products"]);

  const handleSubmit = (data: ProductFormModel) => {
    const { customInvoicesClient, ...formData } = data;

    const [invoiceClientAccountName, invoiceClient, invoiceClientUrl] =
      customInvoicesClient?.split(LIST_ITEM_SEPARATOR) ?? [];

    const productDto: CreateProductDTO = {
      ...formData,
      upsellTo: formData.upsellTo
        ?.map((id) => {
          const found = products.find((product) => product.id === id);
          if (found) {
            return {
              productName: found.name,
              productId: found.id,
              checkoutId: integration?.stripeConnectedAccountId || null,
            };
          } else {
            return null;
          }
        })
        .filter((item): item is UpsellTo => !!item),
      endAccessOffers: formData.endAccessOffers
        ?.map((id) => {
          const found = products.find((product) => product.id === id);
          if (found) {
            return {
              productName: found.name,
              productId: found.id,
            };
          } else {
            return null;
          }
        })
        .filter((item): item is EndAccessOffers => !!item),
      productDuration: getProductDuration(data.productDurationType, data.productDuration),
      type: formData.productType,
      customInvoicesClient:
        data.customInvoicesClient && !data.isFree
          ? {
              accountUrl: invoiceClientUrl ?? "",
              accountName: invoiceClientAccountName ?? "",
              client: invoiceClient as InvoicesClient,
            }
          : null,
      active: true,
      chargeType: data.isFree ? ProductChargeType.FREE : ProductChargeType.PAID,
      images: data.images?.map((image) => (typeof image === "string" ? image : image.url)) ?? [],
      prices: data.isFree
        ? []
        : data.prices.map(
            ({
              currency,
              amount,
              billingPeriod,
              paymentType,
              endAccessStrategy,
              billingInterval,
              billingIntervalCount,
            }) => {
              if (paymentType === PaymentTypeOption.Recurring && billingPeriod) {
                return {
                  amount,
                  currency,
                  type: PaymentTypeOption.Recurring,
                  active: true,
                  interval_count:
                    billingPeriod === BillingPeriod.Custom
                      ? billingIntervalCount || 1
                      : Number(billingPeriod.split("-")[0]),
                  interval:
                    billingPeriod === BillingPeriod.Custom
                      ? billingInterval || "month"
                      : (billingPeriod.split("-")[1] as RecurringInterval),
                } as RecurringPrice;
              }
              return {
                amount,
                currency,
                active: true,
                type: PaymentTypeOption.OneTime,
                endAccessStrategy: endAccessStrategy || EndAccessStrategy.Cancel,
                ...(billingPeriod && billingPeriod !== BillingPeriod.ToCancel
                  ? {
                      interval_count:
                        billingPeriod === BillingPeriod.Custom
                          ? billingIntervalCount || 1
                          : Number(billingPeriod.split("-")[0]),
                      interval:
                        billingPeriod === BillingPeriod.Custom
                          ? billingInterval || "month"
                          : (billingPeriod.split("-")[1] as RecurringInterval),
                    }
                  : {}),
              } as OneTimePrice;
            },
          ),
    };
    const onSuccess = () => {
      void message.success(t("messages.success.addProduct"));
      successCallback?.();
    };

    const onFailure = () => {
      void message.error(t("messages.error.addProduct"));
    };

    void dispatch(productsActions.addProduct({ payload: productDto, onSuccess, onFailure }));
  };

  return {
    handleSubmit,
  };
};
