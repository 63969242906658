import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ClientNutrition } from "@fitness-app/data-models/entities/ClientNutrition";
import { type NutritionAttachment } from "@fitness-app/data-models/entities/MealsPlan";

import { type AsyncThunkCreator } from "../../../index";
import { MEALS_PLANS_REDUCER_NAME } from "../types";

type Payload = {
  id: string;
  traineeId: string;
};

export const fetchMealsPlanAttachments = createAsyncThunk<
  {
    attachments: NutritionAttachment[];
    name: string;
    createdAt: string;
    status: ClientNutrition["status"];
    startAt: ClientNutrition["status"];
    endAt: ClientNutrition["endAt"];
  }[],
  Payload,
  AsyncThunkCreator<string>
>(`${MEALS_PLANS_REDUCER_NAME}/fetchMealsPlanAttachments`, async (payload, { rejectWithValue, extra: { db } }) => {
  const query = db
    .from("client_meals_plan")
    .select("attachments, name, startAt, endAt, status, createdAt, id")
    .eq("traineeId", payload.traineeId)
    .not("id", "eq", payload.id)
    .order("createdAt", { ascending: false });

  const { error, data } = await query.returns<
    {
      attachments: NutritionAttachment[];
      name: string;
      createdAt: string;
      status: ClientNutrition["status"];
      startAt: ClientNutrition["status"];
      endAt: ClientNutrition["endAt"];
    }[]
  >();

  if (error) {
    return rejectWithValue(error.message);
  }

  return data;
});
