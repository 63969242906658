import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Badge, Button, Form, Input, Modal, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

interface DiscordIdCellProps {
  row: ProductClient;
}

const { confirm } = Modal;

const DiscordIdCell = ({ row }: DiscordIdCellProps): React.ReactElement => {
  const { t } = useTranslation(["products", "common"]);
  const [inputValue, setInputValue] = useState(row.discordId ?? "");
  const [formController] = Form.useForm<{ discordId: string }>();

  const defaultDiscordId = row.discordId ?? "";

  const handleSaveDiscordId = () => {
    throw new Error("Not implemented");
  };

  const showPromiseConfirm = () => {
    formController.setFieldsValue({
      discordId: defaultDiscordId,
    });
    confirm({
      title: t("productClients.updateDiscordId"),
      okText: t("common:button.save"),
      icon: <EditOutlined />,
      content: (
        <Form
          layout="vertical"
          className="mt-6"
          form={formController}
          initialValues={{
            discordId: inputValue,
          }}
        >
          <Form.Item
            colon
            label={t("productClients.tableHeader.discordId")}
            name="discordId"
            rules={[
              {
                min: 18,
                message: t<string>("common:validationErrors.minLength", {
                  number: 18,
                }),
              },
              {
                type: "number",
                message: t<string>("productIntegration.discord.wrongFormat"),
                transform: (value) => (value ? Number(value) : 0),
              },
            ]}
          >
            <Input autoFocus value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
          </Form.Item>
        </Form>
      ),
      onOk() {
        return handleSaveDiscordId();
      },
    });
  };

  return (
    <Space size={4}>
      <Badge status={row.discordId ? "success" : "warning"} />{" "}
      {row.discordId ? (
        <>
          <Typography.Text copyable>{row.discordId}</Typography.Text>
          <Button onClick={showPromiseConfirm} type="link" icon={<EditOutlined />} />
        </>
      ) : (
        <>
          <Typography.Text>{t("productClients.tableHeader.notIntegrated")}</Typography.Text>
          <Button onClick={showPromiseConfirm} type="link" icon={<EditOutlined />} />
        </>
      )}
    </Space>
  );
};

export default DiscordIdCell;
