import React, { Fragment, useState, type FunctionComponent } from "react";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Dialog, Transition } from "@headlessui/react";
import { Layout, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "~/components/LanguageSwitcher/LanguageSwitcher";
import logo from "../../assets/ic_launcher.png";

interface OwnProps {
  menu: React.ReactElement;
  rightSection: React.ReactElement;
  leftSection?: React.ReactElement;
  children: React.ReactElement;
}

type Props = OwnProps;

const currentYear = new Date().getFullYear();

const DashboardWrapper: FunctionComponent<Props> = ({ children, menu, rightSection, leftSection }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { t } = useTranslation(["common", "products"]);
  const appVersion = import.meta.env.PACKAGE_VERSION;

  return (
    <>
      <div className="dashboard flex min-h-full overscroll-none">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => {
                        setTimeout(() => {
                          setSidebarOpen(false);
                        }, 300);
                      }}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <CloseOutlined style={{ fontSize: 24, color: "#fff" }} />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center gap-x-2 px-4">
                  <img className="h-12 w-auto" src={logo} alt="Hollywood app" />
                  <Typography.Text
                    style={{
                      color: "#978365",
                      fontSize: 20,
                      fontWeight: 500,
                    }}
                  >
                    SylwetkaPro
                  </Typography.Text>
                </div>
                <div className="mt-5 flex flex-1 flex-col overflow-y-auto">
                  <nav className="flex-1" onClick={() => setSidebarOpen(false)}>
                    <div className="space-y-1">{menu}</div>
                  </nav>
                  <Layout.Footer
                    className="space-between flex flex-col text-center text-white"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      padding: "24px 0",
                    }}
                  >
                    <Space size={18} direction="vertical">
                      <div className="text-xs">
                        {t("common:appVersion")} {appVersion}
                      </div>
                      <div className="text-xs">&reg; {t("auth:copyright", { year: currentYear })}</div>
                    </Space>
                  </Layout.Footer>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-800 lg:pb-4">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col">
            <div className="flex h-16 flex-shrink-0 items-center gap-x-2 bg-gray-900 px-4">
              <img className="h-12 w-auto" src={logo} alt="SylwetkaPro" />
              <Typography.Text
                style={{
                  color: "#978365",
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                SylwetkaPro
              </Typography.Text>
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto">
              <nav className="flex-1 pb-4 pt-2">
                <div className="space-y-1">{menu}</div>
              </nav>
              <Layout.Footer className="space-between flex flex-col bg-transparent text-center text-gray-400">
                <Space size={18} direction="vertical">
                  <div className="text-xs">
                    {t("common:appVersion")} {appVersion}
                  </div>
                  <div className="text-xs">&reg; {t("auth:copyright", { year: currentYear })}</div>
                </Space>
              </Layout.Footer>
            </div>
          </div>
        </div>
        <div className="flex w-0 flex-1 flex-col lg:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuOutlined />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="ml-2 flex flex-1 items-center">{leftSection}</div>
              <div className="ml-4 mr-2 flex items-center gap-6 lg:ml-6">
                <div className="hidden">
                  <LanguageSwitcher />
                </div>
                {rightSection}
              </div>
            </div>
          </div>
          <main className="bg-default max-content-height flex-1">{children}</main>
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
