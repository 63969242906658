import React, { useEffect, useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragOverEvent,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Button, Form, Input, InputNumber, List, Select, Space, Spin, Tag, Typography } from "antd";
import { type FormInstance } from "antd/lib/form/Form";
import round from "lodash.round";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuid } from "uuid";

import { nutritionActions } from "@fitness-app/app-store";
import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal, type Meal } from "@fitness-app/data-models/entities/MealsPlan";
import { calculateMeasureMultiplier } from "@fitness-app/utils/src/nutrition/calculateMeasureMultiplier";
import { calculateForPortion, countCaloriesByIngredients } from "@fitness-app/utils/src/nutrition/countNutrients";
import { findPreferableMeasure } from "@fitness-app/utils/src/nutrition/findPreferableMeasure";
import { recalculateMeasures } from "@fitness-app/utils/src/nutrition/recalculateMeasures";
import { sumNutrients } from "@fitness-app/utils/src/nutrition/sumNutrients";

import { coordinateGetter } from "~/components/Dnd/multipleContainersKeyboardCoordinates";
import DishIngredientItem from "~/modules/Nutrition/DishForm/DishIngredientItem";
import { type DishFormModel } from "~/modules/Nutrition/DishForm/types";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { MealDayLiveSummary } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/components/MealDayLiveSummary";
import { type DishDetailsForm } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsProductProvider";
import { useAppDispatch } from "~/store/initializeStore";

interface DishDetailsProps {
  dish: DishInMeal;
  formController: FormInstance<DishDetailsForm>;
  mealsForDay: Meal[];
  onSubmit: (props: {
    ingredients: DishIngredient[];
    portion: number;
    prepTime: number;
    portions: number;
    calories: number;
    portionCount: number;
    preparationSteps: string[];
    name: string;
  }) => void;
}

const DishDetails = ({ dish, formController, onSubmit, mealsForDay }: DishDetailsProps) => {
  const { t } = useTranslation("nutrition");
  const { measuresMap } = useNutritionMeasures();
  const ingredients = Form.useWatch("ingredients", formController) || dish.ingredients;
  const portions = Form.useWatch("portions", formController) || 1;
  const portionCount = Form.useWatch("portionCount", formController) || 1;
  const name = Form.useWatch("name", { form: formController, preserve: true }) || dish.name;

  const [fetching, setFetching] = useState(false);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<{ value: string; label: string; product: Ingredient }[]>([]);
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  useEffect(() => {
    if (dish) {
      formController.setFieldsValue({
        // @ts-expect-error ignore
        ingredients: dish.ingredients.map((ingredient) => {
          const enhancedIngredient = recalculateMeasures(ingredient);
          const { multiplier } = calculateMeasureMultiplier(enhancedIngredient, measuresMap);

          return {
            ...enhancedIngredient,
            multiplier,
          };
        }),
        name: dish.name,
        portion: dish.portion || 1,
        portions: dish.portions || 1,
        prepTime: dish.prepTime || 0,
        portionCount: dish.portionCount || 1,
        preparationSteps: !dish.preparationSteps || dish.preparationSteps?.[0] === "" ? [] : dish.preparationSteps,
      });
    }
  }, [dish, formController, measuresMap]);

  const protein = calculateForPortion(sumNutrients(ingredients, "protein"), {
    portionCount,
    portions,
  });
  const carbs = calculateForPortion(sumNutrients(ingredients, "carbohydrates"), {
    portionCount,
    portions,
  });
  const fat = calculateForPortion(sumNutrients(ingredients, "fat"), {
    portionCount,
    portions,
  });

  const calories = useMemo(() => {
    return round(countCaloriesByIngredients(ingredients) * (portionCount / portions), 1);
  }, [ingredients, portionCount, portions]);

  const portionSize = useMemo(
    () =>
      calculateForPortion(
        ingredients.reduce((prev, current) => {
          return current.portion + prev;
        }, 0),
        { portions, portionCount },
      ),
    [ingredients, portions, portionCount],
  );

  const handleDragEnd = (event: DragOverEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = ingredients.findIndex(({ id }) => id === active.id);
      const newIndex = ingredients.findIndex(({ id }) => id === over.id);
      const newFields = arrayMove(ingredients, oldIndex, newIndex);

      formController.setFieldValue("ingredients", newFields);
    }
  };

  const handleSubmit = (model: DishDetailsForm) => {
    onSubmit({
      ingredients: model.ingredients.map((ingredient) => ({
        ...ingredient,
        portionCalories: ingredient.nutrients.calories
          ? round((ingredient.nutrients.calories * ingredient.portion) / 100, 1)
          : 0,
      })),
      portionCount: model.portionCount,
      portions: model.portions,
      portion: portionSize,
      calories,
      name: model.name || name || dish.name,
      prepTime: model.prepTime || 0,
      preparationSteps: model.preparationSteps || [],
    });
  };

  const onIngredientSearch = useDebouncedCallback(async (value: string) => {
    setOptions([]);
    setFetching(true);

    const result = await dispatch(nutritionActions.searchIngredient({ searchTerm: value })).unwrap();

    setFetching(false);
    setOptions(
      result.data.map((product) => ({
        label: `${product.name} ${product.brand ? `- ${product.brand}` : ""} - ${product.nutrients.calories} kcal`,
        value: product.id,
        product,
      })),
    );
  }, 300);

  const onAddIngredient = (
    selected: { value: string },
    add: (defaultValue?: any, insertIndex?: number | undefined) => void,
  ) => {
    setSelectValue(null);
    const ingredient = options.find((option) => option.value === selected.value);

    if (ingredient) {
      const measure = ingredient.product.measures[0];

      const preferableMeasure = findPreferableMeasure(ingredient.product) || measure;
      const portion = ingredient.product.packageSize || 100;

      const ingredientInDish: DishIngredient & { multiplier: number } = {
        ...ingredient.product,
        ingredientId: ingredient.product.id,
        calories: preferableMeasure?.energyPerUnit || 0,
        id: uuid(),
        portion: ingredient.product.packageSize || 100,
        hint: null,
        measure: preferableMeasure?.name || "",
        measureId: preferableMeasure?.id || -1,
        measureWeight: preferableMeasure?.weightPerUnit || 0,
        measureCapacity: null,
        multiplier: 1,
        portionCalories: round((ingredient.product.nutrients.calories * portion) / 100, 1),
      };

      add(ingredientInDish);
    }
  };

  const onMeasureSelect = (ingredient: DishIngredient, measureId: number, index: number) => {
    const result = calculateMeasureMultiplier(ingredient, measuresMap, measureId);
    if (result.measureId === 1) {
      formController.setFieldValue(["ingredients", index, "multiplier"], 1);
    } else {
      formController.setFieldValue(["ingredients", index, "multiplier"], 1);
      formController.setFieldValue(["ingredients", index, "portion"], result.weightPerUnit);
    }
  };

  return (
    <>
      <Space direction="vertical" size={24} className="w-full">
        <div>
          <Typography.Title
            level={4}
            editable={{
              autoSize: { minRows: 1, maxRows: 1 },
              maxLength: 1000,
              onChange: (value) => {
                formController.setFieldsValue({ name: value || dish.name });
              },
            }}
          >
            {name}
          </Typography.Title>

          <div className="flex flex-col justify-between gap-4 ">
            <dd className="flex gap-4 text-xl font-medium">
              <span>{round(calories ?? dish.calories ?? 0, 1)} kcal</span>
              <span>/</span>
              <span>{portionSize} g</span>
            </dd>
            <dd className="flex gap-2 text-sm text-gray-700">
              <span>
                {t("nutrients.protein")}: {protein} g
              </span>
              <span>|</span>
              <span>
                {t("nutrients.carbohydrates")}: {carbs} g
              </span>
              <span>|</span>
              <span>
                {t("nutrients.fat")}: {fat} g
              </span>
            </dd>
          </div>
        </div>
        <Form
          onFinish={handleSubmit}
          form={formController}
          key={dish.id}
          initialValues={{
            portionCount: dish.portionCount || 1,
            portions: dish.portions || 1,
            prepTime: dish.prepTime || 0,
            ingredients: [],
            name: dish.name,
          }}
        >
          <div>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragOver={handleDragEnd}>
              {ingredients.length > 0 && (
                <Form.List
                  name="ingredients"
                  rules={[
                    {
                      validator: async (_, levels: string[]) => {
                        if (!levels || levels.length < 1) {
                          return Promise.reject(new Error("At least 1 level"));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { remove, add }) => {
                    return (
                      <div>
                        {fields.map((field, index) => {
                          const ingredient = ingredients[index];

                          if (!ingredient) {
                            return null;
                          }
                          return (
                            <DishIngredientItem
                              key={field.key}
                              ingredient={ingredient}
                              fieldKey={field.key}
                              index={index}
                              fieldName={field.name}
                              onRemove={remove}
                              onMeasureSelect={(value) => onMeasureSelect(ingredient, value, index)}
                              formController={formController as FormInstance<DishFormModel | DishDetailsForm>}
                            />
                          );
                        })}
                        <Form.Item label="Dodaj składnik" labelCol={{ span: 24 }}>
                          <Select
                            labelInValue
                            filterOption={false}
                            placeholder="Wyszukaj składnik..."
                            showSearch
                            onSearch={onIngredientSearch}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            className="mb-4"
                            style={{ maxWidth: 390 }}
                            optionLabelProp="label"
                            value={selectValue}
                            onSelect={(value) => onAddIngredient(value as unknown as { value: string }, add)}
                          >
                            {options.map((option) => (
                              <Select.Option value={option.value} label={option.label} key={option.value}>
                                <List.Item className="group relative cursor-pointer">
                                  <List.Item.Meta
                                    title={`${option.product.name} ${
                                      option.product.brand ? `- ${option.product.brand}` : ""
                                    }`}
                                    description={
                                      <div className="flex justify-between pt-2 font-light">
                                        <dd className="text-xs text-gray-700">
                                          B: {option.product.nutrients.protein} g | W:{" "}
                                          {option.product.nutrients.carbohydrates} g | T: {option.product.nutrients.fat}{" "}
                                          g
                                        </dd>
                                        <dd className="text-xs text-gray-700">
                                          {option.product.nutrients.calories} kcal (
                                          {option.product.packageSize || "100"}
                                          g)
                                        </dd>
                                      </div>
                                    }
                                  />
                                </List.Item>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
              )}
            </DndContext>

            <Form.Item label={t("dish.portion")}>
              <div className="flex items-center gap-x-2">
                <Form.Item name="portionCount" noStyle>
                  <InputNumber precision={1} step={0.5} max={portions} min={1} />
                </Form.Item>
                <span>{t("dish.from")}</span>
                <Form.Item name="portions" noStyle>
                  <InputNumber precision={1} step={0.5} min={1} max={10} />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label={t("dish.prepTime")} name="prepTime">
              <InputNumber min={0} max={400} addonAfter="min" style={{ maxWidth: 150 }} />
            </Form.Item>
          </div>
          <div>
            <Typography.Title level={5}>{t("dish.instruction")}</Typography.Title>

            <Form.List name="preparationSteps">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field) => (
                      <Form.Item
                        {...field}
                        key={field.key}
                        rules={[
                          {
                            required: true,
                            message: t<string>("common:validationErrors.fieldIsRequired"),
                          },
                        ]}
                      >
                        <Input
                          addonAfter={
                            <Button
                              size="small"
                              type="text"
                              icon={<DeleteOutlined className="text-red-500" />}
                              onClick={() => remove(field.key)}
                            />
                          }
                        />
                      </Form.Item>
                    ))}
                    <Button shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add("")}></Button>
                  </div>
                );
              }}
            </Form.List>
          </div>
        </Form>
        <div>
          <Typography.Title level={5}>{t("dish.mealType")}</Typography.Title>
          <div className="flex flex-wrap gap-1 gap-y-2">
            {dish.mealTypes?.map((mealType) => <Tag key={mealType}>{t(`mealType.${mealType}`)}</Tag>)}
          </div>
        </div>
        <div>
          <Typography.Title level={5}>{t("dish.dishType")}</Typography.Title>
          <div className="flex flex-wrap gap-1 gap-y-2">
            {dish.dishTypes?.map((dishType) => <Tag key={dishType}>{t(`dishType.${dishType}`)}</Tag>)}
          </div>
        </div>
      </Space>
      <MealDayLiveSummary
        mealsForDay={mealsForDay}
        currentDish={{
          id: dish.id,
          calories: round(calories ?? dish.calories ?? 0, 1),
          fat,
          protein,
          carbs,
        }}
      />
    </>
  );
};

export default DishDetails;
