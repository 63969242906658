import React, { memo, useMemo, useState } from "react";
import { ClockCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Form, InputNumber, Space, Table } from "antd";
import { useTranslation } from "react-i18next";

import { type ExerciseInWorkout, type SuperSet } from "@fitness-app/data-models/entities/TrainingProgram";

import { ExerciseThumbnail } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/ExerciseThumbnail";
import {
  createExerciseInfo,
  formatDuration,
  prepareSuperSetData,
  type ExerciseLineSet,
  type MergedSeriesData,
} from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/helpers";
import { RecordVideoSwitch } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/RecordVideoSwitch";

interface SuperSetSetProps {
  data: SuperSet;
  changeNumberOfSeries: (numberOfSeries: number) => void;
  updateExerciseInWorkout: (exercise: ExerciseInWorkout) => void;
  toggleRecordingVideoFlag?: (exerciseId: string, checked: boolean) => void;
}

const { Column } = Table;

const SuperSetSet = ({
  data,
  changeNumberOfSeries,
  updateExerciseInWorkout,
  toggleRecordingVideoFlag,
}: SuperSetSetProps) => {
  const dataSource = useMemo(() => prepareSuperSetData(data, 5), [data]);
  const [numberOfSeries, setNumberOfSeries] = useState<null | number>(data.numberOfSeries ?? 1);
  const { t } = useTranslation("workouts");

  return (
    <Space direction="vertical" className="w-full py-4">
      <div>
        <Form.Item label="Liczba serii">
          <InputNumber
            size="small"
            min={1}
            max={15}
            value={numberOfSeries}
            onChange={(value) => {
              setNumberOfSeries(value);
              if (value !== null && value > 0) {
                changeNumberOfSeries(value);
              }
            }}
          />
        </Form.Item>
      </div>

      <Table<MergedSeriesData> dataSource={dataSource} size="small" rowKey="rowKey" pagination={false} data-ctx="table">
        <Column<MergedSeriesData>
          title="Seria"
          dataIndex="series"
          key="series"
          render={(text: string, row) => {
            if (row.colSpan) {
              return {
                props: {
                  colSpan: row.colSpan,
                  className: row.colSpan === 5 ? "restBetweenSeries" : "restBetweenExercise",
                },
                children: (
                  <div className="text-center">
                    <ClockCircleOutlined />
                    <span style={{ marginLeft: 10, fontWeight: 500 }}>Przerwa: {row.restTime} sek</span>
                  </div>
                ),
              };
            }
            if (row.rowSpan) {
              return {
                props: {
                  rowSpan: row.rowSpan,
                },
                children: text,
              };
            }
            return {
              children: "",
              props: {
                rowSpan: 0,
              },
            };
          }}
        />
        <Column<MergedSeriesData>
          title="Ćwiczenie"
          dataIndex="name"
          key="name"
          data-ctx="repeats-column"
          render={(name: string, record) => {
            if (record.colSpan) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            if (record.type !== "restAfterExercise" && record.type !== "restAfterSeries") {
              return (
                <div className="flex items-center gap-x-3">
                  <ExerciseThumbnail exerciseInWorkout={record.exercise} />

                  <span className="text-sm font-medium text-gray-900">{name}</span>
                </div>
              );
            }
            return <span className="text-sm font-medium text-gray-900">{name}</span>;
          }}
        />
        <Column<MergedSeriesData>
          title="Powtórzenia"
          dataIndex="repeats"
          key="repeats"
          data-ctx="repeats-column"
          render={(repeats: ExerciseLineSet["repeats"], record) => {
            if (record.colSpan) {
              return {
                children: null,
                props: {
                  colSpan: 0,
                },
              };
            }
            return (
              <span>
                <div>{createExerciseInfo(repeats)}</div>
              </span>
            );
          }}
        />
        <Column<MergedSeriesData>
          title="Obciąż./Czas trwania"
          dataIndex="weight"
          key="weight"
          data-ctx="weight-column"
          render={(weight: ExerciseLineSet["weight"], record) =>
            "colSpan" in record ? (
              {
                children: null,
                props: {
                  colSpan: 0,
                },
              }
            ) : (
              <div className="flex gap-x-2">
                <div>{createExerciseInfo(weight, " kg")}</div>
                {record.duration?.value && (
                  <>
                    <span className="text-gray-700">|</span>
                    <div>
                      {createExerciseInfo(
                        formatDuration(record.duration),
                        ` ${t(`duration.${record.duration.format}`)}`,
                      )}
                    </div>
                  </>
                )}
              </div>
            )
          }
        />
        <Column<MergedSeriesData>
          title="Opcje/Status"
          dataIndex="status"
          data-ctx="status-column"
          key="status"
          render={(_status, record) =>
            "colSpan" in record ? (
              {
                children: null,
                props: {
                  colSpan: 0,
                },
              }
            ) : (
              <Space direction="horizontal" size={12}>
                <EditOutlined onClick={() => updateExerciseInWorkout(record.exercise)} />
                {record.series === 1 ? (
                  <RecordVideoSwitch
                    size="small"
                    exerciseInWorkoutId={record.exercise.id}
                    toggleRecordingVideoFlag={toggleRecordingVideoFlag}
                    defaultValue={record.exercise.requireExerciseVideoRecording}
                  />
                ) : null}
              </Space>
            )
          }
        />
      </Table>
    </Space>
  );
};

export default memo(SuperSetSet);
