import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../../../enums/requestStatus";
import { addProgram } from "./actions/addProgram";
import { fetchPrograms } from "./actions/fetchPrograms";
import { updateProgram } from "./actions/updateProgram";
import { PROGRAMS_REDUCER_NAME, type ProgramsReducer } from "./types";

const initialState: ProgramsReducer = {
  list: [],
  listStatus: null,
  programStatus: null,
  filters: {
    searchTerm: null,
    addedBy: null,
    isShared: null,
    programLevel: null,
    programType: null,
    tags: null,
  },
  pagination: {
    currentPage: 1,
    totalPages: 0,
    perPage: 25,
  },
};

const reducerSlice = createSlice({
  initialState,
  name: PROGRAMS_REDUCER_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.pending, (state, { meta }) => {
      state.listStatus = RequestStatus.FETCHING;

      if (Array.isArray(meta.arg?.tags) || meta.arg?.tags === null) {
        state.filters.tags = meta.arg?.tags || [];
      }

      if (meta.arg?.addedBy || meta.arg?.addedBy === null) {
        state.filters.addedBy = meta.arg.addedBy;
      }

      if (meta.arg?.programLevel || meta.arg?.programLevel === null) {
        state.filters.programLevel = meta.arg.programLevel;
      }

      if (meta.arg?.programType || meta.arg?.programType === null) {
        state.filters.programType = meta.arg.programType;
      }

      if (meta.arg?.searchTerm || meta.arg?.searchTerm === null) {
        state.filters.searchTerm = meta.arg.searchTerm;
      }
    });
    builder.addCase(fetchPrograms.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.list = payload.data;
      state.pagination.currentPage = payload.page;
      state.pagination.totalPages = payload.totalPages;
    });
    builder.addCase(fetchPrograms.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(addProgram.pending, (state) => {
      state.programStatus = RequestStatus.ADDING;
    });
    builder.addCase(addProgram.rejected, (state) => {
      state.programStatus = null;
    });
    builder.addCase(addProgram.fulfilled, (state) => {
      state.programStatus = null;
    });
    builder.addCase(updateProgram.pending, (state) => {
      state.programStatus = RequestStatus.UPDATING;
    });
    builder.addCase(updateProgram.fulfilled, (state, { payload }) => {
      state.programStatus = null;

      state.list = payload?.archived
        ? state.list.filter((item) => item.id !== payload.id)
        : state.list.map((item) => (item.id === payload?.id ? { ...item, ...payload } : item));
    });
    builder.addCase(updateProgram.rejected, (state) => {
      state.programStatus = null;
    });
  },
});

export default reducerSlice.reducer;
