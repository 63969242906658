import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ExerciseWithVideo } from "@fitness-app/data-models/entities/Exercise";

import { type AsyncThunkCreator } from "../../../index";
import { EXERCISES_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchExercises = createAsyncThunk<ExerciseWithVideo[], Payload, AsyncThunkCreator<string>>(
  `${EXERCISES_REDUCER_NAME}/fetchExercises`,
  async (_payload, { rejectWithValue, extra: { db } }) => {
    const query = db
      .from("exercise")
      .select(
        "*, creator:createdBy(id, firstName, lastName), video:videoId(title, data->thumbnailUrl, data->previewUrl, data->mp4Url,data->hlsUrl, data->guid)",
        {
          count: "exact",
        },
      )
      .order("type")
      .order("createdAt", { ascending: false });

    const { data, error } = await query.returns<ExerciseWithVideo[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
